<div class="nv-modal small">
    <div class="nv-modal-header">
        <h4 class="nv-modal-title">Invite new members</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body">
        <div [formGroup]="invitationForm" class="nv-row gap-30 propagate">
            <ng-container formArrayName="invitations">
                <div class="nv-column desk-12" *ngFor="let item of invitationForm.controls.invitations['controls']; let indexInvitation = index; trackBy: trackBy">
                    <ng-container formGroupName="{{indexInvitation}}">
                        <div class="nv-column-content" formArrayName="invitation" *ngFor="let invitation of item['controls'].invitation['controls']; let i = index;">
                            <div class="nv-row gap-10" formGroupName="{{i}}">
                                <div class="nv-column desk-6">
                                    <div class="nv-column-content">
                                        <nv-input
                                                type='email'
                                                label="Email"
                                                formControlName="email"
                                                required
                                        ></nv-input>
                                    </div>
                                </div>
                                <div class="nv-column desk-4">
                                    <div class="nv-column-content">
                                        <nv-select
                                                [options]="roles"
                                                formControlName="role"
                                                label="Role"
                                        ></nv-select>
                                    </div>
                                </div>
                                <div class="nv-column desk-2 flow-right">
                                    <div class="nv-column-content">
                                        <nv-button
                                                [hidden]="invitationForm.value.invitations.length === 1"
                                                (click)="removeVariation(indexInvitation)"
                                                color="primary"
                                                onlyIcon
                                                icon="&#xe909;"
                                        ></nv-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="nv-column desk-2">
                    <div class="nv-column-content">
                        <nv-button
                                (click)="addVariation()"
                        >Add</nv-button>
                    </div>
                </div>
            </ng-container>
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <small>
                        Invited members will get an email that gives them access to the Navus Organizer Panel.
                        <br>
                        <strong *ngIf="invitationForm.value.expiration > 0">Invitation expires in {{ invitationForm.value.expiration }} days.</strong>
                        <strong *ngIf="invitationForm.value.expiration == 0">Invitation never expires.</strong>
                        Need to change this? (<a (click)="showExpiration = true">set expiration</a>)
                    </small>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-6 tab-12" *ngIf="showExpiration">
                <div class="nv-column-content">
                    <nv-select
                            [options]="expirationOptions"
                            formControlName="expiration"
                    ></nv-select>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div>
    </div>
    <div class="nv-modal-footer">
        <div class="nv-btn-box gap-10 flow-end">
            <nv-button color="neutral" (click)="close()">Cancel</nv-button>
            <nv-button (click)="sendInvitations()" [disabled]="invitationForm.invalid">Send invitations</nv-button>
        </div>
    </div>
</div>
