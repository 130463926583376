export class Certificate {
  id: number;
  title: string;
  send_to: string;
  template_id: number;
  threshold: number;
  poster: number;
  live: number;
  on_demand: number;
  on_site: number;
  active: number;
  unit_name: string;
}
