import { Routes } from '@angular/router';
import { OrganizationGuard } from '../../guards/organization.guard';
import { PresentationListPageComponent } from './pages/presentation-list/presentation-list-page.component';
import { PresentationDetailsPageComponent } from './pages/presentation-details/presentation-details-page.component';
import { BlockCategoryListPageComponent } from './pages/block-category-list/block-category-list-page.component';
import { BlockCategoryDetailsPageComponent } from './pages/block-category-details/block-category-details-page.component';
import { BlockListPageComponent } from './pages/block-list/block-list-page.component';
import { BlockDetailsPageComponent } from './pages/block-details/block-details-page.component';
import { ProgramLandingPageComponent } from './pages/program-landing/program-landing-page.component';
import { LocationListPageComponent } from './pages/location-list/location-list-page.component';
import { LocationDetailsPageComponent } from './pages/location-details/location-details-page.component';

export const ProgramRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: ProgramLandingPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Program'
    }
  },
  {
    path: 'presentations',
    component: PresentationListPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Presentation List'
    }
  },
  {
    path: 'presentation/:presentationId',
    component: PresentationDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Presentation Details'
    }
  },
  {
    path: 'presentation',
    component: PresentationDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Presentation Details'
    }
  },
  {
    path: 'block-categories',
    component: BlockCategoryListPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Session Category List'
    }
  },
  {
    path: 'block-category/:blockCategoryId',
    component: BlockCategoryDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Session Category Details'
    }
  },
  {
    path: 'block-category',
    component: BlockCategoryDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Session Category Details'
    }
  },
  {
    path: 'blocks',
    component: BlockListPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Session List'
    }
  },
  {
    path: 'block/:blockId',
    component: BlockDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Session Details'
    }
  },
  {
    path: 'block',
    component: BlockDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Session Details'
    }
  },
  {
    path: 'locations',
    component: LocationListPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Location List'
    }
  },
  {
    path: 'location/:locationId',
    component: LocationDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Location Details'
    }
  },
  {
    path: 'location',
    component: LocationDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Location Details'
    }
  },
];
