import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class CompanyService {

  constructor(
    private apiService: ApiService
  ) { }

  getSponsors(params: any = {}) {
    return this.apiService.get(`sponsors`, params);
  }

  getSponsor(sponsorId: number, params: any = {}) {
    return this.apiService.get(`sponsors/${sponsorId}`, params);
  }

  updateSponsor(sponsorId: number, params: any = {}) {
    return this.apiService.put(`sponsors/${sponsorId}`, params);
  }

  createSponsor(params: any = {}) {
    return this.apiService.post(`sponsors`, params);
  }

  deleteSponsor(sponsorId: number) {
    return this.apiService.delete(`sponsors/${sponsorId}`);
  }

  getConferenceSponsors(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/sponsors`, params);
  }

  getConferenceSponsor(conferenceId: number, sponsorId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/sponsors/${sponsorId}`, params);
  }

  updateConferenceSponsor(conferenceId: number, sponsorId: number, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/sponsors/${sponsorId}`, params);
  }

  createConferenceSponsor(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/sponsors`, params);
  }

  deleteConferenceSponsor(conferenceId: number, sponsorId: number) {
    return this.apiService.delete(`conferences/${conferenceId}/sponsors/${sponsorId}`);
  }

  getSponsorImages(sponsorId: number, params: any = {}) {
    return this.apiService.get(`sponsors/${sponsorId}/images`, params);
  }

  downloadSponsor(sponsorId: number, params: any = {}) {
    return this.apiService.download(`sponsors/${sponsorId}/download`, `sponsor_${sponsorId}`, params);
  }

  getSponsorHighlights(sponsorId: number, params: any = {}) {
    return this.apiService.get(`sponsors/${sponsorId}/highlights`, params);
  }

  addSponsorHighlight(sponsorId: number, params: any = {}) {
    return this.apiService.post(`sponsors/${sponsorId}/highlights`, params);
  }

  removeSponsorHighlight(sponsorId: number, highlightId: number, params: any = {}) {
    return this.apiService.delete(`sponsors/${sponsorId}/highlights/${highlightId}`, params);
  }

}
