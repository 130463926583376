import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadlinkService } from '../../../../../navus-core/services/leadlink.service';
import { LeadlinkCampaign } from '../../../../../navus-core/classes/leadlink-campaign';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../../../../navus-ui/modal/modal.service';
import { ToastService } from '../../../../../navus-ui/toast/toast.service';

@Component({
  selector: 'main [nv-campaign-details-page]',
  templateUrl: './campaign-details-page.component.html'
})
export class CampaignDetailsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  campaignId: number;
  campaign: LeadlinkCampaign;
  campaignForm: FormGroup;
  subscriptions: Subscription[] = [];

  savingCampaign: boolean = false;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private leadlinkService: LeadlinkService,
    private toastService: ToastService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.initForm();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = +data.organizationId;
        this.conferenceId = +data.conferenceId;
        this.campaignId = +data.campaignId;
        this.getCampaign();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.campaignForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      color: [''],
      settings: this.formBuilder.group({
        optIn: this.formBuilder.group({
          url: [''],
          text: [''],
          privacyPolicy: ['']
        }),
        disclaimer: this.formBuilder.group({
          url: [''],
          text: ['']
        }),
        showEmail: [true],
        use_scandit_scanner: [true]
      })
    });
  }

  getCampaign() {
    if (this.campaignId) {
      this.leadlinkService.getConferenceCampaign(this.conferenceId, this.campaignId)
        .subscribe(
          (response) => {
            this.campaign = response['data'];
            this.campaignForm.patchValue(this.campaign);
          },
          (error) => {
            this.modalService.error({ errors: error });
          }
        );
    } else {
      this.campaign = new LeadlinkCampaign();
    }
  }

  save() {
    if (this.campaignForm.invalid) { return; }

    this.savingCampaign = true;
    if (this.campaignId) {
      this.leadlinkService.updateConferenceCampaign(this.conferenceId, this.campaignId, this.campaignForm.value)
        .subscribe(
          (response) => {
            this.toastService.success('Campaign has been saved');
            this.campaign = response['data'];
            this.savingCampaign = false;
          },
          (error) => {
            this.savingCampaign = false;
            this.modalService.error({ errors: error });
          }
        );
    } else {
      this.leadlinkService.createConferenceCampaign(this.conferenceId, this.campaignForm.value)
        .subscribe(
          (response) => {
            this.toastService.success('Campaign has been created');
            this.campaign = response['data'];
            this.savingCampaign = false;
            this.router.navigate([this.campaign.id], { relativeTo: this.route });
          },
          (error) => {
            this.savingCampaign = false;
            this.modalService.error({ errors: error });
          }
        );
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Campaign Deletion',
      body: 'Are you sure you want to delete this campaign?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.leadlinkService.deleteConferenceCampaign(this.conferenceId, this.campaignId)
                .subscribe(
                    (response) => {
                      this.toastService.success('Campaign has been deleted');
                      this.router.navigate([
                        '/o', this.organizationId, 'conference', this.conferenceId, 'leadlink', this.campaignId, 'landing']);
                    },
                    (error) => {
                      this.toastService.error('Failed to delete the campaign');
                    }
                );
          }
        }
      ]
    });
  }

  close() {
    if (this.campaignId) {
      this.router.navigate(['../..', 'campaigns'], { relativeTo: this.route });
    } else {
      this.router.navigate(['..', 'campaigns'], { relativeTo: this.route });
    }
  }

}
