import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NavusInputComponent } from './input/input.component';
import { NavusCheckboxComponent } from './checkbox/checkbox.component';
import { NavusRadioComponent } from './radio/radio.component';
import { NavusSelectComponent } from './select/select.component';
import { NavusButtonComponent } from './button/button.component';
import { NavusModalComponent } from './modal/default-modal/modal.component';
import { NavusAlertComponent } from './modal/alert/alert.component';
import { NavusConfirmComponent } from './modal/confirm/confirm.component';
import { NavusToastComponent } from './toast/toast.component';
import { NavusTabsComponent } from './tabs/tabs.component';
import { NavusTabComponent } from './tabs/tab.component';
import { NavusErrorComponent } from './modal/error/error.component';
import { InputDirective } from './input/input.directive';
import { FormControlDirective, FormGroupDirective, FormsModule } from '@angular/forms';
import { NavusDatepickerComponent } from './datepicker/datepicker.component';
import { OWL_DATE_TIME_LOCALE, OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';
import { FilterPipe } from './pipes/filter.pipe';
import { NavusTextEditorComponent } from './text-editor/text-editor.component';
import { NavusTooltipComponent } from './tooltip/tooltip.component';
import { NavusProgressBarComponent } from './progress-bar/progress-bar.component';
import { NavusPaginationComponent } from './pagination/pagination.component';
import { NavusLoadingComponent } from './loading/loading.component';
import { LoadingService } from './loading/loading.service';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared';
import { NavusInfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { EditResourceComponent } from './resource-manager/edit-resource/edit-resource.component';
import { ResourceManagerComponent } from './resource-manager/resource-manager.component';
import { FileUploadModule } from 'ng2-file-upload';
import { NavusColorPickerComponent } from './color-picker/color-picker.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { NavusOffClickDirective } from './off-click/off-click.directive';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { NavusSwitchComponent } from './switch/switch.component';
import { NavusAccordionComponent } from './accordion/accordion.component';
import { NavusAccordionsComponent } from './accordion/accordions.component';
import { ChartComponent } from './chart/chart.component';
import { ChartsModule } from 'ng2-charts';
import { NavusFileComponent } from './file/file.component';
import { SlidesComponent } from './carousel/slides.component';
import { SlideComponent } from './carousel/slide.component';
import { NavusBackgroundImageDirective } from './background-image/background-image.directive';
import { NavusResourceModalComponent } from '@navus/ui/modal/resource-modal/resource-modal.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { AddFolderComponent } from './resource-manager/add-folder/add-folder.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { QRCodeModule } from 'angularx-qrcode';
import { QrcodeComponent } from './qrcode/qrcode.component';
import { NavusDatePipe } from '@navus/ui/pipes/date.pipe';
import { NavusMeetingComponent } from '@navus/ui/meeting/meeting.component';
import { QuestionsComponent } from '@navus/ui/questions/questions.component';
import { NavusVotingQuestionComponent } from './voting-question/voting-question.component';
import { NavusChatComponent } from '@navus/ui/chat/chat.component';
import { NavusPublicChatComponent } from '@navus/ui/public-chat/public-chat.component';
import { NavusSearchPipe } from '@navus/ui/pipes/search-user-names.pipe';
import { NavusHtmlPipe } from './pipes/html.pipe';
import { NavusCodeEditorComponent } from './code-editor/code-editor.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NavusWebexComponent } from './webex/webex.component';
import { NavusPublicChatContainerComponent } from '@navus/ui/public-chat-container/public-chat-container.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NavusVideoComponent } from '@navus/ui/video/video.component';
import { NavusBannerComponent } from '@navus/ui/banner/banner.component';
import { NavusMeetingRoomDetailsModalComponent } from './meeting/meeting-room-details/meeting-room-details.component';
import { TimeFormatPipe } from '@navus/ui/pipes/time-format.pipe';

@NgModule({
  imports: [
    CommonModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FormsModule,
    RouterModule,
    SharedModule,
    TooltipModule.forRoot(),
    FileUploadModule,
    ColorPickerModule,
    SwiperModule,
    ChartsModule,
    CodemirrorModule,
    CreditCardDirectivesModule,
    QRCodeModule,
    CKEditorModule,
    ClipboardModule
  ],
  declarations: [
    NavusInputComponent,
    NavusCheckboxComponent,
    NavusRadioComponent,
    NavusSelectComponent,
    NavusButtonComponent,
    NavusModalComponent,
    NavusAlertComponent,
    NavusConfirmComponent,
    NavusToastComponent,
    NavusTabsComponent,
    NavusTabComponent,
    NavusErrorComponent,
    InputDirective,
    NavusDatepickerComponent,
    FilterPipe,
    NavusDatePipe,
    NavusSearchPipe,
    NavusTextEditorComponent,
    NavusCodeEditorComponent,
    NavusTooltipComponent,
    NavusProgressBarComponent,
    NavusPaginationComponent,
    NavusLoadingComponent,
    NavusInfiniteScrollComponent,
    ResourceManagerComponent,
    EditResourceComponent,
    NavusResourceModalComponent,
    NavusColorPickerComponent,
    NavusOffClickDirective,
    NavusBackgroundImageDirective,
    NavusSwitchComponent,
    NavusAccordionComponent,
    NavusAccordionsComponent,
    ChartComponent,
    NavusFileComponent,
    SlidesComponent,
    SlideComponent,
    AddFolderComponent,
    QrcodeComponent,
    NavusMeetingComponent,
    QuestionsComponent,
    NavusVotingQuestionComponent,
    NavusChatComponent,
    NavusPublicChatComponent,
    NavusPublicChatContainerComponent,
    NavusHtmlPipe,
    NavusWebexComponent,
    NavusVideoComponent,
    NavusBannerComponent,
    NavusMeetingRoomDetailsModalComponent,
    TimeFormatPipe
  ],
  exports: [
    NavusInputComponent,
    NavusCheckboxComponent,
    NavusRadioComponent,
    NavusSelectComponent,
    NavusButtonComponent,
    NavusModalComponent,
    NavusAlertComponent,
    NavusConfirmComponent,
    NavusToastComponent,
    NavusTabsComponent,
    NavusTabComponent,
    NavusErrorComponent,
    InputDirective,
    NavusDatepickerComponent,
    NavusTextEditorComponent,
    NavusCodeEditorComponent,
    NavusTooltipComponent,
    NavusProgressBarComponent,
    NavusPaginationComponent,
    NavusLoadingComponent,
    NavusInfiniteScrollComponent,
    ResourceManagerComponent,
    EditResourceComponent,
    NavusResourceModalComponent,
    NavusColorPickerComponent,
    NavusOffClickDirective,
    NavusSwitchComponent,
    NavusAccordionComponent,
    NavusAccordionsComponent,
    ChartComponent,
    NavusFileComponent,
    SlidesComponent,
    SlideComponent,
    NavusBackgroundImageDirective,
    QrcodeComponent,
    NavusDatePipe,
    NavusMeetingComponent,
    QuestionsComponent,
    NavusVotingQuestionComponent,
    NavusChatComponent,
    NavusPublicChatComponent,
    NavusPublicChatContainerComponent,
    NavusHtmlPipe,
    NavusWebexComponent,
    NavusVideoComponent,
    NavusBannerComponent,
    TimeFormatPipe
  ],
  providers: [
    FormGroupDirective,
    FormControlDirective,
    // useValue: LOCALE_ID
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB' },
    LoadingService,
    DatePipe,
    TimeFormatPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  entryComponents: [
    EditResourceComponent,
    ResourceManagerComponent,
    NavusResourceModalComponent,
    NavusMeetingRoomDetailsModalComponent,
    AddFolderComponent
  ],
})
export class NavusUiModule {
}
