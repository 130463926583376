import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PresentationService } from '../../../../../navus-core/services/presentation.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'main [nv-voting-question-details-modal]',
  templateUrl: './voting-question-details-modal.component.html'
})
export class VotingQuestionDetailsModalComponent implements OnInit {
  @Input() conferenceId: number = null;
  @Input() presentationId: number = null;
  @Input() votingQuestionId: number = null;
  votingQuestionForm: FormGroup;
  savingVotingQuestion: boolean = false;
  questionStatuses = [
    { id: 'draft', name: 'Draft' },
    { id: 'published', name: 'Published' },
    { id: 'active', name: 'Active' },
    { id: 'finished', name: 'Finished' }
  ];
  questionTypes = [
    { id: 'radio', name: 'Radio' },
    { id: 'checkbox', name: 'Checkbox' }
  ];
  choices = [ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K' ];

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private presentationService: PresentationService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getVotingQuestion();
  }

  initForm() {
    this.votingQuestionForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      status: ['draft'],
      options: this.formBuilder.group({
        type: 'radio',
        choices: this.formBuilder.array([])
      })
    });
  }

  getVotingQuestion() {
    if (this.votingQuestionId) {
      this.presentationService.getConferencePresentationVoting(this.conferenceId, this.presentationId, this.votingQuestionId)
        .subscribe(
          (response) => {
            const votingQuestion = response.data;
            this.votingQuestionForm.patchValue(votingQuestion);
            const options = this.votingQuestionForm.controls.options as FormGroup;
            const choices = options.controls.choices as FormArray;

            if (votingQuestion.options && votingQuestion.options.choices) {
              for (const choice of votingQuestion.options.choices) {
                choices.push(this.formBuilder.group({
                  choice: [choice.choice],
                  correct: [choice.correct],
                  title: [choice.title]
                }));
              }
            }
          },
          () => { }
        );
    }
  }

  save() {
    if (this.votingQuestionForm.invalid) { return; }

    this.savingVotingQuestion = true;
    if (this.votingQuestionId) {
      this.presentationService
        .updateConferencePresentationVoting(this.conferenceId, this.presentationId, this.votingQuestionId, this.votingQuestionForm.value)
        .subscribe(
          () => {
            this.activeModal.close();
            this.savingVotingQuestion = false;
          },
          () => {
            this.savingVotingQuestion = false;
          }
        );
    } else {
      this.presentationService
        .createConferencePresentationVoting(this.conferenceId, this.presentationId, this.votingQuestionForm.value)
        .subscribe(
          () => {
            this.activeModal.close();
            this.savingVotingQuestion = false;
          },
          () => {
            this.savingVotingQuestion = false;
          }
        );
    }

  }

  close() {
    this.activeModal.dismiss();
  }

  addChoice() {
    const options = this.votingQuestionForm.controls.options as FormGroup;
    const choices = options.controls.choices as FormArray;
    const choice = this.formBuilder.group({
      choice: [this.choices[choices.length]],
      correct: [false],
      title: ['']
    });

    choices.push(choice);
  }

  removeChoice(index: number) {
    const options = this.votingQuestionForm.controls.options as FormGroup;
    const choices = options.controls.choices as FormArray;
    choices.removeAt(index);
  }

  trackBy(index: number) {
    return index;
  }

}
