<div class="nv-ls-config-toolbox">
  <header class="nv-ls-config-toolbox-header">
    <h3>Slides</h3>
  </header><!-- /.nv-ls-config-toolbox-header -->
  <div class="nv-ls-config-toolbox-body">
    <div class="nv-ls-config-toolbox-body-port">
      <div class="nv-ls-slides">
        <p class="nv-ls-config-tip">
          Please pick a presentation to manage your slides.
        </p>
        <table class="nv-sam-table">
          <tr *ngFor="let presentation of presentations">
            <td data-label="Presentation">
              <p [title]="presentation.title">
                {{ presentation.title }}
              </p>
            </td>
            <td data-label="Session">
              <p [title]="presentation.block_name">
                <a>{{ presentation.block_name }}</a>
              </p>
            </td>
            <td data-label="Time">
              <p class="date-n-time">
                <strong>{{ presentation.starts_at | nvDate:'dd.MM.yyyy' }}</strong>
                <span>{{ presentation.starts_at | nvDate:'HH:mm' }} - {{ presentation.ends_at | nvDate:'HH:mm' }}</span>
              </p>
            </td>
            <td data-label="Materials">
              <div class="material-available">
                <a 
                  *ngFor="let material of presentation.materials" 
                  [class.presentation]="material.type === 'main'" 
                  [class.abstract]="material.type === 'abstract'" 
                  [class.webcast]="material.type === 'webcast'" 
                  [title]="material.title"
                ></a>
              </div>
            </td>
          </tr>
        </table><!-- /.nv-sam-table -->
      </div><!-- /.nv-ls-slides -->
    </div><!-- /.nv-ls-config-toolbox-body-port -->
  </div><!-- /.nv-ls-config-toolbox-body -->
</div><!-- /.nv-ls-config-toolbox -->