import { Component } from '@angular/core';

@Component({
    selector: 'app-presentation-detials-page',
    templateUrl: './presentation-details.component.html'
})
export class PresentationDetailsComponent {

    constructor() { }

}
