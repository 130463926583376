<section class="nv-section guttered" id="active-source" *ngIf="activeSource && activeSource.presentation">
    <div class="nv-port">
<!--        <div class="nv-row gap-30 v-stretch spaced-top">-->
<!--            <div class="nv-column desk-12">-->
<!--                <div class="nv-column-content">-->
<!--                    <h2 translate>Active Session</h2>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <main class="nv-row gap-30 v-stretch semi-spaced-top semi-spaced-bottom">
            <div class="nv-column desk-6 tab-12">
                <div class="nv-column-content">
                    <div *ngIf="!activeSource" style="min-height: 270px;"></div>
                    <div class="nv-video-port" [ngClass]="{ 'no-access': !canAccess(activeSource.presentation) }" *ngIf="activeSource">
                        <img src="../../assets/aspect-16-9.png" alt="" class="nv-aspect" aria-hidden="true">
                        <vg-player (onPlayerReady)="onPlayerReady($event)">
                            <vg-overlay-play vgFor="singleVideo"></vg-overlay-play>
                            <vg-controls>
                                <vg-play-pause (click)="onVideoClick()"></vg-play-pause>
                                <vg-mute></vg-mute>
                                <vg-volume></vg-volume>
                                <div style="flex: 1"></div>
                                <vg-fullscreen></vg-fullscreen>
                            </vg-controls>
                            <video #media [vgMedia]="media"
                                   [vgDash]="activeSource.stream"
                                   [vgHls]="activeSource.stream"
                                   [src]="activeSource.stream"
                                   (click)="onVideoClick()"
                                   id="singleVideo"
                                   crossorigin>
                            </video>
                        </vg-player>
                        <div class="get-access">
                            <button class="nv-btn filled quaternary" (click)="onVideoClick()" translate>Watch Now</button>
                        </div>
                    </div>
                    <div class="nv-divider tab-small"></div><!-- /.nv-divider -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column desk-12 -->
            <div class="nv-column desk-6 v-middle tab-12" *ngIf="activeSource && activeSource.presentation">
                <div class="nv-live-stream-active-label">
                    <h4>[Session] {{activeSource.presentation.block?.name}}</h4>
                    <h1>{{activeSource.presentation.title}}</h1>
                    <div class="lsal-author">
                        <div class="la-img" [style.backgroundImage]="'url('+ (activeSource.presentation.speaker_image_thumb ? activeSource.presentation.speaker_image_thumb : '') +')'"></div>
                        <p>{{activeSource.presentation.speaker_name}}</p>
                    </div>
                    <div class="live-stream-meta">
                        <p class="lsm-venue">
                            <span class="lsm-color-dot"></span> <span translate>Live in</span> {{activeSource.location.name}}
                            <em><span translate>Started at</span> {{activeSource.presentation.starts_at | dateFormat:'HH:mm A'}}, <span translate>Ends at</span> {{activeSource.presentation.ends_at | dateFormat:'HH:mm A'}}</em>
                        </p>
                        <!--p class="lsm-category">
                            <span class="lsm-color-dot" style="background-color: #02bfd9;"></span> Category Name
                        </p-->
                        <!--p class="lsm-views">318 viewers</p-->
                    </div>
                </div>
            </div>
        </main><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</section>
<section class="nv-section guttered" *ngIf="liveStreamingSuites">
    <div class="nv-port">
        <div class="nv-row gap-30 v-stretch spaced-top small-spaced-bottom">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <h4 class="nv-slide-adjusted" *ngIf="activeSource" [hidden]="liveStreamingSuites.length <= 1" translate>Now Streaming - Select to activate</h4>
                    <h2 class="nv-slide-adjusted" *ngIf="!activeSource" translate>Streaming presentations</h2>
                </div>
            </div>
        </div>
        <div class="nv-row gap-30 spaced-bottom">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <ngx-slick-carousel class="nv-current-streams carousel" #slickModal="slick-carousel" [config]="slideLiveSuiteConfig">

                        <div class="cs-item slide" ngxSlickItem
                             *ngFor="let source of liveStreamingSuites"
                             [style.display]="source.id !== activeSource?.id ? 'block' : 'none'">
                            <ng-container *ngIf="source.id !== activeSource?.id">
                                <div class="cs-item-frame">
                                    <div class="cs-video-port" *ngIf="source.presentation"
                                         [ngClass]="{ 'no-access': !canAccess(source.presentation) }"
                                         (click)="onClickStream(source)">
                                        <img alt="" aria-hidden="true" class="aspect" src="../../../../assets/aspect-16-9.png">
                                        <vg-player *ngIf="source.stream">
                                            <video
                                                    [vgHls]="source.stream"
                                                    crossorigin>
                                            </video>
                                        </vg-player>
                                        <a title="{{source.presentation.title}}"></a>
                                        <div class="cs-play"></div>
                                        <div class="cs-label">
                                            <div class="sc-label-elements">
                                                <p><span class="cs-dot"></span> <span translate>Live in</span> {{source.location.name}}</p>
                                                <em><span translate>Started at</span> {{source.presentation.starts_at | dateFormat:'HH:mm A'}}</em>
                                            </div>
                                        </div>
                                        <div class="get-access">
                                            <button class="nv-btn filled quaternary" (click)="onClickStream(source)" translate>Get Access</button>
                                        </div>
                                    </div>
                                    <div class="cs-video-port no-presentation-poster" *ngIf="!source.presentation">
                                        <img alt="" class="aspect" src="../../../../assets/aspect-16-9.png">
                                    </div>
                                    <header class="cs-meta" *ngIf="source.presentation">
                                        <h4>[Session] {{source.presentation.block?.name}}</h4>
                                        <h2><a>{{source.presentation.title}}</a></h2>
                                        <p>{{source.presentation.speaker_name}}</p>
                                    </header>
                                    <header class="cs-meta" *ngIf="!source.presentation">
                                        <h2 class="text-center" translate><span translate>There is no currently active</span> <br> <span translate>presentation in</span> {{ source.location.name }}</h2>
                                    </header>
                                    <div class="cs-upcoming" *ngIf="source.next_presentation">
                                        <p>
                                            <strong translate>UPCOMING</strong> -
                                            {{ source.next_presentation.starts_at | nvDate:'dd.MM.yyyy HH:mm' }}
                                        </p>
                                        <div class="csu-label">
                                            <div class="csu-img"
                                                 [style.backgroundImage]="'url('+ (source.next_presentation.speaker_image_thumb ? source.next_presentation.speaker_image_thumb : '') +')'"></div>
                                            <div class="cs-meta"><h4>[Session] {{source.next_presentation.block?.name}}</h4>
                                                <h2>{{source.next_presentation.title}}</h2>
                                                <p>{{source.next_presentation.speaker_name}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cs-upcoming" *ngIf="!source.next_presentation">
                                        <p translate>NO UPCOMING LIVE STREAMS SCHEDULED</p>
                                        <p translate>Please check back later</p>
                                    </div>
                                </div><!-- /.cs-itnv-frame -->
                            </ng-container>
                        </div><!-- /cs-item -->
                    </ngx-slick-carousel>
                </div><!-- /nv-column-content -->
            </div><!-- /nv-column -->
        </div><!-- /nv-row  -->
    </div><!-- /nv-port -->
</section>
