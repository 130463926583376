import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ConfigComponent } from './config.component';
import { CampaignComponent } from './campaign.component';
import { SearchComponent } from './search.component';
import { PlannerComponent } from './planner.component';
import { LiveStreamingComponent } from './live.streaming.component';
import { EplaylistSlideshowComponent } from './eplaylist.slideshow.component';
import { ElementsComponent } from './elements.component';
import { SharedModule } from '../shared';
import { TypographyComponent } from './typography.component';
import { InfoNotesComponent } from './infos.n.notes.component';
import { NavusUiComponent } from './navus-ui.component';
import { ConfigLayoutComponent } from './config-layout.component';
import { ConfigRevElementsComponent } from './config-rev-elements.component';
import { Error404Component } from './error404.component';
import { ConfigBrandingComponent } from './config-branding-component';
import { OnboardingComponent } from './onboarding.component';
import { NavusUiDocumentationComponent } from './navus-ui-documentation.component';
import { NavusUiModule } from '../navus-ui/navus-ui.module';
import { BillingComponent } from './billing.component';
import {DataRoomComponent} from './data-room.component';
import {SetupWizardComponent} from './setup-wizard.component';
import {TemplateFrontComponent} from './template-front.component';
import {ConfigInitialStatesComponent} from './config-initial-states.component';
import {AppWizardComponent} from './app-wizard.component';
import {OnboardingUpdateComponent} from './onboarding-update.component';
import {PlaygroundComponent} from './playground.component';
import {ConfigSidebarComponent} from './config-sidebar.component';
import {AppWizardSetupComponent} from './app-wizard-setup.compononent';
import {MaterialBarComponent} from './material-bar.component';
import {EmaterialsComponent} from './ematerials.component';
import {TemplateComponent} from './template.component';
import {AppWizardSetupRevisionComponent} from './app-wizard-setup-revision.component';
import {NewsSingleComponent} from './news-single.component';
import {ComingSoonConfigComponent} from './coming-soon-config.component';
import {PresentationDetailsComponent} from './presentation-details.component';
import {RegistrationMessagesComponent} from './registration-messages.component';
import {CustomerSsListComponent} from './customer-ss-list.component';
import {CustomerSsDetailComponent} from './customer-ss-detail.component';
import {ChatComponent} from './chat.component';
import {ProgramLightComponent} from './program-light.component';
import {LiveSuite21Component} from './live-suite21.component';
import {GallerySimpleComponent} from './gallery-simple.component';
import {LiveStageComponent} from './live-stage.component';
import {BreakRoomsComponent} from './break-rooms.component';
import {LiveStageConfigComponent} from './live-stage-config.component';
import {ProfilePagesComponent} from './profile-pages.component';
import {JitsiSplashComponent} from  './jitsi-splash.component';
import {LiveStageSpeakerConfigComponent} from './live-stage-speaker-config.component';
import { TrackingTestComponent } from './tracking-test.component';
import {OnDemandComponent} from './on-demand.component';
import {LiveStudioComponent} from './live-studio.component';
import {GamificationInstructionsComponent} from './gamification-instructions.component';



const staticModuleRouting: ModuleWithProviders<any> = RouterModule.forChild([
    {
        path: 'config',
        component: ConfigComponent
    },
    {
        path: 'campaign',
        component: CampaignComponent
    },
    {
        path: 'search-static',
        component: SearchComponent
    },
    {
        path: 'planner',
        component: PlannerComponent
    },
    {
        path: 'elements',
        component: ElementsComponent
    },
    {
        path: 'live-streaming',
        component: LiveStreamingComponent
    },
    {
        path: 'eplaylist-slideshow',
        component: EplaylistSlideshowComponent
    },
    {
        path: 'typography',
        component: TypographyComponent
    },
    {
        path: 'infos-n-notes',
        component: InfoNotesComponent
    },
    {
        path: 'navus-ui-controller-test',
        component: NavusUiComponent
    },
    {
        path: 'ui-documentation',
        component: NavusUiDocumentationComponent
    },
    {
        path: 'config-rev-elements',
        component: ConfigRevElementsComponent
    },
    {
        path: 'onboarding',
        component: OnboardingComponent
    },
    {
        path: 'error404',
        component: Error404Component
    },
    {
        path: 'config-branding',
        component: ConfigBrandingComponent
    },
    {
        path: 'config-layout',
        component: ConfigLayoutComponent
    },
    {
        path: 'billing',
        component: BillingComponent
    },
    {
        path: 'data-room',
        component: DataRoomComponent
    },
    {
        path: 'setup-wizard',
        component: SetupWizardComponent
    },
    {
        path: 'template-front',
        component: TemplateFrontComponent
    },
    {
        path: 'config-initial-states',
        component: ConfigInitialStatesComponent
    },
    {
        path: 'app-wizard',
        component: AppWizardComponent
    },
    {
        path: 'onboarding-update',
        component: OnboardingUpdateComponent
    },
    {
        path: 'playground',
        component: PlaygroundComponent
    },
    {
        path: 'chat',
        component: ChatComponent
    },
    {
        path: 'sidebar',
        component: ConfigSidebarComponent
    },
    {
        path: 'app-wizard-setup',
        component: AppWizardSetupComponent
    },
    {
        path: 'material-bar',
        component: MaterialBarComponent
    },
    {
        path: 'ematerials',
        component: EmaterialsComponent
    },
    {
        path: 'template',
        component: TemplateComponent
    },
    {
        path: 'app-wizard-revision',
        component: AppWizardSetupRevisionComponent
    },
    {
        path: 'news-single',
        component: NewsSingleComponent
    },
    {
        path: 'coming-soon-config',
        component: ComingSoonConfigComponent
    },
    {
        path: 'presentation-details',
        component: PresentationDetailsComponent
    },
    {
        path: 'registration-messages',
        component: RegistrationMessagesComponent
    },
    {
        path: 'customer-ss-list',
        component: CustomerSsListComponent
    },
    {
        path: 'customer-ss-detail',
        component: CustomerSsDetailComponent
    },
    {
        path: 'program-light',
        component: ProgramLightComponent
    },
    {
        path: 'live-suite21',
        component: LiveSuite21Component
    },
    {
        path: 'gallery-simple',
        component: GallerySimpleComponent
    },
    {
        path: 'live-stage',
        component: LiveStageComponent
    },
    {
        path: 'break-rooms',
        component: BreakRoomsComponent
    },
    {
        path: 'live-stage-config',
        component: LiveStageConfigComponent
    },
    {
        path: 'profile-pages',
        component: ProfilePagesComponent
    },
    {
        path: 'jitsi-splash',
        component: JitsiSplashComponent
    },
    {
        path: 'live-stage-speaker-config',
        component: LiveStageSpeakerConfigComponent
    },
    {
        path: 'time-tracking',
        component: TrackingTestComponent
    },
    {
        path: 'on-demand',
        component: OnDemandComponent
    },
    {
        path: 'live-studio',
        component: LiveStudioComponent
    },
    {
        path: 'gamification-instructions',
        component: GamificationInstructionsComponent
    }
]);

@NgModule({
    imports: [
        staticModuleRouting,
        SharedModule,
        NavusUiModule
    ],
    declarations: [
        ConfigComponent,
        CampaignComponent,
        SearchComponent,
        PlannerComponent,
        ElementsComponent,
        LiveStreamingComponent,
        EplaylistSlideshowComponent,
        TypographyComponent,
        InfoNotesComponent,
        ConfigBrandingComponent,
        ConfigRevElementsComponent,
        Error404Component,
        ConfigLayoutComponent,
        OnboardingComponent,
        NavusUiComponent,
        NavusUiDocumentationComponent,
        BillingComponent,
        DataRoomComponent,
        SetupWizardComponent,
        TemplateFrontComponent,
        ConfigInitialStatesComponent,
        AppWizardComponent,
        OnboardingUpdateComponent,
        PlaygroundComponent,
        ChatComponent,
        ConfigSidebarComponent,
        AppWizardSetupComponent,
        MaterialBarComponent,
        EmaterialsComponent,
        TemplateComponent,
        AppWizardSetupRevisionComponent,
        NewsSingleComponent,
        ComingSoonConfigComponent,
        PresentationDetailsComponent,
        RegistrationMessagesComponent,
        CustomerSsListComponent,
        CustomerSsDetailComponent,
        ProgramLightComponent,
        LiveSuite21Component,
        GallerySimpleComponent,
        LiveStageComponent,
        BreakRoomsComponent,
        LiveStageConfigComponent,
        ProfilePagesComponent,
        JitsiSplashComponent,
        LiveStageSpeakerConfigComponent,
        TrackingTestComponent,
        OnDemandComponent,
        LiveStudioComponent,
        GamificationInstructionsComponent
    ],
    exports: [],
    providers: []
})
export class StaticModule { }
