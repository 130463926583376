<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>AI</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-8">

            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav vertical-break-phab">
                <ul>
                    <li>
                        <a class="active">Recommendations</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'ai', 'sponsorship-campaigns']">Sponsorship Campaigns</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
<div class="nv-config-content-port">
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form class="nv-row config-plates clean gap-24 propagate" [formGroup]="recommendationsForm">
                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <h1>AI Recommendations</h1>
                                    <p>
                                        On the App and on the event websites attendees and platform users receive AI generated recommendations
                                        about content that is relevant for them. You can activate the recommendation module in the app and website
                                        modules. Here you can select the algorithms that you want to be used to calculate relevant recommendations.
                                        Users can provide feedback to the system about relevance hence results for future recommendations can be
                                        improved. Recommendations work within one event and if you choose to include more than one of your events
                                        in a Content Hub Recommendations will span across the content of all your events in the Hub.
                                        Set up a Hub <a [routerLink]="['/o', organizationId, 'contact', 'web-hub']">here</a>
                                    </p>
                                </div>
                            </div>
                            <div class="nv-column desk-12" formGroupName="options">
                                <div class="nv-column-content">
                                    <nv-checkbox
                                            label="Viewed By Others"
                                            formControlName="viewed_by_others"
                                    ></nv-checkbox>
                                    <div>
                                        <small>Recommend by finding the most viewed presentations by others</small>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-12" formGroupName="options">
                                <div class="nv-column-content">
                                    <nv-checkbox
                                            label="Viewed Similar Content"
                                            formControlName="viewed_similar_content"
                                    ></nv-checkbox>
                                    <div>
                                        <small>Recommend presentations with similar content to the ones seen by the active user</small>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-12" formGroupName="options">
                                <div class="nv-column-content">
                                    <nv-checkbox
                                            label="Browsed By Others"
                                            formControlName="browsed_by_others"
                                    ></nv-checkbox>
                                    <div>
                                        <small>Recommend presentations on which users spent the most time browsing it's materials</small>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-12" formGroupName="options">
                                <div class="nv-column-content">
                                    <nv-checkbox
                                            label="Part Of The Same Session"
                                            formControlName="part_of_same_session"
                                    ></nv-checkbox>
                                    <div>
                                        <small>If session exists, recommend more presentations from the same session like ones that user already visited</small>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-12" formGroupName="options">
                                <div class="nv-column-content">
                                    <nv-checkbox
                                            label="Viewed Similar"
                                            formControlName="viewed_similar"
                                    ></nv-checkbox>
                                    <div>
                                        <small>Recommend presentations with the similar title</small>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-12" formGroupName="options">
                                <div class="nv-column-content">
                                    <nv-checkbox
                                            label="Searched By User"
                                            formControlName="searched_by_user"
                                    ></nv-checkbox>
                                    <div>
                                        <small>Recommend what was seen by other users with similar search preferences</small>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-12" formGroupName="options">
                                <div class="nv-column-content">
                                    <nv-checkbox
                                            label="Viewed By Similar Users"
                                            formControlName="viewed_by_similar_users"
                                    ></nv-checkbox>
                                    <div>
                                        <small>Clustering the active user and finding look alike users, then recommend their favorite presentations</small>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-12" formGroupName="options">
                                <div class="nv-column-content">
                                    <nv-checkbox
                                            label="Presented By The Same Author"
                                            formControlName="presented_by_the_same_author"
                                    ></nv-checkbox>
                                    <div>
                                        <small>Recommend other presentations from the same author</small>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <nv-button (click)="submitRecommendationsForm()">Save</nv-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div><!-- /.config-layout-lower-fixed -->
