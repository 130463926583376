<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Materials</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-8">
                <div class="nv-multiop-btn" [class.on]="addMaterialOpen">
                    <button type="button"
                            class="nv-btn medium accent1"
                            data-icon-pl="&#xe095;"
                            (click)="addMaterialOpen = !addMaterialOpen">
                        Add material
                    </button>
                    <ul>
                        <li>
                            <a (click)="fileSelect.click()">
                                Upload file
                            </a>
                            <input #fileSelect type="file" ng2FileSelect [uploader]="uploader"
                                   [hidden]="true" multiple
                                   accept="application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,video/*">
                        </li>

                        <li><a (click)="addExternalMaterial()">Add external material</a></li>
                    </ul>
                </div><!-- /.nv-multiop-btn -->
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port spaced-bottom">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav vertical-break-tab">
                <ul>
                    <li>
                        <a class="active">Materials</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'ematerials', 'consent-settings']">Consent Settings</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'ematerials', 'video-player-presets']">Video Player Presets</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <div class="nv-port">
        <div class="nv-row">
            <form class="config-search-bar-w-filters" [formGroup]="formFilter" autocomplete="off">
                <div class="sbwf-port">
                    <input type="text" placeholder="Search..."
                           formControlName="search_term" (change)="getMaterials()">
                    <span class="sbwf-outline"></span>
                </div><!-- /.sbwf-port -->
                <div class="sbwf-filters">
                    <div class="sbwf-trigger" (click)="showFilter = !showFilter">Filters</div>
                </div><!-- /.sbwf-filters -->
            </form><!-- /.config-search-bar-w-filters -->
            <div class="nv-column desk-3" style="margin-left: auto">
                <div class="nv-column-content">
                    <nv-select [options]="sortOptions" label="Sort by" [(ngModel)]="sort" (ngModelChange)="getMaterials()"></nv-select>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-config-filters-row">
        <span class="cfr-item" *ngFor="let filterPill of filterPills">
            {{ filterPill.name }}
            <a (click)="filterRemove(filterPill)"></a>
        </span>
    </div>
</div>
<div class="nv-config-content-port" nvInfiniteScroll (scrolled)="getMaterials(true)">
    <div class="nv-global-loading on" [class.on]="materialsLoading">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <section class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates">
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content" [class.nv-file-over]="isFileOver"
                         ng2FileDrop [uploader]="uploader" (fileOver)="onFileOver($event)">
                        <div *ngIf="pagination.total === 0">
                            <div class="nv-empty-state">
                                <div class="img">
                                    <img src="../../../../assets/empty-states/empty-state-events.svg" alt="">
                                </div>
                                <h4 class="title">No materials!</h4>
                                <p class="text">Once you add material, you'll see them here.</p>
                                <nv-button  (click)="fileSelect.click()">Add material</nv-button>
                            </div>
                        </div>
                        <table class="nv-data-table break-tab">
                            <ng-container *ngFor="let material of uploadingMaterials">
                                <tr>
                                    <td class="dt-cell nv-clickable">
                                        <div class="dt-name-w-file">
                                            <a class="dt-file" [ngSwitch]="material.extension">
                                                <img src="../../assets/ico-material-audio.svg" alt="MP3" *ngSwitchCase="'mp3'">
                                                <img src="../../assets/ico-material-mp-4.svg" alt="Video" *ngSwitchCase="'video'">
                                                <img src="../../assets/ico-material-ppt.svg" alt="PPT" *ngSwitchCase="'ppt'">
                                                <img src="../../assets/ico-material-ppt.svg" alt="PPTX" *ngSwitchCase="'pptx'">
                                                <img src="../../assets/ico-material-pdf.svg" alt="PDF" *ngSwitchCase="'pdf'">
                                                <img src="../../assets/ico-material-file.svg" alt="file" *ngSwitchDefault>
                                            </a>
                                            <div class="dt-name">
                                                <label>MATERIAL NAME</label>
                                                <strong>{{ material.title }}</strong>
                                            </div>
                                        </div><!-- /.dt-name-w-file -->
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-cell nv-clickable">
                                        <label>SIZE</label>
                                        <strong *ngIf="material.file_size">{{ material.file_size / 1024 / 1024 | number:'1.2-2' }}MB</strong>
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-cell nv-clickable">
                                        <label>ATTACHED TO PRESENTATION</label>
                                        <strong>None</strong>
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-cell nv-clickable">
                                        <label>PUBLISHED</label>
                                        <strong>No</strong>
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-cell nv-clickable">
                                        <label>STATUS</label>
                                        <strong class="status unprocessed">uploading</strong><!-- /processed -->
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-actions">
                                        <div class="nv-cell-actions">
                                            <span class="nv-cell-action-trigger"></span>
                                        </div><!-- /.nv-cell-actions -->
                                    </td>
                                </tr>
                                <tr class="dt-progress">
                                    <td colspan="6">
                                        <nv-progress-bar
                                            [active]="true"
                                            [progress]="material.percent_processed"
                                            [text]="'uploading...'"
                                        ></nv-progress-bar>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngFor="let material of materials">
                                <tr>
                                    <td class="dt-cell nv-clickable" (click)="edit(material)">
                                        <div class="dt-name-w-file">
                                            <a class="dt-file" [ngSwitch]="material.extension">
                                                <img src="../../assets/ico-material-audio.svg" alt="MP3" *ngSwitchCase="'mp3'">
                                                <img src="../../assets/ico-material-mp-4.svg" alt="MP4" *ngSwitchCase="'mp4'">
                                                <img src="../../assets/ico-material-ppt.svg" alt="PPT" *ngSwitchCase="'ppt'">
                                                <img src="../../assets/ico-material-ppt.svg" alt="PPTX" *ngSwitchCase="'pptx'">
                                                <img src="../../assets/ico-material-pdf.svg" alt="PDF" *ngSwitchCase="'pdf'">
                                                <img src="../../assets/ico-material-file.svg" alt="file" *ngSwitchDefault>
                                            </a>
                                            <div class="dt-name">
                                                <label>MATERIAL NAME</label>
                                                <strong>{{ material.title }}</strong>
                                            </div>
                                        </div><!-- /.dt-name-w-file -->
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-cell nv-clickable" (click)="edit(material)">
                                        <label>SIZE</label>
                                        <strong *ngIf="material.file_size">{{ material.file_size / 1024 / 1024 | number:'1.2-2' }}MB</strong>
                                        <strong *ngIf="!material.file_size">-</strong>
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-cell nv-clickable">
                                        <label>ATTACHED TO PRESENTATION</label>
                                        <strong *ngIf="material.attached_presentation">
                                            <a
                                                [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program', 'presentation', material.attached_presentation_id]"
                                                class="nv-external-link"
                                            >{{ material.attached_presentation.title }}</a>
                                        </strong>
                                        <strong *ngIf="!material.attached_presentation">
                                            <a (click)="edit(material, true)">
                                                <span>Attach a presentation</span>
                                            </a>
                                        </strong>
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-cell nv-clickable" (click)="edit(material)">
                                        <label>PUBLISHED</label>
                                        <strong *ngIf="material.published_at">{{ material.published_at | nvDate:'dd.MM.yyyy' }}</strong>
                                        <strong *ngIf="!material.published_at">No</strong>
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-cell nv-clickable">
                                        <label>STATUS</label>
                                        <ng-container *ngIf="!(material.errors?.length > 0)">
                                            <strong class="status {{ material.status === 'processing' ? 'unprocessed' : material.status }}">{{ material.status }}</strong><!-- /processed -->
                                        </ng-container>
                                        <ng-container *ngIf="material.errors?.length > 0">
                                            <div (click)="showProcessingErrors(material.errors)">
                                                <strong class="status {{ material.status === 'processing' ? 'unprocessed' : material.status }}">{{ material.status }}</strong><!-- /processed -->
                                                <span class="nv-info-spot inline"></span>                                                
                                            </div>
                                        </ng-container>
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-actions">
                                        <div class="nv-cell-actions">
                                        <span class="nv-cell-action-trigger"
                                            [class.on]="optionsShown === material.id"
                                            (click)="openOptions(material)"></span>
                                            <nav nvOffClick (offClick)="closeOptions()">
                                                <ul>
                                                    <li *ngIf="material.status === 'processed'" (click)="edit(material)">
                                                        <a>Edit</a>
                                                    </li>
                                                    <li class="delete" (click)="delete(material)">
                                                        <a>Delete</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div><!-- /.nv-cell-actions -->
                                    </td>
                                </tr>
                                <tr class="dt-progress" *ngIf="material.status === 'processing' || material.status === 'unprocessed'">
                                    <td colspan="6">
                                        <ng-container *ngIf="!material.errors">
                                            <nv-progress-bar
                                                [active]="true"
                                                [progress]="material.percent_processed"
                                                [text]="(material.status === 'unprocessed' ? 'waiting for processing' : material.status) + '...'"
                                            ></nv-progress-bar>                                            
                                        </ng-container>
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->
</div><!-- /.config-layout-lower-fixed -->

<div class="nv-config-sidebar" [class.on]="showSidebar"><!-- toggle class 'on' -->
    <div class="nv-learning-pane">
        <header>
            <div class="lp-close">
                <a (click)="closeSidebar()" title="Close bar"></a>
            </div>
            <h2>Help &amp; Learning</h2>
        </header>
        <div class="lp-body">
            <div class="lp-body-content">
                <iframe style="width: 100%; height: 200px"
                        src="https://www.youtube.com/embed/A4eKBuU8Yg8"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                ></iframe>
            </div><!-- /.lp-body-content -->
        </div><!-- /.lp-body -->
    </div><!-- /.nv-learning-pane -->
</div><!-- /.nv-config-sidebar -->

<!-- Filters open -->
<form class="nv-side-filters-panel"
      [formGroup]="formFilter"
      [class.on]="showFilter"
>
    <header class="sfp-header">
        <span class="sfp-close" (click)="showFilter = false"></span>
        <h3>Add Filters</h3>
        <p>Changes you make apply to data table.</p>
    </header><!-- /.sfp-header -->
    <div class="sfp-body">

        <div class="fb-filter-chunk">
            <div class="fb-filter-box">
                <nv-select
                        label="Material type"
                        formControlName="material_type"
                        (change)="materialTypeChanged($event)"
                        [options]="materialTypes">
                    ></nv-select>
            </div><!-- /.fb-filter-box -->
        </div><!-- /.fb-filter-chunk -->

        <div class="fb-filter-chunk">
            <div class="fb-filter-box">
                <nv-select
                        label="Presentation type"
                        formControlName="presentation_type"
                        (change)="presentationTypeChanged($event)"
                        [options]="presentationTypes">
                    ></nv-select>
            </div><!-- /.fb-filter-box -->
        </div><!-- /.fb-filter-chunk -->

        <div class="fb-filter-chunk">
            <div class="fb-filter-box">
                <nv-select
                        label="Published"
                        formControlName="published"
                        (change)="publishedChanged($event)"
                        [options]="trueFalseOptions">
                    ></nv-select>
            </div><!-- /.fb-filter-box -->
        </div><!-- /.fb-filter-chunk -->

        <div class="fb-filter-chunk">
            <div class="fb-filter-box">
                <nv-select
                        label="Status"
                        formControlName="status"
                        (change)="statusChanged($event)"
                        [options]="statuses">
                    ></nv-select>
            </div><!-- /.fb-filter-box -->
        </div><!-- /.fb-filter-chunk -->

        <div class="fb-filter-chunk">
            <div class="fb-filter-box">
                <nv-select
                        label="Has attached presentation"
                        formControlName="attached_presentation_id"
                        (change)="hasAttachedPresentationChanged($event)"
                        [options]="trueFalseOptions">
                    ></nv-select>
            </div><!-- /.fb-filter-box -->
        </div><!-- /.fb-filter-chunk -->

    </div><!-- /.sfp-body -->
    <footer class="sfp-footer">
        <label>
            <a (click)="formFilter.reset()">Clear Filters</a>
        </label>
        <div class="sfp-btns">
            <!-- <a href="" class="nv-btn small filled neutral">Cancel</a>
            <a href="" class="nv-btn small filled accent1">Apply</a> -->
        </div>
    </footer><!-- /.sfp-footer -->
</form><!-- /.nv-side-filters-panel -->
