import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DelegateService } from '@navus/core/services/delegate.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { Color } from '@swimlane/ngx-charts';
import { AttendanceSession } from '@navus/core/classes/attendance-session';
import { TimeFormatPipe } from '@navus/ui/pipes/time-format.pipe';
import { Program } from '@navus/core/classes/program';
import { ConferenceService } from '@navus/core/services/conference.service';

@Component({
  selector: 'nv-live-attendance',
  templateUrl: './live-attendance.component.html',
})
export class LiveAttendanceComponent implements OnInit, OnDestroy {
  @Input() templateForTooltip: TemplateRef<void>;
  organizationId: number;
  conferenceId: number;
  delegateId: number;

  chartColorScheme = {
    domain: ['#318AF1', '#2873A1', '#8ABAD6', '#32B799', '#279079', '#18707F', '#ED4649', '#F49092', '#AD465A', '#ED8747']
  } as Color;

  loadingAttendance = false;
  attendanceData: AttendanceSession[];
  sessionData: { name: string, value: number }[];
  sessionLegend: { id: number, name: string, value: string, color: string }[];
  sessionActiveEntries: any[] = [];
  selectedSession: AttendanceSession;

  presentationData: { name: string, value: number }[];
  presentationLegend: { name: string, value: string, color: string }[];
  presentationActiveEntries: any[] = [];
  selectedPresentationInLegend: number;

  availableSessions: { id: number, name: string }[];
  availablePresentations: { id: number, name: string, blockId: number, time: number, duration: number }[];
  private allPresentations: { id: number, name: string, blockId: number, time: number, duration: number }[];
  private subscriptions: Subscription[] = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private delegateService: DelegateService,
              private conferenceService: ConferenceService,
              private timeFormatPipe: TimeFormatPipe,
              private modalService: ModalService) {
  }

  ngOnInit() {
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.delegateId = data.delegateId;

        this.getAttendance();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  onSelect(event) {
    const chosenSession = typeof event === 'string' ? event : event.name;
    if (chosenSession) {
      this.selectedSession = this.attendanceData
        .find(item => item.name === chosenSession);
      this.presentationData = this.selectedSession?.presentations?.map(item => ({ id: item.id, name: item.title, value: item.time_spent }));

      // Initialize legend
      this.presentationLegend = this.selectedSession?.presentations?.map((item, index) => ({
        id: item.id,
        name: item.title,
        value: this.timeFormatPipe.transform(item.time_spent),
        color: this.chartColorScheme.domain[index % 10]
      }));

      setTimeout(() => {
        const presentationChart = document.getElementById('presentation-chart');
        if (presentationChart) {
          presentationChart.scrollIntoView();
        }
      });
    }
  }

  isSessionLabelActive(item: { name: string }): void {
    return this.sessionActiveEntries.find(s => s.name === item.name);
  }

  sessionLegendLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.sessionActiveEntries = this.sessionData.filter(item => item.name === name);
  }

  sessionLegendLabelDeactivate(item: any): void {
    this.sessionActiveEntries = [];
  }

  presentationLegendLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.presentationActiveEntries = this.presentationData.filter(item => item.name === name);
  }

  presentationLegendLabelDeactivate(item: any): void {
    this.presentationActiveEntries = [];
  }

  onSessionSelectedInLegend(sessionId): void {
    if (sessionId) {
      this.availablePresentations = this.allPresentations.filter(p => p.blockId === parseInt(sessionId));
      this.selectedPresentationInLegend = this.availablePresentations?.[0]?.id || null;
    } else {
      this.availablePresentations = [];
    }
  }

  addTime(selection: { id: number, time: number }) {
    this.delegateService.addConferenceDelegatesAttendanceLive(this.conferenceId, this.delegateId, selection.id, selection.time)
      .subscribe(() => {
        this.getAttendance();
      }, (error) => {
        this.modalService.error({ errors: error });
      });
  }

  removeItem(presentationId: number) {
    console.log('presentationId');
    console.log(presentationId);
    this.modalService.defaultModal({
      title: 'Time Spent Deletion',
      body: 'Are you sure you want to remove this attendance record?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.delegateService.deleteConferenceDelegatesAttendanceLive(this.conferenceId, this.delegateId, presentationId)
              .subscribe(
                () => {
                  this.getAttendance();
                },
                (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

  private getAttendance() {
    this.loadingAttendance = true;
    this.delegateService.getConferenceDelegatesAttendanceLive(this.conferenceId, this.delegateId)
      .subscribe(
        (response: { data: AttendanceSession[] }) => {
          this.attendanceData = response.data;
          this.sessionData = this.attendanceData?.filter(item => item.time_spent)
            .map(item => ({ name: item.name, value: item.time_spent }));

          this.sessionLegend = this.attendanceData?.map((item, index) => ({
            id: item.id,
            name: item.name,
            value: this.timeFormatPipe.transform(item.time_spent),
            color: this.chartColorScheme.domain[index % 10]
          }));

          this.getProgram();
          this.loadingAttendance = false;
        },
        (error) => {
          this.sessionData = [];
          this.loadingAttendance = false;
        }
      );
  }

  private getProgram() {
    this.loadingAttendance = true;
    this.conferenceService.getProgram(this.conferenceId)
      .subscribe((response: { data: Program }) => {
        this.loadingAttendance = false;
        this.availableSessions = response.data.blocks
          .map(item => ({ id: item.id, name: item.name }));

        this.allPresentations = response.data.presentations
          .map(item => {
            const presentationSession = this.attendanceData
              .find(s => item.block_id === s.id);
            const presentation = presentationSession?.presentations.find(p => p.id === item.id);

            let msDifference = (new Date(item.ends_at)).getTime() - (new Date(item.starts_at)).getTime();
            let duration = Math.floor(msDifference / 1000);

            return {
              id: item.id,
              name: item.title,
              blockId: item.block_id,
              time: presentation?.time_spent || duration,
              duration
            };
          });
      }, (error) => {
        this.loadingAttendance = false;
        this.modalService.error({ errors: error });
      });
  }
}
