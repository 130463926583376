import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VotingQuestion } from '@navus/core/classes/voting-question';
import { TrackingService } from '@navus/core/services/tracking.service';
import { VotingService } from '@navus/core/services/voting.service';

@Component({
  selector: 'nv-voting-question',
  templateUrl: './voting-question.component.html',
  styleUrls: ['./voting-question.component.scss']
})

export class NavusVotingQuestionComponent implements OnInit {
  @Input() conferenceId: number;
  @Input() votingQuestion: VotingQuestion;
  selectedOption: any = null;
  control: any = null;

  constructor(
    private votingService: VotingService,
    private trackingService: TrackingService,
  ) { }
    readLocalStorageValue(key: string): string {
        return localStorage.getItem(key);
  }
    
  ngOnInit() {
    if (this.votingQuestion && localStorage.getItem('votingID_'+this.votingQuestion.id) && !this.votingQuestion.user_answer) {
        this.votingQuestion.user_answer = localStorage.getItem('votingID_'+this.votingQuestion.id);
    }
  }
  
//
//  parseSavedVote (cookie: string) {
//      const chunks = cookie.split(" ", 2); 
//      if (chunks[1])
//      return chunks[1] ? chunks[1] : 0;
//  }
//  
  vote() {
    const vote = { 'content': { 'choices': [ this.selectedOption ] }};
    this.votingService
        .vote(this.conferenceId, this.votingQuestion.presentation_id, this.votingQuestion.id, this.makeid(10), vote)
        .subscribe(
          (response) => {
            this.trackingService.countTracking(this.conferenceId, 'presentation', this.votingQuestion.presentation_id, 'VOTING');
            localStorage.setItem('votingID_'+this.votingQuestion.id , this.selectedOption);
            this.votingQuestion.user_answer = this.selectedOption;
            this.selectedOption = null;
            
          },
          () => { }
        );
  }

  makeid(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

}
