import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { VotingQuestion } from '@navus/core/classes/voting-question';
import { VotingWindowSelectModalComponent } from '../../../config/modules/voting/modals/voting-window-select/voting-window-select-modal.component';
import { PresentationService } from '@navus/core/services/presentation.service';
import { ModalService } from '@navus/ui/modal/modal.service';

@Component({
  selector: 'nv-speaker-voting',
  templateUrl: './speaker-voting.component.html',
})
export class SpeakerVotingComponent {
  @Input() organizationId: number;
  @Input() conferenceId: number;
  @Input() presentationId: number;
  @Input() votingQuestions: VotingQuestion[];

  @Output() refresh = new EventEmitter<void>();

  constructor(private router: Router,
              private modalService: ModalService,
              private presentationService: PresentationService) {
  }

  openVoting() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/o', this.organizationId, 'conference', this.conferenceId, 'voting', 'votings', this.presentationId])
    );

    window.open(url, '_blank');
  }

  refreshVotingQuestions() {
    this.refresh.emit();
  }

  setQuestionStatus(votingQuestion, status) {
    const params = {
      status: status
    };
    this.presentationService.updateConferencePresentationVoting(this.conferenceId, this.presentationId, votingQuestion.id, params)
      .subscribe(
        () => {
          this.refresh.emit();
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }

  runQuestion(votingQuestion) {
    const h: number = window.screen.availHeight;
    const w: number = window.screen.availWidth;

    const votingPopups = this.getVotingPopups();
    console.log(votingPopups);

    if (votingPopups.length === 0) {
      const uniqueId: string = Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
      const path: string = `/pv/${this.conferenceId}/presentation/${this.presentationId}/${votingQuestion.id}/${uniqueId}`;
      window.open(window.location.origin + path, uniqueId, `height=${h},width=${w}`);
    } else {
      const modalRef = this.modalService.open(VotingWindowSelectModalComponent);
      modalRef.componentInstance.votingPopups = votingPopups;
      modalRef.result.then(
        (popupId) => {
          if (popupId) {
            const path: string = `/pv/${this.conferenceId}/presentation/${this.presentationId}/${votingQuestion.id}/${popupId}`;
            localStorage.setItem(`pv:${popupId}`, path);
          } else {
            const uniqueId: string = Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
            const path: string = `/pv/${this.conferenceId}/presentation/${this.presentationId}/${votingQuestion.id}/${uniqueId}`;
            window.open(window.location.origin + path, uniqueId, `height=${h},width=${w}`);
          }
        },
        () => {
        }
      );
    }
  }

  private getVotingPopups() {
    return Object.keys(localStorage).filter(key => /^pv:.*$/.test(key));
  }
}
