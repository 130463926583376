import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'transformChar' })
export class TransformChar implements PipeTransform {
    transform(text: string, oldValue: string, newValue: string) {
        if (text.includes(oldValue)) {
            const str = text.replace(new RegExp(oldValue, 'g'), newValue);
            return str;
        } else {
            return text;
        }
    }
}
