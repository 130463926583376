import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ContactService } from '@navus/core/services/contact.service';

@Component({
  selector: 'main [nv-ticket-sales-page]',
  templateUrl: './ticket-sales-page.component.html'
})
export class TicketSalesPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;

  ticketSalesForm: FormGroup;
  regions = [
    { id: 'europe', name: 'Europe' },
    { id: 'north_america', name: 'North America' },
    { id: 'south_america', name: 'South America' },
    { id: 'africa', name: 'Africa' },
    { id: 'asia', name: 'Asia' },
    { id: 'australia', name: 'Australia' },
  ];

  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private modalService: ModalService,
    private contactService: ContactService
  ) { }

  ngOnInit() {
    this.initForm();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = +data.organizationId;
        this.conferenceId = +data.conferenceId;
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.ticketSalesForm = this.formBuilder.group({
      message: ['TicketSales'],
      options: this.formBuilder.group({
        audience: [''],
        number_of_attendees: [''],
        region: ['']
      }),
    });
  }

  submitTicketSalesForm() {
    this.contactService.createRequest(this.ticketSalesForm.value)
      .subscribe(
        () => {
          this.modalService.defaultModal({
            title: 'Request submitted',
            body: 'Your request is submitted. Our team will contact you as soon as possible.',
            size: 'small',
            buttons: [
              {
                text: 'Okay',
                color: 'primary',
                role: 'cancel'
              }
            ]
          });

          this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'landing']);
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }

}
