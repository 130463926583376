<div class="nv-config-layout" [class.sidebar-open]="showSidebar">
    <aside class="nv-config-aside"
           [class.hidden]="hideNavigation"
           nv-organization-navigation><!-- < toggle class 'hidden' -->
    </aside>
    <div class="nv-layout-collapse-toggler"
         [class.on]="hideNavigation"
         (click)="toggleNavigation()"></div> <!-- toggle class 'on' -->
    <router-outlet></router-outlet>
    <aside class="nv-config-vertical-bar-right" style="display: none"
           [class.hidden]="hideTools"><!-- < toggle class 'hidden' -->
        <div class="nv-config-side-tools">
            <div class="cst-item help">
                <a (click)="toggleSideBar()"></a>
                <span>Help & Learning</span>
            </div><!-- /.cst-item -->
        </div><!-- /.nv-config-side-tools -->

        <div class="nv-config-side-tools">
            <div class="cst-item hide-panel"
                 (click)="toggleTools()">
                <a></a>
                <span>Hide Side Panel</span>
            </div><!-- /.cst-item -->
        </div><!-- /.nv-config-side-tools -->
    </aside><!-- /.nv-config-vertical-bar-right -->
</div>
