import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ConferenceService } from '@navus/core/services/conference.service';
import { Conference } from '@navus/core/classes/conference';

@Component({
  selector: 'main [nv-sponsor-settings-page]',
  templateUrl: './sponsor-settings-page.component.html',
  styles: []
})
export class SponsorSettingsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  sponsorSettingsForm: FormGroup;
  conference: Conference;

  loadingSponsorSettings: boolean = true;
  savingSponsorSettings: boolean = false;
  unsavedChanges: boolean = false;

  subscriptions: Subscription[] = [];

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private conferenceService: ConferenceService,
    private toastService: ToastService,
    private modalService: ModalService,
  ) { }

  ngOnInit() {
    this.initForm();

    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getSponsorSettings();
      }
    );
    this.subscriptions.push(subscription);

    const changeSubscription = this.sponsorSettingsForm.valueChanges.subscribe(
      () => {
        this.unsavedChanges = true;
      }
    );
    this.subscriptions.push(changeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.sponsorSettingsForm = this.formBuilder.group({
      lead_generation: [false]
    });
    this.unsavedChanges = false;
  }

  getSponsorSettings() {
    this.loadingSponsorSettings = true;
    this.conferenceService
      .getOrganizationConference(this.organizationId, this.conferenceId)
      .subscribe(
        (response) => {
          this.conference = response.data;
          let settings = response.data.settings;
          this.sponsorSettingsForm.patchValue(settings.sponsors || {});
          this.loadingSponsorSettings = false;
          setTimeout(() => { this.unsavedChanges = false; }, 500);
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.loadingSponsorSettings = false;
        }
      );
  }

  save() {
    if (this.sponsorSettingsForm.invalid) { return; }

    const conference = this.conference;
    conference.settings.sponsors = this.sponsorSettingsForm.value;

    this.savingSponsorSettings = true;
    this.conferenceService
      .updateOrganizationConference(this.organizationId, this.conferenceId, this.conference)
      .subscribe(
        (response) => {
          this.toastService.success('Settings successfully saved.', {});
          this.unsavedChanges = false;
          this.savingSponsorSettings = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.savingSponsorSettings = false;
        }
      );
  }

  close() {
    if (this.unsavedChanges) {
      this.modalService.defaultModal({
        title: 'Unsaved changes',
        body: 'Are you sure you want to leave the page without saving changes?',
        size: 'small',
        buttons: [
          {
            text: 'Stay',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Leave',
            color: 'primary',
            handler: () => {
              this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'sponsors', 'companies']);
            }
          }
        ]
      });
    } else {
      this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'sponsors', 'companies']);
    }
  }

}
