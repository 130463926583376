<ng-container *ngIf="user && !editMode">
  <div class="nv-profile-settings-body-gutter">
    <div class="nv-personal-info">
      <div class="nv-personal-info-image">
        <img
          [src]="user.image_url || '../../assets/avatar-placeholder.png'"
          alt="{{ user.first_name }} {{ user.last_name }}"
        />
        <span
          (click)="fileInput.click()"
          class="nv-personal-info-image-edit"
          title="Add / Edit image"
        ></span>
        <span style="visibility: hidden; position: absolute; overflow: hidden; width: 0; height:0;border:none;margin:0; padding:0">
            <input ng2FileSelect #fileInput accept=".jpg,.jpeg,.png" type="file" [uploader]="uploader"/>
        </span>
        <div *ngIf="progressBar" class="nv-progress-bar">
            <div class="progress-bar" role="progressbar"
                 [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
        </div>
      </div>
      <!-- /.nv-personal-info-image -->
      <h3>{{ user.first_name }} {{ user.last_name }}</h3>
      <p class="nv-personal-info-meta">
        <span class="nv-pi-mail">{{ user.email }}</span>
        <span class="nv-pi-location">{{ user.country?.name }}</span>
        <span class="nv-pi-organisation">{{ user.institution }}</span>
      </p>
      <!-- /.nv-personal-info-meta -->
      <p>
        <nv-button (click)="edit()">Edit profile</nv-button>
      </p>
    </div>
    <!-- /.nv-personal-info -->
  </div>
  <!-- /.nv-profile-settings-body-gutter -->
</ng-container>

<ng-container *ngIf="editMode">
  <header class="nv-profile-settings-detail-header">
    <a class="nv-psd-back" (click)="cancel()"></a>
    <h2><span>Edit Profile</span></h2>
  </header>
  <!-- /.nv-profile-settings-detail-header -->
  <div class="nv-profile-settings-detail-body-gutter">
    <div class="nv-profile-settings-form-port">
      <form class="nv-row spaced gap-24 propagate" [formGroup]="userForm">
        <div class="nv-column desk-6 phab-12">
          <div class="nv-column-content">
            <nv-input
              [label]="'First name' | translate"
              formControlName="first_name"
            ></nv-input>
          </div>
          <!-- /.nv-column-content -->
        </div>
        <!-- /.nv-column -->
        <div class="nv-column desk-6 phab-12">
          <div class="nv-column-content">
            <nv-input
              [label]="'Last name' | translate"
              formControlName="last_name"
            ></nv-input>
          </div>
          <!-- /.nv-column-content -->
        </div>
        <!-- /.nv-column -->
        <div class="nv-column desk-12">
          <div class="nv-column-content">
            <nv-input
              [label]="'Email' | translate"
              formControlName="email"
            ></nv-input>
          </div>
          <!-- /.nv-column-content -->
        </div>
        <!-- /.nv-column -->
        <div class="nv-column desk-12">
          <div class="nv-column-content">
            <nv-select
              [label]="'Country' | translate"
              formControlName="country_id"
              [required]="true"
              [options]="filteredCountries"
              [showSearch]="true"
              (searched)="filterCountries($event)"
            ></nv-select>
          </div>
          <!-- /.nv-column-content -->
        </div>
        <!-- /.nv-column -->
        <div class="nv-column desk-12">
          <div class="nv-column-content">
            <nv-input
              [label]="'Company / Institution' | translate"
              formControlName="institution"
            ></nv-input>
          </div>
          <!-- /.nv-column-content -->
        </div>
        <!-- /.nv-column -->
        <div class="nv-column desk-12">
          <div class="nv-column-content">
            <div class="nv-profile-settings-btn-box">
              <nv-button (click)="cancel()" color="passive">Close</nv-button>
              &nbsp;&nbsp;&nbsp;
              <nv-button (click)="save()" [disabled]="userForm.invalid">Save</nv-button>
            </div>
            <!-- /.nv-profile-settings-btn-box -->
          </div>
          <!-- /.nv-column-content -->
        </div>
        <!-- /.nv-column -->
      </form>
      <!-- /.nv-row -->
    </div>
    <!-- /.nv-profile-settings-form-port -->
  </div>
  <!-- /.nv-profile-settings-body-gutter -->
</ng-container>