export class WebsitePageSection {
  id: number;
  order: number;
  type: string = 'custom';
  title: string;
  configuration: any;

  website_page_id: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
