
<section class="nv-section guttered" [class.edit-mode]="mouseOver">
    <ng-container *ngIf="mouseOver">
        <a class="nv-section-add-top" title="Add section" (click)="createBefore()"></a>
        <a class="nv-section-add-bottom" title="Add section" (click)="createAfter()"></a>
        <div class="nv-section-edit-tool-box">
            <a class="edit" title="Edit section" (click)="edit()"></a>
            <a class="delete" title="Delete section" (click)="delete()"></a>
        </div><!-- /.nv-section-edit-tool-box -->
        <div class="nv-section-sort-tool-box">
            <a class="move-up" title="Move up" (click)="moveUp()"></a>
            <a class="move-down" title="Move down" (click)="moveDown()"></a>
        </div><!-- /.nv-section-sort-tool-box -->
    </ng-container>

    <ng-container *ngIf="!configuration.hotTopics">        
        <div class="nv-port" style="position: relative">
            <img style="position: absolute; right: 20px; top: 20px; cursor: pointer" *ngIf="configuration.showInfo"
                 src="../../../../assets/info.svg" (click)="showPopup = true">

            <div class="nv-row v-stretch gap-30 large-spaced-top">
                <div class="nv-column desk-12">
                    <div class="nv-column-content" style="text-align: center;">
                    </div><!-- /.nv-column-content -->
                </div><!-- /nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row v-stretch gap-30 large-spaced-bottom">
                <div class="nv-column desk-12">
                    <div *ngIf="articles" class="nv-column-content">
                        <div class="nv-top-article">
                            <figure class="eta-side-img">
                                <a (click)="openArticle(articles[0])" [title]="articles[0]?.title">
                                    <img [src]="articles[0]?.resource ? articles[0]?.resource['800x430'] : articles[0]?.image_url" alt="{{articles[0]?.title}}">
                                </a>
                            </figure><!-- /.eta-side-img -->
                            <div class="eta-content">
                                <h3><a (click)="openArticle(articles[0])">{{articles[0]?.title}}</a></h3>
                                <p>{{ articles[0]?.lead }}</p>
                                <p class="nv-art-date" *ngIf="articles[0]?.published_at">{{ articles[0]?.published_at  | dateFormat: 'DD.MM.YYYY' }}</p>
                            </div><!-- /.eta-content -->
                        </div><!-- /.nv-top-article -->

                        <div class="nv-home-articles">
                            <div *ngFor="let article of articles | slice:1" class="eha-item">
                                <figure>
                                    <a (click)="openArticle(article)" [title]="article.title">
                                        <img [src]="article.resource ? article.resource['500'] : article.image_url" alt="{{article.title}}">
                                    </a>
                                </figure>
                                <h3><a (click)="openArticle(article)" [title]="article.title">{{article.title}}</a></h3>
                                <p class="nv-art-date" *ngIf="article.published_at">{{ article.published_at  | dateFormat: 'DD.MM.YYYY' }}</p>
                            </div><!-- /.eha-item -->
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- /.nv-port -->
    </ng-container>

    <!-- IF HOT TOPIC -->
    <ng-container *ngIf="configuration.hotTopics">
        <!-- Latest courses -->
            <div class="nv-port">
                <div class="nv-row v-stretch gap-30 large-spaced-top">
                    <div class="nv-column desk-12">
                        <div class="nv-column-content" style="text-align: center;">
                            <h2 translate>HOT TOPICS</h2>
                        </div><!-- /.nv-column-content -->
                    </div><!-- /nv-column -->
                </div><!-- /.nv-row -->
                <div *ngIf="articles" class="nv-row v-stretch gap-30 small-spaced-top">
                    <div *ngFor="let article of articles" class="nv-column desk-2-4 tab-4 phab-6 phone-12">
                        <div class="nv-column-content">
                            <div class="nv-img-n-txt">
                                <figure class="ln-img">
                                    <a (click)="openArticle(article)">
                                        <span class="ln-contain-img" nvBackgroundImage [image]="article.resource ? article.resource['800x430'] : article.image_url"></span>
                                    </a>
                                </figure>
                                <h4 class="int-caption">
                                    <a (click)="openArticle(article)">{{ article.title }}</a>
                                </h4>
                            </div>
                        </div>
                    </div><!-- /nv-column -->
                </div><!-- /.nv-section -->
            </div><!-- /.nv-port -->
    </ng-container>

</section><!-- /.nv-section -->



<div class="nv-info-modal" *ngIf="showPopup">
    <div class="im-pane">
        <header class="im-header">
            <span class="im-close" (click)="showPopup = false"></span>
            <h3>News</h3>
        </header><!-- /.im-header -->
        <div class="im-body" style="max-height: 400px">
            <div class="im-content">
                <p>Add as many articles and news as you want.</p>
                <p>Keep you audience engaged and updated with the latest news and content.</p>
            </div><!-- /.im-content -->
        </div><!-- /.im-body -->
    </div><!-- /.im-pane -->
</div><!-- /.nv-info-modal -->
