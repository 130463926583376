<div class="nv-modal extra-large">
    <div class="nv-modal-header">
        <h4 class="nv-modal-title">{{ title }}</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body">
        <div class="nv-row config-plates modal-data-room">
            <div class="nv-column desk-3" *ngIf="!folder">
                <div class="nv-column-content">
                    <div class="nv-heading-n-tools">
                        <h3 class="pale">Folders</h3>
                        <div class="hnt-tool">
<!--                            <div class="nv-cell-actions">-->
<!--                                <span-->
<!--                                    class="nv-cell-action-trigger"-->
<!--                                    [class.on]="showFoldersAction"-->
<!--                                    (click)="toggleFoldersAction($event)">-->
<!--                                </span>-->
<!--                                <nav>-->
<!--                                    <ul>-->
<!--                                        <li (click)="openOrganizationCreateFolder()"><a>Create New Folder</a></li>-->
<!--                                        <li><a>Show Deleted Files & Folders</a></li>-->
<!--                                    </ul>-->
<!--                                </nav>-->
<!--                            </div>&lt;!&ndash; /nv-cell-actions &ndash;&gt;-->
                        </div><!-- /.hnt-tool -->
                    </div><!-- /.nv-heading-n-tools -->
                    <div class="nv-folders-list-port" >
                        <nav class="nv-folders-list">
                            <ul>
                                <li *ngFor="let folder of folders"
                                    [ngClass]="{'active': selectedFolder === folder, 'locked': isFolderLocked(folder)}"
                                    (click)="onFolderClick(folder)">
                                    <a>
                                        <strong>{{ folder }}</strong>
                                        <span class="remove" (click)="deleteFolder($event, folder)"></span>
                                    </a>
                                </li>
                            </ul>
                        </nav><!-- /.nv-folders-list -->
                    </div><!-- /.nv-folders-list-port -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column" [class.desk-9]="!folder" [class.desk-12]="folder">
                <div class="nv-column-content">
                    <div class="nv-data-room-dboard">
                        <div class="nv-data-room-controls-bar">
                            <div class="drcb-left">
                                <h3 class="pale">My Files</h3>
<!--                                <div class="nv-check-n-sort">-->
<!--                                    <label class="nv-regular-checkbox">-->
<!--                                            <span class="rcb">-->
<!--                                                <input type="checkbox">-->
<!--                                                <span class="rc-visual"></span>-->
<!--                                            </span>&lt;!&ndash; /.rcb &ndash;&gt;-->
<!--                                        <span class="label-txt">Name</span>-->
<!--                                    </label>-->
<!--                                </div>-->
                                <!-- /.nv-check-n-sort -->
                            </div><!-- /.drcb-left -->
                            <div class="drcb-right">
<!--                                <div class="nv-layout-switch">-->
<!--                                    <span class="small-thumbs current" title="Show small thumbs"></span>-->
<!--                                    <span class="large-thumbs" title="Show large thumbs"></span>-->
<!--                                    <span class="list" title="Show as list"></span>-->
<!--                                </div>&lt;!&ndash; /.nv-layout-switch &ndash;&gt;-->
                            </div><!-- /.drcb-right -->
                        </div><!-- /.nv-data-room-controls-bar -->
                        <div *ngIf="selectedFiles.length > 0" class="ct-controls-bar">
                            <div class="ct-control-bar-status">
                                <p>{{ selectedFiles.length }} items selected</p>
                            </div><!-- /.ct-control-bar-status -->
                            <nav class="ct-control-bar-tools">
                                <ul>
                                    <li class="unselect">
                                        <a (click)="unselectAll()">Unselect All</a>
                                    </li>
                                    <li class="alt">
                                        <a (click)="emitselectedFiles()">Confirm</a>
                                    </li>
                                </ul>
                            </nav><!-- /.ct-control-bar-tools -->
                        </div><!-- /.ct-controls-bar -->
                    </div><!-- /.nv-data-room-dboard -->
                    <div class="nv-data-room-content-wrap" 
                        [ngClass]="{'ready': readyToDrop}"
                        nvInfiniteScroll (scrolled)="getFilesPagination(true)">
                        <div class="nv-data-room-content-port">
                            <div class="nv-data-room-content"
                                 (dragenter)="dragEnter($event)"
                                 (dragover)="dragEnter($event)"
                                 (dragleave)="dragLeave($event)"
                                 (drop)="onDrop($event)"><!--  toggle class 'by2' -->

                                <div class="drc-item upload">
                                    <div class="drc-upload">
                                        <div class="drc-upload-port">
                                            <h4>Drop files here</h4>
                                            <span>or</span>
                                            <a class="nv-btn filled small anchor" (click)="fileInput.click()">Choose a file</a>
                                            <input ng2FileSelect #fileInput type="file"
                                                   [uploader]="uploader"
                                                   [hidden]="true"
                                                   [accept]="mime ? mime.join(',') : ''" multiple/>
                                        </div><!-- /.drc-upload-port -->
                                    </div><!-- /.drc-upload -->
                                </div><!-- /.drc-item -->

                                <ng-container *ngIf="resources.length > 0">
                                    <div [nvLoading]="{instance: 'resource'}"></div>
                                    <div class="drc-item" *ngFor="let resource of resources" (click)="onResourceClickMultiple(resource)">
                                        <div class="drc-port"
                                             [ngClass]="{'checked' : this.selectedFiles.includes(resource) }"
                                             (click)="onResourceClick(resource)"
                                             (mouseleave)="resource.toggled = false">
                                            <div class="drc-tools">
                                                <div class="nv-regular-checkbox">
                                                    <div class="rcb">
                                                        <div class="w-checker" *ngIf="multiple">
                                                            <span class="nv-row-checker" [class.checked]="selectedFiles.includes(resource)" title="Select item"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="nv-cell-actions">
                                                    <span class="nv-cell-action-trigger"
                                                          (click)="actionToggle($event,resource)"
                                                          [class.on]="resource.toggled"></span>
                                                    <nav>
                                                        <ul>
                                                            <li
                                                                (click)="copyToClipboard($event,resource?.url); resource.toggled = false">Copy URL</li>
                                                            <li
                                                                (click)="downloadResource($event,resource); resource.toggled = false">Download</li>
                                                            <li
                                                                (click)="openEditResourceModal($event,resource); resource.toggled = false">Edit</li>
                                                            <li class="delete"
                                                                (click)="deleteFile($event,resource?.id); resource.toggled = false">Delete</li>
                                                        </ul>
                                                    </nav>
                                                </div><!-- /nv-cell-actions -->
                                            </div><!-- /.drc-tools -->
                                                <div class="drc-image-port"
                                                     [title]="resource.filename"
                                                     nvBackgroundImage [image]="resource.thumb">
                                                </div><!-- /.drc-image-port -->
                                                <div class="drc-meta">
                                                    <p>{{ resource.original_filename }}</p>
                                                    <!--span>1.2mb</span-->
                                                </div><!-- /.drc-meta -->
                                        </div><!-- /.drc-port -->
                                    </div><!-- /.drc-item -->
                                </ng-container>
                            </div><!-- /.nv-data-room-content -->
                        </div><!-- /.nv-data-room-content-port -->
                    </div><!-- /.nv-data-room-content-wrap -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div>
    </div>
</div>
