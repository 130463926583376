import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'main [nv-file-dashboard]',
  templateUrl: './file-dashboard.component.html'
})
export class FileDashboardComponent implements OnInit, OnDestroy {
  organizationId: number;

  subscriptions: Subscription[] = [];

  @HostBinding('class.nv-config-content') class = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  close() {
    this.router.navigate(['/o', this.organizationId, 'dashboard']);
  }

}
