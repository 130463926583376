import { Component, EventEmitter, forwardRef, Host, Input, OnInit, Optional, Output, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
    selector: 'nv-radio',
    templateUrl: './radio.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => NavusRadioComponent),
        multi: true
    }]
})

export class NavusRadioComponent implements OnInit, ControlValueAccessor {

    onChange: (fn: any) => void;
    onTouched: (fn: any) => void;

    @Input() name: string;
    @Input() value: any;
    @Input() label: string;
    @Input() formControlName: string;
    @Input() checked: boolean = false;
    @Input() disabled: boolean = false;

    @Output() change = new EventEmitter();

    constructor(
      @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer
    ) {}

    ngOnInit(): void {
        if (this.checked !== false) { this.checked = true; }
        if (this.disabled !== false) { this.disabled = true; }
        if (this.formControlName) {
            this.name = this.formControlName;
            const formControl = this.controlContainer.control.get(this.formControlName);
            if (this.value === formControl.value) {
                this.checked = true;
            }
        }
    }

    writeValue(value: any): void {
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    onChangeStatus(event) {
        if (this.onChange) {
            this.onChange(this.value);
        }
        this.change.emit(event);
    }

}

