<div class="nv-row gap-30 propagate" *ngIf="value">
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-input
                    type="text"
                    label="Title"
                    [(ngModel)]="value.title"
                    (ngModelChange)="onChange(value)"
            ></nv-input>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-input
                    type="text"
                    label="Subtitle"
                    [(ngModel)]="value.subtitle"
                    (ngModelChange)="onChange(value)"
            ></nv-input>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-input
                    type="text"
                    label="Placeholder"
                    [(ngModel)]="value.placeholder"
                    (ngModelChange)="onChange(value)"
            ></nv-input>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-input
                    type="text"
                    label="Background Image"
                    [(ngModel)]="value.backgroundImage"
                    (ngModelChange)="onChange(value)"
            ></nv-input>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-colorpicker
                    label="Background Color"
                    [(ngModel)]="value.backgroundColor"
                    (ngModelChange)="onChange(value)"
            ></nv-colorpicker>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-colorpicker
                    label="Button Color"
                    [(ngModel)]="value.color"
                    (ngModelChange)="onChange(value)"
            ></nv-colorpicker>
        </div>
    </div>
</div>
