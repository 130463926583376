import { Routes } from '@angular/router';
import { OrganizationGuard } from '../../guards/organization.guard';
import { ChatSettingsPageComponent } from './pages/chat-settings/chat-settings-page.component';
import { QaSettingsPageComponent } from './pages/qa-settings/qa-settings-page.component';
import { VotingSettingsPageComponent } from './pages/voting-settings/voting-settings-page.component';

export const NetworkingRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'chat-settings'
  },
  {
    path: 'chat-settings',
    component: ChatSettingsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Chat Settings'
    }
  },
  {
    path: 'qa-settings',
    component: QaSettingsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Q&A Settings'
    }
  },
  {
    path: 'voting-settings',
    component: VotingSettingsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Voting Settings'
    }
  }
];
