<div class="nv-template-pane">
    <header class="nv-temp-header nv-section">
        <div class="nv-row space-between">
            <div class="nv-column desk-initial v-middle">
                <div class="nv-column-content">
                    <div class="nv-template-logo">
                        <a href="/" routerlink="/" title="Sponsorship spaces &amp; Virtual Exhibitors"><!----></a>
                    </div>
                </div>
            </div>
            <div class="nv-column desk-initial v-stretch" style="display: flex;">
                <div class="nv-column-content">
                    <div class="nv-template-menu-n-profile">
                        <nav class="nv-template-menu">
                            <ul>
                                <li class="nav-page-1014"><a class="nav-link active" href="/1014/page/home"><span>Home</span></a></li>
                                <li class="nav-page-1037"><a class="nav-link" href="/1037/page/sponsor-packages"><span>Sponsor Packages</span></a></li>
                                <li class="nav-page-1034"><a class="nav-link" href="/sponsors"><span>Pre-View Example Exhibition page</span></a></li>
                                <li class="nav-page-1035"><a class="nav-link" href="/1035/page/brochure"><span>Downlaod Sponsor Brochure</span></a></li>
                            </ul>
                        </nav>
                        <div class="nv-template-notifications">
                            <div class="nv-notifications-toggler">
                                <span>5</span>
                            </div><!-- /.nv-notifications-toggler -->
                            <div class="nv-notifications-pane">
                                <div class="nv-notifications-pane-frame">
                                    <header class="nv-notifications-pane-header">
                                        <h4>Notifications</h4>
                                        <div class="nv-notifications-switch">
                                            <h4>Do not disturb</h4>
                                            <div class="tsq-checkbox-radio check-type-slide">
                                                <label title="Turn notifications on & off">
                                                    <input type="checkbox" name="r1">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </div><!-- /.nv-notifications-switch -->
                                    </header><!-- /.nv-notifications-pane-header -->
                                    <div class="nv-notifications-list">
                                        <div class="nv-notifications-item support">
                                            <div class="nv-notifications-item-icon"></div>
                                            <div class="nv-notifications-item-txt">
                                                <div class="msg">
                                                    <p>Hi there! Welcome to CSANZ Annual Scientific Meeting 2020!</p>
                                                </div><!-- /.msg -->
                                                <div class="msg-posted">a few seconds ago</div>
                                            </div><!-- /.nv-notifications-item-txt -->
                                            <div class="nv-notifications-item-ctrl">
                                                <span></span>
                                                <ul class="nv-notifications-item-options">
                                                    <li><a title="Mark as read">Mark as read</a></li>
                                                    <li><a title="Delete">Delete</a></li>
                                                </ul><!-- /.nv-notifications-item-options -->
                                            </div><!-- /.nv-notifications-item-ctrl -->
                                        </div><!-- /.nv-notifications-item -->
                                        <div class="nv-notifications-item chat">
                                            <div class="nv-notifications-item-icon"></div>
                                            <div class="nv-notifications-item-txt">
                                                <div class="msg">
                                                    <p><strong>Dai Jiangm</strong> wants to chat with you.</p>
                                                </div><!-- /.msg -->
                                                <div class="msg-posted">5 minutes ago</div>
                                            </div><!-- /.nv-notifications-item-txt -->
                                            <div class="nv-notifications-item-ctrl">
                                                <span></span>
                                                <ul class="nv-notifications-item-options">
                                                    <li><a title="Mark as read">Mark as read</a></li>
                                                    <li><a title="Delete">Delete</a></li>
                                                </ul><!-- /.nv-notifications-item-options -->
                                            </div><!-- /.nv-notifications-item-ctrl -->
                                        </div><!-- /.nv-notifications-item -->
                                        <div class="nv-notifications-item chat">
                                            <div class="nv-notifications-item-icon"></div>
                                            <div class="nv-notifications-item-txt">
                                                <div class="msg">
                                                    <p>You have two new messages from <strong>Clarke Gillebert</strong></p>
                                                </div><!-- /.msg -->
                                                <div class="msg-posted">5 minutes ago</div>
                                            </div><!-- /.nv-notifications-item-txt -->
                                            <div class="nv-notifications-item-ctrl">
                                                <span></span>
                                                <ul class="nv-notifications-item-options">
                                                    <li><a title="Mark as read">Mark as read</a></li>
                                                    <li><a title="Delete">Delete</a></li>
                                                </ul><!-- /.nv-notifications-item-options -->
                                            </div><!-- /.nv-notifications-item-ctrl -->
                                        </div><!-- /.nv-notifications-item -->
                                        <div class="nv-notifications-item qa">
                                            <div class="nv-notifications-item-icon"></div>
                                            <div class="nv-notifications-item-txt">
                                                <div class="msg">
                                                    <p><strong>Anna Fali</strong> liked your question.</p>
                                                </div><!-- /.msg -->
                                                <div class="msg-posted">5 minutes ago</div>
                                            </div><!-- /.nv-notifications-item-txt -->
                                            <div class="nv-notifications-item-ctrl">
                                                <span></span>
                                                <ul class="nv-notifications-item-options">
                                                    <li><a title="Mark as read">Mark as read</a></li>
                                                    <li><a title="Delete">Delete</a></li>
                                                </ul><!-- /.nv-notifications-item-options -->
                                            </div><!-- /.nv-notifications-item-ctrl -->
                                        </div><!-- /.nv-notifications-item -->
                                        <div class="nv-notifications-item voting">
                                            <div class="nv-notifications-item-icon"></div>
                                            <div class="nv-notifications-item-txt">
                                                <div class="msg">
                                                    <p>New Voting has started in <strong>Florida</strong>.</p>
                                                </div><!-- /.msg -->
                                                <div class="msg-posted">Yesterday</div>
                                            </div><!-- /.nv-notifications-item-txt -->
                                            <div class="nv-notifications-item-ctrl">
                                                <span></span>
                                                <ul class="nv-notifications-item-options">
                                                    <li><a title="Mark as read">Mark as read</a></li>
                                                    <li><a title="Delete">Delete</a></li>
                                                </ul><!-- /.nv-notifications-item-options -->
                                            </div><!-- /.nv-notifications-item-ctrl -->
                                        </div><!-- /.nv-notifications-item -->
                                        <div class="nv-notifications-item support">
                                            <div class="nv-notifications-item-icon"></div>
                                            <div class="nv-notifications-item-txt">
                                                <div class="msg">
                                                    <p>Hi there! Welcome to CSANZ Annual Scientific Meeting 2020!</p>
                                                </div><!-- /.msg -->
                                                <div class="msg-posted">a few seconds ago</div>
                                            </div><!-- /.nv-notifications-item-txt -->
                                            <div class="nv-notifications-item-ctrl">
                                                <span></span>
                                                <ul class="nv-notifications-item-options">
                                                    <li><a title="Mark as read">Mark as read</a></li>
                                                    <li><a title="Delete">Delete</a></li>
                                                </ul><!-- /.nv-notifications-item-options -->
                                            </div><!-- /.nv-notifications-item-ctrl -->
                                        </div><!-- /.nv-notifications-item -->
                                        <div class="nv-notifications-item chat">
                                            <div class="nv-notifications-item-icon"></div>
                                            <div class="nv-notifications-item-txt">
                                                <div class="msg">
                                                    <p><strong>Dai Jiangm</strong> wants to chat with you.</p>
                                                </div><!-- /.msg -->
                                                <div class="msg-posted">5 minutes ago</div>
                                            </div><!-- /.nv-notifications-item-txt -->
                                            <div class="nv-notifications-item-ctrl">
                                                <span></span>
                                                <ul class="nv-notifications-item-options">
                                                    <li><a title="Mark as read">Mark as read</a></li>
                                                    <li><a title="Delete">Delete</a></li>
                                                </ul><!-- /.nv-notifications-item-options -->
                                            </div><!-- /.nv-notifications-item-ctrl -->
                                        </div><!-- /.nv-notifications-item -->
                                        <div class="nv-notifications-item chat">
                                            <div class="nv-notifications-item-icon"></div>
                                            <div class="nv-notifications-item-txt">
                                                <div class="msg">
                                                    <p>You have two new messages from <strong>Clarke Gillebert</strong></p>
                                                </div><!-- /.msg -->
                                                <div class="msg-posted">5 minutes ago</div>
                                            </div><!-- /.nv-notifications-item-txt -->
                                            <div class="nv-notifications-item-ctrl">
                                                <span></span>
                                                <ul class="nv-notifications-item-options">
                                                    <li><a title="Mark as read">Mark as read</a></li>
                                                    <li><a title="Delete">Delete</a></li>
                                                </ul><!-- /.nv-notifications-item-options -->
                                            </div><!-- /.nv-notifications-item-ctrl -->
                                        </div><!-- /.nv-notifications-item -->
                                        <div class="nv-notifications-item qa">
                                            <div class="nv-notifications-item-icon"></div>
                                            <div class="nv-notifications-item-txt">
                                                <div class="msg">
                                                    <p><strong>Anna Fali</strong> liked your question.</p>
                                                </div><!-- /.msg -->
                                                <div class="msg-posted">5 minutes ago</div>
                                            </div><!-- /.nv-notifications-item-txt -->
                                            <div class="nv-notifications-item-ctrl">
                                                <span></span>
                                                <ul class="nv-notifications-item-options">
                                                    <li><a title="Mark as read">Mark as read</a></li>
                                                    <li><a title="Delete">Delete</a></li>
                                                </ul><!-- /.nv-notifications-item-options -->
                                            </div><!-- /.nv-notifications-item-ctrl -->
                                        </div><!-- /.nv-notifications-item -->
                                        <div class="nv-notifications-item voting">
                                            <div class="nv-notifications-item-icon"></div>
                                            <div class="nv-notifications-item-txt">
                                                <div class="msg">
                                                    <p>New Voting has started in <strong>Florida</strong>.</p>
                                                </div><!-- /.msg -->
                                                <div class="msg-posted">Yesterday</div>
                                            </div><!-- /.nv-notifications-item-txt -->
                                            <div class="nv-notifications-item-ctrl">
                                                <span></span>
                                                <ul class="nv-notifications-item-options">
                                                    <li><a title="Mark as read">Mark as read</a></li>
                                                    <li><a title="Delete">Delete</a></li>
                                                </ul><!-- /.nv-notifications-item-options -->
                                            </div><!-- /.nv-notifications-item-ctrl -->
                                        </div><!-- /.nv-notifications-item -->
                                    </div><!-- /.nv-notifications-list -->
                                    <div class="nv-notifications-pane-footer">
                                        <h4><a title="Mark all as read">Mark all as read</a></h4>
                                    </div><!-- /.nv-notifications-pane-footer -->
                                </div><!-- /.nv-notifications-pane-frame -->
                            </div><!-- /.nv-notifications-pane -->
                        </div><!-- /.nv-template-notifications -->
                        <div class="nv-template-profile">
                            <a translate="" class="nv-btn small accent2 radius-high" href="/profile/login">Login / Register</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="nv-column desk-none tab-initial v-stretch">
                <div class="nv-column-content">
                    <div class="nv-touch-menu-trigger"></div>
                </div>
            </div>
        </div>
    </header><!-- /nv-temp-header -->
    <div class="nv-template-wrap">
        <div class="nv-live-stage-port nv-panel-on nv-program-on"><!-- < control classes = 'nv-program-on' / 'nv-panel-on' -->
            <div class="nv-live-stage-wide-column">
                <div class="nv-live-stage-banner" role="banner">
                    <a href><img src="http://placehold.jp/24/cccccc/ffffff/1200x150.png?text=Sponsor+Banner" alt="Sponsor name"></a>
                </div><!-- /.nv-live-stage-banner -->
                <div class="nv-live-stage-video-port">
                    <div class="nv-live-stage-video-status-bar">
                        <div class="nv-live-stage-video-info">
                            <span class="live">Live</span>
                            <span class="break">Break</span>
                            <span class="room">Florida</span>
                        </div><!-- /.nv-live-stage-video-info -->
                    </div><!-- /.nv-live-stage-video-status-bar -->
                    <div class="nv-live-stage-video-holder">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/c1nzRBd-m1Q" title="YouTube video player" frameborder="0" allow="accelerometer;" allowfullscreen></iframe>
                    </div><!-- /.nv-live-stage-video-holder -->
                    <div class="nv-live-stage-program-pane">
                        <header class="nv-live-stage-program-bar">
                            <h4>LIVE PROGRAM</h4>
                            <div class="nv-live-stage-program-bar-tools">
                                <p class="full">View full program</p>
                                <p class="close">Close Guide</p>
                            </div><!-- /.nv-live-stage-program-bar-tools -->
                        </header><!-- /.nv-live-stage-program-bar -->
                        <div class="nv-live-stage-program-timeline-wrap">
                            <div class="nv-live-stage-program-timeline">
                                <div class="nv-live-stage-timeline-current-wrap">
                                    <span class="nv-live-stage-timeline-current-line" style="left: 180px;" title="8:44am"></span>
                                    <div class="nv-live-stage-timeline-bar">
                                        <h5>Room</h5>
                                        <div class="nv-live-stage-program-timeline-chunk">08:00</div>
                                        <div class="nv-live-stage-program-timeline-chunk">09:00</div>
                                        <div class="nv-live-stage-program-timeline-chunk">10:00</div>
                                        <div class="nv-live-stage-program-timeline-chunk">11:00</div>
                                        <div class="nv-live-stage-program-timeline-chunk">12:00</div>
                                        <div class="nv-live-stage-program-timeline-chunk">13:00</div>
                                        <div class="nv-live-stage-program-timeline-chunk">14:00</div>
                                        <div class="nv-live-stage-program-timeline-chunk">15:00</div>
                                        <div class="nv-live-stage-program-timeline-chunk">16:00</div>
                                        <div class="nv-live-stage-program-timeline-chunk">17:00</div>
                                    </div><!-- /.nv-live-stage-timeline-bar -->

                                    <!-- Timeline row -->
                                    <div class="nv-live-stage-timeline-items nv-timeline-items-row">
                                        <h2 class="active">Florida</h2>
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                    </div><!-- /.nv-live-stage-timeline-items -->

                                    <!-- Timeline row -->
                                    <div class="nv-live-stage-timeline-items nv-timeline-items-row">
                                        <h2 data-watch-now="Watch now">Vancouver</h2>
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                    </div><!-- /.nv-live-stage-timeline-items -->

                                    <!-- Timeline row -->
                                    <div class="nv-live-stage-timeline-items nv-timeline-items-row">
                                        <h2>Jagodina</h2>
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                    </div><!-- /.nv-live-stage-timeline-items -->

                                    <!-- Timeline row -->
                                    <div class="nv-live-stage-timeline-items nv-timeline-items-row">
                                        <h2>Amsterdam</h2>
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                    </div><!-- /.nv-live-stage-timeline-items -->

                                    <!-- Timeline row -->
                                    <div class="nv-live-stage-timeline-items nv-timeline-items-row">
                                        <h2>Munich</h2>
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                    </div><!-- /.nv-live-stage-timeline-items -->

                                    <!-- Timeline row -->
                                    <div class="nv-live-stage-timeline-items nv-timeline-items-row">
                                        <h2>Loooong titled room no 445</h2>
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item booked" style="width: 270px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                    <div class="nv-timeline-hidden">
                                                        <p class="chairs"><strong>Chairs</strong>: Leonard Kritharides, Clara Chow</p>
                                                    </div>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                    <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                        <div class="nv-timeline-tooltip">30 minutes break</div>
                                                    </div>
                                                    <div class="nv-live-stage-sub-block" style="width:15%;">
                                                        <span style="width:50%;"></span>
                                                    </div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                        <div class="nv-live-stage-timeline-item" style="width: 90px;" title="Translating lessons from vascular biology into new treatment for coronary artery disease | 09am - 11:40am">
                                            <div class="nv-live-stage-timeline-item-port">
                                                <div class="nv-live-stage-timeline-txt">
                                                    <h3>Translating lessons from vascular biology into new treatment for coronary artery disease</h3>
                                                </div><!-- /.nv-live-stage-timeline-txt -->
                                                <div class="nv-live-stage-sub-line">
                                                    <div class="nv-live-stage-sub-block chapter" style="width:30%;"></div>
                                                    <div class="nv-live-stage-sub-block" style="width:70%;"></div>
                                                </div><!-- /.nv-live-stage-sub-line -->
                                            </div><!-- /.nv-live-stage-timeline-item-port -->
                                        </div><!-- /.nv-live-stage-timeline-item -->
                                    </div><!-- /.nv-live-stage-timeline-items -->
                                </div><!-- /nv-live-stage-timeline-current -->
                            </div><!-- /.nv-live-stage-program-timeline -->
                        </div><!-- /.nv-live-stage-program-timeline-wrap -->
                    </div><!-- /.nv-live-stage-program-pane -->
                </div><!-- /.nv-live-stage-video-port -->
                <div class="nv-live-stage-video-meta">
                    <div class="nv-live-stage-video-meta-txt">
                        <div class="nv-live-stage-video-meta-line">
                            <span class="state">WATCHING</span>
                            <span class="time">08:30 - 09:30AM</span>
                            <div class="nv-live-stage-title">Examining structural heart disease care management: an exploratory study of the co-ordinator role</div>
                            <div class="nv-live-stage-video-meta-tooltip">
                                <h4>Examining structural heart disease care management: an exploratory study of the co-ordinator role</h4>
                                <p class="nv-video-tooltip-chairs">
                                    Chairs: Leonard Kritharides, Clara Chow
                                </p><!-- /.nv-video-tooltip-chairs -->
                                <p class="nv-video-tooltip-meta time">08:30 AM - 09:30 PM</p>
                                <p class="nv-video-tooltip-meta room">Room: Florida</p>
                            </div><!-- /.nv-live-stage-video-meta-tooltip -->
                        </div>
                        <div class="nv-live-stage-video-meta-line">
                            <span class="state">UP NEXT</span>
                            <span class="time">09:30 - 12:00AM</span>
                            <div class="nv-live-stage-title">Translating lessons from vascular biology into new treatment for coronar artery disease</div>
                            <div class="nv-live-stage-video-meta-tooltip">
                                <h4>Translating lessons from vascular biology into new treatment for coronar artery disease</h4>
                                <p class="nv-video-tooltip-chairs">
                                    Chairs: Leonard Kritharides, Clara Chow
                                </p><!-- /.nv-video-tooltip-chairs -->
                                <p class="nv-video-tooltip-meta time">08:30 AM - 09:30 PM</p>
                                <p class="nv-video-tooltip-meta room">Room: Florida</p>
                            </div><!-- /.nv-live-stage-video-meta-tooltip -->
                        </div>
                    </div><!-- /.nv-live-stage-video-meta-txt -->
                    <div class="nv-live-stage-live-program-toggle">
                        <a href class="nv-btn small accent1" data-icon-pl="&#xe619;">See Live Program Guide</a>
                        <a href class="nv-btn small neutral" data-icon-pl="&#xe082;">Close Live Program Guide</a>
                    </div><!-- /.nv-live-stage-live-program-toggle -->
                </div><!-- /.nv-live-stage-video-meta -->
            </div><!-- /.nv-live-stage-wide-column -->
            <div class="nv-live-stage-narrow-column">

                <div class="nv-live-stage-tool-panel">
                    <div class="nv-live-stage-info-box">
                        <div class="nv-live-stage-info-expander">
                            <div class="nv-live-stage-info-expander-btn">
                                <span>expand</span>
                                <span>collapse</span>
                            </div><!-- /.nv-live-stage-info-expander-btn -->
                        </div><!-- /.nv-live-stage-info-expander -->
                        <div class="nv-live-stage-info-box-port">
                            <header class="nv-live-stage-main-info">
                                <p>08:30 AM - 12:00 PM</p>
                                <h3>
                                    <a title="session name"><span>Session</span> Examining structural heart disease care management: an exploratory study of the co-ordinator role</a>
                                </h3><!-- /.session -->
                                <h2>Examining structural heart disease care management: an exploratory study of the co-ordinator role</h2>
                            </header>
                            <div class="nv-live-stage-participant-info-block">
                                <h4>Chairs</h4>
                                <div class="nv-live-stage-participant-row">
                                    <div class="nv-live-stage-participant">
                                        <div class="avatar"><img src="https://loremflickr.com/320/240/face?lock=1" alt="name"></div>
                                        <div class="txt">
                                            <h5>Alexandre Paiva</h5>
                                            <p>Brazil</p>
                                        </div><!-- /.txt -->
                                    </div><!-- /.nv-live-stage-participant -->
                                    <div class="nv-live-stage-participant">
                                        <div class="avatar"><img src="https://loremflickr.com/320/240/face?lock=2" alt="name"></div>
                                        <div class="txt">
                                            <h5>Tum Tantasatityanon</h5>
                                            <p>Alaska</p>
                                        </div><!-- /.txt -->
                                    </div><!-- /.nv-live-stage-participant -->
                                </div><!-- /.nv-live-stage-participant-row -->
                            </div><!-- /.nv-live-stage-participant-info-block -->
                            <div class="nv-live-stage-participant-info-block">
                                <h4>Speakers</h4>
                                <div class="nv-live-stage-participant-row">
                                    <div class="nv-live-stage-participant">
                                        <div class="avatar"></div>
                                        <div class="txt">
                                            <h5>Alexandre Paiva</h5>
                                            <p>Brazil</p>
                                        </div><!-- /.txt -->
                                    </div><!-- /.nv-live-stage-participant -->
                                    <div class="nv-live-stage-participant">
                                        <div class="avatar"><img src="https://loremflickr.com/320/240/face?lock=3" alt="name"></div>
                                        <div class="txt">
                                            <h5><a href title="Yvonne Thompson">Yvonne Thompson</a></h5>
                                            <p>Croatia</p>
                                        </div><!-- /.txt -->
                                    </div><!-- /.nv-live-stage-participant -->
                                    <div class="nv-live-stage-participant">
                                        <div class="avatar"><img src="https://loremflickr.com/320/240/face?lock=4" alt="name"></div>
                                        <div class="txt">
                                            <h5>John Doe</h5>
                                            <p>Brazil</p>
                                        </div><!-- /.txt -->
                                    </div><!-- /.nv-live-stage-participant -->
                                    <div class="nv-live-stage-participant">
                                        <div class="avatar"><img src="https://loremflickr.com/320/240/face?lock=5" alt="name"></div>
                                        <div class="txt">
                                            <h5>Tum Tantasatityanon</h5>
                                            <p>Alaska</p>
                                        </div><!-- /.txt -->
                                    </div><!-- /.nv-live-stage-participant -->
                                </div><!-- /.nv-live-stage-participant-row -->
                            </div><!-- /.nv-live-stage-participant-info-block -->
                        </div><!-- /.nv-live-stage-info-box-port -->
                    </div><!-- /.nv-live-stage-info-box -->
                    <div class="nv-tool-box-widget nv-live-stage-tool-box">
                        <div class="nv-tool-box-content">
                            <div class="nv-tool-box-current-tool">
                                <h4>Chat</h4>
<!--                                <div class="nv-tool-box-session-info">-->
<!--                                    <h3>Session info</h3>-->
<!--                                    <div class="nv-tool-box-session-state">-->
<!--                                        <span class="tbs-full" title="Show full session info"></span>-->
<!--                                        <span class="tbs-semi active" title="Show session info"></span>-->
<!--                                        <span class="tbs-hide" title="Hide session info"></span>-->
<!--                                    </div>&lt;!&ndash; /.nv-tool-box-session-state &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; /.nv-tool-box-session-info &ndash;&gt;-->
                            </div><!-- /.tool-box-current-tool -->
                            <div class="nv-tool-box-current">
                            </div><!-- /.nv-tool-box-current -->
                        </div><!-- /.nv-tool-box-content -->
                    </div><!-- /.nv-live-stage-tool-box -->
                </div><!-- /.nv-live-stage-tool-panel -->

                <div class="nv-live-stage-tools-controls">
                    <div class="nv-tool-box-controls-port upper">
                        <div class="nv-tool-box-controls-group">
                            <div class="nv-tool-box-controls-sleeve">
                                <div class="nv-tool-box-control-item active" title="Chat">
                                    <div class="nv-tool-box-icon info"></div>
                                    <p>Session info</p>
                                </div><!-- /.nv-tool-box-control-item -->
                            </div><!-- /.nv-tool-box-controls-sleeve -->
                        </div><!-- /.nv-tool-box-controls-group -->
                        <div class="nv-tool-box-clock">09:00AM</div>
                    </div><!-- /.nv-tool-box-controls-port -->
                    <div class="nv-tool-box-controls-port lower">
                        <div class="nv-tool-box-controls-group">
                            <div class="nv-tool-box-controls-sleeve">
                                <div class="nv-tool-box-control-item active" title="Chat">
                                    <div class="nv-tool-box-icon chat"><span>4</span></div>
                                    <p>Chat</p>
                                </div><!-- /.nv-tool-box-control-item -->

                                <div class="nv-tool-box-control-item" title="Support">
                                    <div class="nv-tool-box-icon support"><span>1</span></div>
                                    <p>Support</p>
                                </div><!-- /.nv-tool-box-control-item -->

                                <div class="nv-tool-box-control-item" title="Q&A">
                                    <div class="nv-tool-box-icon qa"><span>1</span></div>
                                    <p>Q&A</p>
                                </div><!-- /.nv-tool-box-control-item -->

                                <div class="nv-tool-box-control-item" title="Voting">
                                    <div class="nv-tool-box-icon voting"><span>1</span></div>
                                    <p>Voting</p>
                                </div><!-- /.nv-tool-box-control-item -->

                                <div class="nv-tool-box-control-item" title="Voting">
                                    <div class="nv-tool-box-icon voting"><span>1</span></div>
                                    <p>Voting</p>
                                </div><!-- /.nv-tool-box-control-item -->
                            </div><!-- /.nv-tool-box-controls-sleeve -->
                        </div><!-- /.nv-tool-box-controls-group -->
                        <div class="nv-tool-box-toggler"><span></span></div>
                    </div><!-- /.nv-tool-box-controls-port -->
                </div><!-- /.nv-live-stage-tools-controls -->
            </div><!-- /.nv-live-stage-narrow-column -->
        </div><!-- /.nv-live-stage-port -->
    </div><!-- /.nv-template-wrap -->
</div><!-- /.nv-template-pane -->


<!--<div class="nv-live-stage-timeline-rooms">-->
<!--    <ul>-->
<!--        <li>Florida</li>-->
<!--        <li>Eurostream 1</li>-->
<!--        <li>Room 3</li>-->
<!--        <li>Geneva</li>-->
<!--        <li>Room 4</li>-->
<!--        <li>Room 5</li>-->
<!--        <li>Room 6</li>-->
<!--        <li>Room 7</li>-->
<!--        <li>Room 8</li>-->
<!--        <li>Room 9</li>-->
<!--        <li>Room 10</li>-->
<!--        <li>Room 11</li>-->
<!--    </ul>-->
<!--</div>&lt;!&ndash; /.nv-live-stage-timeline-rooms &ndash;&gt;-->






