<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Presentations</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-8">
                <nv-button color="primary" iconPl="&#xe095;" (click)="add()">Add presentation</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port spaced-bottom">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav vertical-break-tab">
                <ul>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program']">Program</a>
                    </li>
                    <li>
                        <a class="active">Presentations</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program', 'blocks']">Sessions</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program', 'locations']">Locations</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <form class="config-search-bar-w-filters" [formGroup]="formFilter" autocomplete="off">
        <div class="sbwf-port">
            <input type="text" placeholder="Search..."
                   formControlName="search_term" (change)="getPresentations()">
            <span class="sbwf-outline"></span>
        </div><!-- /.sbwf-port -->
    </form><!-- /.config-search-bar-w-filters -->
</div>
<div class="nv-config-content-port">
    <div class="nv-global-loading on" [class.on]="presentationsLoading">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <section class="nv-section">
        <div class="nv-port">
            <div class="nv-row gap-20 semi-spaced-top propagate" *ngIf="pagination.total > 0">
                <div class="nv-column v-middle desk-auto phab-12">
                    <div class="nv-column-content">
                        <nv-pagination [config]="pagination" (paginationChange)="getPresentations()"></nv-pagination>
                    </div>
                </div>
                <div class="nv-column v-middle desk-initial phab-12">
                    <div class="nv-column-content">
                        <nv-select [options]="sortOptions" label="Sort by" [(ngModel)]="sort" (ngModelChange)="getPresentations()"></nv-select>
                    </div>
                </div>
                <div class="nv-column v-middle desk-initial phab-12">
                    <div class="nv-column-content">
                        <nv-input label="Per page" [(ngModel)]="pagination.per_page" (keyup.enter)="getPresentations()"></nv-input>
                    </div>
                </div>
            </div>
            <div class="nv-row config-plates">
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content">
                        <div *ngIf="pagination.total === 0">
                            <div class="nv-empty-state">
                                <div class="img">
                                    <img src="../../../../assets/empty-states/empty-state-presentations.svg" alt="">
                                </div>
                                <h4 class="title">No presentations!</h4>
                                <p class="text">Once you add presentation, you'll see them here.</p>
                                <nv-button (click)="add()">Add presentation</nv-button>
                            </div>
                        </div>
                        <table class="nv-data-table break-lap">
                            <tr *ngFor="let presentation of presentations">
                                <td class="dt-cell nv-clickable" (click)="edit(presentation)">
                                    <label>DATE</label>
                                    <strong>{{ presentation.starts_at | nvDate: 'dd.MM.yyyy' }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-cell nv-clickable" (click)="edit(presentation)" style="min-width: 120px;">
                                    <label>TIME</label>
                                    <strong>{{ presentation.starts_at | nvDate: 'HH:mm' }} - {{ presentation.ends_at | nvDate: 'HH:mm' }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-cell nv-clickable" (click)="edit(presentation)">
                                    <label>TITLE</label>
                                    <strong>{{ presentation.title }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-cell nv-clickable" (click)="edit(presentation)">
                                    <label>SESSION</label>
                                    <strong>{{ presentation.block?.name || '-' }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="di-avatars nv-clickable" (click)="edit(presentation)">
                                    <ng-container *ngFor="let speaker of presentation.speakers; let i = index">
                                        <span *ngIf="i < 3" 
                                            nvBackgroundImage [image]="speaker.image_url_thumb"
                                            title="{{ speaker.name }}">
                                            &nbsp;
                                        </span>
                                    </ng-container>
                                    <span class="more" *ngIf="presentation.speakers.length > 3">
                                        +{{ presentation.speakers.length - 3 }}
                                    </span>
                                </td><!-- /.dt-cell -->
                                <td class="dt-actions">
                                    <div class="nv-cell-actions">
                                    <span class="nv-cell-action-trigger"
                                          [class.on]="optionsShown === presentation.id"
                                          (click)="openOptions(presentation)"></span>
                                        <nav nvOffClick (offClick)="closeOptions()">
                                            <ul>
                                                <li (click)="edit(presentation)">
                                                    <a>Edit</a>
                                                </li>
                                                <li class="delete" (click)="delete(presentation)">
                                                    <a>Delete</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div><!-- /.nv-cell-actions -->
                                </td>
                                <td class="dt-actions nv-clickable" (click)="editInNewTab(presentation)">
                                    <div class="nv-icon" data-icon="&#xe05a;"></div>
                                </td>
                            </tr>
                        </table>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
            <div class="nv-row semi-spaced-top space-between gap-12 propagate" *ngIf="pagination.total > 0">
                <div class="nv-column v-middle desk-6">
                    <div class="nv-column-content">
                        <nv-pagination [config]="pagination" (paginationChange)="getPresentations()"></nv-pagination>
                    </div>
                </div>
                <div class="nv-column v-middle desk-2">
                    <div class="nv-column-content">
                        <nv-input label="Per page" [(ngModel)]="pagination.per_page" (keyup.enter)="getPresentations()"></nv-input>
                    </div>
                </div>
            </div>
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->
</div><!-- /.config-layout-lower-fixed -->
