import { Component, Input, OnInit, OnDestroy, ViewChild, HostBinding } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Presentation } from '@navus/core/classes/presentation';
import { Program } from '@navus/core/classes/program';
import { PresentationService } from '@navus/core/services/presentation.service';
import { LocationService } from '@navus/core/services/location.service';
import { Location } from '@navus/core/classes/location';
import { Block } from '@navus/core/classes/block';
import { ModalService } from '@navus/ui/modal/modal.service';
import { TimeslotDistribution } from '@navus/core/classes/timeslot-distribution';
import { BlockModalComponent } from '../../../website/modals/block/block-modal.component';
import { ConferenceService } from '@navus/core/services/conference.service';
import { Router } from '@angular/router';
import { Author } from '@navus/core/classes/author';
import { AuthorModalComponent } from '../../../website/modals/author/author-modal.component';
import { BookmarkService } from '@navus/core/services/bookmark.service';
import { Subscription } from 'rxjs';
import { Conference } from '@navus/core/classes/conference';
import * as io from 'socket.io-client';
import Echo from 'laravel-echo';
// import { QuestionsComponent } from '@navus/ui/questions/questions.component';

import Pusher from 'pusher-js';

@Component({
  selector: 'nv-streaming-program',
  templateUrl: './streaming-program.component.html'
})

export class StreamingProgramComponent implements OnInit, OnDestroy {
  // @HostBinding('class.questions') class = true;
  // @ViewChild('questions') questions: QuestionsComponent;
  
  @Input() conferenceId: number;
  @Input() locationId: number;
  @Input() livestreams: number[] = [];
  @Input() hideFinished: boolean = false;
  @Input() disableLinks: boolean = false;
  @Input() showSpeakersTab: boolean = false;

  program: Program;
  days: string[] = [];
  selectedDay: string;
  timeslotDistributions: TimeslotDistribution[] = [];
  bookmarks: number[] = [];
  conference: Conference;
  subscriptions: Subscription[] = [];
  location: Location;
  currentPresentation: Presentation;

  now: string = (new Date()).toISOString().replace('T', ' ').substr(0, 16);
  interval;

  loadAutoPilot: boolean = false;
  qaTab: boolean = false;
  programLoading: boolean = false;
  blocksLoading: number[] = [];
  
  autopilot=0;

  LaravelEcho: Echo;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private presentationService: PresentationService,
    private locationService: LocationService,
    private conferenceService: ConferenceService
  ) {}
//current_presentation_id
  ngOnInit() {
    window['Pusher'] = Pusher;
      window['Echo'] = new Echo({
          broadcaster: 'pusher',
          key: `${environment.mix_pusher_app_key}`,
          wsHost: `${environment.mix_pusher_host}`,
          wsPort: `${environment.mix_pusher_port}`,
          wssPort: `${environment.mix_pusher_port}`,
          forceTLS: `${environment.mix_pusher_port === 443}`,
          encrypted: true,
          disableStats: true,
          enabledTransports: ['ws', 'wss']
    });
        
    this.LaravelEcho = window['Echo'];


    this.LaravelEcho
      .channel('location.current_conference_presentation.' + this.conferenceId)
      .listen('.location.changed_current_presentation',
        (data: any) => {
            console.log(1, data);
        }
      );

    this.getProgram();
  }

  ngOnDestroy() {
    //clearInterval(this.interval);

    this.LaravelEcho.disconnect();
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  togglePresentations(block: Block) {
    if (block.presentations) {
      delete block.presentations;
    } else {
      this.getBlockPresentations(block);
    }
  }

  

  showQA() {
      this.qaTab = true;
    // this.bookmarks.push(presentation.id);
  }

  toggleBookmarkPresentation(presentation: Presentation) {
    // this.bookmarks.push(presentation.id);
  }

  showBlockDetails(blockId: number) {
    const modalRef = this.modalService.open(BlockModalComponent);
    modalRef.componentInstance.blockId = blockId;
  }

  getProgram() {
    this.programLoading = true;
    this.conferenceService.getProgram(this.conferenceId)
      .subscribe(
        (response: { data: Program }) => {

          this.program = response.data;
          this.nestProgramElements();



          if (this.days.length > 0) {
            this.selectDay(this.days[0]);
          }
          this.programLoading = false;
        },
        () => {
          this.programLoading = false;
        }
      );
  }

  nestProgramElements() {
    const now = (new Date()).toISOString().replace('T', ' ').substr(0, 16);
    let acceptable: number[] = [];

    //for (let i = 0; i < this.program.timeslots.length ; i++) {
    //  let timeslot = this.program.timeslots[i];

    //  const index = myArray.indexOf(key, 0);
    //    if (index > -1) {
    //       myArray.splice(index, 1);
    //    }
    //  if (this.days.indexOf(timeslot.date) === -1) {
    //      this.days.push(timeslot.date);
    //  }
    //}

    for (const timeslot of this.program.timeslots) {
      if (this.days.indexOf(timeslot.date) === -1 && timeslot.location_id == this.locationId) {
          this.days.push(timeslot.date);
          
      }
      if (timeslot.location_id == this.locationId) {
        acceptable.push(timeslot.id);
        for (const location of this.program.locations) {
          if (location.id == this.locationId) {
            this.location = location;
            this.autopilot = location.autopilot;
          }
          if (timeslot.location_id === location.id) {
            timeslot.location = location;
          }
        }
      }
    }

    this.days = this.days.sort(
      (a, b) => {
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
        });

    for (var i = this.program.timeslot_distributions.length - 1; i >= 0; i--) {
    //for (const timeslot_distribution of this.program.timeslot_distributions) {
      
      if (acceptable.indexOf(this.program.timeslot_distributions[i].timeslot_id)===-1) {
        this.program.timeslot_distributions.splice(i,1);
        continue;
      }

      if (this.program.timeslot_distributions[i].block_id) {
        for (const block of this.program.blocks) {
          if (this.program.timeslot_distributions[i].block_id === block.id) {
            this.program.timeslot_distributions[i].block = block;
          }
        }
      }

      if (this.program.timeslot_distributions[i].presentation_id) {
        for (const presentation of this.program.presentations) {
          if (this.program.timeslot_distributions[i].presentation_id === presentation.id) {
            this.program.timeslot_distributions[i].presentation = presentation;
          }
        }
      }

      if (this.program.timeslot_distributions[i].timeslot_id) {
        for (const timeslot of this.program.timeslots) {
          if (this.program.timeslot_distributions[i].timeslot_id === timeslot.id) {
            this.program.timeslot_distributions[i].timeslot = timeslot;
          }
        }
      }
    }

    this.timeslotDistributions = this.program.timeslot_distributions.sort(
      (a, b) => {
        if (a.starts_at < b.starts_at) {
          return -1;
        } else if (a.starts_at > b.starts_at) {
          return 1;
        } else {
          return 0;
        }
      });
  }

  selectDay(day: string) {
    this.qaTab = false;
    this.selectedDay = day;
  }

  presentationDetails(presentationId: number) {
    if (this.disableLinks) { return; }
    this.router.navigate(['/presentation', presentationId]);
  }

  updateAutopilot(value:number) {
    this.loadAutoPilot=true;
    
    const params: any = {
        autopilot: value
      };
      
    if (value===0) {
      params.current_presentation_id = null;
    }
    
    this.locationService
      .updateConferenceLocation(this.conferenceId, this.locationId, params)
      .subscribe(
        (response: { data: Location }) => {
          this.autopilot = value;
          this.loadAutoPilot=false;
        },
        (error) => {
          this.loadAutoPilot=false;
        }
      );
  }

  changeCurrentStreamingPresentation(presentation: Presentation) {
    this.locationService
      .updateConferenceLocation(this.conferenceId, this.locationId, { current_presentation_id: presentation.id })
      .subscribe(
        (response: { data: Location }) => {
          this.location = response.data;
          this.currentPresentation = presentation;
          console.log(this.location);
        }
      );
  }
  allowSpeakersToJoin(presentation: Presentation) {
    this.locationService
      .updateConferenceLocation(this.conferenceId, this.locationId, { current_presentation_id: presentation.id })
      .subscribe(
        (response: { data: Location }) => {
          this.location = response.data;
          this.currentPresentation = presentation;
          console.log(this.location);
        }
      );
  }

getBlockPresentations(block: Block) {
    this.blocksLoading.push(block.id);
    this.presentationService
      .getConferencePresentations(this.conferenceId, { block_id: block.id, include: 'speakers', with_pagination: 0 })
      .subscribe(
        (response: { data: Presentation[] }) => {
          block.presentations = response.data;

          const index = this.blocksLoading.indexOf(block.id);
          if (index !== -1) {
            this.blocksLoading.splice(index, 1);
          }
        },
        () => {
          const index = this.blocksLoading.indexOf(block.id);
          if (index !== -1) {
            this.blocksLoading.splice(index, 1);
          }
        }
      );
  }
}
