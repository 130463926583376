import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { BannerService } from '@navus/core/services/banner.service';
import { BannerSectionConfig } from './banner-section.config';
import { WebsiteService } from '@navus/core/services/website.service';
import { BaseSectionComponent } from '../_base/base-section.component';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { WebsiteControlService } from '../../services/website-control.service';

@Component({
  selector: 'nv-banner-section',
  templateUrl: './banner-section.component.html'
})
export class BannerSectionComponent extends BaseSectionComponent implements OnInit, OnDestroy {
  @Input() data: WebsitePageSection;
  configuration: BannerSectionConfig = new BannerSectionConfig();
  banner: any;

  showPopup: boolean = false;
  hover: boolean = false;
  interval: any;

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService,
    private elementRef: ElementRef,
    private bannerService: BannerService
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );
  }

  ngOnInit() {
    this.configuration = new BannerSectionConfig(this.data.configuration);
    this.getBanner();
    this.interval = setInterval(() => this.getBanner(), 60000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  getBanner() {
    const params: any = {
      position: this.configuration.position,
    };
    if (this.configuration.conference_id) { params.conference_id = this.configuration.conference_id; }

    this.bannerService.getBanner(params)
      .subscribe(
        (response) => {
          if (JSON.stringify(this.banner) !== JSON.stringify(response.data)) {
            this.banner = response.data;
//            if (this.configuration.conference_id) {
//              this.trackingService
//                .trackConferenceBannerView(this.configuration.conference_id, 'SHOWN', this.banner.id, 'Banner', 'web')
//                .subscribe();
//            } else {
//              this.trackingService
//                .trackBannerView('SHOWN', this.banner.id, 'Banner', 'web')
//                .subscribe();
//            }
          }
        },
        (error) => {
          this.elementRef.nativeElement.remove();
        }
      );
  }

  bannerClick() {
    window.open(this.banner.external_url, '_blank');
//    if (this.configuration.conference_id) {
//      this.trackingService
//        .trackConferenceBannerView(this.configuration.conference_id, 'CLICKED', this.banner.id, 'Banner', 'web')
//        .subscribe();
//    } else {
//      this.trackingService
//        .trackBannerView('CLICKED', this.banner.id, 'Banner', 'web')
//        .subscribe();
//    }
  }

}
