<a class="tsp-close" (click)="close()" title="Close panel"></a>
<a class="tsp-expand" (click)="toggleExpanded()" title="Expand panel"></a>
<header>
    <h4>Section</h4>
</header>
<nav style="padding: 0 20px">
    <form [formGroup]="sectionForm" class="nv-row gap-30 propagate">
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <nv-input
                        formControlName="title"
                        label="Title"
                        required
                ></nv-input>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <nv-select [options]="sectionTypes"
                           formControlName="type"
                           label="Type"
                ></nv-select>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <ng-container [ngSwitch]="sectionForm.value.type">
                    <div *ngSwitchCase="'customHtml'">
                        <nv-custom-html-section-configuration formControlName="configuration"></nv-custom-html-section-configuration>
                    </div>
                    <div *ngSwitchCase="'custom'">
                        <nv-custom-section-configuration formControlName="configuration" [organizationId]="website.organization_id"></nv-custom-section-configuration>
                    </div>
                    <div *ngSwitchCase="'hero'">
                        <nv-hero-section-configuration formControlName="configuration"></nv-hero-section-configuration>
                    </div>
                    <div *ngSwitchCase="'articleList'">
                        <nv-article-list-section-configuration formControlName="configuration"></nv-article-list-section-configuration>
                    </div>
                    <div *ngSwitchCase="'recommendations'">
                        <nv-recommendations-section-configuration formControlName="configuration"></nv-recommendations-section-configuration>
                    </div>
                    <div *ngSwitchCase="'slider'">
                        <nv-slider-section-configuration formControlName="configuration"></nv-slider-section-configuration>
                    </div>
                    <div *ngSwitchCase="'twitter'">
                        <nv-twitter-section-configuration formControlName="configuration"></nv-twitter-section-configuration>
                    </div>
                    <div *ngSwitchCase="'search'">
                        <nv-search-section-configuration formControlName="configuration"></nv-search-section-configuration>
                    </div>
                    <div *ngSwitchCase="'summary'">
                        <nv-summary-section-configuration formControlName="configuration"></nv-summary-section-configuration>
                    </div>
                    <div *ngSwitchCase="'authorList'">
                        <nv-author-list-section-configuration formControlName="configuration"></nv-author-list-section-configuration>
                    </div>
                    <div *ngSwitchCase="'delegateList'">
                        <nv-delegate-list-section-configuration formControlName="configuration"></nv-delegate-list-section-configuration>
                    </div>
                    <div *ngSwitchCase="'sponsorList'">
                        <nv-sponsor-list-section-configuration formControlName="configuration"></nv-sponsor-list-section-configuration>
                    </div>
                    <div *ngSwitchCase="'liveSuite'">
                        <nv-live-suite-section-configuration formControlName="configuration"></nv-live-suite-section-configuration>
                    </div>
                    <div *ngSwitchCase="'liveSuiteIframe'">
                        <nv-live-suite-iframe-section-configuration formControlName="configuration"></nv-live-suite-iframe-section-configuration>
                    </div>
                    <div *ngSwitchCase="'conferenceList'">
                        <nv-conference-list-section-configuration formControlName="configuration"></nv-conference-list-section-configuration>
                    </div>
                    <div *ngSwitchCase="'programPlanner'">
                        <nv-program-planner-section-configuration formControlName="configuration"></nv-program-planner-section-configuration>
                    </div>
                    <div *ngSwitchCase="'banner'">
                        <nv-banner-section-configuration formControlName="configuration"></nv-banner-section-configuration>
                    </div>
                    <div *ngSwitchCase="'promo'">
                        <nv-promo-section-configuration formControlName="configuration"></nv-promo-section-configuration>
                    </div>
                    <div *ngSwitchCase="'leaderboard'">
                        <nv-leaderboard-section-configuration formControlName="configuration"></nv-leaderboard-section-configuration>
                    </div>
                    <div *ngSwitchDefault>
                        <div translate>Unknown section type</div>
                    </div>
                </ng-container>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <nv-button [loading]="savingSection" (click)="save()">Save</nv-button>
                &nbsp;
                <nv-button *ngIf="section?.id" [loading]="deletingSection" color="accent2" (click)="delete()">Delete</nv-button>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
    </form>
</nav>
