import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../../shared/services';

@Injectable()
export class AuthQueryGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const token = route.queryParams.auth || route.queryParams.token;
    if (token) {
      localStorage.setItem('jwtToken', token);

      this.userService.populate();

      this.router.navigate(route.children[0].url.toString().split(','), {
        queryParams: { auth: null },
        queryParamsHandling: 'merge'
      });

      return false;
    }

    return true;
  }
}
