import { NgModule } from '@angular/core';
import { NavusCoreModule } from '../../../navus-core/navus-core.module';
import { NavusUiModule } from '../../../navus-ui/navus-ui.module';
import { SharedModule } from '../../../shared';
import { VotingListPageComponent } from './pages/voting-list/voting-list-page.component';
import { VotingDetailsPageComponent } from './pages/voting-details/voting-details-page.component';
import { VotingQuestionDetailsModalComponent } from './modals/voting-question-details/voting-question-details-modal.component';
import { VotingWindowSelectModalComponent } from './modals/voting-window-select/voting-window-select-modal.component';

@NgModule({
  imports: [
    NavusCoreModule.forRoot(),
    NavusUiModule,
    SharedModule
  ],
  declarations: [
    VotingListPageComponent,
    VotingDetailsPageComponent,
    VotingQuestionDetailsModalComponent,
    VotingWindowSelectModalComponent
  ],
  entryComponents: [
    VotingQuestionDetailsModalComponent,
    VotingWindowSelectModalComponent
  ],
  providers: []
})
export class VotingModule {
}
