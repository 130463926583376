import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { PresentationService } from '@navus/core/services/presentation.service';
import { Presentation } from '@navus/core/classes/presentation';
import { RecommendationsSectionConfig } from './recommendations-section.config';
import { BaseSectionComponent } from '../_base/base-section.component';
import { WebsiteService } from '@navus/core/services/website.service';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { WebsiteControlService } from '../../services/website-control.service';

@Component({
  selector: 'nv-recommendations-section',
  templateUrl: './recommendations-section.component.html'
})
export class RecommendationsSectionComponent extends BaseSectionComponent implements OnInit, OnDestroy {
  @Input() data: WebsitePageSection;
  configuration: RecommendationsSectionConfig = new RecommendationsSectionConfig();

  presentationColumns: Presentation[][] = [];

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService,
    private presentationService: PresentationService
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );
  }

  ngOnInit() {
    this.configuration = new RecommendationsSectionConfig(this.data.configuration);
    this.getRecommendations();
  }

  ngOnDestroy() {

  }

  getRecommendations() {
    const params = {
      limit: this.configuration.limit
    };

    this.presentationService.getRecommendations(params)
      .subscribe(
        (response) => {
          const presentations = response['data'];
          const chunk = Math.round(presentations.length / (this.configuration.maxColumns || 2));

          this.presentationColumns = [];
          for (let j = 0; j < presentations.length; j += chunk) {
            this.presentationColumns.push(presentations.slice(j, j + chunk));
          }

        }
      );
  }

}
