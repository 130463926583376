<div class="nv-template-page">
    <div class="nv-template-top-controls" *ngIf="editMode">
        <div class="ttc-left">
            <div class="ttc-bck" (click)="exitEditMode()">
                <a title="Go back"></a>
            </div><!-- /.ttc-bck -->
            <nav class="ttc-menu">
                <ul>
                    <li>
                        <a class="design"
                           [class.active]="selected === 'design'"
                           (click)="selected = 'design'"
                           title="Design">Design</a>
                    </li>
                    <li>
                        <a class="pages"
                           [class.active]="selected === 'pages'"
                           (click)="selected = 'pages'"
                           title="Pages">Pages</a>
                    </li>
                    <li>
                        <a class="sections"
                           [class.active]="selected === 'sections'"
                           (click)="selected = 'sections'"
                           title="Sections">Section</a>
                    </li>
                </ul>
            </nav><!-- /.ttc-menu -->
        </div><!-- /.ttc-left -->
        <div class="ttc-right">
            <nv-button color="accent2" [loading]="loadingPublish"
                       (click)="togglePublish()">{{ website.published ? 'Unpublish' : 'Publish' }}</nv-button>
        </div>
    </div><!-- /.nv-template-top-controls -->
    <div class="nv-template-content">
        <ng-container *ngIf="editMode">
            <div class="nv-template-side-panel" nv-design-panel
                 [website]="website"
                 (close)="selected = ''"
                 (saved)="reloadWebsite()"
                 [class.on]="selected === 'design'">
            </div>
            <div class="nv-template-side-panel" nv-pages-panel
                 [website]="website"
                 (newPage)="showPage(null)"
                 (editPage)="showPage($event)"
                 (websiteChanged)="reloadWebsite()"
                 (close)="selected = ''"
                 [class.on]="selected === 'pages'">
            </div>
            <div class="nv-template-side-panel" nv-page-panel
                 [website]="website"
                 [page]="selectedPage"
                 (saved)="reloadWebsite()">
            </div>
            <div class="nv-template-side-panel" nv-sections-panel
                 [website]="website"
                 [page]="currentPage"
                 (close)="selected = ''"
                 [class.on]="selected === 'sections'">
            </div>
            <div class="nv-template-side-panel" nv-section-panel
                 [website]="website"
                 [page]="currentPage"
                 (saved)="reloadWebsite()">
            </div>
        </ng-container>

        <ng-container *ngIf="website?.active_conference_id && chatSettings && chatSettings['enabled'] && showChat">
            <div class="chat-holder">
                <nv-public-chat-container 
                    [eventId]="website?.active_conference_id"
                    [allow1on1]="chatSettings['allow1on1']"
                    [allowGroupChat]="chatSettings['allowGroupChat']">
                </nv-public-chat-container>
            </div>            
        </ng-container>


        <div class="nv-template-pane">
            <nv-website-navigation [website]="website" [user]="user"></nv-website-navigation>
            <div class="nv-template-wrap">
                <router-outlet></router-outlet>
                <section *ngIf="showFooter" class="nv-section guttered nv-portal-footer">
                    <div class="nv-port">
                        <div class="nv-row spaced gap-40 v-stretch">
                            <div class="nv-column desk-12">
                                <div class="nv-column-content" [innerHtml]="website?.settings.footer"></div>
                            </div><!-- /.nv-column desk-12 -->
                        </div><!-- /.nv-row -->
                    </div><!-- /.nv-port -->
                </section>
            </div>
        </div><!-- /.nv-template-pane -->
    </div><!-- /.nv-template-content -->
</div><!-- /.nv-template-page -->


