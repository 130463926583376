import { Injectable, OnDestroy, TemplateRef } from '@angular/core';
import { ApiService } from './api.service';
import { ConferenceService } from '@navus/core/services/conference.service';
import { fromEvent, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';

declare var JitsiMeetExternalAPI;

@Injectable()
export class JitsiService implements OnDestroy {
  // JITSI Object
  jitsi: any;

  private readonly DOMAIN = 'meet.navus.io';
  private readonly SCRIPT_URL = 'https://meet.navus.io/external_api.js';
  private readonly DEFAULT_TOOLBAR_FEATURES = ['chat', 'desktop', 'filmstrip', 'microphone', 'camera', 'videoquality', 'fullscreen', 'hangup', 'raisehand', 'tileview'];
  private readonly ADVANCED_TOOLBAR_FEATURES = ['settings', 'security'];
  private DEFAULT_OPTIONS: JitsiOptions = {
    roomName: '',
    width: '100%',
    height: '100%',
    interfaceConfigOverwrite: {
      SHOW_JITSI_WATERMARK: false,
      SHOW_WATERMARK_FOR_GUESTS: false,
      VIDEO_QUALITY_LABEL_DISABLED: true,
      TOOLBAR_BUTTONS: []
    }
  };

  private unsubscribe$: Subject<boolean> = new Subject();

  constructor(
    private apiService: ApiService,
    private conferenceService: ConferenceService,
  ) {
  }

  initJitsi(conferenceId: number, roomName: string, moderator: boolean, sponsor: boolean, demo: boolean, parentNode: TemplateRef<void>) {
    const advancedOptions = moderator || sponsor;
    const addToken = moderator || sponsor || demo;
    const room = this.fixRoomName(roomName);

    const loadScript = this.jitsi ? of(null) : this.loadScriptFromUrl();

    return loadScript
      .pipe(
        mergeMap(() => {
          const apiCall = addToken ? this.conferenceService.getConferencingToken(conferenceId, room) : of(null);

          return apiCall;
        }),
        map((response) => {
          const toolbar = this.generateToolbar(advancedOptions);
          const jitsiOptions = this.generateOptions(room, parentNode, toolbar);
          const token = response?.data?.['token'];
          if (token) {
            jitsiOptions.jwt = token;
          }
          const jitsi = new JitsiMeetExternalAPI(this.DOMAIN, jitsiOptions);
          if (this.jitsi) {
            this.disposeJitsi();
          }
          this.jitsi = jitsi;

          return jitsi;
        })
      );
  }

  ngOnDestroy() {
    this.disposeJitsi();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  startRtmpRecording(streamingKey: string) {
    this.jitsi.executeCommand('startRecording', {
      mode: 'stream', // recording mode, either `file` or `stream`.
      rtmpStreamKey: streamingKey, // the RTMP stream key.
    });
  }

  startYoutubeRecording(streamingKey: string, youtube: string) {
    this.jitsi.executeCommand('startRecording', {
      mode: 'stream', // recording mode, either `file` or `stream`.
      youtubeStreamKey: streamingKey, // the youtube stream key.
      youtubeBroadcastID: youtube // the youtube broadcast ID.
    });
  }

  toggleTile() {
    this.jitsi.executeCommand('toggleTileView');
  }

  focusParticipant(id: number) {
    this.jitsi.setLargeVideoParticipant(id);
  }

  getAllParticipants() {
    return this.jitsi.getParticipantsInfo();
  }

  muteEveryone() {
    this.jitsi.executeCommand('muteEveryone');
  }

  toggleShareScreen() {
    this.jitsi.executeCommand('toggleShareScreen');
  }

  toggleVideo() {
    this.jitsi.executeCommand('toggleVideo');
  }

  toggleAudio() {
    this.jitsi.executeCommand('toggleAudio');
  }

  stopRecording() {
    this.jitsi.executeCommand('stopRecording', 'stream');
  }

  disposeJitsi() {
    this.jitsi.removeEventListener('readyToClose');
    this.jitsi.dispose();
    this.jitsi = null;
  }

  fixRoomName(roomName: string) {
    roomName = roomName.trim();
    return roomName.replace(
      /(\w)(\S*)(\s*)/g, // matches words and spaces
      (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
    );
  }

  private generateToolbar(advancedOptions: boolean) {
    const toolbar = this.DEFAULT_TOOLBAR_FEATURES;
    if (advancedOptions) {
      toolbar.push(...this.ADVANCED_TOOLBAR_FEATURES);
    }
    return toolbar;
  }

  private generateOptions(room: string, parentNode: TemplateRef<void>, toolbar: string[]) {
    const jitsiOptions = this.DEFAULT_OPTIONS;
    jitsiOptions.roomName = room;
    jitsiOptions.parentNode = parentNode;
    jitsiOptions.interfaceConfigOverwrite.TOOLBAR_BUTTONS = toolbar;

    return jitsiOptions;
  }

  private loadScriptFromUrl(): Observable<Event> {
    const scriptPath = this.SCRIPT_URL;

    const scriptEl = document.createElement('script');
    scriptEl.setAttribute('type', 'text/javascript');
    scriptEl.setAttribute('src', scriptPath);

    const scriptLoaded$ = of(null).pipe(
      tap(() => document.body.appendChild(scriptEl)),
      switchMap(() => fromEvent(scriptEl, 'load').pipe(takeUntil(this.unsubscribe$))),
    );
    return scriptLoaded$;
  }
}

interface JitsiOptions {
  roomName: string;
  parentNode?: TemplateRef<void>;
  onload?;
  jwt?: string;
  width: string;
  height: string;
  interfaceConfigOverwrite: {
    SHOW_JITSI_WATERMARK: boolean;
    SHOW_WATERMARK_FOR_GUESTS: boolean;
    VIDEO_QUALITY_LABEL_DISABLED: boolean;
    TOOLBAR_BUTTONS: string[]
  };
}
