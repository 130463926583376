import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ConferenceService } from '@navus/core/services/conference.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'main [nv-registration-settings-page]',
  templateUrl: './registration-settings-page.component.html'
})
export class RegistrationSettingsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;

  conferenceSettingsForm: FormGroup;
  fieldTypeOptions = [
    { id: 'instructions', name: 'Instructions' },
    { id: 'input', name: 'Input' },
    { id: 'checkbox', name: 'Checkbox' },
    { id: 'select', name: 'Select' },
  ];
  fieldWidthOptions = [
    { id: 'desk-12', name: 'Full' },
    { id: 'desk-6', name: 'Medium' },
    { id: 'desk-3', name: 'Small' },
  ];

  loadingConferenceSettings: boolean = true;
  savingConferenceSettings: boolean = false;
  unsavedChanges: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private modalService: ModalService,
    private conferenceService: ConferenceService
  ) { }

  ngOnInit() {
    this.initForm();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getTicketSettings();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.conferenceSettingsForm = this.formBuilder.group({
      ticket: this.formBuilder.group({
        enabled: [false, [Validators.required]],
        enable_payment: [false, [Validators.required]],
        create_delegate: [false, [Validators.required]],
        send_welcome_email: [false, [Validators.required]],
      }),
      delegate_registration: this.formBuilder.group({
        enabled: [false, [Validators.required]],
        ticketing: [false, [Validators.required]],
        ticketing_enable_bank_transfer: [false, [Validators.required]],
        send_welcome_email: [false, [Validators.required]],
      }),
      delegate_registration_fields: this.formBuilder.array([])
    });
  }

  getTicketSettings() {
    this.loadingConferenceSettings = true;
    this.conferenceService
        .getOrganizationConference(this.organizationId, this.conferenceId)
        .subscribe(
          (response) => {
            const conference = response.data;
            this.conferenceSettingsForm.patchValue(conference.settings);
            
            const fields = this.conferenceSettingsForm.controls.delegate_registration_fields as FormArray;

            if (conference.settings.delegate_registration_fields) {
              for (const field of conference.settings.delegate_registration_fields) {
                fields.push(this.formBuilder.group({
                  field: [field.field],
                  title: [field.title],
                  type: [field.type],
                  width: [field.width],
                  options: [field.options],
                  instructions: [field.instructions],
                  mandatory: [field.mandatory]
                }));
              }
            }

            this.loadingConferenceSettings = false;
            setTimeout(() => { this.unsavedChanges = false; }, 500);
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingConferenceSettings = false;
          }
        );
  }


  save() {
    if (this.conferenceSettingsForm.invalid) { return; }

    this.savingConferenceSettings = true;
    const params = { settings: this.conferenceSettingsForm.value };
    this.conferenceService
        .updateOrganizationConference(this.organizationId, this.conferenceId, params)
        .subscribe(
          () => {
            this.toastService.success('Registration settings successfully saved.', {});
            this.unsavedChanges = false;
            this.savingConferenceSettings = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingConferenceSettings = false;
          }
        );
  }

  addField() {
    const fields = this.conferenceSettingsForm.controls.delegate_registration_fields as FormArray;
    const field = this.formBuilder.group({
      field: [''],
      title: [''],
      type: ['input'],
      width: ['desk-12'],
      options: ['Option 1\nOption 2'],
      instructions: [''],
      mandatory: [false]
    });

    fields.push(field);
  }

  removeField(index: number) {
    const fields = this.conferenceSettingsForm.controls.delegate_registration_fields as FormArray;
    fields.removeAt(index);
  }

  trackBy(index: number) {
    return index;
  }

  drop(event: CdkDragDrop<any[]>) {
    let fields = this.conferenceSettingsForm.controls.delegate_registration_fields as FormArray;
    moveItemInArray(fields.value, event.previousIndex, event.currentIndex);
    this.conferenceSettingsForm.controls.delegate_registration_fields.patchValue(fields.value);
  }

}
