import { Material } from './material';

export class Poster {
    id: number;
    code: string;
    title: string;
    author_names: string;
    description: string;
    starts_at: string;
    ends_at: string;
    available: boolean;
    downloadable: boolean;
    print: boolean;
    section: string;
    block_id: number;
    status: string;

    materials: Material[];
    authors: any[];

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

