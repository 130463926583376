<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Application</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-8">
                <nv-button color="primary" iconPl="&#xe910;"
                           [loading]="savingApplication"
                           [disabled]="applicationForm.invalid"
                           [hidden]="!applicationId"
                           (click)="save()" translate>Save</nv-button>
                &nbsp;
                <nv-button color="primary" (click)="delete()" [hidden]="!applicationId" translate>Delete</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div>
</div>
<div class="nv-config-content-port">
    <div class="nv-global-loading on" [class.on]="applicationLoading">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <div class="nv-section" *ngIf="application && applicationId">
        <div class="nv-port">
            <div class="nv-row gap-24 config-plates spaced-bottom clean v-stretch">
                <div class="nv-column desk-6 lap-12">
                    <div class="nv-column-content">
                        <div class="nv-event-app-overview">
                            <h3 class="medium">Your Event App Overview:</h3>
                            <div class="eao-content">
                                <div class="eao-slider">
                                    <nv-slides>
                                        <nv-slide>
                                            <figure><img src="../../assets/app-screens/slide-phone-sample-01.png" alt="screen name"></figure>
                                        </nv-slide>
                                        <nv-slide>
                                            <figure><img src="../../assets/app-screens/slide-phone-sample-02.png" alt="screen name"></figure>
                                        </nv-slide>
                                        <nv-slide>
                                            <figure><img src="../../assets/app-screens/slide-phone-sample-03.png" alt="screen name"></figure>
                                        </nv-slide>
                                        <nv-slide>
                                            <figure><img src="../../assets/app-screens/slide-phone-sample-04.png" alt="screen name"></figure>
                                        </nv-slide>
                                    </nv-slides>
                                </div><!-- /.eao-slider -->

                                <div class="nv-divider desk-none tab-medium"></div>

                                <div class="eao-meta">
                                    <div class="eao-top-right">
                                        <nv-button color="primary"
                                                   size="small"
                                                   [loading]="loadingPublish"
                                                   (click)="togglePublished()" translate
                                        >{{ application.published ? 'Unpublish' : 'Publish' }}</nv-button>
                                        <!--a>Check app before publishing</a-->
                                    </div><!-- /.eao-top-right -->
                                    <div class="eao-chunk">
                                        <label>PUBLISH STATE</label>
                                        <p>Unpublished</p>
                                    </div><!-- /.eao-chunk -->
                                    <div class="eao-chunk">
                                        <label>APP TYPE</label>
                                        <p *ngIf="application.type === 'navus'">Within the Navus App</p>
                                        <p *ngIf="application.type === 'event'">Stand-alone App</p>
                                    </div><!-- /.eao-chunk -->
                                    <div class="eao-chunk">
                                        <label>APP NAME</label>
                                        <p>{{ application.name }}</p>
                                    </div><!-- /.eao-chunk -->
                                    <div class="eao-chunk">
                                        <label>APP FEATURES:</label>
                                        <ul>
                                            <li *ngIf="configurationForm.value.logo">Logo</li>
                                            <li>Interactive Section:
                                                <span *ngIf="configurationForm.value.program">Program, </span>
                                                <span *ngIf="configurationForm.value.speakers">Speakers, </span>
                                                <span *ngIf="configurationForm.value.ematerials">E-materials, </span>
                                                <span *ngIf="configurationForm.value.voting">Voting, </span>
                                                <span *ngIf="configurationForm.value.timeline">Timeline, </span>
                                                <span *ngIf="configurationForm.value.eposters">E-posters, </span>
                                                <span *ngIf="configurationForm.value.notes">My notes.</span>
                                            </li>
                                            <li *ngIf="configurationForm.value.news">News</li>
                                            <li *ngIf="configurationForm.value.recommendations">Recommedantions</li>
                                        </ul>
                                    </div><!-- /.eao-chunk -->
                                    <div class="nv-row gap-24">
                                        <div class="nv-column desk-6">
                                            <div></div>
                                            <div class="eao-chunk">
                                                <label>APP ICON</label>
                                                <img class="eao-icon" [src]="application.logo" alt>
                                            </div><!-- /.eao-chunk -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-6">
                                            <div></div>
                                            <div class="eao-chunk">
                                                <label>COLOR SCHEME</label>
                                                <div class="eao-colors">
                                                    <p>Primary: <span><span [style.background-color]="application.color_primary"></span>{{ application.color_primary }}</span></p>
                                                    <p>Secondary: <span><span [style.background-color]="application.color_primary_dark"></span>{{ application.color_primary_dark }}</span></p>
                                                </div><!-- /.eao-colors -->
                                            </div><!-- /.eao-chunk -->
                                        </div><!-- /.nv-column -->
                                    </div><!-- /.nv-row -->
                                    <div class="eao-chunk">
                                        <br>
                                        <nv-button size="small"
                                                   (click)="editActive = !editActive"
                                        >{{ !editActive ? 'Show application edit' : 'Hide application edit' }}</nv-button>
                                    </div><!-- /.eao-chunk -->
                                </div><!-- /.eao-meta -->
                            </div><!-- /.eao-content -->
                        </div><!-- /.nv-event-app-overview -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->

                <div class="nv-divider desk-none lap-medium"></div>

                <div class="nv-column desk-6 lap-12">
                    <div class="nv-column-content">
                        <div class="awd-teaser nv-apps-logos-qr blue" *ngIf="application.type === 'event'">
                            <div class="awd-teaser-box">
                                <div class="awd-heading-logo">
                                    <img [src]="application.logo" alt>
                                    <h3 class="medium">Download Your Event App</h3>
                                </div><!-- /.awd-heading-logo -->
                                <div class="awd-badges-n-qr" *ngIf="application.ios_link && application.android_link">
                                    <div class="abnq-badges">
                                        <a [href]="application.ios_link" title="App name on AppStore" target="_blank">
                                            <img alt="App name on AppStore" src="../../../../assets/badge-app-store-us-black.png">
                                        </a>
                                        <a [href]="application.android_link" title="App name  on GooglePlay" target="_blank">
                                            <img alt="App name on GooglePlay" src="../../../../assets/badge-google-play-us.png">
                                        </a>
                                    </div><!-- /.abnq-badges -->
                                    <span>or</span>
                                    <div class="abnq-qr" style="width: 120px">
                                        <nv-qrcode [data]="'https://service.e-materials.com/api/organizations/' + organizationId + '/applications/' + applicationId + '/store'" [width]="120"></nv-qrcode>
                                    </div><!-- /.abnq-qr -->
                                </div><!-- /.awd-badges-n-qr -->
                                <div class="awd-badges-n-qr" *ngIf="!application.ios_link || !application.android_link">
                                    <p>The application is not in stores yet.</p>
                                </div>
                            </div><!-- /.awd-teaser-box -->
                        </div><!-- /awd-teaser -->
                        <div class="awd-teaser nv-apps-logos-qr yellow">
                            <div class="awd-teaser-box">
                                <div class="awd-heading-logo">
                                    <img src="../../../../assets/navus-logo-app-rounded.png" alt="Navus app">
                                    <h3 class="medium">Download Navus Event App</h3>
                                </div><!-- /.awd-heading-logo -->
                                <div class="awd-badges-n-qr">
                                    <div class="abnq-badges">
                                        <a title="Navus on AppStore" href="https://apps.apple.com/hr/app/navus/id1496648713" target="_blank">
                                            <img alt="Navus on AppStore" src="../../../../assets/badge-app-store-us-black.png">
                                        </a>
                                        <a title="Navus on GooglePlay" href="https://play.google.com/store/apps/details?id=io.navus.navus" target="_blank">
                                            <img alt="Navus on GooglePlay" src="../../../../assets/badge-google-play-us.png">
                                        </a>
                                    </div><!-- /.abnq-badges -->
                                    <span>or</span>
                                    <div class="abnq-qr" style="width: 120px">
                                        <nv-qrcode [data]="'https://service.e-materials.com/api/applications/navus'" [width]="120"></nv-qrcode>
                                    </div><!-- /.abnq-qr -->
                                </div><!-- /.awd-badges-n-qr -->
                            </div><!-- /.awd-teaser-box -->
                        </div><!-- /awd-teaser -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div>
    </div>
    <div class="nv-section" *ngIf="!application || !applicationId">
        <div class="nv-port">
            <div class="nv-row config-plates clean">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <nv-accordions>
                            <nv-accordion [title]="'Select app type'" (next)="step1done = true" [edit]="!!applicationId" [active]="!applicationId">
                                <form [formGroup]="applicationForm" class="nv-row spaced-top">
                                    <div class="nv-column desk-12">
                                        <div class="nv-column-content">
                                            <div class="nv-ca-padding">
                                                <h3>Welcome to the App Builder!</h3>
                                                <p>First, select one of the options below, then start managing your App features and customise it based on your needs! <span class="nv-info-spot inline" (click)="showAppFeatures = true"></span></p>

                                                <div class="nv-row gap-24 propagate">
                                                    <div class="nv-column desk-6 lap-12">
                                                        <div class="nv-column-content">
                                                            <div class="nv-app-wizard-chunk nv-clickable"
                                                                 (click)="application?.type === 'event' || navusRadio.click()"
                                                                 [class.selected]="applicationForm.value.type === 'navus'">
                                                                <div class="awsc-txt">
                                                                    <div class="nv-form-item nv-radio-item">
                                                                        <label>
                                                                            <span class="nri-radio-port">
                                                                               <input #navusRadio type="radio" value="navus" formControlName="type">
                                                                               <span class="nri-radio-visual"></span>
                                                                            </span><!-- /.nri-radio-port -->
                                                                        </label>
                                                                    </div><!-- /nv-form-item -->
                                                                    <h4>Within The Navus App</h4>
                                                                    <p>
                                                                        Get your event published instantly within Navus App.<br>
                                                                        It is easy, fast and without any extra cost!
                                                                    </p>
                                                                    <div>
                                                                        <br>
                                                                        <br>
                                                                        Watch the video to find out more
                                                                        about our Navus App features!
                                                                        <br>
                                                                        <br>
                                                                        <img (click)="showVideoNavusApp()" src="../../../../assets/video-thumb-navus-app.jpg" alt>
                                                                    </div>
                                                                </div><!-- /.awsc-txt -->
                                                                <div class="nv-app-wizard-slide-chunk">
                                                                    <nv-slides>
                                                                        <nv-slide>
                                                                            <figure><img src="../../assets/app-screens/withinavusapp1.jpg" alt="Navus App"></figure>
                                                                        </nv-slide>
                                                                        <nv-slide>
                                                                            <figure><img src="../../assets/app-screens/withinavusapp2.jpg" alt="Navus App"></figure>
                                                                        </nv-slide>
                                                                        <nv-slide>
                                                                            <figure><img src="../../assets/app-screens/withinavusapp3.jpg" alt="Navus App"></figure>
                                                                        </nv-slide>
                                                                    </nv-slides>
                                                                </div>
                                                            </div><!-- /.nv-app-wizard-slide-chunk -->
                                                        </div><!-- /.nv-column-content -->
                                                    </div><!-- /nv-column -->

                                                    <div class="nv-divider desk-none lap-medium"></div>

                                                    <div class="nv-column desk-6 lap-12">
                                                        <div class="nv-column-content">
                                                            <div class="nv-app-wizard-chunk nv-clickable"
                                                                 (click)="standaloneRadio.click()"
                                                                 [class.selected]="applicationForm.value.type === 'event'">
                                                                <div class="awsc-txt">
                                                                    <div class="nv-form-item nv-radio-item">
                                                                        <label>
                                                                            <span class="nri-radio-port">
                                                                               <input #standaloneRadio type="radio" value="event" formControlName="type">
                                                                               <span class="nri-radio-visual"></span>
                                                                            </span><!-- /.nri-radio-port -->
                                                                        </label>
                                                                    </div><!-- /nv-form-item -->
                                                                    <h4>Your own stand-alone App</h4>
                                                                    <p>
                                                                        Get a dedicated App for your Event.<br>
                                                                        In just a few days your own Event App will be available for downloading on both App Store and Google Play.
                                                                    </p>
                                                                    <div>
                                                                        <br>
                                                                        Watch the video to know more about
                                                                        the stand-alone App features!
                                                                        <br>
                                                                        <br>
                                                                        <img (click)="showStandaloneNavusApp()" src="../../../../assets/video-thumb-standalone-app.jpg" alt>
                                                                    </div>
                                                                </div><!-- /.awsc-txt -->
                                                                <div class="nv-app-wizard-slide-chunk">
                                                                    <nv-slides>
                                                                        <nv-slide>
                                                                            <figure><img src="../../assets/app-screens/standalone1.jpg" alt="Stand alone App"></figure>
                                                                        </nv-slide>
                                                                        <nv-slide>
                                                                            <figure><img src="../../assets/app-screens/standalone2.jpg" alt="Stand alone App"></figure>
                                                                        </nv-slide>
                                                                        <nv-slide>
                                                                            <figure><img src="../../assets/app-screens/standalone3.jpg" alt="Stand alone App"></figure>
                                                                        </nv-slide>
                                                                        <nv-slide>
                                                                            <figure><img src="../../assets/app-screens/standalone4.jpg" alt="Stand alone App"></figure>
                                                                        </nv-slide>
                                                                    </nv-slides>
                                                                </div>
                                                            </div><!-- /.nv-app-wizard-slide-chunk -->
                                                        </div><!-- /.nv-column-content -->
                                                    </div><!-- /nv-column -->
                                                </div><!-- /.nv-row -->

                                                <p>You can switch options before you publish.<br>
                                                    You further have an option to include this event app in your own group app.<br>
                                                    To request this option <a [routerLink]="['/o', organizationId, 'contact', 'mobile-app-hub']"><strong>click here</strong></a>.
                                                </p>

                                                <div class="nv-row gap-24 propagate v-stretch">
                                                    <div class="nv-column desk-6 lap-12">
                                                        <div class="nv-column-content">

                                                            <div class="awd-teaser nv-get-the-app">
                                                                <div class="awd-teaser-box">
                                                                    <h3>Get the bigger picture. Check the Navus Event App.</h3>
                                                                    <p>
                                                                        Search the Navus Event App in your app store or<br>
                                                                        scan QR code to download<br>
                                                                        <br>
                                                                    </p>
                                                                    <div class="nv-apps-badges">
                                                                        <a title="Navus on AppStore" target="_blank" href="https://apps.apple.com/us/app/navus/id1496648713">
                                                                            <img src="../../assets/logo-app-store-black.svg" alt="Navus on AppStore">
                                                                        </a>
                                                                        <a title="Navus on Google Play" target="_blank" href="https://play.google.com/store/apps/details?id=io.navus.navus">
                                                                            <img src="../../assets/logo-google-play-black.svg" alt="Navus on Google Play">
                                                                        </a>
                                                                    </div><!-- /.nv-apps-badges -->
                                                                    <nv-qrcode style="margin-top: 20px;display: block;" [data]="'https://service.e-materials.com/api/applications/navus'" [width]="120"></nv-qrcode>
                                                                </div><!-- /.awd-teaser-box -->
                                                            </div><!-- /.awd-teaser -->
                                                        </div><!-- /.nv-column-content -->
                                                    </div><!-- /nv-column -->

                                                    <div class="nv-column desk-6 lap-12">
                                                        <div class="nv-column-content">
                                                            <div class="awd-teaser sma">
                                                                <div class="awd-teaser-box">
                                                                    <div class="awd-slide">
                                                                        <nv-slides>
                                                                            <nv-slide>
                                                                                <figure><img src="../../assets/app-screens/multievent1.png" alt="Navus App+Multi-event"></figure>
                                                                            </nv-slide>
                                                                            <nv-slide>
                                                                                <figure><img src="../../assets/app-screens/multievent2.png" alt="Navus App+Multi-event"></figure>
                                                                            </nv-slide>
                                                                            <nv-slide>
                                                                                <figure><img src="../../assets/app-screens/multievent3.png" alt="Navus App+Multi-event"></figure>
                                                                            </nv-slide>
                                                                        </nv-slides>
                                                                    </div><!-- /.awd-slide -->
                                                                    <div class="sma-txt">
                                                                        <h3>Stand-alone Multi-event App</h3>
                                                                        <p>
                                                                            Group all your events under one Organisation App.<br>
                                                                            Give great visibility to your events, create a hub for content and keep your audience engaged!
                                                                        </p>
                                                                        <p>
                                                                            <nv-button color="primary" [routerLink]="['/o', organizationId, 'contact', 'mobile-app-hub']">Apply here</nv-button>
                                                                        </p>
                                                                    </div><!-- /.sm-txt -->
                                                                </div><!-- /.awd-teaser-box -->
                                                            </div><!-- /.awd-teaser sma -->
                                                        </div><!-- /.nv-column-content -->
                                                    </div><!-- /.nv-column -->
                                                </div><!-- /.nv-row -->

                                            </div><!-- /.nv-ca-padding -->
                                            <div class="ca-footer">
                                                <div class="nv-tip">
                                                    <p>
                                                        Tip: Do you know that your App includes unlimited Voting?<br>
                                                        Drive Interaction. Save cost and time. No need for another third party solution.
                                                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'voting']" title="Set up Navus voting"><strong>Set up Voting here</strong></a>.
                                                    </p>
                                                </div><!-- /.nv-tip -->
                                            </div><!-- /.ca-footer -->
                                        </div>
                                    </div>
                                </form>
                            </nv-accordion>
                            <nv-accordion [title]="'Set up general info'" (next)="step2done = true" [edit]="!!applicationId">
                                <form [formGroup]="applicationForm" class="nv-row large-spaced flow-center">
                                    <div class="nv-column desk-8 tab-12">
                                        <div class="nv-column-content">
                                            <div class="nv-ca-padding">
                                                <div class="nv-row config-plates clean gap-30 propagate">
                                                    <div class="nv-column desk-12">
                                                        <div class="nv-column-content">
                                                            <nv-input
                                                                    formControlName="name"
                                                                    label="App name (maximum 30 characters)"
                                                                    required
                                                            ></nv-input>
                                                        </div>
                                                    </div>
                                                    <div class="nv-column desk-12">
                                                        <div class="nv-column-content">
                                                            <nv-file
                                                                    [organizationId]="organizationId"
                                                                    [mime]="['image/png','image/jpg','image/jpeg']"
                                                                    formControlName="logo"
                                                                    label="Logo"
                                                            ></nv-file>
                                                        </div>
                                                    </div>
                                                    <div class="nv-column desk-12" *ngIf="applicationForm.value.type !== 'navus'">
                                                        <div class="nv-column-content">
                                                            <nv-input
                                                                    type="textarea"
                                                                    formControlName="description"
                                                                    label="App description"
                                                            ></nv-input>
                                                        </div>
                                                    </div>
                                                </div><!-- /nv-row -->
                                            </div><!-- /.nv-ca-padding -->
                                        </div>
                                    </div>
                                </form>
                            </nv-accordion>
                            <nv-accordion [title]="'Appearance'" (next)="step3done = true" [edit]="!!applicationId">
                                <form [formGroup]="applicationForm" class="nv-row large-spaced flow-center">
                                    <div class="nv-column desk-8 tab-12">
                                        <div class="nv-column-content">
                                            <div class="nv-ca-padding">
                                                <div class="nv-row config-plates clean gap-30 propagate">
                                                    <div class="nv-column desk-12">
                                                        <div class="nv-column-content">
                                                            Select color scheme
                                                        </div>
                                                    </div>
                                                    <div class="nv-column desk-12">
                                                        <div class="nv-column-content">
                                                            <nv-colorpicker
                                                                    formControlName="color_primary"
                                                                    label="Primary color"
                                                                    position="top"
                                                                    required
                                                            ></nv-colorpicker>
                                                        </div>
                                                    </div>
                                                    <div class="nv-column desk-12">
                                                        <div class="nv-column-content">
                                                            <nv-colorpicker
                                                                    formControlName="color_primary_dark"
                                                                    label="Secondary color"
                                                                    position="top"
                                                                    required
                                                            ></nv-colorpicker>
                                                        </div>
                                                    </div>
                                                </div><!-- /nv-row -->
                                            </div><!-- /.nv-ca-padding -->
                                        </div>
                                    </div>
                                </form>
                            </nv-accordion>
                            <nv-accordion [title]="'Set up features'" (next)="step4done = true" [edit]="!!applicationId">
                                <div class="nv-ca-padding">
                                    <form [formGroup]="configurationForm" class="nv-row spaced flow-center">
                                        <div class="nv-column desk-6 tab-12">
                                            <div class="nv-column-content">
                                                <div class="nv-switch-accordion-item">
                                                    <header style="border-bottom: none">
                                                        <div class="sai-left">
                                                            <span>Logo</span>
                                                        </div><!-- /.sai-left -->
                                                        <div class="sai-right">
                                                            <nv-switch formControlName="logo"></nv-switch>
                                                        </div><!-- /.sai-right -->
                                                    </header>
                                                </div><!-- /.nv-switch-accordion-item -->
                                                <div class="nv-switch-accordion-item">
                                                    <header style="border-bottom: none">
                                                        <div class="sai-left">
                                                            <span>Interactive Section</span>
                                                        </div><!-- /.sai-left -->
                                                        <div class="sai-right">

                                                        </div><!-- /.sai-right -->
                                                    </header>
                                                    <div class="nv-switch-accordion-body">
                                                        <div class="nv-row config-plates clean gap-30 propagate" style="padding: 0 0 0 25px">
                                                            <div class="nv-column desk-12">
                                                                <div class="nv-column-content">
                                                                    <nv-checkbox
                                                                            formControlName="program"
                                                                            label="Program"
                                                                    ></nv-checkbox>
                                                                </div>
                                                            </div>
                                                            <div class="nv-column desk-12">
                                                                <div class="nv-column-content">
                                                                    <nv-checkbox
                                                                            formControlName="speakers"
                                                                            label="Speakers"
                                                                    ></nv-checkbox>
                                                                </div>
                                                            </div>
                                                            <div class="nv-column desk-12">
                                                                <div class="nv-column-content">
                                                                    <nv-checkbox
                                                                            formControlName="ematerials"
                                                                            label="E-Materials"
                                                                    ></nv-checkbox>
                                                                </div>
                                                            </div>
                                                            <div class="nv-column desk-12">
                                                                <div class="nv-column-content">
                                                                    <nv-checkbox
                                                                            formControlName="voting"
                                                                            label="Voting"
                                                                    ></nv-checkbox>
                                                                </div>
                                                            </div>
                                                            <div class="nv-column desk-12">
                                                                <div class="nv-column-content">
                                                                    <nv-checkbox
                                                                            formControlName="timeline"
                                                                            label="Timeline"
                                                                    ></nv-checkbox>
                                                                </div>
                                                            </div>
                                                            <div class="nv-column desk-12">
                                                                <div class="nv-column-content">
                                                                    <nv-checkbox
                                                                            formControlName="eposters"
                                                                            label="E-Posters"
                                                                    ></nv-checkbox>
                                                                </div>
                                                            </div>
                                                            <div class="nv-column desk-12">
                                                                <div class="nv-column-content">
                                                                    <nv-checkbox
                                                                            formControlName="notes"
                                                                            label="My notes"
                                                                    ></nv-checkbox>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div><!-- /.nv-switch-accordion-item -->
                                                <div class="nv-switch-accordion-item">
                                                    <header style="border-bottom: none">
                                                        <div class="sai-left">
                                                            <span>Banner</span>
                                                        </div><!-- /.sai-left -->
                                                        <div class="sai-right">
                                                            <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'ad']"
                                                               style="font-size: 12px; font-weight: bold; color: #ED5D5C"
                                                            >Get feature</a>
                                                        </div><!-- /.sai-right -->
                                                    </header>
                                                </div><!-- /.nv-switch-accordion-item -->
                                                <div class="nv-switch-accordion-item">
                                                    <header style="border-bottom: none">
                                                        <div class="sai-left">
                                                            <span>News</span>
                                                        </div><!-- /.sai-left -->
                                                        <div class="sai-right">
                                                            <nv-switch formControlName="news"></nv-switch>
                                                        </div><!-- /.sai-right -->
                                                    </header>
                                                </div><!-- /.nv-switch-accordion-item -->
                                                <div class="nv-switch-accordion-item">
                                                    <header style="border-bottom: none">
                                                        <div class="sai-left">
                                                            <span>Recommendations</span>
                                                        </div><!-- /.sai-left -->
                                                        <div class="sai-right">
                                                            <nv-switch formControlName="recommendations"></nv-switch>
                                                        </div><!-- /.sai-right -->
                                                    </header>
                                                </div><!-- /.nv-switch-accordion-item -->
                                            </div><!-- /nv-column-content -->
                                        </div>
                                    </form>
                                </div><!-- /.nv-ca-padding -->
                            </nv-accordion>
                        </nv-accordions>
                        <div class="nv-row large-spaced flow-center" *ngIf="step4done && !applicationId">
                            <div class="nv-column desk-8 tab-12">
                                <div class="nv-column-content" style="text-align: center">
                                    <h4>You're ready to go!</h4>
                                    <p>You've finished setting up your event application.</p>
                                    <nv-button color="primary" (click)="save()" 
                                                [loading]="savingApplication" 
                                                [disabled]="applicationForm.invalid"
                                    >Finish</nv-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section" *ngIf="application && applicationId && editActive">
        <div class="nv-port">
            <div class="nv-row config-plates">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-row flow-center config-plates clean">
                            <form [formGroup]="applicationForm" class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <div class="nv-ca-padding">
                                        <h3>Welcome to the App Builder!</h3>
                                        <p>First, select one of the options below, then start managing your App features and customise it based on your needs! <span class="nv-info-spot inline" (click)="showAppFeatures = true"></span></p>

                                        <div class="nv-row gap-24 propagate">
                                            <div class="nv-column desk-6 lap-12">
                                                <div class="nv-column-content">
                                                    <div class="nv-app-wizard-chunk nv-clickable"
                                                         (click)="application?.type === 'event' || navusRadio.click()"
                                                         [class.selected]="applicationForm.value.type === 'navus'">
                                                        <div class="awsc-txt">
                                                            <div class="nv-form-item nv-radio-item">
                                                                <label>
                                                                            <span class="nri-radio-port">
                                                                               <input #navusRadio type="radio" value="navus" formControlName="type">
                                                                               <span class="nri-radio-visual"></span>
                                                                            </span><!-- /.nri-radio-port -->
                                                                </label>
                                                            </div><!-- /nv-form-item -->
                                                            <h4>Within The Navus App</h4>
                                                            <p>
                                                                Get your event published instantly within Navus App.<br>
                                                                It is easy, fast and without any extra cost!
                                                            </p>
                                                            <div>
                                                                <br>
                                                                <br>
                                                                Watch the video to find out more
                                                                about our Navus App features!
                                                                <br>
                                                                <br>
                                                                <img (click)="showVideoNavusApp()" src="../../../../assets/video-thumb-navus-app.jpg" alt>
                                                            </div>
                                                        </div><!-- /.awsc-txt -->
                                                        <div class="nv-app-wizard-slide-chunk">
                                                            <nv-slides>
                                                                <nv-slide>
                                                                    <figure><img src="../../assets/app-screens/withinavusapp1.jpg" alt="Navus App"></figure>
                                                                </nv-slide>
                                                                <nv-slide>
                                                                    <figure><img src="../../assets/app-screens/withinavusapp2.jpg" alt="Navus App"></figure>
                                                                </nv-slide>
                                                                <nv-slide>
                                                                    <figure><img src="../../assets/app-screens/withinavusapp3.jpg" alt="Navus App"></figure>
                                                                </nv-slide>
                                                            </nv-slides>
                                                        </div>
                                                    </div><!-- /.nv-app-wizard-slide-chunk -->
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /nv-column -->

                                            <div class="nv-divider desk-none lap-medium"></div>

                                            <div class="nv-column desk-6 lap-12">
                                                <div class="nv-column-content">
                                                    <div class="nv-app-wizard-chunk nv-clickable"
                                                         (click)="standaloneRadio.click()"
                                                         [class.selected]="applicationForm.value.type === 'event'">
                                                        <div class="awsc-txt">
                                                            <div class="nv-form-item nv-radio-item">
                                                                <label>
                                                                            <span class="nri-radio-port">
                                                                               <input #standaloneRadio type="radio" value="event" formControlName="type">
                                                                               <span class="nri-radio-visual"></span>
                                                                            </span><!-- /.nri-radio-port -->
                                                                </label>
                                                            </div><!-- /nv-form-item -->
                                                            <h4>Your own stand-alone App</h4>
                                                            <p>
                                                                Get a dedicated App for your Event.<br>
                                                                In just a few days your own Event App will be available for downloading on both App Store and Google Play.
                                                            </p>
                                                            <div>
                                                                <br>
                                                                Watch the video to know more about
                                                                the stand-alone App features!
                                                                <br>
                                                                <br>
                                                                <img (click)="showStandaloneNavusApp()" src="../../../../assets/video-thumb-standalone-app.jpg" alt>
                                                            </div>
                                                        </div><!-- /.awsc-txt -->
                                                        <div class="nv-app-wizard-slide-chunk">
                                                            <nv-slides>
                                                                <nv-slide>
                                                                    <figure><img src="../../assets/app-screens/standalone1.jpg" alt="Stand alone App"></figure>
                                                                </nv-slide>
                                                                <nv-slide>
                                                                    <figure><img src="../../assets/app-screens/standalone2.jpg" alt="Stand alone App"></figure>
                                                                </nv-slide>
                                                                <nv-slide>
                                                                    <figure><img src="../../assets/app-screens/standalone3.jpg" alt="Stand alone App"></figure>
                                                                </nv-slide>
                                                                <nv-slide>
                                                                    <figure><img src="../../assets/app-screens/standalone4.jpg" alt="Stand alone App"></figure>
                                                                </nv-slide>
                                                            </nv-slides>
                                                        </div>
                                                    </div><!-- /.nv-app-wizard-slide-chunk -->
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /nv-column -->
                                        </div><!-- /.nv-row -->

                                        <p>You can switch options before you publish.<br>
                                            You further have an option to include this event app in your own group app.<br>
                                            To request this option <a [routerLink]="['/o', organizationId, 'contact', 'mobile-app-hub']"><strong>click here</strong></a>.
                                        </p>

                                        <div class="nv-row gap-24 propagate v-stretch">
                                            <div class="nv-column desk-6 lap-12">
                                                <div class="nv-column-content">

                                                    <div class="awd-teaser nv-get-the-app">
                                                        <div class="awd-teaser-box">
                                                            <h3>Get the bigger picture. Check the Navus Event App.</h3>
                                                            <p>
                                                                Search the Navus Event App in your app store or<br>
                                                                scan QR code to download<br>
                                                                <br>
                                                            </p>
                                                            <div class="nv-apps-badges">
                                                                <a title="Navus on AppStore" target="_blank" href="https://apps.apple.com/us/app/navus/id1496648713">
                                                                    <img src="../../assets/logo-app-store-black.svg" alt="Navus on AppStore">
                                                                </a>
                                                                <a title="Navus on Google Play" target="_blank" href="https://play.google.com/store/apps/details?id=io.navus.navus">
                                                                    <img src="../../assets/logo-google-play-black.svg" alt="Navus on Google Play">
                                                                </a>
                                                            </div><!-- /.nv-apps-badges -->
                                                            <nv-qrcode style="margin-top: 20px;display: block;" [data]="'https://service.e-materials.com/api/applications/navus'" [width]="120"></nv-qrcode>
                                                        </div><!-- /.awd-teaser-box -->
                                                    </div><!-- /.awd-teaser -->
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /nv-column -->

                                            <div class="nv-column desk-6 lap-12">
                                                <div class="nv-column-content">
                                                    <div class="awd-teaser sma">
                                                        <div class="awd-teaser-box">
                                                            <div class="awd-slide">
                                                                <nv-slides>
                                                                    <nv-slide>
                                                                        <figure><img src="../../assets/app-screens/multievent1.png" alt="Navus App+Multi-event"></figure>
                                                                    </nv-slide>
                                                                    <nv-slide>
                                                                        <figure><img src="../../assets/app-screens/multievent2.png" alt="Navus App+Multi-event"></figure>
                                                                    </nv-slide>
                                                                    <nv-slide>
                                                                        <figure><img src="../../assets/app-screens/multievent3.png" alt="Navus App+Multi-event"></figure>
                                                                    </nv-slide>
                                                                </nv-slides>
                                                            </div><!-- /.awd-slide -->
                                                            <div class="sma-txt">
                                                                <h3>Stand-alone Multi-event App</h3>
                                                                <p>
                                                                    Group all your events under one Organisation App.<br>
                                                                    Give great visibility to your events, create a hub for content and keep your audience engaged!
                                                                </p>
                                                                <p>
                                                                    <nv-button color="primary" [routerLink]="['/o', organizationId, 'contact', 'mobile-app-hub']">Apply here</nv-button>
                                                                </p>
                                                            </div><!-- /.sm-txt -->
                                                        </div><!-- /.awd-teaser-box -->
                                                    </div><!-- /.awd-teaser sma -->
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /.nv-column -->
                                        </div><!-- /.nv-row -->

                                    </div><!-- /.nv-ca-padding -->
                                </div>
                            </form>
                            <form [formGroup]="applicationForm" class="nv-column desk-8 tab-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="name"
                                                        label="App name (maximum 30 characters)"
                                                        required
                                                ></nv-input>
                                            </div>
                                        </div>
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-file
                                                        [organizationId]="organizationId"
                                                        formControlName="logo"
                                                        label="Logo"
                                                ></nv-file>
                                            </div>
                                        </div>
                                        <div class="nv-column desk-12" *ngIf="applicationForm.value.type !== 'navus'">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        type="textarea"
                                                        formControlName="description"
                                                        label="App description"
                                                ></nv-input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <form [formGroup]="configurationForm" class="nv-column desk-6 tab-12">
                                <div class="nv-column-content">
                                    <div class="nv-switch-accordion-item">
                                        <header style="border-bottom: none">
                                            <div class="sai-left">
                                                <span>Logo</span>
                                            </div><!-- /.sai-left -->
                                            <div class="sai-right">
                                                <nv-switch formControlName="logo"></nv-switch>
                                            </div><!-- /.sai-right -->
                                        </header>
                                    </div><!-- /.nv-switch-accordion-item -->
                                    <div class="nv-switch-accordion-item">
                                        <header style="border-bottom: none">
                                            <div class="sai-left">
                                                <span>Interactive Section</span>
                                            </div><!-- /.sai-left -->
                                            <div class="sai-right">

                                            </div><!-- /.sai-right -->
                                        </header>
                                        <div class="nv-switch-accordion-body">
                                            <div class="nv-row config-plates clean gap-30 propagate" style="padding: 0 0 0 25px">
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <nv-checkbox
                                                                formControlName="program"
                                                                label="Program"
                                                        ></nv-checkbox>
                                                    </div>
                                                </div>
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <nv-checkbox
                                                                formControlName="speakers"
                                                                label="Speakers"
                                                        ></nv-checkbox>
                                                    </div>
                                                </div>
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <nv-checkbox
                                                                formControlName="ematerials"
                                                                label="E-Materials"
                                                        ></nv-checkbox>
                                                    </div>
                                                </div>
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <nv-checkbox
                                                                formControlName="voting"
                                                                label="Voting"
                                                        ></nv-checkbox>
                                                    </div>
                                                </div>
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <nv-checkbox
                                                                formControlName="timeline"
                                                                label="Timeline"
                                                        ></nv-checkbox>
                                                    </div>
                                                </div>
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <nv-checkbox
                                                                formControlName="eposters"
                                                                label="E-Posters"
                                                        ></nv-checkbox>
                                                    </div>
                                                </div>
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <nv-checkbox
                                                                formControlName="notes"
                                                                label="My notes"
                                                        ></nv-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- /.nv-switch-accordion-item -->
                                    <div class="nv-switch-accordion-item">
                                        <header style="border-bottom: none">
                                            <div class="sai-left">
                                                <span>Banner</span>
                                            </div><!-- /.sai-left -->
                                            <div class="sai-right">
                                                <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'ad']"
                                                   style="font-size: 12px; font-weight: bold; color: #ED5D5C"
                                                >Get feature</a>
                                            </div><!-- /.sai-right -->
                                        </header>
                                    </div><!-- /.nv-switch-accordion-item -->
                                    <div class="nv-switch-accordion-item">
                                        <header style="border-bottom: none">
                                            <div class="sai-left">
                                                <span>News</span>
                                            </div><!-- /.sai-left -->
                                            <div class="sai-right">
                                                <nv-switch formControlName="news"></nv-switch>
                                            </div><!-- /.sai-right -->
                                        </header>
                                    </div><!-- /.nv-switch-accordion-item -->
                                    <div class="nv-switch-accordion-item">
                                        <header style="border-bottom: none">
                                            <div class="sai-left">
                                                <span>Recommendations</span>
                                            </div><!-- /.sai-left -->
                                            <div class="sai-right">
                                                <nv-switch formControlName="recommendations"></nv-switch>
                                            </div><!-- /.sai-right -->
                                        </header>
                                    </div><!-- /.nv-switch-accordion-item -->
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="nv-info-modal" *ngIf="showAppFeatures">
    <div class="im-pane">
        <header class="im-header">
            <span class="im-close" (click)="showAppFeatures = false"></span>
            <h3>App Features</h3>
        </header><!-- /.im-header -->
        <div class="im-body">
            <div class="im-content">
                <div class="nv-row large-spaced flow-center">
                    <div class="nv-column desk-6 tab-12">
                        <div class="nv-column-content">
                            <ul>
                                <li>Interactive Program</li>
                                <li>Speaker</li>
                                <li>Attendees</li>
                                <li>E-materials</li>
                                <li>Unlimited Voting</li>
                                <li>My Timeline</li>
                                <li>Sponsors and Exhibitors</li>
                                <li>Advertising spaces</li>
                            </ul>
                        </div>
                    </div>
                    <div class="nv-column desk-6 tab-12">
                        <div class="nv-column-content">
                            <ul>
                                <li>Notes</li>
                                <li>News Section</li>
                                <li>Twitter Feed</li>
                                <li>Unlimited Push Notifications</li>
                                <li>Info Pages</li>
                                <li>AI recommendations</li>
                                <li>Access right/log in</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div><!-- /.im-content -->
        </div><!-- /.im-body -->
    </div><!-- /.im-pane -->
</div><!-- /.nv-info-modal -->
