<h3 style="text-align: center;">Payment method:</h3>

<div class="nv-row spaced-top gap-24 propagate">
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <div class="nv-payment-methods" style="position: relative;">
                <div class="nv-global-loading on" [class.on]="loadingCards">
                    <div class="nv-spinner"></div>
                </div><!-- /.nv-global-loading -->
                <div class="pm-plate" *ngIf="cards.length === 0">
                    <div class="nv-form-item nv-radio-item"
                    (click)="cardRadio.click()"
                    [class.selected]="card === true">
                        <div class="nv-form-item nv-radio-item">
                            <label>
                            <span class="nri-radio-port">
                                <input type="radio"
                                    #cardRadio
                                    name="{{card}}"
                                    [value]=true
                                    [(ngModel)]="card"
                                    />
                                <span class="nri-radio-visual"></span>
                            </span><!-- /.nri-radio-port -->
                            </label>
                        </div>
                    </div><!-- /.pm-radio -->
                    <div style="text-align: center; width: 100%;">
                        <span><b>Pay with card</b></span><br>
                        <span>Please add your card details to proceed.</span>
                    </div>
                </div>
               
                <div class="pm-plate" *ngFor="let card of cards">
                    <div class="nv-cell-actions">
                        <nv-button
                                (click)="deleteCard(card.id)"
                                color="passive"
                                size="small"
                                iconPl="&#xe054;"
                                [onlyIcon]="true"></nv-button>
                    </div><!-- /nv-cell-actions -->
                    <div class="pm-radio" (click)="setDefaultCard(card.id)">
                        <div class="nv-form-item nv-radio-item">
                            <label>
                            <span class="nri-radio-port">
                                <input type="radio" name="type" [checked]="card.default" value=true>
                                <span class="nri-radio-visual"></span>
                            </span><!-- /.nri-radio-port -->
                            </label>
                        </div>
                    </div><!-- /.pm-radio -->
                    <div class="pm-mean">
                        <h4>Credit card number</h4>
                        <p><img src="../../assets/visa-logo-web.png" alt="Visa">**** **** **** {{ card.last4 }}</p>
                    </div><!-- /.pm-mean -->
                    <div class="pm-exp">
                        <h4>EXP. DATE</h4>
                        <p>{{ card.exp_month }}/{{ card.exp_year }}</p>
                    </div><!-- /.pm-exp -->
                </div><!-- /.pm-plate -->
                <div class="pm-plate" *ngIf="conference?.settings?.delegate_registration?.ticketing_enable_bank_transfer">
                    <div class="nv-form-item nv-radio-item"
                        (click)="bankRadio.click()"
                        [class.selected]="card === false">
                        <div class="nv-form-item nv-radio-item">
                            <label>
                            <span class="nri-radio-port">
                                <input type="radio"
                                    #bankRadio
                                    name="{{card}}"
                                    [value]=false
                                    [(ngModel)]="card"
                                    />
                                <span class="nri-radio-visual"></span>
                            </span><!-- /.nri-radio-port -->
                            </label>
                        </div>
                    </div><!-- /.pm-radio -->
                    <div style="text-align: center; width: 100%;">
                        <span><b>Bank transfer</b></span><br>
                        <span *ngIf="!card">The organizer will contact you with all the info needed to proceed with the payment. You will have 48 hours to process the payment after receiving the instructions.</span>
                    </div>
                </div>
            </div><!-- /.nv-payment-methods -->
        </div><!-- /.nv-column-content -->
    </div><!-- /.nv-column -->
    <div class="nv-column desk-12" *ngIf="cards.length === 0">
        <div class="nv-column-content">
            <nv-button 
                color="secondary" 
                class="fw radius-high" 
                (click)="addCard()"
            >Add payment method</nv-button>
        </div><!-- /.nv-column-content -->
    </div><!-- /.nv-column -->
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-button 
                color="secondary" 
                size="large"
                class="fw radius-high"
                [disabled]="card && cards.length === 0"
                (click)="next()"
            >Next</nv-button><!-- /.nv-btn -->
            <div style="text-align: center; margin-top: 20px;">
                <a [routerLink]="['..', 'ticket']">Back</a>
            </div>
        </div><!-- /.nv-column-content -->
    </div><!-- /.nv-column -->
</div>