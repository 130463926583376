export class Block {
    id: number;
    name: string;
    type: string;
    block_category: any;
    block_category_id: number;
    ends_at: string;
    starts_at: string;
    updated_at: string;
    code: string;
    chairperson: string;
    location_id: number;
    featured: boolean;
    has_available_presentation: boolean;
    has_dialog: boolean;
    has_presentation_on_timeline: boolean;
    survey: boolean;
    presentations: any;
    location: any;
    topic_id: number;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
