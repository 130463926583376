<section class="nv-section guttered nv-sponsors-page" [class.edit-mode]="mouseOver">
    <ng-container *ngIf="mouseOver">
        <a class="nv-section-add-top" title="Add section" (click)="createBefore()"></a>
        <a class="nv-section-add-bottom" title="Add section" (click)="createAfter()"></a>
        <div class="nv-section-edit-tool-box">
            <a class="edit" title="Edit section" (click)="edit()"></a>
            <a class="delete" title="Delete section" (click)="delete()"></a>
        </div><!-- /.nv-section-edit-tool-box -->
        <div class="nv-section-sort-tool-box">
            <a class="move-up" title="Move up" (click)="moveUp()"></a>
            <a class="move-down" title="Move down" (click)="moveDown()"></a>
        </div><!-- /.nv-section-sort-tool-box -->
    </ng-container>

    <div class="nv-port">
        <ng-container *ngFor="let sponsorGroup of sponsorGroups">
            <div class="nv-row v-stretch large-spaced-top gap-40 propagate flow-center {{ sponsorGroup.display }}" *ngIf="sponsorGroup.sponsors.length > 0">
                <div class="nv-column desk-12">
                    <div class="nv-column-content" style="text-align: center;">
                        <h2>{{ sponsorGroup.name }}</h2>
                    </div><!-- /.nv-column-content -->
                </div><!-- /nv-column -->
                <ng-container *ngFor="let sponsor of sponsorGroup.sponsors">
                    <div class="nv-column">
                        <div class="nv-column-content">
                            <div class="nv-single-item">
                                <a [routerLink]="['/sponsor', sponsor.id]"></a>
                                <div class="si-logo" nvBackgroundImage [image]="sponsor.logo_url"></div><!-- /.si-logo -->
                                <h4>{{ sponsor.name }}</h4>
                                <p class="type"><span [style.background-color]="sponsorGroup.color"></span>{{ sponsorGroup.name }}</p><!-- platinum / gold / silver / friend -->
                            </div><!-- /.nv-single-item -->
                        </div><!-- /.nv-column-content -->
                    </div><!-- /nv-column -->
                </ng-container>
            </div><!-- /.nv-row -->
        </ng-container>
    </div><!-- /.nv-port -->
</section><!-- /.nv-section -->
