import { Component } from '@angular/core';

@Component({
    selector: 'conifg-initial-states-page',
    templateUrl: './config-initial-states.component.html'
})
export class ConfigInitialStatesComponent {

    constructor() { }

}
