import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})

export class LoadingService {

    private loadingSource = new BehaviorSubject({instances: [], type: null});
    loading = this.loadingSource.asObservable();

    constructor() {}

    start(instance, type: string = '') {
        const config = this.loadingSource.value;
        config.instances.push(instance);
        config.type = type;
        this.loadingSource.next(config);
    }

    stop(instance) {
        const config = this.loadingSource.value;
        const index = config.instances.indexOf(instance);
        if (index !== -1) {
            config.instances.splice(index, 1);
            this.loadingSource.next(config);
        }
    }
}
