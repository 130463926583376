import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Organization } from '../../../navus-core/classes/organization';
import { Website } from '../../../navus-core/classes/website';
import { Conference } from '../../../navus-core/classes/conference';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../navus-core/services/user.service';
import { OrganizationService } from '../../../navus-core/services/organization.service';
import { WebsiteService } from '../../../navus-core/services/website.service';
import { ConferenceService } from '../../../navus-core/services/conference.service';

@Component({
  selector: 'nv-breadcrumbs',
  templateUrl: './breadcrumbs.component.html'
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  @Input() currentPage: string;
  currentOrganization: Organization = null;
  currentWebsite: Website = null;
  currentConference: Conference = null;

  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private organizationService: OrganizationService,
    private websiteService: WebsiteService,
    private conferenceService: ConferenceService

  ) { }

  ngOnInit() {
    const organizationSubscription = this.organizationService.currentOrganization.subscribe(
      (organization: Organization) => { this.currentOrganization = organization; }
    );
    this.subscriptions.push(organizationSubscription);

    const websiteSubscription = this.websiteService.currentWebsite.subscribe(
      (website: Website) => { this.currentWebsite = website; }
    );
    this.subscriptions.push(websiteSubscription);

    const conferenceSubscription = this.conferenceService.currentConference.subscribe(
      (conference: Conference) => { this.currentConference = conference; }
    );
    this.subscriptions.push(conferenceSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

}
