import { Presentation } from './presentation';
import { SafeHtml } from '@angular/platform-browser';

export class Material {
  id: number;
  title: string;
  type: string;
  file_size: number;
  extension: string;
  status: string;
  published_at: string;
  path: string;
  available: boolean;

  external_url: string | SafeHtml;
  accessible: boolean;
  downloadable: boolean;

  errors: any[];

  presentation_id: number;
  presentation?: Presentation;
  attached_presentation_id: number;
  attached_presentation?: Presentation;

  player_preset_id: string;

  slides: any;
  percent_processed?: any;

  mp4_status: 'disabled' | 'preparing' | 'ready' | 'errored';
}
