import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'nv-custom-dashboard-chart-legend',
  templateUrl: './custom-dashboard-chart-legend.component.html',
})
export class CustomDashboardChartLegendComponent {
  @Input() legendItems: { id?: number, name: string, value: string, color?: string, image?: string }[];
  @Input() activeEntries: any[] = [];
  @Input() chartData: { name: string, value: number }[];

  @Input() title: string;
  @Input() description: string;

  @Output() selectItem = new EventEmitter<string>();
  @Output() activateLabel = new EventEmitter<{ name: string }>();
  @Output() deactivateLabel = new EventEmitter<{ name: string }>();

  isLabelActive(item: { name: string }): void {
    return this.activeEntries?.find(s => s.name === item.name);
  }

  legendLabelActivate(item: any): void {
    this.activeEntries = this.chartData?.filter(sd => sd.name === item.name);
    this.activateLabel.emit(item);
  }

  legendLabelDeactivate(item: any): void {
    this.activeEntries = [];
    this.deactivateLabel.emit(item);
  }

  selectRow(rowName: string) {
    this.selectItem.emit(rowName);
  }
}

