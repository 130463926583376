import { Component } from '@angular/core';

@Component({
    selector: 'app-template-front-page',
    templateUrl: './template-front.component.html'
})
export class TemplateFrontComponent {

    constructor() { }

}
