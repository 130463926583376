import { NgModule } from '@angular/core';
import { NavusCoreModule } from '../../../navus-core/navus-core.module';
import { NavusUiModule } from '../../../navus-ui/navus-ui.module';
import { SharedModule } from '../../../shared';
import { CompanyListPageComponent } from './pages/company-list/company-list-page.component';
import { CompanyDetailsPageComponent } from './pages/company-details/company-details-page.component';
import { SponsorGroupListPageComponent } from './pages/sponsor-group-list/sponsor-group-list-page.component';
import { SponsorGroupDetailsPageComponent } from './pages/sponsor-group-details/sponsor-group-details-page.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SponsorSettingsPageComponent } from './pages/sponsor-settings/sponsor-settings-page.component';

@NgModule({
  imports: [
    NavusCoreModule.forRoot(),
    NavusUiModule,
    SharedModule,
    DragDropModule
  ],
  declarations: [
    CompanyListPageComponent,
    CompanyDetailsPageComponent,
    SponsorGroupListPageComponent,
    SponsorGroupDetailsPageComponent,
    SponsorSettingsPageComponent
  ],
  entryComponents: [],
  providers: []
})
export class SponsorsModule {
}
