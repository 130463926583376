import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { CompanyService } from '@navus/core/services/company.service';
import { WebsiteService } from '@navus/core/services/website.service';
import { Website } from '@navus/core/classes/website';
import { SponsorGroupService } from '@navus/core/services/sponsor-group.service';
import { SponsorGroup } from '@navus/core/classes/sponsor-group';

@Component
({
  selector: 'nv-single-list-page',
  templateUrl: './sponsor-list-page.component.html'
})
export class SponsorListPageComponent implements OnInit, OnDestroy {
  conferenceId: number;
  sponsorGroups: SponsorGroup[] = [];

  subscriptions: Subscription[] = [];

  constructor(
    private sponsorGroupService: SponsorGroupService,
    private companyService: CompanyService,
    private websiteService: WebsiteService,
  ) { }

  ngOnInit() {
    const subscription = this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          this.conferenceId = website.active_conference_id;
          this.getCompanies();
        },
        () => { }
      );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getCompanies() {
    combineLatest([
      this.sponsorGroupService
        .getConferenceSponsorGroups(this.conferenceId, {
          sort_by: 'order',
          sort_direction: 'asc',
          with_pagination: 0
        }),
      this.companyService
        .getConferenceSponsors(this.conferenceId, {
          sort_by: 'order',
          sort_direction: 'asc',
          with_pagination: 0
        })
    ])
      .subscribe(
        (response) => {
          const sponsorGroups = response[0].data.sort((a: any, b: any) => (a.display > b.display) ? 1 : -1);
          const sponsors = response[1].data;

          for (const sponsorGroup of sponsorGroups) {
            sponsorGroup.sponsors = [];
            for (const sponsor of sponsors) {
              if (sponsor.sponsor_group_id === sponsorGroup.id) {
                sponsorGroup.sponsors.push(sponsor);
              }
            }
          }

          sponsorGroups.sort((a: SponsorGroup, b: SponsorGroup) => {
            if (a.order < b.order) { return -1; }
            if (a.order > b.order) { return 1; }
            return 0;
          });

          this.sponsorGroups = sponsorGroups;
        }
      );
  }

}
