import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Author } from '@navus/core/classes/author';
import { AuthorService } from '@navus/core/services/author.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';

@Component({
  selector: 'main [nv-author-details-page]',
  templateUrl: './author-details-page.component.html'
})
export class AuthorDetailsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  authorId: number;
  author: Author;
  authorForm: FormGroup;

  loadingAuthor: boolean = true;
  savingAuthor: boolean = false;
  unsavedChanges: boolean = false;

  subscriptions: Subscription[] = [];

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authorService: AuthorService,
    private toastService: ToastService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.initForm();

    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.authorId = data.authorId;
        this.getAuthor();
      }
    );
    this.subscriptions.push(subscription);

    const changeSubscription = this.authorForm.valueChanges.subscribe(
      () => {
        this.unsavedChanges = true;
      }
    );
    this.subscriptions.push(changeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.authorForm = this.formBuilder.group({
      title: [''],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: [''],
      order: [''],
      biography: [''],
      institution: [''],
      image_path: [''],
      featured: [true]
    });
    this.unsavedChanges = false;
  }

  getAuthor() {
    if (this.authorId) {
      this.loadingAuthor = true;
      this.authorService.getConferenceAuthor(this.conferenceId, this.authorId)
        .subscribe(
          (response) => {
            this.author = response['data'];
            this.authorForm.patchValue(this.author);
            this.loadingAuthor = false;
            setTimeout(() => { this.unsavedChanges = false; }, 500);
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingAuthor = false;
          }
        );
    } else {
      this.author = new Author();
      this.loadingAuthor = false;
    }
  }

  save() {
    if (this.authorForm.invalid) { return; }

    this.savingAuthor = true;
    if (this.authorId) {
      this.authorService
        .updateConferenceAuthor(this.conferenceId, this.authorId, this.authorForm.value)
        .subscribe(
          (response) => {
            this.author = response['data'];
            this.toastService.success('Author successfully saved.', {});
            this.unsavedChanges = false;
            this.savingAuthor = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingAuthor = false;
          }
        );
    } else {
      this.authorService
        .createConferenceAuthor(this.conferenceId, this.authorForm.value)
        .subscribe(
          (response) => {
            this.author = response['data'];
            this.toastService.success('Author successfully created.', {});
            this.router.navigate([this.author.id], { relativeTo: this.route });
            this.savingAuthor = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingAuthor = false;
          }
        );
    }
  }

  close() {
    if (this.unsavedChanges) {
      this.modalService.defaultModal({
        title: 'Unsaved changes',
        body: 'Are you sure you want to leave the page without saving changes?',
        size: 'small',
        buttons: [
          {
            text: 'Stay',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Leave',
            color: 'primary',
            handler: () => {
              this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'authors']);
            }
          }
        ]
      });
    } else {
      this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'authors']);
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Author Deletion',
      body: 'Are you sure you want to delete this author?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.authorService.deleteConferenceAuthor(this.conferenceId, this.authorId)
              .subscribe(
                () => {
                  this.close();
                },
                (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

}
