import { Component, HostBinding, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CertificateService } from '@navus/core/services/certificate.service';
import { Certificate } from '@navus/core/classes/certificate';
import { ModalService } from '@navus/ui/modal/modal.service';

@Component({
  selector: 'main [nv-certificate-list-page]',
  templateUrl: './certificate-list-page.component.html'
})
export class CertificateListPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  certificates: Certificate[] = [];

  pagination: { page?: number, perPage: number, total?: number } = {
    perPage: 30
  };
  sort: { field: string, direction: string } = {
    field: 'name',
    direction: 'asc'
  };
  selectedIds: number[] = [];

  certificatesLoading: boolean = false;
  exportingCertificates: boolean = false;
  downloadingCertificatesTemplate: boolean = false;
  optionsShown: number = null;

  formFilter: FormGroup;
  filterPills: any[] = [];
  showFilter: boolean = false;

  subscriptions: Subscription[] = [];

  @ViewChild('progressTemplate', { static: true }) progressTemplate: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private certificateService: CertificateService,
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) {
  }

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      search_term: [''],
    });
    this.formFilter.valueChanges.subscribe(
      (value) => { this.filterChange(value) }
    )
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getCertificates();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getCertificates(append: boolean = false) {
    if (append && this.pagination.total && this.certificates.length >= this.pagination.total) { return; }

    this.pagination.page = append ? this.pagination.page + 1 : 1;

    const params: any = {
      page: this.pagination.page,
      per_page: this.pagination.perPage,
      sort_by: this.sort.field,
      sort_direction: this.sort.direction,
    };
    if (this.formFilter.value.search_term !== '') {
      params.search_term = this.formFilter.value.search_term;
    }
    this.certificatesLoading = !append;
    this.certificateService
      .getConferenceCertificates(this.conferenceId, params)
      .subscribe(
        (response: { data: Certificate[], meta: any }) => {
          if (append) {
            this.certificates = this.certificates.concat(response.data);
          } else {
            const port = document.getElementsByClassName('nv-config-content-port');
            if (port && port.length > 0) {
              port[0].scrollTop = 0;
            }
            
            this.certificates = response.data;
            this.pagination.total = response.meta.pagination.total;
          }
          this.certificatesLoading = false;
        },
        () => {
          this.certificatesLoading = false;
        }
      );
  }

  edit(certificate: Certificate) {
    this.router.navigate(['..', 'certificate', certificate.id], {relativeTo: this.route});
  }

  editInNewTab(certificate: Certificate) {
    window.open(`/o/${this.organizationId}/conference/${this.conferenceId}/attendees/certificate/${certificate.id}`, '_blank');
  }

  close() {
    this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'landing']);
  }

  add() {
    this.router.navigate(['..', 'certificate'], {relativeTo: this.route});
  }

  delete(certificate: Certificate) {
    this.modalService.defaultModal({
      title: 'Certificate Deletion',
      body: 'Are you sure you want to delete this certificate?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.certificateService
              .deleteConferenceCertificate(this.conferenceId, certificate.id)
              .subscribe(
                () => {
                  this.getCertificates();
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

  sortCertificates(field: string) {
    if (field === this.sort.field) {
      this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sort.field = field;
      this.sort.direction = 'asc';
    }
    this.getCertificates();
  }

  toggleSelected(certificate: Certificate) {
    const index = this.selectedIds.indexOf(certificate.id);
    if (index !== -1) {
      this.selectedIds.splice(index, 1);
    } else {
      this.selectedIds.push(certificate.id);
    }
  }

  selectAll() {
    this.selectedIds = [];
    for (const certificate of this.certificates) {
      this.selectedIds.push(certificate.id);
    }
  }

  unselectAll() {
    this.selectedIds = [];
  }

  openOptions(certificate: Certificate) {
    event.stopPropagation();
    this.optionsShown = certificate.id;
  }

  closeOptions() {
    event.stopPropagation();
    this.optionsShown = null;
  }

  filterChange(value) {
    this.filterPills = [];

    this.getCertificates();
  }

  filterRemove(filterPill) {
    if (filterPill.type === 'ticketType') {
      this.formFilter.get('conference_ticket_type_id').setValue(null);
    }
    if (filterPill.type === 'status') {
      this.formFilter.get('status').setValue(null);
    }
    if (filterPill.type === 'country') {
      let countries = this.formFilter.get('country_ids').value || [];
      countries = countries.filter(c => c != filterPill.id);
      this.formFilter.get('country_ids').setValue(countries);
    }
  }

}
