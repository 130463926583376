import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalService } from '../../../../../navus-ui/modal/modal.service';
import { ToastService } from '../../../../../navus-ui/toast/toast.service';
import { BadgeService } from '@navus/core/services/badge.service';
import { Badge } from '@navus/core/classes/badge';

@Component({
  selector: 'main [nv-badge-list-page]',
  templateUrl: './badge-list.component.html'
})
export class BadgeListPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  badges: Badge[] = [];

  pagination: { page?: number, perPage: number, total?: number } = {
    perPage: 30
  };
  sort: { field: string, direction: string } = {
    field: 'name',
    direction: 'asc'
  };
  selectedIds: string[] = [];

  badgesLoading: boolean = false;
  optionsShown: String = null;
  
  formFilter: FormGroup;

  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private badgeService: BadgeService,
    private router: Router,
    private toastService: ToastService,
    private modalService: ModalService,
  ) { }

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      search_term: [''],
    });
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = +data.organizationId;
        this.conferenceId = +data.conferenceId;
        this.getBadges();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getBadges(append: boolean = false) {
    if (append && this.pagination.total && this.badges.length >= this.pagination.total) { return; }

    this.pagination.page = append ? this.pagination.page + 1 : 1;

    const params: any = {
      include: 'badge_levels',
      page: this.pagination.page,
      per_page: this.pagination.perPage,
      sort_by: this.sort.field,
      sort_direction: this.sort.direction,
    };
    if (this.formFilter.value.search_term !== '') {
      params.search_term = this.formFilter.value.search_term;
    }
    this.badgesLoading = !append;
    this.badgeService
      .getConferenceBadges(this.conferenceId, params)
      .subscribe(
        (response: { data: Badge[], meta: any }) => {
          if (append) {
            this.badges = this.badges.concat(response.data);
          } else {
            const port = document.getElementsByClassName('nv-config-content-port');
            if (port && port.length > 0) {
              port[0].scrollTop = 0;
            }

            this.badges = response.data;
            this.pagination.total = response.meta.total;
          }
          this.badgesLoading = false;
        },
        () => {
          this.badgesLoading = false; 
        }
      );
  }

  edit(badge: Badge) {
    this.router.navigate(['..', 'badge', badge.id], { relativeTo: this.route });
  }

  close() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  add() {
    this.router.navigate(['..', 'badge'], { relativeTo: this.route });
  }

  delete(badge: Badge) {
    this.modalService.defaultModal({
      title: 'Push Badge Deletion',
      body: 'Are you sure you want to delete this badge?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.badgeService
              .deleteConferenceBadge(this.conferenceId, badge.id)
              .subscribe(
                () => {
                  this.getBadges();
                },
                (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

  selectAll() {
    this.selectedIds = [];
    for (const badge of this.badges) {
      this.selectedIds.push(badge.id);
    }
  }

  unselectAll() {
    this.selectedIds = [];
  }

  openOptions(badge: Badge) {
    event.stopPropagation();
    this.optionsShown = badge.id;
  }

  closeOptions() {
    event.stopPropagation();
    this.optionsShown = null;
  }

}
