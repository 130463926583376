import { Component } from '@angular/core';

@Component({
    selector: 'app-coming-soon-config',
    templateUrl: './coming-soon-config.component.html'
})
export class ComingSoonConfigComponent  {

    constructor() { }

}
