import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { from } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class TrackingService {
  paused: boolean = false;
  trackingStack: any[] = [];
  trackingInterval: any;
  trackingIntervalSeconds: number = 60;

  creatingVisitor: boolean = false;

  // TODO Make a stack, use localStorage, have expiration

  constructor(
    private firestore: AngularFirestore
  ) {
    this.paused = document.visibilityState === 'hidden';
    this.trackingInterval = setInterval(() => this.tick(), this.trackingIntervalSeconds * 1000)   
  }

  get timestamp() {
    return firebase.default.firestore.Timestamp.now().toMillis();
  }

  // Get or generate Guid
  get uuid() {
    let uuid = localStorage.getItem('uuid');
    let visitorCreated = localStorage.getItem('uuid.createdAt');
    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem('uuid', uuid);
    }
    if (!visitorCreated && !this.creatingVisitor) {
      console.log('visitor.create start')
      this.creatingVisitor = true;
      this.firestore
        .collection(`visitors`)
        .doc(uuid)
        .set({ timestamp: this.timestamp }, { merge: true })
        .then(
          () => {
            localStorage.setItem('uuid.createdAt', this.timestamp.toString());
            console.log('visitor.create end [success]')
            this.creatingVisitor = false;
          },
          (e) => {
            console.log(e);
            console.log('visitor.create end [error]')
            this.creatingVisitor = false;
          }
        );      
    }
    return uuid;
  }

  // Connect user and uuid
  async identify(userId: number) {
    if (!environment.trackingEnabled) { return; }
    if (localStorage.getItem('uuid.identifiedAt')) { return; }

    
    let uuid = localStorage.getItem('uuid');
    let visitorCreated = localStorage.getItem('uuid.createdAt');
    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem('uuid', uuid);
    }
    if (!visitorCreated && !this.creatingVisitor) {
      console.log('visitor.create start')
      this.creatingVisitor = true;
      await this.firestore
        .collection(`visitors`)
        .doc(uuid)
        .set({ timestamp: this.timestamp }, { merge: true })
        .then(
          () => {
            localStorage.setItem('uuid.createdAt', this.timestamp.toString());
            console.log('visitor.create end [success]')
            this.creatingVisitor = false;
          },
          (e) => {
            console.log(e);
            console.log('visitor.create end [error]')
            this.creatingVisitor = false;
          }
        );      
    }

    const params = {
      user_id: userId,
      browser: navigator.userAgent,
      timestamp: this.timestamp,
    };

    console.log('visitor.identify start')
    return this.firestore
      .collection(`visitors`)
      .doc(uuid)
      .set(params, { merge: true })
      .then(
        () => {
          localStorage.setItem('uuid.identifiedAt', this.timestamp.toString());
          console.log('visitor.identify end [success]')
        },
        (e) => {
          console.log(e);
          console.log('visitor.identify end [error]')
        }
      );

  }

  // Send action to firebase
  sendAction(
    params: {
      entity_type: string,
      entity_id: any,
      action_uuid: string,
      action_name: string,
      action_type: string,
      conference_id: number,
      timestamp?: any,
      additional_info?: any,
    }
  ) {
    params.timestamp = this.timestamp;

    if (environment.trackingLog) {
      console.log(
        `%cTRACK - ${params.action_type}:${params.action_name||''} - ${params.entity_type}:${params.entity_id||''} - ${params.action_uuid}`, 
        `color: ${this.stringToColour(params.entity_type + params.entity_id)}`
      );
    }

    if (environment.trackingEnabled) {
      return from(
        this.firestore
          .collection(`visitors/${this.uuid}/actions`)
          .doc(uuidv4())
          .set(params)
      );
    } else {
      return;
    }

  }

  shownTracking(conference_id: number, entity_type: string, entity_id: any, additional_info?: any): void {
    this.sendAction({
      entity_type: entity_type,
      entity_id: entity_id,
      action_uuid: uuidv4(),
      action_name: null,
      action_type: 'SHOWN',
      conference_id: conference_id,
      additional_info: additional_info || null,
    });  
  }

  clickTracking(conference_id: number, entity_type: string, entity_id: any, additional_info?: any): void {
    this.sendAction({
      entity_type: entity_type,
      entity_id: entity_id,
      action_uuid: uuidv4(),
      action_name: null,
      action_type: 'CLICK',
      conference_id: conference_id,
      additional_info: additional_info || null,
    });  
  }

  countTracking(conference_id: number, entity_type: string, entity_id: any, action_type: any, additional_info?: any): void {
    this.sendAction({
      entity_type: entity_type,
      entity_id: entity_id,
      action_uuid: uuidv4(),
      action_name: 'COUNT',
      action_type: action_type,
      conference_id: conference_id,
      additional_info: additional_info || null,
    });  
  }

  startTracking(conference_id: number, entity_type: string, entity_id: any, action_type: string, additional_info?: any): void {
    // Ignore if already exists
    const isTracking = this.trackingStack.find(
      action => 
      action.entity_type === entity_type &&
      action.entity_id === entity_id &&
      action.action_type === action_type &&
      action.conference_id === conference_id
    );
    if (isTracking) {
      if (environment.trackingLog) {
        console.info(`TRACK - Tried to track object that is already tracked`);
      }
      return;
    }

    // Only one tracking of one action type can exist, stopping previous action automatically
    const similar = this.trackingStack.find(
      action => 
      action.action_type === action_type &&
      action.conference_id === conference_id
    );
    if (similar) {
      if (environment.trackingLog) {
        console.info(`TRACK - Automatically ended: ${similar.entity_type}, ${similar.entity_id}`);
      }
      this.stopTracking(similar.conference_id, similar.entity_type, similar.entity_id, similar.action_type);
    }

    const uuid = uuidv4();

    // Send tracking start if not paused, paused will be send after resuming
    if (!this.paused) {
      this.sendAction({
        entity_type: entity_type,
        entity_id: entity_id,
        action_uuid: uuid,
        action_name: ActionName.START,
        action_type: action_type,
        conference_id: conference_id,
        additional_info: additional_info || null,
      });      
    }

    // Add to stack (start PING)
    this.trackingStack.push({
      entity_type: entity_type,
      entity_id: entity_id,
      action_uuid: uuid,
      action_type: action_type,
      conference_id: conference_id,
      additional_info: additional_info || null,
    });
  }

  stopTracking(conference_id: number, entity_type: string, entity_id: any, action_type: string, additional_info?: any): void {
    // Find action uuid in tracking stack
    const action = this.trackingStack.find(
      action =>
      action.entity_type === entity_type && action.entity_id === entity_id && action.action_type === action_type
    )
    const uuid = action?.action_uuid || null;

    // Send tracking end if not paused, paused will not be resumed
    if (!this.paused) {
      this.sendAction({
        entity_type: entity_type,
        entity_id: entity_id,
        action_uuid: uuid,
        action_name: ActionName.END,
        action_type: action_type,
        conference_id: conference_id,
        additional_info: additional_info || null,
      });      
    }
    // Remove from stack (end PING)
    this.trackingStack = this.trackingStack.filter(
      action => 
      !(action.entity_type === entity_type && action.entity_id === entity_id && action.action_type === action_type)
    );
  }

  stopTrackingAction(conference_id: number, action_type: string): void {
    const similar = this.trackingStack.filter(
      action => 
      action.action_type === action_type &&
      action.conference_id === conference_id
    );
    similar.forEach((action) => {
      this.stopTracking(action.conference_id, action.entity_type, action.entity_id, action.action_type);
    });
  }

  pingTracking(conference_id: number, entity_type: string, entity_id: any, action_type: string, additional_info?: any): void {
    // Find action uuid in tracking stack
    const action = this.trackingStack.find(
      action =>
      action.entity_type === entity_type && action.entity_id === entity_id && action.action_type === action_type
    )
    const uuid = action?.action_uuid || null;

    // Send tracking ping if not paused
    if (!this.paused) {
      this.sendAction({
        entity_type: entity_type,
        entity_id: entity_id,
        action_uuid: uuid, 
        action_name: ActionName.PING,
        action_type: action_type,
        conference_id: conference_id,
        additional_info: additional_info || null,
      });      
    }
  }

  pauseTracking(): void {
    this.paused = true;
    clearInterval(this.trackingInterval);
    // Sent end for all in stack
    this.trackingStack.forEach(action => {
      action.action_name = ActionName.END;
      this.sendAction(action);
    })
  }

  resumeTracking(): void {
    this.paused = false;
    this.trackingInterval = setInterval(() => this.tick(), this.trackingIntervalSeconds * 1000)   
    // Sent end for all in stack
    this.trackingStack.forEach(action => {
      action.action_name = ActionName.START;
      action.action_uuid = uuidv4();
      this.sendAction(action);
    })
  }

  unloadTracking(): void {
    // Sent end for all in stack
    this.trackingStack.forEach(action => {
      action.action_name = ActionName.END;
      this.sendAction(action);
    })
    // Empty stack
    this.trackingStack = [];
  }

  tick(): void {
    // Ping all in stack if not paused
    if (!this.paused) {
      this.trackingStack.forEach(action => {
        action.action_name = ActionName.PING;
        this.sendAction(action);
      })       
    }
  }

  stringToColour = (str) => {
    var colors = ["#e51c23", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#5677fc", "#03a9f4", "#00bcd4", "#009688", "#259b24", "#8bc34a", "#afb42b", "#ff9800", "#ff5722", "#795548", "#607d8b"]
	
    var hash = 0;
	  if (str.length === 0) return hash;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    hash = ((hash % colors.length) + colors.length) % colors.length;
    return colors[hash];
  }

}

enum ActionName {
  START = 'START',
  PING = 'PING',
  END = 'END',
  COUNT = 'COUNT',
}
enum ActionType {
  WATCHING = 'WATCHING',
  BROWSING = 'BROWSING',
  SHOWN = 'SHOWN',
  MEETING = 'MEETING',
  MESSAGE = 'MESSAGE',
  FIRST_MESSAGE = 'FIRST_MESSAGE',
}