<div class="nv-config-layout">
    <aside class="nv-config-aside"><!-- < toggle class 'hidden' -->
        <div class="nv-config-vertical-bar-left">
            <div class="nv-config-logo"><img src="../../assets/navus-textless-logo.svg" alt="Navus CH"></div>
            <div class="nv-vertical-tools">
                <div class="nv-config-side-tools">
                    <div class="cst-item settings">
                        <a></a>
                        <span>Configurator Settings</span>
                    </div><!-- /.cst-item -->
                </div><!-- /.nv-config-side-tools -->
                <div class="nv-vt-profile">
                    <div class="vtp-avatar"><span style="background-image: url('../../assets/avatar-dummy.jpg');"></span></div> <!-- < toggle class 'on' -->
                    <div class="vtp-options-box">
                        <div class="vtp-option-chunk">
                            <h4>YOUR ORGANIZATION</h4>
                            <nav>
                                <ul>
                                    <li><a href="">Settings</a></li>
                                    <li><a href="">Billing & Subscription</a></li>
                                    <li><a href="">Manage Users</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                        <div class="vtp-option-chunk">
                            <h4>YOUR PROFILE</h4>
                            <nav>
                                <ul>
                                    <li><a href="">User Profile</a></li>
                                    <li><a href="">Privacy</a></li>
                                    <li><a href="">Feedback</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                        <div class="vtp-option-chunk">
                            <nav>
                                <ul>
                                    <li><a href="">Sign Out</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                    </div><!-- /.vtp-options-box -->
                </div><!-- /.nv-vt-profile -->
            </div><!-- /.nv-vertical-tools -->
        </div><!-- /.nv-config-vertical-bar-left -->
        <div class="nv-config-menu">
            <div class="cm-instance active">
                <div class="nv-cm-static">
                    <div class="cm-organization">
                        <div class="cm-current-organization"> <!-- < toggle class 'on' -->
                            <div class="cm-organization-logo">
                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                            </div><!-- /.cm-organization-logo -->
                            <div class="cm-organization-label">
                                <h3>ENP</h3>
                                <p>Organization</p>
                            </div><!-- /.cm-organization-label -->
                        </div><!-- /.cm-current-organisation -->
                        <div class="cm-organisation-list">
                            <div class="cm-organization-search">
                                <input type="text" name="" placeholder="Search for organizations…">
                            </div><!-- /.cm-organization-search -->
                            <nav>
                                <ul>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                </ul>
                            </nav>
                            <a href="" class="cm-add">
                                Add organization
                            </a><!-- /.cm-add -->
                        </div><!-- /.cm-organisation-list -->
                    </div><!-- /.cm-organisation -->
                    <div class="cm-bck-link">
                        <a href>Back to events</a>
                    </div><!-- /.cm-bck-link -->
                </div><!-- /.nv-cm-static -->
                <div class="nv-cm-scrollable">
                    <div class="nv-config-navs">
                        <nav>
                            <ul>
                                <li>
                                    <a href>Landing</a>
                                </li>
                                <li>
                                    <a href>Web Portal</a>
                                </li>
                                <li>
                                    <a href>Mobile Applications</a>
                                </li>

                                <li>
                                    <a href class="active">Program</a>
                                </li>
                                <li>
                                    <a href>Speakers</a>
                                </li>
                                <li>
                                    <a href>Locations / Rooms / Tracks</a>
                                </li>
                                <li>
                                    <a href>Sponsors & Exhibitors</a>
                                </li>
                                <li>
                                    <a href>E-materials</a>
                                </li>
                                <li>
                                    <a href>Voting</a>
                                </li>
                                <li>
                                    <a href>Push Notifications</a>
                                </li>
                                <li>
                                    <a href>Ad Platform<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>AI<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>Dashboard</a>
                                </li>
                            </ul>
                        </nav>
                        <h4>Special features</h4>
                        <nav>
                            <ul>
                                <li>
                                    <a href>Lead Capture</a>
                                </li>
                                <li>
                                    <a href>Consent Application</a>
                                </li>
                                <li>
                                    <a href>E-posters</a>
                                </li>
                            </ul>
                        </nav>
                    </div><!-- /.nv-config-navs -->
                </div><!-- /.nv-cm-scrollable -->
            </div><!-- /.cm-instance -->
            <div class="cm-instance">
                <div class="nv-cm-static">
                    <div class="cm-organization">
                        <div class="cm-current-organization on"> <!-- < toggle class 'on' -->
                            <div class="cm-organization-logo">
                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                            </div><!-- /.cm-organization-logo -->
                            <div class="cm-organization-label">
                                <h3>ENP</h3>
                                <p>Organization</p>
                            </div><!-- /.cm-organization-label -->
                        </div><!-- /.cm-current-organisation -->
                        <div class="cm-organisation-list">
                            <div class="cm-organization-search">
                                <input type="text" name="" placeholder="Search for organizations…">
                            </div><!-- /.cm-organization-search -->
                            <nav>
                                <ul>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                </ul>
                            </nav>
                            <a href="" class="cm-add">
                                Add organization
                            </a><!-- /.cm-add -->
                        </div><!-- /.cm-organisation-list -->
                    </div><!-- /.cm-organisation -->
                    <div class="cm-bck-link">
                        <a href>Back to events</a>
                    </div><!-- /.cm-bck-link -->
                </div><!-- /.nv-cm-static -->
                <div class="nv-cm-scrollable">
                    <div class="nv-config-navs">
                        <nav>
                            <ul>
                                <li>
                                    <a href>Landing</a>
                                </li>
                                <li>
                                    <a href>Web Portal</a>
                                </li>
                                <li>
                                    <a href>Mobile Applications</a>
                                </li>

                                <li>
                                    <a href class="active">Program</a>
                                </li>
                                <li>
                                    <a href>Speakers</a>
                                </li>
                                <li>
                                    <a href>Locations / Rooms / Tracks</a>
                                </li>
                                <li>
                                    <a href>Sponsors & Exhibitors</a>
                                </li>
                                <li>
                                    <a href>E-materials</a>
                                </li>
                                <li>
                                    <a href>Voting</a>
                                </li>
                                <li>
                                    <a href>Push Notifications</a>
                                </li>
                                <li>
                                    <a href>Ad Platform<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>AI<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>Dashboard</a>
                                </li>
                            </ul>
                        </nav>
                        <h4>Special features</h4>
                        <nav>
                            <ul>
                                <li>
                                    <a href>Lead Capture</a>
                                </li>
                                <li>
                                    <a href>Consent Application</a>
                                </li>
                                <li>
                                    <a href>E-posters</a>
                                </li>
                            </ul>
                        </nav>
                    </div><!-- /.nv-config-navs -->
                </div><!-- /.nv-cm-scrollable -->
            </div><!-- /.cm-instance -->
        </div><!-- /.nv-config-menu -->
    </aside><!-- /.nv-config-aside -->
    <main class="nv-config-content">
        <div class="nv-config-control-bar">
            <div class="nv-layout-collapse-toggler"></div> <!-- toggle class 'on' -->
            <p class="nv-config-bread-crumbs">
                <span><a>SEHH</a></span>
                <span>Events</span>
            </p><!-- /.nv-config-bread-crumbs -->
            <h1>Events</h1>
        </div><!-- /.nv-config-control-bar -->
        <div class="nv-config-content-port">
            <section class="nv-section">
                <div class="nv-port">
                    <div class="nv-row config-plates gap-24 clean propagate">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <div class="nv-info-bar warning"> <!-- alt = error, success, / -->
                                    <span class="ib-close"></span>
                                    <p>Your card expirts in 32 days. Please <a href>update your card details</a>.</p>
                                </div><!-- /.nv-info-bar -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div><!-- /nv-row -->
                    <div class="nv-row config-plates gap-24">
                        <div class="nv-column desk-3 v-top">
                            <div class="nv-column-content">
                                <div class="nv-current-plan">
                                    <h3 class="pale">Your Plan</h3>
                                    <div class="nv-plan-status">
                                        <h4>Advanced</h4>
                                        <p>
                                            <strong>SUBSCRIBED</strong>
                                            <span>Next charge on 3/1/20</span>
                                        </p>
                                    </div><!-- /.nv-plan-status -->
                                    <div class="nv-plan-price">
                                        <h4>&euro; 99<sub>/ mo</sub></h4>
                                        <p>billed monthly</p>
                                    </div><!-- /.nv-plan-status -->
                                    <p class="nv-plan-links">
                                        <a>View plan details</a>
                                        <br>
                                        <a>Switch to annual billing</a>
                                    </p>
                                </div><!-- /.nv-current-plan -->
                                <div class="nv-btn-box flow-end gap-8">
                                    <a class="nv-btn filled small neutral">Cancel Plan</a>
                                    <a class="nv-btn filled small accent1">Change plan</a>
                                </div><!-- /.nv-btn-box flow-end gap-6 -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                        <div class="nv-column desk-9">
                            <div class="nv-column-content">
                                <h3 class="pale">Manage Payment Methods</h3>
                                <div class="nv-payment-methods">
                                    <div class="pm-plate">
                                        <div class="nv-cell-actions">
                                            <span class="nv-cell-action-trigger"></span>
                                            <nav>
                                                <ul>
                                                    <li><a>Edit</a></li>
                                                    <li><a>Set as primary</a></li>
                                                    <li class="delete"><a>Delete Credit Card</a></li>
                                                </ul>
                                            </nav>
                                        </div><!-- /nv-cell-actions -->
                                        <div class="pm-radio">
                                            <div class="nv-form-item nv-radio-item">
                                                <label>
                                                   <span class="nri-radio-port">
                                                       <input type="radio" name="type">
                                                       <span class="nri-radio-visual"></span>
                                                   </span><!-- /.nri-radio-port -->
                                                </label>
                                            </div>
                                        </div><!-- /.pm-radio -->
                                        <div class="pm-mean">
                                            <h4>Credit card number</h4>
                                            <p><img src="../../assets/visa-logo-web.png" alt="Visa">**** 0955</p>
                                        </div><!-- /.pm-mean -->
                                        <div class="pm-exp">
                                            <h4>EXP. DATE</h4>
                                            <p>01/20</p>
                                        </div><!-- /.pm-exp -->
                                        <div class="pm-primary">
                                            <h4>PRIMARY</h4>
                                            <p>YES</p>
                                        </div><!-- /.pm-primary -->
                                    </div><!-- /.pm-plate -->

                                    <div class="pm-plate">
                                        <div class="nv-cell-actions">
                                            <span class="nv-cell-action-trigger"></span>
                                            <nav>
                                                <ul>
                                                    <li><a title="edit">Edit</a></li>
                                                    <li><a title="set primary">Set as primary</a></li>
                                                    <li class="delete"><a title="delete">Delete Credit Card</a></li>
                                                </ul>
                                            </nav>
                                        </div><!-- /nv-cell-actions -->
                                        <div class="pm-radio">
                                            <div class="nv-form-item nv-radio-item">
                                                <label>
                                                   <span class="nri-radio-port">
                                                       <input type="radio" name="type">
                                                       <span class="nri-radio-visual"></span>
                                                   </span><!-- /.nri-radio-port -->
                                                </label>
                                            </div>
                                        </div><!-- /.pm-radio -->
                                        <div class="pm-mean">
                                            <h4>Credit card number</h4>
                                            <p><img src="../../assets/mastercard-logo-web.png" alt="MasterCard">**** 1234</p>
                                        </div><!-- /.pm-mean -->
                                        <div class="pm-exp">
                                            <h4>EXP. DATE</h4>
                                            <p>05/17</p>
                                        </div><!-- /.pm-exp -->
                                        <div class="pm-primary">
                                            <h4>PRIMARY</h4>
                                            <p>NO</p>
                                        </div><!-- /.pm-primary -->
                                    </div><!-- /.pm-plate -->
                                </div><!-- /.nv-payment-methods -->
                                <p><a href><strong>+ Add a card</strong></a></p>

                                <div class="nv-divider desk-big"></div>

                                <h3 class="pale">Invoice History</h3>

                                <div class="nv-conifg-table">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th class="w-checker">
                                                <!--<span class="nv-row-checker unselect-on" title="deselect items"></span>-->
                                                <span class="nv-row-checker select" title="select items"></span>
                                                <!--<span class="nv-row-checker unselect"></span>-->
                                            </th>
                                            <th>
                                                Date
                                                <div class="nv-cell-sorter">
                                                    <span class="up"></span>
                                                    <span class="down"></span>
                                                </div><!-- /.nv-cell-sorter -->
                                            </th>
                                            <th>
                                                Invoice
                                            </th>
                                            <th>
                                                Total
                                            </th>
                                            <th>
                                                Balance
                                            </th>
                                            <th>
                                                Payment
                                            </th>
                                            <th>
                                                Actions
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td class="w-checker">
                                                <span class="nv-row-checker" title="select item"></span>
                                            </td>
                                            <td>
                                                Dec 19, 2019
                                            </td>
                                            <td>
                                                <a href>INV03987338</a>
                                            </td>
                                            <td>
                                                &euro; 99.00
                                            </td>
                                            <td>
                                                &euro; 00.00
                                            </td>
                                            <td>
                                                Paid
                                            </td>
                                            <td class="actions">
                                                <div class="nv-cell-actions">
                                                    <span class="nv-cell-action-trigger"></span>
                                                    <nav>
                                                        <ul>
                                                            <li><a>delete</a></li>
                                                            <li><a>something</a></li>
                                                        </ul>
                                                    </nav>
                                                </div><!-- /nv-cell-actions -->
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="w-checker">
                                                <span class="nv-row-checker" title="select item"></span>
                                            </td>
                                            <td>
                                                Dec 19, 2019
                                            </td>
                                            <td>
                                                <a href>INV03987338</a>
                                            </td>
                                            <td>
                                                &euro; 99.00
                                            </td>
                                            <td>
                                                &euro; 00.00
                                            </td>
                                            <td>
                                                Paid
                                            </td>
                                            <td class="actions">
                                                <div class="nv-cell-actions">
                                                    <span class="nv-cell-action-trigger"></span>
                                                    <nav>
                                                        <ul>
                                                            <li><a>delete</a></li>
                                                            <li><a>something</a></li>
                                                        </ul>
                                                    </nav>
                                                </div><!-- /nv-cell-actions -->
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="w-checker">
                                                <span class="nv-row-checker" title="select item"></span>
                                            </td>
                                            <td>
                                                Dec 19, 2019
                                            </td>
                                            <td>
                                                <a href>INV03987338</a>
                                            </td>
                                            <td>
                                                &euro; 99.00
                                            </td>
                                            <td>
                                                &euro; 00.00
                                            </td>
                                            <td>
                                                Paid
                                            </td>
                                            <td class="actions">
                                                <div class="nv-cell-actions">
                                                    <span class="nv-cell-action-trigger"></span>
                                                    <nav>
                                                        <ul>
                                                            <li><a>delete</a></li>
                                                            <li><a>something</a></li>
                                                        </ul>
                                                    </nav>
                                                </div><!-- /nv-cell-actions -->
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="w-checker">
                                                <span class="nv-row-checker" title="select item"></span>
                                            </td>
                                            <td>
                                                Dec 19, 2019
                                            </td>
                                            <td>
                                                <a href>INV03987338</a>
                                            </td>
                                            <td>
                                                &euro; 99.00
                                            </td>
                                            <td>
                                                &euro; 00.00
                                            </td>
                                            <td>
                                                Paid
                                            </td>
                                            <td class="actions">
                                                <div class="nv-cell-actions">
                                                    <span class="nv-cell-action-trigger"></span>
                                                    <nav>
                                                        <ul>
                                                            <li><a>delete</a></li>
                                                            <li><a>something</a></li>
                                                        </ul>
                                                    </nav>
                                                </div><!-- /nv-cell-actions -->
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="w-checker">
                                                <span class="nv-row-checker" title="select item"></span>
                                            </td>
                                            <td>
                                                Dec 19, 2019
                                            </td>
                                            <td>
                                                <a href>INV03987338</a>
                                            </td>
                                            <td>
                                                &euro; 99.00
                                            </td>
                                            <td>
                                                &euro; 00.00
                                            </td>
                                            <td>
                                                Paid
                                            </td>
                                            <td class="actions">
                                                <div class="nv-cell-actions">
                                                    <span class="nv-cell-action-trigger"></span>
                                                    <nav>
                                                        <ul>
                                                            <li><a>delete</a></li>
                                                            <li><a>something</a></li>
                                                        </ul>
                                                    </nav>
                                                </div><!-- /nv-cell-actions -->
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="w-checker">
                                                <span class="nv-row-checker" title="select item"></span>
                                            </td>
                                            <td>
                                                Dec 19, 2019
                                            </td>
                                            <td>
                                                <a href>INV03987338</a>
                                            </td>
                                            <td>
                                                &euro; 99.00
                                            </td>
                                            <td>
                                                &euro; 00.00
                                            </td>
                                            <td>
                                                Paid
                                            </td>
                                            <td class="actions">
                                                <div class="nv-cell-actions">
                                                    <span class="nv-cell-action-trigger"></span>
                                                    <nav>
                                                        <ul>
                                                            <li><a>delete</a></li>
                                                            <li><a>something</a></li>
                                                        </ul>
                                                    </nav>
                                                </div><!-- /nv-cell-actions -->
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="w-checker">
                                                <span class="nv-row-checker" title="select item"></span>
                                            </td>
                                            <td>
                                                Dec 19, 2019
                                            </td>
                                            <td>
                                                <a href>INV03987338</a>
                                            </td>
                                            <td>
                                                &euro; 99.00
                                            </td>
                                            <td>
                                                &euro; 00.00
                                            </td>
                                            <td>
                                                Paid
                                            </td>
                                            <td class="actions">
                                                <div class="nv-cell-actions">
                                                    <span class="nv-cell-action-trigger"></span>
                                                    <nav>
                                                        <ul>
                                                            <li><a>delete</a></li>
                                                            <li><a>something</a></li>
                                                        </ul>
                                                    </nav>
                                                </div><!-- /nv-cell-actions -->
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div><!-- /nv-config-table -->
                                <p class="nv-load-more-skinless-bar"><a href title="load more">Load more</a></p>
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div><!--- /nv-row -->
                    <div class="nv-row config-plates gap-24">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <h3 class="pale">Change Plan</h3>
                                <div class="nv-plan-choice-plates">
                                    <div class="pcp-item">
                                        <div class="pcp-pane">
                                            <div class="pcp-upper">
                                                <h2>Starter</h2>
                                                <ul class="pcp-capacity">
                                                    <li>150 delegates</li>
                                                    <li>1 event</li>
                                                </ul><!-- /.pcp-capacity -->
                                                <div class="nv-top-features">
                                                    <h4>TOP FEATURES</h4>
                                                    <ul>
                                                        <li>Full E-Configurator</li>
                                                        <li>1 Hour Event Maker Consultancy</li>
                                                    </ul>
                                                </div><!-- /.nv-top-features -->
                                                <div class="pcp-price">
                                                    <h3>&euro; 9.90<span>/ mo</span></h3>
                                                    <span>billed monthly</span>
                                                </div><!-- /.pcp-price -->
                                            </div><!-- /.pcp-upper -->
                                            <a class="nv-btn small fw filled accent1">Apply Plan</a>
                                        </div><!-- /.pcp-pane -->
                                    </div><!-- /.pcp-item -->

                                    <div class="pcp-item">
                                        <div class="pcp-pane">
                                            <div class="pcp-upper">
                                                <span class="pcp-highlight">MOST POPULAR</span>
                                                <h2>Basic</h2>
                                                <ul class="pcp-capacity">
                                                    <li>500 delegates</li>
                                                    <li>3 event</li>
                                                </ul><!-- /.pcp-capacity -->
                                                <div class="nv-top-features">
                                                    <h4>TOP FEATURES</h4>
                                                    <ul>
                                                        <li>Full E-Configurator</li>
                                                        <li>2 Hour Event Maker Consultancy</li>
                                                    </ul>
                                                </div><!-- /.nv-top-features -->
                                                <div class="pcp-price">
                                                    <h3>&euro; 29.90<span>/ mo</span></h3>
                                                    <span>billed monthly</span>
                                                </div><!-- /.pcp-price -->
                                            </div><!-- /.pcp-upper -->
                                            <a class="nv-btn small fw filled accent1">Apply Plan</a>
                                        </div><!-- /.pcp-pane -->
                                    </div><!-- /.pcp-item -->

                                    <div class="pcp-item">
                                        <div class="pcp-pane">
                                            <div class="pcp-upper">
                                                <h2>Advanced</h2>
                                                <ul class="pcp-capacity">
                                                    <li>1000 delegates</li>
                                                    <li>5 event</li>
                                                </ul><!-- /.pcp-capacity -->
                                                <div class="nv-top-features">
                                                    <h4>TOP FEATURES</h4>
                                                    <ul>
                                                        <li>Full E-Configurator</li>
                                                        <li>4 Hour Event Maker Consultancy</li>
                                                        <li>10% on ad sales</li>
                                                    </ul>
                                                </div><!-- /.nv-top-features -->
                                                <div class="pcp-price">
                                                    <h3>&euro; 99<span>/ mo</span></h3>
                                                    <span>billed monthly</span>
                                                </div><!-- /.pcp-price -->
                                            </div><!-- /.pcp-upper -->
                                            <a class="nv-btn small fw filled accent1">Apply Plan</a>
                                        </div><!-- /.pcp-pane -->
                                    </div><!-- /.pcp-item -->

                                    <div class="pcp-item">
                                        <div class="pcp-pane">
                                            <div class="pcp-upper">
                                                <h2>Performer</h2>
                                                <ul class="pcp-capacity">
                                                    <li>2500 delegates</li>
                                                    <li>10 event</li>
                                                </ul><!-- /.pcp-capacity -->
                                                <div class="nv-top-features">
                                                    <h4>TOP FEATURES</h4>
                                                    <ul>
                                                        <li>Full E-Configurator</li>
                                                        <li>6 Hour Event Maker Consultancy</li>
                                                        <li>15% on ad sales</li>
                                                    </ul>
                                                </div><!-- /.nv-top-features -->
                                                <div class="pcp-price">
                                                    <h3>&euro; 199<span>/ mo</span></h3>
                                                    <span>billed monthly</span>
                                                </div><!-- /.pcp-price -->
                                            </div><!-- /.pcp-upper -->
                                            <a class="nv-btn small fw filled accent1">Apply Plan</a>
                                        </div><!-- /.pcp-pane -->
                                    </div><!-- /.pcp-item -->

                                    <div class="pcp-item">
                                        <div class="pcp-pane">
                                            <div class="pcp-upper">
                                                <h2>Professional</h2>
                                                <ul class="pcp-capacity">
                                                    <li>5000 delegates</li>
                                                    <li>20 event</li>
                                                </ul><!-- /.pcp-capacity -->
                                                <div class="nv-top-features">
                                                    <h4>TOP FEATURES</h4>
                                                    <ul>
                                                        <li>Full E-Configurator</li>
                                                        <li>8 Hour Event Maker Consultancy</li>
                                                        <li>20% on ad sales</li>
                                                        <li>Customised quote for more than 5000 delegates or events</li>
                                                    </ul>
                                                </div><!-- /.nv-top-features -->
                                                <div class="pcp-price">
                                                    <h3>&euro; 299<span>/ mo</span></h3>
                                                    <span>billed monthly</span>
                                                </div><!-- /.pcp-price -->
                                            </div><!-- /.pcp-upper -->
                                            <a class="nv-btn small fw filled accent1">Apply Plan</a>
                                        </div><!-- /.pcp-pane -->
                                    </div><!-- /.pcp-item -->
                                </div><!-- /.nv-plan-choice-plate -->

                                <div class="nv-row small-spaced-top config-plates clean gap-24">
                                    <div class="nv-column desk-2-4 tab-none">
                                        <div class="nv-column-content">
                                            <div class="nv-top-features">
                                                <h4>TOP FEATURES</h4>
                                                <ul>
                                                    <li>Full E-Configurator</li>
                                                    <li>1 Hour Event Maker Consultancy</li>
                                                </ul>
                                            </div><!-- /.nv-top-features -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->

                                    <div class="nv-column desk-2-4 tab-none">
                                        <div class="nv-column-content">
                                            <div class="nv-top-features">
                                                <h4>TOP FEATURES</h4>
                                                <ul>
                                                    <li>Full E-Configurator</li>
                                                    <li>2 Hour Event Maker Consultancy</li>
                                                </ul>
                                            </div><!-- /.nv-top-features -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->

                                    <div class="nv-column desk-2-4 tab-none">
                                        <div class="nv-column-content">
                                            <div class="nv-top-features">
                                                <h4>TOP FEATURES</h4>
                                                <ul>
                                                    <li>Full E-Configurator</li>
                                                    <li>4 Hour Event Maker Consultancy</li>
                                                    <li>10% on ad sales</li>
                                                </ul>
                                            </div><!-- /.nv-top-features -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->

                                    <div class="nv-column desk-2-4 tab-none">
                                        <div class="nv-column-content">
                                            <div class="nv-top-features">
                                                <h4>TOP FEATURES</h4>
                                                <ul>
                                                    <li>Full E-Configurator</li>
                                                    <li>6 Hour Event Maker Consultancy</li>
                                                    <li>15% on ad sales</li>
                                                </ul>
                                            </div><!-- /.nv-top-features -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->

                                    <div class="nv-column desk-2-4 tab-none">
                                        <div class="nv-column-content">
                                            <div class="nv-top-features">
                                                <h4>TOP FEATURES</h4>
                                                <ul>
                                                    <li>Full E-Configurator</li>
                                                    <li>8 Hour Event Maker Consultancy</li>
                                                    <li>20% on ad sales</li>
                                                    <li>Customised quote for more than 5000 delegates or events</li>
                                                </ul>
                                            </div><!-- /.nv-top-features -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                </div><!-- /.nv-row -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div><!-- /.nv-row -->
                </div><!-- /nv-port -->
            </section>
        </div><!-- /.nv-config-content-port -->
    </main><!-- /.nv-config-content -->
    <aside class="nv-config-vertical-bar-right"><!-- < toggle class 'hidden' -->
        <div class="nv-config-side-tools">
            <div class="cst-item help">
                <a></a>
                <span>Help & Learning</span>
            </div><!-- /.cst-item -->
            <div class="cst-item keycuts">
                <a></a>
                <span>Keyboard Shortcuts</span>
            </div><!-- /.cst-item -->
            <div class="cst-item addlink">
                <a></a>
                <span>Add Quick Link</span>
            </div><!-- /.cst-item -->
        </div><!-- /.nv-config-side-tools -->

        <div class="nv-config-side-tools">
            <div class="cst-item hide-panel">
                <a></a>
                <span>Hide Side Panel</span>
            </div><!-- /.cst-item -->
        </div><!-- /.nv-config-side-tools -->
    </aside><!-- /.nv-config-vertical-bar-right -->
</div><!-- /.nv-config-layout -->
