<div class="nv-config-control-bar">
    <div class="nv-config-branding-hero">
        <div class="cbh-photo-port">
            <img src="../../assets/organization-cover-image.jpg" alt="{{ organization?.name }}">
            <!-- <span><p>No photo uploaded</p></span> -->
            <!--a class="cbh-photo-upload">Upload cover photo</a-->
        </div><!-- /cbh-photo-port -->
        <div class="cbh-logo-n-org">
            <div class="cbh-logo-port nv-clickable" (click)="changeLogo()">
                <div nvBackgroundImage *ngIf="organization?.logo" [image]="organization?.logo"></div>
                <span>ADD LOGO</span>
            </div><!-- /cbh-logo-port -->
            <div class="cbh-org">
                <div class="cbh-org-title">
                    <p>ORGANIZATION</p>
                    <h3>{{ organization?.name }}</h3>
                </div><!-- /.cbh-org-title -->
                <div class="cbh-org-edit">
                    <a [routerLink]="['/o', organizationId]" title="Edit">Edit</a>
                </div><!-- /.cbh-org-edit -->
            </div><!-- /.cbh-org -->
        </div><!-- /.cbh-logo-n-org -->
    </div><!-- /.nv-config-branding-hero -->
</div>
<div class="nv-config-content-port">
    <section class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates v-stretch gap-24 propagate">
                <div class="nv-column desk-6 lap-12">
                    <div class="nv-column-content">
                        <div class="nv-row small-spaced-bottom config-plates clean gap-20 propagate">
                            <div class="nv-column desk-auto v-middle">
                                <div class="nv-column-content">
                                    <h4 class="spaceless"><span translate>Upcoming & Past Events</span></h4>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-initial">
                                <div class="nv-column-content">
                                    <nv-button
                                            [routerLink]="['/o', organizationId, 'conference']"
                                            color="primary"
                                            size="small">Create new event</nv-button>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                        </div><!-- /.nv-row -->
                        <div>
                            <div class="nv-result-item nv-upcoming-n-past nv-clickable"
                                 [routerLink]="['/o', organizationId, 'conference', conference.id, 'landing']"
                                 *ngFor="let conference of conferences">
                                <div class="nv-ri-conf">
                                    <h4>{{ conference.name }}</h4>
                                    <span>{{ conference.starts_at | nvDate:'dd MMM yyyy' }}</span>
                                </div>
                                <nv-button color="passive" size="small">View event</nv-button>
                            </div>
                        </div>
                        <div *ngIf="conferencePagination.total > conferencePagination.per_page" class="nv-row flow-center small-spaced-top gap-30">
                            <div class="nv-column desk-initial">
                                <div class="nv-column-content">
                                    <nv-pagination
                                            class="nv-column-content spaced-bottom"
                                            type="thorough"
                                            [config]="conferencePagination"
                                            (paginationChange)="conferencePaginationChange($event)">
                                    </nv-pagination>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="conferences.length <= 0">
                            <div class="nv-empty-state">
                                <div class="img">
                                    <img src="../../../../assets/empty-states/empty-state-events.svg" alt="">
                                </div>
                                <h4 class="title">No events!</h4>
                                <p class="text">Once you add event, you'll see them here.</p>
                                <nv-button [routerLink]="['/o', organizationId, 'conference']">Add event</nv-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nv-column desk-6 lap-12">
                    <div class="nv-column-content">
                        <h4 style="display: flex; align-items: center; justify-content: space-between; height: 40px">
                            <span translate>Activity Stream</span>
                        </h4>
                        <div>
                            <div class="nv-result-item as-item nv-clickable" *ngFor="let item of feed">
                                <div class="asi-avatar" *ngIf="item.user?.image_url">
                                    <img [src]="item.user?.image_url">
                                </div>
                                <div class="asi-details">
                                    <h4 class="asi-event">{{ item.event }}</h4>
                                    <small>{{ item.created_at | nvDate:'dd.MM.yyyy HH:mm' }} by {{ item.user.first_name }} {{ item.user.last_name }}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section><!-- /.nv-section -->
</div>
