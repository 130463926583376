import { Component, Input, OnInit } from '@angular/core';
import { Presentation } from '@navus/core/classes/presentation';

@Component({
  selector: 'nv-speaker-slides',
  templateUrl: 'speaker-slides.component.html'
})

export class SpeakerSlidesComponent implements OnInit {
  @Input() presentations: Presentation[] = [];

  constructor() { }

  ngOnInit() { }
}