import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LivestreamingComponent } from './livestreaming.component';
import { LivestreamingRoutingModule } from './livestreaming-routing.module';
import { LivestreamingLocationEditComponent } from './livestream-location-edit/livestreaming-location-edit.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NavusUiModule } from '@navus/ui/navus-ui.module';
import { SharedModule } from '../shared';
import { LivestreamMaterialsComponent } from './livestream-materials/livestream-materials.component';
import { LivestreamMeetingComponent } from './livestream-meeting/livestream-meeting.component';
import { LivestreamVotingComponent } from './livestream-voting/livestream-voting.component';
import { LivestreamOffAirComponent } from './livestream-off-air/livestream-off-air.component';
import { LivestreamChatContainerComponent } from './livestream-chat-container/livestream-chat-container.component';
import { LivestreamOffAirEditComponent } from './livestream-off-air/livestream-off-air-edit/livestream-off-air-edit.component';
import { LivestreamStreamingProgramComponent } from './livestream-streaming-program/livestream-streaming-program.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { LivestreamQAComponent } from './livestream-q-a/livestream-q-a.component';

@NgModule({
  declarations: [
    LivestreamingComponent,
    LivestreamingLocationEditComponent,
    LivestreamStreamingProgramComponent,
    LivestreamMaterialsComponent,
    LivestreamMeetingComponent,
    LivestreamVotingComponent,
    LivestreamOffAirComponent,
    LivestreamChatContainerComponent,
    LivestreamOffAirEditComponent,
    LivestreamQAComponent,
  ],
  imports: [
    LivestreamingRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    NavusUiModule,
    SharedModule,
    ClipboardModule
  ]
})
export class LivestreamingModule {
}
