export class DelegateListSectionConfig {
  limit: number = 8;
  title: string = 'Delegates';
  viewAll: boolean = false;
  numbered: boolean = false;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
