<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Chat Settings</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-10">
                <nv-button (click)="save()" translate>Save</nv-button>
            </div>
        </div>
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav vertical-break-tab">
                <ul>
                    <li>
                        <a class="active">Chat Settings</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'networking', 'qa-settings']">Q&A Settings</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'networking', 'voting-settings']">Voting Settings</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>

<div class="nv-config-content-port">
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24 small-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form class="nv-row config-plates clean gap-24 small-spaced-top spaced-bottom" [formGroup]="chatSettingsForm">
                            <div class="nv-column desk-6 tab-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-24 propagate">
                                        <div class="nv-column desk-12 no-propagate">
                                            <div class="nv-column-content">
                                                <h1>Chat Settings</h1>
                                            </div>
                                        </div>
                                        <ng-container>
                                            <div class="nv-column desk-12 tab-12">
                                                <div class="nv-column-content">
                                                    <nv-switch
                                                            label="Enable chat"
                                                            formControlName="enabled"
                                                    ></nv-switch>
                                                </div>
                                            </div>
                                            <div class="nv-column desk-6 tab-12">
                                                <div class="nv-column-content">
                                                    <nv-select
                                                            label="Pages where to show chat"
                                                            formControlName="pages"
                                                            [options]="pagesWhereToShowChat"
                                                    ></nv-select>
                                                </div>
                                            </div>
                                            <div class="nv-column desk-12 tab-12">
                                                <div class="nv-column-content">
                                                    <nv-switch
                                                            label="Allow 1:1 chat between delegates"
                                                            formControlName="allow1on1"
                                                    ></nv-switch>
                                                </div>
                                            </div>
                                            <div class="nv-column desk-12 tab-12">
                                                <div class="nv-column-content">
                                                    <nv-switch
                                                            label="Allow Group chat"
                                                            formControlName="allowGroupChat"
                                                    ></nv-switch>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-6 tab-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-6 phab-12">
                                            <div class="nv-column-content">
                                                <strong translate>Public Groups</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-6 phab-12">
                                            <div class="nv-column-content">
                                                <nv-button size="small" iconPl="&#xe095;" (click)="addGroup()">Add
                                                    group
                                                </nv-button>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->

                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <div *ngIf="publicGroups?.length === 0">
                                                    <div class="nv-empty-state">
                                                        <div class="img">
                                                            <img src="../../../../assets/empty-states/empty-state-files.svg"
                                                                 alt="">
                                                        </div>
                                                        <h4 class="title">No public groups!</h4>
                                                        <p class="text">Once you add a public group, you'll see them
                                                            here.</p>
                                                    </div>
                                                </div>
                                                <table class="nv-data-table break-lap">
                                                    <tbody>
                                                    <tr *ngFor="let publicGroup of publicGroups">
                                                        <td class="dt-cell nv-clickable"
                                                            (click)="editGroup(publicGroup)">
                                                            <label>Name</label>
                                                            <strong>{{ publicGroup.name }}</strong>
                                                        </td>
                                                        <td class="dt-cell nv-clickable"
                                                            (click)="editGroup(publicGroup)">
                                                            <label>Autojoin</label>
                                                            <strong>{{ publicGroup.autoJoin || false}}</strong>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
