import { Routes } from '@angular/router';
import { ProfileDelegateComponent } from './pages/delegate/delegate.component';
import { ProfileUserComponent } from './pages/user/user.component';
import { ProfileSecurityComponent } from './pages/security/security.component';
import { ProfileComponent } from './profile.component';
import { ProfileSpeakerComponent } from './pages/speaker/speaker.component';
import { ProfileSponsorComponent } from './pages/sponsor/sponsor.component';
import { ProfileBadgesComponent } from './pages/badges/badges.component';

export const ProfileRoutes: Routes = [
  {
    path: '',
    component: ProfileComponent,
    children: [
      {
        path: 'info',
        component: ProfileUserComponent
      },
      {
        path: 'security',
        component: ProfileSecurityComponent
      },
      {
        path: 'delegate',
        component: ProfileDelegateComponent
      },
      {
        path: 'speaker',
        component: ProfileSpeakerComponent
      },  
      {
        path: 'sponsor',
        component: ProfileSponsorComponent
      },  
      {
        path: 'badges',
        component: ProfileBadgesComponent
      },  
    ]
  }
]