import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ApiService {
    constructor(
        private http: HttpClient
    ) { }

    private formatErrors(error: any) {
        return throwError(error);
    }

    get(path: string, params: any = {}, config: any = {}): Observable<any> {
        const headers: any = {};
        if (config.slug) { headers['Slug'] = config.slug; }

        return this.http.get(`${environment.api_url}${path}`, { headers: headers, params: params })
            .pipe(catchError(this.formatErrors));
    }
    put(path: string, body: Object = {}): Observable<any> {
        return this.http.put(
            `${environment.api_url}${path}`,
            JSON.stringify(body)
        ).pipe(catchError(this.formatErrors));
    }

    post(path: string, body: Object = {}, headers: any = {}): Observable<any> {
        return this.http.post(
            `${environment.api_url}${path}`,
            JSON.stringify(body),
            { headers: headers }
        ).pipe(catchError(this.formatErrors));
    }

  delete(path: string, params?: any): Observable<any> {
    return this.http.delete(
      `${environment.api_url}${path}`, { params }
    ).pipe(catchError(this.formatErrors));
  }

  download(path: string, filename: string, params?: any): Observable<any> {
    return this.http.get(`${environment.api_url}${path}`, { responseType: 'arraybuffer', params: params })
      .pipe(map((response: any) => {
        const ie = navigator.userAgent.match(/MSIE\s([\d.]+)/),
          ie11 = navigator.userAgent.match(/Trident\/7.0/) && navigator.userAgent.match(/rv:11/),
          ieEDGE = navigator.userAgent.match(/Edge/g),
          ieVer = (ie ? ie[1] : (ie11 ? 11 : (ieEDGE ? 12 : -1)));

        if (ie && ieVer < 10) {
          console.log('No blobs on IE ver < 10');
          return;
        }

        const mime = params.mime || 'application/pdf';
        const extension = params.extension ? `.${params.extension}` : `.pdf`;

        const file = new Blob([response], {type: mime});

        if (ieVer > -1) {
          window.navigator.msSaveBlob(file, filename + extension);
        } else {
          const fileURL = URL.createObjectURL(file);
          const a = document.createElement('a');
          a.href = fileURL;
          a.download = filename + extension;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }));
  }

}
