import { Component, EventEmitter, Input, OnInit, Output, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Website } from '@navus/core/classes/website';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { WebsitePage } from '@navus/core/classes/website-page';
import { WebsiteControlService } from '../../services/website-control.service';

@Component({
  selector: '[nv-section-panel]',
  templateUrl: './section-panel.component.html'
})
export class SectionPanelComponent implements OnInit {
  @Input() website: Website;
  @Input() page: WebsitePage;
  @Input() section: WebsitePageSection;
  @Output() saved = new EventEmitter();
  @HostBinding('class.on') show = false;
  @HostBinding('class.expanded') expanded = false;

  sectionForm: FormGroup;
  sectionTypes = [
    // { id: 'attendanceCert', name: 'Attendance Certificate' },
    { id: 'custom', name: 'Custom Section' },
    { id: 'customHtml', name: 'Custom HTML Section' },
    { id: 'hero', name: 'Hero' },
    { id: 'articleList', name: 'Article List' },
    // { id: 'recommendations', name: 'Recommendations' },
    { id: 'slider', name: 'Slider' },
    { id: 'twitter', name: 'Twitter' },
    // { id: 'search', name: 'Search' },
    // { id: 'summary', name: 'Summary' },
    { id: 'authorList', name: 'Author List' },
    { id: 'delegateList', name: 'Delegate List' },
    { id: 'sponsorList', name: 'Sponsor List' },
    // { id: 'liveSuite', name: 'Live Suite' },
    // { id: 'liveSuiteIframe', name: 'Live Suite Iframe' },
    { id: 'conferenceList', name: 'Event List' },
    { id: 'programPlanner', name: 'Program Planner' },
    { id: 'banner', name: 'Banner' },
    { id: 'promo', name: 'Promo' },
    { id: 'leaderboard', name: 'Leaderboard' },
  ];

  savingSection = false;
  deletingSection = false;

  constructor(
    private formBuilder: FormBuilder,
    private pageService: WebsitePageService,
    private websiteControlService: WebsiteControlService,
    private modalService: ModalService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.websiteControlService.editingtWebsitePageSection
      .subscribe(
        (section) => {
          this.section = section;
          this.show = !!section;
          this.initForm();

          if (section) {
            this.sectionForm.patchValue(this.section);
          }
        }
      );
    this.initForm();
  }

  initForm() {
    this.sectionForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      type: ['customHtml', [Validators.required]],
      order: [null],
      configuration: [null],
    });
  }

  save() {
    if (this.sectionForm.invalid) { return; }

    this.savingSection = true;
    if (this.section && this.section.id) {
      this.pageService.updateWebsitePageSection(0, this.website.id, this.page.id, this.section.id, this.sectionForm.value)
        .subscribe(
          (response) => {
            this.section = response['data'];
            this.savingSection = false;
            this.toastService.success('Section successfully saved.');
            this.saved.emit();
            this.close();
          },
          (error) => {
            this.savingSection = false;
            this.modalService.error({ errors: error });
          }
        );
    } else {
      this.pageService.createWebsitePageSection(0, this.website.id, this.page.id, this.sectionForm.value)
        .subscribe(
          (response) => {
            this.initForm();
            this.section = response['data'];
            this.savingSection = false;
            this.toastService.success('Section successfully created.');
            this.saved.emit();
            this.close();
          },
          (error) => {
            this.savingSection = false;
            this.modalService.error({ errors: error });
          }
        );
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Section Deletion',
      body: 'Are you sure you want to delete this section?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.deletingSection = true;
            this.pageService.deleteWebsitePageSection(0, this.website.id, this.page.id, this.section.id)
              .subscribe(
                (response) => {
                  this.deletingSection = false;
                  this.toastService.success('Section successfully deleted.');
                  this.saved.emit();
                  this.close();
                },
                (error) => {
                  this.deletingSection = false;
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

  close() {
    this.show = false;
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
  }

}
