import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class MeetingRoomService {

  constructor(
    private apiService: ApiService
  ) { }

  getConferenceMeetingRooms(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/meeting-rooms`, params);
  }

  createConferenceMeetingRoom(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/meeting-rooms`, params);
  }

  getMeetingRoom(meetingRoomId: string, params: any = {}) {
    return this.apiService.get(`meeting-rooms/${meetingRoomId}`, params);
  }

  updateMeetingRoom(meetingRoomId: string, params: any = {}) {
    return this.apiService.put(`meeting-rooms/${meetingRoomId}`, params);
  }

  deleteMeetingRoom(meetingRoomId: string) {
    return this.apiService.delete(`meeting-rooms/${meetingRoomId}`);
  }

  canCreateConferenceMeetingRoom(conferenceId: number, entityType: string, entityId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/meeting-rooms/can-create/${entityType}/${entityId}`, params);
  }
  
  canJoinMeetingRoom(meetingRoomId: string) {
    return this.apiService.get(`meeting-rooms/${meetingRoomId}/can-join`);
  }

  getMeetingRoomParticipants(meetingRoomId: string) {
    return this.apiService.get(`meeting-rooms/${meetingRoomId}/participants`);
  }

  joinMeetingRoom(meetingRoomId: string, userId: number) {
    return this.apiService.post(`meeting-rooms/${meetingRoomId}/participants/${userId}`);
  }

  leaveMeetingRoom(meetingRoomId: string, userId: number) {
    return this.apiService.delete(`meeting-rooms/${meetingRoomId}/participants/${userId}`);
  }

  getMeetingRoomLivestream(meetingRoomId: string, params: any = {}) {
    return this.apiService.get(`meeting-rooms/${meetingRoomId}/live-stream`, params);
  }

  createMeetingRoomLivestream(meetingRoomId: string, params: any = {}) {
    return this.apiService.post(`meeting-rooms/${meetingRoomId}/live-stream`, params);
  }

  deleteMeetingRoomLivestream(meetingRoomId: string, params: any = {}) {
    return this.apiService.delete(`meeting-rooms/${meetingRoomId}/live-stream`, params);
  }

}
