import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
    selector: 'app-elements-page',
    templateUrl: './elements.component.html'
})
export class ElementsComponent implements OnInit {
    constructor(
        private element: ElementRef
    ) { }

    @ViewChild('tref', { read: ElementRef }) tref: ElementRef;

    onInViewportChange(inViewport: boolean, el) {
        if (document.getElementById(el.getAttribute('data-view')) == null && inViewport) {
            document.getElementById(el.getAttribute('id')).setAttribute('data-view', 'true');
            el.classList.add('special');
        }
    }

    ngOnInit() {}

}
