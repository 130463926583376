import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoPlayerPreset } from '@navus/core/classes/video-player-preset';

@Component({
  selector: 'nv-embed-customize-modal',
  templateUrl: './embed-customize-modal.component.html'
})
export class EmbedCustomizeModalComponent implements OnInit {
  @Input() path: string;
  @Input() preset: VideoPlayerPreset;
  embed: string;

  autoplay: boolean = false;
  mute: boolean = false;
  loop: boolean = false;
  time: number = 0;
  posterTime: number = 0;
  nav = {
    show: true,
    seek: true,
    volume: true,
    quality: false,
    cc: false,
    full_screen: true,
    pip: false,
    time_range: true,
    time: true,
    duration: true
  };

  constructor(
    private activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
    this.loadPreset();
    this.generatePath();
  }

  copy() {
    this.generatePath();
    this.activeModal.close(this.embed);
  }

  close() {
    this.activeModal.dismiss();
  }

  private generatePath() {
    let path = this.path;
    path = path.replace('.m3u8', '');
    path = path.replace('stream.mux.com', 'player.navus.io');

    path += `?nav=${this.nav.show ? 1 : 0}${this.nav.seek ? 1 : 0}${this.nav.volume ? 1 : 0}${this.nav.quality ? 1 : 0}${this.nav.cc ? 1 : 0}${this.nav.full_screen ? 1 : 0}${this.nav.pip ? 1 : 0}${this.nav.time_range ? 1 : 0}${this.nav.time ? 1 : 0}${this.nav.duration ? 1 : 0}&`;
    if (this.autoplay) {
      path += `autoplay=1&`;
    }
    if (this.mute) {
      path += `mute=1&`;
    }
    if (this.loop) {
      path += `loop=1&`;
    }
    if (this.time) {
      path += `t=${this.time}&`;
    }
    if (this.posterTime) {
      path += `pt=${this.posterTime}&`;
    }

    this.embed = path;

    // this.embed = `
    // <iframe src="${path}"></iframe>
    // `;
  }

  private loadPreset() {
    if (this.preset) {
      this.autoplay = this.preset.settings.autoplay === 1;
      this.mute = this.preset.settings.mute === 1;
      this.loop = this.preset.settings.loop === 1;
      this.nav.show = this.preset.settings.nav.show === 1;
      this.nav.seek = this.preset.settings.nav.seek === 1;
      this.nav.volume = this.preset.settings.nav.volume === 1;
      this.nav.quality = this.preset.settings.nav.quality === 1;
      this.nav.cc = this.preset.settings.nav.cc === 1;
      this.nav.full_screen = this.preset.settings.nav.full_screen === 1;
      this.nav.pip = this.preset.settings.nav.pip === 1;
      this.nav.time_range = this.preset.settings.nav.time_range === 1;
      this.nav.time = this.preset.settings.nav.time === 1;
      this.nav.duration = this.preset.settings.nav.duration === 1;
    }
  }
}
