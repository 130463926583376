export class Country {
  id: number;
  code2: string;
  code3: string;
  name: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
