<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Presentation</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-10">
                <nv-button
                        [routerLink]="['/o', organizationId, 'conference', conferenceId, 'voting', 'votings', presentationId]"
                        [disabled]="!presentationId" translate>Manage Voting</nv-button>
                <a (click)="delete()" [hidden]="!presentationId" class="nv-btn secondary medium" translate>Delete</a>
                <nv-button (click)="close()" color="passive" translate>Close</nv-button>
                <nv-button (click)="save()" [loading]="savingPresentation" [disabled]="presentationForm.invalid" translate>Save</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port spaced-bottom">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav vertical-break-tab">
                <ul>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program']">Program</a>
                    </li>
                    <li>
                        <a class="active">Presentations</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program', 'blocks']">Sessions</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program', 'locations']">Locations</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
<div class="nv-config-content-port">
    <div class="nv-global-loading on" [class.on]="loadingPresentation">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form [formGroup]="presentationForm" class="nv-row config-plates clean gap-24 small-spaced-top spaced-bottom">
                            <div class="nv-column desk-6">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Details</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-4">
                                            <div class="nv-column-content">
                                                <nv-checkbox
                                                    [(ngModel)]="inSession"
                                                    [ngModelOptions]="{ standalone: true }"
                                                    (change)="inSessionChange()"
                                                    label="Part of the session"
                                                ></nv-checkbox>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-8" *ngIf="inSession">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        [options]="blocks"
                                                        [showSearch]="true"
                                                        (searched)="searchBlocks($event)"
                                                        (loadData)="getBlocks()"
                                                        (loadMore)="getBlocks(true)"
                                                        [addButtonText]="'+ Add Session'"
                                                        (addButtonClick)="createBlock($event)"
                                                        (change)="sessionChange($event)"
                                                        formControlName="block_id"
                                                        label="Session"
                                                ></nv-select>
                                                <small *ngIf="presentationForm.value.block_id">
                                                    <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program', 'block', presentationForm.value.block_id]">Manage session</a>
                                                </small>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12" *ngIf="!inSession">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        [options]="locations"
                                                        [showSearch]="true"
                                                        (searched)="searchLocations($event)"
                                                        (loadData)="getLocations()"
                                                        (loadMore)="getLocations(true)"
                                                        [addButtonText]="'+ Add Location'"
                                                        (addButtonClick)="createLocation($event)"
                                                        formControlName="location_id"
                                                        label="Location"
                                                ></nv-select>
                                                <small><a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program', 'locations']">Manage locations</a></small>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="title"
                                                        label="Title"
                                                        required
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-4" *ngIf="!inSession">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        [options]="dates"
                                                        [(ngModel)]="date"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        (ngModelChange)="dateChange()"
                                                        label="Day"
                                                        required
                                                ></nv-select>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-4">
                                            <div class="nv-column-content">
                                                <nv-datepicker
                                                        type="timer"
                                                        [(ngModel)]="start_time"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        (ngModelChange)="dateChange()"
                                                        label="Start time"
                                                        required
                                                ></nv-datepicker>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-4">
                                            <div class="nv-column-content">
                                                <nv-datepicker
                                                        type="timer"
                                                        [(ngModel)]="end_time"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        (ngModelChange)="dateChange()"
                                                        label="End time"
                                                        required
                                                ></nv-datepicker>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12" *ngIf="presentationForm.value.starts_at !== ''">
                                            <div class="nv-column-content">
                                                <small>
                                                    Presentation length:
                                                    <a (click)="setEndTime(5)">5 min</a>,
                                                    <a (click)="setEndTime(10)">10 min</a>,
                                                    <a (click)="setEndTime(15)">15 min</a>,
                                                    <a (click)="setEndTime(30)">30 min</a>,
                                                    <a (click)="setEndTime(45)">45 min</a>, 
                                                    <a (click)="setEndTime(60)">60 min</a>
                                                </small>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        [multi]="true"
                                                        [options]="authors"
                                                        [showSearch]="true"
                                                        (searched)="searchAuthors($event)"
                                                        (loadData)="getAuthors()"
                                                        (loadMore)="getAuthors(true)"
                                                        [addButtonText]="'+ Add Speaker'"
                                                        (addButtonClick)="createAuthor($event)"
                                                        formControlName="author_ids"
                                                        label="Speaker(s)"
                                                ></nv-select>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-4">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        [options]="presentationSections"
                                                        formControlName="section"
                                                        label="Section"
                                                        required
                                                ></nv-select>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-4">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        [options]="presentationTypes"
                                                        formControlName="type"
                                                        label="Type"
                                                        required
                                                ></nv-select>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-4">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="code"
                                                        label="Code"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="external_webcast"
                                                        label="Link to external webcast"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Live stream and on-demand</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-checkbox
                                                        formControlName="streamable"
                                                        label="Add to live program"
                                                ></nv-checkbox>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-checkbox
                                                        formControlName="allow_publishing"
                                                        label="Publish material (Speaker consent)"
                                                ></nv-checkbox>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-6">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Description / Abstract</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-text-editor
                                                        formControlName="description"
                                                        label="Description / Abstract"
                                                        [organizationId]="organizationId"
                                                ></nv-text-editor>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong>Talk to speaker</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-checkbox
                                                        formControlName="talk_to_speaker"
                                                        label="Talk to the speaker(s)"
                                                ></nv-checkbox>
                                                <small>Turn on the video call option between the speaker(s) and the audience.</small>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="availability"
                                                        label="Speaker(s) Availability"
                                                ></nv-input>
                                                <small>If using the option to talk to the speaker, set their availability here.</small>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong>Liking</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-checkbox
                                                        formControlName="vote_available"
                                                        label="Use likes"
                                                ></nv-checkbox>
                                                <small>Allow delegates to like this presentation.</small>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12" formGroupName="chat">
                                            <div class="nv-column-content">
                                                <div class="nv-row config-plates clean gap-30 propagate">
                                                    <div class="nv-column desk-12">
                                                        <div class="nv-column-content">
                                                            <strong>Chat with the speaker(s)</strong>
                                                        </div><!-- /.nv-column-content -->
                                                    </div><!-- /.nv-column -->
                                                    <div class="nv-column desk-12">
                                                        <div class="nv-column-content">
                                                            <nv-checkbox
                                                                    formControlName="enabled"
                                                                    label="Use Q&A tool"
                                                            ></nv-checkbox>
                                                            <small>Chat between the speaker(s) and the audience.</small>
                                                        </div><!-- /.nv-column-content -->
                                                    </div><!-- /.nv-column -->
                                                    <div class="nv-column desk-12">
                                                        <div class="nv-column-content">
                                                            <nv-checkbox
                                                                    formControlName="moderation"
                                                                    label="Moderation for public questions"
                                                            ></nv-checkbox>
                                                            <small>Control what questions and messages from the audience will be publicly shown. If off, all messages will be automatically shown to everyone.</small>
                                                        </div><!-- /.nv-column-content -->
                                                    </div><!-- /.nv-column -->                                            
                                                </div>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                       
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-checkbox
                                                        [(ngModel)]="showAdvanced"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        label="Advanced"
                                                ></nv-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="nv-column desk-12" *ngIf="showAdvanced"> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
