<ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'file'">
        <label *ngIf="!hideFilename">
            {{ fileName }}
        </label>
        <div class="nv-upload-block">
            <div>
                <p class="ub-control">
                    <span (click)="openResourceModal($event)"><a>Upload new file</a></span>
                    <span *ngIf="fileUrl" (click)="remove()"><a>Remove file</a></span>
                </p><!-- /.ub-control -->
                <div class="info-p small" *ngIf="information">
                    <p>Upload instructions</p>
                    <div class="nv-input-info-box">
                        <span
                            (mouseenter)="showInfoToggle()"
                            (mouseleave)="showInfoToggle()"
                            [ngClass]="{ 'on': showInfo }"></span>
                        <div class="nv-input-info-content">
                            <div>{{information}}</div>
                        </div>
                    </div>
                </div><!-- /.info-p -->
            </div><!-- /.ub-adjument -->
        </div><!-- /.nv-upload-block -->
    </ng-container>
    <ng-container *ngSwitchDefault>
        <div class="nv-upload-block">
            <label class="ub-img-port">
                <input
                    type="text"
                    [hidden]="true"
                    [name]="name"
                    [disabled]="disabled"
                    [readOnly]="readOnly"
                    [required]="required"
                    [(ngModel)]="value"
                    (change)="emitChange($event)"
                    (click)="openResourceModal($event)">
                <span class="nv-ub-image"
                    *ngIf="fileUrl"
                    [style]="fileUrl"
                ></span><!-- /.nv-ub-image -->
                <span class="nv-ub-image"
                    *ngIf="!fileUrl"
                ></span><!-- /.nv-ub-image -->
            </label>
            <div class="ub-adjument">
                <p class="ub-control">
                    <span (click)="openResourceModal($event)"><a>Upload new file</a></span>
                    <span *ngIf="fileUrl" (click)="remove()"><a>Remove file</a></span>
                </p><!-- /.ub-control -->
                <div class="info-p small" *ngIf="information">
                    <p>Upload instructions</p>
                    <div class="nv-input-info-box">
                        <span
                            (mouseenter)="showInfoToggle()"
                            (mouseleave)="showInfoToggle()"
                            [ngClass]="{ 'on': showInfo }"></span>
                        <div class="nv-input-info-content">
                            <div>{{information}}</div>
                        </div>
                    </div>
                </div><!-- /.info-p -->
            </div><!-- /.ub-adjument -->
        </div><!-- /.nv-upload-block -->
    </ng-container>
</ng-container>
