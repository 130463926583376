import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class CertificateService {

  constructor(
    private apiService: ApiService
  ) { }

  getConferenceCertificates(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/cme_certificates`, params);
  }

  getConferenceCertificate(conferenceId: number, certificateId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/cme_certificates/${certificateId}`, params);
  }

  updateConferenceCertificate(conferenceId: number, certificateId: number, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/cme_certificates/${certificateId}`, params);
  }

  createConferenceCertificate(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/cme_certificates`, params);
  }

  deleteConferenceCertificate(conferenceId: number, certificateId: number) {
    return this.apiService.delete(`conferences/${conferenceId}/cme_certificates/${certificateId}`);
  }

  getConferenceDelegateCertificate(conferenceId: number, certificateId: number, delegateId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/cme_certificates/${certificateId}/delegates/${delegateId}`, params);
  }

  createConferenceDelegateCertificate(conferenceId: number, certificateId: number, delegateId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/cme_certificates/${certificateId}/delegates/${delegateId}`, params);
  }

  downloadConferenceDelegateCertificate(conferenceId: number, certificateId: number, delegateId: number, params: any = {}) {
    return this.apiService.download(`conferences/${conferenceId}/cme_certificates/${certificateId}/delegates/${delegateId}/download`, 'certificate.pdf', params);
  }

}
