import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Presentation } from '@navus/core/classes/presentation';
import { PresentationService } from '@navus/core/services/presentation.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { BlockService } from '@navus/core/services/block.service';
import { VotingQuestion } from '@navus/core/classes/voting-question';
import { AuthorService } from '@navus/core/services/author.service';
import { VotingQuestionDetailsModalComponent } from '../../modals/voting-question-details/voting-question-details-modal.component';
import { VotingWindowSelectModalComponent } from '../../modals/voting-window-select/voting-window-select-modal.component';

@Component({
  selector: 'main [nv-voting-details-page]',
  templateUrl: './voting-details-page.component.html',
  styles: []
})
export class VotingDetailsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  presentationId: number;
  presentation: Presentation = new Presentation();
  votingQuestions: VotingQuestion[] = [];
  votingPopups: string[] = [];

  loadingPresentation: boolean = false;
  loadingQuestions: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private presentationService: PresentationService,
    private blockService: BlockService,
    private authorService: AuthorService,
    private toastService: ToastService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.presentationId = data.presentationId;
        this.getPresentation();
        this.getPresentationVotings();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getPresentation() {
    if (this.presentationId) {
      this.loadingPresentation = true;
      this.presentationService.getConferencePresentation(this.conferenceId, this.presentationId)
        .subscribe(
          (response: { data: Presentation, meta: any }) => {
            this.presentation = response.data;
            this.loadingPresentation = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingPresentation = false;
          }
        );
    }
  }

  getPresentationVotings() {
    if (this.presentationId) {
      this.loadingQuestions = true;
      this.presentationService.getConferencePresentationVotingQuestions(this.conferenceId, this.presentationId)
        .subscribe(
          (response: { data: VotingQuestion[], meta: any }) => {
            this.votingQuestions = response.data;
            this.loadingQuestions = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingQuestions = false;
          }
        );
    }
  }

  close() {
    if (this.presentationId) {
      this.router.navigate(['../..', 'votings'], { relativeTo: this.route });
    } else {
      this.router.navigate(['..', 'votings'], { relativeTo: this.route });
    }
  }

  addQuestion() {
    const modalRef = this.modalService.open(VotingQuestionDetailsModalComponent);
    modalRef.componentInstance.conferenceId = this.conferenceId;
    modalRef.componentInstance.presentationId = this.presentationId;
    modalRef.result.then(
      () => {
        this.getPresentationVotings();
      },
      () => { }
    );
  }

  editQuestion(votingQuestionId: number) {
    const modalRef = this.modalService.open(VotingQuestionDetailsModalComponent);
    modalRef.componentInstance.conferenceId = this.conferenceId;
    modalRef.componentInstance.presentationId = this.presentationId;
    modalRef.componentInstance.votingQuestionId = votingQuestionId;
    modalRef.result.then(
      () => {
        this.getPresentationVotings();
      },
      () => { }
    );
  }

  setQuestionStatus(votingQuestion, status) {
    const params = {
      status: status
    };
    this.presentationService.updateConferencePresentationVoting(this.conferenceId, this.presentationId, votingQuestion.id, params)
      .subscribe(
        () => {
          this.getPresentationVotings();
        },
        () => { }
      );
  }

  runQuestion(votingQuestion) {
    const h: number = window.screen.availHeight;
    const w: number = window.screen.availWidth;

    const votingPopups = this.getVotingPopups();
    console.log(votingPopups);

    if (votingPopups.length === 0) {
      const uniqueId: string = Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
      const path: string = `/pv/${this.conferenceId}/presentation/${this.presentationId}/${votingQuestion.id}/${uniqueId}`;
      window.open(window.location.origin + path, uniqueId, `height=${h},width=${w}`);
    } else {
      const modalRef = this.modalService.open(VotingWindowSelectModalComponent);
      modalRef.componentInstance.votingPopups = votingPopups;
      modalRef.result.then(
        (popupId) => {
          if (popupId) {
            const path: string = `/pv/${this.conferenceId}/presentation/${this.presentationId}/${votingQuestion.id}/${popupId}`;
            localStorage.setItem(`pv:${popupId}`, path);
          } else {
            const uniqueId: string = Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
            const path: string = `/pv/${this.conferenceId}/presentation/${this.presentationId}/${votingQuestion.id}/${uniqueId}`;
            window.open(window.location.origin + path, uniqueId, `height=${h},width=${w}`);
          }
        },
        () => { }
      );
    }
  }

  getVotingPopups() {
    return Object.keys(localStorage).filter(key => /^pv:.*$/.test(key));
  }

  getVotingPopupIds() {
    return this.votingPopups.map(p => p.split('pv:')[1]);
  }

  deleteQuestion(votingQuestionId: number) {
    this.modalService.defaultModal({
      title: 'Question Deletion',
      body: 'Are you sure you want to delete this question?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.presentationService.deleteConferencePresentationVoting(this.conferenceId, this.presentationId, votingQuestionId)
              .subscribe(
                () => {
                  this.getPresentationVotings();
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

}
