  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'iframe'">
      <iframe
        *ngIf="_src"
        width="560" height="315"
        [src]="_src"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen>
      </iframe>
    </ng-container>
    <ng-container *ngSwitchCase="'mux'">
      <media-controller autohide="1">
        <video id="video" slot="media" (contextmenu)="onRightClick($event)"></video>
        <media-control-bar class="mobile show-control" slot="top-chrome">
            <media-mute-button class="volume-control" (click)="toggleMute()"></media-mute-button>
            <media-volume-range class="volume-control" (click)="toggleMute()"></media-volume-range>
            <div class="spacer"></div>
            <media-pip-button class="pip-control" (click)="hideThumbnailPlayButton()"></media-pip-button>
            <media-fullscreen-button class="full_screen-control"></media-fullscreen-button>
        </media-control-bar>
        <media-control-bar class="mobile overlay show-control" slot="centered-chrome">
            <!-- <media-seek-backward-button class="seek-control"></media-seek-backward-button> -->
            <media-play-button (click)="hideThumbnailPlayButton();syncLiveStreamPosition($event)"></media-play-button>
            <!-- <media-seek-forward-button class="seek-control"></media-seek-forward-button> -->
        </media-control-bar>
        <media-control-bar class="mobile show-control">
            <!-- <media-time-range class="time_range-control"></media-time-range>
            <media-time-display show-duration class="time-control"></media-time-display> -->
        </media-control-bar>
        <media-control-bar class="desktop show-control">
            <media-play-button (click)="hideThumbnailPlayButton(); syncLiveStreamPosition($event)"></media-play-button>
            <media-mute-button class="volume-control" (click)="toggleMute()"></media-mute-button>
            <media-volume-range class="volume-control" (click)="toggleMute()"></media-volume-range>
            <div style="width: 100%; background-color: rgba(20,20,30, 0.7)"></div>
            <!-- <media-seek-backward-button class="seek-control"></media-seek-backward-button>
            <media-seek-forward-button class="seek-control"></media-seek-forward-button>
            <media-time-range class="time_range-control"></media-time-range>
            <media-time-display show-duration class="time-control"></media-time-display> -->
            <media-pip-button class="pip-control" (click)="hideThumbnailPlayButton()"></media-pip-button>
            <media-fullscreen-button class="full_screen-control"></media-fullscreen-button>
        </media-control-bar>
        <media-mute-button class="volume-control mute-desktop" id="mute-desktop" (click)="toggleMute()">
          <span slot="off">TAP TO UNMUTE</span>
        </media-mute-button>
        <media-loading-indicator no-auto-hide>
          <svg viewBox="0 0 100 100" slot="loading" style="width: 108px" fill="#fff">
            <path d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform>
            </path>
            </svg>
        </media-loading-indicator>
      </media-controller>
      <div class="nv-video-thumbnail__play-button" id="nv-video-thumbnail__play-button">
        <img src="assets/play.svg" alt="">
      </div>
      <div class="nv-video-error__wrapper" id="nv-video-error__wrapper" [class.show]="error"></div>
    </ng-container>
    <ng-container *ngSwitchCase="'mediasite'">
      <iframe
        *ngIf="_src"
        width="560" height="315"
        [src]="_src" 
        frameborder="0" 
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen>
      </iframe>
    </ng-container>
  </ng-container>

  <!-- <mux-video
    style="width:100%;height:100%;"
    id="video"
    [src]="src"
    [metadata-viewer-user-id]="userId"
    [metadata-video-title]="title"
    [metadata-video-id]="materialId"
    [metadata-custom-1]="presentationId"
    [metadata-custom-2]="conferenceId"
    [env-key]="envKey"
    slot="media"
  ></mux-video> -->