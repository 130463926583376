<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Session</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-10">
                <a (click)="delete()" [hidden]="!blockId" class="nv-btn secondary medium" translate>Delete</a>
                <nv-button (click)="close()" color="passive" translate>Close</nv-button>
                <nv-button (click)="save()" [loading]="savingBlock" [disabled]="blockForm.invalid" translate>Save</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port spaced-bottom">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav vertical-break-tab">
                <ul>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program']">Program</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program', 'presentations']">Presentations</a>
                    </li>
                    <li>
                        <a class="active">Sessions</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program', 'locations']">Locations</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
<div class="nv-config-content-port">
    <div class="nv-global-loading on" [class.on]="loadingBlock">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24 small-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form [formGroup]="blockForm" class="nv-row config-plates clean gap-24 small-spaced-top spaced-bottom">
                            <div class="nv-column desk-6">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Basic Information</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="name"
                                                        label="Name"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-6">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        [options]="locations"
                                                        [showSearch]="true"
                                                        (searched)="searchLocations($event)"
                                                        (loadData)="getLocations()"
                                                        (loadMore)="getLocations(true)"
                                                        [addButtonText]="'+ Add Location'"
                                                        (addButtonClick)="createLocation($event)"
                                                        formControlName="location_id"
                                                        label="Location"
                                                        [required]=true
                                                ></nv-select>
                                                <small><a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program', 'locations']">Manage locations</a></small>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-6">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        [options]="blockTypes"
                                                        formControlName="type"
                                                        label="Type"
                                                ></nv-select>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Timing</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-6">
                                            <div class="nv-column-content">
                                                <nv-datepicker
                                                        formControlName="starts_at"
                                                        label="Starts"
                                                        required
                                                ></nv-datepicker>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-6">
                                            <div class="nv-column-content">
                                                <nv-datepicker
                                                        formControlName="ends_at"
                                                        label="Ends"
                                                        required
                                                ></nv-datepicker>
                                                <small *ngIf="blockForm.value.starts_at !== '' && blockForm.value.ends_at === ''">
                                                    Session length:
                                                    <a (click)="setEndTime(5)">5 min</a>,
                                                    <a (click)="setEndTime(10)">10 min</a>,
                                                    <a (click)="setEndTime(15)">15 min</a>,
                                                    <a (click)="setEndTime(30)">30 min</a>,
                                                    <a (click)="setEndTime(45)">45 min</a>, 
                                                    <a (click)="setEndTime(60)">60 min</a>
                                                </small>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="time_info"
                                                        label="Additional Time Info"
                                                ></nv-input>
                                            </div> 
                                        </div>
                                        
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Additional information</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="subtitle"
                                                        label="Subtitle"
                                                ></nv-input>
                                            </div> 
                                        </div>
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        [multi]="true"
                                                        [options]="authors"
                                                        [showSearch]="true"
                                                        (searched)="searchAuthors($event)"
                                                        (loadData)="getAuthors()"
                                                        (loadMore)="getAuthors(true)"
                                                        [addButtonText]="'+ Add Chairperson'"
                                                        (addButtonClick)="createAuthor($event)"
                                                        formControlName="chairperson_ids"
                                                        label="Chairpersons"
                                                ></nv-select>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-6">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        [options]="blockCategories"
                                                        [showSearch]="true"
                                                        (searched)="searchBlockCategories($event)"
                                                        (loadData)="getBlockCategories()"
                                                        (loadMore)="getBlockCategories(true)"
                                                        [addButtonText]="'+ Add Session Categrory'"
                                                        (addButtonClick)="createBlockCategory($event)"
                                                        formControlName="block_category_id"
                                                        label="Session category"
                                                ></nv-select>
                                                <small><a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program', 'block-categories']">Manage session categories</a></small>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-6">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="imported_id"
                                                        label="Reference number"
                                                ></nv-input>
                                                <small>You can use this field to prioritize the order in the program</small>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Certificate points setup</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-9">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="threshold"
                                                        label="Time spent threshold (in minutes)"
                                                ></nv-input>
                                                <small>Minimal time spent on this session to earn points</small>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-4">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="live_cme_points"
                                                        label="Virtual attendance"
                                                ></nv-input>
                                                <small>Points awarded for following live streaming of this session</small>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-4">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="on_demand_cme_points"
                                                        label="On-demand content viewing"
                                                ></nv-input>
                                                <small>Points awarded for watching this session on-demand</small>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-4">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="in_person_cme_points"
                                                        label="In-person attendance"
                                                ></nv-input>
                                                <small>Points awarded for attending this session in-person</small>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-6">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Live</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        
                                        <!-- <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-checkbox
                                                        formControlName="featured"
                                                        label="Break-out session"
                                                ></nv-checkbox>
                                            </div>
                                        </div> -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-switch
                                                        formControlName="streamable"
                                                        label="Add to live program"
                                                ></nv-switch>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->

                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Q&A Settings</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->

                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-switch
                                                        formControlName="allow_qa"
                                                        label="Allow Q&A during the live stream"
                                                ></nv-switch>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <ng-container *ngIf="blockForm.value?.allow_qa">
                                            <div class="nv-column desk-12 tab-12" formGroupName="q_and_a">
                                                <div class="nv-column-content">
                                                    <nv-switch
                                                            label="Show 'Public questions' tab"
                                                            formControlName="public"
                                                    ></nv-switch>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="blockForm.value?.allow_qa && blockForm.value?.q_and_a?.public">
                                            <div class="nv-column desk-12 tab-12" formGroupName="q_and_a">
                                                <div class="nv-column-content">
                                                    <nv-switch
                                                            label="Enable moderation"
                                                            formControlName="moderation"
                                                    ></nv-switch>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Control access rights</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        [options]="minAccessRights"
                                                        formControlName="min_access_right"
                                                        label="Minimal Access Right"
                                                ></nv-select>
                                            </div>
                                        </div>
                                        
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-select
                                                [multi]="true"
                                                [options]="ticketTypes"
                                                formControlName="conference_ticket_type_ids"
                                                label="Available for tickets"
                                                [disabled]="ticketTypes.length === 0"
                                                ></nv-select>
                                                <ng-container *ngIf="ticketing && ticketTypes.length === 0">
                                                    <small><a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'ticket-types']">Please add ticket types</a></small>
                                                </ng-container>
                                                <ng-container *ngIf="!ticketing">
                                                    <small><a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'registration-settings']">Ticketing is not enabled. Please enable ticketing to apply permissions</a></small>
                                                </ng-container>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                    </div>

                                    <form class="nv-row gap-30 propagate" [formGroup]="invitationForm">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Invite moderators</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        
                                        <div class="nv-column desk-9">
                                            <div class="nv-column-content">
                                                <nv-input
                                                    formControlName="email"
                                                    label="Email"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->  
                                        
                                        <div class="nv-column desk-3">
                                            <div class="nv-column-content" style="text-align: right;">
                                                <nv-button 
                                                    (click)="sendInvitation()"
                                                    [loading]="invitingModerators" 
                                                    [disabled]="invitationForm.invalid" 
                                                    translate
                                                >Send</nv-button>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->    
                                        
                                        <div class="nv-column desk-12 gap-24">
                                            <div class="nv-column-content">
                                                <ng-container *ngFor="let invitation of invitations">
                                                    <div class="nv-row small-spaced">
                                                        <div class="nv-column desk-9">
                                                            <div class="nv-column-content">
                                                                <div>{{ invitation.email }}</div>
                                                                <small>{{ invitation.status }}</small>
                                                            </div>
                                                        </div>
                                                        <div class="nv-column desk-3 v-middle">
                                                            <div class="nv-column-content" style="text-align: right;">
                                                                <nv-button 
                                                                    *ngIf="invitation.status === 'sent'"
                                                                    (click)="cancelInvitation(invitation.id)"
                                                                    size="small"
                                                                    translate
                                                                >Cancel</nv-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->     

                                    </form>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
