<div class="nv-modal extra-large">
    <div class="nv-modal-header">
        <h4 class="nv-modal-title">Choose a plan</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body">
        <div class="nv-row">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <div class="nv-onboarding-chunk">
                        <div class="nv-boarding-switch">
                            <div class="bs-choice" (click)="togglePeriod()">
                                <span [class.on]="period === 'month'">Pay Monthly</span>
                                <label class="nv-toggle-switch">
                                    <input type="checkbox" [checked]="period === 'year'" (click)="togglePeriod()">
                                    <span class="ts-visual"></span>
                                </label><!-- /.nv-toggle-switch -->
                                <span [class.on]="period === 'year'">Pay Annually</span>
                            </div><!-- /.bs-choice -->
                            <h5>Save up to 10% with an annual subscription</h5>
                        </div><!-- /.nv-boarding-switch -->

                        <div class="nv-plan-choice-plates">

                            <ng-container *ngFor="let package of packages">
                                <div class="pcp-item" *ngIf="package.period === period">
                                    <div class="pcp-pane">
                                        <div class="pcp-upper">
                                            <!-- <span class="pcp-highlight">MOST POPULAR</span> RECOMMENDED -->
                                            <h2>{{ package.name }}</h2>
                                            <ul class="pcp-capacity">
                                                <li>{{ package.max_delegates }} delegates</li>
                                                <li>{{ package.max_events }} event</li>
                                            </ul><!-- /.pcp-capacity -->
                                            <div class="nv-top-features">
                                                <h4>TOP FEATURES</h4>
                                                <ul>
                                                    <li>Full E-Configurator</li>
                                                    <li>1 Hour Event Maker Consultancy</li>
                                                </ul>
                                            </div><!-- /.nv-top-features -->
                                            <div class="pcp-price">
                                                <h3>&euro; {{ package.price }}<span>/ {{ package.period }}</span></h3>
                                                <span>billed monthly</span>
                                            </div><!-- /.pcp-price -->
                                        </div><!-- /.pcp-upper -->

                                        <a class="nv-btn small fw filled secondary radius-high" (click)="selectPackage(package.id)">Apply Plan</a>
                                    </div><!-- /.pcp-pane -->
                                </div><!-- /.pcp-item -->
                            </ng-container>

                        </div><!-- /.nv-plan-choice-plates -->
                    </div><!-- /.nv-onboarding-chunk -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->

        <div class="nv-row small-spaced-top gap-16 spaced-bottom">
            <ng-container *ngFor="let package of packages">
                <div class="nv-column desk-2-4 tab-none" *ngIf="package.period === period">
                    <div class="nv-column-content">
                        <div class="nv-top-features">
                            <h4>TOP FEATURES</h4>
                            <ul>
                                <li *ngFor="let feature of package.features.en">{{ feature }}</li>
                            </ul>
                        </div><!-- /.nv-top-features -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </ng-container>
        </div>
    </div>
</div>

