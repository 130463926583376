import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DelegateService } from '@navus/core/services/delegate.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { Color } from '@swimlane/ngx-charts';
import { AttendancePresentation, AttendanceSession } from '@navus/core/classes/attendance-session';
import { TimeFormatPipe } from '@navus/ui/pipes/time-format.pipe';

@Component({
  selector: 'nv-delegate-on-demand-attendance',
  templateUrl: './delegate-on-demand-attendance.component.html',
})
export class DelegateOnDemandAttendanceComponent implements OnInit, OnDestroy {
  @Input() templateForTooltip: TemplateRef<void>;

  @Input() conferenceId: number;
  @Input() delegateId: number;

  chartColorScheme = {
    domain: ['#318AF1', '#2873A1', '#8ABAD6', '#32B799', '#279079', '#18707F', '#ED4649', '#F49092', '#AD465A', '#ED8747']
  } as Color;

  loadingAttendance = false;
  attendanceData: AttendanceSession[];
  sessionData: { name: string, value: number }[];
  sessionLegend: { id: number, name: string, value: string, color: string }[];
  sessionActiveEntries: any[] = [];
  selectedSession: AttendanceSession;

  presentationData: { name: string, value: number }[];
  presentationLegend: { name: string, value: string, color: string }[];
  presentationActiveEntries: any[] = [];
  selectedPresentation: AttendancePresentation;

  materialData: { name: string, value: number }[];
  materialLegend: { name: string, value: string, color: string }[];
  materialActiveEntries: any[] = [];

  private subscriptions: Subscription[] = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private delegateService: DelegateService,
              private modalService: ModalService,
              private timeFormatPipe: TimeFormatPipe) {
  }

  ngOnInit() {
    this.getAttendance();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  onSelectSession(event) {
    const chosenSession = typeof event === 'string' ? event : event.name;
    if (chosenSession) {
      this.selectedSession = this.attendanceData
        .find(item => item.name === chosenSession);
      this.presentationData = this.selectedSession?.presentations?.map(item => ({ name: item.title, value: item.time_spent }));

      // Initialize legend
      this.presentationLegend = this.selectedSession?.presentations?.map((item, index) => ({
        name: item.title,
        value: this.timeFormatPipe.transform(item.time_spent),
        color: this.chartColorScheme.domain[index % 10]
      }));

      setTimeout(() => {
        const presentationChart = document.getElementById('on-demand-presentation-chart');
        if (presentationChart) {
          presentationChart.scrollIntoView();
        }
      });
    }
  }

  onSelectPresentation(event) {
    const chosenPresentation = typeof event === 'string' ? event : event.name;
    if (chosenPresentation) {
      this.selectedPresentation = this.selectedSession.presentations
        .find(item => item.title === chosenPresentation);
      this.materialData = this.selectedPresentation?.materials?.map(item => ({ name: item.title, value: item.time_spent }));

      // Initialize legend
      this.materialLegend = this.selectedPresentation?.materials?.map((item, index) => ({
        name: item.title,
        value: this.timeFormatPipe.transform(item.time_spent),
        color: this.chartColorScheme.domain[index % 10]
      }));

      setTimeout(() => {
        const materialChart = document.getElementById('on-demand-material-chart');
        materialChart.scrollIntoView();
      });
    }
  }

  sessionLegendLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.sessionActiveEntries = this.sessionData.filter(item => item.name === name);
  }

  sessionLegendLabelDeactivate(item: any): void {
    this.sessionActiveEntries = [];
  }

  presentationLegendLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.presentationActiveEntries = this.presentationData.filter(item => item.name === name);
  }

  presentationLegendLabelDeactivate(item: any): void {
    this.presentationActiveEntries = [];
  }

  materialLegendLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.materialActiveEntries = this.materialData.filter(item => item.name === name);
  }

  materialLegendLabelDeactivate(item: any): void {
    this.materialActiveEntries = [];
  }

  private getAttendance() {
    this.loadingAttendance = true;
    this.delegateService.getConferenceDelegatesAttendanceOnDemand(this.conferenceId, this.delegateId)
      .subscribe(
        (response: { data: AttendanceSession[] }) => {
          this.attendanceData = response.data;
          
          this.sessionData = this.attendanceData?.filter(item => item.time_spent)
            .map(item => ({ name: item.name, value: item.time_spent }));

          this.sessionLegend = this.attendanceData?.filter(item => item.time_spent)
            .map((item, index) => (
              {
                id: item.id,
                name: item.name,
                value: (this.conferenceId===14340 && item.threshold*60 < item.time_spent) ? "completed" : this.timeFormatPipe.transform(item.time_spent),
                color: this.chartColorScheme.domain[index % 10]
              }
            ));

          this.loadingAttendance = false;
        },
        (error) => {
          this.sessionData = [];
          this.loadingAttendance = false;
        }
      );
  }
}
