<div class="nv-config-control-bar">
    <div class="nv-config-branding-hero">
        <div class="cbh-photo-port">
            <img style="width: 100%; height: 250px; object-fit: cover" src="../../assets/event-cover-page.jpg" alt="{{ conference?.name }}">
            <!--a class="cbh-photo-upload">Upload cover photo</a-->
        </div><!-- /cbh-photo-port -->
        <div class="cbh-logo-n-org">
            <div class="cbh-logo-port nv-clickable" (click)="changeLogo()">
                <div nvBackgroundImage *ngIf="conference?.logo" [image]="conference?.logo"></div>
                <span>ADD LOGO</span>
            </div><!-- /cbh-logo-port -->
            <div class="cbh-org">
                <div class="cbh-org-title">
                    <p>EVENT</p>
                    <h3>{{ conference?.name }}</h3>
                </div><!-- /.cbh-org-title -->
                <div class="cbh-org-edit">
                    <a [routerLink]="['/o', organizationId, 'conference', conferenceId]" title="Edit">Edit</a>
                </div><!-- /.cbh-org-edit -->
            </div><!-- /.cbh-org -->
        </div><!-- /.cbh-logo-n-org -->
    </div><!-- /.nv-config-branding-hero -->
</div>
<div class="nv-config-content-port">
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row gap-24 config-plates clean">
                <div class="nv-column desk-6 tab-12">
                    <div class="nv-column-content">
                        <h4>Module Status:</h4>
                        <div class="nv-config-accordion">
                            <div class="ca-item"
                                 [class.active]="toToItem.status === 'in_progress'"
                                 [class.done]="toToItem.status === 'done'"
                                 *ngFor="let toToItem of toToItems"> <!-- classes: 'active' , 'done' -->
                                <header>
                                    <h3>{{ toToItem.text }}</h3>
                                    <div class="ca-btns">
                                        <nv-button size="small" (click)="toggleStatus(toToItem)">
                                            <span *ngIf="toToItem.status === 'todo'">To Do</span>
                                            <span *ngIf="toToItem.status === 'in_progress'">In Progress</span>
                                            <span *ngIf="toToItem.status === 'done'">Done</span>
                                        </nv-button>
                                    </div><!-- /.ca-btns -->
                                </header>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="nv-column desk-6 phab-12">
                    <div class="nv-column-content">
                        <h4>Demo Data:</h4>

                        <nv-switch [value]="demoData"
                                   (mouseup)="toggleDemoData()"></nv-switch>

                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
