<div class="nv-config-control-bar">
    <div class="cluf-port">
        <div class="config-title-n-tools">
            <div class="tnt-left">
                <h1 translate>Consent Settings</h1>
            </div><!-- /.tnt-left -->
            <div class="tnt-right">
                <div class="nv-btn-box flow-end gap-10"></div><!-- /.nv-btn-box flow-end gap-6 -->
            </div><!-- /.tnt-right -->
        </div><!-- /.config-title-n-tools -->
        <div class="nv-port spaced-bottom">
            <div class="nv-line-tabs guttered">
                <nav class="nv-tabs-nav vertical-break-tab">
                    <ul>
                        <li>
                            <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'ematerials', 'materials']">Materials</a>
                        </li>
                        <li>
                            <a class="active">Consent Settings</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'ematerials', 'video-player-presets']">Video Player Presets</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>
<div class="nv-config-content-port">
    <div class="nv-global-loading on" [class.on]="consentLoading">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <section class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form [formGroup]="consentForm">
                            <h3 class="medium">Set up consent for this event</h3>
                            <p>Consent for each presentation and material can be set up individually</p>
                            <div class="nv-note-bar success" *ngIf="consentForm.value.completed">
                                <p>Consent setup is done, but you can always edit your settings.</p>
                            </div><!-- /.nv-note-bar -->
                            <div class="nv-switch-accordion-item">
                                <header>
                                    <div class="sai-left">
                                        <h3>Publish speaker slides<span class="nv-info-spot inline"></span></h3>
                                    </div><!-- /.sai-left -->
                                    <div class="sai-right"></div><!-- /.sai-right -->
                                </header>
                                <div class="nv-switch-accordion-body">
                                    <nv-switch formControlName="allow_main"
                                               label="Allow Speaker slides to be published on website / mobile app"
                                               style="display: inline-flex; margin-bottom: 10px;"
                                    ></nv-switch>
                                    <nv-switch formControlName="allow_webcast"
                                               label="Allow Webcasts to be published on website / mobile app"
                                    ></nv-switch>
                                </div><!-- /.nv-switch-accordion-body -->
                            </div><!-- /.nv-switch-accordion-item -->

                            <div class="nv-switch-accordion-item">
                                <header>
                                    <div class="sai-left">
                                        <h3>Use disclaimer<span class="nv-info-spot inline"></span></h3>
                                    </div><!-- /.sai-left -->
                                    <div class="sai-right">
                                        <nv-switch formControlName="disclaimer"></nv-switch>
                                    </div><!-- /.sai-right -->
                                </header>
                                <div class="nv-switch-accordion-body" *ngIf="consentForm.value.disclaimer">
                                    <nv-input
                                        type="textarea"
                                        formControlName="disclaimer_text"
                                        label="Disclaimer"
                                    ></nv-input>
                                </div><!-- /.nv-switch-accordion-body -->
                            </div><!-- /.nv-switch-accordion-item -->

                            <div class="nv-switch-accordion-item">
                                <header>
                                    <div class="sai-left">
                                        <h3>Use watermark<span class="nv-info-spot inline"></span></h3>
                                    </div><!-- /.sai-left -->
                                    <div class="sai-right">
                                        <nv-switch formControlName="watermark"></nv-switch>
                                    </div><!-- /.sai-right -->
                                </header>
                                <div class="nv-switch-accordion-body" *ngIf="consentForm.value.watermark">
                                    <nv-file [organizationId]="organizationId"
                                             [mime]="['image/png','image/jpg','image/jpeg']"
                                             formControlName="watermark_path"
                                    ></nv-file>
                                </div><!-- /.nv-switch-accordion-body -->
                            </div><!-- /.nv-switch-accordion-item -->

                            <div class="nv-switch-accordion-item">
                                <header>
                                    <div class="sai-left">
                                        <h3>Override speaker consent<span class="nv-info-spot inline"></span></h3>
                                    </div><!-- /.sai-left -->
                                    <div class="sai-right">
                                        <nv-switch formControlName="override_speaker_consent"></nv-switch>
                                    </div><!-- /.sai-right -->
                                </header>
                            </div><!-- /.nv-switch-accordion-item -->

                            <div class="nv-row gap-24 space-between spaced-top">
                                <div class="nv-column desk-auto v-middle">
                                    <div class="nv-column-content">
                                        <p class="spaceless" *ngIf="!consentForm.value.completed"><strong>Don't need to set up consent? — <a (click)="saveEmpty()">Skip this step and mark it as done</a></strong></p>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-initial">
                                    <div class="nv-column-content">
                                        <nv-button (click)="save()"
                                        >{{ consentForm.value.completed ? 'Save' : 'Finish' }}</nv-button>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                            </div><!-- /.nv-row -->
                        </form>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
            </div>
        </div>
    </section>
</div>
