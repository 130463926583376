import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchSectionConfig } from './search-section.config';

@Component({
  selector: 'nv-search-section-configuration',
  templateUrl: './search-section-configuration-component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchSectionConfigurationComponent),
      multi: true
    }
  ]
})

export class SearchSectionConfigurationComponent implements OnInit, ControlValueAccessor {
  @Input() value: SearchSectionConfig;

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  constructor() { }

  ngOnInit() { }

  writeValue(value: any): void {
    this.value = new SearchSectionConfig(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

}
