<div style="position: relative">
    <div class="nv-global-loading on" [class.on]="programLoading">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->

    <section class="nv-section guttered" *ngIf="program">
        <div class="nv-port">
            <div class="nv-row spaced-top small-spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                          <nv-button *ngIf="autopilot==0" [loading]="loadAutoPilot" (click)="updateAutopilot(1)" translate>Start auto stream now</nv-button>

                          <ng-container *ngIf="autopilot==1">
                            <span>The autopilot streaming is currently active</span>
                            <nv-button (click)="updateAutopilot(0)" [loading]="loadAutoPilot" translate>Stop auto stream now</nv-button>
                          </ng-container>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->
    <section class="nv-section guttered" *ngIf="program">
        <div class="nv-port">
            <div class="nv-row spaced-top small-spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-line-tabs">
                            <nav class="nv-tabs-nav vertical-break-phab">
                                <ul>
                                    <li *ngFor="let day of days" (click)="selectDay(day)">
                                        <a [ngClass]="{'active': (selectedDay === day && !qaTab) }">{{ day | dateFormat:'MMMM' }} {{ day | dateFormat:'DD' }}</a>
                                    </li>
<!--                                    <li *ngIf="showSpeakersTab" class="nv-tab-aside">
                                        <a routerLink="/speakers">Show speakers</a>
                                    </li>-->
                                    <!-- <li class="nv-tab-aside" (click)="showQA()">
                                        <a [ngClass]="{'active': qaTab }">Q&A</a>
                                    </li> -->
                                </ul>
                            </nav><!-- /.nv-tabs-nav -->
                        </div><!-- /.nv-line-tabs -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->

    <main class="nv-section guttered questions" *ngIf="false">
        <div class="nv-port">
            <div class="nv-row spaced no-side-gap">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">

                    </div>
                </div>
            </div>
        </div>
    </main>
    
    <main class="nv-section guttered" *ngIf="program">
        <div class="nv-port">
            <div class="nv-row spaced no-side-gap">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-planner-item" *ngFor="let timeslotDistribution of timeslotDistributions; index as index">
                            <ng-container *ngIf="timeslotDistribution.timeslot.date === selectedDay && !(hideFinished && timeslotDistribution.ends_at < now)">
                                <header class="nv-planner-header">
                                    <div class="ph-time">
                                        <ng-container *ngIf="index === 0 || timeslotDistribution.starts_at.substr(0, 16) !== timeslotDistributions[index - 1].starts_at.substr(0, 16)">
                                            {{ timeslotDistribution.starts_at | dateFormat:'HH:mm' }}
                                        </ng-container>
                                    </div><!-- /.pt-time -->
                                    <div class="ph-body">
                                        <p class="ph-ls" *ngIf="timeslotDistribution.block?.streamable">livestream</p>
                                        <h3>
                                            <a *ngIf="!timeslotDistribution.block && !timeslotDistribution.presentation">{{ timeslotDistribution.default_title }}</a>
                                            <a *ngIf="timeslotDistribution.presentation" (click)="presentationDetails(timeslotDistribution.presentation.id)" class="nv-clickable">{{ timeslotDistribution.presentation.title }}</a>
                                            <!--<a *ngIf="timeslotDistribution.presentation" (click)="changeCurrentStreamingPresentation(timeslotDistribution.presentation)">Stream now</a>-->                                            
                                            
                                            
                                            <a (click)="togglePresentations(timeslotDistribution.block);">{{ timeslotDistribution.block?.name }}</a>
                                        </h3>
                                        <div class="ph-meta">
                                        <span class="phm-time-frame">
                                            {{ timeslotDistribution.starts_at | dateFormat:'HH:mm A' }} - {{ timeslotDistribution.ends_at | dateFormat:'HH:mm A' }} 
                                            <ng-container *ngIf="conference?.settings?.timezone?.title">| {{ conference.settings.timezone.title }}</ng-container>
                                        </span>
                                            <span class="phm-location" *ngIf="timeslotDistribution.timeslot.location">{{ timeslotDistribution.timeslot.location.type }}: {{ timeslotDistribution.timeslot.location.name }}</span>
                                            <span class="phm-cat" *ngIf="timeslotDistribution.block?.block_category_name!='null'">
                                                <em *ngIf="timeslotDistribution.block?.block_category_name" [style.backgroundColor]="timeslotDistribution.block?.block_category_color!='null' ? timeslotDistribution.block?.block_category_color : '#cccccc'"></em>
                                                {{ timeslotDistribution.block?.block_category_name }}
                                            </span>
                                        </div><!-- /.ph-meta -->
                                        <div class="nv-participant-row" *ngIf="timeslotDistribution.block?.chairperson">
                                            <label>Chaired By:</label>

                                            <div class="nv-participant" *ngFor="let chairperson of timeslotDistribution.block.chairperson.split(',') ">
                                                <div class="p-img"><span></span></div>
                                                <div class="p-title-name">
                                                    <p>{{ chairperson }}</p>
                                                </div><!-- /.p-name -->
                                            </div><!-- /.nv-participant -->
                                        </div><!-- /.nv-participant-row -->
                                    </div><!-- /.ph-body -->

                                </header><!-- /.nv-planner-header -->
                                <div class="nv-planner-sessions">
                                    <div *ngIf="blocksLoading.includes(timeslotDistribution.block?.id)" class="nv-loading" style="min-height: 120px;">
                                        <div class="nv-loading-visual">Loading</div>
                                    </div>
                                    <ng-container *ngIf="!blocksLoading.includes(timeslotDistribution.block_id) && timeslotDistribution.block?.presentations">
                                        <div class="ps-item" *ngFor="let presentation of timeslotDistribution.block?.presentations">
                                            <div class="psi-time">
                                                {{ presentation.starts_at | dateFormat:'HH:mm' }}
                                            </div>
                                            <div class="psi-body">
                                                <div class="nv-participant">
                                                    <div class="p-img">
                                                        <span [style.backgroundImage]="presentation.speaker_image_thumb ? 'url('+ presentation.speaker_image_thumb +')' : ''"></span>
                                                    </div>
                                                    <div class="p-title-name">
                                                        <h4>
                                                            <a (click)="presentationDetails(presentation.id)">{{ presentation.title }}</a>
                                                        </h4>
                                                        <p *ngIf="!presentation.speakers || presentation.speakers.length === 0">{{ presentation.speaker_name }}</p>
                                                        <p *ngIf="presentation.speakers && presentation.speakers.length > 0">
                                                            <ng-container *ngFor="let speaker of presentation.speakers">
                                                                <span>{{ speaker.name }};</span>
                                                            </ng-container>
                                                        </p>
                                                        <a (click)="changeCurrentStreamingPresentation(presentation)">Stream now</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="psi-tools" *ngIf="presentation?.streamable">
                                                <p class="psi-ls">livestream</p>
                                                
                                            </div>
                                        </div>
                                        <div *ngIf="timeslotDistribution.block?.presentations.length === 0" class="nv-planner-no-session">
                                            <span>No presentations added to this session</span>
                                        </div>
                                    </ng-container>
                                </div><!-- /.nv-planner-sessions -->
                            </ng-container>
                        </div><!-- /.nv-planner-item -->
                    </div>
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </main><!-- /.nv-section -->
</div>

