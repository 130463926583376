import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { User } from '../navus-core/classes/user';
import { UserService } from '../navus-core/services/user.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OrganizationService } from '../navus-core/services/organization.service';
import { WebsiteService } from '../navus-core/services/website.service';
import { ConferenceService } from '../navus-core/services/conference.service';
import { LeadlinkService } from '../navus-core/services/leadlink.service';
import { ConfigService } from './services/config.service';

@Component({
  selector: 'nv-config',
  templateUrl: './config.component.html'
})
export class ConfigComponent implements OnInit, OnDestroy {
  user: User;
  conferenceId: number;
  hideNavigation: boolean = false;
  hideTools: boolean = false;
  showSidebar: boolean = false;

  subscriptions: Subscription[] = [];

  @HostListener('window:resize', ['$event']) onResize() { this.menuResponse(); }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private organizationService: OrganizationService,
    private websiteService: WebsiteService,
    private conferenceService: ConferenceService,
    private leadlinkService: LeadlinkService,
    private configService: ConfigService
  ) { }

  ngOnInit() {
    // TODO Find better solution - this will work only for one depth
    this.setOrganization(this.route.firstChild.snapshot.params.organizationId);
    this.setWebsite(this.route.firstChild.snapshot.params.organizationId, this.route.firstChild.snapshot.params.websiteId);
    this.setConference(this.route.firstChild.snapshot.params.conferenceId);
    this.setLeadlinkCampaign(this.route.firstChild.snapshot.params.conferenceId, this.route.firstChild.snapshot.params.campaignId);

    const routerSubscription = this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {
          this.setOrganization(this.route.firstChild.snapshot.params.organizationId);
          this.setWebsite(this.route.firstChild.snapshot.params.organizationId, this.route.firstChild.snapshot.params.websiteId);
          this.setConference(this.route.firstChild.snapshot.params.conferenceId);
          this.setLeadlinkCampaign(this.route.firstChild.snapshot.params.conferenceId, this.route.firstChild.snapshot.params.campaignId);
        }
      }
    );
    this.subscriptions.push(routerSubscription);

    // TODO Move this to app
    this.userService.getCurrentUser().subscribe();

    const userSubscription = this.userService.currentUser.subscribe(
      (user: User) => {
        this.user = user;
      }
    );
    this.subscriptions.push(userSubscription);
    this.menuResponse();

    const sidebarSubscription = this.configService.showSidebar.subscribe(showSidebar => this.showSidebar = showSidebar);
    this.subscriptions.push(sidebarSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  setOrganization(organizationId: number) {
    this.organizationService.setCurrentOrganization(organizationId)
      .subscribe(
        () => { },
        () => { }
      );
  }

  setWebsite(organizationId: number, websiteId: number) {
    this.websiteService.setCurrentWebsite(organizationId, websiteId)
      .subscribe(
        () => { },
        () => { }
      );
  }

  setConference(conferenceId: number) {
    this.conferenceService.setCurrentConference(conferenceId)
      .subscribe(
        () => { },
        () => { }
      );
  }

  setLeadlinkCampaign(conferenceId: number, campaignId: number) {
    this.leadlinkService.setCurrentLeadlinkCampaign(conferenceId, campaignId)
      .subscribe(
        () => { },
        () => { }
      );
  }

  toggleNavigation() {
    this.hideNavigation = !this.hideNavigation;
  }

  toggleTools() {
    this.hideTools = !this.hideTools;
  }

  menuResponse() {
    this.hideTools = window.innerWidth <= 1600;
    this.hideNavigation = window.innerWidth <= 1200;
  }

  toggleSideBar() {
    this.configService.toggleSidebar();
  }
}
