import { Component, Input, OnInit } from '@angular/core';
import { Author } from '@navus/core/classes/author';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthorService } from '@navus/core/services/author.service';

@Component({
  selector: 'nv-author-modal',
  templateUrl: './author-modal.component.html'
})
export class AuthorModalComponent implements OnInit {
  @Input() author: Author;
  biography: string | SafeHtml = `<p style="text-align: center">No biography info</p>`;
  thumb = '';

  constructor(
    private activeModal: NgbActiveModal,
    private authorService: AuthorService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    if (this.author) {
      if (this.author.biography) {
        this.biography = this.sanitizer.bypassSecurityTrustHtml(this.author.biography);
      }
      if (this.author.additional_info) {
        this.biography = this.sanitizer.bypassSecurityTrustHtml(this.author.additional_info);
      }
    }
  }

  close() {
    this.activeModal.dismiss();
  }

}
