import { Routes } from '@angular/router';
import { OrganizationGuard } from '../../guards/organization.guard';
import { BadgeDetailsPageComponent } from './pages/badge-details/badge-details.component';
import { BadgeListPageComponent } from './pages/badge-list/badge-list.component';
import { InstructionsPageComponent } from './pages/instructions/instructions.component';
import { LeaderboardPageComponent } from './pages/leaderboard/leaderboard.component';

export const BadgesRoutes: Routes = [
  {
    path: '',
    component: LeaderboardPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Leaderboard'
    }
  },
  {
    path: 'badges',
    component: BadgeListPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Badges'
    }
  },
  {
    path: 'badge/:badgeId',
    component: BadgeDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Badge'
    }
  },
  {
    path: 'badge',
    component: BadgeDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Badge'
    }
  },
  {
    path: 'instructions',
    component: InstructionsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Instructions'
    }
  },
];
