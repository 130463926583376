import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { BaseSectionComponent } from '../_base/base-section.component';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ConferenceService } from '@navus/core/services/conference.service';
import { Router } from '@angular/router';
import { WebsiteService } from '@navus/core/services/website.service';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { WebsiteControlService } from '../../services/website-control.service';
import { Subscription } from 'rxjs';
import { Website } from '@navus/core/classes/website';
import { Conference } from '@navus/core/classes/conference';
import { User } from '@navus/core/classes/user';
import { UserService } from '@navus/core/services/user.service';
import { ApiService } from '../../../shared/services';

@Component({
  selector: 'nv-mediasite-section',
  templateUrl: './mediasite-section.component.html'
})
export class MediasiteSectionComponent extends BaseSectionComponent implements OnInit, OnDestroy {
  @Input() data: WebsitePageSection;
  website: Website;
  conference: Conference;
  subscriptions: Subscription[] = [];
  currentUser: User;
  
  checkingPermissions: boolean = false;
  
  ticketTypes: any[] = [ 'api-user-csanz-navus1', 'api-user-csanz-navus2', 'api-user-csanz-navus3' ];

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService,
    public userService: UserService,
    private sanitizer: DomSanitizer,
    private apiService: ApiService,
    private router: Router,
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );
  }

  
  ngOnInit() {
 
    const websiteSubscription = this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          this.website = website;
        }
      );
    this.subscriptions.push(websiteSubscription);
     
    const userSubscription = this.userService.currentUser
      .subscribe(
        (user: User) => {
          this.currentUser = user;
        }
      );
    this.subscriptions.push(userSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  
  goOnDemand() {
      if (!this.currentUser) {
        this.router.navigate(['/login']);
      } else {
        this.checkingPermissions = true;
        this.pageService
          .getMediasiteLink(8310)
          .subscribe(
            (response) => {
              this.checkingPermissions = false;
              const link = response.data.link + '?authTicket='+response.data.ticket;
              window.open(link, '_blank');
            },
            (error) => {
              this.checkingPermissions = false;
              this.modalService.defaultModal({
                  title: 'Wrong ticket type',
                  body: "Your registration type doesn't give you the access to this content",
                  size: 'small',
                });
            }
          );
      }
  }
}
