<div class="nv-chat-container">
    <div class="public-chat-content">
        <ng-container *ngIf="(active$ | async);else chatNotActive">
            <ng-container *ngIf="currentUserId;else notLoggedIn">
                <div class="options-row">
                    <div class="options">
                        <ng-container *ngIf="currentTab !== 'conversation'">
                            <div class="tab" [ngClass]="{'active': currentTab === 'my_chats'}"
                                 (click)="changeTab('my_chats')">
                                My chats
                            </div>
                            <div class="tab" [ngClass]="{'active': currentTab === 'all_chats'}"
                                 (click)="changeTab('all_chats')">
                                All chats
                            </div>
                            <div class="groups-search">
                                <input type="text" placeholder="Search" [(ngModel)]="searchTerm" (ngModelChange)="searchChats()"/>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="currentTab === 'conversation'">
                            <div class="tab" (click)="changeTab('my_chats')"><span class="chat-bck"></span></div>
                            <div class="tab">{{ selectedRoomName }}</div>
                            <div class="tab" *ngIf="selectedRoomType === RoomType.PUBLIC_CHAT && !selectedRoomAutoJoin"
                                 (click)="leaveGroup(selectedRoomId)">Archive chat
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="options-row" *ngIf="currentTab==='all_chats' && allowGroupChat">
                    <div class="options">
                        <div class="tab" [ngClass]="{'active': (allChatsFilter$ | async) === RoomType.ONE_TO_ONE}"
                             (click)="filterChats(RoomType.ONE_TO_ONE)">
                            1on1
                        </div>
                        <div class="tab" [ngClass]="{'active': (allChatsFilter$ | async) === RoomType.PUBLIC_CHAT}"
                             (click)="filterChats(RoomType.PUBLIC_CHAT)">
                            Groups
                        </div>
                        <div class="tab" [ngClass]="{'active': !(allChatsFilter$ | async)}"
                             (click)="filterChats(null)">
                            All
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div [nvLoading]="{instance:'searchChat'}"></div>

                    <ng-container *ngIf="currentTab !== 'conversation' && !loadingParticipants">
                        <ng-container *ngIf="(chatsInSelectedTab$ | async)?.length">
                            <div class="group"
                                 *ngFor="let availableChat of (chatsInSelectedTab$ | async)"
                                 [ngClass]="{'not-activated': !availableChat.hasActivatedChat && availableChat.type === RoomType.ONE_TO_ONE}"
                                 (click)="startChat(availableChat)">
                                <div class="logo">
                                    <div class="nv-chat-msg-avatar" [ngClass]="{'initials': !availableChat.pictureUrl}">
                                        <span *ngIf="availableChat.pictureUrl" nvBackgroundImage
                                              [image]="availableChat.pictureUrl"></span>
                                        <div *ngIf="!availableChat.pictureUrl && availableChat.type === RoomType.ONE_TO_ONE">{{ getTwoLetterName(availableChat.name) | uppercase}}</div>
                                        <div *ngIf="!availableChat.pictureUrl && availableChat.type !== RoomType.ONE_TO_ONE"
                                             class="nv-joint-group"></div>
                                    </div>
                                </div>
                                <div class="title">
                                    <div class="first-row">{{ availableChat.name || "No name"}}</div>
                                    <div class="second-row">{{ availableChat.description }}</div>
                                    <div class="second-row" *ngIf="!availableChat.hasActivatedChat">Not using chat yet
                                    </div>
                                </div>
                                <div class="unread-count" *ngIf="availableChat.unreadCount">
                                    {{ availableChat.unreadCount }}
                                </div>
                            </div>
                            <ng-container *ngIf="loadMoreParticipantsVisible && currentTab === 'all_chats' && (allChatsFilter$ | async) !== RoomType.PUBLIC_CHAT">
                                <div class="group"
                                     (click)="loadMoreParticipants()">
                                    <div class="title">
                                        <div class="first-row">LOAD MORE DELEGATES</div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="currentTab === 'conversation'">
                        <nv-chat [eventId]="eventId"
                                 replies="true"
                                 canReply="true"
                                 upVotes="true"
                                 canUpVote="true"
                                 (chatLoading)="onChatLoading($event)"
                                 [currentUserId]="currentUserId"
                                 [chatRoomType]="selectedRoomType"
                                 [roomId]="selectedRoomId"
                                 [usecase]="'Chat'"
                                 placeholder="Type a message"
                        ></nv-chat>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>

<ng-template #noGroups>
    NO GROUPS
</ng-template>

<ng-template #notLoggedIn>
    <div class="enable-chat">
        <h5>You have to log in to use chat.</h5>
    </div>
</ng-template>

<ng-template #chatNotActive>
    <div class="enable-chat">
        <nv-button (click)="activateChat()" translate>Enable chat</nv-button>
    </div>
</ng-template>
