<div class="nv-config-vertical-bar-left">
    <div class="nv-config-logo">
        <a href="https://www.navus.io" target="_blank">
            <img src="../../assets/navus-textless-logo.svg" alt>
        </a>
    </div>
    <div class="nv-vertical-tools">
        <div class="nv-config-side-tools" *ngIf="currentOrganization">
            <div class="cst-item settings">
                <a [routerLink]="['/o', currentOrganization.id]"></a>
                <span>Organization Settings</span>
            </div><!-- /.cst-item -->
        </div><!-- /.nv-config-side-tools -->
        <div class="nv-vt-profile" *ngIf="user" nvOffClick (offClick)="showUserMenu = false">
            <div class="vtp-avatar"
                 [class.on]="showUserMenu"
                 (click)="toggleUserMenu()">
                <span nvBackgroundImage [image]="user?.image_url"></span>
            </div> <!-- < toggle class 'on' -->
            <div class="vtp-options-box">
                <div class="vtp-option-chunk">
                    <h4>YOUR PROFILE</h4>
                    <nav>
                        <ul>
                            <!--li><a>User Profile</a></li-->
                            <li>
                                <a href="http://config.navus.io/assets/PDFs/Privacy-and-Cookies-Policy-Navus_March_26_2019.pdf"
                                   target="_blank"
                                >Privacy</a>
                            </li>
                        </ul>
                    </nav>
                </div><!-- /.vtp-option-chunk -->
                <div class="vtp-option-chunk" *ngIf="currentOrganization">
                    <h4>YOUR ORGANIZATION</h4>
                    <nav>
                        <ul>
                            <li>
                                <a [routerLink]="['/o', currentOrganization.id, 'billing']"
                                   (click)="showUserMenu = false"
                                >Billing & Subscription</a></li>
                            <li>
                                <a [routerLink]="['/o', currentOrganization.id, 'users']"
                                   (click)="showUserMenu = false"
                                >Manage Members</a>
                            </li>
                        </ul>
                    </nav>
                </div><!-- /.vtp-option-chunk -->
                <div class="vtp-option-chunk">
                    <nav>
                        <ul>
                            <li><a (click)="logout()">Sign Out</a></li>
                        </ul>
                    </nav>
                </div><!-- /.vtp-option-chunk -->
            </div><!-- /.vtp-options-box -->
        </div><!-- /.nv-vt-profile -->
    </div><!-- /.nv-vertical-tools -->
</div><!-- /.nv-config-vertical-bar-left -->
<div class="nv-config-menu">
    <div class="cm-instance" [class.active]="currentOrganization">
        <div class="nv-cm-static" *ngIf="currentOrganization">
            <div class="cm-organization" nvOffClick (offClick)="showOrganizations = false">
                <div class="cm-current-organization"
                     [class.on]="showOrganizations"
                     [routerLink]="['/o', currentOrganization.id, 'landing']"
                     routerLinkActive="active">
                    <div class="cm-organization-logo">
                        <span nvBackgroundImage [image]="currentOrganization.logo"></span>
                    </div><!-- /.cm-organization-logo -->
                    <div class="cm-organization-label">
                        <h3>{{ currentOrganization.name }}</h3>
                        <p>Organization</p>
                    </div><!-- /.cm-organization-label -->
                    <span (click)="toggleOrganizations()"></span>
                </div><!-- /.cm-current-organisation -->
                <div class="cm-organisation-list">
                    <div class="cm-organization-search">
                        <input (keyup)="getOrganizations()"
                               [(ngModel)]="organizationsSearch"
                               placeholder="Search organizations..."
                               type="text">
                    </div><!-- /.cm-organization-search -->
                    <nav (scrolled)="getOrganizations(true)" nvInfiniteScroll>
                        <ul>
                            <li *ngFor="let organization of organizations">
                                <div class="cm-list-organization" (click)="setOrganization(organization.id)">
                                    <div class="cm-organization-logo">
                                        <span nvBackgroundImage [image]="organization.logo"></span>
                                    </div><!-- /.cm-organization-logo -->
                                    <div class="cm-organization-label">
                                        <h3>{{ organization.name }}</h3>
                                        <p>Organization</p>
                                    </div><!-- /.cm-organization-label -->
                                </div><!-- /cm-list-organization -->
                            </li>
                        </ul>
                    </nav>
                    <a [routerLink]="['/o', 'setup']" class="cm-add">
                        Add organization
                    </a><!-- /.cm-add -->
                </div><!-- /.cm-organisation-list -->
            </div><!-- /.cm-organisation -->
            <div>&nbsp;</div>
        </div><!-- /.nv-cm-static -->
        <div class="nv-cm-scrollable" *ngIf="currentOrganization">
            <div class="nv-config-navs">
                <nav>
                    <ul>
                        <!--li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conferences']"
                               routerLinkActive="active"
                               title="Events">Events</a>
                        </li-->
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'contact', 'web-hub']"
                               routerLinkActive="active"
                               title="Web Hub">Web Hub</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'contact', 'mobile-app-hub']"
                               routerLinkActive="active"
                               title="Multi-event App">Multi-event App</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'files']"
                               routerLinkActive="active"
                               title="Data Room">Data Room</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'articles']"
                               routerLinkActive="active"
                               title="News Manager">News Manager</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'contact', 'event-maker-support']"
                               routerLinkActive="active"
                               title="Navus Team Support">Navus Team Support</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'dashboard']"
                               routerLinkActive="active"
                               title="Dashboard">Dashboard (Coming soon)</a>
                        </li>
                    </ul>
                </nav>
            </div><!-- /.nv-config-navs -->
        </div><!-- /.nv-cm-scrollable -->
    </div><!-- /.cm-instance -->
    <div class="cm-instance" [class.active]="currentConference">
        <div class="nv-cm-static" *ngIf="currentOrganization && currentConference">
            <div class="cm-organization" nvOffClick (offClick)="showConferences = false">
                <div class="cm-current-organization"
                     [class.on]="showConferences"
                     [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'landing']"
                     routerLinkActive="active">
                    <div class="cm-organization-logo">
                        <span nvBackgroundImage [image]="currentConference.logo"></span>
                    </div><!-- /.cm-organization-logo -->
                    <div class="cm-organization-label">
                        <h3>{{ currentConference.name }}</h3>
                        <p>{{ currentConference.type }}</p>
                    </div><!-- /.cm-organization-label -->
                    <span (click)="toggleConferences()"></span>
                </div><!-- /.cm-current-organisation -->
                <div class="cm-organisation-list">
                    <div class="cm-organization-search">
                        <input (keyup)="getConferences()"
                               [(ngModel)]="conferencesSearch"
                               placeholder="Search events..."
                               type="text">
                    </div><!-- /.cm-organization-search -->
                    <nav (scrolled)="getConferences(true)" nvInfiniteScroll>
                        <ul>
                            <li *ngFor="let conference of conferences">
                                <div class="cm-list-organization" (click)="setConference(conference.id)">
                                    <div class="cm-organization-logo">
                                        <span nvBackgroundImage [image]="conference.logo"></span>
                                    </div><!-- /.cm-organization-logo -->
                                    <div class="cm-organization-label">
                                        <h3>{{ conference.name }}</h3>
                                        <p>{{ conference.type }}</p>
                                    </div><!-- /.cm-organization-label -->
                                </div><!-- /cm-list-organization -->
                            </li>
                        </ul>
                    </nav>
                    <a [routerLink]="['/o', currentOrganization.id, 'conference']" class="cm-add">
                        Add event
                    </a><!-- /.cm-add -->
                </div><!-- /.cm-organisation-list -->
            </div><!-- /.cm-organisation -->
            <div class="cm-bck-link">
                <a [routerLink]="['/o', currentOrganization.id, 'landing']">Back to organization</a>
            </div><!-- /.cm-bck-link -->
        </div><!-- /.nv-cm-static -->
        <div class="nv-cm-scrollable" *ngIf="currentOrganization && currentConference">
            <div class="nv-config-navs">
                <nav>
                    <ul>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'website']"
                               routerLinkActive="active"
                               title="Web Portal">Web Portal</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'application']"
                               routerLinkActive="active"
                               title="Mobile Applications">Mobile Applications</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'attendees']"
                               routerLinkActive="active"
                               title="Attendees & Registration">Attendees & Registration</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'program']"
                               routerLinkActive="active"
                               title="Program">Program</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'authors']"
                               routerLinkActive="active"
                               title="Speakers">Speakers</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'sponsors']"
                               routerLinkActive="active"
                               title="Virtual Exhibitors">Virtual Exhibitors</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'ematerials']"
                               routerLinkActive="active"
                               title="E-materials">E-materials</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'livestreaming']"
                               routerLinkActive="active"
                               title="Livestreaming">Livestreaming</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'voting']"
                               routerLinkActive="active"
                               title="Voting">Voting</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'meeting-rooms']"
                               routerLinkActive="active"
                               title="Meeting Rooms">Meeting Rooms</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'communication']"
                               routerLinkActive="active"
                               title="Communication">Communication</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'networking']"
                               routerLinkActive="active"
                               title="Networking">Networking</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'badges']"
                               routerLinkActive="active"
                               title="Badges">Badges</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'dashboard']"
                               routerLinkActive="active"
                               title="Dashboard">Dashboard</a>
                        </li>
                    </ul>
                </nav>
                <h4 *ngIf="false">Special features</h4>
                <nav *ngIf="false">
                    <ul>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'ad']"
                               routerLinkActive="active"
                               title="Ad Platform">Ad Platform
                                <span class="spec" title="Check it out!"></span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'ai']"
                               routerLinkActive="active"
                               title="AI">AI (beta)
                                <span class="spec" title="Check it out!"></span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'contact', 'attendance-tracking']"
                               routerLinkActive="active"
                               title="Attendance Tracking">Attendance Tracking</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'leadlink']"
                               routerLinkActive="active"
                               title="Lead Capture">Lead Capture</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'contact', 'ticket-sales']"
                               routerLinkActive="active"
                               title="Ticket Sales">Ticket Sales</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'contact', 'e-posters']"
                               routerLinkActive="active"
                               title="E-posters">E-posters</a>
                        </li>
                    </ul>
                </nav>
            </div><!-- /.nv-config-navs -->
        </div><!-- /.nv-cm-scrollable -->
    </div><!-- /.cm-instance -->
</div><!-- /.nv-config-menu -->

<div class="nv-config-search-widget">
    <div class="nv-csw-pane">
        <div class="nv-csw-heading">
            <h3>Search</h3>
        </div><!-- /.nv-csw-heading -->
        <form class="nv-csw-input">
            <input name="" type="text">
            <button type="submit"></button>
            <div class="nv-checks-row">
                <div class="nv-cr-item">
                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <nv-checkbox
                                    label="in presentations">
                            </nv-checkbox>
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->
                </div><!-- /.nv-cr-item -->

                <div class="nv-cr-item">
                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <nv-checkbox
                                    label="in abstracts">
                            </nv-checkbox>
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->
                </div><!-- /.nv-cr-item -->

                <div class="nv-cr-item">
                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <nv-checkbox
                                    label="in something">
                            </nv-checkbox>
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->
                </div><!-- /.nv-cr-item -->
            </div><!-- /.nv-row -->
        </form><!-- /.nv-csw-input -->
        <div class="nv-csw-results">
            <ul>
                <li>
                    <h4>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore nulla similique vero</h4>
                    <p>
                        <span><strong>author</strong>Sian McNullty</span>
                        <span><strong>source</strong>Blah blahy 2018</span>
                    </p>
                </li>
                <li>
                    <h4>Lorem ipsum dolor sit amet, consectetur adipisicing elit</h4>
                    <p>
                        <span><strong>author</strong>Sian McNullty</span>
                        <span><strong>source</strong>Blah blahy 2018</span>
                    </p>
                </li>
                <li>
                    <h4>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam!</h4>
                    <p>
                        <span><strong>author</strong>Sian McNullty</span>
                        <span><strong>source</strong>Blah blahy 2018</span>
                    </p>
                </li>
                <li>
                    <h4>Lorem ipsum dolor sit</h4>
                    <p>
                        <span><strong>author</strong>Sian McNullty</span>
                        <span><strong>source</strong>Blah blahy 2018</span>
                    </p>
                </li>
            </ul>
        </div><!-- /.nv-csw-results -->
        <div class="nv-csw-footer">
            <div class="nv-btn-box gap-10">
                <nv-button color="quaternary">Something</nv-button>
                <nv-button color="neutral">Cancel</nv-button>
            </div><!-- /.nv-btn-box gap-20 flow-end -->
        </div><!-- /.nv-csw-footer -->
    </div><!-- /.nv-csw-pane -->
</div><!-- /.nv-config-search-widget -->

