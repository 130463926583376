<div style="height: 100%; overflow: hidden auto;" nvInfiniteScroll (scrolled)="getDelegates(true)">
    <div class="nv-delegates-banner-holder">
        <nv-banner-section [data]="bannerSettings"></nv-banner-section>
    </div><!-- /.nv-delegates-banner-holder -->
    <section class="nv-section guttered nv-author-list-section" *ngIf="user && (!delegate || (delegate && usingTickets && !delegate.conference_ticket_type_id))">
        <div class="nv-port">
            <div class="nv-row spaced-top small-spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content" style="text-align: center;">
                        <p>To view this page you have to be registered delegate for this event.</p>
                        <p *ngIf="!usingRegistration">If you think that this information is not correct, please contact support.</p>
                        <div *ngIf="usingRegistration" class="foo-btn">
                            <a [routerLink]="['/delegate-registration']" class="nv-btn accent1 large">Register here</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="nv-section guttered nv-author-list-section" *ngIf="user && ((!usingTickets && delegate) || (usingTickets && delegate && delegate.conference_ticket_type_id))">
        <div class="nv-port">
            <div class="nv-row spaced-top small-spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content" style="text-align: center;">
                        <h2>{{currentPage?.title}}</h2>
                    </div><!-- /.nv-column-content -->
                </div><!-- /nv-column -->
            </div><!-- /.nv-row -->
            <div class="nv-row flow-center spaced-bottom">
                <div class="nv-column desk-6 tab-12">
                    <div class="nv-column-content">
                        <form class="config-search-bar-w-filters at-delegates" [formGroup]="formFilter" autocomplete="off">
                            <div>
                                <nv-input
                                        [label]="'Search' | translate"
                                        [value]="formFilter.value.search_term"
                                        (keyup.enter)="search($event)"
                                ></nv-input>
                            </div><!-- /.sbwf-port -->
                            <div class="sbwf-filters">
                                <div class="sbwf-trigger" (click)="showFilter = !showFilter">Filters</div>
                            </div><!-- /.sbwf-filters -->
                        </form><!-- /.config-search-bar-w-filters -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column desk-12">
                    <div class="nv-config-filters-row">
                        <span class="cfr-item" *ngFor="let filterPill of filterPills">
                            {{ filterPill.name }}
                            <a (click)="filterRemove(filterPill)"></a>
                        </span>
                    </div>
                </div>
            </div>
            <div class="nv-row small-spaced-top spaced-bottom gap-24 propagate flow-center" style="position: relative;">
                <div class="nv-global-loading on" [class.on]="delegatesLoading">
                    <div class="nv-spinner"></div>
                </div><!-- /.nv-global-loading -->
                <ng-container>
                    <div class="nv-column desk-2-4 lap-3 tab-4 phab-6 phone-12" *ngFor="let delegate of delegates">
                        <div class="nv-column-content" (click)="openModal(delegate)">
                            <div class="nv-speaker-teaser">
                                <div class="st-svatar">
                                    <a title="{{ delegate?.name || delegate.first_name + ' ' + delegate.last_name }}">
                                        <span nvBackgroundImage [image]="delegate.image_url_thumb || delegate.image_url"></span>
                                    </a>
                                </div><!-- /.st-svatar -->
                                <h3>
                                    <a title="{{ delegate?.name || delegate.first_name + ' ' + delegate.last_name }}">{{(delegate?.title && delegate?.title !== "") ? delegate?.title + " " : ""}}{{ delegate?.name || delegate.first_name + ' ' + delegate.last_name }}</a>
                                </h3>
                                <p *ngIf="delegate?.contact_info?.institution && delegate?.contact_info?.institution !== ''">{{ delegate?.contact_info?.institution }}</p>
                            </div><!-- /.delegate-teaser -->
                        </div><!-- /.em-column-content -->
                    </div><!-- /nv-column -->
                </ng-container><!-- /nv-column -->
                <!--<div *ngIf="viewAll" class="nv-column-content" style="text-align: center;">-->
                <!--<a href="delegates" class="nv-btn hollow neutral medium">{{viewAll}}</a>-->
                <!--</div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->
</div>

<form class="nv-side-filters-panel"
    [formGroup]="formFilter"
    [class.on]="showFilter"
>
    <header class="sfp-header">
        <span class="sfp-close" (click)="showFilter = false"></span>
        <h3>Add Filters</h3>
        <p>Changes you make apply to delegate list.</p>
    </header><!-- /.sfp-header -->
    <div class="sfp-body">

        <div class="fb-filter-chunk">
            <div class="fb-filter-box">
                <nv-select
                    label="Country"
                    formControlName="country_ids"
                    [options]="countries"
                    [multi]="true">
                </nv-select>
            </div><!-- /.fb-filter-box -->
        </div><!-- /.fb-filter-chunk -->

    </div><!-- /.sfp-body -->
    <footer class="sfp-footer">
        <label>
            <a (click)="formFilter.reset()">Clear Filters</a>
        </label>
        <div class="sfp-btns">
            <!-- <a href="" class="nv-btn small filled neutral">Cancel</a>
            <a href="" class="nv-btn small filled accent1">Apply</a> -->
        </div>
    </footer><!-- /.sfp-footer -->
</form><!-- /.nv-side-filters-panel -->
