<div class="nv-material-wrap session-single" [nvLoading]>
    <div class="nv-material-view">
        <div *ngIf="presentation" class="nv-material-view-title">
            <h1 class="nv-material-view-header">
                <a class="nmv-bck-to-session" routerLink="/presentation/{{ presentation.id }}">
                    <span>{{ presentation.type === 'Elearning' ? 'Back to the module' : 'Presentation details' | translate }}: {{ presentation.title }}</span>
                </a>
            </h1>
        </div>

        <ng-container *ngIf="material" [ngSwitch]="material.type">
            <ng-container *ngSwitchCase="'main'">
                <div class="nv-material-detail-submenu">
                    <nav>
                        <ul>
<!--                            <li *ngIf="material && highlights" class="mds-add-remove">
                                <span (click)="toggleHighlight()">
                                    <a>{{ slideHighlight ? 'Remove from playlist' : 'Add to playlist' | translate }}</a>
                                </span>
                            </li>-->
                            <li class="mds-download" *ngIf="material?.downloadable" (click)="download()" [class.nv-clickable]="!downloadingMaterial">
                                <span *ngIf="!downloadingMaterial" translate>download</span>
                                <span *ngIf="downloadingMaterial" translate>downloading...</span>
                                <a *ngIf="!downloadingMaterial" class="mds-download-btn" title="{{'download presentation' | translate}}"></a>
                            </li>
                            <li class="mds-audio" *ngIf="presentation && presentation.type === 'Poster' && relatedMaterial && relatedMaterial.type === 'audio'">
                                <span translate>audio</span>
                                <audio controls title="{{'adjust volume' | translate}}">
                                    <source [src]="trustSrc(audioUrl)" type="audio/ogg">
                                    <span translate>Your browser does not support the audio element.</span>
                                </audio>
                            </li>
                            <li class="mds-zoom">
                                <span translate>zoom</span>
                                <div class="mds-zoom-controls">

                                    <a (click)="zoomIn()" title="{{'zoom in' | translate}}" class="z-in"></a>
                                    <a (click)="zoomOut()" title="{{'zoom out' | translate}}" class="z-out"></a>

                                </div><!-- /.mds-zoom-controls -->
                            </li>
                        </ul>
                    </nav>
                    <div class="nmv-author">
                        <figure></figure>
                        <em>{{ presentation?.speaker_name }}</em>
                    </div><!-- /.nmv-author -->
                    <nav *ngIf="materialsImages">
                        <ul class="nmv-pagination">
                            <li class="mds-prev">
                                <a (click)="goPrevious()" title="{{'previous slide' | translate}}"></a>
                            </li>
                            <li class="mds-page">
                                <input type="number" min=1 max="{{ materialsImages.length }}"
                                       [ngModel]="currentPage" (ngModelChange)="setPage(currentPage = $event)">
                                <span> / &nbsp;&nbsp;{{materialsImages.length}}</span>
                            </li>
                            <li class="mds-next">
                                <a (click)="goNext()" title="{{'next slide' | translate}}"></a>
                            </li>
                        </ul>
                    </nav>
                </div><!-- /nv-material-detail-submenu -->
                <figure class="nv-material-preview">
                    <img *ngIf="material.id<34157 && currentPage && materialsImages" [ngStyle]="{'height': scale * 100 + '%'}"
                         src="{{materialsImages[currentPage - 1]}}&token={{currentUser.token}}&resolution=100&quality=100">
                    <img *ngIf="material.id>34156 && currentPage && materialsImages" [ngStyle]="{'height': scale * 100 + '%'}"
                         src="{{materialsImages[currentPage - 1]}}">
                </figure><!-- /nv-material-preview -->
            </ng-container>
            <ng-container *ngSwitchCase="'webcast'">
                <iframe *ngIf="material.external_url" [src]="material.external_url"
                        style="border: none; height: 100%; width: 100%"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                <figure class="nv-material-preview" *ngIf="!material.external_url && material.conference_id!==8282">
                    <nv-video style="width: 100%; height: 100%;"
                        src="{{material.path}}"
                        [materialId]="materialId"
                        [presentationId]="material.presentation_id"
                        [userId]="currentUser?.id"
                    ></nv-video>
                </figure>

                <figure class="nv-material-preview" *ngIf="!material.external_url && material.conference_id===8282">
                    <video controls controlsList="nodownload" style="width: 100%; height: 100%;"
                           src="//service.e-materials.com/api/materials/{{materialId}}/view?token={{currentUser?.token}}"
                    ></video>
                </figure>
            </ng-container>
        </ng-container>

    </div>
</div>

