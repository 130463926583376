import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { SearchService } from '@navus/core/services/search.service';
import { SearchSectionConfig } from './search-section.config';
import { BaseSectionComponent } from '../_base/base-section.component';
import { WebsiteService } from '@navus/core/services/website.service';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { WebsiteControlService } from '../../services/website-control.service';

@Component({
  selector: 'nv-search-section',
  templateUrl: './search-section.component.html'
})
export class SearchSectionComponent extends BaseSectionComponent implements OnInit, OnDestroy {
  @Input() data: WebsitePageSection;
  configuration: SearchSectionConfig = new SearchSectionConfig();
  searchTerm: string = '';
  searchSuggestions: string[] = [];

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService,
    private router: Router,
    private searchService: SearchService
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );
  }

  ngOnInit() {
    this.configuration = new SearchSectionConfig(this.data.configuration);
  }

  ngOnDestroy() {

  }

  reset() {
    this.searchTerm = '';
    this.searchSuggestions = [];
  }

  search(searchTerm: string) {
    this.router.navigate(['/search'], {queryParams: {search_term: searchTerm}});
  }

  searchTermChange(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.router.navigate(['/search'], {queryParams: {search_term: this.searchTerm}});
    } else {
      this.searchService.getSuggestions({term: this.searchTerm})
        .subscribe(
          (searchSuggestions: string[]) => {
            this.searchSuggestions = searchSuggestions;
          },
          () => {
          }
        );
    }
  }

}
