import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class PresentationService {

  constructor(
    private apiService: ApiService
  ) { }

  getPresentations(params: any = {}) {
    return this.apiService.get(`presentations`, params);
  }

  getPresentation(presentationId: number, params: any = {}) {
    return this.apiService.get(`presentations/${presentationId}`, params);
  }

  updatePresentation(presentationId: number, params: any = {}) {
    return this.apiService.put(`presentations/${presentationId}`, params);
  }

  createPresentation(params: any = {}) {
    return this.apiService.post(`presentations`, params);
  }

  deletePresentation(presentationId: number) {
    return this.apiService.delete(`presentations/${presentationId}`);
  }

  createPresentationVote(presentationId: number, params: any = {}) {
    return this.apiService.post(`presentations/${presentationId}/votes`, params);
  }

  deletePresentationVote(presentationId: number, params: any = {}) {
    return this.apiService.delete(`presentations/${presentationId}/votes`, params);
  }

  getConferencePresentations(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/presentations`, params);
  }

  getConferencePresentation(conferenceId: number, presentationId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/presentations/${presentationId}`, params);
  }

  updateConferencePresentation(conferenceId: number, presentationId: number, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/presentations/${presentationId}`, params);
  }

  createConferencePresentation(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/presentations`, params);
  }

  deleteConferencePresentation(conferenceId: number, presentationId: number) {
    return this.apiService.delete(`conferences/${conferenceId}/presentations/${presentationId}`);
  }

  getConferencePresentationVotingQuestions(conferenceId: number, presentationId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/presentations/${presentationId}/votings`, params);
  }

  getRecommendations(params: any = {}) {
    return this.apiService.get(`recommendations`, params);
  }

  getSimilar(presentationId: number, params: any = {}) {
    return this.apiService.get(`similar_presentations/${presentationId}`, params);
  }

  explore(params: any = {}) {
    return this.apiService.get(`explore`, params);
  }

  downloadAbstract(presentationId: number, params: any = {}) {
    return this.apiService.download(`presentations/${presentationId}/abstract`, `abstract_${presentationId}`, params);
  }

  getConferencePresentationVoting(conferenceId: number, presentationId: number, votingQuestionId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/presentations/${presentationId}/votings/${votingQuestionId}`, params);
  }

  updateConferencePresentationVoting(conferenceId: number, presentationId: number, votingQuestionId: number, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/presentations/${presentationId}/votings/${votingQuestionId}`, params);
  }

  createConferencePresentationVoting(conferenceId: number, presentationId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/presentations/${presentationId}/votings`, params);
  }

  deleteConferencePresentationVoting(conferenceId: number, presentationId: number, votingQuestionId: number, params: any = {}) {
    return this.apiService.delete(`conferences/${conferenceId}/presentations/${presentationId}/votings/${votingQuestionId}`, params);
  }

  getMyPresentations(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/presentations/mine`, params);
  }

  getLikedPresentations(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/presentations/liked`, params);
  }

  getTimelinePresentations(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/presentations/timeline`, params);
  }

  getAttendedOnSitePresentations(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/presentations/on_site`, params);
  }

  getAttendedOnDemandPresentations(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/presentations/on_demand`, params);
  }

  getAttendedLivePresentations(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/presentations/live`, params);
  }

  getConferenceMyPresentationMessages(conferenceId: number, presentationId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/presentations/${presentationId}/messages`, params);
  }

  createConferencePresentationMessages(conferenceId: number, presentationId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/presentations/${presentationId}/messages`, params);
  }

}
