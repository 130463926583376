<div class="nv-modal large">
    <div class="nv-modal-header">
        <h4 class="nv-modal-title">Program</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body" style="position: relative;">
        <div [formGroup]="programForm" class="nv-row gap-30 propagate">
            <ng-container formArrayName="days">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h5>Days</h5>
                    </div>
                </div>
                <div class="nv-column desk-12" *ngFor="let item of programForm.controls.days['controls']; let indexDay = index; trackBy: trackBy">
                    <ng-container formGroupName="{{indexDay}}">
                        <div class="nv-column-content">
                            <div class="nv-row gap-10">
                                <div class="nv-column desk-3">
                                    <div class="nv-column-content">
                                        <nv-datepicker
                                                type="calendar"
                                                label="Date"
                                                formControlName="date"
                                                required
                                        ></nv-datepicker>
                                    </div>
                                </div>
                                <div class="nv-column desk-3">
                                    <div class="nv-column-content">
                                        <nv-datepicker
                                                type="timer"
                                                label="Start time"
                                                formControlName="start_time"
                                                required
                                        ></nv-datepicker>
                                    </div>
                                </div>
                                <div class="nv-column desk-3">
                                    <div class="nv-column-content">
                                        <nv-datepicker
                                                type="timer"
                                                label="End time"
                                                formControlName="end_time"
                                                required
                                        ></nv-datepicker>
                                    </div>
                                </div>
                                <div class="nv-column desk-2 flow-right">
                                    <div class="nv-column-content">
                                        <nv-button
                                                [hidden]="programForm.value.days.length === 1"
                                                (click)="removeDay(indexDay)"
                                                color="primary"
                                                onlyIcon
                                                icon="&#xe909;"
                                        ></nv-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <nv-button
                                (click)="addDay()"
                        >Add a day</nv-button>
                    </div>
                </div>
            </ng-container>
            <ng-container formArrayName="locations">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h5>Locations</h5>
                    </div>
                </div>
                <div class="nv-column desk-12" *ngFor="let item of programForm.controls.locations['controls']; let indexLocation = index; trackBy: trackBy">
                    <ng-container formGroupName="{{indexLocation}}">
                        <div class="nv-column-content">
                            <div class="nv-row gap-10">
                                <div class="nv-column desk-3">
                                    <div class="nv-column-content">
                                        <nv-input
                                                label="Name"
                                                formControlName="name"
                                                required
                                        ></nv-input>
                                    </div>
                                </div>
                                <div class="nv-column desk-3">
                                    <div class="nv-column-content">
                                        <nv-colorpicker
                                                label="Color"
                                                formControlName="color"
                                        ></nv-colorpicker>
                                    </div>
                                </div>
                                <div class="nv-column desk-2 flow-right">
                                    <div class="nv-column-content">
                                        <nv-button
                                                [hidden]="programForm.value.locations.length === 1"
                                                (click)="removeLocation(indexLocation)"
                                                color="primary"
                                                onlyIcon
                                                icon="&#xe909;"
                                        ></nv-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <nv-button
                                (click)="addLocation()"
                        >Add a location</nv-button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="nv-modal-footer">
        <div class="nv-btn-box gap-10 flow-end">
            <nv-button color="passive" (click)="close()">Cancel</nv-button>
            <nv-button (click)="save()" [loading]="creatingProgram" [disabled]="programForm.invalid">Create</nv-button>
        </div>
    </div>
</div>
