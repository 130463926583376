import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class BlockCategoryService {

  constructor(
    private apiService: ApiService
  ) { }

  getBlockCategories(params: any = {}) {
    return this.apiService.get(`block_categories`, params);
  }

  getBlockCategory(blockCategoryId: number, params: any = {}) {
    return this.apiService.get(`block_categories/${blockCategoryId}`, params);
  }

  updateBlockCategory(blockCategoryId: number, params: any = {}) {
    return this.apiService.put(`block_categories/${blockCategoryId}`, params);
  }

  createBlockCategory(params: any = {}) {
    return this.apiService.post(`block_categories`, params);
  }

  deleteBlockCategory(blockCategoryId: number) {
    return this.apiService.delete(`block_categories/${blockCategoryId}`);
  }

  getConferenceBlockCategories(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/block_categories`, params);
  }

  getConferenceBlockCategory(conferenceId: number, blockCategoryId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/block_categories/${blockCategoryId}`, params);
  }

  updateConferenceBlockCategory(conferenceId: number, blockCategoryId: number, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/block_categories/${blockCategoryId}`, params);
  }

  createConferenceBlockCategory(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/block_categories`, params);
  }

  deleteConferenceBlockCategory(conferenceId: number, blockCategoryId: number) {
    return this.apiService.delete(`conferences/${conferenceId}/block_categories/${blockCategoryId}`);
  }

}
