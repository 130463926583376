<div class="nv-section guttered">
    <div class="nv-port">
        <div class="nv-row large-spaced gap-40">
            <div class="nv-column desk-6 tab-12">
                <div class="nv-column-content">
                    <div class="nv-widget-box padded">
                        <header class="wb-header">
                            <h2 translate>Recommendations</h2>
                        </header><!-- /.wb-header -->
                        <div class="wb-body" style="position: relative">
                            <div class="nv-global-loading on" [class.on]="loadingRecommendations">
                                <div class="nv-spinner"></div>
                            </div><!-- /.nv-global-loading -->
                            <div class="logged-bar" *ngIf="!currentUser">
                                <p>
                                    <a [routerLink]="['/profile/login']" translate>Sign in</a>
                                    &nbsp;
                                    <span translate>for personalised recommendations</span>
                                </p>
                            </div><!-- /.logged-bar -->
                            <div class="nv-result-item" *ngIf="banner?.resource_url" style="text-align: center">
                                <img [src]="banner?.resource_url" style="max-height: 200px">
                            </div>
                            <div class="nv-result-item" *ngFor="let presentation of presentationsRecommended">
                                <div class="ri-top">
                                    <h3><a [routerLink]="['/presentation', presentation.id]" [title]="presentation.title"  [innerHTML]="presentation.title"></a></h3>
                                    <div class="ri-top-data">
                                        <div class="rtd-item">
                                            <a class="nv-bookmark-trigger nv-clickable"
                                               [class.on]="bookmarkedPresentationIds.includes(presentation.id)"
                                               (click)="toggleBookmark(presentation)"
                                               title="{{'Bookmark this presentation' | translate}}"></a>
                                        </div><!-- /.rtd-item -->
                                    </div><!-- /.ri-top-data -->
                                </div><!-- /ri-top -->
                                <div class="ri-mid">
                                    <div class="rm-left">
                                        <div class="nv-avatar-n-author">
                                            <figure class="ana-img" *ngIf="presentation.speaker_image_thumb" [style.backgroundImage]="'url('+ presentation.speaker_image_thumb +')'"></figure>
                                            <span [innerHTML]="presentation.speaker_name"></span>
                                        </div><!-- /.nv-avatar-n-author -->
                                        <div class="nv-materials-available" *ngIf="presentation.has_main_material || presentation.has_webcast_material || presentation.description">
                                            <p translate>Materials:</p>
                                            <a *ngIf="presentation.has_main_material" class="ma-item material" title="{{'Material available' | translate}}">
                                                <span translate>Material available</span>
                                            </a>
                                            <a *ngIf="presentation.has_webcast_material" class="ma-item webcast" title="{{'Webcast available' | translate}}">
                                                <span translate>Webcast available</span>
                                            </a>
                                            <a *ngIf="presentation.description" class="ma-item abstract" title="{{'Abstract available' | translate}}">
                                                <span translate>Abstract available</span>
                                            </a>
                                        </div><!-- /.nv-materials-available -->
                                    </div><!-- /.rm-left -->
                                </div><!-- /.ri-mid -->
                            </div><!-- /.nv-result-item -->
                        </div><!-- /.wb-body -->
                    </div><!-- /.nv-widget-box -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-6 tab-12">
                <div class="nv-column-content">
                    <div class="nv-widget-box padded" *ngIf="conferences.length > 1 && !configuration.hideConferences">
                        <header class="wb-header">
                            <h2 translate>Events</h2>
                        </header><!-- /.wb-header -->
                        <div class="wb-body">
                            <!--div class="nv-search-bar-plain">
                                <input type="text" placeholder="Enter the name of the event">
                            </div-->

                            <div class="nv-events-row">
                                <ngx-slick-carousel class="carousel er-port" #slickModal="slick-carousel" [config]="conferencesSliderConfig">
                                    <div (click)="goToConferencePresentations(conference)" ngxSlickItem *ngFor="let conference of conferences" class="slide er-item">
                                        <a title="{{conference.name}}"><span [style.background-image]="conference?.logo ? 'url(' + conference?.logo + ')' : ''"></span></a>
                                        <p title="{{conference.name}}">{{conference.name}}</p>
                                    </div>
                                </ngx-slick-carousel>
                            </div>
                        </div><!-- /.wb-body -->
                    </div><!-- /nv-widget-box padded -->
                    <div *ngIf="conferences.length > 1 && !configuration.hideConferences">&nbsp;</div>
                    <div class="nv-widget-box dark padded" *ngIf="summary">
                        <header class="wb-header">
                            <h2 translate>Summary Graphs</h2>
                        </header><!-- /.wb-header -->
                        <div class="wb-body">
                            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="summarySliderConfig">
                                <div ngxSlickItem class="slide">
                                    <div class="nv-graph-presentations-number">
                                        <h4>{{ summary.presTotal }}</h4>
                                        <p translate>PRESENTATIONS</p>
                                    </div><!-- /.nv-graph-presentations-number -->
                                    <div class="nv-graph-values">
                                        <div class="gv-item" *ngFor="let pres of summary.presentation_type; let i = index;">
                                            <div class="gv-bar-n-score">
                                                <div class="gv-bar" [style.width]="(pres.total/summary.presTotal)*100 + '%'" [style.background]="chartColors[i]">
                                                    <div class="gv-score">{{ pres.total }}</div>
                                                </div>
                                            </div><!-- /.gv-bar-n-score -->
                                            <div class="gv-label">
                                                <a [routerLink]="['/search']" [queryParams]="{ type: pres.type }">
                                                    <span>{{ pres.type | translate }}</span>
                                                </a>
                                            </div>
                                        </div><!-- /.gv-item -->
                                    </div>
                                </div>
                                <div ngxSlickItem class="slide">
                                    <div class="nv-graph-presentations-number">
                                        <h4>{{ summary.matTotal }}</h4>
                                        <p translate>MATERIALS</p>
                                    </div><!-- /.nv-graph-presentations-number -->
                                    <div class="nv-graph-values">
                                        <div class="gv-item" *ngFor="let material of summary.material_type; let i = index;">
                                            <div class="gv-bar-n-score">
                                                <div class="gv-bar" [style.width]="(material.total/summary.matTotal)*100 + '%'" [style.background]="chartColors[i]">
                                                    <div class="gv-score">{{ material.total }}</div>
                                                </div>
                                            </div><!-- /.gv-bar-n-score -->
                                            <div class="gv-label">
                                                <a [routerLink]="['/search']" [queryParams]="{ material: material.type }">
                                                    <span>{{ material.type | translate }}</span>
                                                </a>
                                            </div>
                                        </div><!-- /.gv-item -->
                                    </div>
                                </div>
                            </ngx-slick-carousel>
                        </div>
                    </div><!-- /.nv-widget-box -->
                    <div>&nbsp;</div>
                    <div class="nv-widget-box padded white">
                        <header class="wb-header">
                            <h2 translate>Latest</h2>
                        </header><!-- /.wb-header -->
                        <div class="wb-body" style="position: relative">
                            <div class="nv-global-loading on" [class.on]="loadingLatest">
                                <div class="nv-spinner"></div>
                            </div><!-- /.nv-global-loading -->
                            <nav class="nv-latest-presentations">
                                <ul>
                                    <li *ngFor="let presentation of presentationsLatest">
                                        <a [routerLink]="['/presentation', presentation.id]">{{ presentation.title | truncate:['45', '...'] }}</a>
                                        <div class="nv-materials-available" *ngIf="presentation.has_main_material || presentation.has_webcast_material">
                                            <p translate>Materials:</p>
                                            <a *ngIf="presentation.has_main_material" class="ma-item material" title="{{'Material available' | translate}}">
                                                <span translate>Material available</span>
                                            </a>
                                            <a *ngIf="presentation.has_webcast_material" class="ma-item webcast" title="{{'Webcast available'| translate}}">
                                                <span translate>Webcast available</span>
                                            </a>
                                        </div><!-- /.nv-materials-available -->
                                    </li>
                                </ul>
                            </nav><!-- /.nv-latest-presentations -->
                            <p class="nv-skinless-link left">
                                <a routerLink="/search" translate>View all latest content</a>
                            </p>
                        </div><!-- /.wb-body -->
                    </div><!-- /.nv-widget-box -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->

    </div><!-- /.nv-port -->
</div><!-- /.nv-section -->
