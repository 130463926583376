<div class="nv-config-control-bar">
    <div class="cluf-port">
        <div class="config-title-n-tools">
            <div class="tnt-left">
                <h1 translate>Web Hub</h1>
            </div><!-- /.tnt-left -->
            <div class="tnt-right"></div><!-- /.tnt-right -->
        </div><!-- /.config-title-n-tools -->
    </div>
</div>
<div class="nv-config-content-port">
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form [formGroup]="webHubForm">
                            <div class="nv-row config-plates clean gap-24 propagate">
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <h4>Give great visibility to your events, create a hub for content and keep your audience engaged!</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-row config-plates clean gap-24 propagate">
                                <div class="nv-column desk-4 tab-12">
                                    <div class="nv-column-content">
                                        <nv-slides>
                                            <nv-slide style="text-align: center">
                                                <img style="width: 80%;" src="../../../../../assets/web-hub-slide-1.jpg" alt>
                                            </nv-slide>
                                            <nv-slide style="text-align: center">
                                                <img style="width: 80%;" src="../../../../../assets/web-hub-slide-2.jpg" alt>
                                            </nv-slide>
                                        </nv-slides>
                                    </div>
                                </div>
                                <div class="nv-column desk-8 tab-12">
                                    <div class="nv-column-content">
                                        <ul>
                                            <li>Collect and group all content from all your events under one roof.</li>
                                            <li>Publish all your materials in one place – presentations, e-learning modules, e-posters, abstracts, summary articles, publications, news, etc.</li>
                                            <li>Provide Instant and automated e-education 365 days a year through the main Hub.</li>
                                            <li>Smart Search: AI search function with keywords.</li>
                                            <li>Smart Filters.</li>
                                            <li>Personal recommendations for users based on more than 10 algorithms.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-row config-plates clean gap-24 propagate">
                                <div class="nv-column desk-6 phab-12">
                                    <div class="nv-column-content nv-promo-feature npf1">
                                        <h4>Hub Access Control</h4>
                                        <p>Assign different access right to different groups:</p>
                                        <ul>
                                            <li><strong>Delegates</strong></li>
                                            <li><strong>Members</strong></li>
                                            <li><strong>Guests</strong></li>
                                            <li><strong>etc.</strong></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="nv-column desk-6 phab-12">
                                    <div class="nv-column-content nv-promo-feature npf2">
                                        <h4>Sponsorship</h4>
                                        <p>
                                            Offer sponsorship spaces of the Hub to industry partners.
                                        </p>
                                        <p>
                                            To get unlimited use of all digital ad/sponsorship spaces, upgrade to Premium for <strong>€4.90 per attendee</strong> for any selected event.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-row config-plates clean gap-24 propagate">
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <span>Please select the events that you want to include in your central searchable hub and we will get back with a quote for you.</span>
                                    </div>
                                </div>
                                <div class="nv-column desk-6 tab-12" formGroupName="options">
                                    <div class="nv-column-content">
                                        <nv-select
                                                [options]="conferences"
                                                [multi]="true"
                                                formControlName="conference_ids"
                                                label="Select existing events"
                                                required
                                        ></nv-select>
                                    </div>
                                </div>
                                <div class="nv-column desk-6 tab-12">
                                    <div class="nv-column-content">
                                        <nv-button
                                                (click)="submitWebHubForm()"
                                                [disabled]="webHubForm.invalid"
                                        >Submit</nv-button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
