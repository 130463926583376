import { Component, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { WebsiteService } from '@navus/core/services/website.service';
import { Website } from '@navus/core/classes/website';
import { WebsitePage } from '@navus/core/classes/website-page';
import { Delegate } from '@navus/core/classes/delegate';
import { PaginationConfig } from '@navus/ui/pagination/classes/pagination-config';
import { DelegateService } from '@navus/core/services/delegate.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DelegateModalComponent } from '../../modals/delegate/delegate-modal.component';
import { Country } from '@navus/core/classes/country';
import { CountryService } from '@navus/core/services/country.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@navus/core/services/user.service';
import { User } from '@navus/core/classes/user';
import { Conference } from '@navus/core/classes/conference';


@Component
({
  selector: 'nv-delegate-list-page',
  templateUrl: './delegate-list-page.component.html'
})
export class DelegateListPageComponent implements OnInit, OnDestroy {
  [x: string]: any;

  conferenceId: number;
  conference: Conference;
  currentPage: WebsitePage = null;
  delegates: Delegate[];
  countries: Country[] = [];
  pagination: any = {
    page: 1,
    perPage: 20
  };

  bannerSettings: any = {
    configuration: {
      position: 'Search'
    }
  };

  formFilter: FormGroup;
  filterPills: any[] = [];

  user: User;
  delegate: Delegate;

  usingTickets = false;
  usingRegistration = false;

  delegatesLoading: boolean = true;
  subscriptions: Subscription[] = [];
  constructor(
    private websiteService: WebsiteService,
    private delegateService: DelegateService,
    private countryService: CountryService,
    private userService: UserService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private _sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      search_term: [''],
      country_ids: ['']
    });

    this.getCountries();
    
    const subscription = this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          this.conferenceId = website.active_conference_id;
          this.conference = website.conferences.find(c => c.id === this.conferenceId);
          this.bannerSettings.configuration.conference_id = this.conferenceId;
          this.currentPage = website.pages.filter(x => x.type === 'delegates')[0];
        },
        () => { }
      );

    const userSubscription = this.userService.currentUser
        .subscribe(
          (user) => {
            if(user) {
              this.user = user;
              this.delegate = user.delegates.find(d => d.conference_id === this.conferenceId);
              this.usingTickets = this.conference.settings.delegate_registration?.ticketing;
              this.usingRegistration = this.conference.settings.delegate_registration?.enabled;
            }
          },
          () => {}
        );

    this.route.queryParams
      .subscribe((queryParams) => {
        const params: any = {};
        if(queryParams?.search_term) { 
          params.search_term =queryParams.search_term; 
        }
        if(queryParams?.country_ids) { 
          params.country_ids = queryParams.country_ids.split(',').map(Number);
        }
        this.formFilter.patchValue(params);
        this.filterChange(this.formFilter.value)
      })

     this.formFilter.valueChanges
      .subscribe(
          (value) => { 
            const selectedFilters: any = {};
            if(value.search_term?.length > 0) { selectedFilters.search_term = value.search_term; }
            if(value.country_ids?.length > 0) { selectedFilters.country_ids = value.country_ids.join(','); }
            this.router.navigate([], {queryParams: selectedFilters, relativeTo: this.route});
          }
        )

    this.subscriptions.push(subscription, userSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getDelegates(append: boolean = false) {
    if (append && this.pagination.total && this.delegates.length >= this.pagination.total) { return; }

    this.pagination.page = append ? this.pagination.page + 1 : 1;

    const params: any = {
      page: this.pagination.page,
      per_page: this.pagination.perPage,
      sort_by: 'first_name',
      sort_direction: 'asc'
    };
    if (this.formFilter.value.search_term && this.formFilter.value.search_term !== '') {
      params.search_term = this.formFilter.value.search_term;
    }
    if (this.formFilter.value.country_ids) {
      params.country_ids = this.formFilter.value.country_ids.join(',');
    }
    this.delegatesLoading = !append;
    this.delegateService.getConferenceChatDelegates(this.conferenceId, params)
      .subscribe(
        (response: { data: Delegate[], meta: any }) => {
          if (append) {
            this.delegates = this.delegates.concat(response.data);
          } else {
            this.delegates = response.data;
            this.pagination.total = response.meta.pagination.total;
          }
          this.delegatesLoading = false;
        },
        () => {
          this.delegatesLoading = false;
        }
      );
  }

  getCountries() {
    this.countryService
      .getCountries()
      .subscribe(
        (response) => {
          this.countries = response['data'];
          if (this.formFilter.value.country_ids) {
            const countries = this.countries.filter(c => this.formFilter.value.country_ids.includes(c.id));
            countries.forEach(
              country => {
                this.filterPills.push({
                  id: country.id,
                  type: 'country',
                  name: country.name
                })          
              }
            )
          }
        },
        () => {

        }
      );
  }

  openModal(delegate: Delegate) {
    const modalRef = this.modalService.open(DelegateModalComponent);
    modalRef.componentInstance.delegate = delegate;
  }

  public getDelegateImage(delegate) {
    const image = delegate.image_url_thumb !== null ? delegate.image_url_thumb : delegate.image_url;
    if (image === null) {
      return null;
    } else {
      return this._sanitizer.bypassSecurityTrustStyle(`url(${image})`);
    }
  }

  paginationChange(paginationConfig: PaginationConfig) {
    this.pagination.current_page = paginationConfig.current_page;
    this.getDelegates();
  }

  filterChange(value) {
    this.filterPills = [];
    
    if (value.country_ids) {
      const countries = this.countries.filter(c => value.country_ids.includes(c.id));
      countries.forEach(
        country => {
          this.filterPills.push({
            id: country.id,
            type: 'country',
            name: country.name
          })          
        }
      )
    }

    this.getDelegates();
  }

  filterRemove(filterPill) {
    if (filterPill.type === 'country') {
      let countries = this.formFilter.get('country_ids').value || [];
      countries = countries.filter(c => c != filterPill.id);
      this.formFilter.get('country_ids').setValue(countries);
    }
  }

  search(event) {
    this.formFilter.get('search_term').setValue(event.target.value);
  }

}
