    <div class="nv-page-wrap">
    <div class="nv-section guttered grid-demo">
        <div class="nv-port">
            <div class="nv-row gap-30 large-spaced">
                <div class="nv-column desk-6" style="overflow:hidden;">
                    <div class="nv-column-content">
                        <h1>Sekcije i portovi</h1>

                        <h2>Struktura SVAKE sekcije, osnovni layout block</h2>
                        <code class="block">
                        <pre>
&lt;section class="nv-section guttered"&gt;
    &lt;div class="nv-port"&gt;
        &lt;div class="nv-row"&gt;
            &lt;div class="nv-column desk-12"&gt;
                &lt;div class="nv-column-content"&gt;
                    Content...
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /nv-column --&gt;
        &lt;/div&gt;&lt;!-- /.nv-row --&gt;
    &lt;/div&gt;&lt;!-- /nv-port --&gt;
&lt;/section&gt;

                        </pre>
                        </code>
                        <h2>Opcije sekcije</h2>
                        <p>
                            Mozhe biti bilo koji tag umestio 'section' samo da ima klasu 'nv-section'
                            <code>
                                &lt;section class="nv-section"&gt;&lt;/section>
                            </code>
                        </p>
                        <p>
                            Shiroka <code>&lt;section class="nv-section"&gt;&lt;section&gt;</code> ili u gutteru <code>&lt;section class="nv-section guttered"&gt;&lt;/section&gt;</code>.
                        </p>

                        <span class="nv-divider desk-medium"></span>

                        <h3>Opcije sekcije FULL SCREEN, dodatna klasa 'full-screen'</h3>
                        <p>Podrazumeva da sekcija ima minimalno visinu porta, ukoliko postoji kontent vechi od te visine biche i vecha.</p>
                        <p>U tom sluchaju 'port' odnosno kontent je centriran po vertikali.</p>
                        <code class="block">
                        <pre>
&lt;section class="nv-section guttered full-screen"&gt;
    &lt;div class="nv-port"&gt;
        &lt;div class="nv-row"&gt;
            &lt;div class="nv-column desk-12"&gt;
                &lt;div class="nv-column-content"&gt;
                    Content...
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /nv-column --&gt;
        &lt;/div&gt;&lt;!-- /.nv-row --&gt;
    &lt;/div&gt;&lt;!-- /nv-port --&gt;
&lt;/section&gt;
                        </pre>
                        </code>
                        <p><a target="_blank" href="http://warehouse369.com/tmp/navus/grid-options/full-screen.html">primer</a></p>

                        <span class="nv-divider desk-medium"></span>

                        <h3>Pozadinska slika</h3>
                        <p>Ukoliko se inline doda pozadinska slika <code>style="background-image:url(https://unsplash.it/1900/900);"</code>, podrazumeva se centriranje slike i 'cover' velichina, sto je i potrebno u 95% sluchajeva. Ako treba nesto drugo, ide custom klasa i css.</p>
                        <code class="block">
                        <pre>
&lt;section class="nv-section guttered full-screen" style="background-image:url(https://unsplash.it/1900/900);"&gt;
    &lt;div class="nv-port"&gt;
        &lt;div class="nv-row"&gt;
            &lt;div class="nv-column desk-12"&gt;
                &lt;div class="nv-column-content"&gt;
                    Content...
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /nv-column --&gt;
        &lt;/div&gt;&lt;!-- /.nv-row --&gt;
    &lt;/div&gt;&lt;!-- /nv-port --&gt;
&lt;/section&gt;
                        </pre>
                        </code>
                        <p><a target="_blank" href="http://warehouse369.com/tmp/navus/grid-options/background-image.html">primer</a></p>

                        <span class="nv-divider desk-medium"></span>

                        <h3>Overlay sekcije</h3>
                        <p>Sekcije mogu imati defaultni overlay <code>&lt;section class="nv-section dark-overlay"&gt;</code> ili <code>&lt;section class="nv-section light-overlay"&gt;</code> za brzpotezno korigovanje kontrasta slike i texta.</p>
                        <code class="block">
                        <pre>
&lt;section class="nv-section guttered full-screen dark-overlay" style="background-image:url(https://unsplash.it/1900/900);"&gt;
    &lt;div class="nv-port"&gt;
        &lt;div class="nv-row"&gt;
            &lt;div class="nv-column desk-12"&gt;
                &lt;div class="nv-column-content"&gt;
                    Content...
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /nv-column --&gt;
        &lt;/div&gt;&lt;!-- /.nv-row --&gt;
    &lt;/div&gt;&lt;!-- /nv-port --&gt;
&lt;/section&gt;
                        </pre>
                        </code>

                        <p><a target="_blank" href="http://warehouse369.com/tmp/navus/grid-options/overlay.html">primer</a></p>

                        <span class="nv-divider desk-medium"></span>


                        <h3>Video sekcije</h3>
                        <p>Video u pozadini sekcije <code>&lt;section class="nv-section video full-screen"&gt;</code>. Ochekuje se 16:9 ali ne more. Ne mora ni "full-screen", onda se vidi toliko parche koliko postoji contenta. Moze i iframe umesto video taga.</p>
                        <code class="block">
                        <pre>
&lt;section class="nv-section guttered full-screen video"&gt;
    &lt;div class="nv-port"&gt;
        &lt;div class="nv-row gap-20 large-spaced"&gt;
            &lt;div class="nv-column desk-12 gap-20"&gt;
                &lt;div class="nv-column-content" style="color:yellow;"&gt;
                    &lt;p&gt;Lorem ipsum dolor sit amet, consect facere quo reiciendis.&lt;/p&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
        &lt;/div&gt;&lt;!-- /.nv-row --&gt;
    &lt;/div&gt;&lt;!-- /.nv-port --&gt;
    &lt;div class="nv-video"&gt;
        &lt;div class="nv-video-ports"&gt;
            &lt;video width="320" height="240" autoplay loop muted&gt;
                &lt;source src="gfx/video.mp4" type="video/mp4"&gt;
                &lt;source src="gfx/video.mp4" type="video/ogg"&gt;
                Your browser does not support the video tag.
            &lt;/video&gt;
        &lt;/div&gt;&lt;!-- /.nv-video-ports --&gt;
    &lt;/div&gt;&lt;!-- /.nv-video --&gt;
&lt;/section&gt;&lt;!-- /.nv-section --&gt;
                        </pre>
                        </code>

                        <p><a target="_blank" href="http://warehouse369.com/tmp/navus/grid-options/video.html">primer</a></p>

                        <span class="nv-divider desk-medium"></span>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->

                <div class="nv-column desk-6" style="overflow:hidden;">
                    <div class="nv-column-content">
                        <h1>Redovi i kolone</h1>
                        <h2>Redovi</h2>
                        <h3>Vertikalni prostor redova</h3>
                        <p>'nv-row' ima dodatne klase za vertikalni prostor, npr. <code>&lt;div class="nv-row small-spaced"&gt;</code> koji podrazumeva gornji i donji 'small' padding. Mozhe da ima i samo gornji <code>&lt;div class="nv-row small-spaced-top"&gt;</code> ili donji <code>&lt;div class="nv-row small-spaced-bottom"&gt;</code>.</p>
                        <p>Sufix je uvek isti '-spaced' a postoje sledeche gradacije: <code>small-, semi-, large-, extra-large-, giant- i bez prefixa, samo 'spaced' odnosno 'sapced-top' ili 'spaced-bottom'.</code></p>
                        <code class="block">
                        <pre>
&lt;section class="nv-section guttered" style="background-color:#dcf1ff;"&gt;
    &lt;div class="nv-port"&gt;
        &lt;div class="nv-row gap-20 large-spaced"&gt;
            &lt;div class="nv-column desk-12 gap-20"&gt;
                &lt;div class="nv-column-content"&gt;
                    &lt;p&gt;Lorem ipsum dolor sit amet, consect facere quo reiciendis.&lt;/p&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
        &lt;/div&gt;&lt;!-- /.nv-row --&gt;
    &lt;/div&gt;&lt;!-- /.nv-port --&gt;
&lt;/section&gt;&lt;!-- /.nv-section --&gt;
&lt;section class="nv-section guttered" style="background-color:#ffffdc;"&gt;
    &lt;div class="nv-port"&gt;
        &lt;div class="nv-row gap-20 small-spaced"&gt;
            &lt;div class="nv-column desk-12 gap-20"&gt;
                &lt;div class="nv-column-content"&gt;
                    &lt;p&gt;Lorem ipsum dolor sit amet, consect facere quo reiciendis.&lt;/p&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
        &lt;/div&gt;&lt;!-- /.nv-row --&gt;
    &lt;/div&gt;&lt;!-- /.nv-port --&gt;
&lt;/section&gt;&lt;!-- /.nv-section --&gt;
&lt;section class="nv-section guttered" style="background-color:#c6f4c9;"&gt;
    &lt;div class="nv-port"&gt;
        &lt;div class="nv-row gap-20 extra-large-spaced-gap"&gt;
            &lt;div class="nv-column desk-12 gap-20"&gt;
                &lt;div class="nv-column-content"&gt;
                    &lt;p&gt;Lorem ipsum dolor sit amet, consect facere quo reiciendis.&lt;/p&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
        &lt;/div&gt;&lt;!-- /.nv-row --&gt;
    &lt;/div&gt;&lt;!-- /.nv-port --&gt;
&lt;/section&gt;&lt;!-- /.nv-section --&gt;
                        </pre>
                        </code>

                        <p><a target="_blank" href="http://warehouse369.com/tmp/navus/grid-options/row-space.html">primer</a></p>

                        <span class="nv-divider desk-medium"></span>

                        <h3>Horizontalni prostor izmedju kolona</h3>
                        <p>Na nv-row se definishe prostor izmedju kolona, primer <code>&lt;div class="nv-row gap-20 large-spaced"&gt;</code> podrazumeva prostor od 20px izmedju svake kolone. Moguche vrednosti su: <code>gap-2, gap-8, gap-10, gap-14, gap-18, gap-20, gap-24, gap-30, gap-40, gap-50, gap-60</code>.</p>
                        <code class="block">
                        <pre>
&lt;section class="nv-section guttered" style="background-color:#dcf1ff;"&gt;
    &lt;div class="nv-port"&gt;
        &lt;div class="nv-row gap-20 large-spaced"&gt;
            &lt;div class="nv-column desk-2-4 lap-3 tab-4 phab-6"&gt;
                &lt;div class="nv-column-content"&gt;
					&lt;div style="height:200px;background-color:red;"&gt;&lt;/div&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
			&lt;div class="nv-column desk-2-4 lap-3 tab-4 phab-6"&gt;
                &lt;div class="nv-column-content"&gt;
					&lt;div style="height:200px;background-color:red;"&gt;&lt;/div&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
			&lt;div class="nv-column desk-2-4 lap-3 tab-4 phab-6"&gt;
                &lt;div class="nv-column-content"&gt;
					&lt;div style="height:200px;background-color:red;"&gt;&lt;/div&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
			&lt;div class="nv-column desk-2-4 lap-3 tab-4 phab-6"&gt;
                &lt;div class="nv-column-content"&gt;
					&lt;div style="height:200px;background-color:red;"&gt;&lt;/div&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
			&lt;div class="nv-column desk-2-4 lap-3 tab-4 phab-6"&gt;
                &lt;div class="nv-column-content"&gt;
					&lt;div style="height:200px;background-color:red;"&gt;&lt;/div&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
        &lt;/div&gt;&lt;!-- /.nv-row --&gt;
		&lt;div class="nv-row gap-40 large-spaced"&gt;
            &lt;div class="nv-column desk-2-4 lap-3 tab-4 phab-6"&gt;
                &lt;div class="nv-column-content"&gt;
					&lt;div style="height:200px;background-color:red;"&gt;&lt;/div&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
			&lt;div class="nv-column desk-2-4 lap-3 tab-4 phab-6"&gt;
                &lt;div class="nv-column-content"&gt;
					&lt;div style="height:200px;background-color:red;"&gt;&lt;/div&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
			&lt;div class="nv-column desk-2-4 lap-3 tab-4 phab-6"&gt;
                &lt;div class="nv-column-content"&gt;
					&lt;div style="height:200px;background-color:red;"&gt;&lt;/div&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
			&lt;div class="nv-column desk-2-4 lap-3 tab-4 phab-6"&gt;
                &lt;div class="nv-column-content"&gt;
					&lt;div style="height:200px;background-color:red;"&gt;&lt;/div&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
			&lt;div class="nv-column desk-2-4 lap-3 tab-4 phab-6"&gt;
                &lt;div class="nv-column-content"&gt;
					&lt;div style="height:200px;background-color:red;"&gt;&lt;/div&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
        &lt;/div&gt;&lt;!-- /.nv-row --&gt;
    &lt;/div&gt;&lt;!-- /.nv-port --&gt;
&lt;/section&gt;&lt;!-- /.nv-section --&gt;
                        </pre>
                        </code>
                        <p><a target="_blank" href="http://warehouse369.com/tmp/navus/grid-options/column-space.html">primer</a></p>
                        <span class="nv-divider desk-medium"></span>

                        <h3>Vertikalni aligment</h3>
                        <p>Kada se na nv-row doda klasa v-stretch: <code>&lt;div class="nv-row gap-20 v-stretch large-spaced"&gt;</code> sve kolone che ispratiti visinu najvishlje u redu. Klasa v-stretch je neopohdna ukoliko je unutar reda potreban vertikalni alajment neke kolone.</p>
                        <p>Kombinacija v-stretch na redu i neka od klasa <code>v-top v-middle v-bottom</code> na koloni odredjuje njenu vertikalnu poziciju.</p>
                        <code class="block">
                        <pre>
&lt;section class="nv-section guttered"&gt;
    &lt;div class="nv-port"&gt;
        &lt;div class="nv-row gap-20 v-stretch large-spaced"&gt;
            &lt;div class="nv-column desk-3 tab-4 phab-6"&gt;
                &lt;div class="nv-column-content"&gt;
					&lt;div style="height:300px;background-color:red;"&gt;&lt;/div&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
			&lt;div class="nv-column desk-3 tab-4 phab-6 v-bottom"&gt;
                &lt;div class="nv-column-content"&gt;
					&lt;div style="height:150px;background-color:red;"&gt;&lt;/div&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
			&lt;div class="nv-column desk-3 tab-4 phab-6 v-middle"&gt;
                &lt;div class="nv-column-content"&gt;
					&lt;div style="height:150px;background-color:red;"&gt;&lt;/div&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
			&lt;div class="nv-column desk-3 tab-4 phab-6"&gt;
                &lt;div class="nv-column-content"&gt;
					&lt;div style="height:300px;background-color:red;"&gt;&lt;/div&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
        &lt;/div&gt;&lt;!-- /.nv-row --&gt;
    &lt;/div&gt;&lt;!-- /.nv-port --&gt;
&lt;/section&gt;&lt;!-- /.nv-section --&gt;
                        </pre>
                        </code>
                        <p><a target="_blank" href="http://warehouse369.com/tmp/navus/grid-options/vertical-aligment.html">primer</a></p>
                        <span class="nv-divider desk-medium"></span>
                        <h3>Kolone</h3>
                        <p>Grid je baziran na 12 kolona i postoje 4 breakpoint-a, <code>laptop-screen</code> do 1400px, <code>tablet-screen</code> do 992px, <code>phablet-screen</code> do 580px, <code>phone-screen</code> do 370px.</p>
                        <p>Na <code>nv-column</code> se pishe prefix tih BP-a i vrednost za udeo prostora koliko se kolona prostire.</p>
                        <p>Primeri za raspored na preko 1400px: <code>desk-12 desk-9 desk-8 desk-6 desk-4 desk-3 desk-2-4 desk-2 desk-1 desk-none</code> Ovaj <code>*-none</code> je da kolona nestane na odredjenom rasponu.</p>
                        <p>Primeri kolone pune shirine za svaki BP: <code>desk-12 lap-12 tab-12 phab-12 phone-12</code></p>
                        <code class="block">
                        <pre>
&lt;section class="nv-section guttered" style="background-color:#dcf1ff;"&gt;
    &lt;div class="nv-port"&gt;
        &lt;div class="nv-row gap-20 large-spaced"&gt;
            &lt;div class="nv-column desk-2-4 lap-3 tab-4 phab-6"&gt;
                &lt;div class="nv-column-content"&gt;
					&lt;div style="height:200px;background-color:red;"&gt;&lt;/div&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
			&lt;div class="nv-column desk-2-4 lap-3 tab-4 phab-6"&gt;
                &lt;div class="nv-column-content"&gt;
					&lt;div style="height:200px;background-color:red;"&gt;&lt;/div&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
			&lt;div class="nv-column desk-4 lap-3 tab-4 phab-6"&gt;
                &lt;div class="nv-column-content"&gt;
					&lt;div style="height:200px;background-color:red;"&gt;&lt;/div&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
			&lt;div class="nv-column desk-2-4 lap-3 tab-4 phab-6"&gt;
                &lt;div class="nv-column-content"&gt;
					&lt;div style="height:200px;background-color:red;"&gt;&lt;/div&gt;
                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
        &lt;/div&gt;&lt;!-- /.nv-row --&gt;
    &lt;/div&gt;&lt;!-- /.nv-port --&gt;
&lt;/section&gt;&lt;!-- /.nv-section --&gt;
                        </pre>
                        </code>
                        <p><a target="_blank" href="http://warehouse369.com/tmp/navus/grid-options/columns">primer</a></p>
                        <span class="nv-divider desk-medium"></span>

                        <span class="nv-divider desk-medium"></span>
                        <h3>Vertikalni spacing</h3>
                        <p>Prostor po vertikali mozhe se narihta sa <code>&lt;span class="nv-divider"&gt;&lt;/span&gt;</code> elementom.</p>
                        <p>Pishe se BP-velichina, npr. <code>&lt;span class="nv-divider desk-medium"&gt;&lt;/span&gt;</code></p>
                        <p>Velechine su: <code>x-small x-medium x-big x-large</code></p>
                        <p>Kombinovanje po BP, npr.: <code>&lt;span class="nv-divider desk-medium phab-none"&gt;&lt;/span&gt;</code> znachi da pravi prostor do phablet rezolucije.</p>
                        <code class="block">
                        <pre>
&lt;section class="nv-section guttered" style="background-color:#dcf1ff;"&gt;
    &lt;div class="nv-port"&gt;
        &lt;div class="nv-row gap-20 large-spaced"&gt;
            &lt;div class="nv-column desk-2-4 lap-3 tab-4 phab-6"&gt;
                &lt;div class="nv-column-content"&gt;
					&lt;div style="height:200px;background-color:red;"&gt;&lt;/div&gt;

                    &lt;span class="nv-divider desk-medium"&gt;&lt;/span&gt;

                &lt;/div&gt;&lt;!-- /.nv-column-content --&gt;
            &lt;/div&gt;&lt;!-- /.nv-column --&gt;
        &lt;/div&gt;&lt;!-- /.nv-row --&gt;
    &lt;/div&gt;&lt;!-- /.nv-port --&gt;
&lt;/section&gt;&lt;!-- /.nv-section --&gt;
                        </pre>
                        </code>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column desk-6 -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </div><!-- /.nv-section -->

</div><!-- /.nv-page-wrap -->
