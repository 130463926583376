<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Voting Settings</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-10">
                <nv-button (click)="save()" translate>Save</nv-button>
            </div>
        </div>
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav vertical-break-tab">
                <ul>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'networking', 'chat-settings']">Chat Settings</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'networking', 'qa-settings']">Q&A Settings</a>
                    </li>
                    <li>
                        <a class="active">Voting Settings</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>

<div class="nv-config-content-port">
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates">
                <form class="nv-column desk-12" [formGroup]="votingSettingsForm">
                    <div class="nv-column-content">
                        <div class="nv-row config-plates clean gap-24 propagate">
                            <div class="nv-column desk-12 no-propagate">
                                <div class="nv-column-content">
                                    <h1>Voting Settings</h1>
                                </div>
                            </div>
                            <div class="nv-column desk-12 tab-12">
                                <div class="nv-column-content">
                                    <nv-switch
                                            label="Use Voting during Live stream"
                                            formControlName="enabled"
                                    ></nv-switch>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
