import { Component, Input, OnInit } from '@angular/core';


@Component({
    selector: 'nv-progress-bar',
    templateUrl: './progress-bar.component.html'
})

export class NavusProgressBarComponent implements OnInit {
    @Input() active: boolean;
    @Input() progress: number;
    @Input() text: string;

    constructor() {}

    ngOnInit() { }
}
