import { Routes } from '@angular/router';
import { RegistrationDelegateComponent } from './pages/delegate/delegate.component';
import { RegistrationPaymentComponent } from './pages/payment/payment.component';
import { RegistrationTicketComponent } from './pages/ticket/ticket.component';
import { RegistrationComponent } from './registration.component';

export const RegistrationRoutes: Routes = [
  {
    path: '',
    component: RegistrationComponent,
    children: [
      {
        path: 'ticket',
        component: RegistrationTicketComponent
      },      
      {
        path: 'payment',
        component: RegistrationPaymentComponent
      },      
      {
        path: 'delegate',
        component: RegistrationDelegateComponent
      },
    ]
  }
]