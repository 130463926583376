<div class="nv-section" 
  [class.edit-mode]="mouseOver" 
  [class.guttered]="configuration.guttered"
  [style.background]="configuration.background || ''"
>
  <ng-container *ngIf="mouseOver">
      <a class="nv-section-add-top" title="Add section" (click)="createBefore()"></a>
      <a class="nv-section-add-bottom" title="Add section" (click)="createAfter()"></a>
      <div class="nv-section-edit-tool-box">
          <a class="edit" title="Edit section" (click)="edit()"></a>
          <a class="delete" title="Delete section" (click)="delete()"></a>
      </div><!-- /.nv-section-edit-tool-box -->
      <div class="nv-section-sort-tool-box">
          <a class="move-up" title="Move up" (click)="moveUp()"></a>
          <a class="move-down" title="Move down" (click)="moveDown()"></a>
      </div><!-- /.nv-section-sort-tool-box -->
  </ng-container>

  <div class="nv-port">
    <div class="nv-row {{ configuration.spaced }}">
        <div class="nv-column desk-12">
            <div class="nv-column-content">
              <div class="nv-txt-editor-box" [innerHTML]="configuration.content | nvHtml"></div>
            </div>
        </div>
      </div>
  </div>
</div>
          
