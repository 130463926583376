import { Component } from '@angular/core';

@Component({
    selector: 'app-config-branding-page',
    templateUrl: './config-branding-component.html'
})
export class ConfigBrandingComponent {

    constructor() { }

}