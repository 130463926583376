import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { CustomSectionConfig } from './custom-section.config';
import { BaseSectionComponent } from '../_base/base-section.component';
import { WebsiteService } from '@navus/core/services/website.service';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { WebsiteControlService } from '../../services/website-control.service';

@Component({
  selector: 'nv-custom-section',
  templateUrl: './custom-section.component.html'
})
export class CustomSectionComponent extends BaseSectionComponent implements OnInit, OnDestroy {
  @Input() data: WebsitePageSection;
  configuration: CustomSectionConfig = new CustomSectionConfig();

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );
  }

  ngOnInit() {
    this.configuration = new CustomSectionConfig(this.data.configuration);
  }

  ngOnDestroy() {

  }

}

