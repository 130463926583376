import { Component } from '@angular/core';

@Component({
    selector: 'app-config-sidebar-page',
    templateUrl: './config-sidebar.component.html'
})
export class ConfigSidebarComponent {

    constructor() { }

}
