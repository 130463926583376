<ng-container *ngIf="user">
    <div class="nv-profile-settings-body-gutter">
        <h3>Badges</h3>

        <div class="nv-profile-badges">
            <ng-container *ngFor="let badge of badges">
                <div class="nv-profile-badge-item">
                    <div class="nv-pb-level">
                        <figure>
                            <img [src]="badge.levelImage" alt="{{ badge.levelName }}" />
                        </figure>
                        <p>{{ badge.levelName }}</p>
                    </div>
                    <div class="nv-pb-txt">
                        <h4>{{ badge.name }}</h4>
                        <div [innerHtml]="badge.description"></div>
                        <div class="nv-pb-progress" *ngIf="badge.completionPercentage < 100">
                            <div class="nv-pb-progress-bar">
                                <em [style.width]="badge.completionPercentage + '%'"></em>
                            </div>
                            <span>{{ badge.currentValue }}/{{ badge.goalValue }}</span>
                        </div>
                    </div>
                    <p class="nv-pb-status">
                        <ng-container *ngIf="badge.completedAt">
                            <span>{{ badge.completedLevelName }}</span>
                            <span>Completed</span>
                            <span>{{ badge.completedAt | nvDate: "dd.MM.yyyy" }}</span>
                        </ng-container>
                    </p>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>