import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class WebsitePageService {

  constructor(
    private apiService: ApiService
  ) { }

  getWebsitePages(organizationId: number, websiteId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/websites/${websiteId}/pages`, params);
  }

  getWebsitePage(organizationId: number, websiteId: number, pageId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/websites/${websiteId}/pages/${pageId}`, params);
  }

  updateWebsitePage(organizationId: number, websiteId: number, pageId: number, params: any = {}) {
    return this.apiService.put(`organizations/${organizationId}/websites/${websiteId}/pages/${pageId}`, params);
  }

  createWebsitePage(organizationId: number, websiteId: number, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/websites/${websiteId}/pages`, params);
  }

  deleteWebsitePage(organizationId: number, websiteId: number, pageId: number, params: any = {}) {
    return this.apiService.delete(`organizations/${organizationId}/websites/${websiteId}/pages/${pageId}`, params);
  }

  getWebsitePageSections(organizationId: number, websiteId: number, pageId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/websites/${websiteId}/pages/${pageId}/sections`, params);
  }

  getWebsitePageSection(organizationId: number, websiteId: number, pageId: number, sectionId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/websites/${websiteId}/pages/${pageId}/sections/${sectionId}`, params);
  }

  updateWebsitePageSection(organizationId: number, websiteId: number, pageId: number, sectionId: number, params: any = {}) {
    return this.apiService.put(`organizations/${organizationId}/websites/${websiteId}/pages/${pageId}/sections/${sectionId}`, params);
  }

  createWebsitePageSection(organizationId: number, websiteId: number, pageId: number, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/websites/${websiteId}/pages/${pageId}/sections`, params);
  }

  deleteWebsitePageSection(organizationId: number, websiteId: number, pageId: number, sectionId: number, params: any = {}) {
    return this.apiService.delete(`organizations/${organizationId}/websites/${websiteId}/pages/${pageId}/sections/${sectionId}`, params);
  }
  
  // to be deleted
  getMediasiteLink(conference_id: number) {
    return this.apiService.get(`mediasite/${conference_id}`);
  }

}
