<div class="nv-modal small">
  <div class="nv-modal-header">
      <h4 class="nv-modal-title">Meeting Room</h4>
      <button type="button" aria-label="Close" (click)="close()"></button>
  </div>
  <div class="nv-modal-body">
    <form [formGroup]="meetingRoomForm" class="nv-row gap-30 propagate">
      <div class="nv-column desk-12">
          <div class="nv-column-content">
              <nv-input
                      formControlName="title"
                      label="Title"
                      required
              ></nv-input>
          </div>
      </div>
      <div class="nv-column desk-12">
          <div class="nv-column-content">
              <nv-input
                      formControlName="max_participants"
                      label="Max participants"
                      type="number"
                      required
              ></nv-input>
          </div>
      </div>
      <div class="nv-column desk-12">
          <div class="nv-column-content">
              <nv-button 
                (click)="save()"
                [loading]="savingMeetingRoom"
              >Save</nv-button>
          </div>
      </div>
    </form>
  </div>
</div>

