import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Block } from '@navus/core/classes/block';
import { BlockService } from '@navus/core/services/block.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { LocationService } from '@navus/core/services/location.service';
import { BlockCategoryService } from '@navus/core/services/block-category.service';
import { BlockCategory } from '@navus/core/classes/block-category';
import { Location } from '@navus/core/classes/location';
import { Author } from '@navus/core/classes/author';
import { AuthorService } from '@navus/core/services/author.service';
import { ConferenceService } from '@navus/core/services/conference.service';
import { AuthService } from '@navus/core/services/auth.service';
import { WebsiteService } from '@navus/core/services/website.service';

@Component({
  selector: 'main [nv-block-details-page]',
  templateUrl: './block-details-page.component.html',
  styles: []
})
export class BlockDetailsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  blockId: number;
  block: Block = new Block();
  blockForm: FormGroup;
  invitationForm: FormGroup;
  blockTypes = [
    { id: 'Oral', name: 'Oral' },
    { id: 'Poster', name: 'Poster' },
    { id: 'Break', name: 'Break' }
  ];

  minAccessRights = [
    { id: 'delegate', name: 'Delegate' },
    { id: 'guest', name: 'Guest' },
    { id: 'member', name: 'Member' }
  ];

  blockCategories: BlockCategory[] = [];
  blockCategorySearch: string = '';
  blockCategoryPage: number = 1;
  blockCategoryTotal: number = null;

  locations: Location[] = [];
  locationsSearch: string = '';
  locationsPage: number = 1;
  locationsTotal: number = null;

  authors: Author[] = [];
  authorsSearch: string = '';
  authorsPage: number = 1;
  authorsTotal: number = null;

  ticketing: boolean = false;
  ticketTypes: any[] = [];
  ticketTypesLoading: boolean = false;

  invitations: any[] = [];
  invitationDomain: string = '';

  loadingBlock: boolean = false;
  savingBlock: boolean = false;
  unsavedChanges: boolean = false;
  invitingModerators: boolean = false;

  subscriptions: Subscription[] = [];

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private blockService: BlockService,
    private authorService: AuthorService,
    private locationService: LocationService,
    private blockCategoryService: BlockCategoryService,
    private conferenceService: ConferenceService,
    private toastService: ToastService,
    private modalService: ModalService,
    private websiteService: WebsiteService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.initForm();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.blockId = data.blockId;
        this.getConference();
        this.getBlockCategories();
        this.getLocations();
        this.getBlock();
        this.getTicketTypes();
        this.getInvitations();
        this.getWebsite();
      }
    );
    this.subscriptions.push(subscription);

    const changeSubscription = this.blockForm.valueChanges.subscribe(
      () => {
        this.unsavedChanges = true;
      }
    );
    this.subscriptions.push(changeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.blockForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      subtitle: [''],
      time_info: [''],
      imported_id: [''],
      min_access_right: ['delegate'],
      type: ['Oral'],
      code: [''],
      chairperson_ids: [''],
      starts_at: ['', [Validators.required]],
      ends_at: ['', [Validators.required]],
      block_category_id: [null],
      location_id: [null],
      conference_ticket_type_ids: [null],
      featured: [false],
      streamable: [false],
      allow_qa: [false],
      q_and_a: this.formBuilder.group({
        public: [false],
        moderation: [false]
      }),
      threshold: [null],
      live_cme_points: [null],
      on_demand_cme_points: [null],
      in_person_cme_points: [null],
    });
    this.invitationForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.unsavedChanges = false;
  }

  getConference() {
    this.conferenceService
        .getOrganizationConference(this.organizationId, this.conferenceId)
        .subscribe(
          (response) => {
            const conference = response.data;
            this.ticketing = conference.settings.delegate_registration ? conference.settings.delegate_registration.ticketing : conference.settings.ticket?.enabled;
          },
          () => {}
        )
  }

  getBlock() {
    if (this.blockId) {
      this.loadingBlock = true;
      this.blockService.getConferenceBlock(this.conferenceId, this.blockId, { include: 'location,chairpersons,block_category,conference_ticket_types' })
        .subscribe(
          (response) => {
            const block = response.data;

            this.blockCategories.push(block.block_category);
            delete block.block_category;

            this.locations.push(block.location);
            delete block.location;

            this.authors = this.authors.concat(block.chairpersons);
            delete block.chairpersons;

            block.conference_ticket_type_ids = block.conference_ticket_types.map(ctt => ctt.id);

            this.block = block;
            this.blockForm.patchValue(this.block);
            this.loadingBlock = false;
            setTimeout(() => { this.unsavedChanges = false; }, 500);
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingBlock = false;
          }
        );
    } else {
      this.block = new Block();
      this.loadingBlock = false;
    }
  }

  getBlockCategories(append: boolean = false) {
    if (append) {
      if (this.blockCategoryTotal && this.blockCategories.length >= this.blockCategoryTotal) { return; }
      this.blockCategoryPage++;
    } else {
      this.blockCategoryPage = 1;
    }

    const params: any = {
      page: this.blockCategoryPage,
      per_page: 10,
      sort: 'name',
      order: 'asc'
    };

    if (this.blockCategorySearch !== '') { params.search_term = this.blockCategorySearch; }

    this.blockCategoryService
      .getConferenceBlockCategories(this.conferenceId, params)
      .subscribe(
        (response: { data: BlockCategory[], meta: any }) => {
          if (append) {
            this.blockCategories = this.blockCategories.concat(response.data);
          } else {
            this.blockCategories = response.data;
            this.blockCategoryTotal = response.meta.pagination.total;
          }
        },
        () => { }
      );
  }

  getWebsite() {
    this.websiteService
      .getConferenceWebsite(this.organizationId, this.conferenceId)
      .subscribe(
        (response) => {
          const website = response.data;
          this.invitationDomain = `https://${website.subdomain}.${website.domain}`;
        }, 
        () => { }
      )
  }

  searchBlockCategories(searchTerm) {
    this.blockCategorySearch = searchTerm;
    this.getBlockCategories();
  }

  createBlockCategory(event: any) {
    const blockCategoryName = event.value.toString();
    this.modalService.defaultModal({
      title: 'Add a session category',
      body: 'Do you want to add a session category <strong>' + blockCategoryName + '</strong>?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Add',
          color: 'primary',
          handler: () => {
            const blockCategory = new BlockCategory();
            blockCategory.name = blockCategoryName;

            this.blockCategoryService
              .createConferenceBlockCategory(this.conferenceId, blockCategory)
              .subscribe(
                (response) => {
                  this.getBlockCategories();
                }
              );
          }
        }
      ]
    });
  }

  getTicketTypes() {
    const params: any = {
      with_pagination: 0,
    };

    this.ticketTypesLoading = true;
    this.conferenceService
      .getConferenceTicketTypes(this.conferenceId, params)
      .subscribe(
        (response: { data: any[], meta: any }) => {
          this.ticketTypes = response.data;
          this.ticketTypesLoading = false;
        },
        () => {
          this.ticketTypesLoading = false;
        }
      );
  }

  getLocations(append: boolean = false) {
    if (append) {
      if (this.locationsTotal && this.locations.length >= this.locationsTotal) { return; }
      this.locationsPage++;
    } else {
      this.locationsPage = 1;
    }

    const params: any = {
      page: this.locationsPage,
      per_page: 10,
      sort: 'name',
      order: 'asc'
    };

    if (this.locationsSearch !== '') { params.search_term = this.locationsSearch; }

    this.locationService.getConferenceLocations(this.conferenceId, params)
      .subscribe(
        (response: { data: Location[], meta: any }) => {
          if (append) {
            this.locations = this.locations.concat(response.data);
          } else {
            this.locations = response.data;
            this.locationsTotal = response.meta.pagination.total;
          }
        },
        () => { }
      );
  }

  searchLocations(searchTerm) {
    this.locationsSearch = searchTerm;
    this.getLocations();
  }

  createLocation(event: any) {
    const locationName = event.value.toString();
    this.modalService.defaultModal({
      title: 'Add a location',
      body: 'Do you want to add a location <strong>' + locationName + '</strong>?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Add',
          color: 'primary',
          handler: () => {
            const location = new Location();
            location.name = locationName;

            this.locationService
              .createConferenceLocation(this.conferenceId, location)
              .subscribe(
                (response) => {
                  this.getLocations();
                }
              );
          }
        }
      ]
    });
  }

  getAuthors(append: boolean = false) {
    if (!this.conferenceId) { return; }

    if (append) {
      if (this.authorsTotal && this.authors.length >= this.authorsTotal) { return; }
      this.authorsPage++;
    } else {
      this.authorsPage = 1;
    }

    const params: any = {
      page: this.authorsPage,
      per_page: 10,
      sort: 'name',
      order: 'asc'
    };

    if (this.authorsSearch !== '') { params.search_term = this.authorsSearch; }

    this.authorService.getConferenceAuthors(this.conferenceId, params)
      .subscribe(
        (response: { data: Author[], meta: any }) => {
          if (append) {
            this.authors = this.authors.concat(response.data);
          } else {
            this.authors = response.data;
            this.authorsTotal = response.meta.pagination.total;
          }
        },
        () => { }
      );
  }

  searchAuthors(searchTerm) {
    this.authorsSearch = searchTerm;
    this.getAuthors();
  }

  createAuthor(event: any) {
    const speakerName = event.value.toString();
    this.modalService.defaultModal({
      title: 'Add a chairperson',
      body: 'Do you want to add a chairperson <strong>' + speakerName + '</strong>?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Add',
          color: 'primary',
          handler: () => {
            const nameSegments = speakerName.split(' ');
            const author = new Author();

            author.first_name = nameSegments[0];
            if (nameSegments.length > 1) {
              author.last_name = nameSegments[1];
            }

            this.authorService
              .createConferenceAuthor(this.conferenceId, author)
              .subscribe(
                (response) => {
                  this.getAuthors();
                }
              );
          }
        }
      ]
    });
  }

  save() {
    if (this.blockForm.invalid) { return; }

    const block = this.blockForm.value;
    if (block.block_category_id === '') { 
      block.block_category_id = null; 
    }
    if (!block.conference_ticket_type_ids || block.conference_ticket_type_ids === '') { 
      block.conference_ticket_type_ids = []; 
    }

    this.savingBlock = true;
    if (this.blockId) {
      this.blockService.updateConferenceBlock(this.conferenceId, this.blockId, this.blockForm.value)
        .subscribe(
          (response) => {
            this.block = response['data'];
            this.toastService.success('Block successfully saved.', {});
            this.unsavedChanges = false;
            this.savingBlock = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingBlock = false;
          }
        );
    } else {
      this.blockService.createConferenceBlock(this.conferenceId, this.blockForm.value)
        .subscribe(
          (response) => {
            this.block = response['data'];
            this.toastService.success('Block successfully created.', {});
            this.router.navigate([this.block.id], { relativeTo: this.route });
            this.savingBlock = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingBlock = false;
          }
        );
    }
  }

  close() {
    if (this.unsavedChanges) {
      this.modalService.defaultModal({
        title: 'Unsaved changes',
        body: 'Are you sure you want to leave the page without saving changes?',
        size: 'small',
        buttons: [
          {
            text: 'Stay',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Leave',
            color: 'primary',
            handler: () => {
              if (this.blockId) {
                this.router.navigate(['../..', 'blocks'], { relativeTo: this.route });
              } else {
                this.router.navigate(['..', 'blocks'], { relativeTo: this.route });
              }
            }
          }
        ]
      });
    } else {
      if (this.blockId) {
        this.router.navigate(['../..', 'blocks'], { relativeTo: this.route });
      } else {
        this.router.navigate(['..', 'blocks'], { relativeTo: this.route });
      }
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Block Deletion',
      body: 'Are you sure you want to delete this block?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.blockService.deleteConferenceBlock(this.conferenceId, this.blockId)
              .subscribe(
                () => {
                  this.close();
                },
                (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

  setEndTime(length: number) {
    const startTime = new Date(this.blockForm.get('starts_at').value);
    const endTime = new Date((startTime.getTime() + (length * 60000)) - (startTime.getTimezoneOffset() * 60000));
    this.blockForm.get('ends_at').setValue( endTime.toISOString().substr(0, 10) + ' ' + endTime.toISOString().substr(11, 5) + ':00');
  }

  getInvitations() {
    this.authService
      .getConferenceInvitations('block', this.blockId, this.conferenceId)
      .subscribe(
        (response: { data: any[], meta: any }) => {
          this.invitations = response.data;
        },
        () => {}
      ); 
  }

  cancelInvitation(invitationId: string) {
    this.authService
      .cancelConferenceInvitations(this.conferenceId, invitationId)
      .subscribe(
        () => {
          this.modalService.defaultModal({
            body: 'Invitation canceled.',
            buttons: [{ text: 'OK', role: 'cancel' }]
          });
          this.getInvitations();
        },
        () => {}
      ); 
  }

  sendInvitation() {
    const invitation: any = {
      email: this.invitationForm.get('email').value,
      role: 'moderator',
      resource_id: this.blockId,
      resource_type: 'block',
      url: `${this.invitationDomain}/speaker-studio?tabs=agenda,qna&layout=lo-type1&email=` + this.invitationForm.get('email').value,
    };

    this.invitingModerators = true;
    this.authService
      .createConferenceInvitation(this.conferenceId, { invitations: [invitation] })
      .subscribe(
        (response: { data: any[], meta: any }) => {
          this.invitationForm.reset();
          this.invitingModerators = false;

          this.modalService.defaultModal({
            body: 'Invitation sent.',
            buttons: [{ text: 'OK', role: 'cancel' }]
          });
          this.getInvitations();
        },
        () => {
          this.invitingModerators = false;
        }
      );
  }

}
