<div class="nv-config-control-bar">
  <div class="config-title-n-tools">
      <div class="tnt-left">
          <h1 translate>Badges</h1>
      </div><!-- /.tnt-left -->
      <div class="tnt-right">
          <div class="nv-btn-box flow-end gap-8">
              <nv-button color="primary" iconPl="&#xe095;" (click)="add()">Add badge</nv-button>
          </div><!-- /.nv-btn-box flow-end gap-6 -->
      </div><!-- /.tnt-right -->
  </div><!-- /.config-title-n-tools -->
  <div class="nv-port spaced-bottom">
      <div class="nv-line-tabs guttered">
          <nav class="nv-tabs-nav vertical-break-tab">
              <ul>
                  <li>
                      <a class="active">Leaderboard</a>
                  </li>
                  <li>
                      <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'badges', 'badges']">Badges</a>
                  </li>
                  <li>
                      <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'badges', 'instructions']">Instructions</a>
                  </li>
              </ul>
          </nav>
      </div>
  </div>
  <form class="config-search-bar-w-filters" [formGroup]="formFilter" autocomplete="off">
      <div class="sbwf-port">
        <input (change)="getDelegates()"
            type="text"
            placeholder="Search..."
            formControlName="search_term">
        <span class="sbwf-outline"></span>
      </div><!-- /.sbwf-port -->
      <div style="width: 250px; margin-left: 30px;">
         <nv-select 
            (ngModelChange)="getDelegates()"
            label="Badge Levels"
            formControlName="badge_levels"
            [options]="badgeLevels"
            [multi]="true"
        ></nv-select>       
      </div>
  </form><!-- /.config-search-bar-w-filters -->
  <ng-container *ngIf="false">
      <div class="tnt-right" style="padding-left: 8px;">
          <div class="nv-btn-box flow-end gap-8">
              <nv-button color="passive" [loading]="downloadingDelegatesTemplate" (click)="getDelegateTemplate()">
                  Download XLS
              </nv-button>
          </div><!-- /.nv-btn-box flow-end gap-6 -->
      </div><!-- /.tnt-right -->
      <div *ngIf="false" class="tnt-right" style="padding-left: 8px;">
          <div class="nv-btn-box flow-end gap-8">
              <nv-button color="primary" iconPl="&#xe095;" (click)="fileSelect.click()">Import XLS</nv-button>
              <input #fileSelect type="file" ng2FileSelect [uploader]="uploader" [hidden]="true"
                  accept="application/vnd.ms-excel">
          </div><!-- /.nv-btn-box flow-end gap-6 -->
      </div><!-- /.tnt-right -->            
  </ng-container>
</div>
<div class="nv-config-content-port" nvInfiniteScroll (scrolled)="getDelegates(true)">
  <div class="nv-global-loading on" [class.on]="delegatesLoading">
      <div class="nv-spinner"></div>
  </div><!-- /.nv-global-loading -->
  <section class="nv-section">
      <div class="nv-port">
          <div class="nv-row config-plates">
              <div class="nv-column v-middle desk-12">
                  <div class="nv-column-content">
                      <div *ngIf="pagination.total === 0">
                          <div class="nv-empty-state">
                              <div class="img">
                                  <img src="../../../../assets/empty-states/empty-state-delegates.svg" alt="">
                              </div>
                              <h4 class="title">No delegates!</h4>
                              <p class="text">Once you add delegate, you'll see them here.</p>
                          </div>
                      </div>
                      <table class="nv-data-table break-lap">
                          <tr *ngFor="let delegate of delegates">
                              <td class="dt-cell">
                                  <label>NAME</label>
                                  <strong>{{ delegate.first_name }} {{ delegate.last_name }}</strong>
                              </td><!-- /.dt-cell -->
                              <td class="dt-cell">
                                  <label>EMAIL</label>
                                  <strong>{{ delegate.email }}</strong>
                              </td><!-- /.dt-cell -->
                              <td class="dt-cell">
                                  <label>BADGES</label>
                                  <strong>{{ delegate.badge_count }}</strong>
                              </td><!-- /.dt-cell -->
                          </tr>
                      </table>
                  </div><!-- /.nv-column-content -->
              </div><!-- /.nv-column -->
          </div><!-- /.nv-row -->
      </div><!-- /.nv-port -->
  </section><!-- /.nv-section -->
</div><!-- /.config-layout-lower-fixed -->
