import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'nv-edit-resource',
  templateUrl: './edit-resource.component.html',
  styleUrls: ['./edit-resource.component.scss']
})
export class EditResourceComponent implements OnInit {

  @Input() resource: any;
  @Input() allFolders: any[];
  @Input() availableFolders: any[];
  public editResourceForm: FormGroup;
  public newFolder;
  public showNewFolderField = false;
  @ViewChild('newFolderEl') public newFolderEl;

  @Output() savedResource: EventEmitter<any> = new EventEmitter<any>();

  constructor(private activeModal: NgbActiveModal, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.availableFolders = this.allFolders.filter(folder => !this.resource.tags.includes(folder));
    this.editResourceForm = this.formBuilder.group({
      filename: [this.resource.original_filename, [Validators.required]]
    });
  }

  focusNewFolder() {
    setTimeout(() => this.newFolderEl.nativeElement.focus());
  }

  close() {
    this.activeModal.dismiss();
  }

  save() {
    this.savedResource.next(Object.assign({}, this.resource, this.editResourceForm.value));
    this.activeModal.dismiss('save');
  }

  addFolderToResource(folderName: string) {
    this.resource.tags.push(folderName);
    this.availableFolders = this.availableFolders.filter(folder => folder !== folderName);
  }

  removeFolderFromResource(folderName: string) {
    this.resource.tags = this.resource.tags.filter(folder => folder !== folderName);
    this.availableFolders = this.allFolders.filter(folder => !this.resource.tags.includes(folder));
  }

  addNewFolder() {
    if (!this.newFolder) { return; }
    this.addFolderToResource(this.newFolder);
    this.showNewFolderField = false;
    this.newFolder = '';
  }

  cancelNewFolder() {
    this.newFolder = '';
    this.showNewFolderField = false;
  }
}
