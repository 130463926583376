<section class="nv-section guttered">
    <div class="nv-port">
        <div class="nv-row v-stretch small-spaced">
            <div class="nv-column desk-12 studio-location-questions">
                <div class="nv-column-content">
                    <br>

                    <div class="nv-live-suite-interactions-panels">
                        <div class="nv-live-suite-interactions-bar">
                            <h2>{{selectedLocation?.name}}</h2>
                        </div>
                        <div class="nv-live-suite-interactions-panes giant" [ngClass]="chatMode">
                            <nav class="nv-live-suite-interactions-pane sessions">
                                <ul>
                                    <ng-container *ngFor="let block of blocks">
                                        <ng-container *ngIf="block.startTimestamp <= (currentBlockTimestamp || currentTimestamp)">
                                            <li 
                                                [class.active]="block.id === selectedBlock?.id" 
                                                [title]="block.name" 
                                                (click)="selectBlock(block)"
                                            >
                                                <h4>{{ block.name }}</h4>
                                                <p> 
                                                    <span>{{ block.starts_at | nvDate:'dd.MM.yyyy | HH:mm - ' }} {{ block.ends_at | nvDate:'HH:mm' }}</span>
                                                    <span>{{selectedLocation?.name}}</span>
                                                </p>
                                            </li>                                            
                                        </ng-container>
                                    </ng-container>
                                </ul>
                            </nav>
                            <div class="nv-live-suite-interactions-pane qa">
<!--                                <div class="nv-live-suite-qa-tabs">-->
<!--                                    &lt;!&ndash;div class="nv-live-suite-qa-label">Q&A</div&ndash;&gt;-->
<!--                                    <div class="nv-live-suite-qa-tab active">Questions from the audience</div>-->
<!--                                </div>-->
                                <!-- Option with tools -->
                               <div class="nv-live-suite-interactions-toolbar">
                                   <h4>Questions from the audience</h4>
                                   <div class="nv-interactions-tools">
                                       <!-- <div class="nv-chat-interaction-search">
                                           <nv-input
                                                   type="search"
                                                   label="Search"
                                                   formControlName="input2">
                                           </nv-input>
                                       </div> -->
                                       <div class="nv-chat-interaction-filters">
                                           <nv-select
                                                   [options]="statusOptions"
                                                   [(ngModel)]="approveStatus"
                                                   [label]="'Status' | translate">
                                           </nv-select>
                                       </div>
                                   </div>
                               </div>
                                <ng-container *ngIf="selectedBlock">
                                    <nv-chat
                                        [eventId]="conferenceId"
                                        [roomId]="'block_' + selectedBlock?.id"
                                        [currentUserId]="currentUser?.id"
                                        [replies]="true"
                                        [canReply]="false"
                                        [approval]="true"
                                        [canApprove]="true"
                                        [approveStatus]="approveStatus"
                                        (userClicked)="userDetails($event)"
                                        [usecase]="'Q&A'"
                                    ></nv-chat>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div><!-- /nv-column-content-->
            </div>
              
            <div class="nv-column desk-4 tab-12 nv-live-stream-tabs" *ngIf="(conferenceId==8317 || conferenceId==8200) && currentVotingQuestion">
                <div class="nv-column-content">
                  <div class="nv-tab-content">
                      <div *ngIf="currentVotingQuestion" class="voting in-tab">
                          <h2>Live voting</h2>
                          <nv-voting-question [conferenceId]="conferenceId" [votingQuestion]="currentVotingQuestion"></nv-voting-question>
                      </div>
                      <div *ngIf="!currentVotingQuestion" class="voting in-tab">
                          <strong>Once activated the Voting question will appear here and you will be able to vote</strong>
                      </div>
                  </div>
                </div>
            </div>
        </div>

        <div class="nv-row v-stretch small-spaced" *ngIf="selectedTab === 'waiting'">
            <div class="nv-column desk-12 tab-12">
                <div class="nv-column-content">
                    <h3>Waiting room</h3>
                    <strong>Click on the button to enter the waiting room.</strong>
                    <p>Entering to the waiting room, you can initiate a simulation of your presentation in the Live studio, 
                        exploring all the possibilities, communicate with other speakers and wait for the moderator of the program to invite you to join the Live program.</p>
                    <nv-meeting *ngIf="currentUser" #meeting
                            [buttonLabel]="'Waiting room'"
                            [demo]="true"
                            [moderator]="false"
                            [roomName]="'WaitingRoom'+selectedLocation?.id"
                            [title]="'Live in waiting room'"
                            [speakerName]="currentUser?.first_name+' '+currentUser?.last_name"
                    >

                    </nv-meeting>
                </div>
            </div>
        </div>
                    
    </div>
</section>
