<div class="nv-global-loading on" [class.on]="loadingAttendance">
    <div class="nv-spinner"></div>
</div><!-- /.nv-global-loading -->

<div class="nv-row gap-40 propagate nv-profile-delegate-charts v-stretch">
    <div class="nv-column desk-8">
        <div class="nv-column-content">
            <h4 class="light">On-demand Attendance per session</h4>

                <ng-container *ngIf="sessionData?.length; else noSessionData">
                    <div class="nv-profile-on-demand-charts">
                        <div class="nv-chart-type-350" *ngIf="conferenceId!==14340">
                            <ngx-charts-bar-vertical
                                    [view]="sessionData?.length > 10? null : [sessionData.length * 70 + 100, 350]"
                                    [scheme]="chartColorScheme"
                                    [results]="sessionData"
                                    [xAxis]="sessionData?.length < 5"
                                    [yAxis]="true"
                                    [legend]="false"
                                    [showXAxisLabel]="false"
                                    [showYAxisLabel]="true"
                                    maxXAxisTickLength=16
                                    [yAxisLabel]="'Time spent (seconds)'"
                                    [activeEntries]="sessionActiveEntries"
                                    (activate)="sessionLegendLabelActivate($event)"
                                    (deactivate)="sessionLegendLabelDeactivate($event)"
                                    (select)="onSelectSession($event)">
                                <ng-template #tooltipTemplate let-model="model">
                                    <ng-container *ngTemplateOutlet="templateForTooltip; context: {model: model}"></ng-container>
                                </ng-template>
                            </ngx-charts-bar-vertical>
                        </div><!-- /.nv-chart-type-350 -->

                        <div class="nv-profile-on-demand-charts-legend-n-note">
                            <nv-delegate-chart-legend
                                    [title]="'Sessions'"
                                    [legendItems]="sessionLegend"
                                    [activeEntries]="sessionActiveEntries"
                                    [chartData]="sessionData"
                                    (selectItem)="onSelectSession($event)"
                                    (activateLabel)="sessionLegendLabelActivate($event)"
                                    (deactivateLabel)="sessionLegendLabelDeactivate($event)"
                            ></nv-delegate-chart-legend>
                            <p class="nv-clickable-chart-notice-simple">
                                Click on a session bar in the chart to view a detailed break-down
                            </p><!-- /.nv-clickable-chart-notice -->
                        </div><!-- /.nv-profile-on-demand-charts-legend-n-note -->
                    </div><!-- /.nv-profile-on-demand-charts -->
                </ng-container>

                <ng-template #noSessionData>
                    No On-demand attendance has been recorded.
                </ng-template>

        </div><!-- nv-column-content-->
    </div><!-- nv-column -->

    <div class="nv-column desk-4"  *ngIf="presentationData?.length">
        <div class="nv-column-content nv-dashboard-chart-no-pointer nv-dashboard-chart-no-hover">

                <h4 class="light" id="presentation-chart">On-demand Attendance per presentation for
                    session: {{ selectedSession?.name }}</h4>

                <div class="nv-profile-on-demand-charts">
                    <div class="nv-chart-type-350" *ngIf="conferenceId!==14340">
                        <ngx-charts-bar-vertical
                                [view]="presentationData?.length > 10? null : [presentationData.length * 70 + 100, 350]"
                                [scheme]="chartColorScheme"
                                [activeEntries]="presentationActiveEntries"
                                [results]="presentationData"
                                [xAxis]="presentationData?.length < 5"
                                [yAxis]="true"
                                [legend]="false"
                                [showXAxisLabel]="false"
                                [showYAxisLabel]="true"
                                [trimXAxisTicks]="true"
                                [rotateXAxisTicks]="true"
                                maxXAxisTickLength=16
                                [yAxisLabel]="'Time spent (seconds)'"
                                (select)="onSelectPresentation($event)"
                                (activate)="presentationLegendLabelActivate($event)"
                                (deactivate)="presentationLegendLabelDeactivate($event)">
                            <ng-template #tooltipTemplate let-model="model">
                                <ng-container *ngTemplateOutlet="templateForTooltip; context: {model: model}"></ng-container>
                            </ng-template>
                        </ngx-charts-bar-vertical>
                    </div><!-- /.nv-chart-type-350 -->

                    <div class="nv-profile-on-demand-charts-legend-n-note">
                        <nv-delegate-chart-legend
                                [title]="'Presentations'"
                                [legendItems]="presentationLegend"
                                [activeEntries]="presentationActiveEntries"
                                [chartData]="presentationData"
                                (activateLabel)="presentationLegendLabelActivate($event)"
                                (deactivateLabel)="presentationLegendLabelDeactivate($event)"
                        ></nv-delegate-chart-legend>
                        <p class="nv-clickable-chart-notice-simple">
                            Click on a session bar in the chart to view a detailed break-down
                        </p><!-- /.nv-clickable-chart-notice -->
                    </div><!-- /.nv-profile-on-demand-charts-legend-n-note -->
                </div><!-- /.nv-profile-on-demand-charts -->
        </div><!-- nv-column-content-->
    </div><!-- nv-column -->

    <div class="nv-column desk-6" *ngIf="materialData?.length">
        <div class="nv-column-content nv-dashboard-chart-no-pointer nv-dashboard-chart-no-hover">

                <h4 class="light" id="material-chart">On-demand Attendance per material for
                    presentation: {{ selectedPresentation?.title }}</h4>

                <div class="nv-profile-on-demand-charts">
                    <div class="nv-chart-type-350" *ngIf="conferenceId!==14340">
                        <ngx-charts-bar-vertical
                                [view]="materialData?.length > 10? null : [materialData.length * 70 + 100, 350]"
                                [scheme]="chartColorScheme"
                                [activeEntries]="materialActiveEntries"
                                [results]="materialData"
                                [xAxis]="materialData?.length < 5"
                                [yAxis]="true"
                                [legend]="false"
                                [showXAxisLabel]="false"
                                [showYAxisLabel]="true"
                                [trimXAxisTicks]="true"
                                [rotateXAxisTicks]="true"
                                maxXAxisTickLength=16
                                [yAxisLabel]="'Time spent (seconds)'"
                                (activate)="materialLegendLabelActivate($event)"
                                (deactivate)="materialLegendLabelDeactivate($event)">
                            <ng-template #tooltipTemplate let-model="model">
                                <ng-container *ngTemplateOutlet="templateForTooltip; context: {model: model}"></ng-container>
                            </ng-template>
                        </ngx-charts-bar-vertical>
                    </div><!-- /.nv-chart-type-350 -->
                </div><!-- /.nv-profile-on-demand-charts -->

                <div class="nv-profile-on-demand-charts-legend-n-note">
                    <nv-delegate-chart-legend
                            [title]="'Materials'"
                            [legendItems]="materialLegend"
                            [activeEntries]="materialActiveEntries"
                            [chartData]="materialData"
                            (activateLabel)="materialLegendLabelActivate($event)"
                            (deactivateLabel)="materialLegendLabelDeactivate($event)"
                    ></nv-delegate-chart-legend>
                </div><!-- /.nv-profile-on-demand-charts-legend-n-note -->
        </div><!-- nv-column-content-->
    </div><!-- nv-column -->
</div><!-- /.nv-row -->



