<div *ngIf="loadingChat && retries < 4">
    <ng-template [ngTemplateOutlet]="loadingRoom"></ng-template>
</div>
<div *ngIf="loadingChat && retries === 4">
    <ng-template [ngTemplateOutlet]="timeout"></ng-template>
</div>
<ng-container *ngIf="!loadingChat">
    <ng-container *ngIf="(active$ | async);else chatNotActive">
        <ng-container *ngIf="currentUserId;else notLoggedIn">
            <ng-container *ngIf="roomId; else noConversation">
                <ng-container *ngIf="(chatRoom$ | async) as selectedRoom; else loadingRoom">
                    <div class="nv-chat-pane">
                        <div class="nv-chat-port" #chatcontent (scroll)="onScroll($event)">
                            <ng-container *ngIf="messages?.length;else noMessages">
                                <div class="message-box" *ngIf="loadingMoreMessages">
                                    <div class="chat-message right-bubble">
                                        <div *ngIf="getUserImageFromGroup(selectedRoom, currentUserId)" class="logo">
                                            <span [style.background-image]="getUserImageFromGroup(selectedRoom, currentUserId)"></span>
                                        </div>
                                        <div class="msg unapproved">
                                            <div class="msg-text">
                                                loading...
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="nv-chat-msg" *ngFor="let message of messages;trackBy: trackByFn"
                                     [ngClass]="{'right-bubble': message.createdBy === currentUserId, 'left-bubble': message.createdBy !== currentUserId}">
                                    <div class="nv-chat-msg-avatar"
                                         [ngClass]="{'initials': !getUserImageFromGroup(selectedRoom, message.createdBy)}"
                                         (click)="emitClick(message.createdBy)">
                                        <span *ngIf="getUserImageFromGroup(selectedRoom, message.createdBy) as avatar"
                                              nvBackgroundImage [image]="avatar"></span>
                                        <div *ngIf="!getUserImageFromGroup(selectedRoom, message.createdBy)">{{ getUserInitialsFromGroup(selectedRoom, message.createdBy) | uppercase}}</div>
                                    </div>
                                    <div class="nv-chat-msg-body"
                                         [ngClass]="{'unapproved': approval && !message.approved, 'replied': replies && message.replied}"
                                         [title]="approval && message.approved ? 'Approved' : ''">
                                        <div class="nv-msg-name" (click)="emitClick(message.createdBy)">
                                            <span class="nv-msg-time">{{ (this.canApprove ? message.createTime : message.editedAt) | date:'dd.MM. | HH:mm'}}</span>
                                            {{message.createdBy === currentUserId ? 'Me' : getUserNameFromGroup(selectedRoom, message.createdBy)}}
                                        </div>
                                        <div class="nv-msg-text">
                                            <div class="nv-chat-original" *ngIf="replies && message.relatedMessageText">
                                                Replied to:<br>{{message.relatedMessageText}}</div>
                                            <div>{{message.text}}</div>
                                        </div>
                                        <div class="nv-msg-meta">
                                            <div class="nv-msg-meta-options">
                                                <ng-container *ngIf="canReply">
                                                <span class="nv-msg-reply"
                                                      (click)="replyToMessage(message)">Reply</span>
                                                </ng-container>
                                                <ng-container *ngIf="canApprove">
                                                        <span class="nv-msg-approve"
                                                              [ngClass]="{'approve': !message.approved, 'deny': message.approved}"
                                                              (click)="approveMessage(selectedRoom.room.uid, message, !message.approved)"
                                                        >{{ message.approved ? 'Dismiss' : 'Approve'}}</span>
                                                </ng-container>
                                                <ng-container *ngIf="canUpVote && message.createdBy !== currentUserId">
                                                        <span class="nv-msg-approve"
                                                              [ngClass]="{'approve': !isMessageUpVoted(message), 'deny': isMessageUpVoted(message)}"
                                                              (click)="upVoteMessage(selectedRoom.room.uid, message)"
                                                        >{{ isMessageUpVoted(message) ? 'Unlike' : 'Like' }}</span>
                                                </ng-container>
                                                <ng-container *ngIf="upVotes && canUpVote && message.upVotesCount">
                                                <span class="nv-msg-approve approve">
                                                    {{ message.upVotesCount }}
                                                </span>
                                                </ng-container>
                                            </div>
                                            <!--                                        <span class="nv-msg-time">{{ (this.canApprove ? message.createTime : message.editedAt) | date:'dd.MM. | HH:mm'}}</span>-->
                                        </div>
                                        <ng-container *ngIf="deletion && (message.createdBy === currentUserId || (approval && canApprove))">
                                                        <span class="nv-chat-msg-delete"
                                                              (click)="deleteMessage(selectedRoom.room.uid, message)"
                                                        ></span>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="nv-chat-input">
                            <div class="nv-chat-original" *ngIf="repliedTo">
                                Replying to:<br>{{ repliedTo.text }}
                                <div class="cancel" (click)="cancelReply()"></div>
                            </div>
                            <form [formGroup]="chatForm"
                                  (ngSubmit)="sendMessageToGroup(selectedRoom, chatForm.value)">
                                    <textarea #textarea
                                              type="text"
                                              maxlength="1000"
                                              [placeholder]="
                                                        placeholder ? placeholder
                                                        : (approval && !canApprove) ? 'Ask a question. It will appear once it is approved.' 
                                                        : !approval ? 'Ask a question.' : 'Write reply...'"
                                              formControlName="message"
                                              (keydown)="adjustHeight($event)"
                                              (paste)="adjustHeight($event)"
                                              (cut)="adjustHeight($event)"
                                              (drop)="adjustHeight($event)"
                                              (change)="adjustHeight($event)"
                                              (keydown.enter)="sendMessageToGroup(selectedRoom, chatForm.value);false">
                                    </textarea>
                                <nv-button type="submit">Send</nv-button>
                            </form>
                        </div>
                    </div>

                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #loadingRoom>
    <div class="nv-global-loading on">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
</ng-template>

<ng-template #noConversation>
    <div class="nv-chat-idle">
        NO CONVERSATION SELECTED
    </div>
</ng-template>

<ng-template #noMessages>
    <div class="nv-chat-idle">
        NO MESSAGES
    </div>
</ng-template>

<ng-template #timeout>
    <div class="nv-chat-idle">
        Cannot connect to chat at this moment.
        <nv-button (click)="initialize()">Try again.</nv-button>
    </div>
</ng-template>

<ng-template #notLoggedIn>
    <div class="nv-chat-idle">
        You have to log in to use {{usecase}}.
    </div>
</ng-template>

<ng-template #chatNotActive>
    <div class="nv-chat-idle">
        <nv-button (click)="activateChat()" translate>Enter {{usecase}}</nv-button>
    </div>
</ng-template>


