export class LiveSuiteIframeSectionConfig {
  limit: number = 8;
  title: string = 'Live Suite';
  viewAll: boolean = false;
  numbered: boolean = false;
  conferenceId: number = null;
  code: string;
  name: string;
  banner_medium: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

}

