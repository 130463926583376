import { NgModule } from '@angular/core';
import { NavusCoreModule } from '@navus/core/navus-core.module';
import { NavusUiModule } from '@navus/ui/navus-ui.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../shared';
import { RegistrationDelegateComponent } from './pages/delegate/delegate.component';
import { RegistrationPaymentComponent } from './pages/payment/payment.component';
import { RegistrationTicketComponent } from './pages/ticket/ticket.component';
import { RegistrationComponent } from './registration.component';

@NgModule({
  imports: [
    TranslateModule.forChild(),
    NavusCoreModule.forRoot(),
    NavusUiModule,
    SharedModule
  ],
  declarations: [
    RegistrationComponent,
    RegistrationTicketComponent,
    RegistrationPaymentComponent,
    RegistrationDelegateComponent
  ],
  entryComponents: [],
  providers: []
})
export class RegistrationModule {
}