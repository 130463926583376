import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MaterialService } from '@navus/core/services/material.service';
import { PresentationService } from '@navus/core/services/presentation.service';
import { Presentation } from '@navus/core/classes/presentation';
import { Material } from '@navus/core/classes/material';
import { NavusSelectComponent } from '@navus/ui/select/select.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'nv-material-attach-modal',
  templateUrl: './material-attach-modal.component.html'
})
export class MaterialAttachModalComponent implements OnInit {
  @ViewChild('presentationSelect') presentationSelect: NavusSelectComponent;
  @ViewChild('materialSelect') materialSelect: NavusSelectComponent;

  @Input() conferenceId: number = null;
  @Input() materialId: number = null;
  @Input() presentationId: number = null;
  attachForm: FormGroup;

  presentations: Presentation[] = [];
  presentationsPage: number = 1;
  presentationsTotal: number = null;
  presentationsSearch: string = '';
  presentation: Presentation = null;

  materials: Material[] = [];
  materialsPage: number = 1;
  materialsTotal: number = null;
  materialsSearch: string = '';
  material: Material = null;

  processingMaterial = false;

  constructor(
    private formBuilder: FormBuilder,
    private materialService: MaterialService,
    private presentationService: PresentationService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.getMaterial();
    this.getPresentation();
    this.initForm();
  }

  initForm() {
    this.attachForm = this.formBuilder.group({
      consent: this.formBuilder.group({
        available: [true],
        excluded_slides: [null]
      }),
      published_at: [null],
    });
  }

  searchPresentations(searchTerm: string) {
    this.presentationsSearch = searchTerm;
    this.getPresentations();
  }

  getPresentations(append: boolean = false) {
    if (append) {
      if (this.presentationsTotal && this.presentations.length >= this.presentationsTotal) { return; }
      this.presentationsPage++;
    } else {
      this.presentationsPage = 1;
    }

    const params: any = {
      page: this.presentationsPage,
      per_page: 20,
      sort: 'name',
      order: 'asc'
    };
    if (this.presentationsSearch !== '') {
      params.search_term = this.presentationsSearch;
    }

    this.presentationService.getConferencePresentations(this.conferenceId, params)
      .subscribe(
        (response: { data: Presentation[], meta: any }) => {
          if (append) {
            this.presentations = this.presentations.concat(response.data);
          } else {
            this.presentations = response.data;
            if (response.meta) {
              this.presentationsTotal = response.meta.pagination.total;
            }
          }
        },
        (error) => { }
      );
  }

  getPresentation() {
    if (this.presentationId) {
      this.presentationService.getConferencePresentation(this.conferenceId, this.presentationId, { include: 'consent' })
        .subscribe(
          (response) => {
            this.presentation = response.data;
          },
          (error) => { }
        );
    }
  }

  getMaterials(append: boolean = false) {
    if (append) {
      if (this.materialsTotal && this.materials.length >= this.materialsTotal) { return; }
      this.materialsPage++;
    } else {
      this.materialsPage = 1;
    }

    const params: any = {
      page: this.materialsPage,
      per_page: 20,
      sort: 'name',
      order: 'asc'
    };
    if (this.materialsSearch !== '') {
      params.search_term = this.materialsSearch;
    }

    this.materialService.getConferenceMaterials(this.conferenceId, params)
      .subscribe(
        (response: { data: Material[], meta: any }) => {
          if (append) {
            this.materials = this.materials.concat(response.data);
          } else {
            this.materials = response.data;
            if (response.meta) {
              this.materialsTotal = response.meta.pagination.total;
            }
          }
        },
        (error) => { }
      );
  }

  getMaterial() {
    if (this.materialId) {
      this.materialService.getConferenceMaterial(this.conferenceId, this.materialId)
        .subscribe(
          (response) => {
            this.material = response.data;
          },
          (error) => { }
        );
    }
  }

  selectMaterial() {
    this.materialSelect.toggle();
  }

  selectPresentation() {
    this.presentationSelect.toggle();
  }

  processMaterial() {
    this.processingMaterial = true;
    this.materialService.attachConferenceMaterial(this.conferenceId, this.materialId, this.presentation.id, this.attachForm.value)
      .subscribe(
        (response) => {
          this.activeModal.close();
          this.processingMaterial = false;
        },
        (error) => {
          this.processingMaterial = false;
        }
      );
  }

  close() {
    this.activeModal.dismiss();
  }

}
