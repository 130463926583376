<a class="tsp-close" (click)="close.emit()" title="Close panel"></a>
<ng-container *ngIf="page">
    <header>
        <h4>{{ page.title }} Sections</h4>
        <nv-button size="small" (click)="new()">Add new</nv-button>
    </header>
    <nav>
        <ul cdkDropList (cdkDropListDropped)="drop($event)">
            <li cdkDrag *ngFor="let section of page?.sections">
                {{ section.title }}
                <nv-button
                        (click)="details(section)"
                        color="passive"
                        size="small"
                        iconPl="&#xe959;"
                        [onlyIcon]="true"
                        title="Edit"></nv-button>
            </li>
        </ul>
    </nav>
</ng-container>
<ng-container *ngIf="!page">
    <p style="text-align: center">
        No sections on this page
    </p>
</ng-container>
