<section class="nv-section nv-hero guttered" nvBackgroundImage [image]="configuration.background" [class.edit-mode]="mouseOver">
    <ng-container *ngIf="mouseOver">
        <a class="nv-section-add-top" title="Add section" (click)="createBefore()"></a>
        <a class="nv-section-add-bottom" title="Add section" (click)="createAfter()"></a>
        <div class="nv-section-edit-tool-box">
            <a class="edit" title="Edit section" (click)="edit()"></a>
            <a class="delete" title="Delete section" (click)="delete()"></a>
        </div><!-- /.nv-section-edit-tool-box -->
        <div class="nv-section-sort-tool-box">
            <a class="move-up" title="Move up" (click)="moveUp()"></a>
            <a class="move-down" title="Move down" (click)="moveDown()"></a>
        </div><!-- /.nv-section-sort-tool-box -->
    </ng-container>

    <div class="nv-port" style="position: relative">
        <div class="nv-row flow-center {{ configuration.spaced }}">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <div class="nv-hero-pane">
                        <h1 *ngIf="configuration.bigText"><span [innerHTML]="configuration.bigText"></span></h1>
                        <h3 *ngIf="configuration.smallerText"><span [innerHTML]="configuration.smallerText"></span></h3>
                        <a class="nv-btn giant radius-high accent2" *ngIf="showButton" 
                        [href]="buttonUrl"
                        [target]="configuration.openInNewTab ? '_blank' : '_self'" 
                        >{{ configuration.actionButtonText }}</a>
                    </div><!-- /.nv-hero-pane -->
                </div><!-- /.nv-column-content -->
            </div><!-- /nv-column -->
        </div><!-- /.nv-row -->
    </div>
</section>
