import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {
    transform(items: any[], searchText: string, keyName: string): any[] {
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }
        searchText = searchText.toLowerCase();
        return items.filter(it => {
            it = !it['value'] ? it[keyName] : it['value'][keyName] ? it['value'][keyName] : it[keyName];
            return it.toLowerCase().includes(searchText);
        });
    }
}
