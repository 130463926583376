import { Component } from '@angular/core';

@Component
({
  selector: 'nv-hub-page',
  templateUrl: './hub-page.component.html'
})
export class HubPageComponent {
  searchSettings: any = {
    configuration: {
      backgroundImage: 'https://virtual2020.navus.io/assets/config-branding-hero.jpg'
    }
  };
  summarySettings: any = { };
}
