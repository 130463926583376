export class Location {
    id: number;
    conference_id: number;
    type: string;
    name: string;
    floor: string;
    x_coord: string;
    y_coord: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
