import { Routes } from '@angular/router';
import { OrganizationGuard } from '../../guards/organization.guard';
import { CompanyListPageComponent } from './pages/company-list/company-list-page.component';
import { CompanyDetailsPageComponent } from './pages/company-details/company-details-page.component';
import { SponsorGroupListPageComponent } from './pages/sponsor-group-list/sponsor-group-list-page.component';
import { SponsorGroupDetailsPageComponent } from './pages/sponsor-group-details/sponsor-group-details-page.component';
import { SponsorSettingsPageComponent } from './pages/sponsor-settings/sponsor-settings-page.component';

export const SponsorsRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'companies'
  },
  {
    path: 'companies',
    component: CompanyListPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Company List'
    }
  },
  {
    path: 'company/:companyId',
    component: CompanyDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Company Details'
    }
  },
  {
    path: 'company',
    component: CompanyDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Company Details'
    }
  },
  {
    path: 'sponsor-groups',
    component: SponsorGroupListPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Sponsor Group List'
    }
  },
  {
    path: 'sponsor-group/:sponsorGroupId',
    component: SponsorGroupDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Sponsor Group Details'
    }
  },
  {
    path: 'sponsor-group',
    component: SponsorGroupDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Sponsor Group Details'
    }
  },
  {
    path: 'sponsor-settings',
    component: SponsorSettingsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Sponsor Settings'
    }
  },
];
