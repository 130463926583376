import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@navus/core/classes/location';
import { LocationService } from '@navus/core/services/location.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';

@Component({
  selector: 'main [nv-location-details-page]',
  templateUrl: './location-details-page.component.html'
})
export class LocationDetailsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  locationId: number;
  location: Location;
  locationForm: FormGroup;

  loadingLocation: boolean = true;
  savingLocation: boolean = false;
  unsavedChanges: boolean = false;

  subscriptions: Subscription[] = [];

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private locationService: LocationService,
    private toastService: ToastService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.initForm();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.locationId = data.locationId;
        this.getLocation();
      }
    );
    this.subscriptions.push(subscription);

    const changeSubscription = this.locationForm.valueChanges.subscribe(
      () => {
        this.unsavedChanges = true;
      }
    );
    this.subscriptions.push(changeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.locationForm = this.formBuilder.group({
      name: ['', Validators.required],
      type: ['Room'],
      color: [''],
      floor: [null],
      x_coord: [null],
      y_coord: [null],
    });
    this.unsavedChanges = false;
  }

  getLocation() {
    if (this.locationId) {
      this.loadingLocation = true;
      this.locationService.getConferenceLocation(this.conferenceId, this.locationId)
        .subscribe(
          (response) => {
            this.location = response['data'];
            this.locationForm.patchValue(this.location);
            this.loadingLocation = false;
            setTimeout(() => { this.unsavedChanges = false; }, 500);
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingLocation = false;
          }
        );
    } else {
      this.location = new Location();
      this.loadingLocation = false;
    }
  }

  save() {
    if (this.locationForm.invalid) { return; }

    this.savingLocation = true;
    if (this.locationId) {
      this.locationService.updateConferenceLocation(this.conferenceId, this.locationId, this.locationForm.value)
        .subscribe(
          (response) => {
            this.location = response['data'];
            this.toastService.success('Location successfully saved.', {});
            this.unsavedChanges = false;
            this.savingLocation = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingLocation = false;
          }
        );
    } else {
      this.locationService.createConferenceLocation(this.conferenceId, this.locationForm.value)
        .subscribe(
          (response) => {
            this.location = response['data'];
            this.toastService.success('Location successfully created.', {});
            this.router.navigate([this.location.id], { relativeTo: this.route });
            this.savingLocation = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingLocation = false;
          }
        );
    }
  }

  close() {
    if (this.unsavedChanges) {
      this.modalService.defaultModal({
        title: 'Unsaved changes',
        body: 'Are you sure you want to leave the page without saving changes?',
        size: 'small',
        buttons: [
          {
            text: 'Stay',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Leave',
            color: 'primary',
            handler: () => {
              if (this.locationId) {
                this.router.navigate(['../..', 'locations'], { relativeTo: this.route });
              } else {
                this.router.navigate(['..', 'locations'], { relativeTo: this.route });
              }
            }
          }
        ]
      });
    } else {
      if (this.locationId) {
        this.router.navigate(['../..', 'locations'], { relativeTo: this.route });
      } else {
        this.router.navigate(['..', 'locations'], { relativeTo: this.route });
      }
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Location Deletion',
      body: 'Are you sure you want to delete this location?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.locationService.deleteConferenceLocation(this.conferenceId, this.locationId)
              .subscribe(
                () => {
                  this.close();
                },
                (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

}
