import { Component, Input, OnInit } from '@angular/core';
import { Article } from '@navus/core/classes/article';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'nv-article-modal',
    templateUrl: './article-modal.component.html'
})
export class ArticleModalComponent implements OnInit {
    @Input() article: Article;
    content: string|SafeHtml;

    constructor(
        public activeModal: NgbActiveModal,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit() {
        const html = this.article.content.toString();
        this.content = this.sanitizer.bypassSecurityTrustHtml(html);
    }

    close() {
        this.activeModal.dismiss();
    }

}
