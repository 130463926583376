import { Component, Input, OnInit } from '@angular/core';
import { Block } from '@navus/core/classes/block';
import { BlockService } from '@navus/core/services/block.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'nv-block-details-modal',
  templateUrl: 'block-details.component.html'
})

export class LSBlockDetailsComponent implements OnInit {
  @Input() conferenceId: number;
  @Input() blockId: number;
  block: Block = null;

  constructor(
    private activeModal: NgbActiveModal,
    private blockService: BlockService,
  ) {}

  ngOnInit() {
    this.getBlock();
  }

  getBlock() {
    const params = {
      include: 'chairpersons,location,presentations,presentations.speakers'
    }
    this.blockService
      .getConferenceBlock(this.conferenceId, this.blockId, params)
      .subscribe(
        (response) => {
          this.block = response.data;
        }
      )
  }

  close() {
    this.activeModal.dismiss();
  }
}