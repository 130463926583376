import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component
({
  selector: 'nv-not-found-page',
  templateUrl: './not-found-page.component.html'
})
export class NotFoundPageComponent implements OnInit {

  constructor(
    public location: Location
  ) {}

  ngOnInit() {
  }

}
