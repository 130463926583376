import { Component, Output, EventEmitter, ContentChildren, QueryList, AfterContentInit } from '@angular/core';
import { NavusTabComponent } from './tab.component';

@Component({
  selector: 'nv-tabs',
  templateUrl: './tabs.component.html'
})
export class NavusTabsComponent implements AfterContentInit {
  @ContentChildren(NavusTabComponent) tabs: QueryList<NavusTabComponent>;
  @Output() notify = new EventEmitter();

  ngAfterContentInit() {
    const activeTab = this.tabs.find(tab => tab.active);
    if (!activeTab) {
      setTimeout(() => {
        if (this.tabs && this.tabs.first) {
          this.tabs.first.active = true;
        }
      }, 1500);
    }
  }

  select(tab: NavusTabComponent) {
    this.tabs.toArray().forEach(tabItem => tabItem.active = tabItem === tab);
    this.notify.emit(tab);
  }

}
