import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthorService } from '@navus/core/services/author.service';
import { LoadingService } from '@navus/ui/loading/loading.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { Author } from '@navus/core/classes/author';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'main [nv-author-list-page]',
  templateUrl: './author-list-page.component.html'
})
export class AuthorListPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;
  organizationId: number;
  conferenceId: number;
  authors: Author[] = [];

  pagination: { page?: number, perPage: number, total?: number } = {
    perPage: 30
  };
  sort: { field: string, direction: string } = {
    field: 'order',
    direction: 'asc'
  };
  selectedIds: number[] = [];

  authorsLoading: boolean = true;
  authorsInviting: boolean = false;
  optionsShown: number = null;

  formFilter: FormGroup;
  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authorService: AuthorService,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      search_term: ['']
    });
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getAuthors();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getAuthors(append: boolean = false) {
    if (append && this.pagination.total && this.authors.length >= this.pagination.total) { return; }

    this.pagination.page = append ? this.pagination.page + 1 : 1;

    const params: any = {
      page: this.pagination.page,
      per_page: this.pagination.perPage,
      sort_by: this.sort.field,
      sort_direction: this.sort.direction,
    };
    if (this.formFilter.value.search_term !== '') {
      params.search_term = this.formFilter.value.search_term;
    }
    this.authorsLoading = !append;
    this.authorService.getConferenceAuthors(this.conferenceId, params)
      .subscribe(
        (response: { data: Author[], meta: any }) => {
          if (append) {
            this.authors = this.authors.concat(response.data);
          } else {
            const port = document.getElementsByClassName('nv-config-content-port');
            if (port && port.length > 0) {
              port[0].scrollTop = 0;
            }
            
            this.authors = response.data;
            this.pagination.total = response.meta.pagination.total;
          }
          this.authorsLoading = false;
        },
        () => {
          this.authorsLoading = false;
        }
      );
  }

  edit(author: Author) {
    this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'author', author.id]);
  }

  editInNewTab(author: Author) {
    window.open(`/o/${this.organizationId}/conference/${this.conferenceId}/author/${author.id}`, '_blank');
  }

  close() {
    this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'landing']);
  }

  add() {
    this.router.navigate(['..', 'author'], { relativeTo: this.route });
  }

  delete(author: Author) {
    this.modalService.defaultModal({
      title: 'Author Deletion',
      body: 'Are you sure you want to delete this author?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.authorService.deleteConferenceAuthor(this.organizationId, author.id)
              .subscribe(
                () => {
                  this.getAuthors();
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

  sortAuthors(field: string) {
    if (field === this.sort.field) {
      this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sort.field = field;
      this.sort.direction = 'asc';
    }
    this.getAuthors();
  }

  toggleSelected(author: Author) {
    const index = this.selectedIds.indexOf(author.id);
    if (index !== -1) {
      this.selectedIds.splice(index, 1);
    } else {
      this.selectedIds.push(author.id);
    }
  }

  selectAll() {
    this.selectedIds = [];
    for (const author of this.authors) {
      this.selectedIds.push(author.id);
    }
  }

  unselectAll() {
    this.selectedIds = [];
  }

  openOptions(author: Author) {
    event.stopPropagation();
    this.optionsShown = author.id;
  }

  closeOptions() {
    event.stopPropagation();
    this.optionsShown = null;
  }
    
  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.authors, event.previousIndex, event.currentIndex);

    const author = this.authors[event.currentIndex];
    let order;

    if (event.previousIndex > event.currentIndex) {
      order = this.authors[event.currentIndex + 1].order;
    } else {
      order = this.authors[event.currentIndex - 1].order;
    }

    this.authorService
      .updateConferenceAuthor(this.conferenceId, author.id, { order: order })
      .subscribe(
        (response) => {
            let foundIndex = this.authors.findIndex(x => x.id == author.id);
            this.authors[foundIndex] = response.data;
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }

  inviteSelected() {
    if (this.selectedIds.length === 0) { return; }
    
    // const invitation: any = {
    //   email: this.invitationForm.get('email').value,
    //   role: 'moderator',
    //   resource_id: this.blockId,
    //   resource_type: 'block',
    //   url: `${this.invitationDomain}/speaker-studio?tabs=agenda,qna&layout=lo-type1&email=` + this.invitationForm.get('email').value,
    // };

    // this.authorsInviting = true;
    // this.authService
    //   .createConferenceInvitation(this.conferenceId, { invitations: [invitation] })
    //   .subscribe(
    //     (response: { data: any[], meta: any }) => {
    //       this.authorsInviting = false;
    //       this.modalService.defaultModal({
    //         body: 'Invitation sent.',
    //         buttons: [{ text: 'OK', role: 'cancel' }]
    //       });
    //     },
    //     () => {
    //       this.authorsInviting = false;
    //     }
    //   );
  }

}
