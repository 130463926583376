<div class="custom-chart-legend">
    <div class="custom-chart-legend-title">
        {{title}}
    </div>
    <div class="custom-chart-legend-items">
        <ng-container *ngFor="let row of legendItems">
            <div class="custom-chart-legend-item"
                 [class.active]="isLabelActive(row)"
                 (click)="selectRow(row.name)"
                 (mouseenter)="legendLabelActivate(row)"
                 (mouseleave)="legendLabelDeactivate(row)">
                <div class="custom-chart-color-box" [style.background-color]="row.color"></div>
                <div class="custom-chart-legend-name">{{row.name}}: <b>{{row.value}}</b></div>
                <span class="custom-chart-legend-close"
                      (click)="removeRow(row.id)"
                      *ngIf="itemsManipulation"></span>
            </div>
        </ng-container>
    </div>
</div>

<div class="custom-chart-legend-add" *ngIf="itemsManipulation && availableItems?.length">
    <br>
    <nv-select
            [multi]="true"
            [options]="availableItems"
            [(ngModel)]="selectedItems"
            [label]="'Add session'">
    </nv-select>
    <br>
    <nv-button size="medium" (click)="addRow()">
        Add attendance
    </nv-button>
</div>
