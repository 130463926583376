import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Author } from '@navus/core/classes/author';
import { Block } from '@navus/core/classes/block';
import { Presentation } from '@navus/core/classes/presentation';
import { Program } from '@navus/core/classes/program';
import { TimeslotDistribution } from '@navus/core/classes/timeslot-distribution';
import { ConferenceService } from '@navus/core/services/conference.service';
import { PresentationService } from '@navus/core/services/presentation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'nv-speaker-agenda',
  templateUrl: 'speaker-agenda.component.html'
})

export class SpeakerAgendaComponent implements OnInit {
  @Input() conferenceId: number;
  @Input() filterPresentationIds: number[];

  dayOptions = [];
  showPresentations = true;
  searchTerm: string = '';
  filter: any = {
    locationId: null,
    blockCategoryId: null,
    bookmarked: false
  };

  program: Program;
  days: string[] = [];
  selectedDay: string;
  timeslotDistributions: TimeslotDistribution[] = [];
  subscriptions: Subscription[] = [];

  now: string = (new Date()).toISOString().replace('T', ' ').substr(0, 16);
  interval;

  programLoading: boolean = false;
  blocksLoading: number[] = [];
  filtering: boolean = false;

  speakers: any[] = [];

  constructor(
    private conferenceService: ConferenceService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() { }

  ngOnDestroy() {
    clearInterval(this.interval);
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.conferenceId || changes.filterPresentationIds) {
      this.getProgram();
    }
  }

  getProgram() {
    this.programLoading = true;
    this.conferenceService
      .getProgram(this.conferenceId)
      .subscribe(
        (response: { data: Program }) => {
          this.program = response.data;
          this.nestProgramElements();
          if (this.days.length > 0) {
            if (this.days.length > 1) {
              for (const day of this.days) {
                if(this.isToday(day)) {
                  this.selectedDay = day;
                }
              }
            }
            if (!this.selectedDay) {
              this.selectedDay = this.days[0];
            }
          }
          this.programLoading = false;
        },
        () => {
          this.programLoading = false;
        }
      );
  }

  nestProgramElements() {
    this.filtering = true;
    const now = (new Date()).toISOString().replace('T', ' ').substr(0, 16);

    for (const timeslot of this.program.timeslots) {
      if (this.days.indexOf(timeslot.date) === -1) {
          this.days.push(timeslot.date);
      }
    }
    this.days = this.days.sort(
      (a, b) => {
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      });
    this.dayOptions = this.days.map(d => ({
      id: d,
      name: this.datePipe.transform(d, 'dd.MM.yyyy')
    }))

    for (const timeslot of this.program.timeslots) {
      if (timeslot.location_id) {
        for (const location of this.program.locations) {
          if (timeslot.location_id === location.id) {
            timeslot.location = location;
          }
        }
      }
    }

    for (const presentation of this.program.presentations) {
      if (presentation.speaker_name) {
        this.speakers[presentation.id] = presentation.speaker_name.split(",").map(function(item) {
          return item.trim();
        });
      }
    }

    for (const timeslot_distribution of this.program.timeslot_distributions) {
      if (timeslot_distribution.block_id) {
        for (const block of this.program.blocks) {
          if (timeslot_distribution.block_id === block.id) {
            let speakersPerBlock = this.program.speakers_per_block.filter(x => x.block_id === block.id);
            let chairs: Author[] = [];
            for (const speakerPerBlock of speakersPerBlock) {
              chairs.push(this.program.speakers.find(x => x.id === speakerPerBlock.speaker_id));
            }
            block.chairpersons = chairs;

            timeslot_distribution.block = block;
            
            if (this.showPresentations) {
              block.presentations = [];
              for (const presentation of this.program.presentations) {
                if (block.id === presentation.block_id && presentation.type!='Break') {
                  block.presentations.push(presentation);
                }
              }
            }
          }
        }
      }

      if (timeslot_distribution.presentation_id) {
        for (const presentation of this.program.presentations) {
          if (timeslot_distribution.presentation_id === presentation.id) {
            timeslot_distribution.presentation = presentation;
          }
        }
      }

      if (timeslot_distribution.timeslot_id) {
        for (const timeslot of this.program.timeslots) {
          if (timeslot_distribution.timeslot_id === timeslot.id) {
            timeslot_distribution.timeslot = timeslot;
          }
        }
      }

      // Search and filter
      let shouldRemove = false;
      if (timeslot_distribution.block) {
          shouldRemove = true;

          if (timeslot_distribution.block.presentations) {
            timeslot_distribution.block.presentations.forEach(presentation => {
              if (this.filterPresentationIds.includes(presentation.id)) {
                shouldRemove = false;
              } else {
                presentation['hide'] = true;
              }
            });
          }
      }
      timeslot_distribution.should_remove = shouldRemove;
    }


    this.timeslotDistributions = this.program.timeslot_distributions
    .sort(
      (a, b) => {
        if (a.starts_at < b.starts_at) {
          return -1;
        } else if (a.starts_at > b.starts_at) {
          return 1;
        } else {
          if (a.order < b.order) {
            return -1;
          } else if (a.order > b.order) {
            return 1;
          } else {
            return 0;
          }
        }
      });
    this.timeslotDistributions = this.timeslotDistributions.filter(timeslotDistribution => !timeslotDistribution.should_remove);
    setTimeout(() => {
      this.filtering = false;
    }, 500);
  }

  isToday(date) {
    const today = new Date();
    const inputDate = new Date(date);
    return (today.toDateString() === inputDate.toDateString());
  }

}