import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Article } from '@navus/core/classes/article';
import { ArticleService } from '@navus/core/services/article.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { Conference } from '@navus/core/classes/conference';
import { ConferenceService } from '@navus/core/services/conference.service';

@Component({
  selector: 'main [nv-article-details-page]',
  templateUrl: './article-details-page.component.html',
  styles: []
})
export class ArticleDetailsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  articleId: number;
  article: Article;
  articleForm: FormGroup;
  conferences: Conference[] = [];

  loadingArticle: boolean = true;
  savingArticle: boolean = false;
  unsavedChanges: boolean = false;

  statusOptions = [
    {id: 'draft', name: 'Draft'},
    {id: 'approved', name: 'Published'}
  ];

  subscriptions: Subscription[] = [];

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private articleService: ArticleService,
    private conferenceService: ConferenceService,
    private toastService: ToastService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.initForm();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.articleId = data.articleId;
        this.getConferences();
        this.getArticle();
      }
    );
    this.subscriptions.push(subscription);

    const changeSubscription = this.articleForm.valueChanges.subscribe(
      () => {
        this.unsavedChanges = true;
      }
    );
    this.subscriptions.push(changeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.articleForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      image_url: [''],
      content: ['', [Validators.required]],
      lead: [''],
      published_at: ['', [Validators.required]],
      conference_ids: [null],
      author: [''],
      status: ['draft']
    });
    this.articleForm.patchValue({
      published_at: (new Date()).toISOString().replace('T', ' ').substr(0, 19)
    });
    this.unsavedChanges = false;
  }

  getArticle() {
    if (this.articleId) {
      this.loadingArticle = true;
      this.articleService.getOrganizationArticle(this.organizationId, this.articleId)
        .subscribe(
          (response) => {
            this.article = response['data'];
            this.articleForm.patchValue(this.article);
            this.loadingArticle = false;
            setTimeout(() => { this.unsavedChanges = false; }, 500);
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingArticle = false;
          }
        );
    } else {
      this.article = new Article();
      this.loadingArticle = false;
    }
  }

  getConferences() {
    this.conferenceService.getOrganizationConferences(this.organizationId, { with_pagination: 0 })
      .subscribe(
        (response) => {
          this.conferences = response.data;

          if (this.conferences.length === 1) {
            this.articleForm.get('conference_ids').setValue([this.conferences[0].id]);
          }
        },
        () => { }
      );
  }

  save() {
    if (this.articleForm.invalid) { return; }

    this.savingArticle = true;
    if (this.articleId) {
      this.articleService.updateOrganizationArticle(this.organizationId, this.articleId, this.articleForm.value)
        .subscribe(
          (response) => {
            this.article = response['data'];
            this.toastService.success('Article successfully saved.', {});
            this.unsavedChanges = false;
            this.savingArticle = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingArticle = false;
          }
        );
    } else {
      this.articleService.createOrganizationArticle(this.organizationId, this.articleForm.value)
        .subscribe(
          (response) => {
            this.article = response['data'];
            this.toastService.success('Article successfully saved.', {});
            this.router.navigate([this.article.id], { relativeTo: this.route });
            this.savingArticle = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingArticle = false;
          }
        );
    }
  }

  close() {
    if (this.unsavedChanges) {
      this.modalService.defaultModal({
        title: 'Unsaved changes',
        body: 'Are you sure you want to leave the page without saving changes?',
        size: 'small',
        buttons: [
          {
            text: 'Stay',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Leave',
            color: 'primary',
            handler: () => {
              this.router.navigate(['/o', this.organizationId, 'articles']);
            }
          }
        ]
      });
    } else {
      this.router.navigate(['/o', this.organizationId, 'articles']);
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Article Deletion',
      body: 'Are you sure you want to delete this article?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.articleService.deleteOrganizationArticle(this.organizationId, this.articleId)
              .subscribe(
                () => {
                  this.close();
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

}
