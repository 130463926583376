import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomSectionConfig } from './custom-section.config';

@Component({
  selector: 'nv-custom-section-configuration',
  templateUrl: './custom-section-configuration.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSectionConfigurationComponent),
      multi: true
    }
  ]
})

export class CustomSectionConfigurationComponent implements OnInit, ControlValueAccessor {
  @Input() value: CustomSectionConfig;
  @Input() organizationId: number = 9;
  spacingOptions = [
    { id: 'small-spaced', name: 'Small Spacing' },
    { id: 'spaced', name: 'Normal Spacing' },
    { id: 'large-spaced', name: 'Large Spacing' },
  ];

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  constructor() { }

  ngOnInit() { }

  writeValue(value: any): void {
    this.value = new CustomSectionConfig(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

}
