import { Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Website } from '@navus/core/classes/website';
import { WebsitePage } from '@navus/core/classes/website-page';
import { User } from '@navus/core/classes/user';
import { UserService } from '@navus/core/services/user.service';
import { NotificationService } from '@navus/core/services/notification.service';
import { Subscription } from 'rxjs';
import { Notification } from '@navus/core/classes/notification';
import Echo from 'laravel-echo';
import { environment } from '../../../../environments/environment';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import Pusher from 'pusher-js';

@Component({
  selector: 'nv-website-navigation',
  templateUrl: './website-navigation.component.html'
})
export class WebsiteNavigationComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('headerLogo') headerLogo: ElementRef;
  @ViewChild('headerMenu') headerMenu: ElementRef;

  @Input() website: Website;
  @Input() user: User;

  pages: WebsitePage[] = [];
  sticky: boolean = false;

  notifications: Notification[] = [];
  unreadNotificationsCount: number = 0; 
  pagination: { page?: number, perPage: number, total?: number } = {
    page: 1,
    perPage: 10
  };
  loadingNotifications = false;

  showMenuIcon: boolean = false;
  sumWidths;
  showResponsiveMenu: boolean;
  showSubPages: boolean = false;
  showProfileMenu: boolean = false;
  showNotificationsMenu: boolean = false;

  disableNotificationsMenu: boolean = false;
  disableNotificationsToast: boolean = false;

  subscriptions: Subscription[] = [];
  private LaravelEcho: Echo;

  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private toastService: ToastService,
    private modalService: ModalService,
    private router: Router
  ) {
    // LaravelEcho config
    window['Pusher'] = Pusher;
    window['Echo'] = new Echo({
        broadcaster: 'pusher',
        key: `${environment.mix_pusher_app_key}`,
        wsHost: `${environment.mix_pusher_host}`,
        wsPort: `${environment.mix_pusher_port}`,
        wssPort: `${environment.mix_pusher_port}`,
        forceTLS: `${environment.mix_pusher_port === 443}`,
        encrypted: true,
        disableStats: true,
        authEndpoint: `${environment.api_url}` + 'broadcasting/auth',
        auth: {headers: {'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')}},
        enabledTransports: ['ws', 'wss']
    });
    this.LaravelEcho = window['Echo'];
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.sticky = window.scrollY > 280;
  }

  @HostListener('window:resize') onResize() {
    this.headerResponsive();
  }

  ngOnInit() {
    this.loadPages();
    this.loadNotifications();
    this.LaravelEcho
      .channel('push_notification.' + this.website.active_conference_id)
      .listen('.push_notification.changed_status', (response: { data: Notification }) => {
        const newNotification = response.data;
        if (!this.disableNotificationsToast) {
          if (newNotification?.status === 'published' && newNotification?.channel !== 'app') {
            this.toastService.success(newNotification.title + ': ' + newNotification.body, { disableTimeOut: true, closeButton: true });
          }
        }
        this.loadNotifications();
      });

    this.userService.currentUser
      .subscribe(
        (user) => {
          if (user) {
            this.LaravelEcho
              .channel('push_notification.' + this.website.active_conference_id + '.' + user.id)
              .listen('.badges.badge_won', (response: any) => { 
                this.toastService.newBadge({
                  title: response.badge_level_delegate.badge_level.badge.name,
                  message: response.badge_level_delegate.badge_level.success_message,
                  image_url: response.badge_level_delegate.badge_level.image_url,
                });
              });        
          }
        }
      );

    // Load notification settings
    const conference = this.website.conferences.find(c => c.id === this.website.active_conference_id);

    this.disableNotificationsMenu = !conference.settings?.notification_settings?.show_list;
    this.disableNotificationsToast = !conference.settings?.notification_settings?.show_alert;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user && changes.user.firstChange) {
      setTimeout(() => {
        this.sumWidths = 0; // this.headerLogo.nativeElement.offsetWidth + this.headerMenu.nativeElement.offsetWidth;
        this.headerResponsive();
      }, 1000);
    }
    if (changes.website) {
      this.loadPages();
    }
  }

  ngOnDestroy() {
    this.LaravelEcho.disconnect();
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  isExternalLink(urlString) {
    if (urlString.indexOf('http://') === 0 || urlString.indexOf('https://') === 0) {
      return true;
    }
    return false;
  }

  loadPages() {
    this.pages = [];

    // Sort pages
    const pages = this.website.pages
      .sort((page1: WebsitePage, page2: WebsitePage) => {
        if (page1.order > page2.order) {
          return 1;
        }
        if (page1.order < page2.order) {
          return -1;
        }
        return 0;
      });

    // Add root pages
    pages
      .map((page: WebsitePage) => {
        if (page.visible && page.parent_id === null) {
          this.pages.push(new WebsitePage(page));
        }
      });

    // Add sub-pages
    for (const page of this.pages) {
      for (const subPage of pages) {
        if (subPage.parent_id === page.id && page.visible) {
          if (!page.pages) {
            page.pages = [];
          }
          page.pages.push(new WebsitePage(subPage));
        }
      }
    }
  }

  logout() {
    // TODO reload the page
    this.userService.logout();
    this.router.navigate(['/']);
  }

  headerResponsive() {
    if (window.innerWidth > this.sumWidths) {
      this.sumWidths = 0; // this.headerLogo.nativeElement.offsetWidth + this.headerMenu.nativeElement.offsetWidth;
      this.showMenuIcon = false;
    } else {
      this.showMenuIcon = true;
    }
  }

  toggleMenu() {
    this.showResponsiveMenu = !this.showResponsiveMenu;
    if (this.showResponsiveMenu) {
      document.body.classList.add('nv-responsive-menu-on');
    } else {
      document.body.classList.remove('nv-responsive-menu-on');
    }
  }

  toggleProfileMenu() {
    this.showProfileMenu = !this.showProfileMenu;
  }

  toggleNotificationsMenu() {
    this.showNotificationsMenu = !this.showNotificationsMenu;
  }

  loadNotifications() {
    this.loadingNotifications = true;
    this.pagination.page = 1;
    const params: any = {
      page: this.pagination.page,
      per_page: this.pagination.perPage,
      sort_by: 'schedule_time',
      sort_direction: 'desc',
      status: 'published',
      channel: ['web', 'all'],
    };
    this.notificationService
      .getConferenceNotifications(this.website.active_conference_id, params)
      .subscribe((response: { data: Notification[], meta: any }) => {
        const notifications = response.data;
        this.pagination.total = response.meta.pagination.total;
        this.notifications = notifications;
        this.unreadNotificationsCount = response.meta.unread;
        this.loadingNotifications = false;
      }, (error) => {
        // this.modalService.error({ errors: error });
        this.loadingNotifications = false;
      });
  }

  loadMoreNotifications() {
    if (this.showNotificationsMenu) {
      this.loadingNotifications = true;
      this.pagination.page += 1;
      const params: any = {
        page: this.pagination.page,
        per_page: this.pagination.perPage,
        sort_by: 'schedule_time',
        sort_direction: 'desc',
        status: 'published',
        channel: ['web', 'all'],
      };
      this.notificationService.getConferenceNotifications(this.website.active_conference_id, params)
        .subscribe((response: { data: Notification[], meta: any }) => {
          const notifications = response.data;
          this.notifications = this.notifications.concat(notifications);
          this.unreadNotificationsCount = response.meta.unread;
          this.loadingNotifications = false;
        }, (error) => {
          // this.modalService.error({ errors: error });
          this.loadingNotifications = false;
        });
    }
  }

  markRead(notificationId) {
    this.notificationService
      .readConferenceNotifications(this.website.active_conference_id, [ notificationId ])
      .subscribe((response) => {
        this.notifications.forEach(notification => {
          if (notification.id === notificationId) {
            notification.read = true
          }
        });
        this.unreadNotificationsCount = response.unread;
      });  
  }

  markReadAll() {
    const notificationIds = this.notifications
      .filter(notification => !notification.read)
      .map(notification => notification.id);

    if (notificationIds.length > 0) {
      this.notificationService
        .readConferenceNotifications(this.website.active_conference_id, notificationIds)
        .subscribe((response) => {
          this.notifications.forEach(notification => notification.read = true);
          this.unreadNotificationsCount = response.unread;
        });        
    } 
  }

}
