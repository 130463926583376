import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from '@navus/core/classes/user';
import { UserService } from '@navus/core/services/user.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { InvitationModalComponent } from '../../modals/invitation/invitation-modal.component';

@Component({
  selector: 'main [nv-user-list-page]',
  templateUrl: './user-list-page.component.html'
})
export class UserListPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;
  organizationId: number;
  conferenceId: number;
  users: User[] = [];
  invitations: any[] = [];

  sort: { field: string, direction: string } = {
    field: 'name',
    direction: 'asc'
  };
  selectedIds: number[] = [];

  usersLoading: boolean = false;
  invitationsLoading: boolean = false;
  optionsShown: number = null;

  formFilter: FormGroup;
  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      search_term: ['']
    });
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getInvitations();
        this.getUsers();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getInvitations() {
    this.invitationsLoading = true;
    this.userService.getOrganizationInvitations(this.organizationId)
      .subscribe(
        (response: { data: any[], meta: any }) => {
          this.invitations = response.data.filter((invitation) => {
            return invitation.status === 'sent';
          });
          this.invitationsLoading = false;
        },
        () => {
          this.invitationsLoading = false;
        }
      );
  }

  getUsers() {
    const params: any = {
      with_pagination: 0,
      sort_by: this.sort.field,
      sort_direction: this.sort.direction,
    };
    if (this.formFilter.value.search_term !== '') {
      params.name = this.formFilter.value.search_term;
    }
    this.usersLoading = true;
    this.userService.getOrganizationAdministrators(this.organizationId, params)
      .subscribe(
        (response: { data: User[], meta: any }) => {
          this.users = response.data;
          this.usersLoading = false;
        },
        () => {
          this.usersLoading = false;
        }
      );
  }

  deleteUser(user: User) {
    this.modalService.defaultModal({
      title: 'User Deletion',
      body: 'Are you sure you want to delete this user?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.userService.deleteOrganizationAdministrators(this.organizationId, user.id)
              .subscribe(
                () => {
                  this.getUsers();
                },
                (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

  deleteInvitation(invitation: any) {
    this.modalService.defaultModal({
      title: 'Cancel invitation',
      body: 'Are you sure you want to cancel this invitation?',
      size: 'small',
      buttons: [
        {
          text: 'No',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Yes',
          color: 'accent2',
          handler: () => {
            this.userService.deleteOrganizationInvitation(this.organizationId, invitation.id)
              .subscribe(
                () => {
                  this.getInvitations();
                },
                (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

  sortUsers(field: string) {
    if (field === this.sort.field) {
      this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sort.field = field;
      this.sort.direction = 'asc';
    }
    this.getUsers();
  }

  toggleSelected(user: User) {
    const index = this.selectedIds.indexOf(user.id);
    if (index !== -1) {
      this.selectedIds.splice(index, 1);
    } else {
      this.selectedIds.push(user.id);
    }
  }

  selectAll() {
    this.selectedIds = [];
    for (const user of this.users) {
      this.selectedIds.push(user.id);
    }
  }

  unselectAll() {
    this.selectedIds = [];
  }

  openOptions(user: User) {
    event.stopPropagation();
    this.optionsShown = user.id;
  }

  closeOptions() {
    event.stopPropagation();
    this.optionsShown = null;
  }

  invite() {
    const modalRef = this.modalService.open(InvitationModalComponent);
    modalRef.componentInstance.organizationId = this.organizationId;
    modalRef.result.then(
      () => {
        this.getInvitations();
      },
      () => { }
    );
  }

}

