import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from '@navus/core/classes/user';
import { UserService } from '@navus/core/services/user.service';
import { Organization } from '@navus/core/classes/organization';
import { OrganizationService } from '@navus/core/services/organization.service';
import { ConferenceService } from '@navus/core/services/conference.service';
import { Conference } from '@navus/core/classes/conference';

@Component({
  selector: '[nv-organization-navigation]',
  templateUrl: './organization-navigation.component.html'
})
export class OrganizationNavigationComponent implements OnInit, OnDestroy {
  user: User;

  organizations: Organization[] = [];
  organizationsSearch: string = '';
  organizationsPage: number = 1;
  organizationsTotal: number = null;
  currentOrganization: Organization = null;
  showOrganizations: boolean = false;

  conferences: Conference[] = [];
  conferencesSearch: string = '';
  conferencesPage: number = 1;
  conferencesTotal: number = null;
  currentConference: Conference = null;
  showConferences: boolean = false;

  showUserMenu = false;

  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private organizationService: OrganizationService,
    private conferenceService: ConferenceService
) { }

  ngOnInit() {
    const organizationSubscription = this.organizationService.currentOrganization.subscribe(
      (organization: Organization) => {
        this.currentOrganization = organization;
        this.conferences = [];
      }
    );
    this.subscriptions.push(organizationSubscription);

    const conferenceSubscription = this.conferenceService.currentConference.subscribe(
      (conference: Conference) => { this.currentConference = conference; }
    );
    this.subscriptions.push(conferenceSubscription);

    const userSubscription = this.userService.currentUser.subscribe(
      (user: User) => {
        this.user = user;
      }
    );
    this.subscriptions.push(userSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getOrganizations(append: boolean = false) {
    if (append) {
      if (this.organizationsTotal && this.organizations.length >= this.organizationsTotal) { return; }
      this.organizationsPage++;
    } else {
      this.organizationsPage = 1;
    }

    const params: any = {
      page: this.organizationsPage,
      per_page: 20,
      sort: 'name',
      order: 'asc'
    };

    if (this.organizationsSearch !== '') { params.name = this.organizationsSearch; }

    this.organizationService.getMyOrganizations(params)
      .subscribe(
        (response: { data: Organization[], meta: any }) => {
          if (append) {
            this.organizations = this.organizations.concat(response.data);
          } else {
            this.organizations = response.data;
            if (response.meta) {
              this.organizationsTotal = response.meta.pagination.total;
            }
          }
        },
        () => { }
      );
  }

  setOrganization(organizationId: number) {
    this.router.navigate([ '/o', organizationId, 'landing' ]);
    this.showOrganizations = false;
    this.organizationsSearch = '';
  }

  toggleOrganizations() {
    event.stopPropagation();
    this.showOrganizations = !this.showOrganizations;

    if (this.showOrganizations) {
      this.getOrganizations();
    } else {
      this.organizationsSearch = '';
    }
  }

  getConferences(append: boolean = false) {
    if (append) {
      if (this.conferencesTotal && this.conferences.length >= this.conferencesTotal) { return; }
      this.conferencesPage++;
    } else {
      this.conferencesPage = 1;
    }

    const params: any = {
      page: this.conferencesPage,
      per_page: 20,
      sort: 'name',
      order: 'asc'
    };

    if (this.conferencesSearch !== '') { params.name = this.conferencesSearch; }

    this.conferenceService.getOrganizationConferences(this.currentOrganization.id, params)
      .subscribe(
        (response: { data: Conference[], meta: any }) => {
          if (append) {
            this.conferences = this.conferences.concat(response.data);
          } else {
            this.conferences = response.data;
            this.conferencesTotal = response.meta.pagination.total;
          }
        },
        () => { }
      );
  }

  setConference(conferenceId: number) {
    this.router.navigate([ '/o', this.currentOrganization.id, 'conference', conferenceId, 'landing' ]);
    this.showConferences = false;
    this.conferencesSearch = '';
  }

  toggleConferences() {
    event.stopPropagation();
    this.showConferences = !this.showConferences;

    if (this.showConferences) {
      this.getConferences();
    } else {
      this.conferencesSearch = '';
    }
  }

  toggleUserMenu() {
    this.showUserMenu = !this.showUserMenu;
  }

  logout() {
    this.userService.logout();
    this.router.navigate(['/login']);
  }

}
