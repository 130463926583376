<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Voting</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-8">
                <nv-button (click)="exportVotingResults()" translate>Export Results</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <form class="config-search-bar-w-filters" [formGroup]="formFilter" autocomplete="off">
        <div class="sbwf-port">
            <input type="text" placeholder="Search..."
                   formControlName="search_term" (change)="getPresentations()">
            <span class="sbwf-outline"></span>
        </div><!-- /.sbwf-port -->
    </form><!-- /.config-search-bar-w-filters -->
</div>
<div class="nv-config-content-port" nvInfiniteScroll (scrolled)="getPresentations(true)">
    <div class="nv-global-loading on" [class.on]="presentationsLoading">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <section class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates">
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content">
                        <div *ngIf="pagination.total === 0">
                            <div class="nv-empty-state">
                                <div class="img">
                                    <img src="../../../../assets/empty-states/empty-state-presentations.svg" alt="">
                                </div>
                                <h4 class="title">No votings!</h4>
                                <p class="text">Once you add votings to presentation, you'll see them here.</p>
                            </div>
                        </div>
                        <table class="nv-data-table break-lap">
                            <tr *ngFor="let presentation of presentations">
                                <td class="dt-cell nv-clickable" (click)="edit(presentation)">
                                    <label>TIME</label>
                                    <strong>{{ presentation.starts_at | nvDate: 'dd.MM.yyyy' }} {{ presentation.starts_at | nvDate: 'HH:mm' }} - {{ presentation.ends_at | nvDate: 'HH:mm' }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-cell nv-clickable" (click)="edit(presentation)">
                                    <label>TITLE</label>
                                    <strong>{{ presentation.title }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-cell nv-clickable" (click)="edit(presentation)">
                                    <label>TYPE</label>
                                    <strong>{{ presentation.type }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-actions">
                                    <div class="nv-cell-actions">
                                    <span class="nv-cell-action-trigger"
                                          [class.on]="optionsShown === presentation.id"
                                          (click)="openOptions(presentation)"></span>
                                        <nav nvOffClick (offClick)="closeOptions()">
                                            <ul>
                                                <li (click)="edit(presentation)">
                                                    <a>Edit</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div><!-- /.nv-cell-actions -->
                                </td>
                            </tr>
                        </table>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->
</div><!-- /.config-layout-lower-fixed -->
