import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Conference } from "@navus/core/classes/conference";
import { User } from "@navus/core/classes/user";
import { Website } from "@navus/core/classes/website";
import { BadgeService } from "@navus/core/services/badge.service";
import { UserService } from "@navus/core/services/user.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'nv-website-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {
  website: Website;
  conference: Conference;
  banner: any;
  currentPage: string = '';

  isSpeaker: boolean = false;
  isModerator: boolean = false;
  isAuthor: boolean = false;
  isSponsor: boolean = false;

  hasActiveBadges: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private badgeService: BadgeService,
  ) { }
  
  ngOnInit() {
    this.website = this.route.parent.parent.snapshot.data.website;
    this.conference = this.website?.conferences?.find(c => c.id === this.website.active_conference_id);

    this.checkIfActiveBadges();

    const userSubscription = this.userService.currentUser
      .subscribe((user: User) => {  
        const roles = user?.resource_roles || [];

        this.isSpeaker = !!roles.find(r => 
          r.resource_type === 'conference' &&
          r.resource_id === this.conference.id &&
          r.role === 'speaker'
        );
        this.isModerator = !!roles.find(r => 
          r.resource_type === 'conference' &&
          r.resource_id === this.conference.id &&
          r.role === 'moderator'
        );
        this.isAuthor = !!roles.find(r => 
          r.resource_type === 'conference' &&
          r.resource_id === this.conference.id &&
          r.role === 'author'
        );

        this.isSponsor = !!roles.find(r => 
          r.resource_type === 'conference' &&
          r.resource_id === this.conference.id &&
          r.role === 'sponsor'
        );
      });
    this.subscriptions.push(userSubscription);

    this.currentPage = this.router.url.replace('/profile/', '');
    const routerSubscription = this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {
          this.currentPage = event.url.replace('/profile/', '');

          // Only redirect if not subroute
          if (this.router.url.split('/').length === 2) {
            this.router.navigate(['info'], { relativeTo: this.route });
          }
        }
      }
    );
    this.subscriptions.push(routerSubscription);

    // Only redirect if not subroute
    if (this.router.url.split('/').length === 2) {
      this.router.navigate(['info'], { relativeTo: this.route });
    }
  }

  checkIfActiveBadges() {
    this.badgeService
      .getConferenceBadges(this.conference.id)
      .subscribe(
        (response) => {
          const activeBadges = response.data.filter(b => b.active === 1);
          this.hasActiveBadges = activeBadges.length;
        }
      );
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

}