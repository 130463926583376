import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'nv-stream-change',
  templateUrl: './stream-change.component.html'
})
export class StreamChangeComponent implements OnInit {

  @Input() streams: any;
  @Input() currentStreamId: any;

  streamOptions: any;
  selectedStream: any;
  streamForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.initForm();
    this.getStreams();
    this.streamForm.get('stream_id').valueChanges
      .subscribe((streamId) => {
        this.getStream(streamId);
      })
  }

  initForm() {
    this.streamForm = this.formBuilder.group({
      stream_id: [null, Validators.required]
    });
  }

  getStreams() {
    this.streamOptions = this.streams.map((stream) => {
      return {
        id: stream.location_id, 
        name: stream.name
      }
    })
    if(this.currentStreamId) {
      this.streamForm.get('stream_id').setValue(this.currentStreamId);
      this.getStream(this.currentStreamId);
    }
  }

  getStream(streamId) {
    this.selectedStream = this.streams.find(stream => stream.location_id == streamId);
  }

  setNewStream() {
    if(this.selectedStream.location_id != this.currentStreamId) {
      this.activeModal.close({
        ...this.streamForm.value,
        stream: this.selectedStream
      });
    }else {
      this.activeModal.dismiss();  
    }
  }

  close() {
    this.activeModal.dismiss();
}

}
