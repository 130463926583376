import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map, first } from 'rxjs/operators';

import { WebsiteService } from '@navus/core/services/website.service';
import { SEOService } from '@navus/core/services/seo.service';

@Injectable()
export class StaticPageResolver implements Resolve<any> {

  constructor(
    private seoService: SEOService,
    private router: Router,
    private websiteService: WebsiteService,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.websiteService.currentWebsite
      .pipe(
        first(),
        map((website: any) => {
          this.seoService.setTitle((route.data.title ? route.data.title + ' | ' : '') + website.name);
          this.websiteService.setCurrentWebsitePage(null);
          return null;
        }),
        catchError((error) => {
          console.log(error);
          return this.router.navigateByUrl('/');
        })
      );
  }
}
