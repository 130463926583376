<div class="nv-row gap-30 propagate" *ngIf="value">
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-input
                    label="Live Suite Code"
                    [(ngModel)]="value.code"
                    (ngModelChange)="onChange(value)"
            ></nv-input>
        </div>
    </div>
</div>
