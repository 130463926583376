import { NgModule } from '@angular/core';
import { NavusCoreModule } from '@navus/core/navus-core.module';
import { NavusUiModule } from '@navus/ui/navus-ui.module';
import { SharedModule } from '../../../shared';
import { FileUploadModule } from 'ng2-file-upload';
import { MaterialDetailsPageComponent } from './pages/material-details/material-details-page.component';
import { MaterialListPageComponent } from './pages/material-list/material-list-page.component';
import { ConsentSettingsPageComponent } from './pages/consent-settings/consent-settings-page.component';
import { MaterialDetailsModalComponent } from './modals/material-details/material-details-modal.component';
import { MaterialAttachModalComponent } from './modals/material-attach/material-attach-modal.component';
import { EmbedCustomizeModalComponent } from './modals/embed-customize/embed-customize-modal.component';
import { VideoPlayerPresetsPageComponent } from './pages/video-player-presets-list/video-player-presets-page.component';
import { VideoPlayerPresetsDetailsComponent } from './pages/video-player-presets-details/video-player-presets-details.component';
import { MaterialDetailsAttachModalComponent } from './modals/material-details-attach/material-details-attach-modal.component';
import { ExternalMaterialComponent } from './modals/external-material/external-material.component';

@NgModule({
  imports: [
    NavusCoreModule.forRoot(),
    NavusUiModule,
    SharedModule,
    FileUploadModule
  ],
  declarations: [
    ConsentSettingsPageComponent,
    MaterialListPageComponent,
    MaterialDetailsPageComponent,

    MaterialDetailsModalComponent,
    MaterialAttachModalComponent,
    EmbedCustomizeModalComponent,
    MaterialDetailsAttachModalComponent,
    VideoPlayerPresetsPageComponent,
    VideoPlayerPresetsDetailsComponent,
    ExternalMaterialComponent,
  ],
  entryComponents: [
    MaterialDetailsModalComponent,
    MaterialAttachModalComponent,
    EmbedCustomizeModalComponent,
    MaterialDetailsAttachModalComponent,
    VideoPlayerPresetsDetailsComponent,
    ExternalMaterialComponent,
  ],
  providers: []
})
export class EmaterialsModule {
}
