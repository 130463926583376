import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../shared';
import {PublicVotingComponent} from './public-voting.component';

const routes: Routes = [
    {
        path: 'pv/:conferenceId/:entityType/:entityId/:votingId/:uniqueId',
        component: PublicVotingComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)]
})

export class PublicVotingRoutingModule {
}
