
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { User } from '@navus/core/classes/user';
import { MeetingRoomService } from '@navus/core/services/meeting-room.service';
import { MeetingRoom } from '@navus/core/classes/meeting-room';
import { ModalService } from '../modal/modal.service';
import { NavusMeetingRoomDetailsModalComponent } from './meeting-room-details/meeting-room-details.component';
import { TrackingService } from '@navus/core/services/tracking.service';

declare var JitsiMeetExternalAPI: any;

@Component({
  selector: 'nv-meeting',
  templateUrl: './meeting.component.html'
})

export class NavusMeetingComponent implements OnInit, OnDestroy {
  @Input() set entityType(value: 'Conference'|'Sponsor'|'Location'|'Presentation'|'Delegate'|'User') {
    this._entityType = value;
    if (this.initialized) {
      this.getMeetingRoom();
    }
  };
  @Input() set entityId(value: number) {
    this._entityId = value;
    if (this.initialized) {
      this.getMeetingRoom();
    }
  };
  @Input() set currentUser(value: User) {
    this._currentUser = value;
    if (this.initialized) {
      this.getMeetingRoom();
    }
  };

  @Input() streamingControls: boolean;
  @Input() autoJoin: boolean;
  @Input() autoCreate: boolean;
  @Input() conferenceId: number;

  @Input() createDisabled: boolean = false;
  @Input() createDescriptionText: string = null;
  @Input() createButtonText: string = 'Create a meeting room';
  @Input() notCreatedDescriptionText: string = 'Meeting room not created yet.';
  @Input() joinButtonText: string = 'Join';
  @Input() cannotJoinDescriptionText: string = 'You cannot join this meeting room. If you think this is a mistake please contact meeting administrator.';

  @Output() meetingRoomChange = new EventEmitter();
  @Output() screenSharingStatusChanged = new EventEmitter();
  @Output() videoStatusChanged = new EventEmitter();
  @Output() audioStatusChanged = new EventEmitter();
  @Output() canCreateChanged = new EventEmitter();
  @Output() canJoinChanged = new EventEmitter();
  @Output() joined = new EventEmitter();
  @Output() closing = new EventEmitter();

  meetingRoom: MeetingRoom;
  participant: {
    participant: any,
    moderator: boolean,
    token: string,
  } = null;

  _entityType: 'Conference'|'Sponsor'|'Location'|'Presentation'|'Delegate'|'User';
  _entityId: number;
  _currentUser: User;

  canCreate: boolean = false;
  canJoin: boolean = false;

  initialized: boolean = false;
  loading: boolean = true;
  streaming: boolean = false;
  
  jitsi: any;
  participants: [];

  constructor(
    private meetingRoomService: MeetingRoomService,
    private modalService: ModalService,
    private trackingService: TrackingService,
  ) {}

  ngOnInit() {
    this.getMeetingRoom();
  }
    
  ngOnDestroy() {
    localStorage.removeItem('jitsiMeetId');
    localStorage.removeItem('features/recent-list');
  }

  public join() {
    if (this.meetingRoom) {
      this.meetingRoomService
        .joinMeetingRoom(this.meetingRoom.id, this._currentUser?.id)
        .subscribe(
          (response) => {
            this.participant = response.data;

            this.loadScript(() => this.initJitsi());
          },
          () => { }
        );      
    }
  }

  loadScript(callback: any) {
    // TODO Check if the script exists
    const script = document.createElement('script');
    script.onload = () => { callback(); };
    script.type = 'text/javascript';
    script.src = 'https://meet.navus.io/external_api.js';

    document.getElementsByTagName('head')[0].appendChild(script);
  }
      
  initJitsi() {
    if(!this.conferenceId || !this.meetingRoom) return;

    const room = this.meetingRoom.room_name;
    const domain = 'meet.navus.io';
    const toolbar = [
      'chat', 'desktop', 'filmstrip', 'microphone', 'camera', 
      'videoquality', 'fullscreen', 'hangup', 'raisehand', 'tileview'
    ];     

    if (this.participant.moderator) {
      toolbar.push('settings', 'security');
    }

    let options = {
      roomName: room,
      width: '100%',
      height: '100%',
      interfaceConfigOverwrite: {
        SHOW_PROMOTIONAL_CLOSE_PAGE: false,
        SHOW_JITSI_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
        VIDEO_QUALITY_LABEL_DISABLED: true,
        TOOLBAR_BUTTONS: toolbar,
      },
      
      parentNode: document.querySelector('#meet'),
      onload: () => { this.loading = false; }
    };

    if (this.participant.token) {
      options["jwt"] = this.participant.token;      
    }

    this.jitsi = new JitsiMeetExternalAPI(domain, options);

    this.trackingService.startTracking(
      this.conferenceId,
      this._entityType.toLowerCase(),
      this._entityId,
      'MEETING',
      { user_id: this._currentUser.id }
    );
    
    this.joined.emit()

    this.jitsi.addEventListener('readyToClose', () => {
      console.log('readyToClose');
      this.participant = null;
      this.closing.emit()
      this.trackingService.stopTrackingAction(this.conferenceId, 'MEETING')
    });
   
  }

  startLiveStream() {
    if (this.jitsi) {
      if (!this.meetingRoom?.rtmp_key) {
          alert("You need to provide streaming address first!");
          return;
      }
      
      if (!this.meetingRoom.rtmp_url) {
          alert("Your setup doesn't have streaming link set. You can continue but nothing will play on the live stage.");
      } 

      this.jitsi.executeCommand('startRecording', {
        mode: 'stream',
        rtmpStreamKey: `${this.meetingRoom.rtmp_url}/${this.meetingRoom.rtmp_key}`,
      });
      this.streaming = true;
    }
  }

  stopLiveStream () {
    if (this.jitsi) {
      this.jitsi.executeCommand('stopRecording', 'stream');
      this.streaming = false;
    }
  }

  public getMeetingRoom() {
    this.initialized = true;
    if (this._entityType && this._entityId && this._currentUser) {
      this.meetingRoomService
        .getConferenceMeetingRooms(this.conferenceId, {
          entity_type: this._entityType,
          entity_id: this._entityId,
          include: 'participants'
        })
        .subscribe(
          (response) => {
            const meetingRooms = response.data;
            this.meetingRoom = meetingRooms[0] || null;
            this.meetingRoomChange.emit(this.meetingRoom);
            
            if (!this.meetingRoom) {
              this.canCreateMeetingRoom();
            } else {
              this.canJoinMeetingRoom();
            }
          },
          () => { }
        )      
    }
  }

  canCreateMeetingRoom() {
    this.meetingRoomService
      .canCreateConferenceMeetingRoom(this.conferenceId, this._entityType, this._entityId)
      .subscribe(
        (response) => {
          this.canCreate = response.data;

          if (this.canCreate && this.autoCreate) {
            this.createMeetingRoom();
          } else {
            this.loading = false;
          }
        },
        () => { }
      );
  }

  canJoinMeetingRoom() {
    this.meetingRoomService
      .canJoinMeetingRoom(this.meetingRoom.id)
      .subscribe(
        (response) => {
          this.canJoin = response.data;

          if (this.canJoin && this.autoJoin) {
            this.join();
          } else {
            this.loading = false;
          }
        },
        () => { }
      )
  }

  createMeetingRoom() {
    const params = {
      entity_type: this._entityType,
      entity_id: this._entityId,
      max_participants: 10,
      type: 'internal',
      title: this._currentUser ? this._currentUser.first_name + ' ' + this._currentUser.last_name : 'Unknown',
    }
    this.meetingRoomService
      .createConferenceMeetingRoom(this.conferenceId, params)
      .subscribe(
        () => {
          this.join();
        },
        () => { }
      )
  }

  showMeetingRoomDetails() {
    const modalRef = this.modalService.open(NavusMeetingRoomDetailsModalComponent);
    modalRef.componentInstance.conferenceId = this.conferenceId;
    modalRef.componentInstance.entityType = this._entityType;
    modalRef.componentInstance.entityId = this._entityId;
    modalRef.result.then(
      (result) => {
        this.getMeetingRoom();
      },
      () => { }
    )
  }

  public getAllParticipants() {
    this.participants = this.jitsi.getParticipantsInfo();
  }

  public focusParticipant(id: number) {
    this.jitsi.setLargeVideoParticipant(id);
  }

  public toggleTile() {
    this.jitsi.executeCommand('toggleTileView');
  }

  public muteEveryone() {
    this.jitsi.executeCommand('muteEveryone');
  }
  
  public toggleShareScreen() {
    this.jitsi.executeCommand('toggleShareScreen');
  }

  public toggleVideo() {
    this.jitsi.executeCommand('toggleVideo');
  }

  public toggleAudio() {
    this.jitsi.executeCommand('toggleAudio');
  }
 
  public copyRoomUrl() {
    navigator.clipboard.writeText(`https://meet.navus.io/${this.meetingRoom.room_name}`);
  }
}
