<div class="nv-modal small">
    <div class="nv-modal-header">
        &nbsp;
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body">
        <h4>Welcome to Navus E-Configurator!</h4>
        <p><strong>You are now in the event page where you can:</strong></p>
        <ul>
            <li>Manage all your data</li>
            <li>Create your event app and website</li>
            <li>Interact with your audience</li>
            <li style="list-style: none">... and much more</li>
        </ul>
        <p><strong>Easy access to your organization!</strong></p>
        <p>
            <img src="../../../../assets/back-to-organization.png" alt>
        </p>
        <p>
            To manage your organization, create new events, invite your co-workers and ask our Eventers for support,
            go to the main organization page by clicking on the organization box.
        </p>
    </div>
    <div class="nv-modal-footer">
        <div class="nv-btn-box gap-10 flow-end">
            <nv-button size="small" color="passive" [routerLink]="['/o', organizationId, 'landing']" (click)="close()">Back to organization</nv-button>
            <nv-button size="small" (click)="close()">Continue to event page</nv-button>
        </div>
    </div>
</div>
