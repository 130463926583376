import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class CourseService {

  constructor(
    private apiService: ApiService
  ) { }

  downloadCertificate(courseId: number, params: any = {}) {
    return this.apiService.download(`courses/${courseId}/certificate`, `certificate_${courseId}`, params);
  }

}
