import { Component, Input, ViewChild } from '@angular/core';
import { NavusPublicChatComponent } from '@navus/ui/public-chat/public-chat.component';

@Component({
  selector: 'nv-speaker-chat-container',
  styleUrls: ['./speaker-chat-container.component.scss'],
  templateUrl: './speaker-chat-container.component.html'
})
export class SpeakerChatContainerComponent {
  public chatMinimized = true;

  @Input() public eventId;
  @Input() public organizationId: number;
  @Input() public chatType: 'delegate'| 'admin';
  @Input() public allow1on1 = true;
  @Input() public allowGroupChat = true;

  @ViewChild(NavusPublicChatComponent) public chatComponent: NavusPublicChatComponent;

  public setChatMinimized(minimized: boolean): void {
    this.chatMinimized = minimized;
  }
}
