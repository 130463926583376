import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PromoSectionConfig } from './promo-section.config';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { BaseSectionComponent } from '../_base/base-section.component';
import { WebsiteService } from '@navus/core/services/website.service';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { WebsiteControlService } from '../../services/website-control.service';
import { Website } from '@navus/core/classes/website';
import { Subscription } from 'rxjs';
import { ApplicationService } from '@navus/core/services/application.service';
import { Application } from '@navus/core/classes/application';

@Component({
  selector: 'nv-promo-section',
  templateUrl: './promo-section.component.html'
})
export class PromoSectionComponent extends BaseSectionComponent implements OnInit, OnDestroy {
  @Input() data: WebsitePageSection;
  configuration: PromoSectionConfig = new PromoSectionConfig();

  conferenceId: number;
  organizationId: number;
  app: Application;
  androidLink: string;
  iosLink: string;
  showProgramPopup: boolean = false;
  showMobileAppPopup: boolean = false;
  showEmaterialsPopup: boolean = false;
  showEplaylistPopup: boolean = false;
  showRegistrationPopup: boolean = false;
  showLiveStreamPopup: boolean = false;
  showMobileAppDownload: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService,
    public applicationService: ApplicationService,
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );
  }

  ngOnInit() {
    this.configuration = new PromoSectionConfig(this.data.configuration);
    const subscription = this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          this.conferenceId = website.active_conference_id;
          this.organizationId = website.organization_id;
        },
        () => { }
      );
    this.subscriptions.push(subscription);

    if(this.data.configuration.showMobileApp) {
      const appSubscription = this.applicationService
        .getConferenceApplication(this.organizationId, this.conferenceId)
        .subscribe(
          (response: { data: Application }) => {
            this.androidLink = response.data.android_link;
            this.iosLink = response.data.ios_link;
            this.app = response.data;
          },
          () => { }
        );
        this.subscriptions.push(appSubscription);
    }
  }

  ngOnDestroy() {

  }

}
