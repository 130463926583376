import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ConferenceService } from '@navus/core/services/conference.service';

@Component({
  selector: 'nv-ticket-type-code-modal',
  templateUrl: './ticket-type-code-modal.component.html'
})
export class TicketTypeCodeModalComponent implements OnInit {
  @Input() conferenceId: number = null;
  @Input() ticketTypeId: string = null;
  @Input() ticketTypeCodeId: string = null;
  typeOptions = [
    { id: 'amount', name: 'Amount' }
  ];

  ticketTypeCodeForm: FormGroup;

  savingTicketTypeCode: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private conferenceService: ConferenceService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getTicketTypeCode();
  }

  initForm() {
    this.ticketTypeCodeForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      code: ['', [Validators.required]],
      type: ['amount', [Validators.required]],
      amount: ['', [Validators.required]]
    });
  }

  getTicketTypeCode() {
    if (this.ticketTypeCodeId) {
      this.conferenceService
          .getConferenceTicketTypeCode(this.conferenceId, this.ticketTypeId, this.ticketTypeCodeId)
        .subscribe(
          (response) => {
            this.ticketTypeCodeForm.patchValue(response.data);
          },
          () => { }
        );
    }
  }

  save() {
    if (this.ticketTypeCodeForm.invalid) { return; }

    this.savingTicketTypeCode = true;
    if (this.ticketTypeCodeId) {
      this.conferenceService
          .updateConferenceTicketTypeCode(this.conferenceId, this.ticketTypeId, this.ticketTypeCodeId, this.ticketTypeCodeForm.value)
        .subscribe(
          () => {
            this.activeModal.close();
            this.savingTicketTypeCode = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingTicketTypeCode = false;
          }
        );
    } else {
      this.conferenceService
          .createConferenceTicketTypeCode(this.conferenceId, this.ticketTypeId, this.ticketTypeCodeForm.value)
        .subscribe(
          () => {
            this.activeModal.close();
            this.savingTicketTypeCode = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingTicketTypeCode = false;
          }
        );
    }
  }

  close() {
    this.activeModal.dismiss();
  }

}
