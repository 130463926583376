import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { ArticleService } from '@navus/core/services/article.service';
import { Article } from '@navus/core/classes/article';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ArticleModalComponent } from '../../modals/article/article-modal.component';
import { ArticleListSectionConfig } from './article-list-section.config';
import { WebsiteService } from '@navus/core/services/website.service';
import { Website } from '@navus/core/classes/website';
import { BaseSectionComponent } from '../_base/base-section.component';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { WebsiteControlService } from '../../services/website-control.service';

@Component({
  selector: 'nv-article-list-section',
  templateUrl: './article-list-section.component.html'
})
export class ArticleListSectionComponent extends BaseSectionComponent implements OnInit, OnDestroy {
  @Input() data: WebsitePageSection;
  organizationId: number;
  configuration: ArticleListSectionConfig = new ArticleListSectionConfig();

  showPopup: boolean = false;

  articles: Article[];

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService,
    private articleService: ArticleService,
    private router: Router
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );
  }

  ngOnInit() {
    this.configuration = new ArticleListSectionConfig(this.data.configuration);

    this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          if (website) {
            this.organizationId = website.organization_id;
            this.getArticles(website.active_conference_id);
          }
        }
      );
  }

  ngOnDestroy() {

  }

  getArticles(conferenceId) {
    const params: any = {
      per_page: this.configuration.limit || 20,
      order: 'desc',
      is_hot_topic: this.configuration.hotTopics ? 1 : 0,
      active: 1,
      status: 'approved',
      conference_id: conferenceId
    };

    this.articleService.getOrganizationArticles(this.organizationId, params)
      .subscribe(
        (response) => {
          this.articles = response['data'];
        }
      );
  }

  openArticle(article: Article) {
    if (this.configuration.openPopup) {
      const modalRef = this.modalService.open(ArticleModalComponent);
      modalRef.componentInstance.article = article;
    } else {
      this.router.navigate(['/article', article.id]);
    }
  }

}
