import { Component } from '@angular/core';

@Component({
    selector: 'app-wizard-setup-page',
    templateUrl: './app-wizard-setup.component.html'
})
export class AppWizardSetupComponent {

    constructor() { }

}
