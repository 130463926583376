import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadlinkService } from '../../../../../navus-core/services/leadlink.service';
import { LeadlinkCampaign } from '../../../../../navus-core/classes/leadlink-campaign';
import { LoadingService } from '../../../../../navus-ui/loading/loading.service';

@Component({
  selector: 'main [nv-leadlink-landing-page]',
  templateUrl: './leadlink-landing-page.component.html'
})
export class LeadlinkLandingPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  leadlinkCampaignId: number;
  leadlinkCampaign: LeadlinkCampaign;

  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private leadlinkService: LeadlinkService,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.leadlinkCampaignId = data.campaignId;
      }
    );
    this.subscriptions.push(subscription);

    const leadlinkCampaignSubscription = this.leadlinkService.currentLeadlinkCampaign
      .subscribe(
        (leadlinkCampaign: LeadlinkCampaign) => {
          this.leadlinkCampaign = leadlinkCampaign;
        },
        () => { }
      );
    this.subscriptions.push(leadlinkCampaignSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  close() {
    this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'landing']);
  }

}
