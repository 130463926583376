import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Presentation } from '@navus/core/classes/presentation';
import { PresentationService } from '@navus/core/services/presentation.service';
import { ModalService } from '@navus/ui/modal/modal.service';

@Component({
  selector: 'main [nv-presentation-list-page]',
  templateUrl: './presentation-list-page.component.html'
})
export class PresentationListPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;
  organizationId: number;
  conferenceId: number;
  presentations: Presentation[] = [];

  pagination: { current_page?: number, per_page: number, total?: number } = {
    current_page: 1,
    per_page: 15
  };
  sortOptions: any[] = [
    { id: 'created_at asc', name: 'Created Ascending' },
    { id: 'created_at desc', name: 'Created Descending' },
    { id: 'title asc', name: 'Title Ascending' },
    { id: 'title desc', name: 'Title Descending' },
    { id: 'block_id asc', name: 'Session Ascending' },
    { id: 'block_id desc', name: 'Session Descending' },
    { id: 'starts_at asc', name: 'Start Ascending' },
    { id: 'starts_at desc', name: 'Start Descending' },
  ];
  sort: string = 'created_at desc';
  selectedIds: number[] = [];

  presentationsLoading: boolean = false;
  optionsShown: number = null;

  formFilter: FormGroup;
  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private presentationService: PresentationService,
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      search_term: ['']
    });
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getPresentations();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getPresentations() {
    const params: any = {
      page: this.pagination.current_page,
      per_page: this.pagination.per_page,
      include: 'block,speakers'
    };
    if (this.sort && this.sort !== '') {
      params.sort_by = this.sort.split(' ')[0];
      params.sort_direction = this.sort.split(' ')[1];
    }
    if (this.formFilter.value.search_term !== '') {
      params.search_term = this.formFilter.value.search_term;
    }
    this.presentationsLoading = true;
    this.presentationService.getConferencePresentations(this.conferenceId, params)
      .subscribe(
        (response: { data: Presentation[], meta: any }) => {
          const port = document.getElementsByClassName('nv-config-content-port');
          if (port && port.length > 0) {
            port[0].scrollTop = 0;
          }
          
          this.presentations = response.data;
          this.pagination = response.meta.pagination;
          this.presentationsLoading = false;
        },
        () => {
          this.presentationsLoading = false;
        }
      );
  }

  edit(presentation: Presentation) {
    this.router.navigate(['..', 'presentation', presentation.id], { relativeTo: this.route });
  }

  editInNewTab(presentation: Presentation) {
    window.open(`/o/${this.organizationId}/conference/${this.conferenceId}/program/presentation/${presentation.id}`, '_blank');
  }

  close() {
    this.router.navigate(['..', '..'], { relativeTo: this.route });
  }

  add() {
    this.router.navigate(['..', 'presentation'], { relativeTo: this.route });
  }

  delete(presentation: Presentation) {
    this.modalService.defaultModal({
      title: 'Presentation Deletion',
      body: 'Are you sure you want to delete this presentation?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.presentationService.deleteConferencePresentation(this.conferenceId, presentation.id)
              .subscribe(
                () => {
                  this.getPresentations();
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

  toggleSelected(presentation: Presentation) {
    const index = this.selectedIds.indexOf(presentation.id);
    if (index !== -1) {
      this.selectedIds.splice(index, 1);
    } else {
      this.selectedIds.push(presentation.id);
    }
  }

  selectAll() {
    this.selectedIds = [];
    for (const presentation of this.presentations) {
      this.selectedIds.push(presentation.id);
    }
  }

  unselectAll() {
    this.selectedIds = [];
  }

  openOptions(presentation: Presentation) {
    event.stopPropagation();
    this.optionsShown = presentation.id;
  }

  closeOptions() {
    event.stopPropagation();
    this.optionsShown = null;
  }

}
