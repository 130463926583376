import { Country } from './country';
import { User } from './user';
import { SafeHtml, SafeStyle } from '@angular/platform-browser';

export class Author {
  id: number;
  imported_id: string;
  title: string;
  first_name: string;
  last_name: string;
  institution: string;
  email: string;
  biography: string;
  additional_info: string;
  image_url: string;
  image_url_thumb: string;
  order: number;
  featured: boolean;

  name: string;

  country_id: number;
  country?: Country;
  user_id: number;
  user?: User;
}
