import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class BillingService {

  constructor(
    private apiService: ApiService
  ) { }

  getOrganizationInvoices(organizationId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/invoices`, params);
  }

  getOrganizationCards(organizationId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/cards`, params);
  }

  getOrganizationCard(organizationId: number, cardId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/cards/${cardId}`, params);
  }

  updateOrganizationCard(organizationId: number, cardId: number, params: any = {}) {
    return this.apiService.put(`organizations/${organizationId}/cards/${cardId}`, params);
  }

  createOrganizationCard(organizationId: number, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/cards`, params);
  }

  deleteOrganizationCard(organizationId: number, cardId: number, params: any = {}) {
    return this.apiService.delete(`organizations/${organizationId}/cards/${cardId}`, params);
  }

  createOrganizationSubscription(organizationId: number, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/subscriptions`, params);
  }

  getCards(params: any = {}) {
    return this.apiService.get(`payments/cards`, params);
  }

  createCard(params: any = {}) {
    return this.apiService.post(`payments/cards`, params);
  }

  setDefaultCard(cardId: number, params: any = {}) {
    return this.apiService.put(`payments/cards/${cardId}`, params);
  }

  deleteCard(cardId: number, params: any = {}) {
    return this.apiService.delete(`payments/cards/${cardId}`, params);
  }


}
