import { Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../environments/environment';
import Editor from './build/ckeditor';
@Component({
  selector: 'nv-text-editor',
  templateUrl: 'text-editor.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NavusTextEditorComponent),
    multi: true
  }]
})

export class NavusTextEditorComponent implements OnInit, ControlValueAccessor {
  @Input() value: string = null;
  @Input() config: any = {};
  @Input() disabled: boolean = false;
  @Input() formControlName: string;
  @Input() label: string;
  @Input() required: boolean = false;
  @Input() errorMessages: any = {};
  @Input() organizationId: number = 9;

  public Editor = Editor;

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  data: string;

  errors: any[] = [];
  showErrors: boolean;

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer
  ) {
    this.config = {
      link: {
        addTargetToExternalLinks: true,
        decorators: {
          addTargetToExternalLinks: {
            mode: 'automatic',
            callback: url => /^(https?:)?\/\//.test( url ),
            attributes: {
              target: '_blank',
              rel: 'noopener noreferrer'
            }
          }
        }
      },
      mediaEmbed: {
        extraProviders: [
          // {
          //   name: 'mux',
          //   url: /^https?:\/\/stream\.mux\.com\/(\w+)\.m3u8/,
          //   html: match => `<mux-video src="${match[0]}"></mux-video>`
          // }, 
          {
            name: 'mux',
            url: /stream\.mux\.com\/(\w+)\.(\w+)\??(\w.+)?/,
            html: match => 
            '<div style="position:relative; padding-bottom:56%; height:0">' +
              `<iframe src="https://player.navus.io/${ match[ 1 ] }?${ match[ 3 ]}" frameborder="0" ` +
                'style="position:absolute; width:100%; height:100%; top:0; left:0">' +
              '</iframe>' +
            '</div>'
          },
          {
            name: 'nv-player',
            url: /player\.navus\.io\/(\w+)\??(\w.+)?/,
            html: match => 
            '<div style="position:relative; padding-bottom:56%; height:0">' +
              `<iframe src="https://player.navus.io/${ match[ 1 ] }?${ match[ 2 ]}" frameborder="0" ` +
                'style="position:absolute; width:100%; height:100%; top:0; left:0">' +
              '</iframe>' +
            '</div>'
          }
        ]
      },
      // Fix old font sizes implementation
      fontSize: {
        options: [
          'tiny',
          'small',
          'default',
          'big',
          'huge',
          8,
          12,
          16,
          20,
          24,
          28,
          32,
          36,
          40
      ]
      }
    }
  }

  ngOnInit() {
    if (this.required !== false) {
      this.required = true;
    }
    if (this.disabled !== false) {
      this.disabled = true;
    }
    if (!this.label) {
      this.label = '';
    }
  }

  writeValue(value: any): void {
    setTimeout(() => {
      this.value = value ? value : '';
      if (this.value === '') {
        this.errors = [];
      }
    }, 500)
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  displayError() {
    if (!this.formControlName) {
      return;
    }

    const formControl = this.controlContainer.control.get(this.formControlName);
    if (!formControl.touched) {
      return;
    }

    if (formControl.errors) {
      this.required = formControl.errors.required || false;
      const errors = [];

      for (const [key, value] of Object.entries(formControl.errors)) {
        errors.push(key);
      }

      this.errors = errors;
      if (this.value) {
        formControl.updateValueAndValidity();
        this.errors.splice(this.errors.indexOf('required'), 1);
      }
    } else {
      this.errors = [];
    }
  }
  
  onChangeValue() {
    if (this.onChange) {
      this.onChange(this.value);
      this.displayError();
    }
  }

  toggleErrors() {
    this.showErrors = !this.showErrors;
  }

  onReady(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader)=> {
      return new UploadAdapter(loader, this.organizationId);
    };
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }
}

class UploadAdapter {
  loader: any;
  organizationId: number;

  constructor( loader, organizationId ) {
    this.loader = loader;
    this.organizationId = organizationId;
  }

  upload() {
    return this.loader.file
      .then( (file) => new Promise( ( resolve, reject ) => {

        if (localStorage.getItem('jwtToken')) {
          const uploader = new FileUploader({
            url: `${environment.api_url}organizations/${this.organizationId}/resources`,
            removeAfterUpload: true,
            autoUpload: true,
            authTokenHeader:  'Authorization',
            authToken: 'Bearer ' + localStorage.getItem('jwtToken'),
            headers: [],
          });

          uploader.addToQueue([file]);
          uploader.uploadAll();
          uploader.onCompleteItem = (file, response) => {
            const result = JSON.parse(response);
            resolve({ default: environment.file_root + result.data.path });
          }
        } else {
          var myReader= new FileReader();
          myReader.onloadend = (e) => {
            resolve({ default: myReader.result });
          }
          myReader.readAsDataURL(file);
        }
      } ) );
  };
}