import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PresentationService } from '@navus/core/services/presentation.service';
import { Presentation } from '@navus/core/classes/presentation';
import { ModalService } from '@navus/ui/modal/modal.service';
import { VotingService } from '@navus/core/services/voting.service';

@Component({
  selector: 'main [nv-voting-list-page]',
  templateUrl: './voting-list-page.component.html'
})
export class VotingListPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;
  organizationId: number;
  conferenceId: number;
  presentations: Presentation[] = [];

  pagination: { page?: number, perPage: number, total?: number } = {
    perPage: 30
  };
  sort: { field: string, direction: string } = {
    field: 'title',
    direction: 'asc'
  };
  selectedIds: number[] = [];

  presentationsLoading: boolean = false;
  optionsShown: number = null;

  formFilter: FormGroup;
  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private presentationService: PresentationService,
    private votingService: VotingService,
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      search_term: ['']
    });
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getPresentations();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  
  exportVotingResults() {
    this.votingService
      .downloadConferenceVotingResults(this.conferenceId)
      .subscribe(
        (response) => {
          console.log(response);
        }
      )
  }

  getPresentations(append: boolean = false) {
    if (append && this.pagination.total && this.presentations.length >= this.pagination.total) { return; }

    this.pagination.page = append ? this.pagination.page + 1 : 1;

    const params: any = {
      page: this.pagination.page,
      per_page: this.pagination.perPage,
      sort_by: this.sort.field,
      sort_direction: this.sort.direction,
      has_voting: 1
    };
    if (this.formFilter.value.search_term !== '') {
      params.search_term = this.formFilter.value.search_term;
    }
    this.presentationsLoading = !append;
    this.presentationService.getConferencePresentations(this.conferenceId, params)
      .subscribe(
        (response: { data: Presentation[], meta: any }) => {
          if (append) {
            this.presentations = this.presentations.concat(response.data);
          } else {
            const port = document.getElementsByClassName('nv-config-content-port');
            if (port && port.length > 0) {
              port[0].scrollTop = 0;
            }
            
            this.presentations = response.data;
            this.pagination.total = response.meta.pagination.total;
          }
          this.presentationsLoading = false;
        },
        () => {
          this.presentationsLoading = false;
        }
      );
  }

  edit(presentation: Presentation) {
    this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'voting', 'votings', presentation.id]);
  }

  close() {
    this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'landing']);
  }

  delete(presentation: Presentation) {
    this.modalService.defaultModal({
      title: 'Presentation Deletion',
      body: 'Are you sure you want to delete this presentation?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.presentationService.deleteConferencePresentation(this.conferenceId, presentation.id)
              .subscribe(
                () => {
                  this.getPresentations();
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

  sortPresentations(field: string) {
    if (field === this.sort.field) {
      this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sort.field = field;
      this.sort.direction = 'asc';
    }
    this.getPresentations();
  }

  toggleSelected(presentation: Presentation) {
    const index = this.selectedIds.indexOf(presentation.id);
    if (index !== -1) {
      this.selectedIds.splice(index, 1);
    } else {
      this.selectedIds.push(presentation.id);
    }
  }

  selectAll() {
    this.selectedIds = [];
    for (const presentation of this.presentations) {
      this.selectedIds.push(presentation.id);
    }
  }

  unselectAll() {
    this.selectedIds = [];
  }

  openOptions(presentation: Presentation) {
    event.stopPropagation();
    this.optionsShown = presentation.id;
  }

  closeOptions() {
    event.stopPropagation();
    this.optionsShown = null;
  }

}

