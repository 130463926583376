export class PortalSettings {
    id: number;
    url: string;
    name: string;
    slug: string;
    description: string;
    external_url: string;
    logo: string;
    header: string;
    footer: string;
    terms_and_conditions: string;
    default_language: null;
    use_code: boolean;
    summary_builder: boolean;
    meta_title: string;
    meta_keywords: string;
    meta_description: string;
    updated_at: string;
    organization_id: number;
    app_terms_and_conditions: string;
    gdpr: string;
    regional_settings: any;
    pages: any[];
    conferences: any[];
    primary_color: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
