import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { ApproveStatus, ChatUser, Message, Room, RoomType } from '@navus/core/classes/chat';
import { map, switchMap, take } from 'rxjs/operators';
import firebase from 'firebase/app';
import { ModalService } from '@navus/ui/modal/modal.service';
import { combineLatest, from, Observable, of } from 'rxjs';
import { UserService } from '@navus/core/services/user.service';
import { User } from '@navus/core/classes/user';

@Injectable()
export class ChatService {

  public currentUser: Observable<User> = this.userService.currentUser;
  private readonly COLLECTION_USERS = 'users';
  private readonly COLLECTION_CHAT_ROOMS = 'rooms';
  private readonly COLLECTION_EVENTS = 'events';
  private readonly COLLECTION_MESSAGES = 'messages';
  private readonly MESSAGE_LOAD = 15;

  constructor(
    private apiService: ApiService,
    private firestore: AngularFirestore,
    private firebaseAuth: AngularFireAuth,
    private modalService: ModalService,
    private userService: UserService
  ) { }

  public getAllPublicRooms(eventId: number) {
    return this.firestore.collection<Room>(
      `${this.COLLECTION_EVENTS}/${eventId}/${this.COLLECTION_CHAT_ROOMS}`,
      ref => ref.where('type', '==', RoomType.PUBLIC_CHAT)
    ).valueChanges();
  }

  public getAllPublicGroups(eventId: number) {
    return this.firestore.collection<Room>(
      `${this.COLLECTION_EVENTS}/${eventId}/${this.COLLECTION_CHAT_ROOMS}`,
      ref => ref.where('type', '==', RoomType.PUBLIC_GROUP)
    ).valueChanges();
  }

  public getAllRoomsWithCurrentUser(eventId: number, currentUserId: number) {
    return this.firestore
      .collection<Room>(
        `${this.COLLECTION_EVENTS}/${eventId}/${this.COLLECTION_CHAT_ROOMS}`,
        ref => ref.where('members', 'array-contains', currentUserId)
      ).valueChanges();
  }

  public getRoomById(eventId: number, roomId: string) {
    return this.firestore
      .collection<Room>(`${this.COLLECTION_EVENTS}/${eventId}/${this.COLLECTION_CHAT_ROOMS}`)
      .doc<Room>(roomId)
      .valueChanges();
  }

  public getAllChatUsers() {
    return this.firestore.collection<ChatUser>(`${this.COLLECTION_USERS}`).valueChanges();
  }


  public retrieveAllChatUsers() {
    return this.firestore.collection<ChatUser>(`${this.COLLECTION_USERS}`).get().pipe(
      map(res => res.docs.map(doc => doc.data())),
    );
  }

  public sendMessageToRoom(eventId: number, currentUserId: number, selectedRoomId: string, newMessage: Message) {
    // Save Message
    return this.firestore
      .collection<Message>(`${this.COLLECTION_EVENTS}/${eventId}/${this.COLLECTION_CHAT_ROOMS}/${selectedRoomId}/${this.COLLECTION_MESSAGES}`)
      .doc(newMessage.uid)
      .set(newMessage, { merge: true })
      .then(() => {
        // If this is a reply, update Replied status of the replied message
        if (newMessage.relatedMessageId) {
          this.firestore
            .collection<Message>(`${this.COLLECTION_EVENTS}/${eventId}/${this.COLLECTION_CHAT_ROOMS}/${selectedRoomId}/${this.COLLECTION_MESSAGES}`)
            .doc(newMessage.relatedMessageId)
            .update({ replied: true })
            .catch((error) => this.modalService.error({ errors: error }));
        }
        // Update room data
        this.firestore.collection<Room>(`${this.COLLECTION_EVENTS}/${eventId}/${this.COLLECTION_CHAT_ROOMS}`)
          .doc(selectedRoomId)
          .update({
            latestMessage: newMessage,
            messageCount: newMessage.index,
            subscribers: firebase.firestore.FieldValue.arrayUnion(currentUserId) as any,
            members: firebase.firestore.FieldValue.arrayUnion(currentUserId) as any
          }).then(() => {
          this.updateMessageLog(currentUserId, selectedRoomId, newMessage.index);
        }).catch((error) => this.modalService.error({ errors: error }));
      }).catch((error) => this.modalService.error({ errors: error }));
  }

  public approveMessage(eventId: number, selectedRoomId: string, message: Message, approve: boolean, userId: number) {
    const fieldsToUpdate = {
      approved: approve,
      editedAt: new Date().getTime()
    };
    if (approve) {
      fieldsToUpdate['approvedBy'] = userId;
    }

    return this.firestore
      .collection<Message>(`${this.COLLECTION_EVENTS}/${eventId}/${this.COLLECTION_CHAT_ROOMS}/${selectedRoomId}/${this.COLLECTION_MESSAGES}`)
      .doc(message.uid)
      .update(fieldsToUpdate)
      .then(() => {
        if (message.relatedMessageId) {
          this.firestore
            .collection<Message>(`${this.COLLECTION_EVENTS}/${eventId}/${this.COLLECTION_CHAT_ROOMS}/${selectedRoomId}/${this.COLLECTION_MESSAGES}`)
            .doc(message.relatedMessageId)
            .update(fieldsToUpdate)
            .catch((error) => this.modalService.error({ errors: error }));
        }
      })
      .catch((error) => this.modalService.error({ errors: error }));
  }

  public updateMessage(eventId: number, selectedRoomId: string, message: Message) {
    return this.firestore
      .collection<Message>(`${this.COLLECTION_EVENTS}/${eventId}/${this.COLLECTION_CHAT_ROOMS}/${selectedRoomId}/${this.COLLECTION_MESSAGES}`)
      .doc(message.uid)
      .set({ ...message }, { merge: true })
      .catch((error) => this.modalService.error({ errors: error }));
  }

  public deleteMessage(eventId: number, selectedRoomId: string, message: Message) {
    return this.firestore
      .collection<Message>(`${this.COLLECTION_EVENTS}/${eventId}/${this.COLLECTION_CHAT_ROOMS}/${selectedRoomId}/${this.COLLECTION_MESSAGES}`)
      .doc(message.uid)
      .delete()
      .catch((error) => this.modalService.error({ errors: error }));
  }

  public getMessagesForRoom(eventId: number, roomId: string, limit = true) {
    return this.firestore
      .collection<Message>(`${this.COLLECTION_EVENTS}/${eventId}/${this.COLLECTION_CHAT_ROOMS}/${roomId}/${this.COLLECTION_MESSAGES}`,
        ref => {
          let query = ref
            .orderBy('createTime', 'desc');
          if (limit) {
            query = query.limit(this.MESSAGE_LOAD);
          }
          return query;
        }
      ).valueChanges()
      .pipe(
        map((messages) => messages.sort((msg1, msg2) => msg1.createTime - msg2.createTime)),
      );
  }

  public getMessagesForRoomByStatus(eventId: number, roomId: string, currentUserId: number,
                                    status: ApproveStatus, canApprove: boolean, timestamp?: number, onlyNewerMessages?: boolean) {
    const collectionPath = `${this.COLLECTION_EVENTS}/${eventId}/${this.COLLECTION_CHAT_ROOMS}/${roomId}/${this.COLLECTION_MESSAGES}`;
    const orderByQuery = canApprove ? 'createTime' : 'editedAt';

    if (status === ApproveStatus.mine) {
      const allMyQuestions = this.firestore
        .collection<Message>(collectionPath,
          ref => {
            let query = ref.where('createdBy', '==', currentUserId);
            if (timestamp && !onlyNewerMessages) {
              query = query.where(orderByQuery, '<', timestamp);
              return query.orderBy(orderByQuery, 'desc').limit(this.MESSAGE_LOAD);
            }
            if (timestamp && onlyNewerMessages) {
              query = query.where(orderByQuery, '>', timestamp);
              return query.orderBy(orderByQuery, 'desc');
            }
          }
        ).valueChanges();

      const allAnswersToMyQuestions = this.firestore
        .collection<Message>(collectionPath,
          ref => {
            let query = ref.where('relatedMessageCreator', '==', currentUserId);
            if (timestamp && !onlyNewerMessages) {
              query = query.where(orderByQuery, '<', timestamp);
              return query.orderBy(orderByQuery, 'desc').limit(this.MESSAGE_LOAD);
            }
            if (timestamp && onlyNewerMessages) {
              query = query.where(orderByQuery, '>', timestamp);
              return query.orderBy(orderByQuery, 'desc');
            }
          }
        ).valueChanges();

      return combineLatest([allMyQuestions, allAnswersToMyQuestions]).pipe(
        map(([myQuestions, answers]) => [...myQuestions, ...answers].sort((msg1, msg2) => msg1[orderByQuery] - msg2[orderByQuery]))
      );

    } else {
      return this.firestore
        .collection<Message>(collectionPath,
          ref => {
            let query = ref as any;
            if (status === ApproveStatus.true) {
              query = query.where('approved', '==', true);
            } else if (status === ApproveStatus.false) {
              query = query.where('approved', '==', false);
            } else if (status === ApproveStatus.null) {
              query = query.where('approved', 'not-in', [true, false]);
            }
            if (timestamp && !onlyNewerMessages) {
              query = query.where(orderByQuery, '<', timestamp);
              return query.orderBy(orderByQuery, 'desc').limit(this.MESSAGE_LOAD);
            }
            if (timestamp && onlyNewerMessages) {
              query = query.where(orderByQuery, '>', timestamp);
              return query.orderBy(orderByQuery, 'desc');
            }
          }
        ).valueChanges();
    }
  }

  public getMessagesWithUpVotesForRoom(eventId: number, roomId: string, currentUserId: number,
                                       status: ApproveStatus) {
    const collectionPath = `${this.COLLECTION_EVENTS}/${eventId}/${this.COLLECTION_CHAT_ROOMS}/${roomId}/${this.COLLECTION_MESSAGES}`;

    if (status === ApproveStatus.mine) {
      const allMyQuestions = this.firestore
        .collection<Message>(collectionPath,
          ref => {
            let query = ref.where('createdBy', '==', currentUserId);
            query = query.where('upVotesCount', '>', 0);
            return query.orderBy('upVotesCount', 'desc');
          }
        ).valueChanges();

      const allAnswersToMyQuestions = this.firestore
        .collection<Message>(collectionPath,
          ref => {
            let query = ref.where('relatedMessageCreator', '==', currentUserId);
            query = query.where('upVotesCount', '>', 0);
            return query.orderBy('upVotesCount', 'desc');
          }
        ).valueChanges();

      return combineLatest([allMyQuestions, allAnswersToMyQuestions]).pipe(
        map(([myQuestions, answers]) => [...myQuestions, ...answers].sort((msg1, msg2) => msg1['upVotesCount'] - msg2['upVotesCount']))
      );
    } else {
      return this.firestore
        .collection<Message>(collectionPath,
          ref => {
            let query = ref as any;
            if (status === ApproveStatus.true) {
              query = query.where('approved', '==', true);
            } else if (status === ApproveStatus.false) {
              query = query.where('approved', '==', false);
            } else if (status === ApproveStatus.null) {
              query = query.where('approved', 'not-in', [true, false]);
            }
            query = query.where('upVotesCount', '>', 0);
            return query.orderBy('upVotesCount', 'desc');
          }
        ).valueChanges();
    }
  }

  public loadMoreMessagesForRoom(eventId: number, roomId: string, timestamp: number) {
    return this.firestore
      .collection<Message>(`${this.COLLECTION_EVENTS}/${eventId}/${this.COLLECTION_CHAT_ROOMS}/${roomId}/${this.COLLECTION_MESSAGES}`,
        ref => ref
          .where('createTime', '<', timestamp)
          .orderBy('createTime', 'desc')
          .limit(this.MESSAGE_LOAD)
      ).valueChanges()
      .pipe(
        map((messages) => messages.sort((msg1, msg2) => msg1.createTime - msg2.createTime)),
      );
  }

  public startChat(eventId: number, newRoom: Room) {
    return this.firestore
      .collection<Room>(`${this.COLLECTION_EVENTS}/${eventId}/${this.COLLECTION_CHAT_ROOMS}`)
      .doc(newRoom.uid).set({
        ...newRoom,
        subscribers: firebase.firestore.FieldValue.arrayUnion(...newRoom.subscribers) as any,
        members: firebase.firestore.FieldValue.arrayUnion(...newRoom.members) as any
      }, { merge: true });
  }

  public subscribeToChat(eventId: number, roomId: string, userId: string) {
    return this.firestore
      .collection(`${this.COLLECTION_EVENTS}/${eventId}/${this.COLLECTION_CHAT_ROOMS}`)
      .doc(roomId).set({
        subscribers: firebase.firestore.FieldValue.arrayUnion(userId) as any,
        members: firebase.firestore.FieldValue.arrayUnion(userId) as any
      }, { merge: true });
  }

  public leaveChat(eventId: number, roomId: string, userId: string) {
    return this.firestore
      .collection(`${this.COLLECTION_EVENTS}/${eventId}/${this.COLLECTION_CHAT_ROOMS}`)
      .doc(roomId).set({
        subscribers: firebase.firestore.FieldValue.arrayRemove(userId) as any,
        members: firebase.firestore.FieldValue.arrayRemove(userId) as any
      }, { merge: true });
  }

  public initUser(eventId: number): Observable<ChatUser> {
    return this.currentUser
      .pipe(
        this.signInUserToFirebase,
        this.fetchChatUser,
        map(({ currentChatUser, currentUser }) => {
          // If there is no current user in the application
          if (currentUser === null) {
            return null as ChatUser;
          } else if (!currentChatUser) {
            // If there is current user in the application, but no user in firebase
            const user: ChatUser = {
              userId: currentUser.id,
              name: currentUser.first_name + ' ' + currentUser.last_name,
              edited: new Date().getTime(),
              avatarUrl: currentUser.image_url,
              messageLog: {},
              visible: false,
              activeOnConferences: [eventId],
            };

            this.firestore.collection(`${this.COLLECTION_USERS}`)
              .doc(currentUser.id.toString())
              .set(user, { merge: true })
              .catch((error) => this.modalService.error({ errors: error }));

            return user;
          } else {
            // If there is current user in the application, and user in firebase
            if (currentChatUser?.activeOnConferences && Array.isArray(currentChatUser.activeOnConferences)) {
              if (!currentChatUser.activeOnConferences.includes(eventId)) {
                currentChatUser.activeOnConferences.push(eventId);
              }
            } else {
              currentChatUser.activeOnConferences = [eventId];
            }
            this.firestore
              .collection(`${this.COLLECTION_USERS}`)
              .doc(currentUser.id.toString())
              .update({
                'name': currentUser.first_name + ' ' + currentUser.last_name,
                'edited': new Date().getTime(),
                'avatarUrl': currentUser.image_url,
                'activeOnConferences': currentChatUser.activeOnConferences,
              })
              .catch((error) => this.modalService.error({ errors: error }));

            return {
              ...currentChatUser,
              name: currentUser.first_name + ' ' + currentUser.last_name,
              edited: new Date().getTime(),
              avatarUrl: currentUser.image_url,
              userId: currentUser.id,
            };
          }
        })
      );
  }

  public setUserVisibility(userId: number, visible: boolean) {
    if (userId) {
      return this.firestore
        .collection(`${this.COLLECTION_USERS}`)
        .doc(userId.toString())
        .update({
          'visible': visible
        })
        .catch((error) => this.modalService.error({ errors: error }));
    }
    return of().toPromise();
  }

  public updateMessageLog(currentUserId: number, selectedRoomId: string, index: number) {
    // Update current users last message for this group
    return this.firestore
      .collection(`${this.COLLECTION_USERS}`)
      .doc(currentUserId.toString())
      .update({
        ['messageLog.' + selectedRoomId]: index
      })
      .catch((error) => this.modalService.error({ errors: error }));
  }

  private fetchChatUser = (source: Observable<{ currentUser: User }>) => source.pipe(
    switchMap(({ currentUser }) => currentUser ?
      this.firestore.collection<ChatUser>(this.COLLECTION_USERS)
        .doc(currentUser.id.toString())
        .valueChanges()
        .pipe(
          take(1),
          map((currentChatUser: ChatUser) => ({ currentChatUser, currentUser }))
        )
      : of({ currentChatUser: null as ChatUser, currentUser: null })
    ),
  );

  private signInUserToFirebase = (source: Observable<User>) => source.pipe(
    switchMap((currentUser) =>
      from(this.firebaseAuth.signInAnonymously()).pipe(map((res) => ({
        fireBaseUser: res.user,
        currentUser
      })))
    ));
}

