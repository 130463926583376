import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PresentationService } from '@navus/core/services/presentation.service';
import { Presentation } from '@navus/core/classes/presentation';
import { ModalService } from '@navus/ui/modal/modal.service';
import { NavusMeetingComponent } from '@navus/ui/meeting/meeting.component';
import { ConferenceService } from '@navus/core/services/conference.service';
import { WebsiteService } from '@navus/core/services/website.service';
import { Website } from '@navus/core/classes/website';

@Component({
  selector: 'nv-my-presentation-page',
  templateUrl: './my-presentation-page.component.html'
})
export class MyPresentationPageComponent implements OnInit, OnDestroy {
  @ViewChild('meeting') meeting: NavusMeetingComponent;
  presentationId: number;
  presentation: Presentation;
  website: Website;
  messages: any[] = [];
  interval;
  selectedTab: string = "demo";

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private presentationService: PresentationService,
    private websiteService: WebsiteService,
    private conferenceService: ConferenceService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      (params) => {
        this.presentationId = params.presentationId;
        this.getPresentation();
      }
    );

    this.websiteService.currentWebsite.subscribe(
      (website) => {
        this.website = website;
        this.getMessages();
      }
    );

    this.interval = setInterval(() => {
      this.getMessages();
    }, 30 * 1000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
  
  selectTab(env: string) {
    this.selectedTab = env;
  }

  getPresentation() {
    this.presentationService
      .getPresentation(this.presentationId, { include: 'location' })
      .subscribe(
        (response) => {
          this.presentation = response.data;
        },
        (error) => {
          this.modalService.error({errors: error});
        }
      );
  }

  getMessages() {
    if (!this.website) { return; }

    const params = {
      sort_by: 'created_at',
      sort_direction: 'desc'
    };

    this.conferenceService
      .getConferenceMessages(this.website.active_conference_id, params)
      .subscribe(
        (response) => {
          this.messages = response.data;
        },
        () => { }
      );
  }

  toggleShareScreen() {
    this.meeting.toggleShareScreen();
  }

}
