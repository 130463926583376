<div class="nv-w-switch">
    <div class="tsq-checkbox-radio check-type-slide">
        <label title="activate module">
            <input
                    nvInputDirective
                    type="checkbox"
                    (change)="onChangeStatus($event)"
                    [name]="name"
                    [checked]="value"
                    [disabled]="disabled">
            <span class="tsq-check-visual"></span>
        </label>
    </div><!-- /tsq-checkbox-radio -->
    <p *ngIf="label">{{ label }}</p>
</div><!-- /.nv-w-switch -->
