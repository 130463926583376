<div class="nv-line-tabs">
    <nav class="nv-tabs-nav">
        <ul>
            <li *ngFor="let tab of tabs" (click)="select(tab)">
                <a [class.active]="tab.active">{{ tab.title }}</a>
            </li>
        </ul>
    </nav>
</div><!-- /.nv-line-tabs -->
<ng-content></ng-content>
