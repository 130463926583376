import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import Inputmask from 'inputmask';

@Component({
  selector: 'nv-datepicker',
  templateUrl: './datepicker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NavusDatepickerComponent),
      multi: true
    }
  ]
})

export class NavusDatepickerComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @ViewChild('dateTimeInput') dateTimeInput;

  @Input() type: string = 'both';
  @Input() startView: 'month' | 'year' | 'multi-years' = 'month';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() icon: any;
  @Input() iconPl: any;
  @Input() label: string;
  @Input() formControlName: string;
  @Input() value: any = null;
  @Input() valueFormat: string;
  @Input() readOnly: boolean = false;
  @Input() max: any = null;
  @Input() min: any = null;

  @Output() change = new EventEmitter();

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  hasError: boolean;
  error: string;

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer
  ) {}

  ngOnInit(): void {
    if (this.required !== false) { this.required = true; }
    if (this.disabled !== false) { this.disabled = true; }
    if (this.readOnly !== false) { this.readOnly = true; }
  }

  ngAfterViewInit() {
    this.setInputMask();
  }

  writeValue(value: any): void {
    if (this.type === 'timer') {
      this.value = value ? new Date('2020-02-02 ' + value) : '';
    } else {
      this.value = value ? new Date(value) : '';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  emitChange(value) {
    if (value) {
      const date = new Date(value.getTime() - (value.getTimezoneOffset() * 60000));
      const dateString = date.toISOString().substr(0, 10);
      const timeString = date.toISOString().substr(11, 5) + ':00';

      switch (this.type) {
        case 'calendar':
          this.change.emit({ value: dateString });
          if (this.onChange) {
            this.onChange(dateString);
          }
          break;
        case 'timer':
          this.change.emit({ value: timeString });
          if (this.onChange) {
            this.onChange(timeString);
          }
          break;
        default:
          this.change.emit({ value: dateString + ' ' + timeString });
          if (this.onChange) {
            this.onChange(dateString + ' ' + timeString);
          }
      }
    } else {
      this.change.emit({ value: '' });
      if (this.onChange) {
        this.onChange('');
      }
    }
  }

  setInputMask() {
      let dateTimeFormat: string;
      let dateTimePlaceholder: string;
      switch (this.type) {
        case 'calendar':
          dateTimeFormat = 'dd.mm.yyyy';
          dateTimePlaceholder = 'dd.mm.yyyy';
          break;
        case 'timer':
          this.dateTimeInput.nativeElement.setAttribute('readonly', 'true');
          dateTimeFormat = 'HH:MM';
          dateTimePlaceholder = 'hh:mm';
          break;
        default:
          dateTimeFormat = 'dd.mm.yyyy HH:MM';
          dateTimePlaceholder = 'dd.mm.yyyy hh:mm';
      }
      Inputmask('datetime', {
        inputFormat: dateTimeFormat,
        placeholder: dateTimePlaceholder,
        clearIncomplete: true
      }).mask(this.dateTimeInput.nativeElement);
  }

  checkInputMask(event) {
   if (!this.value) {
     if (!event.target.value.includes('m') || !event.target.value.includes('h') ||
         !event.target.value.includes('d') || !event.target.value.includes('y')) {
       if (this.onChange) {
         this.onChange(event.target.value);
      }
     }
   }
  }
}
