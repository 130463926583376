<section class="nv-section guttered nv-front-registration-form">
    <div class="nv-port">
        <div class="nv-row spaced-top v-middle flow-center">
            <div class="nv-column desk-6 tab-8 phab-12">
                <div class="nv-column-content">
                    <div class="nv-banner-block"><img alt="" src="//service.e-materials.com/storage/resources/navus/spo/sponsors-banner-ad.jpg"></div>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row spaced v-middle flow-center">
            <div class="nv-column desk-6 tab-8 phab-12">
                <div class="nv-column-content">
                    <div class="nv-newly-registered">
                        <h3>John, Welcome to event:</h3>
                        <div class="nv-register-form-brand">
                            <figure>
                                <img src="//service.e-materials.com/storage/resources/navus/vir/virtual-app-icon.svg" alt="portal name">
                            </figure>
                            <h2>Navus Virtual Event 2020</h2>
                            <h4>May 26, 2020 at 10 AM</h4>
                            <h5><a href>Add to calendar</a></h5>
                        </div><!-- /.nv-register-form-brand -->
                        <div class="nv-first-enter">
                            <a href class="nv-btn large accent2 radius-high extruded">Enter</a>
                        </div><!-- /.nv-first-enter -->
                        <p>Enjoy the event!</p>
                        <p>
                            Find more information on the Virtual Event 2020 <a href>homepage</a>.<br>
                            Please contact <a href>mail@mail.com</a> if you have any questions.
                        </p>
                    </div><!-- /.nv-newly-registered -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->

        <div class="nv-divider desk-giant"></div><!-- /.nv-divider desk-giant -->


        <div class="nv-row spaced v-middle flow-center">
            <div class="nv-column desk-6 tab-8 phab-12">
                <div class="nv-column-content">
                    <div class="nv-onboarding-dual-pane">
                        <div class="nv-login-form">
                            <div class="nv-register-form-brand">
                                <figure>
                                    <img src="//service.e-materials.com/storage/resources/navus/vir/virtual-app-icon.svg" alt="portal name">
                                </figure>
                                <h2>Navus Virtual Event 2020</h2>
                            </div><!-- /.nv-register-form-brand -->
                            <p class="nv-logged-in-as">
                                <span>You're already logged in as</span>
                                <strong style="background-image: url('https://loremflickr.com/g/96/96/boy');"></strong>
                                <a href>john smith</a>.
                            </p><!-- /.nv-logged-in-as -->
                            <div class="nv-access-now">
                                <a href class="nv-btn large accent2 fw radius-high">Access now</a>
                            </div><!-- /.nv-access-now -->
                            <p class="nv-not-you">
                                Not you?<br>
                                <a href><strong>Login using another account?</strong></a>
                            </p>
                            <div class="nv-foo-signature"><span></span></div>
                        </div><!-- /.nv-login-form -->
                    </div><!-- /.nv-onboarding-dual-pane -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</section><!-- /nv-section -->

