import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class DelegateService {

  constructor(
    private apiService: ApiService
  ) {
  }

  getDelegates(params: any = {}) {
    return this.apiService.get(`delegates`, params);
  }

  getDelegate(delegateId: number, params: any = {}) {
    return this.apiService.get(`delegates/${delegateId}`, params);
  }

  updateDelegate(delegateId: number, params: any = {}) {
    return this.apiService.put(`delegates/${delegateId}`, params);
  }

  createDelegate(params: any = {}) {
    return this.apiService.post(`delegates`, params);
  }

  deleteDelegate(delegateId: number) {
    return this.apiService.delete(`delegates/${delegateId}`);
  }

  getConferenceChatDelegates(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/chat_delegates`, params);
  }

  getConferenceDelegates(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/delegates`, params);
  }

  getConferenceDelegate(conferenceId: number, delegateId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/delegates/${delegateId}`, params);
  }

  updateConferenceDelegate(conferenceId: number, delegateId: number, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/delegates/${delegateId}`, params);
  }

  createConferenceDelegate(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/delegates`, params);
  }

  deleteConferenceDelegate(conferenceId: number, delegateId: number) {
    return this.apiService.delete(`conferences/${conferenceId}/delegates/${delegateId}`);
  }

  getConferenceDelegateForUser(conferenceId: number, userId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/delegates/users/${userId}`, params);
  }

  exportConferenceDelegates(conferenceId: number) {
    const params: any = { mime: 'text/csv', extension: 'csv' };
    return this.apiService.download(`conferences/${conferenceId}/delegates/data`, 'delegates', params);
  }

  scout(params: any = {}) {
    return this.apiService.post(`livestream-tracking`, params);
  }

  sendRegistrationEmail(conferenceId: number, delegateId: number) {
    return this.apiService.post(`conferences/${conferenceId}/delegates/${delegateId}/sendRegistrationEmail`);
  }

  getConferenceDelegatesAttendanceLive(conferenceId: number, delegateId: number) {
    return this.apiService.get(`conferences/${conferenceId}/delegates/${delegateId}/attendance-live`);
  }

  getConferenceDelegatesAttendanceOnDemand(conferenceId: number, delegateId: number) {
    return this.apiService.get(`conferences/${conferenceId}/delegates/${delegateId}/attendance-on-demand`);
  }

  getConferenceDelegatesAttendanceOnSite(conferenceId: number, delegateId: number) {
    return this.apiService.get(`conferences/${conferenceId}/delegates/${delegateId}/attendance-onsite`);
  }

  addConferenceDelegatesAttendanceOnSite(conferenceId: number, delegateId: number, blockIds: number[]) {
    return this.apiService.post(`conferences/${conferenceId}/delegates/${delegateId}/attendance-onsite`, { block_ids: blockIds });
  }

  deleteConferenceDelegatesAttendanceOnSite(conferenceId: number, delegateId: number, blockId: number) {
    return this.apiService.delete(`conferences/${conferenceId}/delegates/${delegateId}/attendance-onsite/${blockId}`);
  }

  addConferenceDelegatesAttendanceLive(conferenceId: number, delegateId: number, presentationId: number, time: number) {
    return this.apiService.post(`conferences/${conferenceId}/delegates/${delegateId}/live-attendance/${presentationId}`, { time });
  }

  deleteConferenceDelegatesAttendanceLive(conferenceId: number, delegateId: number, presentationId: number) {
    return this.apiService.delete(`conferences/${conferenceId}/delegates/${delegateId}/live-attendance/${presentationId}`);
  }

  addConferenceDelegatesAttendanceOnDemand(conferenceId: number, delegateId: number, materialId: number, time: number) {
    return this.apiService.post(`conferences/${conferenceId}/delegates/${delegateId}/on-demand-attendance/${materialId}`, { time });
  }

  deleteConferenceDelegatesAttendanceOnDemand(conferenceId: number, delegateId: number, materialId: number) {
    return this.apiService.delete(`conferences/${conferenceId}/delegates/${delegateId}/on-demand-attendance/${materialId}`);
  }

}
