import { NgModule } from '@angular/core';
import { NavusCoreModule } from '@navus/core/navus-core.module';
import { NavusUiModule } from '@navus/ui/navus-ui.module';
import { SharedModule } from '../../../shared';
import { PresentationDetailsPageComponent } from './pages/presentation-details/presentation-details-page.component';
import { PresentationListPageComponent } from './pages/presentation-list/presentation-list-page.component';
import { ProgramLandingPageComponent } from './pages/program-landing/program-landing-page.component';
import { BlockListPageComponent } from './pages/block-list/block-list-page.component';
import { BlockDetailsPageComponent } from './pages/block-details/block-details-page.component';
import { BlockCategoryListPageComponent } from './pages/block-category-list/block-category-list-page.component';
import { BlockCategoryDetailsPageComponent } from './pages/block-category-details/block-category-details-page.component';
import { WebsiteModule } from '../../../website/website.module';
import { FileUploadModule } from 'ng2-file-upload';
import { LocationListPageComponent } from './pages/location-list/location-list-page.component';
import { LocationDetailsPageComponent } from './pages/location-details/location-details-page.component';

@NgModule({
  imports: [
    NavusCoreModule.forRoot(),
    NavusUiModule,
    SharedModule,
    WebsiteModule,
    FileUploadModule
  ],
  declarations: [
    ProgramLandingPageComponent,
    PresentationDetailsPageComponent,
    PresentationListPageComponent,
    BlockListPageComponent,
    BlockDetailsPageComponent,
    BlockCategoryListPageComponent,
    BlockCategoryDetailsPageComponent,
    LocationListPageComponent,
    LocationDetailsPageComponent
  ],
  entryComponents: [],
  providers: []
})
export class ProgramModule {
}
