import { NgModule } from '@angular/core';
import { NavusCoreModule } from '../../../navus-core/navus-core.module';
import { NavusUiModule } from '../../../navus-ui/navus-ui.module';
import { SharedModule } from '../../../shared';
import { MeetingRoomDetailsPageComponent } from './pages/meeting-room-details/meeting-room-details-page.component';
import { MeetingRoomListPageComponent } from './pages/meeting-room-list/meeting-room-list-page.component';

@NgModule({
  imports: [
    NavusCoreModule.forRoot(),
    NavusUiModule,
    SharedModule
  ],
  declarations: [
    MeetingRoomListPageComponent,
    MeetingRoomDetailsPageComponent,
  ],
  entryComponents: [],
  providers: []
})
export class MeetingRoomsModule {
}
