import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ToastConfig } from './classes/toast-config';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
      private toastrService: ToastrService
  ) { }

  success(message: string, config: ToastConfig = {}) {
    this.toastrService.success(message, '', {
      toastClass: 'nv-side-toast' + ' ' + (config.positionClass || 'bottom-right'),
      timeOut: config.timeOut || 3000,
      disableTimeOut: config.disableTimeOut || false,
      positionClass: config.positionClass || 'bottom-right',
      closeButton: config.closeButton || false,
      messageClass: 'nv-msg-block success'
    });
  }

  warning(message, config: ToastConfig = {}) {
    this.toastrService.warning(message, '', {
      toastClass: 'nv-side-toast' + ' ' + (config.positionClass || 'bottom-right'),
      timeOut: config.timeOut || 3000,
      disableTimeOut: config.disableTimeOut,
      positionClass: config.positionClass || 'bottom-right',
      closeButton: config.closeButton,
      messageClass: 'nv-msg-block warning'
    });
  }

  error(message, config: ToastConfig = {}) {
    this.toastrService.error(message, '', {
      toastClass: 'nv-side-toast' + ' ' + (config.positionClass || 'bottom-right'),
      timeOut: config.timeOut || 3000,
      disableTimeOut: config.disableTimeOut,
      positionClass: config.positionClass || 'bottom-right',
      closeButton: config.closeButton,
      messageClass: 'nv-msg-block error'
    });
  }

  info(message, config: ToastConfig = {}) {
    this.toastrService.info(message, '', {
      toastClass: 'nv-side-toast' + ' ' + (config.positionClass || 'bottom-right'),
      timeOut: config.timeOut || 3000,
      disableTimeOut: config.disableTimeOut,
      positionClass: config.positionClass || 'bottom-right',
      closeButton: config.closeButton,
      messageClass: ' nv-msg-block info'
    });
  }

  newBadge(config: any = {}) {
    const message = `
    <figure><img src="${config.image_url}" alt="${config.title}"></figure>
    <header>
        <h3>${config.title}</h3>
    </header>
    ${config.message}
    <p class="medium"><a href="/profile/badges" target="_blank" title="View badges">View badges</a></p>
    `;
    this.toastrService.success(message, '', {
      enableHtml: true,
      toastClass: 'nv-side-toast badge top-right',
      timeOut: 3000,
      positionClass: 'top-right',
      messageClass: ' nv-toast-notification badge'
    });
  }
}
