import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Material } from '@navus/core/classes/material';

@Component({
  selector: 'nv-custom-chart-legend',
  templateUrl: './custom-chart-legend.component.html',
})
export class CustomChartLegendComponent implements OnInit {
  @Input() legendItems: { id?: number, name: string, value: string, color: string }[];
  @Input() activeEntries: any[] = [];
  @Input() chartData: { name: string, value: number }[];
  @Input() title: string;
  @Input() deleteItem = true;

  @Input() availableSessions: { id: number, name: string }[];
  filteredAvailableSessions: { id: number, name: string }[];
  @Input() selectedSessions: number[];
  @Input() sessionsManipulation = false;

  @Input() availablePresentations: { id: number, name: string, time?: number, duration?: number }[];
  @Input() selectedPresentation: number;
  @Input() presentationsManipulation = false;

  @Input() availableMaterials: Material[];
  @Input() selectedMaterial: number;
  @Input() materialsManipulation = false;

  @Output() addItem = new EventEmitter<number[]>();
  @Output() addTime = new EventEmitter<{ id: number, time: number }>();
  @Output() removeItem = new EventEmitter<number>();
  @Output() selectItem = new EventEmitter<string>();
  @Output() activateLabel = new EventEmitter<{ name: string }>();
  @Output() deactivateLabel = new EventEmitter<{ name: string }>();
  @Output() sessionSelected = new EventEmitter<number>();
  @Output() presentationSelected = new EventEmitter<number>();

  timeSpent: number;
  maxDuration: number;

  durationForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.filteredAvailableSessions = this.availableSessions;
  }

  searchAvailableSessions(searchTerm: string) {
    if (searchTerm.length > 0) {
      this.filteredAvailableSessions = [];
      for (const country of this.availableSessions) {
        if (country.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          this.filteredAvailableSessions.push(country);
        }
      }
    } else {
      this.filteredAvailableSessions = this.availableSessions;
    }
  }

  isLabelActive(item: { name: string }): void {
    return this.activeEntries.find(s => s.name === item.name);
  }

  legendLabelActivate(item: any): void {
    this.activeEntries = this.chartData.filter(sd => sd.name === item.name);
    this.activateLabel.emit(item);
  }

  legendLabelDeactivate(item: any): void {
    this.activeEntries = [];
    this.deactivateLabel.emit(item);
  }

  addRow() {
    if (!this.presentationsManipulation) {
      this.addItem.emit(this.selectedSessions);
      this.selectedSessions = [];
    } else if (this.presentationsManipulation && !this.materialsManipulation) {
      const time = this.durationForm.value?.time;
      this.addTime.emit({ id: this.selectedPresentation, time });
      this.selectedSessions = [];
      this.availablePresentations = [];
      this.selectedPresentation = null;
    } else if (this.presentationsManipulation && this.materialsManipulation) {
      const time = this.durationForm.value?.time;
      this.addTime.emit({ id: this.selectedMaterial, time });
      this.selectedSessions = [];
      this.availablePresentations = [];
      this.availableMaterials = [];
      this.selectedPresentation = null;
      this.selectedMaterial = null;
    }
  }

  arrayToString = function (string) {
    return string.join(', ');
  };

  removeRow(rowId: number) {
    this.removeItem.emit(rowId);
  }

  selectRow(rowName: string) {
    this.selectItem.emit(rowName);
  }

  onSessionSelect(sessionId: number) {
    this.sessionSelected.emit(sessionId);
    this.timeSpent = null;
    setTimeout(() => {
      if (this.selectedPresentation) {
        this.onPresentationSelect(this.selectedPresentation.toString());
      }
    });
  }

  onPresentationSelect(presentationId: string) {
    if (!this.materialsManipulation) {
      const presentation = this.availablePresentations.find(p => p.id === parseInt(presentationId));

      this.maxDuration = presentation?.duration;
      this.timeSpent = presentation?.time;

      this.initDurationForm();
    } else {
      this.presentationSelected.emit(parseInt(presentationId));
      setTimeout(() => {
        if (this.selectedMaterial) {
          this.onMaterialSelect(this.selectedMaterial.toString());
        }
      });
      this.timeSpent = null;
    }
  }

  onMaterialSelect(materialId: string) {
    this.maxDuration = this.availablePresentations.find(p => p.id === parseInt(this.selectedPresentation.toString()))?.duration;
    this.timeSpent = this.availablePresentations.find(p => p.id === parseInt(this.selectedPresentation.toString()))?.time;

    this.initDurationForm();
  }

  isButtonDisabled() {
    if (this.materialsManipulation) {
      return !this.selectedMaterial || !this.timeSpent || !this.durationForm?.valid;
    } else if (!this.materialsManipulation && this.presentationsManipulation) {
      return !this.selectedPresentation || !this.timeSpent || !this.durationForm?.valid;
    } else if (!this.presentationsManipulation && this.sessionsManipulation) {
      return this.selectedSessions?.length === 0;
    }
  }

  private initDurationForm() {
    this.durationForm = this.formBuilder.group({
      time: [this.timeSpent, [Validators.required, Validators.max(this.maxDuration)]],
    });
  }
}

