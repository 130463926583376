import { Component } from '@angular/core';

@Component({
    selector: 'app-profile-pages-page',
    templateUrl: './profile-pages.component.html'
})
export class ProfilePagesComponent {

    constructor() { }

}
