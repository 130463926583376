import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import { environment } from '../../environments/environment';
import {fromEvent, Observable, of, Subject} from 'rxjs';
import {catchError, delay, filter, flatMap, map, retryWhen, shareReplay, startWith, takeUntil, tap, timeout} from 'rxjs/operators';
import {ApiService} from '../shared/services';
import {Choice, Statistic, Voting} from '../shared/models/voting';
import {ActivatedRoute, Router} from '@angular/router';
import {Presentation} from '../shared/models';
import Echo from 'laravel-echo';
import {animate, style, transition, trigger} from '@angular/animations';
import Pusher from 'pusher-js';

@Component({
    templateUrl: './public-voting.component.html',
    styleUrls: ['./public-voting.component.scss'],
    animations: [
        trigger(
            'placeholderAnimation', [
                transition(':enter', [
                    style({opacity: 0}),
                    animate('1000ms', style({opacity: 1}))
                ]),
                transition(':leave', [
                    style({opacity: 1}),
                    animate('1000ms', style({opacity: 0}))
                ])
            ]
        ),
        trigger(
            'contentAnimation', [
                transition(':enter', [
                    style({opacity: 0}),
                    animate('1000ms 1000ms', style({opacity: 1}))
                ]),
                transition(':leave', [
                    style({opacity: 1}),
                    animate('100ms', style({opacity: 0}))
                ])
            ]
        ),
        trigger(
            'errorAnimation', [
                transition(':enter', [
                    style({transform: 'translateY(-100%)', opacity: 0}),
                    animate('300ms', style({opacity: 1, transform: 'translateY(0)'}))
                ]),
                transition(':leave', [
                    style({opacity: 1}),
                    animate('300ms', style({opacity: 0}))
                ])
            ]
        ),
    ]
})
export class PublicVotingComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<boolean> = new Subject();
    public voting$: Observable<Voting>;
    public presentation$: Observable<Presentation>;
    public status: 'finished' | 'active' | 'waiting';
    public loading: boolean = false;
    public errorMsg: string = '';
    private statistics: Statistic[] = [];
    private LaravelEcho: Echo;

    private lastVotingId: string;

    constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router, private zone: NgZone) {
        // LaravelEcho config

        // window['io'] = io;
        // window['Echo'] = new Echo({
        //     broadcaster: 'socket.io',
        //     host: `${environment.fasung_url}`,
        //     transports: ['websocket'],
        //     auth: {headers: {'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')}}
        // });
        // this.LaravelEcho = window['Echo'];

        window['Pusher'] = Pusher;
        window['Echo'] = new Echo({
            broadcaster: 'pusher',
            key: `${environment.mix_pusher_app_key}`,
            wsHost: `${environment.mix_pusher_host}`,
            wsPort: `${environment.mix_pusher_port}`,
            wssPort: `${environment.mix_pusher_port}`,
            forceTLS: `${environment.mix_pusher_port === 443}`,
            encrypted: true,
            disableStats: true,
            authEndpoint: `${environment.api_url}` + 'broadcasting/auth',
            auth: {headers: {'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')}},
            enabledTransports: ['ws', 'wss']
        });
          
        this.LaravelEcho = window['Echo'];

    }

    ngOnInit() {
        // TODO: Decide logic based on entity type
        window.onbeforeunload = () => localStorage.removeItem(`pv:${this.route.snapshot.params.uniqueId}`);
        localStorage.setItem(`pv:${this.route.snapshot.params.uniqueId}`, location.pathname);
        this.loading = true;

        this.voting$ = this.route.params.pipe(
            tap(() => {
                this.loading = true;
                this.errorMsg = '';
            }),
            flatMap(params => this.apiService.get(
                'conferences/' + params.conferenceId + '/presentations/' + params.entityId + '/votings/' + params.votingId,
                {with_statistics_for_unfinished_voting: 1}
                ).pipe(
                timeout(8 * 1000),
                retryWhen(errors => {
                    let retries: number = 3;
                    return errors.pipe(delay(2000), tap((err) => {
                        if (retries-- > 0) {
                            this.errorMsg = 'Low connection';
                            return err;
                        }
                        this.errorMsg = 'No connection';
                        throw err;
                    }));
                }),
                catchError((err) => of(null)),
                filter(res => res && res.data),
                map(res => res.data),
                tap(voting => {
                    this.errorMsg = '';
                    this.status = voting.status;
                    // In case of navigation to another voting
                    this.LaravelEcho.leave('private-admin.voting.' + this.lastVotingId);

                    if (voting.status !== 'finished') {

                        if (voting.status !== 'active') {
                            this.LaravelEcho.private('admin.voting.' + voting.id).listen('.voting.started', () => {
                                console.log(1233);
                                this.zone.run(() => this.status = 'active');
                            });
                        }

                        this.LaravelEcho.private('admin.voting.' + voting.id).listen('.voting.new_answer', (data) => {
                            console.log(123);
                            this.zone.run(() => this.statistics = data.statistics || []);
                        });

                        this.LaravelEcho.private('admin.voting.' + voting.id).listen('.voting.finished', () => {
                            console.log(12);
                            this.zone.run(() => this.status = 'finished');
                            this.LaravelEcho.leave('private-admin.voting.' + voting.id);
                        });
                    }

                    this.lastVotingId = voting.id;
                })
                )
            ),
            tap(() => this.loading = false),
            takeUntil(this.unsubscribe$),
            shareReplay()
        );

        this.presentation$ = this.route.params.pipe(
            flatMap(params => this.apiService.get('conferences/' + params.conferenceId + '/presentations/' + params.entityId, {})
                .pipe(map(res => res.data))
            ),
            takeUntil(this.unsubscribe$),
            shareReplay(),
            startWith(this.route.snapshot.params)
        );

        fromEvent(window, 'storage').pipe(
            // Only localStorage changes that affect specific popup instance
            filter(ev => /^pv:.*$/.test(ev['key']) && ev['key'].split('pv:')[1] === this.route.snapshot.params.uniqueId),
            takeUntil(this.unsubscribe$)
        ).subscribe((res) => this.router.navigateByUrl(res['newValue']));
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.LaravelEcho.disconnect();
    }

    getAnswers(voting: Voting): any[] {
        if (!(voting && voting.options && voting.options.choices && voting.options.choices.length)) {
            return [];
        }
        const choices: Choice[] = voting.options.choices;
        const statistics: Statistic[] = (this.statistics.length ? this.statistics : voting.statistics) || [];
        return choices.map(c => {
            const stat: any = statistics.find(s => s.choice === c.choice);
            if (stat) {
                return {
                    votings: stat.votings,
                    percentage: stat.percentage,
                    ...c
                };
            } else {
                return c;
            }
        });
    }
}
