import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadlinkService } from '../../../../../navus-core/services/leadlink.service';
import { LeadlinkCampaign } from '../../../../../navus-core/classes/leadlink-campaign';
import { LoadingService } from '../../../../../navus-ui/loading/loading.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalService } from '../../../../../navus-ui/modal/modal.service';

@Component({
  selector: 'main [nv-campaign-list-page]',
  templateUrl: './campaign-list-page.component.html'
})
export class CampaignListPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  campaigns: LeadlinkCampaign[] = [];

  pagination: { page?: number, perPage: number, total?: number } = {
    perPage: 30
  };
  sort: { field: string, direction: string } = {
    field: 'starts_at',
    direction: 'desc'
  };
  selectedIds: number[] = [];

  campaignsLoading: boolean = false;
  optionsShown: number = null;

  formFilter: FormGroup;
  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private leadlinkService: LeadlinkService,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      search_term: ['']
    });

    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getCampaigns();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getCampaigns(append: boolean = false) {
    if (append && this.pagination.total && this.campaigns.length >= this.pagination.total) { return; }

    this.pagination.page = append ? this.pagination.page + 1 : 1;

    const params: any = {
      page: this.pagination.page,
      per_page: this.pagination.perPage,
      sort_by: this.sort.field,
      sort_direction: this.sort.direction,
    };
    if (this.formFilter.value.search_term !== '') {
      params.name = this.formFilter.value.search_term;
    }

    this.campaignsLoading = true;
    this.leadlinkService.getConferenceCampaigns(this.conferenceId, params)
      .subscribe(
        (response: { data: LeadlinkCampaign[], meta: any }) => {
          if (append) {
            this.campaigns = this.campaigns.concat(response.data);
          } else {
            this.campaigns = response.data;
            // this.pagination.total = response.meta.pagination.total;
          }
          this.campaignsLoading = false;
        },
        (error) => {
          this.campaignsLoading = false;
        }
      );
  }

  edit(campaign: LeadlinkCampaign) {
    this.router.navigate(['..', 'campaign', campaign.id], { relativeTo: this.route });
  }

  close() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  add() {
    this.router.navigate(['..', 'campaign'], { relativeTo: this.route });
  }

  delete(campaign: LeadlinkCampaign) {
    this.modalService.defaultModal({
      title: 'Event Deletion',
      body: 'Are you sure you want to delete this event?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.leadlinkService.deleteConferenceCampaign(this.conferenceId, campaign.id)
              .subscribe(
                () => {
                  this.getCampaigns();
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

  sortCampaigns(field: string) {
    if (field === this.sort.field) {
      this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sort.field = field;
      this.sort.direction = 'asc';
    }
    this.getCampaigns();
  }

  toggleSelected(campaign: LeadlinkCampaign) {
    const index = this.selectedIds.indexOf(campaign.id);
    if (index !== -1) {
      this.selectedIds.splice(index, 1);
    } else {
      this.selectedIds.push(campaign.id);
    }
  }

  selectAll() {
    this.selectedIds = [];
    for (const campaign of this.campaigns) {
      this.selectedIds.push(campaign.id);
    }
  }

  unselectAll() {
    this.selectedIds = [];
  }

  openOptions(campaign: LeadlinkCampaign) {
    event.stopPropagation();
    this.optionsShown = campaign.id;
  }

  closeOptions() {
    event.stopPropagation();
    this.optionsShown = null;
  }

}
