import { Component, OnDestroy, OnInit } from '@angular/core';
import { Presentation } from '../../../navus-core/classes/presentation';
import { PresentationService } from '../../../navus-core/services/presentation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from '../../../navus-core/services/search.service';
import { Subscription } from 'rxjs';
import { PaginationConfig } from '../../../navus-ui/pagination/classes/pagination-config';
import { Conference } from '../../../navus-core/classes/conference';
import { WebsiteService } from '../../../navus-core/services/website.service';
import { LoadingService } from '../../../navus-ui/loading/loading.service';
import { BannerService } from '../../../navus-core/services/banner.service';
import { TrackingService } from '@navus/core/services/tracking.service';
import { Block } from '@navus/core/classes/block';
import { BlockService } from '@navus/core/services/block.service';
import { BookmarkService } from '@navus/core/services/bookmark.service';
import { Website } from '@navus/core/classes/website';
import { Bookmark } from '@navus/core/classes/bookmark';

@Component({
  selector: 'nv-search-page',
  templateUrl: './search-page.component.html'
})
export class SearchPageComponent implements OnInit, OnDestroy {
  presentations: Presentation[] = [];
  presentationsPagination: PaginationConfig = {
    current_page: 1,
    per_page: 50
  };
  presentationsMeta: {
    found_by_block_category_title: number[],
    found_by_block_title: number[],
    found_by_material_content: number[],
    found_by_presentation_description: number[],
    found_by_presentation_title: number[],
    found_by_speaker_name: number[],
  };
  presentationTypes = [
    { name: 'Oral', id: 'Oral' },
    { name: 'Poster', id: 'Poster' }
  ];
  materialTypes = [
    { name: 'PDF', id: 'main' },
    { name: 'Webcast', id: 'webcast' },
    { name: 'Audio', id: 'audio' },
    { name: 'Supporting', id: 'supporting' },
    { name: 'Summary', id: 'summary' }
  ];
  conferences: Conference[] = [];
  blocks: Block[] = [];
  blockSearch: string = '';
  blockPage: number = 1;
  blockTotal: number = null;
  mainType: string = '';
  filter: {
    search_term?: string,
    type?: string[],
    material?: string[],
    conferences?: string[],
    blocks?: string[],
    bookmarked?: boolean
  } = {
    search_term: '',
    type: [],
    material: [],
    conferences: [],
    blocks: [],
    bookmarked: false
  };
  sort: string = 'starts_at-asc';
  sortOptions: any[] = [
    { name: 'Time', id: 'starts_at-asc' },
    { name: 'Latest Materials', id: 'published_at-desc' },
    { name: 'Most liked', id: 'vote_count-desc' },
    { name: 'Title [A-Z]', id: 'title-asc' },
    { name: 'Title [Z-A]', id: 'title-desc' },
    { name: 'Session', id: 'session-asc' },
  ];
  showSuggestions: boolean = false;
  searchSuggestions: string[] = [];
  banner: any;
  bookmarks: Bookmark[] = [];
  bookmarkedPresentationIds: number[] = [];
  website: Website;

  inteval: any;

  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private presentationService: PresentationService,
    private searchService: SearchService,
    private websiteService: WebsiteService,
    private blockService: BlockService,
    private bannerService: BannerService,
    private loadingService: LoadingService,
    private trackingService: TrackingService,
    private bookmarkService: BookmarkService,
  ) { }

  ngOnInit() {
    this.getConferences();
    this.inteval = setInterval(() => this.getBanner(), 20000);

    const routeSubscription = this.route.data
      .subscribe((params) => {
        if (params.mainType) {
          this.mainType = params.mainType;
        }
      });
    this.subscriptions.push(routeSubscription);

    const bookmarkSubscription = this.bookmarkService.bookmarks
      .subscribe(
        (bookmarks: Bookmark[]) => {
          this.bookmarks = bookmarks;
          this.bookmarkedPresentationIds = bookmarks.map(b => b.presentation_id);
        }
      );
    this.subscriptions.push(bookmarkSubscription);

    const websiteSubscription = this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          this.website = website;
          this.getBanner();
        }
      );
    this.subscriptions.push(websiteSubscription);

    const subscription = this.route.queryParams
      .subscribe(
        (params: any) => {
          if (params.search_term) { this.filter.search_term = params.search_term; }
          if (params.type) { this.filter.type = params.type.toString().split(','); }
          if (params.material) { this.filter.material = params.material.toString().split(','); }
          if (params.conferences) { this.filter.conferences = params.conferences.toString().split(','); }
          if (params.blocks) { this.filter.blocks = params.blocks.toString().split(',').map((i) => +i); }
          if (params.bookmarked) { this.filter.bookmarked = !!params.bookmarked; }
          if (params.sort) { this.sort = params.sort; }
          this.getPresentations();
        },
        () => { }
      );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    clearInterval(this.inteval);
  }

  reset() {
    this.filter.search_term = '';
    this.searchSuggestions = [];
    this.search();
  }

  search(searchTerm?: string) {
    const params: any = { };
    if (searchTerm) { this.filter.search_term = searchTerm; }
    if (this.filter.search_term) { params.search_term = this.filter.search_term; }
    if (this.filter.type && this.filter.type.length > 0) { params.type = this.filter.type.join(','); }
    if (this.filter.material && this.filter.material.length > 0) { params.material = this.filter.material.join(','); }
    if (this.filter.conferences && this.filter.conferences.length > 0) { params.conferences = this.filter.conferences.join(','); }
    if (this.filter.blocks && this.filter.blocks.length > 0) { params.blocks = this.filter.blocks.join(','); }
    if (this.filter.bookmarked) { params.bookmarked = this.filter.bookmarked; }
    if (this.sort !== 'published_at-desc') { params.sort = this.sort; }
    
    if(this.mainType=='Poster') { 
        this.router.navigate(['/posters'], { queryParams: params });
    } else {
        this.router.navigate(['/search'], { queryParams: params });
    }
  }

  searchFocus() {
    this.showSuggestions = true;
  }

  searchBlur() {
    setTimeout(
      () => {
        this.showSuggestions = false;
      }
    , 1000);
  }

  filterChange() {
    this.search();
  }

  searchTermChange(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.search();
    } else {
      this.searchService.getSuggestions({ term: this.filter.search_term })
        .subscribe(
          (searchSuggestions: string[]) => {
            this.searchSuggestions = searchSuggestions;
          },
          () => { }
        );
    }
  }

  getPresentations() {
    this.loadingService.start('search', 'fullPage');
    const params: any = {
      page: this.presentationsPagination.current_page,
      per_page: this.presentationsPagination.per_page,
      explain: true
    };
    if (this.filter.search_term) { params.search_term = this.filter.search_term; }

    // koji k

    if (this.filter.material && this.filter.material.length > 0) { params['material[]'] = this.filter.material; }
    if (this.filter.conferences && this.filter.conferences.length > 0) { params['conference_ids[]'] = this.filter.conferences; }
    if (this.filter.blocks && this.filter.blocks.length > 0) { params['blocks_ids[]'] = this.filter.blocks; }
    if (this.filter.bookmarked) { params['bookmarked'] = this.filter.bookmarked; }
    if (this.sort) {
      params['sort'] = this.sort.split('-')[0];
      params['order'] = this.sort.split('-')[1];
    }

    if (this.mainType && this.mainType === 'Poster') {
      params['type[]'] = 'Poster';
    } else if (this.filter.type) {
      params['type[]'] = this.filter.type;
    }

    this.presentationService.explore(params)
      .subscribe(
        (response: any) => {
          this.presentations = response.data;
          this.presentationsPagination = response.meta.pagination;
          this.presentationsMeta = response.meta;
          this.loadingService.stop('search');
        },
        () => {
          this.loadingService.stop('search');
        }
      );
  }

  getConferences() {
    this.loadingService.start('search', 'fullPage');
    this.websiteService.getWebsiteConferences()
      .subscribe(
        (response: any) => {
          this.conferences = response;
          this.getBlocks();
          this.loadingService.stop('search');
        },
        () => {
          this.loadingService.stop('search');
        }
      );
  }

  getBlocks(append: boolean = false) {
    if (this.conferences.length === 1) {
      if (append) {
        if (this.blockTotal && this.blocks.length >= this.blockTotal) { return; }
        this.blockPage++;
      } else {
        this.blockPage = 1;
      }

      const params: any = {
        page: this.blockPage,
        per_page: 10,
        sort: 'name',
        order: 'asc'
      };

      if (this.blockSearch !== '') { params.search_term = this.blockSearch; }

      this.blockService.getConferenceBlocks(this.conferences[0].id, params)
        .subscribe(
          (response: { data: Block[], meta: any }) => {
            if (append) {
              this.blocks = this.blocks.concat(response.data);
            } else {
              this.blocks = response.data;
              this.blockTotal = response.meta.pagination.total;
            }
          },
          () => { }
        );
    }
  }

  searchBlocks(searchTerm: string) {
    this.blockSearch = searchTerm;
    this.getBlocks();
  }

  getBanner() {
    const params: any = { position: 'Search' };
    params.conference_id = this.website.active_conference_id;

    this.bannerService.getBanner(params)
      .subscribe(
        (response: any) => {
          this.banner = response.data;
        },
        () => { }
      );
  }

 bannerClick() {
    window.open(this.banner.external_url, '_blank');
//    this.trackingService
//        .trackBannerView('CLICKED', this.banner.id, 'Banner', 'web')
//        .subscribe();
  }

  paginationChange(paginationConfig: PaginationConfig) {
    this.presentationsPagination.current_page = paginationConfig.current_page;
    this.getPresentations();
  }

  toggleBookmark(presentation: Presentation) {
    const bookmark = this.bookmarks.find(b => b.presentation_id === presentation.id);
    if (!bookmark) {
      this.bookmarkService.addWebsiteBookmark(this.website.id, presentation)
        .subscribe(
          () => { },
          () => { }
        );
    } else {
      this.bookmarkService.removeWebsiteBookmark(this.website.id, presentation)
        .subscribe(
          () => { },
          () => { }
        );
    }
  }

}
