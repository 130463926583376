import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from '@navus/core/services/user.service';
import { CountryService } from '@navus/core/services/country.service';
import { Country } from '@navus/core/classes/country';
import { ModalService } from '@navus/ui/modal/modal.service';
import { BannerService } from '@navus/core/services/banner.service';
import { Conference } from '@navus/core/classes/conference';
import { WebsiteService } from '@navus/core/services/website.service';
import { Website } from '@navus/core/classes/website';
import { User } from '@navus/core/classes/user';
import { TrackingService } from '@navus/core/services/tracking.service';

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth.component.html'
})
export class AuthComponent implements OnInit, OnDestroy {
  mode: 'registration'|'login'|'undecided' = 'undecided';
  returnUrl: string = null;
  allCountries: Country[] = [];
  filteredCountries: Country[] = [];
  website: Website;
  conference: Conference;
  authForm: FormGroup;
  codeForm: FormGroup;
  requestingAccessCode: boolean = false;
  accessCodeRequested: boolean = false;
  banner: any;
  userExists: boolean = false;
  registerWithCode: boolean;
  useCodeForPassword: boolean = true;

  subscriptions: Subscription[] = [];

  loading: boolean = false;

  keyEvent(event: KeyboardEvent) {
    if (event.key && event.key.toLowerCase() === 'enter') {
      // On loading button is disabled
      if (this.loading) { return; }
      
      // Remove focus from input
      (document.activeElement as HTMLElement).blur();

      // Call the function that active button click calls
      if (this.mode === 'registration') {
        this.register();
      } else if (this.mode === 'login') {
        this.login();
      } else if (this.mode === 'undecided') {
        this.next();
      }
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private websiteService: WebsiteService,
    private userService: UserService,
    private countryService: CountryService,
    private bannerService: BannerService,
    private modalService: ModalService,
    private trackingService: TrackingService,
  ) { }

  ngOnInit() {
    // Redirect logged in
    if (localStorage.getItem('jwtToken')) {
      this.router.navigate(['/profile', 'delegate']);
    }

    this.initForm();
    this.getCountries();

    const routeSubscription = this.route.data
      .subscribe((params) => {        
        if (params.mode) {
          this.mode = params.mode;
        }
        if (params.registerWithCode) {
          this.registerWithCode = params.registerWithCode;
        }
      });
    this.subscriptions.push(routeSubscription);

    const querySubscription = this.route.queryParams
      .subscribe((params) => {
        if (params.email) {
          this.authForm.reset();
          this.codeForm.reset();
          this.authForm.get('email').setValue(params.email);
          this.codeForm.get('email').setValue(params.email);
        }
        if (params.returnUrl) {
          this.returnUrl = params.returnUrl;
        }
      });
    this.subscriptions.push(querySubscription);

    const websiteSubscription = this.websiteService.currentWebsite
      .subscribe((website: Website) => {
        this.website = website;
        if (website.active_conference_id) {
          this.conference = website.conferences.find(conference => conference.id === website.active_conference_id);

          this.bannerService
            .getBanner({ position: 'Registration', conference_id: website.active_conference_id })
            .subscribe(
              (response) => {
                this.banner = response.data;
                this.trackingService.shownTracking(website.active_conference_id, 'banner', this.banner.id);
              },
              (error) => { }
            );
        }
      });
    this.subscriptions.push(websiteSubscription);

    const emailSubscription = this.authForm.get('email').valueChanges
      .subscribe((value) => {
        if(this.authForm.get('email').status === "VALID") {
          this.checkIfUserExists(value);
        }
      });
    this.subscriptions.push(emailSubscription);
    
    const emailCodeSubscription = this.codeForm.get('email').valueChanges
      .subscribe((value) => {
        if(this.codeForm.get('email').status === "VALID") {
          this.checkIfUserExists(value);
        }
      });
    this.subscriptions.push(emailCodeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  
  checkIfUserExists(value) {
    this.loading = true;
    this.userService.checkEmail(value)
      .subscribe(
        () => {
          this.userExists = true;
          this.loading = false;
        },
        (error) => {
          if (error.status === 404) {
            this.userExists = false;
          }
          this.loading = false;
        }
      );
  }

  initForm() {
    this.authForm = new FormGroup({
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,8}$/)
      ]),
      'first_name': new FormControl('', [
        Validators.required,
        Validators.maxLength(30)
      ]),
      'last_name': new FormControl('', [
        Validators.required,
        Validators.maxLength(30)
      ]),
      'password': new FormControl('', [
        Validators.required,
        // Validators.pattern(/.*^(?=.{6,20})(?=.*[a-zA-Z])(?=.*[0-9]).*$/)
      ]),
      'institution': new FormControl('', [
        Validators.required,
        Validators.maxLength(255)
      ]),
      'country_id': new FormControl('', [
        Validators.required
      ]),
      'terms_and_conditions': new FormControl(false, [
        Validators.pattern('true')
      ]),
      'access_code': new FormControl('')
    });

    this.codeForm = new FormGroup({
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,8}$/)
      ]),
      'password': new FormControl(''),
      'terms_and_conditions': new FormControl(false, [
        Validators.pattern('true')
      ]),
      'code': new FormControl('', [
        Validators.required
      ]),
    });
  }

  forgotPassword() {
    if (this.authForm.value.email === '') {
      this.modalService.defaultModal({
        title: 'Access Code',
        body: 'Please enter your email to continue',
        buttons: [{ text: 'OK', role: 'cancel' }]
      });
      return;
    }

    this.requestingAccessCode = true;
    this.userService.requestAccessCode(this.authForm.value.email)
      .subscribe(
        () => {
          this.requestingAccessCode = false;
          this.accessCodeRequested = true;
          this.modalService.defaultModal({
            title: 'Access Code',
            body: 'Email with access code has been sent to ' + this.authForm.value.email,
            buttons: [{ text: 'OK', role: 'cancel' }]
          });
        },
        (error) => {
          this.requestingAccessCode = false;
          this.modalService.error({ errors: error });
        }
      );
  }

  register() {
    if (this.authForm.valid) {
      this.loading = true;
      this.userService.register(this.authForm.value)
        .subscribe(
          (response) => {
            this.login({ email: response.data.email, password: this.authForm.value.password }, false);
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loading = false;
          }
        );
    }
  }

  toggleUseCodeForPassword() {
    if (this.useCodeForPassword) {
      this.codeForm.get('password').clearValidators();
      this.codeForm.get('password').reset();
    } else {
      this.codeForm.get('password').setValidators(Validators.required);
    }
  }

  registerWithDelegateCode() {
    if (this.codeForm.valid) {
      if (this.useCodeForPassword) {
        this.codeForm.get('password').setValue(this.codeForm.get('code').value);
      }
      if (this.userExists) {
          this.login({ email: this.codeForm.value.email, password: this.codeForm.value.password }, false);
          return;
      }

      this.loading = true;
      this.userService
        .registerWithCode(this.conference.id, this.codeForm.value)
        .subscribe(
          (response) => {
            this.login({ email: this.codeForm.value.email, password: this.codeForm.value.password }, false);
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loading = false;
          }
        );
    }
  }

  login(data:any={}, fromForm:boolean=true) {
    if (fromForm) {
        if(!this.accessCodeRequested && !this.authForm.controls.password.valid) { return; }
        data = this.authForm.value;
    } 

    this.loading = true;
    this.userService.login(data)
      .subscribe(
        () => {
          this.userService.getCurrentUser()
            .subscribe(
              (user: User) => {
                if (user.delegates) {
                  const delegate = user.delegates.find(userDelegate => userDelegate.conference_id === this.conference.id);
                  if (delegate) {
                    if (this.returnUrl) { 
                      this.router.navigateByUrl(this.returnUrl);
                    } else {
                      this.router.navigate(['/']);
                    }
                  } else {
                    if (this.returnUrl) {                      
                      this.router.navigate(['/delegate-registration'], { queryParams: { returnUrl: this.returnUrl }});
                    } else {
                      this.router.navigate(['/delegate-registration']);
                    }
                  }
                }
              },
              () => {
                this.loading = false;
              }
            );
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.loading = false;
        }
      );
  }

  next() {
    // const emailValid = this.authForm.get('email').valid;
    // if (emailValid) {
    //   if (this.userExists) {
    //     this.mode = 'login';
    //   } else {
    //     this.mode = 'registration';
    //   }      
    // }
    // Check again
    this.loading = true;
    const email = this.authForm.get('email').value;
    this.userService.checkEmail(email)
      .subscribe(
        () => {
          this.userExists = true;
          this.mode = 'login';
          this.loading = false;
        },
        (error) => {
          if (error.status === 404) {
            this.userExists = false;
            this.mode = 'registration';
          }
          this.loading = false;
        }
      );
  }

  getCountries() {
    this.countryService.getCountries()
      .subscribe(
      (response: any) => {
          this.allCountries = response.data;
          this.filteredCountries = response.data;
        }
    );
  }

  filterCountries(searchTerm: string) {
    if (searchTerm.length > 2) {
      this.filteredCountries = [];
      for (const country of this.allCountries) {
        if (country.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          this.filteredCountries.push(country);
        }
      }
    } else {
      this.filteredCountries = this.allCountries;
    }
  }

  goToLogin() {
    const codeFormEmail = this.codeForm.get('email').value;
    if (codeFormEmail && codeFormEmail !== '') {
      this.authForm.get('email').setValue(codeFormEmail);      
    }

    this.mode = 'login';
    this.userExists = false;
    this.registerWithCode = false;
  }

  goToCodeRegistration() {
    this.mode = 'undecided';
    this.userExists = false;
    this.registerWithCode = true;
  }

  bannerClick() {
    if (!this.banner) { return; }

    this.trackingService.clickTracking(this.conference.id, 'banner', this.banner.id);
    window.open(this.banner.external_url, '_blank');
  }
}
