<div class="nv-modal" [ngClass]="size">
    <div class="nv-modal-header" *ngIf="showTitle">
        <h4 class="nv-modal-title" translate>{{ title }}</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body" [innerHTML]="body | nvHtml"></div>
    <div class="nv-modal-footer">
        <div class="nv-btn-box gap-10 flow-end">
            <button type="button"
                    *ngFor="let button of buttons"
                    class="nv-btn medium {{ button.color || 'primary' }}"
                    (click)="onClick(button)">{{ button.text }}</button>
        </div><!-- /.nv-btn-box -->
    </div>
</div>
