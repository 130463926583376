<div *ngIf="!fullPage && showLoading" [class.hidden]="!showLoading" class="nv-loading">
    <div *ngIf="showLoading" class="nv-loading-visual">Loading</div>
</div>

<div *ngIf="fullPage && showLoading"
     class="loading-full-screen">
    <div [class.hidden]="!showLoading" class="nv-loading">
        <div *ngIf="showLoading" class="nv-loading-visual">Loading</div>
    </div>
</div>
<ng-content></ng-content>

