<div class="nv-configurator-layout">
    <div class="config-layout-sidebar">

    </div><!-- /.config-layout-sidebar -->
    <div class="config-layout-main-frame">
        <div class="config-layout-lower-fixed">

            <!-- Config Branding -->
            <div class="nv-config-branding-hero">
                <div class="cbh-photo-port">
                    <img src="../../assets/config-branding-hero.jpg" alt="Organization name">
                    <span><p>No photo uploaded</p></span>
                    <div class="cbh-upload-n-info">
                        <a class="cbh-photo-upload">Upload cover photo</a>
                        <a class="cbh-photo-info"></a>
                    </div><!-- /.cbh-upload-n-info -->
                </div><!-- /cbh-photo-port -->
                <div class="cbh-logo-n-org">
                    <div class="cbh-logo-port">
                        <div style="background-image: url('//service.e-materials.com/storage/resources/sehh/seh/sehh-logo.png');"></div> <!-- remove element for initial state -->
                        <span>LOGO</span>
                    </div><!-- /cbh-logo-port -->
                    <div class="cbh-org">
                        <p>ORGANIZATION</p>
                        <h3>TIMM</h3>
                    </div><!-- /.cbh-org -->
                </div><!-- /.cbh-logo-n-org -->
            </div><!-- /.nv-config-branding-hero -->
            <!-- /Config Branding -->


            <section class="nv-section">
                <div class="nv-port">
                    <div class="nv-row config-plates v-stretch gap-24">
                        <div class="nv-column desk-3 tab-6">
                            <div class="nv-column-content">
                                <p>Content</p>
                            </div>
                        </div>
                        <div class="nv-column desk-3 tab-6">
                            <div class="nv-column-content">
                                <p>Content</p>
                            </div>
                        </div>
                        <div class="nv-column desk-3 tab-6">
                            <div class="nv-column-content">
                                <p>Content</p>
                            </div>
                        </div>
                        <div class="nv-column desk-3 tab-6">
                            <div class="nv-column-content">
                                <p>Content</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section><!-- /.nv-section -->
        </div><!-- /.config-layout-lower-fixed -->
    </div><!-- /.config-layout-main-frame -->
</div><!-- /.nv-configurator-layout -->