import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Country } from "@navus/core/classes/country";
import { User } from "@navus/core/classes/user";
import { CountryService } from "@navus/core/services/country.service";
import { UserService } from "@navus/core/services/user.service";
import { ModalService } from "@navus/ui/modal/modal.service";
import { ToastService } from "@navus/ui/toast/toast.service";
import { FileUploader } from "ng2-file-upload";
import { Subscription } from "rxjs";
import { environment } from "../../../../../../environments/environment";

@Component({
  selector: 'main [nv-website-profile-user]',
  templateUrl: './user.component.html'
})
export class ProfileUserComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-profile-settings-body') get classView() { return !this.editMode; }
  @HostBinding('class.nv-profile-settings-detail-body') get classEdit() { return this.editMode; }
  allCountries: Country[] = [];
  filteredCountries: Country[] = [];
  user: User;
  userForm: FormGroup;
  uploader: FileUploader = new FileUploader({});
  progressBar: boolean = false;

  editMode: boolean;

  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private countryService: CountryService,
    private modalService: ModalService,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    this.initForm();
    this.getCountries();

    const userSubscription = this.userService.currentUser
      .subscribe((user: User) => {
        if (user) {
          this.user = user;
          this.userForm.patchValue(user);
          this.initUploader();
        }
      });
    this.subscriptions.push(userSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.userForm = new FormGroup({
      'first_name': new FormControl('', [Validators.required]),
      'last_name': new FormControl('', [Validators.required]),
      'email': new FormControl('', [Validators.required, Validators.email]),
      'country_id': new FormControl(null, [Validators.required]),
      'institution': new FormControl('', [Validators.required]),
    })
  }

  initUploader() {
    this.uploader.setOptions({
      url: `${environment.api_url}users/${this.user.id}/images`,
      removeAfterUpload: true,
      autoUpload: true,
      authToken: 'Bearer ' + localStorage.getItem('jwtToken')
    });
    this.uploader.onAfterAddingFile = (file: any) => {
      file.withCredentials = false; 
    };
    this.uploader.onBuildItemForm = (fileItem, form) => {
      form.append('file', fileItem._file);
      return {fileItem, form};
    };
    this.uploader.onBeforeUploadItem = () => {
      this.progressBar = true;
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.progressBar = false;
      this.uploader.clearQueue();
      this.user = <User>JSON.parse(response).data;
    };
  }

  getCountries() {
    this.countryService.getCountries()
      .subscribe(
        (response: any) => {
          this.allCountries = response.data;
          this.filteredCountries = response.data;
        }
      );
  }

  filterCountries(searchTerm: string) {
    if (searchTerm.length > 2) {
      this.filteredCountries = [];
      for (const country of this.allCountries) {
        if (country.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          this.filteredCountries.push(country);
        }
      }
    } else {
      this.filteredCountries = this.allCountries;
    }
  }

  edit() {
    this.editMode = true;
  }

  cancel() {
    this.editMode = false;
  }

  save() {
    this.modalService.defaultModal({
      title: 'Confirmation',
      body: 'Are you sure you want to change information?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Change',
          color: 'primary',
          handler: () => {
            this.userService.updateUser(this.user.id, this.userForm.value)
              .subscribe(
                () => {
                  this.userService.getCurrentUser().subscribe();
                  this.editMode = false;
                  this.toastService.success('Information successfully saved.');
                },
                () => {
                  this.toastService.error('Failed to save information.');
                }
              );
          }
        }
      ]
    });
  }

}