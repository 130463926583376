<div [nvLoading]="{instance: 'search'}">
    <section class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row spaced">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-search-bar-widget">
                            <div class="sbw-bar">
                                <input placeholder="{{ 'Search' | translate }}"
                                       type="text"
                                       class="sbw-input"
                                       [(ngModel)]="filter.search_term"
                                       [ngModelOptions]="{ standalone: true }"
                                       (keyup)="searchTermChange($event)"
                                       (focus)="searchFocus()"
                                       (blur)="searchBlur()"
                                >
                                <span class="sbw-outline"></span>
                                <button class="sbw-btn" (click)="search(filter.search_term)"></button>
                                <button *ngIf="filter.search_term.length > 0" class="sbw-clear" (click)="reset()" translate>Clear</button>
                                <ul class="nv-live-search" *ngIf="filter.search_term.length > 0 && showSuggestions">
                                    <li *ngFor="let suggestion of searchSuggestions">
                                        <p (click)="search(suggestion)">
                                            {{ suggestion }}
                                        </p>
                                    </li>
                                    <li *ngIf="searchSuggestions.length === 0" class="ls-noresults">
                                        <p translate>No suggestions for this term</p>
                                    </li>
                                </ul>
                            </div>
                        </div><!-- /.nv-search-bar-widget -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->

    <section class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row small-spaced gap-24 propagate">
                
                <div class="nv-column desk-initial tab-12 v-middle">
                    <div class="nv-column-content">
                        <strong><span translate>Filter</span>:</strong>
                    </div>
                </div>

                <div class="nv-column desk-2 tab-6 phab-12" *ngIf="mainType!=='Poster' && presentationTypes.length > 1">
                    <div class="nv-column-content">
                        <nv-select [options]="presentationTypes"
                                    [multi]="true"
                                    label="{{'Presentation types' | translate}}"
                                    [(ngModel)]="filter.type"
                                    (ngModelChange)="filterChange()"
                        ></nv-select>
                    </div>
                </div>

                <div class="nv-column desk-2 tab-6 phab-12" *ngIf="materialTypes.length > 1">
                    <div class="nv-column-content">
                        <nv-select [options]="materialTypes"
                                    [multi]="true"
                                    label="{{'Available materials' | translate}}"
                                    [(ngModel)]="filter.material"
                                    (ngModelChange)="filterChange()"
                        ></nv-select>
                    </div>
                </div>

                <div class="nv-column desk-2 tab-6 phab-12" *ngIf="blocks.length === 1">
                    <div class="nv-column-content">
                        <nv-select [options]="blocks"
                                    [multi]="true"
                                    label="{{'Sessions' | translate}}"
                                    [showSearch]="true"
                                    (searched)="searchBlocks($event)"
                                    (loadData)="getBlocks()"
                                    (loadMore)="getBlocks(true)"
                                    [(ngModel)]="filter.blocks"
                                    (ngModelChange)="filterChange()"
                        ></nv-select>
                    </div>
                </div>

                <div class="nv-column desk-2 tab-6 phab-12" *ngIf="conferences.length > 1">
                    <div class="nv-column-content">
                        <nv-select [options]="conferences"
                                   [multi]="true"
                                   label="{{'Events' | translate}}"
                                   [(ngModel)]="filter.conferences"
                                   (ngModelChange)="filterChange(); getBlocks();"
                        ></nv-select>
                    </div>
                </div>

                <div class="nv-column desk-2 tab-6 phab-12">
                    <div class="nv-column-content">
                        <nv-select [options]="sortOptions"
                                    label="{{'Sort' | translate}}"
                                    [(ngModel)]="sort"
                                    (ngModelChange)="filterChange()"
                        ></nv-select>
                    </div>
                </div>
            </div>
            <div>
                <div *ngIf="website.id === 354">
                    <span> Find here more information and materials about the research work of the Awardees 2021</span>
                </div>
                <!-- JPL events - website.id === 345 -->
                <div *ngIf="website.id === 345">
                    <a target="_blank" href="https://service.e-materials.com/storage/resources/jpl_media/pos/posters_table_of_contents.pdf">Table of contents</a>
                    <span> The Poster Platform is proudly sponsored by Astra Zeneca</span>
                </div>
                <nv-pagination type="thorough"
                                *ngIf="presentations.length > 0"
                                (paginationChange)="paginationChange($event)"
                                [config]="presentationsPagination"
                ></nv-pagination>
            </div>
            <main>
                <div class="nv-row small-spaced">
                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <div class="nv-result-item" *ngIf="banner?.resource_url && banner?.external_url" style="text-align: center">
                                <a (click)="bannerClick()" target="_blank">
                                    <img [src]="banner?.resource_url" alt style="max-height: 200px">
                                </a>
                            </div>
                            <div class="nv-result-item" *ngIf="banner?.resource_url && !banner?.external_url" style="text-align: center">
                                <img [src]="banner?.resource_url" alt style="max-height: 200px">
                            </div>
                            <div *ngFor="let presentation of presentations" class="nv-result-item">
                                <div class="ri-top">
                                    <!--span *ngIf="conference">{{ conference.name }}</span-->
                                    <h3>
                                        <a [routerLink]="['/presentation', presentation.id]"
                                            [title]="presentation.title"
                                            [innerHTML]="presentation.title | highlight: filter.search_term"></a>
                                    </h3>
                                    <div class="ri-top-data">
                                        <div class="rtd-item" *ngIf="sort=='vote_count-desc'">
                                            <p class="nv-liked">
                                                Liked
                                                <span class="vote-count">{{ presentation.vote_count }} </span>
                                                times
                                            </p>
                                        </div><!-- /.rtd-item -->
                                    </div><!-- /.ri-top-data -->
                                    <div class="ri-top-data">
                                        <div class="rtd-item" *ngIf="presentation.updated_at">
                                            <p class="nv-posted-stamp">
                                                <span translate>Updated</span>:
                                                <span>{{ presentation.updated_at | dateFormat: 'MMMM YYYY' }}</span>
                                            </p>
                                        </div><!-- /.rtd-item -->
                                        <div class="rtd-item">
                                            <a class="nv-bookmark-trigger nv-clickable"
                                                [class.on]="bookmarkedPresentationIds.includes(presentation.id)"
                                                (click)="toggleBookmark(presentation)"
                                                title="{{'Bookmark this presentation' | translate}}"></a>
                                        </div><!-- /.rtd-item -->
                                    </div><!-- /.ri-top-data -->
                                </div><!-- /ri-top -->
                                <div class="ri-mid">
                                    <div class="rm-left">
                                        <div class="nv-avatar-n-author">
                                            <!--<figure class="ana-img" *ngIf="presentation.speaker_image_thumb" [style.backgroundImage]="'url('+ presentation.speaker_image_thumb +')'"></figure>-->
                                            <p [innerHTML]="presentation.speaker_name | highlight: filter.search_term"></p>
                                        </div><!-- /.nv-avatar-n-author -->
                                        <div class="nv-materials-available" *ngIf="presentation.has_main_material || presentation.has_webcast_material || presentation.description">
                                            <p><span translate>Materials</span>:</p>
                                            <a [routerLink]="['/presentation', presentation.id]"
                                            [title]="presentation.title" *ngIf="presentation.has_main_material" class="ma-item material" title="{{'Material available' | translate}}">
                                                <span translate>Material available</span>
                                            </a>
                                            <a [routerLink]="['/presentation', presentation.id]"
                                            [title]="presentation.title" *ngIf="presentation.has_webcast_material" class="ma-item webcast" title="{{'Webcast available' | translate}}">
                                                <span translate>Webcast available</span>
                                            </a>
                                            <a [routerLink]="['/presentation', presentation.id]"
                                            [title]="presentation.title" *ngIf="presentation.description" class="ma-item abstract" title="{{'Abstract available' | translate}}">
                                                <span translate>Abstract available</span>
                                            </a>
                                        </div><!-- /.nv-materials-available -->
                                    </div><!-- /.rm-left -->
                                    <!--div class="rm-right">
                                        <a class="nv-btn filled extra-small purchase radius-4" data-icon-pl="&#xe04e;">Purchase this item for &euro; 5.-</a>
                                    </div--><!-- /.rm-right -->
                                </div><!-- /.ri-mid -->
                                <div class="ri-bottom">
                                    <div class="rb-left">
                                        <p class="nv-footnote" *ngIf="filter.search_term" translate><span translate>Found by</span>:
                                            <ng-container *ngIf="presentationsMeta?.found_by_block_category_title && presentationsMeta.found_by_block_category_title.indexOf(presentation.id) != -1">
                                                Session category;
                                            </ng-container>
                                            <ng-container *ngIf="presentationsMeta?.found_by_block_title && presentationsMeta.found_by_block_title.indexOf(presentation.id) != -1">
                                                Session name;
                                            </ng-container>
                                            <ng-container *ngIf="presentationsMeta?.found_by_material_content && presentationsMeta.found_by_material_content.indexOf(presentation.id) != -1">
                                                Material content;
                                            </ng-container>
                                            <ng-container *ngIf="presentationsMeta?.found_by_presentation_description && presentationsMeta.found_by_presentation_description.indexOf(presentation.id) != -1">
                                                Abstract;
                                            </ng-container>
                                            <ng-container *ngIf="presentationsMeta?.found_by_presentation_title && presentationsMeta.found_by_presentation_title.indexOf(presentation.id) != -1">
                                                Presentation title;
                                            </ng-container>
                                            <ng-container *ngIf="presentationsMeta?.found_by_speaker_name && presentationsMeta.found_by_speaker_name.indexOf(presentation.id) != -1">
                                                Speaker name;
                                            </ng-container>
                                        </p>
                                    </div>
                                </div><!-- /.ri-bottom -->
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <div>
                <nv-pagination type="thorough"
                                *ngIf="presentations.length > 0"
                                (paginationChange)="paginationChange($event)"
                                [config]="presentationsPagination"
                ></nv-pagination>
            </div>
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->
</div>
