<div class="nv-modal large">  
    <div class="nv-modal-header">
        <h4 class="nv-modal-title">Session details</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body">
        <div class="nv-session-info-box" *ngIf="block">
            <div class="nv-session-info-left">
                <div class="nv-session-top-meta">
                    <!-- <p class="live"><span>Live</span></p> -->
                    <p class="time">{{ block.starts_at | dateFormat:'HH:mm' }} - {{ block.ends_at | dateFormat:'HH:mm' }}</p>
                    <p class="location">Room: {{ block.location.name }}</p>
                </div><!-- /.nv-session-top-meta -->
                <h2><a>{{ block.name }}</a></h2>

                <!-- <p><a class="nv-btn accent1 medium">This session starts at 13:00</a></p>
                <p>This session has ended.</p> -->

                <p class="nv-sib-label" *ngIf="block.chairpersons.length > 0">Chairs</p>

                <ng-container *ngFor="let chairperson of block.chairpersons">
                    <div class="nv-participant-face">
                        <figure *ngIf="chairperson.image_url" nvBackgroundImage [image]="chairperson.image_url"></figure>
                        <div class="txt">
                            <h4><a>
                                <ng-container *ngIf="chairperson.title">{{chairperson.title}}&nbsp;</ng-container>
                                {{ chairperson.name }}
                            </a></h4>
                            <!-- <p>Brazil</p> -->
                        </div><!-- /.txt -->
                    </div><!-- /.nv-participant-face -->                    
                </ng-container>

            </div><!-- /.nv-session-info-left -->
            <div class="nv-session-info-right">
                <p class="nv-sib-label">Presentations</p>

                <ng-container *ngFor="let presentation of block.presentations">
                    <div class="nv-participant-engagement">
                        <h5>
                            Starts at: {{ presentation.starts_at | dateFormat:'HH:mm' }}
                            <!-- <a class="bookmark" title="Bookmark this presentation"></a> -->
                        </h5>
                        <p>{{ presentation.title }}</p>
                    </div><!-- /.nv-participant-engagement -->
                    <div class="nv-participant-faces">
                        <div class="nv-participant-face" *ngFor="let speaker of presentation.speakers">
                            <figure *ngIf="speaker.image_url" nvBackgroundImage [image]="speaker.image_url"></figure>
                            <div class="txt">
                                <h4><a>
                                    <ng-container *ngIf="speaker.title">{{speaker.title}}&nbsp;</ng-container>
                                    {{ speaker.name }}
                                </a></h4>
                                <!-- <p>Alaska</p> -->
                            </div><!-- /.txt -->
                        </div><!-- /.nv-participant-face -->
                    </div><!-- /.nv-participant-faces -->                   
                </ng-container>

            </div><!-- /.nv-session-info-right -->
        </div><!-- /.nv-session-info-box -->
    </div>
</div>
