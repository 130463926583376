import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DelegateService } from '@navus/core/services/delegate.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { Color } from '@swimlane/ngx-charts';
import { ConferenceService } from '@navus/core/services/conference.service';
import { Program } from '@navus/core/classes/program';
import { AttendanceSession } from '@navus/core/classes/attendance-session';
import { TimeFormatPipe } from '@navus/ui/pipes/time-format.pipe';

@Component({
  selector: 'nv-delegate-on-site-attendance',
  templateUrl: './delegate-on-site-attendance.component.html',
})
export class DelegateOnSiteAttendanceComponent implements OnInit, OnDestroy {
  @Input() templateForTooltip: TemplateRef<void>;

  @Input() conferenceId: number;
  @Input() delegateId: number;

  chartColorScheme = {
    domain: ['#318AF1', '#2873A1', '#8ABAD6', '#32B799', '#279079', '#18707F', '#ED4649', '#F49092', '#AD465A', '#ED8747']
  } as Color;

  loadingAttendance = false;
  attendanceData: AttendanceSession[];
  sessionData: { name: string, value: number }[];
  sessionLegend: { id: number, name: string, value: string, color: string }[];
  sessionActiveEntries: any[] = [];
  selectedSession: AttendanceSession;

  presentationData: { name: string, value: number }[];
  presentationLegend: { name: string, value: string, color: string }[];
  presentationActiveEntries: any[] = [];

  availableSessions: { id: number, name: string }[];
  private subscriptions: Subscription[] = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private conferenceService: ConferenceService,
              private delegateService: DelegateService,
              private modalService: ModalService,
              private timeFormatPipe: TimeFormatPipe) {
  }

  ngOnInit() {
    this.getAttendance();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  onSelect(event) {
    const chosenSession = typeof event === 'string' ? event : event.name;
    if (chosenSession) {
      this.selectedSession = this.attendanceData
        .find(item => item.name === chosenSession);
      this.presentationData = this.selectedSession?.presentations?.map(item => ({ name: item.title, value: item.time_spent }));

      // Initialize legend
      this.presentationLegend = this.selectedSession?.presentations?.map((item, index) => ({
        name: item.title,
        value: this.timeFormatPipe.transform(item.time_spent),
        color: this.chartColorScheme.domain[index % 10]
      }));

      setTimeout(() => {
        const presentationChart = document.getElementById('on-site-presentation-chart');
        if (presentationChart) {
          presentationChart.scrollIntoView();
        }
      });
    }
  }

  isSessionLabelActive(item: { name: string }): void {
    return this.sessionActiveEntries.find(s => s.name === item.name);
  }

  sessionLegendLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.sessionActiveEntries = this.sessionData.filter(item => item.name === name);
  }

  sessionLegendLabelDeactivate(item: any): void {
    this.sessionActiveEntries = [];
  }

  presentationLegendLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.presentationActiveEntries = this.presentationData.filter(item => item.name === name);
  }

  presentationLegendLabelDeactivate(item: any): void {
    this.presentationActiveEntries = [];
  }

  addSession(selectedSessions: number[]) {
    this.delegateService.addConferenceDelegatesAttendanceOnSite(this.conferenceId, this.delegateId, selectedSessions)
      .subscribe(() => {
        this.getAttendance();
      }, (error) => {
        this.modalService.error({ errors: error });
      });
  }

  removeSession(sessionId: number) {
    this.modalService.defaultModal({
      title: 'Session Deletion',
      body: 'Are you sure you want to remove this session?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.delegateService.deleteConferenceDelegatesAttendanceOnSite(this.conferenceId, this.delegateId, sessionId)
              .subscribe(
                () => {
                  this.getAttendance();
                },
                (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

  private getAttendance() {
    this.loadingAttendance = true;
    this.delegateService.getConferenceDelegatesAttendanceOnSite(this.conferenceId, this.delegateId)
      .subscribe(
        (response: AttendanceSession[]) => {
          this.attendanceData = response;
          this.sessionData = this.attendanceData?.filter(item => item.time_spent)
            .map(item => ({ name: item.name, value: item.time_spent }));

          this.sessionLegend = this.attendanceData?.filter(item => item.time_spent)
            .map((item, index) => ({
              id: item.id,
              name: item.name,
              value: this.timeFormatPipe.transform(item.time_spent),
              color: this.chartColorScheme.domain[index % 10]
            }));

          this.getProgram();
          this.loadingAttendance = false;
        },
        (error) => {
          this.sessionData = [];
          this.loadingAttendance = false;
        }
      );
  }

  private getProgram() {
    this.loadingAttendance = true;
    this.conferenceService.getProgram(this.conferenceId)
      .subscribe((response: { data: Program }) => {
        this.loadingAttendance = false;
        this.availableSessions = response.data.blocks
          .map(item => ({ id: item.id, name: item.name }))
          .filter(item => !this.sessionLegend?.map(item => item.name).includes(item.name));
      }, (error) => {
        this.loadingAttendance = false;
        this.modalService.error({ errors: error });
      });
  }
}
