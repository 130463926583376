import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ConfigService {
  public showSidebarSubject = new BehaviorSubject<boolean>(false);
  public showSidebar = this.showSidebarSubject.asObservable();

  constructor() { }

  toggleSidebar() {
    this.showSidebarSubject.next(!this.showSidebarSubject.value);
  }

}
