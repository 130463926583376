<div *ngIf="!currentPresentation" class="nv-ls-feature-panel-add-slides">
    <p>No presentation has been selected. Once a presentation is streamed, you will be able to manage and share it’s slides to the audience.</p>
</div><!-- /.nv-ls-feature-panel-add-slides -->

<ng-container *ngIf="currentPresentation">
    <div *ngIf="!currentPresentation.has_main_material" class="nv-ls-feature-panel-add-slides">
        <p>No slides uploaded yet.</p>
        <a (click)="openEmaterials()" class="nv-btn medium passive">Add slides</a>
        &nbsp;
        <a (click)="refreshEmaterials()" class="nv-btn medium passive">Refresh</a>
    </div><!-- /.nv-ls-feature-panel-add-slides -->

    <!--        TODO: Check inline styles -->
    <div *ngIf="currentPresentation.has_main_material" style="display:flex">
        <ng-container *ngIf="currentPresentationMainMaterial">

            <div class="nv-material-view" style="flex: 4">
                <figure class="nv-material-preview">
                    <img *ngIf="currentPage && materialsImages?.length"
                         [ngStyle]="{'height': scale * 100 + '%', 'maxWidth': '70%'}"
                         [src]="materialsImages[currentPage-1].src">
                </figure><!-- /nv-material-preview -->
            </div>

            <div class="nv-material-view" *ngIf="materialsImages?.length" style="flex: 1">
                <div class="nv-material-detail-submenu">
                    <nav *ngIf="materialsImages?.length">
                        <ul class="nmv-pagination">
                            <li class="mds-prev" *ngIf="currentPage >= 2">
                                <a (click)="setPage(currentPage-1)" title="Previous slide"></a>
                            </li>
                            <li class="mds-page">
                                <input type="number" min=1 max="{{ materialsImages?.length }}"
                                       [ngModel]="currentPage"
                                       (ngModelChange)="setPage($event)">
                                <span> / &nbsp;&nbsp;{{materialsImages?.length}}</span>
                            </li>
                            <li class="mds-next"
                                *ngIf="currentPage < materialsImages.length">
                                <a (click)="setPage(currentPage+1)" title="Next slide"></a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="nv-material-preview">
                    <ng-container *ngFor="let image of materialsImages; let i = index">
                        <img [ngStyle]="{'height': 100 / materialsImages.length + '%', 'maxWidth': 100 / materialsImages.length + '%', margin: '10px'}"
                             [src]="image.src"
                             (click)="setPage(i+1)">
                    </ng-container>
                </div>
            </div>

        </ng-container>
    </div>
</ng-container>

