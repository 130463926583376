<div class="nv-profile-settings-body-gutter">
  <h3>Speakers, Authors and Moderators page</h3>
  <!-- <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p> -->
  <p>
    <button 
      class="nv-btn medium primary" 
      data-icon-pl="&#xe94a;"
      [routerLink]="['/speaker-studio']"
    >Enter the studio</button>
  </p>

  <table class="nv-sam-table">
      <tr class="nv-sam-table-headers">
          <th>
              <span>Presentation</span>
          </th>
          <th>
              <span>Session</span>
          </th>
          <th>
            <span>Speakers</span>
          </th>
          <th>
              <span class="time">Time</span>
          </th>
          <th>
              <span class="location">Location</span>
          </th>
          <th>
              <span>Materials</span>
          </th>
          <th>
              &nbsp;
          </th>
      </tr>
      <ng-container *ngFor="let presentation of myPresentations">
        <tr [class.open]="presentation.id === expandedPresentationId">
          <td data-label="Presentation" [title]="presentation.title">
            <p>{{ presentation.title }}</p>
          </td>
          <td data-label="Session" [title]="presentation.block_name">
            <p>{{ presentation.block_name }}</p>
          </td>
          <td data-label="Speakers">
            <p class="person multi">
              <span>
                <strong 
                  *ngFor="let speaker of presentation.speakers" 
                  class="nv-sam-avatar" 
                  nvBackgroundImage [image]="speaker.image_url"
                  [title]="speaker.name"
                ></strong>
                <em [title]="presentation.speaker_name">{{ presentation.speaker_name }}</em>
              </span>
            </p>
          </td>
          <td data-label="Time">
            <p class="date-n-time">
              <strong>{{ presentation.starts_at | nvDate:'dd.MM.yyyy' }}</strong>
              <span>{{ presentation.starts_at | nvDate:'HH:mm' }} - {{ presentation.ends_at | nvDate:'HH:mm' }}</span>
            </p>
          </td>
          <td data-label="Location">
            <p class="location-venue">
              <span [style.background-color]="presentation.block?.location?.color">{{ presentation.block?.location?.name }}</span>
            </p>
          </td>
          <td data-label="Materials">
            <div class="material-available">
              <a 
                *ngFor="let material of presentation.materials" 
                [class.presentation]="material.type === 'main'" 
                [class.abstract]="material.type === 'abstract'" 
                [class.webcast]="material.type === 'webcast'" 
                [title]="material.title"
              ></a>
            </div>
          </td>
          <td class="toggle-info" (click)="expandedPresentationId = presentation.id === expandedPresentationId ? null : presentation.id">
              <span title="Details"></span>
          </td>
      </tr>
      <tr class="nv-sam-details-row" *ngIf="presentation.id === expandedPresentationId">
          <td colspan="7">
              <div class="nv-sam-details-n-meta">
                  <div class="nv-sam-details-layout">
                      <div class="nv-sam-detail-location-time">
                        <p class="time">{{ presentation.block?.starts_at | nvDate:'HH:mm' }} - {{ presentation.block?.ends_at | nvDate:'HH:mm' }}</p>
                        <p class="location" [style.background-color]="presentation.block?.location?.color">{{ presentation.block?.location?.name }}</p>
                      </div><!-- /.nv-sam-detail-location-time -->
                      <div class="nv-sam-detail-session">
                        <h3>
                          <a><span>Session</span> {{ presentation.block_name }}</a>
                        </h3>
                        <p class="chairs">Chairs: {{ presentation.block?.chairperson }}</p>
                        <h4>
                          <span>{{ presentation.starts_at | nvDate:'HH:mm' }} - {{ presentation.ends_at | nvDate:'HH:mm' }}</span>
                          {{ presentation.title }}
                        </h4>
                        <div class="nv-sam-sessions-speakers">
                          <p>Speakers</p>
                          <div class="nv-sam-sessions-speakers-box">
                            <p class="person" *ngFor="let speaker of presentation.speakers">
                              <span>
                                <strong class="nv-sam-avatar" nvBackgroundImage [image]="speaker.image_url"></strong>
                                <em>{{ speaker.name }}</em>
                              </span>
                            </p>
                          </div><!-- /.nv-sam-sessions-speakers-box -->
                        </div><!-- /.nv-sam-sessions-speakers -->
                      </div><!-- /.nv-sam-detail-session -->
                      <div class="nv-sam-detail-materials">
                          <h4>Materials</h4>
                          <ul>
                            <li 
                              *ngFor="let material of presentation.materials" 
                              [class.presentation]="material.type === 'main'" 
                              [class.abstract]="material.type === 'abstract'" 
                              [class.webcast]="material.type === 'webcast'">
                              <a [routerLink]="['/presentation', presentation.id, 'material', material.id, material.type]">{{ material.title }}</a>
                            </li>
                          </ul>
                      </div><!-- /.nv-sam-detail-materials -->
                  </div><!-- /.nv-sam-details-layout -->
                  <!-- <div class="nv-sam-sub-meta">
                      <p>
                          <strong>16</strong>
                          <span>Liked</span>
                      </p>
                      <p>
                          <strong>122</strong>
                          <span>Added to timeline</span>
                      </p>
                      <p>
                          <strong>709</strong>
                          <span>Attendance</span>
                      </p>
                  </div> -->
              </div><!-- /.nv-sam-details-n-meta -->
          </td>
      </tr>
      </ng-container>
  </table><!-- /.nv-sam-table -->
</div><!-- /.nv-profile-settings-body-gutter -->