import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'nv-speaker-preview',
  templateUrl: 'speaker-preview.component.html'
})

export class SpeakerPreviewComponent implements OnInit {
  @Input() currentStream: any;
  @Input() hasAccess: boolean;
  @Input() conferenceId: number;
  @Output() accessRequested = new EventEmitter();


  constructor() { }

  ngOnInit() { }

  requestAccess() {
    this.accessRequested.emit();
  }

}