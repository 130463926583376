<div class="nv-modal small">
    <div class="nv-modal-header">
        <h4 class="nv-modal-title">Ticket type code</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body">
        <form [formGroup]="publicGroupForm" class="nv-row gap-30 propagate">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <nv-input
                            formControlName="name"
                            label="Name"
                    ></nv-input>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <nv-select
                            label="Join group automatically"
                            formControlName="autoJoin"
                            [options]="autoJoinOptions"
                    ></nv-select>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </form>
    </div>
    <div class="nv-modal-footer">
        <div class="nv-btn-box gap-10 flow-end">
            <nv-button size="small" color="neutral" (click)="close()">Cancel</nv-button>
            <nv-button size="small" color="primary" (click)="save()" [loading]="savingPublicGroup" [disabled]="publicGroupForm.invalid" translate>Save</nv-button>
        </div>
    </div>
</div>

