import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class PosterService {

  constructor(
    private apiService: ApiService
  ) { }

  getPosters(params: any = {}) {
    return this.apiService.get(`posters`, params);
  }

  getPoster(posterId: number, params: any = {}) {
    return this.apiService.get(`posters/${posterId}`, params);
  }

  updatePoster(posterId: number, params: any = {}) {
    return this.apiService.put(`posters/${posterId}`, params);
  }

  createPoster(params: any = {}) {
    return this.apiService.post(`posters`, params);
  }

  deletePoster(posterId: number) {
    return this.apiService.delete(`posters/${posterId}`);
  }

  getConferencePosters(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/posters`, params);
  }

  getConferencePoster(conferenceId: number, posterId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/posters/${posterId}`, params);
  }

  updateConferencePoster(conferenceId: number, posterId: number, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/posters/${posterId}`, params);
  }

  createConferencePoster(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/posters`, params);
  }

  deleteConferencePoster(conferenceId: number, posterId: number) {
    return this.apiService.delete(`conferences/${conferenceId}/posters/${posterId}`);
  }

  getPosterImages(posterId: number, params: any = {}) {
    return this.apiService.get(`posters/${posterId}/images`, params);
  }

  downloadPoster(posterId: number, params: any = {}) {
    return this.apiService.download(`posters/${posterId}/download`, `e_poster_${posterId}`, params);
  }

  getPosterHighlights(posterId: number, params: any = {}) {
    return this.apiService.get(`posters/${posterId}/highlights`, params);
  }

  addPosterHighlight(posterId: number, params: any = {}) {
    return this.apiService.post(`posters/${posterId}/highlights`, params);
  }

  removePosterHighlight(posterId: number, highlightId: number, params: any = {}) {
    return this.apiService.delete(`posters/${posterId}/highlights/${highlightId}`, params);
  }

}
