<section class="nv-section guttered">
    <div class="nv-port">
        <div class="nv-row spaced gap-12 flow-center">
            <div class="nv-column desk-12" *ngIf="presentation">
                <div class="nv-column-content">
                    <h2>{{ presentation.title }}</h2>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="nv-section guttered">
    <div class="nv-port">
        <div class="nv-row spaced-top small-spaced-bottom">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <div class="nv-line-tabs">
                        <nav class="nv-tabs-nav vertical-break-phab">
                            <ul>
                                <li (click)="selectTab('live')">
                                    <a [ngClass]="{'active': selectedTab === 'live' }">Live Enviroment</a>
                                </li>
                                <li (click)="selectTab('demo')">
                                    <a [ngClass]="{'active': selectedTab === 'demo' }">Demo enviroment</a>
                                </li>
                            </ul>
                        </nav><!-- /.nv-tabs-nav -->
                    </div><!-- /.nv-line-tabs -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</section><!-- /.nv-section -->

<main class="nv-section guttered">
    <div class="nv-port">
        <div class="nv-row spaced no-side-gap">
            <ng-container *ngIf="selectedTab === 'live'">
                <div class="nv-column desk-9">
                    <div class="nv-column-content">
                        <ng-container *ngIf="presentation">
                            <nv-meeting #meeting
                                    [buttonLabel]="'Join the Live presentation'"
                                    [roomName]="presentation.location?.id+'/LiveStudio'"
                                    [title]="'Live in studio'"
                                    [speakerName]="presentation?.speaker_name"
                                    [speakerImage]="presentation?.speaker_image"
                            ></nv-meeting>
                        </ng-container>
                    </div>
                </div>
                <div class="nv-column desk-3">
                    <div class="nv-column-content">
                        <h3>Messages</h3>
                        <table class="nv-data-table break-lap" *ngIf="messages.length > 0">
                            <tr *ngFor="let message of messages">
                                <td class="dt-cell">
                                    <div class="nv-qna-item">
                                        <div class="qi-content">
                                            <h3 class="nv-qna-question" [innerHTML]="message.content"></h3>
                                            <small class="nv-qna-timestamp">{{ message.created_at | date:'dd.MM.yyyy HH:mm' }}</small>
                                        </div><!-- /.qi-content -->
                                    </div><!-- /.nv-qna-item -->
                                </td><!-- /.dt-cell -->
                            </tr>
                        </table>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedTab === 'demo'">
                    <nv-meeting #meeting
                            [buttonLabel]="'Your demo studio'"
                            [roomName]="currentUser?.id+'/DemoStudio'"
                            [title]="'Live in demo studio'"
                            [speakerName]="presentation?.speaker_name"
                            [speakerImage]="presentation?.speaker_image"
                    ></nv-meeting>
            </ng-container>
        </div>
    </div>
</main>