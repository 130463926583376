<div class="nv-row gap-30 propagate" *ngIf="value">
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-input
                    type="number"
                    label="Limit"
                    [(ngModel)]="value.limit"
                    (ngModelChange)="onChange(value)"
            ></nv-input>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-input
                    label="Title"
                    [(ngModel)]="value.title"
                    (ngModelChange)="onChange(value)"
            ></nv-input>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-checkbox
                    label="Numbered"
                    [(ngModel)]="value.numbered"
                    (change)="onChange(value)"
            ></nv-checkbox>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-checkbox
                    label="Featured"
                    [(ngModel)]="value.featured"
                    (change)="onChange(value)"
            ></nv-checkbox>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-checkbox
                    label="Show presenting speakers only"
                    [(ngModel)]="value.speakers"
                    (change)="onChange(value)"
            ></nv-checkbox>
        </div>
    </div>
</div>
