export class Conference {
    id: number;
    name: string;
    location: string;
    starts_at: string;
    ends_at: string;
    type: string;
    tags: any;
    settings: any;
    banner_small: string;
    description: string;
    city: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
