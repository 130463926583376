import { Routes } from '@angular/router';

import { AuthQueryGuard } from './guards/auth-query.guard';
import { NavigationGuard } from './guards/navigation.guard';

import { WebsiteResolver } from './resolvers/website.resolver';
import { PageResolver } from './resolvers/page.resolver';

import { WebsiteComponent } from './website.component';
import { CustomPageComponent } from './pages/custom/custom-page.component';
import { SearchPageComponent } from './pages/search/search-page.component';
import { PresentationPageComponent } from './pages/presentation/presentation-page.component';
import { MaterialPageComponent } from './pages/material/material-page.component';
import { ProgramPageComponent } from './pages/program/program-page.component';
import { ArticleDetailsPageComponent } from './pages/article-details/article-details-page.component';
import { AuthGuard } from './guards/auth.guard';
import { NotFoundPageComponent } from './pages/not-found/not-found-page.component';
import { EditQueryGuard } from './guards/edit-query.guard';
import { ComingSoonPageComponent } from './pages/coming-soon/coming-soon-page.component';
import { SponsorListPageComponent } from './pages/sponsor-list/sponsor-list-page.component';
import { SponsorDetailsPageComponent } from './pages/sponsor-details/sponsor-details-page.component';
import { SpeakerListPageComponent } from './pages/speaker-list/speaker-list-page.component';
import { HubPageComponent } from './pages/hub/hub-page.component';
import { LiveStreamingPageComponent } from './pages/live-streaming/live-streaming-page.component';
import { StaticPageResolver } from './resolvers/static-page.resolver';
import { MyPresentationPageComponent } from './pages/my-presentation/my-presentation-page.component';
import { MyStudioPageComponent } from './pages/my-studio/my-studio-page.component';
import { MyStudioLocationPageComponent } from './pages/my-studio-location/my-studio-location-page.component';
import { AuthComponent } from './pages/auth/auth.component';
import { QAndAPageComponent } from './pages/q-and-a/q-and-a-page.component';
import { RegistrationRoutes } from './modules/registration/registration.routes';
import { ProfileRoutes } from './modules/profile/profile.routes';
import { LiveStageRoutes } from './modules/live-stage/live-stage.routes';
import { DelegateListPageComponent } from './pages/delegate-list/delegate-list-page.component';

export const WebsiteRoutes: Routes = [
  {
    path: '',
    component: WebsiteComponent,
    canActivate: [AuthQueryGuard, EditQueryGuard, NavigationGuard],
    resolve: {
      website: WebsiteResolver
    },
    children: [
      {
        path: ':pageId/page/:pageSlug',
        component: CustomPageComponent,
        resolve: {
          page: PageResolver
        }
      },
      {
        path: 'profile/login',
        component: AuthComponent,
        resolve: {
          page: StaticPageResolver
        },
        data: {
          title: 'Login'
        },
      },
      {
        path: 'profile/registration',
        component: AuthComponent,
        resolve: {
          page: StaticPageResolver
        },
        data: {
          title: 'Registration'
        },
      },
      {
        path: 'profile/registration/code',
        component: AuthComponent,
        resolve: {
          page: StaticPageResolver
        },
        data: { 
          title: 'Registration',
          registerWithCode: true 
        },
      },
      {
        path: 'search',
        component: SearchPageComponent,
        resolve: {
          page: StaticPageResolver
        },
        data: {
          title: 'Search'
        },
      },
      {
        path: 'posters',
        component: SearchPageComponent,
        resolve: {
          page: StaticPageResolver
        },
        data: { 
          title: 'Posters',
          mainType: 'Poster' 
        }
      },
      {
        path: 'program',
        component: ProgramPageComponent,
        resolve: {
          page: StaticPageResolver
        },
        data: {
          title: 'Program'
        }
      },
      {
        path: 'sponsors',
        component: SponsorListPageComponent,
        resolve: {
          page: StaticPageResolver
        },
        data: {
          title: 'Sponsors'
        }
      },
      {
        path: 'sponsor/:sponsorId',
        component: SponsorDetailsPageComponent,
        resolve: {
          page: StaticPageResolver
        },
        data: {
          title: 'Sponsor'
        }
      },
      {
        path: 'speakers',
        component: SpeakerListPageComponent,
        resolve: {
          page: StaticPageResolver
        },
        data: {
          title: 'Speakers'
        }
      },
      {
        path: 'delegates',
        component: DelegateListPageComponent,
        canActivate: [AuthGuard],
        resolve: {
          page: StaticPageResolver
        },
        data: {
          title: 'Delegates'
        }
      },
      {
        path: 'e-materials',
        component: HubPageComponent,
        resolve: {
          page: StaticPageResolver
        },
        data: {
          title: 'E-materials'
        }
      },
      {
        path: 'live-streaming',
        component: LiveStreamingPageComponent,
        resolve: {
          page: StaticPageResolver
        },
        data: {
          title: 'Live Streaming'
        }
      },
      {
        path: 'q-and-a/:locationId',
        component: QAndAPageComponent,
        resolve: {
          page: StaticPageResolver
        }
      },
      {
        path: 'presentation/:presentationId',
        component: PresentationPageComponent
      },
      {
        path: 'presentation/:presentationId/material/:materialId/main',
        component: MaterialPageComponent
      },
      {
        path: 'presentation/:presentationId/material/:materialId/webcast',
        component: MaterialPageComponent
      },
      {
        path: 'material/:materialId/main',
        component: MaterialPageComponent
      },
      {
        path: 'material/:materialId/webcast',
        component: MaterialPageComponent
      },
      {
        path: 'article/:articleId',
        component: ArticleDetailsPageComponent
      },
      {
        path: 'my/presentation/:presentationId',
        component: MyPresentationPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'my/studio',
        component: MyStudioPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'my/studio/:locationId',
        component: MyStudioLocationPageComponent,
        canActivate: [AuthGuard]
      },
      // Modules
      {
        path: 'profile',
        children: ProfileRoutes,
        canActivate: [AuthGuard]
      },
      {
        path: 'delegate-registration',
        children: RegistrationRoutes,
        canActivate: [AuthGuard]
      },
      {
        path: 'live-stage',
        children: LiveStageRoutes,
        canActivate: [AuthGuard]
      },
      {
        path: 'not-found',
        component: NotFoundPageComponent
      },
    ]
  },
  {
    path: 'coming-soon',
    component: ComingSoonPageComponent
  }
];
