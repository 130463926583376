import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Presentation } from '@navus/core/classes/presentation';
import { Material } from '@navus/core/classes/material';

@Component({
  selector: 'nv-livestream-materials',
  templateUrl: './livestream-materials.component.html',
})
export class LivestreamMaterialsComponent {
  @Input() currentPresentation: Presentation;
  @Input() currentPresentationMainMaterial: Material;
  @Input() materialsImages: { image: string, src: string }[];

  @Input() organizationId: number;
  @Input() conferenceId: number;
  @Input() locationId: number;

  @Output() refresh = new EventEmitter<void>();

  currentPage: number = 1;
  scale: number = 1;

  constructor(private router: Router) {
  }

  setPage(page: number) {
    this.currentPage = page;
  }

  openEmaterials() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/o', this.organizationId, 'conference', this.conferenceId, 'ematerials'])
    );

    window.open(url, '_blank');
  }

  refreshEmaterials() {
    this.refresh.emit();
  }
}
