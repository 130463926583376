import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ConferenceService } from '@navus/core/services/conference.service';
import { ChatService } from '@navus/core/services/chat.service';
import { Room } from '@navus/core/classes/chat';
import { map, mergeMap, take } from 'rxjs/operators';
import { PublicGroupModalComponent } from '../../modals/ticket-type-code/public-group-modal.component';

@Component({
  selector: 'main [nv-chat-settings-page]',
  templateUrl: './chat-settings-page.component.html'
})
export class ChatSettingsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;

  publicGroups: Room[];

  chatSettingsForm: FormGroup;
  pagesWhereToShowChat = [
    { id: 'all', name: 'All pages' },
    { id: 'live-stage', name: 'Live stage only' }
  ];

  loadingChatSettings: boolean = true;
  savingChatSettings: boolean = false;
  unsavedChanges: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private modalService: ModalService,
    private conferenceService: ConferenceService,
    private chatService: ChatService,
  ) {
  }

  ngOnInit() {
    this.initForm();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getChatSettings();
        this.getPublicGroups();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.chatSettingsForm = this.formBuilder.group({
      enabled: [false],
      pages: ['all'],
      allow1on1: [false],
      allowGroupChat: [false]
    });
  }

  getChatSettings() {
    this.loadingChatSettings = true;
    this.conferenceService
      .getOrganizationConference(this.organizationId, this.conferenceId)
      .subscribe(
        (response) => {
          const conference = response.data;
          this.chatSettingsForm.patchValue(conference.settings?.chat_settings);
          this.loadingChatSettings = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.loadingChatSettings = false;
        }
      );
  }

  save() {
    if (this.chatSettingsForm.invalid) {
      return;
    }

    this.savingChatSettings = true;
    const params = { settings: { chat_settings: this.chatSettingsForm.value } };
    this.conferenceService
      .updateOrganizationConference(this.organizationId, this.conferenceId, params)
      .subscribe(
        () => {
          this.toastService.success('Chat settings successfully saved.', {});
          this.unsavedChanges = false;
          this.savingChatSettings = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.savingChatSettings = false;
        }
      );
  }

  getPublicGroups() {
    if (!this.conferenceId) {
      return;
    }

    this.chatService
      .getAllPublicRooms(this.conferenceId)
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.publicGroups = response;
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }

  addGroup() {
    const modalRef = this.modalService.open(PublicGroupModalComponent);
    modalRef.componentInstance.conferenceId = this.conferenceId;
    modalRef.result.then(
      () => {
        this.getPublicGroups();
      },
      () => {
      }
    );
  }

  editGroup(publicGroup: Room) {
    const modalRef = this.modalService.open(PublicGroupModalComponent);
    modalRef.componentInstance.conferenceId = this.conferenceId;
    modalRef.componentInstance.publicGroup = publicGroup;
    modalRef.result.then(
      () => {
        this.getPublicGroups();
      },
      () => {
      }
    );
  }

}
