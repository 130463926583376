import { Component } from '@angular/core';

@Component({
    selector: 'app-live-studio-page',
    templateUrl: './live-studio.component.html'
})
export class LiveStudioComponent {

    constructor() { }

}
