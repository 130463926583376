import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Bookmark } from '../classes/bookmark';
import { Presentation } from '../classes/presentation';

@Injectable()
export class BookmarkService {
  private bookmarksSubject = new BehaviorSubject<Bookmark[]>([] as Bookmark[]);
  public bookmarks = this.bookmarksSubject.asObservable();

  constructor(
    private apiService: ApiService
  ) { }

  getWebsiteBookmarks(websiteId: number, loggedIn: boolean) {
    if (loggedIn) {
      return this.apiService.get(`websites/${websiteId}/bookmarks`, { with_pagination: 0 })
        .pipe(map(
          (bookmarks: { data: Bookmark[] }) => {
            this.bookmarksSubject.next(bookmarks.data);
            return bookmarks.data;
          }
        ));
    } else {
      return of([]);
    }
  }

  addWebsiteBookmark(websiteId: number, presentation: Presentation) {
    return this.addWebsiteBookmarks(websiteId, [presentation]);
  }

  addWebsiteBookmarks(websiteId: number, presentations: Presentation[]) {
    const createdAt = (new Date()).toISOString().substr(0, 19).replace('T', ' ');
    const bookmarks: Bookmark[] = [];

    for (const presentation of presentations) {
      bookmarks.push({
        presentation_id: presentation.id,
        block_id: presentation.block_id,
        created_at: createdAt
      });
    }

    return this.apiService.post(`websites/${websiteId}/bookmarks`, { bookmarks: bookmarks })
      .pipe(tap(
        () => {
          this.bookmarksSubject.next(this.bookmarksSubject.value.concat(bookmarks));
        }
      ));
  }

  removeWebsiteBookmark(websiteId: number, presentation: Presentation) {
    return this.removeWebsiteBookmarks(websiteId, [presentation]);
  }

  removeWebsiteBookmarks(websiteId: number, presentations: Presentation[]) {
    const bookmarks: any[] = [];

    for (const presentation of presentations) {
      bookmarks.push({
        presentation_id: presentation.id,
        block_id: presentation.block_id,
        is_deleted: 1
      });
    }

    return this.apiService.put(`websites/${websiteId}/bookmarks`, { bookmarks: bookmarks })
      .pipe(tap(
        () => {
          this.bookmarksSubject.next(
            this.bookmarksSubject.value.filter(b => !bookmarks.find(a => a.presentation_id === b.presentation_id))
          );
        }
      ));
  }

  sync() {

  }

}
