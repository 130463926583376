import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Presentation } from '@navus/core/classes/presentation';
import { PresentationService } from '@navus/core/services/presentation.service';
import { UserService } from '@navus/core/services/user.service';
import { User } from '@navus/core/classes/user';
import { WebsiteService } from '@navus/core/services/website.service';
import { Website } from '@navus/core/classes/website';
import { ConferenceService } from '@navus/core/services/conference.service';
import { Conference } from '@navus/core/classes/conference';
import { MaterialService } from '@navus/core/services/material.service';
import { CourseService } from '@navus/core/services/course.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { LoadingService } from '@navus/ui/loading/loading.service';
import { ConferenceAccessModalComponent } from '../../modals/conference-access/conference-access-modal.component';
import { BlockService } from '@navus/core/services/block.service';
import { Block } from '@navus/core/classes/block';
import { AuthorModalComponent } from '../../modals/author/author-modal.component';

@Component({
  selector: 'nv-presentation-page',
  templateUrl: './presentation-page.component.html'
})
export class PresentationPageComponent implements OnInit, OnDestroy {
  presentationId: number;
  presentation: Presentation;
  website: Website;
  block: Block;
  blockPresentations: Presentation[] = [];
  speakerPresentations: Presentation[] = [];
  presentationsRecommended: Presentation[] = [];
  showMoreBio: boolean = true;
  presentationVote: any = null;
  loadingVote: boolean = false;
  chatMode: string = 'expand';
  chatTab: string = 'mine';
  chatTabPublicValue: string = 'true';
  currentUser: User;
  currentDelegate: any;
  speakerUserIds: number[] = [];
  statusOptions: any = [
    { id: 'all', name: 'All' },
    { id: 'true', name: 'Approved' },
    { id: 'false', name: 'Rejected' },
  ];
  approveStatus: string = 'all';


  conference: Conference;

  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private conferenceService: ConferenceService,
    private websiteService: WebsiteService,
    private blockService: BlockService,
    private presentationService: PresentationService,
    private materialService: MaterialService,
    private courseService: CourseService,
    private userService: UserService,
    private modalService: ModalService,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    // TODO SEO
    const subscription = this.route.params.subscribe(
      (data: any) => {
        this.presentationId = +data.presentationId;
        this.getPresentation();
        // this.getRecommendedPresentations();
        this.websiteService.currentWebsite
            .subscribe(
              (website: Website) => {
                this.website = website;
              },
              () => { }
            );
      }
    );
    this.subscriptions.push(subscription);

    this.userService.currentUser.subscribe(
      (user: User) => {
        this.currentUser = user;
        this.getCurrentDelegate();
      }
    );

  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getCurrentDelegate() {
    if(this.currentUser && this.currentUser.delegates.length>0) {
        this.currentDelegate = this.currentUser.delegates[0];
    }
    return;
  }

  getAccess() {
    if (!this.currentUser) {
      this.modalService.defaultModal({
        title: 'Not logged in',
        body: 'You need to be logged in to continue',
        buttons: [
          {
            text: 'Cancel',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Log in',
            handler: () => {
              this.router.navigate(['/profile/login'], { queryParams: { returnUrl: this.router.url } });
            }
          },
        ]
      });
      return;

    } else if (!this.currentDelegate) {
      if (this.conference.settings.delegate_registration ? this.conference.settings.delegate_registration.ticketing : this.conference.settings.ticket?.enabled) {
        this.router.navigate(['/delegate-registration']);
      } else {
        this.modalService.defaultModal({
          title: 'No access',
          body: "You are not registered as a delegate for this event. Based on your current access rights, you don't have permission to access this content. Please contact organizer if you think that this information is not correct."
          });
      }
    } else if (this.currentDelegate) {
        this.modalService.defaultModal({
        title: 'No access',
        body: "Based on your current access rights, you don't have permission to view this content. Please contact support if you think that this information is not correct."
        });
    }
  }

  getPresentation() {
    this.loadingService.start('presentation', 'fullPage');
    this.presentationService.getPresentation(this.presentationId, {
      include: 'speakers,accessible_materials,vote'
    })
      .subscribe(
        (response) => {
          this.presentation = response.data;
          this.presentationVote = this.presentation.vote;
          this.getBlock();
          this.getBlockPresentations();
          this.getSpeakerPresentations();
          this.loadingService.stop('presentation');
          this.conference = this.website?.conferences?.find(c => c.id === this.website.active_conference_id);
          this.speakerUserIds = this.presentation.speakers.map(speaker => speaker.user_id);
          this.chatTabPublicValue = (this.presentation.chat && this.presentation.chat.moderation) ? "true" : "all"
        },
        () => {
          this.loadingService.stop('presentation');
        }
      );
  }

  getBlock() {
    if (!this.presentation.block_id) { return; }

    this.blockService
      .getConferenceBlock(this.presentation.conference_id, this.presentation.block_id, {
        include: 'chairpersons'
      })
      .subscribe(
        (response) => {
          this.block = response.data;
        },
        () => { }
      );
  }

  getBlockPresentations() {
    if (!this.presentation.block_id) { return; }

    this.presentationService
      .getConferencePresentations(this.presentation.conference_id, { block_id: this.presentation.block_id, with_pagination: 0 })
      .subscribe(
        (response: { data: Presentation[], meta: any }) => {
          this.blockPresentations = response.data;
        },
        () => { }
      )
  }

  getSpeakerPresentations() {
    if (this.presentation.speakers.length === 0) { return; }

    this.presentationService
      .getConferencePresentations(this.presentation.conference_id, { speaker_id: this.presentation.speakers[0].id, with_pagination: 0 })
      .subscribe(
        (response: { data: Presentation[], meta: any }) => {
          this.speakerPresentations = response.data;
        },
        () => { }
      )
  }

  getRecommendedPresentations() {
    this.loadingService.start('presentation', 'fullPage');
    this.presentationService
      .getRecommendations({ limit: 3 })
      .subscribe(
        (response: { data: Presentation[], meta: any }) => {
          // Hiding current presentation

          const presentationsRecommended = [];

          for (const presentation of response.data) {
            if (presentation.id !== this.presentationId) {
              presentationsRecommended.push(presentation);
            }
          }

          this.presentationsRecommended = presentationsRecommended.slice(0, 2);
          this.loadingService.stop('presentation');
        },
        () => {
          this.loadingService.stop('presentation');
        }
      );
  }

  handleMaterial(material) {
    if (!this.currentUser) {
      this.modalService.defaultModal({
        title: 'Not logged in',
        body: 'You need to be logged in to continue',
        buttons: [
          {
            text: 'Cancel',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Log in',
            handler: () => {
              this.router.navigate(['/profile/login'], { queryParams: { returnUrl: this.router.url } });
            }
          },
        ]
      });
      return;
    } else if (!this.currentDelegate) {
      

      if (this.conference.settings.delegate_registration ? this.conference.settings.delegate_registration.enabled : this.conference.settings.ticket?.create_delegate) {
        this.router.navigate(['/delegate-registration']);
      } else {
        this.modalService.defaultModal({
          title: 'No access',
          body: "You are not registered as a delegate for this event. Based on your current access rights, you don't have permission to access this content. Please contact organizer if you think that this information is not correct."
        });
      }
    } else if (this.currentDelegate) {
      if (this.conference.settings.delegate_registration ? this.conference.settings.delegate_registration.ticketing : this.conference.settings.ticket?.enabled) {
        const params: any = {
          with_pagination: 0,
          available_for_user: 1
        };

        this.blockService
            .getConferenceBlocks(this.presentation.conference_id, params)
            .subscribe(
              (response) => {
                const availableBlocks = response.data;
                if (availableBlocks.find(b => b.id === this.presentation.block_id)) {
                  this.openMaterial(material);
                } else {
                  this.modalService.defaultModal({
                    title: 'No access',
                    body: "Based on your current access rights, you don't have permission to view this content. Please contact support if you think that this information is not correct."
                  });
                }
              },
              () => {}
          );
      } else {
        this.getMaterialAccess(material);
      }
    }
  }

  getMaterialAccess(material) {
    this.conferenceService.requestAccess(this.presentation.conference_id)
      .subscribe(
        (response: any) => {
          const userAccess = response.data || response.meta;
          if (userAccess.e_points === -1) {
            this.conferenceService.grantAccess(this.presentation.conference_id)
              .subscribe(() => {
                material.accessible = true;
                this.openMaterial(material);
              });
          } else if (userAccess.e_points === 0) {
            const modalRef = this.modalService.open(ConferenceAccessModalComponent);
            modalRef.componentInstance.points = userAccess.e_points;
            modalRef.componentInstance.conferenceId = this.presentation.conference_id;
            modalRef.componentInstance.text = `You don\'t have any access points. \n` +
              `To view this presentation you need to enter the access code or a badge number.`;
            modalRef.result.then(
              () => {
                material.accessible = true;
                this.openMaterial(material);
              },
              () => { }
            );
          } else {
            const modalRef = this.modalService.open(ConferenceAccessModalComponent);
            modalRef.componentInstance.points = userAccess.e_points;
            modalRef.componentInstance.conferenceId = this.presentation.conference_id;
            modalRef.componentInstance.text = `As a ` + userAccess.role + ` you can have ` + userAccess.e_points +
              ` amount of free presentations.`;
            modalRef.result.then(
              () => {
                material.accessible = true;
                this.openMaterial(material);
              },
              () => { }
            );
          }
        },
        (error) => {
          if (error.status === 412) {
            const modalRef = this.modalService.open(ConferenceAccessModalComponent);
            modalRef.componentInstance.conferenceId = this.presentation.conference_id;
            modalRef.componentInstance.text = `To view this presentation you need to enter the access code or a badge number.`;
            modalRef.result.then(
              () => {
                material.accessible = true;
                this.openMaterial(material);
              },
              () => { }
            );
          }
        }
      );
  }

  openMaterial(material) {
    switch (material.type) {
      case 'main':
        this.router.navigate(['/presentation', this.presentationId, 'material', material.id, 'main']);
        break;
      case 'supporting':
        this.materialService.downloadMaterial(material.id).subscribe();
        break;
      case 'webcast':
        this.router.navigate(['/presentation', this.presentationId, 'material', material.id, 'webcast']);
        break;
      default:
        this.router.navigate(['/presentation', this.presentationId, 'material', material.id, 'main']);
        break;
    }
  }

  downloadCertificate(courseId: number) {
    this.courseService.downloadCertificate(courseId)
      .subscribe(() => {});
  }

  downloadAbstract(presentationId: number) {
    this.presentationService.downloadAbstract(presentationId)
      .subscribe(() => {});
  }

  toogleVote() {
    this.loadingVote = true;
    if (this.presentationVote) {
      this.presentationService
          .deletePresentationVote(this.presentationId)
          .subscribe(
            (response) => {
              this.presentationVote = null;
              this.loadingVote = false;
            },
            () => {
              this.loadingVote = false;
            }
          );
    } else {
      this.presentationService
          .createPresentationVote(this.presentationId, { value: true })
          .subscribe(
            (response) => {
              this.presentationVote = response.data;
              this.loadingVote = false;
            },
            () => {
              this.loadingVote = false;
            }
          );
    }
  }

  showAuthor(author) {
    const modalRef = this.modalService.open(AuthorModalComponent);
    modalRef.componentInstance.author = author;
  }

  expandBio() {
      this.showMoreBio = !this.showMoreBio;
  }

  showMeeting = false;
  presentationMeetingRoom;

  meetingJoined() {
    this.showMeeting = true;
  }

  meetingClosed() {
    this.showMeeting = false;
  }

  meetingRoomChange(meetingRoom) {
    this.presentationMeetingRoom = meetingRoom;
  }

}
