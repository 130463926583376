export class LeadlinkCampaign {
  id: number;
  name: string;
  description: string;
  color: string;
  logo: string;

  conference_id: number;
  organization_id: number;
}
