<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Dashboard</h1>
        </div><!-- /.tnt-left -->
    </div>
</div>
<div class="nv-config-content-port">
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row gap-24 spaced">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h3>Coming soon</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
