import { ModuleWithProviders, NgModule } from '@angular/core';
import { ArticleService } from './services/article.service';
import { ConferenceService } from './services/conference.service';
import { OrganizationService } from './services/organization.service';
import { WebsitePageService } from './services/website-page.service';
import { PresentationService } from './services/presentation.service';
import { SEOService } from './services/seo.service';
import { TwitterService } from './services/twitter.service';
import { WebsiteService } from './services/website.service';
import { UserService } from './services/user.service';
import { DelegateService } from './services/delegate.service';
import { AuthorService } from './services/author.service';
import { LocationService } from './services/location.service';
import { BlockCategoryService } from './services/block-category.service';
import { BlockService } from './services/block.service';
import { ApplicationService } from './services/application.service';
import { CountryService } from './services/country.service';
import { Country } from './classes/country';
import { SearchService } from './services/search.service';
import { MaterialService } from './services/material.service';
import { CourseService } from './services/course.service';
import { ResourceService } from './services/resource.service';
import { BookmarkService } from './services/bookmark.service';
import { CompanyService } from './services/company.service';
import { SponsorGroupService } from './services/sponsor-group.service';
import { PosterService } from './services/poster.service';
import { VotingService } from './services/voting.service';
import { LiveSuiteService } from './services/live-suite.service';
import { LeadlinkService } from './services/leadlink.service';
import { BannerService } from './services/banner.service';
import { ContactService } from './services/contact.service';
import { PackageService } from './services/package.service';
import { BillingService } from './services/billing.service';
import { NotificationService } from './services/notification.service';
import { TrackingService } from './services/tracking.service';
import { ChatService } from '@navus/core/services/chat.service';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../../environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { JitsiService } from '@navus/core/services/jitsi.service';
import { AuthService } from './services/auth.service';
import { TemplateService } from './services/template.service';
import { CertificateService } from './services/certificate.service';
import { MeetingRoomService } from './services/meeting-room.service';
import { PublicChatService } from '@navus/core/services/public-chat.service';
import { TimezoneService } from './services/timezone.service';
import { VideoPlayerPresetService } from '@navus/core/services/video-player-preset.service';
import { BadgeService } from './services/badge.service';
import { InactivityService } from './services/inactivity.service';

@NgModule({
  imports: [
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule
  ],
  declarations: [],
  exports: []
})
export class NavusCoreModule {
  public static forRoot() {
    return {
      ngModule: NavusCoreModule,
      providers: [
        AuthService,
        ArticleService,
        ConferenceService,
        OrganizationService,
        WebsitePageService,
        PresentationService,
        SEOService,
        TwitterService,
        WebsiteService,
        UserService,
        DelegateService,
        AuthorService,
        LocationService,
        TemplateService,
        BlockCategoryService,
        BlockService,
        ApplicationService,
        ResourceService,
        CountryService,
        SearchService,
        MaterialService,
        CourseService,
        BookmarkService,
        CompanyService,
        SponsorGroupService,
        PosterService,
        VotingService,
        PosterService,
        LiveSuiteService,
        LeadlinkService,
        BannerService,
        ContactService,
        PackageService,
        BillingService,
        NotificationService,
        TrackingService,
        ChatService,
        PublicChatService,
        JitsiService,
        CertificateService,
        MeetingRoomService,
        TimezoneService,
        VideoPlayerPresetService,
        BadgeService,
        InactivityService,
      ],
      imports: [],
      exports: [
        Country
      ]
    };
  }

  public static forChild(): ModuleWithProviders<NavusCoreModule> {
    return {
      ngModule: NavusCoreModule
    };
  }
}
