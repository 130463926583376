import { Component, Input, OnDestroy, OnInit, ViewChild, NgZone } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription, timer } from 'rxjs';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { User } from '@navus/core/classes/user';
import { UserService } from '@navus/core/services/user.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { LocationService } from '@navus/core/services/location.service';
import { LiveSuiteIframeSectionConfig } from './live-suite-iframe-section-config';
import { ConferenceService } from '@navus/core/services/conference.service';
import { BaseSectionComponent } from '../_base/base-section.component';
import { WebsiteService } from '@navus/core/services/website.service';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { WebsiteControlService } from '../../services/website-control.service';
import { DelegateService } from '@navus/core/services/delegate.service';
import { NavusMeetingComponent } from '@navus/ui/meeting/meeting.component';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { VotingService } from '@navus/core/services/voting.service';
import { VotingQuestion } from '@navus/core/classes/voting-question';
import { ConferenceAccessModalComponent } from '../../modals/conference-access/conference-access-modal.component';
import { Block } from '@navus/core/classes/block';
import { BlockService } from '@navus/core/services/block.service';
import { BannerService } from '../../../navus-core/services/banner.service';
import { AuthorModalComponent } from '../../modals/author/author-modal.component';

@Component({
  selector: 'nv-live-suite-iframe-section',
  templateUrl: './live-suite-iframe-section.component.html',
  styleUrls: ['live-suite-iframe-section.component.scss']
})

export class LiveSuiteIframeSectionComponent extends BaseSectionComponent implements OnInit, OnDestroy {
  @ViewChild('meeting') meeting: NavusMeetingComponent;

  @Input() data: WebsitePageSection;
  @Input() previewMode: boolean = true;
  @Input() slug: string = null;
  @Input() title: string;

  // to read from settings
  controlAccess = false;
  availableBlocks: Block[] = [];

  subscriptions: Subscription[] = [];

  loadingStream: boolean;

  timerInterval: number = 6000;
  locations: any[] = [];
  currentLocation: any;
  currentUser: User;
  currentDelegate: any;
  currentVotingQuestion: VotingQuestion = null;

  bannerPosition = 'Stream';
  banner: any;
  bannerSettings: any = {
   configuration: {
     position: this.bannerPosition
   }
  };
  messages: any[] = [];

  streamingURL: any;

  counter: { min: number, sec: number }

  configuration: LiveSuiteIframeSectionConfig = new LiveSuiteIframeSectionConfig();
  interval;

  blocks: Block[] = [];
  selectedBlock: Block;
  autoSelectBlock: boolean = true;
  chatMode: string = 'minimal';
  chatTab: string = 'true';
  // chatTab: string = 'mine';
  qaSettings: { enabled: boolean, public_tab: boolean, moderation: boolean} = null;
  usingTickets: boolean = false;

  allowJoinBreakout: boolean = false;

  slideLiveSuiteConfig = {
    'slidesToShow': 4,
    'autoplay': false,
    'slidesToScroll': 4,
    'infinite': false,
    'dots': false,
    'centerMode': false,
    'adaptiveHeight': true,
    'arrows': true,
    'prevArrow': '<a class="er-prev"></a>',
    'nextArrow': '<a class="er-next"></a>',
    responsive: [
        {
            breakpoint: 1370,
            settings: {
                slidesToShow: 4
            }
        },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  slideLiveSuiteConfigOne = {
    'slidesToShow': 1,
    'autoplay': false,
    'slidesToScroll': 1,
    'infinite': false,
    'dots': false,
    'centerMode': false,
    'adaptiveHeight': true,
    'arrows': false,
    'prevArrow': '<a class="er-prev"></a>',
    'nextArrow': '<a class="er-next"></a>',
    responsive: [
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  hasAccess: boolean = false;

  private LaravelEcho: Echo;

  selectedTab: string = 'info';

  currentTimestamp: number;
  currentBlockTimestamp: number;

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService,
    private locationService: LocationService,
    private delegateService: DelegateService,
    private conferenceService: ConferenceService,
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private zone: NgZone,
    private votingService: VotingService,
    private blockService: BlockService,
    private bannerService: BannerService,
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );

  }

  ngOnInit() {
    this.configuration = new LiveSuiteIframeSectionConfig(this.data.configuration);

    this.controlAccess = this.configuration.conferenceId===15578;

    window['Pusher'] = Pusher;
    window['Echo'] = new Echo({
        broadcaster: 'pusher',
        key: `${environment.mix_pusher_app_key}`,
        wsHost: `${environment.mix_pusher_host}`,
        wsPort: `${environment.mix_pusher_port}`,
        wssPort: `${environment.mix_pusher_port}`,
        forceTLS: `${environment.mix_pusher_port === 443}`,
        encrypted: true,
        disableStats: true,
        authEndpoint: `${environment.api_url}` + 'broadcasting/auth',
        auth: {headers: {'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')}},
        enabledTransports: ['ws', 'wss']
    });
    this.LaravelEcho = window['Echo'];

    const userSubscription = this.userService.currentUser
      .subscribe(
        (user: User) => {
          this.currentUser = user;

          if (user) {
            this.getCurrentDelegate();
            this.getMessages();
          }
          this.checkAccess();
        }
      );
    this.subscriptions.push(userSubscription);

    const conferenceSubscription = this.conferenceService.getConference(this.configuration.conferenceId)
      .subscribe(
        (result) => {
          this.qaSettings = result?.data?.settings?.qa_settings;
          this.usingTickets = (result?.data?.settings?.delegate_registration?.ticketing === true);
          if (!this.qaSettings?.public_tab) {
            this.chatTab = 'mine';
          }
          
          this.showInstructions(result.data);
        }
      );
    this.subscriptions.push(conferenceSubscription);

    this.getLocations();

    this.runSocket();

    const timerSubscription = timer(0, 5000)
      .subscribe(
        () => {
          this.currentTimestamp = new Date().getTime();
          if (this.currentLocation && this.currentLocation.currentPresentation) {
            const currentBlock = this.blocks.find(block => block.id === this.currentLocation.currentPresentation.block_id);
            if (currentBlock) {
              this.currentBlockTimestamp = new Date(currentBlock.starts_at).getTime();
            } else {
              this.currentBlockTimestamp = null;
            }
          }
        }
      )
    this.subscriptions.push(timerSubscription);
  }

  ngOnDestroy() {
    this.LaravelEcho.disconnect();
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    clearInterval(this.interval);
  }

  showInstructions(conference) {
    if(this.configuration.conferenceId!==14343) {
      return;
    }
    const isShown = localStorage.getItem('isShown-LivestreamInstructions');
    if (!isShown) {
      this.modalService.defaultModal({
        title: `${conference.name} Conference has multiple parallel streaming channels.`,
        size: 'medium',
        body: `
          <p>To switch to another channel, scroll down the page and click on the one you’d like to watch.</p>
          <p><img src="/assets/choose-a-channel-instruction.jpg" /></p>
        `,
        buttons: [{
          text: 'Ok, got it!',
          role: 'cancel'
        }]
      })
      localStorage.setItem('isShown-LivestreamInstructions', 'true');
    }
  }

  runSocket() {
    this.LaravelEcho
      .channel('location.current_conference_presentation.' + this.configuration.conferenceId)
      .listen('.location.changed_current_presentation',
        (response: any) => {
            if (response.data[0].id) {
                const changedLocation = response.data[0];
                if (changedLocation.streaming_presentations && changedLocation.streaming_presentations.length > 0) {
                    this.setCurrentPresentationOnLocation(changedLocation);
                }
            }
        }
      );
  }

  getLocations() {
    this.locationService
      .getConferenceLocations(this.configuration.conferenceId, { active_streaming: 1, include: 'streaming_presentations' })
      .subscribe(
        (response) => {
          this.setupLocations(response.data);
        },
        () => { }
      );
  }

  setupLocations(locations: any) {
    
    for (let location of locations) {
      if(location.streaming_presentations.length>0) {
        this.locations.push(this.prepareStreamingLocation(location));
      }
    }
  }

  getLocationBlocks() {
    if (!this.currentLocation) { return; }
    const params: any = {
      location_id: this.currentLocation.id,
      with_pagination: 0
    };

    if (this.controlAccess) {
      params.available_for_user=1;
    }
    this.blockService
        .getConferenceBlocks(this.currentLocation.conference_id, params)
        .subscribe(
          (response) => {
            this.blocks = response.data
              .sort((a: Block, b: Block) => a.starts_at > b.starts_at ? -1 : 1)
              .map(block => ({
                ...block,
                startTimestamp: new Date(block.starts_at).getTime()
              }));

            this.blocks
                .forEach((block) => {
                  if (block.id === this.currentLocation.currentPresentation.block_id) {
                    this.selectBlock(block);
                  }
                });
          },
          () => {}
        );
  }

  selectBlock(block: Block) {
    this.selectedBlock = block;
  }

  prepareStreamingLocation(location: any) {
      location.currentPresentation = location.streaming_presentations[0] ? location.streaming_presentations[0] : null;

      location.nextPresentation = null;
      if (location.streaming_presentations.length > location.streaming_presentations.indexOf(location.currentPresentation) + 1) {
        location.nextPresentation = location.streaming_presentations[location.streaming_presentations.indexOf(location.currentPresentation) + 1];
      }
//      if currentLocation never existed
      if (location.currentPresentation && !this.currentLocation) {
        this.currentLocation = location;
        this.getLocationBlocks();
        this.setStreamingURL();
      } else if (this.currentLocation && this.currentLocation.id === location.id && JSON.stringify(this.currentLocation.currentPresentation) !== JSON.stringify(location.currentPresentation) ) {
        this.currentLocation = location;
        this.getLocationBlocks();
        this.setStreamingURL();
      }
      return location;
  }

  setStreamingURL(shouldCheckAccess:boolean=true){
    this.loadingStream = true;
    if (this.currentLocation && this.currentLocation.currentPresentation) {

        const presentation = this.currentLocation.currentPresentation;
        if (shouldCheckAccess) {
          this.checkAccess();

          if(this.hasAccess === false) {
              this.streamingURL = null;
              this.loadingStream = false;
              return;
          }
        } 
        
        console.log(this.currentLocation);

        if (presentation.webcast && presentation.webcast.external_url) {
          // finally set the source stream
          let url = this.sanitizer.bypassSecurityTrustResourceUrl(presentation.webcast.external_url);
          if (JSON.stringify(url) !== JSON.stringify(this.streamingURL)) {
              this.streamingURL = url;
          }
        } else {
            let url = this.sanitizer.bypassSecurityTrustResourceUrl(this.currentLocation.stream_url);
            if (JSON.stringify(url) !== JSON.stringify(this.streamingURL)) {
                this.streamingURL = url;
            }
        }
      
      // let url = this.sanitizer.bypassSecurityTrustResourceUrl(this.currentLocation.stage_url);
      // if (JSON.stringify(url) !== JSON.stringify(this.streamingURL)) {
      //     this.streamingURL = url;
      // }
        this.loadingStream = false;
        this.getPresentationVotings();
    }
  }

  setCurrentPresentationOnLocation(newLocation: any) {
    const newPresentations = newLocation.streaming_presentations;
    this.selectedTab = 'info';
    for (let location of this.locations) {
        if (newLocation.id === location.id) {
            if (JSON.stringify(newLocation.stream_url) === JSON.stringify(location.stream_url)) {

                location.currentPresentation = newPresentations[0];
                location.nextPresentation = newPresentations[1] ? newPresentations[1] : null;
            } else {
                location = this.prepareStreamingLocation(newLocation);
            }
            if (!this.currentLocation || (this.currentLocation.id == location.id && JSON.stringify(this.currentLocation.stream_url) !== JSON.stringify(location.stream_url))) {
                this.currentLocation = location;
                this.getLocationBlocks();
                this.setStreamingURL();
            } else if (this.currentLocation.id == location.id && JSON.stringify(this.currentLocation.stream_url) === JSON.stringify(location.stream_url)) {
                this.currentLocation.currentPresentation = location.currentPresentation;
                this.setStreamingURL();
                //start aut oblock
                if (this.autoSelectBlock) {
                  this.blocks
                  .forEach((block) => {
                    if (block.id === this.currentLocation.currentPresentation.block_id) {
                      this.selectBlock(block);
                    }
                  });
                }
            }

            return;
        }
    }
    this.locations.push(this.prepareStreamingLocation(newLocation));
  }

  getCurrentDelegate() {
      if(this.currentUser && this.currentUser.delegates.length>0) {
          this.currentDelegate = this.currentUser.delegates[0];
      }
      return;
  }

  getMessages() {
    if (!this.currentUser) { return; }

    const params = {
      sort_by: 'created_at',
      sort_direction: 'desc'
    };

    this.conferenceService
      .getConferenceMessagesMine(this.configuration.conferenceId, params)
      .subscribe(
        (response) => {
          this.messages = response.data;
        },
        () => { }
      );
  }

  joinTheRoom() {
    this.allowJoinBreakout = true;
  }

  onVideoClick() {
    if (!this.currentUser) {
      this.modalService.defaultModal({
        title: 'Not logged in',
        body: 'You need to be logged in to continue',
        buttons: [
          {
            text: 'Cancel',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Log in',
            handler: () => {
              this.router.navigate(['/profile/login'], { queryParams: { returnUrl: this.router.url } });
            }
          },
        ]
      });
      return;

    } else if (!this.currentDelegate) {
      if (this.controlAccess) {
        this.promptCode();
      } else {
        this.router.navigate(['/delegate-registration']);
      }
    } else if (this.currentDelegate) {
        if (!this.currentUser.member && this.currentLocation.currentPresentation.min_access_right == 'member') {
          this.promptCode();
        } else if((this.currentLocation.currentPresentation.block_id === 25821 || 
            (this.currentLocation.currentPresentation.block_id === 25823 && !this.isPfizerDelegate(this.currentDelegate.id))) && 
            !this.currentUser.hcp) {
        
          this.hcpCheck(this.currentLocation.currentPresentation.block_id);
        } else {
          this.modalService.defaultModal({
            title: 'No access',
            body: "Based on your current access rights, you don't have permission to view this content. Please contact support if you think that this information is not correct."
          });
        }
    }
  }

  onClickStream(location) {
    this.currentLocation = null;
    this.allowJoinBreakout = false;

    const timerSubscription: Subscription = timer(0, 10).subscribe(
      () => {
        this.currentLocation = location;
        this.getLocationBlocks();

        this.setStreamingURL();
        const element = document.getElementById('scroll-anchor');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
        timerSubscription.unsubscribe();
      }
    );
  }

  votingListener(){
    this.LaravelEcho
    .channel('presentation.' + this.currentLocation.currentPresentation.id)
    .listen('.voting.started', (votingQuestion) => {
      this.zone.run(() => {
        this.currentVotingQuestion = votingQuestion;
        this.currentVotingQuestion.active = true;
        this.currentVotingQuestion.finished = false;
        this.selectedTab = 'voting';
      });
    })
    .listen('.voting.finished', (votingQuestion) => {
      this.zone.run(() => {
        this.currentVotingQuestion = votingQuestion;
        this.currentVotingQuestion.active = false;
        this.currentVotingQuestion.finished = true;

        if (localStorage.getItem('votingID_' + this.currentVotingQuestion.id)) {
            this.currentVotingQuestion.user_answer = localStorage.getItem('votingID_' + this.currentVotingQuestion.id);
        }
      });
    });
  }

  getPresentationVotings() {
    if(this.configuration.conferenceId===8353) {
      return;
    }
    this.votingService
      .getConferencePresentationVotings(this.configuration.conferenceId, this.currentLocation.currentPresentation.id)
      .subscribe(
        (response) => {
          const votingQuestions = response.data;
          let activeVotingQuestion = null;
          let finishedVotingQuestion = null;

          for (const votingQuestion of votingQuestions) {
            if (votingQuestion.active) { activeVotingQuestion = votingQuestion; }
            if (votingQuestion.finished) { finishedVotingQuestion = votingQuestion; }
          }

          if (activeVotingQuestion) {
            if (this.currentVotingQuestion === activeVotingQuestion) { return; }
            this.currentVotingQuestion = activeVotingQuestion;
          } else {
            this.currentVotingQuestion = finishedVotingQuestion;
          }
        },
        () => { }
      );

      this.votingListener();
  }

  promptCode() {
    if (!this.usingTickets && !this.currentDelegate) {
      this.router.navigate(['/delegate-registration']);
      return;
    }
    const modalRefAccess = this.modalService.open(ConferenceAccessModalComponent);
    modalRefAccess.componentInstance.conferenceId = this.configuration.conferenceId;
    let text = `To view this session you need to register as a delegate. If you are already a delegate or a member, please enter the access/PIN code.`;
    let buttonLabel = `Register`;
    if (this.usingTickets) {
      text = `To view this session you need to get your ticket. If you are already a delegate or a member, please enter the access/PIN code.`;
      buttonLabel = `Get a ticket`;
    } else if (!this.usingTickets && this.currentDelegate) {
      modalRefAccess.componentInstance.hideButton = true;
    }
    modalRefAccess.componentInstance.text = text;
    modalRefAccess.componentInstance.buttonLabel = buttonLabel;
    modalRefAccess.result.then(
      (result) => {

          this.userService.getCurrentUser()
            .subscribe(
              (user: User) => {
                  this.onClickStream(this.currentLocation);
              },
              () => {
              }
            );
      },
      () => { }
    );
  }

  checkAccess() {
      if (!this.currentUser || !this.currentUser.delegates) {
          this.hasAccess = false;
          return;
      }
      if(this.currentLocation && this.currentLocation.currentPresentation) {
        this.myTicketRights();
      }
  }

  showTab (val:string) {
      this.selectedTab = val;
  }

  myTicketRights() {
    if (!this.currentDelegate && !this.currentUser.member) {
      this.hasAccess = false;
      this.setStreamingURL(false);
      return;
    }
    if (this.currentUser.member) {
      this.hasAccess = true;
      this.setStreamingURL(false);
      return;
    }
    const params: any = {
      with_pagination: 0
    };
    if (this.controlAccess) {
      params.available_for_user=1;
    }
    if (this.availableBlocks.length) {
        if (!this.availableBlocks.find(e => e.id === this.currentLocation.currentPresentation.block_id)) {
            this.hasAccess = false;
        } else {
            if (this.currentLocation.currentPresentation.min_access_right == 'delegate') {
                this.hasAccess = true;
            } else if (this.currentLocation.currentPresentation.min_access_right == 'member') {
                this.hasAccess = false;
                return;
            }
            this.setStreamingURL(false);
        }
    } else {
      this.blockService
          .getConferenceBlocks(this.configuration.conferenceId, params)
          .subscribe(
            (response) => {
              this.availableBlocks = response.data;
              if (!this.availableBlocks.find(e => e.id === this.currentLocation.currentPresentation.block_id)) {
                  this.hasAccess = false;
              } else {
                if (this.currentLocation.currentPresentation.min_access_right == 'delegate') {
                  this.hasAccess = true;
                } else if (this.currentLocation.currentPresentation.min_access_right == 'member') {
                  this.hasAccess = false;
                }
                this.setStreamingURL(false);
                return;
              }
            },
            () => {}
        );
    }
  }
  
  isToday(date) {
    const today = new Date();
    const inputDate = new Date(date);
    return (today.toDateString() === inputDate.toDateString());
  }
  
  openModal(author: any){
    const modalRef = this.modalService.open(AuthorModalComponent);
    modalRef.componentInstance.author = author;
  }
  
  openPresentations(id: number){
    window.open(`/presentation/${id}`, '_blank');
  }
  
  openSession(id: number){
    // window.open(`//${id}`, '_blank');
  }

  hcpCheck(sponsorBlockId: number) {
      const modalRef = this.modalService.defaultModal({

        title: sponsorBlockId === 25823 ? 'This page is intended for Healthcare Professionals*' 
          : 'Are you a healthcare professional?',
        body: sponsorBlockId === 25823 ? 'Are you a healthcare professional*?<br><br><i>*The ABPI Code definition for healthcare professional is members of the medical, dental, pharmacy and nursing professionals and any other persons who in the course of their professional activities may administer, prescribe, purchase, recommend or supply a medicine.</i>'
          : 'The content of this page is intended for Healthcare Professionals. Unfortunately, you cannot enter this site if you are not a Healthcare Professional',
        size: 'small',

        buttons: [
          {
            text: 'Yes',
            color: 'primary',
            handler: () => {
              this.hasAccess = true;
              this.userService
                .updateUser(this.currentUser.id, { hcp: true })
                .subscribe(() => {
                  this.userService
                    .getCurrentUser()
                    .subscribe();
                });
            }
          },
          {
            text: 'No',
            color: 'primary',
            handler: () => {
              this.hasAccess = false;
              // this.router.navigate(['/li']);
            }
          }
        ]
      }); 
  }

  isPfizerDelegate(delegeteId: number) {
    const delegates = [
      190539, //Emma.Coyle@pfizer.com
      190540, //Jay.Aram@pfizer.com
      190541, //Hilary.Duckworth@pfizer.com
      190542, //jennifer.strahan@wearelucidgroup.com
      190543, //Hitesh.shukla@wearelucidgroup.com
      190545, //Matthew.Smith6@pfizer.com
      190546, //Rita.Capparella@pfizer.com
      190547, //Jay.Purdy@pfizer.com
    ];

    return delegates.includes(delegeteId);
  }
}
