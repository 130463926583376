import { Observable } from 'rxjs';

export class Contact {
    id: number;
    source: string;
    email: string;
    additional_info: string;
    organization_id: number;
    country_id: number;
    country_raw: string;
    address: string;
    address_2: string;
    city: string;
    zip: string;
    phone: string;
    website: string;
    email_2: string;
    title: string;
    status: string;
    update(contact): Observable<Contact> { return; }
    remove() {}

    constructor(values) {
        Object.assign(this, values);
    }
}
