<div class="nv-modal" [ngClass]="size">
    <div class="nv-modal-header error">
        <h4 class="nv-modal-title" translate>{{ title }}</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body">
        <div [innerHTML]="errorMessage"></div>
        <ul>
            <li *ngFor="let error of formattedErrors">
               {{error}}
            </li>
        </ul>
    </div>
    <div class="nv-modal-footer">
        <div class="nv-btn-box gap-10 flow-end">
            <button type="button"
                    class="nv-btn medium error"
                    *ngFor="let button of buttons"
                    (click)="onClick(button)">{{ button.text }}</button>
        </div><!-- /.nv-btn-box -->
    </div>
</div>
