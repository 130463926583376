import { Component } from '@angular/core';

@Component({
    selector: 'app-gallery-simple-page',
    templateUrl: './gallery-simple.component.html'
})
export class GallerySimpleComponent {

    constructor() { }

}
