<section class="nv-live-stage-config-panel"
         [ngClass]="{'nv-ls-panel-left-enhanced': currentLayoutOption === 'lo-type3', 'nv-ls-panel-right-enhanced': currentLayoutOption === 'lo-type2'}">
    <!--    TODO: Maybe add the close settings functionality-->
    <!--    <span class="nv-close-stage-config">close settings</span>-->
    <div class="nv-stage-config-layout-options">
        <span>layout options</span>
        <ul>
            <li class="lo-type1" (click)="currentLayoutOption='lo-type1'" title="Equal panels space"></li>
            <li class="lo-type2" (click)="currentLayoutOption='lo-type2'" title="Enhance right panel"></li>
            <li class="lo-type3" (click)="currentLayoutOption='lo-type3'" title="Enhance left panel"></li>
        </ul>
    </div><!-- /.nv-stage-config-layout-options -->
    <div class="nv-live-stage-config-layout">
        <div class="nv-live-stage-config-panes">
            <div class="nv-live-stage-config-left-pane">

                <ng-container *ngFor="let section of controlPanelSectionTabs">
                    <div class="nv-ls-config-feature-panel">
                        <div class="nv-ls-feature-panel-placeholder">
                            <!-- <ng-container
                                    *ngIf="loadingPresentation || loadingVotingQuestions || loadingLocation || loadingOffAirs || loadingProgram else content">
                                <span class="nv-ls-feature-panel-loading"><em></em>Loading. Please wait.</span>
                            </ng-container>
                            <ng-template #content> -->
                                <ng-container [ngSwitch]="section">
                                    <span class="nv-close-ls-config-feature-panel" (click)="closeSection(section)"
                                          *ngIf="controlPanelSectionTabs.length > 1"></span>

                                    <ng-container *ngSwitchCase="'meeting'">
                                        <nv-livestream-meeting
                                            [locationId]="location?.id"
                                            [conferenceId]="conferenceId"
                                            [currentUser]="currentUser"
                                        ></nv-livestream-meeting>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'ematerials'">
                                        <nv-livestream-materials
                                                [conferenceId]="conferenceId"
                                                [locationId]="locationId"
                                                [organizationId]="organizationId"
                                                [currentPresentationMainMaterial]="currentPresentationMainMaterial"
                                                [materialsImages]="materialsImages"
                                                [currentPresentation]="currentPresentation"
                                                (refresh)="loadPresentationDetails(currentPresentation.id)">
                                        </nv-livestream-materials>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'voting'">
                                        <nv-livestream-voting 
                                            [conferenceId]="conferenceId"
                                            [presentationId]="currentPresentationId"
                                            [organizationId]="organizationId"
                                            [votingQuestions]="votingQuestions"
                                            (refresh)="loadPresentationVotingQuestions(currentPresentation.id)">
                                        </nv-livestream-voting>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'off-air'">
                                        <nv-livestream-off-air [conferenceId]="conferenceId"
                                                               [organizationId]="organizationId"
                                                               [offAirs]="offAirs"
                                                               [location]="location"
                                                               (refresh)="loadConferenceOffAirs()">
                                        </nv-livestream-off-air>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'q&a'">
                                        <nv-livestream-q-a
                                            [blockId]="currentPresentation?.block_id"
                                            [availableBlocks]="qnaBlocks"
                                            [conferenceId]="conferenceId"
                                            [currentUserId]="currentUser?.id"
                                        ></nv-livestream-q-a>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'preview'">
                                        <ng-container *ngIf="location?.status === 'onair' && streamingUrl">
                                            <nv-video
                                                style="width: 100%; height: 100%;"
                                                [conferenceId]="conferenceId"
                                                [src]="streamingUrl"
                                            ></nv-video>
                                        </ng-container>
                                        <ng-container *ngIf="location?.status === 'offair'">
                                            <div *ngIf="location?.activeLivestreamOffair">
                                                <p>{{ location.activeLivestreamOffair?.title }}</p>
                                                <p>{{ location.activeLivestreamOffair?.description }}</p>
                                            </div>
                                            <div *ngIf="!location?.activeLivestreamOffair">
                                                <p>Playback URL has not been set up.
                                                    <a (click)="openPreviewSettings()">Set it up here.</a>
                                                </p>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>

                            <!-- </ng-template> -->
                        </div><!-- /.nv-ls-feature-panel-placeholder -->
                    </div><!-- /.nv-ls-config-feature-panel -->
                </ng-container>
            </div><!-- /.nv-live-stage-config-left-pane -->

            <div class="nv-live-stage-config-right-pane">

                <ng-container *ngIf="conferenceId">
                    <ng-container *ngIf="loadingProgram else programContent">
                        <div class="nv-ls-config-feature-panel">
                            <div class="nv-ls-feature-panel-placeholder">
                                <span class="nv-ls-feature-panel-loading"><em></em>Loading. Please wait.</span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #programContent>
                        <nv-livestream-streaming-program
                                [program]="program"
                                [conferenceId]="conferenceId"
                                [currentPresentationId]="currentPresentation?.id"
                                [locationId]="locationId">
                        </nv-livestream-streaming-program>
                    </ng-template>
                </ng-container>

            </div><!-- /.nv-live-stage-config-right-pane -->
        </div><!-- /.nv-live-stage-config-panes -->
        <div class="nv-live-stage-config-bar">
            <div class="nv-live-stage-config-bar-panel">
                <div class="nv-ls-config-bar-status"
                     [ngClass]="location?.status === 'onair'? 'on-air': 'off-air'">
                    <!-- 'not-ready' class for alt state -->
                    <h4>STATUS</h4>
                    <span class="status-txt"
                          [ngClass]="location?.status === 'onair'? 'on-air': 'off-air'">{{location?.status}}</span>
                </div><!-- /.nv-ls-config-bar-status -->
                <div class="nv-ls-config-bar-status current" *ngIf="location?.status === 'onair'">
                    <div class="stop-stream">
                        <span>Stop</span>
                        <button type="button" class="stop-stream" title="Stop Streaming"
                                (click)="stopStreaming()"></button>
                        <span>Stream</span>
                    </div>
                </div><!-- /.nv-ls-config-bar-status -->
                <div class="nv-ls-config-bar-status" *ngIf="location?.status === 'offair'">
                    <button type="button" class="nv-btn medium secondary" (click)="goLive()" title="Go Live"
                            data-icon-pl="&#xe930;">GO LIVE
                    </button>
                </div><!-- /.nv-ls-config-bar-status -->
                <div class="nv-ls-config-bar-status nv-steram-meta">
                    <span class="nv-ls-current-stream-label">
                        <strong>Stream: </strong>
                        <span class="room">{{ location?.name }}</span>
                        <br>
                        <strong>Session: </strong>
                        <span>{{ currentPresentation?.block_name || '-' }}</span>
                    </span>
                    <h4>
                        <ng-container *ngIf="currentPresentation">
                            <time>{{ currentPresentation.starts_at | nvDate:'HH:mm' }} - {{ currentPresentation?.ends_at | nvDate:'HH:mm' }}</time>
                            &nbsp;
                            <strong>{{ currentPresentation.title }}</strong>              
                        </ng-container>
                        <ng-container *ngIf="!currentPresentation">
                            <time>-</time>
                        </ng-container>
                    </h4>
                </div>
                <ul class="nv-live-stage-tool-list">
                    <li><a class="preview-stage" [ngClass]="{'active': controlPanelSectionTabs.includes('meeting')}"
                           (click)="addSection('meeting')">Meeting</a></li>
                    <li><a class="preview-stage" [ngClass]="{'active': controlPanelSectionTabs.includes('preview')}"
                           (click)="addSection('preview')">Preview Stage</a></li>
                    <li><a class="screen-share" [ngClass]="{'active': controlPanelSectionTabs.includes('ematerials')}"
                           (click)="addSection('ematerials')">Slides</a></li>
                    <li><a class="off-air-screen" [ngClass]="{'active': controlPanelSectionTabs.includes('off-air')}"
                           (click)="addSection('off-air')">Off Air Screen</a></li>
                    <li><a class="voting-screen" [ngClass]="{'active': controlPanelSectionTabs.includes('voting')}"
                           (click)="addSection('voting')">Voting</a></li>
                    <li data-tooltip="Let the system automatically run your program based on the program time. You can stop it any time and take over the control.">
                        <a class="auto-pilot" [ngClass]="{'active': autopilot === 1, 'loading': loadingAutoPilot}"
                           (click)="updateAutopilot()">Auto-pilot</a></li>
                    <li><a class="setup" (click)="editLocationSettings()">Setup</a></li>
                    <li><a class="chat" [ngClass]="{'active': controlPanelSectionTabs.includes('q&a')}"
                           (click)="addSection('q&a')">Q&A</a></li>
                </ul>
            </div><!-- /.nv-live-stage-config-bar-panel -->

            <div class="nv-live-stage-config-bar-panel">
                <nv-livestream-chat-container
                        [eventId]="conferenceId"
                        [organizationId]="organizationId"
                        [chatType]="'admin'"
                        [allow1on1]="true"
                        [allowGroupChat]="false">
                </nv-livestream-chat-container>
            </div><!-- /.nv-live-stage-config-bar-panel -->
        </div><!-- /.nv-live-stage-config-bar -->
    </div><!-- /.nv-live-stage-config-layout -->
</section><!-- /.nv-live-stage-config-panel -->
