import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@navus/ui/modal/modal.service';
import { LivestreamOffAirEditComponent } from './livestream-off-air-edit/livestream-off-air-edit.component';
import { OffAir } from '../../shared/models/offAir';
import { ConferenceService } from '@navus/core/services/conference.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { LocationService } from '@navus/core/services/location.service';
import { Location } from '@navus/core/classes/location';

@Component({
  selector: 'nv-livestream-off-air',
  templateUrl: './livestream-off-air.component.html',
})
export class LivestreamOffAirComponent implements OnInit {
  @Input() organizationId: number;
  @Input() conferenceId: number;
  @Input() location: Location;
  @Input() offAirs: OffAir[];

  @Output() refresh = new EventEmitter<void>();

  selectedOffAir: OffAir;
  activatingOffAir: boolean;

  constructor(private router: Router,
              private toasterService: ToastService,
              private conferenceService: ConferenceService,
              private locationService: LocationService,
              private modalService: ModalService) {
  }

  ngOnInit() {
    if (this.location?.activeLivestreamOffair?.id) {
      this.selectedOffAir = this.offAirs.find(o => o.id === this.location.activeLivestreamOffair.id);
    }
  }

  openOffAirEditModal(selectedOffAir?: OffAir) {
    const modalRef = this.modalService.open(LivestreamOffAirEditComponent);
      modalRef.componentInstance.conferenceId = this.conferenceId;
      modalRef.componentInstance.locationId = this.location.id;
      modalRef.componentInstance.organizationId = this.organizationId;
      if (selectedOffAir) {
        modalRef.componentInstance.offAir = selectedOffAir;
      }
      modalRef.result.then(
        () => {
          this.refresh.emit();
        },
        () => {
        }
      );
  }

  activateOffAir() {
    if (!this.selectedOffAir) {
      return;
    }
    this.activatingOffAir = true;
    const params = {
      active_livestream_offair_id: this.selectedOffAir.id
    };
    this.locationService.updateConferenceLocation(this.conferenceId, this.location.id, params)
      .subscribe(
        () => {
          this.toasterService.success('Offair successfully activated.', {});
          this.activatingOffAir = false;
        },
        (error) => {
          this.toasterService.error(error.message);
          this.activatingOffAir = false;
        }
      );
  }

  deleteOffAir() {
    this.modalService.defaultModal({
      title: 'Offair Deletion',
      body: 'Are you sure you want to delete this offair?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.conferenceService.deleteConferenceOffAir(this.conferenceId, this.selectedOffAir.id)
              .subscribe(
                () => {
                  this.refresh.emit();
                },
                (error) => {
                  this.toasterService.error(error.message);
                }
              );
          }
        }
      ]
    });
  }
}
