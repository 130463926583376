import { Directive, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({selector: '[nvBackgroundImage]'})
export class NavusBackgroundImageDirective implements OnChanges {
  @HostBinding('style') style: any;
  @HostBinding('class.nv-image-target') target: boolean = true;
  @HostBinding('class.nv-image-loading') loading: boolean;
  @HostBinding('class.nv-image-loaded') loaded: boolean;
  @Input() image: string = '';

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.image) {
      this.loading = true;
      this.loaded = false;
      const image = new Image();
      image.addEventListener('load', () => {
        this.style = this.sanitizer.bypassSecurityTrustStyle(this.image ? `background-image:url(${this.image})` : ``);
        this.loading = false;
        this.loaded = true;
      });
      image.src = this.image || '';
    }
  }

}
