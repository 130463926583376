<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Ticket Type</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-10">
                <a class="nv-btn medium secondary" (click)="delete()" [hidden]="!ticketTypeId" translate>Delete</a>
                <nv-button (click)="close()" color="passive" translate>Close</nv-button>
                <nv-button (click)="save()" [loading]="savingTicketType" [disabled]="ticketTypeForm.invalid" translate>Save</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav vertical-break-tab">
                <ul>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'delegates']">Attendees</a>
                    </li>
                    <li>
                        <a class="active">Ticketing</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'registration-settings']">Registration Settings</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'certificates']">CME Certificates</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
<div class="nv-config-content-port">
    <div class="nv-global-loading on" [class.on]="loadingTicketType">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24 small-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form [formGroup]="ticketTypeForm" class="nv-row config-plates clean gap-24 small-spaced-top spaced-bottom">
                            <div class="nv-column desk-6 lap-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Basic Information</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="title"
                                                        label="Title"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="description"
                                                        label="Description"
                                                        type="textarea"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="capacity"
                                                        label="Capacity"
                                                        type="number"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-6">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="amount"
                                                        label="Amount"
                                                        type="number"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-6">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        formControlName="currency"
                                                        label="Currency"
                                                        [options]="currencyOptions"
                                                ></nv-select>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-checkbox
                                                        formControlName="allow_codes"
                                                        label="Allow codes"
                                                ></nv-checkbox>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Ticket statistics</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <span>Ticket issued: {{ totalTickets }}</span>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-6 lap-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-6">
                                            <div class="nv-column-content">
                                                <strong translate>Ticket Codes</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-6">
                                            <div class="nv-column-content" style="text-align: right">
                                                <nv-button size="small" iconPl="&#xe095;" (click)="addTicketTypeCode()">Add code</nv-button>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <div *ngIf="ticketTypeCodes.length === 0">
                                                    <div class="nv-empty-state">
                                                        <div class="img">
                                                            <img src="../../../../assets/empty-states/empty-state-files.svg" alt="">
                                                        </div>
                                                        <h4 class="title">No ticket codes!</h4>
                                                        <p class="text">Once you add a ticket code, you'll see them here.</p>
                                                    </div>
                                                </div>
                                                <table class="nv-data-table break-lap">
                                                    <tbody>
                                                        <tr *ngFor="let ticketTypeCode of ticketTypeCodes">
                                                            <td class="dt-cell nv-clickable" (click)="editTicketTypeCode(ticketTypeCode)">
                                                                <label>Title</label>
                                                                <strong>{{ ticketTypeCode.title }}</strong>
                                                            </td>
                                                            <td class="dt-cell nv-clickable" (click)="editTicketTypeCode(ticketTypeCode)">
                                                                <label>Code</label>
                                                                <strong>{{ ticketTypeCode.code }}</strong>
                                                            </td>
                                                            <td class="dt-cell nv-clickable" (click)="editTicketTypeCode(ticketTypeCode)">
                                                                <label style="text-align: center">Amount</label>
                                                                <strong style="text-align: center">{{ ticketTypeCode.amount }}</strong>
                                                            </td>
                                                            <td class="dt-cell nv-clickable" (click)="editTicketTypeCode(ticketTypeCode)">
                                                                <label>Type</label>
                                                                <strong>{{ ticketTypeCode.type | titlecase }}</strong>
                                                            </td>
                                                            <td class="dt-cell nv-clickable" (click)="editTicketTypeCode(ticketTypeCode)">
                                                                <label style="text-align: right">Discounted Price</label>
                                                                <strong style="text-align: right">{{ ticketTypeCode.discounted_ticket_price }}</strong>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
