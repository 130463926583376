<section class="nv-section guttered nv-author-list-section" [class.edit-mode]="mouseOver">
    <ng-container *ngIf="mouseOver">
        <a class="nv-section-add-top" title="Add section" (click)="createBefore()"></a>
        <a class="nv-section-add-bottom" title="Add section" (click)="createAfter()"></a>
        <div class="nv-section-edit-tool-box">
            <a class="edit" title="Edit section" (click)="edit()"></a>
            <a class="delete" title="Delete section" (click)="delete()"></a>
        </div><!-- /.nv-section-edit-tool-box -->
        <div class="nv-section-sort-tool-box">
            <a class="move-up" title="Move up" (click)="moveUp()"></a>
            <a class="move-down" title="Move down" (click)="moveDown()"></a>
        </div><!-- /.nv-section-sort-tool-box -->
    </ng-container>

    <div *ngIf="loadingAuthors" style="min-height: 618px;" class="nv-loading">
        <div class="nv-loading-visual" translate>Loading</div>
    </div>
    <div *ngIf="!loadingAuthors" class="nv-port">
        <div *ngIf="configuration.title && configuration.title !== ''" class="nv-row large-spaced-top small-spaced-bottom gap-30">
            <div class="nv-column desk-12">
                <div class="nv-column-content" style="text-align: center;">
                    <h2>{{ configuration.title }}</h2>
                </div><!-- /.nv-column-content -->
            </div><!-- /nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row small-spaced-top spaced-bottom gap-30 flow-center">
            <div *ngFor="let author of authors" class="nv-column desk-3 tab-4 phab-6 phone-12">
                <div class="nv-column-content" (click)="openModal(author)">
                    <div class="nv-speaker-teaser">
                        <div class="st-svatar">
                            <a title="{{ author.name }}">
                                <span [style.background-image]="getAuthorImage(author)"></span>
                            </a>
                        </div><!-- /.st-svatar -->
                        <h3>
                            <a title="{{ author.name }}">{{(author.title && author.title !== "") ? author.title + " " : ""}}{{ author.name }}</a>
                        </h3>
                        <p *ngIf="author.institution && author.institution !== ''">{{ author.institution }}</p>
                    </div><!-- /.author-teaser -->
                </div><!-- /.em-column-content -->
            </div><!-- /nv-column -->
            <!--<div *ngIf="viewAll" class="nv-column-content" style="text-align: center;">-->
            <!--<a href="authors" class="nv-btn hollow neutral medium">{{viewAll}}</a>-->
            <!--</div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
        </div><!-- /.nv-row -->
        <div *ngIf="conferenceId!=8315 && pagination?.total > configuration.limit" class="nv-row flow-center large-spaced-bottom gap-30">
            <div class="nv-column desk-initial">
                <div class="nv-column-content">
                    <nv-pagination
                            class="nv-column-content spaced-bottom"
                            type="thorough"
                            [config]="pagination"
                            (paginationChange)="paginationChange($event)">
                    </nv-pagination>
                </div>
            </div>
        </div>
    </div><!-- /.nv-port -->
</section><!-- /.nv-section -->
