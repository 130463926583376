import { Material } from './material';
import { Block } from './block';
import { Conference } from './conference';
import { Author } from './author';
import { Location } from './location';
import { PresentationConsent } from './presentation-consent';

export class Presentation {
  id: number;
  code: string;
  title: string;
  type: string;
  section: string;
  description: string;
  starts_at: string;
  ends_at: string;
  speaker_name: string;
  speaker_image: string;
  speaker_image_thumb: string;
  external_url: string;

  streamable: boolean;
  access: boolean;
  available: boolean;
  consent_completed: boolean;

  has_main_material: boolean;
  available_main_material: boolean;
  has_webcast_material: boolean;
  available_webcast_material: boolean;
  has_supporting_material: boolean;
  available_supporting_material: boolean;
  has_summary_material: boolean;
  available_summary_material: boolean;

  min_access_right: string;
  accessible_by_tickets: string[];

  chat?: {
    enabled: boolean;
    moderation: boolean;
  }

  availability: string;
  talk_to_speaker: boolean;
  
  vote_count: number;

  updated_at: string;

  course?: any;
  materials?: Material[];
  webcasts?: Material[];
  accessible_materials?: Material[];
  speakers?: Author[];
  authors?: Author[];

  block_id: number;
  block?: Block;
  block_name?: string;
  location_id: number;
  location?: Location;

  consent?: PresentationConsent;

  conference_id: number;
  conference?: Conference;

  vote?: any;
  vote_available: boolean;
  webcast?: string;
}
