export class Delegate {
    id: number;
    name: string;
    first_name: string;
    last_name: string;
    email: string;
    image_url: string;
    image_url_thumb: string;
    user_id: number;
    country_id: number;
    code?: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
