<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Ticket Sales</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right"></div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
</div>
<div class="nv-config-content-port">
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form class="nv-row config-plates clean gap-24 propagate" [formGroup]="ticketSalesForm">
                            <div class="nv-column desk-12  no-propagate">
                                <div class="nv-column-content">
                                    <h1>Ticket Sales</h1>
                                    <p>
                                        Do you want to boost your ticket sale?
                                        Let our AI work for you and get you in touch with potential attendees.
                                        Tell us more about your goals and a Navus team member will get back to you shortly.
                                    </p>
                                </div>
                            </div>
                            <div class="nv-column desk-12" formGroupName="options">
                                <div class="nv-column-content">
                                    <nv-input
                                            label="Audience"
                                            formControlName="audience"
                                    ></nv-input>
                                    <div>
                                        <small>Describe your audience with up to 5 keywords/categories (comma separated)</small>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-12" formGroupName="options">
                                <div class="nv-column-content">
                                    <nv-input
                                            type="number"
                                            label="Number Of Attendees"
                                            formControlName="number_of_attendees"
                                    ></nv-input>
                                    <div>
                                        <small>Number of attendees you would like to get on board</small>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-12" formGroupName="options">
                                <div class="nv-column-content">
                                    <nv-select
                                            [multi]="true"
                                            [options]="regions"
                                            label="Specify a region"
                                            formControlName="region"
                                    ></nv-select>
                                </div>
                            </div>

                            <div class="nv-column desk-12" formGroupName="options">
                                <div class="nv-column-content">
                                    <nv-button (click)="submitTicketSalesForm()" [disabled]="ticketSalesForm.invalid">Submit request</nv-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div><!-- /.config-layout-lower-fixed -->
