<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>LeadLink</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-8">
                <nv-button color="primary" iconPl="&#xe095;" (click)="add()">Add campaign</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port spaced-bottom">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav">
                <ul>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'leadlink']">LeadLink</a>
                    </li>
                    <li>
                        <a class="active">Campaigns</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <form class="config-search-bar-w-filters" [formGroup]="formFilter" autocomplete="off">
        <div class="sbwf-port">
            <input type="text" placeholder="Search..."
                   formControlName="search_term" (change)="getCampaigns()">
            <span class="sbwf-outline"></span>
        </div><!-- /.sbwf-port -->
        <!--div class="sbwf-filters">
            <div class="sbwf-trigger">Filters</div>
        </div--><!-- /.sbwf-filters -->
    </form><!-- /.config-search-bar-w-filters -->
</div>
<div class="nv-config-content-port" nvInfiniteScroll (scrolled)="getCampaigns(true)">
    <section class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates">
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content">
                        <div class="nv-conifg-table">
                            <div *ngIf="selectedIds.length > 0" class="ct-controls-bar">
                                <div class="ct-control-bar-status">
                                    <p>{{ selectedIds.length }} items selected</p>
                                </div><!-- /.ct-control-bar-status -->
                                <nav class="ct-control-bar-tools">
                                    <ul>
                                        <li class="unselect">
                                            <a (click)="unselectAll()">Unselect All</a>
                                        </li>
                                    </ul>
                                </nav><!-- /.ct-control-bar-tools -->
                            </div><!-- /.ct-controls-bar -->
                            <table>
                                <thead>
                                <tr>
                                    <th class="w-checker">
                                        <span class="nv-row-checker unselect-on" *ngIf="selectedIds.length > 0" (click)="unselectAll()" title="Deselect items"></span>
                                        <span class="nv-row-checker select" *ngIf="selectedIds.length === 0" (click)="selectAll()" title="Select items"></span>
                                    </th>
                                    <th>
                                        Name
                                        <div class="nv-cell-sorter" (click)="sortCampaigns('name')">
                                            <span class="up" *ngIf="sort.field === 'name' && sort.direction === 'asc'"></span>
                                            <span class="down" *ngIf="sort.field === 'name' && sort.direction === 'desc'"></span>
                                        </div><!-- /.nv-cell-sorter -->
                                    </th>
                                    <th>
                                        Actions
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let campaign of campaigns" [class.nv-row-selected]="selectedIds.includes(campaign.id)">
                                    <td class="w-checker" (click)="toggleSelected(campaign)">
                                        <span class="nv-row-checker" [class.checked]="selectedIds.includes(campaign.id)" title="Select item"></span>
                                    </td>
                                    <td class="nv-clickable" (click)="edit(campaign)">{{ campaign.name }}</td>
                                    <td class="actions">
                                        <div class="nv-cell-actions">
                                            <span class="nv-cell-action-trigger" [class.on]="optionsShown === campaign.id" (click)="openOptions(campaign)"></span>
                                            <nav nvOffClick (offClick)="closeOptions()">
                                                <ul>
                                                    <li (click)="delete(campaign)">
                                                        <a>Delete</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div><!-- /nv-cell-actions -->
                                    </td>
                                </tr>
                            </table>
                            <div *ngIf="pagination.total === 0">
                                <div class="nv-empty-state">
                                    <div class="img">
                                        <img src="../../../../assets/empty-states/empty-state-events.svg" alt="">
                                    </div>
                                    <h4 class="title">No events!</h4>
                                    <p class="text">Once you add events, you'll see them here.</p>
                                    <nv-button (click)="add()">Add event</nv-button>
                                </div>
                            </div>
                        </div><!-- /.nv-conifg-table -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->
</div><!-- /.config-layout-lower-fixed -->
