<div class="nv-section">
    <div class="nv-port">
        <div class="nv-row config-plates large-spaced-top">
            <div class="nv-column desk-2 tab-none"></div>
            <div class="nv-column desk-8 tab-12">
                <div class="nv-column-content">
                    <form class="nv-row config-plates clean gap-24 propagate">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <h1>E-Posters</h1>
                                <p>
                                    Coming soon...
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="nv-column desk-2 tab-none"></div>
        </div>
    </div>
</div>
