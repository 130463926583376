import { Component } from '@angular/core';

@Component({
    selector: 'app-jitsi-splash-page',
    templateUrl: './jitsi-splash.component.html'
})
export class JitsiSplashComponent {

    constructor() { }

}
