import { Injectable } from '@angular/core';
import { ApiService } from '@navus/core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor(
    private apiService: ApiService
  ) { }

  getMediasiteTicket(videoId: string, params: any = {}) {
    return this.apiService.get(`mediasite/resources/${videoId}`, params);
  }
}
