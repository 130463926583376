import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrganizationService } from '@navus/core/services/organization.service';
import { Organization } from '@navus/core/classes/organization';
import { Conference } from '@navus/core/classes/conference';
import { ConferenceService } from '@navus/core/services/conference.service';
import { User } from '@navus/core/classes/user';
import { ModalService } from '@navus/ui/modal/modal.service';
import { PaginationConfig } from '@navus/ui/pagination/classes/pagination-config';

@Component({
  selector: 'main [nv-organization-landing-page]',
  templateUrl: './organization-landing-page.component.html'
})
export class OrganizationLandingPageComponent implements OnInit, OnDestroy {
  organizationId: number;
  organization: Organization;
  conferences: Conference[] = [];
  conferencePagination: PaginationConfig = {
    current_page: 1,
    per_page: 5
  };
  feed: { event: string, user: User, created_at: string }[] = [];

  subscriptions: Subscription[] = [];

  @HostBinding('class.nv-config-content') class = true;

  constructor(
    private route: ActivatedRoute,
    private organizationService: OrganizationService,
    private conferenceService: ConferenceService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    const routeSubscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.getConferences();
        this.getFeed();
      }
    );
    this.subscriptions.push(routeSubscription);

    const organizationSubscription = this.organizationService.currentOrganization
      .subscribe(
        (organization: Organization) => {
          this.organization = organization;
        },
        () => { }
      );
    this.subscriptions.push(organizationSubscription);
  }

  getConferences() {
    const params = {
      page: this.conferencePagination.current_page,
      per_page: this.conferencePagination.per_page,
      sort: 'starts_at',
      order: 'desc'
    };

    this.conferenceService.getOrganizationConferences(this.organizationId, params)
      .subscribe(
        (response: { data: Conference[], meta: any }) => {
          this.conferences = response.data;
          this.conferencePagination.total = response.meta.pagination.total;
        },
        () => { }
      );
  }

  conferencePaginationChange(paginationConfig: PaginationConfig) {
    this.conferencePagination.current_page = paginationConfig.current_page;
    this.getConferences();
  }

  getFeed() {
    const params = {
      per_page: 5
    };

    this.organizationService.getOrganizationFeed(this.organizationId, params)
      .subscribe(
        (response) => {
          this.feed = response.data;
        },
        () => { }
      );
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  changeLogo() {
    this.modalService.openResource(this.organizationId)
      .then(
        (response) => {
          if (response) {
            this.organization.logo = response.url;
            this.organizationService.updateOrganization(this.organizationId, { logo: this.organization.logo })
              .subscribe(
                () => {
                  this.organizationService.setCurrentOrganization(this.organizationId, true).subscribe();
                },
                () => { }
              );
          }
        },
        () => { }
      )
      .catch();
  }

}
