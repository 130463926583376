export class ConferenceListSectionConfig {
  limit: number = 0;
  title: string = 'Events';
  conferenceIds: string  = null;


  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
