<div class="nv-template-pane in-profile">
  <div class="nv-template-wrap">
    <div class="nv-profile-settings-layout">
      <router-outlet></router-outlet>

      <!-- MENU -->
      <nav class="nv-profile-settings-menu">
        <ul>
          <li>
            <a
              [class.active]="currentPage === 'info'"
              [routerLink]="['/profile', 'info']"
              title="Personal Info"
            >Personal Info</a>
          </li>
          <li>
            <a 
              [class.active]="currentPage === 'security'"
              [routerLink]="['/profile', 'security']" 
              title="Security"
            >Security</a>
          </li>
          <li *ngIf="hasActiveBadges">
            <a 
              [class.active]="currentPage === 'badges'"
              [routerLink]="['/profile', 'badges']" 
              title="Badges"
            >Badges</a>
          </li>
          <li>
            <a 
              [class.active]="currentPage === 'delegate'"
              [routerLink]="['/profile', 'delegate']" 
              title="Conference Participation"
              >Conference Participation</a>
          </li>
          <li *ngIf="isSpeaker || isModerator || isAuthor">
            <a 
              [routerLink]="['/profile', 'speaker']" 
              title="Speaker/Moderator/Author Page"
            >Speaker / Moderator / Author Page</a>
          </li>
          <li *ngIf="isSponsor">
            <a 
              [routerLink]="['/profile', 'sponsor']"
              title="Sponsor Info"
            >Sponsor Info</a>
          </li>
        </ul>
      </nav>
    </div>
    <!-- /.nv-profile-settings-layout -->
  </div>
  <!-- /.nv-template-wrap -->
</div>
<!-- /.nv-template-pane -->
