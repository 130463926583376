<!-- <section class="nv-section nv-banner-section nv-presentation-detail-banner guttered">
    <div class="nv-port">
        <div class="nv-row spaced flow-center">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <div class="nv-banner-block">
                        <a href><img alt="" src="https://service.e-materials.com/storage/resources/navus/spo/sponsors-banner-ad.jpg"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="nv-section nv-presentation-detail-block guttered" *ngIf="presentation">
    <div class="nv-port">
        <div class="nv-row spaced gap-50">
            <main class="nv-column desk-8 tab-12">
                <div class="nv-column-content">
                    <header class="nv-presentation-detail-header">
                        
                        <!-- <p><a *ngIf="presentation.conference_id != 8336 && presentation.conference_id != 8310" [routerLink]="'/search'" translate><< Browse more</a></p> -->
                        <!-- <p><a *ngIf="(presentation.conference_id == 8336 || presentation.conference_id == 8310) && presentation.type=='Poster'" [routerLink]="'/posters'" translate><< Browse more</a></p> -->
                        <p><a *ngIf="presentation.conference_id == 8310 && presentation.type!='Poster'" [routerLink]="'/program'" translate><< See the full program</a></p>

                        <p class="pdh-cat"><a>{{presentation.block?.name}}</a></p>
                        <h1>{{presentation.title}}</h1>
                        <p class="pdh-posted">Latest Update: {{ presentation.updated_at | nvDate }}</p>
                        <ng-container *ngIf="presentation.vote_available">
                            <nv-button *ngIf="presentationVote" color="white" [loading]="loadingVote" size="small" (click)="toogleVote()">
                                <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                                Remove Like
                            </nv-button>
                            <nv-button *ngIf="!presentationVote" color="primary" [loading]="loadingVote" size="small" (click)="toogleVote()">
                                <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                                Like
                            </nv-button>
                        </ng-container>
                    </header><!-- /.nv-presentation-detail-header -->

                    <ng-container *ngIf="presentation?.talk_to_speaker">
                        <div class="nv-presentation-meeting">
                            <div class="nv-presentation-meeting-btn" *ngIf="!currentUser || !currentDelegate">
                                <a (click)="getAccess()" class="nv-btn medium accent2">
                                    Talk to the speaker</a>
                            </div>
                            
                            <div *ngIf="currentDelegate" class="presentation-meeting" [class.on]="showMeeting">
                                <nv-meeting #meeting
                                    [entityType]="'Presentation'"
                                    [entityId]="presentation?.id"
                                    [currentUser]="currentUser"
                                    [conferenceId]="presentation.conference_id"
                                    [joinButtonText]="'Talk to the speaker'"
                                    [createDisabled]="!speakerUserIds.includes(currentUser?.id)"
                                    (joined)="meetingJoined()"
                                    (closing)="meetingClosed()"
                                    (meetingRoomChange)="meetingRoomChange($event)"
                                ></nv-meeting>
                            </div>
                            <ng-container *ngIf="!presentationMeetingRoom && !speakerUserIds.includes(currentUser?.id)">
                                <div class="presentation-meeting">
                                    Please wait for the author
                                </div>
                            </ng-container>

                            <div class="nv-presentation-meeting-time" *ngIf="presentation && presentation.availability">
                                <h5>Availability:</h5>
                                <span [innerHTML]="presentation.availability | nvHtml"></span>
                            </div>
                        </div>
                    </ng-container>



                    <div class="nv-materials-stack" *ngIf="presentation.accessible_materials.length > 0">
                        <h3>Materials are available for viewing:</h3>

                        <nav>
                            <ul>
                                <li *ngFor="let material of presentation.accessible_materials">
                                    <a *ngIf="material.accessible" (click)="handleMaterial(material)">
                                        <span>
                                            <img *ngIf="material.type === 'main'" src="../../assets/ico-material-pdf.svg" alt="{{material.title}}">
                                            <img *ngIf="material.type === 'webcast'" src="../../assets/ico-material-mp-4.svg" alt="{{material.title}}">
                                        </span>
                                        <strong>{{material.title}}</strong>
                                    </a>
                                    <a *ngIf="!material.accessible">
                                        <span>
                                            <img *ngIf="material.type === 'main'" src="../../assets/ico-material-pdf.svg" alt="{{material.title}}">
                                            <img *ngIf="material.type === 'webcast'" src="../../assets/ico-material-mp-4.svg" alt="{{material.title}}">
                                        </span>
                                        <strong>{{material.title}}</strong>
                                        <small>Material will be available from {{ material.published_at | nvDate: 'dd.MM.yyyy' }} at {{ material.published_at | nvDate: 'HH:mm' }}</small>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div><!-- /.nv-materials-stack -->

                    <div class="nv-line-tabs guttered">
                        <nav class="nv-tabs-nav vertical-break-phab">
                            <ul>
                                <li><a class="active">Abstract</a></li>
                                <!-- <li><a>Voting</a></li> -->
                                <!-- <li><a>Questions</a></li> -->
                            </ul>
                        </nav><!-- /.nv-tabs-nav -->
                        <header class="nv-row gap-16" *ngIf="presentation.description">
                            <div *ngIf="presentation.conference_id == 8200" class="nv-column desk-initial v-middle">
                                <div class="nv-column-content">
                                    <a class="nv-btn small passive radius-high" data-icon="&#xe938;"  (click)="downloadAbstract(presentation.id)">Download abstract as PDF</a>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                        </header><!-- /.nv-row -->
                    </div><!-- /.nv-line-tabs -->

                    <div *ngIf="currentDelegate" class="nv-material-content">

                        <div [innerHTML]="presentation.description | nvHtml"></div>

                        <div *ngIf="!presentation.description">
                            <div style="text-align: center">
                                <figure aria-hidden="true">
                                    <img src="/assets/empty-states/empty-state-presentations.svg">
                                </figure>
                                <h4 translate>There is no abstract for this title</h4>
                                <br>
                            </div>
                        </div>
                    </div><!-- /.nv-material-content -->
                    <div *ngIf="!currentDelegate" class="nv-material-content">
                        <p translate>You don't have permission to see this content.</p>
                    </div>

                </div><!-- /nv-column-content -->
            </main>

            <aside class="nv-column desk-4 tab-12">
                <div class="nv-column-content">
                    <ng-container *ngIf="presentation && presentation.chat?.enabled"> <!-- && presentation.chat?.enabled -->
                        <div class="nv-live-suite-interactions-panels">
                            <div class="nv-live-suite-interactions-bar">
                                <h2 *ngIf="speakerUserIds.includes(currentUser?.id)">Questions for you</h2>
                                <h2 *ngIf="!speakerUserIds.includes(currentUser?.id)">Ask the {{presentation.type=='Poster' ? "author(s)" : "speaker(s)"}}</h2>
                                <div class="nv-live-suite-interactions-toolbar" *ngIf="presentation.chat?.moderation && speakerUserIds.includes(currentUser?.id)">
                                    <div class="nv-interactions-tools">
                                        <div class="nv-chat-interaction-filters">
                                            <nv-select
                                                    [options]="statusOptions"
                                                    [(ngModel)]="approveStatus"
                                                    [label]="'Status' | translate">
                                            </nv-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-live-suite-interactions-panes" [ngClass]="chatMode">
                                <div class="nv-live-suite-interactions-pane qa">
                                    <div class="nv-live-suite-qa-tabs" *ngIf="!speakerUserIds.includes(currentUser?.id)">
                                        <div class="nv-live-suite-qa-tab" [class.active]="chatTab === chatTabPublicValue" (click)="chatTab = chatTabPublicValue">Public Questions</div>
                                        <div class="nv-live-suite-qa-tab" [class.active]="chatTab === 'mine'" (click)="chatTab = 'mine'">My Questions</div>
                                    </div>
                                    <ng-container>
                                        <nv-chat
                                            [eventId]="presentation.conference_id"
                                            [roomId]="'presentation_' + presentation.id"
                                            [currentUserId]="currentUser?.id"
                                            [replies]="true"
                                            [canReply]="speakerUserIds.includes(currentUser?.id)"
                                            [approval]="presentation.chat?.moderation"
                                            [canApprove]="presentation.chat?.moderation && speakerUserIds.includes(currentUser?.id)"
                                            [approveStatus]="speakerUserIds.includes(currentUser?.id) ? 'all' : chatTab"
                                            [usecase]="'Q&A'"
                                        >
                                        </nv-chat>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <br>
                     
                    </ng-container>

                    <div class="nv-aside-box" *ngFor="let speaker of presentation.speakers">
                        <div class="nv-presentation-author">
                            <div class="pa-img" title="{{ speaker.name }}"  
                                 nvBackgroundImage [image]="speaker.image_url_thumb || speaker.image_url || '/assets/avatar-placeholder.png'"></div><!-- /.pa-img -->
                            <div class="pa-meta">
                                <a (click)="showAuthor(speaker)">
                                    <h4>
                                        <ng-container *ngIf="speaker.title">{{speaker.title}}&nbsp;</ng-container>
                                        {{speaker.name}}
                                    </h4>
                                </a>
                                <!-- <p>Founder, GoVirtual</p> -->
                                <!-- <p class="pa-linkedin"><a href>View LinkedIn profile</a></p> -->
                            </div><!-- /.pa-meta -->
                        </div><!-- /.nv-presentation-author -->

                        <div class="nv-truncated"> <!-- < toggle class 'released' -->
                            <div [ngClass]="showMoreBio ? 'released' : 'nv-truncated-clamped'" [innerHTML]="speaker.additional_info"><!-- /.nv-truncated-clamped -->
                            <div class="nv-truncated-more">
                                <a *ngIf="!showMoreBio" (click)="expandBio()">more</a>
                            </div> 
                                </div>
                        </div><!-- /nv-truncated -->

                        <!-- <small>Other presentation from this speaker:</small>
                        <nav class="nv-weird-list">
                            <ul>
                                <li>
                                    <a href>
                                        <span class="ls">
                                            <span>LIVESTREAM</span>
                                        </span>
                                        <strong>Presentation name</strong>
                                    </a>
                                </li>
                                <li>
                                    <a href>
                                        <strong>Presentation name</strong>
                                    </a>
                                </li>
                                <li>
                                    <a href>
                                        <span class="ls streamed">
                                            <span>LIVESTREAMED</span>
                                        </span>
                                        <strong>Presentation name</strong>
                                    </a>
                                </li>
                            </ul>
                        </nav> -->
                    </div><!-- /.nv-aside-box -->

                    <div class="nv-aside-box" *ngIf="block">
                        <h3><span><b><u>Session</u></b></span> {{ block.name }}</h3>

                        <div class="nv-chairs-list" *ngIf="block.chairpersons.length > 0">
                            <p>Chaired By:</p>
                            <ul>
                                <li *ngFor="let chairperson of block.chairpersons">
                                    <span nvBackgroundImage [image]="chairperson.image_url_thumb"></span>
                                    <a (click)="showAuthor(chairperson)">
                                        <strong>
                                            <ng-container *ngIf="chairperson.title">{{chairperson.title}}&nbsp;</ng-container>
                                            {{chairperson.name}}
                                        </strong>
                                    </a>
                                </li>
                            </ul>
                        </div><!-- /.nv-chairs-list -->

                        <small>Other presentation from this session:</small>
                        <nav class="nv-weird-list">
                            <ul>
                                <li *ngFor="let blockPresentation of blockPresentations">
                                    <a [routerLink]="['/presentation', blockPresentation.id]" [class.active]="blockPresentation.id === presentation.id">
                                        <strong>{{ blockPresentation.title }}</strong>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div><!-- /.nv-aside-box -->
                </div><!-- /nv-column-content -->
            </aside>
        </div><!-- /nv-row -->
    </div><!-- /nv-port -->
</section><!-- /nv-section -->

<!--
<section class="nv-section guttered nv-search-page-section single-view single-recommended">
    <div class="nv-port">
        <div class="nv-row spaced flow-center">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <div class="nv-recommended-chunk">
                        <h4 translate>You may also like</h4>
                        <div class="rc-dual">
                            <ng-container *ngFor="let recommendedPresentation of presentationsRecommended">
                                <div class="rcd-item" *ngIf="recommendedPresentation.id !== presentationId">
                                    <h3>
                                        <a [routerLink]="['/presentation', recommendedPresentation.id]" [title]="recommendedPresentation.title">
                                            {{ recommendedPresentation.title }}</a>
                                    </h3>
                                    <p>
                                        <figure nvBackgroundImage [image]="recommendedPresentation.speaker_image_thumb || recommendedPresentation.speaker_image || '/assets/avatar-placeholder.png'"></figure>
                                        <span>{{recommendedPresentation.speaker_name}}</span>
                                    </p>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->
