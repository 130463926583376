import { NgModule } from '@angular/core';
import { NavusCoreModule } from '@navus/core/navus-core.module';
import { NavusUiModule } from '@navus/ui/navus-ui.module';
import { SharedModule } from '../../../shared';
import { LivestreamingListPageComponent } from './livestreaming-list/livestreaming-list-page.component';
import { LivestreamingDetailsPageComponent } from './livestreaming-details/livestreaming-details-page.component';
import { StreamingProgramComponent } from '../../components/streaming-program/streaming-program.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    NavusCoreModule.forRoot(),
    NavusUiModule,
    SharedModule,
    DragDropModule
  ],
  declarations: [
    LivestreamingListPageComponent,
    LivestreamingDetailsPageComponent,
    StreamingProgramComponent
  ],
  entryComponents: [],
  providers: []
})
export class LivestreamingModule {
}
