import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceService } from '@navus/core/services/resource.service';

@Component({
  selector: 'nv-add-tags',
  templateUrl: './add-folder.component.html'
})
export class AddFolderComponent implements OnInit {
  @Input() organizationId: number;

  newFolderName: string;

  constructor(
    private activeModal: NgbActiveModal,
    private resourceService: ResourceService
  ) { }

  ngOnInit() {
  }

  save() {
    if (this.newFolderName) {
      this.resourceService.createOrganizationFolder(this.organizationId, this.newFolderName)
        .subscribe(
          () => {
            this.activeModal.close();
          },
          () => {}
        );
    }
  }

  close() {
    this.activeModal.dismiss();
  }

}
