import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { TwitterService } from '@navus/core/services/twitter.service';
import { TwitterSectionConfig } from './twitter-section.config';
import { BaseSectionComponent } from '../_base/base-section.component';
import { WebsiteService } from '@navus/core/services/website.service';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { WebsiteControlService } from '../../services/website-control.service';

@Component({
  selector: 'nv-twitter-section',
  templateUrl: './twitter-section.component.html'
})
export class TwitterSectionComponent extends BaseSectionComponent implements OnInit, OnDestroy {
  @Input() data: WebsitePageSection;
  configuration: TwitterSectionConfig = new TwitterSectionConfig();
  activeGroup: any = '';
  showMore: boolean = false;

  tweetGroups: any = [];

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService,
    private twitterService: TwitterService
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );
  }

  ngOnInit() {
    this.configuration = new TwitterSectionConfig(this.data.configuration);
    this.getTweets();
  }

  ngOnDestroy() {

  }

  getTweets() {
    const params: any = {
      criteria: this.configuration.criteria.join(',')
    };

    this.twitterService.getTweets(params)
      .subscribe(
        (response) => {
          this.tweetGroups = response.data;
          if (Object.keys(this.tweetGroups).length > 0) {
            this.activeGroup = Object.keys(this.tweetGroups)[0];
          }
        }
      );
  }

}
