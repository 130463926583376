import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Conference } from "@navus/core/classes/conference";
import { Website } from "@navus/core/classes/website";
import { ConferenceService } from "@navus/core/services/conference.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'nv-website-registration-ticket',
  templateUrl: './ticket.component.html'
})
export class RegistrationTicketComponent implements OnInit, OnDestroy {
  website: Website;
  conference: Conference;

  ticketTypes: any[] = [];

  selectedTicketTypeId: string;
  selectedTicketType: any;

  ticketTypeCodeText: string = '';
  ticketTypeCodeError: string = '';
  ticketTypeCode: any;
  checkingCode: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private conferenceService: ConferenceService,
  ) { }
  
  ngOnInit() {
    this.website = this.route.parent.parent.parent.snapshot.data.website;
    this.conference = this.website?.conferences?.find(c => c.id === this.website.active_conference_id);

    this.getTicketTypes();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getTicketTypes() {
    this.conferenceService
      .getConferenceTicketTypes(this.conference.id)
      .subscribe(
        (response) => {
          this.ticketTypes = response.data;

          if (this.ticketTypes.length === 1) {
            this.selectedTicketTypeId = this.ticketTypes[0].id;
            this.selectedTicketType = this.ticketTypes[0];
          }
        },
        () => { }
      );
  }

  ticketTypeChange() {
    this.selectedTicketType = this.ticketTypes.find(
      ticketType => ticketType.id === this.selectedTicketTypeId
    );
  }

  ticketCodeCheck() {
    this.checkingCode = true;
    this.conferenceService
      .checkConferenceTicketTypeCode(this.conference.id, this.selectedTicketTypeId, encodeURIComponent(this.ticketTypeCodeText))
      .subscribe(
        (response) => {
          this.ticketTypeCode = response.data;
          this.checkingCode = false;
        },
        () => {
          this.checkingCode = false;
          this.ticketTypeCodeError = 'Entered code is not recognized';
        }
      );
  }

  next() {
    const amountForPayment = this.ticketTypeCode ? this.ticketTypeCode.discounted_ticket_price : this.selectedTicketType.amount;
    const params: any = {
      ticket: this.selectedTicketTypeId
    }
    if (this.ticketTypeCode) {
      params.code = this.ticketTypeCode.id
    }
    if (this.route.snapshot.queryParams.returnUrl) {
      params.returnUrl = this.route.snapshot.queryParams.returnUrl
    }

    if (amountForPayment > 0) {
      this.router.navigate(['..', 'payment'], { 
        relativeTo: this.route,
        queryParams: params
      });
    } else {
      this.router.navigate(['..', 'delegate'], { 
        relativeTo: this.route,
        queryParams: params
      });
    }
  }

}