import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrganizationService } from '@navus/core/services/organization.service';
import { Organization } from '@navus/core/classes/organization';
import { ModalService } from '@navus/ui/modal/modal.service';

@Component({
  selector: 'main [nv-organization-list-page]',
  templateUrl: './organization-list-page.component.html'
})
export class OrganizationListPageComponent implements OnInit {
  organizations: Organization[] = [];
  organizationsLoading: boolean = true;

  constructor(
    private router: Router,
    private organizationService: OrganizationService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.getOrganizations();
  }

  getOrganizations() {
    this.organizationsLoading = true;

    this.organizationService.getMyOrganizations()
      .subscribe(
        (response: { data: Organization[], meta: any }) => {
          if (response.data.length === 0) {
            this.router.navigate(['/o', 'setup']);
          } else if (response.data.length >= 1) {
            const organization = response.data[0];
            this.router.navigate(['/o', organization.id, 'landing']);
          }

          this.organizationsLoading = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }

}
