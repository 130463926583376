export class Organisation {
    id: number;
    name: string;
    conferences: Array<any>;
    portals: Array<any>;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
