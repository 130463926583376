import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavusModalComponent } from './default-modal/modal.component';
import { NavusAlertComponent } from './alert/alert.component';
import { NavusConfirmComponent } from './confirm/confirm.component';
import { NavusErrorComponent } from './error/error.component';
import { ModalConfig } from './classes/modal-config';
import { NavusResourceModalComponent } from '@navus/ui/modal/resource-modal/resource-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private modalService: NgbModal
  ) { }

  open(content: any) {
    return this.modalService.open( content );
  }

  openResource(organizationId: number, multiple: boolean = false, folder: string = null, mime: string[] = null, config: ModalConfig = {}) {
    const modalRef = this.modalService.open( NavusResourceModalComponent );
    modalRef.componentInstance.organizationId = organizationId;
    modalRef.componentInstance.multiple = multiple;
    modalRef.componentInstance.folder = folder;
    modalRef.componentInstance.mime = mime;
    if (config.title) {
      modalRef.componentInstance.title = config.title;
    } else {
      modalRef.componentInstance.title = 'Select the file';
    }
    return modalRef.result;
  }

  defaultModal(config: ModalConfig) {
    const modalRef = this.modalService.open( NavusModalComponent );
    modalRef.componentInstance.body = config.body;
    modalRef.componentInstance.buttons = config.buttons;
    if (config.title) {
      modalRef.componentInstance.title = config.title;
    }
    if (config.size) {
      modalRef.componentInstance.size = config.size;
    }
    return modalRef;
  }

  alert(config: ModalConfig) {
    const modalRef = this.modalService.open( NavusAlertComponent );
    modalRef.componentInstance.body = config.body;
    modalRef.componentInstance.buttons = config.buttons;
    if (config.title) {
      modalRef.componentInstance.title = config.title;
    }
    if (config.size) {
      modalRef.componentInstance.size = config.size;
    }
  }

  confirm(config: ModalConfig) {
    const modalRef = this.modalService.open( NavusConfirmComponent );
    modalRef.componentInstance.body = config.body;
    modalRef.componentInstance.buttons = config.buttons;
    if (config.title) {
      modalRef.componentInstance.title = config.title;
    }
    if (config.size) {
      modalRef.componentInstance.size = config.size;
    }
  }

  error(config: ModalConfig) {
    const modalRef = this.modalService.open( NavusErrorComponent );
    modalRef.componentInstance.errors = config.errors;
    modalRef.componentInstance.buttons = config.buttons;
    if (config.title) {
      modalRef.componentInstance.title = config.title;
    }
    if (config.size) {
      modalRef.componentInstance.size = config.size;
    }
  }

}


