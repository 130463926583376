import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Delegate } from '@navus/core/classes/delegate';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PublicChatService } from '@navus/core/services/public-chat.service';
import { ChatService } from '@navus/core/services/chat.service';
import { Observable, Subscription } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { UserService } from '@navus/core/services/user.service';

@Component({
  selector: 'nv-delegate-modal',
  templateUrl: './delegate-modal.component.html'
})
export class DelegateModalComponent implements OnInit, OnDestroy {
  @Input() delegate: Delegate;
  biography: string | SafeHtml = `<p style="text-align: center">No biography info</p>`;
  thumb = '';

  loadingDelegateInfo: boolean;
  delegateHasActivatedChat$: Observable<boolean>;

  subscriptions: Subscription[] = [];

  constructor(
    private activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
    private publicChatService: PublicChatService,
    private chatService: ChatService,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    if (this.delegate) {
      this.loadingDelegateInfo = true;
      if (this.delegate.biography) {
        this.biography = this.sanitizer.bypassSecurityTrustHtml(this.delegate.biography);
      }
      if (this.delegate.additional_info) {
        this.biography = this.sanitizer.bypassSecurityTrustHtml(this.delegate.additional_info);
      }
      // User can start a chat only if a delegate has already activated chat
      // Make sure that a user cannot start a chat with himself
      this.delegateHasActivatedChat$ =
        this.userService.currentUser.pipe(
          filter(currentUser => !!currentUser),
          mergeMap((currentUser) => this.chatService.retrieveAllChatUsers().pipe(take(1), map((allChatUsers => ({
            allChatUsers,
            currentUser
          }))))),
          map(({ allChatUsers, currentUser }) => {
            this.loadingDelegateInfo = false;
            return currentUser.id !== this.delegate.user_id && !!allChatUsers.find((cu) => cu.userId === this.delegate.user_id);
          }),
        );
    }
  }

  close() {
    this.activeModal.dismiss();
  }

  startChat(): void {
    this.loadingDelegateInfo = true;
    this.publicChatService.startDelegateChat(this.delegate);
    const chatStartSubscription = this.publicChatService.delegateChatStarted$
      .subscribe((delegate) => {
        if (delegate?.id === this.delegate.id) {
          this.close();
          this.loadingDelegateInfo = false;
        }
      });

    this.subscriptions.push(chatStartSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
