import { Organization } from './organization';
import { WebsitePage } from './website-page';
import { Conference } from '@navus/core/classes/conference';

export class Website {
  id: number;
  slug: string;
  subdomain: string;
  domain: string;
  name: string;
  logo: string;
  favicon: string;
  default_language: string;
  description: string;
  theme: string;
  css_override: string;
  active_conference_id: number;
  published: boolean;
  publish_at: string;

  meta_title: string;
  meta_keywords: string;
  meta_description: string;

  regional_settings: {
    date_format: string;
    time_format: string;
    timezone: string;
  };

  settings: {
    google_analytics_code: string;
    search_page_presentation_types: string;
    search_page_material_types: string;
    footer: string;
    custom_css: string;
  };

  organization_id: number;
  organization?: Organization;

  conferences?: Conference[];

  pages?: WebsitePage[];
}
