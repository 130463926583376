import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Website } from '@navus/core/classes/website';
import { Conference } from '@navus/core/classes/conference';

@Component({
  selector: 'nv-placeholder-preview-modal',
  templateUrl: './placeholder-preview-modal.component.html'
})
export class PlaceholderPreviewModalComponent implements OnInit {
  @Input() website: Website;
  @Input() conference: Conference;

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {

  }

  close() {
    this.activeModal.dismiss();
  }

}
