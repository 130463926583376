import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocationService } from '@navus/core/services/location.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { Location } from '@navus/core/classes/location';

@Component({
  templateUrl: './livestreaming-location-edit.component.html',
})
export class LivestreamingLocationEditComponent implements OnInit, OnDestroy {
  @Input() conferenceId: number;
  @Input() location: Location;

  locationEditForm: FormGroup;
  sourceSaving: boolean = false;
  unsavedChanges: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private locationService: LocationService,
              private toasterService: ToastService,
              private modalService: ModalService,
              private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.initForm();
    const formChange = this.locationEditForm.valueChanges
      .subscribe(
        () => {
          this.unsavedChanges = true;
        }
      );
    this.subscriptions.push(formChange);

    const settingsValueChange = this.locationEditForm.get('settings')
      .valueChanges
      .subscribe((settings) => {
        if (settings === 'external') {
          this.locationEditForm.get('rtmp')?.setValidators([Validators.required]);
          this.locationEditForm.get('stream_url')?.setValidators([Validators.required]);
        } else {
          this.locationEditForm.get('rtmp')?.setValidators(null);
          this.locationEditForm.get('rtmp')?.setValue('');
          this.locationEditForm.get('stream_url')?.setValidators(null);
          this.locationEditForm.get('stream_url')?.setValue('');
        }
      });
    this.subscriptions.push(settingsValueChange);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  close(): void {
    this.activeModal.dismiss();
  }

  save() {
    if (this.locationEditForm.invalid) {
      return;
    }

    this.sourceSaving = true;
    const params = {
      settings: {
        source_type: this.locationEditForm.value?.settings
      },
      rtmp: this.locationEditForm.value?.rtmp || null,
      stream_url: this.locationEditForm.value?.stream_url || null
    };

    this.locationService
      .updateConferenceLocation(this.conferenceId, this.location.id, params)
      .subscribe(
        (response: { data: Location }) => {
          this.toasterService.success('Location successfully saved.', {});
          this.sourceSaving = false;
          this.activeModal.close();
        },
        (error) => {
          this.toasterService.error(error.message);
          this.sourceSaving = false;
        }
      );
  }

  private initForm() {
    this.locationEditForm = this.formBuilder.group({
      settings: [this.location?.settings?.source_type || '', Validators.required],
      rtmp: [this.location?.rtmp || ''],
      stream_url: [this.location.stream_url || '']
    });
    this.unsavedChanges = false;
  }
}
