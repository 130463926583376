<div class="custom-chart-legend">
    <div class="custom-chart-legend-title">
        {{title}}
    </div>
    <div class="custom-chart-legend-items">
        <ng-container *ngFor="let row of legendItems">
            <div class="custom-chart-legend-item"
                 [class.active]="isLabelActive(row)"
                 (mouseenter)="legendLabelActivate(row)"
                 (mouseleave)="legendLabelDeactivate(row)">
                <div class="custom-chart-color-box" (click)="selectRow(row.name)"
                     [style.background-color]="row.color"></div>
                <div class="custom-chart-legend-name" (click)="selectRow(row.name)">{{row.name}}: {{row.value}}</div>
                <span class="custom-chart-legend-close"
                      (click)="removeRow(row.id)"
                      *ngIf="deleteItem"></span>
            </div>
        </ng-container>
    </div>
</div>

<div class="custom-chart-legend-add" *ngIf="sessionsManipulation && availableSessions?.length">
    <br>
    <nv-select
            [multi]="!presentationsManipulation"
            [options]="filteredAvailableSessions"
            [canEmpty]="false"
            [showSearch]="true"
            (searched)="searchAvailableSessions($event)"
            [(ngModel)]="selectedSessions"
            (ngModelChange)="onSessionSelect($event)"
            [label]="'Add sessions'">
    </nv-select>
    <br>
    <nv-select *ngIf="presentationsManipulation && availablePresentations?.length"
               [multi]="false"
               [canEmpty]="false"
               [options]="availablePresentations"
               [(ngModel)]="selectedPresentation"
               (ngModelChange)="onPresentationSelect($event)"
               [label]="'Choose presentation'">
    </nv-select>

    <ng-container *ngIf="materialsManipulation && availableMaterials?.length">
        <br>
        <nv-select [multi]="false"
                   [canEmpty]="false"
                   [options]="availableMaterials"
                   [(ngModel)]="selectedMaterial"
                   (ngModelChange)="onMaterialSelect($event)"
                   [label]="'Choose material'">
        </nv-select>
    </ng-container>

    <ng-container *ngIf="(materialsManipulation && selectedMaterial) || (!materialsManipulation && selectedPresentation) && maxDuration">
        <br>
        <small>{{!materialsManipulation? 'Presentation' : 'Material'}} duration: {{maxDuration | timeFormat}} = {{maxDuration}}s</small>
        <br><br>
    </ng-container>
    <div style="display: flex" *ngIf="durationForm">
        <form [formGroup]="durationForm">
            <nv-input *ngIf="(materialsManipulation && selectedMaterial) || (!materialsManipulation && selectedPresentation)"
                      formControlName="time"
                      type="number"
                      [label]="'Time-spent (seconds)'"
                      [errorMessages]="{ max: 'Max duration of ' + maxDuration + 's exceeded' }">
            </nv-input>
        </form>
        <nv-input *ngIf="(materialsManipulation && selectedMaterial) || (!materialsManipulation && selectedPresentation)"
                  [value]="durationForm?.value?.time | timeFormat"
                  [disabled]="true"
                  style="margin-left: 10px">
        </nv-input>
    </div>
    <br>
    <nv-button size="medium" (click)="addRow()"
               [disabled]="isButtonDisabled()">
        Add attendance
    </nv-button>
</div>
