<h1 translate>Session details</h1>
<div>
<!--    <h3 class="light">{{previewDetails.block.name}}</h3>-->

<!--    <div class="nv-session-detail-meta-box">
        <div class="sdmb-date">
            <h5>{{previewDetails.distribution.starts_at | dateFormat:'dddd'}}</h5>
            <h6>{{previewDetails.distribution.starts_at | dateFormat:'MMM'}}</h6>
            <p>{{previewDetails.distribution.starts_at | dateFormat:'DD'}}</p>
        </div>&lt;!&ndash; /.sdmb-date &ndash;&gt;
        <div class="sdmb-time-n-location">
            <p class="sdmb-time">{{previewDetails.distribution.starts_at | dateFormat:'HH:mm A'}} - {{previewDetails.distribution.ends_at | dateFormat:'HH:mm A'}}</p>
            <p class="sdmb-lcation">{{previewDetails.location.name}}</p>
        </div>&lt;!&ndash; /.sdmb-time-n-location &ndash;&gt;
        <div class="sdmb-tools">
            <a class="sdmb-star" (click)="toggleTimeline()" [ngClass]="{'on' : previewDetails.distribution.timeline && previewDetails.distribution.timeline.length}">
                <span>Add to your selected presentations</span>
            </a>
            &lt;!&ndash;<a class="sdmb-share">&ndash;&gt;
            &lt;!&ndash;<span>Share this somehow</span>&ndash;&gt;
            &lt;!&ndash;</a>&ndash;&gt;
        </div>&lt;!&ndash; /.sdmb-tools &ndash;&gt;
    </div>&lt;!&ndash; /.nv-session-detail-meta-box &ndash;&gt;-->
    <!--<p>{{previewDetails.distribution.starts_at | dateFormat:'DD.MM.YYYY'}}, {{previewDetails.distribution.starts_at | dateFormat:'HH:mm A'}} - {{previewDetails.distribution.ends_at | dateFormat:'HH:mm A'}}</p>-->
    <!--<button (click)="toggleTimeline()" type="button">{{previewDetails.distribution.timeline && previewDetails.distribution.timeline.length ? 'Remove from' : 'Add to'}} timeline</button>-->
    <!--<p>Room: {{previewDetails.location.name}}</p>-->

<!--    <div class="ph-meta">
        <span class="phm-cat" *ngIf="previewDetails.category"><em [style.background-color]="previewDetails.category.color"></em>{{previewDetails.category.name}}</span>
    </div>&lt;!&ndash; /.ph-meta &ndash;&gt;-->

<!--    <div class="nv-planner-sessions" *ngIf="presentations.length">
        <h4>Presentations:</h4>
        <div class="ps-item" *ngFor="let presentation of presentations">
            <div class="psi-time">{{presentation.starts_at | dateFormat:'HH:mm'}}<br>{{presentation.starts_at | dateFormat:'A'}}</div>
            <div class="psi-body">
                <div class="nv-participant">
                    <div class="p-img"><span [style.backgroundImage]="'url('+ presentation.speaker_image_thumb +')'"></span></div> &lt;!&ndash; < <img [src]="presentation.speaker_image_thumb || '../../../assets/avatar-placeholder.png'"> &ndash;&gt;
                    <div class="p-title-name">
                        <h4><a (click)="closeModal('session-details')" [routerLink]="['/','presentation', presentation.id]">{{presentation.title}}</a></h4>
                        <p>{{presentation.speaker_name}}</p>
                    </div>&lt;!&ndash; /.p-name &ndash;&gt;
                </div>&lt;!&ndash; /.nv-participant &ndash;&gt;
            </div>&lt;!&ndash; /.psi-body &ndash;&gt;
            <div class="ph-star" (click)="toggleSingle(presentation)" [ngClass]="{'on' : presentation.timeline}"></div>
        </div>&lt;!&ndash; /.ps-item &ndash;&gt;
    </div>&lt;!&ndash; /.nv-planner-sessions &ndash;&gt;-->

    <!--<br><br>-->

    <!--<p>Category:-->
    <!--<span [style.background-color]="previewDetails.category.color" style="width: 20px; height: 20px; display: inline-block;"></span>-->
    <!--{{previewDetails.category.name}}-->
    <!--</p>-->
    <!--<ul>-->
    <!--<li *ngFor="let presentation of presentations">-->
    <!--<img [src]="presentation.speaker_image_thumb || '../../../assets/avatar-placeholder.png'">-->
    <!--<p>{{presentation.starts_at | dateFormat:'DD.MM.YYYY'}}, {{presentation.starts_at | dateFormat:'HH:mm'}} - {{presentation.ends_at | dateFormat:'HH:mm'}}</p>-->
    <!--<button type="button" (click)="toggleSingle(presentation)">{{presentation.timeline ? 'Remove from' : 'Add to'}} timeline</button>-->
    <!--<p>-->
    <!--<a [routerLink]="['../../..','presentation', presentation.id]">-->
    <!--{{presentation.title}}-->
    <!--</a>-->
    <!--</p>-->
    <!--<p>{{presentation.speaker_name}}</p>-->
    <!--</li>-->
    <!--</ul>-->
</div>
