<div class="nv-ls-config-toolbox">
  <header class="nv-ls-config-toolbox-header">
    <h3>Video Conferencing</h3>
  </header>
  <div class="nv-ls-config-toolbox-body" style="height: 100%; overflow: auto;">
    <div class="nv-ls-config-toolbox-body-port">
      <div class="nv-global-loading on" [class.on]="loading">
        <div class="nv-spinner"></div>
      </div>
        <ng-container *ngIf="activeRoom === 'conference'">
          <nv-meeting
            [autoJoin]="true"
            [conferenceId]="_conferenceId"
            [entityType]="'Conference'"
            [entityId]="_conferenceId"
            [currentUser]="_currentUser"
            (closing)="close()"
          ></nv-meeting>
        </ng-container>
        <ng-container *ngIf="activeRoom === 'user'">
          <nv-meeting
            [autoJoin]="true" [autoCreate]="true"
            [conferenceId]="_conferenceId"
            [entityType]="'User'"
            [entityId]="_currentUser.id"
            [currentUser]="_currentUser"
            (closing)="close()"
          ></nv-meeting>
        </ng-container>
        <ng-container *ngIf="activeRoom === 'location'">
          <nv-meeting
            [autoJoin]="true"
            [conferenceId]="_conferenceId"
            [entityType]="'Location'"
            [entityId]="_locationId"
            [currentUser]="_currentUser"
            (closing)="close()"
          ></nv-meeting>
        </ng-container>        
      </div>
      <ng-container *ngIf="!activeRoom">
        <div class="nv-ls-conferencing-rooms">
          <div class="nv-ls-conferencing-room live"><!-- < additional class 'active' -->
            <div class="nv-ls-conferencing-room-txt">
              <ng-container *ngIf="locationMeetingRoom">
                <h4>{{ locationMeetingRoom.title }}</h4>
                <p>This room is being broadcasted. By entering this room you will become visible to the audience on the live stage. 
                  Follow your agenda and join this room just when it's your turn to present live. </p>
                <p class="nv-ls-crt-btn">
                  <a
                    (click)="activeRoom = 'location'"
                    class="nv-btn medium enter-live"
                    data-icon-pl="&#xe94a;"
                    title="Live room"
                    >Enter the live room</a
                  >
                </p>                
              </ng-container>
              <ng-container *ngIf="!locationMeetingRoom">
                <h4>Live Meeting room</h4>
                <p>
                  The organizer still didn't prepare this live room. Check back later. 
                </p>              
              </ng-container>

            </div>
          </div>
          <div class="nv-ls-conferencing-side-rooms">
            <div class="nv-ls-conferencing-room side">
              <div class="nv-ls-conferencing-room-txt">
                <h4>Waiting room</h4>
                <p>Enter here to chat with your colleagues and get ready to present before entering the live room. This room will not be broadcasted.</p>
                <p class="nv-ls-crt-btn">
                  <a 
                    (click)="activeRoom = 'conference'"
                    class="nv-btn small primary" 
                    title="Waiting room"
                    >Waiting room</a
                  >
                </p>
              </div>
            </div>
            <div class="nv-ls-conferencing-room side">
              <div class="nv-ls-conferencing-room-txt">
                <h4>Personal room</h4>
                <p>This is your personal video room. No one except you can join here. Use this room to test your camera, microphone, screen sharing, etc...</p>
                <p class="nv-ls-crt-btn">
                  <a 
                    (click)="activeRoom = 'user'"
                    class="nv-btn small primary" 
                    title="Personal room"
                    >My room</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>        
      </ng-container>
  </div>
</div>
