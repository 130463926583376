import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (localStorage.getItem('jwtToken')) {
      return of(true);
    } else {
      const queryParams: any = { returnUrl: encodeURI(route.url.join('/')) };
      if (route.queryParams.email) { queryParams.email = route.queryParams.email }
      this.router.navigate(['/profile/login'], { queryParams: queryParams });
    }
  }
}
