import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Website } from '@navus/core/classes/website';
import { User } from '@navus/core/classes/user';
import { UserService } from '@navus/core/services/user.service';
import { WebsitePage } from '@navus/core/classes/website-page';
import { BookmarkService } from '@navus/core/services/bookmark.service';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { WebsiteService } from '@navus/core/services/website.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { WebsiteControlService } from './services/website-control.service';
import { TrackingService } from '@navus/core/services/tracking.service';
import { InactivityService } from '@navus/core/services/inactivity.service';

@Component({
  selector: 'nv-website',
  templateUrl: './website.component.html'
})
export class WebsiteComponent implements OnInit, OnDestroy {
  @HostBinding('class') websiteClass: string = '';
  website: Website;
  user: User;

  editMode = false;
  selected = '';
  selectedPage: WebsitePage = null;
  selectedSection: WebsitePageSection = null;
  currentPage: WebsitePage = null;
  chatSettings = null;

  showFooter: boolean = false;
  showChat: boolean = true;
  showStillWatching: boolean = false;

  inactivityModal;

  loadingPublish: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private bookmarkService: BookmarkService,
    private websiteService: WebsiteService,
    private websiteControlService: WebsiteControlService,
    private modalService: ModalService,
    private translate: TranslateService,
    private trackingService: TrackingService,
    private inactivityService: InactivityService,
  ) { }

  ngOnInit() {
    this.website = this.route.snapshot.data.website;
    this.chatSettings = this.website.conferences.find(c => c.id === this.website.active_conference_id).settings?.chat_settings;
    this.websiteClass = `website-${this.website.id} ${this.website.theme}`;
    // this.websiteClass = `website-${this.website.id} dark-skin`;

    this.translate.setDefaultLang(this.website.default_language || 'en');

    // Track if user stepped away from the device so we can pause tracking
    this.initInactivityTracking();

    if (this.website.id === 279) {
      if (!localStorage.getItem('welcomed')) {
        this.modalService.defaultModal({
          title: 'Welcome',
          body:
            '<p>Welcome. This website is a demo website for a potential virtual conference. All content is for example and demonstration purposes only. For more details on some features click on the (i) icon.</p>' +
            '<p>You can build an event website like this demo website with a few simple steps by using our platform. For the full experience also download the mobile app of this demo virtual event.</p>' +
            '<p>Enjoy browsing the demo. If you like it please share on Social Media.</p>',
          buttons: [
            { text: 'OK', role: 'cancel' }
          ]
        });
        localStorage.setItem('welcomed', 'yes');
      }
    }

    if (this.website.settings.custom_css) {
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.type = 'text/css';
      style.id = 'custom_css';
      style.appendChild(document.createTextNode(this.website.settings.custom_css));
      head.appendChild(style);
    }

    this.trackPageView(this.router.url);
    const routerSubscription = this.router.events
      .subscribe(
        (routerEvent: any) => {
          if (routerEvent instanceof NavigationStart) {
            if (routerEvent.url === '/') {
              const landingPage = new WebsitePage(this.website.pages.find(page => page.landing));
              this.router.navigateByUrl(landingPage.resolveUrl());
            }
            
            if (routerEvent.url.includes('/live-stage')) {
              this.showChat = false;
            } else {
              this.showChat = true;
            }
            
            if (routerEvent.url.includes('/live-stage') || routerEvent.url.includes('/material')) {
              this.showStillWatching = true;
            } else {
              this.showStillWatching = false;
            }

            if (routerEvent.url.includes('/material/') || routerEvent.url.includes('/live-stage') || routerEvent.url.includes('/profile')) {
              this.showFooter = false;
            } else {
              this.showFooter = true;
            }
          }
          if (routerEvent instanceof NavigationEnd) {
            this.trackPageView(routerEvent.url);
          }
        }
      );
    this.subscriptions.push(routerSubscription);

    // TODO Move this to app
    this.userService.getCurrentUser({ include: 'delegates' }).subscribe();
    const userSubscription = this.userService.currentUser
      .subscribe(
        (user) => {
          this.user = user;
          if (user) {
            setTimeout(() => {
              this.trackingService
                .identify(user.id);
            }, 500);
          }
          this.bookmarkService
            .getWebsiteBookmarks(this.website.id, !!user)
            .subscribe();
        }
      );
    this.subscriptions.push(userSubscription);

    const pageSubscription = this.websiteService.currentWebsitePage
      .subscribe(
        (page) => {
          this.currentPage = page;

          if (this.website.settings.footer && this.website.settings.footer !== '') {
            if (page) {
              this.showFooter = page.show_footer;
            } else {
              setTimeout(() => {
                const url = this.router.url;
                if (url.includes('/material/') || url.includes('/live-stage') || url.includes('/profile')) {
                  this.showFooter = false;
                } else {
                  this.showFooter = true;
                }
              }, 500);
            }            
          }

          setTimeout(() => {
            if (this.router.url.includes('/live-stage')) {
              this.showChat = false;
            } else {
              this.showChat = true;
            }
          }, 500);
        }
      );
    this.subscriptions.push(pageSubscription);

    this.editMode = !!localStorage.getItem('edit');
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.trackPageView(this.router.url);
  }

  @HostListener('window:beforeunload', ['$event'])
  @HostListener('window:pagehide', ['$event'])
  beforeunloadHandler(event: any) {
    return this.trackingService.unloadTracking();
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychangeHandler(event: any) {
    if (document.visibilityState === 'hidden') {
      this.trackingService.pauseTracking();
    }
    if (document.visibilityState === 'visible') {
      this.trackingService.resumeTracking();
    }
  }

  initInactivityTracking() {
    this.inactivityService.idle$
      .subscribe(() => {
        if (this.showStillWatching && !this.inactivityModal) {
          this.inactivityModal = this.modalService.defaultModal({
            body: `Are you still watching?`,
            buttons: [
              {
                text: 'Yes',
                handler: () => {
                  this.inactivityModal.close();      
                  this.inactivityModal = null;  
                  this.trackingService.resumeTracking();
                }
              }
            ]
          });   
          this.trackingService.pauseTracking();       
        }
      });

    this.inactivityService.wake$
      .subscribe(() => {
        if (this.inactivityModal) {
          this.inactivityModal.close();      
          this.inactivityModal = null;   
          this.trackingService.resumeTracking();
        }
      });
  }

  showPage(page?: WebsitePage) {
    this.selected = 'page';
    this.selectedPage = page;
  }

  exitEditMode() {
    localStorage.removeItem('edit');
    this.editMode = false;
  }

  reloadWebsite() {
    this.websiteService.getCurrentWebsite({ include: 'pages,conferences' })
      .subscribe((website) => this.website = website );
  }

  togglePublish() {
    this.loadingPublish = true;
    this.websiteService.updateOrganizationWebsite(this.website.organization_id, this.website.id, { published: !this.website.published })
      .subscribe(
        (response) => {
          this.website.published = !this.website.published;
          this.reloadWebsite();
          this.loadingPublish = false;
        },
        () => {
          this.loadingPublish = false;
        }
      );
  }


  // Tracking
  trackPageView(pageUrl: string = null) {
    let entityType, entityId, info;

    if (pageUrl.includes('/page/')) {
      entityType = 'page';
      entityId = pageUrl.split('/')[1];
    } else if (pageUrl.includes('/sponsor/')) {
      entityType = 'sponsor';
      entityId = pageUrl.split('/')[2];
    } else if (pageUrl.includes('/article/')) {
      entityType = 'article';
      entityId = pageUrl.split('/')[2];
    } else if (pageUrl.includes('/material/')) {
      entityType = 'material';
      entityId = pageUrl.split('/')[4];
      info = { presentation_id: +pageUrl.split('/')[2] };
    } else if (pageUrl.includes('/presentation/')) {
      entityType = 'presentation';
      entityId = pageUrl.split('/')[2];
    } else if (pageUrl.includes('/live-stage')) {
      entityType = 'live-stage';
      entityId = null;
    } else if (pageUrl.includes('/posters')) {
      entityType = 'posters';
      entityId = null;
    } else if (pageUrl.includes('/search')) {
      entityType = 'search';
      entityId = null;
    } else if (pageUrl.includes('/profile')) {
      entityType = 'profile';
      entityId = null;
    } else if (pageUrl.includes('/program')) {
      entityType = 'program';
      entityId = null;
    } else if (pageUrl.includes('/sponsors')) {
      entityType = 'sponsors';
      entityId = null;
    } else if (pageUrl.includes('/articles')) {
      entityType = 'articles';
      entityId = null;
    } else if (pageUrl.includes('/speakers')) {
      entityType = 'speakers';
      entityId = null;
    } else if (pageUrl.includes('/delegates')) {
      entityType = 'delegates';
      entityId = null;
    } else {
      entityType = 'other';
      entityId = null;
    }

    if (this.user) {
      if (!info) { info = {} }
      info.user_id = this.user.id;
    }

    this.trackingService.startTracking(
      this.website.active_conference_id, 
      entityType,
      entityId,
      'BROWSING',
      info || null
    );
  }
}
