import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TwitterSectionConfig } from './twitter-section.config';

@Component({
  selector: 'nv-twitter-section-configuration',
  templateUrl: './twitter-section-configuration.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TwitterSectionConfigurationComponent),
      multi: true
    }
  ]
})

export class TwitterSectionConfigurationComponent implements OnInit, ControlValueAccessor {
  @Input() value: TwitterSectionConfig;

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  ngOnInit() {}

  writeValue(value: any): void {
    this.value = new TwitterSectionConfig(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
  trackByIdx(index: number, obj: any): any {
    return index;
  }

  addCriterion() {
    if (!this.value.criteria) {
      this.value.criteria = [];
    }

    this.value.criteria.push('');
  }

  removeCriterion(criteria) {
    const index = this.value.criteria.indexOf(criteria);
    this.value.criteria.splice(index, 1);
  }

}
