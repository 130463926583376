<div class="nv-row gap-30 propagate" *ngIf="value">
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-checkbox
                    label="Hide Events"
                    [(ngModel)]="value.hideConferences"
                    (change)="onChange(value)"
            ></nv-checkbox>
        </div>
    </div>
</div>
