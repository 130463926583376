<ng-container>
    <span aria-label="label-title" class="label-title">{{ label }}</span>
    <ngx-codemirror
            (ngModelChange)="onChangeValue($event)"
            (blur)="displayError()"

            [options]="codeEditorOptions"
            [(ngModel)]="value"
    ></ngx-codemirror>
    <div *ngIf="errors.length > 0" class="nv-input-error-box">
        <div *ngIf="errors.length === 1" class="nv-input-error single">
            <div>{{ errorMessages[errors[0]] || errors[0] }}</div>
        </div>
        <div *ngIf="errors.length > 1" class="nv-input-error multi">
            <p (click)="toggleErrors()">
                <span>You have {{ errors.length }} errors</span>
            </p>
            <div *ngIf="showErrors">
                <ul>
                    <li *ngFor="let error of errors">
                        {{ errorMessages[error] || error }}
                    </li>
                </ul>
            </div>
        </div>
    </div><!-- /nv-input-error-box -->
</ng-container>

