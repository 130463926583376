import { NgModule } from '@angular/core';
import { NavusCoreModule } from '@navus/core/navus-core.module';
import { NavusUiModule } from '@navus/ui/navus-ui.module';
import { SharedModule } from '../../../shared';
import { RecommendationsPageComponent } from './pages/recommendations/recommendations-page.component';
import { SponsorshipCampaignsPageComponent } from './pages/sponsorship-campaigns/sponsorship-campaigns-page.component';

@NgModule({
  imports: [
    NavusCoreModule.forRoot(),
    NavusUiModule,
    SharedModule
  ],
  declarations: [
    RecommendationsPageComponent,
    SponsorshipCampaignsPageComponent
  ],
  entryComponents: [],
  providers: []
})
export class AiModule {
}
