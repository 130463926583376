<div class="nv-row gap-30 propagate" *ngIf="value">
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-text-editor
                    label="Content"
                    [organizationId]="organizationId"
                    [(ngModel)]="value.content"
                    (ngModelChange)="onChange(value)"
            ></nv-text-editor>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-select
                    label="Spacing"
                    [(ngModel)]="value.spaced"
                    (ngModelChange)="onChange(value)"
                    [options]="spacingOptions"
            ></nv-select>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-input
                    label="Background"
                    [(ngModel)]="value.background"
                    (ngModelChange)="onChange(value)"
            ></nv-input>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-checkbox
                    label="Guttered"
                    [(ngModel)]="value.guttered"
                    (ngModelChange)="onChange(value)"
            ></nv-checkbox>
        </div>
    </div>
</div>

