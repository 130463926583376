import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ConferenceService } from '@navus/core/services/conference.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'main [nv-question-list-page]',
  templateUrl: './question-list-page.component.html'
})
export class QuestionListPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  questions: any[] = [];

  pagination: { page?: number, perPage: number, total?: number } = {
    perPage: 30
  };
  sort: { field: string, direction: string } = {
    field: 'created_at',
    direction: 'desc'
  };
  interval: any;

  questionLoading: boolean = false;
  optionsShown: number = null;

  formFilter: FormGroup;
  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private conferenceService: ConferenceService,
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      search_term: ['']
    });
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getQuestions();
      }
    );
    this.subscriptions.push(subscription);

    this.interval = setInterval(() => {
      this.getQuestions();
    }, 60 * 1000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getQuestions(append: boolean = false) {
    if (append && this.pagination.total && this.questions.length >= this.pagination.total) { return; }

    this.pagination.page = append ? this.pagination.page + 1 : 1;
    const params: any = {
      page: this.pagination.page,
      per_page: this.pagination.perPage,
      sort_by: this.sort.field,
      sort_direction: this.sort.direction,
      include: 'author'
    };
    if (this.formFilter.value.search_term !== '') {
      params.search_term = this.formFilter.value.search_term;
    }
    this.questionLoading = !append;
    this.conferenceService.getConferenceMessages(this.conferenceId, params)
      .subscribe(
        (response: { data: any[], meta: any }) => {
          if (append) {
            this.questions = this.questions.concat(response.data);
          } else {
            const port = document.getElementsByClassName('nv-config-content-port');
            if (port && port.length > 0) {
              port[0].scrollTop = 0;
            }
            
            this.questions = response.data;
            this.pagination.total = response.meta.pagination.total;
          }
          this.questionLoading = false;
        },
        () => {
          this.questionLoading = false;
        }
      );
  }

  approve(message: any) {
    this.conferenceService
      .updateConferenceMessage(this.conferenceId, message.id, { status: 'approved' })
      .subscribe(
        (response: { data: any[], meta: any }) => {
          message.status = 'approved';
          this.questionLoading = false;
        },
        () => {
          this.questionLoading = false;
        }
      );
  }

  reject(message: any) {
    this.conferenceService
      .updateConferenceMessage(this.conferenceId, message.id, { status: 'rejected' })
      .subscribe(
        (response: { data: any[], meta: any }) => {
          message.status = 'rejected';
          this.questionLoading = false;
        },
        () => {
          this.questionLoading = false;
        }
      );
  }

  close() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  openOptions(question: any) {
    event.stopPropagation();
    this.optionsShown = question.id;
  }

  closeOptions() {
    event.stopPropagation();
    this.optionsShown = null;
  }

}
