<div class="nv-modal extra-large">
    <div class="nv-modal-header">
        <h4 class="nv-modal-title">Placeholder page - Coming soon</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body" *ngIf="website">
        <div>
            <img [src]="website.logo" *ngIf="website.logo">
            <h2>{{ website.name }}</h2>
            <h4>
                <span>{{ conference.starts_at | nvDate:'MMM, dd-' }}</span>
                <span>{{ conference.ends_at | nvDate:'dd, ' }}</span>
                <span>{{ conference.starts_at | nvDate:'yyyy' }}</span>
                <span *ngIf="conference.location"> • {{ conference.location }}</span>
            </h4>
            <p [innerHtml]="website.description | nvHtml"></p>
        </div>
    </div>
</div>

