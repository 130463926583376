import { Component, Input, Output, EventEmitter, ViewChild, HostListener, AfterContentInit, OnDestroy, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { ActionMenuOriginDirective } from './action-menu-origin.directive';
import { ActionMenuDropdownDirective } from './action-menu-dropdown.directive';

@Component({
    selector: 'app-action-menu',
    templateUrl: './dropdown.component.html'
})
export class ActionMenuComponent implements AfterContentInit, OnDestroy {

    @ViewChild(ActionMenuOriginDirective) origin: ActionMenuOriginDirective;
    @ViewChild(ActionMenuDropdownDirective) dropdown: ActionMenuDropdownDirective;

    @Input() items: any;
    @Input() placeholder: string = 'Select item from the list';
    @Output() deleteAction = new EventEmitter<any>();
    @Output() selectAction = new EventEmitter<any>();
    @Output() editAction = new EventEmitter<any>();

    private open = false;
    private originSub: Subscription;

    constructor(private host: ElementRef) {}

    @HostListener('document:click', ['$event.target'])
    click(target) {
        if (!(this.host.nativeElement as HTMLElement).contains(target)) {
            this.close();
        }
    }

    ngAfterContentInit() {
        this.originSub = this.origin.click.subscribe(_ => {
            this.open = !this.open;
            if (this.open) {
                this.openMenu();
            } else {
                this.close();
            }
        });
    }

    openMenu() {
        this._toggleDropdown();
    }

    deleteItem(item) {
        this.deleteAction.emit(item);
    }

    selectItem(item) {
        this.selectAction.emit(item);
        this.placeholder = item.name;
        this.close();
    }

    editItem(item) {
        this.editAction.emit(item);
    }

    close() {
        this.open = false;
        this._toggleDropdown(false);
    }

    private _toggleDropdown(show = true) {
        const display = show ? 'block' : 'none';
        this.dropdown.element.style.display = display;
    }

    ngOnDestroy() {
        this.originSub && this.originSub.unsubscribe();
        this.close();
    }
}
