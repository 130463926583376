import { Component, Input } from '@angular/core';
import { ConferenceService } from '@navus/core/services/conference.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'nv-conference-access-modal',
  templateUrl: './conference-access-modal.component.html'
})

export class ConferenceAccessModalComponent {
  @Input() conferenceId: number;
  @Input() points: number = 0;
  @Input() text: string = '';
  @Input() buttonLabel: string = 'Register';
  @Input() hideButton: boolean = false;
  @Input() hideCode: boolean = false;

  accessCode: string = '';
  errorCode: boolean = false;
  loading: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private conferenceService: ConferenceService,
    private router: Router
  ) {}

  close() {
    this.activeModal.dismiss();
  }

  purchaseTicket() {
    this.activeModal.dismiss();
    this.router.navigate(['/delegate-registration']);
  }

  applyCode() {
    if (this.accessCode === '') { return; }

    this.loading = true;
    this.conferenceService.grantAccessWithCode(this.conferenceId, this.accessCode)
      .subscribe(
        (response: any) => {
          this.loading = false;
          this.activeModal.close();
        },
        () => {
          this.loading = false;
          this.errorCode = true;
        }
      );
  }

}
