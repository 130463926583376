import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@navus/ui/modal/modal.service';
import { Conference } from '../classes/conference';
import { Presentation } from '../classes/presentation';
import { User } from '../classes/user';
import { ApiService } from './api.service';

@Injectable()
export class AuthService {

  constructor(
    private apiService: ApiService,
    private modalService: ModalService,
    private router: Router,
  ) { }

  getConferenceInvitations(resourceType: string, resourceId: number, conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/invitations/${resourceType}/${resourceId}`, params);
  }

  createConferenceInvitation(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/invitations`, params);
  }

  cancelConferenceInvitations(conferenceId: number, invitationId: string) {
    return this.apiService.delete(`conferences/${conferenceId}/invitations/${invitationId}`);
  }


  hasPresentationAccess(conference: Conference, presentation: Presentation, user: User): Promise<Boolean> {
    if (!conference || !presentation || !user) {
      return Promise.reject();
    }

    const usingDelegateRegistration = conference.settings?.delegate_registration?.enabled;
    const usingTickets = conference.settings?.delegate_registration?.ticketing;

    const delegate = user.delegates.find(d => d.conference_id === conference.id);

    const minAccessRight = presentation.min_access_right;
    const isDelegate = !!delegate;
    const isMember = !!user.member;
    const isBlockAvailableForTicket = delegate ?
    (presentation.accessible_by_tickets.length > 0 ? 
      presentation.accessible_by_tickets.indexOf(delegate.conference_ticket_type_id) > -1
      : true
    ) : false;

    switch (minAccessRight) {
      case 'guest':
        return Promise.resolve(true);
      case 'delegate':
        if (isDelegate || isMember) {
          return Promise.resolve(usingTickets ? isBlockAvailableForTicket : true);
        } else {
          return Promise.resolve(false);
        }
      case 'member':
        if (isMember) {
          return Promise.resolve(usingTickets ? isBlockAvailableForTicket : true);
        } else {
          return Promise.resolve(false);
        }
      default:
        return Promise.resolve(false);
    }
  }

  requestPresentationAccess(conference: Conference, presentation: Presentation, user: User): Promise<Boolean> {
    if (!conference || !presentation) {
      return Promise.reject();
    }

    const usingDelegateRegistration = conference.settings?.delegate_registration?.enabled;
    const usingTickets = conference.settings?.delegate_registration?.ticketing;

    const delegate = user?.delegates.find(d => d.conference_id === conference.id);

    const minAccessRight = presentation.min_access_right;
    const isDelegate = !!delegate;
    const isMember = !!user?.member;
    const isBlockAvailableForTicket = delegate ?
    (presentation.accessible_by_tickets.length > 0 ? 
      presentation.accessible_by_tickets.indexOf(delegate.conference_ticket_type_id) > -1
      : true
    ) : false;

    if (!user) {
      this.promptLogin();
    } else if (!isDelegate) {
      this.promptCode('delegate');
    } else {
      if (!isMember && minAccessRight === 'member') {
        this.promptCode('member');
      } else if (!isBlockAvailableForTicket) {
        this.promptCode('ticket');
      } else {
        this.promptRejection();
      }
    }
  }

  promptLogin() {
    this.modalService.defaultModal({
      title: 'Not logged in',
      body: 'You need to be logged in to continue',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Log in',
          handler: () => {
            this.router.navigate(['/profile/login'], { queryParams: { returnUrl: this.router.url } });
          }
        },
      ]
    });
  }

  promptRejection() {
    this.modalService.defaultModal({
      title: 'No access',
      body: "Based on your current access rights, you don't have permission to view this content. Please contact support if you think that this information is not correct."
    });
  }

  promptCode(option: string) {
    // const modalRefAccess = this.modalService.open(ConferenceAccessModalComponent);
    // modalRefAccess.componentInstance.conferenceId = this.conferenceId;

    // //simple delegate case
    // let text = `To view this session you need to be a delegate. If you are already a delegate, please enter the access/PIN code or click on the "Register" button bellow.`;
    // let buttonLabel = `Register`;

    // let exitStatement = false;

    // switch (option) {
    //   case 'delegate':
    //     if (!this.usingDelegateRegistration) {
    //       modalRefAccess.componentInstance.hideButton = true;
    //       text = `To watch this content you need to be a delegate. Registration is closed, but if you are already a delegate or a member, please enter the access/PIN code.`;
    //     }
    //     break;
    //   case 'member':
    //     modalRefAccess.componentInstance.hideButton = true;
    //     text = `To watch this content you need to be a member. Please enter the access/PIN code.`;
    //     break;
    //   case 'ticket':
    //     if (this.usingDelegateRegistration && this.usingTickets && !this.currentDelegate.conference_ticket_type_id) {
    //       text = `To watch this content you need to have a valid ticket. Please enter the access/PIN code if you are already a delegate with a valid ticket or click on the "Get ticket" button bellow.`;
    //       buttonLabel = `Get ticket`;
    //     } else if (this.usingDelegateRegistration && this.usingTickets && this.currentDelegate.conference_ticket_type_id) {
    //       text = `To watch this content you need to upgrade your ticket. Please enter the access/PIN code or click on the "Upgrade ticket" button bellow.`;
    //       buttonLabel = `Upgrade ticket`;
    //       modalRefAccess.componentInstance.hideCode = true;
    //     } else {
    //       text = `To watch this content you need to have a valid ticket. Registration is closed, but if you are already a delegate with a valid ticket, please enter the access/PIN code or contact support.`;
    //       modalRefAccess.componentInstance.hideButton = true;
    //     }
    //     break;
    // }

    // if (exitStatement) {
    //   this.promptRejection();
    //   return;
    // }

    // modalRefAccess.componentInstance.text = text;
    // modalRefAccess.componentInstance.buttonLabel = buttonLabel;
    // modalRefAccess.result.then(
    //   (result) => {

    //   },
    //   () => { }
    // );
  }



}
