<section class="nv-section guttered" [class.edit-mode]="mouseOver">
    <ng-container *ngIf="mouseOver">
        <a class="nv-section-add-top" title="Add section" (click)="createBefore()"></a>
        <a class="nv-section-add-bottom" title="Add section" (click)="createAfter()"></a>
        <div class="nv-section-edit-tool-box">
            <a class="edit" title="Edit section" (click)="edit()"></a>
            <a class="delete" title="Delete section" (click)="delete()"></a>
        </div><!-- /.nv-section-edit-tool-box -->
        <div class="nv-section-sort-tool-box">
            <a class="move-up" title="Move up" (click)="moveUp()"></a>
            <a class="move-down" title="Move down" (click)="moveDown()"></a>
        </div><!-- /.nv-section-sort-tool-box -->
    </ng-container>
    
    <div *ngIf="loadingConferences" style="min-height: 618px;" class="nv-loading">
        <div class="nv-loading-visual" translate>Loading</div>
    </div>
    <div *ngIf="!loadingConferences" class="nv-port">
        <div class="nv-row large-spaced gap-40">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <div class="nv-widget-box padded">
                        <header *ngIf="configuration.title && configuration.title !== ''" class="wb-header">
                            <h2>{{ configuration.title }}</h2>
                        </header><!-- /.wb-header -->
                        <div class="wb-body">
                            <!--div class="nv-search-bar-plain">
                                <input type="text" placeholder="Enter the name of the event">
                            </div-->

                            <div class="nv-events-row">
                                <ngx-slick-carousel class="carousel er-port" [config]="conferencesSliderConfig">
                                    <div *ngFor="let conference of conferences" ngxSlickItem (click)="goToConferencePresentations(conference)" class="slide er-item">
                                        <a title="{{conference.name}}"><span nvBackgroundImage [image]="conference?.logo"></span></a>
                                        <p title="{{conference.name}}">{{conference.name}}</p>
                                    </div>
                                </ngx-slick-carousel>
                            </div>
                        </div><!-- /.wb-body -->
                    </div><!-- /nv-widget-box padded -->
                </div>
            </div>
        </div>
    </div><!-- /.nv-port -->
</section><!-- /.nv-section -->

