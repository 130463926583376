<section class="nv-section guttered nv-delegate-list-section" [class.edit-mode]="mouseOver">
    <ng-container *ngIf="mouseOver">
        <a class="nv-section-add-top" title="Add section" (click)="createBefore()"></a>
        <a class="nv-section-add-bottom" title="Add section" (click)="createAfter()"></a>
        <div class="nv-section-edit-tool-box">
            <a class="edit" title="Edit section" (click)="edit()"></a>
            <a class="delete" title="Delete section" (click)="delete()"></a>
        </div><!-- /.nv-section-edit-tool-box -->
        <div class="nv-section-sort-tool-box">
            <a class="move-up" title="Move up" (click)="moveUp()"></a>
            <a class="move-down" title="Move down" (click)="moveDown()"></a>
        </div><!-- /.nv-section-sort-tool-box -->
    </ng-container>

    <div *ngIf="loadingDelegates" style="min-height: 618px;" class="nv-loading">
        <div class="nv-loading-visual" translate>Loading</div>
    </div>
    <div *ngIf="!loadingDelegates" class="nv-port">
        <div *ngIf="configuration.title && configuration.title !== ''" class="nv-row large-spaced-top small-spaced-bottom gap-30">
            <div class="nv-column desk-12">
                <div class="nv-column-content" style="text-align: center;">
                    <h2>{{ configuration.title }}</h2>
                </div><!-- /.nv-column-content -->
            </div><!-- /nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row small-spaced-top spaced-bottom gap-30 flow-center" *ngIf="!delegate || (delegate && usingTickets && !delegate.conference_ticket_type_id)">
            <div class="nv-column desk-12">
                <div class="nv-column-content" style="text-align: center;">
                    <p>To view this page you have to be registered delegate for this event.</p>
                    <p *ngIf="!usingRegistration">If you think that this information is not correct, please contact support.</p>
                    <div *ngIf="usingRegistration" class="foo-btn">
                        <a [routerLink]="['/delegate-registration']" class="nv-btn accent1 large">Register here</a>
                    </div>
                </div>
            </div>
        </div><!-- /.nv-row -->
        <div 
            class="nv-row small-spaced-top spaced-bottom gap-30 flow-center" 
            *ngIf="(!usingTickets && delegate) || (usingTickets && delegate && delegate.conference_ticket_type_id)">
            <div *ngFor="let delegate of delegates" class="nv-column desk-3 tab-4 phab-6 phone-12">
                <div class="nv-column-content" (click)="openModal(delegate)">
                    <div class="nv-speaker-teaser">
                        <div class="st-svatar">
                            <a title="{{ delegate?.name || delegate.first_name + ' ' + delegate.last_name }}">
                                <span nvBackgroundImage [image]="delegate.image_url_thumb || delegate.image_url"></span>
                            </a>
                        </div><!-- /.st-svatar -->
                        <h3>
                            <a title="{{ delegate.name || delegate.first_name + ' ' + delegate.last_name}}">{{(delegate.title && delegate.title !== "") ? delegate.title + " " : ""}}{{ delegate.name || delegate.first_name + ' ' + delegate.last_name }}</a>
                        </h3>
                        <p *ngIf="delegate?.contact_info?.institution && delegate?.contact_info?.institution !== ''">{{ delegate?.contact_info?.institution }}</p>
                    </div><!-- /.delegate-teaser -->
                </div><!-- /.em-column-content -->
            </div><!-- /nv-column -->
            <!--<div *ngIf="viewAll" class="nv-column-content" style="text-align: center;">-->
            <!--<a href="delegates" class="nv-btn hollow neutral medium">{{viewAll}}</a>-->
            <!--</div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
        </div><!-- /.nv-row -->
        <div 
            *ngIf="conferenceId!=8315 && pagination?.total > configuration.limit && ((!usingTickets && delegate) || (usingTickets && delegate && delegate.conference_ticket_type_id))" 
            class="nv-row flow-center large-spaced-bottom gap-30">
            <div class="nv-column desk-initial">
                <div class="nv-column-content">
                    <nv-pagination
                            class="nv-column-content spaced-bottom"
                            type="thorough"
                            [config]="pagination"
                            (paginationChange)="paginationChange($event)">
                    </nv-pagination>
                </div>
            </div>
        </div>
    </div><!-- /.nv-port -->
</section><!-- /.nv-section -->
