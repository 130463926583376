<div class="nv-page-wrap">
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row spaced-top small-spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content" style="text-align: center">
                        <h1>Navus UI controller</h1>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </div><!-- /.nv-section -->

    <div class="nv-section guttered grayish">
        <div class="nv-port">
            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content" style="text-align: center">
                        <h3>Navus input / nv-input</h3>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <nv-input
            [(ngModel)]="ngModelTest"
            label="ng model test">
            </nv-input>

            <form [formGroup]="testForm" class="nv-row gap-30 propagate spaced flow-center">

                <div class="nv-column desk-6 phab-12">
                    <div class="nv-column-content">
                        <nv-input
                                type="password"
                                max="6"
                                label="Navus input label 1"
                                formControlName="input1"
                                info="Info about input" >
                        </nv-input>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->

                <div class="nv-column desk-6 phab-12">
                    <div class="nv-column-content">
                        <nv-input
                                type="text"
                                label="Navus input label 2"
                                formControlName="input2"
                                info="Input disabled and this gonna make such confusion in neighborhood's grandmas societiy.">
                        </nv-input>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->

                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <nv-input
                                type="textarea"
                                label="Navus input label 3"
                                formControlName="textarea"
                                cols="20"
                                rows="5"
                                info="Info about input textarea">
                        </nv-input>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->

                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <nv-checkbox
                                label="checkbox (with disable validator for button)"
                                formControlName="checkbox" >
                        </nv-checkbox>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->

                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <nv-radio
                                label="radio item"
                                formControlName="radio" >
                        </nv-radio>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->

                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-divider border desk-medium"></div>
                        <div class="nv-btn-box gap-10">
                           <nv-button color="accent1" iconPl="&#xe93d;" (click)="testBtn()" disabled>Learn more</nv-button>
                           <nv-button size="medium" color="accent2" iconPl="&#xe934;" (click)="submitForm(testForm)">Submit</nv-button>
                           <nv-button size="medium" color="neutral" iconPl="&#xe918;" (click)="submitForm(testForm)">Cancel</nv-button>
                           <nv-button color="neutral" [onlyIcon]="true" iconPl="&#xe918;"></nv-button>
                        </div><!-- /.nv-btn-box gap-20 flow-end -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </form><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Navus button/ nv-button</h3>
                        <nv-button icon="&#9432;" (click)="buttonTest()">Click</nv-button>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Navus default modal</h3>
                        <nv-button (click)="openDefaultModal()">Open Modal</nv-button>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Navus alert modal</h3>
                        <nv-button (click)="openAlertModal()">Open Alert with Title</nv-button>
                        <nv-button (click)="openAlertModalWithoutTitle()">Open Alert without Title</nv-button>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Navus confirm modal</h3>
                        <nv-button (click)="openConfirmModal()">Open Modal</nv-button>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Navus error modal</h3>
                        <nv-button (click)="openErrorModal()">Open Error Modal</nv-button>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Navus toast</h3>
                        <nv-button (click)="openToastSuccess()">Open toast success</nv-button>
                        <nv-button (click)="openToastError()">Open toast error</nv-button>
                        <nv-button (click)="openToastWarning()">Open toast warning</nv-button>
                        <nv-button (click)="openToastInfo()">Open toast info</nv-button>
                        <br>
                        <nv-input label="proba"></nv-input>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row">
                <div class="nv column">
                    <div class="nv-column-content">
                        <nv-tabs>
                            <nv-tab title="Breza"></nv-tab>
                            <nv-tab title="Hrast">
                                <p>No scraps hit the floor win-win-win and we need to start advertising on social media enough to wash your face and please advise soonest nor beef up data-point. It just needs more cowbell regroup eat our own dog food.</p>
                                <p>Powerpoint Bunny golden goose. Performance review market-facing, nor window of opportunity synergestic actionables not a hill to die on. You gotta smoke test your hypothesis crank this out yet customer centric i don't want to drain the whole swamp, i just want to shoot some alligators yet fire up your browser, for game-plan.</p>
                            </nv-tab>
                            <nv-tab title="Bukva">
                                <p>Big picture big boy pants we need to dialog around your choice of work attire. Can I just chime in on that one what's our go to market strategy?, thinking outside the box, nor we need to socialize the comms with the wider stakeholder community loop back. Crank this out. It's a simple lift and shift job the horse is out of the barn we need to have a Come to Jesus meeting with Phil about his attitude overcome key issues to meet key milestones, or we need to dialog around your choice of work attire, for drop-dead date, but prioritize these line items. Minimize backwards overflow products need full resourcing and support from a cross-functional team in order to be built, maintained, and evolved put it on the parking lot. Race without a finish line anti-pattern.</p>
                                <p>Five-year strategic plan strategic high-level 30,000 ft view, but we need to future-proof this, nor that jerk from finance really threw me under the bus window of opportunity, so overcome key issues to meet key milestones.</p>
                            </nv-tab>
                            <nv-tab title="Javor"></nv-tab>
                        </nv-tabs>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv column -->
            </div> <!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Navus date/time picker</h3>
                        <div class="nv-row flow-center gap-24 propagate">
                            <div class="nv-column desk-4">
                                <div class="nv-column-content">
                                    <nv-datepicker label="Date Time Picker" [(ngModel)]="dateTest"></nv-datepicker>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-4">
                                <div class="nv-column-content">
                                    <nv-datepicker label="Date picker" type="calendar"></nv-datepicker>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-4">
                                <div class="nv-column-content">
                                    <nv-datepicker label="Time picker" type="timer" (change)="dateTest2($event)"></nv-datepicker>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                        </div><!-- /.nv-row -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <div class="nv-row gap-24">
                            <div class="nv-column desk-4">
                                <div class="nv-column-content">
                                <h3>Navus Select</h3>
                                <nv-select
                                        [options]="dropTestNew"
                                        label="Default select"
                                        showSearch
                                        (searched)="dropTestSearch($event)"
                                        [addButtonText]="'add'"
                                        addButtonShowValue
                                        (addButtonClick)="dropAddTest($event)"
                                        [notFoundText]="'Not Found'"
                                        [emptyStateText]="'Drop is empty'"></nv-select>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-4">
                                <div class="nv-column-content">
                                    <h3>Navus Input</h3>
                                    <nv-input
                                            type="text"
                                            label="Navus input label 2"
                                            info="Input disabled and this gonna make such confusion in neighborhood's grandmas societiy."
                                            disabled>
                                    </nv-input>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-4">
                                <div class="nv-column-content">
                                    <h3>Navus Select</h3>
                                    <nv-select
                                            label="Multi select"
                                            showSearch
                                            addButtonShowValue
                                            multi
                                            [selectedOptions]="dropSelected"
                                            [options]="dropTestNew"
                                            (searched)="dropTestSearch($event)"
                                            [addButtonText]="'add'"
                                            (addButtonClick)="dropAddTest($event)"
                                            [notFoundText]="'Not Found'"
                                            [emptyStateText]="'Drop is empty'">
                                    </nv-select>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                        </div><!-- /.nv-row -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Navus Select</h3>
                        <nv-select
                                [options]="['option1', 'option2', 'option3']"
                                label="Select with search"
                                showSearch>
                        </nv-select>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Navus Select</h3>
                        <nv-select
                                label="Select disabled"
                                disabled></nv-select>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center" [formGroup]="testEditor">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Navus Text Editor</h3>
                        <nv-text-editor
                            [config]="{
                            placeholder: 'text editor'
                            }"
                            formControlName="test"
                            required
                        ></nv-text-editor>
                        <button (click)="testEditor.reset()">Reset</button>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->


            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Navus Tooltip</h3>
                        <nv-tooltip
                                text="Navus tooltip text"
                                position="right">
                            <p>Navus tooltip INNER</p>
                        </nv-tooltip>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Navus Progress Bar</h3>
                        <nv-progress-bar
                        [active] = "progressBarShow"
                        [progress]="progress"></nv-progress-bar>
                        <nv-button (click)="startProgressBar()" *ngIf="progress == 0">Start Progress Bar</nv-button>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Navus Pagination TEST</h3>
                        <nv-pagination
                            [config]="null"
                            [pagesShow]="3"
                            (change)="paginationCurrentPage($event)"></nv-pagination>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Input test</h3>
                        <nv-input value="test value"></nv-input>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Switch</h3>
                        <nv-switch (change)="switchChange($event)"></nv-switch>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Accordions</h3>
                        <nv-accordions
                                (finish)="testAccordion($event)"
                                [disabled]="true">
                            <nv-accordion title="accordion 1 test" [edit]="false">
                                <p>TEST 1 TEST 1</p>
                            </nv-accordion>
                            <nv-accordion title="accordion 2 test" [edit]="false">
                                <p>TEST 2 TEST 2</p>
                            </nv-accordion>
                            <nv-accordion title="accordion 3 test" [edit]="false">
                                <p>TEST 3 TEST 3</p>
                            </nv-accordion>
                            <nv-accordion title="accordion 4 test" [edit]="false">
                                <p>TEST 4 TEST 4</p>
                            </nv-accordion>
                            <nv-accordion title="accordion 5 test" [edit]="false">
                                <p>TEST 5 TEST 5</p>
                            </nv-accordion>
                        </nv-accordions>
                        <nv-accordions
                                (finish)="testAccordion($event)"
                                [disabled]="true">
                            <nv-accordion title="accordion 1 test" [edit]="true">
                                <p>TEST 1 TEST 1</p>
                            </nv-accordion>
                            <nv-accordion title="accordion 2 test" [edit]="true">
                                <p>TEST 2 TEST 2</p>
                            </nv-accordion>
                            <nv-accordion title="accordion 3 test" [edit]="true">
                                <p>TEST 3 TEST 3</p>
                            </nv-accordion>
                            <nv-accordion title="accordion 4 test" [edit]="true">
                                <p>TEST 4 TEST 4</p>
                            </nv-accordion>
                            <nv-accordion title="accordion 5 test" [edit]="true">
                                <p>TEST 5 TEST 5</p>
                            </nv-accordion>
                        </nv-accordions>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <form [formGroup]="fileTest">
                            <h3>File</h3>
                            <nv-file
                            formControlName="file"
                            [multiple] = true
                            [organizationId]="55"
                            (changeFile)="fileTestFun($event)"
                            [information]="'Test Test Test'"
                            ></nv-file>
                        </form>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                            <nv-file
                                    [organizationId]="55"
                                    [(ngModel)]="fileTest2"
                                    (changeFile)="fileTestFun($event)"
                                    [label]="'TEST'"
                            ></nv-file>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-row">
                            <div class="nv-column desk-6">
                                <div class="nv-column-content">
                                    <h1>Ad Management</h1>
                                    <p>
                                        The Navus platform contains up to 20 digital services for attendees. These services contain banner spaces where sponsor messages can be placed.<br>
                                        Depending on what modules you select you can expect between 50-100 impressions per delegate.
                                    </p>
                                    <p>
                                        The Navus platform allows you to rotate sponsor messages across all banners on all modules. You can set the Share of Voice (as a percentage of all
                                        impressions generated) for each sponsor. So a large sponsor may e.g. get 50% of all impressions and 5 smaller sponsors get 10% each.
                                    </p>
                                    <p>
                                        You can use your sponsorship spaces at this event without any limit when you upgrade. The upgrade costs € 4.90.- per attendee* for this event.
                                    </p>
                                    <p>
                                        You can sell your sponsorship spaces and make a profit. For more information on how to best market your digital sponsorship spaces contact your Event Coordinator.
                                    </p>
                                    <p>
                                        Once you upgrade we will send you banner specifications (positions and resolutions) and instructions.<br>
                                        Upgrade now by contacting your Event Coordinator
                                    </p>
                                    <p>
                                        <i>
                                            *) The actual number of attendees will be requested after an event. Find more about attendees and pricing in our terms.
                                        </i>
                                    </p>
                                </div>
                            </div>
                            <div class="nv-column desk-6">
                                <div class="nv-column-content">
                                    <nv-slides>
                                        <nv-slide style="text-align: center">
                                            <img src="assets/banner-positions/desktop-homepage-banner.jpg" alt>
                                            <h4>DESKTOP - HOMEPAGE BANNER</h4>
                                            <p>
                                                <span>Dimensions: </span><strong>1200x148px</strong><br>
                                                <span>Accepted Ad Formats: </span><strong>GIF, JPEG, animated GIF, PNG</strong>
                                            </p>
                                        </nv-slide>
                                        <nv-slide style="text-align: center">
                                            <img src="assets/banner-positions/desktop-hub-banner.jpg" alt>
                                            <h4>DESKTOP - HUB BANNER</h4>
                                            <p>
                                                <span>Dimensions: </span><strong>1200x148px (8:1 Aspect Ratio)</strong><br>
                                                <span>Accepted Ad Formats: </span><strong>GIF, JPEG, animated GIF, PNG</strong>
                                            </p>
                                        </nv-slide>
                                        <nv-slide style="text-align: center">
                                            <img src="assets/banner-positions/desktop-search-banner.jpg" alt>
                                            <h4>DESKTOP - SEARCH BANNER</h4>
                                            <p>
                                                <span>Dimensions: </span><strong>1200x148px (8:1 Aspect Ratio)</strong><br>
                                                <span>Accepted Ad Formats: </span><strong>GIF, JPEG, animated GIF, PNG</strong>
                                            </p>
                                        </nv-slide>
                                        <nv-slide style="text-align: center">
                                            <img src="assets/banner-positions/app-home-screen-banner.jpg" alt>
                                            <h4>APP - NAVUS HOME SCREEN BANNER</h4>
                                            <p>
                                                <span>Dimensions: </span><strong>1600x650px</strong><br>
                                                <span>Accepted Ad Formats: </span><strong>GIF, JPEG, animated GIF, PNG</strong>
                                            </p>
                                        </nv-slide>
                                        <nv-slide style="text-align: center">
                                            <img src="assets/banner-positions/app-home-screen-banner.jpg" alt>
                                            <h4>APP - EVENT HOME SCREEN BANNER</h4>
                                            <p>
                                                <span>Dimensions: </span><strong>1600x650px</strong><br>
                                                <span>Accepted Ad Formats: </span><strong>GIF, JPEG, animated GIF, PNG</strong>
                                            </p>
                                        </nv-slide>
                                    </nv-slides>
                                </div><!-- /.nv-column-content -->
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Carousel Image Test</h3>
                        <nv-slides>
                            <nv-slide *ngFor="let content of carouselDataTest" [image]="content.imageUrl"></nv-slide>
                        </nv-slides>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Select required</h3>
                        <nv-select
                                [options]="dropTest"
                                required
                        ></nv-select>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Input Password show/hide</h3>
                        <nv-input
                                label="Password"
                                type="password"
                                showPassword
                        ></nv-input>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Open new resource modal multiple</h3>
                        <nv-button (click)="openNewResourceModal()">new resource modal multiple</nv-button>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <h3>Open new resource modal single</h3>
                        <nv-button (click)="openNewResourceModalSingle()">new resource modal single</nv-button>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

        </div><!-- /.nv-port -->
    </div><!-- /.nv-section -->
</div><!-- /nv-page-wrap -->
