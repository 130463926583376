import { Component } from '@angular/core';

@Component({
    selector: 'app-news-single-page',
    templateUrl: './news-single.component.html'
})
export class NewsSingleComponent {

    constructor() { }

}
