import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MaterialService } from '@navus/core/services/material.service';
import { Material } from '@navus/core/classes/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { EmbedCustomizeModalComponent } from '../embed-customize/embed-customize-modal.component';
import { UserService } from '@navus/core/services/user.service';
import { Subscription, timer } from 'rxjs';
import { VideoPlayerPresetService } from '@navus/core/services/video-player-preset.service';
import { VideoPlayerPreset } from '@navus/core/classes/video-player-preset';
import { Presentation } from '@navus/core/classes/presentation';
import { PresentationService } from '@navus/core/services/presentation.service';

enum MaterialModalTabs {
  MATERIAL,
  ATTACH_PRESENTATION
}

@Component({
  selector: 'nv-material-details-attach-modal',
  templateUrl: './material-details-attach-modal.component.html'
})
export class MaterialDetailsAttachModalComponent implements OnInit, OnDestroy {
  @Input() conferenceId: number = null;
  @Input() materialId: number = null;
  @Input() organizationId: number = null;
  @Input() presentationId: number = null;

  @Input() selectedTab: MaterialModalTabs = MaterialModalTabs.MATERIAL;

  MaterialModalTabs = MaterialModalTabs;

  materialForm: FormGroup;
  attachForm: FormGroup;

  materialsImages: { image: string, src: string }[];
  currentPage: number = 1;
  scale: number = 1;

  loadingMaterial = false;
  material: Material = null;

  downloadSelectOpen = false;
  requestingDownload = false;
  downloadingMaterial = false;
  longPollMp4StatusTimer: Subscription;

  videoPlayerPresetsLoading = false;
  videoPlayerPresetsOptions: { id: string, name: string }[];
  videoPlayerPresets: VideoPlayerPreset[];
  selectedVideoPresetId: string;

  loadingPresentation = false;
  selectedPresentationId: number = null;
  presentations: Presentation[] = [];
  presentationsPage: number = 1;
  presentationsTotal: number = null;
  presentationsSearch: string = '';
  presentation: Presentation = null;

  attaching = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private materialService: MaterialService,
    private toastService: ToastService,
    private modalService: ModalService,
    private activeModal: NgbActiveModal,
    private userService: UserService,
    private presentationService: PresentationService,
    private videoPlayerPresetService: VideoPlayerPresetService
  ) {
  }

  ngOnInit() {
    this.getMaterial();
    this.getPresentation();
    this.getVideoPlayerPresets();
    this.initForms();
  }

  initForms() {
    this.initMaterialForm();
    this.initAttachForm();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getMaterial() {
    if (this.materialId) {
      this.loadingMaterial = true;
      this.materialService
        .getConferenceMaterial(this.conferenceId, this.materialId, { include: 'attached_presentation' })
        .subscribe(
          (response) => {
            this.loadingMaterial = false;
            this.material = response.data;
            this.selectedVideoPresetId = this.material.player_preset_id;
            if (this.material.type === 'main') {
              this.getMaterialImages();
            }
            if (this.material.type !== 'webcast') {
              this.attachForm.addControl('downloadable', new FormControl(false));
            }
            this.materialForm.patchValue(response.data);

            this.longPollMp4Status();
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingMaterial = false;
          }
        );
    }
  }

  scrollToBottom(): void {
    let div = document.getElementById('scroll-modal-body');
    div.scrollTop = div.scrollHeight;
  }

  getPresentation() {
    if (this.presentationId) {
      this.loadingPresentation = true;
      this.presentationService.getConferencePresentation(this.conferenceId, this.presentationId, { include: 'consent' })
        .subscribe(
          (response) => {
            this.loadingPresentation = false;
            this.presentation = response.data;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingPresentation = false;
          }
        );
    }
  }

  getVideoPlayerPresets() {
    this.videoPlayerPresetsLoading = true;
    this.videoPlayerPresetService.getPlayerPresets(this.organizationId)
      .subscribe(
        (response) => {
          this.videoPlayerPresets = response.data as VideoPlayerPreset[];
          this.videoPlayerPresetsOptions = this.videoPlayerPresets.map((item) => ({ id: item.id, name: item.name }));
          this.videoPlayerPresetsLoading = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.videoPlayerPresetsLoading = false;
        }
      );
  }

  setDefaultPresetForMaterial() {
    this.videoPlayerPresetsLoading = true;
    this.videoPlayerPresetService.setDefaultPresetForMaterial(this.materialId, this.selectedVideoPresetId)
      .subscribe((response) => {
        this.videoPlayerPresetsLoading = false;
        this.getMaterial();
        this.toastService.success('Default video player preset set successfully', {});
      }, (error) => {
        this.modalService.error({ errors: error });
        this.videoPlayerPresetsLoading = false;
      });
  }

  save() {
    this.loadingMaterial = true;
    this.materialService
      .updateMaterial(this.materialId, this.materialForm.value)
      .subscribe(
        (response) => {
          this.activeModal.close();
          this.loadingMaterial = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.loadingMaterial = false;
        }
      );
  }

  copyPath() {
    let path = this.material.path;
    path = path.replace('.m3u8', '');
    path = path.replace('stream.mux.com', 'player.navus.io');
    navigator.clipboard.writeText(path);
    this.toastService.success('Video path copied to the clipboard');
  }

  copyEmbed() {
    let path = this.material.path;
    path = path.replace('.m3u8', '');
    path = path.replace('stream.mux.com', 'player.navus.io');

    const modalRef = this.modalService.open(EmbedCustomizeModalComponent);
    modalRef.componentInstance.path = path;
    if (this.material.player_preset_id) {
      modalRef.componentInstance.preset = this.videoPlayerPresets?.find(vpp => vpp.id === this.material.player_preset_id);
    }
    modalRef.result.then(
      (embed) => {
        navigator.clipboard.writeText(embed);
        this.toastService.success('Video embed code copied to the clipboard');
      },
      () => {
      }
    );
  }

  close() {
    this.activeModal.dismiss();
  }

  setPage(page: number) {
    this.currentPage = page;
  }

  setTab(tab: MaterialModalTabs): void {
    if (this.selectedTab !== tab) {
      this.selectedTab = tab;
    }
  }

  getPresentations(append: boolean = false) {
    if (append) {
      if (this.presentationsTotal && this.presentations.length >= this.presentationsTotal) {
        return;
      }
      this.presentationsPage++;
    } else {
      this.presentationsPage = 1;
    }

    const params: any = {
      page: this.presentationsPage,
      per_page: 20,
      sort: 'name',
      order: 'asc'
    };
    if (this.presentationsSearch !== '') {
      params.search_term = this.presentationsSearch;
    }

    this.presentationService.getConferencePresentations(this.conferenceId, params)
      .subscribe(
        (response: { data: Presentation[], meta: any }) => {
          if (append) {
            this.presentations = this.presentations.concat(response.data);
          } else {
            this.presentations = response.data;
            if (response.meta) {
              this.presentationsTotal = response.meta.pagination.total;
            }
          }
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }

  searchPresentations(searchTerm: string) {
    this.presentationsSearch = searchTerm;
    this.getPresentations();
  }

  attachMaterial() {
    this.attaching = true;
    const patchValues = this.attachForm.getRawValue();
    patchValues.downloadable = patchValues.downloadable ? 1 : 0;
    patchValues.consent.available = patchValues.consent.available ? 1 : 0;
    patchValues.available = patchValues.consent.available ? 1 : 0;
    this.materialService.attachConferenceMaterial(this.conferenceId, this.materialId, this.selectedPresentationId, patchValues)
      .subscribe(
        (response) => {
          this.activeModal.close();
          this.attaching = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.attaching = false;
        }
      );
  }

  updateAvailability(event) {
    const value = event.target.checked? 1 : 0;
    event.stopPropagation();
    this.attaching = true;
    this.materialService.updateConferencePresentation(this.conferenceId, this.materialId, this.material.attached_presentation_id, { available: value })
      .subscribe(
        (response) => {
          this.attaching = false;
          this.getMaterial();
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.attaching = false;
        }
      );
  }

  detachMaterial() {
    this.modalService.defaultModal({
      title: 'Detach',
      body: 'Are you sure you want to detach this presentation?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.loadingPresentation = true;
            this.materialService.detachConferenceMaterial(this.conferenceId, this.material.id, this.material.attached_presentation.id)
              .subscribe(
                () => {
                  this.toastService.success('Detached material from presentation successfully', {});
                  this.loadingPresentation = false;
                  this.getMaterial();
                  this.initForms();
                },
                (error) => {
                  this.loadingPresentation = false;
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

  downloadMaterial(quality: 'low' | 'medium' | 'high') {
    this.downloadSelectOpen = false;
    this.downloadingMaterial = true;
    this.materialService.downloadMaterialByQuality(this.materialId, quality)
      .subscribe(
        (response: { download_url: string }) => {
          this.downloadingMaterial = false;
          if (response.download_url) {
            window.open(response.download_url, '_blank');
          }
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.downloadingMaterial = false;
        }
      );
  }

  requestMaterialDownload() {
    this.requestingDownload = true;
    this.materialService.requestMp4Support(this.materialId)
      .subscribe(
        () => {
          this.longPollMp4Status();
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.requestingDownload = false;
        }
      );
  }

  private initMaterialForm() {
    this.materialForm = this.formBuilder.group({
      title: ['', [Validators.required]],
    });
  }

  private initAttachForm() {
    this.attachForm = this.formBuilder.group({
      consent: this.formBuilder.group({
        available: [true],
        excluded_slides: [null]
      }),
      published_at: [new Date()],
    });
  }

  private getMaterialImages() {
    this.loadingMaterial = true;
    const currentUserSubscription = this.userService.currentUser
      .subscribe(
        (user) => {
          this.materialService.getMaterialImages(this.material.id)
            .subscribe(
              (response: any) => {
                this.loadingMaterial = false;
                this.materialsImages = response.images.map((image) => ({
                  image,
                  src: image
                }));
              },
              (error) => {
                this.loadingMaterial = false;
                this.modalService.error({ errors: error });
              }
            );
        },
        (error) => {
          this.loadingMaterial = false;
          this.modalService.error({ errors: error });
        }
      );
    this.subscriptions.push(currentUserSubscription);
  }

  private longPollMp4Status() {
    if (this.material?.mp4_status === 'preparing' && !this.longPollMp4StatusTimer) {
      this.requestingDownload = true;
      this.longPollMp4StatusTimer = timer(0, 10000)
        .subscribe(() => {
          if (!['ready', 'errored'].includes(this.material.mp4_status)) {
            this.getMaterial();
          } else {
            this.requestingDownload = false;
            this.longPollMp4StatusTimer.unsubscribe();
            this.longPollMp4StatusTimer = null;
          }
        });
      this.subscriptions.push(this.longPollMp4StatusTimer);
    }
  }

}
