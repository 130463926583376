import { Presentation } from './presentation';

export class Material {
    id: number;
    downloadable: boolean;
    presentation: Presentation;
    images: any;
    references: any;
    external_url: string;
    type: string;
    content: string;
    slides: any;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
