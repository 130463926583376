import { Component, Input, OnInit } from '@angular/core';
import { Presentation } from '@navus/core/classes/presentation';
import { Program } from '@navus/core/classes/program';
import { PresentationService } from '@navus/core/services/presentation.service';
import { LocationService } from '@navus/core/services/location.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { TimeslotDistribution } from '@navus/core/classes/timeslot-distribution';
import { Router } from '@angular/router';
import { Location } from '@navus/core/classes/location';
import { Author } from '@navus/core/classes/author';
import { Material } from '@navus/core/classes/material';
import { ToastService } from '@navus/ui/toast/toast.service';
import { Block } from '@navus/core/classes/block';

@Component({
  selector: 'nv-livestream-streaming-program',
  templateUrl: './livestream-streaming-program.component.html'
})

export class LivestreamStreamingProgramComponent implements OnInit {
  @Input() conferenceId: number;
  @Input() locationId: number;
  @Input() currentPresentationId: number;
  @Input() program: Program;

  programLoading: boolean = false;

  presentationLoading: boolean = false;
  selectedPresentation: Presentation;

  invitedSpeakers: Author[] = [];
  speakersLoading: boolean = false;

  days: string[] = [];
  timeslotDistributions: TimeslotDistribution[] = [];

  hideFinished = false;
  autopilot = 0;

  webcastChecked: { [presentationId: number]: { presentation: Presentation, webcast: Material } } = {};

  constructor(
    private router: Router,
    private modalService: ModalService,
    private toasterService: ToastService,
    private presentationService: PresentationService,
    private locationService: LocationService
  ) { }

  ngOnInit() {
    if (this.program) {
      this.programLoading = true;
      this.days = this.getAvailableDays(false);
      this.autopilot = this.program.locations.find(location => location.id === this.locationId)?.autopilot;
      this.timeslotDistributions = this.generateTimeslotDistributions(false);
      this.generateWebcastsPerPresentation();
      this.getInvitedSpeakers();
      this.programLoading = false;
    }
  }

  hideFinishedItems(hideFinished: boolean) {
    this.hideFinished = hideFinished;
    this.days = this.getAvailableDays(hideFinished);
    this.timeslotDistributions = this.generateTimeslotDistributions(hideFinished);
  }

  changeCurrentStreamingPresentation(presentation: Presentation, webcastId?: number) {
    this.presentationLoading = true;
    this.selectedPresentation = presentation;
    const params = { current_presentation_id: presentation.id, input_type: 'live' };
    if (webcastId) {
      params.input_type = 'webcast_live';
      params['material_id'] = webcastId;
    } else {
      if (this.webcastChecked?.[presentation.id]) {
        params.input_type = 'webcast_live';
        params['material_id'] = this.webcastChecked[presentation.id].webcast.id;
      }
    }
    this.locationService
      .updateConferenceLocation(this.conferenceId, this.locationId, params)
      .subscribe(
        (response: { data: Location }) => {
          this.currentPresentationId = response.data.current_presentation_id;
          this.presentationLoading = false;
        },
        (error) => {
          this.presentationLoading = false;
          this.toasterService.error(error.message);
        }
      );
  }

  updateSpeakerStatus(speakerId: number, event): void {
    if (event.target) {
      const isChecked = event?.target?.checked;
      if (!isChecked) {
        this.unInviteSpeaker(speakerId);
      } else {
        this.inviteSpeaker(speakerId);
      }
    }
  }

  getInvitedSpeakers() {
    this.speakersLoading = true;
    this.locationService
      .getConferenceLocationSpeakers(this.conferenceId, this.locationId)
      .subscribe((response: { data: Author[] }) => {
        this.invitedSpeakers = response.data;
        this.generateParticipantsPerBlock();
        this.speakersLoading = false;
      }, (error) => {
        this.toasterService.error(error.message);
        this.speakersLoading = false;
      });
  }

  webcastCheckedChange(value: { presentation: Presentation, webcast: Material }, event: Event): void {
    // Click event is used to make sure that the checkbox isn't checked if a user clicks cancel
    // This is needed because this is a click event
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    const selectedPresentationId = value.presentation.id;
    const selectedWebcastId = value.webcast.id;
    // Check if a user is changing currently active presentation
    if (this.currentPresentationId === selectedPresentationId) {
      // Check if user is checking or unchecking the material
      const webcastToSelect = (this.webcastChecked[selectedPresentationId]?.webcast.id === selectedWebcastId) ? null : selectedWebcastId;
      this.modalService.defaultModal({
        title: 'Streaming Change',
        body: 'Are you sure that you want to change the source of streaming for this presentation?',
        size: 'small',
        buttons: [
          {
            text: 'Cancel',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Yes',
            color: 'accent2',
            handler: () => {
              this.webcastChecked[value.presentation.id] = webcastToSelect ? value : null;
              this.changeCurrentStreamingPresentation(value.presentation, webcastToSelect);
            }
          }
        ]
      });
    } else {
      // Checking/unchecking logic for non-active presentations
      if (this.webcastChecked[selectedPresentationId]?.webcast.id === selectedWebcastId) {
        this.webcastChecked[selectedPresentationId] = null;
      } else {
        this.webcastChecked[selectedPresentationId] = value;
      }
    }
  }

  private inviteSpeaker(speakerId: number): void {
    this.locationService
      .putConferenceLocationSpeakers(this.conferenceId, this.locationId, [{ id: speakerId, status: 'in' }])
      .subscribe(() => {
        this.getInvitedSpeakers();
      }, (error) => {
        this.toasterService.error(error.message);
      });
  }

  private unInviteSpeaker(speakerId: number): void {
    this.locationService
      .deleteConferenceLocationSpeakers(this.conferenceId, this.locationId, { id: speakerId })
      .subscribe(() => {
        this.getInvitedSpeakers();
      }, (error) => {
        this.toasterService.error(error.message);
      });
  }

  private getAvailableDays(hideFinished: boolean) {
    return this.program.timeslots
      .filter(timeslot => timeslot.location_id === this.locationId)
      .map(timeslot => timeslot.date)
      .filter((value, index, self) => self.indexOf(value) === index)
      .filter(day => hideFinished ? (new Date(day)?.getTime() > new Date().getTime()) : true)
      .sort((date1, date2) => new Date(date1).getTime() - new Date(date2).getTime());
  }

  private generateTimeslotDistributions(hideFinished: boolean) {
    const availableTimeslotIds = this.program.timeslots
      .filter(timeslot => timeslot.location_id === this.locationId)
      .map(timeslot => timeslot.id);
    const timeslotDistributions = this.program.timeslot_distributions
      .filter(timeslotDistribution => availableTimeslotIds.includes(timeslotDistribution.timeslot_id))
      .filter(timeslotDistribution => hideFinished ? new Date(timeslotDistribution.starts_at)?.getTime() > new Date().getTime() : true)
      .sort((td1, td2) => new Date(td1.starts_at).getTime() - new Date(td2.starts_at).getTime());
    timeslotDistributions
      .forEach(timeslotDistribution => {
        timeslotDistribution.block = this.program.blocks.find(b => b.id === timeslotDistribution.block_id) || (new Block);
        const speakersPerBlock = this.program.speakers_per_block
          .filter(spb => spb.block_id === timeslotDistribution.block.id)
          .map(spb => spb.speaker_id);
        timeslotDistribution.block.chairpersons = this.program.speakers
          .filter(s => speakersPerBlock.includes(s.id));
        timeslotDistribution.block.presentations = this.program.presentations
          .filter(p => p.block_id === timeslotDistribution.block_id)
          .filter(p => hideFinished ? new Date(p.starts_at)?.getTime() > new Date().getTime() : true)
          .sort((td1, td2) => new Date(td1.starts_at).getTime() - new Date(td2.starts_at).getTime());
        timeslotDistribution.presentation = this.program.presentations.find(p => p.id === timeslotDistribution.presentation_id);
        timeslotDistribution.timeslot = this.program.timeslots.find(ts => ts.id === timeslotDistribution.timeslot_id);
      });

    return timeslotDistributions;
  }

  private generateParticipantsPerBlock(): void {
    this.timeslotDistributions
      .forEach(timeslotDistribution => {
        const participantsPerBlock = this.program.participants_per_block
          .filter(ppb => ppb.block_id === timeslotDistribution.block.id)
          .sort((pb1, pb2) => pb1.role === 'chair' ? -1 : 1)
          .filter((v, i, a) => a.map(spb => spb.speaker_id).indexOf(v.speaker_id) === i)
          .map(ppb => ({
            ...ppb,
            speaker: this.program.speakers.find(s => s.id === ppb.speaker_id),
            status: this.invitedSpeakers.map(is => is.id)?.includes(ppb.speaker_id) ? 'in' : 'out'
          }));
        timeslotDistribution.block.participants = [...participantsPerBlock];
      });
  }

  private generateWebcastsPerPresentation(): void {
    this.timeslotDistributions
      .forEach(timeslotDistribution => {
        timeslotDistribution.block.presentations
          .forEach(presentation => {
            presentation.webcasts = presentation.materials?.filter(material => material.type === 'webcast');

            if (presentation?.webcasts?.length) {
              this.webcastChecked[presentation.id] = { presentation: presentation, webcast: presentation.webcasts[0] };
            }
          });
      });
  }
}
