<div class="nv-row gap-30 propagate" *ngIf="value">
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-input
                    type="number"
                    label="Conference ID"
                    [(ngModel)]="value.conferenceId"
                    (ngModelChange)="onChange(value)"
            ></nv-input>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-checkbox
                    label="Hide Finished"
                    [(ngModel)]="value.hideFinished"
                    (change)="onChange(value)"
            ></nv-checkbox>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-checkbox
                    label="Show Speakers Tab"
                    [(ngModel)]="value.showSpeakersTab"
                    (change)="onChange(value)"
            ></nv-checkbox>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-checkbox
                    label="Show Presentations"
                    [(ngModel)]="value.showPresentations"
                    (change)="onChange(value)"
            ></nv-checkbox>
        </div>
    </div>
</div>
