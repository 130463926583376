import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Badge } from '@navus/core/classes/badge';
import { BadgeService } from '@navus/core/services/badge.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';

@Component({
  selector: 'main [nv-badge-details]',
  templateUrl: './badge-details.component.html',
  styles: []
})
export class BadgeDetailsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  badgeId: string;
  badge: Badge;
  badgeActive: boolean;
  badgeForm: FormGroup;

  conditionOptions = [
    {
      id: 1,
      name: 'Number of live presentations attended',
      conditions: {
        action_name: 'START',
        action_type: 'WATCHING',
        entity_type: 'presentation',
        entity_id: null,
        unique: true,
      },
    },
    {
      id: 2,
      name: 'Number of on-demand presentations attended',
      conditions: {
        action_name: 'START',
        action_type: 'BROWSING',
        entity_type: 'presentation',
        entity_id: null,
        unique: true,
      },
    },
    {
      id: 3,
      name: 'First question asked in Q&A',
      conditions: {
        action_name: 'COUNT',
        action_type: 'FIRST_MESSAGE',
        entity_type: 'block',
        entity_id: null,
        unique: true,
      },
    },
    {
      id: 4,
      name: 'Participated in Q&A',
      conditions: {
        action_name: 'COUNT',
        action_type: 'MESSAGE',
        entity_type: 'block',
        entity_id: null,
        unique: true,
      },
    },
    {
      id: 5,
      name: 'Activity in Voting',
      conditions: {
        action_name: 'COUNT',
        action_type: 'VOTING',
        entity_type: 'presentation',
        entity_id: null,
        unique: true,
      },
    },
    {
      id: 6,
      name: 'Visit sponsor page',
      conditions: {
        action_name: 'START',
        action_type: 'BROWSING',
        entity_type: 'sponsor',
        entity_id: null,
        unique: true,
      },
    },
    {
      id: 7,
      name: 'Interaction with sponsor',
      conditions: {
        action_name: 'START',
        action_type: 'MEETING',
        entity_type: 'sponsor',
        entity_id: null,
        unique: true,
      },
    },
    {
      id: 8,
      name: 'Interacting with other attendees',
      conditions: {
        action_name: 'COUNT',
        action_type: 'MESSAGE',
        entity_type: 'users',
        entity_id: null,
        unique: true,
      },
    },
  ];

  loadingBadge: boolean = false;
  savingBadge: boolean = false;
  recalculatingBadge: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private badgeService: BadgeService,
    private toastService: ToastService,
    private modalService: ModalService
  ) {
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  ngOnInit() {
    this.initForm();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.badgeId = data.badgeId;
        this.getBadge();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.badgeForm = this.formBuilder.group({
      name: ['', Validators.required],
      badge_levels: this.formBuilder.array([]),
    });
  }

  getBadge() {
    if (this.badgeId) {
      this.loadingBadge = true;
      this.badgeService
        .getConferenceBadge(this.conferenceId, this.badgeId, { include: 'badge_levels' })
        .subscribe(
          (response) => {
            let badge = response.data;
            this.badgeActive = badge.active === 1;

            if (!badge.badge_levels) { badge.badge_levels = []; }

            this.badgeForm.patchValue(badge);

            const badgeLevels = this.badgeForm.controls.badge_levels as FormArray;
            if (badge.badge_levels) {
              for (const badgeLevel of badge.badge_levels) {
                badgeLevels.push(this.formBuilder.group({
                  id: [badgeLevel.id],
                  order: [1], 
                  name: [badgeLevel.name, Validators.required],
                  image_url: [badgeLevel.image_url, Validators.required],
                  condition: this.formBuilder.group({
                    condition_id: [badgeLevel.condition.condition_id, Validators.required],
                    action_name: [badgeLevel.condition.action_name],
                    action_type: [badgeLevel.condition.action_type],
                    entity_type: [badgeLevel.condition.entity_type],
                    entity_id: [badgeLevel.condition.entity_id],
                    unique: [badgeLevel.condition.unique],
                    goal: [badgeLevel.condition.goal, Validators.required],
                  }),
                  condition_description: [badgeLevel.condition_description, Validators.required],
                  success_message: [badgeLevel.success_message, Validators.required],
                }));
              }
            }

            this.loadingBadge = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingBadge = false;
          }
        );
    } else {
      this.badge = new Badge();
      this.loadingBadge = false;
    }
  }

  addLevel() {
    const badgeLevels = this.badgeForm.controls.badge_levels as FormArray;
    badgeLevels.push(this.formBuilder.group({
      id: [],
      order: [1],
      name: ['', Validators.required],
      image_url: ['', Validators.required],
      condition: this.formBuilder.group({
        condition_id: ['', Validators.required],
        action_name: [''],
        action_type: [''],
        entity_type: [''],
        entity_id: [''],
        unique: [''],
        goal: ['', Validators.required],
      }),
      condition_description: ['', Validators.required],
      success_message: ['', Validators.required],
    }));
  }

  removeLevel(levelIndex) {
    const badgeLevels = this.badgeForm.controls.badge_levels as FormArray;
    badgeLevels.removeAt(levelIndex);
  }

  save() {
    if (this.badgeForm.invalid) {
      return;
    }

    // Update order on save
    const badge = this.badgeForm.value;
    for (let i = 0; i < badge.badge_levels.length; i++) {
      badge.badge_levels[i].order = i + 1;

      if (badge.badge_levels[i].condition.condition_id) {
        const condition = this.conditionOptions.find(co => +co.id === +badge.badge_levels[i].condition.condition_id);
        if (condition) {
          badge.badge_levels[i].condition = { ...badge.badge_levels[i].condition, ...condition.conditions };
        }
      }

      // Remove empty conditions
      if (badge.badge_levels[i].condition.action_name === null || badge.badge_levels[i].condition.action_name === '') {
        delete badge.badge_levels[i].condition.action_name;
      }
      if (badge.badge_levels[i].condition.action_type === null || badge.badge_levels[i].condition.action_type === '') {
        delete badge.badge_levels[i].condition.action_type;
      }
      if (badge.badge_levels[i].condition.entity_type === null || badge.badge_levels[i].condition.entity_type === '') {
        delete badge.badge_levels[i].condition.entity_type;
      }
      if (badge.badge_levels[i].condition.entity_id === null || badge.badge_levels[i].condition.entity_id === '') {
        delete badge.badge_levels[i].condition.entity_id;
      }
      if (badge.badge_levels[i].condition.unique === null || badge.badge_levels[i].condition.unique === '') {
        badge.badge_levels[i].condition.unique = false;
      }
    }


    this.savingBadge = true;
    if (this.badgeId) {
      this.badgeService
        .updateConferenceBadge(this.conferenceId, this.badgeId, this.badgeForm.value)
        .subscribe(
          (response) => {
            this.badge = response.data;
            this.toastService.success('Badge successfully saved.', {});
            this.savingBadge = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingBadge = false;
          }
        );
    } else {
      this.badgeService.createConferenceBadge(this.conferenceId, this.badgeForm.value)
        .subscribe(
          (response) => {
            this.badge = response.data;
            this.toastService.success('Badge successfully created.', {});
            this.router.navigate([this.badge.id], { relativeTo: this.route });
            this.savingBadge = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingBadge = false;
          }
        );
    }
  }

  close() {
    if (this.badgeId) {
      this.router.navigate(['../..', 'badges'], { relativeTo: this.route });
    } else {
      this.router.navigate(['..', 'badges'], { relativeTo: this.route });
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Badge Deletion',
      body: 'Are you sure you want to delete this badge?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.badgeService.deleteConferenceBadge(this.conferenceId, this.badgeId)
              .subscribe(
                () => {
                  this.close();
                },
                (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

  recalculate() {
    this.recalculatingBadge = true;
    if (this.badgeId) {
      this.badgeService
        .recalculateConferenceBadge(this.conferenceId, this.badgeId)
        .subscribe(
          (response) => {
            this.toastService.success('Badge successfully recalculated.', {});
            this.recalculatingBadge = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.recalculatingBadge = false;
          }
        );
    }
  }

  activate() {
    this.loadingBadge = true;
    if (this.badgeId) {
      this.badgeService
        .activateConferenceBadge(this.conferenceId, this.badgeId)
        .subscribe(
          (response) => {
            this.badgeService
              .getConferenceBadge(this.conferenceId, this.badgeId)
              .subscribe(
                (response) => {
                  let badge = response.data;
                  this.badgeActive = badge.active === 1;
                  this.toastService.success('Badge successfully activated.', {});
                  this.loadingBadge = false;
                }
              );
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingBadge = false;
          }
        );
    }
  }

  deactivate() {
    this.modalService.defaultModal({
      title: 'Badge Deactivation',
      body: 'Are you sure you want to deactivate this badge?<br>This action will delete all delegate data for this badge',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Deactivate',
          color: 'accent2',
          handler: () => {
            this.loadingBadge = true;
            if (this.badgeId) {
              this.badgeService
                .deactivateConferenceBadge(this.conferenceId, this.badgeId)
                .subscribe(
                  (response) => {
                    this.badgeService
                      .getConferenceBadge(this.conferenceId, this.badgeId)
                      .subscribe(
                        (response) => {
                          let badge = response.data;
                          this.badgeActive = badge.active === 1;
                          this.toastService.success('Badge successfully deactivated.', {});
                          this.loadingBadge = false;
                        }
                      );
                  },
                  (error) => {
                    this.modalService.error({ errors: error });
                    this.loadingBadge = false;
                  }
                );
            }
          }
        }
      ]
    });
  }

  trackBy(index: number) {
    return index;
  }

}
