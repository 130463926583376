import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SliderSectionConfig } from './slider-section.config';

@Component({
  selector: 'nv-slider-section-configuration',
  templateUrl: './slider-section-configuration-component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderSectionConfigurationComponent),
      multi: true
    }
  ]
})

export class SliderSectionConfigurationComponent implements OnInit, ControlValueAccessor {
  @Input() value: SliderSectionConfig;

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  constructor() { }

  ngOnInit() { }

  writeValue(value: any): void {
    this.value = new SliderSectionConfig(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  addSlide() {
    if (!this.value.slides) {
      this.value.slides = [];
    }

    this.value.slides.push({linkUrl: '', imageUrl: ''});
  }

  removeSlide(slide) {
    const index = this.value.slides.indexOf(slide);
    this.value.slides.splice(index, 1);
  }

}
