import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Conference } from '@navus/core/classes/conference';
import { Subscription } from 'rxjs';

@Component({
  selector: 'main [nv-website-profile-sponsor]',
  templateUrl: 'sponsor.component.html'
})

export class ProfileSponsorComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-profile-settings-body') get classView() { return true; }
  conference: Conference;
  conferenceId: number;
  
  subscriptions: Subscription[] = [];
  
  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.conferenceId = this.route.parent?.parent?.parent?.snapshot.data.website?.active_conference_id;
    this.conference = this.route.parent?.parent?.parent?.snapshot.data.website?.conferences.find(c => c.id === this.conferenceId);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

}