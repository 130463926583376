import { Component, OnInit } from '@angular/core';
import { ModalService } from '../navus-ui/modal/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '../navus-ui/toast/toast.service';


@Component({
    selector: 'nv-documentation',
    templateUrl: './navus-ui-documentation.component.html'
})

export class NavusUiDocumentationComponent implements OnInit {

    testForm = this.formBuilder.group({
        input1: ['', [Validators.required]],
        input2: [''],
        input3: ['', [Validators.required]],
        input4: ['', [Validators.email, Validators.required, Validators.minLength(5)]],
        input5: ['', [Validators.required, Validators.minLength(5)]],
        input6: ['', Validators.required],
        textarea: ['', Validators.required],
    });

    carouselSlides = [
        {
            linkUrl: 'slide1',
            imageUrl: 'slide1'
        },
        {
            linkUrl: 'slide2',
            imageUrl: 'slide2'
        },
        {
            linkUrl: 'slide3',
            imageUrl: 'slide3'
        }
    ];

    progress: number = 0;
    progressBarShow = false;
    colorTest = '' ;

    dropTest = [
        {id: 1, name: 'option1'},
        {id: 2, name: 'option2'},
        {id: 3, name: 'option3'}
    ];
    dropTestNew = [];

    radioTest: FormGroup;

    constructor(
        public modalService: ModalService,
        public formBuilder: FormBuilder,
        public toastService: ToastService
    ) {}

    ngOnInit(): void {
        this.radioTest = this.formBuilder.group({
           radio: ['radio2'],
           radio2: ['radio1']
        });
    }

    testRadio() {
        console.log(this.radioTest.value);
    }

    openDefaultModal() {
        this.modalService.defaultModal({
            body: 'Navus Default Modal',
            buttons: [
                {
                    text: 'Ok',
                    handler: () => { console.log('Some handler'); }
                },
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ],
        });
    }

    openAlertModal() {
        this.modalService.alert({
            title: 'Navus Alert Modal',
            body: 'Size of modal large',
            buttons: [
                {
                    text: 'Ok',
                    role: 'cancel'
                }
            ],
            size: 'large'
        });
    }

    openAlertModalWithoutTitle() {
        this.modalService.alert({
            body: 'Navus alert modal. Size of modal medium',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ]
        });
    }

    openConfirmModal() {
        this.modalService.confirm({
            title: 'Navus Confirm Modal',
            body: 'Size of modal small',
            buttons: [
                {
                    text: 'Yes',
                    handler: () => { console.log('confirmed'); }
                },
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ]
        });
    }

    openErrorModal() {
        this.modalService.error({
            title: 'Navus Error Modal',
            body: 'Navus error modal',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ],
        });
    }


    openToastSuccess() {
        this.toastService.success('Success', {
            disableTimeOut: false,
            timeOut: 5000,
            positionClass: 'top-right',
            closeButton: false
        });
    }

    openToastError() {
        this.toastService.error('Error', {
            disableTimeOut: false,
            timeOut: 3000,
            positionClass: 'top-left',
            closeButton: false
        });
    }

    openToastWarning() {
        this.toastService.warning('Warning', {
            disableTimeOut: true,
            positionClass: 'top-left',
            closeButton: true
        });
    }

    openToastInfo() {
        this.toastService.info('Info', {
            disableTimeOut: false,
            timeOut: 3000,
            positionClass: 'center',
            closeButton: false
        });
    }

    startProgressBar() {
        this.progressBarShow = true;
        const progressTestInterval = setInterval(() => {
            if (this.progress === 100) {
                this.progress = 0;
                this.progressBarShow = false;
                clearInterval(progressTestInterval);
            } else {
                this.progress ++;
            }
        }, 200 );
    }

    colorPicker(event) {
        this.colorTest = event;
    }

    dropTestSearch(event) {
        if (!event) {this.dropTestNew = this.dropTest; return; }
        const searchDropOptions = [];
        for (const item of this.dropTest) {
            if (item.name.includes(event)) {
                searchDropOptions.push(item);
            }
        }
        this.dropTestNew = searchDropOptions;
    }

    dropAddTest(event) {
        console.log(event);
    }

    checkboxTest(event) {
        console.log(event.target.checked);
    }
}
