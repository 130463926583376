import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class VotingService {

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getVotings(params: any = {}) {
    return this.apiService.get(`votings`, params);
  }

  getVoting(votingId: number, params: any = {}) {
    return this.apiService.get(`votings/${votingId}`, params);
  }

  updateVoting(votingId: number, params: any = {}) {
    return this.apiService.put(`votings/${votingId}`, params);
  }

  createVoting(params: any = {}) {
    return this.apiService.post(`votings`, params);
  }

  deleteVoting(votingId: number) {
    return this.apiService.delete(`votings/${votingId}`);
  }

  getConferenceVotings(conferenceId: number, params: any = {}) {
    const query = {
      has_voting: 1,
      include: 'votings',
      ...params
    };
    return this.apiService.get(`conferences/${conferenceId}/presentations`, query);
  }

  getConferenceVoting(conferenceId: number, votingId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/votings/${votingId}`, params);
  }

  updateConferenceVoting(conferenceId: number, votingId: number, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/votings/${votingId}`, params);
  }

  createConferenceVoting(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/votings`, params);
  }

  deleteConferenceVoting(conferenceId: number, votingId: number) {
    return this.apiService.delete(`conferences/${conferenceId}/votings/${votingId}`);
  }

  getConferencePresentationVotings(conferenceId: number, presentationId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/presentations/${presentationId}/votings`, params);
  }

  vote(conferenceId: number, presentationId: number, votingId: number, deviceId: string, params: any = {}) {
      const headers: any = {};
      if (deviceId) { headers['device-id'] = deviceId; }
      return this.apiService.post(`conferences/${conferenceId}/presentations/${presentationId}/votings/${votingId}/votes`, params, headers);

      // return this.http.post(
      //   `https://voting.e-materials.com/conferences/${conferenceId}/presentations/${presentationId}/votings/${votingId}/votes`,
      //   JSON.stringify(params),
      //   { headers: {'device-id': deviceId } }
      // );
  }
//  getConferenceVotings(conferenceId: number, params: any = {}) {
//    const query = {
//      has_voting: 1,
//      include: 'votings',
//      ...params
//    };
//    return this.apiService.get(`conferences/${conferenceId}/presentations`, query);
//  }


  downloadConferenceVotingResults(conferenceId: number) {
    const timestamp = new Date().toISOString();
    const filename = `VotingExport [${timestamp}]`;
    const params = {
      mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      extension: 'xlsx'
    }
    return this.apiService.download(`conferences/${conferenceId}/voting/export`, filename, params);
  }

}
