<div class="nv-modal large">
    <div class="nv-modal-header">
        <h4 class="nv-modal-title">Material</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body">
        <div class="nv-row gap-30 propagate">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <ul class="nv-materials-list break-lap break-column-tab"><!-- < custom, two levels breakpoints -->
                        <li *ngIf="material">
                            <div class="ml-content nv-clickable" (click)="selectMaterial()">
                                <div class="ml-file">
                                    <div class="ml-iconavatar">
                                        <img src="../../assets/ico-material-pdf.svg" alt="PDF">
                                    </div><!-- /.ml-iconavatar -->
                                    <div class="ml-name">
                                        <label>MATERIAL NAME</label>
                                        <strong>{{ material.title }}</strong>
                                    </div><!-- /.ml-name -->
                                    <div class="ml-filesize">
                                        <label>SIZE</label>
                                        <strong *ngIf="material.file_size">{{ material.file_size / 1024 / 1024 | number:'1.2-2' }} MB</strong>
                                        <strong *ngIf="!material.file_size">-</strong>
                                    </div><!-- /.ml-filesize -->
                                </div><!-- /.ml-file -->
                                <div class="ml-meta">
                                    <div class="ml-presentation">
                                        <label>ATTACHED TO PRESENTATION</label>
                                        <strong *ngIf="material.presentation">{{ material.presentation.title }}</strong>
                                        <strong *ngIf="!material.presentation">None</strong>
                                    </div><!-- /.ml-presentation -->
                                    <div class="ml-published">
                                        <label>PUBLISHED</label>
                                        <strong *ngIf="material.published_at">{{ material.published_at | nvDate:'dd.MM.yyyy' }}</strong>
                                        <strong *ngIf="!material.published_at">No</strong>
                                    </div><!-- /.ml-published -->
                                    <div class="ml-status">
                                        <label>STATUS</label>
                                        <strong class="status {{ material.status }}">{{ material.status }}</strong><!-- /processed -->
                                    </div><!-- /.ml-status -->
                                </div><!-- /.ml-meta -->
                            </div><!-- /.ml-content -->
                        </li>
                        <li *ngIf="!material">
                            <div class="ml-content">
                                <div>Select material</div>
                            </div>
                        </li>
                    </ul>
                    <nv-select #materialSelect
                               [options]="materials"
                               [headless]="true"
                               label="Material"
                               [(ngModel)]="materialId"
                               [showSearch]="true"
                               (ngModelChange)="getMaterial()"
                               (searched)="getMaterials()"
                               (loadMore)="getMaterials(true)"
                    ></nv-select>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-12">
                <div class="nv-column-content" style="display: flex; justify-content: center">
                    <nv-button size="small"
                               [loading]="processingMaterial"
                               [disabled]="!presentation"
                               (click)="processMaterial()"
                               translate>Click here to start processing</nv-button>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-12">
                <div class="nv-column-content" >
                    <ul class="nv-materials-list break-lap break-column-tab"><!-- < custom, two levels breakpoints -->
                        <li *ngIf="presentation">
                            <div class="ml-content nv-clickable" (click)="selectPresentation()">
                                <div class="ml-file">
                                    <div class="ml-name">
                                        <label>PRESENTATION NAME</label>
                                        <strong>{{ presentation.title }}</strong>
                                    </div><!-- /.ml-name -->
                                </div><!-- /.ml-file -->
                                <div class="ml-meta">
                                    <div class="ml-published"></div>
                                    <div class="ml-published">
                                        <label>DATE</label>
                                        <strong>
                                            <span>{{ presentation.starts_at | nvDate:'dd.MM.yyyy' }}</span>
                                        </strong>
                                    </div><!-- /.ml-published -->
                                    <div class="ml-published">
                                        <label>TIME</label>
                                        <strong>
                                            <span>{{ presentation.starts_at | nvDate:'HH:mm' }}</span>
                                             -
                                            <span>{{ presentation.ends_at | nvDate:'HH:mm' }}</span>
                                        </strong>
                                    </div><!-- /.ml-published -->
                                </div><!-- /.ml-meta -->
                            </div><!-- /.ml-content -->
                        </li>
                        <li *ngIf="!presentation">
                            <div class="ml-content nv-clickable" (click)="selectPresentation()">
                                <div>Select presentation</div>
                            </div>
                        </li>
                    </ul>
                    <nv-select #presentationSelect
                               [options]="presentations"
                               [headless]="true"
                               label="Presentation"
                               [(ngModel)]="presentationId"
                               [showSearch]="true"
                               (ngModelChange)="getPresentation()"
                               (searched)="searchPresentations($event)"
                               (loadMore)="getPresentations(true)"
                    ></nv-select>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <form [formGroup]="attachForm" class="nv-column desk-12">
                <div class="nv-column-content">
                    <div class="nv-switch-accordion-item" formGroupName="consent">
                        <header>
                            <div class="sai-left">
                                <h3>Show this material</h3>
                            </div><!-- /.sai-left -->
                            <div class="sai-right">
                                <nv-switch formControlName="available"></nv-switch>
                            </div><!-- /.sai-right -->
                        </header>
                    </div><!-- /.nv-switch-accordion-item -->
                    <div class="nv-switch-accordion-item" formGroupName="consent" *ngIf="material?.type !== 'webcast'">
                        <header>
                            <div class="sai-left">
                                <h3>Exclude slides</h3>
                            </div><!-- /.sai-left -->
                        </header>
                        <div class="nv-switch-accordion-body cancel-top-space">
                            <nv-input
                                    type="text"
                                    formControlName="excluded_slides"
                                    label="Enter slide number, use comma to separate multiple slides"
                            ></nv-input>
                        </div>
                    </div><!-- /.nv-switch-accordion-item -->
                    <div class="nv-switch-accordion-item no-border">
                        <header>
                            <div class="sai-left">
                                <h3>Publish on</h3>
                            </div><!-- /.sai-left -->
                        </header>
                        <div class="nv-switch-accordion-body cancel-space">
                            <nv-datepicker
                                    formControlName="published_at"
                                    label="Publish date & time:"
                            ></nv-datepicker>
                            <small>
                                <strong>Note:&nbsp;</strong>
                                <span>Presentation start date & time are pre-selected by default.</span>
                            </small>
                        </div><!-- /.nv-switch-accordion-body -->
                    </div><!-- /.nv-switch-accordion-item -->
                </div><!-- /.nv-column-content -->
            </form><!-- /.nv-column -->
        </div>
    </div>
</div>

