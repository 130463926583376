import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProgramPlannerSectionConfig } from './program-planner-section.config';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { ConferenceService } from '@navus/core/services/conference.service';
import { Program } from '@navus/core/classes/program';
import { BaseSectionComponent } from '../_base/base-section.component';
import { WebsiteService } from '@navus/core/services/website.service';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { WebsiteControlService } from '../../services/website-control.service';

@Component({
  selector: 'nv-program-planner-section',
  templateUrl: './program-planner-section.component.html'
})
export class ProgramPlannerSectionComponent extends BaseSectionComponent implements OnInit, OnDestroy {
  @Input() data: WebsitePageSection;
  configuration: ProgramPlannerSectionConfig = new ProgramPlannerSectionConfig();

  program: Program;

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService,
    private route: ActivatedRoute,
    private conferenceService: ConferenceService
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );
  }

  ngOnInit() {
    this.configuration = new ProgramPlannerSectionConfig(this.data.configuration);
  }

  ngOnDestroy() {

  }

  getProgram() {
    this.conferenceService.getProgram(this.configuration.conferenceId)
      .subscribe(
        (response: { data: Program }) => {
          this.program = response.data;
        },
        () => { }
      );
  }

}
