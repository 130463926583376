import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import Echo from 'laravel-echo';
import { FileUploader } from 'ng2-file-upload';
import Pusher from 'pusher-js';

@Injectable()
export class MaterialService {

  constructor(
    private apiService: ApiService
  ) { }

  getMaterials(params: any = {}) {
    return this.apiService.get(`materials`, params);
  }

  getMaterial(materialId: number, params: any = {}) {
    return this.apiService.get(`materials/${materialId}`, params);
  }

  updateMaterial(materialId: number, params: any = {}) {
    return this.apiService.put(`materials/${materialId}`, params);
  }

  createMaterial(params: any = {}) {
    return this.apiService.post(`materials`, params);
  }

  deleteMaterial(materialId: number) {
    return this.apiService.delete(`materials/${materialId}`);
  }

  getConferenceMaterials(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/materials`, params);
  }

  getConferenceMaterial(conferenceId: number, materialId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/materials/${materialId}`, params);
  }

  updateConferenceMaterial(conferenceId: number, materialId: number, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/materials/${materialId}`, params);
  }

  createConferenceMaterial(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/materials`, params);
  }

  deleteConferenceMaterial(conferenceId: number, materialId: number) {
    return this.apiService.delete(`conferences/${conferenceId}/materials/${materialId}`);
  }

  attachConferenceMaterial(conferenceId: number, materialId: number, presentationId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/materials/${materialId}/presentations/${presentationId}`, params);
  }

  updateConferencePresentation(conferenceId: number, materialId: number, presentationId: number, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/materials/${materialId}/presentations/${presentationId}`, params);
  }

  detachConferenceMaterial(conferenceId: number, materialId: number, presentationId: number, params: any = {}) {
    return this.apiService.delete(`conferences/${conferenceId}/materials/${materialId}/presentations/${presentationId}`, params);
  }

  getMaterialImages(materialId: number, params: any = {}) {
    return this.apiService.get(`materials/${materialId}/images`, params);
  }

  downloadMaterial(materialId: number, params: any = {}) {
    return this.apiService.download(`materials/${materialId}/download`, `material_${materialId}`, params);
  }

  requestMp4Support(materialId: number, params: any = {}) {
    return this.apiService.put(`materials/${materialId}/request-mp4-support`, params);
  }

  downloadMaterialByQuality(materialId: number, quality: 'low' | 'medium' | 'high', params: any = {}) {
    return this.apiService.put(`materials/${materialId}/download/${quality}`, params);
  }

  getMaterialHighlights(materialId: number, params: any = {}) {
    return this.apiService.get(`materials/${materialId}/highlights`, params);
  }

  addMaterialHighlight(materialId: number, params: any = {}) {
    return this.apiService.post(`materials/${materialId}/highlights`, params);
  }

  removeMaterialHighlight(materialId: number, highlightId: number, params: any = {}) {
    return this.apiService.delete(`materials/${materialId}/highlights/${highlightId}`, params);
  }

  addExternalMaterial(conferenceId: number, params: any ={}){
    return this.apiService.post(`conferences/${conferenceId}/materials/external`, params);
  }

  createConferenceMaterialUploader(conferenceId: number, params: any = {}) {
    if (!localStorage.getItem('jwtToken')) { return; }

    return new FileUploader({
      ...params,
      url: `${environment.api_url}conferences/${conferenceId}/materials`,
      allowedFileType: ['pdf', 'ppt', 'pptx', 'video', 'mp4', 'mpeg-4', 'webm'],
      allowedMimeType: [
        'application/pdf',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'video/mp4',
        'application/mp4',
        'video/webm'
      ],
      removeAfterUpload: true,
      autoUpload: true,
      itemAlias: 'attachment',
      authTokenHeader:  'Authorization',
      authToken: 'Bearer ' + localStorage.getItem('jwtToken'),
      headers: [],
    });
  }

  async initWebsocket() {
    window['Pusher'] = Pusher;
    window['Echo'] = new Echo({
        broadcaster: 'pusher',
        key: `${environment.mix_pusher_app_key}`,
        wsHost: `${environment.mix_pusher_host}`,
        wsPort: `${environment.mix_pusher_port}`,
        wssPort: `${environment.mix_pusher_port}`,
        forceTLS: `${environment.mix_pusher_port === 443}`,
        encrypted: true,
        disableStats: true,
        authEndpoint: `${environment.api_url}` + 'broadcasting/auth',
        auth: {headers: {'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')}},
        enabledTransports: ['ws', 'wss']
    });

    return window['Echo'];
  }

}
