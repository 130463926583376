import { Component, OnDestroy, OnInit, ViewChild, NgZone } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@navus/core/services/user.service';
import { User } from '@navus/core/classes/user';

import { WebsiteService } from '@navus/core/services/website.service';
import { Website } from '@navus/core/classes/website';

import { PresentationService } from '@navus/core/services/presentation.service';
import { Presentation } from '@navus/core/classes/presentation';
import { Location } from '@navus/core/classes/location';
import { BlockService } from '@navus/core/services/block.service';
import { Block } from '@navus/core/classes/block';
import { NavusMeetingComponent } from '@navus/ui/meeting/meeting.component';

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { VotingService } from '@navus/core/services/voting.service';
import { VotingQuestion } from '@navus/core/classes/voting-question';

import { ModalService } from '@navus/ui/modal/modal.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'nv-my-studio-location-page',
  templateUrl: './my-studio-location-page.component.html'
})
export class MyStudioLocationPageComponent implements OnInit, OnDestroy {
  @ViewChild('meeting') meeting: NavusMeetingComponent;  conferenceId: number;
  locationId: number;
  selectedLocation: any;
  presentation: Presentation;
  website: Website;
  selectedTab: string = 'live';
  currentUser: User;
  currentBlockId: number;
  myPresentations: Presentation[] = [];
  presentingLocations: Location[] = [];
  subscriptions: Subscription[] = [];

  blocks: Block[] = [];
  selectedBlock: Block;
  autoSelectBlock: boolean = true;
  chatMode: string = 'expand';
  statusOptions: any = [
    { id: 'all', name: 'All' },
    { id: 'true', name: 'Approved' },
    { id: 'false', name: 'Rejected' },
  ];
  approveStatus: string = 'all';

  currentTimestamp: number;

  questionLoading: boolean = false;
  optionsShown: number = null;

  currentVotingQuestion: VotingQuestion = null;
  
  QAfilter = [
    { name: 'All', id: 'all' },
    { name: 'Approved', id: 'true' },
    { name: 'Not approved', id: 'false' }
  ];

  private LaravelEcho: Echo;

  constructor(
    private route: ActivatedRoute,
    private presentationService: PresentationService,
    private websiteService: WebsiteService,
    private userService: UserService,
    private blockService: BlockService,
    private votingService: VotingService,
    private modalService: ModalService,
    private zone: NgZone,
    private router: Router,
  ) { }

  ngOnInit() {

    this.route.params.subscribe(
      (params) => {
        this.locationId = params.locationId;
      }
    );

    this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          if (website.active_conference_id) {
            this.conferenceId = website.active_conference_id;

            this.getMyPresentationsOnLocation(this.locationId);
            this.getLocationBlocks();

            this.runSocket();
          }
        },
        () => { }
      );
    this.userService.currentUser
      .subscribe(
        (user: User) => {
          this.currentUser = user;
        },
        () => { }
      );

    // const timerSubscription = timer(0, 5000)
    //   .subscribe(
    //     () => {
    //       this.currentTimestamp = new Date().getTime();
    //     }
    //   )
    // this.subscriptions.push(timerSubscription);
    
  }

  ngOnDestroy() {
    if (this.LaravelEcho) { this.LaravelEcho.disconnect(); }
  }

  // isOpen(ws) { 
  //   console.log("ws.readyState");
  //   console.log(ws.readyState);
  //   return ws.readyState === ws.OPEN }

  runSocket() {
    window['Pusher'] = Pusher;
    window['Echo'] = new Echo({
        broadcaster: 'pusher',
        key: `${environment.mix_pusher_app_key}`,
        wsHost: `${environment.mix_pusher_host}`,
        wsPort: `${environment.mix_pusher_port}`,
        wssPort: `${environment.mix_pusher_port}`,
        forceTLS: `${environment.mix_pusher_port === 443}`,
        encrypted: true,
        disableStats: true,
        authEndpoint: `${environment.api_url}` + 'broadcasting/auth',
        auth: {headers: {'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')}},
        enabledTransports: ['ws', 'wss']
    });
    this.LaravelEcho = window['Echo'];

    this.LaravelEcho
        .channel('location.current_conference_presentation.' + this.conferenceId)
        .listen('.location.changed_current_presentation',
          (response: any) => {
            if (response.data[0].id) {
              if (
                  this.locationId == response.data[0].id &&
                  response.data[0].streaming_presentations.length > 0
              ) {
                this.setCurrentPresentation(response.data[0].streaming_presentations[0].id, response.data[0].streaming_presentations[0]);
              }
            }
          }
        );
  }

  setCurrentPresentation(presentationId: number, presentation: any = null) {
    if (presentation === null) {
      this.presentationService
          .getPresentation(presentationId)
          .subscribe(
            (response) => {
              this.presentation = response.data;
              this.currentBlockId = this.presentation.block_id;


              if (this.autoSelectBlock) {
                this.blocks
                    .forEach((block) => {
                      if (block.id === this.currentBlockId) {
                        this.selectBlock(block, true);
                      }
                    });
                this.getPresentationVotings();
              }
            },
            () => { }
          );
    } else {
      this.presentation = presentation;
      this.currentBlockId = this.presentation.block_id;
      this.getPresentationVotings();
      
      if (this.autoSelectBlock) {
        this.blocks
            .forEach((block) => {
              if (block.id === this.currentBlockId) {
                this.selectBlock(block, true);
              }
            });
      }
    }
  }

  getMyPresentationsOnLocation(locationId: number) {
    const params: any = {
      location_id: locationId,
      include: 'block',
      with_pagination: 0
    };
    this.presentationService
        .getMyPresentations(this.conferenceId, params)
        .subscribe(
          (response) => {
              this.myPresentations = response.data;
              if (this.myPresentations[0] && this.myPresentations[0].location) {
                  this.selectedLocation = this.myPresentations[0].location;
              } else if (this.myPresentations[0].block && this.myPresentations[0].block.location) {
                  this.selectedLocation = this.myPresentations[0].block.location;
              } else {
                  this.router.navigate(['/my/studio']);
              }
              if (this.selectedLocation.current_presentation_id) {
                  this.setCurrentPresentation(this.selectedLocation.current_presentation_id);
              } else {
                this.currentTimestamp = new Date().getTime();
              } 
          },
          () => {

          }
        );
  }

  selectTab(env: string) {
    this.selectedTab = env;
  }

  getLocationBlocks() {
    const params: any = {
      location_id: this.locationId,
      with_pagination: 0
    };

    this.blockService
        .getConferenceBlocks(this.conferenceId, params)
        .subscribe(
          (response) => {
            this.blocks = response.data
              .sort((a: Block, b: Block) => a.starts_at > b.starts_at ? -1 : 1)
              .map(block => ({ 
                ...block, 
                startTimestamp: new Date(block.starts_at).getTime() 
              }));

            this.blocks
                .forEach((block) => {
                  if (block.id === this.currentBlockId) {
                    this.selectBlock(block, true);
                  }
                });
          },
          () => {}
        );
  }

  selectBlock(block: Block, setTime=false) {
    this.selectedBlock = block;
    if (setTime) {
      this.currentTimestamp = block.startTimestamp;
    }
  }

  getPresentationVotings() {
    if (!this.LaravelEcho) { this.runSocket(); }
    this.LaravelEcho
        .channel('presentation.' + this.presentation.id)
        .listen('.voting.started', (votingQuestion) => {
          // https://www.youtube.com/watch?v=siwpn14IE7E
          this.zone.run(() => {
            this.currentVotingQuestion = votingQuestion;
            this.currentVotingQuestion.active = true;
            this.currentVotingQuestion.finished = false;
          });
        })
        .listen('.voting.finished', (votingQuestion) => {
          // https://www.youtube.com/watch?v=siwpn14IE7E
          this.zone.run(() => {
            this.currentVotingQuestion = votingQuestion;
            this.currentVotingQuestion.active = false;
            this.currentVotingQuestion.finished = true;
          });
        });

    this.votingService
        .getConferencePresentationVotings(this.conferenceId, this.presentation.id)
        .subscribe(
          (response) => {
            const votingQuestions = response.data;
            let activeVotingQuestion = null;
            let finishedVotingQuestion = null;

            for (const votingQuestion of votingQuestions) {
              if (votingQuestion.active) { activeVotingQuestion = votingQuestion; }
              if (votingQuestion.finished) { finishedVotingQuestion = votingQuestion; }
            }

            if (activeVotingQuestion) {
              if (this.currentVotingQuestion === activeVotingQuestion) { return; }
              this.currentVotingQuestion = activeVotingQuestion;
            } else {
              this.currentVotingQuestion = finishedVotingQuestion;
            }
          },
          () => { }
        );
  }

  userDetails(userId: number) {
    this.userService
        .getChatUser(userId)
        .subscribe(
          (response) => {
            const user: User = response.data;
            this.modalService
                .defaultModal({
                  body: `
                    <div>${user.first_name} ${user.last_name}</div>
                    <div>${user.institution}</div>
                    <div>${user.email}</div>
                  `
                });
          },
          () => { }
        );
  }

  toggleShareScreen() {
    this.meeting.toggleShareScreen();
  }

  openChatInNewTab() {
    window.open(`/q-and-a/${this.locationId}?sessionId=${this.selectedBlock?.id}`, '_blank');
  }

}
