import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ConferenceService } from '@navus/core/services/conference.service';

@Component({
  selector: 'main [nv-consent-settings-page]',
  templateUrl: './consent-settings-page.component.html',
  styles: []
})
export class ConsentSettingsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;

  consentForm: FormGroup;

  consentLoading: boolean = false;
  savingConsent: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private conferenceService: ConferenceService,
    private toastService: ToastService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.initForm();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;

        this.getConsent();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.consentForm = this.formBuilder.group({
      allow_main: [false],
      allow_supporting: [false],
      allow_webcast: [false],

      disclaimer: [false],
      disclaimer_text: [''],

      watermark: [false],
      watermark_path: [''],

      downloadable: [false],

      override_speaker_consent: [false],

      completed: [false]
    });
  }

  getConsent() {
    this.consentLoading = true;
    this.conferenceService.getOrganizationConference(this.organizationId, this.conferenceId)
      .subscribe(
        (response) => {
          this.consentForm.patchValue(response.data.consent);
          this.consentLoading = false;
        },
        (error) => {
          this.modalService.error({errors: error});
        }
      );
  }

  save() {
    if (this.consentForm.invalid) { return; }

    this.savingConsent = true;
    const consent = this.consentForm.value;
    consent.consent_completed = true;
    consent.completed = true;

    this.conferenceService.updateOrganizationConference(this.organizationId, this.conferenceId, { consent })
      .subscribe(
        (response) => {
          this.savingConsent = false;
          this.toastService.success('Consent successfully saved.', {});
        },
        (error) => {
          this.savingConsent = false;
          this.modalService.error({ errors: error });
        }
      );
  }

  saveEmpty() {
    if (this.consentForm.invalid) { return; }

    this.savingConsent = true;
    const consent: any = {};
    consent.consent_completed = true;
    consent.completed = true;

    this.conferenceService.updateOrganizationConference(this.organizationId, this.conferenceId, { consent })
      .subscribe(
        (response) => {
          this.savingConsent = false;
          this.toastService.success('Consent successfully saved.', {});
        },
        (error) => {
          this.savingConsent = false;
          this.modalService.error({ errors: error });
        }
      );
  }

}
