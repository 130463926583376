export class PromoSectionConfig {
  showProgram: boolean = true;
  showMobileApp: boolean = true;
  showRegistration: boolean = false;
  showEmaterials: boolean = true;
  showEplaylist: boolean = true;
  showLivestream: boolean = true;

  showInfo: boolean = false;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
