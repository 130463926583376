<div class="nv-modal small">
    <div class="nv-modal-header">
        <h4 class="medium">Create new folder</h4>
        <button type="button" class="close" aria-label="Close" (click)="close()"> </button>
    </div>
    <div class="nv-modal-body">
        <div class="nv-divider desk-medium"></div>

        <div class="folder-container">
            <nv-input
                [label]="'Folder name...'"
                [(ngModel)]="newFolderName"
                [autoFocus]="true">
            </nv-input>
        </div><!-- /tags-container -->
    </div>
    <div class="nv-modal-footer">
        <div class="nv-btn-box gap-10 flow-end">
            <nv-button color="neutral" (click)="close()">Close</nv-button>
            <nv-button (click)="save()" [disabled]="!newFolderName">Save</nv-button>
        </div>
    </div>
</div>
