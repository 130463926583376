import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FileUploadModule } from 'ng2-file-upload';
import { TranslateModule } from '@ngx-translate/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgStreamingModule } from '@videogular/ngx-videogular/streaming';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { SharedModule } from '../shared';
import { NavusUiModule } from '@navus/ui/navus-ui.module';

import { RegistrationModule } from './modules/registration/registration.module';
import { ProfileModule } from './modules/profile/profile.module';
import { LiveStageModule } from './modules/live-stage/live-stage.module';

import { WebsiteRoutes } from './website.routes';

import { AuthGuard } from './guards/auth.guard';
import { AuthQueryGuard } from './guards/auth-query.guard';
import { NavigationGuard } from './guards/navigation.guard';
import { EditQueryGuard } from './guards/edit-query.guard';

import { WebsiteResolver } from './resolvers/website.resolver';
import { PageResolver } from './resolvers/page.resolver';

import { ApiService } from '@navus/core/services/api.service';
import { ArticleService } from '@navus/core/services/article.service';
import { PresentationService } from '@navus/core/services/presentation.service';
import { TwitterService } from '@navus/core/services/twitter.service';

import { WebsiteComponent } from './website.component';

import { CustomPageComponent } from './pages/custom/custom-page.component';
import { PageSectionsDirective } from './directives/page-sections.directive';
import { MediasiteSectionComponent } from './sections/mediasite/mediasite-section.component';
import { AttendanceCertSectionComponent } from './sections/attendance-cert/attendance-cert-section.component';
import { CustomHtmlSectionComponent } from './sections/custom-html/custom-html-section.component';
import { CustomHtmlSectionConfigurationComponent } from './sections/custom-html/custom-html-section-configuration.component';
import { SliderSectionComponent } from './sections/slider/slider-section.component';
import { SliderSectionConfigurationComponent } from './sections/slider/slider-section-configuration-component';
import { ArticleListSectionComponent } from './sections/article-list/article-list-section.component';
import { ArticleListSectionConfigurationComponent } from './sections/article-list/article-list-section-configuration.component';
import { RecommendationsSectionComponent } from './sections/recommendations/recommendations-section.component';
import { RecommendationsSectionConfigurationComponent } from './sections/recommendations/recommendations-section-configuration.component';
import { TwitterSectionComponent } from './sections/twitter/twitter-section.component';
import { TwitterSectionConfigurationComponent } from './sections/twitter/twitter-section-configuration.component';
import { WebsiteNavigationComponent } from './components/website-navigation/website-navigation.component';
import { SearchSectionComponent } from './sections/search/search-section.component';
import { SearchSectionConfigurationComponent } from './sections/search/search-section-configuration-component';
import { SearchPageComponent } from './pages/search/search-page.component';
import { PresentationPageComponent } from './pages/presentation/presentation-page.component';
import { MaterialPageComponent } from './pages/material/material-page.component';
import { SummarySectionComponent } from './sections/summary/summary-section.component';
import { SummarySectionConfigurationComponent } from './sections/summary/summary-section-configuration.component';
import { NotFoundPageComponent } from './pages/not-found/not-found-page.component';
import { ProgramPageComponent } from './pages/program/program-page.component';
import { ProgramPlannerComponent } from './components/program-planner/program-planner.component';
import { BlockModalComponent } from './modals/block/block-modal.component';
import { ArticleDetailsPageComponent } from './pages/article-details/article-details-page.component';
import { ConferenceAccessModalComponent } from './modals/conference-access/conference-access-modal.component';
import { ArticleModalComponent } from './modals/article/article-modal.component';
import { AuthorListSectionComponent } from './sections/author-list/author-list-section.component';
import { AuthorListSectionConfigurationComponent } from './sections/author-list/author-list-section-configuration.component';
import { AuthorModalComponent } from './modals/author/author-modal.component';
import { LiveSuiteSectionComponent } from './sections/live-suite/live-suite-section.component';
import { LiveSuiteSectionConfigurationComponent } from './sections/live-suite/live-suite-section-configuration.component';
import { ConferenceListSectionComponent } from './sections/conference-list/conference-list-section.component';
import { ConferenceListSectionConfigurationComponent } from './sections/conference-list/conference-list-section-configuration.component';
import { ProgramPlannerSectionComponent } from './sections/program-planner/program-planner-section.component';
import { ProgramPlannerSectionConfigurationComponent } from './sections/program-planner/program-planner-section-configuration.component';
import { LiveSuiteIframeSectionComponent } from './sections/live-suite-iframe/live-suite-iframe-section.component';
import { LiveSuiteIframeSectionConfigurationComponent } from './sections/live-suite-iframe/live-suite-iframe-section-configuration.component';
import { BannerSectionComponent } from './sections/banner/banner-section.component';
import { BannerSectionConfigurationComponent } from './sections/banner/banner-section-configuration.component';
import { PagesPanelComponent } from './components/pages-panel/pages-panel.component';
import { PagePanelComponent } from './components/page-panel/page-panel.component';
import { DesignPanelComponent } from './components/design-panel/design-panel.component';
import { SectionsPanelComponent } from './components/sections-panel/sections-panel.component';
import { SectionPanelComponent } from './components/section-panel/section-panel.component';
import { ComingSoonPageComponent } from './pages/coming-soon/coming-soon-page.component';
import { HeroSectionComponent } from './sections/hero/hero-section.component';
import { HeroSectionConfigurationComponent } from './sections/hero/hero-section-configuration.component';
import { SponsorListPageComponent } from './pages/sponsor-list/sponsor-list-page.component';
import { SponsorDetailsPageComponent } from './pages/sponsor-details/sponsor-details-page.component';
import { PromoSectionComponent } from './sections/promo/promo-section.component';
import { PromoSectionConfigurationComponent } from './sections/promo/promo-section-configuration.component';
import { SpeakerListPageComponent } from './pages/speaker-list/speaker-list-page.component';
import { HubPageComponent } from './pages/hub/hub-page.component';
import { LiveStreamingPageComponent } from './pages/live-streaming/live-streaming-page.component';
import { StaticPageResolver } from './resolvers/static-page.resolver';
import { MyPresentationPageComponent } from './pages/my-presentation/my-presentation-page.component';
import { MyStudioPageComponent } from './pages/my-studio/my-studio-page.component';
import { MyStudioLocationPageComponent } from './pages/my-studio-location/my-studio-location-page.component';
import { QuestionModalComponent } from './modals/question/question-modal.component';
import { BaseSectionComponent } from './sections/_base/base-section.component';
import { WebsiteControlService } from './services/website-control.service';
import { AuthComponent } from './pages/auth/auth.component';
import { SponsorListSectionComponent } from './sections/sponsor-list/sponsor-list-section.component';
import { SponsorListSectionConfigurationComponent } from './sections/sponsor-list/sponsor-list-section-configuration.component';
import { CardModalComponent } from './modals/card/card-modal.component';
import { CustomSectionConfigurationComponent } from './sections/custom/custom-section-configuration.component';
import { CustomSectionComponent } from './sections/custom/custom-section.component';
import { QAndAPageComponent } from './pages/q-and-a/q-and-a-page.component';
import { StreamChangeComponent } from './modals/stream-change/stream-change.component';
import { DelegateListPageComponent } from './pages/delegate-list/delegate-list-page.component';
import { DelegateModalComponent } from './modals/delegate/delegate-modal.component';
import { DelegateListSectionComponent } from './sections/delegate-list/delegate-list-section.component';
import { DelegateListSectionConfigurationComponent } from './sections/delegate-list/delegate-list-section-configuration.component';
import { LeaderboardSectionComponent } from './sections/leaderboard/leaderboard-section.component';
import { LeaderboardSectionConfigurationComponent } from './sections/leaderboard/leaderboard-section-configuration.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    RouterModule.forChild(WebsiteRoutes),
    SlickCarouselModule,
    SharedModule,
    NavusUiModule,
    FileUploadModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VgStreamingModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DragDropModule,

    RegistrationModule,
    ProfileModule,
    LiveStageModule,
  ],
  declarations: [
    WebsiteNavigationComponent,
    ProgramPlannerComponent,

    PageSectionsDirective,
    WebsiteComponent,
    CustomPageComponent,
    SearchPageComponent,
    PresentationPageComponent,
    MaterialPageComponent,
    NotFoundPageComponent,
    ProgramPageComponent,
    ArticleDetailsPageComponent,
    SponsorListPageComponent,
    SponsorDetailsPageComponent,
    SpeakerListPageComponent,
    HubPageComponent,
    LiveStreamingPageComponent,
    MyPresentationPageComponent,
    MyStudioPageComponent,
    MyStudioLocationPageComponent,
    QAndAPageComponent,
    DelegateListPageComponent,

    BlockModalComponent,
    ArticleModalComponent,
    ConferenceAccessModalComponent,
    AuthorModalComponent,
    QuestionModalComponent,
    CardModalComponent,
    DelegateModalComponent,

    PagesPanelComponent,
    PagePanelComponent,
    DesignPanelComponent,
    SectionsPanelComponent,
    SectionPanelComponent,

    BaseSectionComponent,
    MediasiteSectionComponent,
    AttendanceCertSectionComponent,
    CustomSectionComponent,
    CustomSectionConfigurationComponent,
    CustomHtmlSectionComponent,
    CustomHtmlSectionConfigurationComponent,
    SliderSectionComponent,
    SliderSectionConfigurationComponent,
    ArticleListSectionComponent,
    ArticleListSectionConfigurationComponent,
    RecommendationsSectionComponent,
    RecommendationsSectionConfigurationComponent,
    TwitterSectionComponent,
    TwitterSectionConfigurationComponent,
    SearchSectionComponent,
    SearchSectionConfigurationComponent,
    SummarySectionComponent,
    SummarySectionConfigurationComponent,
    AuthorListSectionComponent,
    AuthorListSectionConfigurationComponent,
    SponsorListSectionComponent,
    SponsorListSectionConfigurationComponent,
    LiveSuiteSectionComponent,
    LiveSuiteSectionConfigurationComponent,
    LiveSuiteIframeSectionComponent,
    LiveSuiteIframeSectionConfigurationComponent,
    LeaderboardSectionComponent,
    LeaderboardSectionConfigurationComponent,
    ConferenceListSectionComponent,
    ConferenceListSectionConfigurationComponent,
    ProgramPlannerSectionComponent,
    ProgramPlannerSectionConfigurationComponent,
    BannerSectionComponent,
    BannerSectionConfigurationComponent,
    HeroSectionComponent,
    HeroSectionConfigurationComponent,
    PromoSectionComponent,
    PromoSectionConfigurationComponent,
    ComingSoonPageComponent,
    AuthComponent,
    StreamChangeComponent,
    DelegateListSectionComponent,
    DelegateListSectionConfigurationComponent
  ],
  providers: [
    AuthQueryGuard,
    EditQueryGuard,
    NavigationGuard,
    WebsiteResolver,
    PageResolver,
    StaticPageResolver,
    ApiService,
    ArticleService,
    PresentationService,
    TwitterService,
    WebsiteControlService,
    AuthGuard,
  ],
  entryComponents: [
    BlockModalComponent,
    ArticleModalComponent,
    ConferenceAccessModalComponent,
    AuthorModalComponent,
    QuestionModalComponent,
    CardModalComponent,
    DelegateModalComponent,

    ArticleListSectionComponent,
    MediasiteSectionComponent,
    AttendanceCertSectionComponent,
    CustomHtmlSectionComponent,
    SliderSectionComponent,
    RecommendationsSectionComponent,
    TwitterSectionComponent,
    SearchSectionComponent,
    SummarySectionComponent,
    AuthorListSectionComponent,
    SponsorListSectionComponent,
    LiveSuiteSectionComponent,
    LiveSuiteIframeSectionComponent,
    ConferenceListSectionComponent,
    ProgramPlannerSectionComponent,
    BannerSectionComponent,
    HeroSectionComponent,
    PromoSectionComponent ,
    DelegateListSectionComponent,
    LiveSuiteSectionComponent,
  ],
  exports: [
    MediasiteSectionComponent,
    AttendanceCertSectionComponent,
    CustomSectionComponent,
    CustomSectionConfigurationComponent,
    CustomHtmlSectionComponent,
    CustomHtmlSectionConfigurationComponent,
    ArticleListSectionComponent,
    ArticleListSectionConfigurationComponent,
    RecommendationsSectionComponent,
    RecommendationsSectionConfigurationComponent,
    SliderSectionComponent,
    SliderSectionConfigurationComponent,
    TwitterSectionComponent,
    TwitterSectionConfigurationComponent,
    SearchSectionComponent,
    SearchSectionConfigurationComponent,
    SummarySectionComponent,
    SummarySectionConfigurationComponent,
    AuthorListSectionComponent,
    AuthorListSectionConfigurationComponent,
    SponsorListSectionComponent,
    SponsorListSectionConfigurationComponent,
    LiveSuiteSectionComponent,
    LiveSuiteSectionConfigurationComponent,
    LiveSuiteIframeSectionComponent,
    LiveSuiteIframeSectionConfigurationComponent,
    ConferenceListSectionComponent,
    ConferenceListSectionConfigurationComponent,
    ProgramPlannerSectionComponent,
    ProgramPlannerSectionConfigurationComponent,
    BannerSectionComponent,
    BannerSectionConfigurationComponent,
    HeroSectionComponent,
    HeroSectionConfigurationComponent,
    PromoSectionComponent,
    PromoSectionConfigurationComponent,
    ProgramPlannerComponent,
    DelegateListSectionComponent,
    DelegateListSectionConfigurationComponent,
    LeaderboardSectionComponent,
    LeaderboardSectionConfigurationComponent,
  ]
})
export class WebsiteModule { }
