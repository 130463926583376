<div class="nv-form-item nv-select-item" [class.headless]="headless">
    <label  class="nv-control-display {{ required ? 'nv-mandatory' : 'nv-not-mandatory' }}"
            tabindex="0"
            (click)="toggle()"
            (focus)="showListToggle()"
            (keydown.tab)="hideList()"
            (keydown.shift.tab)="hideList()"
            [attr.data-icon-pl]="iconPl"
            [attr.data-icon]="icon"
            [ngClass]="{ 'nv-disabled-state': disabled, 'nv-touched': selected.length > 0 || showList }"
            [style.pointer-events]="readOnly ? 'none' : 'auto'"
            #selectEl>
        <span class="nv-display-multiple-value" *ngIf="multi">
            <span class="nv-selected-option"
                  *ngFor="let value of selected"
                  (click)="removeOption(value)"
            >{{ value.name || value.title }}</span>
        </span>
        <span class="nv-display-value" [class.remove]="canEmpty" *ngIf="!multi" (click)="removeOption(selected[0])">
            {{ selected[0]?.name || selected[0]?.title }}
        </span>
        <span aria-label="label-title" class="label-title">{{ label }}</span>
    </label>
    <div class="nv-list-container" [class.show]="showList" nvInfiniteScroll (scrolled)="emitLoadMore()" #listEl>
        <div class="nv-list-search" *ngIf="showSearch">
            <input type="text"
                   class="nv-drop-input"
                   placeholder="Search..."
                   [(ngModel)]="searchTerm"
                   (ngModelChange)="emitSearch()"
                   (keydown.tab)="hideList()"
                   (keyup.enter)="addClick()"
                   #searchInput>
        </div>
        <div class="empty" *ngIf="!searchTerm && (!options || options.length <= 0)">{{emptyStateText}}</div>
        <div class="not-found" *ngIf="searchTerm && (!options || options.length <= 0)">
            <h5>{{notFoundText}}</h5>
            <p *ngIf="addButtonText">Press Enter to Create</p>
        </div>
        <ul class="nv-options-container"    >
            <li class="nv-option" *ngFor="let option of options" (click)="optionSelect(option)">
                <span class="nv-regular-checkbox" *ngIf="multi">
                    <span class="rcb">
                        <input type="checkbox" [checked]="selectedIds.includes(option.id)">
                        <span class="rc-visual"></span>
                    </span><!-- /.rcb -->
                    <span class="label-txt">{{ option?.name || option?.title }}</span>
                </span>
                <span class="label-txt" *ngIf="!multi">{{ option?.name || option?.title }}</span>
            </li>
            <li class="nv-option"
                *ngIf="addButtonText && (!options || options.length <= 0)"
                [ngClass]="{'active': this.addButtonText && (!this.options || this.options.length <= 0)}">
                <a (click)="addClick()">{{addButtonText}} <span [hidden]="!addButtonShowValue">{{searchTerm}}</span></a>
            </li>
        </ul>
    </div>
</div><!-- /.nv-form-item -->


