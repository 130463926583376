import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Certificate } from '@navus/core/classes/certificate';
import { CertificateService } from '@navus/core/services/certificate.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { Template } from '@navus/core/classes/template';
import { TemplateService } from '@navus/core/services/template.service';

@Component({
  selector: 'main [nv-certificate-details-page]',
  templateUrl: './certificate-details-page.component.html',
  styles: []
})
export class CertificateDetailsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  certificateId: number;

  certificate: Certificate;
  templates: Template[] = [];
  certificateForm: FormGroup;

  sendToOptions = [
    { id: 'test', name: 'Test' },
    { id: 'poster_authors', name: 'Poster Authors' },
    { id: 'delegates', name: 'Delegates' },
    { id: 'csv', name: 'CSV' },
  ];

  loadingCertificate: boolean = false;
  savingCertificate: boolean = false;
  unsavedChanges: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private certificateService: CertificateService,
    private templateService: TemplateService,
    private toastService: ToastService,
    private modalService: ModalService
  ) {
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  ngOnInit() {
    this.initForm();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.certificateId = data.certificateId;
        this.getCertificate();
        this.getTemplates();
      }
    );
    this.subscriptions.push(subscription);

    const changeSubscription = this.certificateForm.valueChanges
      .subscribe(
        () => {
          this.unsavedChanges = true;
        }
      );
    this.subscriptions.push(changeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.certificateForm = this.formBuilder.group({
      title: ['', Validators.required],
      send_to: [''],
      template_id: ['', Validators.required],
      threshold: ['', Validators.required],
      unit_name: [''],
      active: [''],
    });
    this.unsavedChanges = false;
  }

  getCertificate() {
    if (this.certificateId) {
      this.loadingCertificate = true;
      this.certificateService
        .getConferenceCertificate(this.conferenceId, this.certificateId, { include: 'entity' })
        .subscribe(
          (response) => {
            const certificate = response['data'];
            this.certificate = certificate;
            this.certificateForm.patchValue(this.certificate);
            this.loadingCertificate = false;
            setTimeout(() => {
              this.unsavedChanges = false;
            }, 500);
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingCertificate = false;
          }
        );
    } else {
      this.certificate = new Certificate();
      this.loadingCertificate = false;
    }
  }

  save() {
    if (this.certificateForm.invalid) {
      return;
    }

    this.savingCertificate = true;
    if (this.certificateId) {
      this.certificateService
        .updateConferenceCertificate(this.conferenceId, this.certificateId, this.certificateForm.value)
        .subscribe(
          (response) => {
            this.certificate = response['data'];
            this.toastService.success('Certificate successfully saved.', {});
            this.unsavedChanges = false;
            this.savingCertificate = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingCertificate = false;
          }
        );
    } else {
      this.certificateService.createConferenceCertificate(this.conferenceId, this.certificateForm.value)
        .subscribe(
          (response) => {
            this.certificate = response['data'];
            this.toastService.success('Certificate successfully created.', {});
            this.router.navigate([this.certificate?.id], { relativeTo: this.route });
            this.savingCertificate = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingCertificate = false;
          }
        );
    }
  }

  close() {
    if (this.unsavedChanges) {
      this.modalService.defaultModal({
        title: 'Unsaved changes',
        body: 'Are you sure you want to leave the page without saving changes?',
        size: 'small',
        buttons: [
          {
            text: 'Stay',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Leave',
            color: 'primary',
            handler: () => {
              if (this.certificateId) {
                this.router.navigate(['../..', 'certificates'], { relativeTo: this.route });
              } else {
                this.router.navigate(['..', 'certificates'], { relativeTo: this.route });
              }
            }
          }
        ]
      });
    } else {
      if (this.certificateId) {
        this.router.navigate(['../..', 'certificates'], { relativeTo: this.route });
      } else {
        this.router.navigate(['..', 'certificates'], { relativeTo: this.route });
      }
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Certificate Deletion',
      body: 'Are you sure you want to delete this certificate?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.certificateService.deleteConferenceCertificate(this.conferenceId, this.certificateId)
              .subscribe(
                () => {
                  this.close();
                },
                (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

  getTemplates() {
    const params = { with_pagination: false };

    this.templateService
      .getOrganizationTemplates(this.organizationId, params)
      .subscribe(
        (response: { data: Template[], meta: any }) => {
          this.templates = response.data;
        },
        () => {
        }
      );
  }

}
