import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Conference } from "@navus/core/classes/conference";
import { User } from "@navus/core/classes/user";
import { Website } from "@navus/core/classes/website";
import { BannerService } from "@navus/core/services/banner.service";
import { TrackingService } from "@navus/core/services/tracking.service";
import { UserService } from "@navus/core/services/user.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'nv-website-registration',
  templateUrl: './registration.component.html'
})
export class RegistrationComponent implements OnInit {
  website: Website;
  conference: Conference;
  banner: any;
  loading: boolean = true;

  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private bannerService: BannerService,
    private trackingService: TrackingService,
  ) { }
  
  ngOnInit() {
    this.website = this.route.parent.parent.snapshot.data.website;
    this.conference = this.website?.conferences?.find(c => c.id === this.website.active_conference_id);

    this.checkDelegate();

    // Only redirect if not subroute
    if (this.router.url.split('/').length === 2) {
      this.redirect();
    }
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  checkDelegate() {
    this.loading = true;
    this.userService
      .getCurrentUser()
      .subscribe((user: User) => {
        if (user) {
          const delegate = user.delegates.find(d => d.conference_id === this.conference.id);
          if (delegate) {
            if (delegate.conference_ticket_type_id) {
              this.router.navigate(['/profile', 'delegate']);
            }
          }
        } else {
          // this.router.navigate(['/profile/login'], { queryParams: { returnUrl: 'delegate-registration' } });
        }
        this.loading = false;
      });
  }

  redirect() {
    // TODO Change setting to something that makes sense
    const hasRegistration = this.conference.settings.delegate_registration ? this.conference.settings.delegate_registration.enabled : this.conference.settings.ticket?.create_delegate;
    const hasTicketing = this.conference.settings.delegate_registration ? this.conference.settings.delegate_registration.ticketing : this.conference.settings.ticket?.enabled;
    console.info(`Registration: ${hasRegistration}, Ticketing: ${hasTicketing}`);

    if (hasRegistration) {
      if (hasTicketing) {
        this.router.navigate(['ticket'], { relativeTo: this.route, queryParams: this.route.snapshot.queryParams });
      } else {
        this.router.navigate(['delegate'], { relativeTo: this.route, queryParams: this.route.snapshot.queryParams });
      }
    } else {
      this.router.navigate(['/'], { queryParams: this.route.snapshot.queryParams });
    }     
  }

  getBanner() {
    if (!this.conference) { return; }
    
    this.bannerService
        .getBanner({ position: 'Registration', conference_id: this.conference.id })
        .subscribe(
          (response) => {
            this.banner = response.data;
            this.trackingService.shownTracking(this.conference.id, 'banner', this.banner.id);
          },
          () => { }
        );
  }

  bannerClick() {
    if (!this.banner) { return; }

    this.trackingService.clickTracking(this.conference.id, 'banner', this.banner.id);
    window.open(this.banner.external_url, '_blank');
  }

}