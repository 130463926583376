import { Component } from '@angular/core';

@Component({
    selector: 'app-app-wizard-page',
    templateUrl: './app-wizard.component.html'
})
export class AppWizardComponent {

    constructor() { }

}
