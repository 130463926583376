import { Component } from '@angular/core';

@Component({
    selector: 'app-error404-page',
    templateUrl: './error404.component.html'
})
export class Error404Component {

    constructor() { }

}
