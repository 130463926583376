<div class="nv-section cayahl-txt guttered" [class.edit-mode]="mouseOver">
    <ng-container *ngIf="mouseOver">
        <a class="nv-section-add-top" title="Add section" (click)="createBefore()"></a>
        <a class="nv-section-add-bottom" title="Add section" (click)="createAfter()"></a>
        <div class="nv-section-edit-tool-box">
            <a class="edit" title="Edit section" (click)="edit()"></a>
            <a class="delete" title="Delete section" (click)="delete()"></a>
        </div><!-- /.nv-section-edit-tool-box -->
        <div class="nv-section-sort-tool-box">
            <a class="move-up" title="Move up" (click)="moveUp()"></a>
            <a class="move-down" title="Move down" (click)="moveDown()"></a>
        </div><!-- /.nv-section-sort-tool-box -->
    </ng-container>

    <div class="nv-port">
        <div class="nv-row gap-50 v-stretch large-spaced">
            <div class="nv-column desk-8 phab-12">
                <div class="nv-column-content">
                    <h3>Watch CSANZ & ANZET Virtual 2020 content on-demand</h3>
                    <p>Click on the button to be redirected to the on-demand content website</p>
       
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-4 phab-12">
                <div class="nv-column-content">
                    <div>
                        <div>
                            <span>
                                <nv-button (click)="goOnDemand()" [loading]="checkingPermissions" [disabled]="checkingPermissions" translate>On-demand</nv-button>
                            </span>
                        </div>

                    </div><!--/cayahl-authors -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</div>
