import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { User } from '@navus/core/classes/user';
import { JitsiService } from '@navus/core/services/jitsi.service';
import { ToastService } from '@navus/ui/toast/toast.service';

@Component({
  selector: 'nv-livestream-meeting',
  templateUrl: './livestream-meeting.component.html'
})

export class LivestreamMeetingComponent {
  @Input() set conferenceId(value: number) {
    this._conferenceId = value;
  };
  @Input() set locationId(value : number) {
    this._locationId = value;
  };
  @Input() set currentUser(value: User) {
    this._currentUser = value;
  };

  _conferenceId: number;
  _locationId: number;
  _currentUser: User;
  
  activeRoom = null;

  constructor(
    private toastService: ToastService
  ) {  }

  join() {
    this.activeRoom = true;
  }

  close() {
    this.activeRoom = false;
  }

}
