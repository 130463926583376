<div class="nv-config-control-bar">
  <div class="cluf-port">
      <div class="config-title-n-tools">
          <div class="tnt-left">
              <h1 translate>Badge</h1>
          </div><!-- /.tnt-left -->
          <div class="tnt-right">
              <div class="nv-btn-box flow-end gap-10">
                  <nv-button (click)="close()" color="passive" translate>Close</nv-button>
              </div><!-- /.nv-btn-box flow-end gap-6 -->
          </div><!-- /.tnt-right -->
      </div><!-- /.config-title-n-tools -->
  </div>
  <div class="nv-port spaced-bottom">
      <div class="nv-line-tabs guttered">
          <nav class="nv-tabs-nav vertical-break-tab">
              <ul>
                <li>
                  <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'badges']">Leaderboard</a>
                </li>
                <li>
                    <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'badges', 'badges']">Badges</a>
                </li>
                <li>
                    <a class="active">Instructions</a>
                </li>
              </ul>
          </nav>
      </div>
  </div>
</div>
<div class="nv-config-content-port">
  <div class="nv-section">
      <div class="nv-port">
          <div class="nv-row config-plates gap-24 small-spaced-top">
              <div class="nv-column desk-12">
                  <div class="nv-column-content">
                    <h2>Badge instructions</h2>
                    <div class="nv-row config-plates clean gap-30">
                        <div class="nv-column desk-6 tab-12">
                            <div class="nv-column-content">
                                <h3>What are badges?</h3>
                                <p>Badges are achievements delegates can earn while attending an event. From attending sessions and presentations to visiting Sponsor’s pages, badges can be used as an incentive for delegates to explore the event and spent more time in it.</p>
                                <p>Badges are customizable and defined per event. For each badge, the organization can define its name, image, description and number of levels.</p>
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                        <div class="nv-column desk-6 tab-12">
                            <div class="nv-column-content">
                                <figure class="nv-gamification-ilustration"><img src="../../assets/gamification-imgs/gamification-1.jpg" alt="What are badges?"></figure>
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <div class="nv-divider desk-large border"></div>
                            </div>
                        </div><!-- /.nv-column -->
                        <div class="nv-column desk-6 tab-12">
                            <div class="nv-column-content">
                                <h3>How to create a badge?</h3>
                                <p>To create a badge, click on the “Add badge” button in the top right corner.</p>
                                <p>In the page that opens, enter the name of the badge, and fill out the required information for the badge levels. Badge levels allow you to expand each badge’s achievements, by increasing the number or length of required actions to achieve the next level of the badge.</p>
                                <p>Each level consists of a: Name, Image, Condition, Condition text and Success message.</p>
                                <h3>Level name</h3>
                                <p>By default, levels are named by their number, but that can be changed. For example, if you want to organize badge levels like medals, you can rename “level 1” to “bronze” etc.</p>
                                <h3>Badge image</h3>
                                <p>You can upload an image of your choice for each level. We suggest using a 150x150 px image.</p>
                                <h3>Condition</h3>
                                <p>A condition consists of an <strong>Action</strong> - requirement for earning a badge, and a <strong>Threshold</strong> - the number of times or the duration an action has to be taken for the delegate to meet the requirement for earning a badge.</p>
                                <p>At the moment, available actions inclue:</p>
                                <ul>
                                    <li>Number of live presentations attended</li>
                                    <li>Number of on-demand presentations attended</li>
                                    <li>First question asked in Q&A</li>
                                    <li>Participated in Q&A</li>
                                    <li>Activity in Voting</li>
                                    <li>Visit sponsor page</li>
                                    <li>Interaction with sponsor</li>
                                    <li>Interacting with other attendees</li>
                                </ul>
                                <h3>Condition text</h3>
                                <p><strong>Condition text</strong> will be displayed to delegates while they’re still earning that level of the badge. This should be a short instruction on how to complete actions needed to earn the bage. For example: “View 5 presentations materials to earn this badge.”.</p>
                                <h3>Success message</h3>
                                <p><strong>Success message</strong> will be displayed to the delegates when they earn the badge. For example: “You have earned the bronze badge for attendance!”.</p>
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                        <div class="nv-column desk-6 tab-12">
                            <div class="nv-column-content">
                                <figure class="nv-gamification-ilustration">
                                    <img src="../../assets/gamification-imgs/gamification-2.jpg" alt="How to create a badge?">
                                    <p>Anatomy of a badge</p>
                                </figure>
                                <br><br>
                                <figure class="nv-gamification-ilustration">
                                    <img src="../../assets/gamification-imgs/gamification-3.jpg" alt="Badge images">
                                    <p>Badge images</p>
                                </figure>
                                <br><br>
                                <figure class="nv-gamification-ilustration">
                                    <img src="../../assets/gamification-imgs/gamification-4.jpg" alt="Badge notifications">
                                    <p>Badge notifications</p>
                                </figure>
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <div class="nv-divider desk-large border"></div>
                            </div>
                        </div><!-- /.nv-column -->
                        <div class="nv-column desk-6 tab-12">
                            <div class="nv-column-content">
                                <h3>Editing badges and recalculating earned points</h3>
                                <p>If you decide to change conditions required to earn badge levels, you can do so by editing the existing badge. After making the changes, make sure that the “Recalculate delegates’ badge completion” checkbox is marked before saving the changes. This will make the system recalculate the actions delegates have taken and adjust the badge level they earned to match the new conditions.</p>

                                <div class="nv-msg-block warning">
                                    <h4>Warning</h4>
                                    <p>Recalculating delegates’ badge completion might cause some delegates to lose badge levels they earned in cases where the condition tresholds are raised, or get higher levels in cases where they are lowered. The delegates will not be automatically notified about these changes.</p>
                                </div><!-- /.nv-msg-block -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                        <div class="nv-column desk-6 tab-12">
                            <div class="nv-column-content">
                                <figure class="nv-gamification-ilustration">
                                    <img src="../../assets/gamification-imgs/gamification-5.jpg" alt="Editing badges and recalculating earned points">
                                </figure>
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div><!-- /nv-row -->
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
