<div #tref class="nv-page-wrap"> <!-- class 'nv-loading' to set loading state -->
    <!--<div class="nv-loading-visual">Loading</div>&lt;!&ndash; /.loading-visual &ndash;&gt;-->

    <div class="nv-configurator-layout">

        <aside class="config-layout-sidebar">
            <div class="scroll-container config-nav-scroll">
                <div class="scrollable-content">
                    <div class="cofnig-meta-face">
                        <div class="cmf-author">
                            <span>Admin</span>
                            <figure class="cmf-author-img">
                                <span><img src="../assets/avatar-dummy.jpg" alt=""></span>
                                <figcaption>Melissa Horstein</figcaption>
                            </figure>
                        </div><!-- /cmf-author -->
                        <div class="cmf-meta">
                            <p>
                                <time datetime="2018-09-08 20:00">09.08.2018 - 8:24pm</time>
                                <span class="location">Belgrade</span>
                            </p>
                        </div><!-- /cmf-meta -->
                        <a href="#" class="settings" title="change settings"></a>
                    </div><!-- / cofnig-meta-face -->

                    <nav class="configurator-menu">
                        <h3>Doing</h3>
                        <ul>
                            <li>
                                <a href="#" title="Portal setup" class="cm-event-setup">
                                    <em class="cm-ico"></em>
                                    <strong>Event Setup</strong>
                                    <span>Set basic properties of the portal</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" title="WebSite Builder" class="cm-website-builder">
                                    <em class="cm-ico"></em>
                                    <strong>Website Builder</strong>
                                    <span>Create portal's element</span>
                                </a>
                            </li>
                            <li class="disabled">
                                <a href="#" title="App Configurator" class="cm-app-configurator">
                                    <em class="cm-ico"></em>
                                    <strong>App Configurator</strong>
                                    <span>Maximize timely deliverables for real-time schemas</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" title="Program Setup" class="cm-program-setup">
                                    <em class="cm-ico"></em>
                                    <strong>Program Setup</strong>
                                    <span>Dynamically procrastinate B2C users</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" title="Speaker Managment" class="cm-speaker-managment">
                                    <em class="cm-ico"></em>
                                    <strong>Speaker Managment</strong>
                                    <span>Maximize Lorem ipsum dolor sit</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" title="Communication Materials" class="cm-communication-materials">
                                    <em class="cm-ico"></em>
                                    <strong>Communication Materials</strong>
                                    <span>Deliverables for real-time schemas</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" title="Campaign Tool" class="active cm-campaign-tool">
                                    <em class="cm-ico"></em>
                                    <strong>Campaign Tool</strong>
                                    <span>Innovate resource-leveling customer</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" title="Sponsors & Exhibitors" class="cm-sponsors-exhibitors">
                                    <em class="cm-ico"></em>
                                    <strong>Sponsors &amp; Exhibitors</strong>
                                    <span>Quickly maximize timely deliverables</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" title="Event Website Builder" class="cm-event-website-builder">
                                    <em class="cm-ico"></em>
                                    <strong>Event Website Builder</strong>
                                    <span>Quickly maximize timely deliverables</span>
                                </a>
                            </li>
                        </ul>
                        <h3>Learning</h3>
                        <ul>
                            <li>
                                <a href="#" title="E-Materials" class="cm-ematerials">
                                    <em class="cm-ico"></em>
                                    <strong>E-Materials</strong>
                                    <span>Dynamically procrastinate B2C users</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" title="Hub" class="cm-hub">
                                    <em class="cm-ico"></em>
                                    <strong>Hub</strong>
                                    <span>Quickly maximize timely deliverables</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" title="AI / Machine Learning" class="cm-ai-mashine">
                                    <em class="cm-ico"></em>
                                    <strong>AI / Machine Learning</strong>
                                    <span>Dynamically innovate resource-leveling customer</span>
                                </a>
                            </li>
                        </ul>
                        <h3>Earning</h3>
                        <ul>
                            <li>
                                <a href="#" title="Sponsored Spaces" class="cm-sponsored-spaces">
                                    <em class="cm-ico"></em>
                                    <strong>Sponsored Spaces</strong>
                                    <span>Quickly maximize timely deliverables</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" title="Registration System" class="cm-registration-system">
                                    <em class="cm-ico"></em>
                                    <strong>Registration System</strong>
                                    <span>Dynamically innovate resource-leveling customer </span>
                                </a>
                            </li>
                            <li>
                                <a href="#" title="Lead Link Services" class="cm-leadlink">
                                    <em class="cm-ico"></em>
                                    <strong>Lead Link Services</strong>
                                    <span>Dynamically innovate resource-leveling customer</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" title="E-campus On-site Service" class="cm-ecampus">
                                    <em class="cm-ico"></em>
                                    <strong>E-campus On-site Service</strong>
                                    <span>Quickly maximize timely deliverables</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div><!-- /scrollable-content -->
            </div>
        </aside><!-- /config-layout-sidebar -->

        <main class="config-layout-main-frame">
            <div class="config-layout-upper-fixed">
                <div class="cluf-port">
                    <div class="cluf-header-n-nav">
                        <header class="cluf-header">
                            <p class="crumb">
                                <span><a href="#">Configurator</a></span>
                            </p>
                            <h1>Campaign Tool</h1>
                        </header>
                        <nav class="config-global-nav">
                            <ul>
                                <li>
                                    <a class="current configure" title="Configure">Configure</a>
                                </li>
                                <li>
                                    <a class="preview" *ngIf="false" title="Preview">Preview</a>
                                </li>
                                <li>
                                    <a class="dashboard" *ngIf="false" title="Dashboard">Dashboard</a>
                                </li>
                            </ul>
                        </nav><!-- /config-global-nav -->
                    </div><!-- /cluf-header-n-nav -->


                    <div class="cluf-additional-bar">
                        <nav class="cluf-additional-nav">
                            <span class="ico campaign-tool"></span>
                            <ul>
                                <li><a href="#" class="active">Campaigns</a></li>
                                <li><a href="#">Contacts</a></li>
                                <li><a href="#">Templates</a></li>
                            </ul>
                        </nav>
                        <!--<nav class="cluf-additional-nav tools">-->
                            <!--<ul>-->
                                <!--<li><a href="#" class="add-page"><span>Add another page</span></a></li>-->
                                <!--<li><a href="#" class="move-page"><span>Move current page</span></a></li>-->
                                <!--<li><a href="#" class="delete-page"><span>Delete current page</span></a></li>-->
                            <!--</ul>-->
                        <!--</nav>&lt;!&ndash; /cluf-additional-nav &ndash;&gt;-->
                    </div><!-- /cluf-additional-bar -->
                </div><!-- /cluf-port --->
            </div><!-- /config-layout-upper-fixed -->

            <!--<div class="config-layout-info-bar-fixed">-->
            <!--<div class="clbf-msg success">-->
            <!--<div class="clbf-content">-->
            <!--<p>Tiramisu icing cookie jelly-o sesame snaps dragée chupa chups marshmallow carrot cake.</p>-->
            <!--</div>-->
            <!--<span class="clbf-close" title="Close Message"></span>-->
            <!--</div>&lt;!&ndash; /clbf-msg &ndash;&gt;-->
            <!--</div>&lt;!&ndash; /config-layout-lower-fixed &ndash;&gt;-->

            <div class="config-layout-lower-fixed">
                <div class="nv-port">
                    <!-- CAMPAIGN MANAGER -->

                    <!--<div class="nv-row config-plates clean gap-24">-->
                        <!--<div class="nv-column desk-4">-->
                            <!--<div class="nv-column-content">-->
                                <!--<header class="nv-config-block-header">-->
                                    <!--<h2>Campaigns</h2>-->
                                    <!--<span class="nv-info-point">-->
                                    <!--<span class="nip-txt">-->
                                        <!--Quis meh echo park polaroid mollit, tumblr banjo actually viral. Pariatur four loko cray taiyaki succulents wayfarers esse ad cupidatat art party et heirloom banjo. Id celiac in, veniam wolf deep v kitsch chambray organic exercitation put a bird on it forage.-->
                                    <!--</span>&lt;!&ndash; /.nip-txt &ndash;&gt;-->
                                <!--</span>&lt;!&ndash; /.nv-info-point &ndash;&gt;-->
                                <!--</header>-->
                            <!--</div>-->
                        <!--</div>&lt;!&ndash; /nv-column &ndash;&gt;-->
                    <!--</div>&lt;!&ndash; /nv-row &#45;&#45; &ndash;&gt;-->

                    <div class="nv-row config-plates gap-24 propagate">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <header class="nv-config-bold-header">
                                    <h3>Campaigns</h3>
                                    <div class="cbh-tools">
                                        <!--<div class="cbh-sort-tool-box">-->
                                            <!--<h6>Sort by:</h6>-->
                                            <!--<div class="cstb-item alphabet" title="Sort by Name" data-label="name">-->
                                                <!--<a ng-href="" class="active" title="descending"></a>-->
                                                <!--<a ng-href="" title="ascending"></a>-->
                                            <!--</div>&lt;!&ndash; /.cstb-item-box &ndash;&gt;-->
                                            <!--<div class="cstb-item time" title="Sort Chronologically" data-label="time">-->
                                                <!--<a ng-href="" title="descending"></a>-->
                                                <!--<a ng-href="" title="ascending"></a>-->
                                            <!--</div>&lt;!&ndash; /.cstb-item-box &ndash;&gt;-->
                                            <!--<div class="cstb-item recipients" title="Sort by number of recipients" data-label="nr. of recipients">-->
                                                <!--<a ng-href="" title="descending"></a>-->
                                                <!--<a ng-href="" title="ascending"></a>-->
                                            <!--</div>&lt;!&ndash; /.cstb-item-box &ndash;&gt;-->
                                        <!--</div>&lt;!&ndash; /.cbh-sort-tool-box &ndash;&gt;-->
                                    </div>
                                </header>

                                <div class="nv-space-between-bar">
                                    <div class="nv-sort-tool-box">
                                        <h6>Sort by:</h6>
                                        <div class="cstb-item alphabet" title="Sort by Name" data-label="name">
                                            <a ng-href="" class="active" title="descending"></a>
                                            <a ng-href="" title="ascending"></a>
                                        </div><!-- /.cstb-item-box -->
                                        <div class="cstb-item time" title="Sort Chronologically" data-label="time">
                                            <a ng-href="" title="descending"></a>
                                            <a ng-href="" title="ascending"></a>
                                        </div><!-- /.cstb-item-box -->
                                        <div class="cstb-item recipients" title="Sort by number of recipients" data-label="nr. of recipients">
                                            <a ng-href="" title="descending"></a>
                                            <a ng-href="" title="ascending"></a>
                                        </div><!-- /.cstb-item-box -->
                                    </div><!-- /.cbh-sort-tool-box -->

                                    <div class="nv-simple-toggler">
                                        <h6>Show labels</h6>
                                        <span class=""></span>
                                    </div><!-- /.nv-toggler -->
                                </div><!-- /.nv-space-between-bar -->



                                <nav class="nv-config-tools-list"><!-- / class 'labels-shown' to show labels:) -->
                                    <ul>
                                        <li>
                                            <h4>
                                                <span class="stamp">28.07.2019 | 14:32</span>
                                                <a href="#">Delegate registrations xy</a>
                                            </h4>
                                            <div class="ncl-status-tools">
                                                <a data-ng-href="" class="draft" title="edit draft"><span>draft</span></a>
                                                <a data-ng-href="" class="copy" title="copy campaign"><span>copy</span></a>
                                                <a data-ng-href="" class="preview" title="quick glimpse at the template used"><span>preview</span></a>
                                            </div><!-- /.ncl-status-tools -->
                                        </li>
                                        <li>
                                            <h4>
                                                <span class="stamp">25.06.2019 | 10:15</span>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing
                                            </h4>
                                            <div class="ncl-status-tools">
                                                <a data-ng-href="" class="report" title="see stats"><span>report</span></a>
                                                <a data-ng-href="" class="copy" title="copy campaign"><span>copy</span></a>
                                                <a data-ng-href="" class="preview" title="quick glimpse at the template used"><span>preview</span></a>
                                            </div><!-- /.ncl-status-tools -->
                                        </li>
                                        <li>
                                            <h4>
                                                <span class="stamp">14.06.2019 | 22:02</span>
                                                Master cleanse woke ennui pabst green juice pug
                                            </h4>
                                            <div class="ncl-status-tools">
                                                <a data-ng-href="" class="report" title="see stats"><span>report</span></a>
                                                <a data-ng-href="" class="copy" title="copy campaign"><span>copy</span></a>
                                                <a data-ng-href="" class="preview" title="quick glimpse at the template used"><span>preview</span></a>
                                            </div><!-- /.ncl-status-tools -->
                                        </li>
                                        <li>
                                            <h4>
                                                <span class="stamp">28.05.2019 | 18:22</span>
                                                Truffaut cronut glossier cloud bread
                                            </h4>
                                            <div class="ncl-status-tools">
                                                <a data-ng-href="" class="report" title="see stats"><span>report</span></a>
                                                <a data-ng-href="" class="copy" title="copy campaign"><span>copy</span></a>
                                                <a data-ng-href="" class="preview" title="quick glimpse at the template used"><span>preview</span></a>
                                            </div><!-- /.ncl-status-tools -->
                                        </li>
                                        <li>
                                            <h4>
                                                <span class="stamp">08.07.2019 | 14:32</span>
                                                <a href="#">Woke adaptogen beard venmo</a>
                                            </h4>
                                            <div class="ncl-status-tools">
                                                <a data-ng-href="" class="draft" title="edit draft"><span>draft</span></a>
                                                <a data-ng-href="" class="copy" title="copy campaign"><span>copy</span></a>
                                                <a data-ng-href="" class="preview" title="quick glimpse at the template used"><span>preview</span></a>
                                            </div><!-- /.ncl-status-tools -->
                                        </li>
                                        <li>
                                            <h4>
                                                <span class="stamp">25.06.2019 | 04:07</span>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing
                                            </h4>
                                            <div class="ncl-status-tools">
                                                <a data-ng-href="" class="report" title="see stats"><span>report</span></a>
                                                <a data-ng-href="" class="copy" title="copy campaign"><span>copy</span></a>
                                                <a data-ng-href="" class="preview" title="quick glimpse at the template used"><span>preview</span></a>
                                            </div><!-- /.ncl-status-tools -->
                                        </li>
                                        <li>
                                            <h4>
                                                <span class="stamp">11.06.2019 | 21:11</span>
                                                Master cleanse woke ennui pabst green juice pug
                                            </h4>
                                            <div class="ncl-status-tools">
                                                <a data-ng-href="" class="report" title="see stats"><span>report</span></a>
                                                <a data-ng-href="" class="copy" title="copy campaign"><span>copy</span></a>
                                                <a data-ng-href="" class="preview" title="quick glimpse at the template used"><span>preview</span></a>
                                            </div><!-- /.ncl-status-tools -->
                                        </li>
                                        <li>
                                            <h4>
                                                <span class="stamp">11.06.2019 | 10:15</span>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing
                                            </h4>
                                            <div class="ncl-status-tools">
                                                <a data-ng-href="" class="report" title="see stats"><span>report</span></a>
                                                <a data-ng-href="" class="copy" title="copy campaign"><span>copy</span></a>
                                                <a data-ng-href="" class="preview" title="quick glimpse at the template used"><span>preview</span></a>
                                            </div><!-- /.ncl-status-tools -->
                                        </li>
                                        <li>
                                            <h4>
                                                <span class="stamp">03.05.2019 | 08:44</span>
                                                Heirloom seitan cronut brunch synth paleo lomo
                                            </h4>
                                            <div class="ncl-status-tools">
                                                <a data-ng-href="" class="report" title="see stats"><span>report</span></a>
                                                <a data-ng-href="" class="copy" title="copy campaign"><span>copy</span></a>
                                                <a data-ng-href="" class="preview" title="quick glimpse at the template used"><span>preview</span></a>
                                            </div><!-- /.ncl-status-tools -->
                                        </li>
                                        <li>
                                            <h4>
                                                <span class="stamp">08.07.2019 | 14:32</span>
                                                <a href="#">Woke adaptogen beard venmo</a>
                                            </h4>
                                            <div class="ncl-status-tools">
                                                <a data-ng-href="" class="draft" title="edit draft"><span>draft</span></a>
                                                <a data-ng-href="" class="copy" title="copy campaign"><span>copy</span></a>
                                                <a data-ng-href="" class="preview" title="quick glimpse at the template used"><span>preview</span></a>
                                            </div><!-- /.ncl-status-tools -->
                                        </li>
                                        <li>
                                            <h4>
                                                <span class="stamp">28.07.2019 | 14:32</span>
                                                <a href="#">Delegate registrations xy</a>
                                            </h4>
                                            <div class="ncl-status-tools">
                                                <a data-ng-href="" class="draft" title="edit draft"><span>draft</span></a>
                                                <a data-ng-href="" class="copy" title="copy campaign"><span>copy</span></a>
                                                <a data-ng-href="" class="preview" title="quick glimpse at the template used"><span>preview</span></a>
                                            </div><!-- /.ncl-status-tools -->
                                        </li>
                                        <li>
                                            <h4>
                                                <span class="stamp">25.06.2019 | 10:15</span>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing
                                            </h4>
                                            <div class="ncl-status-tools">
                                                <a data-ng-href="" class="report" title="see stats"><span>report</span></a>
                                                <a data-ng-href="" class="copy" title="copy campaign"><span>copy</span></a>
                                                <a data-ng-href="" class="preview" title="quick glimpse at the template used"><span>preview</span></a>
                                            </div><!-- /.ncl-status-tools -->
                                        </li>
                                        <li>
                                            <h4>
                                                <span class="stamp">14.06.2019 | 22:02</span>
                                                Master cleanse woke ennui pabst green juice pug
                                            </h4>
                                            <div class="ncl-status-tools">
                                                <a data-ng-href="" class="report" title="see stats"><span>report</span></a>
                                                <a data-ng-href="" class="copy" title="copy campaign"><span>copy</span></a>
                                                <a data-ng-href="" class="preview" title="quick glimpse at the template used"><span>preview</span></a>
                                            </div><!-- /.ncl-status-tools -->
                                        </li>
                                        <li>
                                            <h4>
                                                <span class="stamp">28.05.2019 | 18:22</span>
                                                Truffaut cronut glossier cloud bread
                                            </h4>
                                            <div class="ncl-status-tools">
                                                <a data-ng-href="" class="report" title="see stats"><span>report</span></a>
                                                <a data-ng-href="" class="copy" title="copy campaign"><span>copy</span></a>
                                                <a data-ng-href="" class="preview" title="quick glimpse at the template used"><span>preview</span></a>
                                            </div><!-- /.ncl-status-tools -->
                                        </li>
                                        <li>
                                            <h4>
                                                <span class="stamp">08.07.2019 | 14:32</span>
                                                <a href="#">Woke adaptogen beard venmo</a>
                                            </h4>
                                            <div class="ncl-status-tools">
                                                <a data-ng-href="" class="draft" title="edit draft"><span>draft</span></a>
                                                <a data-ng-href="" class="copy" title="copy campaign"><span>copy</span></a>
                                                <a data-ng-href="" class="preview" title="quick glimpse at the template used"><span>preview</span></a>
                                            </div><!-- /.ncl-status-tools -->
                                        </li>
                                        <li>
                                            <h4>
                                                <span class="stamp">25.06.2019 | 04:07</span>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing
                                            </h4>
                                            <div class="ncl-status-tools">
                                                <a data-ng-href="" class="report" title="see stats"><span>report</span></a>
                                                <a data-ng-href="" class="copy" title="copy campaign"><span>copy</span></a>
                                                <a data-ng-href="" class="preview" title="quick glimpse at the template used"><span>preview</span></a>
                                            </div><!-- /.ncl-status-tools -->
                                        </li>
                                        <li>
                                            <h4>
                                                <span class="stamp">11.06.2019 | 21:11</span>
                                                Master cleanse woke ennui pabst green juice pug
                                            </h4>
                                            <div class="ncl-status-tools">
                                                <a data-ng-href="" class="report" title="see stats"><span>report</span></a>
                                                <a data-ng-href="" class="copy" title="copy campaign"><span>copy</span></a>
                                                <a data-ng-href="" class="preview" title="quick glimpse at the template used"><span>preview</span></a>
                                            </div><!-- /.ncl-status-tools -->
                                        </li>
                                        <li>
                                            <h4>
                                                <span class="stamp">11.06.2019 | 10:15</span>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing
                                            </h4>
                                            <div class="ncl-status-tools">
                                                <a data-ng-href="" class="report" title="see stats"><span>report</span></a>
                                                <a data-ng-href="" class="copy" title="copy campaign"><span>copy</span></a>
                                                <a data-ng-href="" class="preview" title="quick glimpse at the template used"><span>preview</span></a>
                                            </div><!-- /.ncl-status-tools -->
                                        </li>
                                        <li>
                                            <h4>
                                                <span class="stamp">03.05.2019 | 08:44</span>
                                                Heirloom seitan cronut brunch synth paleo lomo
                                            </h4>
                                            <div class="ncl-status-tools">
                                                <a data-ng-href="" class="report" title="see stats"><span>report</span></a>
                                                <a data-ng-href="" class="copy" title="copy campaign"><span>copy</span></a>
                                                <a data-ng-href="" class="preview" title="quick glimpse at the template used"><span>preview</span></a>
                                            </div><!-- /.ncl-status-tools -->
                                        </li>
                                        <li>
                                            <h4>
                                                <span class="stamp">08.07.2019 | 14:32</span>
                                                <a href="#">Woke adaptogen beard venmo</a>
                                            </h4>
                                            <div class="ncl-status-tools">
                                                <a data-ng-href="" class="draft" title="edit draft"><span>draft</span></a>
                                                <a data-ng-href="" class="copy" title="copy campaign"><span>copy</span></a>
                                                <a data-ng-href="" class="preview" title="quick glimpse at the template used"><span>preview</span></a>
                                            </div><!-- /.ncl-status-tools -->
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div><!-- /nv-column -->
                    </div><!-- /nv-row -- -->


                    <!-- individual page -->

                    <div class="nv-row config-plates gap-24 propagate">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <header class="nv-config-bold-header">
                                    <h3>Add new Campaign</h3>
                                </header>

                                <div class="nv-config-input-row nv-row gap-24 config-plates clean propagate">

                                    <div class="nv-column desk-auto">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <span class="nv-info-point question">
                                                    <span class="nip-txt">
                                                        Quis meh echo park polaroid mollit, tumblr banjo actually viral. Pariatur four loko cray taiyaki succulents wayfarers esse ad cupidatat art party et heirloom banjo. Id celiac in, veniam wolf deep v kitsch chambray organic exercitation put a bird on it forage.
                                                    </span><!-- /.nip-txt -->
                                                </span><!-- /.nv-info-point -->
                                                <label>
                                                    <input type="text" placeholder="Name of the new Campaign">
                                                    <span class="label-title">Name of the new Campaign</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column --->

                                    <div class="nv-column desk-initial">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <button type="button" class="nv-btn block fw medium filled gray" data-icon-pl="&#xe085;">Set new campaign</button>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column --->
                                </div><!-- /nv-config-input-row -->
                            </div>
                        </div><!-- /nv-column -->
                    </div><!-- /nv-row -- -->

                    <div class="nv-row config-plates gap-24 propagate">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <header class="nv-config-bold-header">
                                    <div class="nv-aligned-bar-inline">
                                        <a class="nv-go-bck">back</a><!-- /.nv-go-bck -->
                                        <h6 class="nv-label">campagin:</h6>
                                        <h3>Dummy campaign selected</h3>
                                    </div><!-- /.nv-aligned-bar-inline -->

                                    <span class="nv-info-point">
                                        <span class="nip-txt">
                                            Quis meh echo park polaroid mollit, tumblr banjo actually viral. Pariatur four loko cray taiyaki succulents wayfarers esse ad cupidatat art party et heirloom banjo. Id celiac in, veniam wolf deep v kitsch chambray organic exercitation put a bird on it forage.
                                        </span><!-- /.nip-txt -->
                                    </span><!-- /.nv-info-point -->

                                    <!--<div class="cbh-tools">-->
                                        <!--<div class="cbh-sort-tool-box">-->
                                        <!--<h6>Sort by:</h6>-->
                                        <!--<div class="cstb-item alphabet" title="Sort by Name" data-label="name">-->
                                        <!--<a ng-href="" class="active" title="descending"></a>-->
                                        <!--<a ng-href="" title="ascending"></a>-->
                                        <!--</div>&lt;!&ndash; /.cstb-item-box &ndash;&gt;-->
                                        <!--<div class="cstb-item time" title="Sort Chronologically" data-label="time">-->
                                        <!--<a ng-href="" title="descending"></a>-->
                                        <!--<a ng-href="" title="ascending"></a>-->
                                        <!--</div>&lt;!&ndash; /.cstb-item-box &ndash;&gt;-->
                                        <!--<div class="cstb-item recipients" title="Sort by number of recipients" data-label="nr. of recipients">-->
                                        <!--<a ng-href="" title="descending"></a>-->
                                        <!--<a ng-href="" title="ascending"></a>-->
                                        <!--</div>&lt;!&ndash; /.cstb-item-box &ndash;&gt;-->
                                        <!--</div>&lt;!&ndash; /.cbh-sort-tool-box &ndash;&gt;-->
                                    <!--</div>-->
                                </header>

                                <div class="nv-config-input-row nv-row gap-24 config-plates clean propagate">

                                    <div class="nv-column desk-6">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <span class="nv-info-point question">
                                                    <span class="nip-txt">
                                                        Quis meh echo park polaroid mollit, tumblr banjo actually viral. Pariatur four loko cray taiyaki succulents wayfarers esse ad cupidatat art party et heirloom banjo. Id celiac in, veniam wolf deep v kitsch chambray organic exercitation put a bird on it forage.
                                                    </span><!-- /.nip-txt -->
                                                </span><!-- /.nv-info-point -->
                                                <label>
                                                    <input type="text" placeholder="Name of the Campaign">
                                                    <span class="label-title">Name of the Campaign</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->

                                    <div class="nv-column desk-6">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <span class="nv-info-point question">
                                                    <span class="nip-txt">
                                                        Quis meh echo park polaroid mollit, tumblr banjo actually viral. Pariatur four loko cray taiyaki succulents wayfarers esse ad cupidatat art party et heirloom banjo. Id celiac in, veniam wolf deep v kitsch chambray organic exercitation put a bird on it forage.
                                                    </span><!-- /.nip-txt -->
                                                </span><!-- /.nv-info-point -->
                                                <label>
                                                    <select>
                                                        <option>Select options here</option>
                                                        <option>Options to choose form</option>
                                                        <option>Like this one</option>
                                                        <option>Or perhaps 4th from top</option>
                                                        <option>And so on..</option>
                                                    </select>
                                                    <span class="label-title">Select template for the campaign</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->

                                    <div class="nv-column desk-12 hr solid"></div>

                                    <div class="nv-column desk-9">
                                        <div class="nv-column-content">
                                            <h4>Recipients</h4>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet asperiores cupiditate dignissimos doloremque eius eveniet, facilis, itaque necessitatibus nemo nostrum porro quidem sint tenetur.</p>
                                            <p>Selfies roof party flexitarian, vegan microdosing ramps flannel cray cornhole put a bird on it church-key you probably haven't heard of them celiac brooklyn tacos. Kale chips pickled banjo plaid, chillwave gentrify VHS woke typewriter glossier. Synth before they sold out fanny pack, snackwave iceland enamel pin green juice heirloom kogi meh activated charcoal direct trade.</p>
                                        </div>
                                    </div><!-- /nv-column -->

                                    <div class="nv-column desk-initial v-middle">
                                        <div class="nv-column-content">
                                            <button type="button" class="nv-btn block fw small filled grayish" data-icon="&#xe965;">Download example XLS/X file</button>
                                       </div>
                                    </div><!-- /nv-column -->

                                    <div class="nv-column desk-12 hr solid"></div>

                                    <div class="nv-column desk-8">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <label>
                                                    <input type="text" placeholder="Set recipients out of CSV file">
                                                    <span class="label-title">Set recipients out of CSV file</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->
                                    <div class="nv-column desk-4">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <button type="button" class="nv-btn block fw medium filled gray" data-icon-pl="&#xe085;">Choose file</button>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->


                                    <div class="nv-column desk-8">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <label>
                                                    <input type="text" placeholder="Set recipients out of XLS file">
                                                    <span class="label-title">Set recipients out of XLS file</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->
                                    <div class="nv-column desk-4">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <button type="button" class="nv-btn block fw medium filled gray" data-icon-pl="&#xe085;">Choose file</button>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->

                                    <div class="nv-column desk-8">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item disabled">
                                                <label>
                                                    <input type="text" placeholder="john.doe@gmail.com" disabled>
                                                    <span class="label-title">john.doe@gmail.com</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->
                                    <div class="nv-column desk-4">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <button type="button" class="nv-btn block fw medium filled gray" data-icon-pl="&#xe082;">Delete recipient</button>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->

                                    <div class="nv-column desk-8">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <label>
                                                    <input type="text" placeholder="Recipient's Email address">
                                                    <span class="label-title">Recipient's Email address</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->
                                    <div class="nv-column desk-4">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <button type="button" class="nv-btn block fw medium filled gray" data-icon-pl="&#xe095;">Set custom recipient</button>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->

                                    <div class="nv-column desk-12">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <div class="nv-inline-items">
                                                    <div class="ni-item">
                                                        <p>Make this campaign super special (by ticking this box)</p>
                                                        &nbsp;&nbsp;
                                                        <div class="tsq-checkbox-radio">
                                                            <label title="select item">
                                                                <input type="checkbox" name="r2">
                                                                <span class="tsq-check-visual"></span>
                                                            </label>
                                                        </div>
                                                    </div><!-- /ni-item -->
                                                </div>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->

                                    <div class="nv-column desk-12 hr solid"></div>

                                    <div class="nv-column desk-initial">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <div class="nv-inline-items">
                                                    <div class="ni-item">
                                                        <button type="button" class="nv-btn small filled secondary" data-icon-pl="&#xe03f;">Save</button>
                                                    </div><!-- /ni-item -->
                                                </div>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->

                                    <div class="nv-column desk-initial">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <div class="nv-inline-items">
                                                    <div class="ni-item">
                                                        <button type="button" class="nv-btn small filled primary disabled" data-icon-pl="&#xe094;">Send Batch</button>
                                                    </div><!-- /ni-item -->
                                                </div>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->

                                    <div class="nv-column desk-initial">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <div class="nv-inline-items">
                                                    <div class="ni-item">
                                                        <button type="button" class="nv-btn small filled grayish" data-icon-pl="&#xe082;">Cancel</button>
                                                    </div><!-- /ni-item -->
                                                </div>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->

                                </div><!-- /nv-config-input-row -->
                            </div><!-- /nv-column-content -->
                        </div><!-- /nv-column -->
                    </div><!-- /nv-row -- -->



                    <div class="nv-row config-plates gap-24">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <header class="nv-config-bold-header">
                                    <h3>Contact List</h3>
                                </header>

                                <table class="nv-contacts">
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Title</th>
                                        <th>Reference</th>
                                        <th>eMail</th>
                                        <th>Added on</th>
                                        <th>In campaign</th>
                                        <th>
                                            <div class="tsq-checkbox-radio">
                                                <label title="select item">
                                                    <input type="checkbox" name="c1">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>Sue</td>
                                        <td>Monaghan</td>
                                        <td>Mrs</td>
                                        <td>Belfast Medicine University</td>
                                        <td>sue.monaghan@bmu.ir</td>
                                        <td>26.08.2018</td>
                                        <td><span class="td-status y"><a href="#">yes</a></span></td>
                                        <td>
                                            <div class="tsq-checkbox-radio">
                                                <label title="select item">
                                                    <input type="checkbox" name="c1">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Jane</td>
                                        <td>Adams</td>
                                        <td>Mrs</td>
                                        <td>UDF Club</td>
                                        <td>jane.adams@udf-uk.com</td>
                                        <td>18.06.2018</td>
                                        <td><span class="td-status n">no <a href="#" class="ts-check">special</a></span></td>
                                        <td>
                                            <div class="tsq-checkbox-radio">
                                                <label title="select item">
                                                    <input type="checkbox" name="c1">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Tomer</td>
                                        <td>Sisley</td>
                                        <td>Mr</td>
                                        <td>Messiah Society</td>
                                        <td>tomer@messiahforlife.com</td>
                                        <td>11.04.2018</td>
                                        <td><span class="td-status n">no</span></td>
                                        <td>
                                            <div class="tsq-checkbox-radio">
                                                <label title="select item">
                                                    <input type="checkbox" name="c1">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Odile</td>
                                        <td>Vuillemin</td>
                                        <td>Dr</td>
                                        <td>Oncology Clinic Ohio</td>
                                        <td>odile@vuillemin.com</td>
                                        <td>07.10.2018</td>
                                        <td><span class="td-status n">no</span></td>
                                        <td>
                                            <div class="tsq-checkbox-radio">
                                                <label title="select item">
                                                    <input type="checkbox" name="c1">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Nicolas</td>
                                        <td>Briançon</td>
                                        <td>Mr</td>
                                        <td>Brooklyn cred man</td>
                                        <td>nicolasb@bcm-united.co.uk</td>
                                        <td>07.10.2018</td>
                                        <td><span class="td-status n">no</span></td>
                                        <td>
                                            <div class="tsq-checkbox-radio">
                                                <label title="select item">
                                                    <input type="checkbox" name="c1">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Pascal</td>
                                        <td>Elbé</td>
                                        <td>Dr</td>
                                        <td>Winchester Corp</td>
                                        <td>pascel.elbe@winchester-ltd.com</td>
                                        <td>27.02.2019</td>
                                        <td><span class="td-status y"><a href="#">yes</a></span></td>
                                        <td>
                                            <div class="tsq-checkbox-radio">
                                                <label title="select item">
                                                    <input type="checkbox" name="c1">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Sue</td>
                                        <td>Monaghan</td>
                                        <td>Mrs</td>
                                        <td>Belfast Medicine University</td>
                                        <td>sue.monaghan@bmu.ir</td>
                                        <td>26.08.2018</td>
                                        <td><span class="td-status y"><a href="#">yes</a></span></td>
                                        <td>
                                            <div class="tsq-checkbox-radio">
                                                <label title="select item">
                                                    <input type="checkbox" name="c1">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Jane</td>
                                        <td>Adams</td>
                                        <td>Mrs</td>
                                        <td>UDF Club</td>
                                        <td>jane.adams@udf-uk.com</td>
                                        <td>18.06.2018</td>
                                        <td><span class="td-status n">no <a href="#" class="ts-check">special</a></span></td>
                                        <td>
                                            <div class="tsq-checkbox-radio">
                                                <label title="select item">
                                                    <input type="checkbox" name="c1">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Tomer</td>
                                        <td>Sisley</td>
                                        <td>Mr</td>
                                        <td>Messiah Society</td>
                                        <td>tomer@messiahforlife.com</td>
                                        <td>11.04.2018</td>
                                        <td><span class="td-status n">no</span></td>
                                        <td>
                                            <div class="tsq-checkbox-radio">
                                                <label title="select item">
                                                    <input type="checkbox" name="c1">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Odile</td>
                                        <td>Vuillemin</td>
                                        <td>Dr</td>
                                        <td>Oncology Clinic Ohio</td>
                                        <td>odile@vuillemin.com</td>
                                        <td>07.10.2018</td>
                                        <td><span class="td-status n">no</span></td>
                                        <td>
                                            <div class="tsq-checkbox-radio">
                                                <label title="select item">
                                                    <input type="checkbox" name="c1">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Nicolas</td>
                                        <td>Briançon</td>
                                        <td>Mr</td>
                                        <td>Brooklyn cred man</td>
                                        <td>nicolasb@bcm-united.co.uk</td>
                                        <td>07.10.2018</td>
                                        <td><span class="td-status n">no</span></td>
                                        <td>
                                            <div class="tsq-checkbox-radio">
                                                <label title="select item">
                                                    <input type="checkbox" name="c1">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Pascal</td>
                                        <td>Elbé</td>
                                        <td>Dr</td>
                                        <td>Winchester Corp</td>
                                        <td>pascel.elbe@winchester-ltd.com</td>
                                        <td>27.02.2019</td>
                                        <td><span class="td-status y"><a href="#">yes</a></span></td>
                                        <td>
                                            <div class="tsq-checkbox-radio">
                                                <label title="select item">
                                                    <input type="checkbox" name="c1">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                </table>

                                <div class="nv-space-between-bar">
                                    <div class="nv-inline-items">
                                        <div class="ni-item">
                                            <button type="button" class="nv-btn small filled secondary">Set overriding XLS file</button>
                                        </div><!-- /ni-item -->
                                        <div class="ni-item">
                                            <button type="button" class="nv-btn small filled secondary">Add contacts incrementally from XLS file</button>
                                        </div><!-- /ni-item -->
                                    </div>

                                    <div class="nv-aligned-bar-inline">
                                    <span class="nv-label">
                                        with selected
                                    </span><!-- /.nv-label -->
                                        <select class="contacts-action">
                                            <option>Delete</option>
                                            <option>Move to another dimension</option>
                                            <option>Leave it as is</option>
                                        </select>
                                        <button class="go" type="button"></button>
                                    </div><!-- /.nv-aligned-bar-inline -->
                                </div><!-- /.nv-space-between-bar -->
                            </div><!-- /nv-column-content -->
                        </div><!-- /nv-column -->
                    </div><!-- /nv-row -- -->

                    <!-- /CAMPAIGN MANAGER -->

                </div><!-- /nv-port -->
            </div><!-- /config-layout-lower-fixed -->

            <div class="config-layout-info-bar-fixed">

                <nav class="nv-pagination in-bar">
                    <ul>
                        <li class="first disabled"><a href="#" title="first"></a></li>
                        <li class="previous disabled"><a href="#" title="previous"></a></li>
                        <li><a href="#" class="active">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">4</a></li>
                        <li class="next"><a href="#" title="next"></a></li>
                        <li class="last"><a href="#" title="last"></a></li>
                    </ul>
                </nav>

                <!--<div class="clbf-msg success">&lt;!&ndash; < additional case class: [none], success, warn, error &ndash;&gt;-->
                    <!--<div class="clbf-content">-->
                    <!--<p>Tiramisu icing cookie jelly-o sesame snaps dragée chupa chups marshmallow carrot cake.</p>-->
                    <!--</div>-->
                    <!--<span class="clbf-close" title="Close Message"></span>-->
                <!--</div>&lt;!&ndash; /clbf-msg &ndash;&gt;-->

            </div><!-- /config-layout-lower-fixed -->

        </main><!-- /config-layout-main-frame -->
    </div><!-- /.nv-configurator-layout -->

</div><!-- /nv-page-wrap -->