<div class="nv-section guttered full-screen">
    <div class="nv-port">
        <div class="nv-row gap-30 small-spaced v-stretch flow-center">
            <div class="nv-column desk-8 tab-12">
                <div class="nv-column-content">
                    <div class="nv-error404-port">
                        <figure><img alt="Navus, Page not found" src="../../assets/logo-textless.png"></figure>
                        <div class="nv-error-404-msg">
                            <h1>404</h1>
                            <h3>Oops, page not found.</h3>
                            <p>Sorry for the inconvenience. It looks like you’re trying to access a page that has been deleted.</p>
                            <div class="nv-btn-box gap-14">
                                <a href="" class="nv-btn medium hollow neutral radius-high">Go back</a>
                                <a href="" class="nv-btn medium hollow red radius-high">Home</a>
                            </div><!-- /.nv-btn-box gap-12 -->
                        </div><!-- /.nv-error-404-msg -->
                    </div><!-- /.nv-error404-port -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</div><!-- /.nv-section -->