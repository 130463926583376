import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'nv-accordion',
  templateUrl: './accordion.component.html'
})
export class NavusAccordionComponent implements OnInit {
  @Input() title: string;
  @Input() active: boolean;
  @Input() done: boolean = false;
  @Input() edit: boolean = true;
  @Output() activate = new EventEmitter();
  @Output() next = new EventEmitter();
  touched: boolean;
  show: boolean = false;

  constructor() { }

  ngOnInit() {
    if (!this.active) { this.active = false; }
  }

  _activate() {
    this.activate.emit(this);
  }

  _next() {
    this.touched = true;
    this.next.emit(this);
  }

  _close() {
    this.active = false;
  }
}
