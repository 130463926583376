<section class="nv-section guttered {{ 'section-' + data?.id }}"
         [style.backgroundColor]="configuration?.backgroundColor"
         [style.backgroundImage]="configuration?.backgroundImage ? 'url(' + configuration?.backgroundImage + ')' : ''"
         style="background-size: cover; background-position: center">
    <div class="nv-port">
        <div class="nv-row large-spaced">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <header class="nv-underlined-heading"
                            *ngIf="configuration?.title">
                        <h1><span>{{ configuration?.title }}</span></h1>
                    </header><!-- /.nv-underlined-heading -->
                    <h3 *ngIf="configuration?.subtitle">{{ configuration.subtitle }}</h3>
                    <div class="nv-search-bar-widget">
                        <div class="sbw-bar">
                            <input [placeholder]="configuration?.placeholder || 'Search'"
                                   type="text"
                                   class="sbw-input"
                                   [(ngModel)]="searchTerm"
                                   (keyup)="searchTermChange($event)">
                            <span class="sbw-outline"></span>
                            <button class="sbw-btn" [style.backgroundColor]="configuration?.color"
                                    (click)="search(searchTerm)"></button>
                            <button *ngIf="searchTerm.length > 0" class="sbw-clear" (click)="reset()" translate>Clear</button>
                            <ul class="nv-live-search" *ngIf="searchTerm.length > 0">
                                <li *ngFor="let suggestion of searchSuggestions">
                                    <p (click)="search(suggestion)">
                                        {{ suggestion }}
                                    </p>
                                </li>
                                <li *ngIf="searchSuggestions.length === 0" class="ls-noresults">
                                    <p translate>
                                        No suggestions for this term
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <a class="nv-all-materials" [routerLink]="['/search']" title="View all materials">View all materials</a>
                    </div><!-- /.nv-search-bar-widget -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</section><!-- /.nv-section -->
