export class User {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    country_id: number;
    age: number;
    institution: string;
    token: string;
    resource_roles: any;
    test: number;
    image_url: string;
    country: any;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
