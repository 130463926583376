<div class="nv-config-control-bar">
    <div class="cluf-port">
        <div class="config-title-n-tools">
            <div class="tnt-left">
                <h1 translate>Material</h1>
            </div><!-- /.tnt-left -->
            <div class="tnt-right">
                <div class="nv-btn-box flow-end gap-8">
                    <nv-button (click)="save()" [loading]="savingMaterial" [disabled]="materialForm.invalid" translate>Save</nv-button>
                    &nbsp;
                    <nv-button (click)="close()" translate>Close</nv-button>
                    &nbsp;
                    <nv-button color="primary" (click)="delete()" [hidden]="!materialId" translate>Delete</nv-button>
                </div><!-- /.nv-btn-box flow-end gap-6 -->
            </div><!-- /.tnt-right -->
        </div><!-- /.config-title-n-tools -->
        <div class="nv-port spaced-bottom">
            <div class="nv-line-tabs guttered">
                <nav class="nv-tabs-nav vertical-break-tab">
                    <ul>
                        <li>
                            <a class="active">Materials</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'ematerials', 'consent-settings']">Consent Settings</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>
<div class="nv-config-content-port">
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24 small-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form [formGroup]="materialForm" class="nv-row config-plates clean gap-24 small-spaced-top spaced-bottom">
                            <div class="nv-column desk-6">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Basic Information</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="title"
                                                        label="Title"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="type"
                                                        label="Type"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
