<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>DASHBOARD</h1>
        </div><!-- /.tnt-left -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav vertical-break-tab">
                <ul>
                    <li>
                        <a class="active">
                            Totals
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'dashboard', 'live']">
                            Live
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'dashboard', 'on-demand']">
                            On-demand
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'dashboard', 'on-site']">
                            On-site
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'dashboard', 'sponsor']">
                            Sponsor
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>

<div class="nv-config-content-port">
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24 small-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h4 *ngIf="lastRecordedDateTime" class="nv-chart-time-badge"><span>Time of
                            synchronization: <strong>{{ lastRecordedDateTime | nvDate:'MMM dd, HH:mm'  }}
                                GMT</strong></span></h4>

                        <div class="nv-row config-plates clean gap-24 propagate">
                            <div class="nv-column desk-4">
                                <div class="nv-column-content">
                                    <nv-datepicker
                                            [(ngModel)]="dateFrom"
                                            type="calendar"
                                            label="From"
                                            required
                                    ></nv-datepicker>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-4">
                                <div class="nv-column-content">
                                    <nv-datepicker
                                            [(ngModel)]="dateTo"
                                            type="calendar"
                                            label="To"
                                            required
                                    ></nv-datepicker>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-4">
                                <div class="nv-column-content">
                                    <nv-button size="medium" (click)="applyDateRange()">
                                        Apply Range
                                    </nv-button>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                        </div>

                        <div class="nv-row config-plates clean gap-50 propagate">
                            <div class="nv-global-loading on"
                                 [class.on]="loadingOverallNumbers || loadingDetailedNumbers">
                                <div class="nv-spinner"></div>
                            </div><!-- /.nv-global-loading -->

                            <ng-container *ngIf="!loadingOverallNumbers && overallNumbers">
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content nv-dashboard-chart-no-pointer">
                                        <h3 class="light">Conference in numbers</h3>
                                        <div class="nv-chart-holder conference-in-numbers">
                                            <ngx-charts-number-card
                                                    [scheme]="chartColorScheme"
                                                    [results]="conferenceInNumbersChartData"
                                                    [cardColor]="chartCardColor">
                                            </ngx-charts-number-card>
                                        </div><!-- /.nv-chart-holder conference-in-numbers -->
                                    </div><!-- nv-column-content-->
                                </div><!-- nv-column -->
                            </ng-container>

                            <ng-container *ngIf="!loadingOverallNumbers && overallNumbers">
                                <div class="nv-column desk-12 tab-12">
                                    <div class="nv-column-content nv-dashboard-chart-no-pointer">
                                        <h3 class="light">Materials in numbers</h3>
                                        <ngx-charts-advanced-pie-chart
                                                [scheme]="chartColorScheme"
                                                [results]="materialsInNumbersChartData">
                                        </ngx-charts-advanced-pie-chart>
                                    </div><!-- nv-column-content-->
                                </div><!-- nv-column -->
                            </ng-container>

                            <ng-container *ngIf="!loadingDetailedNumbers && detailedNumbers">
                                <div class="nv-column desk-12 tab-12">
                                    <div class="nv-column-content nv-dashboard-chart-no-pointer">
                                        <h3 class="light">Participation measured in time spent</h3>
                                        <ngx-charts-number-card
                                                [valueFormatting]="formatNumber"
                                                [scheme]="chartColorScheme"
                                                [results]="timeSpentCardChartData"
                                                [cardColor]="chartCardColor">
                                        </ngx-charts-number-card>
                                    </div><!-- nv-column-content-->
                                </div><!-- nv-column -->
                                <div class="nv-column desk-12 tab-12">
                                    <div class="nv-column-content nv-dashboard-chart-no-pointer">
                                        <h3 class="light">Time spent on materials</h3>
                                        <ngx-charts-advanced-pie-chart
                                                [animations]="false"
                                                [valueFormatting]="formatNumber"
                                                [scheme]="chartColorScheme"
                                                [results]="timeSpentPieChartData">
                                            <ng-template #tooltipTemplate let-model="model">
                                                <div>{{ transformToTime(model.value) }}</div>
                                                <div>{{ model.name }}</div>
                                            </ng-template>
                                        </ngx-charts-advanced-pie-chart>
                                    </div><!-- nv-column-content-->
                                </div><!-- nv-column -->
                            </ng-container>


                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-40 propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <ng-container
                                                        *ngIf="!loadingDetailedNumbers && participationInNumbersChartData?.length">
                                                    <h3 class="light">Participation measured in numbers</h3>

                                                    <div class="nv-chart-type-350">
                                                        <ngx-charts-bar-horizontal
                                                                [barPadding]="participationInNumbersChartData?.length > 10? 20 : 30"
                                                                [scheme]="chartColorScheme"
                                                                [results]="participationInNumbersChartData"
                                                                [xAxis]="true"
                                                                [yAxis]="true"
                                                                [legend]="false"
                                                                [showXAxisLabel]="true"
                                                                [showYAxisLabel]="true"
                                                                [yAxisLabel]="'Attendance type'"
                                                                [xAxisLabel]="'Number of attendees'"
                                                                [activeEntries]="participationInNumbersActiveEntries"
                                                                (activate)="participationInNumbersLabelActivate($event)"
                                                                (deactivate)="participationInNumbersLabelDeactivate($event)">
                                                        </ngx-charts-bar-horizontal>
                                                        <!--                                                            [view]="participationInNumbersChartData?.length > 10? null : [participationInNumbersChartData.length * 70 + 300, 400]"-->
                                                    </div><!-- /.nv-chart-type-350 -->


                                                    <div class="nv-divider desk-medium lap-small"></div>

                                                    <nv-custom-dashboard-chart-legend
                                                            [title]="'Participation in numbers'"
                                                            [legendItems]="participationInNumbersLegend"
                                                            [activeEntries]="participationInNumbersActiveEntries"
                                                            [chartData]="participationInNumbersChartData"
                                                            (activateLabel)="participationInNumbersLabelActivate($event)"
                                                            (deactivateLabel)="participationInNumbersLabelDeactivate($event)">
                                                    </nv-custom-dashboard-chart-legend>
                                                </ng-container>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                    </div><!-- /nv-row -->

                                    <div class="nv-row config-plates clean gap-40 propagate">
                                        <div class="nv-column desk-8 lap-12">
                                            <div class="nv-column-content">
                                                <ng-container
                                                        *ngIf="!loadingWatchingSessions && sessionTimeSpentChartData?.length">
                                                    <h3 class="light">Participation measured in time per session</h3>

                                                    <div class="nv-chart-type-350">
                                                        <ngx-charts-bar-vertical-stacked
                                                                [barPadding]="sessionTimeSpentChartData?.length > 10? 10 : 30"
                                                                [xAxis]="true"
                                                                [yAxis]="true"
                                                                [legend]="false"
                                                                [showXAxisLabel]="true"
                                                                [showYAxisLabel]="true"
                                                                [xAxisLabel]="'Session'"
                                                                [yAxisLabel]="'Total time spent (seconds)'"
                                                                [scheme]="chartColorScheme"
                                                                [results]="sessionTimeSpentChartData"
                                                                (select)="onSelectSessionTimeSpent($event)">
                                                            <ng-template #tooltipTemplate let-model="model">
                                                                <div>
                                                                    {{ model.name }}
                                                                </div>
                                                                <div>
                                                                    {{ model.value | timeFormat }}
                                                                </div>
                                                            </ng-template>
                                                        </ngx-charts-bar-vertical-stacked>
                                                        <!--                                                            [view]="sessionTimeSpentChartData?.length > 10? null : [sessionTimeSpentChartData.length * 70 + 100, 400]"-->
                                                    </div><!-- /.nv-chart-type-350 -->

                                                    <div class="nv-divider desk-medium lap-small"></div>

                                                    <nv-custom-dashboard-chart-legend class="nv-chart-w-dscr"
                                                                                      [title]="selectedSessionTimeSpent? ('Total time spent on this session: '+ (selectedSessionTimeSpent.time_spent | timeFormat)) : 'Metrics'"
                                                                                      [legendItems]="sessionTimeSpentLegend"
                                                                                      [description]="'This chart shows the time spent watching the content live on the virtual stage and on-demand.'"
                                                    ></nv-custom-dashboard-chart-legend>
                                                </ng-container>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->


                                        <div class="nv-column desk-4 lap-12">
                                            <div class="nv-column-content nv-dashboard-chart-no-pointer nv-dashboard-chart-no-hover">
                                                <ng-container *ngIf="presentationTimeSpentChartData?.length">
                                                    <h3 class="light">Total time spent on this
                                                        session: {{selectedSessionTimeSpent?.name}}</h3>

                                                    <div class="nv-chart-type-350">
                                                        <ngx-charts-bar-vertical-stacked
                                                                [view]="presentationTimeSpentChartData?.length > 10? null : [presentationTimeSpentChartData.length * 70 + 100, 350]"
                                                                [barPadding]="presentationTimeSpentChartData?.length > 10? 10 : 30"
                                                                [xAxis]="true"
                                                                [yAxis]="true"
                                                                [legend]="false"
                                                                [showXAxisLabel]="true"
                                                                [showYAxisLabel]="true"
                                                                [xAxisLabel]="'Session'"
                                                                [yAxisLabel]="'Total time spent (seconds)'"
                                                                [scheme]="chartColorScheme"
                                                                [results]="presentationTimeSpentChartData">
                                                            <ng-template #tooltipTemplate let-model="model">
                                                                <div>
                                                                    {{ model.name }}
                                                                </div>
                                                                <div>
                                                                    {{ model.value | timeFormat }}
                                                                </div>
                                                            </ng-template>
                                                        </ngx-charts-bar-vertical-stacked>
                                                        <!--                                            [view]="presentationTimeSpentChartData?.length > 10? null : [presentationTimeSpentChartData.length * 70 + 100, 400]"-->
                                                    </div><!-- /.nv-chart-type-350 -->

                                                    <div class="nv-divider desk-medium lap-small"></div>

                                                    <nv-custom-dashboard-chart-legend
                                                            [title]="'Metrics'"
                                                            [legendItems]="presentationTimeSpentLegend"
                                                    ></nv-custom-dashboard-chart-legend>
                                                </ng-container>
                                                <div class="nv-clickable-chart-notice">
                                                    <p>Click on a session bar in the chart to view a detailed
                                                        break-down</p>
                                                </div><!-- /.nv-clickable-chart-notice -->
                                            </div><!-- nv-column-content-->
                                        </div><!-- nv-column -->

                                    </div><!-- /nv-row -->
                                </div><!-- nv-column-content-->
                            </div><!-- nv-column -->


                            <ng-container *ngIf="!loadingWatchingSessions && sessionParticipantsChartData?.length">
                                <div class="nv-column desk-8 lap-12">
                                    <div class="nv-column-content">
                                        <h3 class="light">Participants measured in numbers per session</h3>

                                        <div class="nv-chart-type-350">
                                            <ngx-charts-bar-vertical-stacked
                                                    [barPadding]="sessionParticipantsChartData?.length > 10? 10 : 30"
                                                    [xAxis]="true"
                                                    [yAxis]="true"
                                                    [legend]="false"
                                                    [showXAxisLabel]="true"
                                                    [showYAxisLabel]="true"
                                                    [xAxisLabel]="'Session'"
                                                    [yAxisLabel]="'Total participants'"
                                                    [scheme]="chartColorScheme"
                                                    [results]="sessionParticipantsChartData"
                                                    (select)="onSelectSessionParticipants($event)">
                                            </ngx-charts-bar-vertical-stacked>
                                            <!--                                            [view]="sessionParticipantsChartData?.length > 10? null : [sessionParticipantsChartData.length * 70 + 100, 400]"-->
                                        </div><!-- /.nv-chart-type-350 -->

                                        <nv-custom-dashboard-chart-legend
                                                [title]="'Metrics'"
                                                [legendItems]="sessionParticipantsLegend"
                                        ></nv-custom-dashboard-chart-legend>
                                    </div><!-- nv-column-content-->
                                </div><!-- nv-column -->
                            </ng-container>

                            <div class="nv-column desk-4 lap-12">
                                <div class="nv-column-content nv-dashboard-chart-no-pointer nv-dashboard-chart-no-hover nv-chart-w-dscr">
                                    <ng-container *ngIf="presentationParticipantsChartData?.length">
                                        <h3 class="light">Total Participants per
                                            session: {{selectedSessionParticipants?.name}}</h3>

                                        <div class="nv-chart-type-350">
                                            <ngx-charts-bar-vertical-stacked
                                                    [view]="presentationParticipantsChartData?.length > 10? null : [presentationParticipantsChartData.length * 70 + 100, 350]"
                                                    [barPadding]="presentationParticipantsChartData?.length > 10? 10 : 30"
                                                    [xAxis]="true"
                                                    [yAxis]="true"
                                                    [legend]="false"
                                                    [showXAxisLabel]="true"
                                                    [showYAxisLabel]="true"
                                                    [xAxisLabel]="'Session'"
                                                    [yAxisLabel]="'Total participants'"
                                                    [scheme]="chartColorScheme"
                                                    [results]="presentationParticipantsChartData">
                                            </ngx-charts-bar-vertical-stacked>
                                            <!--                                            [view]="presentationParticipantsChartData?.length > 10? null : [presentationParticipantsChartData.length * 70 + 100, 400]"-->
                                        </div><!-- /.nv-chart-type-350 -->

                                        <nv-custom-dashboard-chart-legend
                                                [title]="'Metrics'"
                                                [legendItems]="presentationParticipantsLegend"
                                                [description]="selectedSessionParticipants.name + ': ' + selectedSessionParticipants.total_participants + ' total participants ('+selectedSessionParticipants.total_participants_live + ' Live, ' + selectedSessionParticipants.total_participants_on_demand+ ' On-demand)'"
                                        ></nv-custom-dashboard-chart-legend>
                                    </ng-container>
                                    <div class="nv-clickable-chart-notice">
                                        <p>Click on a session bar in the chart to view a detailed break-down</p>
                                    </div><!-- /.nv-clickable-chart-notice -->
                                </div><!-- nv-column-content-->
                            </div><!-- nv-column -->
                        </div><!-- nv-row -->

                        <div class="nv-row config-plates clean gap-24 propagate">
                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <h3 class="light">Download XLS Reports</h3>

                                    <div class="nv-btn-box gap-10">
                                        <nv-button size="medium" [disabled]="downloadingWatchingSessions"
                                                   (click)="downloadWatchingSessions()">
                                            Watching sessions
                                        </nv-button>
                                        <nv-button size="medium" [disabled]="downloadingWatchingSessionsDetailed"
                                                   (click)="downloadWatchingSessionsDetailed()">
                                            Watching sessions - Detailed
                                        </nv-button>
                                        <nv-button size="medium" [disabled]="downloadingAttendeesWatchingSessionsLive"
                                                   (click)="downloadAttendeesWatchingSessionsLive()">
                                            All attendees watching live
                                        </nv-button>
                                    </div>
                                </div><!-- /nv-column-content -->
                            </div><!-- /nv-column -->
                        </div><!-- /nv-row -->

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
