import { Component } from '@angular/core';

@Component({
    selector: 'app-material-bar-page',
    templateUrl: './material-bar.component.html'
})
export class MaterialBarComponent {

    constructor() { }

}
