import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Company } from '@navus/core/classes/company';
import { CompanyService } from '@navus/core/services/company.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { Country } from '@navus/core/classes/country';
import { CountryService } from '@navus/core/services/country.service';
import { SponsorGroup } from '@navus/core/classes/sponsor-group';
import { SponsorGroupService } from '@navus/core/services/sponsor-group.service';
import { AuthService } from '@navus/core/services/auth.service';
import { WebsiteService } from '@navus/core/services/website.service';

@Component({
  selector: 'main [nv-company-details-page]',
  templateUrl: './company-details-page.component.html',
  styles: []
})
export class CompanyDetailsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  companyId: number;
  company: Company;
  companyForm: FormGroup;
  invitationForm: FormGroup;
  allCountries: Country[] = [];
  filteredCountries: Country[] = [];
  companyGroup: SponsorGroup[] = [];

  invitations: any[] = [];
  invitationDomain: string = '';

  loadingCompany: boolean = true;
  savingCompany: boolean = false;
  unsavedChanges: boolean = false;
  invitingSponsors: boolean = false;

  subscriptions: Subscription[] = [];

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private companyService: CompanyService,
    private toastService: ToastService,
    private modalService: ModalService,
    private countryService: CountryService,
    private sponsorGroupService: SponsorGroupService,
    private websiteService: WebsiteService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getCountries();

    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.companyId = data.companyId;
        this.getCompany();
        this.getSponsorGroup();
        this.getInvitations();
        this.getWebsite();
      }
    );
    this.subscriptions.push(subscription);

    const changeSubscription = this.companyForm.valueChanges.subscribe(
      () => {
        this.unsavedChanges = true;
      }
    );
    this.subscriptions.push(changeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.companyForm = this.formBuilder.group({
      sponsor_group_id: [null],
      name: ['', Validators.required],
      email: [''],
      country_id: [null],
      state: [''],
      city: [''],
      address: [''],
      is_exhibitor: [false],
      description: [''],
      logo_url: [''],
      website: [''],
      zip: [''],
      floor: [''],
      phone: [''],
      fax: [''],
      booth_number: [null],
      latitude: [null],
      longitude: [null],
      additional_materials: this.formBuilder.array([]),
      availability: [''],
      allow_video_chat: [false]
    });
    this.invitationForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.unsavedChanges = false;
  }

  getCompany() {
    if (this.companyId) {
      this.loadingCompany = true;
      this.companyService.getConferenceSponsor(this.conferenceId, this.companyId)
        .subscribe(
          (response) => {
            let company = response.data;

            if (!company.additional_materials) { company.additional_materials = []; }
            
            this.companyForm.patchValue(company);

            const materials = this.companyForm.controls.additional_materials as FormArray;
            if (company.additional_materials) {
              for (const material of company.additional_materials) {
                materials.push(this.formBuilder.group({
                  name: [material.name],
                  type: [material.type],
                  url: [material.url]
                }));
              }
            }

            this.loadingCompany = false;
            setTimeout(() => { this.unsavedChanges = false; }, 500);
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingCompany = false;
          }
        );
    } else {
      this.loadingCompany = false;
    }
  }

  getSponsorGroup() {
    this.sponsorGroupService.getConferenceSponsorGroups(this.conferenceId)
      .subscribe(
        (response) => {
          this.companyGroup = response.data;
        },
        (error) => { }
      );
  }

  getCountries() {
    this.countryService.getCountries()
      .subscribe(
        (response) => {
          this.allCountries = response.data;
          this.filteredCountries = response.data;
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }

  getWebsite() {
    this.websiteService
      .getConferenceWebsite(this.organizationId, this.conferenceId)
      .subscribe(
        (response) => {
          const website = response.data;
          this.invitationDomain = `https://${website.subdomain}.${website.domain}`;
        }, 
        () => { }
      )
  }

  filterCountries(searchTerm: string) {
    if (searchTerm.length > 2) {
      this.filteredCountries = [];
      for (const country of this.allCountries) {
        if (country.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          this.filteredCountries.push(country);
        }
      }
    } else {
      this.filteredCountries = this.allCountries;
    }
  }

  addMaterial() {
    const materials = this.companyForm.controls.additional_materials as FormArray;
    materials.push(this.formBuilder.group({
      name: [''],
      type: ['document'],
      url: ['']
    }));
  }

  removeMaterial(materialIndex) {
    const materials = this.companyForm.controls.additional_materials as FormArray;
    materials.removeAt(materialIndex);
  }

  save() {
    if (this.companyForm.invalid) { return; }

    this.savingCompany = true;
    if (this.companyId) {
      this.companyService.updateConferenceSponsor(this.conferenceId, this.companyId, this.companyForm.value)
        .subscribe(
          (response) => {
            this.company = response['data'];
            this.toastService.success('Company successfully saved.', {});
            this.unsavedChanges = false;
            this.savingCompany = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingCompany = false;
          }
        );
    } else {
      this.companyService.createConferenceSponsor(this.conferenceId, this.companyForm.value)
        .subscribe(
          (response) => {
            this.company = response['data'];
            this.toastService.success('Company successfully created.', {});
            this.router.navigate([this.company.id], { relativeTo: this.route });
            this.savingCompany = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingCompany = false;
          }
        );
    }
  }

  close() {
    if (this.unsavedChanges) {
      this.modalService.defaultModal({
        title: 'Unsaved changes',
        body: 'Are you sure you want to leave the page without saving changes?',
        size: 'small',
        buttons: [
          {
            text: 'Stay',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Leave',
            color: 'primary',
            handler: () => {
              this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'sponsors', 'companies']);
            }
          }
        ]
      });
    } else {
      this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'sponsors', 'companies']);
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Company Deletion',
      body: 'Are you sure you want to delete this company?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.companyService.deleteConferenceSponsor(this.conferenceId, this.companyId)
              .subscribe(
                () => {
                  this.close();
                },
                (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

  trackBy(index: number) {
    return index;
  }

  getInvitations() {
    this.authService
      .getConferenceInvitations('sponsor', this.companyId, this.conferenceId)
      .subscribe(
        (response: { data: any[], meta: any }) => {
          this.invitations = response.data;
        },
        () => {}
      ); 
  }

  cancelInvitation(invitationId: string) {
    this.authService
      .cancelConferenceInvitations(this.conferenceId, invitationId)
      .subscribe(
        () => {
          this.modalService.defaultModal({
            body: 'Invitation canceled.',
            buttons: [{ text: 'OK', role: 'cancel' }]
          });
          this.getInvitations();
        },
        () => {}
      ); 
  }

  sendInvitation() {
    const invitation: any = {
      email: this.invitationForm.get('email').value,
      role: 'admin',
      resource_id: this.companyId,
      resource_type: 'sponsor',
      url: `${this.invitationDomain}/sponsor/${this.companyId}`
    };

    this.invitingSponsors = true;
    this.authService
      .createConferenceInvitation(this.conferenceId, { invitations: [invitation] })
      .subscribe(
        (response: { data: any[], meta: any }) => {
          this.invitationForm.reset();
          this.invitingSponsors = false;

          this.modalService.defaultModal({
            body: 'Invitation sent.',
            buttons: [{ text: 'OK', role: 'cancel' }]
          });
          this.getInvitations();
        },
        () => {
          this.invitingSponsors = false;
        }
      );
  }

}
