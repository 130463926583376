import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { WebsiteService } from '@navus/core/services/website.service';
import { Website } from '@navus/core/classes/website';

@Component
({
  selector: 'nv-live-steaming-page',
  templateUrl: './live-steaming-page.component.html'
})
export class LiveStreamingPageComponent implements OnInit, OnDestroy {
  conferenceId: number;

  subscriptions: Subscription[] = [];

  liveSuiteSettings: any = {
    configuration: {}
  };
  bannerSettings: any = {
    configuration: {
      position: 'Live-stage'
    }
  };
  bannerSettingsAlt: any = {
    configuration: {
      position: 'SearchAlt'
    }
  };
  programSettings: any = {
    configuration: {
      hideFinished: true
    }
  };

  constructor(
    private websiteService: WebsiteService,
  ) { }

  ngOnInit() {
    console.log(this.programSettings);
    const websiteSubscription = this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          this.conferenceId = website.active_conference_id;

          this.liveSuiteSettings.configuration.conferenceId = this.conferenceId;
          this.programSettings.configuration.conferenceId = this.conferenceId;
          this.bannerSettings.configuration.conference_id = this.conferenceId;
          this.bannerSettingsAlt.configuration.conference_id = this.conferenceId;

        }
      );
    this.subscriptions.push(websiteSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

}
