export const environment = {
  production: false,
  api_url: 'https://staging.e-materials.com/api/',
  file_root: 'https://service.e-materials.com/',
  fasung_url: 'https://fasung.navus-hub.com',
  meeting_url: 'https://conferencing.navus.io/',
  hmr: true,
  versionCheckURL: null,
  muxEnvKey: 'sr0mn382n4o3u94j4abvmlobp',
  trackingEnabled: false,
  trackingLog: true,
  firebaseConfig: {
    apiKey: "AIzaSyCQtPx7f2Onh0vsOhosyuNqurBPbSzvhXQ",
    authDomain: "thematic-land-829.firebaseapp.com",
    projectId: "thematic-land-829",
    storageBucket: "thematic-land-829.appspot.com",
    messagingSenderId: "1069668890652",
    appId: "1:1069668890652:web:9379edb35b531d3ee0c7d9",
    measurementId: "G-QG99VZLEBT"
  },
  
  // Staging
  mix_pusher_app_key:"531a844d-f1b7-45a3-8f34-346c0c2e053f",
  mix_pusher_host:"navus-0468e803-daa0-45a8-be57-bd5ee10724c3.navus-workers.workers.dev",
  mix_pusher_port:443
};

/*
    apiKey: "AIzaSyDbivkOkullwQlWhEyQ33bmyonbCtRQyuQ",
    authDomain: "chatdemo-52d71.firebaseapp.com",
    databaseURL: "https://chatdemo-52d71.firebaseio.com",
    projectId: "chatdemo-52d71",
    storageBucket: "chatdemo-52d71.appspot.com",
    messagingSenderId: "1004388996566",
    appId: "1:1004388996566:web:6e149c65ca43ddadc58077"
*/