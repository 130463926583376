import { Directive, Optional, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { FormControlName, NgModel } from '@angular/forms';

import { Subscription } from 'rxjs';

@Directive({
    selector: '[nvInputDirective]'
})
export class InputDirective implements OnInit, OnDestroy {

    valueSub: Subscription;

    constructor(
        private element: ElementRef,
        @Optional() private formControlName: FormControlName,
        @Optional() private model: NgModel
    ) {

    }

    ngOnInit() {
        if (this.formControlName) {
            this.valueSub = this.formControlName.valueChanges.subscribe(value => {
                this.element.nativeElement.classList.toggle('nv-touched', value);
            });

            if (this.formControlName.value) {
                this.element.nativeElement.classList.toggle('nv-touched');
            }
        }

        if (this.model) {
            this.valueSub = this.model.control.valueChanges.subscribe(value => {
                this.element.nativeElement.classList.toggle('nv-touched', value);
            });

            if (this.model.model) {
                this.element.nativeElement.classList.toggle('nv-touched');
            }
        }
    }

    ngOnDestroy() {
        if (this.valueSub) {
            this.valueSub.unsubscribe();
        }
    }

}
