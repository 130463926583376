import { Component, OnInit } from '@angular/core';

import { UserService } from './shared';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { VersionCheckService } from './navus-core/services/version-check.service';
import { environment } from '../environments/environment';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private userService: UserService,
    private versionCheckService: VersionCheckService
  ) {
    // Google analytics
    if (window.location.host.indexOf('localhost') === -1) {
      let code = 'UA-130036896-1';

      if (window.location.host.indexOf('ucn.navus.io') !== -1) {
        code = 'UA-175349327-1';
      }
      if (window.location.host.indexOf('flow-through-salmon.navus.io') !== -1) {
        code = 'UA-175349327-1';
      }
      if (window.location.host.indexOf('efrcancer.org') !== -1) {
        code = 'G-K9Q541ETSV';
      }

      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${code}`;
      document.head.prepend(script);

      router.events
        .pipe(filter(e => e instanceof NavigationEnd))
        .subscribe((e: NavigationEnd) => {
            gtag('config', code, { 'page_path': e.urlAfterRedirects });
        });
    }
  }

  ngOnInit() {
    this.userService.populate();
    if (environment.versionCheckURL) {
      this.versionCheckService.initVersionCheck(environment.versionCheckURL);
    }
  }
}
