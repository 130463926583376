import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChartsModule } from 'ng2-charts';

import { AuthGuard } from './guards/auth.guard';
import { ConfigRoutes } from './config.routes';

import { SharedModule } from '../shared';
import { NavusUiModule } from '@navus/ui/navus-ui.module';
import { NavusCoreModule } from '@navus/core/navus-core.module';
import { WebsiteModule } from '../website/website.module';
import { ProgramModule } from './modules/program/program.module';
import { RegistrationModule } from './modules/registration/registration.module';
import { SponsorsModule } from './modules/sponsors/sponsors.module';
import { LeadlinkModule } from './modules/leadlink/leadlink.module';
import { VotingModule } from './modules/voting/voting.module';
import { ContactModule } from './modules/contact/contact.module';
import { AiModule } from './modules/ai/ai.module';
import { AdModule } from './modules/ad/ad.module';
import { EmaterialsModule } from './modules/ematerials/ematerials.module';

import { ConfigComponent } from './config.component';
import { OrganizationNavigationComponent } from './components/organization-navigation/organization-navigation.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { OrganizationListPageComponent } from './pages/organization-list/organization-list-page.component';
import { OrganizationDashboardPageComponent } from './pages/organization-dashboard/organization-dashboard-page.component';
import { OrganizationDetailsPageComponent } from './pages/organization-details/organization-details-page.component';
import { OrganizationSetupPageComponent } from './pages/organization-setup/organization-setup-page.component';
import { ConferenceDetailsPageComponent } from './pages/conference-details/conference-details-page.component';
import { ConferenceListPageComponent } from './pages/conference-list/conference-list-page.component';
import { AuthorListPageComponent } from './pages/author-list/author-list-page.component';
import { AuthorDetailsPageComponent } from './pages/author-details/author-details-page.component';
import { FileDashboardComponent } from './pages/file-dashboard/file-dashboard.component';
import { ArticleListPageComponent } from './pages/article-list/article-list-page.component';
import { ArticleDetailsPageComponent } from './pages/article-details/article-details-page.component';
import { OrganizationLandingPageComponent } from './pages/organization-landing/organization-landing-page.component';
import { ConferenceLandingPageComponent } from './pages/conference-landing/conference-landing-page.component';
import { OrganizationBillingPageComponent } from './pages/organization-billing/organization-billing-page.component';
import { PlaceholderPageComponent } from './pages/placeholder/placeholder-page.component';
import { UserListPageComponent } from './pages/user-list/user-list-page.component';
import { ConferenceWebsitePageComponent } from './pages/conference-website/conference-website-page.component';
import { ConferenceApplicationPageComponent } from './pages/conference-application/conference-application-page.component';

import { SelectPackageModalComponent } from './modals/select-package/select-package-modal.component';
import { CardDetailsModalComponent } from './modals/card-details/card-details-modal.component';
import { CommunicationModule } from './modules/communication/communication.module';
import { InvitationModalComponent } from './modals/invitation/invitation-modal.component';
import { PlaceholderPreviewModalComponent } from './modals/placeholder-preview/placeholder-preview-modal.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { ConfigService } from './services/config.service';
import { WelcomeModalComponent } from './modals/welcome/welcome-modal.component';
import { LivestreamingModule } from './modules/livestreaming/livestreaming.module';
import { ViewVideoModalComponent } from './modals/view-video/view-video-modal.component';
import { ProgramModalComponent } from './modals/program/program-modal.component';
import { AuthComponent } from './pages/auth/auth.component';
import { TranslateModule } from '@ngx-translate/core';
import { TicketTypeCodeModalComponent } from './modules/registration/modals/ticket-type-code/ticket-type-code-modal.component';
import { PublicGroupModalComponent } from './modules/networking/modals/ticket-type-code/public-group-modal.component';
import { NetworkingModule } from './modules/networking/networking.module';
import { MeetingRoomsModule } from './modules/meeting-rooms/meeting-rooms.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ConferenceDashboardModule } from './pages/conference-dashboard/conference-dashboard.module';
import { BadgesModule } from './modules/badges/badges.module';

@NgModule({
  imports: [
    RouterModule.forChild(ConfigRoutes),
    NavusCoreModule.forRoot(),
    TranslateModule.forChild(),
    NavusUiModule,
    DragDropModule,
    SharedModule,
    WebsiteModule,
    ChartsModule,
    ProgramModule,
    RegistrationModule,
    ConferenceDashboardModule,
    SponsorsModule,
    LeadlinkModule,
    VotingModule,
    ContactModule,
    AiModule,
    CommunicationModule,
    AdModule,
    EmaterialsModule,
    LivestreamingModule,
    CreditCardDirectivesModule,
    NetworkingModule,
    MeetingRoomsModule,
    NgxChartsModule,
    BadgesModule,
  ],
  declarations: [
    OrganizationNavigationComponent,
    BreadcrumbsComponent,
    ConfigComponent,
    OrganizationLandingPageComponent,
    OrganizationDashboardPageComponent,
    OrganizationDetailsPageComponent,
    OrganizationListPageComponent,
    OrganizationSetupPageComponent,
    OrganizationBillingPageComponent,
    ConferenceLandingPageComponent,
    ConferenceDetailsPageComponent,
    ConferenceListPageComponent,
    AuthorListPageComponent,
    AuthorDetailsPageComponent,
    FileDashboardComponent,
    ArticleListPageComponent,
    ArticleDetailsPageComponent,
    CardDetailsModalComponent,
    PlaceholderPageComponent,
    UserListPageComponent,
    SelectPackageModalComponent,
    InvitationModalComponent,
    ConferenceWebsitePageComponent,
    ConferenceApplicationPageComponent,
    PlaceholderPreviewModalComponent,
    WelcomeModalComponent,
    ViewVideoModalComponent,
    ProgramModalComponent,
    TicketTypeCodeModalComponent,
    AuthComponent,
    PublicGroupModalComponent,
  ],
  exports: [],
  entryComponents: [
    CardDetailsModalComponent,
    SelectPackageModalComponent,
    InvitationModalComponent,
    PlaceholderPreviewModalComponent,
    WelcomeModalComponent,
    ViewVideoModalComponent,
    ProgramModalComponent,
    TicketTypeCodeModalComponent
  ],
  providers: [
    ConfigService,
    AuthGuard
  ]
})
export class ConfigModule {
}
