import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Block } from '@navus/core/classes/block';
import { Presentation } from '@navus/core/classes/presentation';
import { Program } from '@navus/core/classes/program';
import { User } from '@navus/core/classes/user';
import { Website } from '@navus/core/classes/website';
import { ChatService } from '@navus/core/services/chat.service';
import { ConferenceService } from '@navus/core/services/conference.service';
import { DelegateService } from '@navus/core/services/delegate.service';
import { LocationService } from '@navus/core/services/location.service';
import { TrackingService } from '@navus/core/services/tracking.service';
import { UserService } from '@navus/core/services/user.service';
import { VotingService } from '@navus/core/services/voting.service';
import { WebsiteService } from '@navus/core/services/website.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { Subscription, timer } from 'rxjs';
import { AuthorModalComponent } from '../../modals/author/author-modal.component';
import { ConferenceAccessModalComponent } from '../../modals/conference-access/conference-access-modal.component';
import { StreamChangeComponent } from '../../modals/stream-change/stream-change.component';
import { LSBlockDetailsComponent } from './modals/block-details.component';
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)


@Component
  ({
    selector: 'nv-live-stage',
    templateUrl: './live-stage.component.html'
  })
export class LiveStageComponent implements OnInit, OnDestroy {
  @ViewChild('programScrollbar') public scrollbar: ElementRef
  conferenceId: number;
  echo: Echo;
  pusher: Pusher;

  subscriptions: Subscription[] = [];

  currentUser: User;
  currentDelegate: any;
  currentVotingQuestion: any;
  currentBlock: Block;
  currentBlockPresentations: Presentation[] = [];

  program: Program;
  programDays: string[] = [];

  upcomingBlocks: any[] = []
  locations: any[] = []
  streams: any[] = [];
  currentStream: any;
  currentTimestamp: number;
  currentDay: string;
  livestreamState: string = 'current';

  controlAccess: boolean = true;
  hasAccess: boolean = false;
  videoLoading: boolean = true;

  qaSettings: { enabled: boolean, public_tab: boolean, moderation: boolean } = null;
  chatSettings = null;
  votingSettings = null;
  usingTickets: boolean = false;
  usingDelegateRegistration: boolean = false;

  timezone: any;
  timezoneOffset: number;

  dayStartTime: Date;
  dayEndTime: Date;

  // View settings
  widthHour = 360;
  timelineWidth = 0;
  timelineHours: string[] = [];

  // View controls
  selectedChatTab = 'true';
  selectedSideMenu = 'streaming';
  showProgram: boolean = false;
  showPanel: boolean = true;
  selectedView = 'stream'; //'program';

  sessionSelectionVisible = false;
  availableBlocks: { id: number, name: string }[] = [];
  qnaBlockIds: number[] = [];

  presentationChannel = null;

  constructor(
    private modalService: ModalService,
    private websiteService: WebsiteService,
    private conferenceService: ConferenceService,
    private locationService: LocationService,
    private userService: UserService,
    private delegateService: DelegateService,
    private votingService: VotingService,
    private router: Router,
    public route: ActivatedRoute,
    private zone: NgZone,
    private trackingService: TrackingService,
    private chatService: ChatService,
  ) { }

  ngOnInit() {
    const queryParamsSubscription = this.route
      .queryParams
      .subscribe(
        (queryParams) => {
          if (queryParams.location) {
            if (this.locations.length === 0) {
              setTimeout(() => {
                this.setActiveStreamByLocation(+queryParams.location);
              }, 1000);
            } else {
              this.setActiveStreamByLocation(+queryParams.location);
            }
          }
        }
      );
    this.subscriptions.push(queryParamsSubscription);

    const websiteSubscription = this.websiteService
      .currentWebsite
      .subscribe(
        (website: Website) => {
          this.conferenceId = website.active_conference_id;

          const conferenceSettings = website.conferences.find(c => c.id === website.active_conference_id).settings;

          this.chatSettings = conferenceSettings?.chat_settings;
          this.qaSettings = conferenceSettings?.qa_settings;
          this.votingSettings = conferenceSettings?.voting_settings;
          this.usingDelegateRegistration = conferenceSettings?.delegate_registration?.enabled;
          this.usingTickets = conferenceSettings?.delegate_registration?.ticketing;
          this.timezone = conferenceSettings?.timezone;

          let conferenceOffset = this.timezone?.offset || 0;
          if (this.timezone?.isdst) conferenceOffset -= 1;
          const clientOffset = - new Date().getTimezoneOffset() / 60;
          this.timezoneOffset = clientOffset - conferenceOffset;

          if (this.qaSettings?.enabled) {
            this.selectedSideMenu = 'qna';
            this.selectedChatTab = this.qaSettings?.public_tab ? 'true' : 'mine';
          }
          
          this.initWebsocket();
          this.getProgram()
          .then(() => {
              this.calculateCurrentTimestamp();
              this.populateSessionsListOnQa();
              this.getCurrentlyStreaming();
            });
        }
      );
    this.subscriptions.push(websiteSubscription);

    const userSubscription = this.userService.currentUser
      .subscribe(
        (user: User) => {
          this.currentUser = user;
          this.currentDelegate = user ? user.delegates.find(d => d.conference_id === this.conferenceId) : null;

          this.myTicketRights();
        }
      );
    this.subscriptions.push(userSubscription);

    const conferenceSubscription = this.conferenceService
      .getConference(this.conferenceId)
      .subscribe(
        (result) => {
          this.qaSettings = result?.data?.settings?.qa_settings;
        }
      );
    this.subscriptions.push(conferenceSubscription);

    const timerSubscription = timer(0, 60*1000)
      .subscribe(
        () => {
          this.timerTick();
        }
      )
    this.subscriptions.push(timerSubscription);

    const chatRoomSubscription = this.chatService
      .getAllPublicGroups(this.conferenceId)
      .subscribe(
        (rooms) => {
          this.qnaBlockIds = rooms
            .filter((r) => r.name.startsWith('block_'))
            .map((r) => +r.name.split('_')[1]);
          this.populateSessionsListOnQa();
        }
      );
    this.subscriptions.push(chatRoomSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.echo.disconnect();
    this.trackingService.stopTrackingAction(this.conferenceId, 'WATCHING');
  }

  initWebsocket() {
    this.websiteService
      .initWebsocket()
      .then(
        (echo) => {
          console.log(echo);
          this.echo = echo;
          this.listenForLocationChange();
        }
      )
  }

  listenForLocationChange() {
    this.echo
      .channel(`location.current_conference_presentation.${this.conferenceId}`)
      .listen(`.location.changed_location`,
        (response: any) => {
          this.changeOnLocation(response);
        }
      );
  }

  listenToVotingChange() {
    if (this.presentationChannel) {
      this.echo.leave(this.presentationChannel);
    }

    this.presentationChannel = `presentation.${this.currentStream.currentPresentation.id}`;
    this.echo
      .channel(this.presentationChannel)
      .listen(`.voting.started`, (votingQuestion) => {
        this.zone.run(() => {
          this.currentVotingQuestion = votingQuestion;
          this.currentVotingQuestion.active = true;
          this.currentVotingQuestion.finished = false;

          this.selectedSideMenu = 'voting';
        });
      })
      .listen('.voting.finished', (votingQuestion) => {
        this.zone.run(() => {
          this.currentVotingQuestion = votingQuestion;
          this.currentVotingQuestion.active = false;
          this.currentVotingQuestion.finished = true;

          // Check
          if (localStorage.getItem('votingID_' + this.currentVotingQuestion.id)) {
            this.currentVotingQuestion.user_answer = localStorage.getItem('votingID_' + this.currentVotingQuestion.id);
          }
        });
      });
  }

  /**
   * Function called every 5 minutes
   *
   * @returns void
   */
  timerTick(): void {
    this.getProgram();
  }

  getPresentationVotings() {
    if (!this.currentStream?.currentPresentation) { return; }

    this.votingService
      .getConferencePresentationVotings(this.conferenceId, this.currentStream.currentPresentation.id)
      .subscribe(
        (response) => {
          const votingQuestions = response.data;
          let activeVotingQuestion = null;
          let finishedVotingQuestion = null;

          for (const votingQuestion of votingQuestions) {
            if (votingQuestion.active) { activeVotingQuestion = votingQuestion; }
            if (votingQuestion.finished) { finishedVotingQuestion = votingQuestion; }
          }

          if (activeVotingQuestion) {
            if (this.currentVotingQuestion === activeVotingQuestion) { return; }
            this.currentVotingQuestion = activeVotingQuestion;
          } else {
            this.currentVotingQuestion = finishedVotingQuestion;
          }
        },
        () => { }
      );

    this.listenToVotingChange();
  }

  changeOnLocation(response: any) {
    const location = response.data;

    // TODO Notify if current is nothing and something appears
    if (location.id === this.currentStream.location_id) {
      if (this.currentStream.status !== 'offair' && location.status === 'offair') {
        if (this.selectedSideMenu === 'voting') {
          this.selectSideMenu('streaming');
        }
      }

      this.currentStream.currentPresentation = location.streaming_presentations[0];
      this.currentStream.url = location.stage_url;
      this.currentStream.offAir = location.activeLivestreamOffair;
      this.currentStream.status = location.status;
      this.currentBlock = this.program?.blocks?.find(b => b.id === this.currentStream?.currentPresentation?.block_id) || null;
      this.currentBlockPresentations = this.program?.presentations?.filter(p => p.block_id === this.currentBlock?.id);

      this.myTicketRights();
      this.getPresentationVotings();
    }
  }

  getCurrentlyStreaming(locationId: number = 0) {
    const params = {
      active_streaming: 1,
      include: 'streaming_presentations',
      with_pagination: 0
    }

    this.locationService
      .getConferenceLocations(this.conferenceId, params)
      .subscribe(
        (response) => {
          const locations = response.data;
          this.streams = locations.sort((a, b) => a.order - b.order).map(
            location => {
              return {
                location_id: location.id,
                name: location.name,
                url: location.stage_url,
                order: location.order,
                presentations: location.streaming_presentations,
                currentPresentation: location.streaming_presentations[0] || null,
                offAir: location.activeLivestreamOffair,
                status: location.status,
              }
            }
          )

          if (locationId > 0) {
            const stream = this.streams.find(s => s.location_id === locationId);
            this.setActiveStream(stream);
          }

          // TODO If there is no locations
          if (!this.currentStream) {
            const today = (new Date()).toISOString().slice(0, 10);
            let stream =
              this.streams.find(stream =>
                stream.currentPresentation &&
                stream.currentPresentation.starts_at.slice(0, 10) === today
              ) ||
              this.streams.find(stream => stream.currentPresentation) ||
              this.streams[0];
            this.setActiveStream(stream);
          }
        },
        () => { }
      );
      
  }

  getCurrentDateTime() {
    let d = new Date();
    let utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    return new Date(utc + (3600000*this.timezone?.offset));
  }

  getCurrentDate() {
    let nd = this.getCurrentDateTime();
    return nd.getFullYear() + '-' + ('0' + (nd.getMonth() + 1)).slice(-2) + '-' + ('0' + nd.getDate()).slice(-2);
  }

  async getProgram(): Promise<void> {
    return await this.conferenceService
      .getProgram(this.conferenceId)
      .toPromise()
      .then(
        (response: { data: Program; }) => {
          this.program = response.data;
          this.locations = [];
          this.upcomingBlocks = [];
          const locations: any[] = this.program.locations;
          const programDays = [];

          const today = this.getCurrentDate();

          this.dayStartTime = new Date(today + ' 23:59');
          this.dayEndTime = new Date(today + ' 00:00');

          // Calculate program start and end
          locations.forEach(location => {
            this.program.timeslots.filter(t => t.location_id === location.id).forEach(timeslot => {
              this.program.timeslot_distributions.filter(td => td.timeslot_id === timeslot.id).forEach(timeslotDistribution => {
                if (timeslotDistribution.block_id) {
                  const block = this.program.blocks.find(b => b.id === timeslotDistribution.block_id);
                  if (block) {
                    const blockDay = timeslot.starts_at.toString().slice(0, 10);
                    if (block.type !== 'Poster' &&
                      block.streamable &&
                      blockDay === today) {
                      const startTime = new Date(timeslotDistribution.starts_at);
                      const endTime = new Date(timeslotDistribution.ends_at);

                      if (startTime < this.dayStartTime) { this.dayStartTime = startTime; }
                      if (endTime > this.dayEndTime) { this.dayEndTime = endTime; }
                    }
                  }
                }
              });
            });
          });

          this.dayStartTime.setMinutes(0, 0, 0);

          this.timelineHours = [];
          for (let i = this.dayStartTime.getHours(); i <= this.dayEndTime.getHours(); i++) {
            this.timelineHours.push((i < 10 ? '0' + i : i) + ':00');
          }

          // Show blocks
          locations.forEach(location => {
            let show = false;
            location.timeslots = this.program.timeslots.filter(t => t.location_id === location.id);
            location.timeslots.forEach(timeslot => {
              timeslot.timeslotDistributions = this.program.timeslot_distributions.filter(td => td.timeslot_id === timeslot.id);
              timeslot.timeslotDistributions.forEach(timeslotDistribution => {

                // Block timeslots
                if (timeslotDistribution.block_id) {
                  const block: any = this.program.blocks.find(b => b.id === timeslotDistribution.block_id);

                  if (block) {
                    const blockDay = timeslot.starts_at.toString().slice(0, 10);
                    if (!programDays.includes(blockDay)) {
                      programDays.push(blockDay);
                    }

                    if (block.type !== 'Poster' &&
                      block.streamable &&
                      blockDay === today) {
                      show = true;

                      const chairpersonIds = this.program.participants_per_block
                        .filter(p => p.role === 'chair' && p.block_id === block.id)
                        .map(p => p.speaker_id);
                      block.chairpersons = this.program.speakers.filter(s => chairpersonIds.includes(s.id));

                      timeslotDistribution.block = block;
                      const startTime = new Date(timeslotDistribution.starts_at);
                      const endTime = new Date(timeslotDistribution.ends_at);

                      const startHours = (startTime.getTime() - this.dayStartTime.getTime()) / 1000 / 60 / 60;
                      const diffHours = (endTime.getTime() - startTime.getTime()) / 1000 / 60 / 60;

                      timeslotDistribution.style = {
                        position: 'absolute',
                        left: (this.widthHour * startHours) + 'px',
                        width: (this.widthHour * diffHours) + 'px'
                      };

                      timeslotDistribution.live = startTime < new Date() && new Date() < endTime;
                    }
                  }
                  block.timeslot = this.program.timeslot_distributions.find(timeslot => timeslot.block_id === block.id);
                  block.location_id = this.program.timeslots.find(timeslot => timeslot.id === block.timeslot.timeslot_id)?.location_id;

                  const blockStartsAt = new Date(block.timeslot.starts_at);
                  // const currentBlockStartsAt = new Date(this.currentBlock?.timeslot?.starts_at);
                  const currentTime = new Date();

                  if (
                    blockStartsAt.getDate() === currentTime.getDate() &&
                    blockStartsAt.getMonth() === currentTime.getMonth() &&
                    blockStartsAt.getFullYear() === currentTime.getFullYear() &&
                    blockStartsAt.getTime() > currentTime.getTime()
                  ) {
                    this.upcomingBlocks.push(block);
                  }
                }
                this.upcomingBlocks = this.upcomingBlocks
                  .sort((b1, b2) => new Date(b1.timeslot.starts_at).getTime() - new Date(b2.timeslot.starts_at).getTime())

                // Presentation timeslots
                if (timeslotDistribution.presentation_id) {
                  timeslot.presentation = this.program.presentations.find(p => p.id === timeslotDistribution.presentation_id);
                }
              });
            });

            if (show) {
              this.locations.push(location);
            }
          });

          this.timelineWidth = (this.dayEndTime.getTime() - this.dayStartTime.getTime()) / 1000 / 60 / 60 * this.widthHour;

          this.programDays = programDays.sort((a, b) => a > b ? 1 : -1);
          if (today < programDays[0]) { this.livestreamState = 'future'; }
          if (today > programDays[programDays.length - 1]) { this.livestreamState = 'past'; }
          if (locations.filter(l => l.timeslots.filter(t => t.date === today).length > 0).length === 0) { this.livestreamState = 'noProgramToday'; }
          
          this.livestreamState === 'current';
          this.locations.sort((a, b) => a.order - b.order);
          
          this.calculateCurrentTimestamp();
        },
        (error) => {
          console.log(error);
        }
      );


  }

  setActiveStream(stream: any): void {
    if (!stream) {
      this.currentStream = null;

      this.myTicketRights();
      this.populateSessionsListOnQa();
      setTimeout(() => { this.videoLoading = false; }, 1000);
    } else {
      this.currentStream = stream;
      this.currentBlock = this.program?.blocks?.find(b => b.id === this.currentStream?.currentPresentation?.block_id) || null;
      this.currentBlockPresentations = this.program?.presentations?.filter(p => p.block_id === this.currentBlock?.id);

      this.myTicketRights();
      this.populateSessionsListOnQa();
      this.getPresentationVotings();
      setTimeout(() => { this.videoLoading = false; }, 1000);
    }
  }

  setActiveStreamByLocation(locationId: number): void {
    this.getCurrentlyStreaming(locationId);
    // this.setActiveStream(stream);
    // const stream = this.streams.find(s => s.location_id === locationId);

  }

  selectSideMenu(sideMenu: string): void {
    this.selectedSideMenu = sideMenu;
  }

  toggleProgram(): void {
    this.showProgram = !this.showProgram;
    if (this.showProgram && this.scrollbar) {
      this.scrollbar.nativeElement.scrollTo({
        top: 0,
        left: this.currentTimestamp,
        behavior: 'smooth'
      });
    }
  }

  togglePanel(): void {
    this.showPanel = !this.showPanel;
  }

  isBlockAvailableWithMyTicket() {
    return this.currentDelegate ?
      (this.currentStream?.currentPresentation.accessible_by_tickets.length > 0
        ? this.currentStream?.currentPresentation.accessible_by_tickets.indexOf(this.currentDelegate.conference_ticket_type_id) > -1
        : true
      ) : false;
  }

  async myTicketRights(): Promise<void> {
    // Well, why not...
    if (this.currentStream?.status !== 'offair' && this.selectedSideMenu === 'voting') {
      this.selectedSideMenu = 'streaming'; 
    }

    if (!this.currentStream?.currentPresentation) {
      this.trackPresentationView();
      return;
    }

    this.trackAttendance(this.currentStream?.currentPresentation.id);

    if (!this.controlAccess) {
      this.hasAccess = true;
      this.trackPresentationView();
      return;
    }

    const minAccessRight = this.currentStream?.currentPresentation?.min_access_right;
    const isDelegate = !!this.currentDelegate;
    const isMember = !!this.currentUser?.member;
    const isBlockAvailableForTicket = this.isBlockAvailableWithMyTicket();

    switch (minAccessRight) {
      case 'guest':
        this.hasAccess = true;
        break;
      case 'delegate':
        if (isDelegate || isMember) {
          this.hasAccess = this.usingTickets ? isBlockAvailableForTicket : true;
        } else {
          this.hasAccess = false;
        }
        break;
      case 'member':
        if (isMember) {
          this.hasAccess = this.usingTickets ? isBlockAvailableForTicket : true;
        } else {
          this.hasAccess = false;
        }
        break;
    }

    this.trackPresentationView();
  }

  trackAttendance(presentationId: number) {
    if (!this.currentDelegate || !presentationId) {
      return;
    }
    this.delegateService
      .scout({ presentation_id: presentationId, party_id: this.currentDelegate.id })
      .subscribe();
  }

  requestAccess() {
    if (!this.currentUser) {
      this.promptLogin();
    } else if (!this.currentDelegate) {
      this.promptCode('delegate');
    } else {
      if (!this.currentUser.member && this.currentStream?.currentPresentation?.min_access_right === 'member') {
        this.promptCode('member');
      } else if (!this.isBlockAvailableWithMyTicket()) {
        this.promptCode('ticket');
      } else {
        this.promptRejection();
      }
    }
  }

  promptRejection() {
    this.modalService.defaultModal({
      title: 'No access',
      body: "Based on your current access rights, you don't have permission to view this content. Please contact support if you think that this information is not correct."
    });
  }

  promptLogin() {
    this.modalService.defaultModal({
      title: 'Not logged in',
      body: 'You need to be logged in to continue',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Log in',
          handler: () => {
            this.router.navigate(['/profile/login'], { queryParams: { returnUrl: this.router.url } });
          }
        },
      ]
    });
  }

  promptCode(option: string) {
    const modalRefAccess = this.modalService.open(ConferenceAccessModalComponent);
    modalRefAccess.componentInstance.conferenceId = this.conferenceId;

    //simple delegate case
    let text = `To view this session you need to be a delegate. If you are already a delegate, please enter the access/PIN code or click on the "Register" button bellow.`;
    let buttonLabel = `Register`;

    let exitStatement = false;

    switch (option) {
      case 'delegate':
        if (!this.usingDelegateRegistration) {
          modalRefAccess.componentInstance.hideButton = true;
          text = `To watch this content you need to be a delegate. Registration is closed, but if you are already a delegate or a member, please enter the access/PIN code.`;
        }
        break;
      case 'member':
        modalRefAccess.componentInstance.hideButton = true;
        text = `To watch this content you need to be a member. Please enter the access/PIN code.`;
        break;
      case 'ticket':
        if (this.usingDelegateRegistration && this.usingTickets && !this.currentDelegate.conference_ticket_type_id) {
          text = `To watch this content you need to have a valid ticket. Please enter the access/PIN code if you are already a delegate with a valid ticket or click on the "Get ticket" button bellow.`;
          buttonLabel = `Get ticket`;
        } else if (this.usingDelegateRegistration && this.usingTickets && this.currentDelegate.conference_ticket_type_id) {
          text = `To watch this content you need to upgrade your ticket. Please enter the access/PIN code or click on the "Upgrade ticket" button bellow.`;
          buttonLabel = `Upgrade ticket`;
          modalRefAccess.componentInstance.hideCode = true;
        } else {
          text = `To watch this content you need to have a valid ticket. Registration is closed, but if you are already a delegate with a valid ticket, please enter the access/PIN code or contact support.`;
          modalRefAccess.componentInstance.hideButton = true;
        }
        break;
    }

    if (exitStatement) {
      this.promptRejection();
      return;
    }

    modalRefAccess.componentInstance.text = text;
    modalRefAccess.componentInstance.buttonLabel = buttonLabel;
    modalRefAccess.result.then(
      (result) => {
        this.userService.getCurrentUser()
          .subscribe(
            (user: User) => {
              this.setActiveStream(this.currentStream);
            },
            () => { }
          );
      },
      () => { }
    );
  }

  showAuthor(author) {
    const modalRef = this.modalService.open(AuthorModalComponent);
    modalRef.componentInstance.author = author;
  }

  showBlock(blockId) {
    const modalRef = this.modalService.open(LSBlockDetailsComponent);
    modalRef.componentInstance.conferenceId = this.conferenceId;
    modalRef.componentInstance.blockId = blockId;
  }

  showFullProgram() {
    window.open('/program', '_blank');
  }

  changeStream() {
    const activeStreams = [];
    this.locations.forEach((element) => {
      const stream = this.streams.find(s => s.location_id === element.id);
      activeStreams.push(stream);
    })
    const modalRef = this.modalService.open(StreamChangeComponent);
    modalRef.componentInstance.streams = activeStreams;
    modalRef.componentInstance.currentStreamId = this.currentStream.location_id;
    modalRef.result
      .then((result) => {
        if (result) {
          this.getCurrentlyStreaming();
          this.setActiveStreamByLocation(result.stream.location_id);
          this.getProgram();
        }
      })
      .catch(() => { })
  }

  setCurrentBlock(blockId: number): void {
    this.currentBlock = this.program?.blocks?.find(b => b.id === blockId) || null;
    this.currentBlockPresentations = this.program?.presentations?.filter(p => p.block_id === this.currentBlock?.id);
    this.sessionSelectionVisible = false;
  }

  calculateCurrentTimestamp() {
    if (this.dayStartTime) {
      const conferenceTimezone = this.timezone.utc[0];
      const conferenceDayStartTime = dayjs(this.dayStartTime).tz(conferenceTimezone, true);
      const localTimezone = dayjs.tz.guess();
      const localDayStartTime = conferenceDayStartTime.tz(localTimezone);

      // (current time - start of the program) * width of an hour
      this.currentTimestamp = ((dayjs().hour() + dayjs().minute() / 60) - (localDayStartTime.hour() + localDayStartTime.minute() / 60)) * this.widthHour;
    }
  }

  private populateSessionsListOnQa(): void {
    if (!this.program) { return; }
    
    this.availableBlocks = this.program.blocks
      .filter(b => this.qnaBlockIds.includes(b.id))
      .filter(b => b.location_id === this.currentStream?.location_id)
      .sort((b1, b2) => new Date(b1.timeslot.starts_at).getTime() - new Date(b2.timeslot.starts_at).getTime())
      .map(b => ({ id: b.id, name: b.name }));
  }

  // Tracking
  trackPresentationView() {
    if (!this.currentUser) { return; }

    const presentationId = this.currentStream?.currentPresentation?.id;
    const offAirId = this.currentStream?.offAir?.id;
    const isOffAir = this.currentStream?.status === 'offair';

    if (isOffAir) {
      this.trackingService.startTracking(this.conferenceId, 'offair', offAirId, 'WATCHING', { user_id: this.currentUser.id });
    } else {
      if (presentationId && this.hasAccess) {
          this.trackingService.startTracking(this.conferenceId, 'presentation', presentationId, 'WATCHING', { user_id: this.currentUser.id });
      } else {
        this.trackingService.stopTrackingAction(this.conferenceId, 'WATCHING');
      }
    }
  }
}
