<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>DASHBOARD</h1>
        </div><!-- /.tnt-left -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav  vertical-break-tab">
                <ul>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'dashboard', 'totals']">
                            Totals
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'dashboard', 'live']">
                            Live
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'dashboard', 'on-demand']">
                            On-demand
                        </a>
                    </li>
                    <li>
                        <a class="active">
                            On-site
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'dashboard', 'sponsor']">
                            Sponsor
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>

<div class="nv-config-content-port">
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24 small-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h4 *ngIf="lastRecordedDateTime" class="nv-chart-time-badge"><span>Time of
                            synchronization: <strong>{{ lastRecordedDateTime | nvDate:'MMM dd, HH:mm'  }} GMT</strong></span></h4>
                        <div class="nv-row config-plates clean gap-24 propagate">

                            <div class="nv-global-loading on"
                                 [class.on]="loadingOnSiteSessions">
                                <div class="nv-spinner"></div>
                            </div><!-- /.nv-global-loading -->

                            <ng-container *ngIf="!loadingOnSiteSessions && sessionParticipantsChartData?.length">
                                <div class="nv-column desk-12 lap-12">
                                    <div class="nv-column-content nv-dashboard-chart-no-pointer">
                                        <h3 class="light">On-site participants per session</h3>

                                        <div class="nv-chart-type-350">
                                            <ngx-charts-bar-vertical
                                                    [view]="sessionParticipantsChartData?.length > 10? null : [sessionParticipantsChartData.length * 70 + 100, 350]"
                                                    [barPadding]="sessionParticipantsChartData?.length > 10? 10 : 30"
                                                    [scheme]="chartColorScheme"
                                                    [results]="sessionParticipantsChartData"
                                                    [xAxis]="true"
                                                    [yAxis]="true"
                                                    [legend]="false"
                                                    [showXAxisLabel]="true"
                                                    [showYAxisLabel]="true"
                                                    [xAxisLabel]="'Session'"
                                                    [yAxisLabel]="'Number of participants'"
                                                    [activeEntries]="sessionParticipantsActiveEntries"
                                                    (activate)="sessionParticipantsLegendLabelActivate($event)"
                                                    (deactivate)="sessionParticipantsLegendLabelDeactivate($event)">
                                            </ngx-charts-bar-vertical>
                                        </div><!-- /.nv-chart-type-350 -->

                                        <div class="nv-divider desk-small"></div>

                                        <nv-custom-dashboard-chart-legend
                                                [title]="'Sessions'"
                                                [legendItems]="sessionParticipantsLegend"
                                                [activeEntries]="sessionParticipantsActiveEntries"
                                                [chartData]="sessionParticipantsChartData"
                                                (activateLabel)="sessionParticipantsLegendLabelActivate($event)"
                                                (deactivateLabel)="sessionParticipantsLegendLabelDeactivate($event)">
                                        </nv-custom-dashboard-chart-legend>
                                    </div><!-- nv-column-content-->
                                </div><!-- nv-column -->

                            </ng-container>

                        </div><!-- nv-row -->

                        <div class="nv-row config-plates clean gap-24 propagate">
                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <h3 class="light">Download XLS Reports</h3>

                                    <div class="nv-btn-box gap-10">
                                        <nv-button size="medium" [disabled]="downloadingSessionAttendance"
                                                   (click)="downloadSessionAttendance()">
                                            Session attendance
                                        </nv-button>
                                        <nv-button size="medium" [disabled]="downloadingAllAttendance"
                                                   (click)="downloadAllAttendance()">
                                            All attendance
                                        </nv-button>
                                    </div>
                                </div><!-- /nv-column-content -->
                            </div><!-- /nv-column -->
                        </div><!-- /nv-row -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
