<div class="nv-modal small">
    <div class="nv-modal-header">
        <h4 class="nv-modal-title" translate>Question</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body">
        <form [formGroup]="messageForm" class="nv-row gap-24">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <nv-input
                            #questionInput
                            type="textarea"
                            formControlName="content"
                            label="Message"
                    ></nv-input>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            
            <div class="nv-divider desk-small"></div>

            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <nv-button (click)="send()" [loading]="sendingMessage" [disabled]="messageForm.invalid" translate>Send</nv-button>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </form>
    </div>
</div>
