<div class="nv-modal small">
    <div class="nv-modal-header">
        <h4 class="nv-modal-title" translate>Biography</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body" *ngIf="delegate">
        <div class="nv-global-loading on" [class.on]="loadingDelegateInfo">
            <div class="nv-spinner"></div>
        </div><!-- /.nv-global-loading -->
        <div class="nv-speaker-teaser">
            <div class="st-svatar">
                <a title="{{ delegate?.name || delegate.first_name + ' ' + delegate.last_name }}">
                    <span nvBackgroundImage [image]="delegate.image_url_thumb || delegate.image_url"></span>
                </a>
            </div><!-- /.st-svatar -->
            <h3>

                <a title="{{ delegate?.name || delegate.first_name + ' ' + delegate.last_name }}">{{(delegate.title && delegate.title !== "") ? delegate.title + " " : ""}}{{ delegate?.name || delegate.first_name + ' ' + delegate.last_name }}</a>
            </h3>
            <p *ngIf="delegate?.contact_info?.institution && delegate?.contact_info?.institution !== ''">{{ delegate?.contact_info?.institution }}</p>
        </div><!-- /.delegate-teaser -->

        <p [innerHTML]="biography"></p>

        <ng-container *ngIf="delegateHasActivatedChat$ | async">
            <nv-button (click)="startChat()">Start chat</nv-button>
        </ng-container>

    </div>
</div>
