<div class="swiper-container" [swiper]="config">
    <div class="swiper-wrapper">
        <ng-content></ng-content>
    </div>

    <div class="swiper-pagination" [hidden]="!config?.pagination"></div>

    <div class="swiper-button-prev" [hidden]="!config?.navigation"></div>
    <div class="swiper-button-next" [hidden]="!config?.navigation"></div>
</div>
