<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Program</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-8">
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port spaced-bottom">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav vertical-break-tab">
                <ul>
                    <li>
                        <a class="active">Program</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program', 'presentations']">Presentations</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program', 'blocks']">Sessions</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program', 'locations']">Locations</a>
                    </li>
<!--                    <li class="nv-tab-aside">-->
                </ul>
            </nav>
        </div>
    </div>
</div>
<div class="nv-config-content-port">
    <section class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-btn-box flow-end gap-8 propagate nv-import-download-template">
                            <nv-button (click)="fileSelect.click()" translate size="small">Import Program</nv-button>
                            <input #fileSelect type="file" ng2FileSelect [uploader]="uploader" [hidden]="true">
                            &nbsp;
                            <nv-button (click)="downloadTemplate()" translate size="small">Download Template</nv-button>
                        </div>
                        <div *ngIf="conferenceId" class="nv-config-program-planner">
                            <nv-program-planner 
                                [organizationId]="organizationId"
                                [conferenceId]="conferenceId"
                                [mode]="'config'"
                            ></nv-program-planner>
                        </div>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->
</div>
