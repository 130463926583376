import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Conference } from "@navus/core/classes/conference";
import { Country } from "@navus/core/classes/country";
import { User } from "@navus/core/classes/user";
import { Website } from "@navus/core/classes/website";
import { ConferenceService } from "@navus/core/services/conference.service";
import { CountryService } from "@navus/core/services/country.service";
import { DelegateService } from "@navus/core/services/delegate.service";
import { UserService } from "@navus/core/services/user.service";
import { ModalService } from "@navus/ui/modal/modal.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'nv-website-registration-delegate',
  templateUrl: './delegate.component.html'
})
export class RegistrationDelegateComponent implements OnInit, OnDestroy {
  website: Website;
  conference: Conference;

  delegateForm: FormGroup;
  allCountries: Country[] = [];
  filteredCountries: Country[] = [];
  delegateFormFields: any[] = [];
  ticketTypeId: string;
  ticketTypeCodeId: string;
  method: string;
  returnUrl: string = null;

  registeringDelegate: boolean = false;

  subscriptions: Subscription[] = [];

  keyEvent(event: KeyboardEvent) {
    if (event.key && event.key.toLowerCase() === 'enter') {
      (document.activeElement as HTMLElement).blur();
      this.registerDelegate();
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private delegateService: DelegateService,
    private countryService: CountryService,
    private conferenceService: ConferenceService,
    private modalService: ModalService,
  ) { }
  
  ngOnInit() {
    this.website = this.route.parent.parent.parent.snapshot.data.website;
    this.conference = this.website?.conferences?.find(c => c.id === this.website.active_conference_id);

    this.initForm();
    this.loadForm();
    this.getCountries();

    const querySubscription = this.route.queryParams
      .subscribe((params) => {
        if (params.ticket) {
          this.ticketTypeId = params.ticket;
        }
        if (params.code) {
          this.ticketTypeCodeId = params.code;
        }
        if (params.method) {
          this.method = params.method;
        }
        if (params.returnUrl) {
          this.returnUrl = params.returnUrl;
        }
      });
    this.subscriptions.push(querySubscription);

    const userSubscription = this.userService.currentUser
      .subscribe((user: User) => {
        if (user) {
          this.delegateForm.get('first_name').setValue(user.first_name);
          this.delegateForm.get('last_name').setValue(user.last_name);
          this.delegateForm.get('email').setValue(user.email);
          this.delegateForm.get('institution').setValue(user.institution);

          setTimeout(() => {
            this.delegateForm.get('country_id').setValue(user.country_id);
          }, 1000);
        }
      });
    this.subscriptions.push(userSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.delegateForm = new FormGroup({
      'first_name': new FormControl('', [
        Validators.required,
        Validators.maxLength(30)
      ]),
      'last_name': new FormControl('', [
        Validators.required,
        Validators.maxLength(30)
      ]),
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,8}$/)
      ]),
      'institution': new FormControl(''),
      'department': new FormControl(''),
      'group': new FormControl(''),
      'poster': new FormControl(''),
      'doctorate': new FormControl(''),
      'year_of_doctorate': new FormControl(''),
      'abstract': new FormControl(''),
      'country_id': new FormControl('', [Validators.required]),
      // 'accept': new FormControl(false, [Validators.requiredTrue]),
      'create_from_user': new FormControl(1)
    });
  }

  getCountries() {
    this.countryService
      .getCountries()
      .subscribe(
      (response: any) => {
          this.allCountries = response.data;
          this.filteredCountries = response.data;
        }
    );
  }

  filterCountries(searchTerm: string) {
    if (searchTerm.length > 2) {
      this.filteredCountries = [];
      for (const country of this.allCountries) {
        if (country.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          this.filteredCountries.push(country);
        }
      }
    } else {
      this.filteredCountries = this.allCountries;
    }
  }

  loadForm() {
    if (this.conference.settings.delegate_registration_fields) {
      this.delegateFormFields = this.conference.settings.delegate_registration_fields;
      for (let field of this.conference.settings.delegate_registration_fields) {
        if (field.type === 'instructions') { continue; }

        if (field.type === 'select') {
          field.formatedOptions = field.options.split('\n').map(option => ({ id: option, name: option }));
        } 

        if (field.mandatory) {
          this.delegateForm.addControl(field.field, new FormControl('', [field.type === 'checkbox' ? Validators.requiredTrue : Validators.required]));
        } else {
          this.delegateForm.addControl(field.field, new FormControl('', []));
        }
      }
    }
  }

  registerDelegate() {
    if (this.delegateForm.valid) {
      this.registeringDelegate = true;
      const params: any = {
        delegates: [
          this.delegateForm.value
        ]
      };
      
      if (this.ticketTypeCodeId) {  params.conference_ticket_code_id = this.ticketTypeCodeId; }
      if (this.method) {  params.method = this.method; }
      if (this.ticketTypeId) {
        this.conferenceService
          .createConferenceTicket(this.conference.id, this.ticketTypeId, params)
          .subscribe(
            (response) => {
              console.log('this.method');
              console.log(this.method);
              
              this.modalService.defaultModal({
                title: this.method=='bank' ? 'Thank you for showing the interest in registering for this event' : 'Thank you for your registration',
                body: 'A confirmation email has been sent to your email address.',
                size: 'small',
                buttons: [
                  {
                    text: 'OK',
                    color: 'primary',
                    role: 'cancel'
                  }
                ]
              });
              
              this.userService.getCurrentUser().subscribe();
              if (this.returnUrl) { 
                this.router.navigateByUrl(this.returnUrl);
              } else {
                this.router.navigate(['/']);
              }
            },
            (error) => {
              this.modalService.error({ errors: error });
              this.registeringDelegate = false;
            }
          );
      } else {
        this.delegateService
          .createConferenceDelegate(this.conference.id, this.delegateForm.value)
          .subscribe(
            (response) => {
              this.modalService.defaultModal({
                title: 'Thanks for your registration',
                body: 'A confirmation email has been sent to your email address.',
                size: 'small',
                buttons: [
                  {
                    text: 'OK',
                    color: 'primary',
                    role: 'cancel'
                  }
                ]
              });
              this.userService.getCurrentUser().subscribe();
              if (this.returnUrl) { 
                this.router.navigateByUrl(this.returnUrl);
              } else {
                this.router.navigate(['/']);
              }
            },
            (error) => {
              this.modalService.error({ errors: error });
              this.registeringDelegate = false;
            }
          );
      }
    }
  }

  skip() {
    if (this.returnUrl) { 
      this.router.navigateByUrl(this.returnUrl);
    } else {
      this.router.navigate(['/']);
    }
  }


}