import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Company } from '@navus/core/classes/company';
import { CompanyService } from '@navus/core/services/company.service';
import { WebsiteService } from '@navus/core/services/website.service';
import { Website } from '@navus/core/classes/website';
import { Conference } from '@navus/core/classes/conference';
import { NavusMeetingComponent } from '@navus/ui/meeting/meeting.component';
import { User } from '@navus/core/classes/user';
import { UserService } from '@navus/core/services/user.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { DelegateService } from '@navus/core/services/delegate.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component
({
  selector: 'nv-single-details-page',
  templateUrl: './sponsor-details-page.component.html'
})
export class SponsorDetailsPageComponent implements OnInit, OnDestroy {
  @ViewChild('meeting') meeting: NavusMeetingComponent;
  conferenceId: number;
  organizationId: number;
  sponsorId: number;
  sponsor: Company;
  additional_materials: any[];
  currentTime: any;
  conference: Conference;
  currentUser: User;
  currentDelegate: any;
  owner: boolean = false;
  videoButtonLabel: string = "Talk now";

  canAccess: boolean = true;

  sponsorForm: FormGroup;

  showEditMode: boolean = false;
  subscriptions: Subscription[] = [];

  constructor(
    private modalService: ModalService,
    private userService: UserService,
    private companyService: CompanyService,
    private websiteService: WebsiteService,
    private delegateService: DelegateService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.organizationId = this.route.snapshot.parent.data.website.organization_id;
    this.initForm();
    const subscription = this.route.params
      .subscribe(
        (data: any) => {
          this.sponsorId = +data.sponsorId;
          this.websiteService.currentWebsite
            .subscribe(
              (website: Website) => {
                this.conferenceId = website.active_conference_id;
                this.conference = website.conferences.find(conference => conference.id === this.conferenceId);
                this.getCompany();
              },
              () => { }
            );
        },
        () => { }
      );
    this.subscriptions.push(subscription);

    const userSubscription = this.userService.currentUser
    .subscribe(
      (user: User) => {
        this.currentUser = user;
        if (user) {
          this.getUserRole();
          this.getCurrentDelegate();
        }

          if (this.sponsorId === 154065 || this.sponsorId === 154066) {
            this.canAccess = false;
            if (user) {
              if (!user.hcp) {
                const modalRef = this.modalService.defaultModal({
                  title: this.sponsorId === 154065 ? 'This page is intended for Healthcare Professionals*' 
                    : 'Are you a healthcare professional?',
                  body: this.sponsorId === 154065 ? 'Are you a healthcare professional*?<br><br><i>*The ABPI Code definition for healthcare professional is members of the medical, dental, pharmacy and nursing professionals and any other persons who in the course of their professional activities may administer, prescribe, purchase, recommend or supply a medicine.</i>'
                    : 'The content of this page is intended for Healthcare Professionals. Unfortunately, you cannot enter this site if you are not a Healthcare Professional',
                  size: 'small',

                  buttons: [
                    {
                      text: 'Yes',
                      color: 'primary',
                      handler: () => {
                        this.canAccess = true;
                        this.userService
                          .updateUser(user.id, { hcp: true })
                          .subscribe(() => {
                            this.userService
                              .getCurrentUser()
                              .subscribe();
                          });
                      }
                    },
                    {
                      text: 'No',
                      color: 'primary',
                      handler: () => {
                        this.router.navigate(['/sponsors']);
                      }
                    }
                  ]
                }); 
                
                modalRef.result.then(
                  () => {},
                  () => {
                    this.router.navigate(['/sponsors']);
                  }
                )
              } else {
                this.canAccess = true;
              }              
            }
          }
      }
    );
    // Allow video chat
    // this.sponsorForm.get('allow_video_chat').valueChanges
    //   .subscribe(
    //     (response) => {
    //       this.sponsor.allow_video_chat = response;
    //     },
    //     () => {}
    //   )

    this.subscriptions.push(userSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.sponsorForm = this.formBuilder.group({
      logo_url: [null],
      name: [null, Validators.required],
      availability: [null],
      description: [null],
      address: [null],
      city: [null],
      state: [null],
      zip: [null],
      phone: [null],
      website: [null],
      email: [null],
      allow_video_chat: [false],
      additional_materials: this.formBuilder.array([])
    })
  }

  getUserRole() {
    for (const resource_role of this.currentUser.resource_roles) {
      if (resource_role.resource_type === 'sponsor' && resource_role.resource_id == this.sponsorId) {
        this.owner = true;
        if (this.owner) {
          this.videoButtonLabel = 'Talk to the attendees';
        }
      }
    }
  }

  getCurrentDelegate() {
    if(this.currentUser && this.currentUser.delegates.length>0) {
        this.currentDelegate = this.currentUser.delegates[0];
        this.trackAttendance('sponsor', this.sponsorId);
    }
    return;
  }

  getAccess(skipCode: boolean = false) {
    if (!this.currentUser) {
      this.modalService.defaultModal({
        title: 'Not logged in',
        body: 'You need to be logged in to continue',
        buttons: [
          {
            text: 'Cancel',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Log in',
            handler: () => {
              this.router.navigate(['/profile/login'], { queryParams: { returnUrl: this.router.url } });
            }
          },
        ]
      });
      return;

    } else if (!this.currentDelegate) {
      if (this.conference.settings.delegate_registration ? this.conference.settings.delegate_registration.enabled : this.conference.settings.ticket?.create_delegate) {
        this.router.navigate(['/delegate-registration']);
      } else {
        this.modalService.defaultModal({
          title: 'No access',
          body: "You are not registered as a delegate for this event. Based on your current access rights, you don't have permission to access this content. Please contact organizer if you think that this information is not correct."
        });
      }
    } else if (this.currentDelegate) {
        this.modalService.defaultModal({
          title: 'No access',
          body: "Based on your current access rights, you don't have permission to view this content. Please contact support if you think that this information is not correct."
        });
    }
  }

  getCompany() {
    this.companyService.getConferenceSponsor(this.conferenceId, this.sponsorId, { include: 'sponsor_group' })
      .subscribe(
        (response) => {                
          this.sponsor = response.data;
          if (!this.sponsor.additional_materials) { this.sponsor.additional_materials = []; }

          this.sponsorForm.patchValue(this.sponsor);

          const materials = this.sponsorForm.controls.additional_materials as FormArray;
          materials.clear();
          if (this.sponsor.additional_materials) {
            for (const material of this.sponsor.additional_materials) {
              materials.push(this.formBuilder.group({
                name: [material.name],
                type: [material.type],
                url: [material.url]
              }));
            }
          }

          if (!this.owner) {
            if (this.sponsor.is_exhibitor) {
              this.videoButtonLabel = 'Talk to the Exhibitor';
            } else {
              this.videoButtonLabel = 'Talk to the Sponsor';
            }            
          }
          this.additional_materials = response.data.additional_materials;
        },
        () => { }
      );
  }

  handleAdditionalMaterial(additionalMaterial: any) {
    const canAccess = this.currentUser && this.currentDelegate;
    const embeddable = additionalMaterial.url.includes('vimeo') || additionalMaterial.url.includes('youtu');
    const leadGeneration = this.conference.settings.sponsors?.lead_generation;

    if (canAccess) {
      if (leadGeneration) {
        const optIns = this.currentDelegate.contact_info.opt_ins;
        if (optIns === null || optIns === undefined) {
          this.modalService.defaultModal({
            body: 'I agree that with any click or material access on this exhibitor page my personal data (name, email address, country, institution) ' +
                  'will be handed over to the exhibiting company. I provide explicit consent that representatives of the exhibiting company can contact ' +
                  'me for a follow up',
            buttons: [
              {
                text: 'Cancel',
                color: 'passive',
                role: 'cancel'
              },
              {
                text: 'I agree',
                handler: () => {
                  const params = { 
                    contact_info: { ...this.currentDelegate.contact_info, opt_ins: [this.sponsorId] } 
                  };
                  this.delegateService
                      .updateConferenceDelegate(this.conferenceId, this.currentDelegate.id, params)
                      .subscribe(
                        (response) => {
                          this.currentDelegate = response.data;
                          if (embeddable) {
                            this.modalService.defaultModal({
                              title: additionalMaterial.name,
                              size: 'large',
                              body: `<oembed url="${additionalMaterial.url}"></oembed>`
                            });
                          } else {
                            window.open(additionalMaterial.url, '_blank');
                          } 
                        },
                        () => {}
                      )
                }
              },
            ]
          });
        } else {
          if (optIns !== false) {
            if (optIns.indexOf(this.sponsorId) === -1) {
              optIns.push(this.sponsorId);
              const params = { 
                contact_info: { ...this.currentDelegate.contact_info, opt_ins: optIns } 
              };
              this.delegateService
                  .updateConferenceDelegate(this.conferenceId, this.currentDelegate.id, params)
                  .subscribe(
                    () => {},
                    () => {}
                  );
            }
          }

          if (embeddable) {
            this.modalService.defaultModal({
              title: additionalMaterial.name,
              size: 'large',
              body: `<oembed url="${additionalMaterial.url}"></oembed>`
            });
          } else {
            window.open(additionalMaterial.url, '_blank');
          }        
        }        
      } else {
        
        if (embeddable) {
          this.modalService.defaultModal({
            title: additionalMaterial.name,
            size: 'large',
            body: `<oembed url="${additionalMaterial.url}"></oembed>`
          });
        } else {
          window.open(additionalMaterial.url, '_blank');
        }  
        this.trackAttendance("sponsor", this.sponsor?.id, additionalMaterial.name);   
      }
    } else {
      this.getAccess();
    }
  }

  trackAttendance(type:any = "sponsor", resource_id: any = null, description: any = ""){
    if(!this.currentDelegate) {
      return;
    }
    this.delegateService
      .scout({ presentation_id: 78416, type: type, party_id: this.currentDelegate.id, "resource_id" : resource_id, "description": description })
      .subscribe();
  }

  //EDIT

  editModeToggle() {
    if(this.showEditMode) { this.getCompany(); }
    this.showEditMode = !this.showEditMode;
  }

  save() {
    if (this.sponsorForm.invalid || !this.owner) { return; }

    this.companyService.updateConferenceSponsor(this.conferenceId, this.sponsor.id, this.sponsorForm.value)
      .subscribe(
        () => {
          this.getCompany();
          this.showEditMode = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }
  
  removeMaterial(materialIndex) {
    const materials = this.sponsorForm.controls.additional_materials as FormArray;
    materials.removeAt(materialIndex);
  }

  addMaterial() {
    const materials = this.sponsorForm.controls.additional_materials as FormArray;
    materials.push(this.formBuilder.group({
      name: [''],
      type: ['document'],
      url: ['']
    }));
  }

  showMeeting = false;
  sponsorMeetingRoom;

  meetingJoined() {
    this.showMeeting = true;
  }

  meetingClosed() {
    this.showMeeting = false;
  }

  meetingRoomChange(meetingRoom) {
    this.sponsorMeetingRoom = meetingRoom;
  }

}