<nav class="config-breadcrambs">
    <ul>
        <li *ngIf="currentOrganization">
            <a [routerLink]="['/o', currentOrganization.id, 'landing']">{{ currentOrganization.name }}</a>
        </li>
        <li *ngIf="currentOrganization && currentConference">
            <a [routerLink]="['/o', currentOrganization.id, 'conference', currentConference.id, 'landing']">{{ currentConference.name }}</a>
        </li>
        <li *ngIf="currentOrganization && currentWebsite">
            <a [routerLink]="['/o', currentOrganization.id, 'website', currentWebsite.id, 'dashboard']">{{ currentWebsite.name }}</a>
        </li>
        <li *ngIf="currentPage">
            <a>{{ currentPage }}</a>
        </li>
    </ul>
</nav>
