<div class="nv-section cayahl-txt guttered">
    <div class="nv-port">
        <div class="nv-row gap-50 v-stretch small-spaced large-spaced-top">
            <div class="nv-column desk-8 phab-12">
                <div class="nv-column-content">
                    <h3>Get your CPE Certificate for the Australian Bookkeepers Summit 2021</h3><h5>for the time period March 3rd to April 30th, 2021</h5>
                    <p>To request your Certificate click on the button.</p>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-4 phab-12">
                <div class="nv-column-content">
                    <div class="nv-row gap-50 v-stretch small-spaced">
                        <div class="nv-column desk-12 phab-12">
                            <div class="nv-column-content">
                                <div>
                                    <div>
                                        <span>
                                            <nv-button (click)="requestCert()" [loading]="checkingPermissions" [disabled]="disableButton" translate>Request CPE certificate</nv-button>
                                        </span>
                                    </div>
            
                                </div><!--/cayahl-authors -->
                            </div>
                        </div>
                    </div>
                    <div class="nv-row gap-50 v-stretch small-spaced" *ngIf="delegateData">
                        <div class="nv-column desk-12 phab-12">
                            <div class="nv-column-content">
                                <div *ngIf="timeSpent===-1">
                                    <!-- <p>Time spent: <b>{{delegateData['contact_info'].timespent}} minute(s)</b></p> -->
                                    <p>In order to qualify any CPE for this event you are required to have completed a minimum of 1 hour engagement with live or on demand content. If you believe that you have achieved more than this minimum please contact the ICB events team to discuss at <a href="mailto:admin@icb.org.au">admin@icb.org.au</a></p>
                                </div><!--/cayahl-authors -->
                                <div *ngIf="timeSpent!==-1">
                                    <p>Congratulations! You have completed <b>{{timeSpent}}</b> hours of engagement with live or on demand content</p>
                                    <p>Download certificate <b><a target="_blank" href="{{delegateData['contact_info'].attendance_certificate_url}}">here</a></b></p>
            
                                </div><!--/cayahl-authors -->
                            </div>
                        </div>
                    </div>
                    
                    
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</div>
