import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { WebsiteService } from '@navus/core/services/website.service';
import { Website } from '@navus/core/classes/website';
import { WebsitePage } from '@navus/core/classes/website-page';
import { Author } from '@navus/core/classes/author';
import { PaginationConfig } from '@navus/ui/pagination/classes/pagination-config';
import { AuthorService } from '@navus/core/services/author.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { AuthorModalComponent } from '../../modals/author/author-modal.component';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component
({
  selector: 'nv-speaker-list-page',
  templateUrl: './speaker-list-page.component.html'
})
export class SpeakerListPageComponent implements OnInit, OnDestroy {
  [x: string]: any;

  conferenceId: number;
  currentPage: WebsitePage = null;
  authors: Author[];
  pagination: any = {
    page: 1,
    perPage: 20
  };

  bannerSettings: any = {
    configuration: {
      position: 'Search'
    }
  };
  authorsLoading: boolean = true;

  formFilter: FormGroup;
  subscriptions: Subscription[] = [];

  constructor(
    private websiteService: WebsiteService,
    private authorService: AuthorService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      search_term: ['']
    });
    const subscription = this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          this.conferenceId = website.active_conference_id;
          this.bannerSettings.configuration.conference_id = this.conferenceId;
          this.getAuthors();
          this.currentPage = website.pages.filter(x => x.type === 'speakers')[0];
        },
        () => { }
      );
    this.subscriptions.push(subscription);

  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getAuthors(append: boolean = false) {
    if (append && this.pagination.total && this.authors.length >= this.pagination.total) { return; }

    this.pagination.page = append ? this.pagination.page + 1 : 1;

    const params: any = {
      page: this.pagination.page,
      per_page: this.pagination.perPage,
      sort_by: 'order',
      sort_direction: 'asc',
      role: '',
      featured: true
    };
    if (this.formFilter.value.search_term !== '') {
      params.search_term = this.formFilter.value.search_term;
    }
    this.authorsLoading = !append;
    this.authorService.getConferenceAuthors(this.conferenceId, params)
      .subscribe(
        (response: { data: Author[], meta: any }) => {
          if (append) {
            this.authors = this.authors.concat(response.data);
          } else {
            this.authors = response.data;
            this.pagination.total = response.meta.pagination.total;
          }
          this.authors = this.authors.sort((a1, a2) => a1.order - a2.order);
          this.authorsLoading = false;
        },
        () => {
          this.authorsLoading = false;
        }
      );
  }

  openModal(author: Author) {
    const modalRef = this.modalService.open(AuthorModalComponent);
    modalRef.componentInstance.author = author;
  }

  public getAuthorImage(author) {
    const image = author.image_url_thumb !== null ? author.image_url_thumb : author.image_url;
    if (image === null) {
      return null;
    } else {
      return this._sanitizer.bypassSecurityTrustStyle(`url(${image})`);
    }
  }

  paginationChange(paginationConfig: PaginationConfig) {
    this.pagination.current_page = paginationConfig.current_page;
    this.getAuthors();
  }

}
