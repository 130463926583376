<section class="nv-section guttered">
    <div class="nv-port">
        <div class="nv-row v-stretch gap-18 large-spaced-top">
            <div class="nv-column desk-12">
                <div class="nv-column-content" style="text-align: center;">
                    <h2 translate>RECOMMENDED FOR YOU</h2>
                </div><!-- /.nv-column-content -->
            </div><!-- /nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row v-stretch gap-30 spaced-top large-spaced-bottom">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <div class="nv-module-presentation-list">
                        <div class="presentation-list max-columns-2">
                            <div class="presentation-column" *ngFor="let presentationColumn of presentationColumns">
                                <div *ngFor="let presentation of presentationColumn" class="presentation">
                                    <div class="speaker-photo">
                                        <img [src]="presentation.speaker_image_thumb || '../../assets/avatar-placeholder.png'" [alt]="presentation.title">
                                    </div>
                                    <div class="details">
                                        <div class="time">
                                            <span>{{presentation.starts_at | nvDate:'EEE'}}</span>
                                            <time datetime="25.05.2018">{{presentation.starts_at | nvDate:'dd.MM.yyyy' }}</time>
                                            <span>{{presentation.starts_at | nvDate:'HH:mm'}} - {{presentation.ends_at | nvDate:'HH:mm'}}</span>
                                        </div>
                                        <h4 class="title" [routerLink]="['/presentation', presentation.id]" style="cursor: pointer">{{presentation.title}}</h4>
                                        <div class="speaker-name">{{presentation.speaker_name}}</div>
                                    </div>
                                </div>
                            </div><!-- /presentation-column -->
                        </div>
                    </div><!-- /.nv-module-presentation-list -->
                </div><!-- /.nv-column-content -->
            </div><!-- /nv-column -->
        </div><!-- /.nv-row -->
        <div *ngIf="presentationColumns.length == 0" translate>
            No recommendations
        </div>
    </div><!-- /.nv-port -->
</section><!-- /.nv-section -->
