import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@navus/core/classes/location';
import { LocationService } from '@navus/core/services/location.service';
import { ModalService } from '@navus/ui/modal/modal.service';

@Component({
  selector: 'main [nv-location-list-page]',
  templateUrl: './location-list-page.component.html'
})
export class LocationListPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;
  organizationId: number;
  conferenceId: number;
  locations: Location[] = [];

  pagination: { page?: number, perPage: number, total?: number } = {
    perPage: 30
  };
  sort: { field: string, direction: string } = {
    field: 'name',
    direction: 'asc'
  };
  selectedIds: number[] = [];

  locationsLoading: boolean = false;
  optionsShown: number = null;

  formFilter: FormGroup;
  subscriptions: Subscription[] = [];


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private locationService: LocationService,
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      search_term: ['']
    });
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getLocations();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getLocations(append: boolean = false) {
    if (append && this.pagination.total && this.locations.length >= this.pagination.total) { return; }

    this.pagination.page = append ? this.pagination.page + 1 : 1;

    const params: any = {
      page: this.pagination.page,
      per_page: this.pagination.perPage,
      sort_by: this.sort.field,
      sort_direction: this.sort.direction,
    };
    if (this.formFilter.value.search_term !== '') {
      params.name = this.formFilter.value.search_term;
    }
    this.locationsLoading = !append;
    this.locationService.getConferenceLocations(this.conferenceId, params)
      .subscribe(
        (response: { data: Location[], meta: any }) => {
          if (append) {
            this.locations = this.locations.concat(response.data);
          } else {
            const port = document.getElementsByClassName('nv-config-content-port');
            if (port && port.length > 0) {
              port[0].scrollTop = 0;
            }
            
            this.locations = response.data;
            this.pagination.total = response.meta.pagination.total;
          }
          this.locationsLoading = false;
        },
        () => {
          this.locationsLoading = false;
        }
      );
  }

  edit(location: Location) {
    this.router.navigate(['..', 'location', location.id], { relativeTo: this.route });
  }

  close() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  add() {
    this.router.navigate(['..', 'location'], { relativeTo: this.route });
  }

  delete(location: Location) {
    this.modalService.defaultModal({
      title: 'Location Deletion',
      body: 'Are you sure you want to delete this location?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.locationService.deleteConferenceLocation(this.conferenceId, location.id)
              .subscribe(
                () => {
                  this.getLocations();
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

  sortLocations(field: string) {
    if (field === this.sort.field) {
        this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
    } else {
        this.sort.field = field;
        this.sort.direction = 'asc';
    }
    this.getLocations();
  }

  toggleSelected(location: Location) {
    const index = this.selectedIds.indexOf(location.id);
    if (index !== -1) {
      this.selectedIds.splice(index, 1);
    } else {
      this.selectedIds.push(location.id);
    }
  }

  selectAll() {
    this.selectedIds = [];
    for (const location of this.locations) {
      this.selectedIds.push(location.id);
    }
  }

  unselectAll() {
    this.selectedIds = [];
  }

  openOptions(location: Location) {
    event.stopPropagation();
    this.optionsShown = location.id;
  }

  closeOptions() {
    event.stopPropagation();
    this.optionsShown = null;
  }

}
