import { Routes } from '@angular/router';
import { OrganizationGuard } from '../../guards/organization.guard';
import { DelegateListPageComponent } from './pages/delegate-list/delegate-list-page.component';
import { DelegateDetailsPageComponent } from './pages/delegate-details/delegate-details-page.component';
import { RegistrationSettingsPageComponent } from './pages/registration-settings/registration-settings-page.component';
import { TicketTypeListPageComponent } from './pages/ticket-type-list/ticket-type-list-page.component';
import { TicketTypeDetailsPageComponent } from './pages/ticket-type-details/ticket-type-details-page.component';
import { CertificateListPageComponent } from './pages/certificate-list/certificate-list-page.component';
import { CertificateDetailsPageComponent } from './pages/certificate-details/certificate-details-page.component';

export const RegistrationRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'delegates'
  },
  {
    path: 'registration-settings',
    component: RegistrationSettingsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Registration Settings'
    }
  },
  {
    path: 'delegates',
    component: DelegateListPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Delegate List'
    }
  },
  {
    path: 'delegate/:delegateId',
    component: DelegateDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Delegate Details'
    }
  },
  {
    path: 'delegate',
    component: DelegateDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Delegate Details'
    }
  },
  {
    path: 'ticket-types',
    component: TicketTypeListPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Ticket Type List'
    }
  },
  {
    path: 'ticket-type/:ticketTypeId',
    component: TicketTypeDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Ticket Type Details'
    }
  },
  {
    path: 'ticket-type',
    component: TicketTypeDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Ticket Type Details'
    }
  },
  {
    path: 'certificates',
    component: CertificateListPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Certificate List'
    }
  },
  {
    path: 'certificate/:certificateId',
    component: CertificateDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Certificate Details'
    }
  },
  {
    path: 'certificate',
    component: CertificateDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Certificate Details'
    }
  },
];
