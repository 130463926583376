import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { AuthorService } from '@navus/core/services/author.service';
import { Author } from '@navus/core/classes/author';
import { ModalService } from '@navus/ui/modal/modal.service';
import { PaginationConfig } from '@navus/ui/pagination/classes/pagination-config';
import { AuthorListSectionConfig } from './author-list-section-config';
import { AuthorModalComponent } from '../../modals/author/author-modal.component';
import { BaseSectionComponent } from '../_base/base-section.component';
import { WebsiteService } from '@navus/core/services/website.service';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { WebsiteControlService } from '../../services/website-control.service';
import { Subscription } from 'rxjs';
import { Website } from '@navus/core/classes/website';


@Component({
  selector: 'nv-author-list-section',
  templateUrl: './author-list-section.component.html'
})
export class AuthorListSectionComponent extends BaseSectionComponent implements OnInit, OnDestroy {
  @Input() data: WebsitePageSection;
  configuration: AuthorListSectionConfig = new AuthorListSectionConfig();

  conferenceId: number;
  authors: Author[];
  pagination: PaginationConfig = {
    current_page: 1,
    per_page: 8
  };

  loadingAuthors: boolean;

  subscriptions: Subscription[] = [];

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService,
    private authorService: AuthorService,
    private router: Router,
    private _sanitizer: DomSanitizer
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );
  }

  ngOnInit() {
    this.configuration = new AuthorListSectionConfig(this.data.configuration);

    const subscription = this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          this.conferenceId = website.active_conference_id;
          this.getAuthors();
        },
        () => { }
      );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getAuthors() {
    const params: any = {
      page: this.pagination.current_page,
      per_page: this.pagination.per_page,
      sort_by: 'order',
      sort_direction: 'asc',
      role: '',
      featured: true
    };
    
    if (this.configuration.speakers) {
      params.role = 'speaker';
    }

    if (this.configuration.limit) {
      params.per_page = this.configuration.limit;
    }

    if (this.configuration.featured) {
      params.featured = true;
    }

    this.loadingAuthors = true;
    this.authorService.getConferenceAuthors(this.conferenceId, params)
      .subscribe(
        (response) => {
          this.authors = response['data'];
          this.pagination = response['meta'].pagination;
          this.loadingAuthors = false;
        },
        () => {
          this.loadingAuthors = false;
        }
      );
  }

  openModal(author: Author) {
    const modalRef = this.modalService.open(AuthorModalComponent);
    modalRef.componentInstance.author = author;
  }

  public getAuthorImage(author) {
    const image = author.image_url_thumb !== null ? author.image_url_thumb : author.image_url;
    if (image === null) {
      return null;
    } else {
      return this._sanitizer.bypassSecurityTrustStyle(`url(${image})`);
    }
  }

  paginationChange(paginationConfig: PaginationConfig) {
    this.pagination.current_page = paginationConfig.current_page;
    this.getAuthors();
  }
}
