import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SponsorListSectionConfig } from './sponsor-list-section-config';
import { SponsorGroup } from '@navus/core/classes/sponsor-group';
import { Subscription, combineLatest } from 'rxjs';
import { SponsorGroupService } from '@navus/core/services/sponsor-group.service';
import { WebsiteService } from '@navus/core/services/website.service';
import { Website } from '@navus/core/classes/website';

@Component({
  selector: 'nv-sponsor-list-section-configuration',
  templateUrl: './sponsor-list-section-configuration.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SponsorListSectionConfigurationComponent),
      multi: true
    }
  ]
})

export class SponsorListSectionConfigurationComponent implements OnInit, ControlValueAccessor {
  @Input() value: SponsorListSectionConfig;

  sponsorGroups: SponsorGroup[] = [];
  subscriptions: Subscription[] = [];
  conferenceId: number;

  typeOptions = [
    { id: 'all', name: 'All' },
    { id: 'exhibitors', name: 'Exhibitors' },
    { id: 'sponsors', name: 'Sponsors' }
  ]

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  constructor(
    private sponsorGroupService: SponsorGroupService,
    public websiteService: WebsiteService,
  ) {
      websiteService
  }

  ngOnInit() {
    const subscription = this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          this.conferenceId = website.active_conference_id;
        },
        () => { }
      );
    this.subscriptions.push(subscription);
    this.getCompanies();
  }

  writeValue(value: any): void {
    this.value = new SponsorListSectionConfig(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  getCompanies() {
    combineLatest([
      this.sponsorGroupService
        .getConferenceSponsorGroups(this.conferenceId, {
          sort_by: 'order',
          sort_direction: 'asc',
          with_pagination: 0
        })
    ])
      .subscribe(
        (response) => {
          const sponsorGroups = response[0].data.sort((a: any, b: any) => (a.display > b.display) ? 1 : -1);

          sponsorGroups.sort((a: SponsorGroup, b: SponsorGroup) => {
            if (a.order < b.order) { return -1; }
            if (a.order > b.order) { return 1; }
            return 0;
          });

          this.sponsorGroups = sponsorGroups;
        }
      );
  }

}
