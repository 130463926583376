export class AuthorListSectionConfig {
  limit: number = 8;
  title: string = 'Speakers';
  viewAll: boolean = false;
  numbered: boolean = false;
  featured: boolean = false;
  speakers: boolean = false;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
