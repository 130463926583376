import {NgModule} from '@angular/core';
import {PublicVotingComponent} from './public-voting.component';
import {PublicVotingRoutingModule} from './public-voting-routing.module';
import {CommonModule} from '@angular/common';

@NgModule({
    declarations: [PublicVotingComponent],
    imports: [PublicVotingRoutingModule, CommonModule]
})
export class PublicVotingModule {
}
