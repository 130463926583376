<h3 style="text-align: center;">Select Ticket</h3>

<div class="nv-row semi-spaced-top gap-24 propagate">
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-select
                      [options]="ticketTypes" 
                      [(ngModel)]="selectedTicketTypeId"
                      (ngModelChange)="ticketTypeChange()"
                      [label]="'Ticket' | translate"
            ></nv-select>
        </div><!-- /.nv-column-content -->
    </div><!-- /.nv-column -->
    <div class="nv-column desk-8" *ngIf="selectedTicketType?.allow_codes">
        <div class="nv-column-content">
            <nv-input [(ngModel)]="ticketTypeCodeText"
                      (ngModelChange)="ticketTypeCodeError = ''"
                      label="{{'Promo Code' | translate}}"
                      (keyup.enter)="codeCheck()"
            ></nv-input>
            <div class="nv-input-error-box" *ngIf="ticketTypeCodeError !== ''">
                <div class="nv-input-error single">
                    <div>{{ ticketTypeCodeError }}</div>
                </div>
            </div><!-- /nv-input-error-box -->
        </div><!-- /.nv-column-content -->
    </div><!-- /.nv-column -->
    <div class="nv-column desk-4" *ngIf="selectedTicketType?.allow_codes">
        <div class="nv-column-content">
            <nv-button class="fw" [loading]="checkingCode" (click)="ticketCodeCheck()">Apply</nv-button>
        </div><!-- /.nv-column-content -->
    </div><!-- /.nv-column -->
    <div class="nv-column desk-12" *ngIf="selectedTicketType">
        <div class="nv-column-content">
            <h4>{{ selectedTicketType.title }}</h4>
            <div>
                <span>Price: </span>
                <span><b>{{ selectedTicketType.currency }}</b>&nbsp;</span>
                <span><b>{{ ticketTypeCode ? ticketTypeCode.discounted_ticket_price : selectedTicketType.amount }}.-</b></span>
            </div>
            <div>{{ selectedTicketType.description }}</div>
        </div><!-- /.nv-column-content -->
    </div><!-- /.nv-column -->
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-button color="secondary" 
                        size="large"
                        class="fw radius-high"
                        [disabled]="!selectedTicketType" 
                        (click)="next()">
                Next
            </nv-button><!-- /.nv-btn -->
        </div><!-- /.nv-column-content -->
    </div><!-- /.nv-column -->
</div>