<div class="nv-modal large">
    <div class="nv-modal-header">
        <h4 class="nv-modal-title">Voting question</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body">
        <form [formGroup]="votingQuestionForm" class="nv-row gap-30 propagate">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <nv-input
                            formControlName="title"
                            label="Question"
                            required
                    ></nv-input>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <nv-select
                            [options]="questionStatuses"
                            formControlName="status"
                            label="Status"
                    ></nv-select>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <ng-container formGroupName="options">
                <div class="nv-column desk-12" *ngIf="false">
                    <div class="nv-column-content">
                        <nv-select
                                [options]="questionTypes"
                                formControlName="type"
                                label="Type"
                        ></nv-select>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
                <ng-container *ngFor="let choice of votingQuestionForm.value?.options?.choices; trackBy: trackBy; let i = index">
                    <div class="nv-column desk-12" formArrayName="choices">
                        <div class="nv-column-content" [formGroupName]="i">
                            <div class="nv-row gap-30">
                                <div class="nv-column desk-2" *ngIf="false">
                                    <div class="nv-column-content">
                                        <nv-input label="Choice" formControlName="choice"></nv-input>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-8">
                                    <div class="nv-column-content">
                                        <nv-input label="Title" formControlName="title"></nv-input>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-2">
                                    <div class="nv-column-content">
                                        <nv-button size="small" (click)="removeChoice(i)">Remove</nv-button>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                            </div>
                            <div class="nv-row gap-30">
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <br>
                                        <nv-checkbox label="Correct" formControlName="correct"></nv-checkbox>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                            </div>
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->
                </ng-container>
            </ng-container>


            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <nv-button size="small" (click)="save()" [loading]="savingVotingQuestion" [disabled]="votingQuestionForm.invalid" translate>Save question</nv-button>
                    &nbsp;
                    <nv-button size="small" (click)="addChoice()" [disabled]="votingQuestionForm.value?.options?.choices.length > 10" translate>Add a choice</nv-button>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </form>
    </div>
</div>

