<div class="nv-section guttered">
    <div class="nv-port">
        <div class="nv-row gap-24 extra-large-spaced-top large-spaced-bottom">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <h1>Ex quando legimus praesent</h1>
                    <h2>Ex quando legimus praesent</h2>
                    <h3>Ex quando legimus praesent</h3>
                    <h4>Ex quando legimus praesent</h4>
                    <h5>Ex quando legimus praesent</h5>
                    <h6>Ex quando legimus praesent</h6>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row gap-24 large-spaced">
            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <h1>Ex quando legimus praesent</h1>
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, mucius melius latine nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram. Ex quando legimus praesent quo, sed recteque prodesset ei, patrioque democritum expetendis eu has.</p>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <h1>Ex quando legimus praesent</h1>
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, mucius melius latine nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram. Ex quando legimus praesent quo, sed recteque prodesset ei, patrioque democritum expetendis eu has.</p>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row gap-24 large-spaced">
            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <h2>Ex quando legimus praesent</h2>
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, mucius melius latine nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram. Ex quando legimus praesent quo, sed recteque prodesset ei, patrioque democritum expetendis eu has.</p>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <h2>Ex quando legimus praesent</h2>
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, mucius melius latine nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram. Ex quando legimus praesent quo, sed recteque prodesset ei, patrioque democritum expetendis eu has.</p>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row gap-24 large-spaced">
            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <h3>Ex quando legimus praesent</h3>
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, mucius melius latine nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram. Ex quando legimus praesent quo, sed recteque prodesset ei, patrioque democritum expetendis eu has.</p>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <h3>Ex quando legimus praesent</h3>
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, mucius melius latine nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram. Ex quando legimus praesent quo, sed recteque prodesset ei, patrioque democritum expetendis eu has.</p>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row gap-24 large-spaced">
            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <h4>Ex quando legimus praesent</h4>
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, mucius melius latine nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram. Ex quando legimus praesent quo, sed recteque prodesset ei, patrioque democritum expetendis eu has.</p>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <h4>Ex quando legimus praesent</h4>
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, mucius melius latine nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram. Ex quando legimus praesent quo, sed recteque prodesset ei, patrioque democritum expetendis eu has.</p>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row gap-24 large-spaced">
            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <h5>Ex quando legimus praesent</h5>
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, mucius melius latine nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram. Ex quando legimus praesent quo, sed recteque prodesset ei, patrioque democritum expetendis eu has.</p>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <h5>Ex quando legimus praesent</h5>
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, mucius melius latine nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram. Ex quando legimus praesent quo, sed recteque prodesset ei, patrioque democritum expetendis eu has.</p>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row gap-24 large-spaced">
            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <h6>Ex quando legimus praesent</h6>
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, mucius melius latine nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram. Ex quando legimus praesent quo, sed recteque prodesset ei, patrioque democritum expetendis eu has.</p>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <h6>Ex quando legimus praesent</h6>
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, mucius melius latine nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram. Ex quando legimus praesent quo, sed recteque prodesset ei, patrioque democritum expetendis eu has.</p>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row gap-24 extra-large-spaced-top large-spaced-bottom">
            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <h3>Ex quando legimus praesent</h3>
                    <ul>
                        <li>His euripidis quaerendum id</li>
                        <li>At dicant causae appetere sed</li>
                        <li>Odio alia ocurreret ea mea ei pri affert</li>
                        <li>Ea sit dicta copiosae suas</li>
                        <li>Te vis quodsi erroribus</li>
                        <li>Utinam facilisis eloquentiam</li>
                        <li>Mandamus iudicabit disputationi has id</li>
                    </ul>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <h3>Ex quando legimus praesent</h3>
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, <a href="">mucius melius latine</a> nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram.</p>
                    <ul>
                        <li>His euripidis quaerendum id</li>
                        <li>At dicant causae appetere sed</li>
                        <li>Odio alia ocurreret ea mea ei pri affert</li>
                        <li>Ea sit dicta copiosae suas</li>
                        <li>Te vis quodsi erroribus</li>
                        <li>Utinam facilisis eloquentiam</li>
                        <li>Mandamus iudicabit disputationi has id</li>
                    </ul>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row gap-24 large-spaced">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <h2>Ex quando legimus praesent</h2>
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, mucius melius latine nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram. Ex quando legimus praesent quo, sed recteque prodesset ei, patrioque democritum expetendis eu has.</p>
                    <blockquote>
                        <p>Quo assum repudiandae an, ei eam brute causae. Per erant voluptua te. t falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, mucius melius latine nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram.</p>
                    </blockquote>
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, mucius melius latine nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram. Ex quando legimus praesent quo, sed recteque prodesset ei, patrioque democritum expetendis eu has.</p>
                    <ol>
                        <li>His euripidis quaerendum id</li>
                        <li>At dicant causae appetere sed</li>
                        <li>Odio alia ocurreret ea mea ei pri affert</li>
                        <li>Ea sit dicta copiosae suas</li>
                        <li>Te vis quodsi erroribus</li>
                        <li>Utinam facilisis eloquentiam</li>
                        <li>Mandamus iudicabit disputationi has id</li>
                    </ol>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row gap-24 large-spaced">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <header class="nv-underlined-heading">
                        <h1><span>Ex quando legimus praesent</span></h1>
                    </header><!-- /.nv-underlined-heading -->
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, <a href="">mucius melius latine</a> nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram.</p>


                    <header class="nv-underlined-heading accent1">
                        <h2><span>Ex quando legimus praesent</span></h2>
                    </header><!-- /.nv-underlined-heading -->
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, <a href="">mucius melius latine</a> nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram.</p>


                    <header class="nv-underlined-heading accent2">
                        <h3><span>Ex quando legimus praesent</span></h3>
                    </header><!-- /.nv-underlined-heading -->
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, <a href="">mucius melius latine</a> nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram.</p>


                    <header class="nv-underlined-heading accent1">
                        <h4><span>Ex quando legimus praesent</span></h4>
                    </header><!-- /.nv-underlined-heading -->
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, <a href="">mucius melius latine</a> nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram.</p>

                    <header class="nv-underlined-heading accent1">
                        <h5><span>Ex quando legimus praesent</span></h5>
                    </header><!-- /.nv-underlined-heading -->
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, <a href="">mucius melius latine</a> nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram.</p>

                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->

        <div class="nv-row gap-24 large-spaced">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <header class="nv-plain-heading">
                        <h1>Ex quando legimus praesent</h1>
                    </header><!-- /.nv-plain-heading -->
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, <a href="">mucius melius latine</a> nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram.</p>

                    <header class="nv-plain-heading">
                        <h2>Ex quando legimus praesent</h2>
                    </header><!-- /.nv-plain-heading -->
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, <a href="">mucius melius latine</a> nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram.</p>

                    <header class="nv-plain-heading">
                        <h3>Ex quando legimus praesent</h3>
                    </header><!-- /.nv-plain-heading -->
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, <a href="">mucius melius latine</a> nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram.</p>

                    <header class="nv-plain-heading">
                        <h4>Ex quando legimus praesent</h4>
                    </header><!-- /.nv-plain-heading -->
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, <a href="">mucius melius latine</a> nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram.</p>


                    <header class="nv-plain-heading">
                        <h5>Ex quando legimus praesent</h5>
                    </header><!-- /.nv-plain-heading -->
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, <a href="">mucius melius latine</a> nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram.</p>

                    <header class="nv-plain-heading">
                        <h6>Ex quando legimus praesent</h6>
                    </header><!-- /.nv-plain-heading -->
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, <a href="">mucius melius latine</a> nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram.</p>

                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->

        <div class="nv-row gap-24 large-spaced">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <header class="nv-plain-heading">
                        <h1>Ex quando legimus praesent</h1>
                        <h3 class="nv-subtitle">Noster neglegentur ei ius, quo alterum urbanitas referrentur</h3>
                    </header><!-- /.nv-plain-heading -->
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, <a href="">mucius melius latine</a> nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram.</p>


                    <h2>Ex quando legimus praesent</h2>
                    <h4 class="nv-subtitle">Noster neglegentur ei ius, quo alterum urbanitas referrentur</h4>
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, <a href="">mucius melius latine</a> nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram.</p>

                    <h3>Ex quando legimus praesent</h3>
                    <h5 class="nv-subtitle light">Noster neglegentur ei ius, quo alterum urbanitas referrentur</h5>
                    <p>At falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, <a href="">mucius melius latine</a> nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram.</p>

                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</div><!-- /.nv-section -->


<div class="nv-section guttered" style="background-color: rgba(0,0,0,0.05)">
    <div class="nv-port">
        <div class="nv-row gap-24 extra-large-spaced-top large-spaced-bottom config-plates">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <h1>H1 Run it up the flagpole shoot me an email</h1>
                    <p>Message the initiative manage expectations into the weeds, so deploy to production. Fast track churning anomalies the last person we talked to said this would be ready nor regroup or hit the ground running. Push back create spaces to explore what’s next core competencies not a hill to die on. We need more paper out of scope enough to wash your face and win-win-win but a set of certitudes based on deductions founded on false premise pivot.</p>
                    <div class="nv-divider desk-large"></div>
                    <h2>H2 Run it up the flagpole shoot me an em</h2>
                    <p>Message the initiative manage expectations into the weeds, so deploy to production. Fast track churning anomalies the last person we talked to said this would be ready nor regroup or hit the ground running. Push back create spaces to explore what’s next core competencies not a hill to die on. We need more paper out of scope enough to wash your face and win-win-win but a set of certitudes based on deductions founded on false premise pivot.</p>
                    <div class="nv-divider desk-large"></div>
                    <h3>H3 Run it up the flagpole shoot me an em</h3>
                    <p>Message the initiative manage expectations into the weeds, so deploy to production. Fast track churning anomalies the last person we talked to said this would be ready nor regroup or hit the ground running. Push back create spaces to explore what’s next core competencies not a hill to die on. We need more paper out of scope enough to wash your face and win-win-win but a set of certitudes based on deductions founded on false premise pivot.</p>
                    <div class="nv-divider desk-large"></div>
                    <h4>H4 Run it up the flagpole shoot me an em</h4>
                    <p>Message the initiative manage expectations into the weeds, so deploy to production. Fast track churning anomalies the last person we talked to said this would be ready nor regroup or hit the ground running. Push back create spaces to explore what’s next core competencies not a hill to die on. We need more paper out of scope enough to wash your face and win-win-win but a set of certitudes based on deductions founded on false premise pivot.</p>
                    <div class="nv-divider desk-large"></div>
                    <h5>H4 Run it up the flagpole shoot me an em</h5>
                    <p>Message the initiative manage expectations into the weeds, so deploy to production. Fast track churning anomalies the last person we talked to said this would be ready nor regroup or hit the ground running. Push back create spaces to explore what’s next core competencies not a hill to die on. We need more paper out of scope enough to wash your face and win-win-win but a set of certitudes based on deductions founded on false premise pivot.</p>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row gap-24 extra-large-spaced-top large-spaced-bottom config-plates">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <p style="text-align: center;"><strong>Pale</strong></p>
                    <h1 class="pale">H1 Run it up the flagpole shoot me an email</h1>
                    <p>Message the initiative manage expectations into the weeds, so deploy to production. Fast track churning anomalies the last person we talked to said this would be ready nor regroup or hit the ground running. Push back create spaces to explore what’s next core competencies not a hill to die on. We need more paper out of scope enough to wash your face and win-win-win but a set of certitudes based on deductions founded on false premise pivot.</p>
                    <div class="nv-divider desk-large"></div>
                    <h2 class="pale">H2 Run it up the flagpole shoot me an em</h2>
                    <p>Message the initiative manage expectations into the weeds, so deploy to production. Fast track churning anomalies the last person we talked to said this would be ready nor regroup or hit the ground running. Push back create spaces to explore what’s next core competencies not a hill to die on. We need more paper out of scope enough to wash your face and win-win-win but a set of certitudes based on deductions founded on false premise pivot.</p>
                    <div class="nv-divider desk-large"></div>
                    <h3 class="pale">H3 Run it up the flagpole shoot me an em</h3>
                    <p>Message the initiative manage expectations into the weeds, so deploy to production. Fast track churning anomalies the last person we talked to said this would be ready nor regroup or hit the ground running. Push back create spaces to explore what’s next core competencies not a hill to die on. We need more paper out of scope enough to wash your face and win-win-win but a set of certitudes based on deductions founded on false premise pivot.</p>
                    <div class="nv-divider desk-large"></div>
                    <h4 class="pale">H4 Run it up the flagpole shoot me an em</h4>
                    <p>Message the initiative manage expectations into the weeds, so deploy to production. Fast track churning anomalies the last person we talked to said this would be ready nor regroup or hit the ground running. Push back create spaces to explore what’s next core competencies not a hill to die on. We need more paper out of scope enough to wash your face and win-win-win but a set of certitudes based on deductions founded on false premise pivot.</p>
                    <div class="nv-divider desk-large"></div>
                    <h5 class="pale">H4 Run it up the flagpole shoot me an em</h5>
                    <p>Message the initiative manage expectations into the weeds, so deploy to production. Fast track churning anomalies the last person we talked to said this would be ready nor regroup or hit the ground running. Push back create spaces to explore what’s next core competencies not a hill to die on. We need more paper out of scope enough to wash your face and win-win-win but a set of certitudes based on deductions founded on false premise pivot.</p>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row gap-24 extra-large-spaced-top large-spaced-bottom config-plates">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <p style="text-align: center;"><strong>Light</strong></p>
                    <h1 class="light">H1 Run it up the flagpole shoot me an email</h1>
                    <p>Message the initiative manage expectations into the weeds, so deploy to production. Fast track churning anomalies the last person we talked to said this would be ready nor regroup or hit the ground running. Push back create spaces to explore what’s next core competencies not a hill to die on. We need more paper out of scope enough to wash your face and win-win-win but a set of certitudes based on deductions founded on false premise pivot.</p>
                    <div class="nv-divider desk-large"></div>
                    <h2 class="light">H2 Run it up the flagpole shoot me an em</h2>
                    <p>Message the initiative manage expectations into the weeds, so deploy to production. Fast track churning anomalies the last person we talked to said this would be ready nor regroup or hit the ground running. Push back create spaces to explore what’s next core competencies not a hill to die on. We need more paper out of scope enough to wash your face and win-win-win but a set of certitudes based on deductions founded on false premise pivot.</p>
                    <div class="nv-divider desk-large"></div>
                    <h3 class="light">H3 Run it up the flagpole shoot me an em</h3>
                    <p>Message the initiative manage expectations into the weeds, so deploy to production. Fast track churning anomalies the last person we talked to said this would be ready nor regroup or hit the ground running. Push back create spaces to explore what’s next core competencies not a hill to die on. We need more paper out of scope enough to wash your face and win-win-win but a set of certitudes based on deductions founded on false premise pivot.</p>
                    <div class="nv-divider desk-large"></div>
                    <h4 class="light">H4 Run it up the flagpole shoot me an em</h4>
                    <p>Message the initiative manage expectations into the weeds, so deploy to production. Fast track churning anomalies the last person we talked to said this would be ready nor regroup or hit the ground running. Push back create spaces to explore what’s next core competencies not a hill to die on. We need more paper out of scope enough to wash your face and win-win-win but a set of certitudes based on deductions founded on false premise pivot.</p>
                    <div class="nv-divider desk-large"></div>
                    <h5 class="light">H4 Run it up the flagpole shoot me an em</h5>
                    <p>Message the initiative manage expectations into the weeds, so deploy to production. Fast track churning anomalies the last person we talked to said this would be ready nor regroup or hit the ground running. Push back create spaces to explore what’s next core competencies not a hill to die on. We need more paper out of scope enough to wash your face and win-win-win but a set of certitudes based on deductions founded on false premise pivot.</p>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row gap-24 extra-large-spaced-top large-spaced-bottom config-plates">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <p style="text-align: center;"><strong>Thin</strong></p>
                    <h1 class="thin">H1 Run it up the flagpole shoot me an email</h1>
                    <p>Message the initiative manage expectations into the weeds, so deploy to production. Fast track churning anomalies the last person we talked to said this would be ready nor regroup or hit the ground running. Push back create spaces to explore what’s next core competencies not a hill to die on. We need more paper out of scope enough to wash your face and win-win-win but a set of certitudes based on deductions founded on false premise pivot.</p>
                    <div class="nv-divider desk-large"></div>
                    <h2 class="thin">H2 Run it up the flagpole shoot me an em</h2>
                    <p>Message the initiative manage expectations into the weeds, so deploy to production. Fast track churning anomalies the last person we talked to said this would be ready nor regroup or hit the ground running. Push back create spaces to explore what’s next core competencies not a hill to die on. We need more paper out of scope enough to wash your face and win-win-win but a set of certitudes based on deductions founded on false premise pivot.</p>
                    <div class="nv-divider desk-large"></div>
                    <h3 class="thin">H3 Run it up the flagpole shoot me an em</h3>
                    <p>Message the initiative manage expectations into the weeds, so deploy to production. Fast track churning anomalies the last person we talked to said this would be ready nor regroup or hit the ground running. Push back create spaces to explore what’s next core competencies not a hill to die on. We need more paper out of scope enough to wash your face and win-win-win but a set of certitudes based on deductions founded on false premise pivot.</p>
                    <div class="nv-divider desk-large"></div>
                    <h4 class="thin">H4 Run it up the flagpole shoot me an em</h4>
                    <p>Message the initiative manage expectations into the weeds, so deploy to production. Fast track churning anomalies the last person we talked to said this would be ready nor regroup or hit the ground running. Push back create spaces to explore what’s next core competencies not a hill to die on. We need more paper out of scope enough to wash your face and win-win-win but a set of certitudes based on deductions founded on false premise pivot.</p>
                    <div class="nv-divider desk-large"></div>
                    <h5 class="thin">H4 Run it up the flagpole shoot me an em</h5>
                    <p>Message the initiative manage expectations into the weeds, so deploy to production. Fast track churning anomalies the last person we talked to said this would be ready nor regroup or hit the ground running. Push back create spaces to explore what’s next core competencies not a hill to die on. We need more paper out of scope enough to wash your face and win-win-win but a set of certitudes based on deductions founded on false premise pivot.</p>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</div><!-- /.nv-section -->
