<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Attendees</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-8">
                <nv-button color="passive" [loading]="exportingDelegates" (click)="export()">Export</nv-button>
                <nv-button color="primary" iconPl="&#xe095;" (click)="add()">Add attendee</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port spaced-bottom">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav vertical-break-tab">
                <ul>
                    <li>
                        <a class="active">Attendees</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'ticket-types']">Ticketing</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'registration-settings']">Registration Settings</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'certificates']">CME Certificates</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <form class="config-search-bar-w-filters" [formGroup]="formFilter" autocomplete="off">
        <div class="sbwf-port">
            <input
                type="text"
                placeholder="Search..."
                formControlName="search_term">
            <span class="sbwf-outline"></span>
        </div><!-- /.sbwf-port -->
        <div class="sbwf-filters">
            <div class="sbwf-trigger" (click)="showFilter = !showFilter">Filters</div>
        </div><!-- /.sbwf-filters -->
    </form><!-- /.config-search-bar-w-filters -->
    <ng-container *ngIf="false">
        <div class="tnt-right" style="padding-left: 8px;">
            <div class="nv-btn-box flow-end gap-8">
                <nv-button color="passive" [loading]="downloadingDelegatesTemplate" (click)="getDelegateTemplate()">
                    Download XLS
                </nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
        <div *ngIf="false" class="tnt-right" style="padding-left: 8px;">
            <div class="nv-btn-box flow-end gap-8">
                <nv-button color="primary" iconPl="&#xe095;" (click)="fileSelect.click()">Import XLS</nv-button>
                <input #fileSelect type="file" ng2FileSelect [uploader]="uploader" [hidden]="true"
                    accept="application/vnd.ms-excel">
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->            
    </ng-container>
    <div class="nv-config-filters-row">
        <span class="cfr-item" *ngFor="let filterPill of filterPills">
            {{ filterPill.name }}
            <a (click)="filterRemove(filterPill)"></a>
        </span>
    </div>
</div>
<div class="nv-config-content-port" nvInfiniteScroll (scrolled)="getDelegates(true)">
    <div class="nv-global-loading on" [class.on]="delegatesLoading">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <section class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates">
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content">
                        <div *ngIf="pagination.total === 0">
                            <div class="nv-empty-state">
                                <div class="img">
                                    <img src="../../../../assets/empty-states/empty-state-delegates.svg" alt="">
                                </div>
                                <h4 class="title">No delegates!</h4>
                                <p class="text">Once you add delegate, you'll see them here.</p>
                                <nv-button (click)="add()" >Add delegate</nv-button>
                            </div>
                        </div>
                        <table class="nv-data-table break-lap">
                            <tr *ngFor="let delegate of delegates">
                                <td class="dt-cell nv-clickable" (click)="edit(delegate)">
                                    <label>CODE</label>
                                    <strong>{{ delegate.code }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-cell nv-clickable" (click)="edit(delegate)">
                                    <label>COUNTRY</label>
                                    <strong>{{ getCountryNameById(delegate.country_id) }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-cell nv-clickable" (click)="edit(delegate)">
                                    <label>NAME</label>
                                    <strong>{{ delegate.title }} {{ delegate.first_name }} {{ delegate.last_name }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-cell nv-clickable" (click)="edit(delegate)">
                                    <label>EMAIL</label>
                                    <strong>{{ delegate.email }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-actions">
                                    <div class="nv-cell-actions">
                                    <span class="nv-cell-action-trigger"
                                          [class.on]="optionsShown === delegate.id"
                                          (click)="openOptions(delegate)"></span>
                                        <nav nvOffClick (offClick)="closeOptions()">
                                            <ul>
                                                <li (click)="edit(delegate)">
                                                    <a>Edit</a>
                                                </li>
                                                <li class="delete" (click)="delete(delegate)">
                                                    <a>Delete</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div><!-- /.nv-cell-actions -->
                                </td>
                                <td class="dt-actions nv-clickable" (click)="editInNewTab(delegate)">
                                    <div class="nv-icon" data-icon="&#xe05a;"></div>
                                </td>
                            </tr>
                        </table>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->
</div><!-- /.config-layout-lower-fixed -->

<!-- Filters open -->

<form class="nv-side-filters-panel"
    [formGroup]="formFilter"
    [class.on]="showFilter"
>
    <header class="sfp-header">
        <span class="sfp-close" (click)="showFilter = false"></span>
        <h3>Add Filters</h3>
        <p>Changes you make apply to data table.</p>
    </header><!-- /.sfp-header -->
    <div class="sfp-body">

        <div class="fb-filter-chunk" *ngIf="ticketTypes.length > 0">
            <div class="fb-filter-box">
                <nv-select
                    label="Ticket type"
                    formControlName="conference_ticket_type_id"
                    [options]="ticketTypes">
                ></nv-select>
            </div><!-- /.fb-filter-box -->
        </div><!-- /.fb-filter-chunk -->

        <div class="fb-filter-chunk">
            <div class="fb-filter-box">
                <nv-select
                    label="Country"
                    formControlName="country_ids"
                    [options]="countries"
                    [multi]="true">
                ></nv-select>
            </div><!-- /.fb-filter-box -->
        </div><!-- /.fb-filter-chunk -->

        <div class="fb-filter-chunk">
            <div class="fb-filter-box">
                <nv-select
                    label="Status"
                    formControlName="status"
                    [options]="statuses">
                ></nv-select>
            </div><!-- /.fb-filter-box -->
        </div><!-- /.fb-filter-chunk -->

    </div><!-- /.sfp-body -->
    <footer class="sfp-footer">
        <label>
            <a (click)="formFilter.reset()">Clear Filters</a>
        </label>
        <div class="sfp-btns">
            <!-- <a href="" class="nv-btn small filled neutral">Cancel</a>
            <a href="" class="nv-btn small filled accent1">Apply</a> -->
        </div>
    </footer><!-- /.sfp-footer -->
</form><!-- /.nv-side-filters-panel -->

<ng-template #progressTemplate>
    <div class="nv-modal" [ngClass]="'small'">
        <div class="nv-modal-header">
            <h4 class="nv-modal-title" translate>Import progress</h4>
            <button type="button" aria-label="Close" (click)="closeModal()"></button>
        </div>
        <div class="nv-modal-body">
            {{progress}}%
        </div>
        <div class="nv-modal-footer">
            <div class="nv-btn-box gap-10 flow-end">
                <button type="button"
                        [disabled]="progress < 100"
                        class="nv-btn medium 'primary'"
                        (click)="close()">Done</button>
            </div><!-- /.nv-btn-box -->
        </div>
    </div>
</ng-template>
