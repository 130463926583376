<div class="nv-config-control-bar">
    <div class="cluf-port">
        <div class="config-title-n-tools">
            <div class="tnt-left">
                <h1 translate>Notification</h1>
            </div><!-- /.tnt-left -->
            <div class="tnt-right">
                <div class="nv-btn-box flow-end gap-10">
                    <a (click)="delete()" *ngIf="notificationId" translate class="nv-btn secondary medium">Delete</a>
                    <nv-button (click)="close()" color="passive" translate>Close</nv-button>
                    <nv-button (click)="save()" [loading]="savingNotification"
                               [disabled]="notificationForm.invalid" translate>Save
                    </nv-button>
                </div><!-- /.nv-btn-box flow-end gap-6 -->
            </div><!-- /.tnt-right -->
        </div><!-- /.config-title-n-tools -->
    </div>
    <div class="nv-port spaced-bottom">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav vertical-break-tab">
                <ul>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'communication', 'templates']">Templates</a>
                    </li>
                    <li>
                        <a class="active">Notifications</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'communication', 'notification-settings']">Notifications Settings</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
<div class="nv-config-content-port">
    <div class="nv-global-loading on" [class.on]="loadingNotification">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24 small-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form [formGroup]="notificationForm"
                              class="nv-row config-plates clean gap-24 small-spaced-top spaced-bottom">
                            <div class="nv-column desk-6 tab-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Basic Information</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="title"
                                                        label="Title"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        [options]="notificationTypes"
                                                        formControlName="type"
                                                        label="Related to"
                                                        [canEmpty]="false"
                                                        required
                                                ></nv-select>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <ng-container [ngSwitch]="notificationForm.value.type">
                                            <div class="nv-column desk-12" *ngSwitchCase="'presentation'">
                                                <div class="nv-column-content">
                                                    <nv-select
                                                            [options]="presentations"
                                                            [showSearch]="true"
                                                            (searched)="searchPresentations($event)"
                                                            (loadData)="getPresentations()"
                                                            (loadMore)="getPresentations(true)"
                                                            formControlName="entity_id"
                                                            label="Presentation"
                                                            [canEmpty]="false"
                                                            required
                                                    ></nv-select>
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /.nv-column -->
                                            <div class="nv-column desk-12" *ngSwitchCase="'block'">
                                                <div class="nv-column-content">
                                                    <nv-select
                                                            [options]="blocks"
                                                            [showSearch]="true"
                                                            (searched)="searchBlocks($event)"
                                                            (loadData)="getBlocks()"
                                                            (loadMore)="getBlocks(true)"
                                                            formControlName="entity_id"
                                                            label="Session"
                                                            [canEmpty]="false"
                                                            required
                                                    ></nv-select>
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /.nv-column -->
                                            <div class="nv-column desk-12" *ngSwitchCase="'sponsor'">
                                                <div class="nv-column-content">
                                                    <nv-select
                                                            [options]="companies"
                                                            [showSearch]="true"
                                                            (searched)="searchCompanies($event)"
                                                            (loadData)="getCompanies()"
                                                            (loadMore)="getCompanies(true)"
                                                            formControlName="entity_id"
                                                            label="Sponsor"
                                                            [canEmpty]="false"
                                                            required
                                                    ></nv-select>
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /.nv-column -->
                                            <div class="nv-column desk-12" *ngSwitchCase="'speaker'">
                                                <div class="nv-column-content">
                                                    <nv-select
                                                            [options]="authors"
                                                            [showSearch]="true"
                                                            (searched)="searchAuthors($event)"
                                                            (loadData)="getAuthors()"
                                                            (loadMore)="getAuthors(true)"
                                                            formControlName="entity_id"
                                                            label="Speaker"
                                                            [canEmpty]="false"
                                                            required
                                                    ></nv-select>
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /.nv-column -->
                                            <div class="nv-column desk-12" *ngSwitchCase="'news'">
                                                <div class="nv-column-content">
                                                    <nv-select
                                                            [options]="articles"
                                                            [showSearch]="true"
                                                            (searched)="searchArticles($event)"
                                                            (loadData)="getArticles()"
                                                            (loadMore)="getArticles(true)"
                                                            formControlName="entity_id"
                                                            label="Article"
                                                            [canEmpty]="false"
                                                            required
                                                    ></nv-select>
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /.nv-column -->
                                        </ng-container>
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        type="textarea"
                                                        formControlName="body"
                                                        label="Body"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-6 tab-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>&nbsp;</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-datepicker
                                                        formControlName="schedule_time"
                                                        label="Schedule Time"
                                                        required
                                                ></nv-datepicker>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12" *ngIf="notificationForm?.get('channel').value !== 'web'">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        [multi]="true"
                                                        [options]="notificationScope"
                                                        formControlName="scope"
                                                        label="Scope"
                                                        required
                                                ></nv-select>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        [options]="notificationChannel"
                                                        formControlName="channel"
                                                        label="Channel"
                                                        [canEmpty]="false"
                                                        required
                                                ></nv-select>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12" *ngIf="notificationForm?.get('channel').value !== 'app'">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        [options]="notificationPlace"
                                                        formControlName="place"
                                                        label="Place"
                                                        [canEmpty]="false"
                                                        required
                                                ></nv-select>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <ng-container *ngIf="notification?.status === 'draft'">
                                                    <nv-button (click)="launch()">Launch</nv-button>
                                                </ng-container>
                                                <ng-container *ngIf="notification?.status === 'published'">
                                                    Notification published
                                                </ng-container>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
