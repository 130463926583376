import { Component, HostBinding, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DelegateService } from '@navus/core/services/delegate.service';
import { Delegate } from '@navus/core/classes/delegate';
import { Country } from '@navus/core/classes/country';
import { CountryService } from '@navus/core/services/country.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ConferenceService } from '@navus/core/services/conference.service';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../../../../environments/environment';
import Echo from 'laravel-echo';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Pusher from 'pusher-js';

@Component({
  selector: 'main [nv-delegate-list-page]',
  templateUrl: './delegate-list-page.component.html'
})
export class DelegateListPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;
  LaravelEcho: Echo;

  organizationId: number;
  conferenceId: number;
  delegates: Delegate[] = [];
  countries: Country[] = [];
  ticketTypes: any[] = [];

  pagination: { page?: number, perPage: number, total?: number } = {
    perPage: 30
  };
  sort: { field: string, direction: string } = {
    field: 'name',
    direction: 'asc'
  };
  selectedIds: number[] = [];

  delegatesLoading: boolean = false;
  exportingDelegates: boolean = false;
  downloadingDelegatesTemplate: boolean = false;
  optionsShown: number = null;

  uploader: FileUploader;
  progress: number = 0;

  formFilter: FormGroup;
  filterPills: any[] = [];
  showFilter: boolean = false;

  statuses: any[] = [
    { id: 'Pre-registered', name: 'Pre-registered' },
    { id: 'Fully registered', name: 'Fully registered' },
  ]

  subscriptions: Subscription[] = [];

  @ViewChild('progressTemplate', { static: true }) progressTemplate: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private delegateService: DelegateService,
    private countryService: CountryService,
    private conferenceService: ConferenceService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      search_term: [''],
      conference_ticket_type_id: [null],
      country_ids: [null],
      status: [null],
    });
    this.formFilter.valueChanges.subscribe(
      (value) => { this.filterChange(value) }
    )
    this.getCountries();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getDelegates();
        this.getTicketTypes();
        this.setupUploader();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    this.LaravelEcho?.disconnect();
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getDelegates(append: boolean = false) {
    if (append && this.pagination.total && this.delegates.length >= this.pagination.total) { return; }

    this.pagination.page = append ? this.pagination.page + 1 : 1;

    const params: any = {
      page: this.pagination.page,
      per_page: this.pagination.perPage,
      sort_by: this.sort.field,
      sort_direction: this.sort.direction,
    };
    if (this.formFilter.value.search_term !== '') {
      params.search_term = this.formFilter.value.search_term;
    }
    if (this.formFilter.value.conference_ticket_type_id) {
      params.conference_ticket_type_id = this.formFilter.value.conference_ticket_type_id;
    }
    if (this.formFilter.value.country_ids) {
      params.country_ids = this.formFilter.value.country_ids.join(',');
    }
    if (this.formFilter.value.status) {
      params.status = this.formFilter.value.status;
    }
    this.delegatesLoading = !append;
    this.delegateService
      .getConferenceDelegates(this.conferenceId, params)
      .subscribe(
        (response: { data: Delegate[], meta: any }) => {
          if (append) {
            this.delegates = this.delegates.concat(response.data);
          } else {
            const port = document.getElementsByClassName('nv-config-content-port');
            if (port && port.length > 0) {
              port[0].scrollTop = 0;
            }
            
            this.delegates = response.data;
            this.pagination.total = response.meta.pagination.total;
          }
          this.delegatesLoading = false;
        },
        () => {
          this.delegatesLoading = false;
        }
      );
  }

  getCountries() {
    this.countryService
      .getCountries()
      .subscribe(
        (response) => {
          this.countries = response['data'];
        },
        () => {

        }
      );
  }

  getCountryNameById(countryId: number) {
    for (const country of this.countries) {
      if (country.id === countryId) {
        return country.name;
      }
    }
    return '';
  }

  getTicketTypes() {
    this.conferenceService
        .getConferenceTicketTypes(this.conferenceId)
        .subscribe(
          (response) => {
            this.ticketTypes = response.data;
          },
          () => {

          }
        )
  }

  edit(delegate: Delegate) {
    this.router.navigate(['..', 'delegate', delegate.id], {relativeTo: this.route});
  }

  editInNewTab(delegate: Delegate) {
    window.open(`/o/${this.organizationId}/conference/${this.conferenceId}/attendees/delegate/${delegate.id}`, '_blank');
  }

  close() {
    this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'landing']);
  }

  add() {
    this.router.navigate(['..', 'delegate'], {relativeTo: this.route});
  }

  delete(delegate: Delegate) {
    this.modalService.defaultModal({
      title: 'Delegate Deletion',
      body: 'Are you sure you want to delete this delegate?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.delegateService
              .deleteConferenceDelegate(this.conferenceId, delegate.id)
              .subscribe(
                () => {
                  this.getDelegates();
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

  export() {
    this.exportingDelegates = true;
    this.delegateService
      .exportConferenceDelegates(this.conferenceId)
      .subscribe(
        () => {
          this.exportingDelegates = false;
        },
        (error) => {
          this.exportingDelegates = false;
          this.modalService.error({errors: error});
        }
      );
  }

  getDelegateTemplate() {
    this.downloadingDelegatesTemplate = true;
    this.conferenceService
      .getDelegateTemplate(this.conferenceId)
      .subscribe(
        () => {
          this.downloadingDelegatesTemplate = false;
        },
        (error) => {
          this.downloadingDelegatesTemplate = false;
          this.modalService.error({ errors: error });
        }
      );
  }

  sortDelegates(field: string) {
    if (field === this.sort.field) {
      this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sort.field = field;
      this.sort.direction = 'asc';
    }
    this.getDelegates();
  }

  toggleSelected(delegate: Delegate) {
    const index = this.selectedIds.indexOf(delegate.id);
    if (index !== -1) {
      this.selectedIds.splice(index, 1);
    } else {
      this.selectedIds.push(delegate.id);
    }
  }

  selectAll() {
    this.selectedIds = [];
    for (const delegate of this.delegates) {
      this.selectedIds.push(delegate.id);
    }
  }

  unselectAll() {
    this.selectedIds = [];
  }

  openOptions(delegate: Delegate) {
    event.stopPropagation();
    this.optionsShown = delegate.id;
  }

  closeOptions() {
    event.stopPropagation();
    this.optionsShown = null;
  }

  filterChange(value) {
    this.filterPills = [];
    

    if (value.conference_ticket_type_id) {
      const ticketType = this.ticketTypes.find(tt => tt.id === value.conference_ticket_type_id);
      this.filterPills.push({
        id: ticketType.id,
        type: 'ticketType',
        name: ticketType.title
      })
    }
    

    if (value.country_ids) {
      const countries = this.countries.filter(c => value.country_ids.includes(c.id));
      countries.forEach(
        country => {
          this.filterPills.push({
            id: country.id,
            type: 'country',
            name: country.name
          })          
        }
      )
    }
    
    if (value.status) {
      const status = this.statuses.find(a => a.id === value.status);
      this.filterPills.push({
        id: status.id,
        type: 'status',
        name: status.name
      })
    }

    this.getDelegates();
  }

  filterRemove(filterPill) {
    if (filterPill.type === 'ticketType') {
      this.formFilter.get('conference_ticket_type_id').setValue(null);
    }
    if (filterPill.type === 'status') {
      this.formFilter.get('status').setValue(null);
    }
    if (filterPill.type === 'country') {
      let countries = this.formFilter.get('country_ids').value || [];
      countries = countries.filter(c => c != filterPill.id);
      this.formFilter.get('country_ids').setValue(countries);
    }
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  private setupUploader() {
    this.uploader = new FileUploader({
      url: `${environment.api_url}conferences/${this.conferenceId}/import_delegates_list`,
      // TODO: Check these 2
      // allowedFileType: ['xls', 'xlsx'],
      // allowedMimeType: ['video/vnd.ms-excel'],
      removeAfterUpload: true,
      autoUpload: true,
      itemAlias: 'xls_template',
      authTokenHeader: 'Authorization',
      authToken: 'Bearer ' + localStorage.getItem('jwtToken'),
      headers: [],
    });
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.openProgressTracker();
    };
    this.uploader.onCompleteAll = () => {
    };
    this.uploader.onErrorItem = (item, response, status) => {
      this.activeModal.dismiss();
      const error = JSON.parse(response);
      this.modalService.error({ errors: error });
    };
  }

  private openProgressTracker() {
    window['Pusher'] = Pusher;
    window['Echo'] = new Echo({
      broadcaster: 'pusher',
      key: `${environment.mix_pusher_app_key}`,
      wsHost: `${environment.mix_pusher_host}`,
      wsPort: `${environment.mix_pusher_port}`,
      wssPort: `${environment.mix_pusher_port}`,
      forceTLS: `${environment.mix_pusher_port === 443}`,
      encrypted: true,
      disableStats: true,
      authEndpoint: `${environment.api_url}` + 'broadcasting/auth',
      auth: {headers: {'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')}},
      enabledTransports: ['ws', 'wss']
    });
    this.LaravelEcho = window['Echo'];
    this.LaravelEcho
      .channel('import.delegates_current_status.' + this.conferenceId)
      // TODO: Listen to correct event
      .listen('.import.delegates_changed_status',
        (data: any) => {
          console.log('upload status changed', data);
          // this.progress = data;
        }
      );

    this.modalService.open(this.progressTemplate);
  }

}
