import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SEOService {
  // TODO Structure ( Site Separator Page )

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  updateMeta() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) { route = route.firstChild; }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data))
          .subscribe((routeData) => {
            // if (routeData['title']) {
            //   this.titleService.setTitle(routeData['title'] + ' | Navus');
            //   this.metaService.updateTag({name: 'description', content: routeData['description']});
            // }
          });
  }

  setTitle(title) {
    this.titleService.setTitle(title);
  }

  setMeta(tag) {
    this.metaService.updateTag(tag);
  }

}
