import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConferenceService } from '@navus/core/services/conference.service';
import { Conference } from '@navus/core/classes/conference';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { Country } from '@navus/core/classes/country';
import { CountryService } from '@navus/core/services/country.service';
import { TimezoneService } from '@navus/core/services/timezone.service';

@Component({
  selector: 'main [nv-conference-details-page]',
  templateUrl: './conference-details-page.component.html'
})
export class ConferenceDetailsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  conference: Conference;
  conferenceForm: FormGroup;
  conferenceTypes = [
    { id: 'Congress', name: 'Congress' },
    { id: 'Conference', name: 'Conference' },
    { id: 'Elearning', name: 'E-learning' },
    { id: 'Meetup', name: 'Community event' },
    { id: 'FollowUp', name: 'Educational meeting' }
  ];
  allCountries: Country[] = [];
  filteredCountries: Country[] = [];
  timezones: any[] = [];
  allTimezones: any[] = [];
  filteredTimezones: any[] = [];
  conferences: Conference[] = [];
  
  conferenceSettings: any;
  timezone;

  subscriptions: Subscription[] = [];

  loadingConference: boolean = true;
  savingConference: boolean = false;
  unsavedChanges: boolean = false;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private conferenceService: ConferenceService,
    private countryService: CountryService,
    private toastService: ToastService,
    private timezoneService: TimezoneService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getCountries();
    this.getTimezones();

    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = +data.organizationId;
        this.conferenceId = +data.conferenceId;
        this.getConference();
        this.getConferences();
      }
    );
    this.subscriptions.push(subscription);

    const changeSubscription = this.conferenceForm.valueChanges.subscribe(
      () => {
        this.unsavedChanges = true;
      }
    );
    this.subscriptions.push(changeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.conferenceForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      starts_at: ['', [Validators.required]],
      ends_at: ['', [Validators.required]],
      country_id: [''],
      website: [''],
      copy_from_existing: this.formBuilder.group({
        conference_id: [null],
        copy_speakers: [true], 
        copy_companies: [true], 
        copy_program: [true],
        copy_voting: [true],
        copy_website: [true],
        copy_mobile_app: [true],
        copy_ematerials: [true],
      })
    });
    this.unsavedChanges = false;
  }

  getConference() {
    if (this.conferenceId) {
      this.loadingConference = true;
      this.conferenceService.getOrganizationConference(this.organizationId, this.conferenceId)
        .subscribe(
          (response) => {
            const conference = response.data;

            this.conference = conference;
            this.conferenceSettings = conference.settings;
            this.timezone = conference.settings.timezone?.value;

            this.conferenceForm.patchValue(conference);
            this.loadingConference = false;
            setTimeout(() => { this.unsavedChanges = false; }, 500);
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingConference = false;
          }
        );
    } else {
      this.conference = new Conference();
      this.loadingConference = false;
    }
  }

  getCountries() {
    this.countryService
      .getCountries()
      .subscribe(
        (response) => {
          this.allCountries = response.data;
          this.filteredCountries = response.data;
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }

  filterCountries(searchTerm: string) {
    if (searchTerm.length > 2) {
      this.filteredCountries = [];
      for (const country of this.allCountries) {
        if (country.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          this.filteredCountries.push(country);
        }
      }
    } else {
      this.filteredCountries = this.allCountries;
    }
  }

  getConferences() {
    const params = {
      with_pagination: false
    }
    this.conferenceService
        .getOrganizationConferences(this.organizationId, params)
        .subscribe(
          (response) => {
            this.conferences = response.data;
          },
          (error) => {
            this.modalService.error({ errors: error });
          }
        )
  }

  getTimezones() {
    this.timezoneService
        .getTimezones()
        .subscribe(
          (response: any) => {
            let timezones = response;
            this.timezones = response;

            timezones = timezones.map(t => ({
              id: t.value,
              name: t.text
            }));

            this.allTimezones = timezones;
            this.filteredTimezones = timezones;
          },
          () => { }
        );
  }

  filterTimezones(searchTerm: string) {
    if (searchTerm.length > 2) {
      this.filteredTimezones = [];
      for (const timezone of this.allTimezones) {
        if (timezone.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          this.filteredTimezones.push(timezone);
        }
      }
    } else {
      this.filteredTimezones = this.allTimezones;
    }
  }

  save() {
    if (this.conferenceForm.invalid) { return; }

    const params = {
      ...this.conferenceForm.value,
      settings: {
        ...this.conferenceSettings,
        timezone: this.timezones.find(t => t.value === this.timezone),
      }
    };

    // Fixes the error that conference_id is not found
    if (params.copy_from_existing && params.copy_from_existing.conference_id === null) {
      delete params.copy_from_existing;
    }

    this.savingConference = true;
    if (this.conferenceId) {
      this.conferenceService
        .updateOrganizationConference(this.organizationId, this.conferenceId, params)
        .subscribe(
          (response) => {
            this.conference = response['data'];
            this.toastService.success('Conference has been saved');
            this.unsavedChanges = false;
            this.savingConference = false;
            this.conferenceService.setCurrentConference(this.conferenceId, true).subscribe();
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingConference = false;
          }
        );
    } else {
      this.conferenceService
        .createOrganizationConference(this.organizationId, params)
        .subscribe(
          (response) => {
            this.conference = response['data'];
            this.toastService.success('Conference has been created');
            this.router.navigate([this.conference.id], { relativeTo: this.route });
            this.savingConference = false;
            this.conferenceService.setCurrentConference(this.conferenceId, true).subscribe();
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingConference = false;
          }
        );
    }
  }

  close() {
    if (this.unsavedChanges) {
      this.modalService.defaultModal({
        title: 'Unsaved changes',
        body: 'Are you sure you want to leave the page without saving changes?',
        size: 'small',
        buttons: [
          {
            text: 'Stay',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Leave',
            color: 'primary',
            handler: () => {
              if (this.conferenceId) {
                this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'landing']);
              } else {
                this.router.navigate(['/o', this.organizationId, 'conferences']);
              }
            }
          }
        ]
      });
    } else {
      if (this.conferenceId) {
        this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'landing']);
      } else {
        this.router.navigate(['/o', this.organizationId, 'conferences']);
      }
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Conference Deletion',
      body: 'Are you sure you want to delete this conference?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.conferenceService.deleteOrganizationConference(this.organizationId, this.conferenceId)
              .subscribe(
                (response) => {
                  this.router.navigate(['/o', this.organizationId, 'conferences']);
                },
              (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

}
