import { Routes } from '@angular/router';
import { OrganizationGuard } from '../../guards/organization.guard';
import { RecommendationsPageComponent } from './pages/recommendations/recommendations-page.component';
import { SponsorshipCampaignsPageComponent } from './pages/sponsorship-campaigns/sponsorship-campaigns-page.component';

export const AiRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'recommendations'
  },
  {
    path: 'recommendations',
    component: RecommendationsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Recommendations'
    }
  },
  {
    path: 'sponsorship-campaigns',
    component: SponsorshipCampaignsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Sponsorship Campaigns'
    }
  },
];
