import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@navus/ui/modal/modal.service';
import { Location } from '@navus/core/classes/location';
import { Presentation } from '@navus/core/classes/presentation';
import Echo from 'laravel-echo';
import { PresentationService } from '@navus/core/services/presentation.service';
import { Material } from '@navus/core/classes/material';
import { VotingQuestion } from '@navus/core/classes/voting-question';
import { UserService } from '@navus/core/services/user.service';
import { User } from '@navus/core/classes/user';
import { LocationService } from '@navus/core/services/location.service';
import { StreamChangeComponent } from '../website/modals/stream-change/stream-change.component';
import { ConferenceAccessModalComponent } from '../website/modals/conference-access/conference-access-modal.component';
import { DelegateService } from '@navus/core/services/delegate.service';
import { WebsiteService } from '@navus/core/services/website.service';
import { BlockService } from '@navus/core/services/block.service';
import { ChatService } from '@navus/core/services/chat.service';

type Tab = 'video-conference' | 'preview' | 'agenda' | 'voting' | 'qna' | 'slides' | 'recordings';

@Component({
  templateUrl: './speaker-studio.component.html',
})
export class SpeakerStudioComponent implements OnInit, OnDestroy {
  organizationId: number;
  conferenceId: number;
  myPresentations: Presentation[] = [];
  myPresentationIds: number[] = [];
  myBlockIds: number[] = [];
  myLocationIds: number[] = [];
  showProfileMenu: boolean = false;

  loadingLocation: boolean;
  location: Location;
  streams: any[] = [];
  currentStream: any;
  currentUser: User;
  currentDelegate: any;

  loadingPresentation: boolean;
  currentPresentation: Presentation;
  currentPresentationId: number;
  currentPresentationMainMaterial: Material;

  loadingVotingQuestions: boolean;
  votingQuestions: VotingQuestion[] = [];

  materialsImages: { image: string, src: string }[];
  currentPage: number = 1;
  highlights: any;
  scale: number = 1;
  
  controlAccess: boolean = true;
  hasAccess: boolean = false;

  usingTickets: boolean = false;
  usingDelegateRegistration: boolean = false;

  streamKey: string;
  streamId: string;
  streamingUrl: any;

  currentLayoutOption: 'lo-type1' | 'lo-type2' | 'lo-type3' = 'lo-type1';
  controlPanelSectionTabs: Tab[];

  echo: Echo;
  private subscriptions: Subscription[] = [];
  qnaBlocks: any[];
  qnaBlockIds: number[] = [];

  constructor(
    private modalService: ModalService,
    private websiteService: WebsiteService,
    private locationService: LocationService,
    private presentationService: PresentationService,
    private delegateService: DelegateService,
    private blockService: BlockService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private chatService: ChatService,
  ) { }

  ngOnInit() {
    const routeParamsSubscription = this.route.data
      .subscribe(
        (data) => {
          this.loadSectionTabsFromLocalStorage();

          this.conferenceId = +data.website.active_conference_id;
          const conferenceSetting = data.website.conferences.find(c => c.id === this.conferenceId).settings;
          this.usingDelegateRegistration = conferenceSetting?.delegate_registration?.enabled;
          this.usingTickets = conferenceSetting?.delegate_registration?.ticketing;

          this.currentUser = data.speaker.user;
          this.currentDelegate = data.speaker.user.delegates.find(d => d.conference_id === this.conferenceId);

          this.myPresentations = data.speaker.presentations;
          this.myPresentationIds = this.myPresentations.map(p => p.id);

          this.myLocationIds = [];
          this.myPresentations.forEach(p => {
            if (!this.myLocationIds.includes(p.block?.location_id)) {
              this.myLocationIds.push(p.block?.location_id);
            }
          });

          this.myBlockIds = [];
          this.myPresentations.forEach(p => {
            if (!this.myBlockIds.includes(p.block_id)) {
              this.myBlockIds.push(p.block_id);
            }
          });

          this.myTicketRights();

          this.initWebsocket();
          this.getCurrentlyStreaming();
        }
      );
    this.subscriptions.push(routeParamsSubscription);

    
    const queryParamsSubscription = this.route.queryParams
        .subscribe(
          (params) => {
            if (params.layout) {
              this.currentLayoutOption = params.layout; 
            }
            if (params.tabs) {
              this.controlPanelSectionTabs = params.tabs.split(','); 
            }

            this.router.navigate([], { relativeTo: this.route, queryParams: {} });
          }
        );

    const chatRoomSubscription = this.chatService
      .getAllPublicGroups(this.conferenceId)
      .subscribe(
        (rooms) => {
          this.qnaBlockIds = rooms
            .filter((r) => r.name.startsWith('block_'))
            .map((r) => +r.name.split('_')[1]);
          this.getQnABlocks();
        }
      );
    this.subscriptions.push(chatRoomSubscription);
  }

  ngOnDestroy() {
    this.echo.disconnect();
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initWebsocket() {
    this.websiteService
      .initWebsocket()
      .then(
        (echo) => {
          this.echo = echo;
          this.listenForLocationChange();
        }
      )
  }

  listenForLocationChange() {
    this.echo
      .channel(`location.current_conference_presentation.${this.conferenceId}`)
      .listen(`.location.changed_location`,
        (response: any) => {
          this.changeOnLocation(response);
        }
      );
  }

  changeOnLocation(response: any) {
    const location = response.data;
    console.log('changeOnLocation', location);

    if (location.location_id === this.currentStream.id) {

      this.currentStream.currentPresentation = location.streaming_presentations[0];
      this.currentStream.url = this.currentStream.stage_url;
      this.currentStream.offAir = location.activeLivestreamOffair;
      this.currentStream.status = location.status;
      this.myTicketRights();
    }
  }

  addSection(section: Tab) {
    if (!this.controlPanelSectionTabs.includes(section)) {
      this.controlPanelSectionTabs.push(section);
      localStorage.setItem('controlPanelSectionTabs', this.controlPanelSectionTabs.join(','));
      // if (this.controlPanelSectionTabs.length < 3) {
      //   this.controlPanelSectionTabs.push(section);
      //   localStorage.setItem('controlPanelSectionTabs', this.controlPanelSectionTabs.join(','));
      // } else {
      //   this.modalService.alert({
      //     title: 'Cannot add section',
      //     body: 'Please close one of the sections shown on the left, before adding another',
      //     buttons: [
      //       {
      //         text: 'Ok',
      //         role: 'cancel'
      //       }
      //     ],
      //     size: 'large'
      //   });
      // }
    } else {
      this.closeSection(section);
    }
  }

  closeSection(section: Tab) {
    if (this.controlPanelSectionTabs.length > 1 && this.controlPanelSectionTabs.includes(section)) {
      this.controlPanelSectionTabs.splice(this.controlPanelSectionTabs.indexOf(section), 1);
      localStorage.setItem('controlPanelSectionTabs', this.controlPanelSectionTabs.join(','));
    }
  }

  private loadSectionTabsFromLocalStorage() {
    const tabsFromLocalStorage = localStorage.getItem('controlPanelSectionTabs');
    if (tabsFromLocalStorage) {
      this.controlPanelSectionTabs = tabsFromLocalStorage.split(',') as Tab[];
    } else {
      this.controlPanelSectionTabs = ['agenda', 'qna'];
    }
  }

  loadPresentationVotingQuestions(presentationId: number) {
    this.loadingVotingQuestions = true;
    this.presentationService.getConferencePresentationVotingQuestions(this.conferenceId, presentationId)
      .subscribe(
        (response: { data: VotingQuestion[], meta: any }) => {
          this.votingQuestions = response.data;
          this.loadingVotingQuestions = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.loadingVotingQuestions = false;
        }
      );
  }

  getCurrentlyStreaming() {
    const params = {
      active_streaming: 1,
      include: 'streaming_presentations',
      with_pagination: 0
    }

    this.locationService
      .getConferenceLocations(this.conferenceId, params)
      .subscribe(
        (response) => {
          const locations = response.data;
          this.streams = locations
            .sort((a, b) => a.order - b.order)
            .filter(l => this.myLocationIds.includes(l.id))
            .map(
              location => {
                return {
                  location_id: location.id,
                  name: location.name,
                  url: location.stage_url,
                  order: location.order,
                  presentations: location.streaming_presentations,
                  currentPresentation: location.streaming_presentations[0] || null,
                  offAir: location.activeLivestreamOffair,
                  status: location.status,
                }
              }
            );

          if (this.streams.length === 0) {
            this.modalService.defaultModal({
              title: 'Not Active',
              body: 'You don\'t have active streams',
            });
            this.router.navigate(['/']);
          }

          if (!this.currentStream) {
            const today = (new Date()).toISOString().slice(0, 10);
            let stream = 
            this.streams.find(stream => 
              stream.currentPresentation && 
              stream.currentPresentation.starts_at.slice(0, 10) === today
            ) || 
            this.streams.find(stream => stream.currentPresentation) ||
            this.streams[0];
            
            this.setActiveStream(stream);
          }
        },
        () => { }
      );
  }

  setActiveStream(stream: any): void {
    if (!stream) { 
      this.currentStream = null;
    } else {
      this.currentStream = stream;
    }

    this.myTicketRights();
    this.getQnABlocks();
  }

  getQnABlocks() {
    this.qnaBlocks = [];
    const params = {
      location_id: this.currentStream?.location_id,
      with_pagination: 0
    }

    if (this.currentStream) {
      this.blockService
        .getConferenceBlocks(this.conferenceId, params)
        .subscribe(
          (response) => {
            const blocks = response.data;
            this.qnaBlocks = blocks
              .filter(b => this.qnaBlockIds.includes(b.id))
              .sort((b1, b2) => new Date(b1.starts_at).getTime() - new Date(b2.starts_at).getTime());
          },
          () => { }
        )

    }
  }

  isBlockAvailableWithMyTicket() {
    return this.currentDelegate ? 
    (this.currentStream?.currentPresentation.accessible_by_tickets.length > 0
      ? this.currentStream?.currentPresentation.accessible_by_tickets.indexOf(this.currentDelegate.conference_ticket_type_id) > -1
      : true
    ) : false;
  }

  async myTicketRights(): Promise<void> {
    if (!this.currentStream?.currentPresentation) {
      return;
    }

    this.trackAttendance(this.currentStream?.currentPresentation.id);

    if (!this.controlAccess) { 
      this.hasAccess = true;
      return; 
    }
    
    const minAccessRight = this.currentStream?.currentPresentation?.min_access_right;
    const isDelegate = !!this.currentDelegate;
    const isMember = !!this.currentUser?.member;
    const isBlockAvailableForTicket = this.isBlockAvailableWithMyTicket();

    switch(minAccessRight) {
      case 'guest':
        this.hasAccess = true;
        break;
      case 'delegate':
        if (isDelegate || isMember) {
         this.hasAccess = this.usingTickets ? isBlockAvailableForTicket : true;         
        } else {
          this.hasAccess = false;
        }
        break;
      case 'member':
        if (isMember) {
         this.hasAccess = this.usingTickets ? isBlockAvailableForTicket : true;         
        } else {
          this.hasAccess = false;
        }
        break;
    } 
  }

  trackAttendance(presentationId: number){
    if(!this.currentDelegate || !presentationId) {
      return;
    }
    this.delegateService
      .scout({ presentation_id: presentationId, party_id: this.currentDelegate.id })
      .subscribe();
  }

  requestAccess() {
    if (!this.currentUser) {
      this.promptLogin();
    } else if (!this.currentDelegate) {
      this.promptCode('delegate');
    } else {
        if (!this.currentUser.member && this.currentStream?.currentPresentation?.min_access_right === 'member') {
          this.promptCode('member');
        } else if (!this.isBlockAvailableWithMyTicket()) {
          this.promptCode('ticket');
        } else {
          this.promptRejection();
        }
    }
  }

  promptRejection() {
    this.modalService.defaultModal({
      title: 'No access',
      body: "Based on your current access rights, you don't have permission to view this content. Please contact support if you think that this information is not correct."
    });
  }

  promptLogin() {
    this.modalService.defaultModal({
      title: 'Not logged in',
      body: 'You need to be logged in to continue',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Log in',
          handler: () => {
            this.router.navigate(['/profile/login'], { queryParams: { returnUrl: this.router.url } });
          }
        },
      ]
    });
  }

  promptCode(option: string) {
    const modalRefAccess = this.modalService.open(ConferenceAccessModalComponent);
    modalRefAccess.componentInstance.conferenceId = this.conferenceId;

    //simple delegate case
    let text = `To view this session you need to be a delegate. If you are already a delegate, please enter the access/PIN code or click on the "Register" button bellow.`;
    let buttonLabel = `Register`;

    let exitStatement = false;

    switch(option) {
      case 'delegate':
        if (!this.usingDelegateRegistration) {
          modalRefAccess.componentInstance.hideButton = true;
          text = `To watch this content you need to be a delegate. Registration is closed, but if you are already a delegate or a member, please enter the access/PIN code.`;
        }
        break;
      case 'member':
        modalRefAccess.componentInstance.hideButton = true;
        text = `To watch this content you need to be a member. Please enter the access/PIN code.`;
        break;
      case 'ticket':
        if (this.usingDelegateRegistration && this.usingTickets && !this.currentDelegate.conference_ticket_type_id) {
          text = `To watch this content you need to have a valid ticket. Please enter the access/PIN code if you are already a delegate with a valid ticket or click on the "Get ticket" button bellow.`;
          buttonLabel = `Get ticket`;
        } else if (this.usingDelegateRegistration && this.usingTickets && this.currentDelegate.conference_ticket_type_id) {
          text = `To watch this content you need to upgrade your ticket. Please enter the access/PIN code or click on the "Upgrade ticket" button bellow.`;
          buttonLabel = `Upgrade ticket`;
          modalRefAccess.componentInstance.hideCode = true;
        } else {
          text = `To watch this content you need to have a valid ticket. Registration is closed, but if you are already a delegate with a valid ticket, please enter the access/PIN code or contact support.`;
          modalRefAccess.componentInstance.hideButton = true;
        }
        break;
    }

    if(exitStatement) {
      this.promptRejection();
      return;
    }

    modalRefAccess.componentInstance.text = text;
    modalRefAccess.componentInstance.buttonLabel = buttonLabel;
    modalRefAccess.result.then(
      (result) => {
        this.userService.getCurrentUser()
          .subscribe(
            (user: User) => {
              this.setActiveStream(this.currentStream);
            },
            () => { }
          );
      },
      () => { }
    );
  }

  changeStream() {
    const modalRef = this.modalService.open(StreamChangeComponent);
    modalRef.componentInstance.streams = this.streams;
    modalRef.componentInstance.currentStreamId = this.currentStream.location_id;
    modalRef.result
      .then((result) => {
        if(result) {
          this.setActiveStream(result.stream);
        }
      })
      .catch(() => {})
  }

  toggleProfileMenu() {
    this.showProfileMenu = !this.showProfileMenu;
  }

}
