import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'main [nv-placeholder-page]',
  templateUrl: './placeholder-page.component.html',
  styleUrls: ['placeholder-page.component.scss']
})
export class PlaceholderPageComponent implements OnInit {

  constructor(

  ) { }

  ngOnInit() {

  }

}
