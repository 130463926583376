import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpeakerStudioComponent } from './speaker-studio.component';
import { SpeakerStudioRoutingModule } from './speaker-studio-routing.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ReactiveFormsModule } from '@angular/forms';
import { NavusUiModule } from '@navus/ui/navus-ui.module';
import { SharedModule } from '../shared';
import { SpeakerChatContainerComponent } from './components/speaker-chat-container/speaker-chat-container.component';
import { SpeakerVotingComponent } from './components/speaker-voting/speaker-voting.component';
import { SpeakerQAComponent } from './components/speaker-q-a/speaker-q-a.component';
import { WebsiteResolver } from './resolvers/website.resolver';
import { SpeakerAgendaComponent } from './components/speaker-agenda/speaker-agenda.component';
import { SpeakerPreviewComponent } from './components/speaker-preview/speaker-preview.component';
import { SpeakerRecordingsComponent } from './components/speaker-recordings/speaker-recordings.component';
import { SpeakerSlidesComponent } from './components/speaker-slides/speaker-slides.component';
import { SpeakerVideoConferenceComponent } from './components/speaker-video-conference/speaker-video-conference.component';
import { TranslateModule } from '@ngx-translate/core';
import { AuthQueryGuard } from './guards/auth-query.guard';
import { AuthGuard } from './guards/auth.guard';
import { SpeakerResolver } from './resolvers/speaker.resolver';

@NgModule({
  providers: [
    AuthQueryGuard,
    AuthGuard,
    WebsiteResolver,
    SpeakerResolver,
  ],
  declarations: [
    SpeakerStudioComponent,
    SpeakerAgendaComponent,
    SpeakerChatContainerComponent,
    SpeakerPreviewComponent,
    SpeakerQAComponent,
    SpeakerRecordingsComponent,
    SpeakerSlidesComponent,
    SpeakerVideoConferenceComponent,
    SpeakerVotingComponent,
  ],
  imports: [
    SpeakerStudioRoutingModule,
    CommonModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    NavusUiModule,
    SharedModule,
    ClipboardModule
  ]
})
export class SpeakerStudioModule {
}
