import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'nv-view-video-modal',
  templateUrl: './view-video-modal.component.html'
})
export class ViewVideoModalComponent {
  @Input() title: string = '&nbsp';
  @Input() videoUrl: string = '';

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  close() {
    this.activeModal.dismiss();
  }

}
