import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SponsorGroup } from '@navus/core/classes/sponsor-group';
import { SponsorGroupService } from '@navus/core/services/sponsor-group.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';

@Component({
  selector: 'main [nv-single-group-details-page]',
  templateUrl: './sponsor-group-details-page.component.html',
  styles: []
})
export class SponsorGroupDetailsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  sponsorGroupId: number;
  sponsorGroup: SponsorGroup;
  sponsorGroupForm: FormGroup;

  loadingSponsorGroup: boolean = true;
  savingSponsorGroup: boolean = false;
  unsavedChanges: boolean = false;
  displayOptions = [
    { id: 'large', name: 'Large' },
    { id: 'medium', name: 'Medium' },
    { id: 'small', name: 'Small' }
  ]

  subscriptions: Subscription[] = [];

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sponsorGroupService: SponsorGroupService,
    private toastService: ToastService,
    private modalService: ModalService,
  ) { }

  ngOnInit() {
    this.initForm();

    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.sponsorGroupId = data.sponsorGroupId;
        this.getSponsorGroup();
      }
    );
    this.subscriptions.push(subscription);

    const changeSubscription = this.sponsorGroupForm.valueChanges.subscribe(
      () => {
        this.unsavedChanges = true;
      }
    );
    this.subscriptions.push(changeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.sponsorGroupForm = this.formBuilder.group({
      name: ['', Validators.required],
      color: [''],
      display: ['medium', Validators.required]
    });
    this.unsavedChanges = false;
  }

  getSponsorGroup() {
    if (this.sponsorGroupId) {
      this.loadingSponsorGroup = true;
      this.sponsorGroupService.getConferenceSponsorGroup(this.conferenceId, this.sponsorGroupId)
        .subscribe(
          (response) => {
            this.sponsorGroup = response['data'];
            this.sponsorGroupForm.patchValue(this.sponsorGroup);
            this.loadingSponsorGroup = false;
            setTimeout(() => { this.unsavedChanges = false; }, 500);
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingSponsorGroup = false;
          }
        );
    } else {
      this.sponsorGroup = new SponsorGroup();
      this.loadingSponsorGroup = false;
    }
  }

  save() {
    if (this.sponsorGroupForm.invalid) { return; }

    this.savingSponsorGroup = true;
    if (this.sponsorGroupId) {
      this.sponsorGroupService.updateConferenceSponsorGroup(this.conferenceId, this.sponsorGroupId, this.sponsorGroupForm.value)
        .subscribe(
          (response) => {
            this.sponsorGroup = response['data'];
            this.toastService.success('Sponsor group successfully saved.', {});
            this.unsavedChanges = false;
            this.savingSponsorGroup = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingSponsorGroup = false;
          }
        );
    } else {
      this.sponsorGroupService.createConferenceSponsorGroup(this.conferenceId, this.sponsorGroupForm.value)
        .subscribe(
          (response) => {
            this.sponsorGroup = response['data'];
            this.toastService.success('Sponsor group successfully created.', {});
            this.router.navigate([this.sponsorGroup.id], { relativeTo: this.route });
            this.savingSponsorGroup = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingSponsorGroup = false;
          }
        );
    }
  }

  close() {
    if (this.unsavedChanges) {
      this.modalService.defaultModal({
        title: 'Unsaved changes',
        body: 'Are you sure you want to leave the page without saving changes?',
        size: 'small',
        buttons: [
          {
            text: 'Stay',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Leave',
            color: 'primary',
            handler: () => {
              this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'sponsors', 'sponsor-groups']);
            }
          }
        ]
      });
    } else {
      this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'sponsors', 'sponsor-groups']);
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Sponsor Group Deletion',
      body: 'Are you sure you want to delete this sponsor group?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.sponsorGroupService.deleteConferenceSponsorGroup(this.conferenceId, this.sponsorGroupId)
              .subscribe(
                () => {
                  this.close();
                },
                (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

}
