<ng-container>

    <div class="nv-global-loading on" [class.on]="loadingAttendance">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->

    <div class="nv-attendee-stats-shown">
        <h4 class="medium">On-demand Attendance per session</h4>
    </div>

    <div class="nv-attendee-stats-block">
        <ng-container>
            <div class="nv-attendee-chart-vertical" *ngIf="sessionData?.length; else noSessionData">
                <ngx-charts-bar-vertical
                        [view]="sessionData?.length > 6? null : [sessionData.length * 70 + 100, 250]"
                        [scheme]="chartColorScheme"
                        [results]="sessionData"
                        [xAxis]="sessionData?.length < 5"
                        [yAxis]="true"
                        [legend]="false"
                        [showXAxisLabel]="false"
                        [showYAxisLabel]="true"
                        maxXAxisTickLength=16
                        [yAxisLabel]="'Time spent (seconds)'"
                        [activeEntries]="sessionActiveEntries"
                        (activate)="sessionLegendLabelActivate($event)"
                        (deactivate)="sessionLegendLabelDeactivate($event)"
                        (select)="onSelectSession($event)">
                    <ng-template #tooltipTemplate let-model="model">
                        <ng-container *ngTemplateOutlet="templateForTooltip; context: {model: model}"></ng-container>
                    </ng-template>
                </ngx-charts-bar-vertical>
            </div><!-- /.nv-attendee-chart-vertical -->

            <div class="nv-attendee-chart-legend">
                <nv-custom-chart-legend
                        [title]="'Sessions'"
                        [legendItems]="sessionLegend"
                        [activeEntries]="sessionActiveEntries"
                        [sessionsManipulation]="true"
                        [presentationsManipulation]="true"
                        [materialsManipulation]="true"
                        [deleteItem]="false"
                        [availableSessions]="availableSessions"
                        [availablePresentations]="availablePresentations"
                        [availableMaterials]="availableMaterials"
                        (sessionSelected)="onSessionSelectedInLegend($event)"
                        [selectedPresentation]="selectedPresentationInLegend"
                        (presentationSelected)="onPresentationSelectedInLegend($event)"
                        [selectedMaterial]="selectedMaterialInLegend"
                        (addTime)="addTime($event)"
                        [chartData]="sessionData"
                        (selectItem)="onSelectSession($event)"
                        (activateLabel)="sessionLegendLabelActivate($event)"
                        (deactivateLabel)="sessionLegendLabelDeactivate($event)"
                ></nv-custom-chart-legend>
            </div><!-- /nv-attendee-chart-legend -->
        </ng-container>
        <ng-template #noSessionData>
            No On-demand attendance has been recorded.
        </ng-template>
    </div><!-- /.nv-attendee-stats-block -->

    <ng-container *ngIf="presentationData?.length">
        <div class="nv-attendee-stats-shown">
            <h5 class="light" id="on-demand-presentation-chart">On-demand Attendance per presentation</h5>
            <div class="nv-attendee-stats-items">
                <span>Session: {{ selectedSession?.name }}</span>
            </div><!-- /.nv-attendee-stats-items -->
        </div>

        <div class="nv-attendee-stats-block">
            <div class="nv-attendee-chart-vertical">
                <ngx-charts-bar-vertical
                        [view]="presentationData?.length > 6? null : [presentationData.length * 70 + 100, 250]"
                        [scheme]="chartColorScheme"
                        [activeEntries]="presentationActiveEntries"
                        [results]="presentationData"
                        [xAxis]="presentationData?.length < 5"
                        [yAxis]="true"
                        [legend]="false"
                        [showXAxisLabel]="false"
                        [showYAxisLabel]="true"
                        [trimXAxisTicks]="true"
                        [rotateXAxisTicks]="true"
                        maxXAxisTickLength=16
                        [yAxisLabel]="'Time spent (seconds)'"
                        (select)="onSelectPresentation($event)"
                        (activate)="presentationLegendLabelActivate($event)"
                        (deactivate)="presentationLegendLabelDeactivate($event)">
                    <ng-template #tooltipTemplate let-model="model">
                        <ng-container *ngTemplateOutlet="templateForTooltip; context: {model: model}"></ng-container>
                    </ng-template>
                </ngx-charts-bar-vertical>
            </div><!-- /.nv-attendee-chart-vertical -->

            <div class="nv-attendee-chart-legend">
                <nv-custom-chart-legend
                        [title]="'Presentations'"
                        [legendItems]="presentationLegend"
                        [activeEntries]="presentationActiveEntries"
                        [chartData]="presentationData"
                        [deleteItem]="false"
                        (activateLabel)="presentationLegendLabelActivate($event)"
                        (deactivateLabel)="presentationLegendLabelDeactivate($event)"
                ></nv-custom-chart-legend>
            </div><!-- /.nv-attendee-chart-legend -->
        </div><!-- /.nv-attendee-stats-block -->

    </ng-container>

    <ng-container *ngIf="materialData?.length">
        <div class="nv-attendee-stats-shown">
            <h5 class="light" id="on-demand-material-chart">On-demand Attendance per material</h5>
            <div class="nv-attendee-stats-items">
                <span>Presentation: {{ selectedPresentation?.title }}</span>
            </div><!-- /.nv-attendee-stats-items -->
        </div>

        <div class="nv-attendee-stats-block">
            <div class="nv-attendee-chart-vertical nv-attendee-chart-no-hover">
                <ngx-charts-bar-vertical
                        [view]="materialData?.length > 10? null : [materialData.length * 70 + 100, 250]"
                        [scheme]="chartColorScheme"
                        [activeEntries]="materialActiveEntries"
                        [results]="materialData"
                        [xAxis]="materialData?.length < 5"
                        [yAxis]="true"
                        [legend]="false"
                        [showXAxisLabel]="false"
                        [showYAxisLabel]="true"
                        [trimXAxisTicks]="true"
                        [rotateXAxisTicks]="true"
                        maxXAxisTickLength=16
                        [yAxisLabel]="'Time spent (seconds)'"
                        (activate)="materialLegendLabelActivate($event)"
                        (deactivate)="materialLegendLabelDeactivate($event)">
                    <ng-template #tooltipTemplate let-model="model">
                        <ng-container *ngTemplateOutlet="templateForTooltip; context: {model: model}"></ng-container>
                    </ng-template>
                </ngx-charts-bar-vertical>
            </div><!-- /.nv-attendee-chart-vertical -->

            <div class="nv-attendee-chart-legend">
                <nv-custom-chart-legend
                        [title]="'Materials'"
                        [legendItems]="materialLegend"
                        [activeEntries]="materialActiveEntries"
                        [chartData]="materialData"
                        (removeItem)="removeMaterialAttendance($event)"
                        (activateLabel)="materialLegendLabelActivate($event)"
                        (deactivateLabel)="materialLegendLabelDeactivate($event)"
                ></nv-custom-chart-legend>
            </div><!-- /.nv-attendee-chart-legend -->
        </div><!-- /.nv-attendee-stats-block -->
    </ng-container>

</ng-container>
