import { Routes } from '@angular/router';
import { OrganizationGuard } from '../../guards/organization.guard';
import { AdLandingPageComponent } from './pages/ad-landing/ad-landing-page.component';
import { BannerManagementPageComponent } from './pages/banner-management/banner-management-page.component';
import { IntegrationsPageComponent } from './pages/integrations/integrations-page.component';

export const AdRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: AdLandingPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'AD'
    }
  },
  {
    path: 'banner-management',
    component: BannerManagementPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Banner Management'
    }
  },
  {
    path: 'integrations',
    component: IntegrationsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Integrations'
    }
  }
];
