import { WebsitePageSection } from './website-page-section';

export class WebsitePage {
  id: number;
  parent_id: number;
  order: number;
  title: string;
  slug: string;
  type: string;
  access: string;
  external: string;
  config: any;
  content: string;
  visible: boolean;
  landing: boolean;
  show_footer: boolean;

  sections?: WebsitePageSection[];
  pages?: WebsitePage[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  public resolveUrl(): string {
    switch (this.type) {
      case 'external':
        return this.config;
      case 'e-materials':
        return `/e-materials`;
      case 'search':
        return `/search`;
      case 'posters':
        return `/posters`;
      case 'program':
        return `/program`;
      case 'sponsors':
        return `/sponsors`;
      case 'speakers':
        return `/speakers`;
      case 'delegates':
        return `/delegates`;
      case 'live-streaming':
        return `/live-streaming`;
      case 'live-stage':
        return `/live-stage`;
      case 'e-playlist':
        return `/e-playlist`;
      default:
        return `/${this.id}/${this.type}/${this.slug}`;
    }
  }
}
