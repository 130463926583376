<section class="nv-section guttered nv-register-form full-screen">
    <div class="nv-port">
        <div class="nv-row small-spaced v-middle flow-center">
            <div class="nv-column" [ngClass]="{'desk-6': !isConfig, 'tab-8': !isConfig, 'phab-12': !isConfig, 'desk-9': isConfig, 'tab-12': isConfig}">
                <div class="nv-column-content">
                    <div class="nv-onboarding-dual-pane">
                        <div class="odp-pane-left" *ngIf="isConfig">
                            <div class="nv-selected-pckg-meta" *ngIf="package">
                                <h3>{{ package.name }}</h3>
                                <h5>Selected Plan</h5>
                                <ul class="nv-banal">
                                    <li>{{ package.max_delegates }} delegates</li>
                                    <li>{{ package.max_events }} events</li>
                                </ul>
                                <h4>TOP FEATURES</h4>
                                <ul class="nv-bulleted secondary">
                                    <li *ngFor="let feature of package.features.en">{{ feature }}</li>
                                </ul>
                            </div><!-- /.nv-selected-pckg-meta -->
                            <div class="nv-selected-pckg-meta" *ngIf="!package">
                                
                                <h3>Basic</h3>
                                <ul class="nv-banal">
                                    <li>300 delegates</li>
                                    <li>3 conferences</li>
                                </ul>
                                <h4>TOP FEATURES</h4>
                                <ul class="nv-bulleted secondary">
                                    <li>Go virtual, live or hybrid</li>
                                    <li>Live Streaming, Video Calls</li>
                                    <li>Networking</li>
                                    <li>Mobile App</li>
                                    <li>Materials Hub</li>
                                    <li>Your own Configurator</li>
                                    <li>Personal Support</li>
                                </ul>
                            </div><!-- /.nv-selected-pckg-meta -->
                            <!--<figure> -->
                                <!--<img src="../../assets/gfx-selected-pckg.png" alt="Navus Selected Plan"> -->
                            <!--</figure> -->
                        </div><!-- /.odp-pane-left -->
                        <form [formGroup]="authForm" [class.odp-pane-right]="isConfig" [class.nv-login-form]="!isConfig">
                            <ng-container *ngIf="isConfig">
                                <h3>Sign up for free<br>without a credit card</h3>
                            </ng-container>
                            <ng-container *ngIf="!isConfig">
                                <h3 *ngIf="mode === 'login'">Sign in</h3>
                                <h3 *ngIf="mode === 'registration'">Create your Navus Account to use all in one event platform</h3>
                            </ng-container>

                            <div class="nv-row semi-spaced-top gap-24 propagate">
                                <div class="nv-column desk-12" *ngIf="!accessCodeRequested">
                                    <div class="nv-column-content">
                                        <nv-input formControlName="email"
                                                  label="Email"
                                                  (keyup.enter)="keyEvent($event)"
                                                  [errorMessages]="{ required:'Email is required' | translate, pattern: 'Please enter a valid email address' | translate }"
                                        ></nv-input>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12" *ngIf="mode === 'registration'">
                                    <div class="nv-column-content">
                                        <nv-select formControlName="country_id"
                                                   label="{{'Country' | translate}}"
                                                   [required]="true"
                                                   [options]="filteredCountries"
                                                   [showSearch]="true"
                                                   (searched)="filterCountries($event)"
                                        ></nv-select>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-6 tab-12" *ngIf="mode === 'registration'">
                                    <div class="nv-column-content">
                                        <nv-input formControlName="first_name"
                                                  label="{{'First name' | translate}}"
                                                  (keyup.enter)="keyEvent($event)"
                                                  [errorMessages]="{ required: 'First name is required' | translate, pattern: 'First name cannot be longer that 30 characters' | translate }"
                                        ></nv-input>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-6 tab-12" *ngIf="mode === 'registration'">
                                    <div class="nv-column-content">
                                        <nv-input formControlName="last_name"
                                                  label="{{'Last name' | translate}}"
                                                  (keyup.enter)="keyEvent($event)"
                                                  [errorMessages]="{ required: 'Last name is required' | translate, pattern: 'Last name cannot be longer that 30 characters' | translate}"
                                        ></nv-input>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12" *ngIf="mode === 'registration'">
                                    <div class="nv-column-content">
                                        <nv-input formControlName="institution"
                                                  label="{{'Institution / Company / Society' | translate}}"
                                                  (keyup.enter)="keyEvent($event)"
                                                  [errorMessages]="{ pattern: 'Institution cannot be longer that 30 characters' | translate }"
                                        ></nv-input>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12" *ngIf="!accessCodeRequested">
                                    <div class="nv-column-content">
                                        <div class="nv-form-item nv-password-item">
                                            <nv-input type="password"
                                                      formControlName="password"
                                                      label="{{'Password' | translate}}"
                                                      (keyup.enter)="keyEvent($event)"
                                                      [showPassword]="true"
                                                      [errorMessages]="{ required: 'Password is required' | translate, pattern: 'Password is not valid' | translate }"
                                            ></nv-input>
                                            <div class="nv-access-code-request" (click)="forgotPassword()" *ngIf="mode === 'login' && !requestingAccessCode" translate>Request temporary access code</div>
                                        </div><!-- /nv-form-item -->
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12" *ngIf="accessCodeRequested">
                                    <div class="nv-column-content">
                                        <div class="nv-form-item nv-password-item">
                                            <nv-input
                                                      formControlName="access_code"
                                                      label="{{'Access Code' | translate}}"
                                                      (keyup.enter)="keyEvent($event)"
                                            ></nv-input>
                                            <div class="nv-access-code-request" (click)="accessCodeRequested = false" translate>Back to login</div>
                                        </div><!-- /nv-form-item -->
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12" *ngIf="mode === 'registration'">
                                    <div class="nv-column-content">
                                        <div class="nv-checkbox-item">
                                            <label>
                                                    <span class="nci-check-port">
                                                        <input type="checkbox" formControlName="terms_and_conditions">
                                                        <span class="nci-check-visual"></span>
                                                    </span><!-- /.nci-check-port -->
                                                <span class="nci-value">I have read and accepted <a href="/assets/legal/Navus_Platform_GTCs_Current.pdf" target="_blank">the terms and conditions</a></span>
                                            </label>
                                        </div><!-- /nv-checkbox-item -->
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <nv-button color="secondary" size="large" *ngIf="mode === 'login'"
                                                   class="fw radius-high" [loading]="loading || requestingAccessCode" (click)="login()">
                                            Sign In
                                        </nv-button><!-- /.nv-btn -->
                                        <nv-button color="secondary" size="large" *ngIf="mode === 'registration'"
                                                   class="fw radius-high" [loading]="loading" (click)="register()">
                                            Sign Up
                                        </nv-button><!-- /.nv-btn -->
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                            </div><!-- /.nv-row -->
                            <p style="text-align: center" *ngIf="mode === 'registration'">or <a (click)="mode = 'login'">sign in</a> with your existing Navus account</p>
                            <p style="text-align: center" *ngIf="mode === 'login'">or <a (click)="mode = 'registration'">create</a> your Navus account</p>
                        </form><!-- /.odp-pane-right -->
                    </div><!-- /.nv-onboarding-dual-pane -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</section><!-- /nv-section -->
