import { Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'nv-code-editor',
  templateUrl: 'code-editor.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NavusCodeEditorComponent),
    multi: true
  }]
})

export class NavusCodeEditorComponent implements OnInit, ControlValueAccessor {
  @Input() value: string = null;
  @Input() disabled: boolean = false;
  @Input() formControlName: string;
  @Input() label: string;
  @Input() required: boolean = false;
  @Input() errorMessages: any = {};
  @Input() mode: string;

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  data: string;

  codeEditor: boolean = false;
  codeEditorOptions: any = {
    lineNumbers: true,
    mode: 'htmlmixed'
  };

  errors: any[] = [];
  showErrors: boolean;

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer
  ) { }

  ngOnInit() {
    if (this.required !== false) {
      this.required = true;
    }
    if (this.disabled !== false) {
      this.disabled = true;
    }
    if (!this.label) {
      this.label = '';
    }
    if (this.mode) {
      this.codeEditorOptions.mode = this.mode;
    }
  }

  writeValue(value: any): void {
    this.value = value ? value : '';
    if (this.value === '') {
      this.errors = [];
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  displayError() {
    if (!this.formControlName) {
      return;
    }

    const formControl = this.controlContainer.control.get(this.formControlName);
    if (!formControl.touched) {
      return;
    }

    if (formControl.errors) {
      this.required = formControl.errors.required || false;
      const errors = [];

      for (const [key, value] of Object.entries(formControl.errors)) {
        errors.push(key);
      }

      this.errors = errors;
      if (this.value) {
        formControl.updateValueAndValidity();
        this.errors.splice(this.errors.indexOf('required'), 1);
      }
    } else {
      this.errors = [];
    }
  }

  onChangeValue(newValue: any) {
    this.onChange(newValue);
    this.displayError();
  }

  toggleErrors() {
    this.showErrors = !this.showErrors;
  }
}

