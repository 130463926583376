import { NgModule } from '@angular/core';
import { NavusCoreModule } from '@navus/core/navus-core.module';
import { NavusUiModule } from '@navus/ui/navus-ui.module';
import { SharedModule } from '../../../shared';
import { NotificationListPageComponent } from './pages/notification-list/notification-list-page.component';
import { NotificationDetailsPageComponent } from './pages/notification-details/notification-details-page.component';
import { QuestionListPageComponent } from './pages/question-list/question-list-page.component';
import { TemplateListPageComponent } from './pages/template-list/template-list-page.component';
import { TemplateDetailsPageComponent } from './pages/template-details/template-details-page.component';
import { NotificationSettingsPageComponent } from './pages/notification-settings/notification-settings-page.component';

@NgModule({
  imports: [
    NavusCoreModule.forRoot(),
    NavusUiModule,
    SharedModule
  ],
  declarations: [
    NotificationListPageComponent,
    NotificationDetailsPageComponent,
    NotificationSettingsPageComponent,
    QuestionListPageComponent,
    TemplateListPageComponent,
    TemplateDetailsPageComponent
  ],
  entryComponents: [],
  providers: []
})
export class CommunicationModule {
}
