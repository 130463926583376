import { Component, EventEmitter, forwardRef, Host, Input, OnInit, Optional, Output, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'nv-switch',
  templateUrl: './switch.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NavusSwitchComponent),
      multi: true
    }
  ]
})
export class NavusSwitchComponent implements OnInit, ControlValueAccessor {

  @Input() formControlName: string;
  @Input() disabled: boolean = false;
  @Input() checked: boolean = false;
  @Input() value: boolean = false;
  @Input() name: string = null;
  @Input() label: string = null;

  @Output() change = new EventEmitter();

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer
  ) { }

  ngOnInit() {
    if (this.value !== false) { this.value = true; }
    if (this.disabled !== false) { this.disabled = true; }
    if (this.checked !== false) { this.checked = true; }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onChangeStatus(event) {
    this.value = event.target.checked;
    if (this.formControlName) {
      this.controlContainer.control.get(this.formControlName).setValue(event.target.checked);
    }
    this.change.emit(event);
  }

}
