import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import "media-chrome";
import { environment } from "../../../environments/environment";
import Hls from "hls.js";
import mux from "mux-embed";
import { DomSanitizer } from "@angular/platform-browser";
import { VideoService } from "./services/video.service";
@Component({
  selector: "nv-video",
  templateUrl: "./video.component.html"
})
export class NavusVideoComponent implements OnInit, AfterViewInit {
  @Input()
  set src(val) {
    if(val.includes('mux.com')) {this.type = 'mux'}
    else if(val.includes('gigtv.com.au')) {this.type = 'mediasite'}
    else if(val.includes('.m3u8')) {this.type = 'mux'}
    else {this.type = 'iframe'} 
    this.init(val);
  }
  @Input() conferenceId: number;
  @Input() presentationId: string;
  @Input() materialId: string;
  @Input() userId: string;
  @Input() title: string;

  @Input() config: {
    autoPlay: boolean;
    mute: boolean;
    loop: boolean;
    nav: string;
    posterTime: string;
    startTime: number;
  } = {
    autoPlay: false,
    mute: false,
    loop: false,
    nav: null,
    posterTime: null,
    startTime: null,
  };

  hlsConfig = {
    autoStartLoad: false,
    startPosition: -1,
    liveSyncDuration: 8
  };
  hls = new Hls(this.hlsConfig);

  _src: any;
  type: string;
  videoId: string;
  navigation: any;

  error = null;

  envKey: string = environment.muxEnvKey;
  constructor(
    private sanitizer: DomSanitizer,
    private videoService: VideoService
  ) {}


  ngOnInit() { 
  }

  ngAfterViewInit() { }

  setEventListener() {
    const videoClickHandler = (e) => {
      if (e.target.paused) {
        e.target.play();
        this.syncLiveStreamPosition();
      } else {
        e.target.pause();
      }
    }
    const playButtonHandler = () => {
      const video: any = document.getElementById("video");
      if (video.paused) {
        this.hideThumbnailPlayButton();
        video.play();
        this.syncLiveStreamPosition();
      }
    }
    document.getElementById("video").removeEventListener("click", videoClickHandler);
    document.getElementById("nv-video-thumbnail__play-button").removeEventListener("click", playButtonHandler);
    // media-chrome@0.4 bug fix
    document.getElementById("video").addEventListener("click", videoClickHandler);
    document.getElementById("nv-video-thumbnail__play-button").addEventListener("click", playButtonHandler);
  }

  init(val) {
    // DEV URL
    // const url_string = "https://player.navus.io/vP9BgmMNlXNinsF2HM1Doi6nShSwTKjllVJ7YCW7yCk?autoplay=0&mute=0&loop=0&nav=1110011&pt=20&t=30";
    // const url_string = "https://stream.mux.com/vP9BgmMNlXNinsF2HM1Doi6nShSwTKjllVJ7YCW7yCk.m3u8?autoplay=1&mute=1&loop=0&nav=1110011111";
    // const url_string = "https://webcast.gigtv.com.au/Mediasite/Play/ce0ee0f099d2453084ae9a5871c224331d?autoStart=true";
    if(this.type === 'mux') {
      this._src = val;
      setTimeout(() => {
        const muxVideo: any = document.getElementById("video");
        muxVideo.pause();
        document.getElementById("nv-video-thumbnail__play-button").classList.remove("show");

        this.setEventListener();
        setTimeout(() => {
            this.getQueryParamsFromUrl();

            if (muxVideo.canPlayType("application/vnd.apple.mpegurl")) {
              // Some browers (safari and ie edge) support HLS natively
              muxVideo.src = this._src;
              this.initPlayer();
            } else if (Hls.isSupported()) {
              this.initPlayer();
            } else {
              console.error("This is a legacy browser that doesn't support MSE");
            }
        
            this.setNavigation();
        
            if ((this.navigation.autoplay && this.navigation.autoplay == "1") || this.config.autoPlay) {
              this.autoPlay();
            }
            if (
              (this.navigation.loop && this.navigation.loop == "1") || this.config.loop) {
              this.loopVideo();
            }
            if (
              (this.navigation.mute && this.navigation.mute == "1") || this.config.mute) {
              this.muteVideo();
            }
        }, 300)
      }, 300)
    }else if(this.type === 'mediasite') {
      console.log(this.conferenceId);
      const videoIdIndex = val.indexOf('/Play/') + 6;
      const videoId = val.substr(videoIdIndex, 34);
      this.videoService
        .getMediasiteTicket(videoId)
        .subscribe(
          (ticket) => {
            // const src = val.includes('?') ? `${val}&authTicket=${ticket}` : `${val}?authTicket=${ticket}`;
            const src = `${val}${val.includes('?') ? '&' : '?'}authTicket=${ticket}`;
            this._src = this.sanitizer.bypassSecurityTrustResourceUrl(src);
          },
          (error) => {
            this._src = this.sanitizer.bypassSecurityTrustResourceUrl(val);
          }
        )    
    }else {
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(val);
    }
  }

  getQueryParamsFromUrl() {
    const url = new URL(this._src);
    const queryParamsString = url.search.substring(1);
    const queryParams = queryParamsString.split("&").reduce((prev, curr) => {
      if (!curr) {
        return {};
      }
      const p = curr.split("=");
      prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
      return prev;
    }, {});
    this.videoId = url.pathname.substring(1).split(".")[0];
    this.navigation = queryParams;
  }

  initPlayer() {
    const muxVideo: any = document.getElementById("video");

    // RESET
    this.error = null;
    this.hls = new Hls(this.hlsConfig);

    this.hls.attachMedia(muxVideo);
    this.hls.on(Hls.Events.MEDIA_ATTACHED, (event, data) => {
      this.hls.loadSource(this._src);
      // TODO Test
      if (typeof mux !== "undefined") {
        mux.monitor(muxVideo, {
          debug: false,
          hlsjs: this.hls,
          Hls: Hls,
          data: {
            env_key: this.envKey,
            viewer_user_id: this.userId,
            video_title: this.title,
            video_id: this.materialId,
            custom_1: this.presentationId,
            custom_2: this.conferenceId,
          },
        });
      }
    });

    this.hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
      if ((!this.navigation.autoplay || this.navigation.autoplay == "0") && !this.config.autoPlay ) {
        this.showThumbnailPlayButton();
      }
    });
    this.hls.on(Hls.Events.MANIFEST_LOADED, (event, data) => {
      document.getElementById('nv-video-error__wrapper').innerHTML = '';
    });

    this.hls.on(Hls.Events.ERROR, (event, data) => {
      switch (data.details) {
        case Hls.ErrorDetails.MANIFEST_LOAD_ERROR:
          // Ping every 5s
          setTimeout(() => { this.hls.loadSource(this._src); }, 5000);
          break;
        default:
          break;
      }

      // On HLS latest
      if (data.error) {
        this.error = "Not found";
        this.createErrorElement();
        return;
      }
      if (data.networkDetails?.response) {
        if (data.networkDetails.response[0] == "{") {
          this.error = JSON.parse(
            data.networkDetails.response
          ).error.messages[0];
        } else {
          this.error = data.networkDetails.response;
        }
      }
      this.createErrorElement();
    });
  }

  autoPlay() {
    this.hls.config.autoStartLoad = true;
    if (this.navigation.t || this.config.startTime) {
      if (this.config.startTime) {
        this.hls.config.startPosition = +this.config.startTime;
      } else {
        this.hls.config.startPosition = +this.navigation.t;
      }
    }
    setTimeout(() => {
      const video = document.getElementById("video") as HTMLVideoElement;
      video.muted = true;
      video.play();
      document.getElementById("mute-desktop").classList.add("show");
    }, 300);
  }

  muteVideo() {
    setTimeout(() => {
      const video = document.getElementById("video") as HTMLVideoElement;
      video.muted = true;
    }, 300);
  }

  loopVideo() {
    setTimeout(() => {
      const video = document.getElementById("video") as HTMLVideoElement;
      video.loop = true;
    }, 300);
  }

  setNavigation() {
    const navElementsCount = 10;
    let navArray = [];
    const navigation = {
      show: 1, // whether it should show the playbar or not
      seek: 0, // allow seeking through the video
      volume: 1, // show or hide volume control
      quality: 0, // show icon for switch the quality of source
      cc: 0, // show the icon for displaying subtitles
      full_screen: 1, // show icon for going to full screen
      pip: 1, // show icon for going to Picture-in-picture mode
      time_range: 1, // show time range
      time: 1, // show time
      duration: 1, // show duration
    };

    if (this.config.nav) {
        navArray = this.config.nav.split("");
      } else if (this.navigation.nav) {
        navArray = this.navigation.nav.split("");
      } else {
        navArray = Object.values(navigation);
      }

    const navArrayLength = navArray.length;
    if (navArray.length < navElementsCount) {
      for (let newElement = 0; newElement < navElementsCount - navArrayLength; newElement++) {
        navArray.push("0");
      }
    }

    const navKeys = Object.keys(navigation);
    navKeys.forEach((key, index) => {
      this.hideControl(navArray[index], key + "-control");
    });
  }

  hideControl(param, name) {
    if (param == "0") {
      const element: any = document.getElementsByClassName(name);
      if (name === "duration-control") {
        const timeControl: any =
          document.getElementsByClassName("time-control");
        if (timeControl) {
          for (let item of timeControl) {
            item.removeAttribute("show-duration");
          }
        }
      } else if (name === "time_range-control") {
        if (element) {
          for (let item of element) {
            item.classList.add("hide-time_range");
          }
        }
      } else {
        if (element) {
          for (let item of element) {
            item.classList.add("hide");
          }
        }
      }
    }
  }

  toggleMute() {
    document.getElementById("mute-desktop").classList.remove("show");
  }

  createErrorElement() {
    const errorWrapper = document.getElementById("nv-video-error__wrapper");
    const errorElement = document.createElement("div");
    const errorMessage = document.createElement("div");

    if(errorWrapper.children && errorWrapper.children.length > 0) {
      errorWrapper.children[0].remove();
    }
    
    errorElement.classList.add("error");
    errorMessage.classList.add("message");
    errorMessage.textContent = this.error;
    errorElement.appendChild(errorMessage);
    errorWrapper.appendChild(errorElement);
  }

  showThumbnailPlayButton() {
    let posterTime = "0";
    let startTime = 0;
    if (this.navigation.pt || this.config.posterTime) {
      if (this.config.posterTime) {
        posterTime = this.config.posterTime;
      } else {
        posterTime = this.navigation.pt;
      }
    } else {
      if (this.navigation.t || this.config.startTime) {
        if (this.config.startTime) {
          startTime = +this.config.startTime;
        } else {
          startTime = +this.navigation.t;
        }
      }
      this.hls.startLoad(startTime);
    }
    document.getElementById("nv-video-thumbnail__play-button").classList.add("show");
    document.getElementById("video").setAttribute("poster", "https://image.mux.com/" + this.videoId + "/thumbnail.png?width=720&height=480&fit_mode=preserve&time=" + posterTime);
  }

  hideThumbnailPlayButton() {
    document.getElementById("nv-video-thumbnail__play-button").classList.remove("show");
    let startTime = 0;
    if (this.navigation.t || this.config.startTime) {
      if (this.config.startTime) {
        startTime = +this.config.startTime;
      } else {
        startTime = +this.navigation.t;
      }
    }
    this.hls.startLoad(startTime);
  }

  onRightClick(event) {
    event.preventDefault();
  }

  syncLiveStreamPosition() {
    const video: any = document.getElementById("video");
    if(this.hls.liveSyncPosition && !video.paused) {
      const video: any = document.getElementById("video");
      video.currentTime = this.hls.liveSyncPosition;
    }
  }
}
