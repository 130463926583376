<div class="nv-page-wrap">
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row spaced-top small-spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h1>Tracking Test Page</h1>
                        <p>uuid{{uuid ? ': ' + uuid : ' will be generated on the first action or on the identification of the user.'}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row spaced-top gap-24 small-spaced-bottom">
                <div class="nv-column desk-6">
                    <form class="nv-column-content" [formGroup]="configForm">
                        <div class="nv-row spaced-top small-spaced-bottom">
                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <nv-input formControlName="entity_type" label="Entity type"></nv-input>
                                </div>
                            </div>
                        </div>
                        <div class="nv-row spaced-top small-spaced-bottom">
                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <nv-input formControlName="entity_id" label="Entity id"></nv-input>
                                </div>
                            </div>
                        </div>
                        <div class="nv-row spaced-top small-spaced-bottom">
                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <nv-select formControlName="action_name" label="Action name" [options]="actionNameOptions"></nv-select>
                                </div>
                            </div>
                        </div>
                        <div class="nv-row spaced-top small-spaced-bottom">
                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <nv-select formControlName="action_type" label="Action type" [options]="actionTypeOptions"></nv-select>
                                </div>
                            </div>
                        </div>
                        <div class="nv-row spaced-top small-spaced-bottom">
                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <nv-input formControlName="conference_id" label="Conference id"></nv-input>
                                </div>
                            </div>
                        </div>
                        <div class="nv-row spaced-top small-spaced-bottom">
                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <nv-input formControlName="additional_info" label="Additional info (JSON)" [type]="'textarea'"></nv-input>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <div class="nv-row spaced-top small-spaced-bottom">
                            <div class="nv-column desk-12">
                                <div class="nv-column-content" style="text-align: right;">
                                    <nv-input label="User id" [(ngModel)]="userId"></nv-input>
                                    <br>
                                    <nv-button (click)="identify()" [disabled]="!userId">Identify</nv-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="nv-row spaced-top small-spaced-bottom">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <nv-button (click)="submit()">Submit</nv-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>