<div class="nv-page-wrap">
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row spaced-top small-spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-wide-msg-bar">
                            <p>Display presentations within sessions</p>
                            <label class="nv-toggle-switch">
                                <input type="checkbox" name="">
                                <span class="ts-visual"></span>
                            </label><!-- /.nv-toggle-switch -->
                        </div><!-- /.nv-wide-msg-bar -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </div><!-- /.nv-section -->

    <section class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row spaced-top small-spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-planner-title">
                            <h1>Schedule - day one (Friday, May 24)</h1>
                            <div class="pt-side">
                                <label class="nv-toggle-switch star">
                                    <input type="checkbox" name="">
                                    <span class="ts-visual"></span>
                                </label><!-- /.nv-toggle-switch -->
                            </div><!-- /.pt-side -->
                        </div><!-- /.nv-planner-title -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->

    <main class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row spaced no-side-gap">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-planner-item">
                            <header class="nv-planner-header">
                                <div class="ph-time">
                                    8:00<span>AM</span>
                                </div><!-- /.pt-time -->
                                <div class="ph-body">
                                    <h3><a href="#">[Session] Anatomy and Pathology</a></h3>
                                    <div class="ph-meta">
                                        <span class="phm-time-frame">8:00 - 8:30 AM</span>
                                        <span class="phm-location">Hörsaal 1</span>
                                        <span class="phm-cat"><em style="background-color: cyan;"></em>Category Name</span>
                                    </div><!-- /.ph-meta -->
                                    <div class="nv-participant-row">
                                        <label>Chairpersons:</label>

                                        <div class="nv-participant">
                                            <div class="p-img"><span style="background-image: url('https://loremflickr.com/g/200/200/face,man/all');"></span></div>
                                            <div class="p-title-name">
                                                <p>Ronan O'Connell</p>
                                            </div><!-- /.p-name -->
                                        </div><!-- /.nv-participant -->

                                        <div class="nv-participant">
                                            <div class="p-img"><span></span></div>
                                            <div class="p-title-name">
                                                <p>Friedrich Wrba</p>
                                            </div><!-- /.p-name -->
                                        </div><!-- /.nv-participant -->
                                    </div><!-- /.nv-participant-row -->
                                </div><!-- /.ph-body -->
                                <div class="ph-star on"></div>
                            </header><!-- /.nv-planner-header -->
                            <div class="nv-planner-sessions">
                                <div class="ps-item">
                                    <div class="psi-time">8:00<br>AM</div>
                                    <div class="psi-body">
                                        <div class="nv-participant">
                                            <div class="p-img"><span style="background-image: url('https://loremflickr.com/g/200/200/face,man/all');"></span></div>
                                            <div class="p-title-name">
                                                <h4><a href="#">Update perianal Crohn's disease</a></h4>
                                                <p>Ronan O'Connell</p>
                                                <h6>President Medical Association Vienna</h6>
                                            </div><!-- /.p-name -->
                                        </div><!-- /.nv-participant -->
                                    </div><!-- /.psi-body -->
                                    <div class="ph-star on"></div>
                                </div><!-- /.ps-item -->
                                <div class="ps-item">
                                    <div class="psi-time">8:00<br>AM</div>
                                    <div class="psi-body">
                                        <div class="nv-participant">
                                            <div class="p-img"><span style="background-image: url('https://loremflickr.com/g/200/200/face,man/all');"></span></div>
                                            <div class="p-title-name">
                                                <h4><a href="#">Update perianal Crohn's disease</a></h4>
                                                <p>Ronan O'Connell</p>
                                                <h6>President Medical Association Vienna</h6>
                                            </div><!-- /.p-name -->
                                        </div><!-- /.nv-participant -->
                                    </div><!-- /.psi-body -->
                                    <div class="ph-star"></div>
                                </div><!-- /.ps-item -->
                                <div class="ps-item">
                                    <div class="psi-time">8:00<br>AM</div>
                                    <div class="psi-body">
                                        <div class="nv-participant">
                                            <div class="p-img"><span style="background-image: url('https://loremflickr.com/g/200/200/face,man/all');"></span></div>
                                            <div class="p-title-name">
                                                <h4><a href="#">Update perianal Crohn's disease</a></h4>
                                                <p>Ronan O'Connell</p>
                                                <h6>President Medical Association Vienna</h6>
                                            </div><!-- /.p-name -->
                                        </div><!-- /.nv-participant -->
                                    </div><!-- /.psi-body -->
                                    <div class="ph-star on"></div>
                                </div><!-- /.ps-item -->
                            </div><!-- /.nv-planner-sessions -->
                        </div><!-- /.nv-planner-item -->

                        <div class="nv-planner-item">
                            <header class="nv-planner-header">
                                <div class="ph-time">
                                    10:00<span>AM</span>
                                </div><!-- /.pt-time -->
                                <div class="ph-body">
                                    <h3><a href="#">Coffee Break</a></h3>
                                    <div class="ph-meta">
                                        <span class="phm-time-frame">10:00 - 10:30 AM</span>
                                    </div><!-- /.ph-meta -->
                                </div><!-- /.ph-body -->
                            </header><!-- /.nv-planner-header -->
                        </div><!-- /.nv-planner-item -->

                        <div class="nv-planner-item">
                            <header class="nv-planner-header">
                                <div class="ph-time">
                                    10:30<span>AM</span>
                                </div><!-- /.pt-time -->
                                <div class="ph-body">
                                    <h3><a href="#">[Session] IBD I</a></h3>
                                    <div class="ph-meta">
                                        <span class="phm-time-frame">8:00 - 8:30 AM</span>
                                        <span class="phm-location">Hörsaal 1</span>
                                        <span class="phm-cat"><em style="background-color: purple;"></em>Category Name</span>
                                    </div><!-- /.ph-meta -->
                                    <div class="nv-participant-row">
                                        <label>Chairpersons:</label>

                                        <div class="nv-participant">
                                            <div class="p-img"><span style="background-image: url('https://loremflickr.com/g/200/200/face,man/all');"></span></div>
                                            <div class="p-title-name">
                                                <p>Ronan O'Connell</p>
                                            </div><!-- /.p-name -->
                                        </div><!-- /.nv-participant -->

                                        <div class="nv-participant">
                                            <div class="p-img"><span></span></div>
                                            <div class="p-title-name">
                                                <p>Friedrich Wrba</p>
                                            </div><!-- /.p-name -->
                                        </div><!-- /.nv-participant -->
                                    </div><!-- /.nv-participant-row -->
                                </div><!-- /.ph-body -->
                                <div class="ph-star on"></div>
                            </header><!-- /.nv-planner-header -->
                            <div class="nv-planner-sessions">
                                <div class="ps-item">
                                    <div class="psi-time">8:00<br>AM</div>
                                    <div class="psi-body">
                                        <div class="nv-participant">
                                            <div class="p-img"><span style="background-image: url('https://loremflickr.com/g/200/200/face,man/all');"></span></div>
                                            <div class="p-title-name">
                                                <h4><a href="#">Update perianal Crohn's disease</a></h4>
                                                <p>Ronan O'Connell</p>
                                            </div><!-- /.p-name -->
                                        </div><!-- /.nv-participant -->
                                    </div><!-- /.psi-body -->
                                    <div class="ph-star on"></div>
                                </div><!-- /.ps-item -->
                                <div class="ps-item">
                                    <div class="psi-time">8:00<br>AM</div>
                                    <div class="psi-body">
                                        <div class="nv-participant">
                                            <div class="p-img"><span style="background-image: url('https://loremflickr.com/g/200/200/face,man/all');"></span></div>
                                            <div class="p-title-name">
                                                <h4><a href="#">Update perianal Crohn's disease</a></h4>
                                                <p>Ronan O'Connell</p>
                                            </div><!-- /.p-name -->
                                        </div><!-- /.nv-participant -->
                                    </div><!-- /.psi-body -->
                                    <div class="ph-star"></div>
                                </div><!-- /.ps-item -->
                                <div class="ps-item">
                                    <div class="psi-time">8:00<br>AM</div>
                                    <div class="psi-body">
                                        <div class="nv-participant">
                                            <div class="p-img"><span style="background-image: url('https://loremflickr.com/g/200/200/face,man/all');"></span></div>
                                            <div class="p-title-name">
                                                <h4><a href="#">Update perianal Crohn's disease</a></h4>
                                                <p>Ronan O'Connell</p>
                                            </div><!-- /.p-name -->
                                        </div><!-- /.nv-participant -->
                                    </div><!-- /.psi-body -->
                                    <div class="ph-star on"></div>
                                </div><!-- /.ps-item -->
                            </div><!-- /.nv-planner-sessions -->
                        </div><!-- /.nv-planner-item -->

                        <div class="nv-planner-item">
                            <header class="nv-planner-header">
                                <div class="ph-time">
                                    12:00<span>AM</span>
                                </div><!-- /.pt-time -->
                                <div class="ph-body">
                                    <h3><a href="#">Lunch Break</a></h3>
                                    <div class="ph-meta">
                                        <span class="phm-time-frame">12:00 - 01:30 PM</span>
                                    </div><!-- /.ph-meta -->
                                </div><!-- /.ph-body -->
                            </header><!-- /.nv-planner-header -->
                        </div><!-- /.nv-planner-item -->

                        <div class="nv-planner-item">
                            <header class="nv-planner-header">
                                <div class="ph-time">
                                    13:30<span>AM</span>
                                </div><!-- /.pt-time -->
                                <div class="ph-body">
                                    <h3><a href="#">Opening Ceremony</a></h3>
                                    <div class="ph-meta">
                                        <span class="phm-time-frame">01:30 - 02:30 PM</span>
                                        <span class="phm-location">Hörsaal 1</span>
                                    </div><!-- /.ph-meta -->
                                    <div class="nv-participant-row">
                                        <div class="nv-participant">
                                            <div class="p-img"><span></span></div>
                                            <div class="p-title-name">
                                                <p>Markus Mueller</p>
                                                <h6>Rector Medical University Vienna</h6>
                                            </div><!-- /.p-name -->
                                        </div><!-- /.nv-participant -->

                                        <div class="nv-participant">
                                            <div class="p-img"><span></span></div>
                                            <div class="p-title-name">
                                                <p>Thomas Szekeres</p>
                                                <h6>President Medical Association Vienna</h6>
                                            </div><!-- /.p-name -->
                                        </div><!-- /.nv-participant -->

                                        <div class="nv-participant">
                                            <div class="p-img"><span></span></div>
                                            <div class="p-title-name">
                                                <p>Béla Teleky</p>
                                                <h6>President EFR</h6>
                                            </div><!-- /.p-name -->
                                        </div><!-- /.nv-participant -->
                                    </div><!-- /.nv-participant-row -->
                                </div><!-- /.ph-body -->
                                <div class="ph-star on"></div>
                            </header><!-- /.nv-planner-header -->
                            <div class="nv-planner-sessions">
                                <div class="ps-item">
                                    <div class="psi-time">8:00<br>AM</div>
                                    <div class="psi-body">
                                        <div class="nv-participant">
                                            <div class="p-img"><span style="background-image: url('https://loremflickr.com/g/200/200/face,man/all');"></span></div>
                                            <div class="p-title-name">
                                                <h4><a href="#">Update perianal Crohn's disease</a></h4>
                                                <p>Ronan O'Connell</p>
                                            </div><!-- /.p-name -->
                                        </div><!-- /.nv-participant -->
                                    </div><!-- /.psi-body -->
                                    <div class="ph-star on"></div>
                                </div><!-- /.ps-item -->
                                <div class="ps-item">
                                    <div class="psi-time">8:00<br>AM</div>
                                    <div class="psi-body">
                                        <div class="nv-participant">
                                            <div class="p-img"><span style="background-image: url('https://loremflickr.com/g/200/200/face,man/all');"></span></div>
                                            <div class="p-title-name">
                                                <h4><a href="#">Update perianal Crohn's disease</a></h4>
                                                <p>Ronan O'Connell</p>
                                            </div><!-- /.p-name -->
                                        </div><!-- /.nv-participant -->
                                    </div><!-- /.psi-body -->
                                    <div class="ph-star"></div>
                                </div><!-- /.ps-item -->
                                <div class="ps-item">
                                    <div class="psi-time">8:00<br>AM</div>
                                    <div class="psi-body">
                                        <div class="nv-participant">
                                            <div class="p-img"><span style="background-image: url('https://loremflickr.com/g/200/200/face,man/all');"></span></div>
                                            <div class="p-title-name">
                                                <h4><a href="#">Update perianal Crohn's disease</a></h4>
                                                <p>Ronan O'Connell</p>
                                            </div><!-- /.p-name -->
                                        </div><!-- /.nv-participant -->
                                    </div><!-- /.psi-body -->
                                    <div class="ph-star on"></div>
                                </div><!-- /.ps-item -->
                            </div><!-- /.nv-planner-sessions -->
                        </div><!-- /.nv-planner-item -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </main><!-- /.nv-section -->
</div><!-- /nv-page-wrap -->


