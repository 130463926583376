import { NgModule } from '@angular/core';
import { NavusCoreModule } from '@navus/core/navus-core.module';
import { NavusUiModule } from '@navus/ui/navus-ui.module';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from 'ng2-file-upload';
import { SharedModule } from '../../../shared';
import { ProfileDelegateComponent } from './pages/delegate/delegate.component';
import { ProfileUserComponent } from './pages/user/user.component';
import { ProfileSecurityComponent } from './pages/security/security.component';
import { ProfileComponent } from './profile.component';
import { ProfileSpeakerComponent } from './pages/speaker/speaker.component';
import { ProfileSponsorComponent } from './pages/sponsor/sponsor.component';
import { DelegateLiveAttendanceComponent } from './pages/delegate/live-attendance/delegate-live-attendance.component';
import { DelegateOnSiteAttendanceComponent } from './pages/delegate/on-site-attendance/delegate-on-site-attendance.component';
import { DelegateOnDemandAttendanceComponent } from './pages/delegate/on-demand-attendance/delegate-on-demand-attendance.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DelegateChartLegendComponent } from './pages/delegate/delegate-chart-legend/delegate-chart-legend.component';
import { ProfileBadgesComponent } from './pages/badges/badges.component';

@NgModule({
  imports: [
    TranslateModule.forChild(),
    NavusCoreModule.forRoot(),
    NavusUiModule,
    FileUploadModule,
    SharedModule,
    NgxChartsModule,
  ],
  declarations: [
    ProfileComponent,
    ProfileUserComponent,
    ProfileSecurityComponent,
    ProfileDelegateComponent,
    ProfileSpeakerComponent,
    ProfileSponsorComponent,
    ProfileBadgesComponent,
    DelegateLiveAttendanceComponent,
    DelegateOnDemandAttendanceComponent,
    DelegateOnSiteAttendanceComponent,
    DelegateChartLegendComponent,
  ],
  entryComponents: [],
  providers: []
})
export class ProfileModule {
}
