import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { BadgeService } from '@navus/core/services/badge.service';
import { LeaderboardSectionConfig } from './leaderboard-section.config';
import { WebsiteService } from '@navus/core/services/website.service';
import { BaseSectionComponent } from '../_base/base-section.component';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { WebsiteControlService } from '../../services/website-control.service';
import { Subscription } from 'rxjs';
import { Website } from '@navus/core/classes/website';

@Component({
  selector: 'nv-leaderboard-section',
  templateUrl: './leaderboard-section.component.html'
})
export class LeaderboardSectionComponent extends BaseSectionComponent implements OnInit, OnDestroy {
  @Input() data: WebsitePageSection;
  configuration: LeaderboardSectionConfig = new LeaderboardSectionConfig();
  leaderboard: any;
  currentDelegate: any = null;
  showCurrentDelegate: boolean = false;

  conferenceId: number;
  hover: boolean = false;
  interval: any;

  subscriptions: Subscription[] = [];

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService,
    private badgeService: BadgeService
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );
  }

  ngOnInit() {
    this.configuration = new LeaderboardSectionConfig(this.data.configuration);

    const subscription = this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          this.conferenceId = website.active_conference_id;
          this.getLeaderboard();
        },
        () => { }
      );
    this.subscriptions.push(subscription);
    
    this.interval = setInterval(() => this.getLeaderboard(), 60000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getLeaderboard() {
    const params: any = {
      per_page: this.configuration.count,
      include: 'delegate,badge_levels',
      completed: true,
    };

    this.badgeService.getConferenceLeaderboard(this.conferenceId, params)
      .subscribe(
        (response) => {
          this.leaderboard = response.data;
          this.leaderboard.forEach(leaderboardDelegate => {
            leaderboardDelegate.badge_levels = leaderboardDelegate.badge_levels
              .filter(bl => bl.completed_at)
              .sort((a, b) => a.completed_at < b.completed_at ? 1 : -1);
          });

          this.currentDelegate = response.authenticated_delegate;
          if (this.currentDelegate?.badge_levels) {
            this.currentDelegate.badge_levels = this.currentDelegate.badge_levels
              .filter(bl => bl.completed_at)
              .sort((a, b) => a.completed_at < b.completed_at ? 1 : -1);            
          }

          this.showCurrentDelegate = !this.leaderboard.find(ld => ld.id === this.currentDelegate?.id); 
        },
        (error) => {
          
        }
      );
  }

}
