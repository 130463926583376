import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Notification } from '@navus/core/classes/notification';
import { NotificationService } from '@navus/core/services/notification.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { BlockService } from '@navus/core/services/block.service';
import { Author } from '@navus/core/classes/author';
import { AuthorService } from '@navus/core/services/author.service';
import { Presentation } from '@navus/core/classes/presentation';
import { PresentationService } from '@navus/core/services/presentation.service';
import { Article } from '@navus/core/classes/article';
import { ArticleService } from '@navus/core/services/article.service';
import { Block } from '@navus/core/classes/block';
import { Company } from '@navus/core/classes/company';
import { CompanyService } from '@navus/core/services/company.service';

@Component({
  selector: 'main [nv-notification-details-page]',
  templateUrl: './notification-details-page.component.html',
  styles: []
})
export class NotificationDetailsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  notificationId: number;
  notification: Notification;
  notificationForm: FormGroup;
  notificationTypes = [
    { id: 'text', name: 'Text' },
    { id: 'speaker', name: 'Speaker' },
    { id: 'sponsor', name: 'Sponsor' },
    { id: 'presentation', name: 'Presentation' },
    { id: 'block', name: 'Session' },
    { id: 'programme', name: 'Programme' },
    { id: 'news', name: 'News' },
  ];
  notificationScope = [
    { id: 'all', name: 'Everyone' },
    { id: 'test', name: 'Test Users' },
  ];
  notificationChannel = [
    { id: 'web', name: 'Web' },
    { id: 'app', name: 'App' },
    { id: 'all', name: 'All' },
  ];
  notificationPlace = [
    { id: 'notification_bar', name: 'Notification bar' },
    { id: 'popup', name: 'Popup' },
    { id: 'video_overlay', name: 'Video overlay' },
  ];

  authors: Author[] = [];
  authorsSearch: string = '';
  authorsPage: number = 1;
  authorsTotal: number = null;

  presentations: Presentation[] = [];
  presentationsSearch: string = '';
  presentationsPage: number = 1;
  presentationsTotal: number = null;

  blocks: Block[] = [];
  blocksSearch: string = '';
  blocksPage: number = 1;
  blocksTotal: number = null;

  companies: Company[] = [];
  companiesSearch: string = '';
  companiesPage: number = 1;
  companiesTotal: number = null;

  articles: Article[] = [];
  articlesSearch: string = '';
  articlesPage: number = 1;
  articlesTotal: number = null;

  loadingNotification: boolean = false;
  savingNotification: boolean = false;
  unsavedChanges: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private presentationService: PresentationService,
    private blockService: BlockService,
    private companyService: CompanyService,
    private authorService: AuthorService,
    private articleService: ArticleService,
    private toastService: ToastService,
    private modalService: ModalService
  ) {
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  ngOnInit() {
    this.initForm();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.notificationId = data.notificationId;
        this.getNotification();
      }
    );
    this.subscriptions.push(subscription);

    const changeSubscription = this.notificationForm.valueChanges
      .subscribe(
        () => {
          this.unsavedChanges = true;
        }
      );
    this.subscriptions.push(changeSubscription);

    const relatedChangeSubscription = this.notificationForm.get('type')
      .valueChanges
      .subscribe(
        (type) => {
          if (type === 'text' || type === 'programme') {
            this.notificationForm.get('entity_id').setValidators(null);
          } else {
            this.notificationForm.get('entity_id').setValidators([Validators.required]);
          }
        }
      );
    this.subscriptions.push(relatedChangeSubscription);

    const channelChangeSubscription = this.notificationForm.get('channel')
      .valueChanges
      .subscribe(
        (channel) => {
          if (channel === 'web') {
            this.notificationForm.get('scope').setValue(['all']);
          }
        }
      );
    this.subscriptions.push(channelChangeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.notificationForm = this.formBuilder.group({
      title: ['', Validators.required],
      type: ['', Validators.required],
      entity_id: [''],
      body: ['', Validators.required],
      schedule_time: ['', Validators.required],
      scope: [['all']],
      channel: ['web', Validators.required],
      place: ['notification_bar', Validators.required],
    });
    this.unsavedChanges = false;
  }

  getNotification() {
    if (this.notificationId) {
      this.loadingNotification = true;
      this.notificationService
        .getConferenceNotification(this.conferenceId, this.notificationId, { include: 'entity' })
        .subscribe(
          (response) => {
            const notification = response['data'];

            if (notification.type === 'speaker') {
              this.authors.push(notification.entity);
            }
            if (notification.type === 'sponsor') {
              this.companies.push(notification.entity);
            }
            if (notification.type === 'speaker') {
              this.authors.push(notification.entity);
            }
            if (notification.type === 'presentation') {
              this.presentations.push(notification.entity);
            }
            if (notification.type === 'block') {
              this.blocks.push(notification.entity);
            }
            if (notification.type === 'news') {
              this.articles.push(notification.entity);
            }
            delete notification.entity;

            this.notification = notification;
            this.notificationForm.patchValue(this.notification);
            this.loadingNotification = false;
            setTimeout(() => {
              this.unsavedChanges = false;
            }, 500);
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingNotification = false;
          }
        );
    } else {
      this.notification = new Notification();
      this.loadingNotification = false;
    }
  }

  save() {
    if (this.notificationForm.invalid) {
      return;
    }

    this.savingNotification = true;
    if (this.notificationId) {
      this.notificationService
        .updateConferenceNotification(this.conferenceId, this.notificationId, this.notificationForm.value)
        .subscribe(
          (response) => {
            this.notification = response['data'];
            this.toastService.success('Push notification successfully saved.', {});
            this.unsavedChanges = false;
            this.savingNotification = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingNotification = false;
          }
        );
    } else {
      this.notificationService.createConferenceNotification(this.conferenceId, this.notificationForm.value)
        .subscribe(
          (response) => {
            this.notification = response['data'];
            this.toastService.success('Push notification successfully created.', {});
            this.router.navigate([this.notification.id], { relativeTo: this.route });
            this.savingNotification = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingNotification = false;
          }
        );
    }
  }

  launch() {
    this.savingNotification = true;
    this.notificationService.updateConferenceNotification(this.conferenceId, this.notificationId, { status: 'published' })
      .subscribe(
        (response) => {
          this.notification = response['data'];
          this.toastService.success('Push notification successfully published.', {});
          this.unsavedChanges = false;
          this.savingNotification = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.savingNotification = false;
        }
      );
  }

  close() {
    if (this.unsavedChanges) {
      this.modalService.defaultModal({
        title: 'Unsaved changes',
        body: 'Are you sure you want to leave the page without saving changes?',
        size: 'small',
        buttons: [
          {
            text: 'Stay',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Leave',
            color: 'primary',
            handler: () => {
              if (this.notificationId) {
                this.router.navigate(['../..', 'notifications'], { relativeTo: this.route });
              } else {
                this.router.navigate(['..', 'notifications'], { relativeTo: this.route });
              }
            }
          }
        ]
      });
    } else {
      if (this.notificationId) {
        this.router.navigate(['../..', 'notifications'], { relativeTo: this.route });
      } else {
        this.router.navigate(['..', 'notifications'], { relativeTo: this.route });
      }
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Notification Deletion',
      body: 'Are you sure you want to delete this push notification?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.notificationService.deleteConferenceNotification(this.conferenceId, this.notificationId)
              .subscribe(
                () => {
                  this.close();
                },
                (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

  getAuthors(append: boolean = false) {
    if (!this.conferenceId) {
      return;
    }

    if (append) {
      if (this.authorsTotal && this.authors.length >= this.authorsTotal) {
        return;
      }
      this.authorsPage++;
    } else {
      this.authorsPage = 1;
    }

    const params: any = {
      page: this.authorsPage,
      per_page: 10,
      sort: 'name',
      order: 'asc'
    };

    if (this.authorsSearch && this.authorsSearch !== '') {
      params.search_term = this.authorsSearch;
    }

    this.authorService.getConferenceAuthors(this.conferenceId, params)
      .subscribe(
        (response: { data: Author[], meta: any }) => {
          if (append) {
            this.authors = this.authors.concat(response.data);
          } else {
            this.authors = response.data;
            this.authorsTotal = response.meta.pagination.total;
          }
        },
        () => {
        }
      );
  }

  searchAuthors(searchTerm) {
    this.authorsSearch = searchTerm;
    this.getAuthors();
  }

  getPresentations(append: boolean = false) {
    if (!this.conferenceId) {
      return;
    }

    if (append) {
      if (this.presentationsTotal && this.presentations.length >= this.presentationsTotal) {
        return;
      }
      this.presentationsPage++;
    } else {
      this.presentationsPage = 1;
    }

    const params: any = {
      page: this.presentationsPage,
      per_page: 10,
      sort: 'name',
      order: 'asc'
    };

    if (this.presentationsSearch && this.presentationsSearch !== '') {
      params.search_term = this.presentationsSearch;
    }

    this.presentationService.getConferencePresentations(this.conferenceId, params)
      .subscribe(
        (response: { data: Presentation[], meta: any }) => {
          if (append) {
            this.presentations = this.presentations.concat(response.data);
          } else {
            this.presentations = response.data;
            this.presentationsTotal = response.meta.pagination.total;
          }
        },
        () => {
        }
      );
  }

  searchPresentations(searchTerm) {
    this.presentationsSearch = searchTerm;
    this.getPresentations();
  }

  getArticles(append: boolean = false) {
    if (!this.conferenceId) {
      return;
    }

    if (append) {
      if (this.articlesTotal && this.articles.length >= this.articlesTotal) {
        return;
      }
      this.articlesPage++;
    } else {
      this.articlesPage = 1;
    }

    const params: any = {
      page: this.articlesPage,
      per_page: 10,
      sort: 'name',
      order: 'asc'
    };

    if (this.articlesSearch && this.articlesSearch !== '') {
      params.search_term = this.articlesSearch;
    }

    this.articleService.getOrganizationArticles(this.organizationId, params)
      .subscribe(
        (response: { data: Article[], meta: any }) => {
          if (append) {
            this.articles = this.articles.concat(response.data);
          } else {
            this.articles = response.data;
            this.articlesTotal = response.meta.pagination.total;
          }
        },
        () => {
        }
      );
  }

  searchArticles(searchTerm) {
    this.articlesSearch = searchTerm;
    this.getArticles();
  }

  getBlocks(append: boolean = false) {
    if (!this.conferenceId) {
      return;
    }

    if (append) {
      if (this.blocksTotal && this.blocks.length >= this.blocksTotal) {
        return;
      }
      this.blocksPage++;
    } else {
      this.blocksPage = 1;
    }

    const params: any = {
      page: this.blocksPage,
      per_page: 10,
      sort: 'name',
      order: 'asc'
    };

    if (this.blocksSearch && this.blocksSearch !== '') {
      params.search_term = this.blocksSearch;
    }

    this.blockService.getConferenceBlocks(this.conferenceId, params)
      .subscribe(
        (response: { data: Block[], meta: any }) => {
          if (append) {
            this.blocks = this.blocks.concat(response.data);
          } else {
            this.blocks = response.data;
            this.blocksTotal = response.meta.pagination.total;
          }
        },
        () => {
        }
      );
  }

  searchBlocks(searchTerm) {
    this.blocksSearch = searchTerm;
    this.getBlocks();
  }

  getCompanies(append: boolean = false) {
    if (!this.conferenceId) {
      return;
    }

    if (append) {
      if (this.companiesTotal && this.companies.length >= this.companiesTotal) {
        return;
      }
      this.companiesPage++;
    } else {
      this.companiesPage = 1;
    }

    const params: any = {
      page: this.companiesPage,
      per_page: 10,
      sort: 'name',
      order: 'asc'
    };

    if (this.companiesSearch && this.companiesSearch !== '') {
      params.search_term = this.companiesSearch;
    }

    this.companyService.getConferenceSponsors(this.conferenceId, params)
      .subscribe(
        (response: { data: Company[], meta: any }) => {
          if (append) {
            this.companies = this.companies.concat(response.data);
          } else {
            this.companies = response.data;
            this.companiesTotal = response.meta.pagination.total;
          }
        },
        () => {
        }
      );
  }

  searchCompanies(searchTerm) {
    this.companiesSearch = searchTerm;
    this.getCompanies();
  }

}
