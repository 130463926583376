<section class="nv-live-stage-config-panel speaker-config"
         [ngClass]="{'nv-ls-panel-left-enhanced': currentLayoutOption === 'lo-type3', 'nv-ls-panel-right-enhanced': currentLayoutOption === 'lo-type2'}">
  <!-- < !! NEW CLASS 'speaker-config' -->
  <div class="nv-stage-config-layout-options">
    <span>layout options</span>
    <ul>
      <li class="lo-type1" (click)="currentLayoutOption='lo-type1'" title="Equal panels space"></li>
      <li class="lo-type2" (click)="currentLayoutOption='lo-type2'" title="Enhance right panel"></li>
      <li class="lo-type3" (click)="currentLayoutOption='lo-type3'" title="Enhance left panel"></li>
    </ul>
  </div><!-- /.nv-stage-config-layout-options -->

  <div class="nv-live-stage-config-layout">
    <div class="nv-live-stage-config-panes">
      <div class="nv-live-stage-config-left-pane">

        <ng-container *ngFor="let section of controlPanelSectionTabs">
          <div class="nv-ls-config-feature-panel" *ngIf="['video-conference','preview','agenda','voting'].includes(section)">
            <div class="nv-ls-feature-panel-placeholder">
              <!-- <ng-container
                *ngIf="loadingPresentation || loadingVotingQuestions || loadingLocation || loadingOffAirs else content">
                <span class="nv-ls-feature-panel-loading"><em></em>Loading. Please wait.</span>
              </ng-container>
              <ng-template #content> -->
                <ng-container [ngSwitch]="section">
                  <span class="nv-close-ls-config-feature-panel" (click)="closeSection(section)"
                    *ngIf="controlPanelSectionTabs.length > 1"></span>

                    <ng-container *ngSwitchCase="'video-conference'">
                      <nv-speaker-video-conference 
                        [conferenceId]="conferenceId"
                        [locationId]="currentStream?.location_id"
                        [currentUser]="currentUser"
                      ></nv-speaker-video-conference>
                    </ng-container>

                    <ng-container *ngSwitchCase="'preview'">
                      <nv-speaker-preview 
                        style="width: 100%; height: 100%;"
                        [conferenceId]="conferenceId"
                        [currentStream]="currentStream"
                        [hasAccess]="hasAccess"
                        (accessRequested)="requestAccess()"
                      ></nv-speaker-preview>
                    </ng-container>

                    <ng-container *ngSwitchCase="'agenda'">
                      <nv-speaker-agenda 
                        [conferenceId]="conferenceId"
                        [filterPresentationIds]="myPresentationIds"
                      ></nv-speaker-agenda>
                    </ng-container>

                    <ng-container *ngSwitchCase="'voting'">
                      <nv-speaker-voting 
                      ></nv-speaker-voting>
                    </ng-container>

                </ng-container>
              <!-- </ng-template> -->
            </div><!-- /.nv-ls-feature-panel-placeholder -->
          </div><!-- /.nv-ls-config-feature-panel -->
        </ng-container>

      </div><!-- /.nv-live-stage-config-left-pane -->

      <div class="nv-live-stage-config-right-pane">

        <ng-container *ngFor="let section of controlPanelSectionTabs">
          <div class="nv-ls-config-feature-panel" *ngIf="['qna','slides','recordings'].includes(section)">
            <div class="nv-ls-feature-panel-placeholder">
              <!-- <ng-container
                *ngIf="loadingPresentation || loadingVotingQuestions || loadingLocation || loadingOffAirs else content">
                <span class="nv-ls-feature-panel-loading"><em></em>Loading. Please wait.</span>
              </ng-container>
              <ng-template #content> -->
                <ng-container [ngSwitch]="section">
                  <span class="nv-close-ls-config-feature-panel" (click)="closeSection(section)"
                    *ngIf="controlPanelSectionTabs.length > 1"></span>

                  <ng-container *ngSwitchCase="'qna'">
                    <nv-speaker-q-a 
                      [blockId]="currentStream?.currentPresentation?.block_id" 
                      [moderatingBlockIds]="myBlockIds"
                      [availableBlocks]="qnaBlocks"
                      [conferenceId]="conferenceId"
                      [currentUserId]="currentUser?.id"
                    ></nv-speaker-q-a>
                  </ng-container>

                  <!-- <ng-container *ngSwitchCase="'slides'">
                    <nv-speaker-slides 
                      [presentations]="myPresentations"
                    ></nv-speaker-slides>
                  </ng-container> -->

                  <!-- <ng-container *ngSwitchCase="'recordings'">
                    <nv-speaker-recordings 
                    ></nv-speaker-recordings>
                  </ng-container> -->

                </ng-container>
              <!-- </ng-template> -->
            </div><!-- /.nv-ls-feature-panel-placeholder -->
          </div><!-- /.nv-ls-config-feature-panel -->
        </ng-container>

      </div><!-- /.nv-live-stage-config-right-pane -->
    </div><!-- /.nv-live-stage-config-panes -->
    <div class="nv-live-stage-config-bar">
      <div class="nv-live-stage-config-bar-panel">
        <div class="nv-ls-config-bar-status"
             [ngClass]="currentStream?.status === 'onair'? 'on-air': 'off-air'">
            <!-- 'not-ready' class for alt state -->
            <h4>STATUS</h4>
            <span class="status-txt"
                  [ngClass]="currentStream?.status === 'onair'? 'on-air': 'off-air'">{{ currentStream?.status }}</span>
        </div><!-- /.nv-ls-config-bar-status -->
        <div class="nv-live-stage-current-stream-switch">
          <ng-container *ngIf="streams.length > 1">
            <a class="switch" title="Switch stream" (click)="changeStream()"></a>
          </ng-container>

          <p class="nv-ls-current-stream-label">
            <strong>Stream:</strong>
            <span class="room">{{ currentStream?.name }}</span>
            |
            <strong>Session:</strong>
            <span>{{ currentStream?.currentPresentation?.block_name || '-' }}</span>
          </p>
          <h4>
            <ng-container *ngIf="currentStream?.currentPresentation">
              <time>{{ currentStream.currentPresentation.starts_at | nvDate:'HH:mm' }} - {{ currentStream.currentPresentation?.ends_at | nvDate:'HH:mm' }}</time>
              <strong>{{ currentStream.currentPresentation.title }}</strong>              
            </ng-container>
            <ng-container *ngIf="!currentStream?.currentPresentation">
              <time>-</time>
            </ng-container>

            <span class="switch-stream"></span>
          </h4>
        </div><!-- /.nv-live-stage-current-stream-switch -->
      </div><!-- /.nv-live-stage-config-bar-panel -->

      <div class="nv-live-stage-config-bar-panel">
        <ul class="nv-live-stage-tool-list">
          <!-- Left -->
          <li>
            <a 
              class="video-conference" 
              [class.active]="controlPanelSectionTabs.includes('video-conference')"
              (click)="addSection('video-conference')"
            >Video Conferencing</a>
          </li>
          <li>
            <a 
              class="preview-stage" 
              [class.active]="controlPanelSectionTabs.includes('preview')"
              (click)="addSection('preview')"
            >Preview Stage</a>
          </li>
          <li>
            <a 
              class="my-agenda" 
              [class.active]="controlPanelSectionTabs.includes('agenda')"
              (click)="addSection('agenda')"
            >My Agenda</a>
          </li>
          <li>
            <a 
              class="voting-screen" 
              [class.active]="controlPanelSectionTabs.includes('voting')"
              (click)="addSection('voting')"
            >Voting</a>
          </li>

          <!-- Right -->
          <li>
            <a 
              class="chat" 
              [class.active]="controlPanelSectionTabs.includes('qna')"
              (click)="addSection('qna')"
            >Q&A</a>
          </li>
          <!-- <li>
            <a 
              class="slides" 
              [class.active]="controlPanelSectionTabs.includes('slides')"
              (click)="addSection('slides')"
            >Slides</a>
          </li> -->
          <!-- <li>
            <a 
              class="recordings" 
              [class.active]="controlPanelSectionTabs.includes('recordings')"
              (click)="addSection('recordings')"
            >Recordings</a>
          </li> -->
        </ul>
      </div><!-- /.nv-live-stage-config-bar-panel -->
      <div class="nv-live-stage-config-bar-panel">                        
        <div *ngIf="currentUser"
              class="nv-btn small accent2 radius-100 icon nv-bar-profile-menu">
            {{ currentUser.first_name.charAt(0) }}{{ currentUser.last_name.charAt(0) }}
        </div> <!-- LOGGED IN VER. -->
        <!-- <nv-speaker-chat-container
          [eventId]="conferenceId"
          [organizationId]="organizationId"
          [chatType]="'admin'"
          [allow1on1]="true"
          [allowGroupChat]="false"
        ></nv-speaker-chat-container> -->
      </div>
      <!-- /.nv-live-stage-config-bar-panel -->
    </div><!-- /.nv-live-stage-config-bar -->
  </div><!-- /.nv-live-stage-config-layout -->
</section><!-- /.nv-live-stage-config-panel -->
