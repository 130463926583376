<section class="nv-section nv-leaderboard-section guttered" style="background-color: #f9f9f9;" [class.edit-mode]="mouseOver">
    <ng-container *ngIf="mouseOver">
        <a class="nv-section-add-top" title="Add section" (click)="createBefore()"></a>
        <a class="nv-section-add-bottom" title="Add section" (click)="createAfter()"></a>
        <div class="nv-section-edit-tool-box">
            <a class="edit" title="Edit section" (click)="edit()"></a>
            <a class="delete" title="Delete section" (click)="delete()"></a>
        </div><!-- /.nv-section-edit-tool-box -->
        <div class="nv-section-sort-tool-box">
            <a class="move-up" title="Move up" (click)="moveUp()"></a>
            <a class="move-down" title="Move down" (click)="moveDown()"></a>
        </div><!-- /.nv-section-sort-tool-box -->
    </ng-container>

    <div class="nv-port">
        <div class="nv-row large-spaced gap-30 propagate">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <h2 style="text-align: center;">Leaderboard</h2>
                    <table class="nv-leaderboard">
                        <tr>
                            <th>RANK</th>
                            <th>NAME</th>
                            <th>BADGES EARNED</th>
                            <!-- <th>LAST EARNED ON</th> -->
                            <th>Badges</th>
                        </tr>
                        <ng-container *ngFor="let leaderboardDelegate of leaderboard">
                            <tr [class.lb-yr]="currentDelegate?.id === leaderboardDelegate.id">
                                <td class="lb-rank" data-cell-label="RANK">{{ leaderboardDelegate.rank_place }}.</td>
                                <td data-cell-label="NAME">{{ leaderboardDelegate.first_name }} {{ leaderboardDelegate.last_name }}</td>
                                <td data-cell-label="BADGES EARNED">{{ leaderboardDelegate.badge_count }}</td>
                                <!-- <td data-cell-label="LAST EARNED ON">{{ leaderboardDelegate.badge_levels[0]?.completed_at | nvDate:'dd.MM.yyyy. HH:mm' }}</td> -->
                                <td class="lb-badges" data-cell-label="Badges">
                                    <div class="lb-badges-box"> <!-- < order inverted -->
                                        <ng-container *ngFor="let badgeLevel of leaderboardDelegate.badge_levels">
                                            <img [src]="badgeLevel.image_url" alt="{{ badgeLevel.name }}">
                                        </ng-container>
                                    </div><!-- /.lb-badges-box -->
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="showCurrentDelegate && currentDelegate?.rank_place">
                            <tr>
                                <td class="lb-label" colspan="5">Your rank</td>
                            </tr>
                            <tr class="lb-yr">
                                <td class="lb-rank" data-cell-label="RANK">{{ currentDelegate.rank_place }}.</td>
                                <td data-cell-label="NAME">{{ currentDelegate.first_name }} {{ currentDelegate.last_name }}</td>
                                <td data-cell-label="BADGES EARNED">{{ currentDelegate.badge_count }}</td>
                                <!-- <td data-cell-label="LAST EARNED ON">{{ currentDelegate.badge_levels[0]?.completed_at | nvDate:'dd.MM.yyyy. HH:mm' }}</td> -->
                                <td class="lb-badges" data-cell-label="Badges">
                                    <div class="lb-badges-box"> <!-- < order inverted -->
                                        <ng-container *ngFor="let badgeLevel of currentDelegate.badge_levels">
                                            <img [src]="badgeLevel.image_url" alt="{{ badgeLevel.name }}">
                                        </ng-container>
                                    </div><!-- /.lb-badges-box -->
                                </td>
                            </tr>
                        </ng-container>
                    </table><!-- /.nv-leaderboard -->
                </div><!-- /nv-column-content -->
            </div><!-- /nv-column -->
        </div><!-- /nv-row -->
    </div><!-- /.nv-port -->
</section><!-- /.nv-section -->
