import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { DelegateService } from '@navus/core/services/delegate.service';
import { Delegate } from '@navus/core/classes/delegate';
import { ModalService } from '@navus/ui/modal/modal.service';
import { PaginationConfig } from '@navus/ui/pagination/classes/pagination-config';
import { DelegateModalComponent } from '../../modals/delegate/delegate-modal.component';
import { BaseSectionComponent } from '../_base/base-section.component';
import { WebsiteService } from '@navus/core/services/website.service';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { WebsiteControlService } from '../../services/website-control.service';
import { Subscription } from 'rxjs';
import { Website } from '@navus/core/classes/website';
import { DelegateListSectionConfig } from './delegate-list-section-config';
import { User } from '@navus/core/classes/user';
import { UserService } from '@navus/core/services/user.service';
import { Conference } from '@navus/core/classes/conference';


@Component({
  selector: 'nv-delegate-list-section',
  templateUrl: './delegate-list-section.component.html'
})
export class DelegateListSectionComponent extends BaseSectionComponent implements OnInit, OnDestroy {
  @Input() data: WebsitePageSection;
  configuration: DelegateListSectionConfig = new DelegateListSectionConfig();

  conferenceId: number;
  conference: Conference;
  delegates: Delegate[];
  pagination: PaginationConfig = {
    current_page: 1,
    per_page: 8
  };

  user: User;
  delegate: Delegate;

  usingTickets = false;
  usingRegistration = false;

  loadingDelegates: boolean;

  subscriptions: Subscription[] = [];

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService,
    private delegateService: DelegateService,
    private userService: UserService,    
    private _sanitizer: DomSanitizer
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );
  }

  ngOnInit() {
    this.configuration = new DelegateListSectionConfig(this.data.configuration);

    const subscription = this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          this.conferenceId = website.active_conference_id;
          this.conference = website.conferences.find(c => c.id === this.conferenceId);
          this.getDelegates();
        },
        () => { }
      );
      const userSubscription = this.userService.currentUser
      .subscribe(
        (user) => {
          this.user = user;
          if(user) {
            this.delegate = user.delegates.find(d => d.conference_id === this.conferenceId);
            this.usingTickets = this.conference.settings.delegate_registration?.ticketing;
            this.usingRegistration = this.conference.settings.delegate_registration?.enabled;
          }else {
            this.delegate = null;
            this.usingTickets = false;
            this.usingRegistration = false;
          }
        },
        () => {}
      );
    this.subscriptions.push(subscription, userSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getDelegates() {
    const params: any = {
      page: this.pagination.current_page,
      per_page: this.pagination.per_page,
      sort_by: 'first_name',
      sort_direction: 'asc'
    };

    if (this.configuration.limit) {
      params.per_page = this.configuration.limit;
    }

    this.loadingDelegates = true;
    this.delegateService.getConferenceChatDelegates(this.conferenceId, params)
      .subscribe(
        (response) => {
          this.delegates = response['data'];
          this.pagination = response['meta'].pagination;
          this.loadingDelegates = false;
        },
        () => {
          this.loadingDelegates = false;
        }
      );
  }

  openModal(delegate: Delegate) {
    const modalRef = this.modalService.open(DelegateModalComponent);
    modalRef.componentInstance.delegate = delegate;
  }

  public getDelegateImage(delegate) {
    const image = delegate.image_url_thumb !== null ? delegate.image_url_thumb : delegate.image_url;
    if (image === null) {
      return null;
    } else {
      return this._sanitizer.bypassSecurityTrustStyle(`url(${image})`);
    }
  }

  paginationChange(paginationConfig: PaginationConfig) {
    this.pagination.current_page = paginationConfig.current_page;
    this.getDelegates();
  }
}
