<div class="nv-section guttered">
    <div class="nv-port">
        <div class="nv-row gap-24 nv-row large-spaced">
            <div class="nv-column desk-12">
                <div class="nv-column-content" style="text-align: center;">
                    <h2>Customer Success Stories</h2>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <div class="customer-success-stories-list">
                        <div class="customer-success-story-excerpt">
                            <div class="customer-sse-frame">
                                <div class="customer-sse-img" style="background-image: url('https://loremflickr.com/720/400/conference');" title="blah"><a title="" href></a></div><!-- /.customer-sse-img -->
                                <div class="customer-ss-txt">
                                    <h3><a>How Navus helped SEHH to get on-demand education 365 days a year</a></h3>
                                    <div class="customer-ss-cats">
                                        <a href class="type1">Medical Industry</a>
                                        <a href class="type2">E-education</a>
                                    </div><!-- /.customer-ss-cats -->
                                </div><!-- /.customer-ss-txt -->
                            </div><!-- /.customer-sse-frame -->
                        </div><!-- /.customer-success-story-excerpt -->

                        <div class="customer-success-story-excerpt">
                            <div class="customer-sse-frame">
                                <div class="customer-sse-img" style="background-image: url('https://loremflickr.com/720/400/conference');" title="blah"><a title="" href></a></div><!-- /.customer-sse-img -->
                                <div class="customer-ss-txt">
                                    <h3><a>How Navus helped SEHH to get on-demand education 365 days a year</a></h3>
                                    <div class="customer-ss-cats">
                                        <a href class="type3">Tech Industry</a>
                                        <a href class="type4">Mobile App</a>
                                    </div><!-- /.customer-ss-cats -->
                                </div><!-- /.customer-ss-txt -->
                            </div><!-- /.customer-sse-frame -->
                        </div><!-- /.customer-success-story-excerpt -->

                        <div class="customer-success-story-excerpt">
                            <div class="customer-sse-frame">
                                <div class="customer-sse-img" style="background-image: url('https://loremflickr.com/720/400/conference');" title="blah"><a title="" href></a></div><!-- /.customer-sse-img -->
                                <div class="customer-ss-txt">
                                    <h3><a>How Navus helped SEHH to get on-demand education 365 days a year</a></h3>
                                    <div class="customer-ss-cats">
                                        <a href class="type1">Medical Industry</a>
                                        <a href class="type2">E-education</a>
                                    </div><!-- /.customer-ss-cats -->
                                </div><!-- /.customer-ss-txt -->
                            </div><!-- /.customer-sse-frame -->
                        </div><!-- /.customer-success-story-excerpt -->

                        <div class="customer-success-story-excerpt">
                            <div class="customer-sse-frame">
                                <div class="customer-sse-img" style="background-image: url('https://loremflickr.com/720/400/conference');" title="blah"><a title="" href></a></div><!-- /.customer-sse-img -->
                                <div class="customer-ss-txt">
                                    <h3><a>How Navus helped SEHH to get on-demand education 365 days a year</a></h3>
                                    <div class="customer-ss-cats">
                                        <a href class="type3">Tech Industry</a>
                                        <a href class="type4">Mobile App</a>
                                    </div><!-- /.customer-ss-cats -->
                                </div><!-- /.customer-ss-txt -->
                            </div><!-- /.customer-sse-frame -->
                        </div><!-- /.customer-success-story-excerpt -->

                        <div class="customer-success-story-excerpt">
                            <div class="customer-sse-frame">
                                <div class="customer-sse-img" style="background-image: url('https://loremflickr.com/720/400/conference');" title="blah"><a title="" href></a></div><!-- /.customer-sse-img -->
                                <div class="customer-ss-txt">
                                    <h3><a>How Navus helped SEHH to get on-demand education 365 days a year</a></h3>
                                    <div class="customer-ss-cats">
                                        <a href class="type1">Medical Industry</a>
                                        <a href class="type2">E-education</a>
                                    </div><!-- /.customer-ss-cats -->
                                </div><!-- /.customer-ss-txt -->
                            </div><!-- /.customer-sse-frame -->
                        </div><!-- /.customer-success-story-excerpt -->

                        <div class="customer-success-story-excerpt">
                            <div class="customer-sse-frame">
                                <div class="customer-sse-img" style="background-image: url('https://loremflickr.com/720/400/conference');" title="blah"><a title="" href></a></div><!-- /.customer-sse-img -->
                                <div class="customer-ss-txt">
                                    <h3><a>How Navus helped SEHH to get on-demand education 365 days a year</a></h3>
                                    <div class="customer-ss-cats">
                                        <a href class="type3">Tech Industry</a>
                                        <a href class="type4">Mobile App</a>
                                    </div><!-- /.customer-ss-cats -->
                                </div><!-- /.customer-ss-txt -->
                            </div><!-- /.customer-sse-frame -->
                        </div><!-- /.customer-success-story-excerpt -->

                        <div class="customer-success-story-excerpt">
                            <div class="customer-sse-frame">
                                <div class="customer-sse-img" style="background-image: url('https://loremflickr.com/720/400/conference');" title="blah"><a title="" href></a></div><!-- /.customer-sse-img -->
                                <div class="customer-ss-txt">
                                    <h3><a>How Navus helped SEHH to get on-demand education 365 days a year</a></h3>
                                    <div class="customer-ss-cats">
                                        <a href class="type1">Medical Industry</a>
                                        <a href class="type2">E-education</a>
                                    </div><!-- /.customer-ss-cats -->
                                </div><!-- /.customer-ss-txt -->
                            </div><!-- /.customer-sse-frame -->
                        </div><!-- /.customer-success-story-excerpt -->

                        <div class="customer-success-story-excerpt">
                            <div class="customer-sse-frame">
                                <div class="customer-sse-img" style="background-image: url('https://loremflickr.com/720/400/conference');" title="blah"><a title="" href></a></div><!-- /.customer-sse-img -->
                                <div class="customer-ss-txt">
                                    <h3><a>How Navus helped SEHH to get on-demand education 365 days a year</a></h3>
                                    <div class="customer-ss-cats">
                                        <a href class="type3">Tech Industry</a>
                                        <a href class="type4">Mobile App</a>
                                    </div><!-- /.customer-ss-cats -->
                                </div><!-- /.customer-ss-txt -->
                            </div><!-- /.customer-sse-frame -->
                        </div><!-- /.customer-success-story-excerpt -->
                    </div><!-- /.customer-success-stories-list -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /nv-port -->
</div><!-- /nv-section -->
