import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'nv-welcome-modal',
  templateUrl: './welcome-modal.component.html'
})
export class WelcomeModalComponent {
  @Input() organizationId: number = null;

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  close() {
    this.activeModal.dismiss();
  }

}
