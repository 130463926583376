<div class="nv-row gap-30 propagate" *ngIf="value">
    <ng-container *ngFor="let slide of value.slides">
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <nv-input
                        type="text"
                        label="Image URL"
                        [(ngModel)]="slide.imageUrl"
                        (ngModelChange)="onChange(value)"
                ></nv-input>
            </div>
        </div>
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <nv-input
                        type="text"
                        label="Link URL"
                        [(ngModel)]="slide.linkUrl"
                        (ngModelChange)="onChange(value)"
                ></nv-input>
            </div>
        </div>
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <nv-button (click)="removeSlide(slide)">Remove slide</nv-button>
            </div>
        </div>
    </ng-container>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-button (click)="addSlide()">Add slide</nv-button>
        </div>
    </div>
</div>
