import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Program } from '@navus/core/classes/program';
import { environment } from '../../../../../../environments/environment';
import { FileUploader } from 'ng2-file-upload';
import { ConferenceService } from '@navus/core/services/conference.service';
import { Conference } from '@navus/core/classes/conference';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ProgramModalComponent } from '../../../../modals/program/program-modal.component';
@Component({
  selector: 'main [nv-program-landing-page]',
  templateUrl: './program-landing-page.component.html'
})
export class ProgramLandingPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  conference: Conference;
  program: Program;
  uploader: FileUploader;

  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private conferenceService: ConferenceService
  ) { }

  ngOnInit() {
    const routeSubscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;

        this.uploader = new FileUploader({
          url: `${environment.api_url}conferences/${this.conferenceId}/program`,
          removeAfterUpload: true,
          autoUpload: true,
          authTokenHeader:  'Authorization',
          authToken: 'Bearer ' + localStorage.getItem('jwtToken'),
          headers: [],
        });
        this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
        this.uploader.onCompleteAll = () => { };
      }
    );
    this.subscriptions.push(routeSubscription);

    const conferenceSubscription = this.conferenceService.currentConference.subscribe(
      (conference: Conference) => {
        if (conference) {
          this.conference = conference;

          // if (!conference.processing.program_created) {
          //   const modalRef = this.modalService.open(ProgramModalComponent);
          //   modalRef.componentInstance.organizationId = this.organizationId;
          //   modalRef.componentInstance.conferenceId = this.conferenceId;
          //   modalRef.result.then(
          //     () => { },
          //     () => { }
          //   );
          // }
        }
      }
    );
    this.subscriptions.push(conferenceSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  downloadTemplate() {
    location.href = 'https://service.e-materials.com/templates/program.xlsx';
  }

}
