import { NgModule } from '@angular/core';
import { NavusCoreModule } from '@navus/core/navus-core.module';
import { NavusUiModule } from '@navus/ui/navus-ui.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../shared';
import { NavusOffAirComponent } from './components/off-air/off-air.component';
import { LiveStageComponent } from './live-stage.component';
import { LSBlockDetailsComponent } from './modals/block-details.component';

@NgModule({
  imports: [
    TranslateModule.forChild(),
    NavusCoreModule.forRoot(),
    NavusUiModule,
    SharedModule,
  ],
  declarations: [
    NavusOffAirComponent,
    LSBlockDetailsComponent,

    LiveStageComponent,
  ],
  entryComponents: [
    LSBlockDetailsComponent,
  ],
  providers: []
})
export class LiveStageModule {
}