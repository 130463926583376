<div class="nv-configurator-layout">
    <div class="config-layout-sidebar">

    </div><!-- /.config-layout-sidebar -->
    <div class="config-layout-main-frame">
        <div class="config-layout-upper-fixed">
            <nav class="config-breadcrambs">
                <ul>
                    <li><a>Breadcrumb 1</a></li>
                    <li><a>Breadcrumb 2</a></li>
                    <li><a>Applications</a></li>
                </ul>
            </nav><!-- /.config-breadcrambs -->
            <div class="config-title-n-tools">
                <div class="tnt-left">
                    <h1 data-icon-pl="&#xe010;">Applications</h1>
                </div><!-- /.tnt-left -->
                <div class="tnt-right">
                    <div class="nv-btn-box flow-end gap-8">
                        <a class="nv-btn small filled accent2" data-icon-pl="&#xe095;">Add Application</a>
                        <a class="nv-btn small filled white" data-icon-pl="&#xe91a;">Secondary Action</a>
                        <a class="nv-btn icon small filled white" data-icon-pl="&#xe602;"></a>
                    </div><!-- /.nv-btn-box flow-end gap-6 -->
                </div><!-- /.tnt-right -->
            </div><!-- /.config-title-n-tools -->
            <form class="config-search-bar-w-filters">
                <div class="sbwf-port">
                    <input type="text" name="">
                    <span class="sbwf-outline"></span>
                </div><!-- /.sbwf-port -->
                <div class="sbwf-filters">
                    <div class="sbwf-trigger">Filters</div>
                </div><!-- /.sbwf-filters -->
            </form><!-- /.config-search-bar-w-filters -->
            <div class="nv-config-filters-row">
                <span class="cfr-item">
                    Silver Trout
                    <a></a>
                </span>
                <span class="cfr-item">
                    Sea Bass
                    <a></a>
                </span>
                <span class="cfr-item">
                    Mackerel
                    <a></a>
                </span>
                <span class="cfr-item">
                    Extremely looong fish name
                    <a></a>
                </span>
                <span class="cfr-item">
                    Cod fish
                    <a></a>
                </span>
                <span class="cfr-control">
                    <a>Clear Filters</a> / <a>View All</a>
                </span>
            </div><!-- /.nv-config-filters-row -->
        </div><!-- /.config-layout-upper-fixed -->
        <div class="config-layout-lower-fixed">
            <section class="nv-section">
                <div class="nv-port">
                    <div class="nv-row gap-24 config-plates">
                        <div class="nv-column v-middle desk-12">
                            <div class="nv-column-content">
                                <div class="nv-conifg-table">
                                    <div class="ct-controls-bar">
                                        <div class="ct-control-bar-status">
                                            <p>4 items selected</p>
                                        </div><!-- /.ct-control-bar-status -->
                                        <nav class="ct-control-bar-tools">
                                            <ul>Application Name
                                                <li class="print"><a>Print</a></li>
                                                <li class="export"><a>Export</a></li>
                                                <li class="delete"><a>Delete</a></li>
                                                <li class="unselect"><a>Unselect All</a></li>
                                            </ul>
                                        </nav><!-- /.ct-control-bar-tools -->
                                    </div><!-- /.ct-controls-bar -->
                                    <table>
                                        <thead>
                                            <tr>
                                                <th class="w-checker">
                                                    <!--<span class="nv-row-checker unselect-on" title="deselect items"></span>-->
                                                    <span class="nv-row-checker select" title="select items"></span>
                                                    <!--<span class="nv-row-checker unselect"></span>-->
                                                </th>
                                                <th>
                                                    Application Name
                                                    <div class="nv-cell-sorter">
                                                        <span class="up"></span>
                                                        <span class="down"></span>
                                                    </div><!-- /.nv-cell-sorter -->
                                                </th>
                                                <th>
                                                    Type
                                                    <div class="nv-cell-sorter">
                                                        <span class="up"></span>
                                                        <span class="down"></span>
                                                    </div><!-- /.nv-cell-sorter -->
                                                </th>
                                                <th>
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="w-checker">
                                                    <span class="nv-row-checker" title="select item"></span>
                                                </td>
                                                <td>
                                                    e-Reader app
                                                </td>
                                                <td>
                                                    scanning
                                                </td>
                                                <td class="actions">
                                                    <div class="nv-cell-actions">
                                                        <span class="nv-cell-action-trigger"></span>
                                                        <nav>
                                                            <ul>
                                                                <li><a>delete</a></li>
                                                                <li><a>something</a></li>
                                                            </ul>
                                                        </nav>
                                                    </div><!-- /nv-cell-actions -->
                                                </td>
                                            </tr>
                                            <tr class="nv-row-selected">
                                                <td class="w-checker">
                                                    <span class="nv-row-checker checked" title="deselect item"></span>
                                                </td>
                                                <td>
                                                    e-Reader app
                                                </td>
                                                <td>
                                                    scanning
                                                </td>
                                                <td class="actions">
                                                    <div class="nv-cell-actions">
                                                        <span class="nv-cell-action-trigger"></span>
                                                        <nav>
                                                            <ul>
                                                                <li><a>delete</a></li>
                                                                <li><a>something</a></li>
                                                            </ul>
                                                        </nav>
                                                    </div><!-- /nv-cell-actions -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w-checker">
                                                    <span class="nv-row-checker checked" title="deselect item"></span>
                                                </td>
                                                <td>
                                                    e-Reader app
                                                </td>
                                                <td>
                                                    scanning
                                                </td>
                                                <td class="actions">
                                                    <div class="nv-cell-actions">
                                                        <span class="nv-cell-action-trigger"></span>
                                                        <nav>
                                                            <ul>
                                                                <li><a>delete</a></li>
                                                                <li><a>something</a></li>
                                                            </ul>
                                                        </nav>
                                                    </div><!-- /nv-cell-actions -->
                                                </td>
                                            </tr>
                                            <tr class="nv-row-selected">
                                                <td class="w-checker">
                                                    <span class="nv-row-checker checked" title="deselect item"></span>
                                                </td>
                                                <td>
                                                    e-Reader app
                                                </td>
                                                <td>
                                                    scanning
                                                </td>
                                                <td class="actions">
                                                    <div class="nv-cell-actions">
                                                        <span class="nv-cell-action-trigger"></span>
                                                        <nav>
                                                            <ul>
                                                                <li><a>delete</a></li>
                                                                <li><a>something</a></li>
                                                            </ul>
                                                        </nav>
                                                    </div><!-- /nv-cell-actions -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w-checker">
                                                    <span class="nv-row-checker" title="select item"></span>
                                                </td>
                                                <td>
                                                    e-Reader app
                                                </td>
                                                <td>
                                                    scanning
                                                </td>
                                                <td class="actions">
                                                    <div class="nv-cell-actions">
                                                        <span class="nv-cell-action-trigger on"></span><!-- toggle class 'on' -->
                                                        <nav>
                                                            <ul>
                                                                <li><a>delete</a></li>
                                                                <li><a>something</a></li>
                                                            </ul>
                                                        </nav>
                                                    </div><!-- /nv-cell-actions -->
                                                </td>
                                            </tr>
                                            <tr class="nv-row-selected">
                                                <td class="w-checker">
                                                    <span class="nv-row-checker checked" title="deselect item"></span>
                                                </td>
                                                <td>
                                                    e-Reader app
                                                </td>
                                                <td>
                                                    scanning
                                                </td>
                                                <td class="actions">
                                                    <div class="nv-cell-actions">
                                                        <span class="nv-cell-action-trigger"></span>
                                                        <nav>
                                                            <ul>
                                                                <li><a>delete</a></li>
                                                                <li><a>something</a></li>
                                                            </ul>
                                                        </nav>
                                                    </div><!-- /nv-cell-actions -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w-checker">
                                                    <span class="nv-row-checker" title="select item"></span>
                                                </td>
                                                <td>
                                                    e-Reader app
                                                </td>
                                                <td>
                                                    scanning
                                                </td>
                                                <td class="actions">
                                                    <div class="nv-cell-actions">
                                                        <span class="nv-cell-action-trigger"></span>
                                                        <nav>
                                                            <ul>
                                                                <li><a>delete</a></li>
                                                                <li><a>something</a></li>
                                                            </ul>
                                                        </nav>
                                                    </div><!-- /nv-cell-actions -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w-checker">
                                                    <span class="nv-row-checker" title="select item"></span>
                                                </td>
                                                <td>
                                                    e-Reader app
                                                </td>
                                                <td>
                                                    scanning
                                                </td>
                                                <td class="actions">
                                                    <div class="nv-cell-actions">
                                                        <span class="nv-cell-action-trigger"></span>
                                                        <nav>
                                                            <ul>
                                                                <li><a>delete</a></li>
                                                                <li><a>something</a></li>
                                                            </ul>
                                                        </nav>
                                                    </div><!-- /nv-cell-actions -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w-checker">
                                                    <span class="nv-row-checker" title="select item"></span>
                                                </td>
                                                <td>
                                                    e-Reader app
                                                </td>
                                                <td>
                                                    scanning
                                                </td>
                                                <td class="actions">
                                                    <div class="nv-cell-actions">
                                                        <span class="nv-cell-action-trigger"></span>
                                                        <nav>
                                                            <ul>
                                                                <li><a>delete</a></li>
                                                                <li><a>something</a></li>
                                                            </ul>
                                                        </nav>
                                                    </div><!-- /nv-cell-actions -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w-checker">
                                                    <span class="nv-row-checker" title="select item"></span>
                                                </td>
                                                <td>
                                                    e-Reader app
                                                </td>
                                                <td>
                                                    scanning
                                                </td>
                                                <td class="actions">
                                                    <div class="nv-cell-actions">
                                                        <span class="nv-cell-action-trigger"></span>
                                                        <nav>
                                                            <ul>
                                                                <li><a>delete</a></li>
                                                                <li><a>something</a></li>
                                                            </ul>
                                                        </nav>
                                                    </div><!-- /nv-cell-actions -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w-checker">
                                                    <span class="nv-row-checker" title="select item"></span>
                                                </td>
                                                <td>
                                                    e-Reader app
                                                </td>
                                                <td>
                                                    scanning
                                                </td>
                                                <td class="actions">
                                                    <div class="nv-cell-actions">
                                                        <span class="nv-cell-action-trigger"></span>
                                                        <nav>
                                                            <ul>
                                                                <li><a>delete</a></li>
                                                                <li><a>something</a></li>
                                                            </ul>
                                                        </nav>
                                                    </div><!-- /nv-cell-actions -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w-checker">
                                                    <span class="nv-row-checker" title="select item"></span>
                                                </td>
                                                <td>
                                                    e-Reader app
                                                </td>
                                                <td>
                                                    scanning
                                                </td>
                                                <td class="actions">
                                                    <div class="nv-cell-actions">
                                                        <span class="nv-cell-action-trigger"></span>
                                                        <nav>
                                                            <ul>
                                                                <li><a>delete</a></li>
                                                                <li><a>something</a></li>
                                                            </ul>
                                                        </nav>
                                                    </div><!-- /nv-cell-actions -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="w-checker">
                                                    <span class="nv-row-checker masked" title="select item"></span><!-- class 'masked' -->
                                                </td>
                                                <td>
                                                    <span class="masked">e-Reader app</span>
                                                </td>
                                                <td>
                                                    <span class="masked">scanning</span>
                                                </td>
                                                <td class="actions">
                                                    <div class="nv-cell-actions masked">
                                                        <span class="nv-cell-action-trigger"></span>
                                                        <nav>
                                                            <ul>
                                                                <li><a>delete</a></li>
                                                                <li><a>something</a></li>
                                                            </ul>
                                                        </nav>
                                                    </div><!-- /nv-cell-actions -->
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div class="nv-responsive-cell-sort">
                                        <span class="nv-row-checker" title="select all items"></span>
                                        <div class="nv-cell-sort-label">
                                            Application Name
                                            <div class="nv-cell-sorter">
                                                <span class="up"></span>
                                                <span class="down"></span>
                                            </div><!-- /.nv-cell-sorter -->
                                        </div><!-- /nv-cell-sort-label -->
                                    </div><!-- /.nv-responsive-cell-sort -->

                                    <div class="nv-responsive-row">
                                        <div class="nv-rr-thead">
                                            <span class="nv-row-checker" title="select item"></span>
                                            <div class="nv-cell-actions">
                                                <span class="nv-cell-action-trigger"></span>
                                                <nav>
                                                    <ul>
                                                        <li><a>delete</a></li>
                                                        <li><a>something</a></li>
                                                    </ul>
                                                </nav>
                                            </div><!-- /nv-cell-actions -->
                                        </div><!-- /.nv-rr-thead -->
                                        <div class="nv-rr-cell">
                                            <span>APPLICATION NAME</span>
                                            <p>ERA-EDTA 2016 congress app Android</p>
                                        </div><!-- /.nv-rr-cell -->
                                        <div class="nv-rr-cell">
                                            <span>TYPE</span>
                                            <p>iOS</p>
                                        </div><!-- /.nv-rr-cell -->
                                    </div><!-- /.nv-responsive-row -->

                                    <div class="nv-responsive-row">
                                        <div class="nv-rr-thead">
                                            <span class="nv-row-checker" title="select item"></span>
                                            <div class="nv-cell-actions">
                                                <span class="nv-cell-action-trigger"></span>
                                                <nav>
                                                    <ul>
                                                        <li><a>delete</a></li>
                                                        <li><a>something</a></li>
                                                    </ul>
                                                </nav>
                                            </div><!-- /nv-cell-actions -->
                                        </div><!-- /.nv-rr-thead -->
                                        <div class="nv-rr-cell">
                                            <span>APPLICATION NAME</span>
                                            <p>ERA-EDTA 2016 congress app Android</p>
                                        </div><!-- /.nv-rr-cell -->
                                        <div class="nv-rr-cell">
                                            <span>TYPE</span>
                                            <p>iOS</p>
                                        </div><!-- /.nv-rr-cell -->
                                    </div><!-- /.nv-responsive-row -->
                                </div><!-- /.nv-conifg-table -->

                                <div class="nv-starting-placeholder apps">
                                    <div class="nv-sp-icon"></div><!-- /.nv-sp-icon -->
                                    <div class="nv-sp-txt">
                                        <h2>No applications!</h2>
                                        <p>Once you add applications, you’ll see them here.</p>
                                        <a class="nv-btn big filled accent1">Add Application</a>
                                    </div><!-- /.nv-sp-txt -->
                                </div><!-- /.nv-starting-placeholder -->

                                <div class="nv-starting-placeholder delegates">
                                    <div class="nv-sp-icon"></div><!-- /.nv-sp-icon -->
                                    <div class="nv-sp-txt">
                                        <h2>No delegates!</h2>
                                        <p>Once you add delegates, you’ll see them here.</p>
                                        <a class="nv-btn big filled accent1">Add Delegates</a>
                                    </div><!-- /.nv-sp-txt -->
                                </div><!-- /.nv-starting-placeholder -->

                                <div class="nv-starting-placeholder events">
                                    <div class="nv-sp-icon"></div><!-- /.nv-sp-icon -->
                                    <div class="nv-sp-txt">
                                        <h2>No events!</h2>
                                        <p>Once you add events, you’ll see them here.</p>
                                        <a class="nv-btn big filled accent1">Add Events</a>
                                    </div><!-- /.nv-sp-txt -->
                                </div><!-- /.nv-starting-placeholder -->

                                <div class="nv-starting-placeholder articles">
                                    <div class="nv-sp-icon"></div><!-- /.nv-sp-icon -->
                                    <div class="nv-sp-txt">
                                        <h2>No articles!</h2>
                                        <p>Once you add articles, you’ll see them here.</p>
                                        <a class="nv-btn big filled accent1">Add Articles</a>
                                    </div><!-- /.nv-sp-txt -->
                                </div><!-- /.nv-starting-placeholder -->

                                <div class="nv-starting-placeholder advertising">
                                    <div class="nv-sp-icon"></div><!-- /.nv-sp-icon -->
                                    <div class="nv-sp-txt">
                                        <h2>No advertisings!</h2>
                                        <p>Once you add advertisings, you’ll see them here.</p>
                                        <a class="nv-btn big filled accent1">Add Advertising</a>
                                    </div><!-- /.nv-sp-txt -->
                                </div><!-- /.nv-starting-placeholder -->

                                <div class="nv-starting-placeholder files">
                                    <div class="nv-sp-icon"></div><!-- /.nv-sp-icon -->
                                    <div class="nv-sp-txt">
                                        <h2>No files!</h2>
                                        <p>Once you add files, you’ll see them here.</p>
                                        <a class="nv-btn big filled accent1">Add Files</a>
                                    </div><!-- /.nv-sp-txt -->
                                </div><!-- /.nv-starting-placeholder -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div><!-- /.nv-row -->
                </div><!-- /.nv-port -->
            </section><!-- /.nv-section -->
        </div><!-- /.config-layout-lower-fixed -->
    </div><!-- /.config-layout-main-frame -->
</div><!-- /.nv-configurator-layout -->


<!-- Filters open -->
<div class="nv-page-overlay"></div>

<div class="nv-side-filters-panel">
    <header class="sfp-header">
        <span class="sfp-close"></span>
        <h3>Add Filters</h3>
        <p>Changes you make apply to data table.</p>
    </header><!-- /.sfp-header -->
    <div class="sfp-body">
        <div class="fb-filter-chunk">
            <h4 class="nv-toggle-label on">
                <span>Application Type</span>
            </h4><!-- /.nv-toggle-label -->
            <div class="fb-filter-box">
                <ul>
                    <li>
                        <div class="fb-filter">
                            <span class="nv-row-checker"></span>
                            App
                        </div><!-- /.fb-filter -->
                    </li>
                    <li>
                        <div class="fb-filter">
                            <span class="nv-row-checker"></span>
                            Web
                        </div><!-- /.fb-filter -->
                    </li>
                    <li>
                        <span class="fb-sub-toggler"></span>
                        <div class="fb-filter">
                            <span class="nv-row-checker checked"></span>
                            Scanning
                        </div><!-- /.fb-filter -->
                        <ul>
                            <li>
                                <div class="fb-filter">
                                    <span class="nv-row-checker"></span>
                                    Web
                                </div><!-- /.fb-filter -->
                            </li>
                            <li>
                                <div class="fb-filter">
                                    <span class="nv-row-checker checked"></span>
                                    Scanning
                                </div><!-- /.fb-filter -->
                            </li>
                            <li>
                                <div class="fb-filter">
                                    <span class="nv-row-checker"></span>
                                    Value
                                </div><!-- /.fb-filter -->
                            </li>
                        </ul>
                    </li>
                    <li>
                        <div class="fb-filter">
                            <span class="nv-row-checker"></span>
                            Value
                        </div><!-- /.fb-filter -->
                    </li>
                    <li>
                        <div class="fb-filter">
                            <span class="nv-row-checker"></span>
                            Second Value
                        </div><!-- /.fb-filter -->
                    </li>
                    <li>
                        <a class="fb-more">[+] More</a><!-- /.fb-more -->
                    </li>
                </ul>
            </div><!-- /.fb-filter-box -->
        </div><!-- /.fb-filter-chunk -->

        <div class="fb-filter-chunk">
            <h4 class="nv-toggle-label">
                <span>Filter name</span>
            </h4><!-- /.nv-toggle-label -->
            <div class="fb-filter-box">
            </div><!-- /.fb-filter-box -->
        </div><!-- /.fb-filter-chunk -->

        <div class="fb-filter-chunk">
            <h4 class="nv-toggle-label on">
                <span>Filter name</span>
            </h4><!-- /.nv-toggle-label -->
            <div class="fb-filter-box">
                <div class="nv-form-item nv-select-item">
                    <label class="nv-control-display nv-touched">
                        <span class="nv-display-value">Just an select</span>
                        <span aria-label="label-title" class="label-title">Default select</span>
                    </label>
                </div><!-- /nv-form-item -->
            </div><!-- /.fb-filter-box -->
        </div><!-- /.fb-filter-chunk -->

        <div class="fb-filter-chunk">
            <h4 class="nv-toggle-label on">
                <span>Filter name</span>
            </h4><!-- /.nv-toggle-label -->
            <div class="fb-filter-box">
                <div class="nv-row gap-12">
                    <div class="nv-column desk-6">
                        <div class="nv-column-content">
                            <div class="nv-form-item">
                                <label class="nv-not-mandatory">
                                    <input placeholder="proba" type="text" class="">
                                    <span aria-label="label-title" class="label-title">From</span>
                                </label>
                            </div>
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column desk-6 -->
                    <div class="nv-column desk-6">
                        <div class="nv-column-content">
                            <div class="nv-form-item">
                                <label class="nv-not-mandatory">
                                    <input placeholder="proba" type="text" class="">
                                    <span aria-label="label-title" class="label-title">To</span>
                                </label>
                            </div>
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column desk-6 -->
                </div><!-- /.nv-row gap-12 -->
            </div><!-- /.fb-filter-box -->
        </div><!-- /.fb-filter-chunk -->
    </div><!-- /.sfp-body -->
    <footer class="sfp-footer">
        <label>
            <a href="">Clear Filters</a>
        </label>
        <div class="sfp-btns">
            <a href="" class="nv-btn small filled neutral">Cancel</a>
            <a href="" class="nv-btn small filled accent1">Apply</a>
        </div><!-- /.sfp-btns -->
    </footer><!-- /.sfp-footer -->
</div><!-- /.nv-side-filters-panel -->
