

<section class="nv-section guttered nv-program-light-section">
    <div class="nv-port">
        <div class="nv-row large-spaced-top semi-spaced-bottom gap-40 v-stretch">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <header class="nv-program-light-header">
                        <div class="nv-program-light-heading">
                            <h1>Pfizer Research Prize Foundation</h1>
                            <h4>30<sup>th</sup>  Pfizer Research Prize</h4>
                        </div>
                        <figure>
                            <img src="https://service.e-materials.com/storage/resources/pfizer_ag/pfi/pfizer-logo.png" alt="Pfizer Research">
                        </figure>
                    </header>
                </div><!-- /.nv-column-content -->
            </div><!-- /nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row spaced-top large-spaced-bottom gap-40 v-stretch">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <h2 class="nv-program-light-title"><span>Program</span></h2>

                    <div class="nv-program-light-item">
                        <h3>Welcome and introduction</h3>
                        <h5>Dr. med. Rahel Troxler Saxer</h5>
                        <ul>
                            <li>President of the Pfizer Research Prize Foundation</li>
                            <li>Medical Director Switzerland Pfizer AG</li>
                        </ul>
                        <h5>Sabine Bruckner</h5>
                        <ul>
                            <li>Member of the Pfizer Research Prize Foundation</li>
                            <li>Country Manager Switzerland Pfizer AG</li>
                        </ul>
                    </div>

                    <div class="nv-program-light-item">
                        <h3>Awardees 2021</h3>
                        <h4>CARDIOVASCULAR, UROLOGY AND NEPHROLOGY</h4>
                        <h5>PD Dr. Ange Maguy, PD Dr. Jin Li</h5>
                        <ul>
                            <li>University of Bern, CHUV Lausanne</li>
                        </ul>

                        <h5>Dr. Nikola Kozhuharov</h5>
                        <ul>
                            <li>University Hospital Basel</li>
                        </ul>
                    </div>

                    <div class="nv-program-light-item">
                        <h3>INFECTIOLOGY, RHEUMATOLOGY AND IMMUNOLOGY</h3>
                        <h5>Dr. Cristina Gil-Cruz, Dr. Christian Pérez-Shibayama, Dr. Veronika Nindl</h5>
                        <ul>
                            <li>Cantonal Hospital St. Gallen</li>
                        </ul>
                    </div>

                    <div class="nv-program-light-item">
                        <h3>NEUROSCIENCE AND DISEASES OF THE NERVOUS SYSTEM</h3>
                        <h5>Dr. Dasha Nelidova</h5>
                        <ul>
                            <li>Institute of Molecular and Clinical Ophthalmology Basel (IOB) and Friedrich Miescher Institute for Biomedical Research, Basel</li>
                        </ul>

                        <h5>Dr. Michael Hugelshofer, Dr. Raphael Buzzi</h5>
                        <ul>
                            <li>University Hospital Zurich</li>
                        </ul>
                    </div>

                    <div class="nv-program-light-item">
                        <h3>ONCOLOGY</h3>
                        <h5>Dr. Chloe Chong, Dr. Michal Bassani-Sternberg</h5>
                        <ul>
                            <li>Ludwig Institute for Cancer Research, Lausanne, CHUV and University of Lausanne</li>
                        </ul>

                        <h5>Prof. Carsten Riether</h5>
                        <ul>
                            <li>University Hospital and University of Bern</li>
                        </ul>
                    </div>
                </div><!-- /.nv-column-content -->
            </div><!-- /nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</section><!-- /.nv-section -->

