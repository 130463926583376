import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, of } from 'rxjs';
import { Organization } from '../classes/organization';
import { map } from 'rxjs/operators';

@Injectable()
export class OrganizationService {
  private currentOrganizationSubject = new BehaviorSubject<Organization>(null as Organization);
  public currentOrganization = this.currentOrganizationSubject.asObservable();

  constructor(
    private apiService: ApiService
  ) { }

  getMyOrganizations(params: any = {}) {
    return this.apiService.get(`users/me/organizations`, params);
  }

  getOrganizations(params: any = {}) {
    return this.apiService.get(`organizations`, params);
  }

  getOrganization(organizationId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}`, params);
  }

  updateOrganization(organizationId: number, params: any = {}) {
    return this.apiService.put(`organizations/${organizationId}`, params);
  }

  createOrganization(params: any = {}) {
    return this.apiService.post(`organizations`, params);
  }

  deleteOrganization(organizationId: number) {
    return this.apiService.delete(`organizations/${organizationId}`);
  }

  getOrganizationFeed(organizationId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/feeds`, params);
  }

  setCurrentOrganization(organizationId: number, reload: boolean = false) {
    if (organizationId) {
      if (!reload) {
        if (this.currentOrganizationSubject.value && this.currentOrganizationSubject.value.id === +organizationId) {
          return of(null);
        }
      }

      return this.apiService.get(`organizations/${organizationId}`, { include: 'package' })
        .pipe(map(response => {
          this.currentOrganizationSubject.next(response.data);
          return response.data;
        }));
    } else {
      this.currentOrganizationSubject.next(null);
      return of(null);
    }
  }

  getCategories(params: any = {}) {
    return this.apiService.get(`organizations/categories`, params);
  }

}
