<!-- Of Air chunk -->
<div class="nv-off-air-iface">
    <div class="nv-off-air-iface-top">
        <h3>Off Air Settings</h3>
    </div><!-- /.nv-off-air-iface-top -->
    <div class="nv-off-air-iface-body" *ngIf="offAirs?.length">

        <div class="nv-ls-feature-panel-placeholder" *ngIf="activatingOffAir">
            <span class="nv-ls-feature-panel-loading"><em></em>Loading. Please wait.</span>
        </div>

        <div class="nv-off-air-if-port" *ngIf="!activatingOffAir">
            <form>
                <ul class="nv-off-air-control">
                    <li *ngFor="let offAir of offAirs" [ngClass]="{'active': location?.activeLivestreamOffair?.id === offAir?.id}">
                        <div class="nv-form-item nv-radio-item">
                            <label>
                                <span class="nri-radio-port">
                                    <input type="radio" name="type" [value]="offAir" [(ngModel)]="selectedOffAir">
                                    <span class="nri-radio-visual"></span>
                                </span><!-- /.nri-radio-port -->
                            </label>
                        </div>
                        <h4 (click)="openOffAirEditModal(offAir)"><span>{{offAir.title}}</span></h4>
                    </li>
                </ul>
            </form>
        </div><!-- /.nv-off-air-if-port -->
    </div><!-- /.nv-off-air-iface-body -->
    <div class="nv-off-air-iface-bottom">
        <ul class="nv-off-air-iface-control">
            <li><a (click)="openOffAirEditModal()" title="Add" class="add">Add</a></li>
            <li><a [ngClass]="{'disabled': !selectedOffAir}" (click)="openOffAirEditModal(selectedOffAir)" title="Edit" class="edit">Edit</a></li>
            <li><a (click)="activateOffAir()" title="Activate" class="activate">Activate</a></li>
            <li><a (click)="deleteOffAir()" title="Delete" class="delete">Delete</a></li>
        </ul><!-- /.nv-off-air-iface-control -->
    </div><!-- /.nv-off-air-iface-bottom -->
</div><!-- /.nv-off-air-iface -->
