<div class="nv-form-item nv-color-picker">
    <label class="{{ required ? 'nv-mandatory' : 'nv-not-mandatory' }}">
    <span  *ngIf="required" aria-hidden="true"></span>
        <input
               nvInputDirective
               (colorPickerSelect)= "onConfirm($event)"
               (colorPickerCancel) = "onCancel($event)"
               (colorPickerClose) = "onCancel($event)"
               (colorPickerChange)="displayError()"
               (cpToggleChange)="cpShow = !cpShow"
               [ngClass]="{'open': cpShow}"
               [(ngModel)]="value"
               (ngModelChange)="onChange(value)"
               [(colorPicker)]="value"
               [cpOKButton]="confirmButton"
               [cpOKButtonText]="confirmButtonText"
               [cpOKButtonClass]= "'nv-btn tiny'"
               [cpCancelButton]="cancelButton"
               [cpCancelButtonText]="cancelButtonText"
               [cpCancelButtonClass]= "'nv-btn tiny'"
               [cpSaveClickOutside]="false"
               [disabled]="disabled"
               [cpPosition]="position"
               [cpPositionOffset]="positionOffset"/>
        <span aria-label="label-title" class="label-title">{{ label }}</span>
    </label>

    <div class="nv-input-info-box">
        <p
            style="width: 18px;height: 18px; border-radius: 50%; position: absolute; right: 0;"
            [style.background]="value">
        </p>
    </div>

    <div *ngIf="errors.length > 0" class="nv-input-error-box">
        <div *ngIf="errors.length === 1" class="nv-input-error single">
            <div>{{ errorMessages[errors[0]] || errors[0] }}</div>
        </div>
        <div *ngIf="errors.length > 1" class="nv-input-error multi">
            <p (click)="toggleErrors()">
                <span>You have {{ errors.length }} errors</span>
            </p>
            <div *ngIf="showErrors">
                <ul>
                    <li *ngFor="let error of errors">
                        {{ errorMessages[error] || error }}
                    </li>
                </ul>
            </div>
        </div>
    </div><!-- /nv-input-error-box -->
</div>
