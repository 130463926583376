import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {FileUploadModule} from 'ng2-file-upload';

import {
    ActionMenuComponent,
    ActionMenuDropdownDirective,
    ActionMenuOriginDirective,
    DropdownContainerComponent,
    ModalComponent,
} from './components';
import {HighlightPipe, MomentPipe, SearchFilterPipe, TransformChar, TruncatePipe} from './pipes';
import {InputFilledDirective} from './input-filled.directive';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,

        SlickCarouselModule,
        FileUploadModule,
    ],
    declarations: [
        ActionMenuDropdownDirective,
        ActionMenuOriginDirective,
        InputFilledDirective,
        ActionMenuComponent,
        ModalComponent,
        DropdownContainerComponent,
        HighlightPipe,
        MomentPipe,
        TransformChar,
        SearchFilterPipe,
        TruncatePipe
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ActionMenuDropdownDirective,
        ActionMenuOriginDirective,
        InputFilledDirective,
        ActionMenuComponent,
        ModalComponent,
        DropdownContainerComponent,
        RouterModule,
        HighlightPipe,
        MomentPipe,
        TransformChar,
        SearchFilterPipe,
        TruncatePipe
    ],
    entryComponents: [
        ActionMenuComponent,
        ModalComponent,
        DropdownContainerComponent
    ],
    providers: [
        MomentPipe,
    ]
})
export class SharedModule {
}
