import { Conference } from './conference';
import { Presentation } from '@navus/core/classes/presentation';

export class Location {
  id: number;
  imported_id: string;
  name: string;
  order: number;
  type: string = 'Room';
  color: string;
  floor: number;
  x_coord: number;
  y_coord: number;
  rtmp: string;
  settings?: { source_type: 'internal' | 'external' };

  current_presentation_id: number;
  stream_url: string;
  stage_url: string;

  autopilot: number;

  conference_id: number;
  conference?: Conference;

  streaming_presentations?: Presentation[];
  status: 'offair' | 'onair';
  activeLivestreamOffair?: {
    id: number;
    description: string;
    title: string;
    resource_type?: 'materials' | 'resources' | 'external';
    resource_value?: string;
  };
}
