export class Notification {
  id: number;
  title: string;
  type: string;
  entity_id: string;
  body: string;
  schedule_time: string;
  scope: string;
  sent: boolean;
  sent_number: number;
  receivers_number: number;
  status: 'draft' | 'published' | 'archived';
  channel: 'web' | 'app' | 'all';
  place: 'notification_bar' | 'popup' | 'video_overlay';
  read: boolean;
}
