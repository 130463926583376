<div id="scroll-anchor"></div>
<section class="nv-section guttered nv-live-stream-video"
         *ngIf="currentLocation && currentLocation.currentPresentation">
    <div class="nv-port">
        <!--<main class="nv-row gap-30 v-stretch large-spaced" *ngIf="!currentLocation.currentPresentation.webcast || !currentLocation.currentPresentation.webcast.id">-->
        <main class="nv-row gap-30 v-stretch spaced">
            <div class="nv-column desk-8 tab-12">
                <div class="nv-column-content">
                    <!--<div class="nv-video-port" [ngClass]="{ 'no-access': !currentUser || !currentDelegate }" *ngIf="currentLocation && currentLocation.currentPresentation.featured_block">
                         <nv-meeting #meeting
                                   [buttonLabel]="'Enter Break-out Session'"
                                   [roomName]="currentLocation.id+'/BreakOutSession'+currentLocation.id"
                                   [title]="'BreakOutSession'"
                                   [speakerName]="currentUser.first_name + ' ' + currentUser.last_name"
                                   [speakerImage]="currentUser.image_url"
                           ></nv-meeting>
                    </div>-->
                    <div *ngIf="loadingStream" style="min-height: 400px;" class="nv-loading">
                        <img src="../../assets/aspect-16-9.png" alt="" class="nv-aspect" aria-hidden="true">
                        <div class="nv-loading-visual" translate>Loading</div>
                    </div>

                    <div *ngIf="streamingURL!==null && !loadingStream && hasAccess && currentLocation"
                         class="nv-video-port">
                        <img src="../../assets/aspect-16-9.png" alt="" class="nv-aspect" aria-hidden="true">


                        <!--if youtube-->
                        <ng-container
                                *ngIf="!currentLocation.currentPresentation.webcast || !currentLocation.currentPresentation.webcast.id">
                            <iframe [src]="streamingURL" width="640" height="360" frameborder="0"
                                    allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                            <!--<iframe id="regular-ls"  width="100%" height="100%"
                                [src]="streamingURL"
                               allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
                               sandbox="allow-same-origin allow-forms allow-scripts allow-presentation"
                               allow="autoplay; encrypted-media"
                               >
                            </iframe>-->
                        </ng-container>

                        <ng-container
                                *ngIf="currentLocation.currentPresentation.webcast && currentLocation.currentPresentation.webcast.id">
                            <iframe *ngIf="currentLocation.currentPresentation.webcast.external_url"
                                    [src]="streamingURL" width="640" height="360" frameborder="0"
                                    allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

                            <video *ngIf="!currentLocation.currentPresentation.webcast.external_url" controls autoplay
                                   controlsList="nodownload noremoteplayback" style="width: 100%; height: 100%;"
                                   src="//service.e-materials.com/api/materials/{{currentLocation.currentPresentation.webcast.id}}/view?token={{currentUser?.token}}"
                            ></video>
                        </ng-container>


                    </div>
                    <div *ngIf="(streamingURL===null || !loadingStream) && !hasAccess && currentLocation"
                         class="nv-video-port no-access">
                        <img src="../../assets/aspect-16-9.png" alt="" class="nv-aspect" aria-hidden="true">
                        <div class="get-access">
                            <button class="nv-btn accent1 medium" (click)="onVideoClick()" translate>Get Access</button>
                        </div>
                    </div>
                    <div class="nv-divider tab-small"></div><!-- /.nv-divider -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column desk-12 -->


            <div class="nv-column desk-4 tab-12 nv-live-stream-tabs">
                <div class="nv-column-content">

                    <div class="nv-row gap-30 v-stretch spaced">
                        <div class="nv-column desk-12 tab-12">
                            <div class="nv-column-content">
                                <div class="nv-line-tabs">
                                    <nav class="nv-tabs-nav">
                                        <ul>
                                            <li>
                                                <a (click)="showTab('info')" [class.active]="selectedTab === 'info'">Info</a>
                                            </li>
                                            <li class="tab-info">
                                                <span class="info-toggler"></span>
                                            </li>
                                            <li *ngIf="configuration.conferenceId!=8353 && configuration.conferenceId!=8348">
                                                <a (click)="showTab('voting')" [class.active]="selectedTab === 'voting'">Voting</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div *ngIf="selectedTab === 'voting'" class="nv-tab-content">
                                    <div *ngIf="currentVotingQuestion" class="voting in-tab">
                                        <h2>Live voting</h2>
                                        <nv-voting-question [conferenceId]="configuration.conferenceId"
                                                            [votingQuestion]="currentVotingQuestion"></nv-voting-question>
                                    </div>
                                    <div *ngIf="!currentVotingQuestion" class="voting in-tab">
                                        <strong>Once activated the Voting question will appear here and you will be able to
                                            vote</strong>
                                    </div>
                                </div>

                                <div *ngIf="selectedTab === 'info'" class="nv-tab-content">
                                    <div class="nv-live-stream-active-label"
                                        *ngIf="currentLocation.currentPresentation.type!=='Break'">
                                        <h4 class="nv-clickable"
                                            (click)="openPresentations(currentLocation.currentPresentation.id)">
                                            <b><u>Session</u></b> {{ currentLocation.currentPresentation.block_name }}</h4>
                                        <h1 class="nv-clickable"
                                            (click)="openPresentations(currentLocation.currentPresentation.id)">{{ currentLocation.currentPresentation.title }}</h1>
                                        <div *ngIf="configuration.conferenceId!=8353 && currentLocation.currentPresentation.speaker_name"
                                            class="lsal-author">
                                            <ng-container *ngFor="let speaker of currentLocation.currentPresentation.authors; let isFirst=first" >
                                                <ng-container *ngIf="!isFirst">&nbsp;&nbsp;</ng-container>
                                                <div class="la-img"
                                                    [style.backgroundImage]="speaker.image_url_thumb ? 'url('+ speaker.image_url_thumb +')' : ''">
                                                </div>
                                                <p><a class="nv-clickable"
                                                    (click)="openModal(speaker)">{{ speaker.name }}</a></p>
                                            </ng-container>
                                        </div>

                                        <ng-container
                                                *ngIf="configuration.conferenceId==8353 && currentLocation.currentPresentation?.authors && currentLocation.currentPresentation?.authors.length>0">
                                            <div class="lsal-author">Chair:
                                                <div style="margin-left: 10px;" class="la-img" [style.backgroundImage]="currentLocation.currentPresentation.speaker_image_thumb ? 
                                                'url('+ currentLocation.currentPresentation.speaker_image_thumb +')' : ''"></div>
                                                <p><a class="nv-clickable"
                                                    (click)="openModal(currentLocation.currentPresentation?.authors[0])">{{ currentLocation.currentPresentation?.authors[0]?.name }}</a>
                                                </p>
                                            </div>

                                            <div *ngIf="(currentLocation.currentPresentation.authors && currentLocation.currentPresentation.authors.length>1)"
                                                class="lsal-author" style="align-items: normal;">
                                                Panelists:
                                                <p style="margin: 0;
                                                font-size: 14px;
                                                line-height: 1.6;
                                                padding: 1px 0 0px 10px;">
                                                    <ng-container
                                                            *ngFor="let speaker of currentLocation.currentPresentation.authors; index as i">

                                                        <span *ngIf="i>0"><a class="nv-clickable"
                                                                            (click)="openModal(speaker)">{{ speaker?.name }};</a>&nbsp;</span>

                                                    </ng-container>
                                                </p>
                                            </div>
                                        </ng-container>

                                        <div class="live-stream-meta">
                                            <p class="lsm-venue">
                                                <span class="lsm-color-dot"></span> <span
                                                    translate>Live in</span> {{ currentLocation.name }}
                                                <em> {{ currentLocation.currentPresentation.starts_at | dateFormat:'HH:mm' }}
                                                    - {{ currentLocation.currentPresentation.ends_at | dateFormat:'HH:mm' }}                                     </em>
                                                </p>
                                                <p [innerHTML]="currentLocation.currentPresentation.block_subtitle">
                                                </p>
                                        </div>
                                    </div>
                                    <div class="nv-live-stream-active-label"
                                        *ngIf="currentLocation.currentPresentation.type==='Break'">
                                        <h1>{{ currentLocation.currentPresentation.title }}</h1>

                                        <div class="live-stream-meta">
                                            <p class="lsm-venue">
                                                <span class="lsm-color-dot"></span> <span
                                                    translate>Break in</span> {{ currentLocation.name }}
                                            </p>
                                            <br>
                                            <div class="ph-meta">
                                                <span class="phm-time-frame">
                                                    {{ currentLocation.currentPresentation.starts_at | dateFormat:'HH:mm' }}
                                                    - {{ currentLocation.currentPresentation.ends_at | dateFormat:'HH:mm' }}
                                                </span>
                                            </div>
                                        </div>
                                        <br>
                                        <strong>Next session
                                            at: {{ currentLocation.currentPresentation.ends_at | dateFormat:'HH:mm' }}</strong>
                                        <!--<strong *ngIf="!isToday(currentLocation.currentPresentation.ends_at)">Live program starts at: {{ currentLocation.currentPresentation.ends_at | dateFormat:'MMM d HH:mm' }}</strong>-->
                                        <!--<strong *ngIf="counter.sec>0 || counter.min>0">Live in {{counter.min}} : {{counter.sec}}</strong>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</section>

<section class="nv-section guttered nv-chat-section" *ngIf="currentLocation && qaSettings?.enabled"><!-- -->
    <div class="nv-port">
        <main class="nv-row flow-center spaced">
            <div class="nv-column desk-8 tab-12 studio-location-questions">
                <div class="nv-column-content">
                    <!-- <nv-checkbox 
                        [label]="'Automatically change session on start of the session' | translate"
                        [(ngModel)]="autoSelectBlock"
                    ></nv-checkbox><br> -->
                    <div class="nv-live-suite-interactions-panels">
                        <div class="nv-live-suite-interactions-bar">
                            <h2>Q&A in track: {{currentLocation?.name}}</h2>
                            <div class="nv-live-suite-interactions-states">
                                <div class="nv-live-suite-interaction-state expand"
                                     [class.active]="chatMode === 'expand'"
                                     (click)="chatMode = 'expand'"
                                >
                                    <span>expand</span>
                                </div>
                                <div class="nv-live-suite-interaction-state minimal"
                                     [class.active]="chatMode === 'minimal'"
                                     (click)="chatMode = 'minimal'"
                                >
                                    <span>minimal</span>
                                </div>
                                <div class="nv-live-suite-interaction-state collapse"
                                     [class.active]="chatMode === 'collapse'"
                                     (click)="chatMode = 'collapse'"
                                >
                                    <span>collapse</span>
                                </div>
                            </div>
                        </div>
                        <div class="nv-live-suite-interactions-panes" [ngClass]="chatMode">
                            <nav class="nv-live-suite-interactions-pane sessions">
                                <ul>
                                    <ng-container *ngFor="let block of blocks">
                                        <ng-container
                                                *ngIf="block.startTimestamp <= (currentBlockTimestamp || currentTimestamp)">
                                            <li
                                                    [class.active]="block.id === selectedBlock?.id"
                                                    [class.disabled]="!block.allow_qa"
                                                    [title]="block.name"
                                                    (click)="selectBlock(block)"
                                            >
                                                <h4>{{ block.name }}</h4>
                                                <p>
                                                    <span>{{ block.starts_at | nvDate:'dd.MM.yyyy | HH:mm - ' }} {{ block.ends_at | nvDate:'HH:mm' }}</span>
                                                    <span>{{currentLocation?.name}}</span>
                                                </p>
                                            </li>
                                        </ng-container>
                                    </ng-container>
                                </ul>
                            </nav>
                            <div class="nv-live-suite-interactions-pane qa">
                                <div class="nv-live-suite-qa-tabs">
                                    <div class="nv-live-suite-qa-label">Q&A</div>
                                    <div *ngIf="configuration.conferenceId != 8348 && (qaSettings?.public_tab && selectedBlock?.q_and_a?.public)"
                                         class="nv-live-suite-qa-tab" [class.active]="chatTab === 'true'"
                                         (click)="chatTab = 'true'">Public
                                    </div>
                                    <div class="nv-live-suite-qa-tab" [class.active]="chatTab === 'mine'"
                                         (click)="chatTab = 'mine'">My questions
                                    </div>
                                </div>
                                <ng-container *ngIf="selectedBlock && selectedBlock.allow_qa">
                                    <ng-container *ngIf="qaSettings?.moderation && selectedBlock.q_and_a?.moderation">
                                        <nv-chat
                                                [eventId]="configuration.conferenceId"
                                                [roomId]="'block_' + selectedBlock?.id"
                                                [currentUserId]="currentUser?.id"
                                                [replies]="true"
                                                [canReply]="false"
                                                [approval]="false"
                                                [canApprove]="false"
                                                [approveStatus]="chatTab"
                                                [usecase]="'Q&A'"
                                        ></nv-chat>
                                    </ng-container>
                                    <ng-container *ngIf="!(qaSettings?.moderation && selectedBlock.q_and_a?.moderation)">
                                        <nv-chat
                                                [eventId]="configuration.conferenceId"
                                                [roomId]="'block_' + selectedBlock?.id"
                                                [currentUserId]="currentUser?.id"
                                                [replies]="chatTab==='true'"
                                                [canReply]="chatTab==='false'"
                                                [approval]="chatTab==='true'"
                                                [canApprove]="chatTab==='false'"
                                                [approveStatus]="chatTab==='true'? 'all' : 'mine'"
                                                [usecase]="'Q&A'"
                                        ></nv-chat>
                                    </ng-container>
                                    <div class="nv-live-suite-qa-input-btn" *ngIf="chatTab === 'true'">
                                        <button class="nv-btn accent1 small" (click)="chatTab = 'mine'">Ask a
                                            question
                                        </button>
                                    </div>
                                </ng-container>
                                <div *ngIf="selectedBlock && !selectedBlock.allow_qa" class="nv-chat-idle">
                                    <span>Q&A not available for this session.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- /nv-column-content-->
            </div>
        </main>
    </div>
</section>

<section class="nv-section guttered" *ngIf="locations && locations.length>1">
    <div class="nv-port">
        <div class="nv-row gap-30 v-stretch spaced-top small-spaced-bottom">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <h2 class="nv-slide-adjusted" *ngIf="!currentLocation" translate>Streaming presentations</h2>
                </div>
            </div>
        </div>
        <div class="nv-row gap-30 small-spaced-top large-spaced-bottom">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <ngx-slick-carousel class="nv-current-streams carousel" #slickModal="slick-carousel"
                                        [config]="slideLiveSuiteConfig">

                        <ng-container *ngFor="let location of locations">
                        <div class="cs-item slide thumbless" ngxSlickItem
                            *ngIf="location.id !== currentLocation?.id"
                             >
                            <ng-container *ngIf="location.id !== currentLocation?.id">
                                <div class="cs-item-frame">
                                    <div class="cs-video-port" *ngIf="location.currentPresentation"
                                         [ngClass]="{ 'no-access': !currentUser || !currentDelegate }"
                                         (click)="onClickStream(location)">
                                        <img alt="" aria-hidden="true" class="aspect"
                                             src="../../../../assets/aspect-16-9.png">
                                        <!--<iframe style="width: 100%; height: 100%; position: absolute; top: 0; border: none" [src]="location.stream_url" (click)="onVideoClick()"></iframe>-->
                                        <a title="{{location.currentPresentation.title}}"></a>
                                        <!--div class="cs-play"></div-->
                                        <div class="cs-label">
                                            <div class="cs-label-elements">
                                                <p>
                                                    <span class="cs-dot"></span> <span
                                                        translate>Live in</span> {{ location.name }}
                                                </p>
                                                <em><span
                                                        translate>Started at</span> {{ location.currentPresentation.starts_at | dateFormat:'HH:mm A' }}
                                                </em>
                                            </div><!-- /.cs-label-elements -->
                                        </div>
                                        <div class="get-access">
                                            <button class="nv-btn  accent1 small" data-icon-pl="&#xe930;" (click)="onClickStream(location)"
                                                    translate>Watch Now
                                            </button>
                                        </div>
                                    </div>
                                    <div class="cs-video-port thumbless no-presentation-poster"
                                         *ngIf="!location.currentPresentation">
                                        <img alt="" class="aspect" src="../../../../assets/aspect-16-9.png">
                                    </div>
                                    <header class="cs-meta" *ngIf="location.currentPresentation"
                                            (click)="onClickStream(location)">
                                        <h4><b><u>Session</u></b> {{ location.currentPresentation.block_name }}</h4>
                                        <h2><a>{{ location.currentPresentation.title }}</a></h2>
                                        <p>{{ location.currentPresentation.speaker_name }}</p>
                                    </header>
                                    <header class="cs-meta" *ngIf="!location.currentPresentation">
                                        <h2 class="text-center" translate><span
                                                translate>There is no currently active</span> <br> <span translate>presentation in</span> {{ location.name }}
                                        </h2>
                                    </header>
                                    <div class="cs-upcoming" *ngIf="location.nextPresentation">
                                        <p>
                                            <strong translate>UPCOMING</strong> -
                                            {{ location.nextPresentation.starts_at | nvDate:'dd.MM.yyyy HH:mm' }}
                                        </p>
                                        <div class="csu-label">
                                            <div class="csu-img"
                                                 [style.backgroundImage]="location.nextPresentation.speaker_image_thumb ? 'url('+ location.nextPresentation.speaker_image_thumb +')' : ''"></div>
                                            <div class="cs-meta"><h4>
                                                <b><u>Session</u></b> {{location.nextPresentation.block_name}}</h4>
                                                <h2>{{location.nextPresentation.title}}</h2>
                                                <p>{{location.nextPresentation.speaker_name}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        </ng-container>
                    </ngx-slick-carousel>
                </div><!-- /nv-column-content -->
            </div><!-- /nv-column -->
        </div><!-- /nv-row  -->
    </div><!-- /nv-port -->
</section>
<!--<section class="nv-section guttered nv-questions" *ngIf="(configuration.conferenceId!=8301 && configuration.conferenceId!=8303) && currentUser && currentDelegate">
    <div class="nv-port">
        <div class="nv-row spaced">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <h4>My questions</h4>
                    <table class="nv-data-table break-lap" *ngIf="messages.length > 0">
                        <tr *ngFor="let message of messages">
                            <td class="dt-cell">
                                <div class="nv-qna-item">
                                    <div class="qi-content">
                                        <h3 class="nv-qna-question" [innerHTML]="message.content"></h3>
                                        <small class="nv-qna-timestamp">{{ message.created_at | date:'dd.MM.yyyy HH:mm' }}</small>
                                    </div> /.qi-content 
                                </div> /.nv-qna-item 
                            </td> /.dt-cell 
                        </tr>
                    </table>

                    <div class="nv-divider desk-medium"></div>

                    <button class="nv-btn medium radius-high accent2" (click)="openQuestionModal(currentLocation?.currentPresentation?.id)">Ask a question</button>
                </div>
            </div>
        </div>
    </div>
</section>-->
