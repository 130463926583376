import { Component } from '@angular/core';

@Component({
    selector: 'app-registration-messages-page',
    templateUrl: './registration-messages.component.html'
})
export class RegistrationMessagesComponent {

    constructor() { }

}
