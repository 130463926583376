import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ConferenceService } from '@navus/core/services/conference.service';
import { Room, RoomType } from '@navus/core/classes/chat';
import { ChatService } from '@navus/core/services/chat.service';
import { fromPromise } from 'rxjs/internal-compatibility';

@Component({
  selector: 'nv-public-group-modal',
  templateUrl: './public-group-modal.component.html'
})
export class PublicGroupModalComponent implements OnInit {
  @Input() conferenceId: number = null;
  @Input() publicGroup: Room = null;

  autoJoinOptions = [
    { id: 'yes', name: 'Yes' },
    { id: 'no', name: 'No' }
  ];

  publicGroupForm: FormGroup;

  savingPublicGroup: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private conferenceService: ConferenceService,
    private modalService: ModalService,
    private chatService: ChatService,
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.publicGroupForm = this.formBuilder.group({
      name: [this.publicGroup ? this.publicGroup.name : '', [Validators.required]],
      autoJoin: [this.publicGroup ? this.publicGroup.autoJoin : false],
    });
  }

  save() {
    if (this.publicGroupForm.invalid) {
      return;
    }

    this.savingPublicGroup = true;
    const newRoom: Room = {
      uid: this.publicGroup?.uid || this.generateUid(),
      name: this.publicGroupForm.value?.name,
      autoJoin: this.publicGroupForm.value?.autoJoin === 'yes',
      type: RoomType.PUBLIC_CHAT,
      subscribers: this.publicGroup?.subscribers || [],
      members: this.publicGroup?.members || [],
      messageCount: 0
    };
    if (newRoom.uid) {
      fromPromise(this.chatService
        .startChat(this.conferenceId, newRoom))
        .subscribe(
          () => {
            this.activeModal.close();
            this.savingPublicGroup = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingPublicGroup = false;
          }
        );
    }
  }

  close() {
    this.activeModal.dismiss();
  }

  private generateUid(length?: number): string {
    const buf: any[] = [];
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charLength: number = chars.length;
    const len: number = length || 32;

    for (let i = 0; i < len; i++) {
      buf[i] = chars.charAt(Math.floor(Math.random() * charLength));
    }

    return buf.join('');
  }

}
