import { Component, OnDestroy, OnInit, ViewChild, NgZone } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@navus/core/services/user.service';
import { User } from '@navus/core/classes/user';

import { WebsiteService } from '@navus/core/services/website.service';
import { Website } from '@navus/core/classes/website';

import { PresentationService } from '@navus/core/services/presentation.service';
import { Presentation } from '@navus/core/classes/presentation';
import { Location } from '@navus/core/classes/location';
import { BlockService } from '@navus/core/services/block.service';
import { Block } from '@navus/core/classes/block';

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { VotingQuestion } from '@navus/core/classes/voting-question';

import { ModalService } from '@navus/ui/modal/modal.service';
import { LocationService } from '@navus/core/services/location.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'nv-q-and-a-page',
  templateUrl: './q-and-a-page.component.html'
})
export class QAndAPageComponent implements OnInit, OnDestroy {  conferenceId: number;
  locationId: number;
  selectedLocation: any;
  presentation: Presentation;
  website: Website;
  selectedTab: string = 'live';
  subscriptions: Subscription[] = [];
  currentUser: User;
  currentBlockId: number;
  myPresentations: Presentation[] = [];
  presentingLocations: Location[] = [];

  blocks: Block[] = [];
  selectedBlock: Block;
  autoSelectBlock: boolean = true;
  chatMode: string = 'expand';
  statusOptions: any = [
    { id: 'all', name: 'All' },
    { id: 'true', name: 'Approved' },
    { id: 'false', name: 'Rejected' },
  ];
  approveStatus: string = 'all';

  currentTimestamp: number;
  currentBlockTimestamp: number;

  questionLoading: boolean = false;
  optionsShown: number = null;

  currentVotingQuestion: VotingQuestion = null;
  
  QAfilter = [
    { name: 'All', id: 'all' },
    { name: 'Approved', id: 'true' },
    { name: 'Not approved', id: 'false' }
  ];

  private LaravelEcho: Echo;

  constructor(
    private route: ActivatedRoute,
    private presentationService: PresentationService,
    private websiteService: WebsiteService,
    private userService: UserService,
    private blockService: BlockService,
    private modalService: ModalService,
    private locationService: LocationService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      (params) => {
        this.locationId = params.locationId;
      }
    );

    this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          if (website.active_conference_id) {
            this.conferenceId = website.active_conference_id;

            this.getLocation();
            this.getLocationBlocks();
            this.getMyPresentationsOnLocation(this.locationId);

            this.runSocket();
          }
        },
        () => { }
      );
    this.userService.currentUser
      .subscribe(
        (user: User) => {
          this.currentUser = user;
        },
        () => { }
      );
  }

  ngOnDestroy() {
    if (this.LaravelEcho) { this.LaravelEcho.disconnect(); }
  }

  isOpen(ws) { return ws.readyState === ws.OPEN }

  runSocket() {
    window['Pusher'] = Pusher;
    window['Echo'] = new Echo({
        broadcaster: 'pusher',
        key: `${environment.mix_pusher_app_key}`,
        wsHost: `${environment.mix_pusher_host}`,
        wsPort: `${environment.mix_pusher_port}`,
        wssPort: `${environment.mix_pusher_port}`,
        forceTLS: `${environment.mix_pusher_port === 443}`,
        encrypted: true,
        disableStats: true,
        authEndpoint: `${environment.api_url}` + 'broadcasting/auth',
        auth: {headers: {'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')}},
        enabledTransports: ['ws', 'wss']
    });
    this.LaravelEcho = window['Echo'];

    this.LaravelEcho
        .channel('location.current_conference_presentation.' + this.conferenceId)
        .listen('.location.changed_current_presentation',
          (response: any) => {
            console.log('changed_current_presentation', response);
            if (response.data[0].id) {
              if (
                  this.locationId == response.data[0].id &&
                  response.data[0].streaming_presentations.length > 0
              ) {
                this.setCurrentPresentation(response.data[0].streaming_presentations[0].id, response.data[0].streaming_presentations[0]);
              }
            }
          }
        );
  }

  setCurrentPresentation(presentationId: number, presentation: any = null) {
    if (presentation === null) {
      this.presentationService
          .getPresentation(presentationId)
          .subscribe(
            (response) => {
              this.presentation = response.data;
              this.currentBlockId = this.presentation.block_id;

              if (this.autoSelectBlock) {
                this.blocks
                    .forEach((block) => {
                      if (block.id === this.currentBlockId) {
                        this.selectBlock(block, true);
                      }
                    });
              }
            },
            () => { }
          );
    } else {
      this.presentation = presentation;
      this.currentBlockId = this.presentation.block_id;
      this.blocks
            .forEach((block) => {
              if (block.id === this.currentBlockId) {
                this.selectBlock(block, true);
              }
            });
    }
  }

  getMyPresentationsOnLocation(locationId: number) {
    const params: any = {
      location_id: locationId,
      include: 'block',
      with_pagination: 0
    };
    this.presentationService
        .getMyPresentations(this.conferenceId, params)
        .subscribe(
          (response) => {
              this.myPresentations = response.data;
              if (this.myPresentations[0] && this.myPresentations[0].location) {
                  this.selectedLocation = this.myPresentations[0].location;
              } else if (this.myPresentations[0].block && this.myPresentations[0].block.location) {
                  this.selectedLocation = this.myPresentations[0].block.location;
              } else {
                  this.router.navigate(['/my/studio']);
              }
              if (this.selectedLocation.current_presentation_id) {
                  this.setCurrentPresentation(this.selectedLocation.current_presentation_id);
              } else {
                this.currentTimestamp = new Date().getTime();
              } 
          },
          () => {

          }
        );
  }

  getLocation() {
    this.locationService
        .getConferenceLocation(this.conferenceId, this.locationId)
        .subscribe(
          (response) => {
            this.selectedLocation = response.data;
          },
          () => {}
        );
  }

  getLocationBlocks() {
    const params: any = {
      location_id: this.locationId,
      with_pagination: 0
    };

    this.blockService
        .getConferenceBlocks(this.conferenceId, params)
        .subscribe(
          (response) => {
            this.blocks = response.data
              .sort((a: Block, b: Block) => a.starts_at > b.starts_at ? -1 : 1)
              .map(block => ({ 
                ...block, 
                startTimestamp: new Date(block.starts_at).getTime() 
              }));

            this.blocks
                .forEach((block) => {
                  if (block.id === this.currentBlockId) {
                    this.selectBlock(block);
                  }
                });
          },
          () => {}
        );
  }
  

  selectBlock(block: Block, setTime = false) {
    this.selectedBlock = block;
      
    if (setTime) {
      this.currentTimestamp = block.startTimestamp;
    }

    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { sessionId: block.id }, 
        queryParamsHandling: 'merge',
      });
  }

  userDetails(userId: number) {
    this.userService
        .getChatUser(userId)
        .subscribe(
          (response) => {
            const user: User = response.data;
            this.modalService
                .defaultModal({
                  body: `
                    <div>${user.first_name} ${user.last_name}</div>
                    <div>${user.institution}</div>
                    <div>${user.email}</div>
                  `
                });
          },
          () => { }
        );
  }

}
