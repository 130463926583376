import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Organization } from '@navus/core/classes/organization';
import { Card } from '@navus/core/classes/card';
import { OrganizationService } from '@navus/core/services/organization.service';
import { BillingService } from '@navus/core/services/billing.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { CardDetailsModalComponent } from '../../modals/card-details/card-details-modal.component';
import { SelectPackageModalComponent } from '../../modals/select-package/select-package-modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryService } from '@navus/core/services/country.service';
import { Country } from '@navus/core/classes/country';

@Component({
  selector: 'main [nv-organization-billing-page]',
  templateUrl: './organization-billing-page.component.html'
})
export class OrganizationBillingPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  organization: Organization;

  cards: Card[] = [];
  invoices: any[] = [];
  allCountries: Country[] = [];
  filteredCountries: Country[] = [];

  billingAddressEdit = false;
  billingAddressForm: FormGroup;

  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private countryService: CountryService,
    private organizationService: OrganizationService,
    private billingService: BillingService
  ) { }

  ngOnInit() {
    this.initBillingAddressForm();
    this.getCountries();

    const routeSubscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.getCards();
        this.getInvoices();
      }
    );
    this.subscriptions.push(routeSubscription);

    const organizationSubscription = this.organizationService.currentOrganization
      .subscribe(
        (organization: Organization) => {
          this.organization = organization;
          if (this.organization) {
            this.billingAddressForm.patchValue(this.organization.billing_address);
          }
        },
        () => { }
      );
    this.subscriptions.push(organizationSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initBillingAddressForm() {
    this.billingAddressForm = this.formBuilder.group({
      country_id: [''],
      city: [''],
      address_line_1: [''],
      address_line_2: [''],
      postal_code: [''],
    });
  }

  saveBillingAddress() {
    this.organizationService.updateOrganization(this.organizationId, { billing_address: this.billingAddressForm.value })
      .subscribe(
        (response) => {
          this.organization.billing_address = response.data.billing_address;
          this.billingAddressEdit = false;
        },
        () => { }
      );
  }

  getCountries() {
    this.countryService.getCountries()
      .subscribe(
        (response) => {
          this.allCountries = response.data;
          this.filteredCountries = response.data;
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }

  filterCountries(searchTerm: string) {
    if (searchTerm.length > 2) {
      this.filteredCountries = [];
      for (const country of this.allCountries) {
        if (country.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          this.filteredCountries.push(country);
        }
      }
    } else {
      this.filteredCountries = this.allCountries;
    }
  }

  getCountryName(country_id: number) {
    for (const country of this.allCountries) {
      if (country.id === +country_id) {
        return country.name;
      }
    }
    return '';
  }

  getCards() {
    this.billingService.getOrganizationCards(this.organizationId)
      .subscribe(
        (response: { data: Card[] }) => {
          this.cards = response.data;
        },
        () => { }
      );
  }

  getInvoices() {
    this.billingService.getOrganizationInvoices(this.organizationId)
      .subscribe(
        (response: { data: any[] }) => {
          this.invoices = response.data;
        },
        () => { }
      );
  }

  addCard() {
    const modalRef = this.modalService.open(CardDetailsModalComponent);
    modalRef.componentInstance.organizationId = this.organizationId;
    modalRef.result.then(
      () => {
        this.getCards();
      },
      () => { }
    );
  }

  selectPackage() {
    const modalRef = this.modalService.open(SelectPackageModalComponent);
    modalRef.result.then(
      (result) => {
        this.organizationService.updateOrganization(this.organizationId, { package_id: result.packageId })
          .subscribe(
            (response) => {
              this.organizationService.setCurrentOrganization(this.organizationId, true).subscribe();
              this.getInvoices();
            }
          );
      },
      () => { }
    );
  }

}
