import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '@navus/core/services/user.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { PresentationService } from '@navus/core/services/presentation.service';

@Injectable()
export class SpeakerResolver implements Resolve<any> {
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(
    private router: Router,
    private modalService: ModalService,
    private userService: UserService,
    private presentationService: PresentationService,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const website = route.parent.data.website;

    return combineLatest([
      this.userService.getCurrentUser(),
      this.presentationService.getMyPresentations(
        website.active_conference_id, 
        { include: 'block,materials' }
      )
    ])
      .pipe(
        map(
          (result) => {
            const user = result[0];
            const presentations = result[1].data;
            const conferenceId = website.active_conference_id;

            const isSpeaker = !!user.resource_roles.find(r => 
              r.resource_type === 'conference' &&
              r.resource_id === conferenceId &&
              r.role === 'speaker'
            );
            const isModerator = !!user.resource_roles.find(r => 
              r.resource_type === 'conference' &&
              r.resource_id === conferenceId &&
              r.role === 'moderator'
            );
            const isAuthor = !!user.resource_roles.find(r => 
              r.resource_type === 'conference' &&
              r.resource_id === conferenceId &&
              r.role === 'author'
            );

            if (isSpeaker || isModerator || isAuthor) {
              return { user, presentations };
            } else {
              this.modalService.defaultModal({
                title: 'Forbidden',
                body: 'You are not assigned speaker or moderator of this conference'
              });
              this.router.navigate(['/']);
            }
          }
        )
      );
  }
}
