<div #tref class="nv-page-wrap"> <!-- class 'nv-loading' to set loading state -->
<!--<div class="nv-loading-visual">Loading</div>&lt;!&ndash; /.loading-visual &ndash;&gt;-->

<div class="nv-configurator-layout">

    <aside class="config-layout-sidebar">
		<div class="scroll-container config-nav-scroll">
			<div class="scrollable-content">
			   <div class="cofnig-meta-face">
					<div class="cmf-author">
						<span>Admin</span>
						<figure class="cmf-author-img">
							<span><img src="../assets/avatar-dummy.jpg" alt=""></span>
							<figcaption>Melissa Horstein</figcaption>
						</figure>    
					</div><!-- /cmf-author -->
					<div class="cmf-meta">
						<p>
							<time datetime="2018-09-08 20:00">09.08.2018 - 8:24pm</time>
							<span class="location">Belgrade</span>
						</p>
					</div><!-- /cmf-meta -->
					<a href="#" class="settings" title="change settings"></a>   
			   </div><!-- / cofnig-meta-face -->     

			   <nav class="configurator-menu">
				   <h3>Doing</h3>
                   <ul>
                        <li>
                           <a href="#" title="Portal setup" class="cm-event-setup">
                                <em class="cm-ico"></em>
                                <strong>Event Setup</strong>
                                <span>Set basic properties of the portal</span>
                           </a>
                        </li>
                        <li>
                            <a href="#" title="WebSite Builder" class="active cm-website-builder">
                                <em class="cm-ico"></em>
                                <strong>Website Builder</strong>
                                <span>Create portal's element</span>
                            </a>
                        </li>
                        <li class="disabled">
                            <a href="#" title="App Configurator" class="cm-app-configurator">
                                <em class="cm-ico"></em>
                                <strong>App Configurator</strong>
                                <span>Maximize timely deliverables for real-time schemas</span>
                            </a>
                        </li>
                        <li>
                           <a href="#" title="Program Setup" class="cm-program-setup">
                               <em class="cm-ico"></em>
                               <strong>Program Setup</strong>
                               <span>Dynamically procrastinate B2C users</span>
                           </a>
                        </li>
                        <li>
                           <a href="#" title="Speaker Managment" class="cm-speaker-managment">
                               <em class="cm-ico"></em>
                               <strong>Speaker Managment</strong>
                               <span>Maximize Lorem ipsum dolor sit</span>
                           </a>
                        </li>
                        <li>
                           <a href="#" title="Communication Materials" class="cm-communication-materials">
                               <em class="cm-ico"></em>
                               <strong>Communication Materials</strong>
                               <span>Deliverables for real-time schemas</span>
                           </a>
                        </li>
                        <li>
                           <a href="#" title="Campaign Tool" class="cm-campaign-tool">
                               <em class="cm-ico"></em>
                               <strong>Campaign Tool</strong>
                               <span>Innovate resource-leveling customer</span>
                           </a>
                        </li>
                        <li>
                           <a href="#" title="Sponsors & Exhibitors" class="cm-sponsors-exhibitors">
                               <em class="cm-ico"></em>
                               <strong>Sponsors &amp; Exhibitors</strong>
                               <span>Quickly maximize timely deliverables</span>
                           </a>
                        </li>
                        <li>
                           <a href="#" title="Event Website Builder" class="cm-event-website-builder">
                               <em class="cm-ico"></em>
                               <strong>Event Website Builder</strong>
                               <span>Quickly maximize timely deliverables</span>
                           </a>
                        </li>
                   </ul>
				   <h3>Learning</h3>
				   <ul>
					   <li>
						   <a href="#" title="E-Materials" class="cm-ematerials">
                               <em class="cm-ico"></em>
								<strong>E-Materials</strong>
								<span>Dynamically procrastinate B2C users</span>
						   </a>
						</li>
						<li>
							<a href="#" title="Hub" class="cm-hub">
                                <em class="cm-ico"></em>
								<strong>Hub</strong>
								<span>Quickly maximize timely deliverables</span>
							</a>
						</li>
						<li>
							<a href="#" title="AI / Machine Learning" class="cm-ai-mashine">
                                <em class="cm-ico"></em>
								<strong>AI / Machine Learning</strong>
								<span>Dynamically innovate resource-leveling customer</span>
							</a>
						</li>
				   </ul>
				   <h3>Earning</h3>
				   <ul>
                       <li>
                           <a href="#" title="Sponsored Spaces" class="cm-sponsored-spaces">
                               <em class="cm-ico"></em>
                               <strong>Sponsored Spaces</strong>
                               <span>Quickly maximize timely deliverables</span>
                           </a>
                       </li>
						<li>
							<a href="#" title="Registration System" class="cm-registration-system">
                                <em class="cm-ico"></em>
								<strong>Registration System</strong>
								<span>Dynamically innovate resource-leveling customer </span>
							</a>
						</li>
                       <li>
                           <a href="#" title="Lead Link Services" class="cm-leadlink">
                               <em class="cm-ico"></em>
                               <strong>Lead Link Services</strong>
                               <span>Dynamically innovate resource-leveling customer</span>
                           </a>
                       </li>
                       <li>
                           <a href="#" title="E-campus On-site Service" class="cm-ecampus">
                               <em class="cm-ico"></em>
                               <strong>E-campus On-site Service</strong>
                               <span>Quickly maximize timely deliverables</span>
                           </a>
                       </li>
				   </ul>
			   </nav>
			</div><!-- /scrollable-content -->
        </div>
    </aside><!-- /config-layout-sidebar -->   
    
    <main class="config-layout-main-frame">
        <div class="config-layout-upper-fixed">
            <div class="cluf-port">
                <div class="cluf-header-n-nav">
                    <header class="cluf-header">
                        <p class="crumb">
                            <span><a href="#">Configurator</a></span>
                        </p>  
                        <h1>WebSite Builder</h1>
                    </header>
                    <nav class="config-global-nav">
                        <ul>
                            <li>
                                <a class="current configure" title="Configure">Configure</a>        
                            </li>
                            <li>
                                <a class="preview" *ngIf="false" title="Preview">Preview</a>        
                            </li>
                            <li>
                                <a class="dashboard" *ngIf="false" title="Dashboard">Dashboard</a>        
                            </li>
                        </ul>
                    </nav><!-- /config-global-nav -->
                </div><!-- /cluf-header-n-nav -->


                <div class="cluf-additional-bar">
                    <nav class="cluf-additional-nav">
                        <span class="ico"></span>
                        <ul>
                            <li><a href="#">Home Page</a></li>
                            <li><a href="#" class="active">Random Inner Page</a></li>
                            <li><a href="#">Additional Page</a></li>
                            <li><a href="#">Etcetera</a></li>
                        </ul>
                    </nav>
                   <nav class="cluf-additional-nav tools">
                       <ul>
                           <li><a href="#" class="add-page"><span>Add another page</span></a></li>
                           <li><a href="#" class="move-page"><span>Move current page</span></a></li>
                           <li><a href="#" class="delete-page"><span>Delete current page</span></a></li>
                       </ul>
                   </nav><!-- /cluf-additional-nav -->
                </div><!-- /cluf-additional-bar -->
            </div><!-- /cluf-port --->
        </div><!-- /config-layout-upper-fixed -->

        <!--<div class="config-layout-info-bar-fixed">-->
            <!--<div class="clbf-msg success">-->
                <!--<div class="clbf-content">-->
                    <!--<p>Tiramisu icing cookie jelly-o sesame snaps dragée chupa chups marshmallow carrot cake.</p>-->
                <!--</div>-->
                <!--<span class="clbf-close" title="Close Message"></span>-->
            <!--</div>&lt;!&ndash; /clbf-msg &ndash;&gt;-->
        <!--</div>&lt;!&ndash; /config-layout-lower-fixed &ndash;&gt;-->

        <div class="config-layout-lower-fixed">
            <div class="nv-port">
                <div class="nv-row config-plates clean gap-24">
                    <div class="nv-column desk-8">
                        <div class="nv-column-content">
                            <header class="nv-config-block-header">
                                <h2>Active modules on page <strong>Random Inner Page</strong>.</h2>
                                <span class="nv-info-point">
                                    <span class="nip-txt">
                                        Quis meh echo park polaroid mollit, tumblr banjo actually viral. Pariatur four loko cray taiyaki succulents wayfarers esse ad cupidatat art party et heirloom banjo. Id celiac in, veniam wolf deep v kitsch chambray organic exercitation put a bird on it forage.
                                    </span><!-- /.nip-txt -->
                                </span><!-- /.nv-info-point -->
                            </header>
                        </div>
                    </div><!-- /nv-column -->

                    <div class="nv-column desk-4">
                        <div class="nv-column-content">
                            <header class="nv-config-block-header">
                                <h2>Available page modules:</h2>
                                <div class="nv-info-point">
                                    <span class="nip-txt">
                                        Quis meh echo park polaroid mollit, tumblr banjo actually viral. Pariatur four loko cray taiyaki succulents wayfarers esse ad cupidatat art party et heirloom banjo. Id celiac in, veniam wolf deep v kitsch chambray organic exercitation put a bird on it forage.
                                    </span><!-- /.nip-txt -->
                                </div><!-- /.nv-info-point -->
                            </header>
                        </div>
                    </div><!-- /nv-column -->
                </div><!-- /nv-row -->
                <div class="nv-row config-plates propagate gap-24">
                    <div class="nv-column desk-8 multi-plates v-top">

                            <div class="nv-column-content"> <!-- ITEM -->
                                <div class="nv-loading-visual">Loading</div><!-- /.loading-visual -->
                                <header class="nv-config-bold-header">
                                    <h3><em>1</em>Twitter</h3>
                                    <div class="cbh-tools">
                                        <div class="cbh-tool-box">
                                            <a class="edit-module" data-tip="edit Module" title="edit Module"></a>
                                            <a class="copy-module" data-tip="copy Module" title="copy Module"></a>
                                            <a class="delete-module" data-tip="delete Module" title="delete Module"></a>
                                        </div><!-- /.cbh-tool-box -->

                                        <div class="cbh-tool-box order">
                                            <a class="move-module-up" data-tip="move Module up the Page" title="move Module up the Page"></a>
                                            <a class="move-module-down" data-tip="move Module down the Page" title="move Module down the Page"></a>
                                        </div><!-- /.cbh-tool-box -->
                                    </div>
                                </header>

                                <div class="nv-config-input-row nv-row gap-24 config-plates clean propagate">
                                    <!-- Twitter Item -->
                                    <div class="nv-column desk-8">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <label>
                                                    <input type="text" placeholder="Twitter hashtag">
                                                    <span class="label-title">Twitter hashtag</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->
                                    <div class="nv-column desk-4">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <button type="button" class="nv-btn block fw filled gray medium" data-icon-pl="&#xe095;">Add another tag</button>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->

                                    <!-- Twitter Item -->
                                    <div class="nv-column desk-8">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <label>
                                                    <input type="text" placeholder="Twitter hashtag">
                                                    <span class="label-title">Twitter hashtag</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->
                                    <div class="nv-column desk-2">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <button type="button" class="nv-btn block fw medium filled gray" data-icon-pl="&#xe095;">Add more</button>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->
                                    <div class="nv-column desk-2">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <button type="button" class="nv-btn block fw medium filled gray" data-icon-pl="&#xe05f;">Edit</button>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->

                                    <div class="nv-column desk-8">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <label>
                                                    <input type="text" placeholder="...">
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->
                                    <div class="nv-column desk-4">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <button type="button" class="nv-btn block fw medium filled gray" data-icon-pl="&#xe032;">Set background image</button>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->

                                    <div class="nv-column desk-6">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <label>
                                                    <input type="text" placeholder="Input Disabled" disabled>
                                                    <span class="label-title">Input Disabled</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->
                                    <div class="nv-column desk-2">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <button type="button" class="nv-btn block fw medium filled gray" data-icon-pl="&#xe095;">Add more</button>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->
                                    <div class="nv-column desk-2">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <button type="button" class="nv-btn block fw medium filled gray" data-icon-pl="&#xe05f;">Edit</button>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->
                                    <div class="nv-column desk-2">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <button type="button" class="nv-btn block fw medium filled gray delete" data-icon-pl="&#xe054;">Delete</button>
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->

                                    <div class="nv-column desk-12">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <div class="nv-inline-items">
                                                    <div class="ni-item">
                                                        <p>Set dark skin (invert colors)</p>
                                                        &nbsp;&nbsp;
                                                        <div class="tsq-checkbox-radio">
                                                            <label title="activate module">
                                                                <input type="checkbox" name="r2">
                                                                <span class="tsq-check-visual"></span>
                                                            </label>
                                                        </div>
                                                    </div><!-- /ni-item -->      
                                                </div>    
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->

                                    <div class="nv-column desk-12 hr solid"></div>

                                    <div class="nv-column desk-12">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <div class="nv-inline-items">
                                                    <div class="ni-item">
                                                        <button type="button" class="nv-btn medium filled secondary" data-icon-pl-after="&#xe022;">If Needed</button>                                               
                                                    </div><!-- /ni-item -->           
                                                </div>    
                                            </div><!-- /nv-form-item -->
                                        </div>
                                    </div><!-- /nv-column -->

                            </div><!-- /nv-config-input-row -->
                        </div><!-- /nv-column-content -->


                        <div class="nv-column-content"> <!-- ITEM -->
                            <header class="nv-config-bold-header">
                                <h3><em>2</em>Some other Module</h3>
                                <div class="cbh-tools">
                                    <div class="cbh-tool-box">
                                        <a class="edit-module" data-tip="edit Module" title="edit Module"></a>
                                        <a class="copy-module" data-tip="copy Module" title="copy Module"></a>
                                        <a class="delete-module" data-tip="delete Module" title="delete Module"></a>
                                    </div><!-- /.cbh-tool-box -->

                                    <div class="cbh-tool-box order">
                                        <a class="move-module-up" data-tip="move Module up the Page" title="move Module up the Page"></a>
                                        <a class="move-module-down" data-tip="move Module down the Page" title="move Module down the Page"></a>
                                    </div><!-- /.cbh-tool-box -->
                                </div>
                            </header>

                            <div class="nv-config-input-row nv-row gap-24 config-plates clean propagate">
                                <!-- Twitter Item -->
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <div class="nv-form-item">
                                            <label>
                                                <input type="text" placeholder="Some Input query">
                                                <span class="label-title">Some Input query</span>
                                            </label>
                                        </div><!-- /nv-form-item -->
                                    </div>
                                </div><!-- /nv-column -->
                                <div class="nv-column desk-6">
                                    <div class="nv-column-content">
                                        <div class="nv-form-item select-item">
                                            <label>
                                                <select>
                                                    <option>Select options here</option>
                                                    <option>Options to choose form</option>
                                                    <option>Like this one</option>
                                                    <option>Or perhaps 4th from top</option>
                                                    <option>And so on..</option>
                                                </select>
                                                <span class="label-title">Select options here</span>
                                            </label>
                                        </div><!-- /nv-form-item -->
                                    </div>
                                </div><!-- /nv-column -->
                                <div class="nv-column desk-6">
                                    <div class="nv-column-content">
                                        <div class="nv-form-item">
                                            <label>
                                                <input type="text" placeholder="Some Input query">
                                                <span class="label-title">Some Input query</span>
                                            </label>
                                        </div><!-- /nv-form-item -->
                                    </div>
                                </div><!-- /nv-column -->
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <div class="nv-form-item">
                                            <label>
                                                <textarea rows="5" cols="30" placeholder="Text aria as optional item"></textarea>
                                                <span class="label-title">Text aria as optional item</span>
                                            </label>
                                        </div><!-- /nv-form-item -->
                                    </div>
                                </div><!-- /nv-column -->
                                <div class="nv-column auto">
                                    <div class="nv-column-content">
                                        <div class="nv-form-item">
                                            <button type="button" class="nv-btn block fw filled gray medium" data-icon-pl="&#xe097;">Call to Action</button>
                                        </div><!-- /nv-form-item -->
                                    </div>
                                </div><!-- /nv-column -->

                                <div class="nv-column auto">
                                    <div class="nv-column-content">
                                        <div class="nv-form-item">
                                            <button type="button" class="nv-btn block fw filled secondary medium" data-icon-pl="&#xe095;">Action as Well</button>
                                        </div><!-- /nv-form-item -->
                                    </div>
                                </div><!-- /nv-column -->
                        </div><!-- /nv-config-input-row -->
                    </div><!-- /nv-column-content -->

                    <div class="nv-column-content"> <!-- ITEM -->
                        <header class="nv-config-bold-header">
                            <h3><em>3</em>Gallery</h3>
                            <div class="cbh-tools">
                                <div class="cbh-tool-box">
                                    <a class="edit-module" data-tip="edit Module" title="edit Module"></a>
                                    <a class="copy-module" data-tip="copy Module" title="copy Module"></a>
                                    <a class="delete-module" data-tip="delete Module" title="delete Module"></a>
                                </div><!-- /.cbh-tool-box -->

                                <div class="cbh-tool-box order">
                                    <a class="move-module-up" data-tip="move Module up the Page" title="move Module up the Page"></a>
                                    <a class="move-module-down" data-tip="move Module down the Page" title="move Module down the Page"></a>
                                </div><!-- /.cbh-tool-box -->
                            </div>
                        </header>

                        <div class="nv-config-input-row nv-row gap-24 config-plates clean propagate">
                            <!-- Twitter Item -->
                            <div class="nv-column desk-6">
                                <div class="nv-column-content">
                                    <div class="nv-form-item">
                                        <label>
                                            <input type="text" placeholder="Some Input query">
                                            <span class="label-title">Some Input query</span>
                                        </label>
                                    </div><!-- /nv-form-item -->
                                </div>
                            </div><!-- /nv-column -->
                            <div class="nv-column desk-6">
                                <div class="nv-column-content">
                                    <div class="nv-form-item">
                                        <label>
                                            <input type="text" placeholder="Some Input query 2">
                                            <span class="label-title">Some Input query 2</span>
                                        </label>
                                    </div><!-- /nv-form-item -->
                                </div>
                            </div><!-- /nv-column -->
                            <div class="nv-column desk-6">
                                <div class="nv-column-content">
                                    <div class="nv-form-item select-item">
                                        <label>
                                            <select>
                                                <option>Select options here</option>
                                                <option>Options to choose form</option>
                                                <option>Like this one</option>
                                                <option>Or perhaps 4th from top</option>
                                                <option>And so on..</option>
                                            </select>
                                            <span class="label-title">Select options here</span>
                                        </label>
                                    </div><!-- /nv-form-item -->
                                </div>
                            </div><!-- /nv-column -->
                            <div class="nv-column desk-6">
                                <div class="nv-column-content">
                                    <div class="nv-form-item">
                                        <label>
                                            <input type="text" placeholder="Something else asked">
                                            <span class="label-title">Something else asked</span>
                                        </label>
                                    </div><!-- /nv-form-item -->
                                </div>
                            </div><!-- /nv-column -->
                            <div class="nv-column auto">
                                <div class="nv-column-content">
                                    <div class="nv-form-item">
                                        <button type="button" class="nv-btn block fw filled gray medium" data-icon-pl="&#xe097;">Call to Action</button>
                                    </div><!-- /nv-form-item -->
                                </div>
                            </div><!-- /nv-column -->

                            <div class="nv-column auto">
                                <div class="nv-column-content">
                                    <div class="nv-form-item">
                                        <button type="button" class="nv-btn block fw filled secondary medium" data-icon-pl="&#xe095;">Action as Well</button>
                                    </div><!-- /nv-form-item -->
                                </div>
                            </div><!-- /nv-column -->
                        </div><!-- /nv-config-input-row -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->

                    <div class="nv-column desk-4 multi-plates v-top">
                        <div class="nv-column-content">
                            <header class="nv-config-header w-tools">
                                <h2>
                                    <span class="ch-ico twitter"></span>
                                    Twitter
                                </h2>

                                <!--<div class="tsq-checkbox-radio">-->
                                    <!--<label>-->
                                        <!--<input type="radio" name="r1">-->
                                        <!--<span class="tsq-radio-visual"></span>-->
                                    <!--</label>-->
                                <!--</div>-->

                                <nav class="nch-toolbox">
                                    <ul>
                                        <li>
                                            <div class="nch-tool">
                                                <span title="toggle Module description" class="toggle-description on"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="nch-tool">
                                                <span title="add another instance" class="add-module-instance">
                                                    <em class="ami-current">2</em>
                                                </span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tsq-checkbox-radio check-type-slide">
                                                <label title="activate module">
                                                    <input type="checkbox" name="r1" checked>
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </nav><!-- /nch-toolbox -->

                            </header>

                            <div class="nv-builder-lead">
                                <div class="nbl-txt">
                                    <p>Minim vegan sed waistcoat pork belly, gastropub pitchfork consequat locavore meh next level put a bird on it pop-up. Pour-over selfies eiusmod, portland keytar ut freegan. Sint art party exercitation anim street art.</p>
                                </div>
                                <figure class="nbl-img">
                                    <img src="../../assets/be-twitter.png" alt="Twitter Module">
                                </figure>
                            </div><!-- /nv-builder-lead -->
                        </div><!-- /nv-column-content -->

                        <div class="nv-column-content">
                            <header class="nv-config-header w-tools">
                                <h2>
                                    Gallery
                                    <span class="ch-ico gallery"></span>
                                </h2>

                                <nav class="nch-toolbox">
                                    <ul>
                                        <li>
                                            <div class="nch-tool">
                                                <span title="toggle Module description" class="toggle-description"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tsq-checkbox-radio check-type-slide">
                                                <label title="activate module">
                                                    <input type="checkbox" name="r2">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </nav><!-- /nch-toolbox -->
                            </header>
                            <div class="nv-builder-lead closed">
                                <div class="nbl-txt">
                                    <p>Minim vegan sed waistcoat pork belly, gastropub pitchfork consequat locavore meh next level put a bird on it pop-up. Pour-over selfies eiusmod, portland keytar ut freegan. Sint art party exercitation anim street art.</p>
                                </div>
                                <figure class="nbl-img">
                                    <img src="../../assets/be-twitter.png" alt="Twitter Module">
                                </figure>
                            </div><!-- /nv-builder-lead -->
                        </div><!-- /nv-column-content -->

                        <div class="nv-column-content">
                            <header class="nv-config-header w-tools">
                                <h2>
                                    Speakers teaser
                                    <span class="ch-ico speaker"></span>
                                </h2>

                                <nav class="nch-toolbox">
                                    <ul>
                                        <li>
                                            <div class="nch-tool">
                                                <span title="toggle description" class="toggle-description"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tsq-checkbox-radio check-type-slide">
                                                <label title="activate module">
                                                    <input type="checkbox" name="r2">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </nav><!-- /nch-toolbox -->
                            </header>
                            <div class="nv-builder-lead closed">
                                <div class="nbl-txt">
                                    <p>Minim vegan sed waistcoat pork belly, gastropub pitchfork consequat locavore meh next level put a bird on it pop-up. Pour-over selfies eiusmod, portland keytar ut freegan. Sint art party exercitation anim street art.</p>
                                </div>
                                <figure class="nbl-img">
                                    <img src="../../assets/be-twitter.png" alt="Twitter Module">
                                </figure>
                            </div><!-- /nv-builder-lead -->
                        </div><!-- /nv-column-content -->

                        <div class="nv-column-content">
                            <header class="nv-config-header w-tools">
                                <h2>
                                    Text &amp; Image
                                    <span class="ch-ico txt-n-img"></span>
                                </h2>

                                <nav class="nch-toolbox">
                                    <ul>
                                        <li>
                                            <div class="nch-tool">
                                                <span title="toggle description" class="toggle-description"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tsq-checkbox-radio check-type-slide">
                                                <label title="activate module">
                                                    <input type="checkbox" name="r2">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </nav><!-- /nch-toolbox -->
                            </header>
                            <div class="nv-builder-lead closed">
                                <div class="nbl-txt">
                                    <p>Minim vegan sed waistcoat pork belly, gastropub pitchfork consequat locavore meh next level put a bird on it pop-up. Pour-over selfies eiusmod, portland keytar ut freegan. Sint art party exercitation anim street art.</p>
                                </div>
                                <figure class="nbl-img">
                                    <img src="../../assets/be-twitter.png" alt="Twitter Module">
                                </figure>
                            </div><!-- /nv-builder-lead -->
                        </div><!-- /nv-column-content -->

                        <div class="nv-column-content">
                            <header class="nv-config-header w-tools">
                                <h2>
                                    Large Slider
                                    <span class="ch-ico large-slider"></span>
                                </h2>

                                <nav class="nch-toolbox">
                                    <ul>
                                        <li>
                                            <div class="nch-tool">
                                                <span title="toggle description" class="toggle-description"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tsq-checkbox-radio check-type-slide">
                                                <label title="activate module">
                                                    <input type="checkbox" name="r2">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </nav><!-- /nch-toolbox -->
                            </header>
                            <div class="nv-builder-lead closed">
                                <div class="nbl-txt">
                                    <p>Minim vegan sed waistcoat pork belly, gastropub pitchfork consequat locavore meh next level put a bird on it pop-up. Pour-over selfies eiusmod, portland keytar ut freegan. Sint art party exercitation anim street art.</p>
                                </div>
                                <figure class="nbl-img">
                                    <img src="../../assets/be-twitter.png" alt="Twitter Module">
                                </figure>
                            </div><!-- /nv-builder-lead -->
                        </div><!-- /nv-column-content -->

                        <div class="nv-column-content">
                            <header class="nv-config-header w-tools">
                                <h2>
                                    Testimonials
                                    <span class="ch-ico testimonials"></span>
                                </h2>

                                <nav class="nch-toolbox">
                                    <ul>
                                        <li>
                                            <div class="nch-tool">
                                                <span title="toggle description" class="toggle-description"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="tsq-checkbox-radio check-type-slide">
                                                <label title="activate module">
                                                    <input type="checkbox" name="r2">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </nav><!-- /nch-toolbox -->
                            </header>
                            <div class="nv-builder-lead closed">
                                <div class="nbl-txt">
                                    <p>Minim vegan sed waistcoat pork belly, gastropub pitchfork consequat locavore meh next level put a bird on it pop-up. Pour-over selfies eiusmod, portland keytar ut freegan. Sint art party exercitation anim street art.</p>
                                </div>
                                <figure class="nbl-img">
                                    <img src="../../assets/be-twitter.png" alt="Twitter Module">
                                </figure>
                            </div><!-- /nv-builder-lead -->
                        </div><!-- /nv-column-content -->
                    </div><!-- /nv-column -->
                </div><!-- /nv-row -->

                <!-- NEW BUILDER APPROACH -->
                <div class="nv-row config-plates clean gap-24">
                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <header class="nv-config-block-header">
                                <h2>Builder</h2>
                                <span class="nv-info-point">
                                    <span class="nip-txt">
                                        Quis meh echo park polaroid mollit, tumblr banjo actually viral. Pariatur four loko cray taiyaki succulents wayfarers esse ad cupidatat art party et heirloom banjo. Id celiac in, veniam wolf deep v kitsch chambray organic exercitation put a bird on it forage.
                                    </span><!-- /.nip-txt -->
                                </span><!-- /.nv-info-point -->
                            </header>
                        </div>
                    </div><!-- /nv-column -->
                </div><!-- /nv-row -- -->

                <div class="nv-row config-plates gap-24">
                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <header class="nv-config-bold-header">
                                <h3>Twitter</h3>
                                <div class="cbh-tools">
                                    <div class="cbh-tool-box">
                                        <a class="edit-module" title="edit Module"></a>
                                        <a class="copy-module" title="copy Module"></a>
                                        <a class="populate-module" title="auto-populate Module (dummy)"></a>
                                        <a class="preview-module" title="preview Module"></a>
                                        <a class="disable-module" title="disable Module"></a>
                                        <a class="delete-module" title="delete Module"></a>
                                    </div><!-- /.cbh-tool-box -->

                                    <div class="cbh-tool-box order">
                                        <a class="move-module-up" title="move Module up the Page"></a>
                                        <a class="move-module-down" title="move Module down the Page"></a>
                                    </div><!-- /.cbh-tool-box -->
                                </div>
                            </header>
                        </div>
                    </div><!-- /nv-column -->
                </div><!-- /nv-row -- -->

                <!-- /NEW APPROACH -->

            </div><!-- /nv-port -->
        </div><!-- /config-layout-lower-fixed -->

        <!--<div class="config-layout-info-bar-fixed">-->
            <!--<div class="clbf-msg success">&lt;!&ndash; < additional case class: [none], success, warn, error &ndash;&gt;-->
                <!--<div class="clbf-content">-->
                    <!--<p>Tiramisu icing cookie jelly-o sesame snaps dragée chupa chups marshmallow carrot cake.</p>-->
                <!--</div>-->
                <!--<span class="clbf-close" title="Close Message"></span>-->
            <!--</div>&lt;!&ndash; /clbf-msg &ndash;&gt;-->
        <!--</div>&lt;!&ndash; /config-layout-lower-fixed &ndash;&gt;-->

    </main><!-- /config-layout-main-frame -->   
</div><!-- /.nv-configurator-layout -->

</div><!-- /nv-page-wrap -->