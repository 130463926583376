import { Component } from '@angular/core';

@Component({
    selector: 'app-playground-page',
    templateUrl: './playground.component.html'
})
export class PlaygroundComponent {

    constructor() { }

}
