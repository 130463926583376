<section class="nv-section guttered" [class.edit-mode]="mouseOver">
    <ng-container *ngIf="mouseOver">
        <a class="nv-section-add-top" title="Add section" (click)="createBefore()"></a>
        <a class="nv-section-add-bottom" title="Add section" (click)="createAfter()"></a>
        <div class="nv-section-edit-tool-box">
            <a class="edit" title="Edit section" (click)="edit()"></a>
            <a class="delete" title="Delete section" (click)="delete()"></a>
        </div><!-- /.nv-section-edit-tool-box -->
        <div class="nv-section-sort-tool-box">
            <a class="move-up" title="Move up" (click)="moveUp()"></a>
            <a class="move-down" title="Move down" (click)="moveDown()"></a>
        </div><!-- /.nv-section-sort-tool-box -->
    </ng-container>

    <div class="nv-port">
        <div class="nv-row spaced">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <ngx-slick-carousel class="carousel nv-slick-carousel-section" [config]="slideConfig">
                        <div ngxSlickItem *ngFor="let slide of configuration.slides" class="slide">
                            <a [routerLink]="slide.linkUrl" target="_blank">
                                <img src="{{ slide.imageUrl }}" alt="{{ slide.imageUrl }}" width="100%">
                            </a>
                        </div>
                    </ngx-slick-carousel>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</section>
