<a class="tsp-close" (click)="close.emit()" title="Close panel"></a>
<header>
    <h4>Pages</h4>
    <nv-button size="small" (click)="new()">Add new</nv-button>
</header>
<nav>
    <ul cdkDropList (cdkDropListDropped)="drop($event)">
        <li cdkDrag *ngFor="let page of website.pages">
            {{ page.title }}
            <nv-button
                    (click)="details(page)"
                    color="passive"
                    size="small"
                    iconPl="&#xe959;"
                    [onlyIcon]="true"
                    title="Edit"></nv-button>
        </li>
    </ul>
</nav>
