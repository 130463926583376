import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LoadingService } from '@navus/ui/loading/loading.service'
import { ModalService } from '@navus/ui/modal/modal.service';
import { Notification } from '@navus/core/classes/notification';
import { NotificationService } from '@navus/core/services/notification.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ConferenceService } from '@navus/core/services/conference.service';

@Component({
  selector: 'main [nv-notification-list-page]',
  templateUrl: './notification-list-page.component.html'
})
export class NotificationListPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  notifications: Notification[] = [];

  pagination: { page?: number, perPage: number, total?: number } = {
    perPage: 30
  };
  sort: { field: string, direction: string } = {
    field: 'schedule_time',
    direction: 'desc'
  };
  selectedIds: number[] = [];

  showNotificationCenter: boolean = false;

  notificationLoading: boolean = false;
  optionsShown: number = null;

  formFilter: FormGroup;
  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private toastService: ToastService,
  ) {
  }

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      search_term: ['']
    });
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getNotifications();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getNotifications(append: boolean = false) {
    if (append && this.pagination.total && this.notifications.length >= this.pagination.total) {
      return;
    }

    this.pagination.page = append ? this.pagination.page + 1 : 1;
    const params: any = {
      page: this.pagination.page,
      per_page: this.pagination.perPage,
      sort_by: this.sort.field,
      sort_direction: this.sort.direction,
    };
    if (this.formFilter.value.search_term !== '') {
      params.search_term = this.formFilter.value.search_term;
    }
    this.notificationLoading = !append;
    this.notificationService.getConferenceNotifications(this.conferenceId, params)
      .subscribe(
        (response: { data: Notification[], meta: any }) => {
          if (append) {
            this.notifications = this.notifications.concat(response.data);
          } else {
            const port = document.getElementsByClassName('nv-config-content-port');
            if (port && port.length > 0) {
              port[0].scrollTop = 0;
            }
            
            this.notifications = response.data;
            this.pagination.total = response.meta.pagination.total;
          }
          this.notificationLoading = false;
        },
        () => {
          this.notificationLoading = false;
        }
      );
  }

  edit(notification: Notification) {
    this.router.navigate(['..', 'notification', notification.id], { relativeTo: this.route });
  }

  archive(notification: Notification) {
    this.notificationService
      .updateConferenceNotification(this.conferenceId, notification.id, { status: 'archived' })
      .subscribe(
        (response) => {
          this.getNotifications();
          this.toastService.success('Push notification archived.', {});
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }

  close() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  add() {
    this.router.navigate(['..', 'notification'], { relativeTo: this.route });
  }

  delete(notification: Notification) {
    this.modalService.defaultModal({
      title: 'Push Notification Deletion',
      body: 'Are you sure you want to delete this push notification?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.notificationService
              .deleteConferenceNotification(this.conferenceId, notification.id)
              .subscribe(
                () => {
                  this.getNotifications();
                },
                (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

  sortNotification(field: string) {
    if (field === this.sort.field) {
      this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sort.field = field;
      this.sort.direction = 'asc';
    }
    this.getNotifications();
  }

  toggleSelected(notification: Notification) {
    const index = this.selectedIds.indexOf(notification.id);
    if (index !== -1) {
      this.selectedIds.splice(index, 1);
    } else {
      this.selectedIds.push(notification.id);
    }
  }

  selectAll() {
    this.selectedIds = [];
    for (const notification of this.notifications) {
      this.selectedIds.push(notification.id);
    }
  }

  unselectAll() {
    this.selectedIds = [];
  }

  openOptions(notification: Notification) {
    event.stopPropagation();
    this.optionsShown = notification.id;
  }

  closeOptions() {
    event.stopPropagation();
    this.optionsShown = null;
  }

}
