import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { LiveSuiteService } from '@navus/core/services/live-suite.service';
import { LiveSuite } from '@navus/core/classes/live-suite';
import { User } from '@navus/core/classes/user';
import { UserService } from '@navus/core/services/user.service';
import { Presentation } from '@navus/core/classes/presentation';
import { ModalService } from '@navus/ui/modal/modal.service';
import { Subscription, timer } from 'rxjs';
import { LiveSuiteSectionConfig } from './live-suite-section-config';
import { LocationService } from '@navus/core/services/location.service';
import { NavusMeetingComponent } from '@navus/ui/meeting/meeting.component';
import { BaseSectionComponent } from '../_base/base-section.component';
import { WebsiteControlService } from '../../services/website-control.service';
import { WebsiteService } from '@navus/core/services/website.service';
import { WebsitePageService } from '@navus/core/services/website-page.service';


@Component({
  selector: 'nv-live-suite-section',
  templateUrl: './live-suite-section.component.html',
  styleUrls: ['live-suite-section.component.scss']
})

export class LiveSuiteSectionComponent extends BaseSectionComponent implements OnInit {
  @ViewChild('meeting') meeting: NavusMeetingComponent;

  @Input() data: WebsitePageSection;
  @Input() previewMode: boolean = true;
  @Input() slug: string = null;
  @Input() title: string;

  subscriptions: Subscription[] = [];
  currentUser: User;
  configuration: LiveSuiteSectionConfig = new LiveSuiteSectionConfig();
  liveStreamingSuites: LiveSuite[];
  activeSource: LiveSuite;
  api: any;
  inLivestream: any = [];

  slideLiveSuiteConfig = {
    'slidesToShow': 3,
    'autoplay': false,
    'slidesToScroll': 3,
    'infinite': false,
    'dots': false,
    'centerMode': false,
    'adaptiveHeight': true,
    'arrows': true,
    'prevArrow': '<a class="er-prev"></a>',
    'nextArrow': '<a class="er-next"></a>',
    responsive: [
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService,
    private liveSuiteService: LiveSuiteService,
    private locationService: LocationService,
    private userService: UserService,
    private router: Router
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );
  }

  ngOnInit() {
    this.configuration = new LiveSuiteSectionConfig(this.data.configuration);
    const userSubscription = this.userService.currentUser
      .subscribe(
        (user: User) => {
          this.currentUser = user;
        }
      );
    this.subscriptions.push(userSubscription);
    this.getLiveSuite();
  }

  getLiveSuite() {
    const params = { };

    this.liveSuiteService.getLiveSuite(this.configuration.code, params)
      .subscribe(
        (response) => {
          this.liveStreamingSuites = response['data'].sources;

          let hasActiveStreams = false;
          for (let i = 0; i < this.liveStreamingSuites.length; i++) {
            if (this.liveStreamingSuites[i].presentation) {
              hasActiveStreams = true;
              if (!this.activeSource) {
                this.activeSource = this.liveStreamingSuites[i];
              }
            }

            if (this.liveStreamingSuites[i].presentation) {
              this.inLivestream.push(this.liveStreamingSuites[i].presentation.id);
              if (this.inLivestream.indexOf(this.liveStreamingSuites[i].presentation.block_id) === -1) {
                this.inLivestream.push(this.liveStreamingSuites[i].presentation.block_id);
              }
            }
          }

          if (!hasActiveStreams) {
            this.activeSource = null;
          }
        },
        () => {}
      );
  }

  canAccess(presentation: Presentation) {
    if (this.currentUser && this.currentUser.resource_roles && presentation) {
      const canAccessAll = ['superAdmin', 'admin'];
      const canAccessConference = ['webcast_viewer'];
      const canAccessBlock = ['webcast_viewer'];
      const freeBlocks = [10405, 10328];

      if (freeBlocks.indexOf(presentation.block_id) !== -1) {
        return true;
      }

      for (const resource_role of this.currentUser.resource_roles) {
        if (
          resource_role.resource_type === 'user' &&
          canAccessAll.indexOf(resource_role.role) !== -1
        ) { return true; }

        if (
          resource_role.resource_type === 'conference' &&
          // resource_role.resource_id === presentation.conference_id &&
          canAccessConference.indexOf(resource_role.role) !== -1
        ) { return true; }

        if (
          resource_role.resource_type === 'block' &&
          resource_role.resource_id === presentation.block_id &&
          canAccessBlock.indexOf(resource_role.role) !== -1
        ) { return true; }
      }

      return false;
    } else {
      return false;
    }
  }

  onPlayerReady(api: any) {
    this.api = api;
  }

  onVideoClick() {
    if (this.currentUser && this.currentUser.resource_roles) {
      if (!this.canAccess(this.activeSource.presentation)) {
        this.modalService.open('access-options');
        this.api.pause();
        return;
      }
    } else {
      this.modalService.defaultModal({
        title: 'Not logged in',
        body: 'You need to be logged in to continue',
        buttons: [
          {
            text: 'Log in',
            handler: () => {
              this.router.navigate(['/profile/login'], { queryParams: { returnUrl: this.router.url } });
            }
          },
          {
            text: 'Cancel',
            role: 'cancel'
          }
        ]
      });
      this.api.pause();
      return;
    }

    if (this.api.state === 'playing') {
      this.api.pause();
    } else {
      this.api.play();
    }
  }

  onClickStream(source) {
    if (this.api) {
      this.api.pause();
    }
    this.activeSource = null;

    const timerSubscription: Subscription = timer(0, 10).subscribe(
      () => {
        this.activeSource = source;
        const element = document.getElementById('active-source');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
        timerSubscription.unsubscribe();
      }
    );

    if (this.currentUser && this.currentUser.resource_roles) {
      if (!this.canAccess(source.presentation)) {
        this.modalService.open('access-options');
        return;
      }
    } else {
      this.modalService.defaultModal({
        title: 'Not logged in',
        body: 'You need to be logged in to continue',
        buttons: [
          {
            text: 'Log in',
            handler: () => {
              this.router.navigate(['/profile/login'], { queryParams: { returnUrl: this.router.url } });
            }
          },
          {
            text: 'Cancel',
            role: 'cancel'
          }
        ]
      });
      return;
    }
  }

}



