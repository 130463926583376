import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'main [nv-voting-window-select-modal]',
  templateUrl: './voting-window-select-modal.component.html'
})
export class VotingWindowSelectModalComponent implements OnInit {
  @Input() votingPopups: string[] = [];

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() { }

  open(votingPopup?) {
    if (votingPopup) {
      this.activeModal.close(votingPopup.split('pv:')[1]);
    } else {
      this.activeModal.close();
    }
  }

  close() {
    this.activeModal.dismiss();
  }

}
