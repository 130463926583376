<div class="edit-resource">
    <div class="nv-modal small">
        <div class="nv-modal-header">
            <h4 class="medium">Edit file</h4>
            <button type="button" class="close" aria-label="Close" (click)="close()"> </button>
        </div>
        <div class="nv-modal-body">
            <form [formGroup]="editResourceForm">
                <nv-input type="text" label="Name" formControlName="filename" disabled></nv-input>
            </form>

            <div class="nv-divider desk-medium"></div>

            <h5 class="medium">This file is in folders</h5>

            <div class="tags-container">
                <div class="tag" *ngFor="let folder of resource.tags">
                    <div class="name">{{folder}}</div>
                    <div class="sign remove" (click)="removeFolderFromResource(folder)"></div>
                </div>
                <p *ngIf="!resource.tags.length">
                    No Folders
                </p>
            </div>

            <div class="nv-divider desk-medium"></div>

            <h5 class="medium">Available folders</h5>

            <div class="tags-container">
                <div class="tag" *ngFor="let folder of availableFolders">
                    <div class="name">{{folder}}</div>
                    <div class="sign" (click)="addFolderToResource(folder)"></div>
                </div>
                <div class="tag new" *ngIf="!showNewFolderField">
                    <div class="name">Add new folder...</div>
                    <div class="sign add" (click)="showNewFolderField = !showNewFolderField;focusNewFolder()"></div>
                </div>
                <div class="tag no-hover" *ngIf="showNewFolderField">
                    <div class="name">
                        <span #newFolderEl contenteditable="true"
                              placeholder="New folder name..."
                              (keydown.enter)="addNewFolder()"
                              (keydown.esc)="cancelNewFolder()"
                              [textContent]="newFolder"
                              (input)="newFolder=$event.target.textContent"></span>
                    </div>
                    <div class="sign add" (click)="addNewFolder()"></div>
                    <div class="sign cancel" (click)="cancelNewFolder()"></div>
                </div>
            </div><!-- /tags-container -->
        </div>
        <div class="nv-modal-footer">
            <div class="nv-btn-box gap-10 flow-end">
                <nv-button color="neutral" (click)="close()">Close</nv-button>
                <nv-button (click)="save()">Save</nv-button>
            </div>
        </div>
    </div>
</div>
