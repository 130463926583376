<div class="nv-config-control-bar">
    <div class="cluf-port">
        <div class="config-title-n-tools">
            <div class="tnt-left">
                <h1 translate>Multi-event App</h1>
            </div><!-- /.tnt-left -->
            <div class="tnt-right"></div><!-- /.tnt-right -->
        </div><!-- /.config-title-n-tools -->
    </div>
</div>
<div class="nv-config-content-port">
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form [formGroup]="mobileAppHubForm">
                            <div class="nv-row config-plates clean gap-24 propagate">
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <h4>Give great visibility to your events, create a hub for content and keep your audience engaged!</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-row config-plates clean gap-24 propagate">
                                <div class="nv-column desk-4 tab-12">
                                    <div class="nv-column-content" style="text-align: center">
                                        <img style="width: 80%;" src="../../../../../assets/app-hub-visual.jpg" alt>
                                    </div>
                                </div>
                                <div class="nv-column desk-8 tab-12">
                                    <div class="nv-column-content">
                                        <ul>
                                            <li>Combine your various event apps under one App branded for your Organization and publish it in the app stores.</li>
                                            <li>Collect and group all content from all your events under one roof.</li>
                                            <li>Access to all events materials from the App Hub – presentations, e-learning modules, e-posters, abstracts, summary articles, publications, news, etc.</li>
                                            <li>Enable interaction and engagement among your audience before, during and after your events.</li>
                                            <li>Keep attendees up to date by sending them push notifications about the latest content (news, articles, banners, notifications, etc.)</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-row config-plates clean gap-24 propagate">
                                <div class="nv-column desk-3 tab-6 phab-12">
                                    <div class="nv-column-content nv-promo-feature npf1">
                                        <h4>Specifications</h4>
                                        <p>Apps are for both:</p>
                                        <ul>
                                            <li><strong>Android (Google Play)</strong></li>
                                            <li><strong>iOS (App Store)</strong></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="nv-column desk-9 tab-6 phab-12">
                                    <div class="nv-column-content nv-promo-feature npf2">
                                        <h4>Sponsorship</h4>
                                        <p>
                                            The Hub App provides great exposure for multiple industry partners and sponsors, whilst generating additional revenue for your organization.
                                        </p>
                                        <p>
                                            To get unlimited use of all digital ad/sponsorship spaces, upgrade to Premium for <strong>€4.90 per attendee</strong> for any selected event.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-row config-plates clean gap-24 propagate">
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <span>Please select the events that you want to include in your central searchable hub and we will get back with a quote for you.</span>
                                    </div>
                                </div>
                                <div class="nv-column desk-6 tab-12" formGroupName="options">
                                    <div class="nv-column-content">
                                        <nv-select
                                                [options]="conferences"
                                                [multi]="true"
                                                formControlName="conference_ids"
                                                label="Select existing events"
                                                required
                                        ></nv-select>
                                    </div>
                                </div>
                                <div class="nv-column desk-6 tab-12">
                                    <div class="nv-column-content">
                                        <nv-button
                                                (click)="submitMobileAppHubForm()"
                                                [disabled]="mobileAppHubForm.invalid"
                                        >Submit</nv-button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
