import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class TimezoneService {

  constructor(
    private http: HttpClient
  ) { }

  getTimezones(params: any = {}) {
    return this.http.get(`/assets/config/timezones.json`, params);
  }

}
