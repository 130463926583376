import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Presentation } from '@navus/core/classes/presentation';
import { Program } from '@navus/core/classes/program';
import { PresentationService } from '@navus/core/services/presentation.service';
import { Block } from '@navus/core/classes/block';
import { ModalService } from '@navus/ui/modal/modal.service';
import { TimeslotDistribution } from '@navus/core/classes/timeslot-distribution';
import { BlockModalComponent } from '../../modals/block/block-modal.component';
import { ConferenceService } from '@navus/core/services/conference.service';
import { Router } from '@angular/router';
import { Author } from '@navus/core/classes/author';
import { AuthorModalComponent } from '../../modals/author/author-modal.component';
import { BookmarkService } from '@navus/core/services/bookmark.service';
import { WebsiteService } from '@navus/core/services/website.service';
import { Subscription } from 'rxjs';
import { Website } from '@navus/core/classes/website';
import { Conference } from '@navus/core/classes/conference';
import { Bookmark } from '@navus/core/classes/bookmark';
@Component({
  selector: 'nv-program-planner',
  templateUrl: './program-planner.component.html'
})
export class ProgramPlannerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() organizationId: number;
  @Input() conferenceId: number;
  @Input() livestreams: number[] = [];
  @Input() hideFinished: boolean = false;
  @Input() showSpeakersTab: boolean = false;
  @Input() showPresentations: boolean = false;
  @Input() showTimeColumn: boolean = true;

  @Input() mode: 'live'|'config' = 'live'


  searchTerm: string = '';
  filter: any = {
    locationId: null,
    blockCategoryId: null,
    bookmarked: false
  };

  program: Program;
  days: string[] = [];
  selectedDay: string;
  timeslotDistributions: TimeslotDistribution[] = [];
  justBookmarked: boolean = false;
  bookmarks: Bookmark[] = [];
  bookmarkedPresentationIds: number[] = [];
  bookmarkedBlockIds: number[] = [];
  website: Website;
  conference: Conference;
  subscriptions: Subscription[] = [];

  now: string = (new Date()).toISOString().replace('T', ' ').substr(0, 16);
  interval;

  programLoading: boolean = false;
  blocksLoading: number[] = [];
  filtering: boolean = false;

  speakers: any[] = [];

  constructor(
    private router: Router,
    private modalService: ModalService,
    private presentationService: PresentationService,
    private websiteService: WebsiteService,
    private bookmarkService: BookmarkService,
    private conferenceService: ConferenceService
  ) {}

  ngOnInit() {
//    if (this.hideFinished) {
//      this.interval = setInterval(() => {
//        this.nestProgramElements();
//      }, 10 * 1000);
//    } 

    const bookmarkSubscription = this.bookmarkService.bookmarks
      .subscribe(
        (bookmarks: Bookmark[]) => {
          this.bookmarks = bookmarks;
          this.bookmarkedPresentationIds = bookmarks.map(b => b.presentation_id);
          this.bookmarkedBlockIds = bookmarks.map(b => b.block_id);
        }
      );
    this.subscriptions.push(bookmarkSubscription);

    const websiteSubscription = this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          this.website = website;
          this.conference = website?.conferences.find(conference => conference.id === this.conferenceId);
        }
      );
    this.subscriptions.push(websiteSubscription);


    this.showPresentations = this.conferenceId === 14340 ? false : true;

    if (this.conferenceId === 8353 || this.conferenceId === 8375) {
      this.showTimeColumn = false;
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.conferenceId) {
      this.getProgram();
    }
  }

  blockClick(block: Block) {
    if (this.mode === 'config') {
      window.open(`//${window.location.host}/o/${this.organizationId}/conference/${this.conferenceId}/program/block/${block.id}`, '_blank');
    } else {
      if (this.showPresentations) { return; }

      if (block.presentations) {
        delete block.presentations;
      } else {
        this.getBlockPresentations(block);
      }      
    }
  }
  
  startsWith(str, word) {
    return word.lastIndexOf(str) === 0;
  }

  getBlockPresentations(block: Block) {
    this.blocksLoading.push(block.id);
    this.presentationService
      .getConferencePresentations(this.conferenceId, { block_id: block.id, include: 'speakers', with_pagination: 0 })
      .subscribe(
        (response: { data: Presentation[] }) => {
          block.presentations = response.data;

          const index = this.blocksLoading.indexOf(block.id);
          if (index !== -1) {
            this.blocksLoading.splice(index, 1);
          }
        },
        () => {
          const index = this.blocksLoading.indexOf(block.id);
          if (index !== -1) {
            this.blocksLoading.splice(index, 1);
          }
        }
      );
  }

  toggleBookmarkPresentation(presentation: Presentation) {
    // this.bookmarks.push(presentation.id);
  }

  showBlockDetails(blockId: number) {
    const modalRef = this.modalService.open(BlockModalComponent);
    modalRef.componentInstance.blockId = blockId;
  }

  getProgram() {
    this.programLoading = true;
    this.conferenceService.getProgram(this.conferenceId)
      .subscribe(
        (response: { data: Program }) => {
          this.program = response.data;
          this.nestProgramElements();
          if (this.days.length > 0) {
            if (this.days.length > 1) {
              for (const day of this.days) {
                if(this.isToday(day)) {
                  this.selectDay(day);
                }
              }
            }
            if (!this.selectedDay) {
              this.selectDay(this.days[0]);
            }
          }
          this.programLoading = false;
        },
        () => {
          this.programLoading = false;
        }
      );
  }

  nestProgramElements() {
    this.filtering = true;
    const now = (new Date()).toISOString().replace('T', ' ').substr(0, 16);

    for (const timeslot of this.program.timeslots) {
//      timezone issue
//      if (this.hideFinished && timeslot.ends_at <= now) { continue; }

      if (this.days.indexOf(timeslot.date) === -1) {
          // if (this.conferenceId==8336 && timeslot.date=="2021-03-03") {
          //   continue;
          // }
          this.days.push(timeslot.date);
      }
    }
    this.days = this.days.sort(
      (a, b) => {
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      });

    for (const timeslot of this.program.timeslots) {
      if (timeslot.location_id) {
        for (const location of this.program.locations) {
          if (timeslot.location_id === location.id) {
            timeslot.location = location;
          }
        }
      }
    }

    // for (const presentation of this.program.presentations) {
    //   if (presentation.speaker_name) {
    //     this.speakers[presentation.id] = presentation.speaker_name.split(",").map(function(item) {
    //       return item.trim();
    //     });
    //   }
    // }

    for (const timeslot_distribution of this.program.timeslot_distributions) {
      if (timeslot_distribution.block_id) {
        for (const block of this.program.blocks) {
          if (timeslot_distribution.block_id === block.id) {
            let speakersPerBlock = this.program.speakers_per_block.filter(x => x.block_id === block.id);
            let chairs: Author[] = [];
            for (const speakerPerBlock of speakersPerBlock) {
              chairs.push(this.program.speakers.find(x => x.id === speakerPerBlock.speaker_id));
            }
            block.chairpersons = chairs;

            timeslot_distribution.block = block;
            
            if (this.showPresentations) {
              block.presentations = [];
              for (const presentation of this.program.presentations) {
                if (block.id === presentation.block_id && presentation.type!='Break') {
                  block.presentations.push(presentation);
                }
              }
            }
          }
        }
      }

      if (timeslot_distribution.presentation_id) {
        for (const presentation of this.program.presentations) {
          if (timeslot_distribution.presentation_id === presentation.id) {
            timeslot_distribution.presentation = presentation;
          }
        }
      }

      if (timeslot_distribution.timeslot_id) {
        for (const timeslot of this.program.timeslots) {
          if (timeslot_distribution.timeslot_id === timeslot.id) {
            timeslot_distribution.timeslot = timeslot;
          }
        }
      }

      // Search and filter
      let shouldRemove = false;
      if (
          timeslot_distribution.block &&
          timeslot_distribution.block.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) === -1 &&
          timeslot_distribution.block.chairperson.toLowerCase().indexOf(this.searchTerm.toLowerCase()) === -1
        ) {
          shouldRemove = true;

          if (timeslot_distribution.block.presentations) {
            timeslot_distribution.block.presentations.forEach(presentation => {
              if (
                presentation.title.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1 ||
                presentation.speaker_name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1
              ) {
                shouldRemove = false;
              }
            });
          }
      }
      if (
          timeslot_distribution.presentation &&
          timeslot_distribution.presentation.title.toLowerCase().indexOf(this.searchTerm.toLowerCase()) === -1 &&
          timeslot_distribution.presentation.speaker_name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) === -1
        ) {
          shouldRemove = true;
      }
//      if (
//          !timeslot_distribution.presentation &&
//          !timeslot_distribution.block &&
//          timeslot_distribution.default_title &&
//          timeslot_distribution.default_title.toLowerCase().indexOf(this.searchTerm.toLowerCase()) === -1
//        ) {
//          shouldRemove = true;
//      }
      if (
          this.filter.locationId &&
          timeslot_distribution.timeslot.location &&
          timeslot_distribution.timeslot.location.id !== +this.filter.locationId
        ) {
          shouldRemove = true;
      }
      if (
          this.filter.blockCategoryId &&
          timeslot_distribution.block &&
          timeslot_distribution.block.block_category_id !== +this.filter.blockCategoryId
        ) {
          shouldRemove = true;
      }
      if (
          this.filter.bookmarked &&
          timeslot_distribution.block &&
          !this.bookmarkedBlockIds.includes(timeslot_distribution.block.id)
        ) {
          shouldRemove = true;
      }
      timeslot_distribution.should_remove = shouldRemove;
    }


    this.timeslotDistributions = this.program.timeslot_distributions
    .sort(
      (a, b) => {
        if (a.starts_at < b.starts_at) {
          return -1;
        } else if (a.starts_at > b.starts_at) {
          return 1;
        } else {
          if (a.order < b.order) {
            return -1;
          } else if (a.order > b.order) {
            return 1;
          } else {
            return 0;
          }
        }
      });
    this.timeslotDistributions = this.timeslotDistributions.filter(timeslotDistribution => !timeslotDistribution.should_remove);
    setTimeout(() => {
      this.filtering = false;
    }, 500);
  } 

  search() {
    this.nestProgramElements();
  }

  selectDay(day: string) {
    this.selectedDay = day;
  }

  toggleFilterBookmarked() {
    this.justBookmarked = !this.justBookmarked;
  }

  toggleBookmark(presentation: Presentation) {
    const bookmark = this.bookmarks.find(b => b.presentation_id === presentation.id);
    if (!bookmark) {
      this.bookmarkService.addWebsiteBookmark(this.website.id, presentation)
        .subscribe(
          () => { },
          () => { }
        );
    } else {
      this.bookmarkService.removeWebsiteBookmark(this.website.id, presentation)
        .subscribe(
          () => { },
          () => { }
        );
    }
  }

  presentationDetails(presentationId: number) {
    if (this.mode === 'config') {
      window.open(`//${window.location.host}/o/${this.organizationId}/conference/${this.conferenceId}/program/presentation/${presentationId}`, '_blank');
    } else {
      this.router.navigate(['/presentation', presentationId]);
    }
  }

  showAuthor(author: Author) {
    const modalRef = this.modalService.open(AuthorModalComponent);
    modalRef.componentInstance.author = author;
  }

  isToday(date) {
    const today = new Date();
    const inputDate = new Date(date);
    return (today.toDateString() === inputDate.toDateString());
  }

  getDimensionsByFilter(haystack, id){
    return haystack.filter(x => x.id === id);
  }
}
