<section class="nv-section guttered">
    <div class="nv-port">
        <div class="nv-row spaced-top small-spaced-bottom gap-12 flow-center">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <h2>Your live studios</h2>
                </div>
            </div>
        </div>
    </div>
</section>

<main class="nv-section guttered">
    <div class="nv-port">
        <div class="nv-row spaced">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <!-- Top bar -->
                    <div class="nv-studio-top-bar">
    <!--                            <form class="studio-top-bar-search">
                            <input type="text" name="" placeholder="Search term...">
                            <button type="submit"></button>
                        </form>-->
                        <!--<h4 *ngIf="!canJoin" class="studio-top-bar-msg">Welcome to the live studio. Once moderator allows, you will be able to join one of the shown studios.</h4>-->
                        <h4 class="studio-top-bar-msg">
                            Find the correct presentation/session that you want to moderate or present at, and click on the button "<strong>Enter the studio</strong>" to join the live conversation.
                        </h4>
                    </div>
                    <!-- Data -->
                    <ng-container *ngFor="let location of presentingLocations" class="nv-data-table break-lap">
                        <!-- Data item -->
                        <h3 class="nv-studio-instance-heading"><span>{{location.name}}</span><strong>Studio</strong></h3>
                        <table class="nv-studio-presentations">
                            <tr>
                                <th><span>Presentation</span></th>
                                <th><span>Session</span></th>
                                <th><span>Time</span></th>
                            </tr>
                            <ng-container *ngFor="let presentation of myPresentations">
                                <ng-container *ngIf="(presentation.location && presentation.location.id == location.id) || (presentation.block.location && presentation.block.location.id == location.id)">
                                    <tr>

                                        <td>{{ presentation.title }}</td>
                                        <td>{{ presentation.block_name }}</td>
                                        <td>{{ presentation.starts_at | nvDate: 'dd.MM.yyyy' }} {{ presentation.starts_at | nvDate: 'HH:mm' }} - {{ presentation.ends_at | nvDate: 'HH:mm' }}</td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </table>
                        <div class="nv-studio-instance-studio-btn">
                            <button (click)="enterStudio(location)" role="button" class="nv-btn accent2 secondary small" data-icon-pl="&#xe94a;" translate>Enter the studio</button>
                        </div>
                    </ng-container>
                    <!-- /Data -->
                </div>
            </div>
        </div>
    </div>
</main>
