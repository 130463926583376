import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class VideoPlayerPresetService {

  constructor(
    private apiService: ApiService
  ) {
  }

  getPlayerPresets(organizationId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/player-presets`, params);
  }

  getPlayerPreset(organizationId: number, presetId: string, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/player-presets/${presetId}`, params);
  }

  createPlayerPreset(organizationId: number, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/player-presets`, params);
  }

  updatePlayerPreset(presetId: string, params: any = {}) {
    return this.apiService.put(`player-presets/${presetId}`, params);
  }

  deletePlayerPreset(presetId: string, params: any = {}) {
    return this.apiService.delete(`player-presets/${presetId}`, params);
  }

  setDefaultPresetForMaterial(materialId: number, presetId: string) {
    return this.apiService.put(`materials/${materialId}/player-presets/${presetId}`);
  }

}
