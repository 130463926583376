import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
    selector: '[appActionMenuDropdown]'
})
export class ActionMenuDropdownDirective {
    @HostBinding('style.display') display = 'none';

    constructor(public host: ElementRef) {
    }

    get element() {
        return this.host.nativeElement;
    }

}
