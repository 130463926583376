import { Component, Input, Output, EventEmitter, ViewChild, HostListener, AfterContentInit, OnDestroy, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { ActionMenuOriginDirective } from '../dropdown/action-menu-origin.directive';
import { ActionMenuDropdownDirective } from '../dropdown/action-menu-dropdown.directive';

@Component({
    selector: 'app-dropdown-container',
    templateUrl: './dropdown-container.component.html'
})
export class DropdownContainerComponent implements AfterContentInit, OnDestroy {

    @ViewChild(ActionMenuOriginDirective) origin: ActionMenuOriginDirective;
    @ViewChild(ActionMenuDropdownDirective) dropdown: ActionMenuDropdownDirective;

    @Input() items: any;
    @Input() placeholder: string;
    @Input() selected: string;

    private open = false;
    private originSub: Subscription;

    constructor(private host: ElementRef) {}

    @HostListener('document:click', ['$event.target'])
    click(target) {
        if (!(this.host.nativeElement as HTMLElement).contains(target)) {
            this.close();
        }
    }

    ngAfterContentInit() {
        this.originSub = this.origin.click.subscribe(_ => {
            this.open = !this.open;
            if (this.open) {
                this.openMenu();
            } else {
                this.close();
            }
        });
    }

    openMenu() {
        this._toggleDropdown();
    }

    close() {
        this.open = false;
        this._toggleDropdown(false);
    }

    private _toggleDropdown(show = true) {
        const display = show ? 'block' : 'none';
        this.dropdown.element.style.display = display;
    }

    ngOnDestroy() {
        this.originSub && this.originSub.unsubscribe();
        this.close();
    }
}
