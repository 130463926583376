<div style="position: relative">
    <div class="nv-global-loading on" [class.on]="programLoading">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->

    <section class="nv-section guttered" *ngIf="program">
        <div class="nv-port">
            <div class="nv-row spaced-top small-spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-line-tabs">
                            <nav class="nv-tabs-nav vertical-break-phab">
                                <ul>
                                    <li *ngFor="let day of days" (click)="selectDay(day)">
                                        <a [ngClass]="{'active': selectedDay === day }">{{ day | dateFormat:'MMMM' | translate }} {{ day | dateFormat:'DD' | translate }}</a>
                                    </li>
                                    <li *ngIf="showSpeakersTab" class="nv-tab-aside">
                                        <a routerLink="/speakers">Show speakers</a>
                                    </li>
                                </ul>
                            </nav><!-- /.nv-tabs-nav -->
                        </div><!-- /.nv-line-tabs -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row spaced-top small-spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-planner-title">
                            <h1> <span translate>Schedule</span> - {{ selectedDay | dateFormat:'dddd' | translate }}, {{ selectedDay | dateFormat:'MMMM' | translate }} {{ selectedDay | dateFormat:'DD' | translate }}</h1>
                            <div class="pt-side">
                                <!--label class="nv-toggle-switch star">
                                    <input type="checkbox" [(ngModel)]="justBookmarked" (change)="toggleFilterBookmarked();">
                                    <span class="ts-visual"></span>
                                </label--><!-- /.nv-toggle-switch -->
                            </div><!-- /.pt-side -->
                        </div><!-- /.nv-planner-title -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->

    <main class="nv-section guttered" *ngIf="program">
        <div class="nv-port">
            <div class="nv-row spaced gap-10 propagate">
                <div class="nv-column desk-4 tab-12">
                    <div class="nv-column-content">
                        <nv-input
                            [label]="'Search' | translate" 
                            [(ngModel)]="searchTerm"
                            (ngModelChange)="search()"
                        ></nv-input>
                    </div>
                </div>
                <div class="nv-column desk-3 tab-12" *ngIf="program.locations && program.locations.length > 1">
                    <div class="nv-column-content">
                        <nv-select
                            [options]="program.locations"
                            [label]="'Location' | translate" 
                            [(ngModel)]="filter.locationId"
                            (ngModelChange)="search()"
                        ></nv-select>
                    </div>
                </div>
                <div class="nv-column desk-3 tab-12" *ngIf="program.block_categories && program.block_categories.length > 1">
                    <div class="nv-column-content">
                        <nv-select 
                            [options]="program.block_categories"
                            [label]="'Category' | translate" 
                            [(ngModel)]="filter.blockCategoryId"
                            (ngModelChange)="search()"
                        ></nv-select>
                    </div>
                </div>
                <div class="nv-column desk-2 tab-12 v-middle">
                    <div class="nv-column-content nv-bookmark-label">
                        <nv-checkbox 
                            [label]="'Bookmarked' | translate" 
                            [(ngModel)]="filter.bookmarked"
                            (ngModelChange)="search()"
                        ></nv-checkbox>
                    </div>
                </div>
            </div>
            <div class="nv-row spaced no-side-gap">
                <div class="nv-column desk-12">
                    <div class="nv-column-content" style="position: relative;">
                        <div class="nv-global-loading on" [class.on]="filtering">
                            <div class="nv-spinner"></div>
                        </div><!-- /.nv-global-loading -->
                        <div class="nv-planner-item" *ngFor="let timeslotDistribution of timeslotDistributions; index as index">
                            <ng-container *ngIf="timeslotDistribution.timeslot.date === selectedDay && (conference?.id!=14340 || startsWith('ON-DEMAND', timeslotDistribution.block?.name))">
                                <header class="nv-planner-header" [ngClass]="{ 'break-type': timeslotDistribution.block?.type === 'Break' || !(timeslotDistribution.block_id || timeslotDistribution.presentation_id) }">
                                    <div *ngIf="showTimeColumn" class="ph-time">
                                        <ng-container *ngIf="index === 0 || timeslotDistribution.starts_at.substr(0, 16) !== timeslotDistributions[index - 1].starts_at.substr(0, 16)">
                                            {{ timeslotDistribution.starts_at | dateFormat:'HH:mm' }}
                                        </ng-container>
                                    </div><!-- /.pt-time -->
                                    <div class="ph-body">
                                        <p class="ph-ls" *ngIf="timeslotDistribution.block?.streamable" translate>livestream</p>&nbsp;
                                        <p class="ph-ls" *ngIf="timeslotDistribution.timeslot?.location" [style.background-color]="timeslotDistribution.timeslot.location?.color" translate>{{timeslotDistribution.timeslot?.location.name}}</p>
                                        <!-- <span class="phm-location" style="background-color: {{timeslotDistribution.timeslot.location.color}}" *ngIf="timeslotDistribution.timeslot.location && program.locations.length>1" [class.nv-highlighted]="filter.locationId">{{ timeslotDistribution.timeslot.location.type | translate }}: {{ timeslotDistribution.timeslot.location.name }}</span> -->
                                        <h3>
                                            <a 
                                                *ngIf="!timeslotDistribution.block && !timeslotDistribution.presentation"
                                                [innerHTML]="timeslotDistribution.default_title | highlight: searchTerm"
                                            ></a>
                                            <a 
                                                *ngIf="timeslotDistribution.presentation" 
                                                (click)="presentationDetails(timeslotDistribution.presentation.id)" 
                                                [innerHTML]="timeslotDistribution.presentation.title | highlight: searchTerm"
                                            ></a>
                                            <a 
                                                *ngIf="timeslotDistribution.block" 
                                                (click)="blockClick(timeslotDistribution.block);" 
                                                [innerHTML]="timeslotDistribution.block.name | highlight: searchTerm"
                                            ></a>
                                        </h3>
                                        <div class="ph-meta">
                                            <span class="phm-time-frame">
                                                {{ timeslotDistribution.starts_at | dateFormat:'HH:mm' }} - {{ timeslotDistribution.ends_at | dateFormat:'HH:mm' }}
                                                <ng-container *ngIf="conference?.settings?.timezone?.title"> {{ conference?.settings?.timezone?.title }}</ng-container>
                                                <ng-container *ngIf="timeslotDistribution?.block?.time_info"> | {{ timeslotDistribution?.block?.time_info }}</ng-container>
                                            </span>
                                            <span class="phm-cat" *ngIf="timeslotDistribution.block?.block_category_name != 'null'" [class.nv-highlighted]="filter.blockCategoryId">
                                                <em *ngIf="timeslotDistribution.block?.block_category_name" [style.backgroundColor]="timeslotDistribution.block?.block_category_color!='null' ? timeslotDistribution.block?.block_category_color : '#cccccc'"></em>
                                                {{ timeslotDistribution.block?.block_category_name }}
                                            </span>
                                        </div><!-- /.ph-meta -->
                                        <h4 *ngIf="timeslotDistribution.block?.subtitle" style="font-weight: 400;" [innerHtml]="timeslotDistribution.block?.subtitle">{{ timeslotDistribution.block?.subtitle }}</h4>
                                        <div class="nv-participant-row" *ngIf="timeslotDistribution.block?.chairpersons.length>0">
                                            <label>Chaired By:</label>

                                            <div class="nv-participant" *ngFor="let chairperson of timeslotDistribution.block.chairpersons">
                                                <!--<div class="p-img"><span></span></div>-->
                                                <span class="p-img" *ngIf="chairperson?.image_url_thumb">
                                                    <span [style.backgroundImage]="chairperson?.image_url_thumb ? 'url('+ chairperson?.image_url_thumb +')' : ''"></span>
                                                </span>
                                                <div class="p-title-name">
                                                    <a (click)="showAuthor(chairperson)" [innerHtml]="chairperson?.title ? chairperson?.title + '&nbsp;' + chairperson?.name : chairperson?.name | highlight: searchTerm"></a>
                                                    <!-- <p [innerHtml]="chairperson.name | highlight: searchTerm"></p> -->
                                                </div><!-- /.p-name -->
                                            </div><!-- /.nv-participant -->
                                        </div><!-- /.nv-participant-row -->
                                    </div><!-- /.ph-body -->
                                   <!-- <div class="ph-tools">-->
<!--                                        <p class="ph-ls" *ngIf="timeslotDistribution.block?.streamable" translate>livestream</p>-->
<!--                                        <div (click)="toggleTimeline(event, dayPreview.key)"-->
<!--                                             [ngClass]="{'on' : event.timeline && event.timeline.length}" class="ph-star">-->
<!--                                            <span></span>-->
<!--                                        </div>-->
<!--                                        <a class="ph-modal-trigger" (click)="showBlockDetails(timeslot_distribution.block_id)">-->
<!--                                            <span>Show details</span>-->
<!--                                        </a>-->
<!--                                    </div>&lt;!&ndash; /.ph-tools &ndash;&gt; -->
                                </header><!-- /.nv-planner-header -->
                                <div class="nv-planner-sessions">
                                    <div *ngIf="blocksLoading.includes(timeslotDistribution.block?.id)" class="nv-loading" style="min-height: 120px;">
                                        <div class="nv-loading-visual" translate>Loading</div>
                                    </div>
                                    <ng-container *ngIf="!blocksLoading.includes(timeslotDistribution.block_id) && timeslotDistribution.block?.presentations">
                                        <ng-container *ngFor="let presentation of timeslotDistribution.block?.presentations">
                                            <div class="ps-item" *ngIf="!filter.bookmarked || bookmarkedPresentationIds.includes(presentation.id)">
                                                <div class="psi-time">
                                                    {{ presentation.starts_at | dateFormat:'HH:mm' }}
                                                </div>
                                                <div class="psi-body">
                                                    <div class="nv-participant">
                                                        <div class="p-title-name">
                                                            <div class="psi-features">
                                                                <p class="psi-ls" *ngIf="presentation.streamable" translate>livestream</p>
                                                                <p class="psi-od" *ngIf="!presentation.streamable && presentation.webcast">on-demand</p>
                                                            </div><!-- /.psi-features -->
                                                            <h4>
                                                                <a (click)="presentationDetails(presentation.id)" [innerHtml]="presentation.title | highlight: searchTerm"></a>
                                                            </h4>
                                                            <div class="nv-participants-box" *ngIf="presentation?.authors && !presentation?.speakers">
                                                                <p *ngFor="let speaker of presentation.authors; let isFirst = first;">
                                                                    <span class="p-img" *ngIf="speaker.image_url_thumb">
                                                                        <span [style.backgroundImage]="speaker.image_url_thumb ? 'url('+ speaker.image_url_thumb +')' : ''"></span>
                                                                    </span>
                                                                    <a (click)="showAuthor(speaker)">
                                                                        <ng-container *ngIf="speaker.title">{{speaker.title}}&nbsp;</ng-container>
                                                                        <span [innerHtml]="speaker.name | highlight: searchTerm"></span>
                                                                    </a>
                                                                </p>
                                                            </div>
                                                            <div class="nv-participants-box" *ngIf="!presentation?.authors && presentation?.speakers">
                                                                <p *ngFor="let speaker of presentation.speakers; let isFirst = first;">
                                                                    <span class="p-img" *ngIf="speaker.image_url_thumb">
                                                                        <span [style.backgroundImage]="speaker.image_url_thumb ? 'url('+ speaker.image_url_thumb +')' : ''"></span>
                                                                    </span>
                                                                    <a (click)="showAuthor(speaker)">
                                                                        <ng-container *ngIf="speaker.title">{{speaker.title}}&nbsp;</ng-container>
                                                                        <span [innerHtml]="speaker.name | highlight: searchTerm"></span>
                                                                    </a>
                                                                </p>
                                                            </div>

                                                            <!-- <div class="nv-participants-box" *ngIf="!presentation?.authors && (speakers[presentation.id] && speakers[presentation.id].length > 0)">
                                                                <p *ngFor="let speaker of speakers[presentation.id]; index as i; let isFirst = first;">
                                                                    <span class="p-img" *ngIf="speaker.image_url_thumb">
                                                                        <span [style.backgroundImage]="speaker.image_url_thumb ? 'url('+ speaker.image_url_thumb +')' : ''"></span>
                                                                    </span>
                                                                    <span [innerHtml]="speaker | highlight: searchTerm"></span>
                                                                </p>
                                                            </div>
                                                            <p *ngIf="!presentation?.authors && (!speakers[presentation.id] || speakers[presentation.id].length === 0)">{{ presentation.speaker_name }}</p>
   -->
                                                            <!-- <p *ngIf="conference?.id==8353 && speakers[presentation.id] && speakers[presentation.id].length>0">
                                                                Chair: <span>{{ speakers[presentation.id][0] }}</span>&nbsp;
                                                                <ng-container *ngFor="let speaker of speakers[presentation.id]; index as i">
                                                                    <ng-container *ngIf="i===1">
                                                                        Panelists: <span>{{ speaker }}</span>;&nbsp;
                                                                    </ng-container>
                                                                    <ng-container *ngIf="i>1">
                                                                        <span>{{ speaker }}</span>;&nbsp;
                                                                    </ng-container>
                                                                </ng-container>
                                                            </p> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="psi-tools">
<!--                                                    <p class="psi-ls" *ngIf="presentation?.streamable" translate>livestream</p>-->
                                                </div>
                                                <div class="nv-bookmark-trigger nv-clickable"
                                                     [class.on]="bookmarkedPresentationIds.includes(presentation.id)"
                                                     (click)="toggleBookmark(presentation)"
                                                     title="{{'Bookmark this presentation' | translate}}"
                                                ></div>
                                            </div>
                                        </ng-container>
                                        <!--div *ngIf="timeslotDistribution.block?.presentations.length === 0" class="nv-planner-no-session">
                                            <span translate>No presentations added to this session</span>
                                        </div-->
                                    </ng-container>
                                </div><!-- /.nv-planner-sessions -->
                            </ng-container>
                        </div><!-- /.nv-planner-item -->
                    </div>
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </main><!-- /.nv-section -->
</div>

