import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BillingService } from "@navus/core/services/billing.service";
import { ModalService } from "@navus/ui/modal/modal.service";
import { Subscription } from "rxjs";
import { CardModalComponent } from '../../../../modals/card/card-modal.component';
import { Website } from "@navus/core/classes/website";
import { Conference } from "@navus/core/classes/conference";

@Component({
  selector: 'nv-website-registration-payment',
  templateUrl: './payment.component.html'
})
export class RegistrationPaymentComponent implements OnInit, OnDestroy {
  cards: any[] = [];
  card: boolean = true;

  loadingCards: boolean = false;

  subscriptions: Subscription[] = [];

  website: Website;
  conference: Conference;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private billingService: BillingService,
    private modalService: ModalService,
  ) { }
  
  ngOnInit() {
    this.website = this.route.parent.parent.parent.snapshot.data.website;
    this.conference = this.website?.conferences?.find(c => c.id === this.website.active_conference_id);
    this.getCards();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getCards() {
    this.loadingCards = true;
    this.billingService
      .getCards()
      .subscribe(
        (response) => {
          this.cards = response.data.sort(
            (a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)
          );
          this.loadingCards = false;
        },
        () => {
          this.loadingCards = false;
        }
      );
  }

  addCard() {
    const modalRef = this.modalService.open(CardModalComponent);
    modalRef.result.then(
      () => {
        this.getCards();
      },
      () => { }
    );
  }

  setDefaultCard(cardId: number) {
    this.loadingCards = true;
    this.billingService.setDefaultCard(cardId)
      .subscribe(
        () => {
          this.getCards();
        },
        (error) => {
          this.modalService.error({errors: error});
          this.loadingCards = false;
        }
      );
  }

  deleteCard(cardId: number) {
    this.modalService.defaultModal({
      title: 'Card Deletion',
      body: 'Are you sure you want to delete this card?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.billingService.deleteCard(cardId)
              .subscribe(
                () => {
                  this.getCards();
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

  next() {
    let params = Object.assign({method:this.card ? "card" : "bank"}, this.route.snapshot.queryParams);

    this.router.navigate(['..', 'delegate'], { 
      relativeTo: this.route,
      queryParams: params
    });
  }

}