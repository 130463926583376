import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ConferenceService } from '@navus/core/services/conference.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '@navus/ui/modal/modal.service';
import { TimeFormatPipe } from '@navus/ui/pipes/time-format.pipe';
import { Conference } from '@navus/core/classes/conference';
import { Color } from '@swimlane/ngx-charts';

@Component({
  selector: 'main [nv-sponsor-dashboard]',
  templateUrl: './sponsor-dashboard.component.html',
})
export class SponsorDashboardComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  conference: Conference;

  loadingBrowsingSponsors: boolean;
  sponsorsBrowsingData: SponsorsTotalData;

  dateFrom: string;
  dateTo: string;

  selectedSponsor: SponsorData;
  chartColorScheme = {
    domain: ['#318AF1', '#2873A1', '#8ABAD6', '#32B799', '#279079', '#18707F', '#ED4649', '#F49092', '#AD465A', '#ED8747']
  } as Color;
  chartCardColor: string = '#232837';

  overallChartData: { name: string, value: number }[] = [];

  viewsPerSponsorChartData: { name: string, series: { name: string, value: number }[] }[] = [];

  timeSpentPerSponsorChartData: { name: string, value: number }[];
  timeSpentPerSponsorLegend: { name: string, value: string, color: string, image?: string }[];
  timeSpentPerSponsorActiveEntries = [];

  downloadingTotal = false;
  downloadingDetailed = false;
  downloadingSponsor = false;
  lastRecordedDateTime: string;
  private subscriptions: Subscription[] = [];

  constructor(private conferenceService: ConferenceService,
              private timeFormatPipe: TimeFormatPipe,
              private modalService: ModalService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;

        this.conferenceService.getConference(this.conferenceId)
          .subscribe((response) => {
            this.conference = response.data;
            this.dateFrom = this.conference.starts_at;
            this.dateTo = this.conference.ends_at;

            this.getConferenceBrowsingSponsors();
          }, (error) => {
            this.modalService.error({ errors: error });
          });

      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  timeSpentLegendLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.timeSpentPerSponsorActiveEntries = this.timeSpentPerSponsorChartData.filter(item => item.name === name);
  }

  timeSpentLegendLabelDeactivate(item: any): void {
    this.timeSpentPerSponsorActiveEntries = [];
  }

  onSelectBar(value): void {
    if (value.series) {
      this.selectedSponsor = this.sponsorsBrowsingData.sponsors.find(sponsor => sponsor.name === value.series);
    }
  }

  downloadSponsorReport() {
    this.downloadingSponsor = true;
    this.conferenceService.downloadDetailedDelegateAccessForSponsorXls(this.conferenceId, this.selectedSponsor.id, this.selectedSponsor.name)
      .subscribe(
        () => {
          this.downloadingSponsor = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.downloadingSponsor = false;
        }
      );
  }

  downloadTotal() {
    this.downloadingTotal = true;
    this.conferenceService.downloadTotalVisitsXls(this.conferenceId)
      .subscribe(
        () => {
          this.downloadingTotal = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.downloadingTotal = false;
        }
      );
  }

  downloadDetailed() {
    this.downloadingDetailed = true;
    this.conferenceService.downloadDetailedDelegateAccessXls(this.conferenceId)
      .subscribe(
        () => {
          this.downloadingDetailed = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.downloadingDetailed = false;
        }
      );
  }

  formatNumber = (value: number): any => this.transformToTime(value);

  transformToTime(item): string {
    if (item.label.indexOf('time') > -1) {
      return this.timeFormatPipe.transform(item.value);
    } else {
      return item.value;
    }
  }

  applyDateRange(): void {
    this.getConferenceBrowsingSponsors();
  }

  private getConferenceBrowsingSponsors(): void {
    this.loadingBrowsingSponsors = true;
    this.conferenceService.getConferenceBrowsingSponsors(this.conferenceId, {
      from_date: this.dateFrom,
      to_date: this.dateTo
    })
      .subscribe((response) => {
        this.loadingBrowsingSponsors = false;
        this.sponsorsBrowsingData = response.data;

        if (this.sponsorsBrowsingData) {
          this.populateOverallChart();
          this.populateViewsChartPerSponsor();
          this.populateTimeSpentChartPerSponsor();
        }

        if (response.last_recorded_date_time) {
          this.lastRecordedDateTime = response.last_recorded_date_time;
        }
      }, (error) => {
        this.loadingBrowsingSponsors = false;
        this.modalService.error({ errors: error });
      });
  }

  private populateOverallChart(): void {
    this.overallChartData = [
      {
        name: 'Total visits',
        value: this.sponsorsBrowsingData.total_views,
      },
      {
        name: 'Unique user visits',
        value: this.sponsorsBrowsingData.total_unique_views,
      },
      {
        name: 'Users time spent',
        value: this.sponsorsBrowsingData.time_spent,
      }
    ];
  }

  private populateViewsChartPerSponsor(): void {
    this.viewsPerSponsorChartData = this.sponsorsBrowsingData.sponsors
      .map(sponsor => ({
        name: sponsor.name,
        series: [
          {
            name: 'Total visits',
            value: sponsor.total_views
          },
          {
            name: 'Unique visits',
            value: sponsor.total_unique_views
          }
        ]
      }));
  }

  private populateTimeSpentChartPerSponsor(): void {
    this.timeSpentPerSponsorChartData = this.sponsorsBrowsingData.sponsors
      .filter(sponsor => sponsor.time_spent)
      .map(sponsor => ({
        name: sponsor.name,
        value: sponsor.time_spent,
      }));
    this.timeSpentPerSponsorLegend = this.sponsorsBrowsingData.sponsors
      .filter(sponsor => sponsor.time_spent)
      .map((sponsor, index) => ({
        name: sponsor.name,
        value: this.timeFormatPipe.transform(sponsor.time_spent),
        color: this.chartColorScheme.domain[index % 10],
        image: sponsor.logo_url
      }));
  }

}

interface SponsorsTotalData {
  sponsors: SponsorData[];
  time_spent: number;
  total_unique_views: number;
  total_views: 4;
}

interface SponsorData {
  id: number;
  logo_url: string;
  name: string;
  time_spent: number;
  total_unique_views: number;
  total_views: 4;
}
