import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebsiteService } from '@navus/core/services/website.service';
import { Website } from '@navus/core/classes/website';

@Injectable()
export class WebsiteResolver implements Resolve<any> {
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(
    private router: Router,
    private websiteService: WebsiteService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.websiteService.getCurrentWebsite({ include: 'pages,conferences' })
      .pipe(
        map(
          (website: Website) => {
            if (website.favicon && website.favicon !== '') {
              this.favIcon.href = website.favicon;
            }

            return website;
          }
        )
      );
  }
}
