import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ConferenceService } from '@navus/core/services/conference.service';
import { map, mergeMap, take } from 'rxjs/operators';
import { ChatService } from '@navus/core/services/chat.service';
import { Room } from '@navus/core/classes/chat';

@Component({
  selector: 'main [nv-qa-settings-page]',
  templateUrl: './qa-settings-page.component.html'
})
export class QaSettingsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;

  qaSettingsForm: FormGroup;
  qaBlocks: Room[];

  loadingChatSettings: boolean = true;
  savingChatSettings: boolean = false;
  unsavedChanges: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private modalService: ModalService,
    private conferenceService: ConferenceService,
    private chatService: ChatService,
  ) {
  }

  ngOnInit() {
    this.initForm();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getQaSettings();
      }
    );
    this.getQABlocks();
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.qaSettingsForm = this.formBuilder.group({
      enabled: [false],
      public_tab: [false],
      moderation: [false],
    });
  }

  getQaSettings() {
    this.loadingChatSettings = true;
    this.conferenceService
      .getOrganizationConference(this.organizationId, this.conferenceId)
      .subscribe(
        (response) => {
          const conference = response.data;
          this.qaSettingsForm.patchValue(conference.settings?.qa_settings);
          this.loadingChatSettings = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.loadingChatSettings = false;
        }
      );
  }

  save() {
    if (this.qaSettingsForm.invalid) {
      return;
    }

    this.savingChatSettings = true;
    const validatedSettings = this.validateForm(this.qaSettingsForm.value);
    const params = { settings: { qa_settings: validatedSettings } };
    this.conferenceService
      .updateOrganizationConference(this.organizationId, this.conferenceId, params)
      .subscribe(
        () => {
          this.toastService.success('Chat settings successfully saved.', {});
          this.unsavedChanges = false;
          this.savingChatSettings = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.savingChatSettings = false;
        }
      );
  }

  exportChats() {
    if (!this.qaBlocks.length) {
      return;
    }
    const messagesPerGroup$ = this.qaBlocks
      .map(group => this.chatService
        .getMessagesForRoom(this.conferenceId, group.uid, false)
        .pipe(map((messages) => ({ group, messages })))
      );

    const messagesSubscription$ =
      this.chatService.getAllChatUsers().pipe(
        take(1),
        mergeMap(chatUsers => combineLatest(messagesPerGroup$).pipe(map(msgs => ({ messages: msgs, chatUsers }))))
      ).subscribe(result => {
        const dataToExport = [];
        result.messages.forEach((item) => {
          item.messages.forEach((msg) => {
            dataToExport.push({
              'Created by': result.chatUsers?.find(user => user.userId === msg.createdBy)?.name,
              'Created at': new Date(msg.createTime).toISOString(),
              'Text': msg.text,
              'Approved': msg.approved,
              'Replied to': msg.relatedMessageText,
              'Block': item.group.name
            });
          });
        });
        this.saveAsCsv(dataToExport);
      });
    this.subscriptions.push(messagesSubscription$);
  }

  private getQABlocks() {
    this.chatService
      .getAllPublicGroups(this.conferenceId)
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.qaBlocks = response.filter(pg => pg.name.indexOf('block_') > -1);
        },
        (error) => {
          this.modalService.error({ errors: error });
        });
  }

  private validateForm(settings: { enabled: boolean, public_tab: boolean, moderation: boolean }) {
    if (!settings.enabled) {
      delete settings.public_tab;
      delete settings.moderation;
    }
    if (!settings.public_tab) {
      delete settings.moderation;
    }
    return settings;
  }

  private saveAsCsv(data) {
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'messages_export_' + this.conferenceId + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

}
