import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, of } from 'rxjs';
import { Website } from '../classes/website';
import { map } from 'rxjs/operators';
import { Conference } from '../classes/conference';
import { WebsitePage } from '../classes/website-page';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { environment } from '../../../environments/environment';
import Echo from 'laravel-echo';
import * as io from 'socket.io-client';

import Pusher from 'pusher-js';

@Injectable()
export class WebsiteService {
  public currentWebsiteSubject = new BehaviorSubject<Website>(null as Website);
  public currentWebsite = this.currentWebsiteSubject.asObservable();
  public currentWebsitePageSubject = new BehaviorSubject<WebsitePage>(null as WebsitePage);
  public currentWebsitePage = this.currentWebsitePageSubject.asObservable();
  public currentWebsitePageSectionSubject = new BehaviorSubject<WebsitePageSection>(null as WebsitePageSection);
  public currentWebsitePageSection = this.currentWebsitePageSectionSubject.asObservable();

  constructor(
    private apiService: ApiService
  ) { }

  getWebsites(params: any = {}) {
    return this.apiService.get(`websites`, params);
  }

  getWebsite(websiteId: number, params: any = {}) {
    return this.apiService.get(`websites/${websiteId}`, params);
  }

  updateWebsite(websiteId: number, params: any = {}) {
    return this.apiService.put(`websites/${websiteId}`, params);
  }

  createWebsite(params: any = {}) {
    return this.apiService.post(`websites`, params);
  }

  deleteWebsite(websiteId: number) {
    return this.apiService.delete(`websites/${websiteId}`);
  }

  getOrganizationWebsites(organizationId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/websites`, params);
  }

  getOrganizationWebsite(organizationId: number, websiteId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/websites/${websiteId}`, params);
  }

  updateOrganizationWebsite(organizationId: number, websiteId: number, params: any = {}) {
    return this.apiService.put(`organizations/${organizationId}/websites/${websiteId}`, params);
  }

  createOrganizationWebsite(organizationId: number, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/websites`, params);
  }

  deleteOrganizationWebsite(organizationId: number, websiteId: number) {
    return this.apiService.delete(`organizations/${organizationId}/websites/${websiteId}`);
  }

  getConferenceWebsite(organizationId: number, conferenceId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/conferences/${conferenceId}/websites`, params);
  }

  updateConferenceWebsite(organizationId: number, conferenceId: number, websiteId: number, params: any = {}) {
    return this.apiService.put(`organizations/${organizationId}/conferences/${conferenceId}/websites/${websiteId}`, params);
  }

  createConferenceWebsite(organizationId: number, conferenceId: number, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/conferences/${conferenceId}/websites`, params);
  }

  deleteConferenceWebsite(organizationId: number, conferenceId: number, websiteId: number) {
    return this.apiService.delete(`organizations/${organizationId}/conferences/${conferenceId}/websites/${websiteId}`);
  }

  getWebsiteConferences(params: any = {}) {
    return this.apiService.get(`websites/current`, { include: 'conferences', ...params })
      .pipe(
        map(
          (response) => {
            return response.data.conferences.sort(
              (a: Conference, b: Conference) => {
                if (a.starts_at > b.starts_at) {
                  return -1;
                }
                if (a.starts_at < b.starts_at) {
                  return 1;
                }
                return 0;
              }
            );
          })
      );
  }

  setCurrentWebsite(organizationId: number, websiteId: number) {
    if (websiteId) {
      if (this.currentWebsiteSubject.value && this.currentWebsiteSubject.value.id === +websiteId) {
        return of(null);
      }

      return this.apiService.get(`organizations/${organizationId}/websites/${websiteId}`)
        .pipe(map(response => {
          this.currentWebsiteSubject.next(response.data);
          return response.data;
        }));
    } else {
      this.currentWebsiteSubject.next(null);
      return of(null);
    }
  }

  getCurrentWebsite(params: any = {}) {
    return this.apiService.get(`websites/current`, params)
      .pipe(map(response => {
        this.currentWebsiteSubject.next(response.data);
        return response.data;
      }));
  }

  setCurrentWebsitePage(page: WebsitePage) {
    this.currentWebsitePageSubject.next(page);
  }

  checkIfDomainExists(params: any = {}) {
    return this.apiService.get(`websites/check`, params);
  }

  async initWebsocket() {
    window['Pusher'] = Pusher;
    window['Echo'] = new Echo({
      broadcaster: 'pusher',
      key: `${environment.mix_pusher_app_key}`,
      wsHost: `${environment.mix_pusher_host}`,
      wsPort: `${environment.mix_pusher_port}`,
      wssPort: `${environment.mix_pusher_port}`,
      forceTLS: `${environment.mix_pusher_port === 443}`,
      encrypted: true,
      disableStats: true,
      enabledTransports: ['ws', 'wss']
    });

    return window['Echo'];
  }

}
