<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Speakers</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-8">
                <nv-button color="primary" (click)="inviteSelected()" [disabled]="selectedIds.length === 0">Invite selected</nv-button>
                <nv-button color="primary" iconPl="&#xe095;" (click)="add()">Add speaker</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <form class="config-search-bar-w-filters" [formGroup]="formFilter" autocomplete="off">
        <div class="sbwf-port">
            <input type="text" placeholder="Search..."
                   formControlName="search_term" (change)="getAuthors()">
            <span class="sbwf-outline"></span>
        </div><!-- /.sbwf-port -->
    </form><!-- /.config-search-bar-w-filters -->
</div>
<div class="nv-config-content-port" nvInfiniteScroll (scrolled)="getAuthors(true)">
    <div class="nv-global-loading on" [class.on]="authorsLoading">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <section class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates">
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content">
                        <div *ngIf="pagination.total === 0">
                            <div class="nv-empty-state">
                                <div class="img">
                                    <img src="../../../../assets/empty-states/empty-state-speakers.svg" alt="">
                                </div>
                                <h4 class="title">No speakers!</h4>
                                <p class="text">Once you add speaker, you'll see them here.</p>
                                <nv-button (click)="add()">Add speaker</nv-button>
                            </div>
                        </div>
                        <table cdkDropList (cdkDropListDropped)="drop($event)" class="nv-data-table break-lap">
                            <tr cdkDrag *ngFor="let author of authors">        
                                <td class="w-checker" (click)="toggleSelected(author)" style="width: 30px; vertical-align: middle;">
                                    <span class="nv-row-checker" [class.checked]="selectedIds.includes(author.id)" title="Select item"></span>
                                </td>
                                <td class="di-avatar nv-clickable" (click)="edit(author)">
                                    <span>
                                        <img *ngIf="author.image_url_thumb" [src]="author.image_url_thumb" alt>
                                    </span>
                                </td><!-- /.dt-cell -->
                                <td class="dt-cell nv-clickable" (click)="edit(author)">
                                    <label>NAME</label>
                                    <strong>{{ author.title }} {{ author.first_name }} {{ author.last_name }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-cell nv-clickable" (click)="edit(author)">
                                    <label>INSTITUTION</label>
                                    <strong>{{ author.institution }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-cell nv-clickable" (click)="edit(author)">
                                    <label>EMAIL</label>
                                    <strong>{{ author.email }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-cell nv-clickable" (click)="edit(author)">
                                    <label>ORDER</label>
                                    <strong>{{ author.order }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-actions">
                                    <div class="nv-cell-actions">
                                    <span class="nv-cell-action-trigger"
                                          [class.on]="optionsShown === author.id"
                                          (click)="openOptions(author)"></span>
                                        <nav nvOffClick (offClick)="closeOptions()">
                                            <ul>
                                                <li (click)="edit(author)">
                                                    <a>Edit</a>
                                                </li>
                                                <li class="delete" (click)="delete(author)">
                                                    <a>Delete</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div><!-- /.nv-cell-actions -->
                                </td>
                                <td class="dt-actions nv-clickable" (click)="editInNewTab(author)">
                                    <div class="nv-icon" data-icon="&#xe05a;"></div>
                                </td>
                            </tr>
                        </table>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->
</div><!-- /.config-layout-lower-fixed -->
