import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, of } from 'rxjs';
import { LeadlinkCampaign } from '../classes/leadlink-campaign';
import { map } from 'rxjs/operators';

@Injectable()
export class LeadlinkService {
  public currentLeadlinkCampaignSubject = new BehaviorSubject<LeadlinkCampaign>(null as LeadlinkCampaign);
  public currentLeadlinkCampaign = this.currentLeadlinkCampaignSubject.asObservable();

  constructor(
    private apiService: ApiService
  ) { }

  getConferenceCampaigns(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/leadlink/campaigns`, params);
  }

  getConferenceCampaign(conferenceId: number, leadlinkCampaignId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/leadlink/campaigns/${leadlinkCampaignId}`, params);
  }

  updateConferenceCampaign(conferenceId: number, leadlinkCampaignId: number, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/leadlink/campaigns/${leadlinkCampaignId}`, params);
  }

  createConferenceCampaign(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/leadlink/campaigns`, params);
  }

  deleteConferenceCampaign(conferenceId: number, leadlinkCampaignId: number, params: any = {}) {
    return this.apiService.delete(`conferences/${conferenceId}/leadlink/campaigns/${leadlinkCampaignId}`, params);
  }

  setCurrentLeadlinkCampaign(conferenceId: number, leadlinkCampaignId: number) {
    if (leadlinkCampaignId) {
      if (this.currentLeadlinkCampaignSubject.value && this.currentLeadlinkCampaignSubject.value.id === +leadlinkCampaignId) {
        return of(null);
      }

      return this.apiService.get(`conferences/${conferenceId}/leadlink/campaigns/${leadlinkCampaignId}`)
        .pipe(map(response => {
          this.currentLeadlinkCampaignSubject.next(response.data);
          return response.data;
        }));
    } else {
      this.currentLeadlinkCampaignSubject.next(null);
      return of(null);
    }
  }

}
