import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalButton } from '../classes/modal-button';
import { ModalErrors } from '../classes/modal-errors';

@Component({
  selector: 'nv-error',
  templateUrl: './error.component.html'
})

export class NavusErrorComponent implements OnInit {
  @Input() title: string = 'Error';
  @Input() buttons: ModalButton[];
  @Input() showTitle: boolean = true;
  @Input() size: string = 'small';

  @Input()
  set errors(errorList: ModalErrors) {
    if (errorList.error.message === 'Server Error') {
        this.errorMessage = '<p>The application has encountered an unknown error.</p>' +
          '<p>It doesn\'t appear to have affected your data, but our technical staff have been ' +
          'automatically notified and will be looking into this with the utmost urgency.</p>';
    } else {
      this.errorMessage = errorList.error.message;
    }
    this.formattedErrors = Object.keys(errorList.error.errors || {})
      .map(key => `${errorList.error.errors[key]}`);
  }
  errorMessage: string;
  formattedErrors: Array<string> = [];

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key && event.key.toLowerCase() === 'enter') {
      this.onClick(this.buttons[this.buttons.length - 1]);
      event.preventDefault();
      return false;
    }
  }

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    if (!this.buttons) {
      this.buttons = [
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ];
    }
  }

  close() {
    this.activeModal.dismiss();
  }

  onClick(button) {
    if (button.role === 'cancel') {
      this.activeModal.dismiss();
    }
    if (button.handler) {
      button.handler();
      this.activeModal.close();
    }
  }

}
