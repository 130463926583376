import { Routes } from '@angular/router';
import { OrganizationGuard } from '../../guards/organization.guard';
import { MeetingRoomListPageComponent } from './pages/meeting-room-list/meeting-room-list-page.component';
import { MeetingRoomDetailsPageComponent } from './pages/meeting-room-details/meeting-room-details-page.component';

export const MeetingRoomRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'meeting-rooms'
  },
  {
    path: 'meeting-rooms',
    component: MeetingRoomListPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Meeting Room List'
    }
  },
  {
    path: 'meeting-room/:meetingRoomId',
    component: MeetingRoomDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Meeting Room Details'
    }
  },
  {
    path: 'meeting-room',
    component: MeetingRoomDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Meeting Room Details'
    }
  },
];
