<ng-container *ngIf="user && !editMode">
  <div class="nv-profile-settings-body-gutter">
		<h3>Security</h3>
		<p>Strong and unique password helps protect your Account.</p>
		<p>
			<nv-button (click)="edit()">Change password</nv-button>
    </p>
  </div><!-- /.nv-profile-settings-body-gutter -->
</ng-container>

<ng-container *ngIf="editMode">
	<header class="nv-profile-settings-detail-header">
    <a class="nv-psd-back" (click)="cancel()"></a>
		<h2><span>Change your password</span></h2>
	</header><!-- /.nv-profile-settings-detail-header -->
	<div class="nv-profile-settings-detail-body-gutter">
		<h6 class="medium">Use a strong and unique password to protect your account.</h6>
		<p>Please use at least 6 characters.</p>
		<form class="nv-row spaced-bottom gap-24 propagate" [formGroup]="passwordForm">
			<div class="nv-column desk-12">
					<div class="nv-column-content">
						<nv-input
							[label]="'New password' | translate"
							formControlName="password"
						></nv-input>
					</div><!-- /.nv-column-content -->
			</div><!-- /.nv-column -->
			<div class="nv-column desk-12">
					<div class="nv-column-content">
						<nv-input
							[label]="'Confirm password' | translate"
							formControlName="confirm_password"
						></nv-input>
					</div><!-- /.nv-column-content -->
			</div><!-- /.nv-column -->
				<div class="nv-column desk-12">
						<div class="nv-column-content">
								<div class="nv-profile-settings-btn-box">
									<nv-button (click)="cancel()" color="passive">Close</nv-button>
									&nbsp;&nbsp;&nbsp;
									<nv-button (click)="save()" [disabled]="passwordForm.invalid">Save</nv-button>
								</div><!-- /.nv-profile-settings-btn-box -->
						</div><!-- /.nv-column-content -->
				</div><!-- /.nv-column -->
		</form><!-- /.nv-row -->
	</div><!-- /.nv-profile-settings-body-gutter -->
</ng-container>