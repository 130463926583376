import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Article } from '@navus/core/classes/article';
import { ArticleService } from '@navus/core/services/article.service';
import { LoadingService } from '@navus/ui/loading/loading.service';
import { ModalService } from '@navus/ui/modal/modal.service';

@Component({
  selector: 'main [nv-article-list-page]',
  templateUrl: './article-list-page.component.html',
})
export class ArticleListPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;
  organizationId: number;
  articles: Article[] = [];

  pagination: { page?: number, perPage: number, total?: number } = {
    perPage: 30
  };
  sort: { field: string, direction: string } = {
    field: 'title',
    direction: 'asc'
  };
  selectedIds: number[] = [];

  articlesLoading: boolean = false;
  optionsShown: number = null;

  formFilter: FormGroup;
  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private articleService: ArticleService,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      search_term: [''],
      status: [null],
      published_from: [null],
      published_to: [null],
    });
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.getArticles();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getArticles(append: boolean = false) {
    if (append && this.pagination.total && this.articles.length >= this.pagination.total) { return; }

    this.pagination.page = append ? this.pagination.page + 1 : 1;

    const params: any = {
      page: this.pagination.page,
      per_page: this.pagination.perPage,
      sort_by: this.sort.field,
      sort_direction: this.sort.direction,
    };
    if (this.formFilter.value.search_term !== '') {
      params.search_term = this.formFilter.value.search_term;
    }
    this.articlesLoading = !append;
    this.articleService.getOrganizationArticles(this.organizationId, params)
      .subscribe(
        (response: { data: Article[], meta: any }) => {
          if (append) {
            this.articles = this.articles.concat(response.data);
          } else {
            const port = document.getElementsByClassName('nv-config-content-port');
            if (port && port.length > 0) {
              port[0].scrollTop = 0;
            }
            
            this.articles = response.data;
            this.pagination.total = response.meta.pagination.total;
          }
          this.articlesLoading = false;
        },
        (error) => {
          this.articlesLoading = false;
        }
      );
  }

  edit(article: Article) {
    this.router.navigate(['/o', this.organizationId, 'article', article.id]);
  }

  close() {
    this.router.navigate(['/o', this.organizationId, 'landing']);
  }

  add() {
    this.router.navigate(['..', 'article'], { relativeTo: this.route });
  }

  delete(article: Article) {
    this.modalService.defaultModal({
      title: 'Article Deletion',
      body: 'Are you sure you want to delete this article?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.articleService.deleteOrganizationArticle(this.organizationId, article.id)
              .subscribe(
                () => {
                  this.getArticles();
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

  sortArticles(field: string) {
    if (field === this.sort.field) {
      this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sort.field = field;
      this.sort.direction = 'asc';
    }
    this.getArticles();
  }

  toggleSelected(article: Article) {
    const index = this.selectedIds.indexOf(article.id);
    if (index !== -1) {
      this.selectedIds.splice(index, 1);
    } else {
      this.selectedIds.push(article.id);
    }
  }

  selectAll() {
    this.selectedIds = [];
    for (const article of this.articles) {
      this.selectedIds.push(article.id);
    }
  }

  unselectAll() {
    this.selectedIds = [];
  }

  openOptions(article: Article) {
    event.stopPropagation();
    this.optionsShown = article.id;
  }

  closeOptions() {
    event.stopPropagation();
    this.optionsShown = null;
  }

}
