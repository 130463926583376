import { Routes } from '@angular/router';
import { OrganizationGuard } from '../../guards/organization.guard';
import { NotificationListPageComponent } from './pages/notification-list/notification-list-page.component';
import { NotificationDetailsPageComponent } from './pages/notification-details/notification-details-page.component';
import { QuestionListPageComponent } from './pages/question-list/question-list-page.component';
import { TemplateListPageComponent } from './pages/template-list/template-list-page.component';
import { TemplateDetailsPageComponent } from './pages/template-details/template-details-page.component';
import { NotificationSettingsPageComponent } from './pages/notification-settings/notification-settings-page.component';

export const CommunicationRoutes: Routes = [
  { path: '',
    pathMatch: 'full',
    redirectTo: 'templates' },
  {
    path: 'notifications',
    component: NotificationListPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Notifications'
    }
  },
  {
    path: 'notification',
    component: NotificationDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Notification'
    }
  },
  {
    path: 'notification/:notificationId',
    component: NotificationDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Notification'
    }
  },
  {
    path: 'notification-settings',
    component: NotificationSettingsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Notification'
    }
  },
  {
    path: 'templates',
    component: TemplateListPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Templates'
    }
  },
  {
    path: 'template',
    component: TemplateDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Template'
    }
  },
  {
    path: 'template/:templateId',
    component: TemplateDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Template'
    }
  },
  {
    path: 'questions',
    component: QuestionListPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Questions'
    }
  },
];
