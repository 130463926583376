import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Delegate } from '@navus/core/classes/delegate';

@Injectable()
export class PublicChatService {
  private delegateChat: BehaviorSubject<Delegate> = new BehaviorSubject<Delegate>(null);
  delegateChat$ = this.delegateChat.asObservable();

  private delegateChatStarted: BehaviorSubject<Delegate> = new BehaviorSubject<Delegate>(null);
  delegateChatStarted$ = this.delegateChatStarted.asObservable();

  startDelegateChat(delegate: Delegate): void {
    this.delegateChat.next(delegate);
  }

  delegateChatIsStarted(delegate: Delegate): void{
    this.delegateChatStarted.next(delegate);
  }
}

