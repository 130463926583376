import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class BannerService {

  constructor(
    private apiService: ApiService
  ) { }

  getBanner(params: any = {}) {
    return this.apiService.get(`banners/next`, params);
  }

}
