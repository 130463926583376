import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { NavusPublicChatComponent } from '@navus/ui/public-chat/public-chat.component';

@Component({
  selector: 'nv-public-chat-container',
  styleUrls: ['./public-chat-container.component.scss'],
  templateUrl: './public-chat-container.component.html'
})
export class NavusPublicChatContainerComponent implements AfterViewInit {
  chatMinimized = true;

  @Input() eventId;
  @Input() organizationId: number;
  @Input() allow1on1 = true;
  @Input() allowGroupChat = true;

  @ViewChild(NavusPublicChatComponent) public chatComponent: NavusPublicChatComponent;

  constructor() {
  }

  ngAfterViewInit(): void {
    this.chatComponent.setChatMinimized$
      .subscribe((minimized) => {
        if (minimized !== this.chatMinimized) {
          this.setChatMinimized(minimized);
        }
      });
  }

  setChatMinimized(minimized: boolean): void {
    this.chatMinimized = minimized;
  }
}
