export class PushNotification {
    id: number;
    title: string;
    body: string;
    schedule_time: string;
    type: 'text' | 'speakers' | 'sponsor' | 'presentation' | 'block' | 'programme' | 'news';
    entity_id: string;
    sent: string;
    scope: any[];
    receivers_number: number;

    constructor(values: Object = {}) {
        Object.assign(this, values);
        if (values['sent'] === true) {
            const receivers_number = this.receivers_number ? this.receivers_number : 0;
            this.sent = 'Sent to ' + receivers_number + ' devices';
        } else {
            this.sent = '';
        }
    }
}
