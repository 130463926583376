import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class PackageService {

  constructor(
    private apiService: ApiService
  ) { }

  getPackageByType(type: string, params: any = {}) {
    return this.apiService.get(`packages/${type}`, params);
  }

  getPackages(params: any = {}) {
    return this.apiService.get(`packages`, params);
  }

}
