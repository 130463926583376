<div class="nv-config-content-port">
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates large-spaced-top">
                <div class="nv-column desk-2 tab-none"></div>
                <div class="nv-column desk-8 tab-12">
                    <div class="nv-column-content">
                        <form class="nv-row config-plates clean gap-24 propagate" [formGroup]="eventMakerSupportForm">
                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <h1>Navus Team Support</h1>
                                    <p>
                                        Please describe what you need. For a better start we suggest a 30 min screen-share
                                        training teleconference with one of our team members.
                                    </p>
                                </div>
                            </div>
                            <div class="nv-column desk-6 phab-12" formGroupName="options">
                                <div class="nv-column-content">
                                    <nv-datepicker
                                            label="Suggested time"
                                            formControlName="time"
                                            required
                                    ></nv-datepicker>
                                </div>
                            </div>
                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <nv-text-editor
                                            label="Write a message"
                                            formControlName="message"
                                            [organizationId]="organizationId"
                                            required
                                    ></nv-text-editor>
                                </div>
                            </div>
                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <nv-button
                                            (click)="submitEventMakerSupportForm()"
                                            [disabled]="eventMakerSupportForm.invalid"
                                    >Request</nv-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="nv-column desk-2 tab-none"></div>
            </div>
        </div>
    </div>
</div>
