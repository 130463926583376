import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';

import {ApiService} from './api.service';
import {User} from '../models/user';
import {distinctUntilChanged, map} from 'rxjs/operators';

@Injectable()
export class UserService {
    public appInfo: any = {};
    public redirectUrl: string = null;
    public userRoles: any[];
    private currentUserSubject = new BehaviorSubject<User>({} as User);
    public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
    private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
    public isAuthenticated = this.isAuthenticatedSubject.asObservable();
    private userRoleSubject = new ReplaySubject<string>(1);
    public userRole = this.userRoleSubject.asObservable();

    private staffRoleDetailsSubject: BehaviorSubject<{ isStaff: boolean, numberOfOrganizations: number }> =
        new BehaviorSubject({
            isStaff: true,
            numberOfOrganizations: 1
        });
    public staffRoleDetails: Observable<{ isStaff: boolean, numberOfOrganizations: number }> = this.staffRoleDetailsSubject.asObservable();

    constructor(
        private apiService: ApiService
    ) {
    }

    populate() {
        if (localStorage.getItem('jwtToken')) {
            this.setUser().subscribe(data => {

            });
        } else {
            this.purgeAuth();
        }
    }

    setRole(resource_roles) {
        for (let i = 0; i < resource_roles.length; i++) {
            if (resource_roles[i].resource_type === 'user' &&
                resource_roles[i].role === 'admin') {
                this.userRoleSubject.next('superAdmin');
            } else if (this.appInfo.portal && resource_roles[i].resource_id === this.appInfo.portal.organization_id &&
                resource_roles[i].role === 'admin' &&
                resource_roles[i].resource_type === 'organization') {
                this.userRoleSubject.next('admin');
            } else if (this.appInfo.portal && resource_roles[i].resource_id === this.appInfo.portal.organization_id &&
                resource_roles[i].role === 'webcast-viewer') {
                this.userRoleSubject.next('webcast-viewer');
            } else if (this.appInfo.portal && resource_roles[i].resource_id === this.appInfo.portal.organization_id &&
                resource_roles[i].role === 'era-edta-member') {
                this.userRoleSubject.next('era-edta-member');
            } else if (this.appInfo.portal && resource_roles[i].resource_id === this.appInfo.portal.organization_id &&
                resource_roles[i].role === 'sponsor' &&
                resource_roles[i].resource_type === 'organization') {
                this.userRoleSubject.next('sponsor');
            }
        }
    }

    setUser() {
        return this.apiService.get('users/me', {})
            .pipe(map(
                data => {
                    this.currentUserSubject.next(new User(data.data));
                    this.isAuthenticatedSubject.next(true);

                    // if (Object.keys(appInfo).length) {
                    this.setRole(data.data.resource_roles);
                    // }
                    this.userRoles = data.data.resource_roles;
                    return data;
                }
            ));
    }

    purgeAuth(logout?: boolean) {
        localStorage.removeItem('jwtToken');
        this.currentUserSubject.next({} as User);
        this.userRoleSubject.next(null);
        this.isAuthenticatedSubject.next(false);
        this.appInfo = {};
    }

    update(user, value): Observable<User> {
        return this.apiService
            .put('users/' + user.id, value)
            .pipe(map(response => {
                this.currentUserSubject.next(new User(response.data));
                return new User(response.data);
            }));
    }

}
