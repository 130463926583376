<a class="tsp-close" (click)="close()" title="Close panel"></a>
<header>
    <h4>Page</h4>
</header>
<nav style="padding: 0 20px">
    <form [formGroup]="pageForm" class="nv-row gap-30 propagate">
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <nv-input
                        formControlName="title"
                        label="Title"
                        required
                ></nv-input>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <nv-input
                        formControlName="slug"
                        label="Slug"
                        required
                ></nv-input>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <nv-select
                        formControlName="parent_id"
                        label="Parent Page"
                        [options]="pages"
                ></nv-select>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-12" *ngIf="pageForm.value.type === 'external'">
            <div class="nv-column-content">
                <nv-input
                        formControlName="config"
                        label="URL"
                        required
                ></nv-input>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <nv-select
                        [options]="pageTypes"
                        formControlName="type"
                        label="Type"
                        required
                ></nv-select>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <nv-checkbox
                        formControlName="visible"
                        label="Visible"
                ></nv-checkbox>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-12" *ngIf="pageForm.value.type === 'page'">
            <div class="nv-column-content">
                <nv-checkbox
                        formControlName="show_footer"
                        label="Show footer"
                ></nv-checkbox>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <nv-button [loading]="savingPage" (click)="save()">Save</nv-button>
                &nbsp;
                <nv-button *ngIf="page?.id" [loading]="deletingPage" color="accent2" (click)="delete()">Delete</nv-button>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <nv-button [loading]="savingPage" [disabled]="page?.landing" (click)="setAsLanding()">Set as landing</nv-button>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
    </form>
</nav>
