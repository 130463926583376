<div class="voting" [ngClass]="status">
    <div class="left-part" *ngIf="!!(voting$ | async) && !loading" [@contentAnimation]>
        <div class="entity-title"><span>{{ (presentation$ | async)?.title  }}</span></div>
        <div class="voting-title">{{ (voting$ | async)?.title }}</div>
        <div class="results">
            <div class="result-row" *ngFor="let voting of getAnswers(voting$ | async)">
                <div class="option"
                     [ngClass]="{'correct': voting.correct && status === 'finished'}">{{ voting.choice }}</div>
                <div class="option-info">
                    <div class="title">{{ voting.title }}</div>
                    <div class="bar" *ngIf="status !== 'waiting'"><span
                            [style.width]="(voting.percentage || 0) + '%'"><span></span></span></div>
                </div>
                <div class="percentage" *ngIf="status !== 'waiting'">{{ (voting.percentage || 0) + '%' }}</div>
            </div>
        </div>
    </div>
    <div class="left-part placeholder" *ngIf="loading" [@placeholderAnimation]>
        <div class="entity-title"><span class="placeholder"></span></div>
        <div class="voting-title first placeholder"></div>
        <div class="voting-title second placeholder"></div>
        <div class="results">
            <div class="result-row">
                <div class="option placeholder"></div>
                <div class="option-info">
                    <div class="title placeholder"></div>
                    <div class="bar"><span class="placeholder"></span></div>
                </div>
                <div class="percentage"></div>
            </div>
            <div class="result-row">
                <div class="option placeholder"></div>
                <div class="option-info">
                    <div class="title placeholder"></div>
                    <div class="bar"><span class="placeholder"></span></div>
                </div>
                <div class="percentage"></div>
            </div>
            <div class="result-row">
                <div class="option placeholder"></div>
                <div class="option-info">
                    <div class="title placeholder"></div>
                    <div class="bar"><span class="placeholder"></span></div>
                </div>
                <div class="percentage"></div>
            </div>
        </div>
    </div>
    <div class="right-part">
        <img src="assets/voting_demo.gif">
    </div>
    <div class="error-msg" *ngIf="errorMsg" [@errorAnimation]>
        <div class="text"><i class="fa fa-exclamation-triangle"></i> {{ errorMsg }}</div>
    </div>
</div>
