<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-10">
                <nv-button (click)="delete()" color="secondary" *ngIf="meetingRoomId" translate>Delete</nv-button>
                <nv-button (click)="close()" color="passive" translate>Close</nv-button>
                <nv-button (click)="save()" [loading]="savingMeetingRoom" [disabled]="meetingRoomForm.invalid" translate>Save</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
</div>
<div class="nv-config-content-port">
    <div class="nv-global-loading on" [class.on]="loadingMeetingRoom">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24 small-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form [formGroup]="meetingRoomForm" class="nv-row config-plates clean gap-24 small-spaced-top spaced-bottom">
                            <div class="nv-column desk-6 lap-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="title"
                                                        label="Title"
                                                ></nv-input>
                                            </div>
                                        </div>
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="max_participants"
                                                        label="Max participants"
                                                        type="number"
                                                ></nv-input>
                                            </div>
                                        </div>
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        formControlName="entity_type"
                                                        label="Type"
                                                        [options]="entityTypeOptions"
                                                ></nv-select>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="meetingRoomForm.value.entity_type === 'Location'">
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <nv-select
                                                            formControlName="entity_id"
                                                            label="Location"
                                                            [options]="locations"
                                                            (loadMore)="getLocations(true)"
                                                    ></nv-select>
                                                </div>
                                            </div>
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <nv-select
                                                            formControlName="type"
                                                            label="Internal/External"
                                                            [options]="typeOptions"
                                                    ></nv-select>
                                                </div>
                                            </div>
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <nv-input
                                                            formControlName="rtmp_url"
                                                            label="RTMP URL"
                                                            [readOnly]="meetingRoomForm.value.type === 'internal'"
                                                    ></nv-input>
                                                </div>
                                            </div>
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <nv-input
                                                            formControlName="rtmp_key"
                                                            label="RTMP Key"
                                                            type="password"
                                                            [showPassword]="true"
                                                            [readOnly]="meetingRoomForm.value.type === 'internal'"
                                                    ></nv-input>
                                                </div>
                                            </div>
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <ng-container *ngFor="let url of meetingRoom?.playback_urls">
                                                        <nv-input
                                                                label="Playback URL"
                                                                [value]="url.url"
                                                                [readOnly]="true"
                                                        ></nv-input>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="meetingRoomForm.value.type === 'internal' && meetingRoomId">
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <nv-button (click)="createRtmp()" [loading]="creatingMeetingRoomLivestream">Create RTMP</nv-button>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="meetingRoomForm.value.entity_type === 'Block'">
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <nv-select
                                                            formControlName="entity_id"
                                                            label="Session"
                                                            [options]="blocks"
                                                            (loadMore)="getBlocks(true)"
                                                    ></nv-select>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-6 lap-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-12" *ngIf="meetingRoom">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        [label]="'Room URL'"
                                                        [value]="'https://meet.navus.io/' + meetingRoom.room_name"
                                                        [readOnly]="true"
                                                ></nv-input>
                                            </div>
                                        </div>
                                        <div class="nv-column desk-12" *ngIf="meetingRoom">
                                            <div class="nv-column-content">
                                                <nv-meeting
                                                        [conferenceId]="conferenceId"
                                                        [currentUser]="currentUser"
                                                        [entityId]="meetingRoom.entity_id"
                                                        [entityType]="meetingRoom.entity_type"
                                                ></nv-meeting>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</div>
