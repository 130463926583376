import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PresentationService } from '@navus/core/services/presentation.service';
import { NavusInputComponent } from '@navus/ui/input/input.component';
import { ConferenceService } from '@navus/core/services/conference.service';

@Component({
  selector: 'nv-question-modal',
  templateUrl: './question-modal.component.html'
})
export class QuestionModalComponent implements OnInit, AfterViewInit {
  @ViewChild('questionInput', { static: true }) questionInput: NavusInputComponent;
  @Input() conferenceId: number;
  @Input() blockId: number;
  messageForm: FormGroup;
  sendingMessage: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private conferenceService: ConferenceService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  ngAfterViewInit() {
    this.questionInput.focus();
  }

  initForm() {
    this.messageForm = this.formBuilder.group({
      content: ['', Validators.required]
    });
  }

  send() {
    this.sendingMessage = true;
    if (this.messageForm.value && this.blockId) {
        this.messageForm.value.block_id=this.blockId;
    }
    this.conferenceService
      .createConferenceMessage(this.conferenceId, this.messageForm.value)
      .subscribe(
        () => {
          this.activeModal.close();
          this.sendingMessage = false;
        },
        () => {
          this.sendingMessage = false;
        }
      );
  }

  close() {
    this.activeModal.dismiss();
  }

}
