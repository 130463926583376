import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ConferenceService } from '@navus/core/services/conference.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '@navus/ui/modal/modal.service';
import { Color } from '@swimlane/ngx-charts';
import { TimeFormatPipe } from '@navus/ui/pipes/time-format.pipe';
import * as moment from 'moment';
import { Conference } from '@navus/core/classes/conference';
import { SessionData } from '../models/sessionData';

@Component({
  selector: 'main [nv-conference-dashboard-live]',
  templateUrl: './conference-dashboard-live.component.html',
})
export class ConferenceDashboardLiveComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  conference: Conference;

  chartColorScheme = {
    domain: ['#318AF1', '#2873A1', '#8ABAD6', '#32B799', '#279079', '#18707F', '#ED4649', '#F49092', '#AD465A', '#ED8747']
  } as Color;

  loadingWatchingLive: boolean;
  watchingLiveNumbers: { time_spent: number, total_participants: number, total_participants_per_day: LiveWatchingDay[] };
  watchingLiveChartData: { name: string, value: number }[] = [];

  totalParticipantsPerHourChartData: { name: string, series: { name: Date, value: number }[] }[] = [];
  totalParticipantsPerHourYMax = 0;
  totalParticipantsPerHourLegend: { name: string, value: string, color: string }[];

  loadingWatchingSessions: boolean;
  watchingSessionData: SessionData[];

  sessionParticipantsChartData: { name: string, value: number }[];
  presentationParticipantsChartData: { name: string, value: number }[];
  sessionParticipantsLegend: { name: string, value: string, color: string }[];
  sessionParticipantsActiveEntries = [];
  presentationParticipantsLegend: { name: string, value: string, color: string }[];
  presentationParticipantsActiveEntries = [];

  sessionTimeSpentChartData: { name: string, value: number }[];
  presentationTimeSpentChartData: { name: string, value: number }[];
  sessionTimeSpentLegend: { name: string, value: string, color: string }[];
  sessionTimeSpentActiveEntries = [];
  presentationTimeSpentLegend: { name: string, value: string, color: string }[];
  presentationTimeSpentActiveEntries = [];

  selectedSessionParticipants: SessionData;
  selectedSessionTimeSpent: SessionData;
  lastRecordedDateTime: string;

  private subscriptions: Subscription[] = [];

  constructor(private conferenceService: ConferenceService,
              private timeFormatPipe: TimeFormatPipe,
              private modalService: ModalService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;

        this.conferenceService.getConference(this.conferenceId)
          .subscribe((response) => {
            this.conference = response.data;

            this.getWatchingLive();
            this.getWatchingSessions();
          }, (error) => {
            this.modalService.error({ errors: error });
          });
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  formatNumber = (value: number): any => this.transformToTime(value);
  formatName = (value: string): any => this.transformToDate(value);

  transformToTime(value: number): string {
    return this.timeFormatPipe.transform(value);
  }

  transformToDate(value: string): string {
    let d = new Date(value);
    return moment(d).format('ddd Do MMM');
  }

  getTooltipText(date: string, value: number): string {
    const d = new Date(date);
    return moment(d).format('LT') + ': ' + value;
  }

  onSelectSessionParticipants(event) {
    const chosenSession = typeof event === 'string' ? event : event.name;
    if (chosenSession) {
      this.selectedSessionParticipants = this.watchingSessionData
        .find(item => item.name === chosenSession);
      this.presentationParticipantsChartData = this.selectedSessionParticipants
        .presentations?.map(item => ({ name: item.title, value: item.total_participants_live }));
      this.presentationParticipantsLegend = this.presentationParticipantsChartData
        .map((presentation, index) => ({
          name: presentation.name,
          value: presentation.value.toString(),
          color: this.chartColorScheme.domain[index % 10]
        }));
    }
  }

  onSelectSessionTimeSpent(event) {
    const chosenSession = typeof event === 'string' ? event : event.name;
    if (chosenSession) {
      this.selectedSessionTimeSpent = this.watchingSessionData
        .find(item => item.name === chosenSession);
      this.presentationTimeSpentChartData = this.selectedSessionTimeSpent
        .presentations?.map(item => ({ name: item.title, value: item.time_spent_live }));
      this.presentationTimeSpentLegend = this.presentationTimeSpentChartData
        .map((presentation, index) => ({
          name: presentation.name,
          value: this.timeFormatPipe.transform(presentation.value),
          color: this.chartColorScheme.domain[index % 10]
        }));
    }
  }

  sessionParticipantsLegendLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.sessionParticipantsActiveEntries = this.sessionParticipantsChartData.filter(item => item.name === name);
  }

  sessionParticipantsLegendLabelDeactivate(item: any): void {
    this.sessionParticipantsActiveEntries = [];
  }

  sessionTimeSpentLegendLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.sessionTimeSpentActiveEntries = this.sessionTimeSpentChartData.filter(item => item.name === name);
  }

  sessionTimeSpentLegendLabelDeactivate(item: any): void {
    this.sessionTimeSpentActiveEntries = [];
  }

  presentationParticipantsLegendLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.presentationParticipantsActiveEntries = this.presentationParticipantsChartData.filter(item => item.name === name);
  }

  presentationParticipantsLegendLabelDeactivate(item: any): void {
    this.presentationParticipantsActiveEntries = [];
  }

  presentationTimeSpentLegendLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.presentationTimeSpentActiveEntries = this.presentationTimeSpentChartData.filter(item => item.name === name);
  }

  presentationTimeSpentLegendLabelDeactivate(item: any): void {
    this.presentationTimeSpentActiveEntries = [];
  }

  private getWatchingLive(): void {
    this.loadingWatchingLive = true;
    this.conferenceService.getConferenceWatchingLive(this.conferenceId, {
      from_date: this.conference.starts_at,
      to_date: this.conference.ends_at
    })
      .subscribe((response) => {
        this.loadingWatchingLive = false;
        this.watchingLiveNumbers = response.data;

        if (this.watchingLiveNumbers?.total_participants_per_day?.length) {
          const watchingLiveChartData = this.watchingLiveNumbers
            .total_participants_per_day
            .map((item) => ({ name: item.day, value: item.time_spent }));
          this.watchingLiveChartData = watchingLiveChartData;

          this.generateTotalParticipantsData();
        }
      }, (error) => {
        this.loadingWatchingLive = false;
        this.modalService.error({ errors: error });
      });
  }

  private generateTotalParticipantsData(): void {
    const chartData: { name: string, series: { name: Date, value: number }[] }[] = [{ name: 'Total participants', series: [] }];
    const legend = [];
    this.watchingLiveNumbers?.total_participants_per_day?.forEach((date) => {
      Object.keys(date.hours).forEach((hour) => {
        chartData[0].series.push(
          {
            name: new Date(new Date(date.day).setHours(parseInt(hour))),
            value: date.hours[hour],
          }
        );
      });
      legend.push({
        name: this.transformToDate(date.day),
        value: date.total_participants,
      });
    });
    this.totalParticipantsPerHourYMax = Math.max(...chartData[0].series.map(i => i.value));

    this.totalParticipantsPerHourChartData = chartData;
    this.totalParticipantsPerHourLegend = legend;
  }

  private getWatchingSessions(): void {
    this.loadingWatchingSessions = true;
    this.conferenceService.getConferenceWatchingSessions(this.conferenceId, {
      from_date: this.conference.starts_at,
      to_date: this.conference.ends_at
    })
      .subscribe((response) => {
        this.loadingWatchingSessions = false;
        this.watchingSessionData = response.data;

        if (response.last_recorded_date_time) {
          this.lastRecordedDateTime = response.last_recorded_date_time;
        }

        this.populateStackedCharts();
      }, (error) => {
        this.loadingWatchingSessions = false;
        this.modalService.error({ errors: error });
      });
  }

  private populateStackedCharts(): void {
    this.sessionParticipantsChartData = this.watchingSessionData
      .filter(item => item.total_participants_live)
      .map(session => ({
        name: session.name,
        value: session.total_participants_live
      }));
    this.sessionParticipantsLegend = this.watchingSessionData
      .filter(item => item.total_participants_live)
      .map((session, index) => ({
        name: session.name,
        value: session.total_participants_live.toString(),
        color: this.chartColorScheme.domain[index % 10]
      }));
    this.sessionTimeSpentChartData = this.watchingSessionData
      .filter(item => item.time_spent_live)
      .map(session => ({
        name: session.name,
        value: session.time_spent_live,
      }));
    this.sessionTimeSpentLegend = this.watchingSessionData
      .filter(item => item.time_spent_live)
      .map((session, index) => ({
        name: session.name,
        value: this.timeFormatPipe.transform(session.time_spent_live),
        color: this.chartColorScheme.domain[index % 10]
      }));
  }
}

interface LiveWatchingDay {
  day: string;
  hours: { [hour: number]: number };
  time_spent: number;
  total_participants: number;
}
