import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nv-speaker-recordings',
  templateUrl: 'speaker-recordings.component.html'
})

export class SpeakerRecordingsComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}