import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@navus/core/services/user.service';
import { User } from '@navus/core/classes/user';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ConferenceService } from '@navus/core/services/conference.service';
import { WebsiteService } from '@navus/core/services/website.service';
import { Website } from '@navus/core/classes/website';
import { LoadingService } from '@navus/ui/loading/loading.service';
import { PresentationService } from '@navus/core/services/presentation.service';
import { Presentation } from '@navus/core/classes/presentation';
import { LocationService } from '@navus/core/services/location.service';
import { Location } from '@navus/core/classes/location';

@Component({
  selector: 'nv-my-studio-page',
  templateUrl: './my-studio-page.component.html'
})
export class MyStudioPageComponent implements OnInit {
  conferenceId: number;
  selectedLocation: Location;
  presentation: Presentation;
  website: Website;

  canJoin: boolean = true;
  currentUser: User;
  currentBlockId: number;
  myPresentations: Presentation[] = [];
  presentingLocations: Location[] = [];
  // questions

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private presentationService: PresentationService,
    private websiteService: WebsiteService,
    private conferenceService: ConferenceService,
    private userService: UserService,
    private loadingService: LoadingService,
    private locationService: LocationService,
    private zone: NgZone,
    private router: Router,
  ) { }

  ngOnInit() {
    this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          if (website.active_conference_id) {
            this.conferenceId = website.active_conference_id;
            this.getPresentations();
          }
        },
        () => { }
      );
    this.userService.currentUser
      .subscribe(
        (user: User) => {
          this.currentUser = user;
        },
        () => { }
      );

  }

  getPresentations() {
    const params: any = {
      include: 'block',
      with_pagination: 0
    };
    this.presentationService
      .getMyPresentations(this.conferenceId, params)
      .subscribe(
        (response) => {
          this.myPresentations = response.data;
          this.getPresentingLocations();
        },
        () => {

        }
      );
  }

  getPresentingLocations() {
      const locations: any = [];
      for (const presentation of this.myPresentations) {
        if (presentation.location) {
            if(locations.indexOf(presentation.location) === -1) {
                locations.push(presentation.location);
            }
        } else if (presentation.block && presentation.block.location) {
            if(locations.indexOf(presentation.block.location) === -1) {
                locations.push(presentation.block.location);
            }
        }
      }
      locations.forEach((item, index) => {
        if (this.presentingLocations.findIndex(i => i.id === item.id) === -1) {
          this.presentingLocations.push(item);
        }
      });
      if (this.presentingLocations.length === 1) {
        this.enterStudio(this.presentingLocations[0]);
      }
  }

  enterStudio(location: Location) {
    this.router.navigate(['/my/studio', location.id]);
  }

}
