import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '@navus/ui/modal/modal.service';
import { MaterialService } from '@navus/core/services/material.service';

@Component({
  templateUrl: './external-material.component.html'
})
export class ExternalMaterialComponent implements OnInit {
  @Input() conferenceId: number = null;

  externalUrlForm: FormGroup;

  savingExternalUrl: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private materialService: MaterialService,
    private modalService: ModalService
  ) {
  }

  ngOnInit() {
    this.initForm();

  }

  initForm() {
    this.externalUrlForm = this.formBuilder.group({
      title: ['', Validators.required],
      external_webcast: ['', Validators.required],
    });
  }

  save() {
    if (this.externalUrlForm.invalid) {
      return;
    }

    this.savingExternalUrl = true;
    this.materialService.addExternalMaterial(this.conferenceId, this.externalUrlForm.value)
      .subscribe(
        () => {
          this.activeModal.close();
          this.savingExternalUrl = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.savingExternalUrl = false;
        }
      );
  }

  close() {
    this.activeModal.dismiss();
  }

}
