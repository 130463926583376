import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from '@navus/ui/loading/loading.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ConferenceService } from '@navus/core/services/conference.service';
import { ApplicationService } from '@navus/core/services/application.service';
import { Application } from '@navus/core/classes/application';
import { Conference } from '@navus/core/classes/conference';
import { ViewVideoModalComponent } from '../../modals/view-video/view-video-modal.component';

@Component({
  selector: 'main [nv-conference-application-page]',
  templateUrl: './conference-application-page.component.html'
})
export class ConferenceApplicationPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  applicationId: number;

  conference: Conference;
  application: Application;
  applicationForm: FormGroup;
  configurationForm: FormGroup;
  step1done = false;
  step2done = false;
  step3done = false;
  step4done = false;
  showAppFeatures: boolean = false;

  subscriptions: Subscription[] = [];

  applicationLoading: boolean = false;
  loadingPublish: boolean = false;
  savingApplication: boolean = false;
  editActive: boolean = false;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private applicationService: ApplicationService,
    private toastService: ToastService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    const subscription = this.route.params.subscribe(
      (data) => {
        this.initForm();

        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;

        this.getApplication();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.applicationForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      type: ['navus', [Validators.required]],

      logo: [''],
      description: [''],

      color_primary: ['', [Validators.required]],
      color_primary_dark: ['', [Validators.required]]
    });

    this.configurationForm = this.formBuilder.group({
      logo: false,
      interactive: false,
        program: false,
        speakers: false,
        ematerials: false,
        voting: false,
        timeline: false,
        eposters: false,
        notes: false,
      news: false,
      recommendations: false
    });
  }

  getApplication() {
    this.applicationLoading = true;
    this.applicationService
      .getConferenceApplication(this.organizationId, this.conferenceId, { include: 'pages' })
      .subscribe(
        (response: { data: Application }) => {
          this.application = response.data;
          this.applicationForm.patchValue(this.application);
          this.applicationId = this.application.id;
          this.applicationLoading = false;

          if (this.application.pages) {
            const homePage = this.application.pages
              .find(page => page.type === 'home');

            if (homePage) {
              const logoSection = homePage.sections
                .find(section => section.type === 'logo');
              this.configurationForm.patchValue({ logo: !!logoSection });

              const interactiveSection = homePage.sections
                .find(section => section.type === 'interactive');
              this.configurationForm.patchValue({ interactive: !!interactiveSection });

              if (interactiveSection && interactiveSection.properties && interactiveSection.properties.items) {
                const programSubSection = interactiveSection.properties.items
                  .find(section => section.item === 'program');
                this.configurationForm.patchValue({ program: !!programSubSection });

                const speakersSubSection = interactiveSection.properties.items
                  .find(section => section.item === 'speakers');
                this.configurationForm.patchValue({ speakers: !!speakersSubSection });

                const ematerialsSubSection = interactiveSection.properties.items
                  .find(section => section.item === 'e_materials');
                this.configurationForm.patchValue({ ematerials: !!ematerialsSubSection });

                const votingSubSection = interactiveSection.properties.items
                  .find(section => section.item === 'voting');
                this.configurationForm.patchValue({ voting: !!votingSubSection });

                const timelineSubSection = interactiveSection.properties.items
                  .find(section => section.item === 'timeline');
                this.configurationForm.patchValue({ timeline: !!timelineSubSection });

                const epostersSubSection = interactiveSection.properties.items
                  .find(section => section.item === 'e_posters');
                this.configurationForm.patchValue({ eposters: !!epostersSubSection });

                const notesSubSection = interactiveSection.properties.items
                  .find(section => section.item === 'notes');
                this.configurationForm.patchValue({ notes: !!notesSubSection });
              }

              const newsSection = homePage.sections
                .find(section => section.type === 'news');
              this.configurationForm.patchValue({ news: !!newsSection });

              const recommendationsSection = homePage.sections
                .find(section => section.type === 'recommendations');
              this.configurationForm.patchValue({ recommendations: !!recommendationsSection });
            }
          }
        },
        () => {
          this.application = new Application();
          this.applicationLoading = false;
        }
      );
  }

  save() {
    if (this.applicationForm.invalid) { return; }

    this.savingApplication = true;
    if (this.applicationId) {
      this.applicationService
        .updateConferenceApplication(this.organizationId, this.conferenceId, this.applicationId, this.applicationForm.value)
        .subscribe(
          (response) => {
            this.toastService.success('Application successfully saved.');
            this.savingApplication = false;

            this.applicationService
              .getConferenceApplication(this.organizationId, this.conferenceId, {include: 'pages'})
              .subscribe(
                (response1: { data: Application }) => {
                  this.application = response1.data;

                  const homePage = this.application.pages
                    .find(page => page.type === 'home');

                  if (homePage) {
                    // Logo

                    const logoSection = homePage.sections
                      .find(section => section.type === 'logo');

                    if (!logoSection && this.configurationForm.value.logo) {
                      this.applicationService
                        .createOrganizationApplicationPageSection(this.organizationId, this.applicationId, homePage.id,
                          {order: 1, type: 'logo'})
                        .subscribe();
                    }
                    if (logoSection && !this.configurationForm.value.logo) {
                      this.applicationService
                        .deleteOrganizationApplicationPageSection(this.organizationId, this.applicationId, homePage.id,
                          logoSection.id)
                        .subscribe();
                    }

                    // Interactive

                    const interactiveSection = homePage.sections
                      .find(section => section.type === 'interactive');

                    if (
                        !interactiveSection && (
                        this.configurationForm.value.program || this.configurationForm.value.speakers ||
                        this.configurationForm.value.ematerials || this.configurationForm.value.voting ||
                        this.configurationForm.value.timeline || this.configurationForm.value.eposters ||
                        this.configurationForm.value.notes
                      )) {
                      this.applicationService
                        .createOrganizationApplicationPageSection(this.organizationId, this.applicationId, homePage.id,
                          {order: 2, type: 'interactive', items: []})
                        .subscribe(
                          (response2) => {

                          },
                          () => { }
                        );
                    }

                    if (interactiveSection) {
                      if (!(
                          this.configurationForm.value.program || this.configurationForm.value.speakers ||
                          this.configurationForm.value.ematerials || this.configurationForm.value.voting ||
                          this.configurationForm.value.timeline || this.configurationForm.value.eposters ||
                          this.configurationForm.value.notes
                        )) {
                        this.applicationService
                          .deleteOrganizationApplicationPageSection(this.organizationId, this.applicationId, homePage.id,
                            interactiveSection.id)
                          .subscribe();
                      } else {
                        const programSubSection = interactiveSection.properties.items
                          .find(section => section.item === 'program');

                        if (!programSubSection && this.configurationForm.value.program) {
                          interactiveSection.properties.items.push({ item: 'program', title: 'Program' });
                        }
                        if (programSubSection && !this.configurationForm.value.program) {
                          interactiveSection.properties.items.splice(interactiveSection.properties.items.indexOf(programSubSection), 1);
                        }

                        const speakersSubSection = interactiveSection.properties.items
                          .find(section => section.item === 'speakers');

                        if (!speakersSubSection && this.configurationForm.value.speakers) {
                          interactiveSection.properties.items.push({ item: 'speakers', title: 'Speakers' });
                        }
                        if (speakersSubSection && !this.configurationForm.value.speakers) {
                          interactiveSection.properties.items.splice(interactiveSection.properties.items.indexOf(speakersSubSection), 1);
                        }

                        const ematerialsSubSection = interactiveSection.properties.items
                          .find(section => section.item === 'e_materials');

                        if (!ematerialsSubSection && this.configurationForm.value.ematerials) {
                          interactiveSection.properties.items.push({ item: 'e_materials', title: 'E-Materials' });
                        }
                        if (ematerialsSubSection && !this.configurationForm.value.ematerials) {
                          interactiveSection.properties.items.splice(interactiveSection.properties.items.indexOf(ematerialsSubSection), 1);
                        }

                        const votingSubSection = interactiveSection.properties.items
                          .find(section => section.item === 'voting');

                        if (!votingSubSection && this.configurationForm.value.voting) {
                          interactiveSection.properties.items.push({ item: 'voting', title: 'Voting' });
                        }
                        if (votingSubSection && !this.configurationForm.value.voting) {
                          interactiveSection.properties.items.splice(interactiveSection.properties.items.indexOf(votingSubSection), 1);
                        }

                        const timelineSubSection = interactiveSection.properties.items
                          .find(section => section.item === 'timeline');

                        if (!timelineSubSection && this.configurationForm.value.timeline) {
                          interactiveSection.properties.items.push({ item: 'timeline', title: 'Timeline' });
                        }
                        if (timelineSubSection && !this.configurationForm.value.timeline) {
                          interactiveSection.properties.items.splice(interactiveSection.properties.items.indexOf(timelineSubSection), 1);
                        }

                        const epostersSubSection = interactiveSection.properties.items
                          .find(section => section.item === 'e_posters');

                        if (!epostersSubSection && this.configurationForm.value.eposters) {
                          interactiveSection.properties.items.push({ item: 'e_posters', title: 'E-Posters' });
                        }
                        if (epostersSubSection && !this.configurationForm.value.eposters) {
                          interactiveSection.properties.items.splice(interactiveSection.properties.items.indexOf(epostersSubSection), 1);
                        }

                        const notesSubSection = interactiveSection.properties.items
                          .find(section => section.item === 'notes');

                        if (!notesSubSection && this.configurationForm.value.notes) {
                          interactiveSection.properties.items.push({ item: 'notes', title: 'My Notes' });
                        }
                        if (notesSubSection && !this.configurationForm.value.notes) {
                          interactiveSection.properties.items.splice(interactiveSection.properties.items.indexOf(notesSubSection), 1);
                        }

                        this.applicationService
                          .updateOrganizationApplicationPageSection(this.organizationId, this.applicationId, homePage.id,
                            interactiveSection.id, interactiveSection)
                          .subscribe();
                      }
                    }

                    // News

                    const newsSection = homePage.sections
                      .find(section => section.type === 'news');

                    if (!newsSection && this.configurationForm.value.news) {
                      this.applicationService
                        .createOrganizationApplicationPageSection(this.organizationId, this.applicationId, homePage.id,
                          {order: 3, type: 'news', properties: { title: 'News', limit: 10 }})
                        .subscribe();
                    }
                    if (newsSection && !this.configurationForm.value.news) {
                      this.applicationService
                        .deleteOrganizationApplicationPageSection(this.organizationId, this.applicationId, homePage.id,
                          newsSection.id)
                        .subscribe();
                    }

                    // Recommendations

                    const recommendationsSection = homePage.sections
                      .find(section => section.type === 'recommendations');

                    if (!recommendationsSection && this.configurationForm.value.recommendations) {
                      this.applicationService
                        .createOrganizationApplicationPageSection(this.organizationId, this.applicationId, homePage.id,
                          {order: 4, type: 'recommendations', properties: { title: 'Recommended for you', limit: 3 }})
                        .subscribe();
                    }
                    if (recommendationsSection && !this.configurationForm.value.recommendations) {
                      this.applicationService
                        .deleteOrganizationApplicationPageSection(this.organizationId, this.applicationId, homePage.id,
                          recommendationsSection.id)
                        .subscribe();
                    }
                  }
                });
          },
          (error) => {
            this.savingApplication = false;
            this.modalService.error({ errors: error });
          }
        );
    } else {
      this.applicationService
        .createConferenceApplication(this.organizationId, this.conferenceId, this.applicationForm.value)
        .subscribe(
          (response) => {
            this.application = response['data'];
            this.toastService.success('Application successfully saved.');
            this.applicationId = this.application.id;
            this.savingApplication = false;

            this.applicationService
              .getConferenceApplication(this.organizationId, this.conferenceId, {include: 'pages'})
              .subscribe(
                (response1: { data: Application }) => {
                  this.application = response1.data;

                  const homePage = this.application.pages
                    .find(page => page.type === 'home');

                  if (homePage) {
                    // Logo

                    const logoSection = homePage.sections
                      .find(section => section.type === 'logo');

                    if (!logoSection && this.configurationForm.value.logo) {
                      this.applicationService
                        .createOrganizationApplicationPageSection(this.organizationId, this.applicationId, homePage.id,
                          {order: 1, type: 'logo'})
                        .subscribe();
                    }
                    if (logoSection && !this.configurationForm.value.logo) {
                      this.applicationService
                        .deleteOrganizationApplicationPageSection(this.organizationId, this.applicationId, homePage.id,
                          logoSection.id)
                        .subscribe();
                    }

                    // Interactive

                    const interactiveSection = homePage.sections
                      .find(section => section.type === 'interactive');

                    if (
                      !interactiveSection && (
                        this.configurationForm.value.program || this.configurationForm.value.speakers ||
                        this.configurationForm.value.ematerials || this.configurationForm.value.voting ||
                        this.configurationForm.value.timeline || this.configurationForm.value.eposters ||
                        this.configurationForm.value.notes
                      )) {
                      this.applicationService
                        .createOrganizationApplicationPageSection(this.organizationId, this.applicationId, homePage.id,
                          {order: 2, type: 'interactive', items: []})
                        .subscribe(
                          (response2) => {

                          },
                          () => { }
                        );
                    }

                    if (interactiveSection) {
                      if (!(
                        this.configurationForm.value.program || this.configurationForm.value.speakers ||
                        this.configurationForm.value.ematerials || this.configurationForm.value.voting ||
                        this.configurationForm.value.timeline || this.configurationForm.value.eposters ||
                        this.configurationForm.value.notes
                      )) {
                        this.applicationService
                          .deleteOrganizationApplicationPageSection(this.organizationId, this.applicationId, homePage.id,
                            interactiveSection.id)
                          .subscribe();
                      } else {
                        const programSubSection = interactiveSection.properties.items
                          .find(section => section.item === 'program');

                        if (!programSubSection && this.configurationForm.value.program) {
                          interactiveSection.properties.items.push({ item: 'program', title: 'Program' });
                        }
                        if (programSubSection && !this.configurationForm.value.program) {
                          interactiveSection.properties.items.splice(interactiveSection.properties.items.indexOf(programSubSection), 1);
                        }

                        const speakersSubSection = interactiveSection.properties.items
                          .find(section => section.item === 'speakers');

                        if (!speakersSubSection && this.configurationForm.value.speakers) {
                          interactiveSection.properties.items.push({ item: 'speakers', title: 'Speakers' });
                        }
                        if (speakersSubSection && !this.configurationForm.value.speakers) {
                          interactiveSection.properties.items.splice(interactiveSection.properties.items.indexOf(speakersSubSection), 1);
                        }

                        const ematerialsSubSection = interactiveSection.properties.items
                          .find(section => section.item === 'e_materials');

                        if (!ematerialsSubSection && this.configurationForm.value.ematerials) {
                          interactiveSection.properties.items.push({ item: 'e_materials', title: 'E-Materials' });
                        }
                        if (ematerialsSubSection && !this.configurationForm.value.ematerials) {
                          interactiveSection.properties.items.splice(interactiveSection.properties.items.indexOf(ematerialsSubSection), 1);
                        }

                        const votingSubSection = interactiveSection.properties.items
                          .find(section => section.item === 'voting');

                        if (!votingSubSection && this.configurationForm.value.voting) {
                          interactiveSection.properties.items.push({ item: 'voting', title: 'Voting' });
                        }
                        if (votingSubSection && !this.configurationForm.value.voting) {
                          interactiveSection.properties.items.splice(interactiveSection.properties.items.indexOf(votingSubSection), 1);
                        }

                        const timelineSubSection = interactiveSection.properties.items
                          .find(section => section.item === 'timeline');

                        if (!timelineSubSection && this.configurationForm.value.timeline) {
                          interactiveSection.properties.items.push({ item: 'timeline', title: 'Timeline' });
                        }
                        if (timelineSubSection && !this.configurationForm.value.timeline) {
                          interactiveSection.properties.items.splice(interactiveSection.properties.items.indexOf(timelineSubSection), 1);
                        }

                        const epostersSubSection = interactiveSection.properties.items
                          .find(section => section.item === 'e_posters');

                        if (!epostersSubSection && this.configurationForm.value.eposters) {
                          interactiveSection.properties.items.push({ item: 'e_posters', title: 'E-Posters' });
                        }
                        if (epostersSubSection && !this.configurationForm.value.eposters) {
                          interactiveSection.properties.items.splice(interactiveSection.properties.items.indexOf(epostersSubSection), 1);
                        }

                        const notesSubSection = interactiveSection.properties.items
                          .find(section => section.item === 'notes');

                        if (!notesSubSection && this.configurationForm.value.notes) {
                          interactiveSection.properties.items.push({ item: 'notes', title: 'My Notes' });
                        }
                        if (notesSubSection && !this.configurationForm.value.notes) {
                          interactiveSection.properties.items.splice(interactiveSection.properties.items.indexOf(notesSubSection), 1);
                        }

                        this.applicationService
                          .updateOrganizationApplicationPageSection(this.organizationId, this.applicationId, homePage.id,
                            interactiveSection.id, interactiveSection)
                          .subscribe();
                      }
                    }

                    // News

                    const newsSection = homePage.sections
                      .find(section => section.type === 'news');

                    if (!newsSection && this.configurationForm.value.news) {
                      this.applicationService
                        .createOrganizationApplicationPageSection(this.organizationId, this.applicationId, homePage.id,
                          {order: 3, type: 'news', properties: { title: 'News', limit: 10 }})
                        .subscribe();
                    }
                    if (newsSection && !this.configurationForm.value.news) {
                      this.applicationService
                        .deleteOrganizationApplicationPageSection(this.organizationId, this.applicationId, homePage.id,
                          newsSection.id)
                        .subscribe();
                    }

                    // Recommendations

                    const recommendationsSection = homePage.sections
                      .find(section => section.type === 'recommendations');

                    if (!recommendationsSection && this.configurationForm.value.recommendations) {
                      this.applicationService
                        .createOrganizationApplicationPageSection(this.organizationId, this.applicationId, homePage.id,
                          {order: 4, type: 'recommendations', properties: { title: 'Recommended for you', limit: 3 }})
                        .subscribe();
                    }
                    if (recommendationsSection && !this.configurationForm.value.recommendations) {
                      this.applicationService
                        .deleteOrganizationApplicationPageSection(this.organizationId, this.applicationId, homePage.id,
                          recommendationsSection.id)
                        .subscribe();
                    }
                  }
                });
          },
          (error) => {
            this.savingApplication = false;
            this.modalService.error({ errors: error });
          }
        );
    }
  }

  close() {
    this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'landing']);
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Application Deletion',
      body: 'Are you sure you want to delete this application?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.applicationService
              .deleteConferenceApplication(this.organizationId, this.conferenceId, this.applicationId)
              .subscribe(
                () => {
                  this.close();
                },
                () => { }
              );
          }
        }
      ]
    });
  }

  togglePublished() {
    this.loadingPublish = true;
    this.applicationService
      .updateOrganizationApplication(this.organizationId, this.applicationId, { published: !this.application.published })
      .subscribe(
        () => {
          this.application.published = !this.application.published;
          this.loadingPublish = false;
        },
        () => {
          this.loadingPublish = false;
        }
      );
  }

  showVideoNavusApp() {
    const modalRef = this.modalService.open(ViewVideoModalComponent);
    modalRef.componentInstance.title = 'Within The Navus App';
    modalRef.componentInstance.videoUrl = '/assets/Navus_App_video.mp4';
  }

  showStandaloneNavusApp() {
    const modalRef = this.modalService.open(ViewVideoModalComponent);
    modalRef.componentInstance.title = 'Stand-alone App';
    modalRef.componentInstance.videoUrl = '/assets/Stand-alone_App_video.mp4';
  }
}
