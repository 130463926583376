import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Template } from '@navus/core/classes/template';
import { TemplateService } from '@navus/core/services/template.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';

@Component({
  selector: 'main [nv-template-details-page]',
  templateUrl: './template-details-page.component.html',
})
export class TemplateDetailsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  templateId: number;
  template: Template = new Template();
  templateForm: FormGroup;
  typeOptions = [
    { id: 'email', name: 'Email' },
    { id: 'document', name: 'Document' },
  ]

  loadingTemplate: boolean = false;
  savingTemplate: boolean = false;
  savingTemplateConsent: boolean = false;
  unsavedChanges: boolean = false;

  subscriptions: Subscription[] = [];

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private templateService: TemplateService,
    private toastService: ToastService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.initForm();

    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.templateId = data.templateId;
        this.getTemplate();
      }
    );
    this.subscriptions.push(subscription);

    const changeSubscription = this.templateForm.valueChanges.subscribe(
      () => {
        this.unsavedChanges = true;
      }
    );
    this.subscriptions.push(changeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.templateForm = this.formBuilder.group({
      type: ['email', [Validators.required]],
      name: ['', [Validators.required]],
      from_name: [''],
      from_address: ['', [Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,8}$/)]],
      subject: [''],
      body: ['', [Validators.required]],
    });
    this.unsavedChanges = false;
  }

  getTemplate() {
    if (this.templateId) {
      this.loadingTemplate = true;
      this.templateService
        .getOrganizationTemplate(this.organizationId, this.templateId)
        .subscribe(
          (response: { data: Template, meta: any }) => {
            const template = response.data;

            this.template = template;
            this.templateForm.patchValue(template);

            this.loadingTemplate = false;
            setTimeout(() => { this.unsavedChanges = false; }, 500);
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingTemplate = false;
          }
        );
    } else {
      this.template = new Template();
      this.loadingTemplate = false;
    }
  }

  save() {
    if (this.templateForm.invalid) { return; }

    this.savingTemplate = true;
    if (this.templateId) {
      this.templateService
        .updateOrganizationTemplate(this.organizationId, this.templateId, this.templateForm.value)
        .subscribe(
          (response) => {
            this.template = response['data'];
            this.savingTemplate = false;
            this.toastService.success('Template successfully saved.', {});
          },
          (error) => {
            this.savingTemplate = false;
            this.modalService.error({ errors: error });
          }
        );
    } else {
      this.templateService
        .createOrganizationTemplate(this.organizationId, this.templateForm.value)
        .subscribe(
          (response) => {
            this.template = response['data'];
            this.savingTemplate = false;
            this.toastService.success('Template successfully created.', {});
            this.router.navigate([this.template.id], { relativeTo: this.route });
          },
          (error) => {
            this.savingTemplate = false;
            this.modalService.error({ errors: error });
          }
        );
    }
  }

  close() {
    if (this.templateId) {
      this.router.navigate(['../..', 'templates'], { relativeTo: this.route });
    } else {
      this.router.navigate(['..', 'templates'], { relativeTo: this.route });
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Template Deletion',
      body: 'Are you sure you want to delete this template?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.templateService
              .deleteOrganizationTemplate(this.organizationId, this.templateId)
              .subscribe(
                () => {
                  this.close();
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

}
