<div class="nv-row gap-30 propagate" *ngIf="value">
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-checkbox
                    label="Show program"
                    [(ngModel)]="value.showProgram"
                    (change)="onChange(value)"
            ></nv-checkbox>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-checkbox
                    label="Show mobile app"
                    [(ngModel)]="value.showMobileApp"
                    (change)="onChange(value)"
            ></nv-checkbox>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-checkbox
                    label="Show registration"
                    [(ngModel)]="value.showRegistration"
                    (change)="onChange(value)"
            ></nv-checkbox>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-checkbox
                    label="Show e-materials"
                    [(ngModel)]="value.showEmaterials"
                    (change)="onChange(value)"
            ></nv-checkbox>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-checkbox
                    label="Show e-playlist"
                    [(ngModel)]="value.showEplaylist"
                    (change)="onChange(value)"
            ></nv-checkbox>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-checkbox
                    label="Show livestream"
                    [(ngModel)]="value.showLivestream"
                    (change)="onChange(value)"
            ></nv-checkbox>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-checkbox
                    label="Show info"
                    [(ngModel)]="value.showInfo"
                    (change)="onChange(value)"
            ></nv-checkbox>
        </div>
    </div>
</div>
