<div class="nv-section guttered" [class.edit-mode]="mouseOver">
    <ng-container *ngIf="mouseOver">
        <a class="nv-section-add-top" title="Add section" (click)="createBefore()"></a>
        <a class="nv-section-add-bottom" title="Add section" (click)="createAfter()"></a>
        <div class="nv-section-edit-tool-box">
            <a class="edit" title="Edit section" (click)="edit()"></a>
            <a class="delete" title="Delete section" (click)="delete()"></a>
        </div><!-- /.nv-section-edit-tool-box -->
        <div class="nv-section-sort-tool-box">
            <a class="move-up" title="Move up" (click)="moveUp()"></a>
            <a class="move-down" title="Move down" (click)="moveDown()"></a>
        </div><!-- /.nv-section-sort-tool-box -->
    </ng-container>

    <div class="nv-port">
        <div class="nv-row gap-24 nv-row large-spaced-top small-spaced-bottom">
            <div class="nv-column desk-12">
                <div class="nv-column-content" style="text-align: center;">
                    <h2 class="nv-twitter-heading">Join the conversation</h2>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row gap-24 v-stretch small-spaced-top spaced-bottom">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <div class="nv-line-tabs">
                        <nav class="nv-tabs-nav">
                            <ul>
                                <li *ngFor="let tweetGroup of tweetGroups | keyvalue">
                                    <a [class.active]="tweetGroup.key === activeGroup"
                                       (click)="activeGroup = tweetGroup.key"
                                    >{{ tweetGroup.key }}</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row gap-24 v-stretch spaced-bottom propagate">

            <ng-container *ngFor="let tweet of tweetGroups[activeGroup]; let i = index">
                <div class="nv-column desk-4 tab-6 phab-12" *ngIf="showMore || i < 3">
                    <div class="nv-column-content">
                        <div class="nv-twitter-item">
                            <header>
                                <div class="nv-twitter-source">
                                    <div class="ta-img">
                                        <img [src]="tweet.user_profile_image_url" alt>
                                    </div><!-- /.ta-img -->
                                    <div class="ta-txt">
                                        <h4>{{tweet.user_name}}</h4>
                                        <p>
                                            <a [href]="'https://twitter.com/' + tweet.user_screen_name" target="_blank">@{{tweet.user_screen_name}} {{tweet.created_at}}</a>
                                        </p>
                                    </div><!-- /.ta-txt -->
                                </div><!-- /.nv-twitter-source -->
                                <!--div class="nv-twitter-follow">
                                    <a href>Follow</a>
                                </div--><!-- /.nv-twitter-follow -->
                            </header>
                            <div class="ta-body" [innerHTML]="tweet.text"></div><!-- /.ta-body -->
                            <!--div class="ta-meta">
                                <div class="ta-comments"><a href title="comments"></a><span>13</span></div>
                                <div class="ta-likes"><a href title="likes"></a><span>22</span></div>
                            </div--><!-- /.ta-meta -->
                        </div><!-- /.nv-twitter-item -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </ng-container>

        </div><!-- /.nv-row -->

        <div class="nv-row gap-24 v-stretch small-spaced-top large-spaced-bottom flow-center" *ngIf="!showMore">
            <div class="nv-column desk-initial">
                <div class="nv-column-content">
                    <a (click)="showMore = true" class="nv-btn twitter medium radius-high">Load more</a>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->

    </div><!-- /.nv-port -->
</div><!-- /.nv-section -->
