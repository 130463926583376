import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'nv-checkbox',
  templateUrl: './checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NavusCheckboxComponent),
      multi: true
    }
  ]
})

export class NavusCheckboxComponent implements OnInit, ControlValueAccessor {
  @Input() formControlName: string;
  @Input() disabled: boolean = false;
  @Input() checked: boolean = false;
  @Input() label: string;
  @Input() value: boolean = false;
  @Input() name: string = null;

  @Output() change = new EventEmitter();

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  constructor() { }

  ngOnInit(): void {
    if (this.value !== false) { this.value = true; }
    if (this.disabled !== false) { this.disabled = true; }
    if (this.checked !== false) { this.checked = true; this.value = true; }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onChangeStatus(event) {
    if (this.onChange) {
      this.onChange(event.target.checked);
      this.value = event.target.checked;
    } else {
      this.value = event.target.checked;
    }
    
    }
}
