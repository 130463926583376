<div *ngIf="!activeRoom">
    <div class="nv-ls-feature-panel-placeholder">
        <a 
        class="nv-btn medium secondary" 
        (click)="join()" 
        title="Start the meeting now"
        >Start the meeting now</a>
    </div><!-- /.nv-ls-feature-panel-placeholder -->
</div>
<div *ngIf="activeRoom">
    <nv-meeting
        [streamingControls]="true"
        [autoJoin]="true" [autoCreate]="true"
        [conferenceId]="_conferenceId"
        [entityType]="'Location'"
        [entityId]="_locationId"
        [currentUser]="_currentUser"
        (closing)="close()"
    ></nv-meeting>    
</div>
