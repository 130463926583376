export class ProgramPlannerSectionConfig {
  conferenceId: number = null;
  hideFinished: boolean = false;
  showSpeakersTab: boolean = false;
  showPresentations: boolean = false;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
