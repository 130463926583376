<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Ticketing</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-8">
                <nv-button color="primary" iconPl="&#xe095;" (click)="add()">Add ticket type</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port spaced-bottom">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav vertical-break-tab">
                <ul>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'delegates']">Attendees</a>
                    </li>
                    <li>
                        <a class="active">Ticketing</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'registration-settings']">Registration Settings</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'certificates']">CME Certificates</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <form class="config-search-bar-w-filters" [formGroup]="formFilter" autocomplete="off">
        <div class="sbwf-port">
            <input type="text" placeholder="Search..."
                   formControlName="search_term" (change)="getTicketTypes()">
            <span class="sbwf-outline"></span>
        </div><!-- /.sbwf-port -->
        <div style="padding-left: 16px;">
            <strong>Tickets issued: {{ totalTickets }}</strong>
        </div>
    </form><!-- /.config-search-bar-w-filters -->
</div>
<div class="nv-config-content-port" nvInfiniteScroll (scrolled)="getTicketTypes(true)">
    <div class="nv-global-loading on" [class.on]="ticketTypesLoading">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <section class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates">
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content">
                        <div *ngIf="pagination.total === 0">
                            <div class="nv-empty-state">
                                <div class="img">
                                    <img src="../../../../assets/empty-states/empty-state-delegates.svg" alt="">
                                </div>
                                <h4 class="title">No ticket types!</h4>
                                <p class="text">Once you add a ticket type, you'll see them here.</p>
                                <nv-button (click)="add()" >Add  ticket type</nv-button>
                            </div>
                        </div>
                        <table class="nv-data-table break-lap">
                            <tr *ngFor="let ticketType of ticketTypes">
                                <td class="dt-cell nv-clickable" (click)="edit(ticketType)">
                                    <label>TITLE</label>
                                    <strong>{{ ticketType.title }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-cell nv-clickable" (click)="edit(ticketType)">
                                    <label>CAPACITY</label>
                                    <strong>{{ ticketType.capacity }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-cell nv-clickable" (click)="edit(ticketType)">
                                    <label>PRICE</label>
                                    <strong>{{ ticketType.amount }}  {{ ticketType.currency }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-actions">
                                    <div class="nv-cell-actions">
                                    <span class="nv-cell-action-trigger"
                                          [class.on]="optionsShown === ticketType.id"
                                          (click)="openOptions(ticketType)"></span>
                                        <nav nvOffClick (offClick)="closeOptions()">
                                            <ul>
                                                <li (click)="edit(ticketType)">
                                                    <a>Edit</a>
                                                </li>
                                                <li class="delete" (click)="delete(ticketType)">
                                                    <a>Delete</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div><!-- /.nv-cell-actions -->
                                </td>
                            </tr>
                        </table>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->
</div><!-- /.config-layout-lower-fixed -->
