import { Component, ContentChildren, Input, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { SlideComponent } from './slide.component';

@Component({
  selector: 'nv-slides',
  templateUrl: './slides.component.html',
  styleUrls: ['carousel.component.scss']
})
export class SlidesComponent implements OnInit {
  @ContentChildren(SlideComponent) slides;

  @Input() autoplay: { delay: number };
  @Input() navigation: boolean = true;

  config: SwiperConfigInterface;

  constructor() { }

  ngOnInit() {
    this.config = {
      direction: 'horizontal',
      slidesPerView: 1,
      autoHeight: true,
      keyboard: true,
      mousewheel: false,
      navigation: true,
      loop: false,
      spaceBetween: 0,
      observer: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
    };

    if (this.autoplay) {
      this.config.autoplay = this.autoplay;
    }
    if (!this.navigation) {
      this.config.navigation = false;
    }
  }

}
