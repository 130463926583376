import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BillingService } from '@navus/core/services/billing.service';
import { CreditCardValidators } from 'angular-cc-library';
import { ModalService } from '@navus/ui/modal/modal.service';

@Component({
  selector: 'nv-card-details-modal',
  templateUrl: './card-details-modal.component.html'
})
export class CardDetailsModalComponent implements OnInit {
  @Input() organizationId: number = null;
  @Input() cardId: number = null;

  cardForm: FormGroup;

  savingCard: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private billingService: BillingService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getCard();
  }

  initForm() {
    const currentYear = new Date().getFullYear();
    this.cardForm = this.formBuilder.group({
      name: [''],
      number: ['', [CreditCardValidators.validateCCNumber]],
      exp_month: ['', [Validators.required, Validators.min(1), Validators.max(12)]],
      exp_year: ['', [Validators.required, Validators.min(currentYear)]],
      cvc: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
    });
  }

  getCard() {
    if (this.cardId) {
      this.billingService.getOrganizationCard(this.organizationId, this.cardId)
        .subscribe(
          (response) => {
            const card = response.data;
            this.cardForm.patchValue(card);
          },
          () => { }
        );
    }
  }

  save() {
    if (this.cardForm.invalid) { return; }

    this.savingCard = true;
    if (this.cardId) {
      this.billingService.updateOrganizationCard(this.organizationId, this.cardId, this.cardForm.value)
        .subscribe(
          () => {
            this.activeModal.close();
            this.savingCard = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingCard = false;
          }
        );
    } else {
      this.billingService.createOrganizationCard(this.organizationId, this.cardForm.value)
        .subscribe(
          () => {
            this.activeModal.close();
            this.savingCard = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingCard = false;
          }
        );
    }
  }

  close() {
    this.activeModal.dismiss();
  }

}
