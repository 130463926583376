export class ArticleListSectionConfig {
  hotTopics: boolean = false;
  summary: boolean = false;
  limit: number = 10;
  openPopup: boolean = false;
  showInfo: boolean = false;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
