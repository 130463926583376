<ng-container *ngIf="banner?.resource_url">
    <div class="nv-banner-block" *ngIf="banner.external_url">
        <a (click)="bannerClick()" target="_blank">
            <img [src]="banner?.resource_url" alt>
        </a>
    </div>
    <div class="nv-banner-block" *ngIf="!banner.external_url">
        <img [src]="banner?.resource_url" alt>
    </div>
</ng-container>
