import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "@navus/core/classes/user";
import { UserService } from "@navus/core/services/user.service";
import { ModalService } from "@navus/ui/modal/modal.service";
import { ToastService } from "@navus/ui/toast/toast.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'main [nv-website-profile-security]',
  templateUrl: './security.component.html'
})
export class ProfileSecurityComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-profile-settings-body') get classView() { return !this.editMode; }
  @HostBinding('class.nv-profile-settings-detail-body') get classEdit() { return this.editMode; }
  user: User;
  passwordForm: FormGroup;

  editMode: boolean;

  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private modalService: ModalService,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    this.initForm();

    const userSubscription = this.userService.currentUser
      .subscribe((user: User) => {
        if (user) {
          this.user = user;
        }
      });
    this.subscriptions.push(userSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.passwordForm = new FormGroup({
      'password': new FormControl('', [Validators.required]),
      'confirm_password': new FormControl('', [Validators.required]),
    })
  }

  edit() {
    this.editMode = true;
  }

  cancel() {
    this.editMode = false;
  }

  save() {
    this.modalService.defaultModal({
      title: 'Confirmation',
      body: 'Are you sure you want to change password?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Change',
          color: 'primary',
          handler: () => {
            this.userService.updateUser(this.user.id, { password: this.passwordForm.value.password })
              .subscribe(
                () => {
                  this.passwordForm.reset();
                  this.editMode = false;
                  this.toastService.success('Password successfully saved.');
                },
                () => {
                  this.toastService.error('Failed to change password.');
                }
              );
          }
        }
      ]
    });
  }

}