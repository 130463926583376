import { Routes } from '@angular/router';
import { OrganizationGuard } from '../../guards/organization.guard';
import { MaterialDetailsPageComponent } from './pages/material-details/material-details-page.component';
import { MaterialListPageComponent } from './pages/material-list/material-list-page.component';
import { ConsentSettingsPageComponent } from './pages/consent-settings/consent-settings-page.component';
import { VideoPlayerPresetsPageComponent } from './pages/video-player-presets-list/video-player-presets-page.component';

export const EmaterialsRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'materials'
  },
  {
    path: 'materials',
    component: MaterialListPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Material List'
    }
  },
  {
    path: 'material/:materialId',
    component: MaterialDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Material Details'
    }
  },
  {
    path: 'material',
    component: MaterialDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Material Details'
    }
  },
  {
    path: 'consent-settings',
    component: ConsentSettingsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Consent Settings'
    }
  },
  {
    path: 'video-player-presets',
    component: VideoPlayerPresetsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Video Player Presets'
    }
  },
];
