<div class="nv-live-stage-port nv-panel-on"
    [class.nv-program-on]="showProgram"
    [class.nv-panel-on]="showPanel"><!-- < control classes = 'nv-program-on' / 'nv-panel-on' -->
    <div class="nv-live-stage-wide-column">
        <div class="nv-live-stage-banner" role="banner">
          <nv-banner [conferenceId]="conferenceId" position="Search"></nv-banner>
        </div><!-- /.nv-live-stage-banner -->
        <div class="nv-live-stage-video-port">
            <div class="nv-live-stage-video-status-bar">
                <div class="nv-live-stage-video-info">
                    <span class="live" *ngIf="currentStream?.currentPresentation && currentStream.status !== 'offair' && currentStream.url">Live</span>
                    <!--span class="break">Break</span-->
                    <span class="room" *ngIf="currentStream">{{ currentStream.name }}</span>
                </div><!-- /.nv-live-stage-video-info -->
            </div><!-- /.nv-live-stage-video-status-bar -->
            <div class="nv-live-stage-video-holder">
                <div class="nv-global-loading on" [class.on]="videoLoading">
                    <div class="nv-spinner"></div>
                </div>
                <ng-container *ngIf="currentStream?.currentPresentation && currentStream.status !== 'offair' && currentStream.url">
                    <ng-container *ngIf="hasAccess">
                        <nv-video
                            style="width: 100%; height: 100%;"
                            [conferenceId]="conferenceId"
                            [src]="currentStream.url">
                        </nv-video>
                    </ng-container>
                    <ng-container *ngIf="!hasAccess">
                        <div class="nv-live-stage-msg">
                        <div class="nv-ls-msg">
                            <h4>No Access</h4>
                            <p>Based on a current access rights, you don't have a permission to watch this content. Hit the button to get the access now.</p>
                            <p>
                                <a (click)="requestAccess()" class="nv-btn small primary">GET ACCESS</a>
                            </p>
                        </div>
                        </div><!-- /.nv-live-stage-msg -->
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!currentStream?.currentPresentation || currentStream.status === 'offair' || !currentStream.url">
                    <ng-container *ngIf="currentStream?.offAir">
                        <nv-off-air [offAir]="currentStream.offAir" [timezone]="timezone" [currentUser]="currentUser"></nv-off-air>
                    </ng-container>
                    <ng-container *ngIf="!currentStream?.offAir">
                        <div class="nv-live-stage-msg off-air">
                            <div class="nv-ls-msg">
                                <h4>Off Air</h4>
                            </div>
                        </div><!-- /.nv-live-stage-msg -->
                    </ng-container>
                </ng-container>
            </div><!-- /.nv-live-stage-video-holder -->
            <div class="nv-live-stage-program-pane">
                <header class="nv-live-stage-program-bar">
                    <h4>
                        <span>LIVE PROGRAM</span>
                        <ng-container *ngIf="timezone">
                            <br>
                            <em>{{ timezone ? 'Timezone: ' + timezone.abbr : '' }}</em>
                        </ng-container>
                        <!-- <br>
                        <em>14:30 CET</em> -->
                    </h4>
                    <div class="nv-live-stage-program-bar-tools">
                        <p class="full" (click)="showFullProgram()">View full program</p>
                        <p class="close" (click)="showProgram = false">Close Guide</p>
                    </div><!-- /.nv-live-stage-program-bar-tools -->
                </header><!-- /.nv-live-stage-program-bar -->
                <div class="nv-live-stage-program-timeline-wrap">
                    <ng-container *ngIf="livestreamState === 'current'">
                        <div class="nv-live-stage-program-timeline">
                            <div class="nv-live-stage-timeline-rooms">
                                <h5>Room</h5>
                                <div class="nv-live-stage-timeline-rooms-holder">
                                    <div class="nv-live-stage-timeline-rooms-gutter">
                                        <ng-container *ngFor="let location of locations">
                                            <ng-container *ngIf="location.timeslots.length > 0">
                                                <!-- this.router.navigate(this.route.snapshot.url, { queryParams: { location: stream.location_id }}); -->
                                                <h2 class="nv-clickable"
                                                    [class.active]="currentStream && currentStream.location_id === location.id"
                                                    [routerLink]="[]"
                                                    [queryParams]="{ location: location.id }"
                                                >{{ location.name }}</h2>
                                            </ng-container>
                                        </ng-container>
                                    </div><!-- /.nv-live-stage-timeline-rooms-gutter -->
                                </div><!-- /.nv-live-stage-timeline-rooms-holder -->
                            </div><!-- /.nv-live-stage-timeline-rooms -->
                            <div class="nv-live-stage-timeline-holder" #programScrollbar>
                                <div class="nv-live-stage-timeline-current-wrap" [style.width]="timelineWidth + 'px'" *ngIf="program">
                                    <span class="nv-live-stage-timeline-current-line" [style.left]="currentTimestamp + 'px'"></span>
                                    <div class="nv-live-stage-timeline-bar">
                                        <ng-container *ngFor="let timelineHour of timelineHours">
                                            <div class="nv-live-stage-program-timeline-chunk" [style.min-width]="widthHour + 'px'">{{ timelineHour }}</div>
                                        </ng-container>
                                    </div><!-- /.nv-live-stage-timeline-bar -->
                                    <ng-container *ngFor="let location of locations">
                                        <ng-container *ngIf="location.timeslots.length > 0">
                                            <!-- Timeline row -->
                                            <div class="nv-live-stage-timeline-items nv-timeline-items-row" [class.active]="currentStream && currentStream.location_id === location.id">
                                                <ng-container *ngFor="let timeslot of location.timeslots">
                                                    <ng-container *ngFor="let timeslotDistribution of timeslot.timeslotDistributions">
                                                        <ng-container *ngIf="timeslotDistribution?.block">
                                                            <div class="nv-live-stage-timeline-item"
                                                                 [class.current]="timeslotDistribution.block.id === currentStream?.currentPresentation?.block_id"
                                                                 [class.live]="timeslotDistribution.live"
                                                                 [class.break]="timeslotDistribution.block.type === 'Break'"
                                                                 [style]="timeslotDistribution.style"
                                                                 [title]="timeslotDistribution.block.name">
                                                                <div class="nv-live-stage-timeline-item-port">
                                                                    <div class="nv-live-stage-timeline-txt">
                                                                        <h3 data-current="Now Playing">{{ timeslotDistribution.block.name }}</h3>
                                                                        <h4 *ngIf="timeslotDistribution.block.id === currentBlock?.id">{{ currentStream?.currentPresentation?.title }}</h4>
                                                                        <!--<div class="nv-timeline-hidden"></div>-->
                                                                    </div><!-- /.nv-live-stage-timeline-txt -->

                                                                    <div class="nv-live-stage-sub-line">
                                                                        <p class="chairs" *ngIf="timeslotDistribution.block.chairperson"><strong>Chairs:</strong> {{ timeslotDistribution.block.chairperson }}</p>

                                                                        <div class="nv-live-stage-timeline-item-status">
                                                                            <span class="current" title="Current stream"></span>
                                                                            <span class="booked" title="Booked stream"></span>
                                                                            <span class="break" title="Break time"></span>
                                                                            <span class="live" title="Live Streaming"></span>
                                                                            <div class="nv-ls-program-info">
                                                                                <span (click)="showBlock(timeslotDistribution.block.id)" class="info" title="Session info"></span>
<!--                                                                                <div class="nv-ls-program-info-pop">-->
<!--                                                                                    <div class="nv-ls-program-info-pop-sleeve">-->
<!--                                                                                        <h3>Staff engagement quick</h3>-->
<!--                                                                                        <p>Core competencies onward and upward, productize the deliverables and focus on the bottom line but productize performance review post launch when does.</p>-->
<!--                                                                                    </div>&lt;!&ndash; /.nv-ls-program-info-pop-sleeve &ndash;&gt;-->
<!--                                                                                </div>&lt;!&ndash; /.nv-ls-program-info-pop &ndash;&gt;-->
                                                                            </div><!-- /.nv-ls-program-info -->
                                                                        </div><!-- /.nv-live-stage-timeline-item-status -->

                                                                        <!-- <div class="nv-live-stage-sub-block chapter" style="width:50%;"></div>
                                                                        <div class="nv-live-stage-sub-block break" style="width:35%;">
                                                                            <div class="nv-timeline-tooltip">30 minutes break</div>
                                                                        </div>
                                                                        <div class="nv-live-stage-sub-block" style="width:15%;">
                                                                            <span style="width:50%;"></span>
                                                                        </div> -->
                                                                    </div><!-- /.nv-live-stage-sub-line -->

                                                                </div><!-- /.nv-live-stage-timeline-item-port -->
                                                            </div><!-- /.nv-live-stage-timeline-item -->
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </div><!-- /.nv-live-stage-timeline-items -->
                                        </ng-container>
                                    </ng-container>
                                </div><!-- /nv-live-stage-timeline-current-wrap -->
                            </div><!-- /.nv-live-stage-timeline-holder -->
                        </div><!-- /.nv-live-stage-program-timeline -->
                    </ng-container>

                    <div class="nv-live-stage-timeline-no-program" *ngIf="livestreamState === 'future'">
                        <div class="nv-ls-timeline-no-program-txt">
                            <p>
                                Event is scheduled to run on <strong>{{ programDays[0] | nvDate }}</strong>.
                                Check the <a [routerLink]="['/program']"><strong>PROGRAM</strong></a> for detailed info.
                            </p>
                        </div><!-- /.nv-ls-timeline-no-program-txt -->
                    </div><!-- /.nv-live-stage-timeline-no-program -->
                    <div class="nv-live-stage-timeline-no-program" *ngIf="livestreamState === 'past'">
                        <div class="nv-ls-timeline-no-program-txt">
                            <p>
                                The Live event has finished. Browse the website for the post event content.
                            </p>
                        </div><!-- /.nv-ls-timeline-no-program-txt -->
                    </div><!-- /.nv-live-stage-timeline-no-program -->
                    <div class="nv-live-stage-timeline-no-program" *ngIf="livestreamState === 'noProgramToday'">
                        <div class="nv-ls-timeline-no-program-txt">
                            <p>
                                No scheduled livestream today. Please check the program.
                            </p>
                        </div><!-- /.nv-ls-timeline-no-program-txt -->
                    </div><!-- /.nv-live-stage-timeline-no-program -->
                </div><!-- /.nv-live-stage-program-timeline-wrap -->
            </div><!-- /.nv-live-stage-program-pane -->
        </div><!-- /.nv-live-stage-video-port -->
        <div class="nv-live-stage-video-meta">
            <div class="nv-live-stage-video-meta-txt">
                <div class="nv-live-stage-video-meta-line" *ngIf="currentStream?.currentPresentation">
                    <span class="state" [class.off-air]="currentStream?.status === 'offair'">{{ currentStream?.status === 'offair' ? 'Off Air' : 'Watching' }}</span><!-- < additional class 'off-air' & text 'Off Air -->
                    <span class="time">{{ currentStream.currentPresentation.starts_at | dateFormat:'HH:mm' }} - {{ currentStream.currentPresentation.ends_at | dateFormat:'HH:mm' }}</span>
                    <div class="nv-live-stage-title">{{ currentStream.currentPresentation.title }}</div>
                    <div class="nv-live-stage-video-meta-tooltip">  
                        <strong style="display: block; margin-bottom: 10px;">
                            <a (click)="showBlock(currentStream.currentPresentation.block_id)"><span>Session:</span> {{ currentStream.currentPresentation.block_name }}</a>
                        </strong><!-- /.session -->
                        <h4>{{ currentStream.currentPresentation.title }}</h4>
                        <p class="nv-video-tooltip-chairs">
                            Speakers: {{ currentStream.currentPresentation.speaker_name }}
                        </p><!-- /.nv-video-tooltip-chairs -->
                        <p class="nv-video-tooltip-meta time">{{ currentStream.currentPresentation.starts_at | dateFormat:'HH:mm' }} - {{ currentStream.currentPresentation.ends_at | dateFormat:'HH:mm' }}</p>
                        <p class="nv-video-tooltip-meta room">Room: {{ currentStream.name }}</p>
                    </div><!-- /.nv-live-stage-video-meta-tooltip -->
                </div>
            </div><!-- /.nv-live-stage-video-meta-txt -->
            <div class="nv-live-stage-live-program-toggle" (click)="toggleProgram()">
                <a class="nv-btn small accent1" data-icon-pl="&#xe619;">See Live Program Guide</a>
                <a class="nv-btn small neutral" data-icon-pl="&#xe082;">Close Live Program Guide</a>
            </div><!-- /.nv-live-stage-live-program-toggle -->
        </div><!-- /.nv-live-stage-video-meta -->
    </div><!-- /.nv-live-stage-wide-column -->
    <div class="nv-live-stage-narrow-column">

        <div class="nv-live-stage-tool-panel">
            <div class="nv-live-stage-info-box">
                <!-- <div class="nv-live-stage-info-expander">
                    <div class="nv-live-stage-info-expander-btn">
                        <span>expand</span>
                        <span>collapse</span>
                    </div>
                </div> -->
                <ng-container *ngIf="currentStream?.status !== 'offair'">
                    <div class="nv-live-stage-info-box-port">
                        <header class="nv-live-stage-main-info" *ngIf="currentStream?.currentPresentation">
                            <p>{{ currentStream.currentPresentation.starts_at | dateFormat:'HH:mm' }} - {{ currentStream.currentPresentation.ends_at | dateFormat:'HH:mm' }}</p>
                            <h3>
                                <a (click)="showBlock(currentStream.currentPresentation.block_id)"><span>Session</span> {{ currentStream.currentPresentation.block_name }}</a>
                            </h3><!-- /.session -->
                            <h2>{{ currentStream.currentPresentation.title }}</h2>
                        </header>
                        <div class="nv-live-stage-participant-info-block" *ngIf="currentStream?.currentPresentation">
                            <h4 *ngIf="currentStream?.currentPresentation?.authors?.length>0">Speakers</h4>
                            <div class="nv-live-stage-participant-row">
                                <ng-container *ngFor="let author of currentStream.currentPresentation.authors">
                                    <div class="nv-live-stage-participant" (click)="showAuthor(author)">
                                        <div class="avatar">
                                            <span nvBackgroundImage [image]="author.image_url_thumb || author.image_url"></span>
                                        </div>
                                        <div class="txt">
                                            <h5><a>
                                                <ng-container *ngIf="author.title">{{author.title}}&nbsp;</ng-container>
                                                {{ author.name }}
                                            </a></h5>
                                            <p>{{ author.institution }}</p>
                                        </div><!-- /.txt -->
                                    </div><!-- /.nv-live-stage-participant -->
                                </ng-container>

                            </div><!-- /.nv-live-stage-participant-row -->
                        </div><!-- /.nv-live-stage-participant-info-block -->
                    </div><!-- /.nv-live-stage-info-box-port -->
                </ng-container>
                <ng-container *ngIf="currentStream?.status === 'offair'">
                    <div class="nv-live-stage-offair-box-port" *ngIf="!currentStream?.offAir">
                        <div class="nv-live-stage-offair-msg">
                            <h4>Off Air</h4>
                        </div>
                    </div><!-- /.nv-live-stage-offair-box-port -->
                    <div class="nv-live-stage-offair-box-port" *ngIf="currentStream?.offAir">
                        <div class="nv-live-stage-offair-msg">
                            <h4>Off Air</h4>
                            <h3 *ngIf="['side', 'both'].includes(currentStream.offAir.title_position)">{{ currentStream.offAir.title }}</h3>
                            <p *ngIf="['side', 'both'].includes(currentStream.offAir.description_position)">{{ currentStream.offAir.description }}</p>
                        </div>
                    </div><!-- /.nv-live-stage-offair-box-port -->
                </ng-container>
            </div><!-- /.nv-live-stage-info-box -->
            <div class="nv-tool-box-widget nv-live-stage-tool-box">
                <div class="nv-tool-box-content">

                    <ng-container *ngIf="selectedSideMenu === 'chat' && chatSettings?.enabled">
                        <div class="nv-tool-box-current-tool">
                            <h4>Chat</h4>
                        </div><!-- /.tool-box-current-tool -->
                        <div class="nv-tool-box-current">
                            <nv-public-chat
                                [eventId]="conferenceId"
                                [allow1on1]="chatSettings['allow1on1']"
                                [allowGroupChat]="chatSettings['allowGroupChat']"
                            >
                            </nv-public-chat>
                        </div><!-- /.nv-tool-box-current -->
                    </ng-container>

                    <!-- <ng-container *ngIf="selectedSideMenu === 'support'">
                        <div class="nv-tool-box-current-tool">
                            <h4>Support</h4>
                        </div>
                        <div class="nv-tool-box-current">

                        </div>
                    </ng-container> -->

                    <ng-container *ngIf="selectedSideMenu === 'qna' && qaSettings?.enabled">
                        <div class="nv-tool-box-current-tool">
                            <h4>Q&A</h4>
                        </div><!-- /.tool-box-current-tool -->
                        <div class="nv-tool-box-current qa">
                            <div class="nv-live-suite-qa-tabs">
                                <div class="nv-live-suite-qa-tabs-group">
                                    <div *ngIf="qaSettings?.public_tab && currentBlock?.q_and_a?.public"
                                         class="nv-live-suite-qa-tab" [class.active]="selectedChatTab === 'true'"
                                         (click)="selectedChatTab = 'true'">Public
                                    </div>
                                    <div class="nv-live-suite-qa-tab" [class.active]="selectedChatTab === 'mine'"
                                         (click)="selectedChatTab = 'mine'">My questions
                                    </div>
                                </div><!-- /.nv-live-suite-qa-tabs-group -->
                                <div class="live-suite-qa-sessions" *ngIf="availableBlocks?.length > 1">
                                    <div class="qa-current-session" title="Change Session" (click)="sessionSelectionVisible = !sessionSelectionVisible">{{currentBlock?.name || 'Select session'}}</div>
                                    <div class="live-suite-qa-session-options" [class.on]="sessionSelectionVisible"> <!-- <toggle class 'on' to show / hide items -->
                                        <div class="live-suite-qa-session-options-sleeve">
                                            <ng-container *ngFor="let block of availableBlocks">
                                                <span (click)="setCurrentBlock(block.id)">{{block.name}}</span>
                                            </ng-container>
                                        </div><!-- /.live-suite-qa-session-options-sleeve -->
                                    </div><!-- /.live-suite-qa-session-options -->
                                </div>
                                <!-- /.live-suite-qa-sessions -->
                            </div>                            
                            <ng-container *ngIf="currentBlock && currentBlock.allow_qa">
                                <nv-chat
                                    [eventId]="conferenceId"
                                    [roomId]="'block_' + currentBlock?.id"
                                    [currentUserId]="currentUser?.id"
                                    [replies]="selectedChatTab === 'true'"
                                    [canReply]="false"
                                    [approval]="qaSettings?.moderation"
                                    [canApprove]="false"
                                    [approveStatus]="selectedChatTab === 'true' ? 'true' : 'mine'"
                                    [usecase]="'Q&A'"
                                ></nv-chat>
                                <div class="nv-live-suite-qa-input-btn" *ngIf="selectedChatTab === 'true'">
                                    <button class="nv-btn passive medium" (click)="selectedChatTab = 'mine'">Ask a question</button>
                                </div>
                            </ng-container>
                            <div *ngIf="currentBlock && !currentBlock.allow_qa"
                                 style="display: flex; justify-content: center; align-items: center; height: 100%;">
                                <span>Q&A not available for this session.</span>
                            </div>
                            <div *ngIf="!currentBlock"
                                 style="display: flex; justify-content: center; align-items: center; height: 100%;">
                                <span>No active session.</span>
                            </div>
                        </div><!-- /.nv-tool-box-current -->
                    </ng-container>

                    <ng-container *ngIf="selectedSideMenu === 'voting' && votingSettings?.enabled">
                        <div class="nv-tool-box-current-tool">
                            <h4>Voting</h4>
                        </div><!-- /.tool-box-current-tool -->
                        <div class="nv-tool-box-current">
                            <div class="votings">
                                <div *ngIf="currentVotingQuestion" class="voting">
                                    <nv-voting-question
                                        [conferenceId]="conferenceId"
                                        [votingQuestion]="currentVotingQuestion"
                                    ></nv-voting-question>
                                </div>
                                <div *ngIf="!currentVotingQuestion" class="voting">
                                    <strong>
                                        Once activated the Voting question will appear here
                                        and you will be able to vote
                                    </strong>
                                </div>
                            </div>
                        </div><!-- /.nv-tool-box-current -->
                    </ng-container>

                    <ng-container *ngIf="selectedSideMenu === 'streaming'">
                        <div class="nv-tool-box-current-tool">
                            <h4>Upcoming</h4>
                        </div>
                        <div class="nv-live-stage-streaming-box">
                            <h3>
                                <span><em>LIVE IN:</em> {{currentStream?.name}}</span>
<!--                                <a title="Change stream" (click)="changeStream()"></a>-->
                                <a *ngIf="locations.length > 1" title="Change stream" (click)="changeStream()">Switch track</a>

                            </h3>
                            <ul class="nv-live-stage-streaming-box-list">
                                <li>
                                    <div *ngIf="currentBlock">
                                        <p class="time">{{currentBlock?.timeslot?.starts_at | dateFormat: 'HH:mm'}}-{{currentBlock?.timeslot?.ends_at | dateFormat: 'HH:mm'}}</p>
                                        <h4 class="nv-clickable" (click)="showBlock(currentBlock.id)">{{currentBlock?.name}}</h4>
                                        <!-- <p *ngIf="currentBlock.chairpersons?.length > 0">
                                            Chairpersons:
                                            <span class="nv-clickable"
                                                *ngFor="let chairperson of currentBlock.chairpersons; let i = index"
                                                (click)="showAuthor(chairperson)">{{ i > 0 ? ' ,' : ''}}{{ chairperson.name }}</span>
                                        </p>
                                        <p *ngIf="!currentBlock.chairpersons && currentBlock.chairperson">
                                            Chairperson: {{currentBlock.chairperson}}
                                        </p> -->
                                    </div>
                                    <ul>
                                        <li *ngIf="currentStream?.currentPresentation" [class.live]="true">
                                            <div>
                                                <p class="time">{{currentStream?.currentPresentation?.starts_at | dateFormat: 'HH:mm' }}</p>
                                                <h5>{{currentStream?.currentPresentation?.title}}</h5>
                                                <span class="author nv-clickable" *ngFor="let author of currentStream?.currentPresentation?.authors" (click)="showAuthor(author)">
                                                    <ng-container *ngIf="author.title">{{author.title}}&nbsp;</ng-container> {{author.name}}
                                                </span>
                                            </div>
                                        </li>

                                        <ng-container *ngFor="let presentation of currentBlockPresentations">
                                            <li *ngIf="presentation.starts_at > currentStream?.currentPresentation?.starts_at && presentation.streamable">
                                                <div>
                                                    <p class="time">{{presentation.starts_at | dateFormat: 'HH:mm' }}</p>
                                                    <h5>{{presentation.title}}</h5>
                                                </div>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </li>
                            </ul>
                            <p class="nv-ls-upcoming" *ngIf="upcomingBlocks.length > 0">UPCOMING</p>
                            <ul class="nv-live-stage-streaming-box-list">
                                <ng-container *ngFor="let upcomingBlock of upcomingBlocks">
                                    <li *ngIf="(upcomingBlock.id !== currentBlock?.id) && (!currentBlock || (upcomingBlock?.timeslot?.starts_at > currentBlock?.timeslot?.starts_at))">
                                        <div>
                                            <p class="time">{{upcomingBlock?.timeslot?.starts_at | dateFormat: 'HH:mm' }}-{{upcomingBlock?.timeslot?.ends_at | dateFormat: 'HH:mm' }}</p>
                                            <h4 class="nv-clickable" (click)="showBlock(upcomingBlock.id)">{{upcomingBlock.name}}</h4>
                                            <p *ngIf="upcomingBlock?.chairpersons?.length > 0">
                                                Chaired By:
                                                <span class="nv-clickable"
                                                    *ngFor="let chairperson of upcomingBlock?.chairpersons; let i = index"
                                                    (click)="showAuthor(chairperson)">{{ i > 0 ? ', ' : ''}}<ng-container *ngIf="chairperson.title">{{chairperson.title}}&nbsp;</ng-container>{{chairperson.name}}</span>
                                            </p>
                                            <p *ngIf="!upcomingBlock?.chairpersons && upcomingBlock?.chairperson">
                                                Chairperson: {{upcomingBlock?.chairperson}}
                                            </p>
                                        </div>
                                    </li>                                    
                                </ng-container>
                            </ul>
                        </div><!-- /.nv-live-stage-streaming-box -->
                    </ng-container>

                </div><!-- /.nv-tool-box-content -->
            </div><!-- /.nv-live-stage-tool-box -->
        </div><!-- /.nv-live-stage-tool-panel -->

        <div class="nv-live-stage-tools-controls">
            <div class="nv-tool-box-controls-port upper">
                <div class="nv-tool-box-controls-group">
                    <div class="nv-tool-box-controls-sleeve">

                        <div class="nv-tool-box-control-item active" title="Session info">
                            <div class="nv-tool-box-icon info"></div>
                            <p>Session info</p>
                        </div><!-- /.nv-tool-box-control-item -->

                    </div><!-- /.nv-tool-box-controls-sleeve -->
                </div><!-- /.nv-tool-box-controls-group -->
                <!--div class="nv-tool-box-clock">09:00AM</div-->
            </div><!-- /.nv-tool-box-controls-port -->
            <div class="nv-tool-box-controls-port lower">
                <div class="nv-tool-box-controls-group accent2">
                    <div class="nv-tool-box-controls-sleeve">

                        <!-- <div class="nv-tool-box-control-item" title="Support"
                            [class.active]="selectedSideMenu === 'support'"
                            (click)="selectSideMenu('support')">
                            <div class="nv-tool-box-icon support"></div>
                            <p>Support</p>
                        </div> -->

                        <div class="nv-tool-box-control-item" title="Streaming"
                            [class.active]="selectedSideMenu === 'streaming'"
                            (click)="selectSideMenu('streaming')">
                            <div class="nv-tool-box-icon streaming"><!--span>1</span--></div>
                            <p>Upcoming</p>
                        </div><!-- /.nv-tool-box-control-item -->

                        <ng-container *ngIf="qaSettings?.enabled">
                            <div class="nv-tool-box-control-item" title="Q&A"
                                [class.active]="selectedSideMenu === 'qna'"
                                (click)="selectSideMenu('qna')">
                                <div class="nv-tool-box-icon qa"><!--span>1</span--></div>
                                <p>Q&A</p>
                            </div><!-- /.nv-tool-box-control-item -->
                        </ng-container>

                        <ng-container *ngIf="chatSettings?.enabled">
                            <div class="nv-tool-box-control-item" title="Chat"
                                [class.active]="selectedSideMenu === 'chat'"
                                (click)="selectSideMenu('chat')">
                                <div class="nv-tool-box-icon chat"><!--span>4</span--></div>
                                <p>Chat</p>
                            </div><!-- /.nv-tool-box-control-item -->
                        </ng-container>

                        <ng-container *ngIf="votingSettings?.enabled && currentStream?.status !== 'offair'">
                            <div class="nv-tool-box-control-item" title="Voting"
                                [class.active]="selectedSideMenu === 'voting'"
                                (click)="selectSideMenu('voting')">
                                <div class="nv-tool-box-icon voting"><!--span>1</span--></div>
                                <p>Voting</p>
                            </div><!-- /.nv-tool-box-control-item -->                            
                        </ng-container>


                    </div><!-- /.nv-tool-box-controls-sleeve -->
                </div><!-- /.nv-tool-box-controls-group -->
                <div class="nv-tool-box-toggler" (click)="togglePanel()"><span></span></div>
            </div><!-- /.nv-tool-box-controls-port -->
        </div><!-- /.nv-live-stage-tools-controls -->
    </div><!-- /.nv-live-stage-narrow-column -->
</div><!-- /.nv-live-stage-port -->


<!--<div class="nv-live-stage-timeline-rooms">-->
<!--    <ul>-->
<!--        <li>Florida</li>-->
<!--        <li>Eurostream 1</li>-->
<!--        <li>Room 3</li>-->
<!--        <li>Geneva</li>-->
<!--        <li>Room 4</li>-->
<!--        <li>Room 5</li>-->
<!--        <li>Room 6</li>-->
<!--        <li>Room 7</li>-->
<!--        <li>Room 8</li>-->
<!--        <li>Room 9</li>-->
<!--        <li>Room 10</li>-->
<!--        <li>Room 11</li>-->
<!--    </ul>-->
<!--</div>&lt;!&ndash; /.nv-live-stage-timeline-rooms &ndash;&gt;-->
