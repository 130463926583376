<a class="tsp-close" (click)="close.emit()" title="Close panel"></a>
<header>
    <h4>Design</h4>
</header>
<nav style="padding: 0 20px">
    <form [formGroup]="websiteForm" class="nv-row gap-30 propagate">
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <h5>Logo</h5>
                <nv-file
                        [organizationId]="website.organization_id"
                        formControlName="logo"
                        label="Logo"
                ></nv-file>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <h5>Footer</h5>
                <ng-container formGroupName="settings">
                    <nv-text-editor
                        [organizationId]="website.organization_id"
                        formControlName="footer"
                    ></nv-text-editor>
                </ng-container>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <h5>Custom CSS</h5>
                <ng-container formGroupName="settings">
                    <nv-code-editor
                        formControlName="custom_css"
                        mode="css"
                    ></nv-code-editor>
                </ng-container>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
<!--        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <h5>Theme</h5>
                <nv-radio
                        formControlName="theme"
                        value="light-skin"
                        label="Light template"
                ></nv-radio>
                <nv-radio
                        formControlName="theme"
                        value="dark-skin"
                        label="Dark template"
                ></nv-radio>
            </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;
        </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <nv-button [disabled]="websiteForm.invalid" (click)="save()">Save</nv-button>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
    </form>
</nav>
