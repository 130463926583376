<div class="nv-modal small">
    <div class="nv-modal-header">
        <h4 class="nv-modal-title">Ticket type code</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body">
        <form [formGroup]="ticketTypeCodeForm" class="nv-row gap-30 propagate">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <nv-input
                            formControlName="title"
                            label="Title"
                    ></nv-input>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <nv-input
                            formControlName="code"
                            label="Code"
                    ></nv-input>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <nv-select
                            formControlName="type"
                            label="Type"
                            [options]="typeOptions"
                    ></nv-select>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <nv-input
                            formControlName="amount"
                            label="Amount"
                    ></nv-input>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </form>
    </div>
    <div class="nv-modal-footer">
        <div class="nv-btn-box gap-10 flow-end">
            <nv-button size="small" color="neutral" (click)="close()">Cancel</nv-button>
            <nv-button size="small" color="primary" (click)="save()" [loading]="savingTicketTypeCode" [disabled]="ticketTypeCodeForm.invalid" translate>Save</nv-button>
        </div>
    </div>
</div>

