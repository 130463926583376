
<ng-container [ngSwitch]="_offAir?.resource_type">
  <ng-container *ngSwitchCase="'resources'">
    <div class="nv-live-stage-msg off-air" nvBackgroundImage [image]="_offAir.resource_value">
      <div class="nv-ls-msg" *ngIf="showTitle || showDescription || showCountdown">
          <h5 *ngIf="showTitle">{{ _offAir.title }}</h5>
          <p *ngIf="showDescription && !counterEnded">{{ _offAir.description }}</p>
          <ng-container *ngIf="showCountdown">
            <div *ngIf="!counterEnded" class="nv-countdown">
              <div class="countdown-section" *ngIf="counterDays !== '00'">
                <div class="countdown-label">Days</div>
                <div class="countdown-value">{{ counterDays }}</div>
              </div>
              <div class="countdown-section" *ngIf="counterHours !== '00'">
                <div class="countdown-label">Hours</div>
                <div class="countdown-value">{{ counterHours }}</div>
              </div>
              <div class="countdown-section">
                <div class="countdown-label">Minutes</div>
                <div class="countdown-value">{{ counterMinutes }}</div>
              </div>
              <div class="countdown-section">
                <div class="countdown-label">Seconds</div>
                <div class="countdown-value">{{ counterSeconds }}</div>
              </div>
            </div>
            <div *ngIf="counterEnded">
              <p>{{ _offAir.countdown_ended_label }}</p>
            </div>
          </ng-container>
      </div>
    </div>  
  </ng-container>
  <ng-container *ngSwitchCase="'external'">
    <div class="nv-live-stage-msg off-air" 
      style="background-position: no-repeat; background-size: contain;" nvBackgroundImage [image]="_offAir.resource_value">
      <div class="nv-ls-msg" *ngIf="showTitle || showDescription || showCountdown">
        <h5 *ngIf="showTitle">{{ _offAir.title }}</h5>
        <p *ngIf="showDescription && !counterEnded">{{ _offAir.description }}</p>
        <ng-container *ngIf="showCountdown">
            <div *ngIf="!counterEnded" class="nv-countdown">
              <div class="countdown-section" *ngIf="counterDays !== '00'">
                <div class="countdown-label">Days</div>
                <div class="countdown-value">{{ counterDays }}</div>
              </div>
              <div class="countdown-section" *ngIf="counterHours !== '00'">
                <div class="countdown-label">Hours</div>
                <div class="countdown-value">{{ counterHours }}</div>
              </div>
              <div class="countdown-section">
                <div class="countdown-label">Minutes</div>
                <div class="countdown-value">{{ counterMinutes }}</div>
              </div>
              <div class="countdown-section">
                <div class="countdown-label">Seconds</div>
                <div class="countdown-value">{{ counterSeconds }}</div>
              </div>
            </div>
            <div *ngIf="counterEnded">
              <p>{{ _offAir.countdown_ended_label }}</p>
            </div>
          </ng-container>
      </div>
    </div>  
  </ng-container>
  <ng-container *ngSwitchCase="'external_video'">
    <div class="nv-live-stage-msg off-air">
      <nv-video 
        style="position: absolute; top: 0; bottom: 0; left: 0; right: 0;"
        [src]="_offAir.resource_value"
      ></nv-video>
      <!-- <div class="nv-ls-msg" *ngIf="showTitle || showDescription || showCountdown">
        <h5 *ngIf="showTitle">{{ _offAir.title }}</h5>
        <p *ngIf="showDescription">{{ _offAir.description }}</p>
        <ng-container *ngIf="showCountdown">
            <div *ngIf="!counterEnded" class="nv-countdown">
              <div class="countdown-section" *ngIf="counterDays !== '00'">
                <div class="countdown-label">Days</div>
                <div class="countdown-value">{{ counterDays }}</div>
              </div>
              <div class="countdown-section" *ngIf="counterHours !== '00'">
                <div class="countdown-label">Hours</div>
                <div class="countdown-value">{{ counterHours }}</div>
              </div>
              <div class="countdown-section">
                <div class="countdown-label">Minutes</div>
                <div class="countdown-value">{{ counterMinutes }}</div>
              </div>
              <div class="countdown-section">
                <div class="countdown-label">Seconds</div>
                <div class="countdown-value">{{ counterSeconds }}</div>
              </div>
            </div>
            <div *ngIf="counterEnded">
              <p>{{ _offAir.countdown_ended_label }}</p>
            </div>
          </ng-container>
      </div> -->
    </div>  
  </ng-container>
  <ng-container *ngSwitchCase="'materials'">
    <ng-container *ngIf="_offAir.materials?.type === 'webcast'">
      <div class="nv-live-stage-msg off-air">
        <nv-video 
          style="position: absolute; top: 0; bottom: 0; left: 0; right: 0;"
          [src]="_offAir.materials.path + '?nav=1010000000&autoplay=1&loop=1'"
        ></nv-video>
        <div class="nv-ls-msg" style="z-index: 1;" *ngIf="showTitle || showDescription || showCountdown">
          <h5 *ngIf="showTitle">{{ _offAir.title }}</h5>
          <p *ngIf="showDescription && !counterEnded">{{ _offAir.description }}</p>
          <ng-container *ngIf="showCountdown">
              <div *ngIf="!counterEnded" class="nv-countdown">
                <div class="countdown-section" *ngIf="counterDays !== '00'">
                  <div class="countdown-label">Days</div>
                  <div class="countdown-value">{{ counterDays }}</div>
                </div>
                <div class="countdown-section" *ngIf="counterHours !== '00'">
                  <div class="countdown-label">Hours</div>
                  <div class="countdown-value">{{ counterHours }}</div>
                </div>
                <div class="countdown-section">
                  <div class="countdown-label">Minutes</div>
                  <div class="countdown-value">{{ counterMinutes }}</div>
                </div>
                <div class="countdown-section">
                  <div class="countdown-label">Seconds</div>
                  <div class="countdown-value">{{ counterSeconds }}</div>
                </div>
              </div>
              <div *ngIf="counterEnded">
                <p>{{ _offAir.countdown_ended_label }}</p>
              </div>
            </ng-container>
        </div>
      </div>      
    </ng-container>
    <ng-container *ngIf="_offAir.materials?.type === 'main' || _offAir.materials?.type === 'supporting'">
      <div class="nv-live-stage-msg off-air">
        <nv-slides style="position: absolute; top: 0; bottom: 0; left: 0; right: 0;">
          <nv-slide *ngFor="let image of images">
            <figure><img [src]="image"></figure>
          </nv-slide>
        </nv-slides>
      </div>     
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <div class="nv-live-stage-msg off-air">
      <div class="nv-ls-msg" *ngIf="showTitle || showDescription || showCountdown">
          <h4>Off Air</h4>
          <h5 *ngIf="showTitle">{{ _offAir.title }}</h5>
          <p *ngIf="showDescription && !counterEnded">{{ _offAir.description }}</p>
          <ng-container *ngIf="showCountdown">
            <div *ngIf="!counterEnded" class="nv-countdown">
              <div class="countdown-section" *ngIf="counterDays !== '00'">
                <div class="countdown-label">Days</div>
                <div class="countdown-value">{{ counterDays }}</div>
              </div>
              <div class="countdown-section" *ngIf="counterHours !== '00'">
                <div class="countdown-label">Hours</div>
                <div class="countdown-value">{{ counterHours }}</div>
              </div>
              <div class="countdown-section">
                <div class="countdown-label">Minutes</div>
                <div class="countdown-value">{{ counterMinutes }}</div>
              </div>
              <div class="countdown-section">
                <div class="countdown-label">Seconds</div>
                <div class="countdown-value">{{ counterSeconds }}</div>
              </div>
            </div>
            <div *ngIf="counterEnded">
              <p>{{ _offAir.countdown_ended_label }}</p>
            </div>
          </ng-container>
      </div>
    </div>    
  </ng-container>
</ng-container>
