<div class="custom-chart-legend">
    <div class="custom-chart-legend-title" *ngIf="title">
        {{title}}
    </div>
    <div class="custom-chart-legend-items">
        <ng-container *ngFor="let row of legendItems">
            <div class="custom-chart-legend-item"
                 [class.active]="isLabelActive(row)"
                 (click)="selectRow(row.name)"
                 (mouseenter)="legendLabelActivate(row)"
                 (mouseleave)="legendLabelDeactivate(row)">
                <div *ngIf="row.color" class="custom-chart-color-box" [style.background-color]="row.color"></div>
                <div class="custom-chart-legend-name">{{row.name + (row.value ? (': ' + row.value) : '')}}</div>
                <img *ngIf="row.image" style="height:20px;margin-right:10px" [src]="row.image">
            </div>
        </ng-container>
    </div>
    <div *ngIf="description">
        {{description}}
    </div>
</div>

