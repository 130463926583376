<div *ngIf="!presentationId" class="nv-ls-feature-panel-add-slides">
    <p>No presentation has been selected. Once a presentation is streamed, you will be able to manage and run the voting.    </p>
</div><!-- /.nv-ls-feature-panel-add-slides -->

<ng-container *ngIf="presentationId">
    <div *ngIf="!votingQuestions?.length" class="nv-ls-feature-panel-add-slides">
        <p>No voting questions yet.</p>
        <a (click)="openVoting()" class="nv-btn medium passive">Go to voting</a>
        &nbsp;
        <a (click)="refreshVotingQuestions()" class="nv-btn medium passive">Refresh</a>
    </div><!-- /.nv-ls-feature-panel-add-slides -->

    <div class="nv-row config-plates clean gap-24 spaced-bottom" *ngIf="votingQuestions?.length">
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <table class="nv-contacts nv-table">
                    <thead>
                    <tr>
                        <th translate>Title</th>
                        <th style="width: 100px" translate>Status</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let question of votingQuestions" class="nv-clickable">
                        <td>{{ question.title }}</td>
                        <td>{{ question.status }}</td>
                        <td>
                            <nv-button size="small" *ngIf="question.status === 'published'"
                                       (click)="setQuestionStatus(question, 'active'); runQuestion(question)">
                                Run
                            </nv-button>
                            &nbsp;
                            <nv-button size="small" *ngIf="question.status === 'active'"
                                       (click)="setQuestionStatus(question, 'finished')">
                                Stop
                            </nv-button>
                            &nbsp;
                            <nv-button size="small"
                                       *ngIf="question.status === 'active' || question.status === 'published'"
                                       (click)="runQuestion(question)">
                                Open
                            </nv-button>
                            &nbsp;
                            <nv-button size="small" *ngIf="question.status === 'finished'"
                                       (click)="runQuestion(question)">
                                Results
                            </nv-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div>&nbsp;</div>
            </div>
        </div>
        <div class="nv-column desk-6">
            <div class="nv-column-content">
                <nv-button (click)="openVoting()" translate>Add question</nv-button>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-6">
            <div class="nv-column-content">
                <nv-button (click)="refreshVotingQuestions()" translate>Refresh voting</nv-button>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
    </div>
</ng-container>
