<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>DASHBOARD</h1>
        </div><!-- /.tnt-left -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav  vertical-break-tab">
                <ul>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'dashboard', 'totals']">
                            Totals
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'dashboard', 'live']">
                            Live
                        </a>
                    </li>
                    <li>
                        <a class="active">
                            On-demand
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'dashboard', 'on-site']">
                            On-site
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'dashboard', 'sponsor']">
                            Sponsor
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>

<div class="nv-config-content-port">
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24 small-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h4 *ngIf="lastRecordedDateTime" class="nv-chart-time-badge"><span>Time of
                            synchronization: <strong>{{ lastRecordedDateTime | nvDate:'MMM dd, HH:mm'  }}</strong></span></h4>

                        <div class="nv-row config-plates clean gap-24 propagate">
                            <div class="nv-column desk-4">
                                <div class="nv-column-content">
                                    <nv-datepicker
                                            [(ngModel)]="dateFrom"
                                            type="calendar"
                                            label="From"
                                            required
                                    ></nv-datepicker>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-4">
                                <div class="nv-column-content">
                                    <nv-datepicker
                                            [(ngModel)]="dateTo"
                                            type="calendar"
                                            label="To"
                                            required
                                    ></nv-datepicker>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-4">
                                <div class="nv-column-content">
                                    <nv-button size="medium" (click)="applyDateRange()">
                                        Apply Range
                                    </nv-button>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                        </div>

                        <div class="nv-row config-plates clean gap-50 propagate">

                            <div class="nv-global-loading on"
                                 [class.on]="loadingOnDemandNumbers">
                                <div class="nv-spinner"></div>
                            </div><!-- /.nv-global-loading -->

                            <ng-container *ngIf="!loadingOnDemandNumbers && totalTimeSpentChartData?.length">
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content nv-dashboard-chart-no-pointer">
                                        <h3 class="light">Total time spent</h3>
                                        <ngx-charts-advanced-pie-chart
                                                [animations]="false"
                                                [valueFormatting]="formatNumber"
                                                [scheme]="chartColorScheme"
                                                [results]="totalTimeSpentChartData">
                                            <ng-template #tooltipTemplate let-model="model">
                                                <div>{{ transformToTime(model.value)}}</div>
                                                <div>{{ model.name }}</div>
                                            </ng-template>
                                        </ngx-charts-advanced-pie-chart>
                                    </div><!-- nv-column-content-->
                                </div><!-- nv-column -->
                            </ng-container>

                            <ng-container *ngIf="!loadingOnDemandNumbers && totalTimeSpentPerDayChartData?.length">
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content nv-dashboard-chart-no-pointer nv-dashboard-chart-no-hover">
                                        <h3 class="light">Total time spent per day</h3>
                                        <ngx-charts-bar-vertical-2d
                                                [scheme]="chartColorScheme"
                                                [results]="totalTimeSpentPerDayChartData"
                                                [xAxis]="true"
                                                [yAxis]="true"
                                                [legend]="true"
                                                [showXAxisLabel]="false"
                                                [showYAxisLabel]="true"
                                                [yAxisLabel]="'Time spent (seconds)'">
                                            <ng-template #tooltipTemplate let-model="model">
                                                <div>{{ transformToTime(model.value)}}</div>
                                                <div>{{ model.name }}</div>
                                            </ng-template>
                                        </ngx-charts-bar-vertical-2d>
                                    </div><!-- nv-column-content-->
                                </div><!-- nv-column -->
                            </ng-container>

                            <ng-container *ngIf="!loadingOnDemandNumbers && totalParticipantsChartData?.length">
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content nv-dashboard-chart-no-pointer">
                                        <h3 class="light">Number of unique participants accessing content</h3>
                                        <ngx-charts-number-card
                                                [scheme]="chartColorScheme"
                                                [results]="totalParticipantsChartData"
                                                [cardColor]="chartCardColor">
                                        </ngx-charts-number-card>
                                    </div><!-- nv-column-content-->
                                </div><!-- nv-column -->
                            </ng-container>

                            <ng-container *ngIf="!loadingOnDemandNumbers && totalParticipantsPerDayChartData?.length">
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content nv-dashboard-chart-no-pointer nv-dashboard-chart-no-hover">
                                        <h3 class="light">Total access per day</h3>
                                        <ngx-charts-bar-vertical-2d
                                                [scheme]="chartColorScheme"
                                                [results]="totalParticipantsPerDayChartData"
                                                [xAxis]="true"
                                                [yAxis]="true"
                                                [legend]="true"
                                                [showXAxisLabel]="false"
                                                [showYAxisLabel]="true"
                                                [yAxisLabel]="'Total accesses'">
                                        </ngx-charts-bar-vertical-2d>
                                    </div><!-- nv-column-content-->
                                </div><!-- nv-column -->
                            </ng-container>

                            <ng-container *ngIf="!loadingWatchingSessions && sessionParticipantsChartData?.length">
                                <div class="nv-column desk-8 lap-12">
                                    <div class="nv-column-content">
                                        <h3 class="light">On-demand participants per session</h3>

                                        <div class="nv-chart-type-350">
                                            <ngx-charts-bar-vertical
                                                    [view]="sessionParticipantsChartData?.length > 10? null : [sessionParticipantsChartData.length * 70 + 100, 350]"
                                                    [barPadding]="sessionParticipantsChartData?.length > 10? 10 : 30"
                                                    [scheme]="chartColorScheme"
                                                    [results]="sessionParticipantsChartData"
                                                    [xAxis]="true"
                                                    [yAxis]="true"
                                                    [legend]="false"
                                                    [showXAxisLabel]="true"
                                                    [showYAxisLabel]="true"
                                                    [xAxisLabel]="'Session'"
                                                    [yAxisLabel]="'On-demand participants'"
                                                    [activeEntries]="sessionParticipantsActiveEntries"
                                                    (activate)="sessionParticipantsLegendLabelActivate($event)"
                                                    (deactivate)="sessionParticipantsLegendLabelDeactivate($event)"
                                                    (select)="onSelectSessionParticipants($event)">
                                            </ngx-charts-bar-vertical>
<!--                                            [view]="sessionParticipantsChartData?.length > 10? null : [sessionParticipantsChartData.length * 70 + 100, 400]"-->
                                        </div><!-- /.nv-chart-type-350 -->

                                        <div class="nv-divider desk-small"></div>

                                        <nv-custom-dashboard-chart-legend
                                                [title]="'Sessions'"
                                                [legendItems]="sessionParticipantsLegend"
                                                [activeEntries]="sessionParticipantsActiveEntries"
                                                [chartData]="sessionParticipantsChartData"
                                                (selectItem)="onSelectSessionParticipants($event)"
                                                (activateLabel)="sessionParticipantsLegendLabelActivate($event)"
                                                (deactivateLabel)="sessionParticipantsLegendLabelDeactivate($event)">
                                        </nv-custom-dashboard-chart-legend>
                                    </div><!-- nv-column-content-->
                                </div><!-- nv-column -->


                                <div class="nv-column desk-4 lap-12">
                                    <div class="nv-column-content nv-dashboard-chart-no-pointer nv-dashboard-chart-no-hover">
                                        <ng-container *ngIf="presentationParticipantsChartData?.length">
                                            <h3 class="light">On-demand participants per presentation for session: {{selectedSessionParticipants?.name}}</h3>

                                            <div class="nv-chart-type-350">
                                                <ngx-charts-bar-vertical
                                                        [view]="presentationParticipantsChartData?.length > 10? null : [presentationParticipantsChartData.length * 70 + 100, 350]"
                                                        [barPadding]="presentationParticipantsChartData?.length > 10? 10 : 30"
                                                        [scheme]="chartColorScheme"
                                                        [results]="presentationParticipantsChartData"
                                                        [xAxis]="true"
                                                        [yAxis]="true"
                                                        [legend]="false"
                                                        [showXAxisLabel]="true"
                                                        [showYAxisLabel]="true"
                                                        [xAxisLabel]="'Presentations'"
                                                        [activeEntries]="presentationParticipantsActiveEntries"
                                                        [yAxisLabel]="'On-demand participants'"
                                                        (activate)="presentationParticipantsLegendLabelActivate($event)"
                                                        (deactivate)="presentationParticipantsLegendLabelDeactivate($event)">
                                                </ngx-charts-bar-vertical>
                                        <!--                                            [view]="presentationParticipantsChartData?.length > 10? null : [presentationParticipantsChartData.length * 70 + 100, 400]"-->
                                            </div><!-- /.nv-chart-type-350 -->

                                            <div class="nv-divider desk-small"></div>

                                            <nv-custom-dashboard-chart-legend
                                                    [title]="'Presentations'"
                                                    [legendItems]="presentationParticipantsLegend"
                                                    [activeEntries]="presentationParticipantsActiveEntries"
                                                    [chartData]="presentationParticipantsChartData"
                                                    (activateLabel)="presentationParticipantsLegendLabelActivate($event)"
                                                    (deactivateLabel)="presentationParticipantsLegendLabelDeactivate($event)">
                                            </nv-custom-dashboard-chart-legend>
                                        </ng-container>
                                        <div class="nv-clickable-chart-notice">
                                            <p>Click on a session bar in the chart to view a detailed break-down</p>
                                        </div><!-- /.nv-clickable-chart-notice -->
                                    </div><!-- nv-column-content-->
                                </div><!-- nv-column -->
                            </ng-container>

                            <ng-container *ngIf="!loadingWatchingSessions && sessionTimeSpentChartData?.length">
                                <div class="nv-column desk-8 lap-12">
                                    <div class="nv-column-content">
                                        <h3 class="light">On-demand time spent</h3>

                                        <div class="nv-chart-type-350">
                                            <ngx-charts-bar-vertical
                                                    [view]="sessionTimeSpentChartData?.length > 10? null : [sessionTimeSpentChartData.length * 70 + 100, 350]"
                                                    [barPadding]="sessionTimeSpentChartData?.length > 10? 10 : 30"
                                                    [scheme]="chartColorScheme"
                                                    [results]="sessionTimeSpentChartData"
                                                    [xAxis]="true"
                                                    [yAxis]="true"
                                                    [legend]="false"
                                                    [showXAxisLabel]="true"
                                                    [showYAxisLabel]="true"
                                                    [xAxisLabel]="'Session'"
                                                    [yAxisLabel]="'On-demand time spent (seconds)'"
                                                    [activeEntries]="sessionTimeSpentActiveEntries"
                                                    (activate)="sessionTimeSpentLegendLabelActivate($event)"
                                                    (deactivate)="sessionTimeSpentLegendLabelDeactivate($event)"
                                                    (select)="onSelectSessionTimeSpent($event)">
                                                <ng-template #tooltipTemplate let-model="model">
                                                    <div>
                                                        {{ model.name }}
                                                    </div>
                                                    <div>
                                                        {{ model.value | timeFormat }}
                                                    </div>
                                                </ng-template>
                                            </ngx-charts-bar-vertical>
                                            <!-- [view]="sessionTimeSpentChartData?.length > 10? null : [sessionTimeSpentChartData.length * 70 + 100, 400]"-->
                                        </div><!-- /.nv-chart-type-350 -->

                                        <div class="nv-divider desk-small"></div>

                                        <nv-custom-dashboard-chart-legend
                                                [title]="'Sessions'"
                                                [legendItems]="sessionTimeSpentLegend"
                                                [activeEntries]="sessionTimeSpentActiveEntries"
                                                [chartData]="sessionTimeSpentChartData"
                                                (selectItem)="onSelectSessionTimeSpent($event)"
                                                (activateLabel)="sessionTimeSpentLegendLabelActivate($event)"
                                                (deactivateLabel)="sessionTimeSpentLegendLabelDeactivate($event)">
                                        </nv-custom-dashboard-chart-legend>
                                    </div><!-- nv-column-content-->
                                </div><!-- nv-column -->

                                <div class="nv-column desk-4 lap-12">
                                    <div class="nv-column-content nv-dashboard-chart-no-pointer nv-dashboard-chart-no-hover">
                                        <ng-container  *ngIf="presentationTimeSpentChartData?.length">
                                            <h3 class="light">On-demand time spent per presentation for session: {{selectedSessionTimeSpent?.name}}</h3>
                                            <div class="nv-chart-type-350">
                                                <ngx-charts-bar-vertical
                                                        [view]="presentationTimeSpentChartData?.length > 10? null : [presentationTimeSpentChartData.length * 70 + 100, 350]"
                                                        [barPadding]="presentationTimeSpentChartData?.length > 10? 10 : 30"
                                                        [scheme]="chartColorScheme"
                                                        [results]="presentationTimeSpentChartData"
                                                        [xAxis]="true"
                                                        [yAxis]="true"
                                                        [legend]="false"
                                                        [showXAxisLabel]="true"
                                                        [showYAxisLabel]="true"
                                                        [xAxisLabel]="'Presentations'"
                                                        [yAxisLabel]="'On-demand time spent (seconds)'"
                                                        (activate)="presentationTimeSpentLegendLabelActivate($event)"
                                                        (deactivate)="presentationTimeSpentLegendLabelDeactivate($event)"
                                                        [activeEntries]="presentationTimeSpentActiveEntries">
                                                    <ng-template #tooltipTemplate let-model="model">
                                                        <div>
                                                            {{ model.name }}
                                                        </div>
                                                        <div>
                                                            {{ model.value | timeFormat }}
                                                        </div>
                                                    </ng-template>
                                                </ngx-charts-bar-vertical>
                                                <!-- [view]="presentationTimeSpentChartData?.length > 10? null : [presentationTimeSpentChartData.length * 70 + 100, 400]" -->
                                            </div><!-- /.nv-chart-type-350 -->

                                            <div class="nv-divider desk-small"></div>

                                            <nv-custom-dashboard-chart-legend
                                                    [title]="'Presentations'"
                                                    [legendItems]="presentationTimeSpentLegend"
                                                    [activeEntries]="presentationTimeSpentActiveEntries"
                                                    [chartData]="presentationTimeSpentChartData"
                                                    (activateLabel)="presentationTimeSpentLegendLabelActivate($event)"
                                                    (deactivateLabel)="presentationTimeSpentLegendLabelDeactivate($event)">
                                            </nv-custom-dashboard-chart-legend>
                                        </ng-container>
                                        <div class="nv-clickable-chart-notice">
                                            <p>Click on a session bar in the chart to view a detailed break-down</p>
                                        </div><!-- /.nv-clickable-chart-notice -->
                                    </div><!-- nv-column-content-->
                                </div><!-- nv-column -->
                            </ng-container>
                        </div><!-- nv-row -->

                        <div class="nv-row config-plates clean gap-24 propagate">
                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <h3 class="light">Download XLS Reports</h3>
                                    <small>Range: {{ dateFrom | nvDate:'MMM dd'  }} - {{ dateTo | nvDate:'MMM dd'  }}</small>
                                    <div class="nv-btn-box gap-10">
                                        <nv-button size="medium" [disabled]="downloadingOnDemandReport"
                                                   (click)="downloadOnDemandReport()">
                                            On Demand
                                        </nv-button>
                                    </div>
                                </div><!-- /nv-column-content -->
                            </div><!-- /nv-column -->
                        </div><!-- /nv-row -->

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
