import { Component, Input } from '@angular/core';


@Component({
    selector: 'nv-tooltip',
    templateUrl: './tooltip.component.html'
})

export class NavusTooltipComponent {

    @Input() text: string;
    @Input() position: string | 'left' | 'right' | 'top' | 'bottom' = 'top';
}
