import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { CustomHtmlSectionConfig } from './custom-html-section.config';
import { BaseSectionComponent } from '../_base/base-section.component';
import { WebsiteService } from '@navus/core/services/website.service';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { WebsiteControlService } from '../../services/website-control.service';

@Component({
  selector: 'nv-custom-html-section',
  templateUrl: './custom-html-section.component.html'
})
export class CustomHtmlSectionComponent extends BaseSectionComponent implements OnInit, OnDestroy {
  @Input() data: WebsitePageSection;
  configuration: CustomHtmlSectionConfig = new CustomHtmlSectionConfig();
  htmlContent: any;

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );
  }

  ngOnInit() {
    this.configuration = new CustomHtmlSectionConfig(this.data.configuration);
    this.htmlContent = (this.configuration && this.configuration.content) || '';
  }

  ngOnDestroy() {

  }

}

