import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DelegateService } from '@navus/core/services/delegate.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { Color } from '@swimlane/ngx-charts';
import { AttendancePresentation, AttendanceSession } from '@navus/core/classes/attendance-session';
import { TimeFormatPipe } from '@navus/ui/pipes/time-format.pipe';
import { Program } from '@navus/core/classes/program';
import { Block } from '@navus/core/classes/block';
import { Presentation } from '@navus/core/classes/presentation';
import { ConferenceService } from '@navus/core/services/conference.service';
import { BlockService } from '@navus/core/services/block.service';
import { PresentationService } from '@navus/core/services/presentation.service';
import { Material } from '@navus/core/classes/material';

@Component({
  selector: 'nv-on-demand-attendance',
  templateUrl: './on-demand-attendance.component.html',
})
export class OnDemandAttendanceComponent implements OnInit, OnDestroy {
  @Input() templateForTooltip: TemplateRef<void>;

  organizationId: number;
  conferenceId: number;
  delegateId: number;

  chartColorScheme = {
    domain: ['#318AF1', '#2873A1', '#8ABAD6', '#32B799', '#279079', '#18707F', '#ED4649', '#F49092', '#AD465A', '#ED8747']
  } as Color;

  loadingAttendance = false;
  attendanceData: AttendanceSession[];
  sessionData: { name: string, value: number }[];
  sessionLegend: { id: number, name: string, value: string, color: string }[];
  sessionActiveEntries: any[] = [];
  selectedSession: AttendanceSession;

  presentationData: { name: string, value: number }[];
  presentationLegend: { name: string, value: string, color: string }[];
  presentationActiveEntries: any[] = [];
  selectedPresentation: AttendancePresentation;
  selectedPresentationInLegend: number;

  materialData: { name: string, value: number }[];
  materialLegend: { name: string, value: string, color: string }[];
  materialActiveEntries: any[] = [];
  selectedMaterialInLegend: number;

  availableSessions: { id: number, name: string }[];
  availablePresentations: { id: number, name: string, blockId: number, time: number, duration: number }[];
  availableMaterials: Material[];
  private allPresentations: { id: number, name: string, blockId: number, time: number, duration: number, materials: Material[] }[];

  private subscriptions: Subscription[] = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private delegateService: DelegateService,
              private conferenceService: ConferenceService,
              private blockService: BlockService,
              private presentationService: PresentationService,
              private modalService: ModalService,
              private timeFormatPipe: TimeFormatPipe) {
  }

  ngOnInit() {
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.delegateId = data.delegateId;

        this.getAttendance();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  onSelectSession(event) {
    const chosenSession = typeof event === 'string' ? event : event.name;
    if (chosenSession) {
      this.selectedSession = this.attendanceData
        .find(item => item.name === chosenSession);
      this.presentationData = this.selectedSession?.presentations?.map(item => ({ name: item.title, value: item.time_spent }));

      // Initialize legend
      this.presentationLegend = this.selectedSession?.presentations?.map((item, index) => ({
        name: item.title,
        value: this.timeFormatPipe.transform(item.time_spent),
        color: this.chartColorScheme.domain[index % 10]
      }));

      setTimeout(() => {
        const presentationChart = document.getElementById('on-demand-presentation-chart');
        if (presentationChart) {
          presentationChart.scrollIntoView();
        }
      });
    }
  }

  onSelectPresentation(event) {
    const chosenPresentation = typeof event === 'string' ? event : event.name;
    if (chosenPresentation) {
      this.selectedPresentation = this.selectedSession.presentations
        .find(item => item.title === chosenPresentation);
      this.materialData = this.selectedPresentation?.materials?.map(item => ({ name: item.title, value: item.time_spent }));

      // Initialize legend
      this.materialLegend = this.selectedPresentation?.materials?.map((item, index) => ({
        name: item.title,
        value: this.timeFormatPipe.transform(item.time_spent),
        color: this.chartColorScheme.domain[index % 10]
      }));

      setTimeout(() => {
        const materialChart = document.getElementById('on-demand-material-chart');
        if (materialChart) {
          materialChart.scrollIntoView();
        }
      });
    }
  }

  sessionLegendLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.sessionActiveEntries = this.sessionData.filter(item => item.name === name);
  }

  sessionLegendLabelDeactivate(item: any): void {
    this.sessionActiveEntries = [];
  }

  presentationLegendLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.presentationActiveEntries = this.presentationData.filter(item => item.name === name);
  }

  presentationLegendLabelDeactivate(item: any): void {
    this.presentationActiveEntries = [];
  }

  materialLegendLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.materialActiveEntries = this.materialData.filter(item => item.name === name);
  }

  materialLegendLabelDeactivate(item: any): void {
    this.materialActiveEntries = [];
  }

  onSessionSelectedInLegend(sessionId): void {
    if (sessionId) {
      this.availablePresentations = this.allPresentations.filter(p => p.blockId === parseInt(sessionId));
      this.selectedPresentationInLegend = this.availablePresentations?.[0]?.id || null;
    } else {
      this.availablePresentations = [];
    }
  }

  onPresentationSelectedInLegend(presentationId): void {
    if (presentationId) {
      this.availableMaterials = this.allPresentations.find(p => p.id === parseInt(presentationId))?.materials;
      const webcast = this.availableMaterials?.find(m=> m.type === 'webcast');
      this.selectedMaterialInLegend = webcast?.id || null;
    } else {
      this.availableMaterials = [];
    }
  }

  addTime(selection: { id: number, time: number }) {
    this.delegateService.addConferenceDelegatesAttendanceOnDemand(this.conferenceId, this.delegateId, selection.id, selection.time)
      .subscribe(() => {
        this.getAttendance();
      }, (error) => {
        this.modalService.error({ errors: error });
      });
  }

  removeMaterialAttendance(selectionId: number) {
    this.delegateService.deleteConferenceDelegatesAttendanceOnDemand(this.conferenceId, this.delegateId, selectionId)
      .subscribe(() => {
        this.getAttendance();
      }, (error) => {
        this.modalService.error({ errors: error });
      });
  }

  private getAttendance() {
    this.loadingAttendance = true;
    this.delegateService.getConferenceDelegatesAttendanceOnDemand(this.conferenceId, this.delegateId)
      .subscribe(
        (response: { data: AttendanceSession[] }) => {
          this.attendanceData = response.data;
          this.sessionData = this.attendanceData?.filter(item => item.time_spent)
            .map(item => ({ name: item.name, value: item.time_spent }));

          this.sessionLegend = this.attendanceData?.map((item, index) => ({
            id: item.id,
            name: item.name,
            value: this.timeFormatPipe.transform(item.time_spent),
            color: this.chartColorScheme.domain[index % 10]
          }));

          this.getProgram();
          this.loadingAttendance = false;
        },
        (error) => {
          this.sessionData = [];
          this.loadingAttendance = false;
        }
      );
  }

  private getProgram() {
    this.loadingAttendance = true;
    const params = {
      with_pagination: 0,
    }
    this.blockService.getConferenceBlocks(this.conferenceId, params)
      .subscribe((response) => {
        this.loadingAttendance = false;
        this.availableSessions = response.data
          .map(item => ({ id: item.id, name: item.name }));
        const params2 = {
          with_pagination: 0,
          include: 'materials'
        }
        this.presentationService.getConferencePresentations(this.conferenceId, params2)
          .subscribe((response) => {

          this.allPresentations = response.data
            .map(item => {
              const presentationSession = this.attendanceData
                .find(s => item.block_id === s.id);
              const presentation = presentationSession?.presentations.find(p => p.id === item.id);

              let msDifference = (new Date(item.ends_at)).getTime() - (new Date(item.starts_at)).getTime();
              let duration = Math.floor(msDifference / 1000);

              return {
                id: item.id,
                name: item.title,
                blockId: item.block_id,
                time: presentation?.time_spent || duration,
                materials: item.materials,
                duration
              };
            });
          });
      }, (error) => {
        this.loadingAttendance = false;
        this.modalService.error({ errors: error });
      });
  }
}
