import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class LocationService {

  constructor(
    private apiService: ApiService
  ) { }

  getLocations(params: any = {}) {
    return this.apiService.get(`locations`, params);
  }

  getLocation(locationId: number, params: any = {}) {
    return this.apiService.get(`locations/${locationId}`, params);
  }

  updateLocation(locationId: number, params: any = {}) {
    return this.apiService.put(`locations/${locationId}`, params);
  }

  createLocation(params: any = {}) {
    return this.apiService.post(`locations`, params);
  }

  deleteLocation(locationId: number) {
    return this.apiService.delete(`locations/${locationId}`);
  }

  getConferenceLocations(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/locations`, params);
  }

  getConferenceLocation(conferenceId: number, locationId: any, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/locations/${locationId}`, params);
  }

  updateConferenceLocation(conferenceId: number, locationId: number, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/locations/${locationId}`, params);
  }

  createConferenceLocation(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/locations`, params);
  }

  deleteConferenceLocation(conferenceId: number, locationId: number) {
    return this.apiService.delete(`conferences/${conferenceId}/locations/${locationId}`);
  }

  getConferenceLocationMessages(conferenceId: number, locationId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/locations/${locationId}/messages`, params);
  }

  getConferenceLocationSpeakers(conferenceId: number, locationId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/locations/${locationId}/speakers`, params);
  }

  putConferenceLocationSpeakers(conferenceId: number, locationId: number, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/locations/${locationId}/speakers`, params);
  }

  deleteConferenceLocationSpeakers(conferenceId: number, locationId: number, params: any = {}) {
    return this.apiService.delete(`conferences/${conferenceId}/locations/${locationId}/speakers`, params);
  }

}
