import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ContactService } from '@navus/core/services/contact.service';
import { Conference } from '@navus/core/classes/conference';
import { ConferenceService } from '@navus/core/services/conference.service';

@Component({
  selector: 'main [nv-mobile-app-hub-form-page]',
  templateUrl: './mobile-app-hub-form-page.component.html'
})
export class MobileAppHubFormPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  conferences: Conference[] = [];

  mobileAppHubForm: FormGroup;

  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private modalService: ModalService,
    private contactService: ContactService,
    private conferenceService: ConferenceService,
  ) { }

  ngOnInit() {
    this.initForm();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = +data.organizationId;
        this.conferenceId = +data.conferenceId;

        this.getConferences();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.mobileAppHubForm = this.formBuilder.group({
      message: ['Multi-event App', [Validators.required]],
      options: this.formBuilder.group({
        conference_ids: ['', [Validators.required]]
      }),
    });
  }

  getConferences() {
    this.conferenceService.getOrganizationConferences(this.organizationId, { with_pagination: 0 })
      .subscribe(
        (response: { data: Conference[], meta: any }) => {
          this.conferences = response.data
        },
        () => { }
      );
  }

  submitMobileAppHubForm() {
    this.contactService.createRequest(this.mobileAppHubForm.value)
      .subscribe(
        () => {
          this.modalService.defaultModal({
            title: 'Request submitted',
            body: 'Your request is submitted. Our team will contact you as soon as possible.',
            size: 'small',
            buttons: [
              {
                text: 'Okay',
                color: 'primary',
                role: 'cancel'
              }
            ]
          });

          this.router.navigate(['/o', this.organizationId, 'landing']);
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }

}
