<div class="nv-row gap-30 propagate" *ngIf="value">
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-input
                    type="text"
                    label="Position"
                    [(ngModel)]="value.position"
                    (ngModelChange)="onChange(value)"
            ></nv-input>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-input
                    type="number"
                    label="Conference ID"
                    [(ngModel)]="value.conference_id"
                    (ngModelChange)="onChange(value)"
            ></nv-input>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-checkbox
                    label="Show info"
                    [(ngModel)]="value.showInfo"
                    (change)="onChange(value)"
            ></nv-checkbox>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-input
                    label="Change interval"
                    [(ngModel)]="value.interval"
                    (change)="onChange(value)"
            ></nv-input>
        </div>
    </div>
</div>
