<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Company</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-10">
                <a (click)="delete()" [hidden]="!companyId" class="medium nv-btn secondary" translate>Delete</a>
                <nv-button (click)="close()" color="passive" translate>Close</nv-button>
                <nv-button (click)="save()" [loading]="savingCompany" [disabled]="companyForm.invalid" translate>Save</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav vertical-break-tab">
                <ul>
                    <li>
                        <a class="active">Companies</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'sponsors', 'sponsor-groups']">Sponsor Groups</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'sponsors', 'sponsor-settings']">Sponsor Settings</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
<div class="nv-config-content-port">
    <div class="nv-global-loading on" [class.on]="loadingCompany">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24 small-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form [formGroup]="companyForm" class="nv-row config-plates clean gap-24 small-spaced-top spaced-bottom">
                            <div class="nv-column desk-6 lap-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Basic Information</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="name"
                                                        label="Name"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-4">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        formControlName="sponsor_group_id"
                                                        label="Group"
                                                        [required]="true"
                                                        [options]="companyGroup">
                                                </nv-select>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-8">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="website"
                                                        label="Website"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-file
                                                        formControlName="logo_url"
                                                        label="Logo"
                                                        [organizationId]="organizationId"
                                                        [folderName]="'Sponsors & Exhibitors'"
                                                ></nv-file>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Contact</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="email"
                                                        label="Email"
                                                        type="email"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-6">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="phone"
                                                        label="Phone"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-6">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="fax"
                                                        label="Fax"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Video chat</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-checkbox
                                                        formControlName="allow_video_chat"
                                                        label="Allow video chat"
                                                ></nv-checkbox>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12" *ngIf="companyForm.value.allow_video_chat">
                                            <div class="nv-column-content">
                                                <div translate>Availability</div>
                                                <nv-text-editor
                                                        formControlName="availability"
                                                        label="Availability"
                                                        [organizationId]="organizationId"
                                                ></nv-text-editor>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <ng-container formArrayName="additional_materials">
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <strong>Additional Materials</strong>
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /.nv-column -->                   
                                            <ng-container *ngFor="let item of companyForm.controls.additional_materials['controls']; let indexMaterial = index; trackBy: trackBy">
                                                <ng-container formGroupName="{{indexMaterial}}">
                                                    <div class="nv-column desk-9">
                                                        <div class="nv-column-content">
                                                            <nv-input
                                                                    formControlName="name"
                                                                    label="Name"
                                                            ></nv-input>
                                                            <nv-select
                                                                    style="margin: 20px 0 12px; display: block;"
                                                                    formControlName="type"
                                                                    label="Type"
                                                                    [options]="[{id:'video',name:'Video'},{id:'document',name:'Document'}]"
                                                            ></nv-select>
                                                            <nv-input
                                                                    style="margin: 20px 0 12px; display: block;"
                                                                    formControlName="url"
                                                                    label="URL"
                                                            ></nv-input>
                                                            <nv-file
                                                                    style="margin-left: 20px; display: block;"
                                                                    [hideFilename]="true"
                                                                    type="file"
                                                                    formControlName="url"
                                                                    label="Material"
                                                                    [organizationId]="organizationId"
                                                            ></nv-file>
                                                        </div><!-- /.nv-column-content -->
                                                    </div><!-- /.nv-column -->   
                                                    <div class="nv-column desk-3">
                                                        <div class="nv-column-content">
                                                            <nv-button 
                                                                color="passive"
                                                                size="small"
                                                                iconPl="&#xe918;"
                                                                [onlyIcon]="true"
                                                                (click)="removeMaterial(indexMaterial)"
                                                            ></nv-button>
                                                        </div>
                                                    </div> 
                                                </ng-container>                   
                                            </ng-container>      
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <nv-button class="nv-bnt" size="small" (click)="addMaterial()">Add material</nv-button>
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /.nv-column -->                 
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-6">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Description</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-text-editor
                                                        formControlName="description"
                                                        label="Description"
                                                        [organizationId]="organizationId"
                                                ></nv-text-editor>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-3">
                                            <div class="nv-column-content">
                                                <nv-checkbox
                                                        formControlName="is_exhibitor"
                                                        label="Exhibitor"
                                                        [checked]="companyForm.value.is_exhibitor">
                                                </nv-checkbox>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-9" *ngIf="companyForm.value.is_exhibitor">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="booth_number"
                                                        label="Booth"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Address</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="address"
                                                        label="Address"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-9">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="city"
                                                        label="City"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-3">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="zip"
                                                        label="Zip"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-6">
                                            <div class="nv-column-content">
                                                <nv-select formControlName="country_id"
                                                           label="Country"
                                                           [options]="filteredCountries"
                                                           [showSearch]="true"
                                                           (searched)="filterCountries($event)"
                                                ></nv-select>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-6">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="state"
                                                        label="State"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                    </div>
                                </div>
                            </div>
                        </form>
                        
                        <form [formGroup]="invitationForm" class="nv-row gap-30 propagate">
                            <div class="nv-column desk-6 lap-12">
                                <div class="nv-column-content">
                                    
                                </div>
                            </div>
                            <div class="nv-column desk-6 lap-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Invite moderators</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        
                                        <div class="nv-column desk-9">
                                            <div class="nv-column-content">
                                                <nv-input
                                                    formControlName="email"
                                                    label="Email"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->  
                                        
                                        <div class="nv-column desk-3">
                                            <div class="nv-column-content" style="text-align: right;">
                                                <nv-button 
                                                    (click)="sendInvitation()"
                                                    [loading]="invitingSponsors" 
                                                    [disabled]="invitationForm.invalid" 
                                                    translate
                                                >Send</nv-button>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->    
                                        
                                        <div class="nv-column desk-12 gap-24">
                                            <div class="nv-column-content">
                                                <ng-container *ngFor="let invitation of invitations">
                                                    <div class="nv-row small-spaced">
                                                        <div class="nv-column desk-9">
                                                            <div class="nv-column-content">
                                                                <div>{{ invitation.email }}</div>
                                                                <small>{{ invitation.status }}</small>
                                                            </div>
                                                        </div>
                                                        <div class="nv-column desk-3 v-middle">
                                                            <div class="nv-column-content" style="text-align: right;">
                                                                <nv-button 
                                                                    *ngIf="invitation.status === 'sent'"
                                                                    (click)="cancelInvitation(invitation.id)"
                                                                    size="small"
                                                                    translate
                                                                >Cancel</nv-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->     
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
