export class CustomSectionConfig {
  content: string = '';
  guttered: boolean = true;
  spaced: string = 'spaced';
  background: string = '#FFFFFF';

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
