import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Conference } from '@navus/core/classes/conference';
import { Presentation } from '@navus/core/classes/presentation';
import { PresentationService } from '@navus/core/services/presentation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'main [nv-website-profile-speaker]',
  templateUrl: './speaker.component.html',
})

export class ProfileSpeakerComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-profile-settings-body') get classView() { return true; }
  conference: Conference;
  conferenceId: number;

  myPresentations: Presentation[] = [];
  expandedPresentationId: number = null;
  
  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private presentationService: PresentationService
  ) { }

  ngOnInit() {
    this.conferenceId = this.route.parent?.parent?.parent?.snapshot.data.website?.active_conference_id;
    this.conference = this.route.parent?.parent?.parent?.snapshot.data.website?.conferences.find(c => c.id === this.conferenceId);
  
    this.getMyPresentations();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getMyPresentations() {
    const params = {
      include: 'block,speakers,materials'
    }
    this.presentationService
        .getMyPresentations(this.conferenceId, params)
        .subscribe(
          (response) => {
            this.myPresentations = response.data;
          },
          () => { }
        );
  }

}