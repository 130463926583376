import { BlockCategory } from './block-category';
import { Presentation } from './presentation';
import { Location } from './location';
import { Author } from '@navus/core/classes/author';

export class Block {
  id: number;
  name: string;

  min_access_right: string = 'delegate';

  type: string = 'Oral';
  chairperson: string;
  chairperson_ids: string;
  starts_at: string;
  ends_at: string;
  streamable: boolean;
  subtitle: string;
  time_info: string;
  timeslot: any;

  allow_qa: boolean;

  block_category_name: string;
  block_category_color: string;

  loading: boolean;

  location?: Location;
  location_id: number;
  presentations?: Presentation[];
  chairpersons: Author[];
  block_category?: BlockCategory;
  block_category_id: number;

  participants?: { speaker_id: number, block_id: number, role: string, speaker: Author, status?: string }[];

  startTimestamp?: number;

  imported_id?: number;

  threshold?: number;

  live_cme_points?: number;
  on_demand_cme_points?: number;
  in_person_cme_points?: number;
}
