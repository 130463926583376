import { NgZone, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat',
})
export class TimeFormatPipe implements PipeTransform {

  constructor(private ngZone: NgZone) {
  }

  public transform(value: number): string {
    return this.sToTime(value);
  }

  private sToTime(msRemaining: number): string | null {
    if (msRemaining < 0) {
      return '0h 0m 0s';
    }
    const seconds: string | number = Math.floor((msRemaining) % 60);
    const minutes: string | number = Math.floor((msRemaining / (60)) % 60);
    const hours: string | number = Math.floor((msRemaining / (60 * 60)));

    let formattedTime = '';
    if (hours) {
      formattedTime += `${hours}h`;
    }
    if (minutes) {
      formattedTime += ` ${minutes}m`;
    }
    if (seconds) {
      formattedTime += ` ${seconds}s`;
    }

    return formattedTime;
  }
}
