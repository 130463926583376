import { Package } from './package';

export class Organization {
  id: number;
  name: string;
  logo: string;
  trial_starts_at: string;
  trial_ends_at: string;
  subscription_starts_at: string;
  subscription_ends_at: string;
  subscription_canceled_at: string;

  package_id: string;

  package?: Package;
  contact_info?: {
    country_id: number,
    city: string,
    address_line_1: string,
    address_line_2: string,
    postal_code: string,
  };
  billing_address?: {
    country_id: number,
    city: string,
    address_line_1: string,
    address_line_2: string,
    postal_code: string,
  };
}
