<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Billing & Subscription</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-8">

            </div>
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
</div>
<div class="nv-config-content-port">
    <section class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24">
                <div class="nv-column desk-3 v-top">
                    <div class="nv-column-content">
                        <div class="nv-current-plan" *ngIf="organization?.package">
                            <h3 class="pale">Your Plan</h3>
                            <div class="nv-plan-status">
                                <h4>{{ organization.package.name }}</h4>
                                <p>
                                    <strong>SUBSCRIBED</strong>
                                    <span>Next charge on {{ organization.subscription_ends_at || organization.trial_ends_at | nvDate:'dd.MM.yyyy' }}</span>
                                </p>
                            </div><!-- /.nv-plan-status -->
                            <div class="nv-plan-price" *ngIf="organization.package.period === 'year'">
                                <h4>&euro; {{ organization.package.price / 12 | number:'1.2-2' }}<sub>/ month</sub></h4>
                                <p>billed yearly</p>
                            </div><!-- /.nv-plan-status -->
                            <div class="nv-plan-price" *ngIf="organization.package.period === 'month'">
                                <h4>&euro; {{ organization.package.price }}<sub>/ month</sub></h4>
                                <p>billed monthly</p>
                            </div><!-- /.nv-plan-status -->
                            <!--p class="nv-plan-links">
                                <a>View plan details</a>
                                <br>
                                <a>Switch to annual billing</a>
                            </p-->
                        </div><!-- /.nv-current-plan -->
                        <div class="nv-current-plan" *ngIf="!organization?.package">
                            <h3 class="pale">No plan selected</h3>
                        </div>
                        <div class="nv-btn-box flow-end gap-8">
                            <a class="nv-btn filled small accent1" (click)="selectPackage()">Change plan</a>
                        </div><!-- /.nv-btn-box flow-end gap-6 -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
                <div class="nv-column desk-9">
                    <div class="nv-column-content">
                        <h3 class="pale">Manage Payment Methods</h3>
                        <div class="nv-payment-methods">

                            <div class="pm-plate" *ngFor="let card of cards">
                                <div class="nv-cell-actions">
                                    <span class="nv-cell-action-trigger"></span>
                                    <nav>
                                        <ul>
                                            <li><a>Edit</a></li>
                                            <li><a>Set as primary</a></li>
                                            <li class="delete"><a>Delete Credit Card</a></li>
                                        </ul>
                                    </nav>
                                </div><!-- /nv-cell-actions -->
                                <div class="pm-radio">
                                    <div class="nv-form-item nv-radio-item">
                                        <label>
                                           <span class="nri-radio-port">
                                               <input type="radio" name="type" [checked]="card.default">
                                               <span class="nri-radio-visual"></span>
                                           </span><!-- /.nri-radio-port -->
                                        </label>
                                    </div>
                                </div><!-- /.pm-radio -->
                                <div class="pm-mean">
                                    <h4>Credit card number</h4>
                                    <p><img src="../../assets/visa-logo-web.png" alt="Visa">**** **** **** {{ card.last4 }}</p>
                                </div><!-- /.pm-mean -->
                                <div class="pm-exp">
                                    <h4>EXP. DATE</h4>
                                    <p>{{ card.exp_month }}/{{ card.exp_year }}</p>
                                </div><!-- /.pm-exp -->
                                <div class="pm-primary">
                                    <h4>PRIMARY</h4>
                                    <p>{{ card.default ? 'Yes' : 'No' }}</p>
                                </div><!-- /.pm-primary -->
                            </div><!-- /.pm-plate -->

                        </div><!-- /.nv-payment-methods -->
                        <p>
                            <a (click)="addCard()"><strong>+ Add a card</strong></a>
                        </p>

                        <div class="nv-divider desk-big"></div>
                        <h3 class="pale">Billing Address</h3>
                        <div *ngIf="organization && !billingAddressEdit">
                            <p *ngIf="organization.billing_address">
                                <strong>Country: </strong><span>{{ getCountryName(organization.billing_address.country_id) }}</span><br>
                                <strong>City: </strong><span>{{ organization.billing_address.city }}</span><br>
                                <strong>Postal Code: </strong><span>{{ organization.billing_address.postal_code }}</span><br>
                                <strong>Address:</strong><br>
                                <span>{{ organization.billing_address.address_line_1 }}</span><br>
                                <span>{{ organization.billing_address.address_line_2 }}</span><br>
                                <br>
                                <a (click)="billingAddressEdit = true"><strong>Change Billing Address</strong></a>
                            </p>
                            <p *ngIf="!organization.billing_address">
                                <span>No billing address</span><br>
                                <br>
                                <a (click)="billingAddressEdit = true"><strong>+ Add Billing Address</strong></a>
                            </p>
                        </div>
                        <div *ngIf="billingAddressEdit">
                            <form [formGroup]="billingAddressForm"
                                  class="nv-row config-plates clean gap-24 propagate">
                                <div class="nv-column desk-6">
                                    <div class="nv-column-content">
                                        <nv-select formControlName="country_id"
                                                   label="Country"
                                                   [required]="true"
                                                   [options]="filteredCountries"
                                                   [showSearch]="true"
                                                   (searched)="filterCountries($event)"
                                        ></nv-select>
                                    </div>
                                </div>
                                <div class="nv-column desk-6">
                                    <div class="nv-column-content">
                                        <nv-input
                                                formControlName="city"
                                                label="City"
                                        ></nv-input>
                                    </div>
                                </div>
                                <div class="nv-column desk-6">
                                    <div class="nv-column-content">
                                        <nv-input
                                                formControlName="postal_code"
                                                label="Postal Code"
                                        ></nv-input>
                                    </div>
                                </div>
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <nv-input
                                                formControlName="address_line_1"
                                                label="Address Line 1"
                                        ></nv-input>
                                    </div>
                                </div>
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <nv-input
                                                formControlName="address_line_2"
                                                label="Address Line 2"
                                        ></nv-input>
                                    </div>
                                </div>
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <nv-button (click)="saveBillingAddress()">Save</nv-button>
                                    </div>
                                </div>

                            </form>
                        </div>

                        <div class="nv-divider desk-big"></div>

                        <h3 class="pale">Invoice History</h3>

                        <div *ngIf="invoices.length === 0">Invoices will show here</div>
                        <div class="nv-conifg-table" *ngIf="invoices.length > 0">
                            <table>
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Invoice</th>
                                    <th>Total</th>
                                    <th>Balance</th>
                                    <th>Payment</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let invoice of invoices">
                                    <td>{{ invoice.date | nvDate:'dd.MM.yyyy' }}</td>
                                    <td>
                                        <a [href]="invoice.invoice_pdf">{{ invoice.number }}</a>
                                    </td>
                                    <td>&euro; {{ invoice.amount_due }}</td>
                                    <td>&euro; {{ invoice.amount_paid }}</td>
                                    <td>{{ invoice.paid ? 'Paid' : 'Due' }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div><!-- /nv-config-table -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div>
        </div>
    </section>
</div>
