import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ContactService } from '@navus/core/services/contact.service';
import { OrganizationService } from '@navus/core/services/organization.service';
import { Organization } from '@navus/core/classes/organization';
import { PackageService } from '@navus/core/services/package.service';
import { Package } from '@navus/core/classes/package';

@Component({
  selector: 'main [nv-event-maker-support-form-page]',
  templateUrl: './event-maker-support-form-page.component.html'
})
export class EventMakerSupportFormPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;

  eventMakerSupportForm: FormGroup;
  package: Package;

  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private modalService: ModalService,
    private contactService: ContactService,
    private organizationService: OrganizationService,
    private packageService: PackageService
  ) { }

  ngOnInit() {
    this.initForm();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = +data.organizationId;
        this.conferenceId = +data.conferenceId;
      }
    );
    this.subscriptions.push(subscription);

    const organizationSubscription = this.organizationService.currentOrganization
      .subscribe(
        (organization: Organization) => {
          if (organization) {
            this.packageService.getPackages()
              .subscribe(
                (response: { data: Package[] }) => {
                  for (const package1 of response.data) {
                    if (package1.id === organization.package_id) {
                      this.package = package1;
                    }
                  }
                }
              );
          }
        }
      );
    this.subscriptions.push(organizationSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.eventMakerSupportForm = this.formBuilder.group({
      message: [''],
      options: this.formBuilder.group({
        time: ['', [Validators.required]]
      }),
    });
  }

  submitEventMakerSupportForm() {
    this.contactService.createRequest(this.eventMakerSupportForm.value)
      .subscribe(
        () => {
          this.modalService.defaultModal({
            title: 'Request submitted',
            body: 'Your request is submitted. Our team will contact you as soon as possible.',
            size: 'small',
            buttons: [
              {
                text: 'Okay',
                color: 'primary',
                role: 'cancel'
              }
            ]
          });

          this.router.navigate(['/o', this.organizationId, 'landing']);
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }

}
