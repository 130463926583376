<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Track</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-10">
                <a (click)="delete()" [hidden]="!locationId" class="nv-btn medium secondary" translate>Delete</a>
                <nv-button (click)="close()" color="passive" translate>Close</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
</div>
<div class="nv-config-content-port">
    <div class="nv-global-loading on" [class.on]="loadingLocation">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates small-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-row gap-30">
                            <div class="nv-column desk-6 tab-12">
                                <div class="nv-column-content">
                                    <form [formGroup]="locationForm" class="nv-row config-plates clean gap-24">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <div class="nv-row config-plates clean gap-30 propagate">
                                                    <div class="nv-column desk-12">
                                                        <div class="nv-column-content">
                                                            <h3 translate>Track</h3>
                                                        </div><!-- /.nv-column-content -->
                                                    </div><!-- /.nv-column -->
                                                    <div class="nv-column desk-12 tab-12">
                                                        <div class="nv-column-content">
                                                            <nv-input
                                                                    formControlName="name"
                                                                    label="Track name"
                                                            ></nv-input>
                                                            <div style="margin-top: 15px; text-align: right;">
                                                                <nv-button size="small" (click)="save()" [loading]="savingLocation" [disabled]="locationForm.invalid" translate>Save</nv-button>
                                                            </div>
                                                        </div><!-- /.nv-column-content -->
                                                    </div><!-- /.nv-column -->
                                                    <div class="nv-column desk-12 tab-12">
                                                        <div class="nv-column-content">
                                                            <p>
                                                                Manage this track in our powerful new tool. Studio enables you to manage every aspect of the livestreaming experience.
                                                            </p>
                                                            <div style="text-align: right;">
                                                                <nv-button color="passive" iconPl="&#xe94a;" (click)="openStudio()">Go to the Studio</nv-button>
                                                            </div>
                                                        </div><!-- /.nv-column-content -->
                                                    </div><!-- /.nv-column -->
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="nv-column desk-6 tab-12">
                                <div class="nv-column-content">
                                    <h3>Meeting Room & RTMP</h3>
                                    <div class="nv-column desk-12">
                                        <div class="nv-column-content">
                                            <p>Setup your video conferencing room for this track. Chose to use Navus internal services for live streaming and playing the stream on the stage, or use your own resources.</p>
                                        </div>
                                    </div>
                                        
                                    <ng-container *ngIf="!meetingRoom || meetingRoomEdit">
                                        <form [formGroup]="meetingRoomForm" class="nv-row gap-30 propagate">
                                          <div class="nv-column desk-12">
                                              <div class="nv-column-content">
                                                  <nv-input
                                                        formControlName="title"
                                                        label="Room title"
                                                  ></nv-input>
                                              </div>
                                          </div>
                                          <div class="nv-column desk-12">
                                              <div class="nv-column-content">
                                                  <nv-select
                                                        formControlName="type"
                                                        label="Navus Streaming Service"
                                                        [options]="typeOptions"
                                                  ></nv-select>
                                              </div>
                                          </div>
                                          <div class="nv-column desk-12" *ngIf="meetingRoomForm.value.type === 'external'">
                                              <div class="nv-column-content">
                                                  <nv-input
                                                          formControlName="playback_url"
                                                          label="Playback URL"
                                                  ></nv-input>
                                                  <small>Set your custom playback URL as the main video source on the live stage for this track.</small>
                                              </div>
                                          </div>
                                          <div class="nv-column desk-12" *ngIf="meetingRoomForm.value.type === 'external'">
                                              <div class="nv-column-content">
                                                  <nv-input
                                                          formControlName="rtmp_url"
                                                          label="RTMP URL"
                                                  ></nv-input>
                                              </div>
                                          </div>
                                          <div class="nv-column desk-12" *ngIf="meetingRoomForm.value.type === 'external'">
                                              <div class="nv-column-content">
                                                  <nv-input
                                                          formControlName="rtmp_key"
                                                          label="RTMP Key"
                                                  ></nv-input>
                                              </div>
                                          </div>
                                          <div class="nv-column desk-12">
                                              <div class="nv-column-content">
                                                  <nv-input
                                                        formControlName="max_participants"
                                                        label="Maximum number of participants"
                                                        type="number"
                                                  ></nv-input>
                                                  <small>Limit the number of participants that can join the video conferencing room.</small>
                                              </div>
                                          </div>
                                          <ng-container *ngIf="meetingRoomForm.value.type === 'internal' && meetingRoom">
                                              <div class="nv-column desk-12">
                                                  <div class="nv-column-content" style="text-align: right;">
                                                      <nv-button 
                                                          size="small"
                                                          (click)="createRtmp()" 
                                                          [loading]="creatingMeetingRoomLivestream"
                                                      >
                                                      {{ meetingRoom.rtmp_url ? 'Recreate RTMP' : 'Create RTMP' }}
                                                      </nv-button>
                                                  </div>
                                              </div>
                                          </ng-container>
                                          <div class="nv-column desk-12">
                                                <div class="nv-column-content" style="text-align: right;">
                                                    <nv-button 
                                                        *ngIf="meetingRoom"
                                                        size="small"
                                                        (click)="meetingRoomEdit = false"
                                                    >Cancel</nv-button>
                                                    &nbsp;
                                                    <nv-button 
                                                        size="small"
                                                        (click)="saveMeetingRoom()"
                                                        [loading]="savingMeetingRoom"
                                                    >Save</nv-button>
                                              </div>
                                          </div>
                                        </form>
                                    </ng-container>
                                    <div class="nv-qr-box" *ngIf="meetingRoom && !meetingRoomEdit">
                                        <div class="nv-row">
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <h4>{{ meetingRoom.title }}</h4>
                                                </div>
                                            </div>
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content" style="text-align: left;">
                                                    <span ong>Recording: </span>
                                                    <strong>{{ meetingRoom.recording }}</strong>
                                                </div>
                                            </div>
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content" style="text-align: left;">
                                                    <span>Maximum number of participants: </span>
                                                    <strong>{{ meetingRoom.max_participants }}</strong>
                                                </div>
                                            </div>
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content" style="text-align: left;">
                                                    <span ong>Playback URL: </span>
                                                    <strong>{{ meetingRoom.playback_url }}</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="nv-row gap-30 propagate" style="margin-top: 30px;">
                                            <div class="nv-column desk-12" *ngIf="meetingRoom.rtmp_url">
                                                <div class="nv-column-content">
                                                    <nv-input
                                                            label="RTMP URL"
                                                            [value]="meetingRoom.rtmp_url"
                                                            [readOnly]="true"
                                                    ></nv-input>
                                                </div>
                                            </div>
                                            <div class="nv-column desk-12" *ngIf="meetingRoom.rtmp_key">
                                                <div class="nv-column-content">
                                                    <nv-input
                                                            label="RTMP Key"
                                                            [value]="meetingRoom.rtmp_key"
                                                            [type]="'password'"
                                                            [showPassword]="true"
                                                            [readOnly]="true"
                                                    ></nv-input>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="nv-row">
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content" style="text-align: right;">
                                                    <ng-container *ngIf="meetingRoom?.type === 'internal'">
                                                        <nv-button 
                                                            size="small"
                                                            (click)="createRtmp()" 
                                                            [loading]="creatingMeetingRoomLivestream"
                                                        >
                                                        {{ meetingRoom.rtmp_url ? 'Recreate RTMP' : 'Create RTMP' }}
                                                        </nv-button>
                                                        &nbsp;
                                                    </ng-container>
                                                    <nv-button 
                                                        size="small"
                                                        (click)="copyRoomURL()"
                                                    >Copy room URL</nv-button>
                                                    &nbsp;
                                                    <nv-button 
                                                        size="small"
                                                        (click)="meetingRoomEdit = true"
                                                    >Edit</nv-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
    </div>
</div>
