<header *ngIf="sponsor" class="nv-section guttered nv-single-detail-header"  [style.background-color]="sponsor.sponsor_group?.color">
    <div class="nv-port">
        <div class="nv-row v-stretch large-spaced-top semi-spaced-bottom">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <h1>{{ sponsor.sponsor_group?.name }}</h1>
                </div><!-- /.nv-column-content -->
            </div><!-- /nv-column -->
        </div><!-- /.nv-row -->
        <a *ngIf="conferenceId != 8348" class="nv-sdh-back" [routerLink]="['/sponsors']">Sponsors</a>
    </div><!-- /.nv-port -->
</header><!-- /.nv-section -->
<main *ngIf="sponsorId === 154065 && !currentUser" class="nv-section guttered nv-single-detail-body">
    <div style="padding: 50px 0;">
        <h5 style="text-align: center;">
            This page is restricted. Please <a [routerLink]="['/profile', 'login']">log in</a> to continue.
        </h5>   
    </div>
</main>
<main *ngIf="sponsor && canAccess" class="nv-section guttered nv-single-detail-body" [formGroup]="sponsorForm">
    <span class="nv-single-stripe"  [style.background-color]="sponsor.sponsor_group?.color"></span>
    <div class="nv-port">
        <div class="nv-row v-stretch">
            <div class="nv-column desk-12">
                <div class="nv-column-content" [class.edit-mode]="showEditMode">
                    <div style="text-align: right;margin-bottom: 20px;" *ngIf="owner">
                        <nv-button [size]="'small'" *ngIf="!showEditMode" (click)="editModeToggle()">Edit</nv-button>
                        <nv-button style="margin-right: 10px;" [color]="'passive'" [size]="'small'" *ngIf="showEditMode" (click)="editModeToggle()">Close</nv-button>
                        <nv-button [size]="'small'" *ngIf="showEditMode" [disabled]="sponsorForm.invalid" (click)="save()">Save</nv-button>
                    </div>
                    <div class="nv-single-detail-body-header">
                        <div class="sdbh-logo" *ngIf="!showEditMode">
                            <span nvBackgroundImage [image]="sponsor.logo_url"></span>
                        </div><!-- /.sdbh-logo -->
                        <nv-file
                            *ngIf="showEditMode"
                            [organizationId]="organizationId"
                            [folderName]="'Sponsors/' + sponsorId"
                            [mime]="['image/png','image/jpg','image/jpeg']"
                            label="Sponsor photo"
                            formControlName="logo_url"
                        ></nv-file>
                        <div class="sdbh-txt">
                            <h2 *ngIf="!showEditMode">{{ sponsor?.name }}</h2>
                            <nv-input *ngIf="showEditMode" formControlName="name"></nv-input>
                            <!-- <nv-checkbox
                                *ngIf="showEditMode"
                                formControlName="allow_video_chat"
                                label="Allow video chat"
                            ></nv-checkbox> -->
                            <ng-container *ngIf="sponsor.allow_video_chat">
                                <!-- <div class="sdh-btn" >
                                    <a (click)="getAccess()" *ngIf="sponsor.allow_video_chat && (!currentUser || (!currentDelegate && !owner))" class="nv-btn medium accent2">
                                        {{videoButtonLabel}}</a>
                                </div> -->
                                    <div *ngIf="showEditMode">&nbsp;</div>
                                    <!--Talk to the sponsor-->
                                    <div class="sponsor-meeting" [class.on]="showMeeting">
                                        <nv-meeting *ngIf="(owner || currentDelegate)" #meeting
                                            [entityType]="'Sponsor'"
                                            [entityId]="sponsorId"
                                            [currentUser]="currentUser"
                                            [conferenceId]="conferenceId"
                                            [joinButtonText]="videoButtonLabel"
                                            [createDisabled]="!owner"
                                            (joined)="meetingJoined()"
                                            (closing)="meetingClosed()"
                                            (meetingRoomChange)="meetingRoomChange($event)"
                                        ></nv-meeting>                                        
                                    </div>

                                    &nbsp;&nbsp;
                                <div class="ph-meta">
                                    <strong><span class="phm-time-frame">Availability:</span> 
                                        <br> <br> 
                                        <span *ngIf="!showEditMode" [innerHTML]="sponsor.availability | nvHtml"><br></span>
                                        <nv-text-editor *ngIf="showEditMode" formControlName="availability" style="width: 99%;"></nv-text-editor>
                                    </strong>
                                </div>
                            </ng-container>
                        </div><!-- /.sdbh-txt -->
                        <ng-container *ngIf="false">
                            <div class="sdbh-txt">
                                <span class="sdh-label">SPONSOR OF THE SESSION</span>
                                <p class="sdh-link"><a href class="nv-external-link">Session Name</a></p>
                            </div><!-- /.sdbh-txt -->
                            <div class="sdh-btn" >
                                <a class="nv-btn medium accent1 radius-high">Subscribe</a>
                            </div><!-- /.sdh-btn -->
                        </ng-container>
                    
                    </div><!-- /.nv-single-detail-body-header -->
                    <div class="nv-row gap-50 spaced-bottom" *ngIf="sponsor">
                        <div class="nv-column desk-8 tab-12">
                            <div class="nv-column-content">
                                <div *ngIf="sponsor.description && !showEditMode" [innerHTML]="sponsor.description | nvHtml"></div><!-- /.nv-column-content -->
                                <nv-text-editor *ngIf="showEditMode" formControlName="description" style="width: 100%;"></nv-text-editor>
                                <ng-container *ngIf="!sponsor.description">
                                    <h5 class="nv-classs-heading">No additional description</h5>
                                </ng-container><!-- /.nv-column-content -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column desk-8 -->
                        <div class="nv-column desk-4 tab-12">
                            <div class="nv-column-content">
                                <div class="nv-divider tab-medium"></div>  

                                <ng-container formArrayName="additional_materials" *ngIf="showEditMode">
                                    <h5>Access materials:</h5>
                                    <ng-container *ngFor="let item of sponsorForm.controls.additional_materials['controls']; let indexMaterial = index; trackBy: trackBy">
                                        <div class="nv-row gap-10 spaced-top" formGroupName="{{indexMaterial}}">
                                            <div class="nv-column desk-9">
                                                <div class="nv-column-content">
                                                    <nv-input
                                                            formControlName="name"
                                                            label="Name"
                                                    ></nv-input>
                                                    <nv-select
                                                            style="margin: 20px 0 12px; display: block;"
                                                            formControlName="type"
                                                            label="Type"
                                                            [options]="[{id:'video',name:'Video'},{id:'document',name:'Document'}]"
                                                    ></nv-select>
                                                    <nv-input
                                                            style="margin: 20px 0 12px; display: block;"
                                                            formControlName="url"
                                                            label="URL"
                                                    ></nv-input>
                                                    <nv-file
                                                            style="margin-left: 20px; display: block;"
                                                            [hideFilename]="true"
                                                            type="file"
                                                            formControlName="url"
                                                            label="Material"
                                                            [folderName]="'Sponsors/' + sponsorId"
                                                            [organizationId]="organizationId"
                                                    ></nv-file>
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /.nv-column -->   
                                            <div class="nv-column desk-3">
                                                <div class="nv-column-content">
                                                    <nv-button 
                                                        color="passive"
                                                        size="small"
                                                        iconPl="&#xe918;"
                                                        [onlyIcon]="true"
                                                        (click)="removeMaterial(indexMaterial)"
                                                    ></nv-button>
                                                </div>
                                            </div> 
                                        </div>                   
                                    </ng-container>    
                                    <div class="nv-row semi-spaced-top spaced-bottom">
                                        <div class="nv-column desk-12">      
                                            <div class="nv-column-content">
                                                <nv-button class="fw" (click)="addMaterial()">Add material</nv-button>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->                                          
                                    </div>
                                </ng-container>


                                <ng-container *ngIf="!showEditMode && sponsor.additional_materials?.length > 0">
                                    <h5>Access materials:</h5>
                                    <nav class="nv-promo-mat-list">
                                        <ul>
                                            <li *ngFor="let additionalMaterial of sponsor.additional_materials">
                                                <a (click)="handleAdditionalMaterial(additionalMaterial)">
                                                    <span *ngIf="additionalMaterial.type !== 'video'"><img src="../../../../assets/ico-material-pdf.svg" [alt]="additionalMaterial.name"></span>
                                                    <span *ngIf="additionalMaterial.type === 'video'"><img src="../../../../assets/ico-material-mp-4.svg" [alt]="additionalMaterial.name"></span>
                                                    {{ additionalMaterial.name }}                                                     
                                                    <span *ngIf="showEditMode" class="nv-btn-close" style="position: absolute; right: 10px;" (click)="removeMaterial($event, additionalMaterial)"></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav><!-- /.nv-promo-mat-list -->
                                </ng-container>

                                <ng-container *ngIf="conferenceId === 8303 || conferenceId === 8301 || conferenceId === 8200">
                                    <div class="nv-chairs-list" *ngIf="false">
                                        <h5>Sponsor contacts:</h5>
                                        <ul>
                                            <li>
                                                <span class="nv-image-target nv-image-loaded" style="background-image: url(&quot;https://service.e-materials.com/storage/resources/jpl_media/spe/speaker1.jpg&quot;);"></span>
                                                <div><strong><a>Jennifer Prisk</a></strong><br>Marketing Director</div>
                                            </li>
                                            <li>
                                                <span class="nv-image-target nv-image-loaded" style="background-image: url(&quot;https://service.e-materials.com/storage/resources/jpl_media/spe/speaker3f.jpg&quot;);"></span>
                                                <div><strong><a>Jonathan  Grigg</a></strong><br>Sales Representative</div>
                                            </li>
                                            <li>
                                                <span class="nv-image-target nv-image-loaded" style="background-image: url(&quot;https://service.e-materials.com/storage/resources/jpl_media/spe/speaker2.jpg&quot;);"></span>
                                                <div><strong><a>Gregg Ruppel</a></strong><br>Business Developer</div>
                                            </li>
                                        </ul>
                                    </div>
                                    <h5>Accessible promo material:</h5>

                                    <nav class="nv-promo-mat-list">
                                        <ul>

                                            <li>
                                                <a href>
                                                    <span><img src="../../../../assets/ico-material-mp-4.svg" alt="Mp4 file"></span>
                                                    Promo video
                                                </a>
                                            </li>
                                            <li>
                                                <a href>
                                                    <span><img src="../../../../assets/ico-material-pdf.svg" alt="PDF file"></span>
                                                    Brochure
                                                </a>
                                            </li>
                                            <li>
                                                <a href>
                                                    <span><img src="../../../../assets/ico-material-ppt.svg" alt="PPT file"></span>
                                                    PPT presentation
                                                </a>
                                            </li>
                                        </ul>
                                    </nav><!-- /.nv-promo-mat-list -->
                                </ng-container>
                                <h5>Info</h5>
                                <ng-container *ngIf="sponsor">
                                    <p *ngIf="sponsor.is_exhibitor && sponsor.booth_number">
                                        Exhibitor booth number:<br>
                                        <strong>{{ sponsor.booth_number }}</strong>
                                    </p>
                                    <p *ngIf="sponsor.is_exhibitor && sponsor.floor">
                                        Floor:<br>
                                        <strong>{{ sponsor.floor }}</strong>
                                    </p>
                                    <p *ngIf="sponsor.website && !showEditMode">
                                        Website:<br>
                                        <strong>
                                            <span *ngIf="!sponsor.website">-</span>
                                            <a *ngIf="sponsor.website" [href]="sponsor.website" target="_blank">{{ sponsor.website }}</a>
                                        </strong>
                                    </p>
                                    <div *ngIf="showEditMode" >
                                        <nv-input [label]="'Website'" formControlName="website"></nv-input>
                                        <br>
                                    </div>
                                    <p *ngIf="sponsor.email && !showEditMode">
                                        Email:<br>
                                        <strong><a [href]="'mailto:' + sponsor.email">{{ sponsor.email }}</a></strong>
                                    </p>
                                    <div *ngIf="showEditMode" >
                                        <nv-input [label]="'Email'" formControlName="email"></nv-input>
                                        <br>
                                    </div>
                                    <p *ngIf="sponsor.phone && !showEditMode">
                                        Phone:<br>
                                        <strong><a [href]="'tel:' + sponsor.phone">{{ sponsor.phone }}</a></strong>
                                    </p>
                                    <div *ngIf="showEditMode" >
                                        <nv-input [label]="'Phone'" formControlName="phone"></nv-input>
                                        <br>
                                    </div>
                                    <p *ngIf="(sponsor.address || sponsor.city || sponsor.country) && !showEditMode">
                                        Address:<br>
                                        <strong *ngIf="sponsor.address">{{ sponsor.address }}<br></strong>
                                        <strong *ngIf="sponsor.city || sponsor.state || sponsor.zip">
                                            <ng-container *ngIf="sponsor.city">{{ sponsor.city }}</ng-container>
                                            <ng-container *ngIf="sponsor.city && (sponsor.state || sponsor.zip)">,</ng-container>
                                            <ng-container *ngIf="sponsor.state"> {{sponsor.state}}</ng-container>
                                            <ng-container *ngIf="sponsor.zip"> {{sponsor.zip}}</ng-container>
                                            <br>
                                        </strong>
                                        <strong *ngIf="sponsor.country">{{ sponsor?.country?.name }}</strong>
                                    </p>
                                    <div *ngIf="showEditMode">
                                        <div *ngIf="sponsor.address">
                                            <nv-input [label]="'Address'" formControlName="address"></nv-input>
                                            <br>
                                        </div>
                                        <div *ngIf="sponsor.city || sponsor.state || sponsor.zip">
                                            <nv-input [label]="'City'" formControlName="city"></nv-input>
                                            <br>
                                            <nv-input [label]="'State'" formControlName="state"></nv-input>
                                            <br>
                                            <nv-input [label]="'Zip'" formControlName="zip"></nv-input>
                                        </div>
                                    </div>
                                </ng-container>
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column desk-8 -->
                    </div><!-- /.nv-row -->
                    <div class="nv-divider desk-large"></div>
                </div><!-- /.nv-column-content -->
            </div><!-- /nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</main><!-- /.nv-section -->
