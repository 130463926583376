
<div class="nv-page-wrap">
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 large-spaced">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">

                        <div class="nv-msg-block error">
                            <p>Quo assum repudiandae an, ei eam brute causae. Per erant voluptua te. t falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, mucius melius latine nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram.</p>
                        </div><!-- /.nv-msg-block -->

                        <div class="nv-msg-block warning">
                            <p>Quo assum repudiandae an, ei eam brute causae. Per erant voluptua te. t falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, mucius melius latine nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram.</p>
                        </div><!-- /.nv-msg-block -->

                        <div class="nv-msg-block success">
                            <p>Quo assum repudiandae an, ei eam brute causae. Per erant voluptua te. t falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, mucius melius latine nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram.</p>
                        </div><!-- /.nv-msg-block -->

                        <div class="nv-msg-block info">
                            <p>Quo assum repudiandae an, ei eam brute causae. Per erant voluptua te. t falli facilisis patrioque mel, pri ei alienum epicurei. Te vero ocurreret consequuntur sed, mucius melius latine nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto velit electram.</p>
                        </div><!-- /.nv-msg-block -->

                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </div><!-- /.nv-section -->

    <div class="nv-side-toast top-right on">
        <div class="nv-msg-block error">
            <p>Quo assum repudiandae an, ei eam brute causae. Per erant voluptua te. Falli facilisis patrioque mel, pri ei alienum epicurei.</p>
        </div><!-- /.nv-msg-block -->

        <div class="nv-msg-block info">
            <p>Te vero ocurreret consequuntur sed, mucius melius latine nam cu. Noster neglegentur ei ius, quo alterum urbanitas referrentur an, duo in purto.</p>
        </div><!-- /.nv-msg-block -->
    </div><!-- /.nv-side-toast -->
</div><!-- /.nv-page-wrap -->
