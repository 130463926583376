<div class="nv-modal small">
    <div class="nv-modal-header">
        <h4 class="nv-modal-title" translate>Event Access</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body">
        <div class="nv-row v-stretch gap-30 propagate">
            <div class="nv-column desk-12 phab-12">
                <div class="nv-column-content">
                    <p translate>{{ text }}</p>
                    <ng-container *ngIf="!hideCode">
                        <nv-input [(ngModel)]="accessCode" (change)="errorCode = false"
                                label="PIN / Badge Number / Access Code"></nv-input>
                        <div *ngIf="errorCode" style="position: relative">
                            <div class="nv-invalid-feedback">
                                <div class="nif-item" translate>Code not found.</div>
                            </div>
                            &nbsp;
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <nv-button *ngIf="!hideCode" (click)="applyCode()"
                               [loading]="loading"
                               [disabled]="accessCode === ''"
                               translate>OK</nv-button>
                    &nbsp;
                    <nv-button *ngIf="!hideButton" (click)="purchaseTicket();" color="accent1" translate>{{buttonLabel}}</nv-button>&nbsp;
                    <nv-button (click)="close();" color="passive" translate>Cancel</nv-button>
                </div>
            </div>
        </div>
    </div>
</div>
