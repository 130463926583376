import { Component } from '@angular/core';

@Component({
    selector: 'data-room-page',
    templateUrl: './data-room.component.html'
})
export class DataRoomComponent {

    constructor() { }

}
