import { Component } from '@angular/core';

@Component({
    selector: 'app-onboarding-update-page',
    templateUrl: './onboarding-update.component.html'
})
export class OnboardingUpdateComponent {

    constructor() { }

}
