<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Session Categories</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-8">
                <nv-button color="primary" iconPl="&#xe095;" (click)="add()">Add session category</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port spaced-bottom">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav vertical-break-tab">
                <ul>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program']">Program</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program', 'presentations']">Presentations</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program', 'blocks']">Sessions</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program', 'locations']">Locations</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <form class="config-search-bar-w-filters" [formGroup]="formFilter" autocomplete="off">
        <div class="sbwf-port">
            <input type="text" placeholder="Search..."
                   formControlName="search_term" (change)="getBlockCategories()">
            <span class="sbwf-outline"></span>
        </div><!-- /.sbwf-port -->
    </form><!-- /.config-search-bar-w-filters -->
</div>
<div class="nv-config-content-port" nvInfiniteScroll (scrolled)="getBlockCategories(true)">
    <div class="nv-global-loading on" [class.on]="blockCategoriesLoading">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <section class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates">
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content">
                        <div *ngIf="pagination.total === 0">
                            <div class="nv-empty-state">
                                <div class="img">
                                    <img src="../../../../assets/empty-states/empty-state-categories.svg" alt="">
                                </div>
                                <h4 class="title">No session categories!</h4>
                                <p class="text">Once you add session category, you'll see them here.</p>
                                <nv-button (click)="add()">Add session category</nv-button>
                            </div>
                        </div>
                        <table class="nv-data-table break-lap">
                            <tr *ngFor="let blockCategory of blockCategories">
                                <td class="di-avatar nv-clickable" (click)="edit(blockCategory)">
                                    <span [style.background]="blockCategory.color"></span>
                                </td><!-- /.dt-cell -->
                                <td class="dt-cell nv-clickable" (click)="edit(blockCategory)">
                                    <label>NAME</label>
                                    <strong>{{ blockCategory.name }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-actions">
                                    <div class="nv-cell-actions">
                                    <span class="nv-cell-action-trigger"
                                          [class.on]="optionsShown === blockCategory.id"
                                          (click)="openOptions(blockCategory)"></span>
                                        <nav nvOffClick (offClick)="closeOptions()">
                                            <ul>
                                                <li (click)="edit(blockCategory)">
                                                    <a>Edit</a>
                                                </li>
                                                <li class="delete" (click)="delete(blockCategory)">
                                                    <a>Delete</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div><!-- /.nv-cell-actions -->
                                </td>
                            </tr>
                        </table>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->
</div><!-- /.config-layout-lower-fixed -->
