import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { ModalService } from '@navus/ui/modal/modal.service';
import { PaginationConfig } from '@navus/ui/pagination/classes/pagination-config';
import { Conference } from '@navus/core/classes/conference';
import { WebsiteService } from '@navus/core/services/website.service';
import { ConferenceListSectionConfig } from './conference-list-section-config';
import { BaseSectionComponent } from '../_base/base-section.component';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { WebsiteControlService } from '../../services/website-control.service';

@Component({
  selector: 'nv-conference-list-section',
  templateUrl: './conference-list-section.component.html'
})
export class ConferenceListSectionComponent extends BaseSectionComponent implements OnInit, OnDestroy {
  @Input() data: WebsitePageSection;
  configuration: ConferenceListSectionConfig = new ConferenceListSectionConfig();

  hover: boolean = false;
  conferences: Conference[];
  pagination: PaginationConfig = {
    current_page: 1,
    per_page: 8
  };

  conferencesSliderConfig = {
    'slidesToShow': 6,
    'autoplay': false,
    'slidesToScroll': 6,
    'infinite': false,
    'dots': false,
    'centerMode': false,
    'adaptiveHeight': true,
    'arrows': true,
    'prevArrow': '<a class="er-prev"></a>',
    'nextArrow': '<a class="er-next"></a>'
  };

  loadingConferences: boolean;

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService,
    private router: Router,
    private _sanitizer: DomSanitizer
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );
  }

  ngOnInit() {
    this.configuration = new ConferenceListSectionConfig(this.data.configuration);
    this.getConferences();
  }

  ngOnDestroy() {

  }

  getConferences() {
    this.loadingConferences = true;
    this.websiteService.getWebsiteConferences()
      .subscribe(
        (response: Conference[]) => {
          this.conferences = response.sort((conference1: Conference, conference2: Conference) => {
            if (conference1.type > conference2.type) {
              return 1;
            }

            if (conference1.type < conference2.type) {
              return -1;
            }

            if (conference1.type === conference2.type) {
              if (conference1.starts_at < conference2.starts_at) {
                return 1;
              }

              if (conference1.starts_at > conference2.starts_at) {
                return -1;
              }
            }

            return 0;
          });

          if (this.configuration.limit !== 0) {
            this.conferences = this.conferences.splice(0, this.configuration.limit);
          }

          this.loadingConferences = false;
        },
        () => {
          this.loadingConferences = false;
        }
      );
  }

  goToConferencePresentations(conference: Conference) {
    this.router.navigate(['search'], { queryParams: { conferences: conference.id }});
  }

  paginationChange(paginationConfig: PaginationConfig) {
    this.pagination.current_page = paginationConfig.current_page;
    this.getConferences();
  }
}
