<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Website</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-8">
                <nv-button color="primary" iconPl="&#xe910;"
                           [loading]="savingWebsite"
                           [disabled]="websiteForm.invalid"
                           [hidden]="!websiteId"
                           (click)="save()" translate>Save</nv-button>
                &nbsp;
                <nv-button color="primary" (click)="delete()" [hidden]="!websiteId" translate>Delete</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div>
</div>
<div class="nv-config-content-port">
    <div class="nv-global-loading on" [class.on]="websiteLoading">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <div class="nv-section" *ngIf="website && websiteId">
        <div class="nv-port">
            <div class="nv-row gap-24 config-plates spaced-bottom clean v-stretch">
                <div class="nv-column desk-6 lap-12">
                    <div class="nv-column-content">
                        <div class="nv-event-app-overview">
                            <h3 class="medium">Your Event Website Overview:</h3>
                            <div class="eao-content">
                                <div class="eao-meta">
                                    <div class="eao-top-right">
                                        <nv-button size="small"
                                                   [loading]="loadingPublish"
                                                   (click)="togglePublished()" translate
                                        >{{ website.published ? 'Unpublish' : 'Publish' }}</nv-button>
                                    </div><!-- /.eao-top-right -->
                                    <div class="eao-chunk">
                                        <label>PUBLISH STATE</label>
                                        <p>{{ website.published ? 'Published' : 'Unpublished' }}</p>
                                    </div><!-- /.eao-chunk -->
                                    <div class="eao-chunk">
                                        <label>DOMAIN</label>
                                        <p>
                                            <strong *ngIf="conferenceId !== '20369'">
                                                <a [href]="'https://' + website.subdomain + '.' + website.domain" target="_blank">{{ website.subdomain + '.' + website.domain }}</a>
                                            </strong>
                                            <strong *ngIf="conferenceId === '20369'">
                                                <a [href]="'https://conference.pugchallenge.eu'" target="_blank">conference.pugchallenge.eu</a>
                                            </strong>
                                        </p>
                                    </div><!-- /.eao-chunk -->
                                    <div class="eao-chunk">
                                        <label>WEBSITE TITLE</label>
                                        <p>{{ website.name }}</p>
                                    </div><!-- /.eao-chunk -->
                                    <div class="eao-chunk">
                                        <label>DEFAULT LANGUAGE</label>
                                        <p *ngIf="website.default_language === 'es'">Spanish</p>
                                        <p *ngIf="website.default_language === 'en'">English</p>
                                        <p *ngIf="website.default_language !== 'en' && website.default_language === 'es'">{{ website.default_language }}</p>
                                    </div><!-- /.eao-chunk -->
                                    <div class="eao-chunk logo">
                                        <label>WEBSITE BRANDING</label>
                                        <p *ngIf="website.logo && website.logo !== ''"><img [src]="website.logo" alt></p>
                                        <p *ngIf="!website.logo || website.logo === ''">
                                            <a (click)="websiteLogo.openResourceModal($event)">Set logo</a>
                                        </p>
                                    </div><!-- /.eao-chunk -->
                                    <div class="eao-chunk">
                                        <label>EVENT DESCRIPTION</label>
                                        <p [innerHtml]="website.description | nvHtml"></p>
                                    </div><!-- /.eao-chunk -->
                                    <div class="eao-chunk">
                                        <label>WEBSITE TEMPLATE</label>
                                        <p *ngIf="website.theme">{{ website.theme.replace('-', ' ') | titlecase }}</p>
                                    </div><!-- /.eao-chunk -->
                                    <div class="eao-chunk">
                                        <nv-button size="small"
                                                   (click)="editActive = !editActive"
                                        >{{ !editActive ? 'Show website edit' : 'Hide website edit' }}</nv-button>
                                    </div><!-- /.eao-chunk -->
                                </div><!-- /.eao-meta -->
                            </div><!-- /.eao-content -->
                        </div><!-- /.nv-event-app-overview -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->

                <div class="nv-divider desk-none lap-medium"></div>

                <div class="nv-column desk-6 lap-12">
                    <div class="nv-column-content">
                        <div class="nv-event-app-preview">
                            <div class="nv-qr-box">
                                <h4>Preview your event website</h4>
                                <ng-container *ngIf="conferenceId !== '20369'">
                                    <p>Event website URL: <a [href]="'https://' + website.subdomain + '.' + website.domain" target="_blank">{{ website.subdomain + '.' + website.domain }}</a></p>
                                </ng-container>
                                <ng-container *ngIf="conferenceId === '20369'">
                                    <p>Event website URL: <a [href]="'https://conference.pugchallenge.eu'" target="_blank">conference.pugchallenge.eu</a></p>
                                </ng-container>
                                <div class="nv-row gap-12 flow-center propagate">
                                    <div class="nv-column desk-initial tab-12">
                                        <nv-button (click)="editWebsite()" color="passive" iconPl="&#xe959;">Edit your event website</nv-button>
                                    </div><!-- /.nv-column -->
                                    <div class="nv-column desk-initial tab-12">
                                        <div class="nv-column-content">
                                            <nv-button (click)="previewWebsite()" color="primary">Preview</nv-button>
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                </div><!-- /.nv-row -->
                            </div><!-- /.nv-qr-box -->

                            <div class="nv-divider desk-medium"></div>

                            <h4>Add banner ads space to your website</h4>
                            <p>
                                <img src="../../../../assets/desktop-homepage-banner.jpg" alt>
                            </p>
                            <p>
                                Upgrade for only <strong>€4.90.- per attendee</strong> for this event.<br>
                                Upgrade by contacting your Navus Eventer <a [routerLink]="['/o', organizationId, 'contact', 'event-maker-support']">here</a>.
                            </p>
                        </div><!-- /.nv-event-app-preview -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div>
    </div>
    <div class="nv-section" *ngIf="!website || !websiteId">
        <div class="nv-port">
            <div class="nv-row config-plates clean">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form [formGroup]="websiteForm">
                            <nv-accordions>
                                <nv-accordion [title]="'Setup website name, subdomain & publication date'"
                                              (next)="step1done = true" [edit]="!!websiteId" [active]="!websiteId">
                                    <div class="nv-row large-spaced flow-center">
                                        <div class="nv-column desk-8 tab-12">
                                            <div class="nv-column-content">
                                                <div class="nv-row config-plates clean gap-30 propagate">
                                                    <div class="nv-column desk-12">
                                                        <div class="nv-column-content">
                                                            <nv-input
                                                                    formControlName="name"
                                                                    label="Website title"
                                                                    required
                                                            ></nv-input>
                                                        </div>
                                                    </div>
                                                    <div class="nv-column desk-12">
                                                        <div class="nv-column-content">
                                                            <nv-input
                                                                    formControlName="subdomain"
                                                                    suffix=".navus.io"
                                                                    label="Domain"
                                                                    [errorMessages]="{ 'domain_taken': 'Domain is already taken.' }"
                                                                    required
                                                            ></nv-input>
                                                        </div>
                                                    </div>
                                                    <div class="nv-column desk-6">
                                                        <div class="nv-column-content">
                                                            <nv-select
                                                                    [options]="languageOptions"
                                                                    formControlName="default_language"
                                                                    label="Default language"
                                                            ></nv-select>
                                                        </div>
                                                    </div>
                                                    <div class="nv-column desk-12">
                                                        <div class="nv-column-content">
                                                            <ng-container formGroupName="settings">
                                                                <nv-input
                                                                        type="textarea"
                                                                        formControlName="google_analytics_code"
                                                                        label="Google Analytics Code"
                                                                ></nv-input>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </nv-accordion>
                                <nv-accordion [title]="'Placeholder page - Coming soon'"
                                              (next)="step2done = true" [edit]="!!websiteId">
                                    <div class="nv-row large-spaced flow-center">
                                        <div class="nv-column desk-8 tab-12">
                                            <div class="nv-column-content">
                                                <div class="nv-row config-plates clean gap-30 propagate">
                                                    <div class="nv-column desk-12">
                                                        <div class="nv-column-content">
                                                            <h4>Event branding for the website</h4>
                                                            <nv-file #websiteLogo
                                                                     [organizationId]="organizationId"
                                                                     formControlName="logo"
                                                                     label="Logo"
                                                            ></nv-file>
                                                        </div>
                                                    </div> 
                                                    <div class="nv-column desk-12">
                                                        <div class="nv-column-content">
                                                            <nv-file #websiteFavicon
                                                                     [organizationId]="organizationId"
                                                                     formControlName="favicon"
                                                                     label="Favicon"
                                                            ></nv-file>
                                                        </div>
                                                    </div>
                                                    <div class="nv-column desk-12">
                                                        <div class="nv-column-content">
                                                            Let your visitors know that the full version of your website is in the works.
                                                            Add event description and click on "Preview" button to preview your event
                                                            coming soon page.
                                                        </div>
                                                    </div>
                                                    <div class="nv-column desk-12">
                                                        <div class="nv-column-content">
                                                            <nv-text-editor
                                                                formControlName="description"
                                                                label="Event description"
                                                                required
                                                            ></nv-text-editor>
                                                        </div>
                                                    </div>
                                                    <div class="nv-column desk-12">
                                                        <div class="nv-column-content">
                                                            <nv-button (click)="showPlaceholderPreview()">Preview</nv-button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </nv-accordion>
                                <!--nv-accordion [title]="'Pick a website template'"
                                              (next)="step3done = true" [edit]="!!websiteId">
                                    <div class="nv-row large-spaced flow-center">
                                        <div class="nv-column desk-12 tab-12">
                                            <div class="nv-column-content">
                                                <div class="nv-row config-plates clean gap-80 flow-center">
                                                    <div class="nv-column desk-initial">
                                                        <div class="nv-column-content">
                                                            <div class="nv-template-choice" [class.on]="websiteForm.value.theme === 'light-skin'">
                                                                <nv-radio
                                                                        formControlName="theme"
                                                                        value="light-skin"
                                                                        label="Light template">
                                                                </nv-radio>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="nv-column desk-initial">
                                                        <div class="nv-column-content">
                                                            <div class="nv-template-choice" [class.on]="websiteForm.value.theme === 'dark-skin'">
                                                                <nv-radio
                                                                        formControlName="theme"
                                                                        value="dark-skin"
                                                                        label="Dark template">
                                                                </nv-radio>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </nv-accordion-->
                            </nv-accordions>
                            <div class="nv-row large-spaced flow-center" *ngIf="step2done && !websiteId">
                                <div class="nv-column desk-8 tab-12">
                                    <div class="nv-column-content" style="text-align: center">
                                        <h4>You're ready to go!</h4>
                                        <p>You've finished setting up your event website. You can continue to customize your page from the website builder.</p>
                                        <nv-button color="primary" (click)="save()"
                                                    [loading]="savingWebsite" 
                                                    [disabled]="websiteForm.invalid"
                                        >Finish</nv-button>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section" *ngIf="website && websiteId && editActive">
        <div class="nv-port">
            <div class="nv-row config-plates">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form [formGroup]="websiteForm">
                            <div class="nv-row large-spaced-top flow-center">
                                <div class="nv-column desk-8 tab-12">
                                    <div class="nv-column-content">
                                        <div class="nv-row config-plates clean gap-30 propagate">
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <nv-input
                                                            formControlName="name"
                                                            label="Website title"
                                                            required
                                                    ></nv-input>
                                                </div>
                                            </div>
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <nv-input
                                                            formControlName="subdomain"
                                                            suffix=".navus.io"
                                                            label="Domain"
                                                            [errorMessages]="{ 'domain_taken': 'Domain is already taken.' }"
                                                            required
                                                    ></nv-input>
                                                </div>
                                            </div>
                                            <div class="nv-column desk-6">
                                                <div class="nv-column-content">
                                                    <nv-select
                                                            [options]="languageOptions"
                                                            formControlName="default_language"
                                                            label="Default language"
                                                    ></nv-select>
                                                </div>
                                            </div>
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <ng-container formGroupName="settings">
                                                        <nv-input
                                                                type="textarea"
                                                                formControlName="google_analytics_code"
                                                                label="Google Analytics Code"
                                                        ></nv-input>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="nv-column desk-8 tab-12">
                                    <div class="nv-column-content">
                                        <div class="nv-row config-plates clean gap-30 propagate">
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <h4>Event branding for the website</h4>
                                                    <nv-file #websiteLogo
                                                             [organizationId]="organizationId"
                                                             formControlName="logo"
                                                             label="Logo"
                                                    ></nv-file>
                                                </div>
                                            </div>
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <h4>Favicon</h4>
                                                    <nv-file #websiteFavicon
                                                            [organizationId]="organizationId"
                                                            formControlName="favicon"
                                                            label="Favicon"
                                                            class="favicon"
                                                    ></nv-file>
                                                </div>
                                            </div>
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    Let your visitors know that the full version of your website is in the works.
                                                    Add event description and click on "Preview" button to preview your event
                                                    coming soon page.
                                                </div>
                                            </div>
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <nv-text-editor
                                                            formControlName="description"
                                                            label="Event description"
                                                            required
                                                    ></nv-text-editor>
                                                </div>
                                            </div>
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <nv-button (click)="showPlaceholderPreview()">Preview</nv-button>
                                                </div>
                                            </div>
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <ng-container formGroupName="settings">
                                                        <h4>Footer</h4>
                                                        <nv-text-editor
                                                                formControlName="footer"
                                                                [organizationId]="organizationId"
                                                        ></nv-text-editor>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
