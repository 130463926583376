import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SummarySectionConfig } from './summary-section.config';

@Component({
  selector: 'nv-summary-section-configuration',
  templateUrl: './summary-section-configuration.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SummarySectionConfigurationComponent),
      multi: true
    }
  ]
})

export class SummarySectionConfigurationComponent implements OnInit, ControlValueAccessor {
  @Input() value: SummarySectionConfig;

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  constructor() { }

  ngOnInit() { }

  writeValue(value: any): void {
    this.value = new SummarySectionConfig(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

}
