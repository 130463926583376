import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResourceService } from '@navus/core/services/resource.service';
import { take } from 'rxjs/operators';
import { ToastService } from '../toast/toast.service';
import { ModalService } from '../modal/modal.service';
import { FileUploader } from 'ng2-file-upload';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditResourceComponent } from './edit-resource/edit-resource.component';
import { AddFolderComponent } from '@navus/ui/resource-manager/add-folder/add-folder.component';
import { NavusModalComponent } from '@navus/ui/modal/default-modal/modal.component';

@Component({
  selector: '[nv-resource-manager]',
  templateUrl: './resource-manager.component.html'
})
export class ResourceManagerComponent implements OnInit {
  @Input() organizationId: number;
  @Input() isEditMode: boolean;
  @Input() selectFolder: string;
  @Output() selectedResource: EventEmitter<any> = new EventEmitter<any>();

  folders: any[] = [];
  resources: any[] = [];
  selectedFolder: string;
  selectedFiles: any[] = [];

  readyToDrop: boolean = false;
  loading: boolean = false;
  showFoldersAction: boolean = false;

  pagination: any;
  openedResource: any;
  uploader: FileUploader;

  constructor(
      private resourceService: ResourceService,
      private toastService: ToastService,
      private modalService: ModalService,
      private ngbModal: NgbModal,
  ) { }

  ngOnInit() {
    this.getOrganizationFolders();
    this.getOrganizationResources();
    this.setUploader();
  }

  setUploader() {
    const params = {
      removeAfterUpload: true,
      autoUpload: true,
      headers: []
    };
    this.uploader = this.resourceService.createResourceUploader(this.organizationId, params);
    this.uploader.onBeforeUploadItem = (file: any) => {
      this.loading = true;
    };
    this.uploader.onAfterAddingFile = (file: any) => {
      file.withCredentials = false;
    };

    this.uploader.onCompleteAll = () => {
      this.loading = false;
      this.toastService.success('Finished uploading of resources');
      this.uploader.clearQueue();
      this.getOrganizationResources();
    };
    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      if (filter && filter.name === 'fileSize') {
        this.toastService.info('Failed to add file: File too large');
      } else {
        this.toastService.info('Failed to add file');
      }
    };
    this.uploader.onErrorItem = () => {
      this.toastService.info('Failed to upload resource');
    };
    this.uploader.onAfterAddingFile = (fileItem) => {
      fileItem.file.name = fileItem.file.name.replace(/[^a-zA-Z0-9\.]/g, '_');
      console.log(fileItem.file.name);
    }; 
  }

  getOrganizationFolders() {
    this.resourceService.getOrganizationFolders(this.organizationId)
      .subscribe(
        (response) => {
          this.folders = response.data;
        },
        () => {}
      );
  }

  getOrganizationResources() {
    const params: any = {
      with_pagination: true,
      per_page: 31
    };
    if (this.selectedFolder) {
      params.tags = this.selectedFolder;
    }

    this.loading = true;
    this.resourceService.getOrganizationResources(this.organizationId, params)
      .subscribe(
        (response) => {
          this.resources = response.data;
          this.pagination = response.meta.pagination;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  getFilesPagination(append: boolean = false) {
    if (append && this.pagination.total && this.resources.length >= this.pagination.total) { return; }

    this.pagination.current_page = append ? this.pagination.current_page + 1 : 1;

    const params: any = {
      page: this.pagination.current_page,
      per_page: this.pagination.per_page + 1,
    };
    if (this.selectedFolder) {
      params.tags = this.selectedFolder;
    }

    this.loading = !append;
    this.resourceService.getOrganizationResources(this.organizationId, params)
      .subscribe(
        (res) => {
          if (append) {
            this.resources = this.resources.concat(res.data);
          } else {
            this.resources = res.data;
            this.pagination = res.meta;
          }
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );

  }


  openFullSize(url: string) {
    if (url) {
      window.open(url, '_blank');
    }
  }

  openEditResourceModal(resource: any) {
    const modalRef = this.ngbModal.open(EditResourceComponent);

    modalRef.componentInstance.size = 'medium';
    modalRef.componentInstance.resource = JSON.parse(JSON.stringify(resource));
    modalRef.componentInstance.allFolders = this.folders;
    modalRef.componentInstance.savedResource.pipe(take(1))
      .subscribe(
        (savedResource) => {
          // TODO: Add real API call
          this.loading = true;
          this.resourceService.updateOrganizationResource(this.organizationId, savedResource.id, savedResource.tags)
            .subscribe(
              () => {
                this.toastService.success('Resource successfully updated.');
                this.loading = false;
                if (savedResource.tags) {
                  this.getOrganizationFolders();
                  this.getOrganizationResources();
                }
                // this.redoSearch$.next();
                // this.openedResource.tags = savedResource.tags;
              },
              () => {
                this.toastService.error('Cannot update resource.');
                this.loading = false;
              }
            );
        }
      );
  }

  openOrganizationCreateFolder() {
    this.showFoldersAction = false;
    const modalRef = this.ngbModal.open(AddFolderComponent);

    modalRef.componentInstance.organizationId = this.organizationId;
    modalRef.result
      .then(
        (result) => {
          this.getOrganizationFolders();
        },
        () => {}
      );
  }


  toggleFoldersAction(event) {
    event.stopPropagation();
    this.showFoldersAction = !this.showFoldersAction;
  }

  actionToggle(event, resource) {
    event.stopPropagation();
    resource.toggled = !resource.toggled;
  }


  onResourceClick(resource: any) {
    if (this.selectedFiles.includes(resource)) {
      this.selectedFiles.splice(this.selectedFiles.indexOf(resource), 1);
    } else {
      this.selectedFiles.push(resource);
    }
  }

  unselectAll() {
    this.selectedFiles = [];
  }

  onFolderClick(folder: any) {
    if (this.selectedFolder === folder) {
      this.selectedFolder = null;
    } else {
      this.selectedFolder = folder;
      this.uploader.setOptions({
        additionalParameter: {
          tags: [folder]
        }
      })
    }
    this.getOrganizationResources();
  }

  isFolderLocked(folder: any): boolean {
    return this.folders && this.folders.length && this.folders.includes(folder.name);
  }


  deleteResource(resourceId: number) {
    if (resourceId) {
      const modalRef = this.ngbModal.open(NavusModalComponent);

      modalRef.componentInstance.title = 'File Deletion';
      modalRef.componentInstance.body = 'Are you sure you want to delete this file?';
      modalRef.componentInstance.size = 'small';
      modalRef.componentInstance.buttons = [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.resourceService.deleteOrganizationResource(this.organizationId, resourceId)
              .subscribe(
                () => {
                  this.toastService.success('File successfully deleted');
                  this.getOrganizationResources();
                  // this.redoSearch$.next();
                }, () => {
                  this.toastService.error('Error occurred. Please refresh page and try again');
                }
              );
          }
        }
      ];
    } else {
      this.toastService.error('Error occurred. Please refresh page and try again');
    }
  }

  deleteSelectedFiles() {
    if (this.selectedFiles.length > 0) {

    }
  }

  deleteFolder(event, folderName: string) {
    event.stopPropagation();
    this.modalService.defaultModal({
      title: 'Folder Deletion',
      body: 'Are you sure you want to delete ' + folderName + ' folder?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'primary',
          handler: () => {
            this.resourceService.deleteOrganizationFolder(this.organizationId, folderName)
              .subscribe(
                () => {
                  this.toastService.success('Folder successfully deleted.');
                  this.getOrganizationFolders();
                }, () => {
                  this.toastService.error('Failed to delete folder.');
                }
              );
          }
        }
      ]
    });
  }


  dragEnter(event: DragEvent) {
    this.readyToDrop = true;
    event.preventDefault();
    event.stopPropagation();
  }

  dragLeave(event: DragEvent) {
    this.readyToDrop = false;
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    this.dragLeave(event);
    if (!(event && event.dataTransfer && event.dataTransfer.files)) {
      return;
    }
    if (event.dataTransfer.files.length > 10) {
      this.toastService.error('Maximum number of files for upload is 10');
    } else {
      Array.from(event.dataTransfer.files).forEach(file => {
        file['withCredentials'] = false;
        this.uploader.addToQueue([file]);
      });
      this.uploader.uploadAll();
    }
  }

  copyToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.success('Link copied to clipboard');
  }

  isResourceAnImage(resource: any): boolean {
    return resource && resource.mime && resource.mime.startsWith('image/');
  }

  downloadResource(resource: any) {
    if (!(resource && resource.url && resource.original_filename)) {
      return;
    }

    const a = document.createElement('a');
    a.href = resource.url;
    a.download = resource.original_filename;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }


  closeResourceInfo(event: MouseEvent) {
    this.openedResource = null;
    event.stopPropagation();
  }

  searchResource(event: any) {
    console.log(event.target.value);
  }

  searchFolders(event: any) {
    this.folders.map(tags => tags.filter(t => t.name.toLowerCase().includes(event.target.value.toLowerCase())));
  }

}
