\<div class="nv-modal large">

    <div class="nv-modal-header">
        <h4 class="nv-modal-title">Edit Off Air</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>

    <div class="nv-modal-body">
        <!-- Off Air modal content -->
        <form [formGroup]="offAirEditForm" class="nv-row">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <div class="nv-row gap-24 propagate">
                        <div class="nv-column desk-8">
                            <div class="nv-column-content">
                                <div class="nv-form-item">
                                    <nv-input 
                                        label="Title" 
                                        formControlName="title"
                                    ></nv-input>
                                </div><!-- /nv-form-item -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->

                        <div class="nv-column desk-4">
                            <div class="nv-column-content">
                                <div class="nv-form-item">
                                    <nv-select
                                            [options]=positionOptions
                                            formControlName="title_position"
                                            label="Title Position"
                                    ></nv-select>
                                </div><!-- /nv-form-item -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div>
                    <div class="nv-row gap-24 propagate">
                        <div class="nv-column desk-8">
                            <div class="nv-column-content">
                                <div class="nv-form-item">
                                    <nv-input 
                                        label="Description" 
                                        formControlName="description"
                                    ></nv-input>
                                </div><!-- /nv-form-item -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->

                        <div class="nv-column desk-4">
                            <div class="nv-column-content">
                                <div class="nv-form-item">
                                    <nv-select
                                            [options]=positionOptions
                                            formControlName="description_position"
                                            label="Description Position"
                                    ></nv-select>
                                </div><!-- /nv-form-item -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div>
                    <div class="nv-row gap-24 propagate">
                        <div class="nv-column desk-8">
                            <div class="nv-column-content">
                                <div class="nv-form-item">
                                    <nv-datepicker 
                                        label="Countdown Finishes" 
                                        formControlName="countdown_ends_at"
                                    ></nv-datepicker>
                                </div><!-- /nv-form-item -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->

                        <div class="nv-column desk-4">
                            <div class="nv-column-content">
                                <div class="nv-form-item">
                                    <nv-select
                                            [options]=positionOptions
                                            formControlName="countdown_position"
                                            label="Countdown Position"
                                    ></nv-select>
                                </div><!-- /nv-form-item -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->   
                        
                        <div class="nv-column desk-12" *ngIf="offAirEditForm.value.countdown_ends_at">
                            <div class="nv-column-content">
                                <div class="nv-form-item">
                                    <nv-input 
                                        type="textarea" 
                                        label="Countdown Finished Message" 
                                        formControlName="countdown_ended_label"
                                    ></nv-input>
                                    <small>This text is shown when countdown finishes</small>
                                </div><!-- /nv-form-item -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->                     
                    </div>
                    <div class="nv-row gap-24 propagate">   
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <div class="nv-form-item select-item">
                                    <nv-select
                                            [options]=resourceTypes
                                            formControlName="resource_type"
                                            label="Resource Type"
                                    ></nv-select>
                                </div><!-- /nv-form-item -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->

                        <div class="nv-column desk-12" *ngIf="offAirEditForm.value.resource_type === 'materials'">
                            <div class="nv-column-content">
                                <div class="nv-form-item select-item">
                                    <nv-select
                                            [options]=materials
                                            [showSearch]="true"
                                            (searched)="getMaterials()"
                                            (loadMore)="getMaterials(true)"
                                            (searched)="getMaterials(false, $event)"
                                            formControlName="material_id"
                                            label="Select Material"
                                    ></nv-select>
                                </div><!-- /nv-form-item -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->

                        <div class="nv-column desk-12"
                            *ngIf="offAirEditForm.value.resource_type && offAirEditForm.value.resource_type !== 'materials'">
                            <div class="nv-column-content">
                                <div class="nv-form-item">

                                    <nv-file *ngIf="offAirEditForm.value.resource_type === 'resources'"
                                            [organizationId]="organizationId"
                                            [folderName]="'Speakers'"
                                            [mime]="['image/png','image/jpg','image/jpeg']"
                                            formControlName="resource_value"
                                            label="Resource">
                                    </nv-file>

                                    <nv-input *ngIf="offAirEditForm.value.resource_type === 'external'"
                                            type="text"
                                            label="Resource"
                                            formControlName="resource_value">
                                    </nv-input>

                                    <nv-input *ngIf="offAirEditForm.value.resource_type === 'external_video'"
                                            type="text"
                                            label="Resource"
                                            formControlName="resource_value">
                                    </nv-input>

                                </div><!-- /nv-form-item -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->                               
                    </div>
                    <div class="nv-row gap-24 propagate">
                        <div class="nv-column desk-6 phab-12">
                            <div class="nv-column-content">
                                <nv-button (click)="save()" class="fw" [loading]="offAirSaving" [disabled]="offAirEditForm.invalid" translate>Save</nv-button>
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                        <div class="nv-column desk-6 phab-12">
                            <div class="nv-column-content">
                                <button type="submit" class="nv-btn neutral fw medium" (click)="close()">Cancel</button>
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->                
                    </div>                   
                </div>
            </div>
        </form><!-- /.nv-row -->
        <!-- /Off Air modal content -->

    </div>
</div>
