<div class="nv-modal medium">
    <div class="nv-modal-header">
        <h4 class="nv-modal-title">Material</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body" id="scroll-modal-body">
        <nav class="nv-tabs-nav vertical-break-phab equal-width-items">
            <ul>
                <li>
                    <a [class.active]="selectedTab === MaterialModalTabs.MATERIAL"
                       (click)="setTab(MaterialModalTabs.MATERIAL)">Material preview</a>
                </li>
                <li>
                    <a [class.active]="selectedTab === MaterialModalTabs.ATTACH_PRESENTATION"
                       (click)="setTab(MaterialModalTabs.ATTACH_PRESENTATION)">Attach to presentation</a>
                </li>
            </ul>
        </nav>

        <ng-container *ngIf="selectedTab === MaterialModalTabs.MATERIAL">
            <ng-template *ngTemplateOutlet="materialDetails"></ng-template>
        </ng-container>

        <ng-container *ngIf="selectedTab === MaterialModalTabs.ATTACH_PRESENTATION">
            <ng-template *ngTemplateOutlet="attachPresentation"></ng-template>
        </ng-container>
    </div>

    <div class="nv-modal-footer">
        <ng-container *ngIf="selectedTab === MaterialModalTabs.MATERIAL">
            <ng-template *ngTemplateOutlet="materialDetailsFooter"></ng-template>
        </ng-container>

        <ng-container *ngIf="selectedTab === MaterialModalTabs.ATTACH_PRESENTATION">
            <ng-template *ngTemplateOutlet="attachPresentationFooter"></ng-template>
        </ng-container>
    </div>
</div>

<!-- MATERIAL DETAILS MODAL CONTENT-->
<ng-template #materialDetails>
    <div class="nv-global-loading on" [class.on]="loadingMaterial">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->

    <div class="nv-row gap-24 spaced-top propagate">
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <div class="nv-form-item nv-mandatory fw">
                    <form [formGroup]="materialForm">
                        <nv-input
                                label="Title"
                                formControlName="title"
                        ></nv-input>
                    </form>
                </div><!-- /nv-form-item -->
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->

        <div class="nv-column desk-12" *ngIf="!material">
            <div class="nv-column-content">
                <div class="nv-no-material-added">
                    <p>No material added yet.</p>
                </div><!-- /.nv-no-material-added -->
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->


        <div class="nv-column desk-12" *ngIf="material">
            <div class="nv-column-content" style="position:relative">

                <ng-container *ngIf="material.type === 'webcast'">
                    <div class="nv-video-box-block padded">
                        <nv-video [src]="material.external_url || material.path"></nv-video>
                    </div><!-- /.nv-video-box-block -->
                </ng-container>

                <ng-container *ngIf="material.type === 'main' && materialsImages?.length">
                    <div class="nv-material-view" style="flex: 1;">
                        <div class="nv-material-detail-submenu">
                            <nav>
                                <ul class="nmv-pagination">
                                    <li class="mds-prev" *ngIf="currentPage >= 2">
                                        <a (click)="setPage(currentPage-1)" title="Previous slide"></a>
                                    </li>
                                    <li class="mds-page">
                                        <input type="number" min="1" [max]="materialsImages?.length"
                                               [ngModel]="currentPage"
                                               (ngModelChange)="setPage($event)"
                                               class="ng-untouched ng-pristine ng-valid">
                                        <span> / &nbsp;&nbsp;{{materialsImages?.length}}</span>
                                    </li>
                                    <li class="mds-next"
                                        *ngIf="currentPage < materialsImages.length">
                                        <a (click)="setPage(currentPage+1)" title="Next slide"></a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div class="nv-material-preview">
                            <ng-container *ngFor="let image of materialsImages; let i = index">
                                <img [ngStyle]="{'height': '80px', 'maxWidth': '120px', margin: '10px'}"
                                     [src]="image.src"
                                     (click)="setPage(i+1)">
                            </ng-container>
                        </div>

                        <div class="nv-material-preview single">
                            <img *ngIf="currentPage && materialsImages?.length"
                                 [ngStyle]="{'height': scale * 100 + '%'}"
                                 [src]="materialsImages[currentPage-1].src">
                            <div class="nv-global-loading"> <!-- < toggle class 'on' -->
                                <div class="nv-spinner"></div>
                            </div><!-- /.nv-global-loading -->
                        </div>
                    </div><!-- /nv-material-view -->
                </ng-container>

            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-12" *ngIf="material">
            <div class="nv-column-content">
                <div class="nv-row gap-24 space-between propagate">
                    <ng-container *ngIf="material.type === 'webcast' && !material.external_url">
                        <div class="nv-column desk-6 pab-12">
                            <div class="nv-column-content">
                                <nv-select
                                        [options]="videoPlayerPresetsOptions"
                                        [(ngModel)]="selectedVideoPresetId"
                                        [label]="'Default player preset'">
                                </nv-select>
                            </div>
                        </div>
                        <div class="nv-column desk-6 pab-12">
                            <div class="nv-column-content">
                                <nv-button class="fw" [loading]="videoPlayerPresetsLoading"
                                           (click)="setDefaultPresetForMaterial()">Set
                                    default player preset
                                </nv-button>
                            </div>
                        </div>
                        <div class="nv-column desk-6">
                            <div class="nv-column-content">
                                <a class="nv-btn txt accent1 medium" data-icon-pl="&#xe92a;"
                                   [routerLink]="['/o', organizationId, 'conference', conferenceId, 'ematerials', 'video-player-presets']"
                                   (click)="close()">
                                    <span>Manage player presets</span>
                                </a>
                            </div>
                        </div>

                        <div class="nv-column desk-6">
                            <div class="nv-column-content">
                                <ng-container
                                        *ngIf="material.mp4_status === 'disabled' || material.mp4_status === 'preparing' || requestingDownload;else downloadAvailable">
                                    <div>
                                        <nv-button size="small" [disabled]="requestingDownload"
                                                   (click)="requestMaterialDownload()">
                                            {{ (material.mp4_status === 'preparing' || requestingDownload) ? 'Preparing...' : 'Request download' }}
                                        </nv-button>
                                    </div>
                                </ng-container>
                                <ng-template #downloadAvailable>
                                    <div class="nv-multiop-btn" [class.on]="downloadSelectOpen"
                                         [attr.data-tooltip]="material.mp4_status === 'errored'? 'There has been error accessing this resource for download. Support team is already notified.' : null">
                                        <button type="button" [disabled]="material.mp4_status !== 'ready'"
                                                class="nv-btn small accent1" data-icon-pl="&#xe941;"
                                                (click)="downloadSelectOpen = !downloadSelectOpen">
                                            {{ material.mp4_status === 'errored' ? 'Unavailable for download' : 'Download' }}
                                        </button>
                                        <ul>
                                            <li><a (click)="downloadMaterial('low')">Low quality</a></li>
                                            <li><a (click)="downloadMaterial('medium')">Medium quality</a></li>
                                            <li><a (click)="downloadMaterial('high')">High quality</a></li>
                                        </ul>
                                    </div><!-- /.nv-multiop-btn -->
                                </ng-template>

                            </div>
                        </div>

                    </ng-container>
                </div><!-- /.nv-row -->
            </div><!-- /.nv-column-content -->
        </div>
    </div><!-- /.nv-row -->
</ng-template>
<!-- MATERIAL DETAILS MODAL FOOTER-->
<ng-template #materialDetailsFooter>
    <div class="nv-row gap-24 space-between">
        <div class="nv-column desk-initial v-middle">
            <div class="nv-column-content">
                <div class="nv-btn-box gap-20">
                    <button type="button" *ngIf="material?.type === 'webcast' && !material.external_url"
                            class="nv-btn medium accent1 txt"
                            (click)="copyEmbed()">
                        Copy embed code
                    </button>
                    <button type="button" *ngIf="material?.type === 'main' && !material.external_url"
                            class="nv-btn medium accent1 txt"
                            (click)="copyPath()">
                        Copy path
                    </button>
                </div>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-initial">
            <div class="nv-column-content">
                <div class="nv-btn-box flow-end gap-10">
                    <nv-button size="medium" (click)="save()">Save</nv-button>
                </div>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
    </div><!-- /.nv-row -->
</ng-template>

<!-- ATTACH PRESENTATION MODAL CONTENT-->
<ng-template #attachPresentation>
    <div class="nv-global-loading on" [class.on]="loadingPresentation">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <div class="nv-row gap-24 propagate semi-spaced-top">
        <div class="nv-column desk-12">
            <div class="nv-column-content">

                <h5 class="nv-light">Attached presentations</h5>
                <table class="nv-data-table break-lap" *ngIf="material?.attached_presentation;else noPresentations">
                    <tr>
                        <td class="dt-cell">
                            <div class="dt-name-w-file">
                                <div class="dt-name">
                                    <label>PRESENTATION NAME</label>
                                    <strong>{{ material.attached_presentation.title }}</strong>
                                </div>
                            </div><!-- /.dt-name-w-file -->
                        </td><!-- /.dt-cell -->
                        <td class="dt-cell">
                            <label>STARTS AT</label>
                            <strong *ngIf="material.attached_presentation.starts_at">{{ material.attached_presentation.starts_at | nvDate:'dd.MM.yyyy' }}</strong>
                            <strong *ngIf="!material.published_at">-</strong>
                        </td><!-- /.dt-cell -->
                        <td class="dt-cell">
                            <label>SHOW</label>
                            <div class="nv-custom-toggle-checkbox">
                                <nv-switch (change)="updateAvailability($event)" [value]="material.available"></nv-switch>
                            </div><!-- /.nv-custom-toggle-checkbox -->
                        </td><!-- /.dt-cell -->
                        <td class="dt-cell">
                            <label>DETACH PRESENTATION</label>
                            <a (click)="detachMaterial()">
                                <span>Detach</span>
                            </a>
                        </td><!-- /.dt-cell -->
                    </tr>
                </table>

                <ng-template #noPresentations>
                    <div class="nv-column desk-12 no-propagate">
                        <div class="nv-column-content">
                            <div class="nv-no-material-added">
                                <p>No presentation added yet.</p>
                            </div><!-- /.nv-no-material-added -->
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->
                </ng-template>

            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
    </div><!-- /.nv-row -->

    <div class="nv-row gap-30 small-spaced-top">
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <div class="nv-modal-visual-chunk">
                    <div class="nv-row gap-24 propagate semi-spaced-top">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <h5 class="nv-light">Attach to presentation</h5>
                                <div class="nv-form-item nv-select-item">
                                    <label>
                                        <nv-select [options]="presentations"
                                                   [headless]="false"
                                                   label="Presentation"
                                                   [(ngModel)]="selectedPresentationId"
                                                   [showSearch]="true"
                                                   (click)="scrollToBottom()"
                                                   (ngModelChange)="getPresentation()"
                                                   (searched)="searchPresentations($event)"
                                                   (loadMore)="getPresentations(true)"
                                        ></nv-select>
                                    </label>
                                </div><!-- /nv-form-item -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->

                        <ng-container *ngIf="selectedPresentationId">
                            <div class="nv-column desk-auto phab-12 v-middle">
                                <div class="nv-column-content">
                                    <p class="spaceless">Show/Hide</p>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div [formGroup]="attachForm" class="nv-column desk-initial phab-12 v-middle">
                                <div class="nv-column-content" formGroupName="consent">
                                    <nv-switch formControlName="available"></nv-switch>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->

                            <ng-container *ngIf="material?.type !== 'webcast'">
                                <div class="nv-column desk-auto phab-12 v-middle">
                                    <div class="nv-column-content">
                                        <p class="spaceless">Downloadable</p>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div [formGroup]="attachForm" class="nv-column desk-initial phab-12 v-middle">
                                    <div class="nv-column-content">
                                        <nv-switch formControlName="downloadable"></nv-switch>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                            </ng-container>

                            <div [formGroup]="attachForm" class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <nv-datepicker
                                            formControlName="published_at"
                                            type="calendar"
                                            label="Publish date"
                                            required
                                    ></nv-datepicker>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div [formGroup]="attachForm" class="nv-column desk-12 no-propagate">
                                <div class="nv-column-content">
                                    <div class="nv-form-item nv-mandatory fw" formGroupName="consent">
                                        <nv-input
                                                type="text"
                                                formControlName="excluded_slides"
                                                label="Exclude slides"
                                        ></nv-input>
                                    </div><!-- /nv-form-item -->
                                    <p style="opacity: 0.6;"><small>Example: 1, 3, 10…</small></p>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                        </ng-container>

                    </div><!-- /.nv-row -->
                </div><!-- /.nv-modal-visual-chunk -->
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
    </div><!-- /.nv-row -->
</ng-template>
<!-- ATTACH PRESENTATION MODAL FOOTER-->
<ng-template #attachPresentationFooter>
    <div class="nv-row">
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <div class="nv-btn-box flow-end gap-10">
                    <nv-button size="medium"
                               [loading]="attaching"
                               (click)="attachMaterial()">Attach
                    </nv-button>
                    <!-- DISABLED > <button type="button" class="nv-btn medium accent1" disabled>Attach</button> -->
                </div>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
    </div><!-- /.nv-row -->
</ng-template>


