<!-- <div class="nv-public-chat">
    <div [style.display]="chatMinimized? 'none' : 'block'">
        <div class="public-chat-header">
            <div class="close" (click)="setChatMinimized(true)"></div>
        </div>
        <nv-public-chat
            [eventId]="eventId"
            [organizationId]="organizationId"
            [chatType]="chatType"
            [allow1on1]="allow1on1"
            [allowGroupChat]="allowGroupChat">
        </nv-public-chat>
    </div>

    <div *ngIf="chatMinimized" class="minimized" (click)="setChatMinimized(false)">
        <div class="count"
             *ngIf="(chatComponent?.totalUnreadCount$ | async) > 0">{{ (chatComponent?.totalUnreadCount$ | async) }}</div>
        <span class="nv-chat-label">Chat</span>
    </div>
</div> -->

<div class="chat-holder">
    <nv-public-chat-container
        [eventId]="eventId"
        [organizationId]="organizationId"
        [allow1on1]="allow1on1"
        [allowGroupChat]="allowGroupChat">
    </nv-public-chat-container>
</div>