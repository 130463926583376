import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Material } from '../../../../../navus-core/classes/material';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MaterialService } from '../../../../../navus-core/services/material.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '../../../../../navus-ui/toast/toast.service';
import { ModalService } from '../../../../../navus-ui/modal/modal.service';
import { BlockService } from '../../../../../navus-core/services/block.service';

@Component({
  selector: 'main [nv-material-details-page]',
  templateUrl: './material-details-page.component.html',
  styles: []
})
export class MaterialDetailsPageComponent implements OnInit, OnDestroy {
  organizationId: number;
  conferenceId: number;
  materialId: number;
  material: Material;
  materialForm: FormGroup;

  savingMaterial: boolean = false;

  subscriptions: Subscription[] = [];

  @HostBinding('class.nv-config-content') class = true;
  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private materialService: MaterialService,
    private blockService: BlockService,
    private toastService: ToastService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.initForm();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.materialId = data.materialId;
        this.getMaterial();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.materialForm = this.formBuilder.group({
      title: ['', Validators.required],
      type: [''],
    });
  }

  getMaterial() {
    if (this.materialId) {
      this.materialService.getConferenceMaterial(this.conferenceId, this.materialId)
        .subscribe(
          (response) => {
            this.material = response['data'];
            this.materialForm.patchValue(this.material);
          },
          (error) => {

          }
        );
    } else {
      this.material = new Material();
    }
  }

  save() {
    if (this.materialForm.invalid) { return; }

    this.savingMaterial = true;
    if (this.materialId) {
      this.materialService.updateConferenceMaterial(this.conferenceId, this.materialId, this.materialForm.value)
        .subscribe(
          (response) => {
            this.material = response['data'];
            this.savingMaterial = false;
            this.toastService.success('Material successfully saved.', {});
          },
          (error) => {
            this.savingMaterial = false;
            this.modalService.error({ errors: error });
          }
        );
    } else {
      this.materialService.createConferenceMaterial(this.conferenceId, this.materialForm.value)
        .subscribe(
          (response) => {
            this.material = response['data'];
            this.savingMaterial = false;
            this.toastService.success('Material successfully created.', {});
            this.router.navigate([this.material.id], { relativeTo: this.route });
          },
          (error) => {
            this.savingMaterial = false;
            this.modalService.error({ errors: error });
          }
        );
    }
  }

  close() {
    if (this.materialId) {
      this.router.navigate(['../..', 'materials'], { relativeTo: this.route });
    } else {
      this.router.navigate(['..', 'materials'], { relativeTo: this.route });
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Material Deletion',
      body: 'Are you sure you want to delete this material?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.materialService.deleteConferenceMaterial(this.conferenceId, this.materialId)
              .subscribe(
                () => {
                  this.close();
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

}
