import { Component } from '@angular/core';

@Component({
    selector: 'app-chat-page',
    templateUrl: './chat.component.html'
})
export class ChatComponent {

    constructor() { }

}
