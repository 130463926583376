<ng-container *ngIf="votingQuestion">

  <h4>{{ votingQuestion.title }}</h4>
  <ng-container *ngIf="votingQuestion.active">
    
    <ng-container *ngIf="!votingQuestion.user_answer">
      <div class="results" *ngIf="votingQuestion.options?.choices">
        <ng-container *ngFor="let votingOption of votingQuestion.options.choices">
          <div class="result-row" (click)="selectedOption = votingOption.choice">
            <div class="option" [class.active]="selectedOption == votingOption.choice">{{ votingOption.choice }}</div>
            <div class="option-info">
              <div>{{ votingOption.title }}</div>
            </div>
          </div>
        </ng-container>
        <nv-button [disabled]="!selectedOption" (click)="vote()">Vote</nv-button>
      </div>
    </ng-container>
    
    <ng-container *ngIf="votingQuestion.user_answer">
      <div class="results" *ngIf="votingQuestion.options?.choices">
        <ng-container *ngFor="let votingOption of votingQuestion.options.choices">
          <div class="result-row">
            <div class="option" [class.active]="votingQuestion.user_answer == votingOption.choice">{{ votingOption.choice }}</div>
            <div class="option-info">
              <div>{{ votingOption.title }}</div>
            </div>
          </div>
        </ng-container>
        <strong>You have already voted</strong>
      </div>
    </ng-container>

  </ng-container>

  <ng-container *ngIf="votingQuestion.finished">
    <div class="results" *ngIf="votingQuestion.statistics">
      <ng-container *ngFor="let votingOption of votingQuestion.statistics">
        <div class="result-row" [ngClass]="{'correct': votingOption.correct }">
            
          <div class="option" [class.active]="votingQuestion.user_answer == votingOption.choice">{{ votingOption.choice }}</div>
          <div class="option-info">
            <div>{{ votingOption.title }}</div>
            <div class="bar"><span [style.width]="votingOption.percentage + '%'"></span></div>
          </div>
          <div class="percentage">{{ votingOption.percentage }}%</div>
        </div>
      </ng-container>
    </div>
  </ng-container>

</ng-container>
