import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@navus/core/services/user.service';

@Component({
  selector: 'nv-invitation-modal',
  templateUrl: './invitation-modal.component.html'
})
export class InvitationModalComponent implements OnInit {
  @Input() organizationId: number = null;
  showExpiration = false;
  expirationOptions = [
    { id: 7, name: 'Expires in 7 days' },
    { id: 14, name: 'Expires in 14 days' },
    { id: 30, name: 'Expires in 30 days' },
    { id: 0, name: 'Never expires' },
  ];

  invitationForm: FormGroup;

  roles: any[] = [
    { id: 'admin', name: 'Admin' },
    { id: 'staff', name: 'Staff' },
    { id: 'article_manager', name: 'Article Manager' },
    { id: 'article_writer', name: 'Article Writer' }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.invitationForm = this.formBuilder.group({
      expiration: [14],
      invitations: this.formBuilder.array([
        this.formBuilder.group({
          invitation: this.formBuilder.array([
            this.formBuilder.group({
              email: ['', [Validators.required, Validators.email]],
              role: ['admin']
            })
          ])
        })
      ])
    });

  }

  addVariation() {
    const variation = this.invitationForm.controls.invitations as FormArray;
    const newVariation = this.formBuilder.group({
      invitation: this.formBuilder.array([
        this.formBuilder.group({
          email: ['', [Validators.required, Validators.email]],
          role: ['admin']
        })
      ])
    });
    variation.push(newVariation);
  }

  removeVariation(variationIndex) {
    const variation = this.invitationForm.controls.invitations as FormArray;
    variation.removeAt(variationIndex);
  }

  sendInvitations() {
    const params = {
      invitations: []
    };
    this.invitationForm.value.invitations.map((item) => {
      params.invitations.push(item.invitation[0]);
    });
    this.userService.createOrganizationInvitations(this.organizationId, params)
      .subscribe(
        () => {
          this.activeModal.close();
        }
      );
  }

  close() {
    this.activeModal.dismiss();
  }

  trackBy(index) {
    return index;
  }

}
