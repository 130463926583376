<div class="nv-template-page">
    <div class="nv-template-top-controls">
        <div class="ttc-left">
            <div class="ttc-bck">
                <a href title="Go back"></a>
            </div><!-- /.ttc-bck -->
            <nav class="ttc-menu">
                <ul>
                    <li><a class="pages" href title="Pages">Pages</a></li>
                    <li><a class="design active" href title="Design">Design</a></li>
                    <li><a class="sections" href title="Sections">Section</a></li>
                </ul>
            </nav><!-- /.ttc-menu -->
        </div><!-- /.ttc-left -->
        <div class="ttc-right">
            <div class="nv-btn-box flow-end gap-16">
                <a class="nv-btn hollow neutral" data-icon-pl="&#xe087;">Preview</a>
                <a class="nv-btn filled accent2" data-icon-pl="&#xe910;">Save</a>
            </div><!-- /.nv-btn-box -->
        </div><!-- /.ttc-right -->
    </div><!-- /.nv-template-top-controls -->
    <div class="nv-template-content">
        <div class="nv-template-side-panel on"><!-- toggle class 'on' to show / hide -->
            <a class="tsp-close" title="Close panel"></a>
            <header>
                <h4>Pages</h4>
                <a href class="nv-btn filled tiny anchor">Add new</a>
            </header>
            <nav>
                <ul>
                    <li>
                        Home page
                        <a href title="Edit" class="nv-btn hollow neutral icon small" data-icon-pl="&#xe959;"></a>
                    </li>
                    <li>
                        Program
                        <a href title="Edit" class="nv-btn hollow neutral icon small" data-icon-pl="&#xe959;"></a>
                    </li>
                    <li>
                        Presentations
                        <a href title="Edit" class="nv-btn hollow neutral icon small" data-icon-pl="&#xe959;"></a>
                    </li>
                    <li>
                        Sign in
                        <a href title="Edit" class="nv-btn hollow neutral icon small" data-icon-pl="&#xe959;"></a>
                    </li>
                </ul>
            </nav>
        </div><!-- /.nv-template-side-panel -->
        <div class="nv-template-pane">
            <section class="nv-section guttered nv-template-header">
                <div class="nv-port">
                    <div class="nv-row spaced">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <div class="nv-logo-n-menu">
                                    <div class="lnm-logo">
                                        <a href><img src="//service.e-materials.com/storage/resources/eha/cel/celgene.png" alt="Portal name"></a>
                                    </div><!-- /.lnm-logo -->
                                    <nav class="lnm-menu">
                                        <ul>
                                            <li><a href title="Program">Program</a></li>
                                            <li><a href title="Presentations">Presentations</a></li>
                                            <li><a href title="Sign in">Sign in</a></li>
                                        </ul>
                                    </nav><!-- /.lnm-menu -->
                                </div><!-- /.nv-logo-n-menu -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div><!-- /.nv-row -->
                </div><!-- /.nv-port -->
            </section><!-- /.nv-section -->

            <section class="nv-section guttered nv-template-hero" style="background-image: url('../../assets/navus-template-hero-bg.jpg');">
                <div class="nv-port">
                    <div class="nv-row extra-large-spaced flow-center">
                        <div class="nv-column desk-6">
                            <div class="nv-column-content">
                                <header class="th-pane">
                                    <h1 class="light">H1 headline to make a big announcement</h1>
                                    <h2 class="light">May 25-27, 2018</h2>
                                    <h4 class="light">Location TBD, Serbia, Belgrade</h4>
                                </header><!-- /.th-pane -->
                                <div class="th-cta">
                                    <a class="nv-btn accent1 big filled" title="Action">Action</a>
                                </div><!-- /.th-cta -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div><!-- /.nv-row -->
                </div><!-- /.nv-port -->
            </section><!-- /.nv-section -->

            <section class="nv-section guttered light-gray edit-mode">
                <a href class="nv-section-add-top" title="Add section"></a>
                <a href class="nv-section-add-bottom" title="Add section"></a>
                <div class="nv-section-edit-tool-box">
                    <a href class="edit" title="Edit section"></a>
                    <a href class="delete" title="Delete section"></a>
                </div><!-- /.nv-section-edit-tool-box -->
                <div class="nv-section-sort-tool-box">
                    <a href class="move-up" title="Move down"></a>
                    <a href class="move-down" title="Move up"></a>
                </div><!-- /.nv-section-sort-tool-box -->
                <div class="nv-port">
                    <div class="nv-row extra-large-spaced">
                        <div class="nv-column desk-4">
                            <div class="nv-column-content">
                                <div class="nv-feature-teaser planner">
                                    <a href title="Program Planner"></a>
                                    <h3>Program Planner</h3>
                                    <h6 class="semi-light">Create your personal timeline. Sync with the mobile App. Access speaker presentations and join online session dialogues.</h6>
                                </div><!-- /.nv-feature-teaser -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                        <div class="nv-column desk-4">
                            <div class="nv-column-content">
                                <div class="nv-feature-teaser presentations">
                                    <a href title="Access Presentations"></a>
                                    <h3>Access Presentations</h3>
                                    <h6 class="semi-light">View available Speaker Slides already during the congress and Webcasts and E-posters after the meeting.</h6>
                                </div><!-- /.nv-feature-teaser -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                        <div class="nv-column desk-4">
                            <div class="nv-column-content">
                                <div class="nv-feature-teaser e-playlist">
                                    <a href title="E-playlist"></a>
                                    <h3>E-playlist</h3>
                                    <h6 class="semi-light">View and organize your tagged slides, add your notes, and present them to your colleagues after the meeting.</h6>
                                </div><!-- /.nv-feature-teaser -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div><!-- /.nv-row -->
                </div><!-- /.nv-port -->
            </section><!-- /.nv-section -->

            <footer class="nv-section guttered light-gray nv-template-footer">
                <div class="nv-port">
                    <div class="nv-row large-spaced">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <nav>
                                    <ul>
                                        <li><a href>Home Page</a></li>
                                        <li><a href>Program</a></li>
                                        <li><a href>Presentation</a></li>
                                    </ul>
                                </nav>
                                <div class="colophon">
                                    <h4>For support please contact us on <a href>info@navus.io</a>.</h4>
                                    <p>The featured platform is powered by Navus. <a href>Cookie and Privacy Policy</a> &amp; <a href>Terms and Conditions</a></p>
                                </div><!-- /.colophon -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div><!-- /.nv-row -->
                </div><!-- /.nv-port -->
            </footer><!-- /.nv-section -->
        </div><!-- /.nv-template-pane -->
    </div><!-- /.nv-template-content -->
</div><!-- /.nv-template-page -->


