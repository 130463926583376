import { Component, Input } from '@angular/core';

@Component({
  selector: 'nv-attendance-page',
  templateUrl: './attendance-page.component.html',
})
export class AttendancePageComponent {
  @Input() userId: number | null;

  showStatistics = false;

  showCharts(): void {
    this.showStatistics = !this.showStatistics;
  }
}
