import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { SliderSectionConfig } from './slider-section.config';
import { BaseSectionComponent } from '../_base/base-section.component';
import { WebsiteService } from '@navus/core/services/website.service';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { WebsiteControlService } from '../../services/website-control.service';

@Component({
  selector: 'nv-slider-section',
  templateUrl: './slider-section.component.html'
})
export class SliderSectionComponent extends BaseSectionComponent implements OnInit, OnDestroy {
  @Input() data: WebsitePageSection;
  configuration: SliderSectionConfig = new SliderSectionConfig();

  slides: any[];
  slideConfig = {
    'slidesToShow': 1,
    'autoplay': true,
    'autoplaySpeed': 5000,
    'slidesToScroll': 1,
    'infinite': true,
    'dots': true,
    'adaptiveHeight' : true,
    'dotsClass': 'nvt-dots',
    'centerMode': true,
    'centerPadding': '',
    'prevArrow': '<a class="nv-slick-arrow-prev"></a>',
    'nextArrow': '<a class="nv-slick-arrow-next"></a>'
  };

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService,
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );
  }

  ngOnInit() {
    this.configuration = new SliderSectionConfig(this.data.configuration);
  }

  ngOnDestroy() {

  }

}
