import { Component } from '@angular/core';

@Component({
    selector: 'app-live-stage-speaker-config',
    templateUrl: './live-stage-speaker-config.component.html'
})
export class LiveStageSpeakerConfigComponent {

    constructor() { }

}
