export class Message {
  editedAt?: number;
  deleted?: boolean;
  createdBy: number;
  createTime: number;
  index: number;
  uid: string;
  text: string;
  relatedMessageText?: string;
  relatedMessageId?: string;
  relatedMessageCreator?: number;
  approved?: boolean;
  replied?: boolean;
  upVotes?: number[];
  upVotesCount?: number;
}

export class Room {
  latestMessage?: Message;
  messageCount?: number;
  imageUrl?: string;
  members?: number[];
  type: RoomType;
  subscribers?: number[];
  uid: string;
  name?: string;
  autoJoin?: boolean;
}

export class ChatUser {
  avatarUrl: string;
  edited?: number;
  messageLog?: {
    [groupId: string]: number;
  };
  name: string;
  userId: number;
  visible: boolean;
  activeOnConferences: number[];
}

export enum RoomType {
  PUBLIC_GROUP = 'public_group',
  PUBLIC_CHAT = 'public_chat',
  ONE_TO_ONE = 'one_to_one',
}

export enum ApproveStatus {
  null = 'null',
  true = 'true',
  false = 'false',
  all = 'all',
  mine = 'mine',
}
