<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Communication</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-8">
                <nv-button color="primary" iconPl="&#xe095;" (click)="add()">Send Notification</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port spaced-bottom">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav vertical-break-tab">
                <ul>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'communication', 'templates']">Templates</a>
                    </li>
                    <li>
                        <a class="active">Notifications</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'communication', 'notification-settings']">Notifications Settings</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <form class="config-search-bar-w-filters" [formGroup]="formFilter" autocomplete="off">
        <div class="sbwf-port">
            <input type="text" placeholder="Search..."
                   formControlName="search_term" (change)="getNotifications()">
            <span class="sbwf-outline"></span>
        </div><!-- /.sbwf-port -->
    </form><!-- /.config-search-bar-w-filters -->
</div>
<div class="nv-config-content-port" nvInfiniteScroll (scrolled)="getNotifications(true)">
    <div class="nv-global-loading on" [class.on]="notificationLoading">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <section class="nv-section">
        <div class="nv-port">
            <div  *ngIf="pagination.total === 0">
                <div class="nv-empty-state">
                    <div class="img">
                        <img src="../../../../assets/empty-states/empty-state-sessions.svg" alt="">
                    </div>
                    <h4 class="title">No push notifications!</h4>
                    <p class="text">Once you send push notification, you'll see them here.</p>
                    <nv-button (click)="add()">Send push notification</nv-button>
                </div>
            </div>
            <table class="nv-data-table break-lap">
                <tr *ngFor="let notification of notifications">
                    <td class="dt-cell nv-clickable" (click)="edit(notification)">
                        <label>TITLE</label>
                        <strong>{{ notification.title }}</strong>
                    </td><!-- /.dt-cell -->
                    <td class="dt-cell nv-clickable" (click)="edit(notification)">
                        <label>TYPE</label>
                        <strong>{{ notification.type | titlecase }}</strong>
                    </td><!-- /.dt-cell -->
                    <td class="dt-cell nv-clickable" (click)="edit(notification)">
                        <label>SENT</label>
                        <strong>{{ notification.sent_number }}</strong>
                    </td><!-- /.dt-cell -->
                    <td class="dt-cell nv-clickable" (click)="edit(notification)">
                        <label>DELIVERD</label>
                        <strong>{{ notification.receivers_number }}</strong>
                    </td><!-- /.dt-cell -->
                    <td class="dt-cell nv-clickable" (click)="edit(notification)">
                        <label>STATUS</label>
                        <strong>{{ notification.sent ? 'Sent' : 'Scheduled for ' + notification.schedule_time }}</strong>
                    </td><!-- /.dt-cell -->
                    <td class="dt-actions">
                        <div class="nv-cell-actions">
                                    <span class="nv-cell-action-trigger"
                                          [class.on]="optionsShown === notification.id"
                                          (click)="openOptions(notification)"></span>
                            <nav nvOffClick (offClick)="closeOptions()">
                                <ul>
                                    <li (click)="edit(notification)">
                                        <a>Edit</a>
                                    </li>
                                    <li *ngIf="notification.status !== 'archived'" (click)="archive(notification)">
                                        <a>Archive</a>
                                    </li>
                                    <li class="delete" (click)="delete(notification)">
                                        <a>Delete</a>
                                    </li>
                                </ul>
                            </nav>
                        </div><!-- /.nv-cell-actions -->
                    </td>
                </tr>
            </table>
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->
</div><!-- /.config-layout-lower-fixed -->
