<section class="nv-live-stage-config-panel speaker-config"><!-- < !! NEW CLASS 'speaker-config' -->
    <div class="nv-stage-config-layout-options">
        <span>layout options</span>
        <ul>
            <li class="lo-type1 active" title="Equal panels space"></li>
            <li class="lo-type2" title="Enhance left panel"></li>
            <li class="lo-type3" title="Enhance right panel"></li>
        </ul>
    </div><!-- /.nv-stage-config-layout-options -->
    <div class="nv-live-stage-config-layout">
        <div class="nv-live-stage-config-panes">
            <div class="nv-live-stage-config-left-pane">
                <div class="nv-ls-config-feature-panel">
                    <span class="nv-close-ls-config-feature-panel"></span>
                    <div class="nv-ls-config-toolbox">
                        <header class="nv-ls-config-toolbox-header">
                            <h3>Video Conferencing</h3>
                        </header><!-- /.nv-ls-config-toolbox-header -->
                        <div class="nv-ls-config-toolbox-body">
                            <div class="nv-ls-config-toolbox-body-port">
                                <div class="nv-ls-conferencing-rooms">
                                    <div class="nv-ls-conferencing-room live"><!-- < additional class 'active' -->
                                        <div class="nv-ls-conferencing-room-txt">
                                            <h4>Live room</h4>
                                            <p>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            <p class="nv-ls-crt-btn"><a href class="nv-btn medium enter-live" data-icon-pl="&#xe94a;" title="Personal room">Enter live</a></p>
                                        </div><!-- /.nv-ls-conferencing-room-txt -->
                                    </div><!-- /.nv-ls-conferencing-room live -->
                                    <div class="nv-ls-conferencing-side-rooms">
                                        <div class="nv-ls-conferencing-room side">
                                            <div class="nv-ls-conferencing-room-txt">
                                                <h4>Waiting room</h4>
                                                <p>Duis aute irure dolor in reprehender in voluptate velit esse.</p>
                                                <p class="nv-ls-crt-btn"><a href class="nv-btn small primary" title="Waiting room">Waiting room</a></p>
                                            </div><!-- /.nv-ls-conferencing-room-txt -->
                                        </div><!-- /.nv-ls-conferencing-room -->
                                        <div class="nv-ls-conferencing-room side">
                                            <div class="nv-ls-conferencing-room-txt">
                                                <h4>Personal room</h4>
                                                <p>Sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                                <p class="nv-ls-crt-btn"><a href class="nv-btn small primary" title="Personal room">Personal room</a></p>
                                            </div><!-- /.nv-ls-conferencing-room-txt -->
                                        </div><!-- /.nv-ls-conferencing-room -->
                                    </div><!-- /.nv-ls-conferencing-side-rooms -->
                                </div><!-- /.nv-ls-conferencing-rooms -->
                            </div><!-- /.nv-ls-config-toolbox-body-port -->
                        </div><!-- /.nv-ls-config-toolbox-body -->
                    </div><!-- /.nv-ls-config-toolbox -->
                </div><!-- /.nv-ls-config-feature-panel -->


                <div class="nv-ls-config-feature-panel">
                    <span class="nv-close-ls-config-feature-panel"></span>
                    <div class="nv-ls-config-toolbox">
                        <header class="nv-ls-config-toolbox-header">
                            <h3>My agenda</h3>
                        </header><!-- /.nv-ls-config-toolbox-header -->
                        <div class="nv-ls-config-toolbox-body">
                            <div class="nv-ls-config-toolbox-body-port">

                                <div class="nv-ls-planner">
                                    <div class="nv-ls-planner-filter">
                                        <div class="nv-form-item select-item">
                                            <label>
                                                <select>
                                                    <option>Novemebr 21</option>
                                                    <option>Novemebr 22</option>
                                                    <option>Novemebr 23</option>
                                                </select>
                                                <span class="label-title">Novemebr 20</span>
                                            </label>
                                        </div><!-- /nv-form-item -->
                                    </div><!-- /.nv-ls-planner-filter -->
                                    <div class="nv-planner-item"><header class="nv-planner-header" ng-reflect-ng-class="[object Object]"><div class="ph-time"> 09:00 <!--ng-container--><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--></div><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--><div class="ph-body"><p translate="" class="ph-ls" ng-reflect-translate="">livestream</p><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }-->&nbsp; <p translate="" class="ph-ls" ng-reflect-translate="" style="background-color: rgb(152, 90, 219);">Live in Geneva</p><!--bindings={
                                      "ng-reflect-ng-if": "[object Object]"
                                    }--><h3><!--bindings={
                                      "ng-reflect-ng-if": "false"
                                    }--><!--bindings={}--><a>Break Out Session</a></h3><div class="ph-meta"><span class="phm-time-frame"> 09:00 - 11:00 <!--bindings={
                                      "ng-reflect-ng-if": null
                                    }--><!--bindings={
                                      "ng-reflect-ng-if": null
                                    }--></span><span class="phm-cat"><!--bindings={
                                      "ng-reflect-ng-if": null
                                    }-->  </span><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--></div><!--bindings={
                                      "ng-reflect-ng-if": ""
                                    }--><div class="nv-participant-row"><label>Chairpersons:</label><div class="nv-participant"><span class="p-img"><span style="background-image: url(&quot;https://service.e-materials.com/storage/resources/navus/per/persona29.jpg&quot;);"></span></span><!--bindings={
                                      "ng-reflect-ng-if": "https://service.e-materials.co"
                                    }--><div class="p-title-name"><a>Dr.&nbsp;Smith Dickens</a></div></div><!--bindings={
                                      "ng-reflect-ng-for-of": "[object Object]"
                                    }--></div><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--></div></header><div class="nv-planner-sessions"><!--bindings={
                                      "ng-reflect-ng-if": "false"
                                    }--><div class="ps-item"><div class="psi-time"> 17:00 </div><div class="psi-body"><div class="nv-participant"><div class="p-title-name"><div class="psi-features"><p translate="" class="psi-ls" ng-reflect-translate="">livestream</p><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--><!--bindings={
                                      "ng-reflect-ng-if": "false"
                                    }--></div><h4><a>Explore demo website</a></h4><div class="nv-participants-box"><p><span class="p-img"><span style="background-image: url(&quot;https://service.e-materials.com/storage/resources/navus/per/persona24.jpg&quot;);"></span></span><!--bindings={
                                      "ng-reflect-ng-if": "https://service.e-materials.co"
                                    }--><a>Dr.&nbsp;<!--ng-container--><!--bindings={
                                      "ng-reflect-ng-if": "Dr."
                                    }--><span>Mia Hills</span></a></p><p><span class="p-img"><span style="background-image: url(&quot;https://service.e-materials.com/storage/resources/navus/per/persona29.jpg&quot;);"></span></span><!--bindings={
                                      "ng-reflect-ng-if": "https://service.e-materials.co"
                                    }--><a>Dr.&nbsp;<!--ng-container--><!--bindings={
                                      "ng-reflect-ng-if": "Dr."
                                    }--><span>Smith Dickens</span></a></p><p><span class="p-img"><span style="background-image: url(&quot;https://service.e-materials.com/storage/resources/navus/su-/su-san-lee-vft8w5vfhw8-unsplash.jpg&quot;);"></span></span><!--bindings={
                                      "ng-reflect-ng-if": "https://service.e-materials.co"
                                    }--><a><!--bindings={
                                      "ng-reflect-ng-if": null
                                    }--><span>Speaker Account</span></a></p><!--bindings={
                                      "ng-reflect-ng-for-of": "[object Object],[object Object"
                                    }--></div><!--bindings={
                                      "ng-reflect-ng-if": "[object Object]"
                                    }--><!--bindings={
                                      "ng-reflect-ng-if": "false"
                                    }--><!--bindings={
                                      "ng-reflect-ng-if": "false"
                                    }--></div></div></div><div class="psi-tools"></div><div class="nv-bookmark-trigger nv-clickable" title="Bookmark this presentation"></div></div><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--><!--ng-container--><!--bindings={
                                      "ng-reflect-ng-for-of": "[object Object]"
                                    }--><!--ng-container--><!--bindings={
                                      "ng-reflect-ng-if": "[object Object]"
                                    }--></div><!--ng-container--><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }-->
                                    </div>
                                    <div class="nv-planner-item"><header class="nv-planner-header" ng-reflect-ng-class="[object Object]"><div class="ph-time"> 09:00 <!--ng-container--><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--></div><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--><div class="ph-body"><p translate="" class="ph-ls" ng-reflect-translate="">livestream</p><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }-->&nbsp; <p translate="" class="ph-ls" ng-reflect-translate="" style="background-color: rgb(152, 90, 219);">Live in Geneva</p><!--bindings={
                                      "ng-reflect-ng-if": "[object Object]"
                                    }--><h3><!--bindings={
                                      "ng-reflect-ng-if": "false"
                                    }--><!--bindings={}--><a>Break Out Session</a></h3><div class="ph-meta"><span class="phm-time-frame"> 09:00 - 11:00 <!--bindings={
                                      "ng-reflect-ng-if": null
                                    }--><!--bindings={
                                      "ng-reflect-ng-if": null
                                    }--></span><span class="phm-cat"><!--bindings={
                                      "ng-reflect-ng-if": null
                                    }-->  </span><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--></div><!--bindings={
                                      "ng-reflect-ng-if": ""
                                    }--><div class="nv-participant-row"><label>Chairpersons:</label><div class="nv-participant"><span class="p-img"><span style="background-image: url(&quot;https://service.e-materials.com/storage/resources/navus/per/persona29.jpg&quot;);"></span></span><!--bindings={
                                      "ng-reflect-ng-if": "https://service.e-materials.co"
                                    }--><div class="p-title-name"><a>Dr.&nbsp;Smith Dickens</a></div></div><!--bindings={
                                      "ng-reflect-ng-for-of": "[object Object]"
                                    }--></div><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--></div></header><div class="nv-planner-sessions"><!--bindings={
                                      "ng-reflect-ng-if": "false"
                                    }--><div class="ps-item"><div class="psi-time"> 17:00 </div><div class="psi-body"><div class="nv-participant"><div class="p-title-name"><div class="psi-features"><p translate="" class="psi-ls" ng-reflect-translate="">livestream</p><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--><!--bindings={
                                      "ng-reflect-ng-if": "false"
                                    }--></div><h4><a>Explore demo website</a></h4><div class="nv-participants-box"><p><span class="p-img"><span style="background-image: url(&quot;https://service.e-materials.com/storage/resources/navus/per/persona24.jpg&quot;);"></span></span><!--bindings={
                                      "ng-reflect-ng-if": "https://service.e-materials.co"
                                    }--><a>Dr.&nbsp;<!--ng-container--><!--bindings={
                                      "ng-reflect-ng-if": "Dr."
                                    }--><span>Mia Hills</span></a></p><p><span class="p-img"><span style="background-image: url(&quot;https://service.e-materials.com/storage/resources/navus/per/persona29.jpg&quot;);"></span></span><!--bindings={
                                      "ng-reflect-ng-if": "https://service.e-materials.co"
                                    }--><a>Dr.&nbsp;<!--ng-container--><!--bindings={
                                      "ng-reflect-ng-if": "Dr."
                                    }--><span>Smith Dickens</span></a></p><p><span class="p-img"><span style="background-image: url(&quot;https://service.e-materials.com/storage/resources/navus/su-/su-san-lee-vft8w5vfhw8-unsplash.jpg&quot;);"></span></span><!--bindings={
                                      "ng-reflect-ng-if": "https://service.e-materials.co"
                                    }--><a><!--bindings={
                                      "ng-reflect-ng-if": null
                                    }--><span>Speaker Account</span></a></p><!--bindings={
                                      "ng-reflect-ng-for-of": "[object Object],[object Object"
                                    }--></div><!--bindings={
                                      "ng-reflect-ng-if": "[object Object]"
                                    }--><!--bindings={
                                      "ng-reflect-ng-if": "false"
                                    }--><!--bindings={
                                      "ng-reflect-ng-if": "false"
                                    }--></div></div></div><div class="psi-tools"></div><div class="nv-bookmark-trigger nv-clickable" title="Bookmark this presentation"></div></div><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--><!--ng-container--><!--bindings={
                                      "ng-reflect-ng-for-of": "[object Object]"
                                    }--><!--ng-container--><!--bindings={
                                      "ng-reflect-ng-if": "[object Object]"
                                    }--></div><!--ng-container--><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }-->
                                    </div>
                                    <div class="nv-planner-item"><header class="nv-planner-header" ng-reflect-ng-class="[object Object]"><div class="ph-time"> 09:00 <!--ng-container--><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--></div><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--><div class="ph-body"><p translate="" class="ph-ls" ng-reflect-translate="">livestream</p><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }-->&nbsp; <p translate="" class="ph-ls" ng-reflect-translate="" style="background-color: rgb(152, 90, 219);">Live in Geneva</p><!--bindings={
                                      "ng-reflect-ng-if": "[object Object]"
                                    }--><h3><!--bindings={
                                      "ng-reflect-ng-if": "false"
                                    }--><!--bindings={}--><a>Break Out Session</a></h3><div class="ph-meta"><span class="phm-time-frame"> 09:00 - 11:00 <!--bindings={
                                      "ng-reflect-ng-if": null
                                    }--><!--bindings={
                                      "ng-reflect-ng-if": null
                                    }--></span><span class="phm-cat"><!--bindings={
                                      "ng-reflect-ng-if": null
                                    }-->  </span><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--></div><!--bindings={
                                      "ng-reflect-ng-if": ""
                                    }--><div class="nv-participant-row"><label>Chairpersons:</label><div class="nv-participant"><span class="p-img"><span style="background-image: url(&quot;https://service.e-materials.com/storage/resources/navus/per/persona29.jpg&quot;);"></span></span><!--bindings={
                                      "ng-reflect-ng-if": "https://service.e-materials.co"
                                    }--><div class="p-title-name"><a>Dr.&nbsp;Smith Dickens</a></div></div><!--bindings={
                                      "ng-reflect-ng-for-of": "[object Object]"
                                    }--></div><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--></div></header><div class="nv-planner-sessions"><!--bindings={
                                      "ng-reflect-ng-if": "false"
                                    }--><div class="ps-item"><div class="psi-time"> 17:00 </div><div class="psi-body"><div class="nv-participant"><div class="p-title-name"><div class="psi-features"><p translate="" class="psi-ls" ng-reflect-translate="">livestream</p><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--><!--bindings={
                                      "ng-reflect-ng-if": "false"
                                    }--></div><h4><a>Explore demo website</a></h4><div class="nv-participants-box"><p><span class="p-img"><span style="background-image: url(&quot;https://service.e-materials.com/storage/resources/navus/per/persona24.jpg&quot;);"></span></span><!--bindings={
                                      "ng-reflect-ng-if": "https://service.e-materials.co"
                                    }--><a>Dr.&nbsp;<!--ng-container--><!--bindings={
                                      "ng-reflect-ng-if": "Dr."
                                    }--><span>Mia Hills</span></a></p><p><span class="p-img"><span style="background-image: url(&quot;https://service.e-materials.com/storage/resources/navus/per/persona29.jpg&quot;);"></span></span><!--bindings={
                                      "ng-reflect-ng-if": "https://service.e-materials.co"
                                    }--><a>Dr.&nbsp;<!--ng-container--><!--bindings={
                                      "ng-reflect-ng-if": "Dr."
                                    }--><span>Smith Dickens</span></a></p><p><span class="p-img"><span style="background-image: url(&quot;https://service.e-materials.com/storage/resources/navus/su-/su-san-lee-vft8w5vfhw8-unsplash.jpg&quot;);"></span></span><!--bindings={
                                      "ng-reflect-ng-if": "https://service.e-materials.co"
                                    }--><a><!--bindings={
                                      "ng-reflect-ng-if": null
                                    }--><span>Speaker Account</span></a></p><!--bindings={
                                      "ng-reflect-ng-for-of": "[object Object],[object Object"
                                    }--></div><!--bindings={
                                      "ng-reflect-ng-if": "[object Object]"
                                    }--><!--bindings={
                                      "ng-reflect-ng-if": "false"
                                    }--><!--bindings={
                                      "ng-reflect-ng-if": "false"
                                    }--></div></div></div><div class="psi-tools"></div><div class="nv-bookmark-trigger nv-clickable" title="Bookmark this presentation"></div></div><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--><!--ng-container--><!--bindings={
                                      "ng-reflect-ng-for-of": "[object Object]"
                                    }--><!--ng-container--><!--bindings={
                                      "ng-reflect-ng-if": "[object Object]"
                                    }--></div><!--ng-container--><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }-->
                                    </div>
                                    <div class="nv-planner-item"><header class="nv-planner-header" ng-reflect-ng-class="[object Object]"><div class="ph-time"> 09:00 <!--ng-container--><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--></div><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--><div class="ph-body"><p translate="" class="ph-ls" ng-reflect-translate="">livestream</p><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }-->&nbsp; <p translate="" class="ph-ls" ng-reflect-translate="" style="background-color: rgb(152, 90, 219);">Live in Geneva</p><!--bindings={
                                      "ng-reflect-ng-if": "[object Object]"
                                    }--><h3><!--bindings={
                                      "ng-reflect-ng-if": "false"
                                    }--><!--bindings={}--><a>Break Out Session</a></h3><div class="ph-meta"><span class="phm-time-frame"> 09:00 - 11:00 <!--bindings={
                                      "ng-reflect-ng-if": null
                                    }--><!--bindings={
                                      "ng-reflect-ng-if": null
                                    }--></span><span class="phm-cat"><!--bindings={
                                      "ng-reflect-ng-if": null
                                    }-->  </span><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--></div><!--bindings={
                                      "ng-reflect-ng-if": ""
                                    }--><div class="nv-participant-row"><label>Chairpersons:</label><div class="nv-participant"><span class="p-img"><span style="background-image: url(&quot;https://service.e-materials.com/storage/resources/navus/per/persona29.jpg&quot;);"></span></span><!--bindings={
                                      "ng-reflect-ng-if": "https://service.e-materials.co"
                                    }--><div class="p-title-name"><a>Dr.&nbsp;Smith Dickens</a></div></div><!--bindings={
                                      "ng-reflect-ng-for-of": "[object Object]"
                                    }--></div><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--></div></header><div class="nv-planner-sessions"><!--bindings={
                                      "ng-reflect-ng-if": "false"
                                    }--><div class="ps-item"><div class="psi-time"> 17:00 </div><div class="psi-body"><div class="nv-participant"><div class="p-title-name"><div class="psi-features"><p translate="" class="psi-ls" ng-reflect-translate="">livestream</p><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--><!--bindings={
                                      "ng-reflect-ng-if": "false"
                                    }--></div><h4><a>Explore demo website</a></h4><div class="nv-participants-box"><p><span class="p-img"><span style="background-image: url(&quot;https://service.e-materials.com/storage/resources/navus/per/persona24.jpg&quot;);"></span></span><!--bindings={
                                      "ng-reflect-ng-if": "https://service.e-materials.co"
                                    }--><a>Dr.&nbsp;<!--ng-container--><!--bindings={
                                      "ng-reflect-ng-if": "Dr."
                                    }--><span>Mia Hills</span></a></p><p><span class="p-img"><span style="background-image: url(&quot;https://service.e-materials.com/storage/resources/navus/per/persona29.jpg&quot;);"></span></span><!--bindings={
                                      "ng-reflect-ng-if": "https://service.e-materials.co"
                                    }--><a>Dr.&nbsp;<!--ng-container--><!--bindings={
                                      "ng-reflect-ng-if": "Dr."
                                    }--><span>Smith Dickens</span></a></p><p><span class="p-img"><span style="background-image: url(&quot;https://service.e-materials.com/storage/resources/navus/su-/su-san-lee-vft8w5vfhw8-unsplash.jpg&quot;);"></span></span><!--bindings={
                                      "ng-reflect-ng-if": "https://service.e-materials.co"
                                    }--><a><!--bindings={
                                      "ng-reflect-ng-if": null
                                    }--><span>Speaker Account</span></a></p><!--bindings={
                                      "ng-reflect-ng-for-of": "[object Object],[object Object"
                                    }--></div><!--bindings={
                                      "ng-reflect-ng-if": "[object Object]"
                                    }--><!--bindings={
                                      "ng-reflect-ng-if": "false"
                                    }--><!--bindings={
                                      "ng-reflect-ng-if": "false"
                                    }--></div></div></div><div class="psi-tools"></div><div class="nv-bookmark-trigger nv-clickable" title="Bookmark this presentation"></div></div><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }--><!--ng-container--><!--bindings={
                                      "ng-reflect-ng-for-of": "[object Object]"
                                    }--><!--ng-container--><!--bindings={
                                      "ng-reflect-ng-if": "[object Object]"
                                    }--></div><!--ng-container--><!--bindings={
                                      "ng-reflect-ng-if": "true"
                                    }-->
                                    </div>
                                </div><!-- /.nv-ls-planner -->

                            </div><!-- /.nv-ls-config-toolbox-body-port -->
                        </div><!-- /.nv-ls-config-toolbox-body -->
                    </div><!-- /.nv-ls-config-toolbox -->
                </div><!-- /.nv-ls-config-feature-panel -->

            </div><!-- /.nv-live-stage-config-left-pane -->
            <div class="nv-live-stage-config-right-pane">
                <div class="nv-ls-config-feature-panel">
                    <span class="nv-close-ls-config-feature-panel"></span>
                    <div class="nv-ls-config-toolbox">
                        <header class="nv-ls-config-toolbox-header">
                            <h3>Slides</h3>
                        </header><!-- /.nv-ls-config-toolbox-header -->
                        <div class="nv-ls-config-toolbox-body">
                            <div class="nv-ls-config-toolbox-body-port">
                                <div class="nv-ls-slides">
                                    <p class="nv-ls-config-tip">Please pick a presentation to manage your slides.</p>
                                    <table class="nv-sam-table">
                                        <tr>
                                            <td data-label="Presentation">
                                                <p title="The life and death of diagram nose">The life and death of diagram nose</p>
                                            </td>
                                            <td data-label="Session">
                                                <p title="Plenary session 01 - The 10th anniversary"><a href>Plenary session 01 - The 10th anniversary</a></p>
                                            </td>
                                            <td data-label="Time">
                                                <p class="date-n-time">
                                                    <strong>19 May 2021</strong>
                                                    <span>08:30 - 12:00</span>
                                                </p>
                                            </td>
                                            <td data-label="Materials">
                                                <div class="material-available">
                                                    <a href class="presentation" title="presentation name"></a>
                                                    <a href class="abstract" title="abstract name"></a>
                                                    <a href class="webcast" title="webcast name"></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td data-label="Presentation">
                                                <p title="The life and death of diagram nose">The life and death of diagram nose</p>
                                            </td>
                                            <td data-label="Session">
                                                <p title="Plenary session 01 - The 10th anniversary"><a href>Plenary session 01 - The 10th anniversary</a></p>
                                            </td>
                                            <td data-label="Time">
                                                <p class="date-n-time">
                                                    <strong>24 May 2021</strong>
                                                    <span>13:30 - 17:00</span>
                                                </p>
                                            </td>
                                            <td data-label="Materials">
                                                <div class="material-available">
                                                    <a href class="presentation" title="presentation name"></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td data-label="Presentation">
                                                <p title="The life and death of diagram nose">The life and death of diagram nose</p>
                                            </td>
                                            <td data-label="Session">
                                                <p title="Plenary session 01 - The 10th anniversary"><a href>Plenary session 01 - The 10th anniversary</a></p>
                                            </td>
                                            <td data-label="Time">
                                                <p class="date-n-time">
                                                    <strong>26 May 2021</strong>
                                                    <span>10:30 - 11:00</span>
                                                </p>
                                            </td>
                                            <td data-label="Materials">
                                                <div class="material-available">
                                                    <a href class="abstract" title="abstract name"></a>
                                                    <a href class="webcast" title="webcast name"></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td data-label="Presentation">
                                                <p title="The life and death of diagram nose">The life and death of diagram nose</p>
                                            </td>
                                            <td data-label="Session">
                                                <p title="Plenary session 01 - The 10th anniversary"><a href>Plenary session 01 - The 10th anniversary</a></p>
                                            </td>
                                            <td data-label="Time">
                                                <p class="date-n-time">
                                                    <strong>19 May 2021</strong>
                                                    <span>08:30 - 12:00</span>
                                                </p>
                                            </td>
                                            <td data-label="Materials">
                                                <div class="material-available">
                                                    <a href class="presentation" title="presentation name"></a>
                                                    <a href class="abstract" title="abstract name"></a>
                                                    <a href class="webcast" title="webcast name"></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td data-label="Presentation">
                                                <p title="The life and death of diagram nose">The life and death of diagram nose</p>
                                            </td>
                                            <td data-label="Session">
                                                <p title="Plenary session 01 - The 10th anniversary"><a href>Plenary session 01 - The 10th anniversary</a></p>
                                            </td>
                                            <td data-label="Time">
                                                <p class="date-n-time">
                                                    <strong>24 May 2021</strong>
                                                    <span>13:30 - 17:00</span>
                                                </p>
                                            </td>
                                            <td data-label="Materials">
                                                <div class="material-available">
                                                    <a href class="presentation" title="presentation name"></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td data-label="Presentation">
                                                <p title="The life and death of diagram nose">The life and death of diagram nose</p>
                                            </td>
                                            <td data-label="Session">
                                                <p title="Plenary session 01 - The 10th anniversary"><a href>Plenary session 01 - The 10th anniversary</a></p>
                                            </td>
                                            <td data-label="Time">
                                                <p class="date-n-time">
                                                    <strong>26 May 2021</strong>
                                                    <span>10:30 - 11:00</span>
                                                </p>
                                            </td>
                                            <td data-label="Materials">
                                                <div class="material-available">
                                                    <a href class="abstract" title="abstract name"></a>
                                                    <a href class="webcast" title="webcast name"></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td data-label="Presentation">
                                                <p title="The life and death of diagram nose">The life and death of diagram nose</p>
                                            </td>
                                            <td data-label="Session">
                                                <p title="Plenary session 01 - The 10th anniversary"><a href>Plenary session 01 - The 10th anniversary</a></p>
                                            </td>
                                            <td data-label="Time">
                                                <p class="date-n-time">
                                                    <strong>19 May 2021</strong>
                                                    <span>08:30 - 12:00</span>
                                                </p>
                                            </td>
                                            <td data-label="Materials">
                                                <div class="material-available">
                                                    <a href class="presentation" title="presentation name"></a>
                                                    <a href class="abstract" title="abstract name"></a>
                                                    <a href class="webcast" title="webcast name"></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td data-label="Presentation">
                                                <p title="The life and death of diagram nose">The life and death of diagram nose</p>
                                            </td>
                                            <td data-label="Session">
                                                <p title="Plenary session 01 - The 10th anniversary"><a href>Plenary session 01 - The 10th anniversary</a></p>
                                            </td>
                                            <td data-label="Time">
                                                <p class="date-n-time">
                                                    <strong>24 May 2021</strong>
                                                    <span>13:30 - 17:00</span>
                                                </p>
                                            </td>
                                            <td data-label="Materials">
                                                <div class="material-available">
                                                    <a href class="presentation" title="presentation name"></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td data-label="Presentation">
                                                <p title="The life and death of diagram nose">The life and death of diagram nose</p>
                                            </td>
                                            <td data-label="Session">
                                                <p title="Plenary session 01 - The 10th anniversary"><a href>Plenary session 01 - The 10th anniversary</a></p>
                                            </td>
                                            <td data-label="Time">
                                                <p class="date-n-time">
                                                    <strong>26 May 2021</strong>
                                                    <span>10:30 - 11:00</span>
                                                </p>
                                            </td>
                                            <td data-label="Materials">
                                                <div class="material-available">
                                                    <a href class="abstract" title="abstract name"></a>
                                                    <a href class="webcast" title="webcast name"></a>
                                                </div>
                                            </td>
                                        </tr>
                                    </table><!-- /.nv-sam-table -->
                                </div><!-- /.nv-ls-slides -->
                            </div><!-- /.nv-ls-config-toolbox-body-port -->
                        </div><!-- /.nv-ls-config-toolbox-body -->
                    </div><!-- /.nv-ls-config-toolbox -->
                </div><!-- /.nv-ls-config-feature-panel -->

                <div class="nv-ls-config-feature-panel">
                    <span class="nv-close-ls-config-feature-panel"></span>
                    <div class="nv-ls-config-toolbox">
                        <header class="nv-ls-config-toolbox-header">
                            <h3>Q&A</h3>
                        </header><!-- /.nv-ls-config-toolbox-header -->
                        <div class="nv-ls-config-toolbox-body">
                            <h4>Poteraj ovde Q&A pa opet ja...</h4>
                        </div><!-- /nv-ls-config-toolbox-body -->
                    </div>
                </div><!-- /.nv-ls-config-feature-panel -->
            </div><!-- /.nv-live-stage-config-right-pane -->
        </div><!-- /.nv-live-stage-config-panes -->
        <div class="nv-live-stage-config-bar">
            <div class="nv-live-stage-config-bar-panel">
            </div><!-- /.nv-live-stage-config-bar-panel -->

            <div class="nv-live-stage-config-bar-panel">
                <ul class="nv-live-stage-tool-list">
                    <li><a href="" class="video-conference">Video Conferencing</a></li>
                    <li><a href="" class="preview-stage">Preview Stage</a></li>
                    <li><a href="" class="slides">Slides</a></li>
                    <li><a href="" class="voting-screen">Voting</a></li>
                    <li><a href="" class="my-agenda">My Agenda</a></li>
                    <li><a href="" class="q-n-a">Q&A</a></li>
                    <li><a href="" class="recordings">Recordings</a></li>
                </ul>
            </div><!-- /.nv-live-stage-config-bar-panel -->
            <div class="nv-live-stage-config-bar-panel">
                <ul class="nv-live-stage-tool-list">
                    <li><a href="" class="chat">Chat</a></li>
                </ul>
            </div><!-- /.nv-live-stage-config-bar-panel -->
        </div><!-- /.nv-live-stage-config-bar -->
    </div><!-- /.nv-live-stage-config-layout -->
</section><!-- /.nv-live-stage-config-panel -->
