import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ConferenceService } from '@navus/core/services/conference.service';
import { Room } from '@navus/core/classes/chat';

@Component({
  selector: 'main [nv-voting-settings-page]',
  templateUrl: './voting-settings-page.component.html'
})
export class VotingSettingsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;

  votingSettingsForm: FormGroup;
  votingBlocks: Room[];

  loadingChatSettings: boolean = true;
  savingChatSettings: boolean = false;
  unsavedChanges: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private modalService: ModalService,
    private conferenceService: ConferenceService,
  ) {
  }

  ngOnInit() {
    this.initForm();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getVotingSettings();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.votingSettingsForm = this.formBuilder.group({
      enabled: [false],
    });
  }

  getVotingSettings() {
    this.loadingChatSettings = true;
    this.conferenceService
      .getOrganizationConference(this.organizationId, this.conferenceId)
      .subscribe(
        (response) => {
          const conference = response.data;
          this.votingSettingsForm.patchValue(conference.settings?.voting_settings);
          this.loadingChatSettings = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.loadingChatSettings = false;
        }
      );
  }

  save() {
    if (this.votingSettingsForm.invalid) {
      return;
    }

    this.savingChatSettings = true;
    const params = { settings: { voting_settings: this.votingSettingsForm.value } };
    this.conferenceService
      .updateOrganizationConference(this.organizationId, this.conferenceId, params)
      .subscribe(
        () => {
          this.toastService.success('Voting settings successfully saved.', {});
          this.unsavedChanges = false;
          this.savingChatSettings = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.savingChatSettings = false;
        }
      );
  }

}
