<header class="nv-temp-header nv-section website-279" ng-reflect-ng-class="[object Object]"><div class="nv-row space-between"><div class="nv-column desk-initial v-middle"><div class="nv-column-content"><div class="nv-template-logo"><a href="/" routerlink="/" ng-reflect-router-link="/" title="Navus Hybrid Demo Event"><img alt="Navus Hybrid Demo Event" src="https://service.e-materials.com/storage/resources/navus_ag/nav/navus-demo-logo.jpg"><!--bindings={
  "ng-reflect-ng-if": "https://service.e-materials.co"
}--></a></div></div></div><div class="nv-column desk-initial v-stretch" style="display: flex;"><div class="nv-column-content"><div class="nv-template-menu-n-profile">


    <nav class="nv-template-menu"><ul><li class="nav-page-580"><a class="nav-link" ng-reflect-router-link="/580/page/home" ng-reflect-router-link-active="active" href="/580/page/home"><span>Home</span></a><!--bindings={
  "ng-reflect-ng-if": "true"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={}--></li><li class="nav-page-582"><a class="nav-link active" ng-reflect-router-link="/search" ng-reflect-router-link-active="active" href="/search"><span>On Demand</span></a><!--bindings={
  "ng-reflect-ng-if": "true"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={}--></li><li class="nav-page-583"><a class="nav-link" ng-reflect-router-link="/program" ng-reflect-router-link-active="active" href="/program"><span>Program</span></a><!--bindings={
  "ng-reflect-ng-if": "true"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={}--></li><li class="nav-page-581"><a class="nav-link" ng-reflect-router-link="/live-stage" ng-reflect-router-link-active="active" href="/live-stage"><span>Live Stage</span></a><!--bindings={
  "ng-reflect-ng-if": "true"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={}--></li><li class="nav-page-726"><a class="nav-link" ng-reflect-router-link="/speakers" ng-reflect-router-link-active="active" href="/speakers"><span>Speakers</span></a><!--bindings={
  "ng-reflect-ng-if": "true"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={}--></li><li class="nav-page-584"><a class="nav-link" ng-reflect-router-link="/sponsors" ng-reflect-router-link-active="active" href="/sponsors"><span>Sponsors </span></a><!--bindings={
  "ng-reflect-ng-if": "true"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={}--></li><!--bindings={
  "ng-reflect-ng-for-of": "[object Object],[object Object"
}--></ul></nav>




    <div class="nv-template-notifications on">
        <div class="nv-notifications-toggler">
            <span>4</span>
        </div><!-- /.nv-notifications-toggler -->
        <div class="nv-notifications-pane">
            <div class="nv-notifications-pane-frame">


                <!-- Toast  Notification -->
<!--                <div class="nv-toast-notification">-->
<!--                    <header>-->
<!--                        <h3>Notification title</h3>-->
<!--                        <span class="close"></span>-->
<!--                    </header>-->
<!--                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam venenatis iaculis nisl, id bibendum orci finibus id. Lorem ipsum dolor sit amet.</p>-->
<!--                    <p><a href class="nv-btn small primary">Action Button</a></p>-->
<!--                </div>&lt;!&ndash; /.nv-toast-notification &ndash;&gt;-->



                <!-- Toast  Notification BADGE -->
<!--                <div class="nv-toast-notification badge">&lt;!&ndash; < new class 'badge' &ndash;&gt;-->
<!--                    <figure><img src="../../assets/badge-attendance-bronze.svg" alt="Badge Bronze"></figure>-->
<!--                    <header>-->
<!--                        <h3>Badge earned!</h3>-->
<!--                        <span class="close"></span>-->
<!--                    </header>-->
<!--                    <p><strong>Time spent on Main Stage</strong></p>-->
<!--                    <p class="medium"><a href title="View badges">View badges</a></p>-->
<!--                </div>&lt;!&ndash; /.nv-toast-notification &ndash;&gt;-->


                <!-- Original Notifications -->
<!--                <header class="nv-notifications-pane-header">-->
<!--                    <h4>Notifications</h4>-->
<!--                    <a class="nv-notifications-mark-read" title="Mark all as read">Mark all as read</a>-->
<!--                </header>&lt;!&ndash; /.nv-notifications-pane-header &ndash;&gt;-->
<!--                <nav class="nv-notifications-list-alt">-->
<!--                    <ul>-->
<!--                        <li>-->
<!--                            <span class="nv-mark-as-read">-->
<!--                                <a></a>-->
<!--                                <em>Mark as read</em>-->
<!--                            </span>-->
<!--                            <time>12:30 - 13:15</time>-->
<!--                            <h3>Notification title</h3>-->
<!--                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam venenatis iaculis nisl, id bibendum orci finibus id. Lorem ipsum dolor sit amet.</p>-->
<!--                            <p><a href class="nv-btn small accent1">Action Button</a></p>-->
<!--                        </li>-->
<!--                        <li class="nv-read">-->
<!--                            <span class="nv-mark-as-read">-->
<!--                                <a></a>-->
<!--                                <em>Unmark as read</em>-->
<!--                            </span>-->
<!--                            <time>12:30 - 13:15</time>-->
<!--                            <h3>Notification title</h3>-->
<!--                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam venenatis iaculis nisl, id bibendum orci finibus id. Lorem ipsum dolor sit amet.</p>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <span class="nv-mark-as-read">-->
<!--                                <a></a>-->
<!--                                <em>Mark as read</em>-->
<!--                            </span>-->
<!--                            <time>12:30 - 13:15</time>-->
<!--                            <h3>Notification title</h3>-->
<!--                            <p>Bibendum orci finibus id. Lorem ipsum dolor sit amet.</p>-->
<!--                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <span class="nv-mark-as-read">-->
<!--                                <a></a>-->
<!--                                <em>Mark as read</em>-->
<!--                            </span>-->
<!--                            <time>12:30 - 13:15</time>-->
<!--                            <h3>Notification title</h3>-->
<!--                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam venenatis iaculis nisl, id bibendum orci finibus id. Lorem ipsum dolor sit amet.</p>-->
<!--                            <p><a href class="nv-btn small accent1">Action Button</a></p>-->
<!--                        </li>-->
<!--                        <li class="nv-read">-->
<!--                            <span class="nv-mark-as-read">-->
<!--                                <a></a>-->
<!--                                <em>Unmark as read</em>-->
<!--                            </span>-->
<!--                            <time>12:30 - 13:15</time>-->
<!--                            <h3>Notification title</h3>-->
<!--                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam venenatis iaculis nisl, id bibendum orci finibus id. Lorem ipsum dolor sit amet.</p>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <span class="nv-mark-as-read">-->
<!--                                <a></a>-->
<!--                                <em>Mark as read</em>-->
<!--                            </span>-->
<!--                            <time>12:30 - 13:15</time>-->
<!--                            <h3>Notification title</h3>-->
<!--                            <p>Bibendum orci finibus id. Lorem ipsum dolor sit amet.</p>-->
<!--                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </nav>&lt;!&ndash; /.nv-notifications-list-alt &ndash;&gt;-->
            </div><!-- /.nv-notifications-pane-frame -->
        </div><!-- /.nv-notifications-pane -->
    </div><!-- /.nv-template-notifications -->















<div class="nv-template-profile">
<div nvoffclick="" class="nv-btn small accent2 radius-100 icon nv-bar-profile-menu"> DK <nav><ul><li><a ng-reflect-router-link="/profile" href="/profile">Profile</a></li><li><a translate="" ng-reflect-translate="">Sign Out</a></li></ul></nav></div><!--bindings={
  "ng-reflect-ng-if": "[object Object]"
}--></div></div></div></div><div class="nv-column desk-none tab-initial v-stretch"><div class="nv-column-content"><div class="nv-touch-menu-trigger"></div></div></div></div></header>

<div class="wrap">

    <!-- PUG Gallery -->

    <section class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row large-spaced gap-30 propagate">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
<!--                        <div class="pug-gall-preamble">-->
<!--                            <h2>PUG CHALLENGE 2022 Reminiscence area</h2>-->
<!--                            <h4>Thank you to all attendees and sponsors for this years event. After unexpected technical delays - we are now ready to present this years pictures and videos below</h4>-->
<!--                            <p>First of all we have a collection of pictures from all the attendes that can be watched below:</p>-->
<!--                        </div>&lt;!&ndash; /.pug-gall-preamble &ndash;&gt;-->

                        <div class="pug-gallery">
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/000/0001.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/000/thumbs/0001.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/000/0002.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/000/thumbs/0002.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/000/0003.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/000/thumbs/0003.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/000/0004.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/000/thumbs/0004.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/000/0005.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/000/thumbs/0005.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/000/0006.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/000/thumbs/0006.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/000/0007.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/000/thumbs/0007.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/000/0008.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/000/thumbs/0008.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/000/0009.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/000/thumbs/0009.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/001/0010.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/001/thumbs/0010.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/001/0011.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/001/thumbs/0011.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/001/0012.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/001/thumbs/0012.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/001/0013.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/001/thumbs/0013.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/001/0014.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/001/thumbs/0014.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/001/0015.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/001/thumbs/0015.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/001/0016.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/001/thumbs/0016.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/001/0018.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/001/thumbs/0018.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/001/0019.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/001/thumbs/0019.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/002/0020.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/002/thumbs/0020.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/002/0021.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/002/thumbs/0021.jpg"/></a></div>


                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/002/0022.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/002/thumbs/0022.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/002/0023.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/002/thumbs/0023.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/002/0024.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/002/thumbs/0024.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/002/0025.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/002/thumbs/0025.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/002/0026.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/002/thumbs/0026.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/002/0027.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/002/thumbs/0027.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/002/0028.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/002/thumbs/0028.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/002/0029.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/002/thumbs/0029.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/003/0030.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/003/thumbs/0030.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/003/0031.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/003/thumbs/0031.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/003/0032.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/003/thumbs/0032.jpg"/></a></div>

                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/003/0033.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/003/thumbs/0033.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/003/0034.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/003/thumbs/0034.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/003/0035.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/003/thumbs/0035.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/003/0036.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/003/thumbs/0036.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/003/0037.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/003/thumbs/0037.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/003/0039.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/003/thumbs/0039.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/004/0040.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/004/thumbs/0040.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/004/0044.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/004/thumbs/0044.jpg"/></a></div>

                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/005/0051.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/005/thumbs/0051.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/005/0052.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/005/thumbs/0052.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/005/0053.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/005/thumbs/0053.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/005/0054.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/005/thumbs/0054.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/005/0055.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/005/thumbs/0055.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/005/0056.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/005/thumbs/0056.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/005/0057.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/005/thumbs/0057.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/005/0058.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/005/thumbs/0058.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/005/0059.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/005/thumbs/0059.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/006/0060.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/006/thumbs/0060.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/006/0061.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/006/thumbs/0061.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/006/0062.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/006/thumbs/0062.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/006/0063.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/006/thumbs/0063.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/006/0064.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/006/thumbs/0064.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/006/0065.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/006/thumbs/0065.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/006/0066.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/006/thumbs/0066.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/006/0067.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/006/thumbs/0067.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/006/0068.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/006/thumbs/0068.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/006/0069.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/006/thumbs/0069.jpg"/></a></div>

                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/007/0070.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/007/thumbs/0070.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/007/0071.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/007/thumbs/0071.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/007/0072.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/007/thumbs/0072.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/007/0073.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/007/thumbs/0073.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/007/0074.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/007/thumbs/0074.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/007/0075.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/007/thumbs/0075.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/007/0076.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/007/thumbs/0076.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/007/0077.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/007/thumbs/0077.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/007/0078.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/007/thumbs/0078.jpg"/></a></div>

                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/008/0087.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/008/thumbs/0087.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/008/0088.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/008/thumbs/0088.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/008/0089.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/008/thumbs/0089.jpg"/></a></div>


                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/009/0090.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/009/thumbs/0090.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/009/0091.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/009/thumbs/0091.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/009/0092.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/009/thumbs/0092.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/009/0093.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/009/thumbs/0093.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/009/0094.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/009/thumbs/0094.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/009/0095.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/009/thumbs/0095.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/009/0096.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/009/thumbs/0096.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/009/0097.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/009/thumbs/0097.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/009/0098.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/009/thumbs/0098.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/009/0099.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/009/thumbs/0099.jpg"/></a></div>

                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/010/0100.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/010/thumbs/0100.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/010/0101.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/010/thumbs/0101.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/010/0102.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/010/thumbs/0102.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/010/0103.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/010/thumbs/0103.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/010/0104.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/010/thumbs/0104.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/010/0105.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/010/thumbs/0105.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/010/0106.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/010/thumbs/0106.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/010/0107.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/010/thumbs/0107.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/010/0108.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/010/thumbs/0108.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/010/0109.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/010/thumbs/0109.jpg"/></a></div>


                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/011/0111.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/011/thumbs/0111.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/011/0112.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/011/thumbs/0112.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/011/0113.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/011/thumbs/0113.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/011/0114.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/011/thumbs/0114.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/011/0115.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/011/thumbs/0115.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/011/0116.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/011/thumbs/0116.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/011/0117.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/011/thumbs/0117.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/011/0118.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/011/thumbs/0118.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/011/0119.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/011/thumbs/0119.jpg"/></a></div>


                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/012/0120.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/012/thumbs/0120.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/012/0121.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/012/thumbs/0121.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/012/0122.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/012/thumbs/0122.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/012/0123.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/012/thumbs/0123.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/012/0124.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/012/thumbs/0124.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/012/0125.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/012/thumbs/0125.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/012/0126.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/012/thumbs/0126.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/012/0127.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/012/thumbs/0127.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/012/0128.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/012/thumbs/0128.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/012/0129.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/012/thumbs/0129.jpg"/></a></div>

                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/013/0130.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/013/thumbs/0130.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/013/0131.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/013/thumbs/0131.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/013/0132.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/013/thumbs/0132.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/013/0133.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/013/thumbs/0133.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/013/0134.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/013/thumbs/0134.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/013/0135.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/013/thumbs/0135.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/013/0136.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/013/thumbs/0136.jpg"/></a></div>

                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/015/0154.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/015/thumbs/0154.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/015/0155.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/015/thumbs/0155.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/015/0156.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/015/thumbs/0156.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/015/0157.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/015/thumbs/0157.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/015/0158.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/015/thumbs/0158.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/015/0159.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/015/thumbs/0159.jpg"/></a></div>

                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/016/0160.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/016/thumbs/0160.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/016/0161.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/016/thumbs/0161.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/016/0162.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/016/thumbs/0162.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/016/0163.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/016/thumbs/0163.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/016/0164.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/016/thumbs/0164.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/016/0165.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/016/thumbs/0165.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/016/0166.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/016/thumbs/0166.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/016/0167.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/016/thumbs/0167.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/016/0168.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/016/thumbs/0168.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/016/0169.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/016/thumbs/0169.jpg"/></a></div>

                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/017/0170.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/017/thumbs/0170.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/017/0171.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/017/thumbs/0171.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/017/0172.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/017/thumbs/0172.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/017/0173.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/017/thumbs/0173.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/017/0174.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/017/thumbs/0174.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/017/0175.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/017/thumbs/0175.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/017/0178.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/017/thumbs/0178.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/017/0179.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/017/thumbs/0179.jpg"/></a></div>

                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/018/0181.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/018/thumbs/0181.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/018/0182.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/018/thumbs/0182.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/018/0183.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/018/thumbs/0183.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/018/0184.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/018/thumbs/0184.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/018/0185.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/018/thumbs/0185.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/018/0186.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/018/thumbs/0186.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/018/0187.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/018/thumbs/0187.jpg"/></a></div>

                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/019/0190.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/019/thumbs/0190.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/019/0193.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/019/thumbs/0193.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/019/0194.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/019/thumbs/0194.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/019/0195.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/019/thumbs/0195.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/019/0196.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/019/thumbs/0196.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/019/0197.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/019/thumbs/0197.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/019/0198.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/019/thumbs/0198.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/019/0199.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/019/thumbs/0199.jpg"/></a></div>

                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/020/0200.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/020/thumbs/0200.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/020/0202.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/020/thumbs/0202.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/020/0205.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/020/thumbs/0205.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/020/0208.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/020/thumbs/0208.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/020/0209.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/020/thumbs/0209.jpg"/></a></div>


                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/021/0210.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/021/thumbs/0210.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/021/0212.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/021/thumbs/0212.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/021/0213.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/021/thumbs/0213.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/021/0214.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/021/thumbs/0214.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/021/0215.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/021/thumbs/0215.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/021/0216.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/021/thumbs/0216.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/021/0217.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/021/thumbs/0217.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/021/0218.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/021/thumbs/0218.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/021/0219.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/021/thumbs/0219.jpg"/></a></div>

                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/022/0220.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/022/thumbs/0220.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/022/0221.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/022/thumbs/0221.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/022/0222.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/022/thumbs/0222.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/022/0223.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/022/thumbs/0223.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/022/0224.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/022/thumbs/0224.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/022/0225.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/022/thumbs/0225.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/022/0226.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/022/thumbs/0226.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/022/0227.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/022/thumbs/0227.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/022/028.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/022/thumbs/0228.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/022/0229.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/022/thumbs/0229.jpg"/></a></div>

                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/023/0230.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/023/thumbs/0230.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/023/0231.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/023/thumbs/0231.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/023/0232.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/023/thumbs/0232.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/023/0233.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/023/thumbs/0233.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/023/0234.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/023/thumbs/0234.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/023/0235.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/023/thumbs/0235.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/023/0236.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/023/thumbs/0236.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/023/0237.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/023/thumbs/0237.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/023/0238.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/023/thumbs/0238.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/023/0239.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/023/thumbs/0239.jpg"/></a></div>

                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/024/00240.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/024/thumbs/0240.jpg"/></a></div>
                            <div class="nv-pug-img-thumb"><a target="_blank" href="https://service.e-materials.com/storage/resources/pug_challenge/024/00244.jpg"><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/024/thumbs/0244.jpg"/></a></div>
                        </div><!-- /.pug-gallery -->

<!--                        <div class="pug-gallery-fs">-->
<!--                            <div class="nv-pug-img" id="n1"><div class="nv-pug-img-holder"><a href="/playground/#!"></a><img alt="" src="https://service.e-materials.com/storage/resources/pug_challenge/000/0001.jpg"/></div></div>-->
<!--                        </div>&lt;!&ndash; /pug-gallery-fs &ndash;&gt;-->

                    </div><!-- /nv-column-content -->
                </div>
            </div>
        </div>
    </section>


    <section class="nv-section guttered" style="background-color: #f9f9f9;">
        <div class="nv-port">
            <div class="nv-row large-spaced gap-30 propagate">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h2 style="text-align: center;">Leaderboard</h2>
                        <div class="nv-scroll-box">
                            <table class="nv-leaderboard">
                                <tr>
                                    <th>RANK</th>
                                    <th>NAME</th>
                                    <th>BADGES EARNED</th>
                                    <th>LAST EARNED ON</th>
                                    <th>Badges</th>
                                </tr>
                                <tr>
                                    <td class="lb-rank" data-cell-label="RANK">1.</td>
                                    <td data-cell-label="NAME">dr. Sandra G.M</td>
                                    <td data-cell-label="BADGES EARNED">15</td>
                                    <td data-cell-label="LAST EARNED ON">22.07.2022</td>
                                    <td class="lb-badges" data-cell-label="Badges">
                                        <div class="lb-badges-box"> <!-- < order inverted -->
                                            <img src="../../assets/badge-attendance-bronze.svg" alt="Bronze Badge">
                                            <img src="../../assets/badge-attendance-silver.svg" alt="Silver Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                        </div><!-- /.lb-badges-box -->
                                    </td>
                                </tr>
                                <tr>
                                    <td class="lb-rank" data-cell-label="RANK">2.</td>
                                    <td data-cell-label="NAME">Albert W.</td>
                                    <td data-cell-label="BADGES EARNED">12</td>
                                    <td data-cell-label="LAST EARNED ON">20.07.2022</td>
                                    <td class="lb-badges" data-cell-label="Badges">
                                        <div class="lb-badges-box"> <!-- < order inverted -->
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                        </div><!-- /.lb-badges-box -->
                                    </td>
                                </tr>
                                <tr>
                                    <td class="lb-rank" data-cell-label="RANK">3.</td>
                                    <td data-cell-label="NAME">prof. Jonathan Q.</td>
                                    <td data-cell-label="BADGES EARNED">10</td>
                                    <td data-cell-label="LAST EARNED ON">07.08.2022</td>
                                    <td class="lb-badges" data-cell-label="Badges">
                                        <div class="lb-badges-box"> <!-- < order inverted -->
                                            <img src="../../assets/badge-attendance-bronze.svg" alt="Bronze Badge">
                                            <img src="../../assets/badge-attendance-silver.svg" alt="Silver Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                        </div><!-- /.lb-badges-box -->
                                    </td>
                                </tr>
                                <tr>
                                    <td class="lb-rank" data-cell-label="RANK">4.</td>
                                    <td data-cell-label="NAME">dr. Leonard P.</td>
                                    <td data-cell-label="BADGES EARNED">7</td>
                                    <td data-cell-label="LAST EARNED ON">02.08.2022</td>
                                    <td class="lb-badges" data-cell-label="Badges">
                                        <div class="lb-badges-box"> <!-- < order inverted -->
                                            <img src="../../assets/badge-attendance-bronze.svg" alt="Bronze Badge">
                                            <img src="../../assets/badge-attendance-silver.svg" alt="Silver Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                        </div><!-- /.lb-badges-box -->
                                    </td>
                                </tr>
                                <tr>
                                    <td class="lb-label" colspan="5">Your rank</td>
                                </tr>
                                <tr class="lb-yr">
                                    <td class="lb-rank" data-cell-label="RANK">103.</td>
                                    <td data-cell-label="NAME">dr. Leonard P.</td>
                                    <td data-cell-label="BADGES EARNED">7</td>
                                    <td data-cell-label="LAST EARNED ON">02.08.2022</td>
                                    <td class="lb-badges" data-cell-label="Badges">
                                        <div class="lb-badges-box"> <!-- < order inverted -->
                                            <img src="../../assets/badge-attendance-bronze.svg" alt="Bronze Badge">
                                            <img src="../../assets/badge-attendance-silver.svg" alt="Silver Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                        </div><!-- /.lb-badges-box -->
                                    </td>
                                </tr>
                            </table><!-- /.nv-leaderboard -->
                        </div>
                        <!-- /.nv-scroll-box -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
            </div><!-- /nv-row -->
        </div><!-- /nv-port -->
    </section>

    <section class="nv-section guttered" style="background-color: #fff;">
        <div class="nv-port">
            <div class="nv-row large-spaced gap-30 propagate">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-table-responsive">
                        <table class="nv-quasi-program-table">
                            <tbody>
                            <tr>
                                <th class="day" colspan="6">Wednesday August 31<sup>st</sup></th>
                            </tr>

                            <tr>
                                <th>
                                    Start
                                </th>
                                <th>
                                    End
                                </th>
                                <th>
                                    Grand Klimt Hall 1
                                </th>
                                <th>
                                    Grand Klimt Hall 2
                                </th>
                                <th>
                                    Grand Klimt Hall 3
                                </th>
                                <th>
                                    Park Suite 6
                                </th>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    09:00
                                </td>
                                <td class="time-span">
                                    11:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86895" title="Workshop: Your Safe Move to the Cloud - How to transform a Windows solution into a full-blown web application.">Workshop: Your Safe Move to the Cloud - How to transform a Windows solution into a full-blown web application.</a></h4>
                                    <p>Klaus Gudat &amp; Stefan Bolte, <span>Progress Software</span></p>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86896" title="Workshop: Tips and Tricks for Progress Application Server for OpenEdge">Workshop: Tips and Tricks for Progress Application Server for OpenEdge</a></h4>
                                    <p>Roy Ellis, <span>Progress Software</span></p>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86897" title="Workshop: OpenEdge batch processing in a Docker Container">Workshop: OpenEdge batch processing in a Docker Container</a></h4>
                                    <p>Cameron Wright, <span>Progress Software</span></p>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    11:45
                                </td>
                                <td class="time-span">
                                    12:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86899" title="What's New In ProTop 2022">What's New In ProTop 2022</a></h4>
                                    <p>Tom Bascom, <span>WSS</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href title="A minimal viable pipeline setup for Docker PASOE image delivery">A minimal viable pipeline setup for Docker PASOE image delivery</a></h4>
                                    <p>Daniel van Doorn, <span>Consultingwerk</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86900" title="Microservices Architecture The importance of doing it right">Microservices Architecture The importance of doing it right</a></h4>
                                    <p>Coenraad Vermaak, <span>Dimensiondata</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86916" title="Web, Mobile, App, Exe, ... all with one Environment">Web, Mobile, App, Exe, ... all with one Environment</a></h4>
                                    <p>Robert Prediger, <span>Web4biz</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    12:30
                                </td>
                                <td class="time-span">
                                    13:30
                                </td>
                                <td colspan="4">
                                    Lunch Break
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    13:30
                                </td>
                                <td class="time-span">
                                    14:15
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86898" title="Non-Uniform Memory Architecture">Non-Uniform Memory Architecture</a></h4>
                                    <p>Mike Furgal, <span>Progress OpenEdge Expert</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86903" title="What is my PASOE doing all day?">What is my PASOE doing all day?</a></h4>
                                    <p>Mike Fechner, <span>Consultingwerk</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86904" title="In pursuit of Big Data it all starts with CDC">In pursuit of Big Data it all starts with CDC</a></h4>
                                    <p>Demetrius Ganesh, <span>>MIP</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86905" title="Index and search for everything on your database">Index and search for everything on your database</a></h4>
                                    <p>Claudiu Socaciu, <span>Yonder</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    14:30
                                </td>
                                <td class="time-span">
                                    16:00
                                </td>
                                <td colspan="4">
                                    Opening General Session
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    16:00
                                </td>
                                <td class="time-span">
                                    16:30
                                </td>
                                <td colspan="4" class="break-time">
                                    <h4>Expo-Break</h4>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    16:30
                                </td>
                                <td class="time-span">
                                    17:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86906" title="OpenEdge Roadmap">OpenEdge Roadmap</a></h4>
                                    <p>Rob Straight, <span>Progress Software</span></p>
                                    <span class="duration">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86908" title="OpenEdge DB and Continuous Operations">OpenEdge DB and Continuous Operations</a></h4>
                                    <p>Jim Clark, <span>Progress Software</span></p>
                                    <span class="duration">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86909" title="Commercial Session: Reporting against all of your Progress data sources easily and quickly">Commercial Session: Reporting against all of your Progress data sources easily and quickly</a></h4>
                                    <p>Dave Darby, <span>Cyberscience</span></p>
                                    60 minutes
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86925" title="Commercial Session: Build.One - the first end-to-end DEV platform for large-scale enterprise web-applications">Commercial Session: Build.One - the first end-to-end DEV platform for large-scale enterprise web-applications</a></h4>
                                    <p>Mike Liewehr, <span>BuildOne</span></p>
                                    <span class="duration">60 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    17:45
                                </td>
                                <td class="time-span">
                                    18:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86910" title="Benchmarking Bonanza - Cloud Edition!">Benchmarking Bonanza - Cloud Edition!</a></h4>
                                    <p>Paul Koufalis &amp; Adam Backman, <span>WSS</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86911" title="Generic collections in ABL">Generic collections in ABL</a></h4>
                                    <p>Peter Judge, <span>Consultingwerk</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86912" title="Updates and Improvements to OAuth2 and SAML in PAS for OpenEdge">Updates and Improvements to OAuth2 and SAML in PAS for OpenEdge</a></h4>
                                    <p>Mike Banks, <span>Progress Software</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86913" title="What s App Business Keeping your data out of third-party channels, OMNI Channel and GDPR compliance">What s App Business Keeping your data out of third-party channels, OMNI Channel and GDPR compliance</a></h4>
                                    <p>Chris Koster (and Noreen Koster), <span>MIP</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                     </div><!-- /.nv-table-responsive -->

                        <div class="nv-divider desk-large"></div>


                        <div class="nv-table-responsive">
                        <table class="nv-quasi-program-table">
                            <tbody>
                            <tr>
                                <th class="day" colspan="6">Thursday 1<sup>st</sup> of September</th>
                            </tr>
                            <tr>
                                <th class="time-span">
                                    Start
                                </th>
                                <th class="time-span">
                                    End
                                </th>
                                <th>
                                    Grand Klimt Hall 1
                                </th>
                                <th>
                                    Grand Klimt Hall 2
                                </th>
                                <th>
                                    Grand Klimt Hall 3
                                </th>
                                <th>
                                    Park Suite 6
                                </th>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    09:00
                                </td>
                                <td class="time-span">
                                    11:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86914" title="Workshop: Structured Error-Handling">Workshop: Structured Error-Handling</a></h4>
                                    <p>Peter Judge &amp; Mike Fechner, <span>Consultingwerk</span></p>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86915" title="Workshop: OpenEdge batch processing in a Docker Container">Workshop: OpenEdge batch processing in a Docker Container</a></h4>
                                    <p>Cameron Wright, <span>Progress Software</span></p>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86958" title="Workshop: OpenEdge DBA: Advanced Problem Solving">Workshop: OpenEdge DBA: Advanced Problem Solving
                                    <br/>
                                    What Every OpenEdge DBA Needs to Know</a></h4>
                                    <p>Paul Koufalis &amp; Tom Bascom, <span>White Star Software</span></p>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    11:45
                                </td>
                                <td class="time-span">
                                    12:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86919" title="Benefits of Upgrading to OpenEdge 12: A Database Perspective">Benefits of Upgrading to OpenEdge 12: A Database Perspective</a></h4>
                                    <p>Rob Fitzpatrick, <span>WSS</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86918" title="1, 2, 3, 4 Counting records in ABL">1, 2, 3, 4 Counting records in ABL</a></h4>
                                    <p>Peter Judge, <span>Consultingwerk</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86917" title="Monitoring Progress Application Server for OpenEdge">Monitoring Progress Application Server for OpenEdge</a></h4>
                                    <p>Roy Ellis, <span>Progress Software</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86901" title="How to use Classic Corticon and CorticonJS with OpenEdge">How to use Classic Corticon and CorticonJS with OpenEdge</a></h4>
                                    <p>Mike Liewehr, <span>BuildOne</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    12:30
                                </td>
                                <td class="time-span">
                                    13:30
                                </td>
                                <td colspan="4" class="break-time">
                                    <h4>Lunch Break</h4>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    13:30
                                </td>
                                <td class="time-span">
                                    14:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86924" title="What s new in the ABL">What s new in the ABL</a></h4>
                                    <p>Anil Kotha, Progress Software</p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86927" title="HAR HAR HAR: Tracing and debugging HTTP Client requests">HAR HAR HAR: Tracing and debugging HTTP Client requests</a></h4>
                                    <p>Peter Judge, <span>Consultingwerk</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86926" title="Commercial Session: You Will Be Modernized - Resistance Is Futile">Commercial Session: You Will Be Modernized - Resistance Is Futile</a></h4>
                                    <p>Roland de Pijper, <span>Progress Software</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86907" title="Commercial Session: OpenEdge Modernization">Commercial Session: OpenEdge Modernization</a></h4>
                                    <p>Klaus Erichsen (IAP), Bernd Hellmann (IAP), Oliver Jung (proALPHA), <span>IAP</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    14:45
                                </td>
                                <td class="time-span">
                                    15:45
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86929" title="High Performance Database">High Performance Database</a></h4>
                                    <p>Libor Laubacher, <span>Progress Software</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86948" title="OpenEdge ABL Application Memory Profiling">OpenEdge ABL Application Memory Profiling</a></h4>
                                    <p>Tim Sargent <span>Progress Software</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86928" title="Commercial Session: LoadMaster for PASOE Load Balancing, and More!">Commercial Session: LoadMaster for PASOE Load Balancing, and More!</a></h4>
                                    <p>Roben Droge, <span>Progress Software</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86930" title="Commercial Session: SmartComponent Library the modernization framework for OpenEdge">Commercial Session: SmartComponent Library the modernization framework for OpenEdge</a></h4>
                                    <p>Mike Fechner, <span>Consultingwerk</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    15:45
                                </td>
                                <td class="time-span">
                                    16:15
                                </td>
                                <td colspan="4" class="break-time">
                                    <h4>Expo-Break</h4>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    16:15
                                </td>
                                <td class="time-span">
                                    17:00
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86920" title="Database Performance and Resilience Tips for 2022">Database Performance and Resilience Tips for 2022</a></h4>
                                    <p>Tom Bascom, <span>WSS</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86921" title="VSCode for ABL Applications">VSCode for ABL Applications</a></h4>
                                    <p>Gilles Querret, <span>Riverside</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86922" title="How to install and use node">How to install and use node</a></h4>
                                    <p>Julian Lyndon-Smith, <span>BuildOne</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a href="https://conference.pugchallenge.eu/presentation/86923" title="An ERP is always a work in Progress : A 30-year retrospective on powering Diapason with Progress">An ERP is always a work in Progress : A 30-year retrospective on powering Diapason with Progress</a></h4>
                                    <p>Sylvain De Sousa Maxime Castera, <span>ISIA SAS</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    17:15
                                </td>
                                <td class="time-span">
                                    17:45
                                </td>
                                <td>
                                    <h4><a target="_blank" href title="5 Ways to CRASH Your Database">5 Ways to CRASH Your Database</a></h4>
                                    <p>Nectar Daloglou, <span>OmegaServe</span></p>
                                    <span class="time">30 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href title="JIRA Integration Using the OpenEdge HTTP Client">JIRA Integration Using the OpenEdge HTTP Client</a></h4>
                                    <p>Peter Judge, <span>Consultingwerk</span></p>
                                    <span class="time">30 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86934" title="Monitoring Progress OpenEdge Resources with Splunk">Monitoring Progress OpenEdge Resources with Splunk</a></h4>
                                    <p>Michal Dzmuran, <span>Freelance</span></p>
                                    <span class="time">30 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86931" title="Commercial Session: Peace of Mind for Your OpenEdge Application">Commercial Session: Peace of Mind for Your OpenEdge Application</a></h4>
                                    <p>Roy Ellis, <span>Progress Software</span></p>
                                    <span class="time">30 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    17:45
                                </td>
                                <td class="time-span">
                                    18:15
                                </td>
                                <td colspan="4" class="break-time">
                                    <h4>Expo Break</h4>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    18:15
                                </td>
                                <td class="time-span">
                                    19:00
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86938" title="How to live in a 24 hour world">How to live in a 24 hour world</a></h4>
                                    <p>Adam Backman, <span>WSS</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86949" title="Ensuring the success of your Progress App modernization">Ensuring the success of your Progress App modernization</a></h4>
                                    <p>Gabriel Lucaciu, <span>Yonder</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86936" title="ProBro: VSC extension for Progress DB browsing">ProBro: VSC extension for Progress DB browsing</a></h4>
                                    <p>Paulius Kuprevičius, <span>Baltic Amadeus</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86947" title="Commercial Session">Commercial Session</a></h4>
                                    <p>TBD, <span>JK Tech</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                            </tr>
                            <tr>
                            </tr>
                            </tbody>
                        </table>
                        </div><!-- /.nv-table-responsive -->

                        <div class="nv-divider desk-large"></div>

                        <div class="nv-table-responsive">
                        <table class="nv-quasi-program-table">
                            <tbody>
                            <tr>
                                <th class="day" colspan="6">Friday, 2<sup>nd</sup> of September</th>
                            </tr>
                            <tr>
                                <th>
                                    Start
                                </th>
                                <th>
                                    End
                                </th>
                                <th>
                                    Grand Klimt Hall 1
                                </th>
                                <th>
                                    Grand Klimt Hall 2
                                </th>
                                <th>
                                    Grand Klimt Hall 3
                                </th>
                                <th>
                                    Park Suite 6
                                </th>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    09:00
                                </td>
                                <td class="time-span">
                                    11:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86939" title="Workshop: OpenEdge DBA: Advanced Problem Solving">Workshop: OpenEdge DBA: Advanced Problem Solving
                                    <br/>
                                        What Every OpenEdge DBA Needs to Know</a></h4>
                                    <p>Paul Koufalis &amp; Tom Bascom, <span>White Star Software</span></p>
                                </td>
                                <td>
                                    <h4><a target="_blank" href title="Workshop: Supporting Progress Application Server for OpenEdge">Workshop: Supporting Progress Application Server for OpenEdge</a></h4>
                                    <p>Roy Ellis, <span>Progress Software</span></p>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86940" title="Workshop: Structured Error-Handling">Workshop: Structured Error-Handling</a></h4>
                                    <p>Peter Judge &amp; Mike Fechner, <span>Consultingwerk</span></p>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    11:45
                                </td>
                                <td class="time-span">
                                    12:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86941" title="New Hardware Worse Performance">New Hardware Worse Performance</a></h4>
                                    <p>Nectar Daloglou, <span>OmegaServe</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86942" title="Using the Profiler to Find Performance Problems in Production">Using the Profiler to Find Performance Problems in Production</a></h4>
                                    <p>Tom Bascom, <span>WSS</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86943" title="OpenEdge OOP and Unit testing. The hidden story?">OpenEdge OOP and Unit testing. The hidden story?</a></h4>
                                    <p>Martyn Kemp, <span>Consultingwerk</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86944" title="Bridging the Gap - OpenEdge with MongoDB">Bridging the Gap - OpenEdge with MongoDB</a></h4>
                                    <p>Allar Allas, <span>Inspirators</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    12:30
                                </td>
                                <td class="time-span">
                                    13:30
                                </td>
                                <td class="break-time" colspan="4">
                                    <h4>Lunch Break</h4>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    13:30
                                </td>
                                <td class="time-span">
                                    14:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86945" title="Record Format and Before Image Notes">Record Format and Before Image Notes</a></h4>
                                    <p>Gus Bjorklund , <span>The Parmington Foundation</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/87024" title="What's New In OpenEdge RDBMS">What's New In OpenEdge RDBMS</a></h4>
                                    <p>Jim Clark, <span>Progress Software</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86935" title="Is over-engineering a threat in your project?">Is over-engineering a threat in your project?</a></h4>
                                    <p>Mihai Neagoe, <span>Wayfare</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href title="ProTop Commercial Session">ProTop Commercial Session</a></h4>
                                    <p>Paul Koufalis, <span>WSS</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    14:45
                                </td>
                                <td class="time-span">
                                    15:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86952" title="A Modern OpenEdge Management Console">A Modern OpenEdge Management Console</a></h4>
                                    <p>Tushar Dhaka, <span>Progress Software</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86951" title="Git Tipps and Tricks - or how to avoid losing your hair (or the days work)">Git Tipps and Tricks - or how to avoid losing your hair (or the days work)</a></h4>
                                    <p>Gilles Querret, <span>Riverside</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86950" title="Your modernization project is over, what now?">Your modernization project is over, what now?</a></h4>
                                    <p>Coenraad Vermaak, <span>Dimensiondata</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86937" title="Requirements. Can t progress without them.">Requirements. Can t progress without them.</a></h4>
                                    <p>Claudia Deacu , <span>Wayfare</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    15:30
                                </td>
                                <td class="time-span">
                                    16:00
                                </td>
                                <td class="break-time" colspan="4">
                                    <h4>Expo-Break</h4>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    16:00
                                </td>
                                <td class="time-span">
                                    17:00
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86956" title="The Checkpoint">The Checkpoint</a></h4>
                                    <p>Mike Furgal, <span>Progress OpenEdge Expert</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86955" title="ABL Code and Memory Leaks">ABL Code and Memory Leaks</a></h4>
                                    <p>Mike Fechner, <span>Consultingwerk</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86954" title="OpenEdge 12.5 Kafka producer and consumer">OpenEdge 12.5 Kafka producer and consumer</a></h4>
                                    <p>Mike Banks &amp; Cameron Wright, <span>Progress Software</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href title="ISCorp Commercial Session">ISCorp Commercial Session</a></h4>
                                    <p>Mark Holton, <span>ISCorp</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                            </tr>
                            <tr>
                            </tr>
                            </tbody>
                        </table>
                        </div><!-- /.nv-table-responsive -->


                    </div><!-- /.nv-column-content -->
                </div><!-- /nv-column -->
            </div><!-- /row -->
        </div><!-- /nv-port -->
    </section>

    <section class="nv-section guttered pug-countries">
        <div class="nv-port">
            <div class="nv-row large-spaced gap-30 propagate">
                <div class="nv-column desk-6 lap-12">
                    <div class="nv-column-content">
                        <div class="nv-upload-block">
                            <label class="ub-img-port">
                                <input type="file" name="">
                                <span class="nv-ub-image" style="background-image: url('../../assets/badge-attendance-bronze.svg');"></span><!-- /.nv-ub-image --> <!-- < remove style attribute for initial state -->
                            </label>
                            <div class="ub-adjument">
                                <p class="ub-control">
                                    <span><a href>Upload new file</a></span>
                                    <span><a href>Remove file</a></span>
                                </p><!-- /.ub-control -->
                            </div><!-- /.ub-adjument -->
                        </div><!-- /.nv-upload-block -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /nv-column -->
            </div><!-- /row -->
        </div><!-- /nv-port -->
    </section>



    <section class="nv-section guttered pug-countries">
        <div class="nv-port">
            <div class="nv-row large-spaced gap-30 propagate">
                <div class="nv-column desk-2 lap-3 tab-4 phab-12">
                    <div class="nv-column-content">
                        <h3>Baltic</h3><img src="https://www.pugchallenge.eu/images/default-source/default-album/estonia.png"><img src="https://www.pugchallenge.eu/images/default-source/default-album/letonia.png"><img src="https://www.pugchallenge.eu/images/default-source/default-album/lithuania.png"><p>PUG Baltic</p><p>Kristi Pool</p><p>Löötsa 6</p><p>11415 Tallinn</p><p>Estonia</p><h4><a href="mailto:kristi.pool@helmes.ee">kristi.pool@helmes.ee</a>
                        </h4><h4><a href="https://community.progress.com/progress_user_groups/pug_baltic/" target="_blank">Website </a>
                        </h4>
                    </div><!-- /.nv-column-content -->
                </div>

                <div class="nv-column desk-2 lap-3 tab-4 phab-12">
                    <div class="nv-column-content">
                        <h3>Belgium</h3><img src="https://www.pugchallenge.eu/images/default-source/default-album/belgium.png"><p>PUG Belgium</p><p>Koen Verbeeck</p><p>Rijksweg 7, 2870 PUURS</p><p>Belgium</p><h4><a href="mailto:koen.verbeeck@binteq.com">koen.verbeeck@binteq.com</a>
                        </h4><h4><a href="http://www.pugbe.org/nl" target="_blank">Website </a>
                        </h4>
                    </div>
                </div>

                <div class="nv-column desk-2 lap-3 tab-4 phab-12">
                    <div class="nv-column-content">
                        <h3>Denmark</h3><img src="https://www.pugchallenge.eu/images/default-source/default-album/denmark.png"><p>PUG Denmark</p><p>Thomas Hansen</p><p>appSolutions a|s</p><p>Åstrupvej 10</p><p>9800 Hjørring</p><p>Denmark</p><h4><a href="mailto:thomas@app-solutions.com">thomas@app-solutions.com</a>
                        </h4><h4><a href="https://community.progress.com/progress_user_groups/w/wiki/747.denmark-pug" target="_blank">Website </a>
                        </h4>
                    </div>
                </div>

                <div class="nv-column desk-2 lap-3 tab-4 phab-12">
                    <div class="nv-column-content">
                        <h3>Finland</h3><img src="https://www.pugchallenge.eu/images/default-source/default-album/finland.png"><p>PUG Finland</p><p>Teppo Määttänen</p><h4><a href="mailto:teppo@trtiimi.fi">teppo@trtiimi.fi</a>
                        </h4><h4><a href="http://www.finpug.fi/" target="_blank">Website </a>
                        </h4>
                    </div>
                </div>

                <div class="nv-column desk-2 lap-3 tab-4 phab-12">
                    <div class="nv-column-content">
                        <h3>France</h3><img src="https://www.pugchallenge.eu/images/default-source/default-album/france.png"><p>PUG France</p><p>Gilles Querret</p><p>Chez Proginov</p><p>PA Tournebride</p><p>44118 La Chevroliére</p><p>France</p><h4><a href="mailto:contact@pug-france.fr">contact@pug-france.fr</a>
                        </h4><h4><a href="http://www.pug-france.fr" target="_blank">Website </a>
                        </h4>
                    </div>
                </div>

                <div class="nv-column desk-2 lap-3 tab-4 phab-12">
                    <div class="nv-column-content">
                        <h3>Germany</h3><img src="https://www.pugchallenge.eu/images/default-source/default-album/germany.png"><p>PUG Deutschland e.V.</p><p>Robert Prediger</p><p>c/o web4biz Consulting</p><p>Butterfeld 1</p><p>85368 Wang</p><h4><a href="http://www.pug.de" target="_blank">Website </a>
                        </h4>
                    </div>
                </div>

                <div class="nv-column desk-2 lap-3 tab-4 phab-12">
                    <div class="nv-column-content">
                        <h3>Italy</h3><img src="https://www.pugchallenge.eu/images/default-source/default-album/italy.png"><p>PUG Italia</p><h4><a href="http://www.pugitalia.it" target="_blank">Website </a>
                        </h4>
                    </div>
                </div>

                <div class="nv-column desk-2 lap-3 tab-4 phab-12">
                    <div class="nv-column-content">
                        <h3>Netherlands</h3><img src="https://www.pugchallenge.eu/images/default-source/default-album/netherlands.png"><p>PUG Netherlands</p><p>Bert Tukkers</p><p>p.a. Paddestoelweg 21</p><p>2403 HE Alphen aan den Rijn</p><h4><a href="mailto:tukkers@pug.nl">tukkers@pug.nl</a>
                        </h4><h4><a href="http://www.pug.nl" target="_blank">Website </a>
                        </h4>
                    </div>
                </div>

                <div class="nv-column desk-2 lap-3 tab-4 phab-12">
                    <div class="nv-column-content">
                        <h3>Norway</h3><img src="https://www.pugchallenge.eu/images/default-source/default-album/norway.png"><p>PUG Norway</p><p>Odd Hauahei</p><p>Proventus</p><h4><a href="mailto:oddh@proventus.no">oddh@proventus.no</a>
                        </h4><h4><a href="http://www.pugnorway.no" target="_blank">Website </a>
                        </h4>
                    </div>
                </div>

                <div class="nv-column desk-2 lap-3 tab-4 phab-12">
                    <div class="nv-column-content">
                        <h3>South Africa</h3><img src="https://www.pugchallenge.eu/images/default-source/default-album/southafrica.png"><p>Pieter Meyer</p><h4><a href="mailto:pieterm@mip.co.za">pieterm@mip.co.za</a>
                        </h4><h4><a href="https://community.progress.com/progress_user_groups/pug_southafrica/" target="_blank">Website </a>
                        </h4>
                    </div>
                </div>


                <div class="nv-column desk-2 lap-3 tab-4">
                    <div class="nv-column-content">
                        <h3>UK &amp; Ireland</h3><img src="https://www.pugchallenge.eu/images/default-source/default-album/uk.png"><img src="https://www.pugchallenge.eu/images/default-source/default-album/irland.png"><p>PUG UK &amp; Ireland</p><p>James Palmer</p><p>PO Box 7713</p><p>Poole</p><p>Dorset</p><p>BH15 9GP</p><h4><a href="mailto:office@puguk.org">office@puguk.org</a>
                        </h4><h4><a href="http://www.puguk.org" target="_blank">Website </a>
                        </h4>
                    </div>
                </div>
            </div><!-- /row -->
        </div><!-- /nv-port -->
    </section>


    <section class="nv-section guttered pug-1">
        <div class="nv-port">
            <div class="nv-row large-spaced gap-30 propagate">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h2>PUG Challenge 2022</h2>
                        <h5>VIENNA, THE LOCATION FOR PUG CHALLENGE 2022, IS THE CAPITAL OF AUSTRIA - ALSO DESCRIBED AS EUROPE'S CULTURAL CAPITAL - A CITY WITH UNIQUE CHARM, VIBRANCY AND FLAIR. IT BOASTS OUTSTANDING INFRASTRUCTURE. IS GREEN AND SAFE, AND HAS ALL THE INSPIRATION YOU COULD WISH FOR IN ORDER TO DISCOVER THIS WONDERFUL PART OF EUROPE.</h5>
                        <p>Vienna is situated on the banks of the River Danube and is the most popular urban tourist destination in Austria. The climate is seasonal and generally moderate. For those with an interest in history Vienna is a dream city for sightseeing - visit the Schonbrunn Palace, the Imperial Palace (Hofburg), or admire the architecture along the Ring boulevard. Culturally Vienna offers a vast array of attractions - famous coffee houses, fine art, literature, theatre, film, dance, opera, exhibitions and concerts - no matter when you visit there is sure to be something to appeal. Home to Mozart, Beethoven, Schubert and Johann Strauss, Vienna and its outstanding musical heritage has been preserved right to the present day. Vienna is compact and walkable, has a safe and reliable public transport network and is 16 minutes from the airport to the city centre (City Airport Train - CAT) or 20 minutes by taxi.</p>
                    </div>
                </div>
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                    </div>
                </div>
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <h2>Hilton Vienna Park Hotel</h2>
                        <h5>THE HOTEL FEATURES AN UNDERGROUND CAR PARK, BUS PARKING FACILITIES AND EXCELLENT PUBLIC TRANSPORT CONNECTIONS.</h5>
                        <p>Hilton Vienna is Austria's largest Meeting & Conference hotel. The hotel is ideally located in the city centre within walking distance to St. Stephen's Cathedral and offers a high standard of comfort and amenities.</p>
                   </div>
                </div>
            </div>
        </div>
    </section>


    <section class="nv-section guttered sales-tier-landing">
        <div class="nv-port">
            <div class="nv-row large-spaced gap-30 propagate flow-center">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <header class="nv-underlined-heading accent1"><h2><span>Sales Tier 100 Course Journey Modules</span></h2></header>

                        <div class="nv-divider desk-medium"></div>

                        <h3 class="nv-sales-tier-heading"><span>Introduction</span></h3>

                        <div class="nv-sales-tier-landing-row">
                            <div class="nv-sales-tier-module">
                                <h2 class="nv-st-module-num">Module 1</h2>
                                <div class="nv-st-module-dscr">
                                    <div class="nv-st-dscr-pane">
                                        <h4>Introduction</h4>
                                        <p>General overview of goals</p>
                                    </div><!-- /.nv-st-dscr-pane -->
                                </div><!-- /.nv-st-dscr -->
                                <div class="nv-st-module-meta">
                                    <span class="time">5 min</span>
                                    <a href class="details">Details</a>
                                </div><!-- /.nv-st-module-meta -->
                            </div><!-- /.nv-sales-tier-module -->

                            <div class="nv-sales-tier-module">
                                <h2 class="nv-st-module-num">Module 2</h2>
                                <div class="nv-st-module-dscr">
                                    <div class="nv-st-dscr-pane">
                                        <h4>Matrix 42</h4>
                                        <p>The company, the history, and the eXperience</p>
                                    </div><!-- /.nv-st-dscr-pane -->
                                </div><!-- /.nv-st-dscr -->
                                <div class="nv-st-module-meta">
                                    <span class="time">8 min</span>
                                    <a href class="details">Details</a>
                                </div><!-- /.nv-st-module-meta -->
                            </div><!-- /.nv-sales-tier-module -->

                            <div class="nv-sales-tier-module">
                                <h2 class="nv-st-module-num">Module 3</h2>
                                <div class="nv-st-module-dscr">
                                    <div class="nv-st-dscr-pane">
                                        <h4>Selling Matrix 42</h4>
                                        <p>Creating the framework for Sales Wins</p>
                                    </div><!-- /.nv-st-dscr-pane -->
                                </div><!-- /.nv-st-dscr -->
                                <div class="nv-st-module-meta">
                                    <span class="time">20 min</span>
                                    <a href class="details">Details</a>
                                </div><!-- /.nv-st-module-meta -->
                            </div><!-- /.nv-sales-tier-module -->
                        </div><!-- /.nv-sales-tier-landing-row -->

                        <h3 class="nv-sales-tier-heading"><span>Reviewing the Basics</span></h3>

                        <div class="nv-sales-tier-landing-row">
                            <div class="nv-sales-tier-module">
                                <h2 class="nv-st-module-num">Module 4</h2>
                                <div class="nv-st-module-dscr">
                                    <div class="nv-st-dscr-pane">
                                        <h4>Targeting</h4>
                                        <p>Understanding the audience</p>
                                    </div><!-- /.nv-st-dscr-pane -->
                                </div><!-- /.nv-st-dscr -->
                                <div class="nv-st-module-meta">
                                    <span class="time">15 min</span>
                                    <a href class="details">Details</a>
                                </div><!-- /.nv-st-module-meta -->
                            </div><!-- /.nv-sales-tier-module -->

                            <div class="nv-sales-tier-module">
                                <h2 class="nv-st-module-num">Module 5a</h2>
                                <div class="nv-st-module-dscr">
                                    <div class="nv-st-dscr-pane">
                                        <h4>Talk Tracks I</h4>
                                        <p>Deliver Matrix key messaged effectively and accurately</p>
                                    </div><!-- /.nv-st-dscr-pane -->
                                </div><!-- /.nv-st-dscr -->
                                <div class="nv-st-module-meta">
                                    <span class="time">20 min</span>
                                    <a href class="details">Details</a>
                                </div><!-- /.nv-st-module-meta -->
                            </div><!-- /.nv-sales-tier-module -->

                            <div class="nv-sales-tier-module">
                                <h2 class="nv-st-module-num">Module 5b</h2>
                                <div class="nv-st-module-dscr">
                                    <div class="nv-st-dscr-pane">
                                        <h4>Talk Tracks II</h4>
                                        <p>Deliver Matrix key messaged effectively and accurately</p>
                                    </div><!-- /.nv-st-dscr-pane -->
                                </div><!-- /.nv-st-dscr -->
                                <div class="nv-st-module-meta">
                                    <span class="time">15 min</span>
                                    <a href class="details">Details</a>
                                </div><!-- /.nv-st-module-meta -->
                            </div><!-- /.nv-sales-tier-module -->
                        </div><!-- /.nv-sales-tier-landing-row -->

                        <h3 class="nv-sales-tier-heading"><span>Becoming a Solution Expert</span></h3>

                        <div class="nv-sales-tier-landing-row">
                            <div class="nv-sales-tier-module">
                                <h2 class="nv-st-module-num">Module 6</h2>
                                <div class="nv-st-module-dscr">
                                    <div class="nv-st-dscr-pane">
                                        <h4>The Visual Conversation</h4>
                                        <p>Using visual tools for effect and emphasis</p>
                                    </div><!-- /.nv-st-dscr-pane -->
                                </div><!-- /.nv-st-dscr -->
                                <div class="nv-st-module-meta">
                                    <span class="time">15 min</span>
                                    <a href class="details">Details</a>
                                </div><!-- /.nv-st-module-meta -->
                            </div><!-- /.nv-sales-tier-module -->

                            <div class="nv-sales-tier-module">
                                <h2 class="nv-st-module-num">Live 1</h2>
                                <div class="nv-st-module-dscr">
                                    <div class="nv-st-dscr-pane">
                                        <h4>White Boarding</h4>
                                        <p>Sell by visualizing unknown and unmet needs</p>
                                    </div><!-- /.nv-st-dscr-pane -->
                                </div><!-- /.nv-st-dscr -->
                                <div class="nv-st-module-meta">
                                    <span class="time">60 min</span>
                                    <span class="live">Live</span>
                                    <a href class="details">Details</a>
                                </div><!-- /.nv-st-module-meta -->
                            </div><!-- /.nv-sales-tier-module -->

                            <div class="nv-sales-tier-module">
                                <h2 class="nv-st-module-num">MODULE 7</h2>
                                <div class="nv-st-module-dscr">
                                    <div class="nv-st-dscr-pane">
                                        <h4>Solution Selling</h4>
                                        <p>Deep diving into what Matrix42 offers the customer</p>
                                    </div><!-- /.nv-st-dscr-pane -->
                                </div><!-- /.nv-st-dscr -->
                                <div class="nv-st-module-meta">
                                    <span class="time">45 min</span>
                                    <a href class="details">Details</a>
                                </div><!-- /.nv-st-module-meta -->
                            </div><!-- /.nv-sales-tier-module -->
                        </div><!-- /.nv-sales-tier-landing-row -->

                        <h3 class="nv-sales-tier-heading"><span>The Advanced Toolkit</span></h3>

                        <div class="nv-sales-tier-landing-row">
                            <div class="nv-sales-tier-module">
                                <h2 class="nv-st-module-num">Live 2</h2>
                                <div class="nv-st-module-dscr">
                                    <div class="nv-st-dscr-pane">
                                        <h4>Case Studies</h4>
                                        <p>Learning by dissecting real sales opportunities</p>
                                    </div><!-- /.nv-st-dscr-pane -->
                                </div><!-- /.nv-st-dscr -->
                                <div class="nv-st-module-meta">
                                    <span class="time">60 min</span>
                                    <span class="live">Live</span>
                                    <a href class="details">Details</a>
                                </div><!-- /.nv-st-module-meta -->
                            </div><!-- /.nv-sales-tier-module -->

                            <div class="nv-sales-tier-module">
                                <h2 class="nv-st-module-num">MODULE 8</h2>
                                <div class="nv-st-module-dscr">
                                    <div class="nv-st-dscr-pane">
                                        <h4>Objection Handling</h4>
                                        <p>Sales Aikido: the art of redirection</p>
                                    </div><!-- /.nv-st-dscr-pane -->
                                </div><!-- /.nv-st-dscr -->
                                <div class="nv-st-module-meta">
                                    <span class="time">15 min</span>
                                    <a href class="details">Details</a>
                                </div><!-- /.nv-st-module-meta -->
                            </div><!-- /.nv-sales-tier-module -->

                            <div class="nv-sales-tier-module">
                                <h2 class="nv-st-module-num">Live 3</h2>
                                <div class="nv-st-module-dscr">
                                    <div class="nv-st-dscr-pane">
                                        <h4>Q&A and Best Practice</h4>
                                        <p>Feedback from the Sales team.</p>
                                        <p>Discussion, not monologue</p>
                                    </div><!-- /.nv-st-dscr-pane -->
                                </div><!-- /.nv-st-dscr -->
                                <div class="nv-st-module-meta">
                                    <span class="time">60 min</span>
                                    <span class="live">Live</span>
                                    <a href class="details">Details</a>
                                </div><!-- /.nv-st-module-meta -->
                            </div><!-- /.nv-sales-tier-module -->
                        </div><!-- /.nv-sales-tier-landing-row -->

                        <h3 class="nv-sales-tier-heading"><span>Live Practice and Cases</span></h3>

                        <div class="nv-sales-tier-landing-row">
                            <div class="nv-sales-tier-module">
                                <h2 class="nv-st-module-num">Live 4</h2>
                                <div class="nv-st-module-dscr">
                                    <div class="nv-st-dscr-pane">
                                        <h4>Sales Role Playing</h4>
                                        <p>No clients will be harmed in the making of this simulation</p>
                                    </div><!-- /.nv-st-dscr-pane -->
                                </div><!-- /.nv-st-dscr -->
                                <div class="nv-st-module-meta">
                                    <span class="time">45 min</span>
                                    <span class="live">Live</span>
                                    <a href class="details">Details</a>
                                </div><!-- /.nv-st-module-meta -->
                            </div><!-- /.nv-sales-tier-module -->

                            <div class="nv-sales-tier-module">
                                <h2 class="nv-st-module-num">MODULE 9</h2>
                                <div class="nv-st-module-dscr">
                                    <div class="nv-st-dscr-pane">
                                        <h4>Success Stories</h4>
                                        <p>How to use past wins to secure future ones</p>
                                    </div><!-- /.nv-st-dscr-pane -->
                                </div><!-- /.nv-st-dscr -->
                                <div class="nv-st-module-meta">
                                    <span class="time">30 min</span>
                                    <a href class="details">Details</a>
                                </div><!-- /.nv-st-module-meta -->
                            </div><!-- /.nv-sales-tier-module -->

                            <div class="nv-sales-tier-module">
                                <h2 class="nv-st-module-num">Certification</h2>
                                <div class="nv-st-certification">
                                    <img src="https://service.e-materials.com/storage/resources/academy_platform/sal/sales_tier_certificate.png" alt="SalesTier Certificate">
                                </div><!-- /.nv-st-certification -->
                            </div><!-- /.nv-sales-tier-module -->
                        </div><!-- /.nv-sales-tier-landing-row -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
            </div><!-- /nv-row -->
        </div><!-- /nv-port -->
    </section>

<!--    <section class="nv-section guttered nv-pfizer-awards-videos">-->
<!--        <div class="nv-port">-->
<!--            <div class="nv-row spaced gap-30 propagate flow-center">-->
<!--                <div class="nv-column v-middle desk-initial">-->
<!--                    <div class="nv-column-content">-->

<!--                        <div style="max-width: 600px;">-->
<!--                        &lt;!&ndash; Modal (medium sized) content &ndash;&gt;-->

<!--                            <nav class="nv-tabs-nav vertical-break-phab equal-width-items">-->
<!--                                <ul>-->
<!--                                    <li>-->
<!--                                        <a class="active" href>Material preview</a>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <a href>Attach to presentation</a>-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </nav>-->

<!--                            <div class="nv-row gap-24 spaced propagate">-->
<!--                                <div class="nv-column desk-12">-->
<!--                                    <div class="nv-column-content">-->
<!--                                        <div class="nv-form-item nv-mandatory fw">-->
<!--                                            <label>-->
<!--                                                <input type="text" placeholder="Title">-->
<!--                                                <span class="label-title">Title</span>-->
<!--                                            </label>-->
<!--                                        </div>&lt;!&ndash; /nv-form-item &ndash;&gt;-->
<!--                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                                <div class="nv-column desk-12">-->
<!--                                    <div class="nv-column-content">-->
<!--                                        <div class="nv-no-material-added">-->
<!--                                            <p>No material added yet.</p>-->
<!--                                        </div>&lt;!&ndash; /.nv-no-material-added &ndash;&gt;-->
<!--                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->


<!--                                <div class="nv-column desk-12">-->
<!--                                    <div class="nv-column-content">-->
<!--                                        <div class="nv-material-view" style="flex: 1;">-->
<!--                                            <div class="nv-material-detail-submenu">-->
<!--                                                <nav>-->
<!--                                                    <ul class="nmv-pagination">-->
<!--                                                        <li class="mds-page"><input type="number" min="1" max="5" class="ng-untouched ng-pristine ng-valid"><span> / &nbsp;&nbsp;5</span></li>-->
<!--                                                        <li class="mds-next"><a title="Next slide"></a></li>-->
<!--                                                    </ul>-->
<!--                                                </nav>-->
<!--                                            </div>-->

<!--                                            &lt;!&ndash; probably redundant &ndash;&gt;-->
<!--                                            <div class="nv-material-preview">-->
<!--                                                <img src="https://loremflickr.com/g/80/80/paris" style="height: 80px; max-width: 120px; margin: 10px;">-->
<!--                                                <img src="https://loremflickr.com/g/80/80/paris" style="height: 80px; max-width: 120px; margin: 10px;">-->
<!--                                                <img src="https://loremflickr.com/g/80/80/paris" style="height: 80px; max-width: 120px; margin: 10px;">-->
<!--                                                <img src="https://loremflickr.com/g/80/80/paris" style="height: 80px; max-width: 120px; margin: 10px;">-->
<!--                                                <img src="https://loremflickr.com/g/80/80/paris" style="height: 80px; max-width: 120px; margin: 10px;">-->
<!--                                           </div>-->
<!--                                            &lt;!&ndash; /probably redundant &ndash;&gt;-->

<!--                                            <div class="nv-material-preview single">-->
<!--                                                <img src="https://loremflickr.com/g/800/800/paris">-->
<!--                                                &lt;!&ndash; if needed &ndash;&gt;-->
<!--                                                <div class="nv-global-loading"> &lt;!&ndash; < toggle class 'on' &ndash;&gt;-->
<!--                                                    <div class="nv-spinner"></div>-->
<!--                                                </div>&lt;!&ndash; /.nv-global-loading &ndash;&gt;-->
<!--                                                &lt;!&ndash; /if needed &ndash;&gt;-->
<!--                                            </div>-->
<!--                                        </div>&lt;!&ndash; /nv-material-view &ndash;&gt;-->
<!--                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->

<!--                                <div class="nv-column desk-12">-->
<!--                                    <div class="nv-column-content">-->
<!--                                        <ul class="nv-materials-list break-lap break-column-tab">-->
<!--                                            <li>-->
<!--                                                <div class="ml-content nv-clickable">-->
<!--                                                    <div>Select presentation</div>-->
<!--                                                </div>-->
<!--                                            </li>-->
<!--                                        </ul>-->
<!--                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                            </div>&lt;!&ndash; /.nv-row &ndash;&gt;-->

<!--                            <div class="nv-row gap-24 propagate space-between">-->
<!--                                <div class="nv-column desk-initial v-middle">-->
<!--                                    <div class="nv-column-content">-->
<!--                                        <div class="nv-btn-box gap-20">-->
<!--                                            <button type="button" class="nv-btn medium accent1 txt">Copy embed code</button>-->
<!--                                            <button type="button" class="nv-btn medium accent1 txt">Copy path</button>-->
<!--                                        </div>-->
<!--                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                                <div class="nv-column desk-initial">-->
<!--                                    <div class="nv-column-content">-->
<!--                                        <div class="nv-btn-box flow-end gap-10">-->
<!--                                            <button type="button" class="nv-btn medium accent1">Save</button>-->
<!--                                        </div>-->
<!--                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                            </div>&lt;!&ndash; /.nv-row &ndash;&gt;-->

<!--                            <div class="nv-row gap-24 propagate">-->
<!--                                <div class="nv-column desk-12">-->
<!--                                    <div class="nv-column-content">-->

<!--                                        <h5 class="nv-light">Attached presentations</h5>-->
<!--                                        <table class="nv-data-table break-lap">-->
<!--                                            <tr>-->
<!--                                                <td class="dt-cell">-->
<!--                                                    <div class="dt-name-w-file">-->
<!--                                                        <a href title="file name" class="dt-file"><img src="../../assets/ico-material-audio.svg" alt="audio file"></a>-->
<!--                                                        <div class="dt-name">-->
<!--                                                            <label>MATERIAL NAME</label>-->
<!--                                                            <strong>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum iusto quibusdam tempora unde, velit voluptatem.</strong>-->
<!--                                                        </div>-->
<!--                                                    </div>&lt;!&ndash; /.dt-name-w-file &ndash;&gt;-->
<!--                                                </td>&lt;!&ndash; /.dt-cell &ndash;&gt;-->
<!--                                                <td class="dt-cell">-->
<!--                                                    <label>PUBLISHED</label>-->
<!--                                                    <strong>21.02.2022</strong>-->
<!--                                                </td>&lt;!&ndash; /.dt-cell &ndash;&gt;-->
<!--                                                <td class="dt-cell">-->
<!--                                                    <label>SHOW</label>-->
<!--                                                    <div class="nv-custom-toggle-checkbox">-->
<!--                                                        <label>-->
<!--                                                            <input type="checkbox">-->
<!--                                                            <span class="nv-custom-toggle-checkbox-visual">-->
<!--                                                            </span>&lt;!&ndash; /.nv-custom-toggle-checkbox-visual &ndash;&gt;-->
<!--                                                        </label>-->
<!--                                                    </div>&lt;!&ndash; /.nv-custom-toggle-checkbox &ndash;&gt;-->
<!--                                                </td>&lt;!&ndash; /.dt-cell &ndash;&gt;-->
<!--                                            </tr>-->
<!--                                            <tr>-->
<!--                                                <td class="dt-cell">-->
<!--                                                    <div class="dt-name-w-file">-->
<!--                                                        <a href title="file name" class="dt-file"><img src="../../assets/ico-material-pdf.svg" alt="audio file"></a>-->
<!--                                                        <div class="dt-name">-->
<!--                                                            <label>MATERIAL NAME</label>-->
<!--                                                            <strong>Lorem ipsum dolor sit amet</strong>-->
<!--                                                        </div>-->
<!--                                                    </div>&lt;!&ndash; /.dt-name-w-file &ndash;&gt;-->
<!--                                                </td>&lt;!&ndash; /.dt-cell &ndash;&gt;-->
<!--                                                <td class="dt-cell">-->
<!--                                                    <label>PUBLISHED</label>-->
<!--                                                    <strong>21.02.2022</strong>-->
<!--                                                </td>&lt;!&ndash; /.dt-cell &ndash;&gt;-->
<!--                                                <td class="dt-cell">-->
<!--                                                    <label>SHOW</label>-->
<!--                                                    <div class="nv-custom-toggle-checkbox">-->
<!--                                                        <label>-->
<!--                                                            <input type="checkbox">-->
<!--                                                            <span class="nv-custom-toggle-checkbox-visual">-->
<!--                                                            </span>&lt;!&ndash; /.nv-custom-toggle-checkbox-visual &ndash;&gt;-->
<!--                                                        </label>-->
<!--                                                    </div>&lt;!&ndash; /.nv-custom-toggle-checkbox &ndash;&gt;-->
<!--                                                </td>&lt;!&ndash; /.dt-cell &ndash;&gt;-->
<!--                                            </tr>-->
<!--                                        </table>-->

<!--                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                            </div>&lt;!&ndash; /.nv-row &ndash;&gt;-->

<!--                            <div class="nv-row gap-30 small-spaced-top">-->
<!--                                <div class="nv-column desk-12">-->
<!--                                    <div class="nv-column-content">-->
<!--                                        <div class="nv-modal-visual-chunk">-->
<!--                                            <div class="nv-row gap-24 propagate">-->
<!--                                                <div class="nv-column desk-12">-->
<!--                                                    <div class="nv-column-content">-->
<!--                                                        <h5 class="nv-light">Attach to presentation</h5>-->
<!--                                                        <div class="nv-form-item nv-select-item">-->
<!--                                                            <label>-->
<!--                                                                <select>-->
<!--                                                                    <option>Search for Presentation</option>-->
<!--                                                                </select>-->
<!--                                                                <span class="label-title">Search for Presentation</span>-->
<!--                                                            </label>-->
<!--                                                        </div>&lt;!&ndash; /nv-form-item &ndash;&gt;-->
<!--                                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                                                <div class="nv-column desk-auto phab-12 v-middle">-->
<!--                                                    <div class="nv-column-content">-->
<!--                                                        <p class="spaceless">Show/Hide</p>-->
<!--                                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                                                <div class="nv-column desk-initial phab-12 v-middle">-->
<!--                                                    <div class="nv-column-content">-->
<!--                                                        <div class="nv-custom-toggle-checkbox">-->
<!--                                                            <label>-->
<!--                                                                <input type="checkbox">-->
<!--                                                                <span class="nv-custom-toggle-checkbox-visual">-->
<!--                                                            </span>&lt;!&ndash; /.nv-custom-toggle-checkbox-visual &ndash;&gt;-->
<!--                                                            </label>-->
<!--                                                        </div>&lt;!&ndash; /.nv-custom-toggle-checkbox &ndash;&gt;-->
<!--                                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                                                <div class="nv-column desk-auto phab-12 v-middle">-->
<!--                                                    <div class="nv-column-content">-->
<!--                                                        <p class="spaceless">Downloadable</p>-->
<!--                                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                                                <div class="nv-column desk-initial phab-12 v-middle">-->
<!--                                                    <div class="nv-column-content">-->
<!--                                                        <div class="nv-custom-toggle-checkbox">-->
<!--                                                            <label>-->
<!--                                                                <input type="checkbox">-->
<!--                                                                <span class="nv-custom-toggle-checkbox-visual">-->
<!--                                                            </span>&lt;!&ndash; /.nv-custom-toggle-checkbox-visual &ndash;&gt;-->
<!--                                                            </label>-->
<!--                                                        </div>&lt;!&ndash; /.nv-custom-toggle-checkbox &ndash;&gt;-->
<!--                                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                                                <div class="nv-column desk-6 phab-12">-->
<!--                                                    <div class="nv-column-content">-->
<!--                                                        <nv-datepicker-->
<!--                                                                type="date"-->
<!--                                                                label="Publish date"-->
<!--                                                                required-->
<!--                                                        ></nv-datepicker>-->
<!--                                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                                                <div class="nv-column desk-6 phab-12">-->
<!--                                                    <div class="nv-column-content">-->
<!--                                                        <nv-datepicker-->
<!--                                                                type="timer"-->
<!--                                                                label="Publish time"-->
<!--                                                                required-->
<!--                                                        ></nv-datepicker>-->
<!--                                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                                                <div class="nv-column desk-12">-->
<!--                                                    <div class="nv-column-content">-->
<!--                                                        <div class="nv-form-item nv-mandatory fw">-->
<!--                                                            <label>-->
<!--                                                                <input type="text" placeholder="Exclude slides">-->
<!--                                                                <span class="label-title">Exclude slides</span>-->
<!--                                                            </label>-->
<!--                                                        </div>&lt;!&ndash; /nv-form-item &ndash;&gt;-->
<!--                                                        <p style="opacity: 0.6;"><small>Example: 1, 3, 10…</small></p>-->
<!--                                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                                            </div>&lt;!&ndash; /.nv-row &ndash;&gt;-->
<!--                                            <div class="nv-row">-->
<!--                                                <div class="nv-column desk-12">-->
<!--                                                    <div class="nv-column-content">-->
<!--                                                        <div class="nv-btn-box flow-end gap-10">-->
<!--                                                            <button type="button" class="nv-btn medium accent1">Attach</button>-->
<!--                                                            &lt;!&ndash; DISABLED > <button type="button" class="nv-btn medium accent1" disabled>Attach</button> &ndash;&gt;-->
<!--                                                        </div>-->
<!--                                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                                            </div>&lt;!&ndash; /.nv-row &ndash;&gt;-->
<!--                                        </div>&lt;!&ndash; /.nv-modal-visual-chunk &ndash;&gt;-->
<!--                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                            </div>&lt;!&ndash; /.nv-row &ndash;&gt;-->

<!--                        &lt;!&ndash; /Modal (medium sized) content &ndash;&gt;-->
<!--                        </div>-->

<!--                    </div>&lt;!&ndash; /nv-column-content &ndash;&gt;-->
<!--                </div>-->
<!--            </div>&lt;!&ndash; /nv-row &ndash;&gt;-->
<!--        </div>&lt;!&ndash; /nv-section &ndash;&gt;-->
<!--    </section>-->


    <section class="nv-section guttered nv-pfizer-awards-videos">
        <div class="nv-port">
            <div class="nv-row spaced gap-30 propagate">
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content">
                        <h2 class="nv-awards-videos-cat">
                            <span>Cardiovascular, Urology and Nephrology</span>
                        </h2>
                    </div>
                </div>
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content">
                        <h3 class="nv-research-type">
                            Basic Research
                        </h3>
                    </div>
                </div>
                <div class="nv-column v-middle desk-4 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-awardees">
                            <div class="nv-awardee">
                                <figure>
                                        <img src="https://service.e-materials.com/storage/resources/pfizer_ag/ir_/ir_14_hai_li.jpg" alt="name">
                                    </figure>
                                <p>
                                    Wenfei Sun
                                    <span>
                                        <br>
                                        Swiss Federal Institute of Technology Zurich (ETH)
                                    </span>
                                </p>
                            </div>
                            <div class="nv-awardee">
                                <figure>
                                        <img src="https://service.e-materials.com/storage/resources/pfizer_ag/hun/hun_09_hua_dong.jpg" alt="name">
                                    </figure>
                                <p>
                                    Hua Dong
                                    <span>
                                        <br>
                                        Swiss Federal Institute of Technology Zurich (ETH)
                                   </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nv-column v-middle desk-8 lap-6 tab-12">
                    <div class="nv-column-content">
                        <figure class="media">
                            <!--<oembed url="https://vimeo.com/656986210" height="200" width="400"></oembed>-->
                        </figure>
                        <p class="nv-video-type">
                            Awardee Video
                        </p>
                    </div>
                </div>
                <div class="nv-column v-middle desk-4 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-awardee">
                            <p style="text-align:center;">
                                <b><strong>Laudator</strong></b>
                            </p>
                            <p>
                                Prof. Daniel Hayoz
                            </p>
                            <p>
                                <span>
                               HFR Fribourg - Cantonal Hospital
                                <br>
                                Head Physician Medical Clinic
                                <br>
                                President of the Scientific Commission
                                <br>
                                Cardiovascular Medicine, Urology, and Nephrology
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="nv-column v-middle desk-8 lap-6 tab-12">
                    <div class="nv-column-content">
                        <figure class="media">
                            <!--<oembed url="https://vimeo.com/656986210"></oembed>-->
                        </figure>
                        <p class="nv-video-type">
                            Laudation Video
                        </p>
                    </div>
                </div>
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content">
                        <h3 class="nv-research-type">
                            Clinical Research
                        </h3>
                    </div>
                </div>
                <div class="nv-column v-middle desk-4 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-awardees">
                            <div class="nv-awardee">
                            <figure>
                                    <img src="https://service.e-materials.com/storage/resources/pfizer_ag/hun/hun_05_david_legouis.jpg" alt="name">
                                </figure>
                            <p>
                                David Legouis
                            </p>
                        </div>
                            <div class="nv-awardee">
                                <figure>
                                        <img src="https://service.e-materials.com/storage/resources/pfizer_ag/hun/hun_05_pietro_cippa.jpg" alt="name">
                                    </figure>
                                <p>
                                    Pietro Cippa
                                </p>
                            </div>
                        </div>
                    </div><!-- /nv-awardees -->
                </div>
                <div class="nv-column v-middle desk-8 lap-6 tab-12">
                    <div class="nv-column-content">
                        <figure class="media">
                            <!--<oembed url="https://vimeo.com/656986210"></oembed>-->
                        </figure>
                        <p>

                        </p>
                        <p class="nv-video-type">
                            Awardee Video
                        </p>
                    </div>
                </div>
                <div class="nv-column v-middle desk-4 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-awardee">
                            <p>
                                <b><strong>Laudator</strong></b>
                            </p>
                            <p>
                                Prof. Daniel Hayoz
                            </p>
                            <p>
                                <span>
                                    HFR Fribourg - Cantonal Hospital
                                    <br>
                                    Head Physician Medical Clinic
                                    <br>
                                    <br>
                                    President of the Scientific Commission
                                    <br>
                                    Cardiovascular Medicine, Urology, and Nephrology
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="nv-column v-middle desk-8 lap-6 tab-12">
                    <div class="nv-column-content">
                        <figure class="media">
                            <!--<oembed url="https://vimeo.com/656986210"></oembed>-->
                        </figure>
                        <p class="nv-video-type">
                            Laudation Video
                        </p>
                    </div>
                </div>
            </div>
            <div class="nv-row spaced gap-30">
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content">
                        <h2 class="nv-awards-videos-cat">
                            <span>Infectious Diseases, Rheumatology and Immunology</span>
                        </h2>
                    </div>
                </div>
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content">
                        <h3 class="nv-research-type">
                            Basic Research
                        </h3>
                    </div>
                </div>
                <div class="nv-column v-middle desk-4 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-awardees">
                            <div class="nv-awardee">
                                <figure>
                                        <img src="https://service.e-materials.com/storage/resources/pfizer_ag/ir_/ir_14_hai_li.jpg" alt="Hai Li">
                                    </figure>
                                <p>
                                    Hai Li
                                </p>
                            </div>
                            <div class="nv-awardee">
                                <figure>
                                        <img src="https://service.e-materials.com/storage/resources/pfizer_ag/ir_/ir_14_julien_limenitakis.jpg" alt="Julien Limenitakis">
                                    </figure>
                                <p>
                                    Julien Limenitakis Li
                                </p>
                            </div>
                            <div class="nv-awardee">
                                <figure>
                                        <img src="https://service.e-materials.com/storage/resources/pfizer_ag/ir_/ir_14_stephanie_ganal_vonarburg.jpg" alt="Stephanie Ganal-Vonarburg">
                                    </figure>
                                <p>
                                    Stephanie Ganal-Vonarburg
                                </p>
                            </div>
                        </div><!-- /nv-awardees -->
                    </div>
                </div>
                <div class="nv-column v-middle desk-8 lap-6 tab-12">
                    <div class="nv-column-content">
                        <figure class="media">
                            <!--<oembed url="https://vimeo.com/656986210"></oembed>-->
                        </figure>
                        <p class="nv-video-type">
                            Awardee Video
                        </p>
                    </div>
                </div>
                <div class="nv-column v-middle desk-4 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-awardee">
                            <p>
                                <b><strong>Laudator</strong></b>
                            </p>
                            <p>
                                Prof. Huldrych Günthard
                            </p>
                            <p>
                                <span>
                                    Vice Clinic Director
                                    <br>
                                    Clinic for Infectious Diseases and Hospital Hygiene
                                    <br>
                                    University Hospital Zurich
                                    <br>
                                    <br>
                                    President of the Scientific Commission
                                    <br>
                                    Infectious Diseases, Rheumatology, and Immunology
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="nv-column v-middle desk-8 lap-6 tab-12">
                    <div class="nv-column-content">
                        <figure class="media">
                            <!--<oembed url="https://vimeo.com/656986210"></oembed>-->
                        </figure>
                        <p class="nv-video-type">
                            Laudation Video
                        </p>
                    </div>
                </div>
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content">
                        <h3 class="nv-research-type">
                            Clinical Research
                        </h3>
                    </div>
                </div>
                <div class="nv-column v-middle desk-4 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-awardee">
                            <figure>
                                    <img src="https://service.e-materials.com/storage/resources/pfizer_ag/ir_/ir_09_katharina_kusejko.jpg" alt="Katharina Kusejko">
                                </figure>
                            <p>
                                Katharina Kusejko
                            </p>
                        </div>
                    </div>
                </div>
                <div class="nv-column v-middle desk-8 lap-6 tab-12">
                    <div class="nv-column-content">
                        <figure class="media">
                            <!--<oembed url="https://vimeo.com/656986210"></oembed>-->
                        </figure>
                        <p class="nv-video-type">
                            Awardee Video
                        </p>
                    </div>
                </div>
                <div class="nv-column v-middle desk-4 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-awardee">
                            <p>
                                <b><strong>Laudator</strong></b>
                            </p>
                            <p>
                                Prof. Huldrych Günthard
                            </p>
                            <p>
                                <span>
                                    Vice Clinic Director
                                    <br>
                                    Clinic for Infectious Diseases and Hospital Hygiene
                                    <br>
                                    University Hospital Zurich
                                    <br>
                                    <br>
                                    President of the Scientific Commission
                                    <br>
                                    Infectious Diseases, Rheumatology, and Immunology
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="nv-column v-middle desk-8 lap-6 tab-12">
                    <div class="nv-column-content">
                        <figure class="media">
                            <!--<oembed url="https://vimeo.com/656986210"></oembed>-->
                        </figure>
                        <p class="nv-video-type">
                            Laudation Video
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row spaced gap-30 flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <div class="nv-session-info-box">
                            <div class="nv-session-info-left">
                                <div class="nv-session-top-meta">
                                    <p class="live"><span>Live</span></p>
                                    <p class="time">08:30 AM - 12:00 PM</p>
                                    <p class="location">Room: Florida</p>
                                </div><!-- /.nv-session-top-meta -->
                                <h2><a href>Welcome to the CSANZ Annual Scientific Meeting 2020 and ANZET Meeting 2020</a></h2>
                                <p><a href class="nv-btn accent1 medium">This session starts at 13:00</a></p>
                                <p>This session has ended.</p>
                                <p class="nv-sib-label">Chairs</p>


                                <!-- EXAMPLE with participant INFO -->
                                <div class="nv-participant-face">
                                    <figure><img src="../../assets/avatar-placeholder.png" alt="name"></figure>
                                    <div class="txt">
                                        <h4><a href>Alexandre Paiva</a></h4>
                                        <p>Brazil</p>
                                        <div class="nv-participant-face-info" style="display: none;"><!-- < toggle display on h4 > a click -->
                                            <div class="nv-participant-face-info-pane">
                                                <header>
                                                    <span class="close"></span>
                                                    <h3>Mariano Rasgado</h3>
                                                </header>
                                                <div class="nv-participant-face-info-pane-body">
                                                    <div class="nv-participant-face-info-pane-body-sleeve">
                                                        <p class="nv-sib-label">Chair of</p>

                                                        <div class="nv-participant-engagement streamed">
                                                            <h5>Streamed at: 08:30 am</h5>
                                                            <p>Transcatheter aortic valve replacement in severe thrombocytopenia</p>
                                                        </div><!-- /.nv-participant-engagement -->

                                                        <p class="nv-sib-label">Speaker of</p>

                                                        <div class="nv-participant-engagement watching">
                                                            <h5>Watching: 09:30 - 12:00AM</h5>
                                                            <p>Translating lessons from vascular biology into new treatment for coronar artery disease</p>
                                                        </div><!-- /.nv-participant-engagement -->

                                                        <div class="nv-participant-engagement forthcoming">
                                                            <h5>Sat, 11:30-12:15am, Florida</h5>
                                                            <p>Transcatheter aortic valve replacement in severe thrombocytopenia lorem ipsum</p>
                                                        </div><!-- /.nv-participant-engagement -->

                                                        <p><a href>View more info about speakers</a></p>
                                                    </div><!-- /.nv-participant-face-info-pane-body-sleeve -->
                                                </div><!-- /.nv-participant-face-info-pane-body -->
                                            </div><!-- /.nv-participant-face-info-pane -->
                                        </div><!-- /.nv-participant-face-info -->
                                    </div><!-- /.txt -->
                                </div><!-- /.nv-participant-face -->
                                <!-- //EXAMPLE with INFO -->

                                <div class="nv-participant-face">
                                    <figure><img src="../../assets/avatar-placeholder.png" alt="name"></figure>
                                    <div class="txt">
                                        <h4><a href>Tum Tantasatityanon</a></h4>
                                        <p>Alaska</p>
                                    </div><!-- /.txt -->
                                </div><!-- /.nv-participant-face -->
                            </div><!-- /.nv-session-info-left -->
                            <div class="nv-session-info-right">
                                <p class="nv-sib-label">Presentations</p>
                                <div class="nv-participant-engagement">
                                    <h5>
                                        Starts at: 08:30am
                                        <a href class="bookmark" title="Bookmark this presentation"></a>
                                    </h5>
                                    <p>Transcatheter aortic valve replacement in severe thrombocytopenia</p>
                                </div><!-- /.nv-participant-engagement -->
                                <div class="nv-participant-faces">
                                    <div class="nv-participant-face">
                                        <figure><img src="../../assets/avatar-placeholder.png" alt="name"></figure>
                                        <div class="txt">
                                            <h4><a href>Tum Tantasatityanon</a></h4>
                                            <p>Alaska</p>
                                        </div><!-- /.txt -->
                                    </div><!-- /.nv-participant-face -->
                                    <div class="nv-participant-face">
                                        <figure><img src="../../assets/avatar-placeholder.png" alt="name"></figure>
                                        <div class="txt">
                                            <h4><a href>Tum Tantasatityanon</a></h4>
                                            <p>Alaska</p>
                                        </div><!-- /.txt -->
                                    </div><!-- /.nv-participant-face -->
                                    <div class="nv-participant-face">
                                        <figure><img src="../../assets/avatar-placeholder.png" alt="name"></figure>
                                        <div class="txt">
                                            <h4><a href>Tum Tantasatityanon</a></h4>
                                            <p>Alaska</p>
                                        </div><!-- /.txt -->
                                    </div><!-- /.nv-participant-face -->
                                    <div class="nv-participant-face">
                                        <figure><img src="../../assets/avatar-placeholder.png" alt="name"></figure>
                                        <div class="txt">
                                            <h4><a href>Tum Tantasatityanon</a></h4>
                                            <p>Alaska</p>
                                        </div><!-- /.txt -->
                                    </div><!-- /.nv-participant-face -->
                                </div><!-- /.nv-participant-faces -->

                                <div class="nv-participant-engagement">
                                    <h5>
                                        Starts at: 10:00am
                                        <a href class="bookmark" title="Bookmark this presentation"></a>
                                    </h5>
                                    <p>Transcatheter aortic valve replacement in severe thrombocytopenia</p>
                                </div><!-- /.nv-participant-engagement -->
                                <div class="nv-participant-faces">
                                    <div class="nv-participant-face">
                                        <figure><img src="../../assets/avatar-placeholder.png" alt="name"></figure>
                                        <div class="txt">
                                            <h4><a href>Tum Tantasatityanon</a></h4>
                                            <p>Alaska</p>
                                        </div><!-- /.txt -->
                                    </div><!-- /.nv-participant-face -->
                                    <div class="nv-participant-face">
                                        <figure><img src="../../assets/avatar-placeholder.png" alt="name"></figure>
                                        <div class="txt">
                                            <h4><a href>Tum Tantasatityanon</a></h4>
                                            <p>Alaska</p>
                                        </div><!-- /.txt -->
                                    </div><!-- /.nv-participant-face -->
                                </div><!-- /.nv-participant-faces -->
                            </div><!-- /.nv-session-info-right -->
                        </div><!-- /.nv-session-info-box -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /nv-row -->
        </div><!-- /.nv-port -->
    </section>


    <!-- Pfizer awards -->
    <section class="nv-section guttered nv-pfizer-awards-videos">
        <div class="nv-port">

            <!-- ONE ITEM (category) -->
            <div class="nv-row spaced gap-30 propagate">
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content">
                        <h2 class="nv-awards-videos-cat"><span>Award Category</span></h2>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content">
                        <h3 class="nv-research-type">Basic Research</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum dicta dolorum, incidunt obcaecati quas quibusdam.<br><br><a class="nv-btn small accent1" data-icon-pl="&#xe04c;">Cum dicta dolorum PDF</a></p>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column v-middle desk-4 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-awardees">
                            <div class="nv-awardee">
                                <figure><img src="https://service.e-materials.com/storage/resources/pfizer_ag/ava/avatar_placeholder.png" alt="name"></figure>
                                <p>John Doe<br><a class="nv-awardee-pdf"></a></p>
                            </div><!-- /.nv-awardee -->
                            <div class="nv-awardee">
                                <figure><img src="https://service.e-materials.com/storage/resources/pfizer_ag/ava/avatar_placeholder.png" alt="name"></figure>
                                <p>John Doe</p>
                            </div><!-- /.nv-awardee -->
                            <div class="nv-awardee">
                                <figure><img src="https://service.e-materials.com/storage/resources/pfizer_ag/ava/avatar_placeholder.png" alt="name"></figure>
                                <p>John Doe</p>
                            </div><!-- /.nv-awardee -->
                        </div><!-- /.nv-awardees -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column v-middle desk-8 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-video-box-block padded">
                            <video width="320" height="240" controls poster="https://service.e-materials.com/storage/resources/efr/vid/video_ss.jpg">
                                <source src="https://service.e-materials.com/storage/resources/efr/efr/efr_intro.mp4" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                        </div><!-- /.nv-video-box-block -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column v-middle desk-4 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-awardee">
                            <figure><img src="https://service.e-materials.com/storage/resources/pfizer_ag/ava/avatar_placeholder.png" alt="name"></figure>
                            <p>John Doe</p>
                        </div><!-- /.nv-awardee -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column v-middle desk-8 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-video-box-block padded">
                            <video width="320" height="240" controls poster="https://service.e-materials.com/storage/resources/efr/vid/video_ss.jpg">
                                <source src="https://service.e-materials.com/storage/resources/efr/efr/efr_intro.mp4" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                        </div><!-- /.nv-video-box-block -->
                        <p class="nv-video-type">Awardee Video</p>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content">
                        <h3 class="nv-research-type">Clinical Research</h3>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column v-middle desk-4 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-awardee">
                            <figure><img src="https://service.e-materials.com/storage/resources/pfizer_ag/ava/avatar_placeholder.png" alt="name"></figure>
                            <p>John Doe</p>
                        </div><!-- /.nv-awardee -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column v-middle desk-8 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-video-box-block padded">
                            <video width="320" height="240" controls poster="https://service.e-materials.com/storage/resources/efr/vid/video_ss.jpg">
                                <source src="https://service.e-materials.com/storage/resources/efr/efr/efr_intro.mp4" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                        </div><!-- /.nv-video-box-block -->
                        <p class="nv-video-type">Laudation Video</p>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column v-middle desk-4 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-awardee">
                            <figure><img src="https://service.e-materials.com/storage/resources/pfizer_ag/ava/avatar_placeholder.png" alt="name"></figure>
                            <p>John Doe</p>
                        </div><!-- /.nv-awardee -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column v-middle desk-8 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-video-box-block padded">
                            <video width="320" height="240" controls poster="https://service.e-materials.com/storage/resources/efr/vid/video_ss.jpg">
                                <source src="https://service.e-materials.com/storage/resources/efr/efr/efr_intro.mp4" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                        </div><!-- /.nv-video-box-block -->
                        <p class="nv-video-type">Awardee Video</p>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
            </div><!-- /nv-row -->

            <!-- ONE ITEM (category) -->
            <div class="nv-row spaced gap-30">
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content">
                        <h2 class="nv-awards-videos-cat"><span>Award Category</span></h2>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content">
                        <h3 class="nv-research-type">Basic Research</h3>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column v-middle desk-4 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-awardee">
                            <figure><img src="https://service.e-materials.com/storage/resources/pfizer_ag/ava/avatar_placeholder.png" alt="name"></figure>
                            <p>John Doe</p>
                        </div><!-- /.nv-awardee -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column v-middle desk-8 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-video-box-block padded">
                            <video width="320" height="240" controls poster="https://service.e-materials.com/storage/resources/efr/vid/video_ss.jpg">
                                <source src="https://service.e-materials.com/storage/resources/efr/efr/efr_intro.mp4" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                        </div><!-- /.nv-video-box-block -->
                        <p class="nv-video-type">Laudation Video</p>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column v-middle desk-4 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-awardee">
                            <figure><img src="https://service.e-materials.com/storage/resources/pfizer_ag/ava/avatar_placeholder.png" alt="name"></figure>
                            <p>John Doe</p>
                        </div><!-- /.nv-awardee -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column v-middle desk-8 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-video-box-block padded">
                            <video width="320" height="240" controls poster="https://service.e-materials.com/storage/resources/efr/vid/video_ss.jpg">
                                <source src="https://service.e-materials.com/storage/resources/efr/efr/efr_intro.mp4" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                        </div><!-- /.nv-video-box-block -->
                        <p class="nv-video-type">Awardee Video</p>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content">
                        <h3 class="nv-research-type">Clinical Research</h3>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column v-middle desk-4 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-awardee">
                            <figure><img src="https://service.e-materials.com/storage/resources/pfizer_ag/ava/avatar_placeholder.png" alt="name"></figure>
                            <p>John Doe</p>
                        </div><!-- /.nv-awardee -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column v-middle desk-8 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-video-box-block padded">
                            <video width="320" height="240" controls poster="https://service.e-materials.com/storage/resources/efr/vid/video_ss.jpg">
                                <source src="https://service.e-materials.com/storage/resources/efr/efr/efr_intro.mp4" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                        </div><!-- /.nv-video-box-block -->
                        <p class="nv-video-type">Laudation Video</p>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column v-middle desk-4 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-awardee">
                            <figure><img src="https://service.e-materials.com/storage/resources/pfizer_ag/ava/avatar_placeholder.png" alt="name"></figure>
                            <p>John Doe</p>
                        </div><!-- /.nv-awardee -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column v-middle desk-8 lap-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-video-box-block padded">
                            <video width="320" height="240" controls poster="https://service.e-materials.com/storage/resources/efr/vid/video_ss.jpg">
                                <source src="https://service.e-materials.com/storage/resources/efr/efr/efr_intro.mp4" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                        </div><!-- /.nv-video-box-block -->
                        <p class="nv-video-type">Awardee Video</p>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
            </div><!-- /nv-row -->
        </div>
    </section>
    <!-- //Pfizer awards -->

        <!-- Select append to body -->
        <section class="nv-section guttered">
            <div class="nv-port">
                <div class="nv-row spaced gap-40">
                    <div class="nv-column desk-4 lap-6 tab-8 phab-12">
                        <div class="nv-column-content">
                            <nv-select 
                                [label]="'Select'"
                                [appendTo]="'body'"
                                [options]="[{name: 'test 1', id: 1}, {name: 'test 2', id: 2}, {name: 'test 3', id: 3}]">
                            </nv-select>
                        </div><!-- /nv-column-content -->
                    </div><!-- /nv-column -->
                </div>
            </div>
        </section>

    <!-- LIVE STAGE 'streaming' chunk" -->
    <section class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row spaced gap-40">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">


                        <div class="tmp-ls-container" style="width: 560px; height: 308px; overflow:  auto; border: 1px solid #999;">
                            <!-- code to take -->

                                <div class="nv-live-stage-streaming-box">
                                    <h3>
                                        <span>LIVE IN: Euro stream</span>
                                        <a href title="Change stream"></a>
                                    </h3>
                                    <ul class="nv-live-stage-streaming-box-list">
                                        <li>
                                            <div>
                                                <p class="time">11:00-13:00</p>
                                                <h4>Virtual Journey Design</h4>
                                                <p>Chairperson: Dr. Delores Glover</p>
                                            </div>
                                            <ul>
                                                <li>
                                                    <div>
                                                        <p class="time">11:00</p>
                                                        <h5>Translating lessons from vascular biology into...</h5>
                                                        <span class="author">Dr. Mia Hills</span>
                                                    </div>
                                                </li>
                                                <li class="live">
                                                    <div>
                                                        <p class="time">11:00</p>
                                                        <h5>Translating lessons from vascular biology into...</h5>
                                                        <span class="author">Dr. Mia Hills</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <p class="time">11:00</p>
                                                        <h5>Translating lessons from vascular biology into...</h5>
                                                        <span class="author">Dr. Mia Hills</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <p class="nv-ls-upcoming">UPCOMING</p>
                                    <ul class="nv-live-stage-streaming-box-list">
                                        <li>
                                            <div>
                                                <p class="time">11:00-13:00</p>
                                                <h4>Virtual Journey Design</h4>
                                                <p>Chairperson: Dr. Delores Glover</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div><!-- /.nv-live-stage-streaming-box -->

                            <!-- //code to take -->
                        </div><!-- /.tmp-ls-container -->

                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
            </div>
        </div>
    </section>

    <section class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row spaced gap-40">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                       <div class="nv-abstract-book">
                           <a class="nv-btn medium accent1" data-icon-pl="&#xe04c;" href>See the Abstract book here</a>
                       </div><!-- /.nv-abstract-book -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
            </div>
        </div>
    </section>

    <section class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row large-spaced-top spaced-bottom gap-40 v-stretch">
                <div class="nv-column desk-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-video-box-block padded">
                            <iframe src="https://player.vimeo.com/video/612652613" frameborder="0" allowfullscreen=""></iframe>
                        </div><!-- /.nv-video-box-block -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column desk-6 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-video-box-block padded">
                            <iframe src="https://player.vimeo.com/video/612396933" frameborder="0" allowfullscreen=""></iframe>
                        </div><!-- /.nv-video-box-block -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
            </div>
        </div>
    </section>



    <section class="nv-section guttered" style="display: none;">
        <div class="nv-port">
            <div class="nv-row spaced gap-40 v-stretch">
                <div class="nv-column desk-4 tab-6 phab-12">
                    <div class="nv-column-content">
                        <h3>Poster Session 1</h3>
                        <ul class="nv-poster-list">
                          <li>
                              <h4><span>P001</span>Organocatalytic Enantioselective Desymmetrisation of Cyclic Sulfonium Salts through Asymmetric Deprotonation</h4>
                              <p>Jorge Humbrias</p>
                          </li>
                            <li>
                                <h4><span>P002</span>Nanostructured hybrid xerogels synthesized by microwave heating</h4>
                                <p>Natalia Rey</p>
                            </li>
                            <li>
                                <h4><span>P003</span>Novel Catalysts for the Dehydrogenation of Formic Acid: Ligand Design and Mechanistic Details</h4>
                                <p>Manuel Iglesias Alonso</p>
                            </li>
                            <li>
                                <h4><span>P004</span>Dynamic covalent [2+2] macrocycles through multiple cyclic aminal formation of primary amines and aromatic dialdehydes.</h4>
                                <p>Antonio Jesús Ruiz Sanchez</p>
                            </li>
                            <li>
                                <h4><span>P005</span>Selective Hydroboration of Alkynes Catalyzed by Magnesium:?Dinuclear Organomagnesium Catalysis </h4>
                                <p>Ángeles Fuentes Domínguez</p>
                            </li>
                            <li>
                                <h4><span>P006</span>Thermal stability of polymers based on ethylene and norbornene for a new generation of capacitors  </h4>
                                <p>Alberto García Peñas</p>
                            </li>
                            <li>
                                <h4><span>P007</span>Naturally derived biopolymer electrolyte for Zn-air batteries: from lab-scale towards industry </h4>
                                <p>NAGORE ORTIZ VITORIANO</p>
                            </li>
                            <li>
                                <h4><span>P008</span>KuQuinone-Co3O4 nanoparticles hybrids for the oxygen evolution reaction</h4>
                                <p>Nuria Romero Fernández/p>
                            </li>
                            <li>
                                <h4><span>P009</span>Molecular Recognition of Complement System Proteins in Immunity Diseases and Immunoevasion. Computational Studies</h4>
                                <p>P009 Molecular Recognition of Complement System Proteins in Immunity Diseases and Immunoevasion. Computational Studies</p>
                            </li>
                            <li>
                                <h4><span>P010</span>Selective implantation of diamines for cooperative catalysis in isoreticular heterometallic titanium-organic frameworks</h4>
                                <p>Elena Lopez</p>
                            </li>
                            <li>
                                <h4><span>P011</span>Negatively-Curved Heptagon-Containing Nanographenes: Self-Association and Complexation Studies with Polycyclic Aromatic Hydrocarbons and Fullerenes </h4>
                                <p>Sandra Míguez Lago</p>
                            </li>
                            <li>
                                <h4><span>P012</span>Rhodium-Catalyzed ortho-Alkynylation of Nitroarenes</h4>
                                <p>Marc MONTESINOS-MAGRANER</p>
                            </li>
                            <li>
                                <h4><span>P013</span>EASY WIDE SCOPE ACCESS TO LUMINESCENT FLUOROPHOSPHORANES</h4>
                                <p>Jaime Ponce de León</p>
                            </li>
                            <li>
                                <h4><span>P014</span>Visible-light-accelerated Aminoalkylation of Quinoxalin-2-ones and Benzoxazin-2-ones with Dialkylazocarboxylates</h4>
                                <p>Jaume Rostoll Berenguer</p>
                            </li>
                            <li>
                                <h4><span>P015</span>Assembly of Tetrahydroquinolines and 2-Benzazepines by Pd-Catalyzed Cycloadditions Involving the Activation of C(sp3)–H Bonds</h4>
                                <p>Xandro Vidal</p>
                            </li>
                            <li>
                                <h4><span>P016</span>Gold(I)-Cavitand Complexes for the Enantioselective Alkoxycyclization of 1,6-Enynes </h4>
                                <p>Inmaculada Martin</p>
                            </li>
                            <li>
                                <h4><span>P017</span>Enantioselective Aminocatalytic [2+2] Photocycloaddition through Visible Light Excitation </h4>
                                <p>Thomas Rigotti</p>
                            </li>
                            <li>
                                <h4><span>P018</span>Insulin and IGF-1 conjugates for receptor studies and therapeutic applications</h4>
                                <p>Marisol Garre</p>
                            </li>
                            <li>
                                <h4><span>P019</span>Hybrid Systems Based on Porous Polymers for Solar Fuels Production: The Victory of Crystallinity</h4>
                                <p>Teresa Naranjo Sánchez</p>
                            </li>
                            <li>
                                <h4><span>P020</span>Synthesis of Enantiopure cis-Decahydroquinolines: Towards the Total Synthesis of Schoberine B</h4>
                                <p>Arnau Calbo</p>
                            </li>
                            <li>
                                <h4><span>P021</span>Kinetic Resolution of Allylamine Derivatives via Pd-Catalyzed C?H Functionalization with Allenes: Enantioselective Assembly of Tetrahydropyridine Scaffolds</h4>
                                <p>José Manuel González</p>
                            </li>
                            <li>
                                <h4><span>P022</span>Understanding the electronic properties of nitrogen-doped polycyclic aromatic dicarboximides</h4>
                                <p>Alexandra Harbuzaru Harbuzaru</p>
                            </li>
                            <li>
                                <h4><span>P023</span>Benzofused Seven-Membered N,O-Heterocycles  via Pd-Catalyzed Allylic C-H Activation</h4>
                                <p>Alvaro Velasco Rubio</p>
                            </li>
                            <li>
                                <h4><span>P024</span>Synthesis of gold-catalyzed propargylamines starting from benzyl alcohols</h4>
                                <p>Stephany Zárate</p>
                            </li>
                            <li>
                                <h4><span>P025</span>Organofluorine Compounds from Organocatalytic and Enantioselective 1,2-Addition of ?-Keto Hydrazones to ?-Fluorinated Ketones </h4>
                                <p>Saúl Alberca Manzano</p>
                            </li>
                            <li>
                                <h4><span>P026</span>Asymmetric (3+2) cycloadditions of alkylidenecyclopropanes catalyzed by low-valent cobalt complexes </h4>
                                <p>Eduardo da Concepción Vicente</p>
                            </li>
                            <li>
                                <h4><span>P027</span>Palladium Catalysts Confined On Porous Organic Polymers For Heterocoupling Reactions</h4>
                                <p>María Eugenia Marqués López</p>
                            </li>
                            <li>
                                <h4><span>P028</span>Asymmetric Organocatalyzed Synthesis of Chromenes via Phospha-Michael Addition</h4>
                                <p>María Eugenia Marqués López</p>
                            </li>
                            <li>
                                <h4><span>P029</span>Development of the diastereoselective intramolecular cycloaromatization- Pictet-Spengler tandem reaction1,2</h4>
                                <p>Daniel Gaviña Rueda</p>
                            </li>
                            <li>
                                <h4><span>P030</span>Mechanochemical one-pot synthesis of rufinamide </h4>
                                <p>Jorge Gómez-Carpintero</p>
                            </li>
                            <li>
                                <h4><span>P031</span>A Photoactivatable Ru(II)-Complex for the Photorelease of an Organocatalyst </h4>
                                <p>José Antonio González-Delgado</p>
                            </li>
                            <li>
                                <h4><span>P032</span>Structure sensitivity reaction of TCM HDC to light olefins using Pd catalysts supported on carbon nanotubes and carbon nanofibers</h4>
                                <p>Sichen Liu</p>
                            </li>
                            <li>
                                <h4><span>P033</span>Sulfolefin-type ligands for the enantioselective synthesis of tertiary trifluoromethyl carbinols: the prevalent role of the flag-methyl group. </h4>
                                <p>Rocío Recio Jiménez</p>
                            </li>
                            <li>
                                <h4><span>P034</span>New strategy to catalytic asymmetric synthesis of tetrahydroisoquinolines</h4>
                                <p>Sandra Rodríguez</p>
                            </li>
                            <li>
                                <h4><span>P035</span>Synthesis of Methyl 6-Substituted 2-Methoxybenzoates by Cycloaromatization of 2,4-Dien-6-ynecarboxylic Esters Catalyzed by Gold.</h4>
                                <p>Sergio Sánchez-Alonso</p>
                            </li>
                            <li>
                                <h4><span>P036</span>Novel synthesis and characterization of water-soluble air-stable metal-metal bonded diruthenium compounds </h4>
                                <p>Aaron Teran More</p>
                            </li>
                            <li>
                                <h4><span>P037</span>Chiral Heterogeneous Catalyst based on Binaphthyl-dihydro-azepine.</h4>
                                <p>Antonio Valverde González</p>
                            </li>
                            <li>
                                <h4><span>P038</span>An Intramolecular Annulation Catalyzed by Copper for the Efficient Formation of Fused Heterocycles</h4>
                                <p>Christopher Whiteoak</p>
                            </li>
                            <li>
                                <h4><span>P039</span>Continuous decontamination and recovery of polluted water with phosphorous compounds by Metal-Organic Frameworks</h4>
                                <p>Francisco Carmona</p>
                            </li>
                            <li>
                                <h4><span>P040</span>Co(III)-Catalyzed C?H Hydroarylation of Alkenes for the Synthesis of Dihydrobenzofurans</h4>
                                <p>Asier Carral</p>
                            </li>
                            <li>
                                <h4><span>P041</span>Modification of Cyclobutene Ring-Opening Reactions Torquoselectivity by Non-Innocent Remote Substituents</h4>
                                <p>Abel de Cózar</p>
                            </li>
                            <li>
                                <h4><span>P042</span>Searching for an Ir-catalyst for the asymmetric hydrogenation of a more diverse group of olefins. Moving from P,N ligands to P,C/S ligands</h4>
                                <p>Pol De La Cruz Sánchez Badia</p>
                            </li>
                            <li>
                                <h4><span>P043</span>Microwave assisted synthesis of ?-, ?- and ?-lactams from N-tethered benzyl and alkenyl trichloroacetamides catalyzed by RuCl2(PPh3)3</h4>
                                <p>Faïza DIABA</p>
                            </li>
                            <li>
                                <h4><span>P044</span>NHC-CDI betaine adducts as promoters in C-Cl bond activation processes for dichloromethane valorisation</h4>
                                <p>David Sánchez Roa</p>
                            </li>
                            <li>
                                <h4><span>P045</span>Microwave Assisted Palladium Catalyzed C-H Acylation of Thiophenes</h4>
                                <p>Carlos Santiago</p>
                            </li>
                            <li>
                                <h4><span>P046</span>A chemical system of astrochemical interest, LiH2+: New Potential Energy Surfaces and Dynamics studies.</h4>
                                <p>Pedro Alberto Enríquez</p>
                            </li>
                            <li>
                                <h4><span>P046</span>A chemical system of astrochemical interest, LiH2+: New Potential Energy Surfaces and Dynamics studies.</h4>
                                <p>Pedro Alberto Enríquez</p>
                            </li>
                            <li>
                                <h4><span>P047</span>Metal organic framework MIL-101 for magnetic solid phase extraction of six opium alkaloids in food samples followed by HPLC-MS/MS</h4>
                                <p>Gonzalo Martínez García</p>
                            </li>
                            <li>
                                <h4><span>P048</span>Application of Advanced Oxidation Processes to the remediation of groundwater polluted with HCHs </h4>
                                <p>Aurora Santos</p>
                            </li>
                            <li>
                                <h4><span>P049</span>Analysis of Se and Hg biomolecules distribution in poorly studied protein fractions of muscle tissues of highly consumed fishes</h4>
                                <p>Tamara Fernandez Bautista</p>
                            </li>
                            <li>
                                <h4><span>P050</span>Exploring the capabilities of ICP-MS-based techniques for the rapid analysis of platinum nanoparticles in bioanalytical applications</h4>
                                <p>Sergio Fernandez Trujillo</p>
                            </li>
                            <li>
                                <h4><span>P051</span>A Real Space Analysis of Intermolecular Electrostatic Interactions: The Role of Intramolecular Stabilization in Charge Penetration Energy</h4>
                                <p>Fernando Jimenez</p>
                            </li>
                            <li>
                                <h4><span>P052</span>Effect of Porous Polymer Network Loads in the gas separation properties of Polymers of Intrinsic Microporosity</h4>
                                <p>Laura Matesanz Niño</p>
                            </li>
                            <li>
                                <h4><span>P053</span>Efficient removal of BPA from water with laccase@NH2-MIL-53(Al) biocatalysts prepared in one-step</h4>
                                <p>Asun Molina Esquinas</p>
                            </li>
                            <li>
                                <h4><span>P054</span>Nanoelicitors based on Calcium Phospate nanoparticles for precision agriculture</h4>
                                <p>Belén Parra Torrejón</p>
                            </li>
                            <li>
                                <h4><span>P055</span>Hydrothermal carbonization and anaerobic digestion as a solution for biowaste management</h4>
                                <p>Eneko Suarez</p>
                            </li>
                            <li>
                                <h4><span>P056</span>Microwave-assisted synthesis of tunable CsPb(Cl,Br)3 nanocrystals</h4>
                                <p>Carolina Fernandez Saiz</p>
                            </li>
                            <li>
                                <h4><span>P057</span>Formation of Water-in-water (W/W) emulsions by phase transition methods </h4>
                                <p>Jordi Esquena</p>
                            </li>
                            <li>
                                <h4><span>P058</span>Hidroarilación intramolecular de alenil-indoles catalizada por nanopartículas de plata </h4>
                                <p>Amparo Luna Costales</p>
                            </li>
                            <li>
                                <h4><span>P059</span>Exploring the copper-catalyzed azide?alkyne cycloaddition (CuAAC) mechanism: Key species depending on the photocatalyst. </h4>
                                <p>Rebeca Martínez-Haya</p>
                            </li>
                            <li>
                                <h4><span>P060</span>Dynamics and Kinetics of Capture Processes in He, Ne, Ar + H2+ Collisions</h4>
                                <p>M.Pilar Puyuelo</p>
                            </li>
                            <li>
                                <h4><span>P061</span>Photochemical C-C and C-N couplings through C-H activation in dehydroaminoacids promoted by BF3</h4>
                                <p>Esteban Urriolabeitia</p>
                            </li>

                            <li>
                                <h4><span>P062</span>Shining Light on Copper: anti-Hydroboration of Alkynes via Photoinduced Dual Ligand Tandem Catalysis</h4>
                                <p>Javier Corpas Pardo</p>
                            </li>
                            <li>
                                <h4><span>P063</span>Outstanding nobility observed in Cu5 clusters showing high thermal stability and reversible oxidation</h4>
                                <p>Miguel Cuerva</p>
                            </li>
                            <li>
                                <h4><span>P064</span>New Discoveries in the Ni-Catalyzed Heck Reaction: b-Hydride Elimination Assisted by Deprotonable P-N Ligands.</h4>
                                <p>Tomas Gil Santiago</p>
                            </li>
                            <li>
                                <h4><span>P065</span>Ni-catalysed domino hydroborylative policyclization of allenynes</h4>
                                <p>Inés Manjon</p>
                            </li>
                            <li>
                                <h4><span>P066</span>Structural changes of waterborne poly(urethane urea) by adding graphene oxide with different surface chemistry</h4>
                                <p>Miguel Martín Martínez</p>
                            </li>
                            <li>
                                <h4><span>P067</span>Use of a Tripotic Asymmetric Alkyne in the Synthesis of a New Yttrium-based MOF: Electrophoretic behavior and Catalytic Performance</h4>
                                <p>Cristina Ruiz Martinez</p>
                            </li>
                            <li>
                                <h4><span>P068</span>Assessment of food grade avocado oil-in water emulsions and emulgels stabilized by phycocyanin extract and pectin</h4>
                                <p>Luis Alfonso Trujillo Cayado</p>
                            </li>
                            <li>
                                <h4><span>P069</span>Novel functional semiconductors based on 1,8-Naphthalimide. From the molecule to the material</h4>
                                <p>Matías Alonso Navarro</p>
                            </li>
                            <li>
                                <h4><span>P070</span>Recovery of zinc and iron from spent pickling acids: A life cycle perspective</h4>
                                <p>Andrea Arguillarena Maza</p>
                            </li>
                            <li>
                                <h4><span>P071</span>Simple regeneration of used activated carbon (hazardous waste) for sustainable cathodes in Li–S batteries</h4>
                                <p>Almudena Benítez de la Torre</p>
                            </li>
                            <li>
                                <h4><span>P072</span>Discovery of small-molecule inhibitor by a dynamic combinatorial strategy</h4>
                                <p>Andrea Canal Martin</p>
                            </li>
                            <li>
                                <h4><span>P073</span>Band level engineering of hybrid organic-copper oxide p-semiconductors</h4>
                                <p>Ignacio Chamorro-Mena</p>
                            </li>
                            <li>
                                <h4><span>P074</span>Al-ion Batteries: cheap, reliable and cleaner energy storage</h4>
                                <p>David Giraldo</p>
                            </li>
                            <li>
                                <h4><span>P075</span>Metal-Free Temperature-controlled Regiodivergent Borylative Cyclization of enynes: a Boron-migration Promoted Skeletal Rearrangement</h4>
                                <p>Ana Milián</p>
                            </li>
                            <li>
                                <h4><span>P076</span>New Derivatives of Peyilenomonoimide and Ruthenium(II) Complexes of Interest as Fluorescent Markers in Bioconjugation</h4>
                                <p>Ángel Sáez</p>
                            </li>
                            <li>
                                <h4><span>P077</span>Catalytic performance of Zr-loaded P-containing activated carbon in the Methanol to Dimethyl Ether process at high temperatures</h4>
                                <p>Javier Torres Liñán</p>
                            </li>
                            <li>
                                <h4><span>P078</span>Functionalization of 4a,8a-Dihydro-4a-Aza-8a-Boranaphthalene via Regioselective Iridium-Catalyzed C?H Borylation</h4>
                                <p>Isabel Valencia</p>
                            </li>
                            <li>
                                <h4><span>P079</span>Towards greener chemistry by DFT</h4>
                                <p>Albert Poater</p>
                            </li>
                            <li>
                                <h4><span>P080</span>Carbohydrate recognition by complement Factor H and related proteins viewed by NMR</h4>
                                <p>Amaia Pereda</p>
                            </li>
                            <li>
                                <h4><span>P081</span>Computational Design of Rhenium(I) Carbonyl Complexes for Anticancer Photodynamic Therapy</h4>
                                <p>Daniel Álvarez Lorenzo</p>
                            </li>
                            <li>
                                <h4><span>P082</span>Guidelines for Tuning the Excited State Hückel?Baird Hybrid Aromatic Character of Pro?Aromatic Quinoidal Compounds</h4>
                                <p>Silvia Escayola</p>
                            </li>
                            <li>
                                <h4><span>P083</span>1,4-Dihydropyridine derivatives as potential anticancer drugs</h4>
                                <p>Sandra Ardevines</p>
                            </li>
                            <li>
                                <h4><span>P084</span>A novel red phosphorescent Ir(III)-Pt(II) complex:  Synthesis, characterization, anticancer activity</h4>
                                <p>Pezhman Ashoo</p>
                            </li>
                            <li>
                                <h4><span>P085</span>Enantioselective synthesis of pharmacologically relevant 4-amino-hydrocoumarins and their phosphorous isosters </h4>
                                <p>Gabriel Borrego</p>
                            </li>
                            <li>
                                <h4><span>P086</span>Heterogeneous riboflavin-based photocatalyst for pollutant oxidation through electron transfer processes</h4>
                                <p>Oscar Cabezuelo</p>
                            </li>
                            <li>
                                <h4><span>P087</span>Enzyme-Degradable PolyIon-Complex nanoparticles for antimicrobial delivery.</h4>
                                <p>Sameh El Sayed</p>
                            </li>
                            <li>
                                <h4><span>P088</span>Styrene derivatives: Study of the kinetic rate constant and the formation of secondary organic aerosols from their ozonolysis</h4>
                                <p>Alba Escalona</p>
                            </li>
                            <li>
                                <h4><span>P089</span>Predicting QTAIM charges with Neural Networks</h4>
                                <p>Miguel Gallegos González</p>
                            </li>
                            <li>
                                <h4><span>P090</span>Charge Transfer Complexes of an Artificial Oxyanion-Hole Enzyme Mimic with an Adaptable Cleft </h4>
                                <p>José Garrido</p>
                            </li>
                            <li>
                                <h4><span>P091</span>NHC-Catalyzed [3+2]-Asymmetric Annulation between Pyrazolidine-4,5- diones and Enals: Synthesis of novel Spirocyclic Pyrazolone-?- Butyrolactones and Computational Study of Mechanism and Stereoselectivity</h4>
                                <p>Marta Gil Ordóñez</p>
                            </li>
                            <li>
                                <h4><span>P092</span>Approach to novel vitamin D analogue of UVB1 with potential biological activity</h4>
                                <p>Uxía Gómez Bouzó</p>
                            </li>
                            <li>
                                <h4><span>P093</span>Characterization and valorisation of agri-food residues from garlic industry in Castilla-La Mancha </h4>
                                <p>Diego Jesús Gonzalez Serrano</p>
                            </li>
                            <li>
                                <h4><span>P094</span>VERY HIGH PHOTOTOXIC INDEXES IN PHOTOCHEMOTHERAPY WITH IRIDIUM COMPLEXES CONTAINING ?-EXTENDED BENZOQUINOLINE LIGANDS</h4>
                                <p>CARLOS GONZALO NAVARRO</p>
                            </li>
                            <li>
                                <h4><span>P095</span>Molecular Basis of Bicyclic Boronate ?-Lactamase Inhibitors of Ultrabroad Efficacy ? Insights from Molecular Dynamics Simulation Studies</h4>
                                <p>Emilio José Lence Quintana</p>
                            </li>
                            <li>
                                <h4><span>P096</span>Rational Design of Bithiophene Photocatalysts for the Visible-Light-Induced Functionalization of Heteroarenes</h4>
                                <p>Raúl Martin Lozano</p>
                            </li>
                            <li>
                                <h4><span>P097</span>Specificity and thermodynamics of the interaction between four human PDZ domains and eight host, viral and designed ligands</h4>
                                <p>Javier Murciano Calles</p>
                            </li>
                            <li>
                                <h4><span>P098</span>Titanium based compounds as anodes in potassium-ion batteries</h4>
                                <p>Elena Sánchez Ahijón</p>
                            </li>
                            <li>
                                <h4><span>P100</span>Luminescent properties of a new family of Ln-MOFs after being easily processed into pellets and membranes for their future possible integration into devices.</h4>
                                <p>Estitxu Echenique Erradonea</p>
                            </li>
                            <li>
                                <h4><span>P101</span>Following the light: Assembly of sensors based on Covalent Organic Frameworks with dual emission and 3D printing of fluorescent materials</h4>
                                <p>Paloma Garcia Arroyo</p>
                            </li>
                            <li>
                                <h4><span>P102</span>Eco-friendly mechanochemical synthesis of titania-graphene nanocomposites for pesticide photodegradation</h4>
                                <p>VIVIANA JEHOVA GONZÁLEZ VELÁZQUEZ</p>
                            </li>
                            <li>
                                <h4><span>P103</span>Lignocellulose use to obtain high-added value products: Elastomers, adhesives and lubricating greases</h4>
                                <p>Antonio Borrero Lopez</p>
                            </li>
                            <li>
                                <h4><span>P104</span>Tuneable emission of a post-synthetic modified Covalent Organic Framework.</h4>
                                <p>Marcos Martinez Fernandez</p>
                            </li>
                            <li>
                                <h4><span>P105</span>Enzyme-controlled mesoporous nanomachine for dual-responsive delivery</h4>
                                <p>Beatriz Mayol</p>
                            </li>
                            <li>
                                <h4><span>P106</span>Tetrazine covalent adaptable networks</h4>
                                <p>David Santana Rivero</p>
                            </li>
                            <li>
                                <h4><span>P107</span>Towards the development of new membranes for the recycling of fluorinated gases</h4>
                                <p>Sergio Valentín Gutiérrez Hernández</p>
                            </li>
                            <li>
                                <h4><span>P108</span>Macroscopic Ultralight Aerogel Monoliths of Imine-based Covalent Organic Frameworks</h4>
                                <p>Jesús Ángel Martín Illán</p>
                            </li>
                            <li>
                                <h4><span>P109</span>Synthesis and photocatalytic performance of TiO2/Fe3O4/rGO composite for the removal of S-metolachlor</h4>
                                <p>Carmen Barquín</p>
                            </li>
                            <li>
                                <h4><span>P110</span>Recent approaches in novel hybrid perovskites with optimal bandgap for optoelectronic applications</h4>
                                <p>Antonio Chacón</p>
                            </li>
                            <li>
                                <h4><span>P111</span>Photobehaviour of Ensemble and Single Crystals of NR@2D ITQ MOF Composites</h4>
                                <p>Boiko Cohen</p>
                            </li>
                            <li>
                                <h4><span>P112</span>Effect of water on the methane adsorption performance of commercial Basolite metal-organic frameworks (MOFs)</h4>
                                <p>Eva Diaz</p>
                            </li>
                            <li>
                                <h4><span>P113</span>Developing isostructural conductive MOFs from tryphenylene derivatives for chemiresistive gas sensors</h4>
                                <p>Ignasi Fort Grandas </p>
                            </li>
                            <li>
                                <h4><span>P114</span>Ultrafast synthesis of tin chalcogenides for energy storage and conversion applications</h4>
                                <p>Marta González Barrios</p>
                            </li>
                            <li>
                                <h4><span>P115</span>Hydroxamate titanium-organic polyhedra with permanent porosity</h4>
                                <p>Belén Lerma Berlanga</p>
                            </li>
                            <li>
                                <h4><span>P116</span>Developme<span>nt o</span> Stimuli-Responsive Hybrid Materials based on Poly(phenylacetylene)s and Metal Nanoparticles</h4>
                                <p>Manuel Núñez Martínez</p>
                            </li>
                            <li>
                                <h4><span>P117</span>Detailed study of CO2 adsorption and SMM behavior on a Co-MOF</h4>
                                <p>Oier Pajuelo</p>
                            </li>
                            <li>
                                <h4><span>P118</span>New Mixed Matrix Membranes containing Porous Organic Polymers for CO2 capture</h4>
                                <p>Sandra Rico Martinez</p>
                            </li>
                            <li>
                                <h4><span>P119</span>A novel extraction method of Platinum nanoparticles from road dust: Optimization and characterization</h4>
                                <p>Armando Sanchez Cachero</p>
                            </li>
                            <li>
                                <h4><span>P120</span>Ge-based anodes for Li-ion batteries</h4>
                                <p>Laura Sierra Trujillo</p>
                            </li>
                            <li>
                                <h4><span>P121</span>Investigating mayenite electride as a potential thermoelectric material </h4>
                                <p>Marina Tabuyo Martínez</p>
                            </li>
                            <li>
                                <h4><span>P122</span>MICROPOROUS POLYMERS BASED ON DIPHOSPHINES AND THEIR APPLICATION IN CONFINED CATALYSIS</h4>
                                <p>M. Cristina Viyuela</p>
                            </li>
                            <li>
                                <h4><span>P123</span>UV-assisted catalytic wet peroxide oxidation of the cytostatic bicalutamide</h4>
                                <p>Alicia García Costa</p>
                            </li>
                            <li>
                                <h4><span>P124</span>Synthesis of ZnO nanoparticles and their incorporation in soy bioplastics for horticulture applications</h4>
                                <p>Mercedes Jiménez Rosado</p>
                            </li>
                            <li>
                                <h4><span>P125</span>Towards a new generation of multifunctional materials inspired in prebiotic chemistry</h4>
                                <p>Marta Ruiz Bermejo</p>
                            </li>
                            <li>
                                <h4><span>P126</span>Sandwich-type amperometric aptasensor for troponin I detection based on electrodes coated with carboxyethylsilanetriol-modified graphene oxide</h4>
                                <p>Anabel Villalonga Chico</p>
                            </li>
                            <li>
                                <h4><span>P127</span>Controlled-Release of Antimicrobial Silver loaded on Biocompatible Submicron Titanium Phosphate Phases </h4>
                                <p>Alaa Adawy</p>
                            </li>
                            <li>
                                <h4><span>P128</span>Tunning of the emissive properties of heteroleptic copper(I) complexes and their composite materials</h4>
                                <p>Adrián ALCONCHEL</p>
                            </li>
                            <li>
                                <h4><span>P129</span>Unravelling autoimmune diseases with novel electrochemical bioplatforms</h4>
                                <p>Beatriz Arévalo</p>
                            </li>
                            <li>
                                <h4><span>P130</span>Towards a rapid and easy pathogen detection using gated nanomaterials</h4>
                                <p>Elena Aznar</p>
                            </li>
                            <li>
                                <h4><span>P131</span>UNIMOLECULAR CONDUCTANCE OF AN ORGANIC BIRADICAL</h4>
                                <p>Raquel CASARES</p>
                            </li>
                            <li>
                                <h4><span>P132</span>The promise of graphene oxide for neural regeneration</h4>
                                <p>María Concepción Serrano</p>
                            </li>
                            <li>
                                <h4><span>P133</span>New hybrid organochlorinated xerogels</h4>
                                <p>Guillermo Cruz</p>
                            </li>
                            <li>
                                <h4><span>P134</span>Electrochemiluminiscent HER2 biomarker aptasensor for early diagnosis of breast cancer</h4>
                                <p>María Encarnación Lorenzo</p>
                            </li>
                            <li>
                                <h4><span>P135</span>Analysis of microfluidic systems for the continuous capture of target compounds using functionalized magnetic nanoparticles</h4>
                                <p>Belén García</p>
                            </li>
                            <li>
                                <h4><span>P136</span>Microfluidics mediated nanoparticles self-assembly for ultrasensitive detection  </h4>
                                <p>Sergio Gomez</p>
                            </li>
                            <li>
                                <h4><span>P137</span>Tin-loaded mesoporous silica nanomaterials: Anti-cancer activity, antiangiogenic properties and genotoxicity evaluation</h4>
                                <p>Santiago Gómez Ruiz</p>
                            </li>
                            <li>
                                <h4><span>P138</span>Preparation of Anti-inflammatory Cotton Fabrics and Silica Nanoparticles with Topical Medical Applications</h4>
                                <p>Albert Granados Toda</p>
                            </li>
                            <li>
                                <h4><span>P139</span>Effect of graphene-based nanomaterials on cell differentiation processes</h4>
                                <p>Marián Mantecon Oria</p>
                            </li>
                            <li>
                                <h4><span>P140</span>Hydrogels in soft robotics</h4>
                                <p>Francisco Javier Patiño Rodrigo</p>
                            </li>
                            <li>
                                <h4><span>P141</span>Organic nanoparticles of pyrene derivatives</h4>
                                <p>Andrea Revilla</p>
                            </li>
                            <li>
                                <h4><span>P142</span>Photopolymerizable chitosan hydrogels for tissue engineering</h4>
                                <p>Irene San Millán Rodríguez</p>
                            </li>
                            <li>
                                <h4><span>P143</span>Microwave-assisted conversion of olive stone into platform chemicals and biomaterials</h4>
                                <p>Alberto José Huertas Alonso</p>
                            </li>
                            <li>
                                <h4><span>P143</span>Microwave-assisted conversion of olive stone into platform chemicals and biomaterials</h4>
                                <p>Alberto José Huertas Alonso</p>
                            </li>
                            <li>
                                <h4><span>P144</span>Substituent Effects and Non-Covalent Interactions as Perturbating Factors of Intramolecular Hydrogen Bonds</h4>
                                <p>Al Mokhtar Lamsabhi</p>
                            </li>
                            <li>
                                <h4><span>P145</span>Design of consensus tetratricopeptide repeat (CTPR)-based orthogonal scaffolding units for the ordered assembly of enzymes</h4>
                                <p>Alba Ledesma Férnandez</p>
                            </li>
                            <li>
                                <h4><span>P146</span>Development of Fluorescent Molecular Sensor of Nitrotyrosine</h4>
                                <p>Lidia Pérez Márquez</p>
                            </li>
                            <li>
                                <h4><span>P147</span>Multi-Dynamic Fluorescent Gels</h4>
                                <p>Esteban Suárez-Picado</p>
                            </li>
                            <li>
                                <h4><span>P148</span>Acetylation of Tyrosine-Containing Peptides Using Ethanol as Sustainable Feedstock</h4>
                                <p>Iñaki Urruzuno</p>
                            </li>
                            <li>
                                <h4><span>P149</span>Synthesis of Star-Shaped Polypeptide as Theranostic Nanomedicine</h4>
                                <p>Santiago SARASA SAN-JOSÉ</p>
                            </li>
                            <li>
                                <h4><span>P150</span>Mesostructured silicas as clean-up sorbents in a miniaturized QuEChERS strategy for the determination of pyrrolizidine alkaloids in aromatic herbs</h4>
                                <p>Natalia Casado</p>
                            </li>
                            <li>
                                <h4><span>P151</span>CO2 activation by enzymatic mimics based on pseudopeptidic macrocycles </h4>
                                <p>Eduardo Cepeda</p>
                            </li>
                            <li>
                                <h4><span>P152</span>The Competitve Aggregation Pathway of an Asymmetric Chiral Oligo(p- phenyleneethynylene) for Obtaining Individual Helical Aggregates</h4>
                                <p>Zulema Fernández</p>
                            </li>
                            <li>
                                <h4><span>P153</span>Chemical modification of allergenic proteins by plant-based polyphenols</h4>
                                <p>Nuria Fernandez Rivera</p>
                            </li>
                            <li>
                                <h4><span>P154</span>Design and systhesis of new supramolecular Polyethylenimine- Bisphosphonate-Cyclodextrin Ternary Conjugates for the Delivery of Antineoplastic Drugs</h4>
                                <p>Pablo García</p>
                            </li>
                            <li>
                                <h4><span>P155</span>The Role of polymer nanostructuration in hybrid materials for hydrogen production by means of artificial photosynthesis </h4>
                                <p>Alejandro García Eguizábal</p>
                            </li>
                            <li>
                                <h4><span>P156</span>Reactivity control of metallodrugs in solution to achieve biologically active metal complexes</h4>
                                <p>Adoracion Gomez Quiroga</p>
                            </li>
                            <li>
                                <h4><span>P157</span>Effect of Lys-207 of Trastuzumab on Stability of Antibody-Drug Conjugates Bearing Linkers Based on Acetals and Thiol-maleimide Chemistry</h4>
                                <p>Ester Jiménez</p>
                            </li>
                            <li>
                                <h4>Templated Self-Assembly of Nanoparticles from Water-in-Oil Microemulsions into Filament-like Superstructures</h4>
                                <p>Rebeca Fortes</p>
                            </li>
                            <li>
                                <h4>Cyclic Peptide Based Hydrogels</h4>
                                <p>Alfonso Bayon</p>
                            </li>
                            <li>
                                <h4>SUPRAMOLECULAR CAPSULES AS CATALYST</h4>
                                <p>Victoria Lopez</p>
                            </li>
                        </ul><!-- /.nv-poster-list -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column desk-12 -->
                <div class="nv-column desk-4 tab-6 phab-12">
                    <div class="nv-column-content">
                        <h3>Poster Session 2</h3>
                        <ul class="nv-poster-list">
                            <li>
                                <h4>P158 Mechanistic insights into the photorelease mechanism of avobenzone-based photocages</h4>
                                <p>Mauricio Lineros</p>
                            </li>
                            <li>
                                <h4>P159 Enhanced Photodynamic Activity of Carnitine-based BODIPY Sensitizers </h4>
                                <p>Larissa Maierhofer</p>
                            </li>
                            <li>
                                <h4>P160 Alkoxyl Radicals as Promoters of Radical Cascades Reactions in Cyclodextrin Frameworks </h4>
                                <p>Angeles Martín</p>
                            </li>
                            <li>
                                <h4>P161 Vibrio vulnificus , an emerging pathogen associated with climate change</h4>
                                <p>Mari Nieves Aranda Sobrino</p>
                            </li>
                            <li>
                                <h4>P162 Influence of the environmental conditions on the formation of metal soaps Marta PÉREZ- ESTÉBANEZ1, M. Piovesan1, M. San Andrés Moya11</h4>
                                <p>Marta Pérez Estébanez</p>
                            </li>
                            <li>
                                <h4>P163 Single-Stranted DNA as Supramolecular nanotool</h4>
                                <p>Antonio Pérez-Romero</p>
                            </li>
                            <li>
                                <h4>P164 The non-covalent nido-cage···?-ring interaction between boron clusters and aromatic rings</h4>
                                <p>Jordi Poater</p>
                            </li>
                            <li>
                                <h4>P165 Molecular super-gluing: a straightforward tool for antibody labelling and its application to mycotoxin biosensing</h4>
                                <p>Fernando Pradanas</p>
                            </li>
                            <li>
                                <h4>P166 Anthracene Photodimerization to Bend Self-assembling Peptide Nanotubes </h4>
                                <p>Marcos Vilela Picos</p>
                            </li>
                            <li>
                                <h4>P167 Synthesis of 1,3-thiazinane and 5,6-dihydro-4H-1,3-thiazine derivatives by gold(I) complexes</h4>
                                <p>Guillermo Canudo Barreras</p>
                            </li>
                            <li>
                                <h4>P168 Stereocontrolled synthesis of lipofuscin pigments</h4>
                                <p>Óscar Iglesias Menduiña</p>
                            </li>
                            <li>
                                <h4>P169 On-line liquid-liquid extraction for migration studies of 4,4´-dihydroxybiphenyl in food-contact materials</h4>
                                <p>Eulogio Llorent Martínez</p>
                            </li>
                            <li>
                                <h4>P170 Development of a method for the determination of polyamines including N -acetylated forms in human saliva via benzoylation and gas chromatography-mass spectrometry</h4>
                                <p>Javier Pena</p>
                            </li>
                            <li>
                                <h4>P171 Chiral distorted hexa-peri-hexabenzocoronenes bearing octagon- and nonagon-embedded carbo[5]helicenes</h4>
                                <p>Miguel Ángel Medel Miranda</p>
                            </li>
                            <li>
                                <h4>P172 Modeling lactic acid and lactose separation by nanofiltration</h4>
                                <p>Clara Casado Coterillo</p>
                            </li>
                            <li>
                                <h4>P173 Synthesis of Cyclobutanones via Gold(I)-Catalyzed [2+2] Cycloaddition of Ynol Ethers with Alkenes</h4>
                                <p>Andrea Cataffo</p>
                            </li>
                            <li>
                                <h4>P174 A General Organocatalytic System for Electron Donor?Acceptor Complex Photoactivation and its Use in Radical Processes </h4>
                                <p>Eduardo de Pedro</p>
                            </li>
                            <li>
                                <h4>P175 Aerobic/Moisture Compatible Selective Formation of C-P bonds by using Lithium Phosphides (LiPR2) in Deep Eutectic Solvents</h4>
                                <p>Joaquín García Álvarez</p>
                            </li>
                            <li>
                                <h4>P176 Bioorthogonal synthesis of quinoxalines enabled by copper-catalyzed N-H carbene insertions within mammalian cells</h4>
                                <p>Sara Gutiérrez Hernández</p>
                            </li>
                            <li>
                                <h4>P177 Visible light-mediated enantioselective aminoarylation of alkenes: streamlined access to ?,?-diarylethylamines</h4>
                                <p>Estíbaliz Merino Marcos</p>
                            </li>
                            <li>
                                <h4>P178 Smart synthesis of polypyrazolylborate ligands: completing the family</h4>
                                <p>Andrea Olmos</p>
                            </li>
                            <li>
                                <h4>P179 Atroposelective Transfer Hydrogenation of Biaryl Aminals via Dynamic Kinetic Resolution. Synthesis of Axially Chiral Diamines</h4>
                                <p>Carlos Rodríguez-Franco</p>
                            </li>
                            <li>
                                <h4>P180 Rotational Energy Relaxation inside Superfluid Helium Nanodroplets in two Different Dynamic Regimes. Comparison Between HCl and H2</h4>
                                <p>Eloi Sanchez Ambros</p>
                            </li>
                            <li>
                                <h4>P181 Diastereoselective additions to N-tert-butanesulfinyl imines derived from 4-halobutanal: Synthesis of pyrrolidine and indolizidine alkaloids</h4>
                                <p>Ana Sirvent Verdú</p>
                            </li>
                            <li>
                                <h4>P182 Synthesis of Iodo-carbazoles from Alkynyl Iodo-indoles</h4>
                                <p>Adelina Sonia Petcu</p>
                            </li>
                            <li>
                                <h4>P183 Nanolayered Molybdenum Sulfide-Based Catalysts for Fine and Sustainable Chemistry</h4>
                                <p>Iván Sorribes</p>
                            </li>
                            <li>
                                <h4>P184 A Supramolecular Reflection in Gold: Templated Formation of Gold Nanodendrites from Self-Assembled Organic Nanoparticles</h4>
                                <p>Tomás Torroba</p>
                            </li>
                            <li>
                                <h4>P185 Synergistic Cu/Pd catalysis as a handle for regioselectivity switch in allyl- allyl cross-coupling </h4>
                                <p>Nuria Vázquez Galiñanes</p>
                            </li>
                            <li>
                                <h4>P186 [2,2 ?-Bipyridin]-6-(1H)-one Ligand Promote Aerobic Oxidative Heck Reaction of Fluorobenzenes and Other Arenes: When Does the C-H Activation Need Help?</h4>
                                <p>Francisco Villalba</p>
                            </li>
                            <li>
                                <h4>P187 Diels–Alder reaction on perylenediimides: synthesis and theoretical study of core-expanded diimides.</h4>
                                <p>Nathalie Zink Lorre</p>
                            </li>
                            <li>
                                <h4>P188 On the rational design of magnetophoretic microseparators for blood detoxification: advanced MD and CFD models</h4>
                                <p>Cristina González-Fernández</p>
                            </li>
                            <li>
                                <h4>P189 Liposomes as cellular model on the study of colloidal dynamics in confined and crowded environments</h4>
                                <p>Irene Abelenda Núñez</p>
                            </li>
                            <li>
                                <h4>P190 Nuisance substructures and aggregators in food compounds:cheminformatic analysis of FooDB as source for putative false positives and promiscuity in bioassays</h4>
                                <p>Gonzalo Colmenarejo</p>
                            </li>
                            <li>
                                <h4>P191 Bicyclic DNJ-based GlcNAc mimetics as a new family of potent and selective O-GlcNAcase inhibitors</h4>
                                <p>Manuel GONZALEZ CUESTA</p>
                            </li>
                            <li>
                                <h4>P192 Discovery of a new anti-Alzheimer lead with in vivo efficacy guided by virtual screening directed to a cryptic pocket of BACE-1</h4>
                                <p>Diego Muñoz-Torrero</p>
                            </li>
                            <li>
                                <h4>P193 Visualization and mapping of [o-COSAN]- uptake in glioblastoma initiating (GICs) cells by using SR-FTIR microscopy</h4>
                                <p>Miquel Nuez-Martínez</p>
                            </li>
                            <li>
                                <h4>P194 Rational design of pathogen-selective antimicrobial peptides based on the amphibian peptide Andersonin-D1 </h4>
                                <p>Lucía Ageitos Castiñeiras</p>
                            </li>
                            <li>
                                <h4>P195 Structure-based Design of Anti-cancer Vaccines</h4>
                                <p>Francisco Corzana</p>
                            </li>
                            <li>
                                <h4>P197 Sugar-driven Cis-Trans Conformation in Peptides</h4>
                                <p>Fayna Garcia-Martin</p>
                            </li>
                            <li>
                                <h4>P198 Dissecting the Essential Role of Anomeric ??Triflates in  Glycosylation Reactions</h4>
                                <p>Andrés González Santana</p>
                            </li>
                            <li>
                                <h4>P199 Capacitive sensors based on MOF thin films as active layer </h4>
                                <p>Miguel Ángel Andrés del Rincón</p>
                            </li>
                            <li>
                                <h4>P200 Sustainable Approaches for Synthesis of Glucuronic Acid Glycodendrimers using CuAAC Reaction</h4>
                                <p>María Hernáiz</p>
                            </li>
                            <li>
                                <h4>P201 Reducing the spread of antibiotic-resistant bacteria (ARB) in complex urines with electrochemical technology</h4>
                                <p>Miguel Herraiz Carboné</p>
                            </li>
                            <li>
                                <h4>P202 Synthesis and biological evaluation of a new family of benzothiazole-based sulfinamide-sulfoxides derivatives as PPAR antagonists</h4>
                                <p>Nazaret Moreno</p>
                            </li>
                            <li>
                                <h4>P203 Deciphering the molecular feature of the ideal binder for DC-SIGN lectin through computation</h4>
                                <p>Reyes Núñez Franco</p>
                            </li>
                            <li>
                                <h4>P204 (Magneto)-catalytic Janus micromotors for bacterial endotoxin detection</h4>
                                <p>Marta Pacheco</p>
                            </li>
                            <li>
                                <h4>P205 Hydrothermal nutrient recovery from chicken bones</h4>
                                <p>Ricardo Paul Ipiales Macas</p>
                            </li>
                            <li>
                                <h4>P206 Polyurea/polyurethane Nanocapsules Targeting the Acidic Tumoral Microenviroment as Smart Drug Delivery Vehicles In Vitro and In Vivo</h4>
                                <p>Roberto Quesada</p>
                            </li>
                            <li>
                                <h4>P207 Design and synthesis of Mannich base-type derivatives containing imidazole and benzimidazole as lead compounds for drug discovery in Chagas Disease</h4>
                                <p>Mercedes Rubio Hernandez</p>
                            </li>
                            <li>
                                <h4>P208 Electrochemical Immunosensors as Analytical Tools for Monitoring Autoimmune Diseases</h4>
                                <p>Esther Sánchez Tirado</p>
                            </li>
                            <li>
                                <h4>P209 Electrochemical sensor for the assessment of carbohydrate deficient transferrin: Application to diagnosis of congenital disorders of glycosilation</h4>
                                <p>Tania Sierra</p>
                            </li>
                            <li>
                                <h4>P210 Synthesis of glycomimetics as rigid conformational analogues of sulforaphane </h4>
                                <p>Victoria Valdivia</p>
                            </li>
                            <li>
                                <h4>P212 Multivalent lanthanide-based glycoconjugates: towards a luminescent detection strategy for bacterial lectins</h4>
                                <p>Karolina Wojtczak</p>
                            </li>
                            <li>
                                <h4>P213 Enantioselective Ir-Catalyzed Hydroarylations: A Direct Approach to Pyrrole- and Indole-Fused Heterocyclic Systems with Quaternary Stereocenters</h4>
                                <p>Andrés Arribas</p>
                            </li>
                            <li>
                                <h4>P214 Highly Selective Insertion of Complex Alkynes through Manganese C?H activation</h4>
                                <p>Sara Cembellín</p>
                            </li>
                            <li>
                                <h4>P215 Novel organocatalytic tandem process to access chiral pyrrolizidinones</h4>
                                <p>Marcos Escolano</p>
                            </li>
                            <li>
                                <h4>P216 Protein crystallization: industrial scale-up</h4>
                                <p>Sara Illescas</p>
                            </li>
                            <li>
                                <h4>P217 Oxidation of Nitrogen-Containing Compounds using HFIP-UHP System</h4>
                                <p>Natalia Llopis</p>
                            </li>
                            <li>
                                <h4>P218 Readily available and highly modular metal-catalysts for challenging asymmetric hydrogenations and for water oxidation</h4>
                                <p>Jèssica Margalef Paralles</p>
                            </li>
                            <li>
                                <h4>P219 Obtention of cyclic carbonates by Gallium Catalyst: a comparison with their Aluminium Congeners</h4>
                                <p>Lucía Álvarez Miguel</p>
                            </li>
                            <li>
                                <h4>P220 Mechano-Responsive Luminescence via Crystal-to-Mesophase Transition in Gold(I) Isocyanide Complexes</h4>
                                <p>María Barcenilla</p>
                            </li>
                            <li>
                                <h4>P221 Desarrollo de un dispositivo luminiscente basado en papel para la determinación de nitritos y nitratos</h4>
                                <p>Isabel Blasco</p>
                            </li>
                            <li>
                                <h4>P222 Cellulose nanocrystals: chemistry and applications in energy</h4>
                                <p>Raúl Calle</p>
                            </li>
                            <li>
                                <h4>P223 Synthesis of fused dihydroazepine derivatives of fullerenes by a Rh- catalyzed cascade process</h4>
                                <p>Cristina Castanyer</p>
                            </li>
                            <li>
                                <h4>P224 Tracing Molecular and Structural Changes of U-shaped D-A-D ð-conjugated Systems with Mechanochromic Properties: Insight into their Vibrational Properties</h4>
                                <p>Sara Fernández Palacios</p>
                            </li>
                            <li>
                                <h4>P225 Exploring the synthesis and properties of three-dimensional rylenimide based semiconductors for their application in Organic Photovoltaics</h4>
                                <p>Elena Gala Sánchez</p>
                            </li>
                            <li>
                                <h4>P226 HMS mesostructured silica functionalized with sulfonic groups as cationic exchange sorbent for the extraction of atropine and scopolamine from thyme, basil and coriander</h4>
                                <p>Judith Gañán Aceituno</p>
                            </li>
                            <li>
                                <h4>P227 Breaking a Chemical Bond in a Superfluid Helium Nanodroplet. Photodissociation of Halogen Molecules in the B state </h4>
                                <p>Miguel González</p>
                            </li>
                            <li>
                                <h4>P228 Hierarchical zeolites as basic catalysts for the transformation of bulky molecules</h4>
                                <p>Monica Judith Mendoza</p>
                            </li>
                            <li>
                                <h4>P229 Catalytic isomerization of linear and cyclic allylic alcohols in water: What role plays water?  </h4>
                                <p>Belén López</p>
                            </li>
                            <li>
                                <h4>P230 ,NEW NAPHTHALENEMONOIMIDES WITH APLICATIONS FOR THE DETECTION OF OXIDATIVE ASPECIES AND CATIONS</h4>
                                <p>Mateo Martín Salgado</p>
                            </li>
                            <li>
                                <h4>P231 Few atoms metal clusters with high catalytic and cytotoxic activity characterized by synchrotron techniques.</h4>
                                <p>Judit Oliver Meseguer</p>
                            </li>
                            <li>
                                <h4>P232 Osmium(II) Tethered Half-Sandwich Complexes: pH-dependent Aqueous Speciation and Transfer Hydrogenation in Cells</h4>
                                <p>Ana Pizarro</p>
                            </li>
                            <li>
                                <h4>P233 On the road to enhance membrane properties for redox flow battery application </h4>
                                <p>Ivan Salmeron Sànchez</p>
                            </li>
                            <li>
                                <h4>P234 In situ formation of magnetite nanoparticles inside pores of protein crystals</h4>
                                <p>Mariia Savchenko</p>
                            </li>
                            <li>
                                <h4>P235 Determinación de la cinética de cristalización de polímeros a partir de los resulyados del método NPK</h4>
                                <p>Julià Sempere</p>
                            </li>
                            <li>
                                <h4>P236 Coupling scanning probe microscopy with electrochemistry to image electrochemical processes on surfaces at the micron scale</h4>
                                <p>Ricardo Souto</p>
                            </li>
                            <li>
                                <h4>P237 Organogels for the Control of the Concomitant Crystallization of Barbital and Thalidomide</h4>
                                <p>Iván Torres Moya</p>
                            </li>
                            <li>
                                <h4>P238 Copper(II) complexes of HPH-NH2 and HPHPY-NH2 as structural and functional models of lytic polysaccharide monooxygenases</h4>
                                <p>Azza A. HASSOON</p>
                            </li>
                            <li>
                                <h4>P239 Hypoxic actives Ru(II) PDT photosensitizers that operates under green light irradiation</h4>
                                <p>Francisco José Ballester</p>
                            </li>
                            <li>
                                <h4>P240 Novel Triazine-based materials for heterogeneous photocatalysis</h4>
                                <p>Beatriz Fuerte Díez</p>
                            </li>
                            <li>
                                <h4>P241 Methylparaben photodegradation in an exciplex flow-through photoreactor</h4>
                                <p>Maria Dolores Murcia Almagro</p>
                            </li>
                            <li>
                                <h4>P242 UNRAVELING THE STRUCTURAL, OPTICAL AND PHOTOLUMINESCENT PROPERTIES OF A PYRAZOLE-BASED COBALT(II) FRAMEWORK</h4>
                                <p>Mohammed Abdelbaky</p>
                            </li>
                            <li>
                                <h4>P243 Exploring carbonaceous 3D structures for lithium electrodeposition to be used in batteries. </h4>
                                <p>Begoña Acebedo</p>
                            </li>
                            <li>
                                <h4>P244 Coatings with Nanoencapsulated Phase Change Materials for Thermal Energy Storage </h4>
                                <p>Inés Adam Cervera</p>
                            </li>
                            <li>
                                <h4>P245 Three component one pot synthesis of cyclopalladated (E)-N-benzyl-1-phenylmethanimines</h4>
                                <p>Joan Albert Mach</p>
                            </li>
                            <li>
                                <h4>P246 Phosphorescent mono and binuclear 2-phenylbenzothiazole platinum complexes with pyrazole or pyrazolate ligands </h4>
                                <p>Eduardo Alcolea Villalba</p>
                            </li>
                            <li>
                                <h4>P247 Nickel-Catalyzed Heck: Studies on the Oxidative Addition step with PN Hybrid Ligands for the formation of Ni(0)</h4>
                                <p>Diego Alexandre</p>
                            </li>
                            <li>
                                <h4>P248 Computational insights into the isomerization of a planar triruthenium cluster via consecutive intramolecular metathesis reactions</h4>
                                <p></p>
                            </li>
                            <li>
                                <h4></h4>
                                <p></p>
                            </li>
                            <li>
                                <h4></h4>
                                <p></p>
                            </li>
                            <li>
                                <h4></h4>
                                <p></p>
                            </li>
                            <li>
                                <h4></h4>
                                <p></p>
                            </li>
                            <li>
                                <h4></h4>
                                <p></p>
                            </li>
                            <li>
                                <h4></h4>
                                <p></p>
                            </li>
                            <li>
                                <h4></h4>
                                <p></p>
                            </li>
                            <li>
                                <h4></h4>
                                <p></p>
                            </li>
                            <li>
                                <h4></h4>
                                <p></p>
                            </li>
                            <li>
                                <h4></h4>
                                <p></p>
                            </li>
                            <li>
                                <h4></h4>
                                <p></p>
                            </li>
                            <li>
                                <h4></h4>
                                <p></p>
                            </li>
                            <li>
                                <h4></h4>
                                <p></p>
                            </li>
                            <li>
                                <h4></h4>
                                <p></p>
                            </li>
                        </ul><!-- /.nv-poster-list -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column desk-12 -->
            </div>
        </div>
    </section>

    <section class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row large-spaced gap-40 v-stretch">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h2>Sessions</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>

        <section class="nv-section guttered">
            <div class="nv-port">
                <div class="nv-row spaced gap-40 v-stretch">
                <div class="nv-column desk-4 tab-6 phab-12">
                    <div class="nv-column-content">
                        <table cellspacing="0" border="0" class="nv-custom-presentations-list">
                            <tbody>
                            <tr class="label">
                                <td colspan="2">Poster Session 1</td>
                            </tr>
                            <tr>
                                <td><span>P001</span> Organocatalytic Enantioselective Desymmetrisation of Cyclic Sulfonium Salts through Asymmetric Deprotonation  </td>

                                <td>Jorge Humbrias</td>
                            </tr>
                            <tr>
                                <td><span>P002</span> Nanostructured hybrid xerogels synthesized by microwave heating </td>

                                <td>Natalia Rey</td>
                            </tr>
                            <tr>
                                <td><span>P003</span> Novel Catalysts for the Dehydrogenation of Formic Acid: Ligand Design and Mechanistic Details</td>

                                <td>Manuel Iglesias Alonso</td>
                            </tr>
                            <tr>
                                <td><span>P004</span> Dynamic covalent [2+2] macrocycles through multiple cyclic aminal formation of primary amines and aromatic dialdehydes.</td>

                                <td>Antonio Jesús Ruiz Sanchez</td>
                            </tr>
                            <tr>
                                <td><span>P005</span> Selective Hydroboration of Alkynes Catalyzed by Magnesium:?Dinuclear Organomagnesium Catalysis </td>

                                <td>Ángeles Fuentes Domínguez</td>
                            </tr>
                            <tr>
                                <td><span>P006</span> Thermal stability of polymers based on ethylene and norbornene for a new generation of capacitors  </td>

                                <td>Alberto García Peñas</td>
                            </tr>
                            <tr>
                                <td><span>P007</span> Naturally derived biopolymer electrolyte for Zn-air batteries: from lab-scale towards industry </td>

                                <td>NAGORE ORTIZ VITORIANO</td>
                            </tr>
                            <tr>
                                <td><span>P008</span> KuQuinone-Co3O4 nanoparticles hybrids for the oxygen evolution reaction</td>

                                <td>Nuria Romero Fernández</td>
                            </tr>
                            <tr>
                                <td><span>P009</span> Molecular Recognition of Complement System Proteins in Immunity Diseases and Immunoevasion. Computational Studies</td>

                                <td>Elena Gómez</td>
                            </tr>
                            <tr>
                                <td><span>P010</span> Selective implantation of diamines for cooperative catalysis in isoreticular heterometallic titanium-organic frameworks</td>

                                <td>Elena Lopez</td>
                            </tr>
                            <tr>
                                <td><span>P011</span> Negatively-Curved Heptagon-Containing Nanographenes: Self-Association and Complexation Studies with Polycyclic Aromatic Hydrocarbons and Fullerenes </td>

                                <td>Sandra Míguez Lago</td>
                            </tr>
                            <tr>
                                <td><span>P012</span> Rhodium-Catalyzed ortho-Alkynylation of Nitroarenes</td>

                                <td>Marc MONTESINOS-MAGRANER</td>
                            </tr>
                            <tr>
                                <td><span>P013</span> EASY WIDE SCOPE ACCESS TO LUMINESCENT FLUOROPHOSPHORANES</td>

                                <td>Jaime Ponce de León</td>
                            </tr>
                            <tr>
                                <td><span>P014</span> Visible-light-accelerated Aminoalkylation of Quinoxalin-2-ones and Benzoxazin-2-ones with Dialkylazocarboxylates</td>

                                <td>Jaume Rostoll Berenguer</td>
                            </tr>
                            <tr>
                                <td><span>P015</span> Assembly of Tetrahydroquinolines and 2-Benzazepines by Pd-Catalyzed Cycloadditions Involving the Activation of C(sp3)–H Bonds</td>

                                <td>Xandro Vidal</td>
                            </tr>
                            <tr>
                                <td><span>P016</span> Gold(I)-Cavitand Complexes for the Enantioselective Alkoxycyclization of 1,6-Enynes </td>

                                <td>Inmaculada Martin</td>
                            </tr>
                            <tr>
                                <td><span>P017</span> Enantioselective Aminocatalytic [2+2] Photocycloaddition through Visible Light Excitation </td>

                                <td>Thomas Rigotti</td>
                            </tr>
                            <tr>
                                <td><span>P018</span> Insulin and IGF-1 conjugates for receptor studies and therapeutic applications</td>

                                <td>Marisol Garre</td>
                            </tr>
                            <tr>
                                <td><span>P019</span> Hybrid Systems Based on Porous Polymers for Solar Fuels Production: The Victory of Crystallinity</td>

                                <td>Teresa Naranjo Sánchez</td>
                            </tr>
                            <tr>
                                <td><span>P020</span> Synthesis of Enantiopure cis-Decahydroquinolines: Towards the Total Synthesis of Schoberine B</td>

                                <td>Arnau Calbo</td>
                            </tr>
                            <tr>
                                <td><span>P021</span> Kinetic Resolution of Allylamine Derivatives via Pd-Catalyzed C?H Functionalization with Allenes: Enantioselective Assembly of Tetrahydropyridine Scaffolds</td>

                                <td>José Manuel González</td>
                            </tr>
                            <tr>
                                <td><span>P022</span> Understanding the electronic properties of nitrogen-doped polycyclic aromatic dicarboximides</td>

                                <td>Alexandra Harbuzaru Harbuzaru</td>
                            </tr>
                            <tr>
                                <td><span>P023</span> Benzofused Seven-Membered N,O-Heterocycles  via Pd-Catalyzed Allylic C-H Activation</td>

                                <td>Alvaro Velasco Rubio</td>
                            </tr>
                            <tr>
                                <td><span>P024</span> Synthesis of gold-catalyzed propargylamines starting from benzyl alcohols</td>

                                <td>Stephany Zárate</td>
                            </tr>
                            <tr>
                                <td><span>P025</span> Organofluorine Compounds from Organocatalytic and Enantioselective 1,2-Addition of ?-Keto Hydrazones to ?-Fluorinated Ketones </td>

                                <td>Saúl Alberca Manzano</td>
                            </tr>
                            <tr>
                                <td><span>P026</span> Asymmetric (3+2) cycloadditions of alkylidenecyclopropanes catalyzed by low-valent cobalt complexes </td>

                                <td>Eduardo da Concepción Vicente</td>
                            </tr>
                            <tr>
                                <td><span>P027</span> Palladium Catalysts Confined On Porous Organic Polymers For Heterocoupling Reactions</td>

                                <td>Noelia Esteban</td>
                            </tr>
                            <tr>
                                <td><span>P028</span> Asymmetric Organocatalyzed Synthesis of Chromenes via Phospha-Michael Addition</td>

                                <td>María Eugenia Marqués López</td>
                            </tr>
                            <tr>
                                <td><span>P029</span> Development of the diastereoselective intramolecular cycloaromatization- Pictet-Spengler tandem reaction1,2</td>

                                <td>Daniel Gaviña Rueda</td>
                            </tr>
                            <tr>
                                <td><span>P030</span> Mechanochemical one-pot synthesis of rufinamide </td>

                                <td>Jorge Gómez-Carpintero</td>
                            </tr>
                            <tr>
                                <td><span>P031</span> A Photoactivatable Ru(II)-Complex for the Photorelease of an Organocatalyst </td>

                                <td>José Antonio González-Delgado</td>
                            </tr>
                            <tr>
                                <td><span>P032</span> Structure sensitivity reaction of TCM HDC to light olefins using Pd catalysts supported on carbon nanotubes and carbon nanofibers</td>

                                <td>Sichen Liu</td>
                            </tr>
                            <tr>
                                <td><span>P033</span> Sulfolefin-type ligands for the enantioselective synthesis of tertiary trifluoromethyl carbinols: the prevalent role of the flag-methyl group. </td>

                                <td>Rocío Recio Jiménez</td>
                            </tr>
                            <tr>
                                <td><span>P034</span> New strategy to catalytic asymmetric synthesis of tetrahydroisoquinolines</td>

                                <td>Sandra Rodríguez</td>
                            </tr>
                            <tr>
                                <td><span>P035</span> Synthesis of Methyl 6-Substituted 2-Methoxybenzoates by Cycloaromatization of 2,4-Dien-6-ynecarboxylic Esters Catalyzed by Gold.</td>

                                <td>Sergio Sánchez-Alonso</td>
                            </tr>
                            <tr>
                                <td><span>P036</span> Novel synthesis and characterization of water-soluble air-stable metal-metal bonded diruthenium compounds </td>

                                <td>Aaron Teran More</td>
                            </tr>
                            <tr>
                                <td><span>P037</span> Chiral Heterogeneous Catalyst based on Binaphthyl-dihydro-azepine.</td>

                                <td>Antonio Valverde González</td>
                            </tr>
                            <tr>
                                <td><span>P038</span> An Intramolecular Annulation Catalyzed by Copper for the Efficient Formation of Fused Heterocycles</td>

                                <td>Christopher Whiteoak</td>
                            </tr>
                            <tr>
                                <td><span>P039</span> Continuous decontamination and recovery of polluted water with phosphorous compounds by Metal-Organic Frameworks</td>

                                <td>Francisco Carmona</td>
                            </tr>
                            <tr>
                                <td><span>P040</span> Co(III)-Catalyzed C?H Hydroarylation of Alkenes for the Synthesis of Dihydrobenzofurans</td>

                                <td>Asier Carral</td>
                            </tr>
                            <tr>
                                <td><span>P041</span> Modification of Cyclobutene Ring-Opening Reactions Torquoselectivity by Non-Innocent Remote Substituents</td>

                                <td>Abel de Cózar</td>
                            </tr>
                            <tr>
                                <td><span>P042</span> Searching for an Ir-catalyst for the asymmetric hydrogenation of a more diverse group of olefins. Moving from P,N ligands to P,C/S ligands</td>

                                <td>Pol De La Cruz Sánchez Badia</td>
                            </tr>
                            <tr>
                                <td><span>P043</span> Microwave assisted synthesis of ?-, ?- and ?-lactams from N-tethered benzyl and alkenyl trichloroacetamides catalyzed by RuCl2(PPh3)3</td>

                                <td>Faïza DIABA</td>
                            </tr>
                            <tr>
                                <td><span>P044</span> NHC-CDI betaine adducts as promoters in C-Cl bond activation processes for dichloromethane valorisation</td>

                                <td>David Sánchez Roa</td>
                            </tr>
                            <tr>
                                <td><span>P045</span> Microwave Assisted Palladium Catalyzed C-H Acylation of Thiophenes</td>

                                <td>Carlos Santiago</td>
                            </tr>
                            <tr>
                                <td><span>P046</span> A chemical system of astrochemical interest, LiH2+: New Potential Energy Surfaces and Dynamics studies.</td>

                                <td>Pedro Alberto Enríquez</td>
                            </tr>
                            <tr>
                                <td><span>P047</span> Metal organic framework MIL-101 for magnetic solid phase extraction of six opium alkaloids in food samples followed by HPLC-MS/MS</td>

                                <td>Gonzalo Martínez García</td>
                            </tr>
                            <tr>
                                <td><span>P048</span> Application of Advanced Oxidation Processes to the remediation of groundwater polluted with HCHs </td>

                                <td>Aurora Santos</td>
                            </tr>
                            <tr>
                                <td><span>P049</span> Analysis of Se and Hg biomolecules distribution in poorly studied protein fractions of muscle tissues of highly consumed fishes</td>

                                <td>Tamara Fernandez Bautista</td>
                            </tr>
                            <tr>
                                <td><span>P050</span> Exploring the capabilities of ICP-MS-based techniques for the rapid analysis of platinum nanoparticles in bioanalytical applications</td>

                                <td>Sergio Fernandez Trujillo</td>
                            </tr>
                            <tr>
                                <td><span>P051</span> A Real Space Analysis of Intermolecular Electrostatic Interactions: The Role of Intramolecular Stabilization in Charge Penetration Energy</td>

                                <td>Fernando Jimenez</td>
                            </tr>
                            <tr>
                                <td><span>P052</span> EFFECT OF POROUS POLYMER NETWORK LOADS IN THE GAS SEPARATION PROPERTIES OF POLYMERS OF INTRINSIC MICROPOROSITY</td>

                                <td>Laura Matesanz Niño</td>
                            </tr>
                            <tr>
                                <td><span>P053</span> Efficient removal of BPA from water with laccase@NH2-MIL-53(Al) biocatalysts prepared in one-step</td>

                                <td>Asun Molina Esquinas</td>
                            </tr>
                            <tr>
                                <td><span>P054</span> Nanoelicitors based on Calcium Phospate nanoparticles for precision agriculture</td>

                                <td>Belén Parra Torrejón</td>
                            </tr>
                            <tr>
                                <td><span>P055</span> Hydrothermal carbonization and anaerobic digestion as a solution for biowaste management </td>

                                <td>Eneko Suarez</td>
                            </tr>
                            <tr>
                                <td><span>P056</span> Microwave-assisted synthesis of tunable CsPb(Cl,Br)3 nanocrystals</td>

                                <td>Carolina Fernandez Saiz</td>
                            </tr>
                            <tr>
                                <td><span>P057</span> Formation of Water-in-water (W/W) emulsions by phase transition methods </td>

                                <td>Jordi Esquena</td>
                            </tr>
                            <tr>
                                <td><span>P058</span> Hidroarilación intramolecular de alenil-indoles catalizada por nanopartículas de plata </td>

                                <td>Amparo Luna Costales</td>
                            </tr>
                            <tr>
                                <td><span>P059</span> Exploring the copper-catalyzed azide?alkyne cycloaddition (CuAAC) mechanism: Key species depending on the photocatalyst. </td>

                                <td>Rebeca Martínez-Haya</td>
                            </tr>
                            <tr>
                                <td><span>P060</span> Dynamics and Kinetics of Capture Processes in He, Ne, Ar + H2+ Collisions</td>

                                <td>M.Pilar Puyuelo</td>
                            </tr>
                            <tr>
                                <td><span>P061</span> Photochemical C-C and C-N couplings through C-H activation in dehydroaminoacids promoted by BF3</td>

                                <td>Esteban Urriolabeitia</td>
                            </tr>
                            <tr>
                                <td><span>P062</span> Shining Light on Copper: anti-Hydroboration of Alkynes via Photoinduced Dual Ligand Tandem Catalysis</td>

                                <td>Javier Corpas Pardo </td>
                            </tr>
                            <tr>
                                <td><span>P063</span> Outstanding nobility observed in Cu5 clusters showing high thermal stability and reversible oxidation</td>

                                <td>Miguel Cuerva</td>
                            </tr>
                            <tr>
                                <td><span>P064</span> New Discoveries in the Ni-Catalyzed Heck Reaction: b-Hydride Elimination Assisted by Deprotonable P-N Ligands.</td>

                                <td>Tomas Gil Santiago</td>
                            </tr>
                            <tr>
                                <td><span>P065</span> Ni-catalysed domino hydroborylative policyclization of allenynes</td>

                                <td>Inés Manjon</td>
                            </tr>
                            <tr>
                                <td><span>P066</span> Structural changes of waterborne poly(urethane urea) by adding graphene oxide with different surface chemistry</td>

                                <td>Miguel Martín Martínez</td>
                            </tr>
                            <tr>
                                <td><span>P067</span> Use of a Tripotic Asymmetric Alkyne in the Synthesis of a New Yttrium-based MOF: Electrophoretic behavior and Catalytic Performance</td>

                                <td>Cristina Ruiz Martinez</td>
                            </tr>
                            <tr>
                                <td><span>P068</span> Assessment of food grade avocado oil-in water emulsions and emulgels stabilized by phycocyanin extract and pectin </td>

                                <td>Luis Alfonso Trujillo Cayado</td>
                            </tr>
                            <tr>
                                <td><span>P069</span> Novel functional semiconductors based on 1,8-Naphthalimide. From the molecule to the material.</td>

                                <td>Matías Alonso Navarro</td>
                            </tr>
                            <tr>
                                <td><span>P070</span> Recovery of zinc and iron from spent pickling acids: A life cycle perspective</td>

                                <td>Andrea Arguillarena Maza</td>
                            </tr>
                            <tr>
                                <td><span>P071</span> Simple regeneration of used activated carbon (hazardous waste) for sustainable cathodes in Li–S batteries</td>

                                <td>Almudena Benítez de la Torre</td>
                            </tr>
                            <tr>
                                <td><span>P072</span> Discovery of small-molecule inhibitor by a dynamic combinatorial strategy </td>

                                <td>Andrea Canal Martin</td>
                            </tr>
                            <tr>
                                <td><span>P073</span> Band level engineering of hybrid organic-copper oxide p-semiconductors</td>

                                <td>Ignacio Chamorro-Mena</td>
                            </tr>
                            <tr>
                                <td><span>P074</span> Al-ion Batteries: cheap, reliable and cleaner energy storage</td>

                                <td>David Giraldo</td>
                            </tr>
                            <tr>
                                <td><span>P075</span> Metal-Free Temperature-controlled Regiodivergent Borylative Cyclization of enynes: a Boron-migration Promoted Skeletal Rearrangement  </td>

                                <td>Ana Milián</td>
                            </tr>
                            <tr>
                                <td><span>P076</span> New Derivatives of Peyilenomonoimide and Ruthenium(II) Complexes of Interest as Fluorescent Markers in Bioconjugation </td>

                                <td>Ángel Sáez</td>
                            </tr>
                            <tr>
                                <td><span>P077</span> Catalytic performance of Zr-loaded P-containing activated carbon in the Methanol to Dimethyl Ether process at high temperatures</td>

                                <td>Javier Torres Liñán</td>
                            </tr>
                            <tr>
                                <td><span>P078</span> Functionalization of 4a,8a-Dihydro-4a-Aza-8a-Boranaphthalene via Regioselective Iridium-Catalyzed C?H Borylation.</td>

                                <td>Isabel Valencia</td>
                            </tr>
                            <tr>
                                <td><span>P079</span> Towards greener chemistry by DFT </td>

                                <td>Albert Poater</td>
                            </tr>
                            <tr>
                                <td><span>P080</span> Carbohydrate recognition by complement Factor H and related proteins viewed by NMR</td>

                                <td>Amaia Pereda</td>
                            </tr>
                            <tr>
                                <td><span>P081</span> Computational Design of Rhenium(I) Carbonyl Complexes for Anticancer Photodynamic Therapy</td>

                                <td>Daniel Álvarez Lorenzo</td>
                            </tr>
                            <tr>
                                <td><span>P082</span> Guidelines for Tuning the Excited State Hückel?Baird Hybrid Aromatic Character of Pro?Aromatic Quinoidal Compounds</td>

                                <td>Silvia Escayola</td>
                            </tr>
                            <tr>
                                <td><span>P083</span> 1,4-Dihydropyridine derivatives as potential anticancer drugs</td>

                                <td>Sandra Ardevines</td>
                            </tr>
                            <tr>
                                <td><span>P084</span> A novel red phosphorescent Ir(III)-Pt(II) complex:  Synthesis, characterization, anticancer activity</td>

                                <td>Pezhman Ashoo</td>
                            </tr>
                            <tr>
                                <td><span>P085</span> Enantioselective synthesis of pharmacologically relevant 4-amino-hydrocoumarins and their phosphorous isosters </td>

                                <td>Gabriel Borrego</td>
                            </tr>
                            <tr>
                                <td><span>P086</span> Heterogeneous riboflavin-based photocatalyst for pollutant oxidation through electron transfer processes</td>

                                <td>Oscar Cabezuelo</td>
                            </tr>
                            <tr>
                                <td><span>P087</span> Enzyme-Degradable PolyIon-Complex nanoparticles for antimicrobial delivery.</td>

                                <td>Sameh El Sayed</td>
                            </tr>
                            <tr>
                                <td><span>P088</span> Styrene derivatives: Study of the kinetic rate constant and the formation of secondary organic aerosols from their ozonolysis</td>

                                <td>Alba Escalona</td>
                            </tr>
                            <tr>
                                <td><span>P089</span> Predicting QTAIM charges with Neural Networks</td>

                                <td>Miguel Gallegos González</td>
                            </tr>
                            <tr>
                                <td><span>P090</span> Charge Transfer Complexes of an Artificial Oxyanion-Hole Enzyme Mimic with an Adaptable Cleft </td>

                                <td>José Garrido</td>
                            </tr>
                            <tr>
                                <td><span>P091</span> NHC-Catalyzed [3+2]-Asymmetric Annulation between Pyrazolidine-4,5- diones and Enals: Synthesis of novel Spirocyclic Pyrazolone-?- Butyrolactones and Computational Study of Mechanism and Stereoselectivity</td>

                                <td>Marta Gil Ordóñez</td>
                            </tr>
                            <tr>
                                <td><span>P092</span> Approach to novel vitamin D analogue of UVB1 with potential biological activity</td>

                                <td>Uxía Gómez Bouzó</td>
                            </tr>
                            <tr>
                                <td><span>P093</span> Characterization and valorisation of agri-food residues from garlic industry in Castilla-La Mancha </td>

                                <td>Diego Jesús Gonzalez Serrano</td>
                            </tr>
                            <tr>
                                <td><span>P094</span> VERY HIGH PHOTOTOXIC INDEXES IN PHOTOCHEMOTHERAPY WITH IRIDIUM COMPLEXES CONTAINING ?-EXTENDED BENZOQUINOLINE LIGANDS</td>

                                <td>CARLOS GONZALO NAVARRO</td>
                            </tr>
                            <tr>
                                <td><span>P095</span> Molecular Basis of Bicyclic Boronate ?-Lactamase Inhibitors of Ultrabroad Efficacy ? Insights from Molecular Dynamics Simulation Studies </td>

                                <td>Emilio José Lence Quintana</td>
                            </tr>
                            <tr>
                                <td><span>P096</span> Rational Design of Bithiophene Photocatalysts for the Visible-Light-Induced Functionalization of Heteroarenes</td>

                                <td>Raúl Martin Lozano</td>
                            </tr>
                            <tr>
                                <td><span>P097</span> Specificity and thermodynamics of the interaction between four human PDZ domains and eight host, viral and designed ligands</td>

                                <td>Javier Murciano Calles</td>
                            </tr>
                            <tr>
                                <td><span>P098</span> Titanium based compounds as anodes in potassium-ion batteries</td>

                                <td>Elena Sánchez Ahijón</td>
                            </tr>
                            <tr>
                                <td><span>P100</span> Luminescent properties of a new family of Ln-MOFs after being easily processed into pellets and membranes for their future possible integration into devices.</td>

                                <td>Estitxu Echenique Erradonea</td>
                            </tr>
                            <tr>
                                <td><span>P101</span> Following the light: Assembly of sensors based on Covalent Organic Frameworks with dual emission and 3D printing of fluorescent materials</td>

                                <td>Paloma Garcia Arroyo</td>
                            </tr>
                            <tr>
                                <td><span>P102</span> Eco-friendly mechanochemical synthesis of titania-graphene nanocomposites for pesticide photodegradation</td>

                                <td>VIVIANA JEHOVA GONZÁLEZ VELÁZQUEZ</td>
                            </tr>
                            <tr>
                                <td><span>P103</span> Lignocellulose use to obtain high-added value products: Elastomers, adhesives and lubricating greases</td>

                                <td>Antonio Borrero Lopez</td>
                            </tr>
                            <tr>
                                <td><span>P104</span> Tuneable emission of a post-synthetic modified Covalent Organic Framework.</td>

                                <td>Marcos Martinez Fernandez</td>
                            </tr>
                            <tr>
                                <td><span>P105</span> Enzyme-controlled mesoporous nanomachine for dual-responsive delivery</td>

                                <td>Beatriz Mayol</td>
                            </tr>
                            <tr>
                                <td><span>P106</span> Tetrazine covalent adaptable networks</td>

                                <td>David Santana Rivero</td>
                            </tr>
                            <tr>
                                <td><span>P107</span> Towards the development of new membranes for the recycling of fluorinated gases</td>

                                <td>Sergio Valentín Gutiérrez Hernández</td>
                            </tr>
                            <tr>
                                <td><span>P108</span> Macroscopic Ultralight Aerogel Monoliths of Imine-based Covalent Organic Frameworks</td>

                                <td>Jesús Ángel Martín Illán</td>
                            </tr>
                            <tr>
                                <td><span>P109</span> Synthesis and photocatalytic performance of TiO2/Fe3O4/rGO composite for the removal of S-metolachlor </td>

                                <td>Carmen Barquín</td>
                            </tr>
                            <tr>
                                <td><span>P110</span> Recent approaches in novel hybrid perovskites with optimal bandgap for optoelectronic applications </td>

                                <td>Antonio Chacón</td>
                            </tr>
                            <tr>
                                <td><span>P111</span> Photobehaviour of Ensemble and Single Crystals of NR@2D ITQ MOF Composites </td>

                                <td>Boiko Cohen</td>
                            </tr>
                            <tr>
                                <td><span>P112</span> Effect of water on the methane adsorption performance of commercial Basolite metal-organic frameworks (MOFs) </td>

                                <td>Eva Diaz</td>
                            </tr>
                            <tr>
                                <td><span>P113</span> Developing isostructural conductive MOFs from tryphenylene derivatives for chemiresistive gas sensors</td>

                                <td>Ignasi Fort Grandas </td>
                            </tr>
                            <tr>
                                <td><span>P114</span> Ultrafast synthesis of tin chalcogenides for energy storage and conversion applications</td>

                                <td>Marta González Barrios</td>
                            </tr>
                            <tr>
                                <td><span>P115</span> Hydroxamate titanium-organic polyhedra with permanent porosity </td>

                                <td>Belén Lerma Berlanga</td>
                            </tr>
                            <tr>
                                <td><span>P116</span> Development of Stimuli-Responsive Hybrid Materials based on Poly(phenylacetylene)s and Metal Nanoparticles</td>

                                <td>Manuel Núñez Martínez</td>
                            </tr>
                            <tr>
                                <td><span>P117</span> Detailed study of CO2 adsorption and SMM behavior on a Co-MOF</td>

                                <td>Oier Pajuelo</td>
                            </tr>
                            <tr>
                                <td><span>P118</span> New Mixed Matrix Membranes containing Porous Organic Polymers for CO2 capture</td>

                                <td>Sandra Rico Martinez</td>
                            </tr>
                            <tr>
                                <td><span>P119</span> A novel extraction method of Platinum nanoparticles from road dust: Optimization and characterization </td>

                                <td>Armando Sanchez Cachero</td>
                            </tr>
                            <tr>
                                <td><span>P120</span> Ge-based anodes for Li-ion batteries</td>

                                <td>Laura Sierra Trujillo</td>
                            </tr>
                            <tr>
                                <td><span>P121</span> Investigating mayenite electride as a potential thermoelectric material </td>

                                <td>Marina Tabuyo Martínez</td>
                            </tr>
                            <tr>
                                <td>MICROPOROUS POLYMERS BASED ON DIPHOSPHINES AND THEIR APPLICATION IN CONFINED CATALYSIS</td>

                                <td>M. Cristina Viyuela</td>
                            </tr>
                            <tr>
                                <td><span>P123</span> UV-assisted catalytic wet peroxide oxidation of the cytostatic bicalutamide</td>

                                <td>Alicia García Costa</td>
                            </tr>
                            <tr>
                                <td><span>P124</span> Synthesis of ZnO nanoparticles and their incorporation in soy bioplastics for horticulture applications</td>

                                <td>Mercedes Jiménez Rosado</td>
                            </tr>
                            <tr>
                                <td><span>P125</span> Towards a new generation of multifunctional materials inspired in prebiotic chemistry</td>

                                <td>Marta Ruiz Bermejo</td>
                            </tr>
                            <tr>
                                <td><span>P126</span> Sandwich-type amperometric aptasensor for troponin I detection based on electrodes coated with carboxyethylsilanetriol-modified graphene oxide</td>

                                <td>Anabel Villalonga Chico</td>
                            </tr>
                            <tr>
                                <td><span>P127</span> Controlled-Release of Antimicrobial Silver loaded on Biocompatible Submicron Titanium Phosphate Phases </td>

                                <td>Alaa Adawy</td>
                            </tr>
                            <tr>
                                <td><span>P128</span> Tunning of the emissive properties of heteroleptic copper(I) complexes and their composite materials</td>

                                <td>Adrián ALCONCHEL</td>
                            </tr>
                            <tr>
                                <td><span>P129</span> Unravelling autoimmune diseases with novel electrochemical bioplatforms </td>

                                <td>Beatriz Arévalo</td>
                            </tr>
                            <tr>
                                <td><span>P130</span> Towards a rapid and easy pathogen detection using gated nanomaterials</td>

                                <td>Elena Aznar</td>
                            </tr>
                            <tr>
                                <td><span>P131</span> UNIMOLECULAR CONDUCTANCE OF AN ORGANIC BIRADICAL</td>

                                <td>Raquel CASARES</td>
                            </tr>
                            <tr>
                                <td><span>P132</span> The promise of graphene oxide for neural regeneration</td>

                                <td>María Concepción Serrano</td>
                            </tr>
                            <tr>
                                <td><span>P133</span> New hybrid organochlorinated xerogels</td>

                                <td>Guillermo Cruz</td>
                            </tr>
                            <tr>
                                <td><span>P134</span> Electrochemiluminiscent HER2 biomarker aptasensor for early diagnosis of breast cancer.  </td>

                                <td>María Encarnación Lorenzo</td>
                            </tr>
                            <tr>
                                <td><span>P135</span> Analysis of microfluidic systems for the continuous capture of target compounds using functionalized magnetic nanoparticles</td>

                                <td>Belén García</td>
                            </tr>
                            <tr>
                                <td><span>P136</span> Microfluidics mediated nanoparticles self-assembly for ultrasensitive detection  </td>

                                <td>Sergio Gomez</td>
                            </tr>
                            <tr>
                                <td><span>P137</span> Tin-loaded mesoporous silica nanomaterials: Anti-cancer activity, antiangiogenic properties and genotoxicity evaluation</td>

                                <td>Santiago Gómez Ruiz</td>
                            </tr>
                            <tr>
                                <td><span>P138</span> Preparation of Anti-inflammatory Cotton Fabrics and Silica Nanoparticles with Topical Medical Applications </td>

                                <td>Albert Granados Toda</td>
                            </tr>
                            <tr>
                                <td><span>P139</span> Effect of graphene-based nanomaterials on cell differentiation processes</td>

                                <td>Marián Mantecon Oria</td>
                            </tr>
                            <tr>
                                <td><span>P140</span> Hydrogels in soft robotics</td>

                                <td>Francisco Javier Patiño Rodrigo</td>
                            </tr>
                            <tr>
                                <td><span>P141</span> Organic nanoparticles of pyrene derivatives</td>

                                <td>Andrea Revilla</td>
                            </tr>
                            <tr>
                                <td><span>P142</span> Photopolymerizable chitosan hydrogels for tissue engineering</td>

                                <td>Irene San Millán Rodríguez</td>
                            </tr>
                            <tr>
                                <td><span>P143</span> Microwave-assisted conversion of olive stone into platform chemicals and biomaterials </td>

                                <td>Alberto José Huertas Alonso</td>
                            </tr>
                            <tr>
                                <td><span>P144</span> Substituent Effects and Non-Covalent Interactions as Perturbating Factors of Intramolecular Hydrogen Bonds.</td>

                                <td>Al Mokhtar Lamsabhi</td>
                            </tr>
                            <tr>
                                <td><span>P145</span> Design of consensus tetratricopeptide repeat (CTPR)-based orthogonal scaffolding units for the ordered assembly of enzymes</td>

                                <td>Alba Ledesma Férnandez</td>
                            </tr>
                            <tr>
                                <td><span>P146</span> Development of Fluorescent Molecular Sensor of Nitrotyrosine</td>

                                <td>Lidia Pérez Márquez</td>
                            </tr>
                            <tr>
                                <td><span>P147</span> Multi-Dynamic Fluorescent Gels</td>

                                <td>Esteban Suárez-Picado</td>
                            </tr>
                            <tr>
                                <td><span>P148</span> Acetylation of Tyrosine-Containing Peptides Using Ethanol as Sustainable Feedstock</td>

                                <td>Iñaki Urruzuno</td>
                            </tr>
                            <tr>
                                <td><span>P149</span> Synthesis of Star-Shaped Polypeptide as Theranostic Nanomedicine</td>

                                <td>Santiago SARASA SAN-JOSÉ</td>
                            </tr>
                            <tr>
                                <td><span>P150</span> Mesostructured silicas as clean-up sorbents in a miniaturized QuEChERS strategy for the determination of pyrrolizidine alkaloids in aromatic herbs</td>

                                <td>Natalia Casado</td>
                            </tr>
                            <tr>
                                <td><span>P151</span> CO2 activation by enzymatic mimics based on pseudopeptidic macrocycles </td>

                                <td>Eduardo Cepeda</td>
                            </tr>
                            <tr>
                                <td><span>P152</span> The Competitve Aggregation Pathway of an Asymmetric Chiral Oligo(p- phenyleneethynylene) for Obtaining Individual Helical Aggregates</td>

                                <td>Zulema Fernández</td>
                            </tr>
                            <tr>
                                <td><span>P153</span> Chemical modification of allergenic proteins by plant-based polyphenols </td>

                                <td>Nuria Fernandez Rivera</td>
                            </tr>
                            <tr>
                                <td><span>P154</span> Design and systhesis of new supramolecular Polyethylenimine- Bisphosphonate-Cyclodextrin Ternary Conjugates for the Delivery of Antineoplastic Drugs</td>

                                <td>Pablo García</td>
                            </tr>
                            <tr>
                                <td><span>P155</span> The Role of polymer nanostructuration in hybrid materials for hydrogen production by means of artificial photosynthesis </td>

                                <td>Alejandro García Eguizábal</td>
                            </tr>
                            <tr>
                                <td><span>P156</span> Reactivity control of metallodrugs in solution to achieve biologically active metal complexes</td>

                                <td>Adoracion Gomez Quiroga</td>
                            </tr>
                            <tr>
                                <td><span>P157</span> Effect of Lys-207 of Trastuzumab on Stability of Antibody-Drug Conjugates Bearing Linkers Based on Acetals and Thiol-maleimide Chemistry</td>

                                <td>Ester Jiménez</td>
                            </tr>
                            </tbody>
                        </table>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
                <div class="nv-column desk-4 tab-6 phab-12">
                    <div class="nv-column-content">
                        <table cellspacing="0" border="0" class="nv-custom-presentations-list">
                            <tbody>
                            <tr class="label">
                                <td colspan="2">Poster Session 2</td>
                            </tr>
                            <tr>
                                <td><span>P158</span> Mechanistic insights into the photorelease mechanism of avobenzone-based photocages </td>

                                <td>Mauricio Lineros</td>
                            </tr>
                            <tr>
                                <td><span>P159</span> Enhanced Photodynamic Activity of Carnitine-based BODIPY Sensitizers </td>

                                <td>Larissa Maierhofer</td>
                            </tr>
                            <tr>
                                <td><span>P160</span> Alkoxyl Radicals as Promoters of Radical Cascades Reactions in Cyclodextrin Frameworks </td>

                                <td>Angeles Martín</td>
                            </tr>
                            <tr>
                                <td><span>P161</span> Vibrio vulnificus , an emerging pathogen associated with climate change</td>

                                <td>Mari Nieves Aranda Sobrino</td>
                            </tr>
                            <tr>
                                <td><span>P162</span> Influence of the environmental conditions on the formation of metal soaps Marta PÉREZ- ESTÉBANEZ1, M. Piovesan1, M. San Andrés Moya11 </td>

                                <td>Marta Pérez Estébanez</td>
                            </tr>
                            <tr>
                                <td><span>P163</span> Single-Stranted DNA as Supramolecular nanotool </td>

                                <td>Antonio Pérez-Romero</td>
                            </tr>
                            <tr>
                                <td><span>P164</span> The non-covalent nido-cage···?-ring interaction between boron clusters and aromatic rings</td>

                                <td>Jordi Poater</td>
                            </tr>
                            <tr>
                                <td><span>P165</span> Molecular super-gluing: a straightforward tool for antibody labelling and its application to mycotoxin biosensing </td>

                                <td>Fernando Pradanas</td>
                            </tr>
                            <tr>
                                <td><span>P166</span> Anthracene Photodimerization to Bend Self-assembling Peptide Nanotubes </td>

                                <td>Marcos Vilela Picos</td>
                            </tr>
                            <tr>
                                <td><span>P167</span> Synthesis of 1,3-thiazinane and 5,6-dihydro-4H-1,3-thiazine derivatives by gold(I) complexes</td>

                                <td>Guillermo Canudo Barreras </td>
                            </tr>
                            <tr>
                                <td><span>P168</span> Stereocontrolled synthesis of lipofuscin pigments</td>

                                <td>Óscar Iglesias Menduiña</td>
                            </tr>
                            <tr>
                                <td><span>P169</span> On-line liquid-liquid extraction for migration studies of 4,4´-dihydroxybiphenyl in food-contact materials</td>

                                <td>Eulogio Llorent Martínez</td>
                            </tr>
                            <tr>
                                <td><span>P170</span> Development of a method for the determination of polyamines including N -acetylated forms in human saliva via benzoylation and gas chromatography-mass spectrometry</td>

                                <td>Javier Pena</td>
                            </tr>
                            <tr>
                                <td><span>P171</span> Chiral distorted hexa-peri-hexabenzocoronenes bearing octagon- and nonagon-embedded carbo[5]helicenes</td>

                                <td>Miguel Ángel Medel Miranda</td>
                            </tr>
                            <tr>
                                <td><span>P172</span> Modeling lactic acid and lactose separation by nanofiltration </td>

                                <td>Clara Casado Coterillo</td>
                            </tr>
                            <tr>
                                <td><span>P173</span> Synthesis of Cyclobutanones via Gold(I)-Catalyzed [2+2] Cycloaddition of Ynol Ethers with Alkenes.</td>

                                <td>Andrea Cataffo</td>
                            </tr>
                            <tr>
                                <td><span>P174</span> A General Organocatalytic System for Electron Donor?Acceptor Complex Photoactivation and its Use in Radical Processes </td>

                                <td>Eduardo de Pedro</td>
                            </tr>
                            <tr>
                                <td><span>P175</span> Aerobic/Moisture Compatible Selective Formation of C-P bonds by using Lithium Phosphides (LiPR2) in Deep Eutectic Solvents </td>

                                <td>Joaquín García Álvarez</td>
                            </tr>
                            <tr>
                                <td><span>P176</span> Bioorthogonal synthesis of quinoxalines enabled by copper-catalyzed N-H carbene insertions within mammalian cells</td>

                                <td>Sara Gutiérrez Hernández</td>
                            </tr>
                            <tr>
                                <td><span>P177</span> Visible light-mediated enantioselective aminoarylation of alkenes: streamlined access to ?,?-diarylethylamines</td>

                                <td>Estíbaliz Merino Marcos</td>
                            </tr>
                            <tr>
                                <td><span>P178</span> Smart synthesis of polypyrazolylborate ligands: completing the family.</td>

                                <td>Andrea Olmos</td>
                            </tr>
                            <tr>
                                <td><span>P179</span> Atroposelective Transfer Hydrogenation of Biaryl Aminals via Dynamic Kinetic Resolution. Synthesis of Axially Chiral Diamines</td>

                                <td>Carlos Rodríguez-Franco</td>
                            </tr>
                            <tr>
                                <td><span>P180</span> Rotational Energy Relaxation inside Superfluid Helium Nanodroplets in two Different Dynamic Regimes. Comparison Between HCl and H2.</td>

                                <td>Eloi Sanchez Ambros</td>
                            </tr>
                            <tr>
                                <td><span>P181</span> Diastereoselective additions to N-tert-butanesulfinyl imines derived from 4-halobutanal: Synthesis of pyrrolidine and indolizidine alkaloids </td>

                                <td>Ana Sirvent Verdú</td>
                            </tr>
                            <tr>
                                <td><span>P182</span> Synthesis of Iodo-carbazoles from Alkynyl Iodo-indoles </td>

                                <td>Adelina Sonia Petcu</td>
                            </tr>
                            <tr>
                                <td><span>P183</span> Nanolayered Molybdenum Sulfide-Based Catalysts for Fine and Sustainable Chemistry </td>

                                <td>Iván Sorribes</td>
                            </tr>
                            <tr>
                                <td><span>P184</span> A Supramolecular Reflection in Gold: Templated Formation of Gold Nanodendrites from Self-Assembled Organic Nanoparticles</td>

                                <td>Tomás Torroba</td>
                            </tr>
                            <tr>
                                <td><span>P185</span> Synergistic Cu/Pd catalysis as a handle for regioselectivity switch in allyl- allyl cross-coupling </td>

                                <td>Nuria Vázquez Galiñanes</td>
                            </tr>
                            <tr>
                                <td><span>P186</span> [2,2 ?-Bipyridin]-6-(1H)-one Ligand Promote Aerobic Oxidative Heck Reaction of Fluorobenzenes and Other Arenes: When Does the C-H Activation Need Help?</td>

                                <td>Francisco Villalba</td>
                            </tr>
                            <tr>
                                <td><span>P187</span> Diels–Alder reaction on perylenediimides: synthesis and theoretical study of core-expanded diimides.</td>

                                <td>Nathalie Zink Lorre</td>
                            </tr>
                            <tr>
                                <td><span>P188</span> On the rational design of magnetophoretic microseparators for blood detoxification: advanced MD and CFD models</td>

                                <td>Cristina González-Fernández</td>
                            </tr>
                            <tr>
                                <td><span>P189</span> Liposomes as cellular model on the study of colloidal dynamics in confined and crowded environments</td>

                                <td>Irene Abelenda Núñez</td>
                            </tr>
                            <tr>
                                <td><span>P190</span> Nuisance substructures and aggregators in food compounds:cheminformatic analysis of FooDB as source for putative false positives and promiscuity in bioassays</td>

                                <td>Gonzalo Colmenarejo</td>
                            </tr>
                            <tr>
                                <td><span>P191</span> Bicyclic DNJ-based GlcNAc mimetics as a new family of potent and selective O-GlcNAcase inhibitors</td>

                                <td>Manuel GONZALEZ CUESTA</td>
                            </tr>
                            <tr>
                                <td><span>P192</span> Discovery of a new anti-Alzheimer lead with in vivo efficacy guided by virtual screening directed to a cryptic pocket of BACE-1</td>

                                <td>Diego Muñoz-Torrero</td>
                            </tr>
                            <tr>
                                <td><span>P193</span> Visualization and mapping of [o-COSAN]- uptake in glioblastoma initiating (GICs) cells by using SR-FTIR microscopy</td>

                                <td>Miquel Nuez-Martínez</td>
                            </tr>
                            <tr>
                                <td><span>P194</span> Rational design of pathogen-selective antimicrobial peptides based on the amphibian peptide Andersonin-D1 </td>

                                <td>Lucía Ageitos Castiñeiras</td>
                            </tr>
                            <tr>
                                <td><span>P195</span> Structure-based Design of Anti-cancer Vaccines</td>

                                <td>Francisco Corzana</td>
                            </tr>
                            <tr>
                                <td><span>P197</span> Sugar-driven Cis-Trans Conformation in Peptides</td>

                                <td>Fayna Garcia-Martin</td>
                            </tr>
                            <tr>
                                <td><span>P198</span> Dissecting the Essential Role of Anomeric ??Triflates in  Glycosylation Reactions</td>

                                <td>Andrés González Santana</td>
                            </tr>
                            <tr>
                                <td><span>P199</span> Capacitive sensors based on MOF thin films as active layer </td>

                                <td>Miguel Ángel Andrés del Rincón</td>
                            </tr>
                            <tr>
                                <td><span>P200</span> Sustainable Approaches for Synthesis of Glucuronic Acid Glycodendrimers using CuAAC Reaction</td>

                                <td>María Hernáiz</td>
                            </tr>
                            <tr>
                                <td><span>P201</span> Reducing the spread of antibiotic-resistant bacteria (ARB) in complex urines with electrochemical technology</td>

                                <td>Miguel Herraiz Carboné</td>
                            </tr>
                            <tr>
                                <td><span>P202</span> Synthesis and biological evaluation of a new family of benzothiazole-based sulfinamide-sulfoxides derivatives as PPAR antagonists</td>

                                <td>Nazaret Moreno</td>
                            </tr>
                            <tr>
                                <td><span>P203</span> Deciphering the molecular feature of the ideal binder for DC-SIGN lectin through computation </td>

                                <td>Reyes Núñez Franco</td>
                            </tr>
                            <tr>
                                <td><span>P204</span> (Magneto)-catalytic Janus micromotors for bacterial endotoxin detection</td>

                                <td>Marta Pacheco</td>
                            </tr>
                            <tr>
                                <td><span>P205</span> Hydrothermal nutrient recovery from chicken bones</td>

                                <td>Ricardo Paul Ipiales Macas</td>
                            </tr>
                            <tr>
                                <td><span>P206</span> Polyurea/polyurethane Nanocapsules Targeting the Acidic Tumoral Microenviroment as Smart Drug Delivery Vehicles In Vitro and In Vivo </td>

                                <td>Roberto Quesada</td>
                            </tr>
                            <tr>
                                <td><span>P207</span> Design and synthesis of Mannich base-type derivatives containing imidazole and benzimidazole as lead compounds for drug discovery in Chagas Disease</td>

                                <td>Mercedes Rubio Hernandez</td>
                            </tr>
                            <tr>
                                <td><span>P208</span> Electrochemical Immunosensors as Analytical Tools for Monitoring Autoimmune Diseases</td>

                                <td>Esther Sánchez Tirado</td>
                            </tr>
                            <tr>
                                <td><span>P209</span> Electrochemical sensor for the assessment of carbohydrate deficient transferrin: Application to diagnosis of congenital disorders of glycosilation </td>

                                <td>Tania Sierra</td>
                            </tr>
                            <tr>
                                <td><span>P210</span> Synthesis of glycomimetics as rigid conformational analogues of sulforaphane </td>

                                <td>Victoria Valdivia</td>
                            </tr>
                            <tr>
                                <td><span>P212</span> Multivalent lanthanide-based glycoconjugates: towards a luminescent detection strategy for bacterial lectins</td>

                                <td>Karolina Wojtczak</td>
                            </tr>
                            <tr>
                                <td><span>P213</span> Enantioselective Ir-Catalyzed Hydroarylations: A Direct Approach to Pyrrole- and Indole-Fused Heterocyclic Systems with Quaternary Stereocenters</td>

                                <td>Andrés Arribas</td>
                            </tr>
                            <tr>
                                <td><span>P214</span> Highly Selective Insertion of Complex Alkynes through Manganese C?H activation</td>

                                <td>Sara Cembellín</td>
                            </tr>
                            <tr>
                                <td><span>P215</span> Novel organocatalytic tandem process to access chiral pyrrolizidinones</td>

                                <td>Marcos Escolano</td>
                            </tr>
                            <tr>
                                <td><span>P216</span> Protein crystallization: industrial scale-up</td>

                                <td>Sara Illescas</td>
                            </tr>
                            <tr>
                                <td><span>P217</span> Oxidation of Nitrogen-Containing Compounds using HFIP-UHP System</td>

                                <td>Natalia Llopis</td>
                            </tr>
                            <tr>
                                <td><span>P218</span> Readily available and highly modular metal-catalysts for challenging asymmetric hydrogenations and for water oxidation</td>

                                <td>Jèssica Margalef Paralles</td>
                            </tr>
                            <tr>
                                <td><span>P219</span> Obtention of cyclic carbonates by Gallium Catalyst: a comparison with their Aluminium Congeners</td>

                                <td>Lucía Álvarez Miguel</td>
                            </tr>
                            <tr>
                                <td><span>P220</span> Mechano-Responsive Luminescence via Crystal-to-Mesophase Transition in Gold(I) Isocyanide Complexes</td>

                                <td>María Barcenilla</td>
                            </tr>
                            <tr>
                                <td><span>P221</span> Desarrollo de un dispositivo luminiscente basado en papel para la determinación de nitritos y nitratos</td>

                                <td>Isabel Blasco</td>
                            </tr>
                            <tr>
                                <td><span>P222</span> Cellulose nanocrystals: chemistry and applications in energy  </td>

                                <td>Raúl Calle</td>
                            </tr>
                            <tr>
                                <td><span>P223</span> Synthesis of fused dihydroazepine derivatives of fullerenes by a Rh- catalyzed cascade process</td>

                                <td>Cristina Castanyer</td>
                            </tr>
                            <tr>
                                <td><span>P224</span> Tracing Molecular and Structural Changes of U-shaped D-A-D ð-conjugated Systems with Mechanochromic Properties: Insight into their Vibrational Properties</td>

                                <td>Sara Fernández Palacios</td>
                            </tr>
                            <tr>
                                <td><span>P225</span> Exploring the synthesis and properties of three-dimensional rylenimide based semiconductors for their application in Organic Photovoltaics </td>

                                <td>Elena Gala Sánchez</td>
                            </tr>
                            <tr>
                                <td><span>P226</span> HMS mesostructured silica functionalized with sulfonic groups as cationic exchange sorbent for the extraction of atropine and scopolamine from thyme, basil and coriander</td>

                                <td>Judith Gañán Aceituno</td>
                            </tr>
                            <tr>
                                <td><span>P227</span> Breaking a Chemical Bond in a Superfluid Helium Nanodroplet. Photodissociation of Halogen Molecules in the B state </td>

                                <td>Miguel González</td>
                            </tr>
                            <tr>
                                <td><span>P228</span> Hierarchical zeolites as basic catalysts for the transformation of bulky molecules</td>

                                <td>Monica Judith Mendoza</td>
                            </tr>
                            <tr>
                                <td><span>P229</span> Catalytic isomerization of linear and cyclic allylic alcohols in water: What role plays water?  </td>

                                <td>Belén López</td>
                            </tr>
                            <tr>
                                <td><span>P230</span> ,NEW NAPHTHALENEMONOIMIDES WITH APLICATIONS FOR THE DETECTION OF OXIDATIVE ASPECIES AND CATIONS</td>

                                <td>Mateo Martín Salgado</td>
                            </tr>
                            <tr>
                                <td><span>P231</span> Few atoms metal clusters with high catalytic and cytotoxic activity characterized by synchrotron techniques.</td>

                                <td>Judit Oliver Meseguer</td>
                            </tr>
                            <tr>
                                <td><span>P232</span> Osmium(II) Tethered Half-Sandwich Complexes: pH-dependent Aqueous Speciation and Transfer Hydrogenation in Cells</td>

                                <td>Ana Pizarro</td>
                            </tr>
                            <tr>
                                <td><span>P233</span> On the road to enhance membrane properties for redox flow battery application </td>

                                <td>Ivan Salmeron Sànchez</td>
                            </tr>
                            <tr>
                                <td><span>P234</span> In situ formation of magnetite nanoparticles inside pores of protein crystals</td>

                                <td>Mariia Savchenko</td>
                            </tr>
                            <tr>
                                <td><span>P235</span> Determinación de la cinética de cristalización de polímeros a partir de los resulyados del método NPK</td>

                                <td>Julià Sempere</td>
                            </tr>
                            <tr>
                                <td><span>P236</span> Coupling scanning probe microscopy with electrochemistry to image electrochemical processes on surfaces at the micron scale</td>

                                <td>Ricardo Souto</td>
                            </tr>
                            <tr>
                                <td><span>P237</span> Organogels for the Control of the Concomitant Crystallization of Barbital and Thalidomide</td>

                                <td>Iván Torres Moya</td>
                            </tr>
                            <tr>
                                <td><span>P238</span> Copper(II) complexes of HPH-NH2 and HPHPY-NH2 as structural and functional models of lytic polysaccharide monooxygenases</td>

                                <td>Azza A. HASSOON</td>
                            </tr>
                            <tr>
                                <td><span>P239</span> Hypoxic actives Ru(II) PDT photosensitizers that operates under green light irradiation</td>

                                <td>Francisco José Ballester</td>
                            </tr>
                            <tr>
                                <td><span>P240</span> Novel Triazine-based materials for heterogeneous photocatalysis</td>

                                <td>Beatriz Fuerte Díez</td>
                            </tr>
                            <tr>
                                <td><span>P241</span> Methylparaben photodegradation in an exciplex flow-through photoreactor</td>

                                <td>Maria Dolores Murcia Almagro</td>
                            </tr>
                            <tr>
                                <td><span>P242</span> UNRAVELING THE STRUCTURAL, OPTICAL AND PHOTOLUMINESCENT PROPERTIES OF A PYRAZOLE-BASED COBALT(II) FRAMEWORK</td>

                                <td>Mohammed Abdelbaky</td>
                            </tr>
                            <tr>
                                <td><span>P243</span> Exploring carbonaceous 3D structures for lithium electrodeposition to be used in batteries. </td>

                                <td>Begoña Acebedo</td>
                            </tr>
                            <tr>
                                <td><span>P244</span> Coatings with Nanoencapsulated Phase Change Materials for Thermal Energy Storage </td>

                                <td>Inés Adam Cervera</td>
                            </tr>
                            <tr>
                                <td><span>P245</span> Three component one pot synthesis of cyclopalladated (E)-N-benzyl-1-phenylmethanimines</td>

                                <td>Joan Albert Mach</td>
                            </tr>
                            <tr>
                                <td><span>P246</span> Phosphorescent mono and binuclear 2-phenylbenzothiazole platinum complexes with pyrazole or pyrazolate ligands </td>

                                <td>Eduardo Alcolea Villalba</td>
                            </tr>
                            <tr>
                                <td><span>P247</span> Nickel-Catalyzed Heck: Studies on the Oxidative Addition step with PN Hybrid Ligands for the formation of Ni(0)</td>

                                <td>Diego Alexandre</td>
                            </tr>
                            <tr>
                                <td><span>P248</span> Computational insights into the isomerization of a planar triruthenium cluster via consecutive intramolecular metathesis reactions</td>

                                <td>Andrés Algarra</td>
                            </tr>
                            <tr>
                                <td><span>P249</span> Reduction quinones in wine with metal elements</td>

                                <td>Manuel Alvarez</td>
                            </tr>
                            <tr>
                                <td><span>P250</span> Origin of Stereo- and Enantioselectivity of Copper-Catalyzed Allylboration of Alkynes with Allylic gem-Dichlorides: A Computational Study</td>

                                <td>Andrés Manuel Álvarez</td>
                            </tr>
                            <tr>
                                <td><span>P251</span> Two Copper-Carbenes from One Diazo Compound </td>

                                <td>María Álvarez Martínez</td>
                            </tr>
                            <tr>
                                <td><span>P252</span> Multinuclear Titanium Complexes: Synthesis, Characterization and Catalytic Studies</td>

                                <td>Elena Alvarez Ruiz</td>
                            </tr>
                            <tr>
                                <td><span>P253</span> Study of biopolymers degradation under anaerobic conditions during the management of agrarian residues</td>

                                <td>Sergio Álvarez-Méndez</td>
                            </tr>
                            <tr>
                                <td><span>P254</span> Synthesis of carbazoles, bis(carbazoles) and helicenes</td>

                                <td>Cristina Aragoncillo Abánades</td>
                            </tr>
                            <tr>
                                <td><span>P255</span> Kinetic study of 3,3-dimethylbutanal with Cl atoms and 3,3-dimethyl-2- butanone with Cl atoms and OH radicals.</td>

                                <td>María Inmaculada Aranda</td>
                            </tr>
                            <tr>
                                <td><span>P256</span> Conducting polymer nanostructuration in MOFs through template effect</td>

                                <td>Giacomo Armani</td>
                            </tr>
                            <tr>
                                <td><span>P257</span> Gas-phase reactivity of 2-methylbutanal with Cl atoms: kinetics and degradation products </td>

                                <td>María Asensio</td>
                            </tr>
                            <tr>
                                <td><span>P258</span> Hydroalkenylation of 2-vinylpyridine by Iridium-NHC Catalysts: Preparation of new Butadienylpyridines. </td>

                                <td>Ramón Azpiroz</td>
                            </tr>
                            <tr>
                                <td><span>P259</span> A statistical design of experiments to optimise the chemical recycling of poly(ethylene terephtalate) by glycolysis aided by commercial ionic liquids</td>

                                <td>José David Badia Valiente</td>
                            </tr>
                            <tr>
                                <td><span>P260</span> Design and recognition of cucurbituril-secured platinum-bound oligopeptides</td>

                                <td>Héctor BARBERO</td>
                            </tr>
                            <tr>
                                <td><span>P261</span> Palladium-Catalyzed Cascade Reactions to Access Pyrrolo[1,2- b]isoquinoline Scaffolds, New Antileishmanial Agents</td>

                                <td>Iratxe Barbolla Cuadrado</td>
                            </tr>
                            <tr>
                                <td><span>P262</span> Development of NHC-Au and -Ag complexes for biological and catalytic applications </td>

                                <td>Roberto Berbés Martínez</td>
                            </tr>
                            <tr>
                                <td><span>P263</span> Nanocarriers based on orthogonal clickable block-copolycarbonates</td>

                                <td>Sara Bescós</td>
                            </tr>
                            <tr>
                                <td><span>P264</span> Role of zeolite properties in products distribution in the catalytic cracking of bio-oil</td>

                                <td>Javier Bilbao</td>
                            </tr>
                            <tr>
                                <td><span>P265</span> Mono- and Dinuclear-Iridium Complexes for Transfer Hydrogenation Reactions from Alcohols to Ketones</td>

                                <td>Laura Blanco</td>
                            </tr>
                            <tr>
                                <td><span>P266</span> Fe3O4 nanoparticles as potential catalyst for polymeric materials degradation</td>

                                <td>Verónica Blanco Gutiérrez</td>
                            </tr>
                            <tr>
                                <td><span>P267</span> Fe3O4 Nanoparticles (MNPs) as Nanozyme for Signal Enhancement in Lateral Flow Immunoassays (LFIA)</td>

                                <td>María del Carmen Blanco López</td>
                            </tr>
                            <tr>
                                <td><span>P268</span> Hydrofluoroethers as potential substitutes of greenhouse gases</td>

                                <td>Sergio Blázquez González</td>
                            </tr>
                            <tr>
                                <td><span>P269</span> Comparative study of different methods to synthesize Carbon-Sulfur composites for Li-S batteries</td>

                                <td>Álvaro Bonilla</td>
                            </tr>
                            <tr>
                                <td><span>P270</span> Design and synthesis of Metal Organic Frameworks as enantioselective fluorescence sensors</td>

                                <td>María del Carmen Borrallo Aniceto</td>
                            </tr>
                            <tr>
                                <td><span>P271</span> Olefin aziridination catalyzed by copper complex in water</td>

                                <td>Elena Borrego Blanco</td>
                            </tr>
                            <tr>
                                <td><span>P272</span> Preparation of activated carbons from exhausted olive pomace by a simple, eco-friendly and low-cost valorization process</td>

                                <td>Álvaro Caballero Amores</td>
                            </tr>
                            <tr>
                                <td><span>P273</span> SYNTHESIS AND STUDY OF A NEW ANION RECEPTORS BY CALCOGEN BINDING INTERACTION BASED ON THIO- AND SELENOCYANATES.</td>

                                <td>Antonio Caballero Pérez</td>
                            </tr>
                            <tr>
                                <td><span>P274</span> Strongly electron-donating triazolylidene ligands: cationic metal carbonyl complexes of 1-methyl-1,2,3-triazole as triazolium surrogates.</td>

                                <td>Purificación Cañadas</td>
                            </tr>
                            <tr>
                                <td><span>P275</span> Dynamic combinatorial optimization of potent heparin antidotes</td>

                                <td>Daniel Carbajo</td>
                            </tr>
                            <tr>
                                <td><span>P276</span> Organocatalytic Enantioselective Aminoalkylation of 5-Aminopyrazole Derivatives with Cyclic Imines</td>

                                <td>Laura Carceller Ferrer</td>
                            </tr>
                            <tr>
                                <td><span>P277</span> Curcumin heterocyclic analogues as potential NIR fluorescence probes for Alzheimer’s disease hallmarks</td>

                                <td>Noelia Carmona</td>
                            </tr>
                            <tr>
                                <td><span>P278</span> Keto-enol tautomerism in Passerini and Ugi adducts</td>

                                <td>Israel Carreira-Barral</td>
                            </tr>
                            <tr>
                                <td><span>P279</span> Polinuclear Gold(I) Complexes Bearing A PNP Ligand: Catalytic Activity </td>

                                <td>Martina Casciotti</td>
                            </tr>
                            <tr>
                                <td><span>P280</span> Enantioselective conjugate addition of sodium bisulfite to trans-?-nitrostyrenes</td>

                                <td>Álvaro Castilla Mocholí</td>
                            </tr>
                            <tr>
                                <td><span>P281</span> Chemical Stability of (3,1)-Chiral Graphene Nanoribbons</td>

                                <td>Jesús Castro Esteban</td>
                            </tr>
                            <tr>
                                <td><span>P282</span> Synthesis of Bio-Derived Cyclic Carbonates Catalyzed by an Efficient Aluminum Catalyst</td>

                                <td>José A Castro-Osma</td>
                            </tr>
                            <tr>
                                <td><span>P283</span> Copper-catalyzed asymmetric allylboration of alkynes with allylic gem- dichlorides.</td>

                                <td>Andrea Chaves Pouso</td>
                            </tr>
                            <tr>
                                <td><span>P284</span> SYNTHESIS OF XANTHENE DERIVATIVES IN AN IONIC LIQUID</td>

                                <td>Esther Choque Zárate</td>
                            </tr>
                            <tr>
                                <td><span>P285</span> Recovering Carbapenem Efficacy Against Superbugs by Penicillin-based Sulfone ?-lactamase Inhibitors</td>

                                <td>Esther Colchón Pierna</td>
                            </tr>
                            <tr>
                                <td><span>P286</span> Revalorization of lignin via nucleophilic attack on epoxidized castor oil for the viscosity enhancement of more sustainable thickening formulations</td>

                                <td>Esperanza Cortés Triviño</td>
                            </tr>
                            <tr>
                                <td><span>P287</span> Acid Responsive Hydrogen-Bonded Organic Frameworks in Polymer Matrix</td>

                                <td>Mario De La Hoz Tomas</td>
                            </tr>
                            <tr>
                                <td><span>P288</span> Enantioselective Intramolecular Cycloaddition of Azomethine Ylides with Fluorinated Dipolarophiles</td>

                                <td>Carlos del Pozo</td>
                            </tr>
                            <tr>
                                <td><span>P289</span> Zirconium-metal-organic polyhedra as drug delivery systems for reactivation of AChE enzyme inhibited by organophosphorus compounds.</td>

                                <td>Pedro Delgado</td>
                            </tr>
                            <tr>
                                <td><span>P290</span> Cyanovinylation of Aldehydes: Organocatalytic Multicomponent Synthesis of Congugated Cyanomethyñ Vinyl Etheres </td>

                                <td>Samuel Delgado</td>
                            </tr>
                            <tr>
                                <td><span>P291</span> De Novo Design of Selective Quadruplex-Duplex Junction Ligands and Structural Characterisation of Their Binding Mode: Targeting the G4 Hot-Spot  </td>

                                <td>Laura Diaz Casado</td>
                            </tr>
                            <tr>
                                <td><span>P292</span> Study of the phototherapeutic properties of novel targeting-modified nanomaterials functionalized with tin complexes</td>

                                <td>Diana Diaz Garcia</td>
                            </tr>
                            <tr>
                                <td><span>P293</span> Optical study on new RE-doped perovskites</td>

                                <td>Selene Díaz González</td>
                            </tr>
                            <tr>
                                <td><span>P294</span> Green chemistry synthesis of F-TiO2 nanoparticles ?with high photodegradative activity</td>

                                <td>Miguel Diaz Sanchez</td>
                            </tr>
                            <tr>
                                <td><span>P295</span> Copper catalysts supported on a barium deficient manganese perovskite (Ba0,7MnO3) for CO oxidation reaction</td>

                                <td>Álvaro Díaz Verde</td>
                            </tr>
                            <tr>
                                <td><span>P296</span> New insight in the coordination ability of 1, 3, 5-Triaza-7-phosphaadamantane derivatives towards lanthanide ions </td>

                                <td>Ismael Francisco Diaz-Ortega</td>
                            </tr>
                            <tr>
                                <td><span>P297</span> Sonochemical Degradation of Neonicotinoid Pesticides in Natural Surface Waters. Operational and Environmental Conditions</td>

                                <td>Joaquín Dominguez</td>
                            </tr>
                            <tr>
                                <td><span>P298</span> Rhodium-catalyzed intramolecular [2+2+2]-cycloaddition of chiral triynes </td>

                                <td>Jorge Escorihuela</td>
                            </tr>
                            <tr>
                                <td><span>P299</span> Dynamic Combinatorial Chemistry as hit identification tool</td>

                                <td>Natalia Esteban</td>
                            </tr>
                            <tr>
                                <td><span>P300</span> Discovery of novel tropane analogues with potential biological activities and new synthons for their synthesis</td>

                                <td>Alberto Esteban Aranda</td>
                            </tr>
                            <tr>
                                <td><span>P301</span> Stereodivengent synthesis of iminicyclohexitols and polyhydroxylated pipecolic acids</td>

                                <td>Ramon Estevez</td>
                            </tr>
                            <tr>
                                <td><span>P302</span> (-)-Shikimic acid alkylbisamides: new ice recrystallization inhibitors </td>

                                <td>Juan Carlos Estévez Cabanas</td>
                            </tr>
                            <tr>
                                <td><span>P303</span> Morphology influence of carbon based materials as artificial SEI on lithium metal anodes for next generation of high Energy Density Lithium Metal Battery cells</td>

                                <td>Noelia Estrella Vicente</td>
                            </tr>
                            <tr>
                                <td><span>P304</span> Supported Palladium catalyst for the hydrogenation of CO2 into formate</td>

                                <td>María Dolores Fenández</td>
                            </tr>
                            <tr>
                                <td><span>P305</span> Photoelectrochemical water splitting response of TiO2 nanotubes doped with nitrogen</td>

                                <td>Ramón Fernandez Domene</td>
                            </tr>
                            <tr>
                                <td><span>P306</span> Unexplored Complexity of Simple AuIII Reactions: Striking Cl/aryl Scrambling. Driving Forces and Mechanisms.</td>

                                <td>Sara Fernández Moyano</td>
                            </tr>
                            <tr>
                                <td><span>P307</span> Chiral Conjugated BINOL-Porous Polymers as heterogenous catalysts</td>

                                <td>Pilar Fernández Seriñán</td>
                            </tr>
                            <tr>
                                <td><span>P308</span> Molecular simulation of supercooled electrolyte solutions </td>

                                <td>Lucía Fernández-Sedano</td>
                            </tr>
                            <tr>
                                <td><span>P309</span> Dihydrogen and water activation mediated by an Rh/N masked FLP </td>

                                <td>Joaquina Ferrer</td>
                            </tr>
                            <tr>
                                <td><span>P310</span> Structural challenges in scopionate polypirazolderivatives with elctronwithdrawing substituents.</td>

                                <td>Guillermo Galcerán</td>
                            </tr>
                            <tr>
                                <td><span>P311</span> Synthesis and in vitro studies of imidazolium- and metallo-perylenediimides as photosensitizers for photodynamic therapy</td>

                                <td>Jose Garcés Garcés</td>
                            </tr>
                            <tr>
                                <td><span>P312</span> (CTF)-derived N-doped carbon materials with controlled porous structure and enhanced electrochemical performance</td>

                                <td>Elena Garcia</td>
                            </tr>
                            <tr>
                                <td><span>P313</span> Cyclometalated Iridium(III) grafted mesoporous silica nanoparticles for visible-light Single Electron Tranfer photocatalysis </td>
                                <td>David García</td>
                            </tr>
                            <tr>
                                <td><span>P314</span> Counter-Anion-Dependent Divergent Gold Homogeneous Catalysis with Halolakynes</td>

                                <td>Pedro García</td>
                            </tr>
                            <tr>
                                <td><span>P315</span> Synthesis of hybrid compounds with quinoline and naphthohydroquinone motifs</td>
                                <td>Pilar García</td>
                            </tr>
                            </tbody>
                        </table>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
                <div class="nv-column desk-4 tab-6 phab-12">
                    <div class="nv-column-content">
                        <table cellspacing="0" border="0" class="nv-custom-presentations-list">
                            <tbody>
                            <tr class="label">
                                <td colspan="2">Poster Session 3</td>
                            </tr>
                            <tr>
                                <td><span>P317</span> Pulsed Laser Synthesis of Carbon Quantum Dots. A Case Study</td>

                                <td>David Garcia Fresnadillo</td>
                            </tr>
                            <tr>
                                <td><span>P318</span> Dihalogenation of Alkenes Using Combinations of N-Halosuccinimides and Alkali Metal Halides</td>

                                <td>Olaya Garcia Pedrero</td>
                            </tr>
                            <tr>
                                <td><span>P319</span> Superparamagnetic Iron Oxide Nanoparticles for T-cell reprogramming</td>

                                <td>Sara Garcia Pedrero</td>
                            </tr>
                            <tr>
                                <td><span>P320</span> Controlling the porosity of activated carbons produced by chemical activation of sodium lignosulfonate</td>

                                <td>Miguel García Rollán</td>
                            </tr>
                            <tr>
                                <td><span>P321</span> Cobalt, Rhodium and Iridium complexes with bifunctional phosphanebenzotriazoles ligands: Design and characterization</td>

                                <td>Susana Garcia-Abellan</td>
                            </tr>
                            <tr>
                                <td><span>P322</span> Hybrid materials for biomedical applications</td>

                                <td>Bárbara Gimenez Hernandez</td>
                            </tr>
                            <tr>
                                <td><span>P323</span> 2-Ethylhexyl laurate biosynthesis with Novozym® 435 lipase</td>

                                <td>María Gómez</td>
                            </tr>
                            <tr>
                                <td><span>P324</span> Small-volume NMR: an efficient alternative for photo-CIDNP spectroscopy</td>

                                <td>M. Victoria Gómez Almagro</td>
                            </tr>
                            <tr>
                                <td><span>P325</span> Optimizing the preparation of activated carbons derived from sewage sludge</td>

                                <td>Juan Luis Gómez Camer</td>
                            </tr>
                            <tr>
                                <td><span>P326</span> Luminescent cyclometalated PtIV compounds with N-donor ligands: synthesis and study of their properties</td>

                                <td>David Gómez De Segura Zorzano</td>
                            </tr>
                            <tr>
                                <td><span>P327</span> Experimental kinetics at ultra-low temperatures of the OH+CH3NH2 reaction in the gas-phase </td>

                                <td>Daniel González</td>
                            </tr>
                            <tr>
                                <td><span>P328</span> Electronic and Charge Transport Properties of Two Tetracarbazole Macrocycles</td>

                                <td>Raúl González Núñez</td>
                            </tr>
                            <tr>
                                <td><span>P329</span> Synthesis and applications of new coordination polymers based on novel pyrene derivative ligands</td>

                                <td>Pablo Guerrero García</td>
                            </tr>
                            <tr>
                                <td><span>P330</span> Iron oxide-covalent organic framework nanocomposite for As(III) removal in water</td>

                                <td>Ana Guillem</td>
                            </tr>
                            <tr>
                                <td><span>P331</span> Thiorredoxin A modulates the redox status of the transcriptional regulator FurA in Anabaena sp. PCC 7120</td>

                                <td>Jorge Guío</td>
                            </tr>
                            <tr>
                                <td><span>P332</span> Semihydrogenation of alkynes catalysed by imidazolyl-amine Mo3S4 clusters: Influence of the alkylation of the N-H funcionality</td>

                                <td>Maria Gutierrez Blanco</td>
                            </tr>
                            <tr>
                                <td><span>P333</span> Antineoplastic drugs activated under hypoxia conditions </td>

                                <td>Asmaa HABIB</td>
                            </tr>
                            <tr>
                                <td><span>P334</span> Macrocyclic ligands and their metal complexes with potential applications in radiotherapy</td>

                                <td>Charlene Harriswangler</td>
                            </tr>
                            <tr>
                                <td><span>P335</span> N-glycosylation pattern of recombinant GAS1 analyzed by NMR</td>

                                <td>Diego Heras Marquez</td>
                            </tr>
                            <tr>
                                <td><span>P336</span> Development of new Os(II) arene-complexes based on benzothiazole as anticancer drug </td>

                                <td>Alba Hernández</td>
                            </tr>
                            <tr>
                                <td><span>P337</span> New Potential Anticancer Compounds based on Privileged Structures </td>

                                <td>Judith Hernández Garcés</td>
                            </tr>
                            <tr>
                                <td><span>P338</span> A green Lithium-Ion Battery using a biomass derived Carbon-Sulfur cathode and a Silicon oxide-based anode with a stable high capacity</td>

                                <td>Celia Hernández Rentero</td>
                            </tr>
                            <tr>
                                <td><span>P339</span> Development of a molecular probe based on the Nile Blue fluorophore for senescent cells detection in vivo models </td>

                                <td>Guillermo Hernández Sigüenza</td>
                            </tr>
                            <tr>
                                <td><span>P340</span> Design and study of ?-conjugated pyrimidine systems as molecular platforms  </td>

                                <td>Daniel Herrera</td>
                            </tr>
                            <tr>
                                <td><span>P341</span> Aerobic Borylation of Heteroarenes Triggered by Visible-Light in a Gel Nanoreactor </td>

                                <td>Jorge Herrera</td>
                            </tr>
                            <tr>
                                <td><span>P342</span> Ruthenium catalysts to produce Hydrogen On-Demand using the pair silane/alcohol</td>

                                <td>Laura Ibáñez</td>
                            </tr>
                            <tr>
                                <td><span>P343</span> Theoretical study selective extraction of lithium from seawater desalination concentrates by molecular simulation </td>

                                <td>Raquel Ibáñez</td>
                            </tr>
                            <tr>
                                <td><span>P344</span> Bioorthogonal chemistry on living cell membranes: creating hotspots for transient increase of living cell membrane permeability</td>

                                <td>Javier Idiago</td>
                            </tr>
                            <tr>
                                <td><span>P345</span> Encapsulation of amino Rh(I) organometallic complexes inside a supramolecular gallium metallocage </td>

                                <td>Ignacio Izquiredo Sanchez</td>
                            </tr>
                            <tr>
                                <td><span>P346</span> Electrochemistry comparison between bromine complexing agents tested in a Zinc-Bromine flow battery </td>

                                <td>Uxua Jiménez Blasco</td>
                            </tr>
                            <tr>
                                <td><span>P347</span> Recovery of aluminium from saline slags by acid or basic treatment</td>

                                <td>Alejandro Jiménez Martín</td>
                            </tr>
                            <tr>
                                <td><span>P348</span> Cartel: ?Acido Folico</td>

                                <td>Gabriel Jiménez Zerón</td>
                            </tr>
                            <tr>
                                <td><span>P349</span> Enhanced catalytic activity of bifunctional bipyridine ligands in self- assembled systems</td>

                                <td>Ciril Jimeno</td>
                            </tr>
                            <tr>
                                <td><span>P350</span> Application of efficient titanocene(III)-based chemistry to the synthesis of meroterpenes</td>

                                <td>José Justicia Ladrón de Guevara</td>
                            </tr>
                            <tr>
                                <td><span>P351</span> Complexes of ruthenium containing phosphano-thiourea and -guanidine ligands as FLP catalysts </td>

                                <td>Pilar Lamata</td>
                            </tr>
                            <tr>
                                <td><span>P352</span> Ring-Opening Copolymerization of Cyclohexene Oxide and Carbon Dioxide Catalyzed by Scorpionate Zinc Complexes</td>

                                <td>Agustín Lara-Sánchez</td>
                            </tr>
                            <tr>
                                <td><span>P353</span> Asymmetric dearomatization of 2-nitrobenzofurans via formal [3+2] cycloaddition reactions with isocyanoacetates</td>

                                <td>ADRIAN Laviós Gomis</td>
                            </tr>
                            <tr>
                                <td><span>P354</span> Conjugates of ?-CT peptides of insulin and IGF-1 receptors </td>

                                <td>Jingjing Lin</td>
                            </tr>
                            <tr>
                                <td><span>P355</span> New C,N-cyclometalated platinum(II) complexes derived from azo compounds as potential photosensitizer agents in chemotherapy</td>

                                <td>Antonio Linero</td>
                            </tr>
                            <tr>
                                <td><span>P356</span> Anthraquinone-based molecular nanographenes for organic electronics</td>

                                <td>Juan Lión Villar</td>
                            </tr>
                            <tr>
                                <td><span>P357</span> Controlled ROP polymerization of cyclic esters using potassium crown ether derivatives</td>

                                <td>Christian Llorente Rentero</td>
                            </tr>
                            <tr>
                                <td><span>P358</span> Novel haptens and high-affinity monoclonal antibodies for the development of a new LFIA for ochratoxin A detection</td>

                                <td>Daniel López Puertollano</td>
                            </tr>
                            <tr>
                                <td><span>P359</span> Solvent-Free Hydroboration Catalyzed by a Novel Heterobimetallic Yttrium/Europium Metal-Organic Framework </td>

                                <td>Mireya Estela López Vargas</td>
                            </tr>
                            <tr>
                                <td><span>P360</span> IN SITU REAL-TIME IMAGING THE MECHANISM OF SELF-ASSEMBLY OF AMPHIPHILIC PEPTIDES MEDIATED BY SALTS</td>

                                <td>Mari Carmen Mañas Torres</td>
                            </tr>
                            <tr>
                                <td><span>P361</span> Synthesis of a new series of Rhenium(I) complexes and study of their properties as anticancer agents </td>

                                <td>Alicia Marco Calero</td>
                            </tr>
                            <tr>
                                <td><span>P362</span> Copper activation of C-X bonds: What if we consider a second copper in the mechanism?</td>

                                <td>Guillermo Marcos Ayuso</td>
                            </tr>
                            <tr>
                                <td><span>P363</span> Simple thermal regeneration of granular activated carbon from the deodorization system of a WWTP for its reuse </td>

                                <td>Pedro Márquez García</td>
                            </tr>
                            <tr>
                                <td><span>P364</span> Electrochemical Study of Bismuth Recovery Coming from Highly Concentrated HCl Solutions </td>

                                <td>Manuel César Martí </td>
                            </tr>
                            <tr>
                                <td><span>P365</span> Ni(II) Precatalysts Enable Thioetherification of (Hetero)aryl Halides and Tosylates and Tandem C-S/C-N Couplings</td>

                                <td>Maria Trinidad Martín</td>
                            </tr>
                            <tr>
                                <td><span>P366</span> Rheological properties of cellulose nanofibers in water suspensions at high temperature and pressure</td>

                                <td>María José Martín Alfonso</td>
                            </tr>
                            <tr>
                                <td><span>P367</span> Multilayer hydrogels for tissue engineering</td>

                                <td>Carlos Martín Andreu</td>
                            </tr>
                            <tr>
                                <td><span>P368</span> Cationic Tripodal Ligands as precursors for titanium catalysts</td>

                                <td>Joan Martín Vinueza</td>
                            </tr>
                            <tr>
                                <td><span>P369</span> Design of Ir-NHC Catalysts for Acceptorless Dehydrogenation of Alcohols to Carboxylic Acids </td>

                                <td>Joaquín Martinez</td>
                            </tr>
                            <tr>
                                <td><span>P370</span> Cyclometallated Ir(III) complexes with polyaromatic bidentate ligands: synthesis and applications in photocatalysis </td>

                                <td>Mónica Martinez Aguirre</td>
                            </tr>
                            <tr>
                                <td><span>P371</span> Multi?Stimuli?Responsive Isocyanide Cyclometalated Platinum(II) Complexes: Structural, theoretical and photophysical properties</td>

                                <td>Mónica Martínez Junquera</td>
                            </tr>
                            <tr>
                                <td><span>P372</span> Stereoselective Synthesis of (Z)-?-Indol-3-yl ?-(2-Thioalkenyl) Ketones</td>

                                <td>Fernando Martinez Lara</td>
                            </tr>
                            <tr>
                                <td><span>P373</span> Efficient and versatile stabilizing groups for [1,2-b] indenofluorene scaffolds.</td>

                                <td>Álvaro Martínez Pinel</td>
                            </tr>
                            <tr>
                                <td><span>P374</span> Metabolomics for Alzheimer Disease detection </td>

                                <td>M.Carmen Martínez-Bisbal</td>
                            </tr>
                            <tr>
                                <td><span>P375</span> Effect of thermal aging on the viscous flow behavior of xanthan gum in formate brines</td>

                                <td>Francisco J. MARTÍNEZ-BOZA</td>
                            </tr>
                            <tr>
                                <td><span>P376</span> Alkane C?H bond activation by TpxIr(III) complexes</td>

                                <td>Jonathan Martínez-Laguna</td>
                            </tr>
                            <tr>
                                <td><span>P377</span> Polymers of intrinsic microporosity (PIMs) for energy storage</td>

                                <td>Juan Carlos Martínez-López</td>
                            </tr>
                            <tr>
                                <td><span>P378</span> Porous metal organic frameworks as multifunctional materials towards organophosphate poisoning treatment</td>

                                <td>Javier David Martin-Romera</td>
                            </tr>
                            <tr>
                                <td><span>P379</span> Imidazolium-urea Low Transition Temperature Mixtures and their application to the UHP-promoted oxidation of boron compounds.</td>

                                <td>Mario Martos</td>
                            </tr>
                            <tr>
                                <td><span>P380</span> Toll-like receptors 2 and 4: innate immunity modulation from the computational chemistry </td>

                                <td>Alejandra Matamoros-Recio</td>
                            </tr>
                            <tr>
                                <td><span>P381</span> Radical C-C coupling catalyzed by a molybdenum cluster sulfide</td>

                                <td>Juan Mateu Campos</td>
                            </tr>
                            <tr>
                                <td><span>P382</span> Potassium oximate compounds as active catalysts for controlled lactide ROP polymerization</td>

                                <td>Asier Medel Garcia</td>
                            </tr>
                            <tr>
                                <td><span>P383</span> Cationic cyclometalated IrIII complexes with potential theranostic properties.</td>

                                <td>Gonzalo millan fernandez</td>
                            </tr>
                            <tr>
                                <td><span>P384</span> Synthesis of Ni/Al LDHs using DMA </td>

                                <td>Alexander Misol Gallego</td>
                            </tr>
                            <tr>
                                <td><span>P385</span> N-Heterocycles as Liquid Organic Hydrogen Carriers (LOHCs) </td>

                                <td>Andrés Mollar Cuni</td>
                            </tr>
                            <tr>
                                <td><span>P386</span> MOD synthesis of M-BiVO4 (M = Ca, Cr) photocatalytic wide band semiconductors </td>

                                <td>Guillermo Monros</td>
                            </tr>
                            <tr>
                                <td><span>P387</span> Water-Soluble Organic Molecular Cages for Biological Applications</td>

                                <td>Giovanni Montà González</td>
                            </tr>
                            <tr>
                                <td><span>P388</span> N-Substituted Aminobiphenyl Palladacycles Supported by Dialkylterphenyl Phosphanes </td>

                                <td>Andrea Monti</td>
                            </tr>
                            <tr>
                                <td><span>P389</span> BaFe1-xNixO3 catalysts for NOx-assisted diesel soot oxidation </td>

                                <td>Salvador Montilla</td>
                            </tr>
                            <tr>
                                <td><span>P390</span> Alternative strategies for searching new DNA G-quadruplex ligands with anticancer and antiparasitic activity</td>

                                <td>Juan Carlos Morales</td>
                            </tr>
                            <tr>
                                <td><span>P391</span> Tropane and opium alkaloids: evaluation of their degradation during food processing by HPLC-DAD</td>

                                <td>Sonia Morante Zarcero</td>
                            </tr>
                            <tr>
                                <td><span>P392</span> Possibilites of Filtration by Ultracentrifugation as an Isolation/Pre-concentration method for Ag and TiO2 Nanoparticles from Urine</td>

                                <td>Antonio Moreda Piñeiro</td>
                            </tr>
                            <tr>
                                <td><span>P393</span> Copper Catalyze The Chemoselective Nitrene Transfer Reaction To The Double or Triple Bond of Enynes</td>

                                <td>Anabel Moreno</td>
                            </tr>
                            <tr>
                                <td><span>P394</span> Exploring applications of microfluidization to the development of a light salad dressing enriched with pea fibre and omega-3 fatty acids  </td>

                                <td>José Muñoz García</td>
                            </tr>
                            <tr>
                                <td><span>P395</span> Chemical Stimulation Affects the Dynamics of Shuttling in a Two-Station Molecular Shuttle</td>

                                <td>Tomás Nicolás García</td>
                            </tr>
                            <tr>
                                <td><span>P396</span> Study of the optical and cytotoxic properties of cyclometalated Ir(III) derivatives</td>

                                <td>Mattia Nieddu</td>
                            </tr>
                            <tr>
                                <td><span>P397</span> Synthesis of pyrrolidines from redox active esters through a nickel catalyzed cross-coupling process.</td>

                                <td>Juan Carlos Nieto Carmona</td>
                            </tr>
                            <tr>
                                <td><span>P398</span> Spirocyclobutenes as a Template to Prepare Functionalized Spirocycles</td>

                                <td>Luis Novoa</td>
                            </tr>
                            <tr>
                                <td><span>P399</span> 19F-NMR SCREENING AND CHEMICAL MAPPING OF FLUORINATED N-ACYL-AMINOSUGARS-LECTIN INTERACTIONS</td>

                                <td>Paola Oquist</td>
                            </tr>
                            <tr>
                                <td>Enantioselective Synthesis of Monoterpene Indole Alkaloids</td>

                                <td>Sergi Ordeix</td>
                            </tr>
                            <tr>
                                <td><span>P401</span> Tuning of type-I and type-II mechanisms for visible light degradation in tris(styryl)benzene-sensitized TiO2 nanoparticles.</td>

                                <td>Josefa Ortiz-Bustos</td>
                            </tr>
                            <tr>
                                <td><span>P402</span> Progress towards the Total Synthesis of silvalactam aglycone </td>

                                <td>Daniel Otero</td>
                            </tr>
                            <tr>
                                <td><span>P403</span> Supramolecular materials based on low molecular weight peptides (LWMPs) for biomedical applications; synthesis and characterizations</td>

                                <td>Nagihan Ozbek</td>
                            </tr>
                            <tr>
                                <td><span>P404</span> Heterometallic aluminium-alkali metal catalysts for ?-myrcene polymerization</td>

                                <td>Miguel Palenzuela Cebrian</td>
                            </tr>
                            <tr>
                                <td><span>P405</span> Regiodivergent Cross-Coupling of propargylic Sulfones with Grignard Reagents </td>

                                <td>Naiara Pascual</td>
                            </tr>
                            <tr>
                                <td><span>P406</span> Porphyrin-glycofullerene nanostructures for biomedical applications</td>

                                <td>Jennifer Patino Alonso</td>
                            </tr>
                            <tr>
                                <td><span>P407</span> Performance of UV-C LED in the disinfection and remediation of wastewaters </td>

                                <td>Deva Pelayo Torices</td>
                            </tr>
                            <tr>
                                <td><span>P408</span> Development of a method for the extraction of TiO2 nanoparticles from edible seaweed using alkaline media </td>

                                <td>Elena Peña-Vázquez</td>
                            </tr>
                            <tr>
                                <td><span>P409</span> Dynamic Nucleophilic Aromatic Substitution of Tetrazines</td>

                                <td>Yaiza Perez</td>
                            </tr>
                            <tr>
                                <td><span>P410</span> Efficient Solvent-Free Synthesis of Cyanohydrin Silyl Ethers Catalyzed by a Novel Yttrium-based Metal-Organic Framework</td>

                                <td>Juana M. Pérez</td>
                            </tr>
                            <tr>
                                <td><span>P411</span> The effect of choline hydroxide in the synthesis of based Bi2O3/Bi2O2CO3 photocatalyst for the antibiotic removal in wastewater</td>

                                <td>Helena Pérez del Pulgar Villena</td>
                            </tr>
                            <tr>
                                <td><span>P412</span> Selective formation of Si-N bonds by catalytic nitrene insertion into Si-H bonds of silanes</td>

                                <td>Jorge Pérez Ruiz</td>
                            </tr>
                            <tr>
                                <td><span>P413</span> A bottom-up multidisciplinary approach to design supramolecular co-assemblies based on amyloid-like peptides and engineered proteins</td>

                                <td>Laura Perez-Chirinos</td>
                            </tr>
                            <tr>
                                <td><span>P414</span> Low Energy Photoredox Catalysis using Triplet Fusion Upconversion for Carbon-Carbon Coupling Reactions</td>

                                <td>Raúl Pérez-Ruiz</td>
                            </tr>
                            <tr>
                                <td><span>P415</span> Overcharging of PAMAM Dendrimers through Electrochemiluminescent (ECL) Reactions in an Aqueous Medium</td>

                                <td>Pilar Perez-Tejeda</td>
                            </tr>
                            <tr>
                                <td><span>P416</span> Measuring Nanoparticle-Induced Resonance Energy Transfer Effect by Electrogenerated Chemiluminescent Reactions</td>

                                <td>Pilar Perez-Tejeda</td>
                            </tr>
                            <tr>
                                <td><span>P417</span> Derivatives of coumarin and stilbenes structurally related as polymerase inhibitors  </td>

                                <td>Marcelle Perretti Martín</td>
                            </tr>
                            <tr>
                                <td><span>P418</span> Pd-catalyzed direct arylation of non-protected anilines assisted by the ligand [2,2-bipyridin]-6(1H)-one</td>

                                <td>Cintya Pinilla</td>
                            </tr>
                            <tr>
                                <td><span>P419</span> Thio- and Seleno- Saccharide and Nucleoside Derivatives with Potential Biological Activities</td>

                                <td>María Soledad Pino González</td>
                            </tr>
                            <tr>
                                <td><span>P420</span> Recent applications to the synthesis of oxindole alkaloids from tryptophanol-derived lactams.</td>

                                <td>Valentina Piras</td>
                            </tr>
                            <tr>
                                <td><span>P421</span> ADAP-CuCl complexes as highly active catalysts for the functionalization of N-Heterocycles. </td>

                                <td>Juan Diego Pizarro Javier</td>
                            </tr>
                            <tr>
                                <td><span>P422</span> Kinetic Study Of The Oxidation Of Benzaldehyde By Hexacyanoferrate (III) Catalyzed By Ru (VI) In Basic Medium</td>

                                <td>Francisco Poblete</td>
                            </tr>
                            <tr>
                                <td><span>P423</span> Alkaline cations induce selectively hydrogel formation by amino acid-derived anionic surfactants</td>

                                <td>Victor Pozo Gavara</td>
                            </tr>
                            <tr>
                                <td><span>P424</span> Development of Metal-Hydride Atom Transfer Tandem Radical Reactions</td>

                                <td>Jordi Puig Bosch</td>
                            </tr>
                            <tr>
                                <td><span>P425</span> Ph2PCH2CH2B(C8H14) and its formaldehyde adduct as catalysts for the reduction of CO2 with hydroboranes</td>

                                <td>Alberto Ramos</td>
                            </tr>
                            <tr>
                                <td>Structural diversity of metal-organic compounds based on first-row transition metals and nicotinate-type ligands</td>

                                <td>Laura Razquin Bobillo</td>
                            </tr>
                            <tr>
                                <td><span>P427</span> Yields and composition of the gas, liquid and solid fractions obtained by conventional pyrolysis of almond shells</td>

                                <td>Maria del Carmen Recio Ruiz</td>
                            </tr>
                            <tr>
                                <td><span>P428</span> Dual Emissive Cyclometallated IrIII Complexes as Photoselective Anticancer Agents</td>

                                <td>Marta Redrado</td>
                            </tr>
                            <tr>
                                <td><span>P429</span> Nanostructured Manganese Dioxide for Hybrid Supercapacitor Electrodes</td>

                                <td>Jon Rodriguez</td>
                            </tr>
                            <tr>
                                <td><span>P430</span> Synthetic Approaches Toward the Undescribed Ring System of Aspernomine </td>

                                <td>Laura Rodríguez</td>
                            </tr>
                            <tr>
                                <td><span>P431</span> Production of bio-addittive: acetalization of glycerol with furfural assisted by solid acid catalyst to obtain dioxolane.</td>

                                <td>Enrique Rodríguez Castellón</td>
                            </tr>
                            <tr>
                                <td><span>P432</span> Disarming Deathly Bacterial Pathogens in Clinical Settings by Quorum Quenching Strategies</td>

                                <td>Ángela Rodríguez Costa</td>
                            </tr>
                            <tr>
                                <td><span>P433</span> AFM characterization of AgNWs modified electrodes by Langmuir-Blodgett: the effect of electrochemical measurements</td>

                                <td>Maria Luz Rodriguez Mendez</td>
                            </tr>
                            <tr>
                                <td><span>P434</span> Study of imine-based COFs in benzene/cyclohexane separation. </td>

                                <td>Esther Roldán Molina</td>
                            </tr>
                            <tr>
                                <td><span>P435</span> Graphene influence over the thermodynamic of quaternary systems: experimental and model evaluation</td>

                                <td>Marta Romay-Romero</td>
                            </tr>
                            <tr>
                                <td><span>P436</span> Hydrogen production via Steam Reforming of bio-oil model compounds on Ni-based catalysts supported by activated carbon  </td>

                                <td>Juana Rosas</td>
                            </tr>
                            <tr>
                                <td><span>P437</span> CATALYTIC FLASH PYROLYSIS OF SOYBEAN HULLS </td>

                                <td>Ramiro Ruiz Rosas</td>
                            </tr>
                            <tr>
                                <td><span>P438</span> Metal-Hydride Atom Transfer Reactions in Natural Product Synthesis</td>

                                <td>Mar Saladrigas</td>
                            </tr>
                            <tr>
                                <td><span>P439</span> Influence of temperature on the stability of oleuropein-rich aqueous extracts from olive leaves</td>

                                <td>María Rosario Salinas</td>
                            </tr>
                            <tr>
                                <td><span>P440</span> Photochromic polyoxometalate-based enzyme-free reusable sensors for real-time colorimetric detection of alcohol in sweat and saliva</td>

                                <td>Manuel Sanchez</td>
                            </tr>
                            <tr>
                                <td><span>P441</span> Janus self-propelled nanomotors enzymatically controlled </td>

                                <td>Alfredo Sánchez</td>
                            </tr>
                            <tr>
                                <td><span>P442</span> Assessment of the curing condition influence on the bio-sourced moisture-curable polyurethane adhesive performance</td>

                                <td>Mª Carmen Sánchez</td>
                            </tr>
                            <tr>
                                <td><span>P443</span> NOVEL USE OF METAL-ORGANIC FRAMEWORKS IN THE ELIMINATION OF DRINKING WATER DISINFECTION BY- PRODUCTS </td>

                                <td>Gabriel Sánchez Cano</td>
                            </tr>
                            <tr>
                                <td><span>P444</span> Spectral and Photodynamical Behavior of Two Isostructural Luminescent Zn-MOFs</td>

                                <td>Francisco Sanchez Martinez</td>
                            </tr>
                            <tr>
                                <td><span>P445</span> A Bifunctional Supramolecular Receptor for Zwitterionic Amino Acids</td>

                                <td>Estela Sanchez Santos</td>
                            </tr>
                            <tr>
                                <td><span>P446</span> N-doped activated carbon prepared by hydrothermal carbonization of biomass waste </td>

                                <td>Inés Sanchis Pérez</td>
                            </tr>
                            <tr>
                                <td><span>P447</span> SYNTHESIS AND CHEMICAL REDUCTION OF TITANIUM COMPOUNDS SUPPORTED BY A REDOX ACTIVE LIGAND</td>

                                <td>Ignacio Sancho</td>
                            </tr>
                            <tr>
                                <td><span>P448</span> Efficient transformation of carbon dioxide with ionic liquids and polymeric ionic liquids: towards CO2 utilisation and H2 storage applications  </td>

                                <td>Victor Sans Sangorrin</td>
                            </tr>
                            <tr>
                                <td><span>P449</span> Spiro (pyrrolidine-3,1'-pyrrolo[3,4-c]pyrrole) skeleton generated by simple multicomponent 1,3-dipolar cycloaddition</td>

                                <td>Jose Miguel Sansano Gil</td>
                            </tr>
                            <tr>
                                <td><span>P450</span> Novel fluorescents hybrid systems for CN– recognizing hemicyanine- functionalized 7-pyrenyl- and 7-fluorenyl-pyrazolo[1,5-a]pyrimidines-based </td>

                                <td>Jeymy Sarmiento</td>
                            </tr>
                            <tr>
                                <td><span>P451</span> Electrosynthesis of biomimetic Ni@ZnO@ZnS-microalgae photocatalysts for visible-light-driven removal of toxic cyanobacteria blooms and cyanotoxins</td>

                                <td>Albert Serrà Ramos</td>
                            </tr>
                            <tr>
                                <td><span>P452</span> µ-SPEed® as a novel and promising solid phase microextraction technique combined with ultra-high performance liquid chromatography coupled to mass spectrometry for the analysis of pyrrolizidine alkaloids</td>

                                <td>María Isabel Sierra Alonso</td>
                            </tr>
                            <tr>
                                <td><span>P453</span> In vitro detection of cysteine using systems chemistry</td>

                                <td>Jordi Solà</td>
                            </tr>
                            <tr>
                                <td><span>P454</span> Highly graphitized carbon nanosheets with embedded Ni nanocrystals as cathode for Li-S batteries</td>

                                <td>Francisco Javier Soler Piña</td>
                            </tr>
                            <tr>
                                <td><span>P455</span> Experimental and computational study of catalytic systems based on gold subnanoclusters</td>

                                <td>Alba Sorroche</td>
                            </tr>
                            <tr>
                                <td><span>P456</span> Azolic Benzazepines by Pd-Catalyzed Double C-H Activation</td>

                                <td>Alejandro Suárez</td>
                            </tr>
                            <tr>
                                <td><span>P457</span> Crystallization and preliminary crystallographic studies of the recombinant GAP-related domain of human Neurofibromin 1</td>

                                <td>Alex Taranu</td>
                            </tr>
                            <tr>
                                <td><span>P458</span> Catalytic Enantioselective synthesis of Ivabradine</td>

                                <td>Javier Teresa</td>
                            </tr>
                            <tr>
                                <td><span>P459</span> Catalytic asymmetric addition of isoxazolin-5-ones to isatin ketimines</td>

                                <td>Ricardo Toran Munoz</td>
                            </tr>
                            <tr>
                                <td><span>P460</span> Optical biosensing of DNA mutations based on allele-specific hybridization chain reaction</td>

                                <td>Luis Tortajada-Genaro</td>
                            </tr>
                            <tr>
                                <td><span>P461</span> New Ruthenium Terpyridine Derivatives with Potential in Photoactivated Chemotherapy  </td>

                                <td>Antonio Troyano</td>
                            </tr>
                            <tr>
                                <td><span>P462</span> Protein dynamics of NPC1L1 upon cholesterol binding and transport</td>

                                <td>Aitor Valdivia</td>
                            </tr>
                            <tr>
                                <td><span>P463</span> Glucocorticoids vehiculised in stearic acid-based solid lipid nanoparticles protect auditory cells from cisplatin-induced ototoxicity</td>

                                <td>Isabel Varela Nieto</td>
                            </tr>
                            <tr>
                                <td><span>P464</span> Nitrone reactivity study with diboron and pyridine reagents</td>

                                <td>Emily Vargas Rodríguez</td>
                            </tr>
                            <tr>
                                <td><span>P465</span> New Hydroarylation for the Direct Synthesis of Thiochromenes Derivatives  </td>

                                <td>Noelia Velasco Perez</td>
                            </tr>
                            <tr>
                                <td><span>P466</span> Synthesis of 1,3-Disubstituted 5-Alkoxy-1H-pyrazoles by Reaction between N-Tosylhydrazones and Fischer Alkoxyalkynylcarbene Complexes </td>

                                <td>Antonio VELÁZQUEZ-STAVÍNOV</td>
                            </tr>
                            <tr>
                                <td><span>P467</span> C–H Bond Activation by a Low-valent Bimetallic Ga(I) System </td>

                                <td>Hellen Videa</td>
                            </tr>
                            <tr>
                                <td><span>P468</span> Study of the On-surface Thermal Stability of a Tropone Moiety in a Graphenic Structure.</td>

                                <td>Federico Villalobos Romero</td>
                            </tr>
                            <tr>
                                <td><span>P469</span> Advances in structured organic nanomaterials (NOMs) for bioactives delivery: synthesis and analytical characterization of quercetin nanoemulsions </td>

                                <td>Natalia Villamayor Moreno</td>
                            </tr>
                            <tr>
                                <td><span>P470</span> Synthesis and characterization of curcumin-loaded silk fibroin/hyaluronic acid hydrogels</td>

                                <td>Gloria Villora</td>
                            </tr>
                            <tr>
                                <td><span>P471</span> Pd(II) complex with a tetrahydro-1,3-thiazine/2-thiazoline ligand: Synthesis, structural characterization and evaluation of cytotoxic activity in HL-60 tumor cell line</td>

                                <td>Emilio Vinuelas Zahinos</td>
                            </tr>
                            <tr>
                                <td><span>P472</span> Luminescence and electrochemistry of tris-cyclometalated Pt(IV) complexes bearing N-heterocyclic carbene ligands</td>

                                <td>Ángela Vivancos</td>
                            </tr>
                            <tr>
                                <td><span>P473</span> Allenamides as substrates in carbophilic activation: addition and cycloaddition processes catalyzed by carbophilic species</td>

                                <td>Paz Yepes</td>
                            </tr>
                            <tr>
                                <td><span>P474</span> Solvent effect on the formation of a new class of supramolecular polymers induced by anions</td>

                                <td>Fabiola Zapata Fernández</td>
                            </tr>
                            <tr>
                                <td><span>P475</span> Study of the synthesis conditions of covalent organic frameworks for hybrid membranes</td>

                                <td>Beatriz Zornoza</td>
                            </tr>
                            <tr>
                                <td><span>P476</span> SARS-CoV-2 Spike Protein Receptor Binding Domain. Computational Exploration, Drug Repurposing and Beyond</td>

                                <td>Javier García Marín</td>
                            </tr>
                            </tbody></table>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section>

    <form class="nv-row gap-24 small-spaced propagate">
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <div class="nv-form-item">
                    <label>
                        <input type="text" placeholder="Title">
                        <span class="label-title">Title</span>
                    </label>
                </div><!-- /nv-form-item -->
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <div class="nv-form-item">
                    <label>
                        <input type="text" placeholder="Description">
                        <span class="label-title">Description</span>
                    </label>
                </div><!-- /nv-form-item -->


                <!-- TextArea option
                <div class="nv-form-item">
                    <label>
                        <textarea rows="3" colls="50" placeholder="Description">
                        </textarea>
                        <span class="label-title">Description</span>
                    </label>
                </div>
                -->

            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->

        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <div class="nv-form-item select-item">
                    <label>
                        <select>
                            <option>Materials</option>
                            <option>Resources</option>
                            <option>External</option>
                        </select>
                        <span class="label-title">Resource Type</span>
                    </label>
                </div><!-- /nv-form-item -->
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->

        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <div class="nv-form-item select-item">
                    <label>
                        <select>
                            <option>Material 01</option>
                            <option>Material 02</option>
                            <option>Material 03</option>
                        </select>
                        <span class="label-title">Select Material</span>
                    </label>
                </div><!-- /nv-form-item -->
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->

        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <div class="nv-form-item">
                    <label>
                        <input type="text" placeholder="Resource">
                        <span class="label-title">Resource</span>
                    </label>
                </div><!-- /nv-form-item -->
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->

        <div class="nv-column desk-6 phab-12">
            <div class="nv-column-content">
               <button type="submit" class="nv-btn primary fw medium">Save</button>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-6 phab-12">
            <div class="nv-column-content">
                <button type="submit" class="nv-btn neutral fw medium">Cancel</button>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
    </form><!-- /.nv-row -->
    <section class="nv-section guttered nv-eagen-bunch">
        <div class="nv-port">
            <div class="nv-row spaced gap-40 nv-eb-hero">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-eb-hero-img" style="background-image: url('https://service.e-materials.com/storage/resources/eagen_european_association_of_gastroenterology_endoscopy_nutrition/web/webinar-hero.jpg');"></div>
                        <div class="nv-eb-content">
                            <div class="nv-eb-logo"><img src="https://service.e-materials.com/storage/resources/eagen_european_association_of_gastroenterology_endoscopy_nutrition/eag/eagen_banner_2000px_-_750px.png" alt="EAGEN"></div>
                            <h1>Webinar Series: Monthly Updates in Gastroenterology</h1>
                            <h3>Interactive, high-quality, specified education & communication</h3>
                        </div><!-- /.nv-eb-content -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column desk-12 -->
            </div><!-- /.nv-row -->
            <div class="nv-row large-spaced gap-40 nv-eb-list">
                <div class="nv-column desk-6 tab-12">
                    <div class="nv-column-content">
                        <ul>
                            <li>
                                <h4>September 2021</h4>
                                <p><strong>Gastroesophageal diseases</strong>: L. Herszenyi, P. Malfertheiner, D. Dumitrascu</p>
                            </li>
                            <li>
                                <h4>October 2021</h4>
                                <p><strong>Optimal use of breath tests</strong>: P. Malfertheiner, H. Hammer, A. Link</p>
                            </li>
                            <li>
                                <h4>November 2021</h4>
                                <p><strong>Functional gastrointestinal diseases</strong>: H. Hammer, J. Tack, T. Vanyutsel</p>
                            </li>
                            <li>
                                <h4>December 2021</h4>
                                <p><strong>Immunotherapies in GI Neoplasia</strong>: G.Krejs, T. Matysiak- Budnik, R. Nakov</p>
                            </li>
                            <li>
                                <h4>January 2022</h4>
                                <p><strong>Endoscopy - new developments ready for clinical translation</strong>: J. Tack, M. Bruno</p>
                            </li>
                            <li>
                                <h4>February 2022</h4>
                                <p><strong>Rare gastrointestinal diseases</strong>: D. Dumitrascu, Z. Tulassay, R. Nakov</p>
                            </li>
                        </ul>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column desk-6 -->
                <div class="nv-column desk-6 tab-12">
                    <div class="nv-column-content">
                        <ul>
                            <li>
                                <h4>March 2022</h4>
                                <p><strong>Microbiome modulation - time to revisit treatment options</strong>: P. Malfertheiner, A. Gasbarrini</p>
                            </li>
                            <li>
                                <h4>April 2022</h4>
                                <p><strong>Metabolic syndrome - beyond life style modifications</strong>: T. Milosavlijevic, D. Stimac</p>
                            </li>
                            <li>
                                <h4>May 2022</h4>
                                <p><strong>Autoimmune diseases in Gastroenterology</strong>: P. Malfertheiner, P. Dite, A. Link</p>
                            </li>
                            <li>
                                <h4>June 2022</h4>
                                <p><strong>Colon cancer - From screen to treat</strong>: T. Matysiak-Budnik, J. Regula, J. Kupcinskas</p>
                            </li>
                            <li>
                                <h4>July 2022</h4>
                                <p><strong>H. pylori - what is new in management?</strong>: P. Malfertheiner, T. Milosavljevic</p>
                            </li>
                            <li>
                                <h4>August 2022</h4>
                                <p><strong>Refractory IBD</strong>: J. Regula, J. Kupcinskas, T. Vanyutsel</p>
                            </li>
                            <li>
                                <h4>September 2022</h4>
                                <p><strong>Irritable Bowel Syndrome - Time for new medical devices</strong>: J. Malagelada, L. Herszenyi, D. Dumitrascu</p>
                            </li>
                        </ul>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column desk-6 -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section>
    <section class="nv-section guttered nv-portal-footer">
        <div class="nv-port">
            <div class="nv-row spaced gap-40 v-stretch">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <p>Website: <a href="http://www.eagen.org" target="_blank">www.eagen.org</a></p>
                        <p><small>All rights reserved &copy;Eagen 2021</small></p>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column desk-12 -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section>
    <section class="nv-section guttered nv-program-light-section nv-awardees">
        <div class="nv-port">
            <div class="nv-row gap-24 nv-row large-spaced">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <p>
                            <nv-button color="primary" iconPl="&#xe095;" [loading]="true">Add webcast</nv-button>
                        </p>
                        <table class="navus-price-table">
                            <thead>
                                <tr class="red">
                                    <th></th>
                                    <th>Basic</th>
                                    <th>ADVANCED</th>
                                    <th>PERFORMER</th>
                                    <th>ENTERPRISE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="nv-table-side-cell">Monthly</td>
                                    <td>99&euro;</td>
                                    <td>199&euro;</td>
                                    <td>499&euro;</td>
                                    <td rowspan="2">Let's talk!</td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Annualy (10% discount)</td>
                                    <td>1069.20&euro;</td>
                                    <td>2149.20&euro;</td>
                                    <td>5389.20&euro;</td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell" rowspan="2">Plan detail</td>
                                    <td>Up to 300 delegates</td>
                                    <td>Up to 500 delegates</td>
                                    <td>Up to 500 delegates per event</td>
                                    <td rowspan="2">Contact us for a customised quote</td>
                                </tr>
                                <tr>
                                    <td>1 event (5h program)</td>
                                    <td>up to 3 events (15h program)</td>
                                    <td>Up to 10 events (100h program)</td>
                                </tr>
                                <tr>
                                    <td class="nv-full-width-cell" colspan="5">E-configurator: Navus all-in-one platform</td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Add up to 10 admins from your organisation</td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Full access to all modules to set up and mange your event</td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-full-width-cell" colspan="5">Event website</td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Create your own event website</td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Website builder</td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Personalised domain</td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-full-width-cell" colspan="5">Live Stream</td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Live stage with single stream</td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Limit of LS hours</td>
                                    <td><span class="nv-table-yes"></span> 5h included. Add extra hours 90&euro;/hour</td>
                                    <td><span class="nv-table-yes"></span> 15h included. Add extra hours 90&euro;/hour</td>
                                    <td><span class="nv-table-yes"></span> 100h included. Add extra hours 90&euro;/hour</td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Live stage with Parallel streams</td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-yes"></span> Add parallel streams 90&euro;/hour</td>
                                    <td><span class="nv-table-yes"></span> Up to 3 parallel streams. Add parallel streams 90&euro;/hour</td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-full-width-cell" colspan="5">Sponsor spaces</td>
                                <tr>
                                    <td class="nv-table-side-cell">Exhibitors page</td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Sponsor banners</td>
                                    <td><span class="nv-table-yes"></span> additional fee of 4,9&euro;/delegate</td>
                                    <td><span class="nv-table-yes"></span> additional fee of 4,9&euro;/delegate</td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Live chat with the sponsors from the exhibitors page</td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Multi-user breakout session with sponsors</td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-full-width-cell" colspan="5">Event Registration system</td>
                                <tr>
                                <tr>
                                    <td class="nv-table-side-cell">Registration system</td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Ticket types</td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-full-width-cell" colspan="5">Networking</td>
                                <tr>
                                <tr>
                                    <td class="nv-table-side-cell">Unlimited live voting</td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Q&A with moderation</td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Live chat on the event website</td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Live chat on the event app</td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Chat breakout rooms</td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">One to one chat with speakers and authors</td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-full-width-cell" colspan="5">On demand content publishing</td>
                                <tr>
                                <tr>
                                    <td class="nv-table-side-cell">Upload slides and written content up to 50 files</td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Upload webcasts and podcasts up to 20 hours</td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span> additional fee of 5&euro;/file</td>
                                    <td><span class="nv-table-yes"></span> additional fee of 5&euro;/file</td>
                                </tr>
                                <tr>
                                    <td class="nv-full-width-cell" colspan="5">Analytics</td>
                                <tr>
                                <tr>
                                    <td class="nv-table-side-cell">Basic Event Analytics</td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Advanced Event Analytics and reports</td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-full-width-cell" colspan="5">Support</td>
                                <tr>
                                <tr>
                                    <td class="nv-table-side-cell">On boarding training session</td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Consultancy support</td>
                                    <td><span class="nv-table-yes"></span> 2h consultancy</td>
                                    <td><span class="nv-table-yes"></span> 4h consultancy</td>
                                    <td><span class="nv-table-yes"></span> 6h consultancy</td>
                                    <td><span class="nv-table-yes"></span> Unlimited</td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Personal Navus Event Coordinator</td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Technical support</td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Technical flow moderation during the conference</td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span> get it for 159&euro;/hour/LS track</td>
                                    <td><span class="nv-table-yes"></span> get it for 159&euro;/hour/LS track</td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-full-width-cell" colspan="5">Event App</td>
                                <tr>
                                <tr>
                                    <td class="nv-table-side-cell">Event App published on the Navus App</td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Stand alone app</td>
                                    <td colspan="4"><span class="nv-table-yes"></span> get it for 990&euro;</td>
                                </tr>
                                <tr>
                                    <td class="nv-full-width-cell" colspan="5">HUBs</td>
                                </tr>
                                <tr>
                                    <td class="nv-table-side-cell">Web HUB</td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                <tr>
                                <tr>
                                    <td class="nv-table-side-cell">App HUB</td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-no"></span></td>
                                    <td><span class="nv-table-yes"></span></td>
                                <tr>
                            </tbody>
                        </table>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /nv-port -->
    </section>


    <section class="nv-section guttered nv-program-light-section nv-awardees">
        <div class="nv-port">
            <div class="nv-row spaced-top spaced-bottom gap-40 v-stretch">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h2 class="nv-program-light-title"><span>Individual Award Videos</span></h2>
                        <br>
                        <h2 class="nv-program-light-title"><span>Awardees 2021</span></h2>
                    </div>
                </div>
            </div><!--/ nv-row -->
            <div class="nv-row large-spaced-bottom gap-40 v-stretch">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-program-light-item">
                            <h3>Welcome and introduction</h3>
                            <h5>Dr. med. Rahel Troxler Saxer</h5>
                            <ul>
                                <li>President of the Pfizer Research Prize Foundation</li>
                                <li>Medical Director Switzerland Pfizer AG</li>
                            </ul>
                            <h5>Sabine Bruckner</h5>
                            <ul>
                                <li>Member of the Pfizer Research Prize Foundation</li>
                                <li>Country Manager Switzerland Pfizer AG</li>
                            </ul>
                        </div>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
            </div><!--/ nv-row -->
            <div class="nv-row gap-24 propagate">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h4><span>CARDIOVASCULAR, UROLOGY AND NEPHROLOGY</span></h4>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
                <div class="nv-column desk-4 tab-6 phab-12">
                    <div class="nv-column-content">
                        <h5>PD Dr. Ange Maguy, PD Dr. Jin Li</h5>
                        <ul>
                            <li>University of Bern, CHUV Lausanne</li>
                        </ul>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column desk-8 tab-6 phab-12">
                    <div class="nv-column-content">
                        <div class="nv-video-box-block padded">
                            <iframe src="https://video.ibm.com/embed/recorded/129948522?showtitle=false" allowfullscreen></iframe>
                        </div><!-- /.nv-video-box-block -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->

                <div class="nv-column desk-4 tab-6 phab-12">
                    <div class="nv-column-content">
                        <h5>Dr. Nikola Kozhuharov</h5>
                        <ul>
                            <li>University Hospital Basel</li>
                        </ul>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column desk-8 tab-6 phab-12">
                    <div class="nv-column-content">
                        <div class="nv-video-box-block padded">
                            <iframe src="https://video.ibm.com/embed/recorded/129948520?showtitle=false" allowfullscreen></iframe>
                        </div><!-- /.nv-video-box-block -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->

                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h4><span>INFECTIOLOGY, RHEUMATOLOGY AND IMMUNOLOGY</span></h4>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->

                <div class="nv-column desk-4 tab-6 phab-12">
                    <div class="nv-column-content">
                        <h5>Dr. Cristina Gil-Cruz, Dr. Christian Pérez-Shibayama, Dr. Veronika Nindl</h5>
                        <ul>
                            <li>Cantonal Hospital St. Gallen</li>
                        </ul>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column desk-8 tab-6 phab-12">
                    <div class="nv-column-content">
                        <div class="nv-video-box-block padded">
                            <iframe src="https://video.ibm.com/embed/recorded/129948521?showtitle=false" allowfullscreen></iframe>
                        </div><!-- /.nv-video-box-block -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->

                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h4>NEUROSCIENCE AND DISEASES OF THE NERVOUS SYSTEM</h4>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->

                <div class="nv-column desk-4 tab-6 phab-12">
                    <div class="nv-column-content">
                        <h5>Dr. Dasha Nelidova</h5>
                        <ul>
                            <li>Institute of Molecular and Clinical Ophthalmology Basel (IOB) <br><br>Friedrich Miescher Institute for Biomedical Research, Basel</li>
                        </ul>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column desk-8 tab-6 phab-12">
                    <div class="nv-column-content">
                        <div class="nv-video-box-block padded">
                            <iframe src="https://video.ibm.com/embed/recorded/129948509?showtitle=false" allowfullscreen></iframe>
                        </div><!-- /.nv-video-box-block -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->


                <div class="nv-column desk-4 tab-6 phab-12">
                    <div class="nv-column-content">
                        <h5>Dr. Michael Hugelshofer, Dr. Raphael Buzzi</h5>
                        <ul>
                            <li>University Hospital Zurich</li>
                        </ul>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column desk-8 tab-6 phab-12">
                    <div class="nv-column-content">
                        <div class="nv-video-box-block padded">
                            <iframe src="https://video.ibm.com/embed/recorded/129948506?showtitle=false" allowfullscreen></iframe>
                        </div><!-- /.nv-video-box-block -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->

                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h4><span>ONCOLOGY</span></h4>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->

                <div class="nv-column desk-4 tab-6 phab-12">
                    <div class="nv-column-content">
                        <h5>Dr. Chloe Chong, Dr. Michal Bassani-Sternberg</h5>
                        <ul>
                            <li>Ludwig Institute for Cancer Research, Lausanne, CHUV and University of Lausanne</li>
                        </ul>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column desk-8 tab-6 phab-12">
                    <div class="nv-column-content">
                        <div class="nv-video-box-block padded">
                            <iframe src="https://video.ibm.com/embed/recorded/129948504?showtitle=false" allowfullscreen></iframe>
                        </div><!-- /.nv-video-box-block -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->

                <div class="nv-column desk-4 tab-6 phab-12">
                    <div class="nv-column-content">
                        <h5>Prof. Carsten Riether</h5>
                        <ul>
                            <li>Inselspital and University of Bern</li>
                        </ul>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column desk-8 tab-6 phab-12">
                    <div class="nv-column-content">
                        <div class="nv-video-box-block padded">
                            <iframe src="https://video.ibm.com/embed/recorded/129948507?showtitle=false" allowfullscreen></iframe>
                        </div><!-- /.nv-video-box-block -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->

                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h4><span>PEDIATRICS</span></h4>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->

                <div class="nv-column desk-4 tab-6 phab-12">
                    <div class="nv-column-content">
                        <h5>Dr. Dr. Andrea Alexis Mauracher</h5>
                        <ul>
                            <li>University Children's Hospital Zurich</li>
                        </ul>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column desk-8 tab-6 phab-12">
                    <div class="nv-column-content">
                        <div class="nv-video-box-block padded">
                            <iframe src="https://video.ibm.com/embed/recorded/129948508?showtitle=false" allowfullscreen></iframe>
                        </div><!-- /.nv-video-box-block -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->

                <div class="nv-column desk-4 tab-6 phab-12">
                    <div class="nv-column-content">
                        <h5>Dr. Christa König, Dr. Cécile Adam</h5>
                        <ul>
                            <li>University Hospital and University of Bern, CHUV Lausanne</li>
                        </ul>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column desk-8 tab-6 phab-12">
                    <div class="nv-column-content">
                        <div class="nv-video-box-block padded">
                            <iframe src="https://video.ibm.com/embed/recorded/129948505?showtitle=false" allowfullscreen></iframe>
                        </div><!-- /.nv-video-box-block -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->

    <div class="nv-section">
        <div class="nv-row">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <div class="nv-inner-hero-simple" title="" style="background-image: url('//service.e-materials.com/storage/resources/navus/aqu/aquarium-media-sponsoship-hero.jpg')">
                        <h1><span>Sponsorship Options</span></h1>
                    </div><!-- /.nv-block-banner -->
                </div>
            </div>
        </div><!-- /nv-row -->
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-40 nv-row spaced-top large-spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-table-wrap">
                            <table class="nv-sponsorship-prices">
                                <tbody>
                                <tr>
                                    <th>Phase</th>
                                    <th>Item Description</th>
                                    <th>Platinum Sponsor</th>
                                    <th>Gold Sponsor</th>
                                    <th>Exhibitor</th>
                                </tr>
                                <tr>
                                    <td><strong>Pre-Event</strong></td>
                                    <td>Text</td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-no"></span></td>
                                </tr>
                                <tr>
                                    <td><strong>During-Event</strong></td>
                                    <td>Text</td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-yes"></span></td>
                                </tr>
                                <tr>
                                    <td><strong>During-Event</strong></td>
                                    <td>Text</td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-no"></span></td>
                                    <td><span class="nv-ico-no"></span></td>
                                </tr>
                                <tr>
                                    <td><strong>Post-Event</strong></td>
                                    <td>Text</td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-yes"></span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div><!-- /.nv-table-wrap -->
                    </div><!-- /nv-column-content -->
                </div>
            </div><!--  /nv-row -->
        </div>
    </div><!-- /nv-section -->
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-40 nv-row large-spaced">
                <div class="nv-column desk-3 tab-12">
                    <div class="nv-column-content">
                        <h4>Continue your presence and engage with the EFR community</h4>
                        <p>Join the EFR Reception 2021 as a <strong>Partner</strong> or <strong>Exhibitor</strong> sponsor.</p>
                        <p>Further sponsorship services:</p>
                        <ul>
                            <li>Sponsor a presentation/speaker</li>
                            <li>Pop up lead generation</li>
                            <li>Etc.</li>
                        </ul>
                        <p>..on request.</p>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
                <div class="nv-column desk-9 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-table-wrap">
                            <table class="nv-sponsorship-prices">
                                <tbody>
                                <tr>
                                    <th></th>
                                    <th>Partner</th>
                                    <th>Exhibitor</th>
                                </tr>
                                <tr class="nv-sponsorship-prices-heading">
                                    <td>Fee net without VAT</td>
                                    <td>2,000&euro;</td>
                                    <td>900&euro;</td>
                                </tr>
                                <tr>
                                    <td>Showcase your messages, products and services on your own company / booth page. Display hero image, description text and links, publish videos and materials.</td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-yes"></span></td>
                                </tr>
                                <tr>
                                    <td>Branding (Logo, size according to level) on Home page, links to profile page in Exhibition area.</td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-no"></span></td>
                                </tr>
                                <tr>
                                    <td>Exclusive main banner (rotating to show other Platinum sponsors) on all conference pages including home page, program page, live stage page etc.)</td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-no"></span></td>
                                </tr>
                                <tr>
                                    <td>Branding of networking break out rooms</td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-no"></span></td>
                                </tr>
                                <tr>
                                    <td>Branding on Transition Slides between presentations</td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-no"></span></td>
                                </tr>
                                <tr>
                                    <td>Live Host button on your company page allowing visitors to speak to you directly with one click.</td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-no"></span></td>
                                </tr>
                                <tr>
                                    <td>Exclusive (written) Chat area with visitors on your company page.</td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-no"></span></td>
                                </tr>
                                <tr>
                                    <td>Mentioning of Sponsor in social media activities.</td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-no"></span></td>
                                </tr>
                                <tr>
                                    <td>One exclusive notification on the live stage during the event.</td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-no"></span></td>
                                </tr>
                                <tr>
                                    <td>Branding in two email letters that goes to more than 14.000 members of the EFR network before and after the meeting.</td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-no"></span></td>
                                </tr>
                                <tr>
                                    <td>Include one company video (max 1 minute) between sessions, as  part of the introduction, close and breaks during the live conference.</td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-no"></span></td>
                                </tr>
                                <tr>
                                    <td>Publish up to one videos, article and brochures in the on- demand materials hub that is live post conference.</td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-no"></span></td>
                                </tr>
                                <tr>
                                    <td>Your company has its own chat group in the chat module available for Attendees.</td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-no"></span></td>
                                </tr>
                                <tr>
                                    <td>Receive data (name, email, country, organization) from Attendees who engaged with your company page.</td>
                                    <td><span class="nv-ico-yes"></span></td>
                                    <td><span class="nv-ico-yes"></span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div><!-- /.nv-table-wrap -->
                    </div><!-- /nv-column-content -->
                </div>
            </div><!--  /nv-row -->
        </div>
    </div><!-- /nv-section -->

    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 nv-row large-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h2>Sponsorship spaces &amp;amp; Virtual Exhibitors</h2>
                        <p>Increase your visibility and enhance your brand awareness across all digital channels before, during and after the event. If you are looking to gain brand exposure within the industry and create interactions and engagement with professionals from your sector, this event is the perfect place to connect!</p>
                        <h3>How?</h3>
                    </div><!-- /.nv-column-content -->
                </div>
            </div><!--  /nv-row -->
            <div class="nv-row gap-30 nv-row">
                <div class="nv-column desk-4 tab-6 phab-12 nv-sponsorship-demo-txt v-middle">
                    <div class="nv-column-content">
                        <h3>Unlimited use of dedicated sponsorship spaces (banners) across all different channels</h3>
                    </div><!-- /.nv-column-content -->
                </div>
                <div class="nv-column desk-8 tab-6 phab-12 nv-sponsorship-demo-gfx v-middle">
                    <div class="nv-column-content">
                        <img src="//service.e-materials.com/storage/resources/efr/spo/sponsorship-banner-web-new.jpg" alt="Sponsor banner">
                        <h5>Sponsorship banner</h5>
                    </div><!-- /.nv-column-content -->
                </div>
            </div><!--  /nv-row -->
            <div class="nv-row gap-30 nv-row">
                <div class="nv-column desk-6 phab-12 nv-sponsorship-demo-gfx on-left v-middle">
                    <div class="nv-column-content">
                        <img class="nv-sponsors-page-screen" src="//service.e-materials.com/storage/resources/efr/spo/sponsors-page-web.png" alt="Sponsors page">
                        <h5>Virtual Exhibitors page</h5>
                    </div><!-- /.nv-column-content -->
                </div>
                <div class="nv-column desk-6 nv-sponsorship-demo-txt v-middle">
                    <div class="nv-column-content">
                        <h3>Dedicated exhibition area with virtual Sponsor booths</h3>
                    </div><!-- /.nv-column-content -->
                </div>
            </div><!--  /nv-row -->
            <div class="nv-row gap-30 nv-row">
                <div class="nv-column desk-4 tab-6 phab-12 nv-sponsorship-demo-txt v-middle">
                    <div class="nv-column-content">
                        <h2>Online Branding</h2>
                        <h3>Place your messages across all webpages and generate maximum impressions and clicks</h3>
                        <ul>
                            <li>Home page</li>
                            <li>Registration form and Registration Confirmation Emails</li>
                            <li>Speakerscpage</li><li>Live-streaming stage</li>
                            <li>Program page</li>
                            <li>On Demand Page</li>
                        </ul>
                        <h4>Different rotation frequencies (share of impressions) can be set for different type of message at different times.</h4>
                        <h4>All banners are clickable and can lead to your booth page or an external landing page.</h4>

                    </div><!-- /.nv-column-content -->
                </div>
                <div class="nv-column desk-8 tab-6 phab-12 nv-sponsorship-demo-gfx v-middle">
                    <div class="nv-column-content">
                        <img src="//service.e-materials.com/storage/resources/efr/spo/sponsorship-visibility-new-2.jpg" alt="Sponsor banner">
                        <h5>Your messages across all webpages</h5>
                    </div><!-- /.nv-column-content -->
                </div>
            </div><!--  /nv-row -->
            <div class="nv-row gap-30 nv-row">
                <div class="nv-column desk-6 phab-12 nv-sponsorship-demo-gfx on-left v-middle">
                    <div class="nv-column-content">
                        <img src="//service.e-materials.com/storage/resources/efr/spo/sponsorship-virtual-exhibitors-web.jpg" alt="Sponsor banner">
                        <h5>Virtual Exhibitors</h5>
                    </div><!-- /.nv-column-content -->
                </div>
                <div class="nv-column desk-6 phab-12 nv-sponsorship-demo-txt v-middle">
                    <div class="nv-column-content">
                        <h2>Virtual Exhibition</h2>
                        <p>Sponsors can gain visibility and interaction with the audience via a dedicated exhibition area.<br><br>Sponsors can be presented indifferent categories (such as <strong>Gold</strong>, <strong>Silver</strong> and <strong>Platinum</strong>).<br><br>Get your own virtual booth with various options based on sponsorship levels.</p>
                    </div><!-- /.nv-column-content -->
                </div>
            </div><!--  /nv-row -->
            <div class="nv-row gap-30 nv-row spaced-bottom">
                <div class="nv-column desk-4 tab-6 phab-12 nv-sponsorship-demo-txt v-middle">
                    <div class="nv-column-content">
                        <h2>Next Level Networking</h2>
                        <p>The virtual booths are built for engagement and interaction!<br><br>Enjoy 1:1 video calls and hosted break out rooms to enhance and encourage the interaction between the sponsors and the attendees.<br><br>Use live chat feature to engage with the audience and answer any questions they have before, during and after the event.</p>
                    </div><!-- /.nv-column-content -->
                </div>
                <div class="nv-column desk-8 tab-6 phab-12 nv-sponsorship-demo-gfx v-middle">
                    <div class="nv-column-content">
                        <img src="//service.e-materials.com/storage/resources/efr/spo/sponsorship-networking.png" alt="Sponsor Networking">
                        <h5>Networking</h5>
                    </div><!-- /.nv-column-content -->
                </div>
            </div><!--  /nv-row -->
        </div>
    </div><!-- /nv-section -->




<div class="nv-section guttered">
    <div class="nv-port">


        <div class="nv-row gap-24 nv-row large-spaced-top small-spaced-bottom">
            <div class="nv-column desk-12">
                <div class="nv-column-content" style="text-align: center;">
                    <h2 class="nv-twitter-heading">Join the conversation</h2>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row gap-24 v-stretch small-spaced-top spaced-bottom">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <div class="nv-line-tabs">
                        <nav class="nv-tabs-nav">
                            <ul>
                                <li>
                                    <a href class="active">Coronavirus</a>
                                </li>
                                <li>
                                    <a href>Virtual Event</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row gap-24 v-stretch spaced-bottom propagate">
            <div class="nv-column desk-4 tab-6 phab-12">
                <div class="nv-column-content">
                 <div class="nv-twitter-item">
                     <header>
                         <div class="nv-twitter-source">
                            <div class="ta-img">
                                <img src="https://loremflickr.com/g/72/72/paris" alt="source name">
                            </div><!-- /.ta-img -->
                             <div class="ta-txt">
                                 <h4>Coronavirus</h4>
                                 <p><a href>@Covid19Bots · 2h</a></p>
                             </div><!-- /.ta-txt -->
                         </div><!-- /.nv-twitter-source -->
                         <div class="nv-twitter-follow">
                             <a href>Follow</a>
                         </div><!-- /.nv-twitter-follow -->
                     </header>
                     <div class="ta-body">
                         <p>The role of wearing a mask: visualization of the airflow when the person wearing the mask and the person not … <a href>http://www.example.com/index.html</a></p>
                     </div><!-- /.ta-body -->
                     <div class="ta-meta">
                         <div class="ta-comments"><a href title="comments"></a><span>13</span></div>
                         <div class="ta-likes"><a href title="likes"></a><span>22</span></div>
                     </div><!-- /.ta-meta -->
                 </div><!-- /.nv-twitter-item -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column desk-4 tab-6 phab-12">
                <div class="nv-column-content">
                    <div class="nv-twitter-item">
                        <header>
                            <div class="nv-twitter-source">
                                <div class="ta-img">
                                    <img src="https://loremflickr.com/g/72/72/paris" alt="source name">
                                </div><!-- /.ta-img -->
                                <div class="ta-txt">
                                    <h4>Coronavirus</h4>
                                    <p><a href>@Covid19Bots · 2h</a></p>
                                </div><!-- /.ta-txt -->
                            </div><!-- /.nv-twitter-source -->
                            <div class="nv-twitter-follow">
                                <a href>Follow</a>
                            </div><!-- /.nv-twitter-follow -->
                        </header>
                        <div class="ta-body">
                            <p>The role of wearing a mask: visualization of the airflow when the person wearing the mask and the person not … <a href>http://www.example.com/index.html</a></p>
                        </div><!-- /.ta-body -->
                        <div class="ta-meta">
                            <div class="ta-comments"><a href title="comments"></a><span>13</span></div>
                            <div class="ta-likes"><a href title="likes"></a><span>22</span></div>
                        </div><!-- /.ta-meta -->
                    </div><!-- /.nv-twitter-item -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column desk-4 tab-6 phab-12">
                <div class="nv-column-content">
                    <div class="nv-twitter-item">
                        <header>
                            <div class="nv-twitter-source">
                                <div class="ta-img">
                                    <img src="https://loremflickr.com/g/72/72/paris" alt="source name">
                                </div><!-- /.ta-img -->
                                <div class="ta-txt">
                                    <h4>Coronavirus</h4>
                                    <p><a href>@Covid19Bots · 2h</a></p>
                                </div><!-- /.ta-txt -->
                            </div><!-- /.nv-twitter-source -->
                            <div class="nv-twitter-follow">
                                <a href>Follow</a>
                            </div><!-- /.nv-twitter-follow -->
                        </header>
                        <div class="ta-body">
                            <p>Canatics exploratory investigation data masking. Bleeding edge. Driving the initiative forward pivot hit the ground running talk to the slides you better eat a reality sandwich before you walk back in that boardroom.</p>
                        </div><!-- /.ta-body -->
                        <div class="ta-meta">
                            <div class="ta-comments"><a href title="comments"></a><span>13</span></div>
                            <div class="ta-likes"><a href title="likes"></a><span>22</span></div>
                        </div><!-- /.ta-meta -->
                    </div><!-- /.nv-twitter-item -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row gap-24 v-stretch small-spaced-top large-spaced-bottom flow-center">
            <div class="nv-column desk-initial">
                <div class="nv-column-content">
                    <a href class="nv-btn twitter medium radius-high">Share your views</a>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</div><!-- /.nv-section -->

<div class="nv-section guttered">
    <div class="nv-port">
        <div class="nv-row gap-24 v-stretch large-spaced-top spaced-bottom propagate">
            <div class="nv-column desk-4">
                <div class="nv-column-content">
                    <div class="nv-promo-box">
                        <div class="pb-icon" data-icon-pl="&#xe95b;"></div><!-- /.pb-icon -->
                        <h2>Live Stream</h2>
                        <p>About managing expectations, get all your ducks in a row, for this is meaningless but gain alignment for please submit the sop and uat files by next monday.</p>
                        <div class="foo-btn">
                            <a href class="nv-btn accent1 medium radius-high">Button Label</a>
                        </div><!-- /.foo-btn -->
                    </div><!-- /.nv-promo-box -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column desk-4">
                <div class="nv-column-content">
                    <div class="nv-promo-box">
                        <div class="pb-icon" data-icon-pl="&#xe95b;"></div><!-- /.pb-icon -->
                        <h2>Live Stream</h2>
                        <p>About managing expectations, get all your ducks in a row, for this is meaningless but gain alignment for please submit the sop and uat files by next monday.</p>
                        <div class="foo-btn">
                            <a href class="nv-btn accent1 medium radius-high">Button Label</a>
                        </div><!-- /.foo-btn -->
                    </div><!-- /.nv-promo-box -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column desk-4">
                <div class="nv-column-content">
                    <div class="nv-promo-box">
                        <div class="pb-icon" data-icon-pl="&#xe95b;"></div><!-- /.pb-icon -->
                        <h2>Live Stream</h2>
                        <p>About managing expectations, get all your ducks in a row, for this is meaningless but gain alignment for please submit the sop and uat files by next monday.</p>
                        <div class="foo-btn">
                            <a href class="nv-btn accent1 medium radius-high">Button Label</a>
                        </div><!-- /.foo-btn -->
                    </div><!-- /.nv-promo-box -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <div class="nv-promo-box">
                        <div class="pb-icon" data-icon-pl="&#xe95b;"></div><!-- /.pb-icon -->
                        <h2>Live Stream</h2>
                        <p>About managing expectations, get all your ducks in a row, for this is meaningless but gain alignment for please submit the sop and uat files by next monday.</p>
                        <div class="foo-btn">
                            <a href class="nv-btn accent1 medium radius-high">Button Label</a>
                        </div><!-- /.foo-btn -->
                    </div><!-- /.nv-promo-box -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <div class="nv-promo-box">
                        <div class="pb-icon" data-icon-pl="&#xe95b;"></div><!-- /.pb-icon -->
                        <h2>Live Stream</h2>
                        <p>About managing expectations, get all your ducks in a row, for this is meaningless but gain alignment for please submit the sop and uat files by next monday.</p>
                        <div class="foo-btn">
                            <a href class="nv-btn accent1 medium radius-high">Button Label</a>
                        </div><!-- /.foo-btn -->
                    </div><!-- /.nv-promo-box -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column desk-12">
                <div class="nv-column-content">
                   <div class="nv-banner-block">
                       <img src="https://service.e-materials.com/storage/resources/equity_pitcher/eve/eventbanner.png">
                   </div><!-- /.nv-banner-block -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</div><!-- /.nv-section -->

<div class="nv-section guttered">
    <div class="nv-port">
        <div class="nv-row spaced-top large-spaced-bottom">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <div class="nv-banner-block">
                        <img src="https://service.e-materials.com/storage/resources/equity_pitcher/eve/eventbanner.png">
                    </div><!-- /.nv-banner-block -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</div><!-- /.nv-section -->


<div class="nv-section guttered" style="background-color: rgba(0,0,0,0.035);">
    <div class="nv-port">
        <div class="nv-row gap-24 v-stretch large-spaced-top spaced-bottom propagate flow-center">
            <div class="nv-column desk-4">
                <div class="nv-column-content">
                    <div class="nv-promo-box">
                        <div class="pb-icon" data-icon-pl="&#xe95b;"></div><!-- /.pb-icon -->
                        <h2>Live Stream</h2>
                        <p>About managing expectations, get all your ducks in a row, for this is meaningless but gain alignment for please submit the sop and uat files by next monday.</p>
                        <div class="foo-btn">
                            <a href class="nv-btn accent1 medium radius-high">Button Label</a>
                        </div><!-- /.foo-btn -->
                    </div><!-- /.nv-promo-box -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column desk-4">
                <div class="nv-column-content">
                    <div class="nv-promo-box">
                        <div class="pb-icon" data-icon-pl="&#xe95b;"></div><!-- /.pb-icon -->
                        <h2>Live Stream</h2>
                        <p>About managing expectations, get all your ducks in a row, for this is meaningless but gain alignment for please submit the sop and uat files by next monday.</p>
                        <div class="foo-btn">
                            <a href class="nv-btn accent1 medium radius-high">Button Label</a>
                        </div><!-- /.foo-btn -->
                    </div><!-- /.nv-promo-box -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column desk-4">
                <div class="nv-column-content">
                    <div class="nv-promo-box">
                        <div class="pb-icon" data-icon-pl="&#xe95b;"></div><!-- /.pb-icon -->
                        <h2>Live Stream</h2>
                        <p>About managing expectations, get all your ducks in a row, for this is meaningless but gain alignment for please submit the sop and uat files by next monday.</p>
                        <div class="foo-btn">
                            <a href class="nv-btn accent1 medium radius-high">Button Label</a>
                        </div><!-- /.foo-btn -->
                    </div><!-- /.nv-promo-box -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column desk-4">
                <div class="nv-column-content">
                    <div class="nv-promo-box">
                        <div class="pb-icon" data-icon-pl="&#xe95b;"></div><!-- /.pb-icon -->
                        <h2>Live Stream</h2>
                        <p>About managing expectations, get all your ducks in a row, for this is meaningless but gain alignment for please submit the sop and uat files by next monday.</p>
                        <div class="foo-btn">
                            <a href class="nv-btn accent1 medium radius-high">Button Label</a>
                        </div><!-- /.foo-btn -->
                    </div><!-- /.nv-promo-box -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column desk-4">
                <div class="nv-column-content">
                    <div class="nv-promo-box">
                        <div class="pb-icon" data-icon-pl="&#xe95b;"></div><!-- /.pb-icon -->
                        <h2>Live Stream</h2>
                        <p>About managing expectations, get all your ducks in a row, for this is meaningless but gain alignment for please submit the sop and uat files by next monday.</p>
                        <div class="foo-btn">
                            <a href class="nv-btn accent1 medium radius-high">Button Label</a>
                        </div><!-- /.foo-btn -->
                    </div><!-- /.nv-promo-box -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</div><!-- /.nv-section -->


<div class="nv-section guttered">
    <div class="nv-port">
        <div class="nv-row gap-30 v-middle extra-large-spaced propagate">
            <div class="nv-column desk-4">
                <div class="nv-column-content">
                    <a href class="nv-btn large primary">Button Text</a>

                    <div class="nv-divider desk-medium"></div>

                    <a href class="nv-btn medium primary">Button Text</a>

                    <div class="nv-divider desk-medium"></div>

                    <a href class="nv-btn small primary">Button Text</a>

                    <div class="nv-divider desk-medium"></div>

                    <a href class="nv-btn medium primary" disabled>Button Text</a>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column desk-4">
                <div class="nv-column-content">
                    <a href class="nv-btn large secondary">Button Text</a>

                    <div class="nv-divider desk-medium"></div>

                    <a href class="nv-btn medium secondary">Button Text</a>

                    <div class="nv-divider desk-medium"></div>

                    <a href class="nv-btn small secondary">Button Text</a>

                    <div class="nv-divider desk-medium"></div>

                    <a href class="nv-btn medium secondary" disabled>Button Text</a>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column desk-4">
                <div class="nv-column-content">
                    <a href class="nv-btn large passive">Button Text</a>

                    <div class="nv-divider desk-medium"></div>

                    <a href class="nv-btn medium passive">Button Text</a>

                    <div class="nv-divider desk-medium"></div>

                    <a href class="nv-btn small passive">Button Text</a>

                    <div class="nv-divider desk-medium"></div>

                    <a href class="nv-btn medium passive" disabled>Button Text</a>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column desk-4">
                <div class="nv-column-content">
                    <a href class="nv-btn large primary" data-icon-pl="&#xe923;">Button Text</a>

                    <div class="nv-divider desk-medium"></div>

                    <a href class="nv-btn medium primary" data-icon="&#xe923;">Button Text</a>

                    <div class="nv-divider desk-medium"></div>

                    <a href class="nv-btn small primary" data-icon-pl="&#xe923;">Button Text</a>

                    <div class="nv-divider desk-medium"></div>

                    <a href class="nv-btn medium primary" disabled>Button Text</a>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column desk-4">
                <div class="nv-column-content">
                    <a href class="nv-btn large primary icon radius-high" data-icon-pl="&#xe923;"></a>

                    <div class="nv-divider desk-medium"></div>

                    <a href class="nv-btn medium primary icon radius-high" data-icon="&#xe923;"></a>

                    <div class="nv-divider desk-medium"></div>

                    <a href class="nv-btn small primary icon radius-high" data-icon-pl="&#xe923;"></a>

                    <div class="nv-divider desk-medium"></div>

                    <a href class="nv-btn medium primary icon radius-high" data-icon="&#xe923;" disabled></a>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column desk-4">
                <div class="nv-column-content">
                    <a href class="nv-btn primary large txt">Button Text</a>

                    <div class="nv-divider desk-medium"></div>

                    <a href class="nv-btn medium primary txt">Button Text</a>

                    <div class="nv-divider desk-medium"></div>

                    <a href class="nv-btn small primary txt" disabled>Button Text</a>

                    <div class="nv-divider desk-medium"></div>

                    <a href class="nv-btn medium accent2 txt">Button Text</a>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column desk-4">
                <div class="nv-column-content">
                    <a href class="nv-btn giant accent2">Button Text</a>

                    <div class="nv-divider desk-large"></div>

                    <a href class="nv-btn giant accent2" disabled>Button Text</a>

                    <div class="nv-divider desk-large"></div>

                    <a href class="nv-btn giant accent2 radius-high">Button Text</a>

                    <div class="nv-divider desk-large"></div>

                    <a href class="nv-btn giant accent2 radius-high block">Button Text</a>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</div><!-- /.nv-section -->

<div class="nv-info-modal" style="display: none;">
    <div class="im-pane">
        <header class="im-header">
            <span class="im-close"></span>
            <h3>Info title</h3>
        </header><!-- /.im-header -->
        <div class="im-body">
            <div class="im-content">
                <p>A loss a day will keep you focus 4-blocker or show pony, and land the plane i’ve been doing some research this morning and we need to better. Sacred cow we need to crystallize a plan and we need a paradigm shift land the plane who's responsible for the ask for this request? and UX. Driving the initiative forward build on a culture of contribution and inclusion or conversational content so player-coach for crisp ppt what's our go to market strategy?. Going forward time vampire so shotgun approach, but throughput but i have a hard stop in an hour and half.</p>
                <h4 class="medium">Subtitle info</h4>
                <p>Take five, punch the tree, and come back in here with a clear head granularity pivot. Let's schedule a standup during the sprint to review our kpis draw a line in the sand, per my previous email for product management breakout fastworks, we need to start advertising on social media. High performance keywords take five, punch the tree, and come back in here with a clear head what's the status on the deliverables for eow? my capacity is full nor parallel path.</p>
                <ul>
                    <li>Please advise soonest deploy it is all exactly as i said</li>
                    <li>We’re all in this together, even if our businesses function differently low-hanging fruit</li>
                    <li>Powerpoint Bunny keep it lean collaboration through advanced technlogy</li>
                </ul>
                <p>This vendor is incompetent curate. Those options are already <a href>baked in with this</a> model. We need to dialog around your choice of work attire how much bandwidth do you have yet incentivization what about scaling components to a global audience? even dead cats bounce, so closer to the metal.</p>
                <h5 class="medium">What about scaling components to a global audience?</h5>
                <p>This medium needs to be more dynamic circle back around, we need to start advertising on social media nor ensure to follow requirements when developing solutions yet i am dead inside, nor UI disband the squad but rehydrate as needed.</p>
            </div><!-- /.im-content -->
        </div><!-- /.im-body -->
    </div><!-- /.im-pane -->
</div><!-- /.nv-info-modal -->




</div><!-- /.wrap -->
