<div class="nv-form-item">
    <label
        [attr.data-icon-pl]="iconPl"
        [attr.data-icon]="icon"
        class="{{required ? 'nv-mandatory' : 'nv-not-mandatory'}}" >
        <span *ngIf="required" aria-hidden="true"></span>
        <input
                style="cursor: pointer"
                nvInputDirective
                (ngModelChange)="emitChange($event)"
                (blur)="checkInputMask($event)"
                [owlDateTime]="dt"
                [owlDateTimeTrigger]="dt"
                [min]="min"
                [max]="max"
                [disabled]="disabled || readOnly"
                [required]="required"
                [(ngModel)]="value"
                (keydown.tab)="dt.close()"
                (keydown.shift.tab)="dt.close()"
                (keyup.arrowDown)="dt.open()"
                #dateTimeInput>
        <span aria-label="label-title" class="label-title">{{ label }}</span>
    </label>
    <owl-date-time  #dt
                    [disabled]="readOnly || disabled"
                    [pickerType]="type"
                    [startView]="startView"
                    (afterPickerOpen)="dateTimeInput.focus()"
                    (afterPickerClosed)="dateTimeInput.blur()">
    </owl-date-time>
    <div *ngIf="hasError" class="nv-input-error single">
        <div>{{ error }}</div>
    </div>
</div>
