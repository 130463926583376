
<div class="nv-global-loading on" [class.on]="loading">
    <div class="nv-spinner"></div>
</div>
<section class="nv-section guttered nv-register-form nv-front-registration-form" *ngIf="!loading">
    <div class="nv-port">
        <div class="nv-row spaced-top v-middle flow-center" *ngIf="banner">
            <div class="nv-column desk-6 tab-8 phab-12">
                <div class="nv-column-content">
                    <div class="nv-banner-block" *ngIf="banner?.resource_url && banner?.external_url">
                        <a (click)="bannerClick()" target="_blank">
                            <img [src]="banner?.resource_url" alt>
                        </a>
                    </div>
                    <div class="nv-banner-block" *ngIf="banner?.resource_url && !banner?.external_url">
                        <img [src]="banner?.resource_url" alt>
                    </div>
                </div>
            </div>
        </div>
        <div class="nv-row spaced v-middle flow-center">
            <ng-container *ngIf="website?.id===414">
              <div class="nv-column desk-9 tab-8 phab-12">
                  <div class="nv-column-content">
                      <div class="nv-onboarding-dual-pane">
                          <div class="nv-login-form">
                              <div class="nv-register-form-brand">
                                  <figure *ngIf="website && website.logo">
                                      <img [src]="website.logo" alt="{{ website.name }}">
                                  </figure>
                                  <h2>{{ website.name }}</h2>
                              </div>
                              <router-outlet></router-outlet>
                          </div>
                      </div>
                  </div><!-- /.nv-column-content -->
              </div><!-- /.nv-column -->
            </ng-container>
            <ng-container *ngIf="website?.id!==414">
                <div class="nv-column desk-6 tab-8 phab-12">
                    <div class="nv-column-content">
                        <div class="nv-onboarding-dual-pane">
                            <div class="nv-login-form">
                                <div class="nv-register-form-brand">
                                    <figure *ngIf="website && website.logo">
                                        <img [src]="website.logo" alt="{{ website.name }}">
                                    </figure>
                                    <h2>{{ website.name }}</h2>
                                </div>
                                <router-outlet></router-outlet>
                            </div>
                        </div>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </ng-container>
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
  </section><!-- /nv-section -->