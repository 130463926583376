import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { WebsiteService } from '@navus/core/services/website.service';
import { WebsitePage } from '@navus/core/classes/website-page';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';

@Injectable()
export class WebsiteControlService {
  public editingtWebsitePageSubject = new BehaviorSubject<WebsitePage>(null as WebsitePage);
  public editingtWebsitePage = this.editingtWebsitePageSubject.asObservable();
  public editingtWebsitePageSectionSubject = new BehaviorSubject<WebsitePageSection>(null as WebsitePageSection);
  public editingtWebsitePageSection = this.editingtWebsitePageSectionSubject.asObservable();

  constructor(
    private websiteService: WebsiteService
  ) { }

  setEditingSection(section: WebsitePageSection) {
    this.editingtWebsitePageSectionSubject.next(section);
  }

  setEditingPage(page: WebsitePage) {
    this.editingtWebsitePageSubject.next(page);
  }

}
