import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class TemplateService {

  constructor(
    private apiService: ApiService
  ) { }

  getOrganizationTemplates(organizationId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/templates`, params);
  }

  getOrganizationTemplate(organizationId: number, templateId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/templates/${templateId}`, params);
  }

  updateOrganizationTemplate(organizationId: number, templateId: number, params: any = {}) {
    return this.apiService.put(`organizations/${organizationId}/templates/${templateId}`, params);
  }

  createOrganizationTemplate(organizationId: number, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/templates`, params);
  }

  deleteOrganizationTemplate(organizationId: number, templateId: number) {
    return this.apiService.delete(`organizations/${organizationId}/templates/${templateId}`);
  }

}
