<div class="nv-row gap-30 propagate" *ngIf="value">
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-code-editor
                    label="Content"
                    [(ngModel)]="value.content"
                    (ngModelChange)="onChange(value)"
            ></nv-code-editor>
            <!-- <nv-text-editor *ngIf="!showSource"
                    label="Content"
                    [(ngModel)]="value.content"
                    (ngModelChange)="onChange(value)"
            ></nv-text-editor>
            <small>
                <a (click)="showSource = !showSource">toggle source</a>
            </small> -->
        </div>
    </div>
</div>

