import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class EditQueryGuard implements CanActivate {
  constructor(
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (route.queryParams.edit) {
      localStorage.setItem('edit', 'true');

      this.router.navigate(['.'], {
        queryParams: { edit: null },
        queryParamsHandling: 'merge'
      });

      return false;
    }

    return true;
  }
}
