import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, shareReplay, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ChatUser, Room, RoomType } from '@navus/core/classes/chat';
import { ChatService } from '@navus/core/services/chat.service';
import { DelegateService } from '@navus/core/services/delegate.service';
import { Delegate } from '@navus/core/classes/delegate';
import { User } from '@navus/core/classes/user';
import { UserService } from '@navus/core/services/user.service';
import { LoadingService } from '@navus/ui/loading/loading.service';
import { PublicChatService } from '@navus/core/services/public-chat.service';

@Component({
  selector: 'nv-public-chat',
  styleUrls: ['./public-chat.component.scss'],
  templateUrl: './public-chat.component.html'
})
export class NavusPublicChatComponent implements OnInit, OnChanges, OnDestroy {
  @Input() eventId;
  @Input() organizationId;
  @Input() allow1on1 = true;
  @Input() allowGroupChat = true;
  @Input() chatType: 'admin' | 'delegate' = 'delegate';

  currentTab: 'conversation' | 'my_chats' | 'all_chats';
  currentUserId;
  searchTerm: string;
  conversationLoading = true;

  chatsInSelectedTab$: Observable<Array<AvailableChat>>;
  myRecentChats$: Observable<Array<AvailableChat>>;
  allAvailablePeopleAndGroups$: Observable<Array<AvailableChat>>;
  totalUnreadCount$: Observable<number>;

  selectedRoomId: string;
  selectedRoomType: RoomType;
  selectedRoomName: string;
  selectedRoomAutoJoin: boolean;

  allChatsFilter$: BehaviorSubject<RoomType> = new BehaviorSubject<RoomType>(null);

  RoomType = RoomType;
  active$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadMoreParticipantsVisible: boolean;
  participantsPageIndex: number = 1;
  loadingParticipants: boolean;
  setChatMinimized$: Subject<boolean> = new Subject();
  readonly loadMoreParticipants$: Subject<{ page: number, search_term?: string }> = new Subject();
  private startDelegateChat$: BehaviorSubject<Delegate> = new BehaviorSubject<Delegate>(null);
  private allAvailablePeopleAndGroups: Array<AvailableChat> = [];
  private readonly cancelPreviousSubscriptions$: Subject<void> = new Subject();
  private readonly cancelPreviousFiltering$: Subject<void> = new Subject();
  private unsubscribe$: Subject<boolean> = new Subject();

  private readonly PARTICIPANTS_LOAD = 15;

  private readonly groupNameFilter = ['block_', 'presentation_', 'sponsor_'];

  constructor(
    private chatService: ChatService,
    private publicChatService: PublicChatService,
    private userService: UserService,
    private delegateService: DelegateService,
    private loadingService: LoadingService,
  ) {
  }

  ngOnInit(): void {
    this.chatService.initUser(this.eventId).pipe(
      tap((userFromFirebase) => userFromFirebase && userFromFirebase.visible ? this.active$.next(true) : null),
      switchMap((userFromFirebase) => this.active$.pipe(filter((active) => active), map(() => userFromFirebase)))
    ).subscribe((userFromFirebase) => {
      if (userFromFirebase) {
        this.currentUserId = userFromFirebase.userId;
        this.chatService.setUserVisibility(this.currentUserId, true);
        this.cancelPreviousSubscriptions$.next();
        this.initPublicChat();
        this.changeTab('my_chats');
      } else {
        this.currentUserId = null;
      }
    });
    this.publicChatService.delegateChat$
      .subscribe((delegate) => {
        if (delegate) {
          this.activateChat();
          this.startDelegateChat$.next(delegate);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.hasOwnProperty('eventId') && changes.eventId.previousValue !== changes.eventId.currentValue)) {
      if (this.eventId && this.currentUserId) {
        this.cancelPreviousSubscriptions$.next();
        this.initPublicChat();
      }
    }
  }

  ngOnDestroy(): void {
    this.cancelPreviousSubscriptions$.complete();
    this.cancelPreviousFiltering$.complete();
    this.allChatsFilter$.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initPublicChat(): void {
    // Helper variables
    const allPublicGroups$ =
      this.allowGroupChat ?
        this.chatService.getAllPublicRooms(this.eventId)
          .pipe(takeUntil(this.cancelPreviousSubscriptions$), takeUntil(this.unsubscribe$), shareReplay(1)) : of([]);
    const allUsers$ = this.chatService.getAllChatUsers()
      .pipe(takeUntil(this.cancelPreviousSubscriptions$), takeUntil(this.unsubscribe$));
    const allMyRooms$ = this.chatService.getAllRoomsWithCurrentUser(this.eventId, this.currentUserId)
      .pipe(takeUntil(this.cancelPreviousSubscriptions$), takeUntil(this.unsubscribe$));
    const chatParticipants$: Observable<Delegate[] | User[]> = this.getChatParticipantsObservable();

    allPublicGroups$.subscribe((publicGroups) => {
      publicGroups.forEach((group) => {
        if (!group.subscribers.includes(this.currentUserId) && group.autoJoin) {
          this.chatService.subscribeToChat(this.eventId, group.uid, this.currentUserId);
        }
      });
    });

    this.myRecentChats$ = combineLatest([allMyRooms$, allUsers$]).pipe(
      map(([myRooms, chatUsers]) => {
        const myMessageLog = chatUsers.find(cu => cu.userId === this.currentUserId)?.messageLog;
        let filteredMyRooms = myRooms.filter(room => !this.groupNameFilter.some(groupName => room.name?.indexOf(groupName) > -1));
        if (!this.allow1on1) {
          filteredMyRooms = filteredMyRooms.filter(room => room.type !== RoomType.ONE_TO_ONE);
        }
        if (!this.allowGroupChat) {
          filteredMyRooms = filteredMyRooms.filter(room => room.type !== RoomType.PUBLIC_CHAT);
        }
        return filteredMyRooms.map(room => ({
          name: room.type === RoomType.ONE_TO_ONE ?
            this.getOneToOneGroupName(this.currentUserId, room.members, chatUsers) : room.name,
          type: room.type,
          description: room.type === RoomType.ONE_TO_ONE ?
            '' : this.getRoomUsers(chatUsers.filter(chatUser => room.members.includes(chatUser.userId))),
          pictureUrl: room.imageUrl,
          messageCount: room.messageCount,
          roomId: room.uid,
          hasActivatedChat: true,
          unreadCount: room.messageCount - (myMessageLog[room.uid] || 0),
          autoJoin: room.autoJoin,
        }));
      }),
      takeUntil(this.cancelPreviousSubscriptions$),
      takeUntil(this.unsubscribe$),
      shareReplay(1)
    );
    this.totalUnreadCount$ = this.myRecentChats$.pipe(
      map((chats) => chats?.length ? chats?.map(c => c.unreadCount || 0).reduce((a, b) => a + b) : 0),
      takeUntil(this.cancelPreviousSubscriptions$),
      takeUntil(this.unsubscribe$)
    );
    this.allAvailablePeopleAndGroups$ = combineLatest([
      chatParticipants$,
      allPublicGroups$,
      allUsers$,
      allMyRooms$,
    ]).pipe(
      map(([allConferenceParticipants, allPublicGroups, allChatUsers, allMyRooms]) => {
        const allAvailablePeopleAndGroups: AvailableChat[] = [];

        // Add all delegates to available chats
        allConferenceParticipants.forEach((cd) => {
          if (cd.user_id === this.currentUserId) {
            return;
          }
          const item: AvailableChat = this.getAvailableChat(cd, allChatUsers, allMyRooms);
          allAvailablePeopleAndGroups.push(item);
        });

        // Add all groups to available chats
        allPublicGroups.forEach((group) => {
          const item: AvailableChat = {
            name: group.name,
            description: this.getRoomUsers(allChatUsers.filter(chatUser => group.members.includes(chatUser.userId))),
            pictureUrl: group.imageUrl,
            type: RoomType.PUBLIC_CHAT,
            roomId: group.uid,
            messageCount: group.messageCount || 0,
            hasActivatedChat: true,
            autoJoin: group.autoJoin
          };
          allAvailablePeopleAndGroups.push(item);
        });

        this.allAvailablePeopleAndGroups.push(...allAvailablePeopleAndGroups);

        return this.allAvailablePeopleAndGroups
          .filter((value, index, self) => self.map(x => x.roomId).indexOf(value.roomId) === index)
          .sort((a, b) => a.name.localeCompare(b.name))
          .sort((a, b) => a.type === RoomType.ONE_TO_ONE ? 1 : -1)
          .sort((a, b) => a.hasActivatedChat && !b.hasActivatedChat ? -1 : 1)
          .filter((item) => this.filterChatsByTerm(item, this.searchTerm));
      }),
      takeUntil(this.cancelPreviousSubscriptions$),
      takeUntil(this.unsubscribe$),
    );

    this.startDelegateChat$
      .pipe(
        switchMap((delegate) => combineLatest([allUsers$, allMyRooms$]).pipe(take(1), map((res) => ({ delegate, res }))))
      )
      .subscribe(({ delegate, res }) => {
        if (delegate) {
          const chat = this.getAvailableChat(delegate, res[0], res[1]);
          this.startChat(chat, delegate);
        }
      });
  }

  onChatLoading(loading: boolean) {
    this.conversationLoading = loading;
  }

  exitChat(event): void {
    if (event && event.currentTarget && event.currentTarget.checked === false) {
      this.chatService.setUserVisibility(this.currentUserId, false).then(() => {
        this.active$.next(false);
      });
    }
  }

  startChat(chat: AvailableChat, informDelegate: Delegate = null): void {
    const newRoom: Room = {
      uid: chat.roomId,
      type: chat.type,
      messageCount: chat.messageCount || 0,
      subscribers: [this.currentUserId],
      members: [this.currentUserId],
    };

    if (chat.type === RoomType.ONE_TO_ONE) {
      newRoom.subscribers = chat.roomId.split('_').map((id) => parseFloat(id));
      newRoom.members = chat.roomId.split('_').map((id) => parseFloat(id));
    }

    this.chatService.startChat(this.eventId, newRoom)
      .then((room) => {
        this.chatService.updateMessageLog(this.currentUserId, newRoom.uid, newRoom.messageCount)
          .then(() => {
            this.selectedRoomId = chat.roomId;
            this.selectedRoomType = chat.type;
            this.selectedRoomName = chat.name;
            this.selectedRoomAutoJoin = chat.autoJoin;
            this.currentTab = 'conversation';
            this.setChatMinimized$.next(false);
            if (informDelegate) {
              this.publicChatService.delegateChatIsStarted(informDelegate);
            }
          });
      });
  }

  changeTab(tab: 'conversation' | 'my_chats' | 'all_chats'): void {
    this.cancelPreviousFiltering$.next();
    this.currentTab = tab;
    let observable;
    if (tab === 'my_chats') {
      observable = this.myRecentChats$;
    }
    if (tab === 'all_chats') {
      observable = this.allAvailablePeopleAndGroups$;
    }

    this.chatsInSelectedTab$ = observable?.pipe(
      switchMap(
        (chats: AvailableChat[]) => this.allChatsFilter$.pipe(
          map((chatFilter) => chats.filter((ch) => (chatFilter && tab === 'all_chats') ? ch.type === chatFilter : true)),
        )
      ),
      takeUntil(this.cancelPreviousFiltering$),
      shareReplay(1),
    );
    this.searchTerm = '';
    this.searchChats();
  }

  filterChats(tab: RoomType): void {
    this.allChatsFilter$.next(tab);
  }

  getRoomUsers(users: ChatUser[]): string {
    if (users.length < 4) {
      return users.map(user => user.name).join(', ');
    } else {
      return (users.slice(0, 2).map(user => user.name).join(', ')) + ', ' + (users.length - 2) + ' others';
    }
  }

  activateChat(): void {
    this.active$.next(true);
  }

  getTwoLetterName(roomName: string): string {
    if (!roomName) {
      return '';
    }
    const firstName = (roomName.split(' ').length ? roomName.split(' ')[0] : roomName[0]);
    const lastName = (roomName.split(' ').length > 1 ? roomName.split(' ')[1] : (roomName[1] || ''));
    return (firstName?.length ? firstName[0] : '') + (lastName?.length ? lastName[0] : '');
  }

  leaveGroup(publicGroupId: string) {
    this.chatService.leaveChat(this.eventId, publicGroupId, this.currentUserId).then(() => {
      this.changeTab('my_chats');
    });
  }

  searchChats() {
    this.loadMoreParticipants$.next({ page: 1, search_term: this.searchTerm });
  }

  loadMoreParticipants() {
    this.loadMoreParticipants$.next({ page: this.participantsPageIndex + 1 });
  }

  private getOneToOneGroupName(currentUserId: number, members: number[], chatUsers: ChatUser[]): string {
    const otherUserId = members.filter(m => m !== currentUserId)?.[0];
    if (otherUserId) {
      return chatUsers.find(cu => cu.userId === otherUserId)?.name;
    }
    return 'Unknown';
  }

  private getOneToOneGroupId(user1: number, user2: number): string {
    const max = user1 > user2 ? user1 : user2;
    const min = user1 < user2 ? user1 : user2;
    return min + '_' + max;
  }

  private getChatParticipantsObservable(): Observable<Delegate[]> {
    return this.allow1on1 ?
      this.loadMoreParticipants$.pipe(
        startWith({ page: 1, search_term: '' }),
        debounceTime(500),
        tap(() => {
          this.loadingService.start('searchChat');
          this.loadingParticipants = true;
        }),
        switchMap((params: { page: number, search_term: '' }) => {
          this.participantsPageIndex = params.page;
          const queryParams: any = {
            page: this.participantsPageIndex,
            per_page: this.PARTICIPANTS_LOAD,
            sort_by: 'first_name',
            sort_direction: 'asc',
          };
          if (params.search_term) {
            queryParams.search_term = params.search_term;
          }
          return this.getParticipantsRequest(queryParams)
            .pipe(
              map(res => {
                if (res?.meta?.pagination?.total >= (this.participantsPageIndex * this.PARTICIPANTS_LOAD)) {
                  this.loadMoreParticipantsVisible = true;
                } else {
                  this.loadMoreParticipantsVisible = false;
                }

                return res.data;
              }),
              takeUntil(this.cancelPreviousSubscriptions$),
              takeUntil(this.unsubscribe$),
              catchError((error) => of([])),
            );
        }),
        tap(() => {
          this.loadingService.stop('searchChat');
          this.loadingParticipants = false;
        }),
      )
      : of([]);
  }

  private getParticipantsRequest(queryParams) {
    if (this.chatType === 'delegate') {
      return this.delegateService.getConferenceChatDelegates(this.eventId, queryParams);
    } else if (this.chatType === 'admin' && this.organizationId) {
      return this.userService.getOrganizationAdministrators(this.organizationId, queryParams);
    } else {
      return of([]);
    }
  }

  private getAvailableChat(input: User | Delegate, allChatUsers, allMyRooms): AvailableChat {
    return {
      name: input.first_name + ' ' + input.last_name,
      description: '',
      pictureUrl: allChatUsers.find((cu) => cu.userId === input['user_id'])?.avatarUrl,
      type: RoomType.ONE_TO_ONE,
      roomId: this.getOneToOneGroupId(this.currentUserId, input['user_id']),
      messageCount:
        allMyRooms.find((myRoom) => myRoom.uid === this.getOneToOneGroupId(this.currentUserId, input['user_id']))?.messageCount || 0,
      hasActivatedChat: !!allChatUsers.find((cu) => cu.userId === input['user_id']),
      delegateInfo: {
        title: input['title'],
        email: input.email,
        website: input['website'],
        city: input['city'],
        country: input.country?.name,
        institution: input['contact_info']?.institution,
        jobTitle: input['contact_info']?.job_title
      }
    };
  }

  private filterChatsByTerm(delegate: AvailableChat, searchTerm: string): boolean {
    return [...Object.values(delegate?.delegateInfo || {}), delegate.name]?.map(s => s?.toLowerCase()).join(' ').includes(searchTerm);
  }

}

interface AvailableChat {
  roomId: string;
  name: string;
  type: RoomType;
  pictureUrl: string;
  description: string;
  hasActivatedChat: boolean;
  messageCount?: number;
  unreadCount?: number;
  autoJoin?: boolean;
  delegateInfo?: {
    title?: string;
    email?: string;
    website?: string;
    city?: string;
    country?: string;
    institution?: string;
    jobTitle?: string;
  };
}
