import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MaterialService } from '@navus/core/services/material.service';
import { Material } from '@navus/core/classes/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { EmbedCustomizeModalComponent } from '../embed-customize/embed-customize-modal.component';
import { UserService } from '@navus/core/services/user.service';
import { Subscription } from 'rxjs';
import { VideoPlayerPresetService } from '@navus/core/services/video-player-preset.service';
import { VideoPlayerPreset } from '@navus/core/classes/video-player-preset';

@Component({
  selector: 'nv-material-details-modal',
  templateUrl: './material-details-modal.component.html'
})
export class MaterialDetailsModalComponent implements OnInit, OnDestroy {

  @Input() conferenceId: number = null;
  @Input() materialId: number = null;
  @Input() organizationId: number = null;
  @Input() presentationId: number = null;
  materialForm: FormGroup;

  materialsImages: { image: string, src: string }[];

  material: Material = null;
  currentPage: number = 1;
  scale: number = 1;

  loadingMaterial = false;

  videoPlayerPresetsLoading = false;
  videoPlayerPresetsOptions: { id: string, name: string }[];
  selectedVideoPresetId: string;

  pathCopied = false;
  embedCopied = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private materialService: MaterialService,
    private toastService: ToastService,
    private modalService: ModalService,
    private activeModal: NgbActiveModal,
    private userService: UserService,
    private videoPlayerPresetService: VideoPlayerPresetService
  ) {
  }

  ngOnInit() {
    this.getMaterial();
    this.getVideoPlayerPresets();
    this.initForm();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.materialForm = this.formBuilder.group({
      title: ['', [Validators.required]],
    });
  }

  getMaterial() {
    if (this.materialId) {
      this.loadingMaterial = true;
      this.materialService
        .getConferenceMaterial(this.conferenceId, this.materialId)
        .subscribe(
          (response) => {
            this.loadingMaterial = false;
            this.material = response.data;
            this.selectedVideoPresetId = this.material.player_preset_id;
            this.materialForm.patchValue(response.data);
            if (this.material.type === 'main') {
              this.getMaterialImages();
            }
          },
          (error) => {
            this.loadingMaterial = false;
          }
        );
    }
  }

  getVideoPlayerPresets() {
    this.videoPlayerPresetsLoading = true;
    this.videoPlayerPresetService.getPlayerPresets(this.organizationId)
      .subscribe(
        (response) => {
          this.videoPlayerPresetsOptions = (response.data as VideoPlayerPreset[]).map((item) => ({ id: item.id, name: item.name }));
          this.videoPlayerPresetsLoading = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.videoPlayerPresetsLoading = false;
        }
      );
  }

  setDefaultPresetForMaterial() {
    this.videoPlayerPresetsLoading = true;
    this.videoPlayerPresetService.setDefaultPresetForMaterial(this.materialId, this.selectedVideoPresetId)
      .subscribe((response) => {
        this.videoPlayerPresetsLoading = false;
        this.toastService.success('Default video player preset set successfully', {});
      }, (error) => {
        this.modalService.error({ errors: error });
        this.videoPlayerPresetsLoading = false;
      });
  }

  save() {
    this.loadingMaterial = true;
    this.materialService
      .updateMaterial(this.materialId, this.materialForm.value)
      .subscribe(
        (response) => {
          this.activeModal.close();
          this.loadingMaterial = false;
        },
        (error) => {
          this.loadingMaterial = false;
        }
      );
  }

  copyPath() {
    let path = this.material.path;
    path = path.replace('.m3u8', '');
    path = path.replace('stream.mux.com', 'player.navus.io');
    navigator.clipboard.writeText(path);
    this.toastService.success('Video path copied to the clipboard');

    this.pathCopied = true;
    this.embedCopied = false;
    setTimeout(() => {
      this.pathCopied = false;
    }, 10 * 1000);
  }

  copyEmbed() {
    let path = this.material.path;
    path = path.replace('.m3u8', '');
    path = path.replace('stream.mux.com', 'player.navus.io');

    const modalRef = this.modalService.open(EmbedCustomizeModalComponent);
    modalRef.componentInstance.path = path;
    modalRef.result.then(
      (embed) => {
        navigator.clipboard.writeText(embed);
        this.toastService.success('Video embed code copied to the clipboard');

        this.embedCopied = true;
        this.pathCopied = false;
        setTimeout(() => {
          this.embedCopied = false;
        }, 10 * 1000);
      },
      () => {

      }
    );
  }

  close() {
    this.activeModal.dismiss();
  }

  setPage(page: number) {
    this.currentPage = page;
  }

  private getMaterialImages() {
    this.loadingMaterial = true;
    const currentUserSubscription = this.userService.currentUser
      .subscribe(
        (user) => {
          this.materialService.getMaterialImages(this.material.id)
            .subscribe(
              (response: any) => {
                this.loadingMaterial = false;
                this.materialsImages = response.images.map((image) => ({
                  image,
                  src: image
                }));
              },
              (error) => {
                this.loadingMaterial = false;
                this.modalService.error({ errors: error });
              }
            );
        },
        (error) => {
          this.loadingMaterial = false;
          this.modalService.error({ errors: error });
        }
      );
    this.subscriptions.push(currentUserSubscription);
  }

}
