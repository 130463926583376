<div [ngSwitch]="type" class="nv-form-item {{ errors.length > 0 ? 'nv-with-errors' : '' }}  {{ info ? 'nv-with-info' : '' }}">
    <label *ngSwitchCase="'textarea'"
        class="{{required ? 'nv-mandatory' : 'nv-not-mandatory'}}" >
        <span  *ngIf="required"
               aria-hidden="true"></span>
        <textarea
            #input
            nvInputDirective
            (input)= "onChangeValue()"
            (blur)= "displayError()"

            [(ngModel)]="value"
            [rows]= "rows"
            [cols]= "cols"
            [readOnly]="readOnly">
        </textarea>
        <span aria-label="label-title" class="label-title">{{ label }}</span>
    </label>
    <label *ngSwitchCase="'card'"
           class="{{required ? 'nv-mandatory' : 'nv-not-mandatory'}}" >
        <span
            *ngIf="required"
            aria-hidden="true"></span>
        <input
            #input
            nvInputDirective
            (input)= "onChangeValue()"
            (blur)= "displayError()"

            [(ngModel)]="value"
            [placeholder]="label"
            [type]= "type"
            [autofocus]= "autoFocus"
            [autocomplete]= "autoComplete"
            [max]="max"
            [min]="min"
            [disabled]="disabled"
            [readOnly]="readOnly"
            ccNumber
        >
        <span aria-label="label-title" class="label-title">{{ label }}</span>
        <span class="suffix" *ngIf="!!suffix">{{ suffix }}</span>
    </label>
    <label *ngSwitchDefault
           class="{{required ? 'nv-mandatory' : 'nv-not-mandatory'}}" >
        <span
            *ngIf="required"
            aria-hidden="true"></span>
        <input
            #input
            nvInputDirective
            (input)= "onChangeValue()"
            (blur)= "displayError()"

            [(ngModel)]="value"
            [placeholder]="label"
            [type]= "type"
            [autofocus]= "autoFocus"
            [autocomplete]= "autoComplete"
            [max]="max"
            [min]="min"
            [disabled]="disabled"
            [readOnly]="readOnly">
        <span aria-label="label-title" class="label-title">{{ label }}</span>
        <span class="suffix" *ngIf="!!suffix">{{ suffix }}</span>
    </label>

    <div class="nv-input-password-toggle"
         *ngIf="showPassword"
         (click)="togglePassword()"
         [ngClass]="hidePassword ? 'hide' : 'show'"></div>

    <div class="nv-input-info-box" *ngIf="info">
        <span (click)="toggleInfo()" [class.on]="showInfo"></span>
        <div class="nv-input-info-content"><div>{{ info }}</div></div>
    </div>

    <div *ngIf="errors.length > 0" class="nv-input-error-box">
        <div *ngIf="errors.length === 1" class="nv-input-error single">
            <div [innerHTML]="errorMessages[errors[0]] || errors[0]"></div>
        </div>
        <div *ngIf="errors.length > 1" class="nv-input-error multi">
            <p (click)="toggleErrors()">
                <span>You have {{ errors.length }} errors</span>
            </p>
            <div *ngIf="showErrors">
                <ul>
                    <li *ngFor="let error of errors" [innerHTML]="errorMessages[error] || error"></li>
                </ul>
            </div>
        </div>
    </div><!-- /nv-input-error-box -->
</div>


