import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { HeroSectionConfig } from './hero-section.config';
import { BaseSectionComponent } from '../_base/base-section.component';
import { WebsiteService } from '@navus/core/services/website.service';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { WebsiteControlService } from '../../services/website-control.service';

@Component({
  selector: 'nv-hero-section',
  templateUrl: './hero-section.component.html'
})
export class HeroSectionComponent extends BaseSectionComponent implements OnInit, OnDestroy {
  @Input() data: WebsitePageSection;
  configuration: HeroSectionConfig = new HeroSectionConfig();
  showButton: boolean = false;
  buttonUrl: string = '';
  hover: boolean = false;

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService,
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );
  }

  ngOnInit() {
    this.configuration = new HeroSectionConfig(this.data.configuration);

    this.showButton = !!(this.configuration.actionButtonUrl &&
                      this.configuration.actionButtonUrl !== '' &&
                      this.configuration.actionButtonText);
    this.buttonUrl = this.configuration.actionButtonUrl.startsWith('http') ?
                     this.configuration.actionButtonUrl : 'http://' + this.configuration.actionButtonUrl;
  }

  ngOnDestroy() {

  }

}

