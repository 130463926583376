import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { User } from "@navus/core/classes/user";
import { BadgeService } from "@navus/core/services/badge.service";
import { UserService } from "@navus/core/services/user.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'main [nv-website-profile-badges]',
  templateUrl: './badges.component.html'
})
export class ProfileBadgesComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-profile-settings-body') get classView() { return !this.editMode; }
  @HostBinding('class.nv-profile-settings-detail-body') get classEdit() { return this.editMode; }
  user: User;
  conferenceId: number;

  badges: any[] = [];

  editMode: boolean;
  loadingBadges: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private badgeService: BadgeService,
  ) { }

  ngOnInit() {
    this.conferenceId = this.route.parent?.parent?.parent?.snapshot.data.website?.active_conference_id;

    this.getMyBadges();

    const userSubscription = this.userService.currentUser
      .subscribe((user: User) => {
        if (user) {
          this.user = user;
        }
      });
    this.subscriptions.push(userSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getMyBadges() {
    this.loadingBadges = true;
    this.badgeService
      .getConferenceBadgeLevelsMine(this.conferenceId)
      .subscribe(
        (response) => {
          /* 

          */
          const badges = response.data.map((badge: any) => {
            const badgeLevels = badge.badge_levels.sort((a, b) => a.order - b.order);

            let currentLevel = null;
            for (let i = 0; i < badgeLevels.length; i++) {
              if (badgeLevels[i].badge_level_delegates.length > 0 && badgeLevels[i].badge_level_delegates[0].completed_at) {
                currentLevel = badgeLevels[i];
              }
            }

            let nextLevel = null;
            for (let i = badgeLevels.length - 1; i >= 0; i--) {
              if (badgeLevels[i].badge_level_delegates.length === 0 || !badgeLevels[i].badge_level_delegates[0].completed_at) {
                nextLevel = badgeLevels[i];
              }
            }

            // show next level image

            const myBadge = {
              name: badge.name,
              levelImage: null,
              levelName: '',
              description: '',
              currentValue: 0,
              goalValue: 0,
              completionPercentage: 0,
              completedLevelName: null,
              completedAt: null,
            }

            if (currentLevel) {
              const delegate = currentLevel.badge_level_delegates[0];

              myBadge.levelImage = currentLevel.image_url;
              myBadge.levelName = currentLevel.name;
              myBadge.description = currentLevel.success_message;
              myBadge.currentValue = delegate.current;
              myBadge.goalValue = delegate.goal;
              
              myBadge.completedAt = delegate.completed_at;
              myBadge.completedLevelName = currentLevel.name;
            }

            if (nextLevel) {
              const delegate = nextLevel.badge_level_delegates[0];

              if (delegate) {
                myBadge.currentValue = delegate.current;
                myBadge.goalValue = delegate.goal;
              }
              myBadge.levelImage = nextLevel.image_url;
              myBadge.levelName = nextLevel.name;
              myBadge.description = nextLevel.condition_description;
            } 

            if (myBadge.currentValue > myBadge.goalValue) {
              myBadge.currentValue = myBadge.goalValue;
            }
            myBadge.completionPercentage = myBadge.currentValue / myBadge.goalValue * 100; 

            return myBadge;
          });

          this.badges = badges;
          this.loadingBadges = false;
        },
        () => {
          this.loadingBadges = false;
        }
      );
  }

}