<button
    class="nv-btn {{ class }} {{ size }} {{ color }}"
    [attr.data-icon-pl]="iconPl"
    [attr.data-icon]="icon"
    [disabled]="disabled"
    [type]="type"
    [ngClass]="{
    'nv-loading-state': loading,
    'nv-disabled-state': disabled,
    'icon': onlyIcon
    }"
>
    <span><ng-content></ng-content></span>
</button>
