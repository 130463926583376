<div class="onboardig-page-wrap">
    <header class="onboarding-header">
        <div class="oh-logo">
            <a href><img src="../../assets/logo.svg" alt="NAVUS on-boarding"></a>
        </div><!-- /.oh-logo -->
        <div class="oh-step-label">
            <h2>SETUP YOUR NAVUS organizer Panel</h2>
        </div><!-- /.oh-step-label -->
        <div class="oh-step">
            <p>1 of 2</p>
        </div><!-- /.oh-step -->
        <span class="oh-progress-bar"><em style="width: 42%;"></em></span>
    </header><!-- /.onboarding-header -->

    <!-- STEP 1 -->
    <section class="nv-section onboarding-body guttered" style="display: none;"> <!-- < step 1 -->
        <div class="nv-port">
            <div class="nv-row large-spaced v-middle flow-center">
                    <div class="nv-column desk-6 tab-8 phab-12">
                        <div class="nv-column-content">
                            <div class="nv-onboarding-chunk">
                                <header>
                                    <h1><em>1.</em>Create an Organization</h1>
                                    <h4>Manage events, event apps & websites, campaigns, sponsors, articles & files for organization.</h4>
                                </header>
                                <div class="nv-feature-placeholder organization"></div><!-- /.nv-feature-placeholder -->
                                <form action="">
                                    <div class="nv-row flow-center spaced-bottom">
                                        <div class="nv-column desk-8">
                                            <div class="nv-column-content">
                                                <div class="nv-form-item">
                                                    <label>
                                                        <input type="text" placeholder="Organization Name">
                                                        <span class="label-title">Organization Name</span>
                                                    </label>
                                                </div><!-- /nv-form-item -->
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                    </div><!-- /.nv-row -->
                                    <div class="nv-row flow-center">
                                        <div class="nv-column desk-initial">
                                            <div class="nv-column-content">
                                                <button type="button" class="nv-btn filled secondary medium primary">Create</button>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                    </div><!-- /.nv-row -->
                                </form>
                            </div><!-- /.nv-onboarding-chunk -->
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.onboarding-body -->

    <!-- STEP 2 -->
    <section class="nv-section onboarding-body guttered" style="display: none;"> <!-- < step 2 -->
        <div class="nv-port">
            <div class="nv-row large-spaced v-middle flow-center">
                <div class="nv-column desk-6 lap-8 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-onboarding-chunk">
                            <header>
                                <h1><em>2.</em>What would you like to do in Navus Organizer Panel?</h1>
                                <h4>This is just to get started. You can always do more later.</h4>
                            </header>
                            <form action="">
                                <div class="nv-row flow-center spaced-bottom">
                                    <div class="nv-column desk-12">
                                        <div class="nv-column-content">
                                            <div class="nv-onboarding-features-select">
                                                <div class="ofs-item">
                                                    <div class="ofs-pane">
                                                        <input type="checkbox" name="">
                                                        <div class="ofs-cb-visual"></div>
                                                        <div class="ofs-pane-visual"></div>
                                                        <div class="ofs-icon" data-icon-pl="&#xe619;"></div>
                                                        <h4>Manage<br>events</h4>
                                                    </div><!-- /.ofs-pane -->
                                                </div><!-- /.ofs-item -->

                                                <div class="ofs-item">
                                                    <div class="ofs-pane">
                                                        <input type="checkbox" name="">
                                                        <div class="ofs-cb-visual"></div>
                                                        <div class="ofs-pane-visual"></div>
                                                        <div class="ofs-icon" data-icon-pl="&#xe011;"></div>
                                                        <h4>Manage event<br>websites</h4>
                                                    </div><!-- /.ofs-pane -->
                                                </div><!-- /.ofs-item -->

                                                <div class="ofs-item">
                                                    <div class="ofs-pane">
                                                        <input type="checkbox" name="">
                                                        <div class="ofs-cb-visual"></div>
                                                        <div class="ofs-pane-visual"></div>
                                                        <div class="ofs-icon" data-icon-pl="&#xe010;"></div>
                                                        <h4>Manage articles<br>&amp; files</h4>
                                                    </div><!-- /.ofs-pane -->
                                                </div><!-- /.ofs-item -->

                                                <div class="ofs-item">
                                                    <div class="ofs-pane">
                                                        <input type="checkbox" name="">
                                                        <div class="ofs-cb-visual"></div>
                                                        <div class="ofs-pane-visual"></div>
                                                        <div class="ofs-icon" data-icon-pl="&#xe040;"></div>
                                                        <h4>Manage articles<br>&amp; files</h4>
                                                    </div><!-- /.ofs-pane -->
                                                </div><!-- /.ofs-item -->

                                                <div class="ofs-item">
                                                    <div class="ofs-pane">
                                                        <input type="checkbox" name="">
                                                        <div class="ofs-cb-visual"></div>
                                                        <div class="ofs-pane-visual"></div>
                                                        <div class="ofs-icon" data-icon-pl="&#xe906;"></div>
                                                        <h4>Manage<br>sponsors</h4>
                                                    </div><!-- /.ofs-pane -->
                                                </div><!-- /.ofs-item -->
                                            </div><!-- /.nv-onboarding-features-select -->
                                            <div class="nv-dialogue-bar">
                                                <a href="" class="bb-side-label">Skip for now</a>
                                                <div class="db-btns">
                                                    <a href="" class="nv-btn filled neutral medium">Back</a>
                                                    <a href="" class="nv-btn filled primary">Next</a>
                                                </div><!-- /.db-btns -->
                                            </div><!-- /.nv-dialogue-bar -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                </div><!-- /.nv-row -->
                            </form>
                        </div><!-- /.nv-onboarding-chunk -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.onboarding-body -->

    <!-- STEP 2 ALT -->
    <section class="nv-section onboarding-body guttered"> <!-- < step 2 alt -->
        <div class="nv-port">
            <div class="nv-row large-spaced-top">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-onboarding-chunk">
                            <header class="centered">
                                <h1><em>2.</em>Choose a Plan and Start your 30-Days Free Trial</h1>
                                <h4>Select the best plan for your organization needs and get started.</h4>
                            </header>
                            <div class="nv-boarding-switch">
                                <div class="bs-choice">
                                    <span class="on">Pay Monthly</span>
                                    <label class="nv-toggle-switch">
                                        <input type="checkbox" name="">
                                        <span class="ts-visual"></span>
                                    </label><!-- /.nv-toggle-switch -->
                                    <span>Pay Annually</span>
                                </div><!-- /.bs-choice -->
                                <h5>Save up to 10% with an annual subscription</h5>
                            </div><!-- /.nv-boarding-switch -->

                            <div class="nv-plan-choice-plates">
                                <div class="pcp-item">
                                    <div class="pcp-pane">
                                        <div class="pcp-upper">
                                            <h2>Starter</h2>
                                            <ul class="pcp-capacity">
                                                <li>150 delegates</li>
                                                <li>1 event</li>
                                            </ul><!-- /.pcp-capacity -->
                                            <div class="nv-top-features">
                                                <h4>TOP FEATURES</h4>
                                                <ul>
                                                    <li>Full E-Configurator</li>
                                                    <li>1 Hour Event Maker Consultancy</li>
                                                </ul>
                                            </div><!-- /.nv-top-features -->
                                            <div class="pcp-price">
                                                <h3>&euro; 9.90<span>/ mo</span></h3>
                                                <span>billed monthly</span>
                                            </div><!-- /.pcp-price -->
                                        </div><!-- /.pcp-upper -->
                                        <a class="nv-btn small fw filled primary">Apply Plan</a>
                                    </div><!-- /.pcp-pane -->
                                </div><!-- /.pcp-item -->

                                <div class="pcp-item">
                                    <div class="pcp-pane">
                                        <div class="pcp-upper">
                                            <span class="pcp-highlight">MOST POPULAR</span>
                                            <h2>Basic</h2>
                                            <ul class="pcp-capacity">
                                                <li>500 delegates</li>
                                                <li>3 event</li>
                                            </ul><!-- /.pcp-capacity -->
                                            <div class="nv-top-features">
                                                <h4>TOP FEATURES</h4>
                                                <ul>
                                                    <li>Full E-Configurator</li>
                                                    <li>2 Hour Event Maker Consultancy</li>
                                                </ul>
                                            </div><!-- /.nv-top-features -->
                                            <div class="pcp-price">
                                                <h3>&euro; 29.90<span>/ mo</span></h3>
                                                <span>billed monthly</span>
                                            </div><!-- /.pcp-price -->
                                        </div><!-- /.pcp-upper -->
                                        <a class="nv-btn small fw filled primary">Apply Plan</a>
                                    </div><!-- /.pcp-pane -->
                                </div><!-- /.pcp-item -->

                                <div class="pcp-item">
                                    <div class="pcp-pane">
                                        <div class="pcp-upper">
                                            <h2>Advanced</h2>
                                            <ul class="pcp-capacity">
                                                <li>1000 delegates</li>
                                                <li>5 event</li>
                                            </ul><!-- /.pcp-capacity -->
                                            <div class="nv-top-features">
                                                <h4>TOP FEATURES</h4>
                                                <ul>
                                                    <li>Full E-Configurator</li>
                                                    <li>4 Hour Event Maker Consultancy</li>
                                                    <li>10% on ad sales</li>
                                                </ul>
                                            </div><!-- /.nv-top-features -->
                                            <div class="pcp-price">
                                                <h3>&euro; 99<span>/ mo</span></h3>
                                                <span>billed monthly</span>
                                            </div><!-- /.pcp-price -->
                                        </div><!-- /.pcp-upper -->
                                        <a class="nv-btn small fw filled primary">Apply Plan</a>
                                    </div><!-- /.pcp-pane -->
                                </div><!-- /.pcp-item -->

                                <div class="pcp-item">
                                    <div class="pcp-pane">
                                        <div class="pcp-upper">
                                            <h2>Performer</h2>
                                            <ul class="pcp-capacity">
                                                <li>2500 delegates</li>
                                                <li>10 event</li>
                                            </ul><!-- /.pcp-capacity -->
                                            <div class="nv-top-features">
                                                <h4>TOP FEATURES</h4>
                                                <ul>
                                                    <li>Full E-Configurator</li>
                                                    <li>6 Hour Event Maker Consultancy</li>
                                                    <li>15% on ad sales</li>
                                                </ul>
                                            </div><!-- /.nv-top-features -->
                                            <div class="pcp-price">
                                                <h3>&euro; 199<span>/ mo</span></h3>
                                                <span>billed monthly</span>
                                            </div><!-- /.pcp-price -->
                                        </div><!-- /.pcp-upper -->
                                        <a class="nv-btn small fw filled primary">Apply Plan</a>
                                    </div><!-- /.pcp-pane -->
                                </div><!-- /.pcp-item -->

                                <div class="pcp-item">
                                    <div class="pcp-pane">
                                        <div class="pcp-upper">
                                            <h2>Professional</h2>
                                            <ul class="pcp-capacity">
                                                <li>5000 delegates</li>
                                                <li>20 event</li>
                                            </ul><!-- /.pcp-capacity -->
                                            <div class="nv-top-features">
                                                <h4>TOP FEATURES</h4>
                                                <ul>
                                                    <li>Full E-Configurator</li>
                                                    <li>8 Hour Event Maker Consultancy</li>
                                                    <li>20% on ad sales</li>
                                                    <li>Customised quote for more than 5000 delegates or events</li>
                                                </ul>
                                            </div><!-- /.nv-top-features -->
                                            <div class="pcp-price">
                                                <h3>&euro; 299<span>/ mo</span></h3>
                                                <span>billed monthly</span>
                                            </div><!-- /.pcp-price -->
                                        </div><!-- /.pcp-upper -->
                                        <a class="nv-btn small fw filled primary">Apply Plan</a>
                                    </div><!-- /.pcp-pane -->
                                </div><!-- /.pcp-item -->
                            </div><!-- /.nv-plan-choice-platec -->
                        </div><!-- /.nv-onboarding-chunk -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row small-spaced-top gap-16">
                <div class="nv-column desk-2-4 tab-none">
                    <div class="nv-column-content">
                        <div class="nv-top-features">
                            <h4>TOP FEATURES</h4>
                            <ul>
                                <li>Full E-Configurator</li>
                                <li>1 Hour Event Maker Consultancy</li>
                            </ul>
                        </div><!-- /.nv-top-features -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->

                <div class="nv-column desk-2-4 tab-none">
                    <div class="nv-column-content">
                        <div class="nv-top-features">
                            <h4>TOP FEATURES</h4>
                            <ul>
                                <li>Full E-Configurator</li>
                                <li>2 Hour Event Maker Consultancy</li>
                            </ul>
                        </div><!-- /.nv-top-features -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->

                <div class="nv-column desk-2-4 tab-none">
                    <div class="nv-column-content">
                        <div class="nv-top-features">
                            <h4>TOP FEATURES</h4>
                            <ul>
                                <li>Full E-Configurator</li>
                                <li>4 Hour Event Maker Consultancy</li>
                                <li>10% on ad sales</li>
                            </ul>
                        </div><!-- /.nv-top-features -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->

                <div class="nv-column desk-2-4 tab-none">
                    <div class="nv-column-content">
                        <div class="nv-top-features">
                            <h4>TOP FEATURES</h4>
                            <ul>
                                <li>Full E-Configurator</li>
                                <li>6 Hour Event Maker Consultancy</li>
                                <li>15% on ad sales</li>
                            </ul>
                        </div><!-- /.nv-top-features -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->

                <div class="nv-column desk-2-4 tab-none">
                    <div class="nv-column-content">
                        <div class="nv-top-features">
                            <h4>TOP FEATURES</h4>
                            <ul>
                                <li>Full E-Configurator</li>
                                <li>8 Hour Event Maker Consultancy</li>
                                <li>20% on ad sales</li>
                                <li>Customised quote for more than 5000 delegates or events</li>
                            </ul>
                        </div><!-- /.nv-top-features -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-divider desk-large"></div>
        </div><!-- /.nv-port -->
    </section><!-- /.onboarding-body -->
</div><!-- /.onboardig-page-wrap -->
