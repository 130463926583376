import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ToastService } from '@navus/ui/toast/toast.service';


@Component({
    selector: 'app-navus-ui-page',
    templateUrl: './navus-ui.component.html'
})

export class NavusUiComponent implements OnInit {

    ngModelTest = null;

    testForm: FormGroup;
    testEditor: FormGroup;
    fileTest: FormGroup;
    fileTest2: any;
    progress = 0;
    progressBarShow = false;
    colorTest = '' ;
    carouselDataTest = [
        {
            linkUrl: 'slide1',
            imageUrl: 'https://service.e-materials.com/storage/resources/grupa_tnt/kid/kidney.jpg'
        },
        {
            linkUrl: 'slide2',
            imageUrl: ''
        },
        {
            linkUrl: 'slide3',
            imageUrl: 'https://service.e-materials.com/storage/resources/grupa_tnt/kid/kidney.jpg'
        },
        {
            linkUrl: 'slide4',
            imageUrl: ''
        },
        {
            linkUrl: 'slide3',
            imageUrl: 'https://service.e-materials.com/storage/resources/grupa_tnt/kid/kidney.jpg'
        },
        {
            linkUrl: 'slide4',
            imageUrl: ''
        },
        {
            linkUrl: 'slide3',
            imageUrl: 'https://service.e-materials.com/storage/resources/grupa_tnt/kid/kidney.jpg'
        },
        {
            linkUrl: 'slide4',
            imageUrl: ''
        },

    ];

    dateTest: any;

    dropSelected = [
        {id: 1, name: 'option1'},
        {id: 2, name: 'option2'}
    ];

    dropTest = [
        {id: 1, name: 'option1'},
        {id: 2, name: 'option2'},
        {id: 3, name: 'option3'}
    ];
    dropTestNew = [];
    fix;

    constructor(
        public formBuilder: FormBuilder,
        public modalService: ModalService,
        public toastService: ToastService
    ) { }

    ngOnInit() {
        this.testForm = this.formBuilder.group({
            input1: ['', [Validators.required]],
            input2: ['', Validators.required],
            textarea: ['', Validators.required],
            checkbox: [false],
            radio: [''],
            select: [''],
            switch: ['']
        });

        this.testEditor = this.formBuilder.group({
           test: ['']
        });
        this.dropTestNew = this.dropTest;

        this.fileTest = this.formBuilder.group({
            file: ['']
        });
    }

    openNewResourceModal() {
        this.modalService.openResource(55, true)
          .then(
            (data) => {
                console.log(data);
            },
            () => {}
          );
    }

    openNewResourceModalSingle() {
        this.modalService.openResource(55)
          .then(
            (data) => {
                console.log(data);
            },
            () => {}
          );
    }

    dateTest2(event) {
        // console.log(event);
    }

    dateTestF() {
        console.log(this.dateTest);
    }

    testBtn() {
        console.log('TEST!!!!');
    }

    submitForm(form) {
        console.log(form.value);
        this.modalService.alert({
            title: '',
            body: 'Form is submitted',
            buttons: [
                {
                    text: 'Cancel',
                    handler: () => {}
                }
            ],
            size: 'medium'
        });
    }

    buttonTest() {
        this.modalService.alert({
            title: '',
            body: 'Navus button test',
            buttons: [
                {
                    text: 'Cancel',
                    handler: () => { }
                }
            ],
            size: 'large'
        });
    }

    openDefaultModal() {
        this.modalService.defaultModal({
            title: 'Hi',
            body: 'Navus default modal',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ],
            size: 'small',
        });
    }

    openAlertModal() {
        this.modalService.alert({
            title: 'Hi',
            body: 'Navus alert modal',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ],
            size: 'small'
        });
    }

    openAlertModalWithoutTitle() {
        this.modalService.alert({
            body: 'Navus alert modal',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ],
            size: 'small'
        });
    }

    openConfirmModal() {
        this.modalService.confirm({
            title: 'Hi',
            body: 'Navus confirm modal',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ],
            size: 'small',
        });
    }

    openErrorModal() {
        this.modalService.error({
            title: 'Hi',
            body: 'Navus error modal',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ],
            size: 'large'
        });
    }

    openToastSuccess() {
        this.toastService.success('Success', {
            disableTimeOut: true,
            positionClass: 'top-right',
            closeButton: true
        });
    }

    openToastError() {
        this.toastService.error('Error', {
            disableTimeOut: false,
            timeOut: 3000,
            positionClass: 'top-left',
            closeButton: true
        });
    }

    openToastWarning() {
        this.toastService.warning('Warning', {
            disableTimeOut: true,
            positionClass: 'bottom-left',
            closeButton: true
        });
    }

    openToastInfo() {
        this.toastService.info('Info', {
            disableTimeOut: false,
            timeOut: 3000,
            positionClass: 'center',
            closeButton: true
        });
    }

    startProgressBar() {
        this.progressBarShow = true;
      const progressTestInterval = setInterval(() => {
          if (this.progress === 100) {
              this.progress = 0;
              this.progressBarShow = false;
              clearInterval(progressTestInterval);
          } else {
              this.progress ++;
          }
        }, 200 );
    }

    paginationCurrentPage(event) {
        console.log(event);
    }

    colorPickerModelTest(event) {
        this.colorTest = event;
    }

    switchChange(event) {
        console.log(event);
    }

    dropTestSearch(event) {
        if (!event) {this.dropTestNew = this.dropTest; return; }
        const searchDropOptions = [];
        for (const item of this.dropTest) {
            if (item.name.includes(event)) {
                searchDropOptions.push(item);
            }
        }
        this.dropTestNew = searchDropOptions;
    }

    dropAddTest(event) {
        console.log(event);
    }

    fileTestFun(event) {
        console.log(this.fileTest.value, 'FORM');
        console.log(this.fileTest2, 'NgModel');
    }

    testAccordion(event) {
        console.log(event._results);
    }

    testGetAccordion(event) {
        console.log(event);
        event.done = true;
    }

}
