<div class="nv-live-suite-interactions-pane qa">
    <!-- Option with tools -->
    <div class="nv-live-suite-interactions-toolbar nv-speaker-qa-toolbar">
        <h4>Q&A</h4>
        <div class="nv-interactions-tools">
            <div class="nv-chat-interaction-filters">
                <nv-select
                    [options]="availableBlocks"
                    [(ngModel)]="_blockId"
                    [label]="'Session'"
                    [canEmpty]="false">
                </nv-select>
            </div>
            <div class="nv-chat-interaction-filters">
                <nv-select
                    [options]="statusOptions"
                    [(ngModel)]="approveStatus"
                    [label]="'Status'"
                    [canEmpty]="false">
                </nv-select>
            </div>
        </div>
    </div>
    <ng-container *ngIf="_blockId">
        <nv-chat
            [eventId]="conferenceId"
            [roomId]="'block_' + _blockId"
            [currentUserId]="currentUserId"
            [replies]="true"
            [canReply]="true"
            [approval]="true"
            [canApprove]="true"
            [approveStatus]="approveStatus"
            [usecase]="'Q&A'"
            (userClicked)="userDetails($event)"
        ></nv-chat>
    </ng-container>
    <div *ngIf="!_blockId" style="display: flex; justify-content: center; align-items: center; height: 100%;">
        <span>Session not selected</span>
    </div>
</div>
