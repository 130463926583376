import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Delegate } from '@navus/core/classes/delegate';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ConferenceService } from '@navus/core/services/conference.service';
import { TicketTypeCodeModalComponent } from '../../modals/ticket-type-code/ticket-type-code-modal.component';

@Component({
  selector: 'main [nv-ticket-type-details-page]',
  templateUrl: './ticket-type-details-page.component.html'
})
export class TicketTypeDetailsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  ticketTypeId: string;
  ticketType: any;
  ticketTypeForm: FormGroup;
  totalTickets: number = 0;

  ticketTypeCodes: any[] = [];

  loadingTicketType: boolean = true;
  savingTicketType: boolean = false;
  unsavedChanges: boolean = false;

  subscriptions: Subscription[] = [];

  currencyOptions = [
    { id: 'eur', name: 'Euro' },
    { id: 'usd', name: 'US Dollars' },
    { id: 'aud', name: 'Australian Dollars' },
  ]

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private conferenceService: ConferenceService,
    private toastService: ToastService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.initForm();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.ticketTypeId = data.ticketTypeId;
        this.getTicketType();
        this.getTicketTypeCodes();
      }
    );
    this.subscriptions.push(subscription);

    const changeSubscription = this.ticketTypeForm.valueChanges.subscribe(
      () => {
        this.unsavedChanges = true;
      }
    );
    this.subscriptions.push(changeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.ticketTypeForm = this.formBuilder.group({
      title: [''],
      description: [''],
      capacity: [''],
      amount: [''],
      currency: [''],
      allow_codes: [false]
    });
    this.unsavedChanges = false;
  }

  getTicketType() {
    if (this.ticketTypeId) {
      this.loadingTicketType = true;
      this.conferenceService
        .getConferenceTicketType(this.conferenceId, this.ticketTypeId)
        .subscribe(
          (response) => {
            this.ticketType = response.data;
            this.totalTickets = response.meta.tickets_count;
            this.ticketTypeForm.patchValue(this.ticketType);
            this.loadingTicketType = false;
            setTimeout(() => { this.unsavedChanges = false; }, 500);
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingTicketType = false;
          }
        );
    } else {
      this.ticketType = new Delegate();
      this.loadingTicketType = false;
    }
  }

  getTicketTypeCodes() {
    if (!this.ticketTypeId) { return; }

    this.conferenceService
        .getConferenceTicketTypeCodes(this.conferenceId, this.ticketTypeId)
        .subscribe(
          (response) => {
            this.ticketTypeCodes = response.data;
          },
          (error) => {
            this.modalService.error({ errors: error });
          }
        );
  }

  addTicketTypeCode() {
    const modalRef = this.modalService.open(TicketTypeCodeModalComponent);
    modalRef.componentInstance.conferenceId = this.conferenceId;
    modalRef.componentInstance.ticketTypeId = this.ticketTypeId;
    modalRef.result.then(
      () => {
        this.getTicketTypeCodes();
      },
      () => {}
    );
  }

  editTicketTypeCode(ticketTypeCode: any) {
    const modalRef = this.modalService.open(TicketTypeCodeModalComponent);
    modalRef.componentInstance.conferenceId = this.conferenceId;
    modalRef.componentInstance.ticketTypeId = this.ticketTypeId;
    modalRef.componentInstance.ticketTypeCodeId = ticketTypeCode.id;
    modalRef.result.then(
      () => {
        this.getTicketTypeCodes();
      },
      () => {}
    );
  }

  save() {
    if (this.ticketTypeForm.invalid) { return; }

    this.savingTicketType = true;
    if (this.ticketTypeId) {
      this.conferenceService
        .updateConferenceTicketType(this.conferenceId, this.ticketTypeId, this.ticketTypeForm.value)
        .subscribe(
          (response) => {
            this.ticketType = response['data'];
            this.toastService.success('Ticket type successfully saved.', {});
            this.unsavedChanges = false;
            this.savingTicketType = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingTicketType = false;
          }
        );
    } else {
      this.conferenceService
        .createConferenceTicketType(this.conferenceId, this.ticketTypeForm.value)
        .subscribe(
          (response) => {
            this.ticketType = response['data'];
            this.toastService.success('Ticket type successfully created.', {});
            this.router.navigate([this.ticketType.id], { relativeTo: this.route });
            this.savingTicketType = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingTicketType = false;
          }
        );
    }
  }

  close() {
    if (this.unsavedChanges) {
      this.modalService.defaultModal({
        title: 'Unsaved changes',
        body: 'Are you sure you want to leave the page without saving changes?',
        size: 'small',
        buttons: [
          {
            text: 'Stay',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Leave',
            color: 'primary',
            handler: () => {
              if (this.ticketTypeId) {
                this.router.navigate(['../..', 'ticket-types'], { relativeTo: this.route });
              } else {
                this.router.navigate(['..', 'ticket-types'], { relativeTo: this.route });
              }
            }
          }
        ]
      });
    } else {
      if (this.ticketTypeId) {
        this.router.navigate(['../..', 'ticket-types'], { relativeTo: this.route });
      } else {
        this.router.navigate(['..', 'ticket-types'], { relativeTo: this.route });
      }
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Ticket Type Deletion',
      body: 'Are you sure you want to delete this ticket type?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.conferenceService
              .deleteConferenceTicketType(this.conferenceId, this.ticketTypeId)
              .subscribe(
                () => {
                  this.close();
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

}
