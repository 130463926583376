import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class CountryService {

  constructor(
    private apiService: ApiService
  ) { }

  getCountries(params: any = {}) {
    return this.apiService.get(`countries`, params);
  }

}
