<div class="nv-config-control-bar">
  <div class="config-title-n-tools">
      <div class="tnt-left">
          <h1 translate>Badges</h1>
      </div><!-- /.tnt-left -->
      <div class="tnt-right">
          <div class="nv-btn-box flow-end gap-8">
              <nv-button color="primary" iconPl="&#xe095;" (click)="add()">Add badge</nv-button>
          </div><!-- /.nv-btn-box flow-end gap-6 -->
      </div><!-- /.tnt-right -->
  </div><!-- /.config-title-n-tools -->
  <div class="nv-port spaced-bottom">
      <div class="nv-line-tabs guttered">
          <nav class="nv-tabs-nav vertical-break-tab">
              <ul>
                  <li>
                      <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'badges']">Leaderboard</a>
                  </li>
                  <li>
                      <a class="active">Badges</a>
                  </li>
                  <li>
                      <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'badges', 'instructions']">Instructions</a>
                  </li>
              </ul>
          </nav>
      </div>
  </div>
  <form class="config-search-bar-w-filters" [formGroup]="formFilter" autocomplete="off">
      <div class="sbwf-port">
          <input (change)="getBadges()"
              type="text"
              placeholder="Search..."
              formControlName="search_term">
          <span class="sbwf-outline"></span>
      </div><!-- /.sbwf-port -->
  </form><!-- /.config-search-bar-w-filters -->
  <ng-container *ngIf="false">
      <div class="tnt-right" style="padding-left: 8px;">
          <div class="nv-btn-box flow-end gap-8">

          </div><!-- /.nv-btn-box flow-end gap-6 -->
      </div><!-- /.tnt-right -->
      <div *ngIf="false" class="tnt-right" style="padding-left: 8px;">
          <div class="nv-btn-box flow-end gap-8">
              <nv-button color="primary" iconPl="&#xe095;" (click)="fileSelect.click()">Import XLS</nv-button>
              <input #fileSelect type="file" ng2FileSelect [uploader]="uploader" [hidden]="true"
                  accept="application/vnd.ms-excel">
          </div><!-- /.nv-btn-box flow-end gap-6 -->
      </div><!-- /.tnt-right -->            
  </ng-container>
</div>
<div class="nv-config-content-port" nvInfiniteScroll (scrolled)="getBadges(true)">
  <div class="nv-global-loading on" [class.on]="badgesLoading">
      <div class="nv-spinner"></div>
  </div><!-- /.nv-global-loading -->
  <section class="nv-section">
      <div class="nv-port">
          <div class="nv-row config-plates">
              <div class="nv-column v-middle desk-12">
                  <div class="nv-column-content">
                      <div *ngIf="pagination.total === 0">
                          <div class="nv-empty-state">
                              <div class="img">
                                  <img src="../../../../assets/empty-states/empty-state-delegates.svg" alt="">
                              </div>
                              <h4 class="title">No badges!</h4>
                              <p class="text">Once you add badge, you'll see them here.</p>
                          </div>
                      </div>
                      <table class="nv-data-table break-lap">
                          <tr *ngFor="let badge of badges">
                              <td class="dt-cell nv-clickable" (click)="edit(badge)">
                                  <label>NAME</label>
                                  <strong>{{ badge.name }}</strong>
                              </td><!-- /.dt-cell -->
                              <td class="dt-cell nv-clickable" (click)="edit(badge)">
                                  <label>LEVELS</label>
                                  <strong>{{ badge.badge_levels?.length }}</strong>
                              </td><!-- /.dt-cell -->
                              <td class="dt-actions">
                                  <div class="nv-cell-actions">
                                  <span class="nv-cell-action-trigger"
                                        [class.on]="optionsShown === badge.id"
                                        (click)="openOptions(badge)"></span>
                                      <nav nvOffClick (offClick)="closeOptions()">
                                          <ul>
                                              <li (click)="edit(badge)">
                                                  <a>Edit</a>
                                              </li>
                                              <li class="delete" (click)="delete(badge)">
                                                  <a>Delete</a>
                                              </li>
                                          </ul>
                                      </nav>
                                  </div><!-- /.nv-cell-actions -->
                              </td>
                          </tr>
                      </table>
                  </div><!-- /.nv-column-content -->
              </div><!-- /.nv-column -->
          </div><!-- /.nv-row -->
      </div><!-- /.nv-port -->
  </section><!-- /.nv-section -->
</div><!-- /.config-layout-lower-fixed -->
