export class HeroSectionConfig {
  background: string = '';
  bigText: string = '';
  smallerText: string = '';
  actionButtonUrl: string = '';
  actionButtonText: string = '';
  openInNewTab: boolean = false;
  spaced: string = 'large-spaced';

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
