import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { BannerService } from '@navus/core/services/banner.service';
import { TrackingService } from '@navus/core/services/tracking.service';

@Component({
  selector: 'nv-banner',
  templateUrl: './banner.component.html'
})
export class NavusBannerComponent implements OnInit, OnDestroy {
  @Input() conferenceId: number;
  @Input() position: string;
  banner: any;
  interval: any;

  constructor(
    private elementRef: ElementRef,
    private bannerService: BannerService,
    private trackingService: TrackingService,
  ) { }

  ngOnInit() {
    this.getBanner();
    this.interval = setInterval(() => this.getBanner(), 30000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  getBanner() {
    const params: any = {
      position: this.position,
    };
    if (this.conferenceId) { params.conference_id = this.conferenceId; }

    this.bannerService.getBanner(params)
      .subscribe(
        (response) => {
          if (JSON.stringify(this.banner) !== JSON.stringify(response.data)) {
            this.banner = response.data;
            this.trackingService.shownTracking(this.conferenceId, 'banner', this.banner.id);
          }
        },
        (error) => {
          this.elementRef.nativeElement.remove();
        }
      );
  }

  bannerClick() {
    if (!this.banner) { return; }

    this.trackingService.clickTracking(this.conferenceId, 'banner', this.banner.id);
    window.open(this.banner.external_url, '_blank');
  }

}
