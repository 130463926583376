import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { VideoPlayerPresetsDetailsComponent } from '../video-player-presets-details/video-player-presets-details.component';
import { VideoPlayerPreset } from '@navus/core/classes/video-player-preset';
import { VideoPlayerPresetService } from '@navus/core/services/video-player-preset.service';

@Component({
  selector: 'main [nv-video-player-presets-settings-page]',
  templateUrl: './video-player-presets-page.component.html',
  styles: []
})
export class VideoPlayerPresetsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;

  optionsShown: string = null;

  videoPlayerPresetsLoading: boolean = false;
  videoPlayerPresets: VideoPlayerPreset[];

  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private videoPlayerPresetService: VideoPlayerPresetService,
    private toastService: ToastService,
    private modalService: ModalService
  ) {
  }

  ngOnInit() {
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;

        this.getVideoPlayerPresets();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  openOptions(preset: VideoPlayerPreset) {
    event.stopPropagation();
    this.optionsShown = preset.id;
  }

  closeOptions() {
    event.stopPropagation();
    this.optionsShown = null;
  }

  getVideoPlayerPresets() {
    this.videoPlayerPresetsLoading = true;
    this.videoPlayerPresetService.getPlayerPresets(this.organizationId)
      .subscribe(
        (response) => {
          this.videoPlayerPresets = response.data;
          this.videoPlayerPresetsLoading = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.videoPlayerPresetsLoading = false;
        }
      );
  }

  new() {
    const modalRef = this.modalService.open(VideoPlayerPresetsDetailsComponent);
    modalRef.componentInstance.organizationId = this.organizationId;
    modalRef.result.then(
      () => {
        this.getVideoPlayerPresets();
      },
      () => {

      }
    );
  }


  edit(preset: VideoPlayerPreset) {
    const modalRef = this.modalService.open(VideoPlayerPresetsDetailsComponent);
    modalRef.componentInstance.organizationId = this.organizationId;
    modalRef.componentInstance.preset = preset;
    modalRef.result.then(
      () => {
        this.getVideoPlayerPresets();
      },
      () => {

      }
    );
  }


  delete(preset: VideoPlayerPreset) {
    this.modalService.defaultModal({
      title: 'Video Player Preset Deletion',
      body: 'Are you sure you want to delete this video player preset?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.videoPlayerPresetService.deletePlayerPreset(preset.id)
              .subscribe(
                () => {
                  this.getVideoPlayerPresets();
                },
                (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

}
