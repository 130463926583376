import { NgModule } from '@angular/core';
import { NavusCoreModule } from '@navus/core/navus-core.module';
import { NavusUiModule } from '@navus/ui/navus-ui.module';
import { SharedModule } from '../../../shared';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ConferenceDashboardTotalsComponent } from './totals/conference-dashboard-totals.component';
import { ConferenceDashboardLiveComponent } from './live/conference-dashboard-live.component';
import { ConferenceDashboardOnDemandComponent } from './on-demand/conference-dashboard-on-demand.component';
import { ConferenceDashboardOnSiteComponent } from './on-site/conference-dashboard-on-site.component';
import { CustomDashboardChartLegendComponent } from './custom-dashboard-chart-legend/custom-dashboard-chart-legend.component';
import { SponsorDashboardComponent } from './sponsor/sponsor-dashboard.component';

@NgModule({
  imports: [
    NavusCoreModule.forRoot(),
    NavusUiModule,
    SharedModule,
    NgxChartsModule,
  ],
  declarations: [
    ConferenceDashboardTotalsComponent,
    ConferenceDashboardLiveComponent,
    ConferenceDashboardOnDemandComponent,
    ConferenceDashboardOnSiteComponent,
    SponsorDashboardComponent,
    CustomDashboardChartLegendComponent,
  ],
  entryComponents: [],
  providers: []
})
export class ConferenceDashboardModule {
}
