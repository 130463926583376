import { Component, Input, OnInit } from '@angular/core';
import { User } from '@navus/core/classes/user';
import { MeetingRoomService } from '@navus/core/services/meeting-room.service';

@Component({
  selector: 'nv-speaker-video-conference',
  templateUrl: 'speaker-video-conference.component.html'
})

export class SpeakerVideoConferenceComponent implements OnInit {
  @Input() set conferenceId(value: number) {
    this._conferenceId = value;
  };
  @Input() set locationId(value : number) {
    this._locationId = value;
    this.getLocationMeetingRoom();
  };
  @Input() set currentUser(value: User) {
    this._currentUser = value;
  };

  _conferenceId: number;
  _locationId: number;
  _currentUser: User;

  locationMeetingRoom: any;
  loading: boolean = true;

  activeRoom = null;

  constructor(
    private meetingRoomService: MeetingRoomService,
  ) { }

  ngOnInit() { }

  getLocationMeetingRoom() {
    if (!this._locationId) { return }

    const params = {
      entity_type: 'Location',
      entity_id: this._locationId
    }
    this.loading = true;
    this.meetingRoomService
      .getConferenceMeetingRooms(this._conferenceId, params)
      .subscribe(
        (response) => {
          this.locationMeetingRoom = response.data[0] || null;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      )
  }

  close() {
    this.activeRoom = null;
  }
}