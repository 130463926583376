import {
  AfterContentChecked,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Renderer2,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'nv-select',
  templateUrl: './select.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NavusSelectComponent),
    multi: true
  }]
})

export class NavusSelectComponent implements OnInit, ControlValueAccessor, AfterContentChecked, OnDestroy {
  @ViewChild('searchInput', { read: ElementRef }) searchInput: ElementRef;
  @ViewChild('selectEl', { read: ElementRef }) selectEl: ElementRef;
  @ViewChild('listEl', { read: ElementRef }) listEl: ElementRef;

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  @Input() icon: any;
  @Input() iconPl: any;
  @Input() name: string;
  @Input() formControlName: string;
  @Input() label: string;
  @Input() value: any;
  @Input() showSearch: boolean = false;
  @Input() multi: boolean = false;
  @Input() disabled: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() required: boolean = false;
  @Input() addButtonText: string;
  @Input() addButtonShowValue: boolean = false;
  @Input() emptyStateText: string = 'Empty';
  @Input() notFoundText: string = 'Not Found';
  @Input() headless: boolean = false;
  @Input() appendTo: string | 'default' | 'body' = 'default';
  @Input() listPosition: string | 'auto' | 'bottom' | 'top' = 'bottom';
  @Input() canEmpty: boolean = true;

  @Input() selectedOptions: any = null;
  @Input() options: any[] = [];
  showList: boolean;
  selected: any[] = [];
  selectedIds: any[] = [];
  searchTerm: string;


  selectOverlayContainer: HTMLElement;

  @Output() addButtonClick = new EventEmitter();
  @Output() loadData = new EventEmitter();
  @Output() loadMore = new EventEmitter();
  @Output() searched = new EventEmitter();
  @Output() change = new EventEmitter();


  @HostListener('document:click', ['$event'])
  offClick(event) {
    if (!this.headless && !this.elementRef.nativeElement.contains(event.target)) {
      this.showList = false;
      if (this.selectOverlayContainer) { this.hide(); }
    }
  }

  constructor(
    private elementRef: ElementRef,
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    if (this.required !== false) { this.required = true; }
    if (this.disabled !== false) { this.disabled = true; }
    if (this.readOnly !== false) { this.readOnly = true; }
    if (this.showSearch !== false) { this.showSearch = true; }
    if (this.addButtonShowValue !== false) { this.addButtonShowValue = true; }
    if (this.multi !== false) { this.multi = true; }
  }

  ngAfterContentChecked() {
    this.setSelectedOptions();
  }

  ngOnDestroy() {
    if (this.selectOverlayContainer) { this.hide(); }
  }

  writeValue(value: any) {
    this.value = value;
    if (value) {
      this.selectedIds = value;
    }
    setTimeout(() => {
      this.applyChange();
    }, 300);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  setSelectedOptions() {
    if (this.selectedOptions) {
      this.selected = this.selectedOptions;
    }
  }

  toggle() {
    if (this.disabled) {
      return;
    }

    if (this.showList) {
      this.showList = false;
    } else {
      this.showList = true;
      if(this.appendTo === 'body') {
        this.create();
      }
      if (this.searched) {
        this.searchTerm = '';
        this.emitSearch();
        setTimeout(() => {
          if (this.searchInput) {
            this.searchInput.nativeElement.focus();
          }
        }, 300);
      }
    }
  }

  showListToggle() {
    if (this.disabled) { return; }

    if (!this.showList) {
      setTimeout(() => {
        this.showList = true;
        if (this.searched) {
          this.searchTerm = '';
          this.emitSearch();
          setTimeout(() => {
            if (this.searchInput) {
              this.searchInput.nativeElement.focus();
            }
          }, 300);
        }
      }, 200);
    }
  }

  hideList() {
    this.searchTerm = '';
    this.showList = false;
    if (this.selectOverlayContainer) { this.hide(); }
    if (this.showSearch) {
      this.emitSearch();
    }
  }

  optionSelect(option) {
    if (this.multi) {
      const checkIndex = this.selectedIds.indexOf(option.id);
      if (checkIndex !== -1) {
        this.selectedIds.splice(checkIndex, 1);
      } else {
        this.selectedIds.push(option.id);
      }
      const index = this.selected.findIndex(x => x.id.toString() === option.id.toString());
      if (index !== -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(option);
      }
    } else {
      this.selected = [];
      this.selected.push(option);
      this.showList = false;
      if (this.selectOverlayContainer) { this.hide(); }
      this.searchTerm = '';
    }

    this.emitChange();
  }

  removeOption(option) {
    if (!this.canEmpty) { return }
    
    event.stopPropagation();
    this.showList = true;
    const index = this.selected.indexOf(option);
    if (index !== -1) {
      this.selected.splice(index, 1);
    }
    if (this.multi) {
      const testIndex = this.selectedIds.indexOf(option.id);
      if (testIndex !== -1) {
        this.selectedIds.splice(testIndex, 1);
      }
    }
    this.emitChange();
  }

  emitSearch() {
    this.searched.emit(this.searchTerm);
  }

  emitLoadMore() {
    this.loadMore.emit();
  }

  emitChange() {
    const selected_ids = [];
    for (const option of this.selected) {
      selected_ids.push(option.id);
    }
    this.change.emit(this.multi ? selected_ids : selected_ids.join(','));
    if (this.onChange) {
      this.onChange(this.multi ? selected_ids : selected_ids.join(','));
    }
  }

  applyChange() {
    this.selected = [];

    if (!this.value) { return; }

    const selected_ids = this.value.toString().split(',');
    for (const selected_id of selected_ids) {
      for (const option of this.options) {
        if (selected_id.toString() === option.id.toString()) {
          this.selected.push(option);
        }
      }
    }
  }

  addClick() {
    if (this.addButtonText && (!this.options || this.options.length <= 0)) {
      this.addButtonClick.emit({ value: this.searchTerm });
    }
  }

  hide() {
    this.renderer.removeClass(this.selectOverlayContainer, 'nv-select-overlay-container');
    this.renderer.removeChild(document.body, this.selectOverlayContainer);
  }

  create() {
    this.selectOverlayContainer = this.renderer.createElement('div');
    this.renderer.appendChild(document.body, this.selectOverlayContainer);
    this.renderer.addClass(this.selectOverlayContainer, 'nv-select-overlay-container');
    this.renderer.addClass(this.selectOverlayContainer, 'nv-form-item');
    this.renderer.addClass(this.selectOverlayContainer, 'nv-select-item');
    this.renderer.appendChild(this.selectOverlayContainer, this.listEl.nativeElement);
    this.listEl.nativeElement.style.width = this.selectEl.nativeElement.offsetWidth + 'px';
    this.setPosition();
  }

  setPosition() {
    const hostPos = this.selectEl.nativeElement.getBoundingClientRect();
    const space = window.innerHeight - hostPos.bottom;

    const offset = 45;
    let top = 0;
    let bottom = 0;
    let left = 0;
    let placement;

    if(this.listPosition === 'auto') {
      if (space < this.listEl.nativeElement.scrollHeight) { placement = 'top'; } else { placement = 'bottom'; }
    }else {
      placement = this.listPosition;
    }
    // TODO Calculate position for top placment
    if (placement === 'top') {
      bottom = hostPos.bottom;
      left = hostPos.left;
      this.renderer.setStyle(this.listEl.nativeElement, 'top', `${bottom}px`);
    }

    if (placement === 'bottom') {
      top = hostPos.bottom;
      left = hostPos.left;
      this.renderer.setStyle(this.listEl.nativeElement, 'top', `${top}px`);
    }
    this.renderer.setStyle(this.listEl.nativeElement, 'left', `${left}px`);
  }
}
