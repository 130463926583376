export class SearchSectionConfig {
  title: string = '';
  subtitle: string = '';
  placeholder: string = '';
  backgroundColor: string = '';
  backgroundImage: string = '';
  color: string = '';

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
