import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nv-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['carousel.component.scss']
})
export class SlideComponent implements OnInit {

  constructor() { }

  @Input() image;

  @HostBinding('class.swiper-slide') hostClass(): any {}

  ngOnInit() {
  }

}
