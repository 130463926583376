import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'nv-webex',
  templateUrl: './webex.component.html'
})

export class NavusWebexComponent implements AfterViewInit {

  constructor() {}

  ngAfterViewInit() {
    var widgetEl = document.getElementById('my-webexteams-widget');
    // console.log(webex);
    // // Init a new widget
    // webex.widget(widgetEl).spaceWidget({
    //     guestToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1c2VyLTc3OCIsIm5hbWUiOiJGaWxpcCBMdWtpYyIsImlzcyI6IlkybHpZMjl6Y0dGeWF6b3ZMM1Z5YmpwVVJVRk5PbVYxTFdObGJuUnlZV3d0TVY5ckwwOVNSMEZPU1ZwQlZFbFBUaTlrWVdGaE5USmlOUzAxWTJRd0xUUXhPVEV0T0Rjd1l5MWlNRGRtTVdVNE4yUTBZekkiLCJleHAiOiIxNTExMjg2ODQ5In0.VYbEP2Z_MnMrleUCD_Oqah1kXlq61Ocgkv20yAosodE',
    //     destinationId: 'SPACE_ID',
    //     destinationType: 'spaceId'
    // });
  }

}