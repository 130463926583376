<div class="nv-modal large">
    <div class="nv-modal-header">
        <h4 class="nv-modal-title">Edit location source</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body">
        <div class="nv-row">
            <form [formGroup]="locationEditForm" class="nv-column desk-12">
                <div class="nv-column-content">
                    <div class="nv-row gap-24 propagate">
                        <div class="nv-column desk-12">
                            <header class="nv-location-source-header">
                                <h5>Location Source</h5>
                                <div class="nv-form-item nv-radio-item">
                                    <label>
                                        <span class="nri-radio-port">
                                            <input type="radio" value="internal" formControlName="settings">
                                            <span class="nri-radio-visual"></span>
                                        </span><!-- /.nri-radio-port -->
                                    </label>
                                </div>
                                <p>Internal</p>
                                <div class="nv-form-item nv-radio-item">
                                    <label>
                                        <span class="nri-radio-port">
                                            <input type="radio" value="external" formControlName="settings">
                                            <span class="nri-radio-visual"></span>
                                        </span><!-- /.nri-radio-port -->
                                    </label>
                                </div>
                                <p>External</p>
                            </header><!-- /.nv-location-source-header -->
                        </div>
                        <ng-container *ngIf="locationEditForm.value.settings === 'external'">
                            <div class="nv-column desk-12 tab-12">
                                <div class="nv-column-content">
                                    <nv-input
                                            formControlName="rtmp"
                                            label="RTMP"
                                    ></nv-input>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                        <div class="nv-column desk-12 tab-12">
                            <div class="nv-column-content">
                                <nv-input
                                        formControlName="stream_url"
                                        label="Stage video"
                                ></nv-input>
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                        </ng-container>
                    </div><!-- /.nv-row -->
                </div><!-- /.nv-column-content -->
            </form><!-- /.nv-column -->
        </div>
    </div>
    <div class="nv-modal-footer">
        <div class="nv-btn-box gap-10 flow-end">
            <nv-button size="small" color="neutral" (click)="close()">Cancel</nv-button>
            <nv-button size="small" color="primary" (click)="save()" [loading]="sourceSaving" [disabled]="locationEditForm.invalid" translate>Save</nv-button>
        </div>
    </div>
</div>

