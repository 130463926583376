<div class="nv-template-pane">
    <header class="nv-temp-header nv-section"></header>
    <div class="nv-template-wrap">
    <!-- Profile setting begin -->

        <div class="nv-profile-settings-layout">
            <!-- Personal Info -->
            <main class="nv-profile-settings-body" style="display: none;">
                <div class="nv-profile-settings-body-gutter">
                    <div class="nv-personal-info">
                        <div class="nv-personal-info-image">
                            <img src="/assets/avatar-placeholder.png" alt="Sladjana Grujic">
                            <span class="nv-personal-info-image-edit" title="Add / Edit image"></span>
                        </div><!-- /.nv-personal-info-image -->
                        <h3>Sladjana Grujic</h3>
                        <p class="nv-personal-info-meta">
                            <span class="nv-pi-mail">sladjana.grujic@navus.io</span>
                            <span class="nv-pi-location">Serbia</span>
                            <span class="nv-pi-organisation">Navus AD</span>
                        </p><!-- /.nv-personal-info-meta -->
                        <p><button class="nv-btn medium primary">Edit profile</button></p>
                    </div><!-- /.nv-personal-info -->
                </div><!-- /.nv-profile-settings-body-gutter -->
            </main><!-- /.nv-profile-settings-body -->

            <!-- Personal Info Edit -->
            <main class="nv-profile-settings-detail-body" style="display: none;">
                <header class="nv-profile-settings-detail-header">
                    <a href class="nv-psd-back"></a>
                    <h2><span>Edit Profile</span></h2>
                </header><!-- /.nv-profile-settings-detail-header -->
                <div class="nv-profile-settings-detail-body-gutter">
                    <div class="nv-profile-settings-form-port">
                        <form class="nv-row spaced gap-24 propagate">
                            <div class="nv-column desk-6 phab-12">
                                <div class="nv-column-content">
                                    <div class="nv-form-item">
                                        <label class="nv-not-mandatory">
                                            <input placeholder="First Name" type="text" class="">
                                            <span aria-label="label-title" class="label-title">First Name</span>
                                        </label>
                                    </div><!-- /nv-form-item -->
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-6 phab-12">
                                <div class="nv-column-content">
                                    <div class="nv-form-item">
                                        <label class="nv-not-mandatory">
                                            <input placeholder="Last Name" type="text" class="">
                                            <span aria-label="label-title" class="label-title">Last Name</span>
                                        </label>
                                    </div><!-- /nv-form-item -->
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <div class="nv-form-item">
                                        <label class="nv-not-mandatory">
                                            <input placeholder="Email" type="email" class="">
                                            <span aria-label="label-title" class="label-title">Email</span>
                                        </label>
                                    </div><!-- /nv-form-item -->
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <div class="nv-form-item nv-select-item">
                                        <label class="nv-not-mandatory">
                                            <select>
                                                <option>option 1</option>
                                                <option>option 2</option>
                                                <option>option 3</option>
                                                <option>option 4</option>
                                            </select>
                                            <span aria-label="label-title" class="label-title">Country</span>
                                        </label>
                                    </div><!-- /nv-form-item -->
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <div class="nv-form-item">
                                        <label class="nv-not-mandatory">
                                            <input placeholder="Company / Institution" type="text" class="">
                                            <span aria-label="label-title" class="label-title">Company / Institution</span>
                                        </label>
                                    </div><!-- /nv-form-item -->
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <div class="nv-profile-settings-btn-box">
                                        <button type="button" class="nv-btn medium passive">Close</button>
                                        <button type="button" class="nv-btn medium primary">Save</button>
                                    </div><!-- /.nv-profile-settings-btn-box -->
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                        </form><!-- /.nv-row -->
                    </div><!-- /.nv-profile-settings-form-port -->
                </div><!-- /.nv-profile-settings-body-gutter -->
            </main><!-- /.nv-profile-settings-body -->

            <!-- Password (Security) -->
            <main class="nv-profile-settings-body" style="display: none;">
                <div class="nv-profile-settings-body-gutter">
                    <h3>Security</h3>
                    <p>Strong and unique password helps protect your Account.</p>
                    <p><button class="nv-btn medium primary">Change password</button></p>
                </div><!-- /.nv-profile-settings-body-gutter -->
            </main><!-- /.nv-profile-settings-body -->

            <!-- Password Edit (Security) -->
            <main class="nv-profile-settings-detail-body" style="display: none;">
                <header class="nv-profile-settings-detail-header">
                    <a href class="nv-psd-back"></a>
                    <h2><span>Change your password</span></h2>
                </header><!-- /.nv-profile-settings-detail-header -->
                <div class="nv-profile-settings-detail-body-gutter">
                    <h6 class="medium">Use a strong and unique password to protect your account.</h6>
                    <p>Please use at least 6 characters.</p>
                    <form class="nv-row spaced-bottom gap-24 propagate">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <div class="nv-form-item" >
                                    <label class="nv-mandatory">
                                        <span aria-hidden="true"></span>
                                        <input placeholder="New Password" type="password">
                                        <span aria-label="label-title" class="label-title">New Password</span>
                                        <div class="nv-input-password-toggle hide"></div>
                                    </label>
                                </div>
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <div class="nv-profile-settings-btn-box">
                                    <button type="button" class="nv-btn medium passive">Close</button>
                                    <button type="button" class="nv-btn medium primary" disabled>Save</button>
                                </div><!-- /.nv-profile-settings-btn-box -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </form><!-- /.nv-row -->
                </div><!-- /.nv-profile-settings-body-gutter -->
            </main><!-- /.nv-profile-settings-body -->


            <!-- Conference Participation - not registered -->
            <main class="nv-profile-settings-body" style="display: none;">
                <div class="nv-profile-settings-body-gutter">
                    <h3>Conference Participation</h3>
                    <p>Sorry, you are not registred user of this event. Please click on the button bellow to register.</p>
                    <p><button class="nv-btn medium primary">Register for the event</button></p>
                </div><!-- /.nv-profile-settings-body-gutter -->
            </main><!-- /.nv-profile-settings-body -->


            <!-- Conference Participation - registered user -->
            <main class="nv-profile-settings-body" style="display: none;">
                <div class="nv-profile-settings-body-gutter">
                    <h3>Conference Participation</h3>
                    <p>You will find here all info about your participation at this event.</p>

                    <div class="nv-profile-user-data-sheet nv-row v-stretch gap-24 propagate">
                        <div class="nv-column desk-6 tab-12">
                            <div class="nv-column-content">
                                <div class="nv-profile-settings-box">
                                    <header>
                                        <h3>Your tickets and permissions</h3>
                                    </header>
                                    <div class="nv-profile-settings-box-body">
                                        <div class="nv-tickets-access-types">
                                            <h4>
                                                <span>Ticket type</span>
                                                Standard ticket
                                            </h4>
                                            <h4>
                                                <span>Access type</span>
                                                Limited access
                                            </h4>
                                        </div><!-- /.nv-tickets-access-types -->
                                        <p class="nv-ps-link"><a href>See Details</a></p>
                                    </div><!-- /.nv-profile-settings-box-body -->
                                </div><!-- /.nv-profile-tickets-access-types -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->

                        <div class="nv-column desk-6 tab-12">
                            <div class="nv-column-content">
                                <div class="nv-profile-settings-box">
                                    <header>
                                        <h3>Your Attendance</h3>
                                    </header>
                                    <div class="nv-profile-settings-box-body">
                                        <p>Number of presentations you’ve attended:</p>
                                        <ul class="attendance-numbers">
                                            <li><strong>3</strong><span>live</span></li>
                                            <li><strong>6</strong><span>on-demand</span></li>
                                            <li><strong>11</strong><span>in-person</span></li>
                                        </ul><!-- /.attendance-numbers -->
                                        <p class="nv-settings-request-cf"><a class="nv-btn medium primary">Request the Certificate of attendance</a></p>
                                    </div><!-- /.nv-profile-settings-box-body -->
                                </div><!-- /.nv-profile-tickets-access-types -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->

                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <div class="nv-profile-settings-box">
                                    <header>
                                        <h3 class="liked">Presentations you liked</h3>
                                    </header>
                                    <div class="nv-profile-settings-box-body">
                                        <ul class="nv-profile-settings-presentations">
                                            <li>
                                                <h6>Poster</h6>
                                                <h4>The life and death of diagnostic methods in mycology</h4>
                                                <p>Session Plenary session 01 - The 10<sup>th</sup> TIMM Anniversary</p>
                                                <p><strong>Sybren De Hoog</strong></p>
                                            </li>
                                            <li>
                                                <h6>Oral</h6>
                                                <h4>Highlights of clinical trials in medical mycology</h4>
                                                <p>Session Plenary session 01 - The 10<sup>th</sup> TIMM Anniversary</p>
                                                <p><strong>George Petrikkos</strong></p>
                                            </li>
                                        </ul>
                                        <p class="nv-ps-link"><a href>Show more</a></p>
                                    </div><!-- /.nv-profile-settings-box-body -->
                                </div><!-- /.nv-profile-tickets-access-types -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->

                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <div class="nv-profile-settings-box">
                                    <header>
                                        <h3 class="watched">Presentations you watched</h3>
                                    </header>
                                    <div class="nv-profile-settings-box-body">
                                        <ul class="nv-profile-settings-presentations">
                                            <li>
                                                <h6>Poster</h6>
                                                <h4>The life and death of diagnostic methods in mycology</h4>
                                                <p>Session Plenary session 01 - The 10<sup>th</sup> TIMM Anniversary</p>
                                                <p><strong>Sybren De Hoog</strong></p>
                                            </li>
                                            <li>
                                                <h6>Oral</h6>
                                                <h4>Highlights of clinical trials in medical mycology</h4>
                                                <p>Session Plenary session 01 - The 10<sup>th</sup> TIMM Anniversary</p>
                                                <p><strong>George Petrikkos</strong></p>
                                            </li>
                                        </ul>
                                        <p class="nv-ps-link"><a href>Show more</a></p>
                                    </div><!-- /.nv-profile-settings-box-body -->
                                </div><!-- /.nv-profile-tickets-access-types -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div><!-- /.nv-profile-user-data-sheet -->
                </div><!-- /.nv-profile-settings-body-gutter -->
            </main><!-- /.nv-profile-settings-body -->

            <!-- Conference Participation - your attendance -->
            <main class="nv-profile-settings-detail-body" style="display: none;">
                <header class="nv-profile-settings-detail-header">
                    <a href class="nv-psd-back"></a>
                    <h2><span>Your Attendance</span></h2>
                </header><!-- /.nv-profile-settings-detail-header -->
                <div class="nv-profile-settings-detail-body-gutter">
                    <div class="nv-profile-points-calculate">
                        <div class="nv-calculate-date">
                            <h5>Calculated on</h5>
                            <h3>Oct 31, 2021</h3>
                            <h4>4:35 PM</h4>
                            <p><a href>Recalculate now</a></p>
                        </div><!-- /.nv-calculate-date -->
                        <div class="nv-calculate-score">
                            <h3>7</h3>
                            <p>Total of your cme points</p>
                        </div><!-- /.nv-calculate-score -->
                    </div><!-- /.nv-profile-points-calculate -->

                    <div class="nv-profile-points-earned">
                        <h3>The number of points earned in each category:</h3>
                        <ul>
                            <li>
                                <span>Live</span>
                                <strong>2</strong>
                            </li>
                            <li>
                                <span>On-demand</span>
                                <strong>5</strong>
                            </li>
                            <li>
                                <span>In-person</span>
                                <strong>9</strong>
                            </li>
                            <li class="total">
                                <span>Total</span>
                                <strong>16</strong>
                            </li>
                        </ul>
                        <p><a href class="nv-btn medium primary">Download the Certificate</a></p>
                    </div><!-- /.nv-profile-points-earned -->
                </div><!-- /.nv-profile-settings-body-gutter -->
            </main><!-- /.nv-profile-settings-body -->

            <main class="nv-profile-settings-body" style="display: none">
                <div class="nv-profile-settings-body-gutter">
                    <h3>Speakers, Authors and Moderators page</h3>
                    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                    <p><button class="nv-btn medium primary" data-icon-pl="&#xe94a;">Enter the studio</button></p>

                    <table class="nv-sam-table">
                        <tr class="nv-sam-table-headers">
                            <th>
                                <span>Presentation</span>
                            </th>
                            <th>
                                <span>Session</span>
                            </th>
                            <th>
                                <span>Speakers</span>
                            </th>
                            <th>
                                <span class="time">Time</span>
                            </th>
                            <th>
                                <span class="location">Location</span>
                            </th>
                            <th>
                                <span>Materials</span>
                            </th>
                            <th>
                                &nbsp;
                            </th>
                        </tr>
                        <tr>
                            <td data-label="Presentation">
                                <p title="The life and death of diagram nose">The life and death of diagram nose</p>
                            </td>
                            <td data-label="Session">
                                <p title="Plenary session 01 - The 10th anniversary"><a href>Plenary session 01 - The 10th anniversary</a></p>
                            </td>
                            <td data-label="Speakers">
                                <p class="person" title="María Paula Morterero">
                                    <span>
                                        <strong class="nv-sam-avatar">
                                            <img src="../../assets/avatar-dummy-1.jpg" title="María Paula Morterero">
                                        </strong>
                                        <em>María Paula Morterero</em>
                                    </span>
                                </p>
                            </td>
                            <td data-label="Time">
                                <p class="date-n-time">
                                    <strong>19 May 2021</strong>
                                    <span>08:30 - 12:00</span>
                                </p>
                            </td>
                            <td data-label="Location">
                                <p class="location-venue" title="Conference suite 2A">
                                    <span style="background-color: #35bba2;">Conference suite 2A</span>
                                </p>
                            </td>
                            <td data-label="Materials">
                                <div class="material-available">
                                    <a href class="presentation" title="presentation name"></a>
                                    <a href class="abstract" title="abstract name"></a>
                                    <a href class="webcast" title="webcast name"></a>
                                </div>
                            </td>
                            <td class="toggle-info">
                                <span title="Details"></span>
                            </td>
                        </tr>
                        <tr class="open">
                            <td data-label="Presentation">
                                <p>The life and death of diagram nose</p>
                            </td>
                            <td data-label="Session">
                                <p>Plenary session 01 - The 10th anniversary</p>
                            </td>
                            <td data-label="Speakers">
                                <p class="person">
                                    <span>
                                        <strong class="nv-sam-avatar">
                                            <span class="initials">MM</span>
                                        </strong>
                                        <em>María Paula Morterero</em>
                                    </span>
                                </p>
                            </td>
                            <td data-label="Time">
                                <p class="date-n-time">
                                    <strong>19 May 2021</strong>
                                    <span>08:30 - 12:00</span>
                                </p>
                            </td>
                            <td data-label="Location">
                                <p class="location-venue">
                                    <span style="background-color: #35bba2;">Conference suite 2A</span>
                                </p>
                            </td>
                            <td data-label="Materials">
                                <div class="material-available">
                                    <a href class="presentation" title="presentation name"></a>
                                    <a href class="abstract" title="abstract name"></a>
                                    <a href class="webcast" title="webcast name"></a>
                                </div>
                            </td>
                            <td class="toggle-info">
                                <span title="Details"></span>
                            </td>
                        </tr>
                        <tr class="nv-sam-details-row">
                            <td colspan="7">
                                <div class="nv-sam-details-n-meta">
                                    <div class="nv-sam-details-layout">
                                        <div class="nv-sam-detail-location-time">
                                            <p class="time">08:30AM - 12:00PM</p>
                                            <p class="location" style="background-color: #efb24a;">Conference suite 2B</p>
                                        </div><!-- /.nv-sam-detail-location-time -->
                                        <div class="nv-sam-detail-session">
                                            <h3><a href><span>Session</span> Managing data</a></h3>
                                            <p class="chairs">Chairs: Toni Cathomen, Klaus Olgaard</p>
                                            <h4>
                                                <span>08:00 - 12:00</span>
                                                Turning events into virtual translating lessons from vascular biology into new treatment for coronar artery disease
                                            </h4>
                                            <div class="nv-sam-sessions-speakers">
                                                <p>Speakers</p>
                                                <div class="nv-sam-sessions-speakers-box">
                                                    <p class="person">
                                                        <span>
                                                            <strong class="nv-sam-avatar">
                                                                <span class="initials">MM</span>
                                                            </strong>
                                                            <em>María Paula Morterero</em>
                                                        </span>
                                                    </p>
                                                    <p class="person">
                                                        <span>
                                                            <strong class="nv-sam-avatar">
                                                                 <img src="../../assets/avatar-dummy-1.jpg" title="Speakere Name">
                                                            </strong>
                                                            <em>María Paula Morterero</em>
                                                        </span>
                                                    </p>
                                                    <p class="person">
                                                        <span>
                                                            <strong class="nv-sam-avatar">
                                                                 <img src="../../assets/avatar-dummy-1.jpg" title="Speakere Name">
                                                            </strong>
                                                            <em>María Paula Morterero</em>
                                                        </span>
                                                    </p>
                                                    <p class="person">
                                                        <span>
                                                            <strong class="nv-sam-avatar">
                                                                 <img src="../../assets/avatar-dummy-1.jpg" title="Speakere Name">
                                                            </strong>
                                                            <em>María Paula Morterero</em>
                                                        </span>
                                                    </p>
                                                    <p class="person">
                                                        <span>
                                                            <strong class="nv-sam-avatar">
                                                                 <img src="../../assets/avatar-dummy-1.jpg" title="Speakere Name">
                                                            </strong>
                                                            <em>María Paula Morterero</em>
                                                        </span>
                                                    </p>
                                                    <p class="person">
                                                        <span>
                                                            <strong class="nv-sam-avatar">
                                                                 <img src="../../assets/avatar-dummy-1.jpg" title="Speakere Name">
                                                            </strong>
                                                            <em>María Paula Morterero</em>
                                                        </span>
                                                    </p>
                                                    <p class="person">
                                                        <span>
                                                            <strong class="nv-sam-avatar">
                                                                <span class="initials">MM</span>
                                                            </strong>
                                                            <em>María Paula Morterero</em>
                                                        </span>
                                                    </p>
                                                </div><!-- /.nv-sam-sessions-speakers-box -->
                                            </div><!-- /.nv-sam-sessions-speakers -->
                                        </div><!-- /.nv-sam-detail-session -->
                                        <div class="nv-sam-detail-materials">
                                            <h4>Materials</h4>
                                            <ul>
                                                <li class="webcast">
                                                    <a href>Generating profits with virtual events - the economics</a>
                                                </li>
                                                <li class="abstract">
                                                    <a href>Critical assessment of cell wall integrity factors contributing to in vivo echinocandin tolerance and resistance in Candida glabrata</a>
                                                </li>
                                                <li class="presentation">
                                                    <a href>In vitro antifungal effect of plant-based compound CIN-102 on filamentous fungi and their biofilm</a>
                                                </li>
                                            </ul>
                                        </div><!-- /.nv-sam-detail-materials -->
                                    </div><!-- /.nv-sam-details-layout -->
                                    <div class="nv-sam-sub-meta">
                                        <p>
                                            <strong>16</strong>
                                            <span>Liked</span>
                                        </p>
                                        <p>
                                            <strong>122</strong>
                                            <span>Added to timeline</span>
                                        </p>
                                        <p>
                                            <strong>709</strong>
                                            <span>Attendance</span>
                                        </p>
                                    </div><!-- /.nv-sam-sub-meta -->
                                </div><!-- /.nv-sam-details-n-meta -->
                            </td>
                        </tr>
                        <tr>
                            <td data-label="Presentation">
                                <p>The life and death of diagram nose</p>
                            </td>
                            <td data-label="Session">
                                <p>Plenary session 01 - The 10th anniversary</p>
                            </td>
                            <td data-label="Speakers">
                                <p class="person multi">
                                    <span>
                                        <strong class="nv-sam-avatar">
                                            <span class="initials" title="Kuentos Lubelo">KL</span>
                                        </strong>
                                        <strong class="nv-sam-avatar">
                                            <img src="../../assets/avatar-dummy-1.jpg" title="María Paula Morterero">
                                        </strong>
                                        <strong class="nv-sam-avatar">
                                            <span class="initials" title="María Paula Morterero">MM</span>
                                        </strong>
                                        <strong class="nv-sam-avatar">
                                            <img src="../../assets/avatar-dummy-1.jpg" title="María Paula Morterero">
                                        </strong>
                                        <strong class="nv-sam-avatar">
                                            <span class="over" title="+2">+2</span>
                                        </strong>
                                    </span>
                                </p>
                            </td>
                            <td data-label="Time">
                                <p class="date-n-time">
                                    <strong>19 May 2021</strong>
                                    <span>08:30 - 12:00</span>
                                </p>
                            </td>
                            <td data-label="Location">
                                <p class="location-venue">
                                    <span style="background-color: #35bba2;">Conference suite 2A</span>
                                </p>
                            </td>
                            <td data-label="Materials">
                                <div class="material-available">
                                    <a href class="presentation" title="presentation name"></a>
                                    <a href class="abstract" title="abstract name"></a>
                                    <a href class="webcast" title="webcast name"></a>
                                </div>
                            </td>
                            <td class="toggle-info">
                                <span title="Details"></span>
                            </td>
                        </tr>
                    </table><!-- /.nv-sam-table -->
                </div><!-- /.nv-profile-settings-body-gutter -->
            </main><!-- /.nv-profile-settings-body -->


            <!-- BADGES -->
            <main class="nv-profile-settings-body">
                <div class="nv-profile-settings-body-gutter">
                    <h3>Badges</h3>

                    <div class="nv-profile-badges">
                        <div class="nv-profile-badge-item">
                            <div class="nv-pb-level">
                                <figure><img src="../../assets/badge-attendance-silver.svg" alt="Badge Silver"></figure>
                                <p>Level 1</p>
                            </div><!-- /.nv-pb-level -->
                            <div class="nv-pb-txt">
                                <h4>First chat post in session</h4>
                                <p>Lorem ipsum dolor sit amet. Consectetur adipiscing elit.</p>
                                <div class="nv-pb-progress">
                                    <div class="nv-pb-progress-bar"></div>
                                    <span>0/5</span>
                                </div><!-- /.nv-pb-progress -->
                            </div><!-- /.nv-pb-txt -->
                            <p class="nv-pb-status">
                                <span>Completed</span>07.07.2022
                            </p><!-- /.nv-pb-status -->
                        </div><!-- /.nv-profile-badge-item -->

                        <div class="nv-profile-badge-item default">
                            <div class="nv-pb-level">
                                <figure><img src="../../assets/badge-attendance-default.svg" alt="Badge Default"></figure>
                                <p>Level 1</p>
                            </div><!-- /.nv-pb-level -->
                            <div class="nv-pb-txt">
                                <h4>First chat post in session</h4>
                                <p>Lorem ipsum dolor sit amet. Consectetur adipiscing elit.</p>
                                <div class="nv-pb-progress">
                                    <div class="nv-pb-progress-bar"></div>
                                    <span>0/5</span>
                                </div><!-- /.nv-pb-progress -->
                            </div><!-- /.nv-pb-txt -->
                            <p class="nv-pb-status">
                                <span>Completed</span>07.07.2022
                            </p><!-- /.nv-pb-status -->
                        </div><!-- /.nv-profile-badge-item -->

                        <div class="nv-profile-badge-item">
                            <div class="nv-pb-level">
                                <figure><img src="../../assets/badge-attendance-bronze.svg" alt="Badge Bronze"></figure>
                                <p>Level 3</p>
                            </div><!-- /.nv-pb-level -->
                            <div class="nv-pb-txt">
                                <h4>First chat post in session</h4>
                                <p>Lorem ipsum dolor sit amet. Consectetur adipiscing elit.</p>
                                <div class="nv-pb-progress">
                                    <div class="nv-pb-progress-bar"></div>
                                    <span>0/5</span>
                                </div><!-- /.nv-pb-progress -->
                            </div><!-- /.nv-pb-txt -->
                            <p class="nv-pb-status">
                                <span>Completed</span>07.07.2022
                            </p><!-- /.nv-pb-status -->
                        </div><!-- /.nv-profile-badge-item -->

                        <div class="nv-profile-badge-item">
                            <div class="nv-pb-level">
                                <figure><img src="../../assets/badge-attendance-bronze.svg" alt="Badge Bronze"></figure>
                                <p>Level 3</p>
                            </div><!-- /.nv-pb-level -->
                            <div class="nv-pb-txt">
                                <h4>First chat post in session</h4>
                                <p>Lorem ipsum dolor sit amet. Consectetur adipiscing elit.</p>
                                <div class="nv-pb-progress">
                                    <div class="nv-pb-progress-bar"><em style="width: 60%;"></em></div>
                                    <span>3/5</span>
                                </div><!-- /.nv-pb-progress -->
                            </div><!-- /.nv-pb-txt -->
                            <p class="nv-pb-status">
                                <span>Completed</span>07.07.2022
                            </p><!-- /.nv-pb-status -->
                        </div><!-- /.nv-profile-badge-item -->

                    </div><!-- /.nv-profile-badges -->
                </div><!-- /.nv-profile-settings-body-gutter -->
            </main><!-- /.nv-profile-settings-body -->

            <!-- MENU -->
            <nav class="nv-profile-settings-menu">
                <ul>
                    <li><a class="active" title="Personal Info">Personal Info</a></li>
                    <li><a title="Security">Security</a></li>
                    <li><a title="Conference Participation">Conference Participation</a></li>
                    <li><a title="Speaker/Moderator/Author Page">Speaker / Moderator / Author Page</a></li>
                    <li><a title="Sponsor Info">Sponsor Info</a></li>
                </ul>
            </nav>
        </div><!-- /.nv-profile-settings-layout -->



    <!-- Profile setting ends -->
    </div><!-- /.nv-template-wrap -->
</div><!-- /.nv-template-pane -->

