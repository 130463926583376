import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'nv-delegate-chart-legend',
  templateUrl: './delegate-chart-legend.component.html',
})
export class DelegateChartLegendComponent {
  @Input() legendItems: { id?: number, name: string, value: string, color: string }[];
  @Input() activeEntries: any[] = [];
  @Input() chartData: { name: string, value: number }[];

  @Input() availableItems: { id: number, name: string }[];
  @Input() selectedItems: number[];
  @Input() title: string;
  @Input() itemsManipulation = false;
  @Input() deleteItem = true;

  @Output() addItem = new EventEmitter<number[]>();
  @Output() removeItem = new EventEmitter<number>();
  @Output() selectItem = new EventEmitter<string>();
  @Output() activateLabel = new EventEmitter<{ name: string }>();
  @Output() deactivateLabel = new EventEmitter<{ name: string }>();

  isLabelActive(item: { name: string }): void {
    return this.activeEntries.find(s => s.name === item.name);
  }

  legendLabelActivate(item: any): void {
    this.activeEntries = this.chartData.filter(sd => sd.name === item.name);
    this.activateLabel.emit(item);
  }

  legendLabelDeactivate(item: any): void {
    this.activeEntries = [];
    this.deactivateLabel.emit(item);
  }

  addRow() {
    this.addItem.emit(this.selectedItems);
    this.selectedItems = [];
  }

  removeRow(rowId: number) {
    this.removeItem.emit(rowId);
  }

  selectRow(rowName: string) {
    this.selectItem.emit(rowName);
  }
}

