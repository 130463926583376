import { NgModule } from '@angular/core';
import { NavusCoreModule } from '../../../navus-core/navus-core.module';
import { NavusUiModule } from '../../../navus-ui/navus-ui.module';
import { SharedModule } from '../../../shared';
import { EventMakerSupportFormPageComponent } from './pages/event-maker-support-form/event-maker-support-form-page.component';
import { AttendanceTrackingFormPageComponent } from './pages/attendance-tracking-form/attendance-tracking-form-page.component';
import { ConsentApplicationFormPageComponent } from './pages/consent-application-form/consent-application-form-page.component';
import { EPostersFormPageComponent } from './pages/e-posters-form/e-posters-form-page.component';
import { MobileAppHubFormPageComponent } from './pages/mobile-app-hub-form/mobile-app-hub-form-page.component';
import { WebHubFormPageComponent } from './pages/web-hub-form/web-hub-form-page.component';
import { TicketSalesPageComponent } from './pages/ticket-sales/ticket-sales-page.component';

@NgModule({
  imports: [
    NavusCoreModule.forRoot(),
    NavusUiModule,
    SharedModule
  ],
  declarations: [
    EventMakerSupportFormPageComponent,
    AttendanceTrackingFormPageComponent,
    ConsentApplicationFormPageComponent,
    EPostersFormPageComponent,
    MobileAppHubFormPageComponent,
    WebHubFormPageComponent,
    TicketSalesPageComponent
  ],
  entryComponents: [],
  providers: []
})
export class ContactModule {
}
