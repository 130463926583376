export class Application {
  id: number;
  name: string;
  type: string;
  logo: string;
  api_key: string;
  ios_link: string;
  android_link: string;
  description: string;
  color_primary: string;
  color_primary_dark: string;
  published: boolean;

  pages?: any[];
}
