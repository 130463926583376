import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TrackingService } from '@navus/core/services/tracking.service';

@Component({
    selector: 'app-tracking-test-page',
    templateUrl: './tracking-test.component.html'
})
export class TrackingTestComponent implements OnInit {

    userId: number;
    actionNameOptions = [
        {
            id: 'START',
            name: 'START'
        },
        {
            id: 'END',
            name: 'END'
        },
        {
            id: 'PING',
            name: 'PING'
        }
    ];
    actionTypeOptions = [
        {
            id: 'BROWSING',
            name: 'BROWSING'
        },
        {
            id: 'WATCHING',
            name: 'WATCHING'
        }
    ];

    entityTypeOptions = [
        {
            id: 'page',
            name: 'page'
        },
        {
            id: 'banner',
            name: 'banner'
        },
        {
            id: 'live-stage',
            name: 'live-stage'
        }
    ];
    configForm: FormGroup;
    uuid: string = localStorage.getItem('uuid');
    constructor(
        private trackingService: TrackingService,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        this.initForm();
    }

    initForm() {
        this.configForm = this.formBuilder.group({
            entity_type: ['page'],
            entity_id: [580],
            action_name: ['START'],
            action_type: ['BROWSING'],
            conference_id: [8200],
            additional_info: [null],
        });
    }

    identify() {
        this.trackingService.identify(this.userId)
            .then(() => {
                this.uuid = localStorage.getItem('uuid');
            })
            .catch((e) => { console.log(e); })
    }

    submit() {
       const params: any = {...this.configForm.value};
       if(params.additional_info && params.additional_info.length > 0) {
        params.additional_info = JSON.parse(params.additional_info)
       }
       this.trackingService.sendAction(params)
        .subscribe(
            () => { 
                this.uuid = localStorage.getItem('uuid');
            },
            (e) => { console.log(e); }
        );
    }

}
