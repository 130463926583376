<div class="nv-page-wrap">
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-30 v-stretch large-spaced-top semi-spaced-bottom">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <div class="nv-video-port">
                            <img src="../../assets/aspect-16-9.png" alt="" class="nv-aspect" aria-hidden="true">
                            <video width="320" height="240" controls poster="../../assets/video-ss.jpg">
                                <source src="../../assets/sample-video_1280x720_1mb.mp4" type="video/mp4">
                                <!--<source src="movie.ogg" type="video/ogg">-->
                                Your browser does not support the video tag.
                            </video>
                        </div><!-- /.nv-ls-video-port -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
                <div class="nv-column desk-6 v-middle">
                    <div class="nv-live-stream-active-label">
                        <h4>[Session] Anatomy and Pathology</h4>
                        <h1>Crohns disease - why early surgery is better</h1>
                        <div class="lsal-author">
                            <div class="la-img" style="background-image: url('../../assets/avatar-dummy-3.jpg')"></div>
                            <p>Ronan O'Connell</p>
                        </div><!-- /.lsal-author -->
                        <div class="live-stream-meta">
                            <p class="lsm-venue">
                                <span class="lsm-color-dot"></span>
                                Live in Horsaal 1
                                <em>Started at 8:00 AM , Ends at 8:30 AM</em>
                            </p>
                            <p class="lsm-category">
                                <span class="lsm-color-dot" style="background-color: #02bfd9;"></span>
                                Category Name
                            </p>
                            <p class="lsm-views">318 viewers</p>
                        </div><!-- /live-stream-meta -->
                    </div><!-- /.nv-live-stream-active-label -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </div><!-- /.nv-section -->

    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-30 v-stretch spaced-top small-spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h4>Now Streaming</h4>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
            <div class="nv-row gap-30 v-stretch small-spaced-top spaced-bottom propagate">
                <div class="nv-column desk-4">
                    <div class="nv-column-content">
                        <div class="nv-current-streams">
                            <div class="cs-item">
                                <div class="cs-video-port" style="background-image: url('../../assets/video-ss.jpg');">
                                    <img src="../../assets/aspect-16-9.png" alt="" class="aspect" aria-hidden="true">
                                    <a href="" title="Holy Plane - Understanding of two extra fascial layers"></a>
                                    <div class="cs-play"></div>
                                    <div class="cs-label">
                                        <div class="cs-label-elements">
                                            <p>
                                                <span class="cs-dot"></span>
                                                Live in Horsaal 1
                                            </p>
                                            <em>Started at 8:00</em>
                                        </div><!-- /.cs-label-elements -->
                                    </div><!-- /.cs-label -->
                                </div><!-- /.cs-video-port -->
                                <header class="cs-meta">
                                    <h4>[Session] Anatomy and Pathology</h4>
                                    <h2><a href="">“Holy Plane” - Understanding of two extra fascial layers</a></h2>
                                    <p>Speaker Name</p>
                                </header><!-- /.cs-meta -->
                                <div class="cs-upcoming">
                                    <p><strong>UPCOMING</strong> - 14:15</p>
                                    <div class="csu-label">
                                        <div class="csu-img" style="background-image: url('../../assets/avatar-dummy-3.jpg')"></div>
                                        <div class="cs-meta">
                                            <h4>[Session] Anatomy and Pathology</h4>
                                            <h2>“Holy Plane” - Understanding of two extra fascial layers</h2>
                                            <p>Speaker Name</p>
                                        </div><!-- /.cs-meta -->
                                    </div><!-- /.csu-label -->
                                </div><!-- /.cs-upcoming -->
                            </div><!-- /.cs-item -->
                        </div><!-- /.nv-current-streams -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
                <div class="nv-column desk-4">
                    <div class="nv-column-content">
                        <div class="nv-current-streams">
                            <div class="cs-item">
                                <div class="cs-video-port" style="background-image: url('../../assets/video-ss.jpg');">
                                    <img src="../../assets/aspect-16-9.png" alt="" class="aspect" aria-hidden="true">
                                    <a href="" title="Holy Plane - Understanding of two extra fascial layers"></a>
                                    <div class="cs-play"></div>
                                    <div class="cs-label">
                                        <p>
                                            <span class="cs-dot"></span>
                                            Live in Horsaal 1
                                            <em>Started at 8:00</em>
                                        </p>
                                    </div><!-- /.cs-label -->
                                </div><!-- /.cs-video-port -->
                                <header class="cs-meta">
                                    <h4>[Session] Anatomy and Pathology</h4>
                                    <h2><a href="">“Holy Plane” - Understanding of two extra fascial layers</a></h2>
                                    <p>Speaker Name</p>
                                </header><!-- /.cs-meta -->
                                <div class="cs-no-upcoming">
                                    <h5>NO UPCOMING LIVE STREAMS SCHEDULED</h5>
                                    <p>Please check back later</p>
                                </div><!-- /.cs-no-upcoming -->
                            </div><!-- /.cs-item -->
                        </div><!-- /.nv-current-streams -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
                <div class="nv-column desk-4">
                    <div class="nv-column-content">
                        <div class="nv-current-streams">
                            <div class="cs-item">
                                <div class="cs-video-port" style="background-image: url('../../assets/video-ss.jpg');">
                                    <img src="../../assets/aspect-16-9.png" alt="" class="aspect" aria-hidden="true">
                                    <a href="" title="Holy Plane - Understanding of two extra fascial layers"></a>
                                    <div class="cs-play"></div>
                                    <div class="cs-label">
                                        <p>
                                            <span class="cs-dot"></span>
                                            Live in Horsaal 1
                                            <em>Started at 8:00</em>
                                        </p>
                                    </div><!-- /.cs-label -->
                                </div><!-- /.cs-video-port -->
                                <header class="cs-meta">
                                    <h4>[Session] Anatomy and Pathology</h4>
                                    <h2><a href="">“Holy Plane” - Understanding of two extra fascial layers</a></h2>
                                    <p>Speaker Name</p>
                                </header><!-- /.cs-meta -->
                                <div class="cs-upcoming">
                                    <p><strong>UPCOMING</strong> - 14:15</p>
                                    <div class="csu-label">
                                        <div class="csu-img" style="background-image: url('../../assets/avatar-dummy-3.jpg')"></div>
                                        <div class="cs-meta">
                                            <h4>[Session] Anatomy and Pathology</h4>
                                            <h2>“Holy Plane” - Understanding of two extra fascial layers</h2>
                                            <p>Speaker Name</p>
                                        </div><!-- /.cs-meta -->
                                    </div><!-- /.csu-label -->
                                </div><!-- /.cs-upcoming -->
                            </div><!-- /.cs-item -->
                        </div><!-- /.nv-current-streams -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
                <div class="nv-column desk-4">
                    <div class="nv-column-content">
                        <div class="nv-current-streams">
                            <div class="cs-item">
                                <div class="cs-video-port" style="background-image: url('../../assets/video-ss.jpg');">
                                    <img src="../../assets/aspect-16-9.png" alt="" class="aspect" aria-hidden="true">
                                    <a href="" title="Holy Plane - Understanding of two extra fascial layers"></a>
                                    <div class="cs-play"></div>
                                    <div class="cs-label">
                                        <p>
                                            <span class="cs-dot"></span>
                                            Live in Horsaal 1
                                            <em>Started at 8:00</em>
                                        </p>
                                    </div><!-- /.cs-label -->
                                </div><!-- /.cs-video-port -->
                                <header class="cs-meta">
                                    <h4>[Session] Anatomy and Pathology</h4>
                                    <h2><a href="">“Holy Plane” - Understanding of two extra fascial layers</a></h2>
                                    <p>Speaker Name</p>
                                </header><!-- /.cs-meta -->
                                <div class="cs-no-upcoming">
                                    <h5>NO UPCOMING LIVE STREAMS SCHEDULED</h5>
                                    <p>Please check back later</p>
                                </div><!-- /.cs-no-upcoming -->
                            </div><!-- /.cs-item -->
                        </div><!-- /.nv-current-streams -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->

            <div class="nv-row gap-30 small-spaced-top spaced-bottom propagate flow-center">
                <div class="nv-column desk-4">
                    <div class="nv-column-content">
                        <div class="nv-current-streams">
                            <div class="cs-item">
                                <div class="cs-video-port" style="background-image: url('../../assets/video-ss.jpg');">
                                    <img src="../../assets/aspect-16-9.png" alt="" class="aspect" aria-hidden="true">
                                    <a href="" title="Holy Plane - Understanding of two extra fascial layers"></a>
                                    <div class="cs-play"></div>
                                    <div class="cs-label">
                                        <p>
                                            <span class="cs-dot"></span>
                                            Live in Horsaal 1
                                            <em>Started at 8:00</em>
                                        </p>
                                    </div><!-- /.cs-label -->
                                </div><!-- /.cs-video-port -->
                                <header class="cs-meta">
                                    <h4>[Session] Anatomy and Pathology</h4>
                                    <h2><a href="">“Holy Plane” - Understanding of two extra fascial layers</a></h2>
                                    <p>Speaker Name</p>
                                </header><!-- /.cs-meta -->
                                <div class="cs-no-upcoming">
                                    <h5>NO UPCOMING LIVE STREAMS SCHEDULED</h5>
                                    <p>Please check back later</p>
                                </div><!-- /.cs-no-upcoming -->
                            </div><!-- /.cs-item -->
                        </div><!-- /.nv-current-streams -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </div><!-- /.nv-section -->
</div><!-- /.nv-page-wrap -->
