<div class="nv-template-page">
    <div class="nv-template-content">
        <div class="nv-template-pane">
            <header class="nv-temp-header nv-section website-354"><div class="nv-row space-between"><div class="nv-column desk-initial v-middle"><div class="nv-column-content"><div class="nv-template-logo"><a href="/" routerlink="/" title="Pfizer Research Prize 2021"><!----><img src="https://service.e-materials.com/storage/resources/pfizer_ag/pfi/pfizer-logo.png" alt="Pfizer Research Prize 2021"></a></div></div></div><div class="nv-column desk-initial v-stretch" style="display: flex;"><div class="nv-column-content"><div class="nv-template-menu-n-profile"><nav class="nv-template-menu"><ul><!----><li class="nav-page-879"><!----><a class="nav-link" href="/879/page/home"><span>Home</span></a><!----><!----><!----></li><li class="nav-page-926"><!----><a class="nav-link" href="/926/page/pfizer-research-prize"><span>Pfizer Research Prize</span></a><!----><!----><!----></li><li class="nav-page-927"><!----><a class="nav-link active" href="/927/page/program-announcement"><span>Program</span></a><!----><!----><!----></li></ul></nav><div class="nv-template-profile"><!----><!----><div class="nv-btn small accent2 radius-100 icon nv-bar-profile-menu" nvoffclick=""> DK <nav><ul><li><a translate="">Sign Out</a></li><li><a href="/profile">Profile</a></li></ul></nav></div></div></div></div></div><div class="nv-column desk-none tab-initial v-stretch"><div class="nv-column-content"><div class="nv-touch-menu-trigger"></div></div></div></div></header>
            <div class="nv-template-wrap">
                <section class="nv-section guttered">
                    <div class="nv-port">
                        <div class="nv-row large-spaced-top spaced-bottom">
                            <div class="nv-column desk-12">
                                <h1>Gallery title</h1>
                            </div><!-- /.nv-column -->
                        </div><!-- /.nv-row -->
                        <div class="nv-row large-spaced-bottom semi-spaced-top">
                            <div class="nv-column desk-12">
                                <div class="nv-gallery-simple by6"><!-- < by 3-6 -->
                                    <div class="nv-gallery-simple-item">
                                        <div class="nv-gallery-simple-port">
                                            <a href="" title="descr"></a>
                                            <figure class="nv-gallery-simple-img"><img src="https://loremflickr.com/1000/800/hamburg" alt="descr"></figure>
                                            <p>Product management breakout fastworks</p>
                                        </div><!-- /.nv-gallery-simple-port -->
                                    </div><!-- /.nv-gallery-simple-item -->
                                    <div class="nv-gallery-simple-item">
                                        <div class="nv-gallery-simple-port">
                                            <a href="" title="descr"></a>
                                            <figure class="nv-gallery-simple-img"><img src="https://loremflickr.com/1000/800/rome" alt="descr"></figure>
                                            <p>Baked in with this model land</p>
                                        </div><!-- /.nv-gallery-simple-port -->
                                    </div><!-- /.nv-gallery-simple-item -->
                                    <div class="nv-gallery-simple-item">
                                        <div class="nv-gallery-simple-port">
                                            <a href="" title="descr"></a>
                                            <figure class="nv-gallery-simple-img"><img src="https://loremflickr.com/1000/800/nice" alt="descr"></figure>
                                            <p>My grasp on reality right</p>
                                        </div><!-- /.nv-gallery-simple-port -->
                                    </div><!-- /.nv-gallery-simple-item -->
                                    <div class="nv-gallery-simple-item">
                                        <div class="nv-gallery-simple-port">
                                            <a href="" title="descr"></a>
                                            <figure class="nv-gallery-simple-img"><img src="https://loremflickr.com/1000/800/venice" alt="descr"></figure>
                                            <p>We've got kpis for that pre launch</p>
                                        </div><!-- /.nv-gallery-simple-port -->
                                    </div><!-- /.nv-gallery-simple-item -->
                                    <div class="nv-gallery-simple-item">
                                        <div class="nv-gallery-simple-port">
                                            <a href="" title="descr"></a>
                                            <figure class="nv-gallery-simple-img"><img src="https://loremflickr.com/1000/800/zagreb" alt="descr"></figure>
                                            <p>Can we align on lunch orders incentivization</p>
                                        </div><!-- /.nv-gallery-simple-port -->
                                    </div><!-- /.nv-gallery-simple-item -->
                                    <div class="nv-gallery-simple-item">
                                        <div class="nv-gallery-simple-port">
                                            <a href="" title="descr"></a>
                                            <figure class="nv-gallery-simple-img"><img src="https://loremflickr.com/1000/800/stockholm" alt="descr"></figure>
                                            <p>Blue money let's pressure test this</p>
                                        </div><!-- /.nv-gallery-simple-port -->
                                    </div><!-- /.nv-gallery-simple-item -->
                                    <div class="nv-gallery-simple-item">
                                        <div class="nv-gallery-simple-port">
                                            <a href="" title="descr"></a>
                                            <figure class="nv-gallery-simple-img"><img src="https://loremflickr.com/1000/800/amsterdam" alt="descr"></figure>
                                            <p>Productize circle back around nor form</p>
                                        </div><!-- /.nv-gallery-simple-port -->
                                    </div><!-- /.nv-gallery-simple-item -->
                                    <div class="nv-gallery-simple-item">
                                        <div class="nv-gallery-simple-port">
                                            <a href="" title="descr"></a>
                                            <figure class="nv-gallery-simple-img"><img src="https://loremflickr.com/1000/800/berlin" alt="descr"></figure>
                                            <p>Jesus meeting with Phil about his attitude</p>
                                        </div><!-- /.nv-gallery-simple-port -->
                                    </div><!-- /.nv-gallery-simple-item -->
                                    <div class="nv-gallery-simple-item">
                                        <div class="nv-gallery-simple-port">
                                            <a href="" title="descr"></a>
                                            <figure class="nv-gallery-simple-img"><img src="https://loremflickr.com/1000/800/prague" alt="descr"></figure>
                                            <p>That jerk from finance really threw me under the bus</p>
                                        </div><!-- /.nv-gallery-simple-port -->
                                    </div><!-- /.nv-gallery-simple-item -->
                                    <div class="nv-gallery-simple-item">
                                        <div class="nv-gallery-simple-port">
                                            <a href="" title="descr"></a>
                                            <figure class="nv-gallery-simple-img"><img src="https://loremflickr.com/1000/800/sidney" alt="descr"></figure>
                                            <p>Let's prioritize the low-hanging fruit</p>
                                        </div><!-- /.nv-gallery-simple-port -->
                                    </div><!-- /.nv-gallery-simple-item -->
                                    <div class="nv-gallery-simple-item">
                                        <div class="nv-gallery-simple-port">
                                            <a href="" title="descr"></a>
                                            <figure class="nv-gallery-simple-img"><img src="https://loremflickr.com/1000/800/mastricht" alt="descr"></figure>
                                            <p>Talk to the slides conversational</p>
                                        </div><!-- /.nv-gallery-simple-port -->
                                    </div><!-- /.nv-gallery-simple-item -->
                                    <div class="nv-gallery-simple-item">
                                        <div class="nv-gallery-simple-port">
                                            <a href="" title="descr"></a>
                                            <figure class="nv-gallery-simple-img"><img src="https://loremflickr.com/1000/800/budapest" alt="descr"></figure>
                                            <p>Social currency dunder mifflin</p>
                                        </div><!-- /.nv-gallery-simple-port -->
                                    </div><!-- /.nv-gallery-simple-item -->
                                </div><!-- /.nv-gallery-simple -->
                            </div><!-- /.nv-column -->
                        </div><!-- /.nv-row -->
                    </div><!-- /.nv-port -->
                </section><!-- /nv-section -->
            </div><!-- /.nv-template-wrap -->
    </div><!-- /.nv-template-pane -->
</div><!-- /.nv-template-content -->
