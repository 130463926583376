import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'nv-qrcode',
    templateUrl: './qrcode.component.html'
})

export class QrcodeComponent implements OnInit {
    @Input() data: string;
    @Input() width: number = 256;

    constructor() {}

    ngOnInit() {

    }

}

