import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BlockCategory } from '@navus/core/classes/block-category';
import { BlockCategoryService } from '@navus/core/services/block-category.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';

@Component({
  selector: 'main [nv-block-category-details-page]',
  templateUrl: './block-category-details-page.component.html'
})
export class BlockCategoryDetailsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  blockCategoryId: number;
  blockCategory: BlockCategory;
  blockCategoryForm: FormGroup;

  loadingBlockCategory: boolean = true;
  savingBlockCategory: boolean = false;
  unsavedChanges: boolean = false;

  subscriptions: Subscription[] = [];

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private blockCategoryService: BlockCategoryService,
    private toastService: ToastService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.initForm();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.blockCategoryId = data.blockCategoryId;
        this.getBlockCategory();
      }
    );
    this.subscriptions.push(subscription);

    const changeSubscription = this.blockCategoryForm.valueChanges.subscribe(
      () => {
        this.unsavedChanges = true;
      }
    );
    this.subscriptions.push(changeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.blockCategoryForm = this.formBuilder.group({
      name: ['', Validators.required],
      color: ['']
    });
    this.unsavedChanges = false;
  }

  getBlockCategory() {
    if (this.blockCategoryId) {
      this.loadingBlockCategory = true;
      this.blockCategoryService.getConferenceBlockCategory(this.conferenceId, this.blockCategoryId)
        .subscribe(
          (response) => {
            this.blockCategory = response['data'];
            this.blockCategoryForm.patchValue(this.blockCategory);
            this.loadingBlockCategory = false;
            setTimeout(() => { this.unsavedChanges = false; }, 500);
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingBlockCategory = false;
          }
        );
    } else {
      this.blockCategory = new BlockCategory();
      this.loadingBlockCategory = false;
    }
  }

  save() {
    if (this.blockCategoryForm.invalid) { return; }

    this.savingBlockCategory = true;
    if (this.blockCategoryId) {
      this.blockCategoryService.updateConferenceBlockCategory(this.conferenceId, this.blockCategoryId, this.blockCategoryForm.value)
        .subscribe(
          (response) => {
            this.blockCategory = response['data'];
            this.toastService.success('Session category successfully saved.', {});
            this.unsavedChanges = false;
            this.savingBlockCategory = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingBlockCategory = false;
          }
        );
    } else {
      this.blockCategoryService.createConferenceBlockCategory(this.conferenceId, this.blockCategoryForm.value)
        .subscribe(
          (response) => {
            this.blockCategory = response['data'];
            this.toastService.success('Session category successfully created.', {});
            this.router.navigate([this.blockCategory.id], { relativeTo: this.route });
            this.savingBlockCategory = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingBlockCategory = false;
          }
        );
    }
  }

  close() {
    if (this.unsavedChanges) {
      this.modalService.defaultModal({
        title: 'Unsaved changes',
        body: 'Are you sure you want to leave the page without saving changes?',
        size: 'small',
        buttons: [
          {
            text: 'Stay',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Leave',
            color: 'primary',
            handler: () => {
              if (this.blockCategoryId) {
                this.router.navigate(['../..', 'block-categories'], { relativeTo: this.route });
              } else {
                this.router.navigate(['..', 'block-categories'], { relativeTo: this.route });
              }
            }
          }
        ]
      });
    } else {
      if (this.blockCategoryId) {
        this.router.navigate(['../..', 'block-categories'], { relativeTo: this.route });
      } else {
        this.router.navigate(['..', 'block-categories'], { relativeTo: this.route });
      }
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Session Category Deletion',
      body: 'Are you sure you want to delete this session category?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.blockCategoryService.deleteConferenceBlockCategory(this.conferenceId, this.blockCategoryId)
              .subscribe(
                () => {
                  this.close();
                },
                (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

}
