<div class="nv-template-page">
<div class="nv-template-content">



<header class="nv-temp-header nv-section website-279" ng-reflect-ng-class="[object Object]"><div class="nv-row space-between"><div class="nv-column desk-initial v-middle"><div class="nv-column-content"><div class="nv-template-logo"><a href="/" routerlink="/" ng-reflect-router-link="/" title="Navus Hybrid Demo Event"><img alt="Navus Hybrid Demo Event" src="https://service.e-materials.com/storage/resources/navus_ag/nav/navus-demo-logo.jpg"><!--bindings={
  "ng-reflect-ng-if": "https://service.e-materials.co"
}--></a></div></div></div><div class="nv-column desk-initial v-stretch" style="display: flex;"><div class="nv-column-content"><div class="nv-template-menu-n-profile">


    <nav class="nv-template-menu"><ul><li class="nav-page-580"><a class="nav-link" ng-reflect-router-link="/580/page/home" ng-reflect-router-link-active="active" href="/580/page/home"><span>Home</span></a><!--bindings={
  "ng-reflect-ng-if": "true"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={}--></li><li class="nav-page-582"><a class="nav-link active" ng-reflect-router-link="/search" ng-reflect-router-link-active="active" href="/search"><span>On Demand</span></a><!--bindings={
  "ng-reflect-ng-if": "true"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={}--></li><li class="nav-page-583"><a class="nav-link" ng-reflect-router-link="/program" ng-reflect-router-link-active="active" href="/program"><span>Program</span></a><!--bindings={
  "ng-reflect-ng-if": "true"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={}--></li><li class="nav-page-581"><a class="nav-link" ng-reflect-router-link="/live-stage" ng-reflect-router-link-active="active" href="/live-stage"><span>Live Stage</span></a><!--bindings={
  "ng-reflect-ng-if": "true"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={}--></li><li class="nav-page-726"><a class="nav-link" ng-reflect-router-link="/speakers" ng-reflect-router-link-active="active" href="/speakers"><span>Speakers</span></a><!--bindings={
  "ng-reflect-ng-if": "true"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={}--></li><li class="nav-page-584"><a class="nav-link" ng-reflect-router-link="/sponsors" ng-reflect-router-link-active="active" href="/sponsors"><span>Sponsors </span></a><!--bindings={
  "ng-reflect-ng-if": "true"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={}--></li><!--bindings={
  "ng-reflect-ng-for-of": "[object Object],[object Object"
}--></ul></nav>
    <div class="nv-template-profile">
        <div nvoffclick="" class="nv-btn small accent2 radius-100 icon nv-bar-profile-menu"> DK <nav><ul><li><a ng-reflect-router-link="/profile" href="/profile">Profile</a></li><li><a translate="" ng-reflect-translate="">Sign Out</a></li></ul></nav></div><!--bindings={
  "ng-reflect-ng-if": "[object Object]"
}--></div></div></div></div><div class="nv-column desk-none tab-initial v-stretch"><div class="nv-column-content"><div class="nv-touch-menu-trigger"></div></div></div></div></header>

<div class="nv-template-wrap">



    <!-- ON DEMAND -->

    <section class="nv-on-demand-wrap">
        <div class="nv-on-demand-video-portion">
            <div class="nv-on-demand-stage">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/E3alqOZee3g" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div class="nv-on-demand-stage-forthcoming on"><!-- < toggle class 'on' -->
                    <h5>NEXT MATERIAL IN 8</h5>
                    <h3>Material title 3 lorem ipsum dolor sit amet lorem consectetur</h3>
                    <div class="nv-btn-box flow-end gap-10">
                        <a href class="nv-btn small passive">Cancel</a>
                        <a href class="nv-btn small accent1">Play Now</a>
                    </div><!-- /.nv-btn-box -->
                </div><!-- /.nv-on-demand-stage-forthcoming -->
            </div><!-- /.nv-on-demand-stage -->
            <header class="on-demand-heading-w-tools">
                <h2>Presentation title 2 lorem ipsum dolor sit amet</h2>
                <ul class="nv-on-demand-tools-panel">
                    <li><a class="qna" title="Questions & Answers">Q&A</a></li>
                    <li><a class="vote" title="Voting">Vote</a></li>
                    <li><a class="like" title="Like">Like</a></li><!-- < additional class 'liked' -->
                    <li><a class="download" title="Download video">Download</a></li>
                </ul>
            </header><!-- /.on-demand-heading-w-tools -->

            <div class="nv-on-demand-meta-row">
                <ul>
                    <li class="date">May 6, 2022</li>
                    <li class="time">14:00 - 14:30</li>
                    <li class="type oral">Oral</li>
                </ul>
                <h4><strong>Session</strong>: <a href title="Session title">Title lorem ipsum dolor sit amet</a></h4>
            </div><!-- /.nv-on-demand-meta-row -->

            <div class="nv-on-demand-prime-users-box">
                <div class="nv-od-prime-user">
                    <header>
                        <figure class="avatar"><img src="../../assets/avatar-placeholder.png" alt="User name"></figure>
                        <h3>
                            <a href title="name">Dr. Ernestina Kohler</a>
                            <span>Pacocha-Kozey</span>
                        </h3>
                    </header>
                    <div class="nv-od-prime-user-bar">
                        <div class="nv-od-prime-user-tools">
                            <a href title="Video Call" class="vc">Video Call</a>
                            <a href title="Chat" class="chat">Chat</a>
                        </div><!-- /.nv-od-prime-user-tools -->
                        <div class="nv-od-prime-user-soc">
                            <a href title="LinkedIn" class="li"></a>
                        </div><!-- /.nv-od-prime-user-soc -->
                    </div><!-- /.nv-od-prime-user-bar -->
                </div><!-- /.nv-od-prime-user -->
                <div class="nv-od-prime-user">
                    <header>
                        <figure class="avatar"><img src="https://loremflickr.com/g/400/400/face" alt="User name"></figure>
                        <h3>
                            <a href title="name">Dr. Smith Dickens</a>
                            <span>Kuhlman PLC</span>
                        </h3>
                    </header>
                    <div class="nv-od-prime-user-bar">
                        <div class="nv-od-prime-user-tools">
                            <a href title="Video Call" class="vc">Video Call</a>
                            <a href title="Chat" class="chat">Chat</a>
                        </div><!-- /.nv-od-prime-user-tools -->
                        <div class="nv-od-prime-user-soc">
                            <a href title="LinkedIn" class="li"></a>
                        </div><!-- /.nv-od-prime-user-soc -->
                    </div><!-- /.nv-od-prime-user-bar -->
                </div><!-- /.nv-od-prime-user -->
                <div class="nv-od-prime-user">
                    <header>
                        <figure class="avatar"><img src="https://loremflickr.com/g/400/400/flower" alt="User name"></figure>
                        <h3>
                            <a href title="name">Dr. Roxanne Smith</a>
                            <span>Tremblay-O'Keefe</span>
                        </h3>
                    </header>
                    <div class="nv-od-prime-user-bar">
                        <div class="nv-od-prime-user-tools">
                            <a href title="Video Call" class="vc">Video Call</a>
                            <a href title="Chat" class="chat">Chat</a>
                        </div><!-- /.nv-od-prime-user-tools -->
                        <div class="nv-od-prime-user-soc">
                            <a href title="LinkedIn" class="li"></a>
                        </div><!-- /.nv-od-prime-user-soc -->
                    </div><!-- /.nv-od-prime-user-bar -->
                </div><!-- /.nv-od-prime-user -->
            </div><!-- /.nv-on-demand-prime-users-box -->

            <div class="nv-on-demand-txt">
                <h2>Abstract</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vulputate finibus leo, placerat mollis augue convallis vitae. Fusce dui ex, fringilla sed iaculis vitae, convallis at sem. Ut magna dui, volutpat sed commodo et, lobortis et turpis. Cras bibendum neque feugiat nulla malesuada vestibulum. Nunc id varius erat, in aliquet dui. Sed et enim ut dolor tristique euismod. Vestibulum nisl felis, faucibus sit amet nisl a, eleifend luctus diam. Aliquam sodales volutpat ornare. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Fusce non eros turpis. Suspendisse a ligula et mi imperdiet eleifend. Phasellus in tincidunt dolor.</p>
                <p>In tempus magna a nulla elementum molestie. Mauris eget vestibulum erat. Ut auctor congue eleifend. Cras libero ante, rhoncus non leo ut, cursus egestas tellus. Vivamus eleifend nisl justo, non tincidunt felis viverra ut. Ut vel tellus imperdiet dolor consectetur placerat sed vitae lectus. Vestibulum ullamcorper diam a faucibus finibus. Aliquam erat volutpat. Etiam in justo consectetur risus facilisis feugiat eu at sem. Aliquam eu elementum diam, in mollis purus.</p>
            </div><!-- /.nv-on-demand-txt -->

            <div class="nv-on-demand-toolbox qa">
                <div class="nv-od-tb-placholder">
                    <h4>Q&A</h4>
                </div><!-- /.nv-od-tb-placholder -->
            </div><!-- /.nv-on-demand-toolbox -->
        </div><!-- /.nv-on-demand-video-portion -->

        <div class="nv-on-demand-meta-portion">
            <div class="nv-on-demand-meta-panel">
                <header class="nv-on-demand-meta-header">
                    <ul>
                        <li><a href title="All materials" class="active">All materials</a></li>
                        <li><a href title="Saved materials">Saved</a></li>
                    </ul>
                </header><!-- /.nv-on-demand-meta-header -->
                <div class="nv-on-demand-meta-panel-body">
                    <div class="nv-on-demand-meta-panel-body-sleeve">
                        <div class="nv-od-search-n-type-filter">
                            <div class="nv-form-item search-item">
                                <label>
                                    <input type="text" placeholder="Title">
                                    <span class="label-title">Search</span>
                                </label>
                            </div><!-- /nv-form-item -->
                            <div class="nv-form-item select-item">
                                <label>
                                    <select>
                                        <option>Webcast</option>
                                        <option>Slides</option>
                                        <option>Poster</option>
                                    </select>
                                    <span class="label-title">Type</span>
                                </label>
                            </div><!-- /nv-form-item -->
                        </div><!-- /.nv-od-search-n-type-filter -->
                        <div class="nv-od-toggle-n-clear">
                            <div class="nv-od-toggle">
                                <div class="nv-custom-toggle-checkbox">
                                    <label title="Turn on Autoplay">
                                        <input type="checkbox">
                                        <span class="nv-custom-toggle-checkbox-visual"></span>
                                    </label>
                                </div><!-- /.nv-custom-toggle-checkbox -->
                                <p>Show presentations without materials</p>
                            </div><!-- /.nv-od-toggle -->
                            <div class="nv-od-clear">
                                <a href>Clear all</a>
                            </div><!-- /.nv-od-clear -->
                        </div><!-- /.nv-od-toggle-n-clear -->

                        <div class="nv-on-demand-list">
                            <h2><strong>Session</strong>: Title lorem ipsum dolor sit amet</h2>
                            <h3><a href>Presentation title 1 lorem ipsum dolor sit amet</a></h3>
                            <p class="nv-od-list-note">No materials available on demand.</p>
                            <h3 class="active"><a href>Presentation title 2 lorem ipsum dolor sit amet</a></h3>
                            <nav class="nv-on-demand-list-nav">
                                <ul>
                                    <li>
                                        <a href title="Material 1"></a>
                                        <figure><img src="https://loremflickr.com/g/800/450/nature" alt="User name"></figure>
                                        <div class="od-txt-tools">
                                            <h3>Material 1</h3>
                                            <div class="od-tools">
                                                <a href class="bookmark" title="Save"></a>
                                            </div><!-- /.od-tools -->
                                        </div><!-- /.txt-tools -->
                                    </li>
                                    <li class="active">
                                        <a href title="Material 2"></a>
                                        <figure><img src="https://loremflickr.com/g/800/450/nature" alt="User name"></figure>
                                        <div class="od-txt-tools">
                                            <h3>Material 2</h3>
                                            <div class="od-tools">
                                                <a href class="bookmark saved" title="Saved, unsave?"></a>
                                            </div><!-- /.od-tools -->
                                        </div><!-- /.txt-tools -->
                                    </li>
                                    <li>
                                        <a href title="Material 3"></a>
                                        <figure><img src="https://loremflickr.com/g/800/450/nature" alt="User name"></figure>
                                        <div class="od-txt-tools">
                                            <h3>Material 2</h3>
                                            <div class="od-tools">
                                                <a href class="bookmark" title="Save"></a>
                                            </div><!-- /.od-tools -->
                                        </div><!-- /.txt-tools -->
                                    </li>
                                </ul>
                            </nav>

                            <h3><a href>Presentation title 3 lorem ipsum dolor</a></h3>
                            <nav class="nv-on-demand-list-nav">
                                <ul>
                                    <li>
                                        <a href title="Material 1"></a>
                                        <figure><img src="https://loremflickr.com/g/800/450/nature" alt="User name"></figure>
                                        <div class="od-txt-tools">
                                            <h3>Material 1</h3>
                                            <div class="od-tools">
                                                <a href class="bookmark" title="Save"></a>
                                            </div><!-- /.od-tools -->
                                        </div><!-- /.txt-tools -->
                                    </li>
                                    <li>
                                        <a href title="Material 2"></a>
                                        <figure><img src="https://loremflickr.com/g/800/450/nature" alt="User name"></figure>
                                        <div class="od-txt-tools">
                                            <h3>Material 2</h3>
                                            <div class="od-tools">
                                                <a href class="bookmark saved" title="Saved, unsave?"></a>
                                            </div><!-- /.od-tools -->
                                        </div><!-- /.txt-tools -->
                                    </li>
                                    <li>
                                        <a href title="Material 3"></a>
                                        <figure><img src="https://loremflickr.com/g/800/450/nature" alt="User name"></figure>
                                        <div class="od-txt-tools">
                                            <h3>Material 2</h3>
                                            <div class="od-tools">
                                                <a href class="bookmark" title="Save"></a>
                                            </div><!-- /.od-tools -->
                                        </div><!-- /.txt-tools -->
                                    </li>
                                    <li>
                                        <a href title="Material 4"></a>
                                        <figure><img src="https://loremflickr.com/g/800/450/nature" alt="User name"></figure>
                                        <div class="od-txt-tools">
                                            <h3>Material 2</h3>
                                            <div class="od-tools saved">
                                                <a href class="play" title="Play"></a>
                                                <a href class="delete" title="Remove from playlist"></a>
                                                <a href class="order" title="Reorder"></a>
                                            </div><!-- /.od-tools -->
                                        </div><!-- /.txt-tools -->
                                    </li>
                                </ul>
                            </nav>
                        </div><!-- /.nv-on-demand-list -->

                    </div><!-- /.nv-on-demand-meta-panel-body-sleeve -->
                </div><!-- /.nv-on-demand-meta-panel-body -->
                <div class="nv-on-demand-meta-footer">
                    <div class="nv-od-autoplay-toggle">
                        <div class="nv-custom-toggle-checkbox">
                            <label title="Turn on Autoplay">
                                <input type="checkbox">
                                <span class="nv-custom-toggle-checkbox-visual"></span>
                            </label>
                        </div><!-- /.nv-custom-toggle-checkbox -->
                        <p>Autoplay</p>
                    </div><!-- /.nv-od-autoplay-toggle -->
                    <div class="nv-od-prev-next">
                        <div class="nv-od-prev">
                            <a href class="prev"></a>
                            <div class="nv-prev-view">
                                <h6>PREVIOUS MATERIAL</h6>
                                <h4>Webcast title 1 lorem ipsum dolor sit amet consectetur</h4>
                            </div>
                        </div>
                        <a href class="next" title="Go to next presentation"></a>
                    </div><!-- /.nv-od-prev-next -->
                    <div class="nv-od-next-item">
                        <p>UP NEXT</p>
                        <h3>Material title 3 lorem ipsum dolor sit america</h3>
                    </div><!-- /.nv-od-next-item -->
                </div><!-- /.nv-on-demand-meta-footer -->
            </div><!-- /.nv-on-demand-meta-panel -->
        </div><!-- /.nv-on-demand-meta-portion -->
    </section><!-- /.nv-on-demand-wrap -->

    <!-- ON DEMAND -->




</div><!-- /nv-template-wrap -->
</div><!-- /.nv-template-content -->
</div><!-- /.nv-template-page -->
