import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ConferenceService } from '@navus/core/services/conference.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '@navus/ui/modal/modal.service';
import { SessionData } from '../models/sessionData';
import { Conference } from '@navus/core/classes/conference';
import { Color } from '@swimlane/ngx-charts';
import { TimeFormatPipe } from '@navus/ui/pipes/time-format.pipe';

@Component({
  selector: 'main [nv-conference-dashboard-on-site]',
  templateUrl: './conference-dashboard-on-site.component.html',
})
export class ConferenceDashboardOnSiteComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  conference: Conference;

  loadingOnSiteSessions: boolean;
  onSiteSessionData: SessionData[];

  chartColorScheme = {
    domain: ['#318AF1', '#2873A1', '#8ABAD6', '#32B799', '#279079', '#18707F', '#ED4649', '#F49092', '#AD465A', '#ED8747']
  } as Color;

  sessionParticipantsChartData: { name: string, value: number }[];
  sessionParticipantsLegend: { name: string, value: string, color: string }[];
  sessionParticipantsActiveEntries = [];

  lastRecordedDateTime: string;
  downloadingSessionAttendance = false;
  downloadingAllAttendance = false;

  private subscriptions: Subscription[] = [];

  constructor(private conferenceService: ConferenceService,
              private timeFormatPipe: TimeFormatPipe,
              private modalService: ModalService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.conferenceService.getConference(this.conferenceId)
          .subscribe((response) => {
            this.conference = response.data;

            this.getOnSiteSessions();
          }, (error) => {
            this.modalService.error({ errors: error });
          });
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  sessionParticipantsLegendLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.sessionParticipantsActiveEntries = this.sessionParticipantsChartData.filter(item => item.name === name);
  }

  sessionParticipantsLegendLabelDeactivate(item: any): void {
    this.sessionParticipantsActiveEntries = [];
  }

  downloadSessionAttendance() {
    this.downloadingSessionAttendance = true;
    this.conferenceService.downloadSessionAttendanceXls(this.conferenceId)
      .subscribe(
        () => {
          this.downloadingSessionAttendance = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.downloadingSessionAttendance = false;
        }
      );
  }

  downloadAllAttendance() {
    this.downloadingAllAttendance = true;
    this.conferenceService.downloadAllAttendanceXls(this.conferenceId)
      .subscribe(
        () => {
          this.downloadingAllAttendance = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.downloadingAllAttendance = false;
        }
      );
  }

  private getOnSiteSessions(): void {
    this.loadingOnSiteSessions = true;
    this.conferenceService.getConferenceOnSiteSessions(this.conferenceId, {
      from_date: this.conference.starts_at,
      to_date: this.conference.ends_at
    })
      .subscribe((response) => {
        this.loadingOnSiteSessions = false;
        this.onSiteSessionData = response.data;
        if (response.last_recorded_date_time) {
          this.lastRecordedDateTime = response.last_recorded_date_time;
        }

        this.populateSessionCharts();
      }, (error) => {
        this.loadingOnSiteSessions = false;
        this.modalService.error({ errors: error });
      });
  }

  private populateSessionCharts(): void {
    this.sessionParticipantsChartData = this.onSiteSessionData
      .filter(session => session.total_participants)
      .map(session => ({
        name: session.name,
        value: session.total_participants
      }));
    this.sessionParticipantsLegend = this.onSiteSessionData
      .filter(session => session.total_participants)
      .map((session, index) => ({
        name: session.name,
        value: session.total_participants.toString(),
        color: this.chartColorScheme.domain[index % 10]
      }));
  }
}
