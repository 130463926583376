<div class="nv-modal small">
    <div class="nv-modal-header">
        <h4 class="nv-modal-title">Active Windows</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body">
        <table class="nv-contacts nv-table">
            <tbody>
                <tr *ngFor="let votingPopup of votingPopups">
                    <td class="nv-clickable" (click)="open(votingPopup)">{{ votingPopup }}</td>
                    <td></td>
                </tr>
                <tr>
                    <td class="nv-clickable" (click)="open()" translate>Open in new window</td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

