import { Country } from './country';
import { User } from './user';

export class Delegate {
  id: number;
  imported_id: string;
  code: string;
  title: string;
  first_name: string;
  last_name: string;
  name: string;
  email: string;
  address1: string;
  city: string;
  website: string;
  additional_info: string;
  zip: string;
  abstract: string;
  poster: string;
  status: string;
  biography: string;

  conference_ticket_type_id: string;
  conference_id: number;
  country_id: number;
  country?: Country;
  user_id: number;
  user?: User;
  contact_info?: any;
}
