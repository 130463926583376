<div class="nv-modal small" style="width: 450px">
  <div class="nv-modal-header">
      <h4 class="nv-modal-title">Edit payment info</h4>
      <button type="button" aria-label="Close" (click)="close()"></button>
  </div>
  <div class="nv-modal-body">
      <form [formGroup]="cardForm" class="nv-row gap-30 propagate">
          <div class="nv-column desk-12">
              <div class="nv-column-content">
                  <nv-input
                          formControlName="name"
                          label="Name on the card"
                          required
                  ></nv-input>
              </div><!-- /.nv-column-content -->
          </div><!-- /.nv-column -->
          <div class="nv-column desk-12">
              <div class="nv-column-content">
                  <nv-input
                          type="card"
                          formControlName="number"
                          label="Card number"
                          required
                          [errorMessages]="{ ccNumber: 'Please enter a valid card number' }"
                  ></nv-input>
              </div><!-- /.nv-column-content -->
          </div><!-- /.nv-column -->
          <div class="nv-column desk-4">
              <div class="nv-column-content">
                  <nv-input
                          formControlName="exp_month"
                          label="Exp. Month"
                          required
                  ></nv-input>
              </div><!-- /.nv-column-content -->
          </div><!-- /.nv-column -->
          <div class="nv-column desk-4">
              <div class="nv-column-content">
                  <nv-input
                          formControlName="exp_year"
                          label="Exp. Year"
                          required
                  ></nv-input>
              </div><!-- /.nv-column-content -->
          </div><!-- /.nv-column -->
          <div class="nv-column desk-4">
              <div class="nv-column-content">
                  <nv-input
                          formControlName="cvc"
                          label="CVC"
                          required
                  ></nv-input>
              </div><!-- /.nv-column-content -->
          </div><!-- /.nv-column -->
      </form>
  </div>
  <div class="nv-modal-footer">
      <div class="nv-btn-box gap-10 flow-end">
          <nv-button size="small" color="neutral" (click)="close()">Cancel</nv-button>
          <nv-button size="small" color="primary" (click)="save()" [loading]="savingCard" [disabled]="cardForm.invalid" translate>Save</nv-button>
      </div>
  </div>
</div>

