import { Routes } from '@angular/router';
import { OrganizationGuard } from '../../guards/organization.guard';
import { VotingListPageComponent } from './pages/voting-list/voting-list-page.component';
import { VotingDetailsPageComponent } from './pages/voting-details/voting-details-page.component';

export const VotingRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'votings'
  },
  {
    path: 'votings',
    component: VotingListPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Votings List'
    }
  },
  {
    path: 'votings/:presentationId',
    component: VotingDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Voting Details'
    }
  },
  {
    path: 'votings',
    component: VotingDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Voting Details'
    }
  },
];
