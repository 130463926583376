import { Routes } from '@angular/router';
import { OrganizationGuard } from '../../guards/organization.guard';
import { LivestreamingListPageComponent } from './livestreaming-list/livestreaming-list-page.component';
import { LivestreamingDetailsPageComponent } from './livestreaming-details/livestreaming-details-page.component';

export const LivestreamingRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'livestreams',
  },
  {
    path: 'livestreams',
    component: LivestreamingListPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Livestreaming'
    }
  },
  {
    path: 'livestream/:locationId',
    component: LivestreamingDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Livestreaming Details'
    }
  }
];
