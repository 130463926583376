import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nv-block-modal',
  templateUrl: './block-modal.component.html'
})
export class BlockModalComponent implements OnInit {
  @Input() blockId: number;

  constructor() { }

  ngOnInit() {

  }

}
