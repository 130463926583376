<section class="nv-section guttered nv-register-form nv-front-registration-form">
    <div class="nv-port">
        <div class="nv-row spaced-top v-middle flow-center" *ngIf="banner">
            <div class="nv-column desk-6 tab-8 phab-12">
                <div class="nv-column-content">
                    <div class="nv-banner-block" *ngIf="banner?.resource_url && banner?.external_url">
                        <a (click)="bannerClick()" target="_blank">
                            <img [src]="banner?.resource_url" alt>
                        </a>
                    </div>
                    <div class="nv-banner-block" *ngIf="banner?.resource_url && !banner?.external_url">
                        <img [src]="banner?.resource_url" alt>
                    </div>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
        <div class="nv-row spaced v-middle flow-center">
            <div class="nv-column desk-6 tab-8 phab-12">
                <div class="nv-column-content">
                    <div class="nv-onboarding-dual-pane">
                        <form [formGroup]="authForm" class="nv-login-form" *ngIf="!registerWithCode">
                            <nv-button
                                *ngIf="mode !== 'undecided'"
                                iconPl="&#xe949;"
                                size="small"
                                color="passive"
                                [onlyIcon]="true"
                                (click)="mode = 'undecided'"
                            ></nv-button>
                            <div class="nv-register-form-brand">
                                <figure style="width: 50%;">
                                    <img src="assets/logo.svg" alt="Navus">
                                </figure>
                            </div><!-- /.nv-register-form-brand -->
                            <h3 style="text-align: center;" *ngIf="mode === 'login'">Login</h3>
                            <h3 style="text-align: center;" *ngIf="mode === 'registration'">Create your platform account</h3>
                            <h3 style="text-align: center;" *ngIf="mode === 'undecided'">Enter the platform</h3>
                            <div *ngIf="mode === 'login'">
                                Please input your login details to continue
                            </div>
                            <ul *ngIf="mode === 'registration'">
                                <li>Fast register for this and other events</li>
                                <li>Access valuable content</li>
                                <li>Benefit if you organise your own events</li>
                            </ul>
                            <div *ngIf="mode === 'undecided'">
                                Please input your email to start registration / login
                            </div>
                            <div class="nv-row semi-spaced-top gap-24 propagate">
                                <div class="nv-column desk-12" *ngIf="!accessCodeRequested">
                                    <div class="nv-column-content">
                                        <nv-input 
                                            formControlName="email"
                                            label="Email"
                                            (keyup.enter)="keyEvent($event)"
                                            [errorMessages]="{ 
                                                required:'Email is required' | translate, 
                                                pattern: 'Please enter a valid email address' | translate 
                                            }"
                                            [disabled]="mode !== 'undecided'"
                                        ></nv-input>
                                        <small *ngIf="mode !== 'undecided'" style="font-weight: 600;">
                                            <a (click)="mode = 'undecided'">Change email</a>
                                        </small>

                                        <!-- <div class="nv-input-error-box" *ngIf="userExists && mode === 'registration'">
                                            <div class="nv-input-error single">
                                                <div>An account with this email already exists. Please <a (click)="goToLogin()">login</a> with this existing address or register with a different email address.</div>
                                            </div>
                                        </div> -->
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-6 tab-12" *ngIf="mode === 'registration'">
                                    <div class="nv-column-content">
                                        <nv-input formControlName="first_name"
                                                label="{{'First name' | translate}}"
                                                (keyup.enter)="keyEvent($event)"
                                                [errorMessages]="{ 
                                                    required: 'First name is required' | translate, 
                                                    pattern: 'First name cannot be longer that 30 characters' | translate
                                                }"
                                        ></nv-input>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-6 tab-12" *ngIf="mode === 'registration'">
                                    <div class="nv-column-content">
                                        <nv-input formControlName="last_name"
                                                label="{{'Last name' | translate}}"
                                                (keyup.enter)="keyEvent($event)"
                                                [errorMessages]="{ 
                                                    required: 'Last name is required' | translate, 
                                                    pattern: 'Last name cannot be longer that 30 characters' | translate
                                                }"
                                        ></nv-input>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12" *ngIf="mode === 'registration'">
                                    <div class="nv-column-content">
                                        <nv-select formControlName="country_id"
                                                label="{{'Country' | translate}}"
                                                [required]="true"
                                                [options]="filteredCountries"
                                                [showSearch]="true"
                                                (searched)="filterCountries($event)"
                                        ></nv-select>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12" *ngIf="mode === 'registration'">
                                    <div class="nv-column-content">
                                        <nv-input formControlName="institution"
                                                label="{{'Institution / Company / Society' | translate}}"
                                                (keyup.enter)="keyEvent($event)"
                                                [errorMessages]="{ 
                                                    pattern: 'Institution cannot be longer that 30 characters' | translate 
                                                }"
                                        ></nv-input>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12" *ngIf="!accessCodeRequested && mode !== 'undecided'">
                                    <div class="nv-column-content">
                                        <div class="nv-form-item nv-password-item">
                                            <nv-input type="password"
                                                    formControlName="password"
                                                    label="{{'Password' | translate}}"
                                                    (keyup.enter)="keyEvent($event)"
                                                    [showPassword]="true"
                                                    [errorMessages]="{ 
                                                        required: 'Password is required' | translate, 
                                                        pattern: 'Password is not valid' | translate 
                                                    }"
                                            ></nv-input>
                                        </div><!-- /nv-form-item -->
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12" *ngIf="mode === 'login' && !requestingAccessCode && !accessCodeRequested">
                                    <div class="nv-column-content">
                                        <div>
                                            If you forgot your password you can login with <a (click)="forgotPassword()">temp access code</a>
                                            <br>
                                            <br>
                                        </div>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12" *ngIf="accessCodeRequested">
                                    <div class="nv-column-content">
                                        <div class="nv-form-item nv-password-item">
                                            <nv-input
                                                    formControlName="access_code"
                                                    label="{{'Access Code' | translate}}"
                                                    (keyup.enter)="keyEvent($event)"
                                            ></nv-input>
                                            <div class="nv-access-code-request" (click)="accessCodeRequested = false" translate>Back to login</div>
                                        </div><!-- /nv-form-item -->
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12" *ngIf="mode === 'registration'">
                                    <div class="nv-column-content">
                                        <div class="nv-checkbox-item">
                                            <label>
                                                    <span class="nci-check-port">
                                                        <input type="checkbox" formControlName="terms_and_conditions">
                                                        <span class="nci-check-visual"></span>
                                                    </span><!-- /.nci-check-port -->
                                                <span class="nci-value">
                                                    I have read and accept <a href="/assets/legal/Navus_AG_TC_Digital_Services_19_02_2020.pdf" target="_blank"><strong>the terms and conditions</strong></a><br>
                                                    <!--Read our Privacy Policy <a href="https://navus.io/wp-content/uploads/2020/02/Privacy-and-Cookies-Policy-Navus-1.pdf" target="_blank"><strong>here</strong></a>.-->
                                                </span>
                                            </label>
                                        </div><!-- /nv-checkbox-item -->
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <nv-button 
                                            *ngIf="mode === 'login'"
                                            color="secondary" 
                                            size="large" 
                                            class="fw radius-high" 
                                            [loading]="loading || requestingAccessCode" 
                                            (click)="login()"
                                        >
                                            Login
                                        </nv-button><!-- /.nv-btn -->
                                        <nv-button 
                                            *ngIf="mode === 'registration'"
                                            color="secondary" 
                                            size="large" 
                                            class="fw radius-high" 
                                            [loading]="loading" 
                                            (click)="register()"
                                        >
                                            Continue
                                        </nv-button><!-- /.nv-btn -->
                                        <nv-button 
                                            *ngIf="mode === 'undecided'"
                                            color="secondary" 
                                            size="large" 
                                            class="fw radius-high" 
                                            [loading]="loading" 
                                            (click)="next()"
                                        >
                                            Next
                                        </nv-button><!-- /.nv-btn -->
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                            </div><!-- /.nv-row -->
                            
                            <p style="text-align: center">If you are registered delegate with PIN/Badge code, you can use our fast registration <a (click)="goToCodeRegistration()"><strong>here</strong></a></p>
                            <div class="nv-foo-signature"><span></span></div>
                        </form><!-- /.odp-pane-right -->

                        <form [formGroup]="codeForm" class="nv-login-form" *ngIf="registerWithCode">
                            <nv-button
                                iconPl="&#xe949;"
                                size="small"
                                color="passive"
                                [onlyIcon]="true"
                                (click)="registerWithCode = false"
                            ></nv-button>
                            <div class="nv-register-form-brand">
                                <figure style="width: 50%;">
                                    <img src="assets/logo.svg" alt="Navus">
                                </figure>
                            </div><!-- /.nv-register-form-brand -->
                            <h3 style="text-align: center;">Connect with your platform account</h3>
                            <ul>
                                <li>Quickly authenticate yourself as an event attendee</li>
                                <li>Access valuable content</li>
                            </ul>
                            
                            <div class="nv-row semi-spaced-top gap-24 propagate">
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <nv-input 
                                            formControlName="email"
                                            label="Email"
                                            (keyup.enter)="keyEvent($event)"
                                            [errorMessages]="{ 
                                                required:'Email is required' | translate, 
                                                pattern: 'Please enter a valid email address' | translate 
                                            }"
                                        ></nv-input>

                                        <div class="nv-input-error-box" *ngIf="userExists">
                                            <div class="nv-input-error single">
                                                <div>An account with this email already exists. Please <a (click)="goToLogin()">login</a> with this existing address or register with a different email address.</div>
                                            </div>
                                        </div>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <div class="nv-form-item nv-password-item">
                                            <nv-input
                                                    formControlName="code"
                                                    label="{{'Access/PIN code' | translate}}"
                                                    (keyup.enter)="keyEvent($event)"
                                            ></nv-input>
                                        </div><!-- /nv-form-item -->
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <nv-checkbox
                                            [(ngModel)]="useCodeForPassword"
                                            [ngModelOptions]="{ standalone: true }"
                                            (ngModelChange)="toggleUseCodeForPassword()"
                                            [label]="'Use Access/PIN code as the password' | translate"
                                        ></nv-checkbox>
                                    </div>
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12" *ngIf="!useCodeForPassword">
                                    <div class="nv-column-content">
                                        <div class="nv-form-item nv-password-item">
                                            <nv-input
                                                    formControlName="password"
                                                    label="{{'Password' | translate}}"
                                                    (keyup.enter)="keyEvent($event)"
                                            ></nv-input>
                                        </div><!-- /nv-form-item -->
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <div class="nv-checkbox-item">
                                            <label>
                                                <span class="nci-check-port">
                                                    <input type="checkbox" formControlName="terms_and_conditions">
                                                    <span class="nci-check-visual"></span>
                                                </span><!-- /.nci-check-port -->
                                                <span class="nci-value">
                                                    I have read and accept <a href="/assets/legal/Navus_AG_TC_Digital_Services_19_02_2020.pdf" target="_blank"><strong>the terms and conditions</strong></a><br>
                                                    <!--Read our Privacy Policy <a href="https://navus.io/wp-content/uploads/2020/02/Privacy-and-Cookies-Policy-Navus-1.pdf" target="_blank"><strong>here</strong></a>.-->
                                                </span>
                                            </label>
                                        </div><!-- /nv-checkbox-item -->
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <nv-button 
                                            color="secondary" 
                                            size="large"
                                            class="fw radius-high" 
                                            [loading]="loading" 
                                            (click)="registerWithDelegateCode()" 
                                            [disabled]="codeForm.invalid"
                                        >
                                            Enter
                                        </nv-button><!-- /.nv-btn -->
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                            </div><!-- /.nv-row -->
                            <div class="nv-foo-signature"><span></span></div>
                        </form><!-- /.odp-pane-right -->
                    </div><!-- /.nv-onboarding-dual-pane -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</section><!-- /nv-section -->
