import { Component } from '@angular/core';

@Component({
    selector: 'app-program-light-page',
    templateUrl: './program-light.component.html'
})
export class ProgramLightComponent {

    constructor() { }

}
