import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { Package } from '../../../navus-core/classes/package';
import { PackageService } from '../../../navus-core/services/package.service';

@Component({
  selector: 'nv-select-package-details-modal',
  templateUrl: './select-package-modal.component.html'
})
export class SelectPackageModalComponent implements OnInit {
  packages: Package[] = [];
  period: string = 'year';

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private packageService: PackageService
  ) { }

  ngOnInit() {
    this.getPackages();
  }

  getPackages() {
    this.packageService.getPackages()
      .subscribe(
        (response: any) => {
          this.packages = response.data.sort(
            (a: Package, b: Package) => {
              if (a.order > b.order) {
                return 1;
              }
              if (a.order < b.order) {
                return -1;
              }
              return 0;
            }
          );
        },
        () => { }
      );
  }

  togglePeriod() {
    if (this.period === 'year') {
      this.period = 'month';
    } else {
      this.period = 'year';
    }
  }

  selectPackage(packageId: string) {
    this.activeModal.close({ packageId });
  }

  close() {
    this.activeModal.dismiss();
  }

}
