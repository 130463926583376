<div class="nv-playlist-slideshow-wrap">
    <aside class="nv-slidetrack">
        <div class="st-search">
            <form class="st-form">
                <input type="text">
                <button type="submit"></button>
            </form>
        </div>
        <!-- /.st-search -->
        <div class="st-slide-scroll">
            <ul>
                <li>
                    <a>
                        <span class="st-order">1</span>
                        <img src="../../assets/dummy-slide.png" alt="slide name">
                    </a>
                </li>
                <li>
                    <a>
                        <span class="st-order">2</span>
                        <img src="../../assets/dummy-slide.png" alt="slide name">
                    </a>
                </li>
                <li>
                    <a>
                        <span class="st-order">3</span>
                        <img src="../../assets/dummy-slide.png" alt="slide name">
                    </a>
                </li>
                <li>
                    <a class="active">
                        <span class="st-order">4</span>
                        <img src="../../assets/dummy-slide.png" alt="slide name">
                    </a>
                </li>
                <li>
                    <a>
                        <span class="st-order">5</span>
                        <img src="../../assets/dummy-slide.png" alt="slide name">
                    </a>
                </li>
                <li>
                    <a>
                        <span class="st-order">6</span>
                        <img src="../../assets/dummy-slide.png" alt="slide name">
                    </a>
                </li>
                <li>
                    <a>
                        <span class="st-order">7</span>
                        <img src="../../assets/dummy-slide.png" alt="slide name">
                    </a>
                </li>
            </ul>
        </div>
    </aside>
    <main class="nv-slideshow-main-port">
        <!-- Upper / fixed chunk -->
        <div class="nv-smp-upper">
            <nav class="smp-uppermost-controls">
                <ul>
                    <li><a>Hide Slides</a></li> <!--  toggles text node with 'Show Slides' and toggle additional class 'hidden' on element 'nv-slidetrack' -->
                    <li><a>End Presentation</a></li>
                </ul>
            </nav><!-- /.smp-uppermost-controls -->

            <div class="smp-slideshow-control-bar">
                <div class="smp-cb-side left">
                    <span class="cb-item time">42:25</span>
                    <a class="cb-item play"></a> <!-- toggle with class 'pause' -->
                    <a class="cb-item reload"></a>
                </div><!-- /.smp-cb-side -->
                <div class="smp-cb-side right">
                    <a class="cb-item more"></a>
                </div><!-- /.smp-cb-side -->
            </div><!-- /.smp-slideshow-control-bar -->
        </div><!-- /.nv-smp-upper -->

        <!-- Lower / flexy chunk -->
        <div class="nv-smp-lower">
            <div class="nv-smp-lower-gutter">
                <figure class="smp-slide">
                    <img src="../../assets/dummy-slide.png" alt="slide name">
                </figure><!-- /.smp-slide -->

                <div class="smp-slide-paging">
                    <a class="smp-prev"></a>
                    <p>Slide <strong>2</strong> of 15</p>
                    <a class="smp-next"></a>
                </div><!-- /.smp-slide-paging -->

                <div class="smp-presentation-notes">
                    <h3>Presentation Notes</h3>
                    <div class="pn-port">
                        <p>So, let’s go to the next part about the lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin et nunc
                            id purus rhoncus molestie. Fusce justo mauris, viverra a malesuada id, porttitor ut lectus. Proin tempus
                            ante id nisi scelerisque, sed suscipit mauris ultricies. Vestibulum rhoncus leo orci, tempus fermentum orci
                            lobortis at. Phasellus vel imperdiet magna. Aenean ut dui vel sapien tincidunt rutrum.</p>

                        <p>Nunc non nibh elit.Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                    </div><!-- /.pn-port -->
                </div><!-- /.smp-presentation-notes -->
            </div><!-- /.nv-smp-lower-gutter -->
        </div><!-- /.nv-smp-lower -->
    </main><!-- /.nv-slideshow-main-port -->
</div><!-- /.nv-playlist-slideshow-wrap -->