import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nv-chart',
  templateUrl: './chart.component.html'
})
export class ChartComponent implements OnInit {

  @Input() chartDataObj: { chartData: any, xValues: any };
  @Input() chartType: string;
  @Input() chartLegend: boolean;

  public chartOptions: any;

  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true
      }],
      yAxes: [{
        stacked: true
      }]
    },
    legend: {
      display: true,
      labels: {
        fontColor: 'rgb(255, 99, 132)'
      }
    },
    scaleShowValues: true,
    scaleValuePaddingX: 10,
    scaleValuePaddingY: 10
  };

  public lineChartOptions: any = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          suggestedMin: 0
        }
      }]
    },
    pan: {
      enabled: true,
      mode: 'x'
    },
    zoom: {
      enabled: true,
      mode: 'x',
      limits: {
        max: 10,
        min: 0.5
      }
    }
  };

  constructor() { }

  ngOnInit() {
    this.chartOptions = this.chartType === 'bar' ? this.barChartOptions : this.lineChartOptions;
  }

}
