<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Voting</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-10">
                <nv-button [routerLink]="['/o', organizationId, 'conference', conferenceId, 'program', 'presentation', presentationId]" translate>Manage Presentation</nv-button>
                <nv-button (click)="close()" color="passive" translate>Close</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
</div>
<div class="nv-config-content-port">
    <div class="nv-global-loading on" [class.on]="loadingQuestions || loadingPresentation">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24 small-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h2>{{ presentation.title }}</h2>
                        <h4>Questions</h4>
                        <div class="nv-row config-plates clean gap-24 small-spaced-top spaced-bottom">
                            <div class="nv-column desk-12" *ngIf="votingQuestions.length > 0">
                                <div class="nv-column-content">
                                    <table class="nv-contacts nv-table">
                                        <thead>
                                        <tr>
                                            <th translate>Title</th>
                                            <th style="width: 100px" translate>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let question of votingQuestions" class="nv-clickable">
                                            <td (click)="editQuestion(question.id)">{{ question.title }}</td>
                                            <td (click)="editQuestion(question.id)" translate>{{ question.status }}</td>
                                            <td>
                                                <nv-button size="small" *ngIf="question.status === 'published'"
                                                        (click)="setQuestionStatus(question, 'active'); runQuestion(question)">
                                                    Run
                                                </nv-button>
                                                &nbsp;
                                                <nv-button size="small" *ngIf="question.status === 'active'"
                                                        (click)="setQuestionStatus(question, 'finished')">
                                                    Stop
                                                </nv-button>
                                                &nbsp;
                                                <nv-button size="small" *ngIf="question.status === 'active' || question.status === 'published'"
                                                           (click)="runQuestion(question)">
                                                    Open
                                                </nv-button>
                                                &nbsp;
                                                <nv-button size="small" *ngIf="question.status === 'finished'"
                                                           (click)="runQuestion(question)">
                                                    Results
                                                </nv-button>
                                                &nbsp;
                                                <nv-button size="small"
                                                           (click)="deleteQuestion(question.id)">
                                                    Delete
                                                </nv-button>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div>&nbsp;</div>
                                </div>
                            </div>
                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <nv-button (click)="addQuestion()" translate>Add question</nv-button>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
