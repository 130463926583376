import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MeetingRoom } from '@navus/core/classes/meeting-room';
import { MeetingRoomService } from '@navus/core/services/meeting-room.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { UserService } from '@navus/core/services/user.service';
import { User } from '@navus/core/classes/user';
import { Location } from '@navus/core/classes/location';
import { Block } from '@navus/core/classes/block';
import { LocationService } from '@navus/core/services/location.service';
import { BlockService } from '@navus/core/services/block.service';

@Component({
  selector: 'main [nv-meeting-room-details-page]',
  templateUrl: './meeting-room-details-page.component.html',
  styles: []
})
export class MeetingRoomDetailsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  meetingRoomId: string;
  meetingRoom: MeetingRoom;
  meetingRoomForm: FormGroup;
  currentUser: User;
  typeOptions = [
    { id: 'internal', name: 'Internal' },
    { id: 'external', name: 'External' },
  ];
  statusOptions = [
    { id: 'online', name: 'online' },
    { id: 'offline', name: 'offline' },
    { id: 'idle', name: 'idle' },
  ];
  entityTypeOptions = [
    // { id: 'User', name: 'User' },
    // { id: 'Sponsor', name: 'Sponsor' },
    // { id: 'Delegate', name: 'Delegate' },
    // { id: 'Presentation', name: 'Presentation' },
    // { id: 'Location', name: 'Live room' },
    { id: 'Conference', name: 'Waiting room' },
    // { id: 'Block', name: 'Break-out' },
  ];

  locations: Location[] = [];
  locationsLoading: boolean = false;
  locationsPagination: { page?: number, perPage: number, total?: number } = {
    perPage: 30
  };
  locationsSearchTerm: string = '';

  blocks: Block[] = [];
  blocksLoading: boolean = false;
  blocksPagination: { page?: number, perPage: number, total?: number } = {
    perPage: 30
  };
  blocksSearchTerm: string = '';

  loadingMeetingRoom: boolean = false;
  savingMeetingRoom: boolean = false;
  creatingMeetingRoomLivestream: boolean = false;
  unsavedChanges: boolean = false;

  subscriptions: Subscription[] = [];

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private meetingRoomService: MeetingRoomService,
    private userService: UserService,
    private locationService: LocationService,
    private blockService: BlockService,
    private modalService: ModalService,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    this.initForm();

    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.meetingRoomId = data.meetingRoomId;
        this.getMeetingRoom();
      }
    );
    this.subscriptions.push(subscription);

    const currentUserSubscription = this.userService.currentUser
      .subscribe(
        (user) => {
          this.currentUser = user;
        },
        (error) => { }
      );
    this.subscriptions.push(currentUserSubscription);

    const changeSubscription = this.meetingRoomForm.valueChanges.subscribe(
      () => {
        this.unsavedChanges = true;
      }
    );
    this.subscriptions.push(changeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.meetingRoomForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      entity_id: ['', [Validators.required]],
      entity_type: ['', [Validators.required]],
      room_name: ['', [Validators.required]],
      type: ['internal', [Validators.required]],
      max_participants: [10, [Validators.required]],
      rtmp_url: [''],
      rtmp_key: [''],
      status: ['online', [Validators.required]],
    });
    this.unsavedChanges = false;

    this.meetingRoomForm.get('title').valueChanges
      .subscribe(
        (value) => {
          this.meetingRoomForm.get('room_name')
            .setValue(value);
        }
      )

    this.meetingRoomForm.get('entity_type').valueChanges
      .subscribe(
        (value) => {
          if (value === 'Location') {
            this.getLocations();
          } else if (value === 'Block') {
            this.getBlocks();
          } else if (value === 'Conference') {
            this.meetingRoomForm.get('entity_id').setValue(this.conferenceId);
          }
        }
      )
  }

  getMeetingRoom() {
    if (this.meetingRoomId) {
      this.loadingMeetingRoom = true;
      this.meetingRoomService
        .getMeetingRoom(this.meetingRoomId)
        .subscribe(
          (response: { data: MeetingRoom, meta: any }) => {
            this.meetingRoom = response.data;
            this.meetingRoomForm.patchValue(response.data);

            this.loadingMeetingRoom = false;
            setTimeout(() => { this.unsavedChanges = false; }, 500);
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingMeetingRoom = false;
          }
        );
    }
  }

  getLocations(append: boolean = false) {
    if (append && this.locationsPagination.total && this.locations.length >= this.locationsPagination.total) { return; }

    this.locationsPagination.page = append ? this.locationsPagination.page + 1 : 1;

    const params: any = {
      page: this.locationsPagination.page,
      per_page: this.locationsPagination.perPage,
      sort_by: 'name',
      sort_direction: 'asc',
    };
    if (this.locationsSearchTerm !== '') {
      params.name = this.locationsSearchTerm;
    }
    this.locationsLoading = !append;
    this.locationService
      .getConferenceLocations(this.conferenceId, params)
      .subscribe(
        (response: { data: Location[], meta: any }) => {
          if (append) {
            this.locations = this.locations.concat(response.data);
          } else {            
            this.locations = response.data;
            this.locationsPagination.total = response.meta.pagination.total;
          }
          this.locationsLoading = false;
        },
        () => {
          this.locationsLoading = false;
        }
      );
  }

  getBlocks(append: boolean = false) {
    if (append && this.blocksPagination.total && this.blocks.length >= this.blocksPagination.total) { return; }

    this.blocksPagination.page = append ? this.blocksPagination.page + 1 : 1;

    const params: any = {
      page: this.blocksPagination.page,
      per_page: this.blocksPagination.perPage,
      sort_by: 'name',
      sort_direction: 'asc',
    };
    if (this.blocksSearchTerm !== '') {
      params.name = this.blocksSearchTerm;
    }
    this.blocksLoading = !append;
    this.blockService
      .getConferenceBlocks(this.conferenceId, params)
      .subscribe(
        (response: { data: Block[], meta: any }) => {
          if (append) {
            this.blocks = this.blocks.concat(response.data);
          } else {            
            this.blocks = response.data;
            this.blocksPagination.total = response.meta.pagination.total;
          }
          this.blocksLoading = false;
        },
        () => {
          this.blocksLoading = false;
        }
      );
  }

  createRtmp() {
    if (this.meetingRoomId) { 
      this.creatingMeetingRoomLivestream = true;
      this.meetingRoomService
      .createMeetingRoomLivestream(this.meetingRoomId)
      .subscribe(
        (response: { data: any, meta: any }) => {
          this.meetingRoom = response.data;
          this.meetingRoomForm.patchValue(response.data);

          this.creatingMeetingRoomLivestream = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.creatingMeetingRoomLivestream = false;
        }
      );      
    }
  }

  save() {
    if (this.meetingRoomForm.invalid) { return; }

    this.savingMeetingRoom = true;
    if (this.meetingRoomId) {
      this.meetingRoomService
        .updateMeetingRoom(this.meetingRoomId, this.meetingRoomForm.value)
        .subscribe(
          (response) => {
            this.meetingRoom = response['data'];
            this.toastService.success('Company successfully saved.', {});
            this.unsavedChanges = false;
            this.savingMeetingRoom = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingMeetingRoom = false;
          }
        );
    } else {
      this.meetingRoomService
        .createConferenceMeetingRoom(this.conferenceId, this.meetingRoomForm.value)
        .subscribe(
          (response) => {
            this.meetingRoom = response['data'];
            this.toastService.success('Company successfully created.', {});
            this.router.navigate([this.meetingRoom.id], { relativeTo: this.route });
            this.savingMeetingRoom = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingMeetingRoom = false;
          }
        );
    }
  }

  close() {
    if (this.unsavedChanges) {
      this.modalService.defaultModal({
        title: 'Unsaved changes',
        body: 'Are you sure you want to leave the page without saving changes?',
        size: 'small',
        buttons: [
          {
            text: 'Stay',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Leave',
            color: 'primary',
            handler: () => {
              this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'meeting-rooms', 'meeting-rooms']);
            }
          }
        ]
      });
    } else {
      this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'meeting-rooms', 'meeting-rooms']);
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Meeting Room Deletion',
      body: 'Are you sure you want to delete this meeting room?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.meetingRoomService
              .deleteMeetingRoom(this.meetingRoomId)
              .subscribe(
                () => {
                  this.close();
                },
                (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

}
