import { Component } from '@angular/core';

@Component({
    selector: 'app-config-layout-page',
    templateUrl: './config-layout.component.html'
})
export class ConfigLayoutComponent {

    constructor() { }

}
