import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const fullLocation = window.location.host.replace('www.', '');
        const parts = fullLocation.split('.');
        const slug: string = parts[0];

        // console.log(fullLocation);

        const headersConfig = {};

        if (!req.headers.has('Accept')) {
            headersConfig['Accept'] ='application/json';
        }
        if (!req.headers.has('Content-Type')) {
            headersConfig['Content-Type'] ='application/json';
        }

        if (req.headers.has('Slug')) {
            headersConfig['Slug'] = req.headers.get('Slug');
        } else if (slug) {
            headersConfig['Slug'] = slug;
        }

        const token = localStorage.getItem('jwtToken');
        if (token) {
            headersConfig['Authorization'] = `Bearer ${token}`;
        }

        const request = req.clone({ setHeaders: headersConfig });

        return next.handle(request);
    }
}
