import { NgModule } from '@angular/core';
import { NavusCoreModule } from '@navus/core/navus-core.module';
import { NavusUiModule } from '@navus/ui/navus-ui.module';
import { SharedModule } from '../../../shared';
import { ChatSettingsPageComponent } from './pages/chat-settings/chat-settings-page.component';
import { QaSettingsPageComponent } from './pages/qa-settings/qa-settings-page.component';
import { VotingSettingsPageComponent } from './pages/voting-settings/voting-settings-page.component';

@NgModule({
  imports: [
    NavusCoreModule.forRoot(),
    NavusUiModule,
    SharedModule
  ],
  declarations: [
    ChatSettingsPageComponent,
    QaSettingsPageComponent,
    VotingSettingsPageComponent
  ],
  entryComponents: [],
  providers: []
})
export class NetworkingModule {
}
