<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>DASHBOARD</h1>
        </div><!-- /.tnt-left -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav  vertical-break-tab">
                <ul>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'dashboard', 'totals']">
                            Totals
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'dashboard', 'live']">
                            Live
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'dashboard', 'on-demand']">
                            On-demand
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'dashboard', 'on-site']">
                            On-site
                        </a>
                    </li>
                    <li>
                        <a class="active">
                            Sponsor
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>

<div class="nv-config-content-port">
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24 small-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h4 *ngIf="lastRecordedDateTime" class="nv-chart-time-badge"><span>Time of
                            synchronization: <strong>{{ lastRecordedDateTime | nvDate:'MMM dd, HH:mm'  }} GMT</strong></span></h4>

                        <div class="nv-row config-plates clean gap-24 propagate">
                            <div class="nv-column desk-4">
                                <div class="nv-column-content">
                                    <nv-datepicker
                                            [(ngModel)]="dateFrom"
                                            type="calendar"
                                            label="From"
                                            required
                                    ></nv-datepicker>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-4">
                                <div class="nv-column-content">
                                    <nv-datepicker
                                            [(ngModel)]="dateTo"
                                            type="calendar"
                                            label="To"
                                            required
                                    ></nv-datepicker>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-4">
                                <div class="nv-column-content">
                                    <nv-button size="medium" (click)="applyDateRange()">
                                        Apply Range
                                    </nv-button>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                        </div>

                        <div class="nv-row config-plates clean gap-50 propagate">

                            <div class="nv-global-loading on"
                                 [class.on]="loadingBrowsingSponsors">
                                <div class="nv-spinner"></div>
                            </div><!-- /.nv-global-loading -->

                            <ng-container *ngIf="!loadingBrowsingSponsors && overallChartData?.length">
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content nv-dashboard-chart-no-pointer">
                                        <h3 class="light">Overall data</h3>
                                        <ngx-charts-number-card
                                                [valueFormatting]="formatNumber"
                                                [scheme]="chartColorScheme"
                                                [results]="overallChartData"
                                                [cardColor]="chartCardColor">
                                        </ngx-charts-number-card>
                                    </div><!-- nv-column-content-->
                                </div><!-- nv-column -->
                            </ng-container>

                            <ng-container *ngIf="!loadingBrowsingSponsors && viewsPerSponsorChartData?.length">
                                <div class="nv-column desk-12 tab-12">
                                    <div class="nv-column-content">
                                        <h3 class="light">Views per sponsor</h3>
                                        <div class="nv-row config-plates clean gap-24 propagate"
                                             *ngIf="selectedSponsor">
                                            <div class="nv-column desk-12 tab-12">
                                                <div class="nv-column-content">
                                                    <h5 class="nv-download-sponsor-report">
                                                        <span>Download Report for sponsor:</span>
                                                        <img *ngIf="selectedSponsor.logo_url"
                                                             [src]="selectedSponsor.logo_url">
                                                        {{ selectedSponsor.name }}
                                                        <nv-button [size]="'small'"
                                                                   [disabled]="downloadingSponsor"
                                                                   (click)="downloadSponsorReport()">
                                                            Download
                                                        </nv-button>
                                                    </h5>
                                                </div><!-- /nv-column-content -->
                                            </div><!-- /nv-column -->
                                        </div><!-- /nv-row -->
                                        <ngx-charts-bar-vertical-2d
                                                [scheme]="chartColorScheme"
                                                [results]="viewsPerSponsorChartData"
                                                [xAxis]="true"
                                                [yAxis]="true"
                                                [legend]="true"
                                                [barPadding]="100 / viewsPerSponsorChartData.length"
                                                [groupPadding]="40 / viewsPerSponsorChartData.length"
                                                [showXAxisLabel]="true"
                                                [showYAxisLabel]="true"
                                                (select)="onSelectBar($event)">
                                        </ngx-charts-bar-vertical-2d>
                                    </div><!-- nv-column-content-->
                                </div><!-- nv-column -->
                            </ng-container>

                            <ng-container *ngIf="!loadingBrowsingSponsors && timeSpentPerSponsorChartData?.length">
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content nv-dashboard-chart-no-pointer nv-dashboard-chart-no-hover">
                                        <h3 class="light">Time spent per sponsor</h3>

                                        <div class="nv-chart-type-350">
                                            <ngx-charts-bar-vertical
                                                    [view]="timeSpentPerSponsorChartData?.length > 10? null : [timeSpentPerSponsorChartData.length * 70 + 100, 350]"
                                                    [barPadding]="timeSpentPerSponsorChartData?.length > 10? 20 : 30"
                                                    [scheme]="chartColorScheme"
                                                    [results]="timeSpentPerSponsorChartData"
                                                    [xAxis]="true"
                                                    [yAxis]="true"
                                                    [legend]="false"
                                                    [showXAxisLabel]="true"
                                                    [showYAxisLabel]="true"
                                                    [xAxisLabel]="'Sponsor name'"
                                                    [yAxisLabel]="'Time spent (seconds)'"
                                                    [activeEntries]="timeSpentPerSponsorActiveEntries"
                                                    (activate)="timeSpentLegendLabelActivate($event)"
                                                    (deactivate)="timeSpentLegendLabelDeactivate($event)">
                                                <ng-template #tooltipTemplate let-model="model">
                                                    <div>
                                                        {{ model.name }}
                                                    </div>
                                                    <div>
                                                        {{ model.value | timeFormat }}
                                                    </div>
                                                </ng-template>
                                            </ngx-charts-bar-vertical>
                                        </div><!-- /.nv-chart-type-350 -->


                                        <div class="nv-divider desk-small"></div>

                                        <nv-custom-dashboard-chart-legend
                                                [title]="'Sponsors'"
                                                [legendItems]="timeSpentPerSponsorLegend"
                                                [activeEntries]="timeSpentPerSponsorActiveEntries"
                                                [chartData]="timeSpentPerSponsorChartData"
                                                (activateLabel)="timeSpentLegendLabelActivate($event)"
                                                (deactivateLabel)="timeSpentLegendLabelDeactivate($event)">
                                        </nv-custom-dashboard-chart-legend>
                                    </div><!-- nv-column-content-->
                                </div><!-- nv-column -->
                            </ng-container>
                        </div><!-- nv-row -->

                        <div class="nv-row config-plates clean gap-24 propagate">
                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <h3 class="light">XLS Export Reports</h3>

                                    <div class="nv-btn-box gap-10">
                                        <nv-button size="medium" [disabled]="downloadingTotal"
                                                   (click)="downloadTotal()">
                                            Total visits
                                        </nv-button>
                                        <nv-button size="medium" [disabled]="downloadingDetailed"
                                                   (click)="downloadDetailed()">
                                            Detailed delegate access
                                        </nv-button>
                                    </div>
                                </div><!-- /nv-column-content -->
                            </div><!-- /nv-column -->
                        </div><!-- /nv-row -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
