<div style="height: 100%; overflow: hidden auto;" nvInfiniteScroll (scrolled)="getAuthors(true)">
    <nv-banner-section [data]="bannerSettings"></nv-banner-section>
    <section class="nv-section guttered nv-author-list-section">
        <div class="nv-port">
            <div class="nv-row large-spaced-top small-spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content" style="text-align: center;">
                        <h2>{{currentPage?.title}}</h2>
                    </div><!-- /.nv-column-content -->
                </div><!-- /nv-column -->
            </div><!-- /.nv-row -->
            <div class="nv-row large-spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form [formGroup]="formFilter" autocomplete="off">
                            <nv-input 
                                [label]="'Search' | translate" 
                                formControlName="search_term" 
                                (change)="getAuthors()"
                            ></nv-input>
                        </form>
                    </div>
                </div>
            </div>
            <div class="nv-row small-spaced-top spaced-bottom gap-30 flow-center" style="position: relative;">
                <div class="nv-global-loading on" [class.on]="authorsLoading">
                    <div class="nv-spinner"></div>
                </div><!-- /.nv-global-loading -->
                <ng-container>
                    <div class="nv-column desk-3 tab-4 phab-6 phone-12" *ngFor="let author of authors">
                        <div class="nv-column-content" (click)="openModal(author)">
                            <div class="nv-speaker-teaser">
                                <div class="st-svatar">
                                    <a title="{{ author?.name }}">
                                        <span [style.background-image]="getAuthorImage(author)"></span>
                                    </a>
                                </div><!-- /.st-svatar -->
                                <h3>
                                    <a title="{{ author?.name }}">{{(author?.title && author?.title !== "") ? author?.title + " " : ""}}{{ author?.name }}</a>
                                </h3>
                                <p *ngIf="author?.institution && author?.institution !== ''">{{ author?.institution }}</p>
                            </div><!-- /.author-teaser -->
                        </div><!-- /.em-column-content -->
                    </div><!-- /nv-column -->
                </ng-container><!-- /nv-column -->
                <!--<div *ngIf="viewAll" class="nv-column-content" style="text-align: center;">-->
                <!--<a href="authors" class="nv-btn hollow neutral medium">{{viewAll}}</a>-->
                <!--</div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->
</div>
