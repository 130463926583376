import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'nvHtml'
})
export class NavusHtmlPipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer
  ) { }

  transform(htmlContent: any): any {
    let found: any;

    if (!htmlContent) { return null; }
    if (typeof htmlContent.matchAll !== 'function') { return htmlContent; }

    // YouTube Long
    found = htmlContent.matchAll(/<oembed url=".+?watch\?v=(.+?)"><\/oembed>/g); // We can do better
    for (let match of found) {
      const oembed = match[0];
      const videoCode = match[1];
    
      const embed = `
        <div class="embed-container youtube-embed">
          <iframe 
            src="https://youtube.com/embed/${videoCode}" 
            frameborder="0"; 
            scrolling="no"; 
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen
          ></iframe>
        </div>
      `;

      htmlContent = htmlContent.replace(oembed, embed);
    }

    // YouTube Short
    found = htmlContent.matchAll(/<oembed url="https:\/\/youtu.be\/(.+?)"><\/oembed>/g);
    for (let match of found) {
      const oembed = match[0];
      const videoCode = match[1];

      const embed = `
        <div class="embed-container youtube-embed">
          <iframe 
            src="https://youtube.com/embed/${videoCode}" 
            frameborder="0"; 
            scrolling="no"; 
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen
          ></iframe>
        </div>
      `;

      htmlContent = htmlContent.replace(oembed, embed);
    }

    // Vimeo
    found = htmlContent.matchAll(/<oembed url="https:\/\/vimeo.com\/(.+?)"><\/oembed>/g);
    for (let match of found) {
      const oembed = match[0];
      const videoCode = match[1];

      const embed = `
        <div class="embed-container vimeo-embed">
          <iframe 
            src="https://player.vimeo.com/video/${videoCode}" 
            frameborder="0" 
            allowfullscreen
          ></iframe>
        </div>
      `;

      htmlContent = htmlContent.replace(oembed, embed);
    }

    // Vimeo
    found = htmlContent.matchAll(/<oembed url="https:\/\/stream\.mux\.com\/(\w+)\.(\w+)\??(\w.+)?"><\/oembed>/g);
    for (let match of found) {
      const oembed = match[0];
      const videoCode = match[1];
      const videoParams = match[3];

      const embed = `
        <div class="embed-container mux-embed">
          <iframe 
            src="https://player.navus.io/${videoCode}?${videoParams}" 
            frameborder="0" 
            allowfullscreen
          ></iframe>
        </div>
      `;

      htmlContent = htmlContent.replace(oembed, embed);
    }

    // nv-player
    found = htmlContent.matchAll(/<oembed url="https:\/\/player\.navus\.io\/(\w+)\??(\w.+)?"><\/oembed>/g);
    for (let match of found) {
      const oembed = match[0];
      const videoCode = match[1];
      const videoParams = match[2];
      const embed = `
        <div class="embed-container mux-embed">
          <iframe 
            src="https://player.navus.io/${videoCode}?${videoParams}" 
            frameborder="0" 
            allowfullscreen
          ></iframe>
        </div>
      `;

      htmlContent = htmlContent.replace(oembed, embed);
    }


    return this.sanitizer.bypassSecurityTrustHtml(htmlContent);
  }
}