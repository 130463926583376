<div class="nv-section guttered full-screen">
    <div class="nv-port" *ngIf="website">
        <div class="nv-row gap-30 small-spaced v-stretch flow-center">
            <div class="nv-column desk-8 tab-12">
                <div class="nv-column-content" style="text-align: center">
                    <img [src]="website.logo" style="max-width: 300px" alt="{{ website.name }}" *ngIf="website.logo">
                    <h1>{{ website.name }}</h1>
                    <h4 *ngIf="conference && conference.starts_at !== conference.ends_at">
                        <span>{{ conference.starts_at | nvDate:'MMM, dd-' }}</span>
                        <span>{{ conference.ends_at | nvDate:'dd, ' }}</span>
                        <span>{{ conference.starts_at | nvDate:'yyyy' }}</span>
                        <span *ngIf="conference.location"> • {{ conference.location }}</span>
                    </h4>
                    <h4 *ngIf="conference && conference.starts_at === conference.ends_at">
                        <span>{{ conference.starts_at | nvDate:'dd, MMMM yyyy' }}</span>
                        <span *ngIf="conference.location"> • {{ conference.location }}</span>
                    </h4>
                    <p [innerHtml]="website.description | nvHtml"></p>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</div><!-- /.nv-section -->
