<form [formGroup]="delegateForm">
    <h3 style="text-align: center;">Continue your registration</h3>

    <div class="nv-row semi-spaced-top gap-24 propagate">
        <div class="nv-column desk-6 tab-12">
            <div class="nv-column-content">
                <nv-input formControlName="first_name"
                        label="{{'First name' | translate}}"
                        (keyup.enter)="keyEvent($event)"
                        [errorMessages]="{ required: 'First name is required' | translate }"
                ></nv-input>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-6 tab-12">
            <div class="nv-column-content">
                <nv-input formControlName="last_name"
                        label="{{'Last name' | translate}}"
                        (keyup.enter)="keyEvent($event)"
                        [errorMessages]="{ required: 'Last name is required' | translate }"
                ></nv-input>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <nv-input formControlName="email"
                        label="{{'Email' | translate}}"
                        (keyup.enter)="keyEvent($event)"
                        [errorMessages]="{ required: 'Email is required' | translate }"
                ></nv-input>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <nv-select formControlName="country_id"
                        label="{{'Country' | translate}}"
                        [required]="true"
                        [options]="filteredCountries"
                        [showSearch]="true"
                        (searched)="filterCountries($event)"
                ></nv-select>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <nv-input formControlName="institution"
                        label="{{'Institution / Company' | translate}}"
                        (keyup.enter)="keyEvent($event)"
                        [errorMessages]="{ required: 'Institution is required' | translate }"
                ></nv-input>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <ng-container *ngFor="let field of delegateFormFields">
                <div class="nv-column {{field.width ? field.width : 'desk-12'}}">
                    <div class="nv-column-content">
                        <ng-container [ngSwitch]="field.type">
                            <ng-container *ngSwitchCase="'checkbox'">
                                <nv-checkbox 
                                        [formControlName]="field.field"
                                        [label]="field.title"
                                        (keyup.enter)="keyEvent($event)"
                                ></nv-checkbox>
                            </ng-container>
                            <ng-container *ngSwitchCase="'select'">
                                <nv-select 
                                        [formControlName]="field.field"
                                        [label]="field.title"
                                        [options]="field.formatedOptions"
                                        [required]="field.mandatory"
                                        (keyup.enter)="keyEvent($event)"
                                ></nv-select>
                            </ng-container>
                            <ng-container *ngSwitchCase="'instructions'">
                                <div [innerHtml]="field.instructions | nvHtml"></div>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <nv-input 
                                        [formControlName]="field.field"
                                        [label]="field.title"
                                        (keyup.enter)="keyEvent($event)"
                                ></nv-input>
                            </ng-container>
                        </ng-container>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
        </ng-container>

        <!-- <div class="nv-column desk-12">
            <div class="nv-column-content">
                <label>
                    <span class="nci-check-port">
                        <input type="checkbox" formControlName="accept" [required]="true">
                        <span class="nci-check-visual"></span>
                    </span> /.nci-check-port -->
                        <!-- <span class="nci-value">
                        I have read and accept all Terms and Conditions
                    </span>
                </label>
            </div> -->
        <!-- </div> -->
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <nv-button color="secondary" 
                        size="large"
                        class="fw radius-high"
                        [loading]="registeringDelegate"
                        [disabled]="delegateForm.invalid"
                        (click)="registerDelegate()">
                    Submit Registration
                </nv-button><!-- /.nv-btn -->
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
    </div><!-- /.nv-row -->
    <!--p style="text-align: center"><a (click)="skip()"><strong>Complete and submit later</strong></a></p-->
    <div class="nv-foo-signature"><span></span></div>
</form><!-- /.odp-pane-right -->