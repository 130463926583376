<div class="nv-row gap-30 propagate" *ngIf="value">
    <ng-container *ngFor="let criteria of value.criteria; let i = index;trackBy:trackByIdx">
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <nv-input
                        type="text"
                        label="Criterion"
                        [(ngModel)]="value.criteria[i]"
                        (ngModelChange)="onChange(value)"
                ></nv-input>
            </div>
        </div>
        <div class="nv-column desk-12">
            <div class="nv-column-content">
                <nv-button (click)="removeCriterion(criteria)">Remove criterion</nv-button>
            </div>
        </div>
    </ng-container>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-button (click)="addCriterion()">Add criterion</nv-button>
        </div>
    </div>
</div>
