import { Component, OnInit } from '@angular/core';
import { WebsiteService } from '@navus/core/services/website.service';
import { Website } from '@navus/core/classes/website';
import { Conference } from '@navus/core/classes/conference';
import { Router } from '@angular/router';

@Component
({
  selector: 'nv-coming-soon-page',
  templateUrl: './coming-soon-page.component.html'
})
export class ComingSoonPageComponent implements OnInit {
  website: Website;
  conference: Conference;

  constructor(
    private websiteService: WebsiteService,
    private router: Router
  ) {}

  ngOnInit() {
    this.websiteService.getCurrentWebsite({ include: 'pages,conferences' })
      .subscribe(
        (website) => {
          if (website.published) {
            this.router.navigate(['/']);
          }

          this.website = website;
          if (website.conferences && website.conferences.length > 0) {
            this.conference = website.conferences[0];
          }
        }
      );
  }

}
