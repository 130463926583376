import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceService } from '@navus/core/services/resource.service';
import { FileUploader } from 'ng2-file-upload';
import { ToastService } from '@navus/ui/toast/toast.service';
import { FileSaver } from 'file-saver';
import { EditResourceComponent } from '@navus/ui/resource-manager/edit-resource/edit-resource.component';
import { take } from 'rxjs/operators';
import { LoadingService } from '@navus/ui/loading/loading.service';
import { AddFolderComponent } from '@navus/ui/resource-manager/add-folder/add-folder.component';
import { NavusModalComponent } from '@navus/ui/modal/default-modal/modal.component';
@Component({
  selector: 'nv-resource-modal-new',
  templateUrl: './resource-modal.component.html'
})
export class NavusResourceModalComponent implements OnInit {

  @Input() title: string;
  @Input() organizationId: number;
  @Input() multiple: boolean = false;
  @Input() folder: string;
  @Input() mime: string[];
  @Output() selected: EventEmitter<any> = new EventEmitter<any>();

  folders: any[] = [];
  selectedFolder: string;
  resources: any[] = [];
  selectedFiles: any[] = [];

  uploader: FileUploader;
  pagination: any;
  readyToDrop: boolean = false;
  showFoldersAction: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private resourceService: ResourceService,
    private toastService: ToastService,
    private ngbModal: NgbModal,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    if (this.folder) { 
      this.selectedFolder = this.folder 
    }

    this.getOrganizationFolders();
    this.getOrganizationResources();
    this.setUploader();
  }

  close() {
    this.activeModal.dismiss();
  }

  setUploader() {
    const params: any = {
      removeAfterUpload: true,
      autoUpload: true,
      headers: []
    };
/*    if (this.mime) {
      params.allowedFileType = this.mime.map((mime) => mime.split('/')[1]);
      params.allowedMimeType = this.mime;
    }*/

    this.uploader = this.resourceService.createResourceUploader(this.organizationId, params);
    this.uploader.onBeforeUploadItem = () => this.loadingService.start('resource');
    this.uploader.onAfterAddingFile = (file: any) => { file.withCredentials = false; };

    this.uploader.onCompleteItem = (item, response, status, headers) => {
      const file = JSON.parse(response).data;
      if (this.folder) {
        this.resourceService.updateOrganizationResource(this.organizationId, file.id, [this.folder]).subscribe(
          () => {
            if (this.folders.indexOf(this.folder) === -1) {
              this.getOrganizationFolders();
            }
          },
          () => {}
        );
      }
      this.getOrganizationResources();
    };
    this.uploader.onCompleteAll = () => {
      this.loadingService.stop('resource');
      this.toastService.success('Finished uploading of resources'); 
      this.uploader.clearQueue();
    };
    this.uploader.onErrorItem = () => { this.toastService.error('Failed to upload resource'); };
  }

  getOrganizationFolders() {
    this.resourceService.getOrganizationFolders(this.organizationId)
      .subscribe(
        (response) => {
          this.folders = response.data;
        },
        () => {}
      );
  }

  getOrganizationResources() {
    const params: any = {
      with_pagination: true,
      per_page: 31
    };
    if (this.selectedFolder) {
      params.tags = this.selectedFolder;
    }
    if (this.mime) {
      params['mime[]'] = this.mime;
    }
    this.resourceService.getOrganizationResources(this.organizationId, params)
      .subscribe(
        (response) => {
          this.resources = response.data;
          this.pagination = response.meta.pagination;
        },
        () => {}
      );
  }

  getFilesPagination(append: boolean = false) {
    if (append && this.pagination.total && this.resources.length >= this.pagination.total) { return; }

    this.pagination.current_page = append ? this.pagination.current_page + 1 : 1;

    const params: any = {
      page: this.pagination.current_page,
      per_page: this.pagination.per_page + 1,
    };

    this.resourceService.getOrganizationResources(this.organizationId, params)
      .subscribe(
        (res) => {
          if (append) {
            this.resources = this.resources.concat(res.data);
          } else {
            this.resources = res.data;
            this.pagination = res.meta;
          }
        },
        () => {}
      );
  }


  onFolderClick(folder: any) {
    if (this.selectedFolder === folder) {
      this.selectedFolder = null;
    } else {
      this.selectedFolder = folder;
      this.uploader.setOptions({
        additionalParameter: {
          tags: [folder]
        }
      })
    }
    this.getOrganizationResources();
  }

  onResourceClick(resource: any) {
    if (this.multiple) { return; }
    this.activeModal.close(resource);
  }

  onResourceClickMultiple(resource) {
    if (this.selectedFiles.includes(resource)) {
      this.selectedFiles.splice(this.selectedFiles.indexOf(resource), 1);
    } else {
      this.selectedFiles.push(resource);
    }
  }


  openOrganizationCreateFolder() {
    this.showFoldersAction = false;
    const modalRef = this.ngbModal.open(AddFolderComponent);

    modalRef.componentInstance.organizationId = this.organizationId;
    modalRef.result.then((result) => {
      if (result) { this.getOrganizationFolders(); }
    });
  }

  openEditResourceModal(event, resource: any) {
    event.stopPropagation();
    const modalRef = this.ngbModal.open(EditResourceComponent);

    modalRef.componentInstance.size = 'medium';
    modalRef.componentInstance.resource = JSON.parse(JSON.stringify(resource));
    modalRef.componentInstance.allFolders = this.folders;
    modalRef.componentInstance.savedResource.pipe(take(1))
      .subscribe(
        (savedResource) => {
          // TODO: Add real API call
          this.resourceService.updateOrganizationResource(this.organizationId, savedResource.id, savedResource.tags)
            .subscribe(
              () => {
                this.toastService.success('Resource successfully updated.');
                if (savedResource.tags) {
                  this.getOrganizationFolders();
                  this.getOrganizationResources();
                }
                // this.redoSearch$.next();
                // this.openedResource.tags = savedResource.tags;
              },
              () => {
                this.toastService.error('Cannot update resource.');
              }
            );
        }
      );
  }


  isFolderLocked(folder: any): boolean {
    return this.folders && this.folders.length && this.folders.includes(folder.name);
  }

  dragEnter(event: DragEvent) {
    this.readyToDrop = true;
    event.preventDefault();
    event.stopPropagation();
  }

  dragLeave(event: DragEvent) {
    this.readyToDrop = false;
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    this.dragLeave(event);
    if (!(event && event.dataTransfer && event.dataTransfer.files)) {
      return;
    }
    if (event.dataTransfer.files.length > 10) {
      this.toastService.error('Maximum number of files for upload is 10');
    } else {
      Array.from(event.dataTransfer.files).forEach(file => {
        file['withCredentials'] = false;
        this.uploader.addToQueue([file]);
      });
      this.uploader.uploadAll();
    }
  }

  copyToClipboard(event, val: string) {
    event.stopPropagation();
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.success('Link copied to clipboard');
  }

  downloadResource(event, resource: any) {
    event.stopPropagation();
    if (!(resource && resource.url && resource.original_filename)) {
      return;
    }
    const xhr = new XMLHttpRequest();
    xhr.open('GET', resource.url);
    xhr.responseType = 'blob';
    xhr.onload = () => FileSaver.saveAs(xhr.response, resource.original_filename);
    xhr.send();
  }

  deleteFile(event, resourceId: number) {
    event.stopPropagation();
    if (resourceId) {
      const modalRef = this.ngbModal.open(NavusModalComponent);

      modalRef.componentInstance.title = 'File Deletion';
      modalRef.componentInstance.body = 'Are you sure you want to delete this file?';
      modalRef.componentInstance.buttons = [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.resourceService.deleteOrganizationResource(this.organizationId, resourceId)
              .subscribe(
                () => {
                  this.toastService.success('File successfully deleted');
                  this.getOrganizationResources();
                }, () => {
                  this.toastService.error('Error occurred. Please refresh page and try again');
                }
              );
          }
        }
      ];
    } else {
      this.toastService.error('Error occurred. Please refresh page and try again');
    }
  }

  deleteFolder(event, folderName: string) {
    event.stopPropagation();
    const modalRef = this.ngbModal.open(NavusModalComponent);

    modalRef.componentInstance.title = 'Folder Deletion';
    modalRef.componentInstance.body = 'Are you sure you want to delete ' + folderName + ' folder?';
    modalRef.componentInstance.buttons = [
      {
        text: 'Cancel',
        color: 'passive',
        role: 'cancel'
      },
      {
        text: 'Delete',
        color: 'accent2',
        handler: () => {
          // this.resourceService.deleteOrganizationFolder(this.organizationId, folderName)
          //   .subscribe(
          //     () => {
          //       this.toastService.success('Tag successfully deleted.');
          //       this.selectedFolders = [];
          //     }, () => {
          //       this.toastService.error('Failed to delete tag.');
          //     }
          //   );
        }
      }
    ];
  }

  unselectAll() {
    this.selectedFiles = [];
  }

  emitselectedFiles() {
    this.activeModal.close(this.selectedFiles);
  }

  actionToggle(event, resource) {
    event.stopPropagation();
    resource.toggled = !resource.toggled;
  }

  toggleFoldersAction(event) {
    event.stopPropagation();
    this.showFoldersAction = !this.showFoldersAction;
  }


}
