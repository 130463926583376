import { Routes } from '@angular/router';
import { OrganizationGuard } from '../../guards/organization.guard';
import { ConferenceDashboardTotalsComponent } from './totals/conference-dashboard-totals.component';
import { ConferenceDashboardLiveComponent } from './live/conference-dashboard-live.component';
import { ConferenceDashboardOnDemandComponent } from './on-demand/conference-dashboard-on-demand.component';
import { ConferenceDashboardOnSiteComponent } from './on-site/conference-dashboard-on-site.component';
import { SponsorDashboardComponent } from './sponsor/sponsor-dashboard.component';

export const ConferenceDashboardRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'totals'
  },
  {
    path: 'totals',
    component: ConferenceDashboardTotalsComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Dashboard Totals'
    }
  },
  {
    path: 'live',
    component: ConferenceDashboardLiveComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Dashboard Live'
    }
  },
  {
    path: 'on-demand',
    component: ConferenceDashboardOnDemandComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Dashboard On Demand'
    }
  },
  {
    path: 'on-site',
    component: ConferenceDashboardOnSiteComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Dashboard On Site'
    }
  },
  {
    path: 'sponsor',
    component: SponsorDashboardComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Sponsor Dashboard'
    }
  },
];
