import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Material } from '@navus/core/classes/material';
import { Presentation } from '@navus/core/classes/presentation';
import { User } from '@navus/core/classes/user';
import { MaterialService } from '@navus/core/services/material.service';
import { UserService } from '@navus/core/services/user.service';
import { LoadingService } from '@navus/ui/loading/loading.service';
import { TrackingService } from '@navus/core/services/tracking.service';
import { Website } from '@navus/core/classes/website';
import { WebsiteService } from '@navus/core/services/website.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'nv-material-page',
  templateUrl: './material-page.component.html'
})
export class MaterialPageComponent implements OnInit {
  conferenceId: number;
  presentationId: number;
  materialId: number;
  material: Material;
  presentation: Presentation;
  currentUser: User;
  audioUrl: string;
  materialsImages: string[];
  currentPage: number = 1;
  highlights: any;
  slideHighlight: any = null;
  scale: number = 1;
  relatedMaterial: Material;

  downloadingMaterial: boolean = false;
  
  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private materialService: MaterialService,
    private userService: UserService,
    private loadingService: LoadingService,
    private websiteService: WebsiteService,
    private trackingService: TrackingService,
  ) { }

  ngOnInit() {
    const routeSubscription = this.route.params
      .subscribe(
        (params) => {
          this.presentationId = params.presentationId;
          this.materialId = params.materialId;
        }
      );
    this.subscriptions.push(routeSubscription);

    const websiteSubscription = this.websiteService
      .currentWebsite
      .subscribe(
        (website: Website) => {
          this.conferenceId = website.active_conference_id;
        }
      );
    this.subscriptions.push(websiteSubscription);

    const userSubscription = this.userService
      .currentUser
      .subscribe(
        (user: User) => {
          this.currentUser = user;
          if (user) {
            this.getMaterial();
          }
        }
      );
    this.subscriptions.push(userSubscription);
  }

  ngOnDestroy() {
    this.trackingService.stopTrackingAction(this.conferenceId, 'WATCHING');
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getMaterial() {
    this.loadingService.start('material', 'fullPage');
    this.materialService.getMaterial(this.materialId, {include: 'presentation,presentation.materials,slides'})
      .subscribe(
        (response: any) => {
          this.material = response.data;
          
          if (this.currentUser) {
            this.trackingService.startTracking(this.conferenceId, 'material', this.materialId, 'WATCHING', {
              user_id: this.currentUser.id,
              slide_id: this.currentPage || null,
              presentation_id: +this.presentationId,
            });            
          }

          this.presentation = this.material.presentation;
          if (this.material.type !== "webcast") {
            this.getMaterialImages();
          }
          
          if (this.material.external_url) {
            this.material.external_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.material.external_url.toString());
          }

          if (this.presentation.type === 'Poster') {
            for (const material of this.presentation.materials) {
              if (material.type === 'audio') {
                this.audioUrl =
                  (this.currentUser.token) ? 'https://navus.io/materials/' + material.id + '/view?token=' + this.currentUser.token : '';
              }
            }
          }
          this.loadingService.stop('material');
        },
        () => {
          this.loadingService.stop('material');
        }
      );
  }

  getMaterialImages() {
    this.loadingService.start('material', 'fullPage');
    this.materialService.getMaterialImages(this.materialId)
      .subscribe(
        (response: any) => {
          this.materialsImages = response.images;
          this.setPage(this.currentPage);
          this.loadingService.stop('material');
        },
        () => {
          this.loadingService.stop('material');
        }
      );
  }

  download() {
    if (this.downloadingMaterial) { return };
    
    this.downloadingMaterial = true;
    this.materialService.downloadMaterial(this.materialId)
      .subscribe(
        () => {
          this.downloadingMaterial = false;
        },
        (error) => {
          this.downloadingMaterial = false;
        }
      );
  }

  trustSrc(src) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(src);
  }

  setPage(page) {
    if (this.currentUser) {
      this.trackingService.pingTracking(this.conferenceId, 'material', this.materialId, 'WATCHING', {
        user_id: this.currentUser.id,
        slide_id: this.currentPage || null,
        presentation_id: +this.presentationId,
      });
    }
  }

  goPrevious() {
    if (this.currentPage === 1) {
      return;
    }
    this.setPage(--this.currentPage);
  }

  goNext() {
    if (this.currentPage >= this.materialsImages.length) {
      return;
    }
    this.setPage(++this.currentPage);
  }

  zoomIn() {
    this.scale = this.scale + 0.1;
    if (2 < this.scale) {
      this.scale = 2;
    }

    this.scale = Math.round(this.scale * 10) / 10;
  }

  zoomOut() {
    this.scale = this.scale - 0.1;
    if (this.scale < 0.6) {
      this.scale = 0.6;
    }

    this.scale = Math.round(this.scale * 10) / 10;
  }

}
