import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class SponsorGroupService {

  constructor(
    private apiService: ApiService
  ) { }

  getSponsorGroups(params: any = {}) {
    return this.apiService.get(`sponsor_groups`, params);
  }

  getSponsorGroup(sponsorGroupId: number, params: any = {}) {
    return this.apiService.get(`sponsor_groups/${sponsorGroupId}`, params);
  }

  updateSponsorGroup(sponsorGroupId: number, params: any = {}) {
    return this.apiService.put(`sponsor_groups/${sponsorGroupId}`, params);
  }

  createSponsorGroup(params: any = {}) {
    return this.apiService.post(`sponsor_groups`, params);
  }

  deleteSponsorGroup(sponsorGroupId: number) {
    return this.apiService.delete(`sponsor_groups/${sponsorGroupId}`);
  }

  getConferenceSponsorGroups(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/sponsor_groups`, params);
  }

  getConferenceSponsorGroup(conferenceId: number, sponsorGroupId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/sponsor_groups/${sponsorGroupId}`, params);
  }

  updateConferenceSponsorGroup(conferenceId: number, sponsorGroupId: number, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/sponsor_groups/${sponsorGroupId}`, params);
  }

  createConferenceSponsorGroup(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/sponsor_groups`, params);
  }

  deleteConferenceSponsorGroup(conferenceId: number, sponsorGroupId: number) {
    return this.apiService.delete(`conferences/${conferenceId}/sponsor_groups/${sponsorGroupId}`);
  }

  getSponsorGroupImages(sponsorGroupId: number, params: any = {}) {
    return this.apiService.get(`sponsor_groups/${sponsorGroupId}/images`, params);
  }

  downloadSponsorGroup(sponsorGroupId: number, params: any = {}) {
    return this.apiService.download(`sponsorGroups/${sponsorGroupId}/download`, `sponsor_groups_${sponsorGroupId}`, params);
  }

  getSponsorGroupHighlights(sponsorGroupId: number, params: any = {}) {
    return this.apiService.get(`sponsor_groups/${sponsorGroupId}/highlights`, params);
  }

  addSponsorGroupHighlight(sponsorGroupId: number, params: any = {}) {
    return this.apiService.post(`sponsor_groups/${sponsorGroupId}/highlights`, params);
  }

  removeSponsorGroupHighlight(sponsorGroupId: number, highlightId: number, params: any = {}) {
    return this.apiService.delete(`sponsor_groups/${sponsorGroupId}/highlights/${highlightId}`, params);
  }

}
