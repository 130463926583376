import { SafeHtml } from '@angular/platform-browser';

export class Article {
  id: number;
  title: string;
  lead: string;
  content: string | SafeHtml;
  author: string;
  external_url: string;
  image_url: string;
  is_hot_topic: boolean;
  link: string;
  order: number;
  published_at: string;
  status: string;
  active: boolean;
  portal_id: number;
  resource?: any;
}
