import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Website } from '@navus/core/classes/website';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { WebsiteService } from '@navus/core/services/website.service';

@Component({
  selector: '[nv-design-panel]',
  templateUrl: './design-panel.component.html'
})
export class DesignPanelComponent implements OnInit {
  @Input() website: Website;
  @Output() close = new EventEmitter();
  @Output() saved = new EventEmitter();

  websiteForm: FormGroup;

  savingWebsite = false;

  constructor(
    private formBuilder: FormBuilder,
    private websiteService: WebsiteService,
    private modalService: ModalService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.websiteForm = this.formBuilder.group({
      logo: [''],
      settings: this.formBuilder.group({
        google_analytics_code: [null],
        search_page_presentation_types: [null],
        search_page_material_types: [null],
        event_website: [''],
        release_option: ['manual'],
        release_date: [''],
        release_notification: [false],
        footer: [''],
        custom_css: [''],
      })
    });

    this.websiteForm.patchValue(this.website);
  }

  save() {
    if (this.websiteForm.invalid) { return; }

    this.savingWebsite = true;
    this.websiteService.updateOrganizationWebsite(this.website.organization_id, this.website.id, this.websiteForm.value)
      .subscribe(
        (response) => {
          this.website = response['data'];
          this.savingWebsite = false;
          this.toastService.success('Website successfully saved.');
          this.saved.emit();
          this.close.emit();
        },
        (error) => {
          this.savingWebsite = false;
          this.modalService.error({ errors: error });
        }
      );
  }

}
