import { Component } from '@angular/core';

@Component({
    selector: 'app-live-suite-21-page',
    templateUrl: './live-suite21.component.html'
})
export class LiveSuite21Component {

    constructor() { }

}
