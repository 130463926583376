<div class="nv-modal small">
    <div class="nv-modal-header">
        <h4 class="nv-modal-title">Streams</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body">
        <form [formGroup]="streamForm" class="nv-row gap-24">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <nv-select
                        [options]="streamOptions"
                        formControlName="stream_id"
                        label="Stream"
                        [appendTo]="'body'"
                    ></nv-select>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-divider desk-small"></div>

            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <div class="nv-live-stage-streaming-box-in-modal">
                        <h5 [hidden]="!selectedStream">Currently streaming:</h5>
                        <ul class="nv-live-stage-modal-streaming-list">
                            <li>
                                <h5 class="airing">{{selectedStream ? selectedStream?.currentPresentation?.title || 'Off Air' : 'Please select stream'}}</h5>
                                <div class="nv-live-stage-modal-streaming-authors">
                                    <span class="author" *ngFor="let author of selectedStream?.currentPresentation?.authors;let i = index">
                                        {{author.title}}{{author.name}}
                                    </span>
                                </div><!-- /.nv-live-stage-modal-streaming-authors -->
                            </li>
                        </ul>
                    </div>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            
            <div class="nv-divider desk-small"></div>

            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <nv-button (click)="setNewStream()" [disabled]="streamForm.invalid">Watch</nv-button>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </form>
    </div>
</div>
