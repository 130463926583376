import { Component, OnInit, Input, Output, EventEmitter, HostBinding, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@navus/core/services/user.service';
import { User } from '@navus/core/classes/user';
import { Subscription } from 'rxjs';
import { ConferenceService } from '@navus/core/services/conference.service';

//import { encode, TAlgorithm } from "jwt-simple";

@Component({
  selector: 'nv-questions',
  templateUrl: './questions.component.html'
})

export class QuestionsComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-section') class = true;  
  @Input() roomName: string;
  @Input() title: string;

  organizationId: number;
  conferenceId: number;
  questions: any[] = [];

  pagination: { page?: number, perPage: number, total?: number } = {
    perPage: 30
  };
  sort: { field: string, direction: string } = {
    field: 'created_at',
    direction: 'desc'
  };
  interval: any;
  
  questionLoading: boolean = false;
  optionsShown: number = null;

  subscriptions: Subscription[] = [];
//  @Output() screenSharingStatusChanged = new EventEmitter();
//  @Output() videoStatusChanged = new EventEmitter();

//  currentUser: User;
  
//  loading: boolean = true;
 
  constructor(
      private userService: UserService,
      private conferenceService: ConferenceService,
      private route: ActivatedRoute,
      private router: Router
  ) {}

  ngOnInit() {
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getQuestions();
      }
    );
    this.subscriptions.push(subscription);

    this.interval = setInterval(() => {
      this.getQuestions();
    }, 60 * 1000);
  }
    
  ngOnDestroy() {
    clearInterval(this.interval);
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  
  getQuestions(append: boolean = false) {
    if (append && this.pagination.total && this.questions.length >= this.pagination.total) { return; }

    this.pagination.page = append ? this.pagination.page + 1 : 1;
    const params: any = {
      page: this.pagination.page,
      per_page: this.pagination.perPage,
      sort_by: this.sort.field,
      sort_direction: this.sort.direction,
      include: 'author'
    };
    this.questionLoading = !append;
    this.conferenceService.getConferenceMessages(this.conferenceId, params)
      .subscribe(
        (response: { data: any[], meta: any }) => {
          if (append) {
            this.questions = this.questions.concat(response.data);
          } else {
            const port = document.getElementsByClassName('nv-config-content-port');
            if (port && port.length > 0) {
              port[0].scrollTop = 0;
            }
            
            this.questions = response.data;
            this.pagination.total = response.meta.pagination.total;
          }
          this.questionLoading = false;
        },
        () => {
          this.questionLoading = false;
        }
      );
  }
  
}
