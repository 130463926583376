<div class="nv-modal small">
    <div class="nv-modal-header">
        <h4 class="nv-modal-title">{{ article.title }}</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body">
        <div [innerHTML]="content"></div>
    </div>
</div>

