import { Component } from '@angular/core';

@Component({
    selector: 'app-gamification-instructions-page',
    templateUrl: './gamification-instructions.component.html'
})
export class GamificationInstructionsComponent {

    constructor() { }

}
