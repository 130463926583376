import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SummarySectionConfig } from './summary-section.config';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PresentationService } from '@navus/core/services/presentation.service';
import { BookmarkService } from '@navus/core/services/bookmark.service';
import { Presentation } from '@navus/core/classes/presentation';
import { User } from '@navus/core/classes/user';
import { UserService } from '@navus/core/services/user.service';
import { WebsiteService } from '@navus/core/services/website.service';
import { Website } from '@navus/core/classes/website';
import { Conference } from '@navus/core/classes/conference';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { BannerService } from '@navus/core/services/banner.service';
import { BaseSectionComponent } from '../_base/base-section.component';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { WebsiteControlService } from '../../services/website-control.service';
import { Bookmark } from '@navus/core/classes/bookmark';

@Component({
  selector: 'nv-summary-section',
  templateUrl: './summary-section.component.html'
})
export class SummarySectionComponent extends BaseSectionComponent implements OnInit, OnDestroy {
  @Input() data: WebsitePageSection;
  configuration: SummarySectionConfig = new SummarySectionConfig();
  subscriptions: Subscription[] = [];

  presentationsRecommended: Presentation[] = [] as Presentation[];
  presentationsLatest: Presentation[] = [] as Presentation[];
  conferences: Conference[] = [] as Conference[];
  bookmarks: Bookmark[] = [];
  bookmarkedPresentationIds: number[] = [];
  currentUser: User = null;
  summary: any;
  website: Website;
  banner: any;

  loadingRecommendations: boolean = false;
  loadingLatest: boolean = false;

  chartColors = [
    '#3a56b1',
    '#629dff',
    '#51aee9',
    '#e8966a',
    '#de6c6d',
    '#c24903'
  ];

  summarySliderConfig = {
    'slidesToShow': 1,
    'autoplay': false,
    'slidesToScroll': 1,
    'infinite': false,
    'fade': true,
    'dots': true,
    'arrows': false,
    'adaptiveHeight': true,
    'dotsClass': 'wb-graph-dots'
  };

  conferencesSliderConfig = {
    'slidesToShow': 3,
    'autoplay': false,
    'slidesToScroll': 3,
    'infinite': false,
    'dots': false,
    'centerMode': false,
    'adaptiveHeight': true,
    'arrows': true,
    'prevArrow': '<a class="er-prev"></a>',
    'nextArrow': '<a class="er-next"></a>'
  };

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService,
    private presentationService: PresentationService,
    private userService: UserService,
    private bookmarkService: BookmarkService,
    private bannerService: BannerService,
    private router: Router
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );
  }

  ngOnInit() {
    this.configuration = new SummarySectionConfig(this.data.configuration);
    this.getRecommendedPresentations();
    this.getLatestPresentations();
    this.getConferences();
    this.getBanner();

    const userSubscription = this.userService.currentUser
      .subscribe(
        (user: User) => {
          this.currentUser = user;
        }
      );
    this.subscriptions.push(userSubscription);

    const bookmarkSubscription = this.bookmarkService.bookmarks
      .subscribe(
        (bookmarks: Bookmark[]) => {
          this.bookmarks = bookmarks;
          this.bookmarkedPresentationIds = bookmarks.map(b => b.presentation_id);
        }
      );
    this.subscriptions.push(bookmarkSubscription);

    const websiteSubscription = this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          this.website = website;
        }
      );
    this.subscriptions.push(websiteSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getRecommendedPresentations() {
    this.loadingRecommendations = true;
    this.presentationService.getRecommendations({ limit: 5 })
      .subscribe(
        (response: any) => {
          this.presentationsRecommended = response.data;
          this.loadingRecommendations = false;
        },
        () => {
          this.loadingRecommendations = false;
        }
      );
  }

  getLatestPresentations() {
    const params = {
      per_page: 3,
      order: 'desc',
      sort: 'pm.published_at',
      explain: true
    };

    this.loadingLatest = true;
    this.presentationService.explore(params)
      .subscribe(
        (response) => {
          this.presentationsLatest = response.data;

          response.meta.explanation.presentation_type.sort((a, b) => (a.total < b.total) ? 1 : -1);
          response.meta.explanation.presTotal =
            response.meta.explanation.presentation_type.reduce((curr, acc) => curr + acc.total, 0);
          response.meta.explanation.material_type.sort((a, b) => (a.total < b.total) ? 1 : -1);
          response.meta.explanation.matTotal =
            response.meta.explanation.material_type.reduce((curr, acc) => curr + acc.total, 0);
          this.summary = response.meta.explanation;
          this.loadingLatest = false;
        },
        () => {
          this.loadingLatest = false;
        }
      );
  }

  getBanner() {
    this.bannerService.getBanner({ position: 'Recommendations' })
      .subscribe(
        (response: any) => {
          this.banner = response.data;
        },
        () => { }
      );
  }

  getConferences() {
    const params = {
      with_pagination: 0
    };

    this.websiteService.getWebsiteConferences()
      .subscribe(
        (response: Conference[]) => {
          this.conferences = response;
        },
        () => { }
      );
  }

  toggleBookmark(presentation: Presentation) {
    const bookmark = this.bookmarks.find(b => b.presentation_id === presentation.id);
    if (!bookmark) {
      this.bookmarkService.addWebsiteBookmark(this.website.id, presentation)
        .subscribe(
          () => { },
          () => { }
        );
    } else {
      this.bookmarkService.removeWebsiteBookmark(this.website.id, presentation)
        .subscribe(
          () => { },
          () => { }
        );
    }
  }

  goToConferencePresentations(conference: Conference) {
    this.router.navigate(['search'], { queryParams: { conferences: conference.id }});
  }

}
