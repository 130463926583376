<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Sponsor Settings</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-10">
                <nv-button (click)="close()" color="passive" translate>Close</nv-button>
                <nv-button (click)="save()" [loading]="savingSponsorSettings" [disabled]="sponsorSettingsForm.invalid" translate>Save</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav vertical-break-tab">
                <ul>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'sponsors', 'companies']">Companies</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'sponsors', 'sponsor-groups']">Sponsor Groups</a>
                    </li>
                    <li>
                        <a class="active">Sponsor Settings</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
<div class="nv-config-content-port">
    <div class="nv-global-loading on" [class.on]="loadingSponsorSettings">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24 small-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form [formGroup]="sponsorSettingsForm" class="nv-row config-plates clean gap-24 small-spaced-top spaced-bottom">
                            <div class="nv-column desk-6 lap-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Sponsor Settings</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-checkbox
                                                        formControlName="lead_generation"
                                                        label="Lead Generation"
                                                ></nv-checkbox>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
