import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConferenceService } from '@navus/core/services/conference.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@navus/ui/modal/modal.service';
import { Location } from '@navus/core/classes/location';
import { LocationService } from '@navus/core/services/location.service';
import { LivestreamingLocationEditComponent } from './livestream-location-edit/livestreaming-location-edit.component';
import { Presentation } from '@navus/core/classes/presentation';
import Echo from 'laravel-echo';
import { environment } from '../../environments/environment';
import { PresentationService } from '@navus/core/services/presentation.service';
import { Material } from '@navus/core/classes/material';
import { MaterialService } from '@navus/core/services/material.service';
import { VotingQuestion } from '@navus/core/classes/voting-question';
import { UserService } from '@navus/core/services/user.service';
import { OffAir } from '../shared/models/offAir';
import { User } from '@navus/core/classes/user';
import { Program } from '@navus/core/classes/program';
import { ChatService } from '@navus/core/services/chat.service';
import { BlockService } from '@navus/core/services/block.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import Pusher from 'pusher-js';

type Tab = 'meeting' | 'ematerials' | 'voting' | 'preview' | 'off-air' | 'q&a';

@Component({
  templateUrl: './livestreaming.component.html',
})
export class LivestreamingComponent implements OnInit, OnDestroy {
  organizationId: number;
  conferenceId: number;
  locationId: number;

  loadingLocation: boolean;
  location: Location;

  loadingProgram: boolean;
  program: Program;

  loadingPresentation: boolean;
  currentPresentation: Presentation;
  currentPresentationId: number;
  currentPresentationMainMaterial: Material;

  loadingVotingQuestions: boolean;
  votingQuestions: VotingQuestion[] = [];

  loadingOffAirs: boolean;
  offAirs: OffAir[] = [];

  loadingAutoPilot: boolean;
  autopilot: number;

  materialsImages: { image: string, src: string }[];
  currentPage: number = 1;
  highlights: any;
  scale: number = 1;

  streamKey: string;
  streamId: string;
  streamingUrl: any;

  currentLayoutOption: 'lo-type1' | 'lo-type2' | 'lo-type3' = 'lo-type1';
  controlPanelSectionTabs: Tab[];

  currentUser: User;

  laravelEcho: Echo;
  private subscriptions: Subscription[] = [];
  qnaBlocks: any[];
  qnaBlockIds: number[] = [];

  constructor(
    private conferenceService: ConferenceService,
    private modalService: ModalService,
    private toasterService: ToastService,
    private locationService: LocationService,
    private presentationService: PresentationService,
    private materialService: MaterialService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private blockService: BlockService,
    private chatService: ChatService,
  ) { }

  ngOnInit() {
    const routeParamsSubscription = this.route.params
      .subscribe(
        (data) => {
          this.loadSectionTabsFromLocalStorage();

          this.organizationId = parseFloat(data.organizationId);
          this.conferenceId = parseFloat(data.conferenceId);
          this.locationId = parseFloat(data.locationId);

          this.getLocation();
          this.getProgram();
          this.loadConferenceOffAirs();
          this.initEcho();

          // Init current user -> needed for chat
          this.userService.getCurrentUser()
            .subscribe((user) => {
              this.currentUser = user;
            });
        }
      );
    this.subscriptions.push(routeParamsSubscription);

    const chatRoomSubscription = this.chatService
      .getAllPublicGroups(this.conferenceId)
      .subscribe(
        (rooms) => {
          this.qnaBlockIds = rooms
            .filter((r) => r.name.startsWith('block_'))
            .map((r) => +r.name.split('_')[1]);
          this.getQnABlocks();
        }
      );
    this.subscriptions.push(chatRoomSubscription);

    
    this.getQnABlocks();
  }

  ngOnDestroy() {
    this.laravelEcho.disconnect();
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getLocation() {
    if (this.locationId) {
      this.loadingLocation = true;

      const params = {
        active_streaming: 1,
        include: 'streaming_presentations',
        with_pagination: 0
      }
      this.locationService
        .getConferenceLocations(this.conferenceId, params)
        .subscribe(
          (response) => {
            console.log(response);
            const locations = response.data;
            this.location = locations.find(l => +l.id === +this.locationId);

            console.log(this.location);

            if (this.location) {
              this.autopilot = this.location.autopilot;
              this.loadingLocation = false;

              this.streamKey = this.location.rtmp;
              this.streamId = this.location.stream_url;

              if (this.location.streaming_presentations[0]) {
                this.setNewPresentation(this.location.streaming_presentations[0].id);
              }              
            }
          },
          (error) => {
            this.toasterService.error(error.message);
            this.loadingLocation = false;
          }
        );
    } else {
      this.location = new Location();
      this.loadingLocation = false;
    }
  }

  editLocationSettings() {
    const modalRef = this.modalService.open(LivestreamingLocationEditComponent);
    modalRef.componentInstance.conferenceId = this.conferenceId;
    modalRef.componentInstance.location = this.location;
    modalRef.result.then(
      () => {
        this.getLocation();
      },
      () => {
      }
    );
  }

  updateAutopilot() {
    const value = this.autopilot === 0 ? 1 : 0;
    const promptText = value === 0 ? 'Are you sure you want to switch off Auto-pilot mode and go back to manual selection of the streaming program? ' : 'Are you sure you want to switch live program to Auto-pilot mode?';
    this.modalService.defaultModal({
      title: 'Auto-pilot',
      body: promptText,
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Yes',
          color: 'accent2',
          handler: () => {
            this.loadingAutoPilot = true;

            const params: any = {
              autopilot: value
            };
            this.locationService
              .updateConferenceLocation(this.conferenceId, this.locationId, params)
              .subscribe(
                (response: { data: Location }) => {
                  this.autopilot = value;
                  this.loadingAutoPilot = false;
                },
                (error) => {
                  this.toasterService.error(error.message);
                  this.loadingAutoPilot = false;
                }
              );
          }
        }
      ]
    });

  }

  loadPresentationDetails(presentationId: number) {
    this.loadingPresentation = true;
    this.presentationService.getPresentation(presentationId, { include: 'block,speakers,accessible_materials' })
      .subscribe(
        (presentationResponse) => {
          this.currentPresentation = presentationResponse?.data;
          this.currentPresentationMainMaterial = this.currentPresentation.accessible_materials?.find(m => m.type === 'main');
          if (this.currentPresentationMainMaterial) {
            this.getMaterialImages();
          }
          this.loadingPresentation = false;
          this.setStreamingUrl();
        },
        (error) => {
          this.toasterService.error(error.message);
          this.loadingPresentation = false;
        });
  }

  loadPresentationVotingQuestions(presentationId: number) {
    this.loadingVotingQuestions = true;
    this.presentationService.getConferencePresentationVotingQuestions(this.conferenceId, presentationId)
      .subscribe(
        (response: { data: VotingQuestion[], meta: any }) => {
          this.votingQuestions = response.data;
          this.loadingVotingQuestions = false;
        },
        (error) => {
          this.toasterService.error(error.message);
          this.loadingVotingQuestions = false;
        }
      );
  }

  loadConferenceOffAirs() {
    this.loadingOffAirs = true;
    this.conferenceService.getConferenceOffAirs(this.conferenceId)
      .subscribe(
        (response: { data: OffAir[], meta: any }) => {
          this.offAirs = response.data;
          this.loadingOffAirs = false;
        },
        (error) => {
          this.toasterService.error(error.message);
          this.loadingOffAirs = false;
        }
      );
  }

  stopStreaming() {
    this.modalService.defaultModal({
      title: 'Stop Streaming',
      body: 'Are you sure you want to stop? If you stop, currently active offair item will be shown.',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Confirm',
          color: 'accent2',
          handler: () => {
            this.locationService.updateConferenceLocation(this.conferenceId, this.locationId, { current_presentation_id: null })
              .subscribe(
                () => {
                  this.currentPresentation = null;
                  this.currentPresentationId = null;
                  this.materialsImages = null;
                  this.votingQuestions = null;
                  this.getLocation();
                },
                (error) => {
                  this.toasterService.error(error.message);
                }
              );
          }
        }
      ]
    });
  }

  goLive() {
    this.modalService.defaultModal({
      title: 'Go Live',
      body: 'To go live, click Play button next to a presentation that you want to stream or chose Auto-pilot option.',
      size: 'small',
      buttons: [
        {
          text: 'Ok',
          color: 'passive',
          role: 'cancel'
        }
      ]
    });
  }

  addSection(section: Tab) {
    if (!this.controlPanelSectionTabs.includes(section)) {
      if (this.controlPanelSectionTabs.length < 3) {
        this.controlPanelSectionTabs.push(section);
        localStorage.setItem('controlPanelSectionTabs', this.controlPanelSectionTabs.join(','));
      } else {
        this.modalService.alert({
          title: 'Cannot add section',
          body: 'Please close one of the sections shown on the left, before adding another',
          buttons: [
            {
              text: 'Ok',
              role: 'cancel'
            }
          ],
          size: 'large'
        });
      }
    } else {
      this.closeSection(section);
    }
  }

  closeSection(section: Tab) {
    if (this.controlPanelSectionTabs.length > 1 && this.controlPanelSectionTabs.includes(section)) {
      this.controlPanelSectionTabs.splice(this.controlPanelSectionTabs.indexOf(section), 1);
      localStorage.setItem('controlPanelSectionTabs', this.controlPanelSectionTabs.join(','));
    }
  }

  openPreviewSettings() {
    // TODO: Which link?
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/o', this.organizationId, 'conference', this.conferenceId, 'ematerials'])
    );

    window.open(url, '_blank');
  }

  private initEcho() {
    window['Pusher'] = Pusher;
    window['Echo'] = new Echo({
        broadcaster: 'pusher',
        key: `${environment.mix_pusher_app_key}`,
        wsHost: `${environment.mix_pusher_host}`,
        wsPort: `${environment.mix_pusher_port}`,
        wssPort: `${environment.mix_pusher_port}`,
        forceTLS: `${environment.mix_pusher_port === 443}`,
        encrypted: true,
        disableStats: true,
        authEndpoint: `${environment.api_url}` + 'broadcasting/auth',
        auth: {headers: {'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')}},
        enabledTransports: ['ws', 'wss']
    });
    this.laravelEcho = window['Echo'];
    this.laravelEcho
      .channel('location.current_conference_presentation.' + this.conferenceId)
      .listen(`.location.changed_location`,
        (response: { data: Location }) => {
          if (response?.data?.id === this.locationId) {
            const streamingPresentation = response?.data?.streaming_presentations?.[0];
            this.location = response.data;
            if (streamingPresentation) {
              this.setNewPresentation(streamingPresentation.id);
            } else {
              this.currentPresentation = null;
              this.currentPresentationId = null;
              this.materialsImages = null;
              this.votingQuestions = null;
            }
          }
        }
      );
  }

  private getMaterialImages() {
    const currentUserSubscription = this.userService.currentUser
      .subscribe(
        (user) => {
          this.materialService.getMaterialImages(this.currentPresentationMainMaterial?.id)
            .subscribe(
              (response: any) => {
                this.materialsImages = response.images.map((image) => ({
                  image,
                  src: image
                }));
              },
              (error) => {
                this.toasterService.error(error.message);
              }
            );
        },
        (error) => {
          this.toasterService.error(error.message);
        }
      );
    this.subscriptions.push(currentUserSubscription);
  }

  private setNewPresentation(presentationId: number) {
    this.currentPresentationId = presentationId;
    this.loadPresentationDetails(this.currentPresentationId);
    this.loadPresentationVotingQuestions(this.currentPresentationId);
  }

  private setStreamingUrl() {
    const presentationWebcast: any = this.currentPresentation.accessible_materials.find(m => m.type === 'webcast');
    if (presentationWebcast?.external_url) {
      const url = presentationWebcast.external_url;
      if (JSON.stringify(url) !== JSON.stringify(this.streamingUrl)) {
        this.streamingUrl = url;
      }
    } else {
      const url = this.location.stage_url;
      if (JSON.stringify(url) !== JSON.stringify(this.streamingUrl)) {
        this.streamingUrl = url;
      }
    }
  }

  private loadSectionTabsFromLocalStorage() {
    const tabsFromLocalStorage = localStorage.getItem('controlPanelSectionTabs');
    if (tabsFromLocalStorage) {
      this.controlPanelSectionTabs = tabsFromLocalStorage.split(',') as Tab[];
    } else {
      this.controlPanelSectionTabs = ['meeting', 'preview'];
    }
  }

  private getProgram() {
    this.loadingProgram = true;
    this.conferenceService.getProgram(this.conferenceId)
      .subscribe((response: { data: Program }) => {
          this.loadingProgram = false;
          this.program = response.data;
        }, (error) => {
          this.toasterService.error(error.message);
          this.loadingProgram = false;
        }
      );
  }

  getQnABlocks() {
    this.qnaBlocks = [];
    const params = {
      location_id: this.locationId,
      with_pagination: 0
    }

    this.blockService
      .getConferenceBlocks(this.conferenceId, params)
      .subscribe(
        (response) => {
          const blocks = response.data;
          this.qnaBlocks = blocks
            .filter(b => this.qnaBlockIds.includes(b.id))
            .sort((b1, b2) => new Date(b1.starts_at).getTime() - new Date(b2.starts_at).getTime());
        },
        () => { }
      )
  }
}
