import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthQueryGuard } from './guards/auth-query.guard';
import { AuthGuard } from './guards/auth.guard';
import { SpeakerResolver } from './resolvers/speaker.resolver';
import { WebsiteResolver } from './resolvers/website.resolver';
import { SpeakerStudioComponent } from './speaker-studio.component';

const routes: Routes = [
    {
        path: 'speaker-studio',
        canActivate: [AuthQueryGuard, AuthGuard],
        resolve: {
          website: WebsiteResolver,
        },
        children: [
            {
                path: '',
                component: SpeakerStudioComponent,     
                resolve: {
                  speaker: SpeakerResolver,
                },           
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)]
})

export class SpeakerStudioRoutingModule {
}
