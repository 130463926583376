import { NgModule } from '@angular/core';
import { NavusCoreModule } from '../../../navus-core/navus-core.module';
import { NavusUiModule } from '../../../navus-ui/navus-ui.module';
import { SharedModule } from '../../../shared';
import { CampaignListPageComponent } from './pages/campaign-list/campaign-list-page.component';
import { CampaignDetailsPageComponent } from './pages/campaign-details/campaign-details-page.component';
import { LeadlinkLandingPageComponent } from './pages/leadlink-landing/leadlink-landing-page.component';

@NgModule({
  imports: [
    NavusCoreModule.forRoot(),
    NavusUiModule,
    SharedModule
  ],
  declarations: [
    LeadlinkLandingPageComponent,
    CampaignListPageComponent,
    CampaignDetailsPageComponent
  ],
  entryComponents: [],
  providers: []
})
export class LeadlinkModule {
}
