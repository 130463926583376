import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ConferenceService } from '@navus/core/services/conference.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { Material } from '@navus/core/classes/material';
import { MaterialService } from '@navus/core/services/material.service';
import { OffAir } from '../../../shared/models/offAir';

@Component({
  selector: 'nv-livestream-off-air-edit',
  templateUrl: './livestream-off-air-edit.component.html',
})
export class LivestreamOffAirEditComponent implements OnInit, OnDestroy {
  @Input() conferenceId: number;
  @Input() organizationId: number;
  @Input() locationId: number;
  @Input() offAir: OffAir;

  offAirEditForm: FormGroup;
  offAirSaving: boolean = false;

  materials: Material[] = [];
  materialsPage: number = 1;
  materialsTotal: number = null;
  materialsSearch: string = '';

  resourceTypes = [
    { id: 'materials', name: 'Materials' },
    { id: 'resources', name: 'Resources' },
    { id: 'external', name: 'External Image' },
    { id: 'external_video', name: 'External Video' },
  ];
  positionOptions = [
    { id: 'hide', name: 'Hidden' },
    { id: 'both', name: 'Main + Side' },
    { id: 'main', name: 'Main' },
    { id: 'side', name: 'Side' },
  ];

  unsavedChanges: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private toasterService: ToastService,
    private conferenceService: ConferenceService,
    private materialService: MaterialService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.initForm();
    const offAirEditFormChanges = this.offAirEditForm.valueChanges
      .subscribe(
        () => {
          this.unsavedChanges = true;
        }
      );
    this.subscriptions.push(offAirEditFormChanges);

    const resourceTypeChange = this.offAirEditForm.get('resource_type')
      .valueChanges
      .subscribe((resourceType) => {
        if (resourceType === 'materials') {
          this.offAirEditForm.get('material_id').setValidators([Validators.required]);
          
          this.offAirEditForm.get('resource_value').setValidators(null);
          this.offAirEditForm.get('resource_value').setValue('');
        } else {
          this.offAirEditForm.get('material_id').setValidators(null);
          this.offAirEditForm.get('material_id').setValue('');

          this.offAirEditForm.get('resource_value').setValidators(null);
        }
      });
    this.subscriptions.push(resourceTypeChange);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  close(): void {
    this.activeModal.dismiss();
  }

  save() {
    if (this.offAirEditForm.invalid) {
      return;
    }

    this.offAirSaving = true;
    const params = this.offAirEditForm.getRawValue();

    let apiCall = this.conferenceService.createConferenceOffAir(this.conferenceId, params);

    if (this.offAir?.id) {
      apiCall = this.conferenceService.updateConferenceOffAir(this.conferenceId, this.offAir.id, params);
    }

    apiCall.subscribe(
      (response: { data: Location }) => {
        this.toasterService.success('Offair successfully saved.', {});
        this.offAirSaving = false;
        this.activeModal.close();
      },
      (error) => {
        this.toasterService.error(error.message);
        this.offAirSaving = false;
      }
    );
  }

  getMaterials(append: boolean = false, search_term?: string) {
    if (append) {
      if (this.materialsTotal && this.materials.length >= this.materialsTotal) {
        return;
      }
      this.materialsPage++;
    } else {
      this.materialsPage = 1;
    }

    const params: any = {
      page: this.materialsPage,
      per_page: 20,
      sort: 'name',
      order: 'asc'
    };
    if (search_term) {
      params.search_term = search_term;
    }

    this.materialService.getConferenceMaterials(this.conferenceId, params)
      .subscribe(
        (response: { data: Material[], meta: any }) => {
          if (append) {
            this.materials = this.materials.concat(response.data);
          } else {
            this.materials = response.data;
            if (response.meta) {
              this.materialsTotal = response.meta.pagination.total;
            }
          }
        },
        (error) => {
          this.toasterService.error(error.message);
        }
      );
  }

  private initForm() {
    this.offAirEditForm = this.formBuilder.group({
      title: [this.offAir?.title, Validators.required],
      title_position: [this.offAir?.title_position, Validators.required],
      description: [this.offAir?.description, Validators.required],
      description_position: [this.offAir?.description_position, Validators.required],
      countdown_ends_at: [this.offAir?.countdown_ends_at],
      countdown_ended_label: [this.offAir?.countdown_ended_label],
      countdown_position: [this.offAir?.countdown_position],
      resource_type: [this.offAir?.resource_type],
      material_id: [this.offAir?.materials?.id],
      resource_value: [this.offAir?.resource_value],
      location_id: [this.locationId, Validators.required],
    });
    this.unsavedChanges = false;
  }
}
