<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>AI</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-8">

            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port spaced-bottom">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav">
                <ul>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'ai', 'recommendations']">Recommendations</a>
                    </li>
                    <li>
                        <a class="active">Sponsorship Campaigns</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
<div class="nv-config-content-port">
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates spaced-top">
                <div class="nv-column desk-2 tab-none"></div>
                <div class="nv-column desk-8 tab-12">
                    <div class="nv-column-content">
                        To come...
                    </div>
                </div>
                <div class="nv-column desk-2 tab-none"></div>
            </div>
        </div>
    </div>
</div><!-- /.config-layout-lower-fixed -->
