import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoPlayerPreset } from '@navus/core/classes/video-player-preset';
import { VideoPlayerPresetService } from '@navus/core/services/video-player-preset.service';

@Component({
  selector: 'main [nv-video-player-presets-details]',
  templateUrl: './video-player-presets-details.component.html',
  styles: []
})
export class VideoPlayerPresetsDetailsComponent implements OnInit, OnDestroy {
  @Input() organizationId: number = null;
  @Input() preset: VideoPlayerPreset = null;

  presetForm: FormGroup;
  loadingPreset = false;

  autoplay: boolean = false;
  mute: boolean = false;
  loop: boolean = false;
  nav = {
    show: true,
    seek: true,
    volume: true,
    quality: false,
    cc: false,
    full_screen: true,
    pip: false,
    time_range: true,
    time: true,
    duration: true
  };

  private subscriptions: Subscription[] = [];

  constructor(private formBuilder: FormBuilder,
              private activeModal: NgbActiveModal,
              private videoPlayerPresetService: VideoPlayerPresetService
  ) {
  }

  ngOnInit() {
    if (this.preset) {
      this.autoplay = Boolean(this.preset.settings?.autoplay);
      this.mute = Boolean(this.preset.settings?.autoplay);
      this.autoplay = Boolean(this.preset.settings?.autoplay);
      this.nav.show = Boolean(this.preset.settings?.nav?.show);
      this.nav.seek = Boolean(this.preset.settings?.nav?.seek);
      this.nav.volume = Boolean(this.preset.settings?.nav?.volume);
      this.nav.quality = Boolean(this.preset.settings?.nav?.quality);
      this.nav.cc = Boolean(this.preset.settings?.nav?.cc);
      this.nav.full_screen = Boolean(this.preset.settings?.nav?.full_screen);
      this.nav.pip = Boolean(this.preset.settings?.nav?.pip);
      this.nav.time_range = Boolean(this.preset.settings?.nav?.time_range);
      this.nav.time = Boolean(this.preset.settings?.nav?.time);
      this.nav.duration = Boolean(this.preset.settings?.nav?.duration);
    }
    this.initForm();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.presetForm = this.formBuilder.group({
      name: [this.preset?.name, [Validators.required]],
      default: [this.preset?.default]
    });
  }

  savePreset() {
    if (this.preset?.id) {
      this.update();
    } else {
      this.create();
    }
  }

  close() {
    this.activeModal.dismiss();
  }

  private update() {
    this.loadingPreset = true;
    const presetFormValue = this.getFormValue();
    this.videoPlayerPresetService
      .updatePlayerPreset(this.preset.id, presetFormValue)
      .subscribe(
        (response) => {
          this.activeModal.close();
          this.loadingPreset = false;
        },
        (error) => {
          this.loadingPreset = false;
        }
      );
  }

  private create() {
    this.loadingPreset = true;
    const presetFormValue = this.getFormValue();
    this.videoPlayerPresetService
      .createPlayerPreset(this.organizationId, presetFormValue)
      .subscribe(
        (response) => {
          this.activeModal.close();
          this.loadingPreset = false;
        },
        (error) => {
          this.loadingPreset = false;
        }
      );
  }

  private getFormValue() {
    const presetValue = {
      ...this.presetForm.getRawValue(),
      settings: {
        autoplay: this.autoplay ? 1 : 0,
        mute: this.mute ? 1 : 0,
        loop: this.loop ? 1 : 0,
        nav: {},
      }
    };
    Object.keys(this.nav)
      .forEach((key) => presetValue.settings.nav[key] = this.nav[key] ? 1 : 0);

    return presetValue;
  }

}
