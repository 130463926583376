import { Component } from '@angular/core';

@Component({
    selector: 'app-live-stage-page',
    templateUrl: './live-stage.component.html'
})
export class LiveStageComponent {

    constructor() { }

}
