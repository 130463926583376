import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Website } from '@navus/core/classes/website';
import { WebsitePage } from '@navus/core/classes/website-page';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { WebsiteService } from '@navus/core/services/website.service';
import { Subscription } from 'rxjs';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { WebsiteControlService } from '../../services/website-control.service';

@Component({
  selector: '[nv-sections-panel]',
  templateUrl: './sections-panel.component.html'
})
export class SectionsPanelComponent implements OnInit, OnDestroy {
  @Input() website: Website;
  @Input() page: WebsitePage;
  @Output() close = new EventEmitter();
  @Output() websiteChanged = new EventEmitter();

  subscriptions: Subscription[] = [];

  constructor(
    private websiteService: WebsiteService,
    private websitePageService: WebsitePageService,
    private websiteControlService: WebsiteControlService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    const websiteSubscription = this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          this.website = website;
          if (this.page) {
            this.page = website.pages.find((page) => page.id === this.page.id);
          }
        }
      );
    this.subscriptions.push(websiteSubscription);

    const pageSubscription = this.websiteService.currentWebsitePage
      .subscribe(
        (websitePage: WebsitePage) => {
          this.page = websitePage;
        }
      );
    this.subscriptions.push(pageSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  new() {
    this.websiteControlService.setEditingSection(new WebsitePageSection());
  }

  details(section: WebsitePageSection) {
    this.websiteControlService.setEditingSection(section);
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.page.sections, event.previousIndex, event.currentIndex);

    const section = this.page.sections[event.currentIndex];
    section.order = event.currentIndex + 1;
    if (section.order === this.page.sections.length) { section.order++; }

    this.websitePageService
      .updateWebsitePageSection(this.website.organization_id, this.website.id, this.page.id, section.id, section)
      .subscribe(
        (response) => {
          this.websiteChanged.emit();
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }
}
