import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BillingService } from '@navus/core/services/billing.service';
import { CreditCardValidators } from 'angular-cc-library';
import { ModalService } from '@navus/ui/modal/modal.service';

@Component({
  selector: 'nv-card-modal',
  templateUrl: './card-modal.component.html'
})
export class CardModalComponent implements OnInit {
  cardForm: FormGroup;

  savingCard: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private billingService: BillingService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    const currentYear = new Date().getFullYear();
    this.cardForm = this.formBuilder.group({
      name: [''],
      number: ['', [CreditCardValidators.validateCCNumber]],
      exp_month: ['', [Validators.required, Validators.min(1), Validators.max(12)]],
      exp_year: ['', [Validators.required, Validators.min(currentYear)]],
      cvc: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
    });
  }

  save() {
    if (this.cardForm.invalid) { return; }

    this.savingCard = true;
    this.billingService.createCard(this.cardForm.value)
      .subscribe(
        () => {
          this.activeModal.close();
          this.savingCard = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.savingCard = false;
        }
      );
  }

  close() {
    this.activeModal.dismiss();
  }

}
