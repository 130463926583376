import {
  Component,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf,
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalService } from '../modal/modal.service';
import { ResourceService } from '@navus/core/services/resource.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'nv-file',
  templateUrl: './file.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NavusFileComponent),
    multi: true
  }]
})
export class NavusFileComponent implements OnInit {
  @Input() type: string = 'image';
  @Input() value: any = null;
  @Input() formControlName: string;
  @Input() name: string;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() information: string;
  @Input() organizationId: number = null;
  @Input() label: string;
  @Input() multiple: boolean = false;
  @Input() folderName: string = null;
  @Input() hideFilename: boolean = false;
  @Input() mime: string[] = null;

  @Output() changeFile = new EventEmitter();

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  showInfo: boolean = false;
  fileUrl: any = null;
  fileName: string = '';

  constructor(
    private modalService: ModalService,
    private resourceService: ResourceService,
    public sanitizer: DomSanitizer,
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer
  ) { }

  ngOnInit() { }

  writeValue(value: any): void {
    this.value = value ? value : null;
    if (value) { 
      this.fileUrl = this.sanitizer.bypassSecurityTrustStyle('background-image:url(' + value + ');'); 

      const fileSegments = value.split('/');
      this.fileName = fileSegments[fileSegments.length - 1];
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  emitChange(event) {
    this.changeFile.emit(event);
  }

  remove() {
    this.modalService.defaultModal({
      title: 'File Removal',
      body: 'Are you sure you want to remove this file?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Remove',
          color: 'accent2',
          handler: () => {
            if (this.formControlName) {
              const formControl = this.controlContainer.control.get(this.formControlName);
              formControl.reset();
            }
            this.value = null;
            this.fileUrl = null;
          }
        }
      ]
    });
  }

  openResourceModal(event) {
    event.preventDefault();
    if (!this.organizationId) { return; }
    this.modalService.openResource(this.organizationId, this.multiple, this.folderName, this.mime)
      .then(
        (response) => {
          if (response) {
            this.fileUrl = this.sanitizer.bypassSecurityTrustStyle('background-image:url(' + response.url + ');');
            this.onChange(response.url);
            this.changeFile.emit(response);
          }
        }
      )
      .catch();
  }

  showInfoToggle() {
    this.showInfo = !this.showInfo;
  }

}
