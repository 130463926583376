import { Routes } from '@angular/router';

import { OrganizationGuard } from './guards/organization.guard';

import { ConfigComponent } from './config.component';
import { AuthGuard } from './guards/auth.guard';
import { OrganizationListPageComponent } from './pages/organization-list/organization-list-page.component';
import { OrganizationDashboardPageComponent } from './pages/organization-dashboard/organization-dashboard-page.component';
import { OrganizationDetailsPageComponent } from './pages/organization-details/organization-details-page.component';
import { OrganizationSetupPageComponent } from './pages/organization-setup/organization-setup-page.component';
import { ConferenceDetailsPageComponent } from './pages/conference-details/conference-details-page.component';
import { ConferenceListPageComponent } from './pages/conference-list/conference-list-page.component';
import { AuthorListPageComponent } from './pages/author-list/author-list-page.component';
import { AuthorDetailsPageComponent } from './pages/author-details/author-details-page.component';
import { FileDashboardComponent } from './pages/file-dashboard/file-dashboard.component';
import { ArticleDetailsPageComponent } from './pages/article-details/article-details-page.component';
import { ArticleListPageComponent } from './pages/article-list/article-list-page.component';
import { OrganizationLandingPageComponent } from './pages/organization-landing/organization-landing-page.component';
import { ConferenceLandingPageComponent } from './pages/conference-landing/conference-landing-page.component';
import { OrganizationBillingPageComponent } from './pages/organization-billing/organization-billing-page.component';
import { PlaceholderPageComponent } from './pages/placeholder/placeholder-page.component';
import { ProgramRoutes } from './modules/program/program.routes';
import { RegistrationRoutes } from './modules/registration/registration.routes';
import { SponsorsRoutes } from './modules/sponsors/sponsors.routes';
import { LeadlinkRoutes } from './modules/leadlink/leadlink.routes';
import { VotingRoutes } from './modules/voting/voting.routes';
import { ContactRoutes } from './modules/contact/contact.routes';
import { AiRoutes } from './modules/ai/ai.routes';
import { CommunicationRoutes } from './modules/communication/communication.routes';
import { AdRoutes } from './modules/ad/ad.routes';
import { EmaterialsRoutes } from './modules/ematerials/ematerials.routes';
import { UserListPageComponent } from './pages/user-list/user-list-page.component';
import { ConferenceWebsitePageComponent } from './pages/conference-website/conference-website-page.component';
import { ConferenceApplicationPageComponent } from './pages/conference-application/conference-application-page.component';
import { LivestreamingRoutes } from './modules/livestreaming/livestreaming.routes';
import { AuthComponent } from './pages/auth/auth.component';
import { NetworkingRoutes } from './modules/networking/networking.routes';
import { MeetingRoomRoutes } from './modules/meeting-rooms/meeting-rooms.routes';
import { ConferenceDashboardRoutes } from './pages/conference-dashboard/conference-dashboard.routes';
import { BadgesRoutes } from './modules/badges/badges.routes';

export let ConfigRoutes: Routes = [
  {
    path: 'o',
    component: ConfigComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: OrganizationListPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Organization List'
        }
      },
      {
        path: 'setup',
        component: OrganizationSetupPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Organization'
        }
      },
      {
        path: ':organizationId/setup/industry',
        component: OrganizationSetupPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Organization',
          step: 'industry'
        }
      },
      {
        path: ':organizationId/setup/event',
        component: OrganizationSetupPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Organization',
          step: 'event'
        }
      },
      {
        path: ':organizationId/setup/start',
        component: OrganizationSetupPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Organization',
          step: 'start'
        }
      },
      {
        path: ':organizationId/billing',
        component: OrganizationBillingPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Billing'
        }
      },
      {
        path: ':organizationId/landing',
        component: OrganizationLandingPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Organization'
        }
      },
      {
        path: ':organizationId/dashboard',
        component: OrganizationDashboardPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Organization Details'
        }
      },
      {
        path: ':organizationId',
        component: OrganizationDetailsPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Organization Details'
        }
      },
      {
        path: ':organizationId/conference/:conferenceId/landing',
        component: ConferenceLandingPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Conference'
        }
      },
      {
        path: ':organizationId/conference/:conferenceId/dashboard',
        canActivate: [OrganizationGuard],
        children: ConferenceDashboardRoutes,
      },
      {
        path: ':organizationId/conference/:conferenceId',
        component: ConferenceDetailsPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Conference Details'
        }
      },
      {
        path: ':organizationId/conference',
        component: ConferenceDetailsPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Conference Details'
        }
      },
      {
        path: ':organizationId/conferences',
        component: ConferenceListPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Conference List'
        }
      },
      {
        path: ':organizationId/conference/:conferenceId/attendees',
        canActivate: [OrganizationGuard],
        children: RegistrationRoutes
      },
      {
        path: ':organizationId/conference/:conferenceId/program',
        canActivate: [OrganizationGuard],
        children: ProgramRoutes
      },
      {
        path: ':organizationId/conference/:conferenceId/sponsors',
        canActivate: [OrganizationGuard],
        children: SponsorsRoutes
      },
      {
        path: ':organizationId/conference/:conferenceId/leadlink',
        canActivate: [OrganizationGuard],
        children: LeadlinkRoutes
      },
      {
        path: ':organizationId/conference/:conferenceId/voting',
        canActivate: [OrganizationGuard],
        children: VotingRoutes
      },
      {
        path: ':organizationId/conference/:conferenceId/badges',
        canActivate: [OrganizationGuard],
        children: BadgesRoutes
      },
      {
        path: ':organizationId/conference/:conferenceId/meeting-rooms',
        canActivate: [OrganizationGuard],
        children: MeetingRoomRoutes
      },
      {
        path: ':organizationId/contact',
        canActivate: [OrganizationGuard],
        children: ContactRoutes
      },
      {
        path: ':organizationId/conference/:conferenceId/contact',
        canActivate: [OrganizationGuard],
        children: ContactRoutes
      },
      {
        path: ':organizationId/conference/:conferenceId/ai',
        canActivate: [OrganizationGuard],
        children: AiRoutes
      },
      {
        path: ':organizationId/conference/:conferenceId/communication',
        canActivate: [OrganizationGuard],
        children: CommunicationRoutes
      },
      {
        path: ':organizationId/conference/:conferenceId/ad',
        canActivate: [OrganizationGuard],
        children: AdRoutes
      },
      {
        path: ':organizationId/conference/:conferenceId/ematerials',
        canActivate: [OrganizationGuard],
        children: EmaterialsRoutes
      },
      {
        path: ':organizationId/conference/:conferenceId/livestreaming',
        canActivate: [OrganizationGuard],
        children: LivestreamingRoutes
      },
      {
        path: ':organizationId/conference/:conferenceId/authors',
        component: AuthorListPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Speaker List'
        }
      },
      {
        path: ':organizationId/conference/:conferenceId/author/:authorId',
        component: AuthorDetailsPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Speaker Details'
        }
      },
      {
        path: ':organizationId/conference/:conferenceId/author',
        component: AuthorDetailsPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Speaker Details'
        }
      },
      {
        path: ':organizationId/conference/:conferenceId/website',
        component: ConferenceWebsitePageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Website'
        }
      },
      {
        path: ':organizationId/conference/:conferenceId/application',
        component: ConferenceApplicationPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Application'
        }
      },
      {
        path: ':organizationId/article/:articleId',
        component: ArticleDetailsPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Article Details'
        }
      },
      {
        path: ':organizationId/article',
        component: ArticleDetailsPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Article Details'
        }
      },
      {
        path: ':organizationId/articles',
        component: ArticleListPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Article List'
        }
      },
      {
        path: ':organizationId/files',
        component: FileDashboardComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'File Manager'
        }
      },
      {
        path: ':organizationId/users',
        component: UserListPageComponent,
        canActivate: [OrganizationGuard],
        data: {
          title: 'Users'
        }
      },
      {
        path: ':organizationId/conference/:conferenceId/networking',
        canActivate: [OrganizationGuard],
        children: NetworkingRoutes
      }
    ]
  },
  {
    path: 'login',
    component: AuthComponent
  },
  {
    path: 'registration',
    component: AuthComponent
  },
  {
    path: 'config-coming-soon',
    component: PlaceholderPageComponent
  }
];
