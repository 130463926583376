import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Website } from '@navus/core/classes/website';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { WebsiteControlService } from '../../services/website-control.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { WebsitePage } from '@navus/core/classes/website-page';

@Component({
  selector: '[nv-pages-panel]',
  templateUrl: './pages-panel.component.html'
})
export class PagesPanelComponent {
  @Input() website: Website;
  @Output() close = new EventEmitter();
  @Output() newPage = new EventEmitter();
  @Output() editPage = new EventEmitter();
  @Output() websiteChanged = new EventEmitter();

  subscriptions: Subscription[] = [];

  constructor(
    private websitePageService: WebsitePageService,
    private websiteControlService: WebsiteControlService,
    private modalService: ModalService
  ) { }

  new() {
    this.websiteControlService.setEditingPage(new WebsitePage());
  }

  details(page: WebsitePage) {
    this.websiteControlService.setEditingPage(page);
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.website.pages, event.previousIndex, event.currentIndex);

    const page = this.website.pages[event.currentIndex];
    page.order = event.currentIndex + 1;
    if (page.order === this.website.pages.length) { page.order++; }

    this.websitePageService
      .updateWebsitePage(this.website.organization_id, this.website.id, page.id, page)
      .subscribe(
        (response) => {
          this.websiteChanged.emit();
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }
}
