import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Template } from '@navus/core/classes/template';
import { TemplateService } from '@navus/core/services/template.service';
import { ModalService } from '@navus/ui/modal/modal.service';

@Component({
  selector: 'main [nv-template-list-page]',
  templateUrl: './template-list-page.component.html'
})
export class TemplateListPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;
  organizationId: number;
  conferenceId: number;
  templates: Template[] = [];

  pagination: { current_page?: number, per_page: number, total?: number } = {
    current_page: 1,
    per_page: 15
  };
  sortOptions: any[] = [
    { id: 'created_at asc', name: 'Created Ascending' },
    { id: 'created_at desc', name: 'Created Descending' },
    { id: 'name asc', name: 'Name Ascending' },
    { id: 'name desc', name: 'Name Descending' },
  ];
  sort: string = 'name asc';
  selectedIds: number[] = [];

  templatesLoading: boolean = false;
  optionsShown: number = null;

  formFilter: FormGroup;
  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private templateService: TemplateService,
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      search_term: ['']
    });
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getTemplates();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getTemplates() {
    const params: any = {
      page: this.pagination.current_page,
      per_page: this.pagination.per_page
    };
    if (this.sort && this.sort !== '') {
      params.sort_by = this.sort.split(' ')[0];
      params.sort_direction = this.sort.split(' ')[1];
    }
    if (this.formFilter.value.search_term !== '') {
      params.search_term = this.formFilter.value.search_term;
    }
    this.templatesLoading = true;
    this.templateService.getOrganizationTemplates(this.organizationId, params)
      .subscribe(
        (response: { data: Template[], meta: any }) => {
          const port = document.getElementsByClassName('nv-config-content-port');
          if (port && port.length > 0) {
            port[0].scrollTop = 0;
          }
          
          this.templates = response.data;
          this.pagination = response.meta.pagination;
          this.templatesLoading = false;
        },
        () => {
          this.templatesLoading = false;
        }
      );
  }

  edit(template: Template) {
    this.router.navigate(['..', 'template', template.id], { relativeTo: this.route });
  }

  close() {
    this.router.navigate(['..', '..'], { relativeTo: this.route });
  }

  add() {
    this.router.navigate(['..', 'template'], { relativeTo: this.route });
  }

  delete(template: Template) {
    this.modalService.defaultModal({
      title: 'Template Deletion',
      body: 'Are you sure you want to delete this template?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.templateService.deleteOrganizationTemplate(this.organizationId, template.id)
              .subscribe(
                () => {
                  this.getTemplates();
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

  toggleSelected(template: Template) {
    const index = this.selectedIds.indexOf(template.id);
    if (index !== -1) {
      this.selectedIds.splice(index, 1);
    } else {
      this.selectedIds.push(template.id);
    }
  }

  selectAll() {
    this.selectedIds = [];
    for (const template of this.templates) {
      this.selectedIds.push(template.id);
    }
  }

  unselectAll() {
    this.selectedIds = [];
  }

  openOptions(template: Template) {
    event.stopPropagation();
    this.optionsShown = template.id;
  }

  closeOptions() {
    event.stopPropagation();
    this.optionsShown = null;
  }

}
