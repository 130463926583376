import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Company } from '@navus/core/classes/company';
import { CompanyService } from '@navus/core/services/company.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'main [nv-company-list-page]',
  templateUrl: './company-list-page.component.html'
})
export class CompanyListPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;
  organizationId: number;
  conferenceId: number;
  companies: Company[] = [];

  pagination: { page?: number, perPage: number, total?: number } = {
    perPage: 30
  };
  sort: { field: string, direction: string } = {
    field: 'order',
    direction: 'asc'
  };
  selectedIds: number[] = [];

  companiesLoading: boolean = false;
  optionsShown: number = null;

  formFilter: FormGroup;
  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private companyService: CompanyService,
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      search_term: ['']
    });
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getCompanies();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getCompanies(append: boolean = false) {
    if (append && this.pagination.total && this.companies.length >= this.pagination.total) { return; }

    this.pagination.page = append ? this.pagination.page + 1 : 1;

    const params: any = {
      page: this.pagination.page,
      per_page: this.pagination.perPage,
      sort_by: this.sort.field,
      sort_direction: this.sort.direction,
      include: 'sponsor_group'
    };
    if (this.formFilter.value.search_term !== '') {
      params.name = this.formFilter.value.search_term;
    }
    this.companiesLoading = !append;
    this.companyService.getConferenceSponsors(this.conferenceId, params)
      .subscribe(
        (response: { data: Company[], meta: any }) => {
          if (append) {
            this.companies = this.companies.concat(response.data);
          } else {
            const port = document.getElementsByClassName('nv-config-content-port');
            if (port && port.length > 0) {
              port[0].scrollTop = 0;
            }
            
            this.companies = response.data;
            this.pagination.total = response.meta.pagination.total;
          }
          this.companiesLoading = false;
        },
        () => {
          this.companiesLoading = false;
        }
      );
  }

  edit(company: Company) {
    this.router.navigate(['..', 'company', company.id], { relativeTo: this.route });
  }

  editInNewTab(company: Company) {
    window.open(`/o/${this.organizationId}/conference/${this.conferenceId}/sponsors/company/${company.id}`, '_blank');
  }

  close() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  add() {
    this.router.navigate(['..', 'company'], { relativeTo: this.route });
  }

  delete(company: Company) {
    this.modalService.defaultModal({
      title: 'Company Deletion',
      body: 'Are you sure you want to delete this company?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.companyService.deleteConferenceSponsor(this.conferenceId, company.id)
              .subscribe(
                () => {
                  this.getCompanies();
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

  sortCompanies(field: string) {
    if (field === this.sort.field) {
      this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sort.field = field;
      this.sort.direction = 'asc';
    }
    this.getCompanies();
  }

  toggleSelected(company: Company) {
    const index = this.selectedIds.indexOf(company.id);
    if (index !== -1) {
      this.selectedIds.splice(index, 1);
    } else {
      this.selectedIds.push(company.id);
    }
  }

  selectAll() {
    this.selectedIds = [];
    for (const company of this.companies) {
      this.selectedIds.push(company.id);
    }
  }

  unselectAll() {
    this.selectedIds = [];
  }

  openOptions(company: Company) {
    event.stopPropagation();
    this.optionsShown = company.id;
  }

  closeOptions() {
    event.stopPropagation();
    this.optionsShown = null;
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.companies, event.previousIndex, event.currentIndex);

    const company = this.companies[event.currentIndex];
    let order;

    if (event.previousIndex > event.currentIndex) {
      order = this.companies[event.currentIndex + 1].order;
    } else {
      order = this.companies[event.currentIndex - 1].order;
    }

    this.companyService
      .updateConferenceSponsor(this.conferenceId, company.id, { order: order })
      .subscribe(
        (response) => {
          this.getCompanies();
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }

}
