import { Component, EventEmitter, Input, OnInit, Output, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Website } from '@navus/core/classes/website';
import { WebsitePage } from '@navus/core/classes/website-page';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { WebsiteControlService } from '../../services/website-control.service';

@Component({
  selector: '[nv-page-panel]',
  templateUrl: './page-panel.component.html'
})
export class PagePanelComponent implements OnInit {
  @Input() website: Website;
  @Input() page: WebsitePage;
  @Output() saved = new EventEmitter();
  @HostBinding('class.on') show = false;

  pages: WebsitePage[] = [];
  pageForm: FormGroup;
  pageTypes: any[] = [
    { id: 'page', name: 'Page' },
    { id: 'external', name: 'External URL' },
    { id: 'program', name: 'Program' },
    { id: 'sponsors', name: 'Sponsors' },
    { id: 'speakers', name: 'Speakers' },
    { id: 'delegates', name: 'Delegates' },
    { id: 'search', name: 'Search' },
    { id: 'posters', name: 'Posters' },
    { id: 'live-streaming', name: 'Live Stage' },
    { id: 'live-stage', name: 'Live Stage New' },
    { id: 'e-materials', name: 'E-materials' },
    // { id: 'e-playlist', name: 'E-playlist' },
  ];

  savingPage = false;
  deletingPage = false;

  constructor(
    private formBuilder: FormBuilder,
    private pageService: WebsitePageService,
    private websiteControlService: WebsiteControlService,
    private modalService: ModalService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.websiteControlService.editingtWebsitePage
      .subscribe(
        (page) => {
          this.page = page;
          this.show = !!page;
          this.initForm();

          if (page) {
            this.pageForm.patchValue(page);
          }
        }
      );

    this.pages = this.website.pages
        .filter(p => p.id !== this.page?.id);

    this.initForm();
  }

  initForm() {
    this.pageForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      slug: ['', [Validators.required]],
      type: ['page', [Validators.required]],
      parent_id: [null],
      config: [null],
      visible: [true],
      show_footer: [true],
    });
  }

  save() {
    if (this.pageForm.invalid) { return; }

    this.savingPage = true;
    if (this.page && this.page.id) {
      this.pageService.updateWebsitePage(0, this.website.id, this.page.id, this.pageForm.value)
        .subscribe(
          (response) => {
            this.page = response['data'];
            this.savingPage = false;
            this.toastService.success('Page successfully saved.');
            this.saved.emit();
            this.close();
          },
          (error) => {
            this.savingPage = false;
            this.modalService.error({ errors: error });
          }
        );
    } else {
      this.pageService.createWebsitePage(0, this.website.id, this.pageForm.value)
        .subscribe(
          (response) => {
            this.page = response['data'];
            this.savingPage = false;
            this.toastService.success('Page successfully created.');
            this.saved.emit();
            this.close();
          },
          (error) => {
            this.savingPage = false;
            this.modalService.error({ errors: error });
          }
        );
    }
  }

  setAsLanding() {
    this.modalService.defaultModal({
      title: 'Landing page',
      body: 'Are you sure you want to set this page as landing page?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Set',
          color: 'primary',
          handler: () => {
            this.savingPage = true;
            this.pageService.updateWebsitePage(0, this.website.id, this.page.id, { landing: true })
              .subscribe(
                (response) => {
                  this.savingPage = false;
                  this.toastService.success('This page is landing page now.');
                  this.saved.emit();
                  this.close();
                },
                (error) => {
                  this.savingPage = false;
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Page Deletion',
      body: 'Are you sure you want to delete this page?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.deletingPage = true;
            this.pageService.deleteWebsitePage(0, this.website.id, this.page.id)
              .subscribe(
                (response) => {
                  this.deletingPage = false;
                  this.toastService.success('Page successfully deleted.');
                  this.saved.emit();
                  this.close();
                },
                (error) => {
                  this.deletingPage = false;
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

  close() {
    this.show = false;
  }

}
