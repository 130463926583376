import { Component, HostListener, Input } from '@angular/core';
import { WebsiteService } from '@navus/core/services/website.service';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { WebsiteControlService } from '../../services/website-control.service';

@Component({
  selector: 'nv-base-section',
  template: ''
})
export class BaseSectionComponent {
  @Input() data: any;
  editMode: boolean = !!localStorage.getItem('edit');
  mouseOver: boolean = false;

  deletingSection: boolean = false;

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService,
  ) { }

  @HostListener('mouseenter')
  onMouseEnter() { this.mouseOver = this.editMode && true; }

  @HostListener('mouseleave')
  onMouseLeave() { this.mouseOver = false; }

  createBefore() {
    const section: WebsitePageSection = this.data;
    this.websiteControlService.setEditingSection(new WebsitePageSection({ order: section.order }));
  }

  createAfter() {
    const section: WebsitePageSection = this.data;
    this.websiteControlService.setEditingSection(new WebsitePageSection({ order: section.order + 1 }));
  }

  edit() {
    const section: WebsitePageSection = this.data;
    this.websiteControlService.setEditingSection(section);
  }

  delete() {
    if (this.deletingSection) { return; }

    const section: WebsitePageSection = this.data;

    this.modalService.defaultModal({
      title: 'Section Deletion',
      body: 'Are you sure you want to delete this section?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.deletingSection = true;
            this.pageService
              .deleteWebsitePageSection(0, 0, section.website_page_id, section.id)
              .subscribe(
                (response) => {
                  this.websiteService.getCurrentWebsite({ include: 'pages,conferences' }).subscribe();
                  this.deletingSection = false;
                },
                (error) => {
                  this.deletingSection = false;
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

  moveUp() {
    const section: WebsitePageSection = this.data;

    this.pageService
      .updateWebsitePageSection(0, 0, section.website_page_id, section.id, { order: section.order - 1 })
      .subscribe(
        (response) => {
          this.websiteService.getCurrentWebsite({ include: 'pages,conferences' }).subscribe();
        },
        (error) => {
          this.modalService.error({errors: error});
        }
      );
  }

  moveDown() {
    const section: WebsitePageSection = this.data;

    this.pageService
      .updateWebsitePageSection(0, 0, section.website_page_id, section.id, { order: section.order + 1 })
      .subscribe(
        (response) => {
          this.websiteService.getCurrentWebsite({ include: 'pages,conferences' }).subscribe();
        },
        (error) => {
          this.modalService.error({errors: error});
        }
      );
  }

}

