import { Component, EventEmitter, forwardRef, Host, Input, OnInit, Optional, Output, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: 'nv-colorpicker',
  templateUrl: './color-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NavusColorPickerComponent),
      multi: true
    }
  ]
})

export class NavusColorPickerComponent implements OnInit, ControlValueAccessor {

  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() value: string = null;
  @Input() confirmButton: boolean = true;
  @Input() confirmButtonText: string = 'Ok';
  @Input() cancelButton: boolean = true;
  @Input() cancelButtonText: string = 'Cancel';
  @Input() formControlName: string;
  @Input() label: string = '';
  @Input() errorMessages: any = {};
  @Input() position: string | 'auto' | 'top' | 'bottom' | 'left' | 'right' | 'top-left'| 'top-right' | 'bottom-left' | 'bottom-right' = 'bottom';
  @Input() positionOffset: string;

  @Output() change = new EventEmitter<string>();

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  errors: any[] = [];
  showErrors: boolean = false;
  cpShow: boolean;

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer
  ) {}


  ngOnInit() {
    if (this.required !== false) {
      this.required = true;
    }
    if (this.disabled !== false) {
      this.disabled = true;
    }
    if (!this.formControlName) {
      this.cancelButton = false;
    }
  }

  writeValue(value: any): void {
    this.value = value ? value : '';
    if (this.value === '') {
      this.errors = [];
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onConfirm(value) {
    if (this.formControlName) {
      this.onChange(this.value = value);
      this.displayError();
    } else {
      this.onChange(this.value);
    }
    this.change.emit(value);
  }

  onCancel(value) {
    if (this.formControlName) {
      const formControl = this.controlContainer.control.get(this.formControlName);
      this.value = formControl.value;
      this.displayError();
    } else {
      this.onChange(this.value = value);
    }
    this.change.emit(value);
  }

  displayError() {
    if (!this.formControlName) { return; }

    const formControl = this.controlContainer.control.get(this.formControlName);

    if (formControl.errors) {
      this.required = formControl.errors.required || false;
      const errors = [];

      for (const [key, value] of Object.entries(formControl.errors)) {
        errors.push(key);
      }

      this.errors = errors;
    } else {
      this.errors = [];
    }
  }

  toggleErrors() {
    this.showErrors = !this.showErrors;
  }
}
