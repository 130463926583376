<div class="nv-template-pane">
    <header class="nv-temp-header nv-section">
        <div class="nv-row space-between">
            <div class="nv-column desk-initial v-middle">
                <div class="nv-column-content">
                    <div class="nv-template-logo">
                        <a href="/" routerlink="/" title="Sponsorship spaces &amp; Virtual Exhibitors"><!----></a>
                    </div>
                </div>
            </div>
            <div class="nv-column desk-initial v-stretch" style="display: flex;">
                <div class="nv-column-content">
                    <div class="nv-template-menu-n-profile">
                        <nav class="nv-template-menu">
                            <ul>
                                <li class="nav-page-1014"><a class="nav-link active" href="/1014/page/home"><span>Home</span></a></li>
                                <li class="nav-page-1037"><a class="nav-link" href="/1037/page/sponsor-packages"><span>Sponsor Packages</span></a></li>
                                <li class="nav-page-1034"><a class="nav-link" href="/sponsors"><span>Pre-View Example Exhibition page</span></a></li>
                                <li class="nav-page-1035"><a class="nav-link" href="/1035/page/brochure"><span>Downlaod Sponsor Brochure</span></a></li>
                            </ul>
                        </nav>
                        <div class="nv-template-notifications">
                            <div class="nv-notifications-toggler">
                                <span>5</span>
                            </div><!-- /.nv-notifications-toggler -->
                            <div class="nv-notifications-pane">
                                <div class="nv-notifications-pane-frame">
                                    <header class="nv-notifications-pane-header">
                                        <h4>Notifications</h4>
                                        <div class="nv-notifications-switch">
                                            <h4>Do not disturb</h4>
                                            <div class="tsq-checkbox-radio check-type-slide">
                                                <label title="Turn notifications on & off">
                                                    <input type="checkbox" name="r1">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div>
                                        </div><!-- /.nv-notifications-switch -->
                                    </header><!-- /.nv-notifications-pane-header -->
                                    <div class="nv-notifications-list">
                                        <div class="nv-notifications-item support">
                                            <div class="nv-notifications-item-icon"></div>
                                            <div class="nv-notifications-item-txt">
                                                <div class="msg">
                                                    <p>Hi there! Welcome to CSANZ Annual Scientific Meeting 2020!</p>
                                                </div><!-- /.msg -->
                                                <div class="msg-posted">a few seconds ago</div>
                                            </div><!-- /.nv-notifications-item-txt -->
                                            <div class="nv-notifications-item-ctrl">
                                                <span></span>
                                                <ul class="nv-notifications-item-options">
                                                    <li><a title="Mark as read">Mark as read</a></li>
                                                    <li><a title="Delete">Delete</a></li>
                                                </ul><!-- /.nv-notifications-item-options -->
                                            </div><!-- /.nv-notifications-item-ctrl -->
                                        </div><!-- /.nv-notifications-item -->
                                        <div class="nv-notifications-item chat">
                                            <div class="nv-notifications-item-icon"></div>
                                            <div class="nv-notifications-item-txt">
                                                <div class="msg">
                                                    <p><strong>Dai Jiangm</strong> wants to chat with you.</p>
                                                </div><!-- /.msg -->
                                                <div class="msg-posted">5 minutes ago</div>
                                            </div><!-- /.nv-notifications-item-txt -->
                                            <div class="nv-notifications-item-ctrl">
                                                <span></span>
                                                <ul class="nv-notifications-item-options">
                                                    <li><a title="Mark as read">Mark as read</a></li>
                                                    <li><a title="Delete">Delete</a></li>
                                                </ul><!-- /.nv-notifications-item-options -->
                                            </div><!-- /.nv-notifications-item-ctrl -->
                                        </div><!-- /.nv-notifications-item -->
                                        <div class="nv-notifications-item chat">
                                            <div class="nv-notifications-item-icon"></div>
                                            <div class="nv-notifications-item-txt">
                                                <div class="msg">
                                                    <p>You have two new messages from <strong>Clarke Gillebert</strong></p>
                                                </div><!-- /.msg -->
                                                <div class="msg-posted">5 minutes ago</div>
                                            </div><!-- /.nv-notifications-item-txt -->
                                            <div class="nv-notifications-item-ctrl">
                                                <span></span>
                                                <ul class="nv-notifications-item-options">
                                                    <li><a title="Mark as read">Mark as read</a></li>
                                                    <li><a title="Delete">Delete</a></li>
                                                </ul><!-- /.nv-notifications-item-options -->
                                            </div><!-- /.nv-notifications-item-ctrl -->
                                        </div><!-- /.nv-notifications-item -->
                                        <div class="nv-notifications-item qa">
                                            <div class="nv-notifications-item-icon"></div>
                                            <div class="nv-notifications-item-txt">
                                                <div class="msg">
                                                    <p><strong>Anna Fali</strong> liked your question.</p>
                                                </div><!-- /.msg -->
                                                <div class="msg-posted">5 minutes ago</div>
                                            </div><!-- /.nv-notifications-item-txt -->
                                            <div class="nv-notifications-item-ctrl">
                                                <span></span>
                                                <ul class="nv-notifications-item-options">
                                                    <li><a title="Mark as read">Mark as read</a></li>
                                                    <li><a title="Delete">Delete</a></li>
                                                </ul><!-- /.nv-notifications-item-options -->
                                            </div><!-- /.nv-notifications-item-ctrl -->
                                        </div><!-- /.nv-notifications-item -->
                                        <div class="nv-notifications-item voting">
                                            <div class="nv-notifications-item-icon"></div>
                                            <div class="nv-notifications-item-txt">
                                                <div class="msg">
                                                    <p>New Voting has started in <strong>Florida</strong>.</p>
                                                </div><!-- /.msg -->
                                                <div class="msg-posted">Yesterday</div>
                                            </div><!-- /.nv-notifications-item-txt -->
                                            <div class="nv-notifications-item-ctrl">
                                                <span></span>
                                                <ul class="nv-notifications-item-options">
                                                    <li><a title="Mark as read">Mark as read</a></li>
                                                    <li><a title="Delete">Delete</a></li>
                                                </ul><!-- /.nv-notifications-item-options -->
                                            </div><!-- /.nv-notifications-item-ctrl -->
                                        </div><!-- /.nv-notifications-item -->
                                        <div class="nv-notifications-item support">
                                            <div class="nv-notifications-item-icon"></div>
                                            <div class="nv-notifications-item-txt">
                                                <div class="msg">
                                                    <p>Hi there! Welcome to CSANZ Annual Scientific Meeting 2020!</p>
                                                </div><!-- /.msg -->
                                                <div class="msg-posted">a few seconds ago</div>
                                            </div><!-- /.nv-notifications-item-txt -->
                                            <div class="nv-notifications-item-ctrl">
                                                <span></span>
                                                <ul class="nv-notifications-item-options">
                                                    <li><a title="Mark as read">Mark as read</a></li>
                                                    <li><a title="Delete">Delete</a></li>
                                                </ul><!-- /.nv-notifications-item-options -->
                                            </div><!-- /.nv-notifications-item-ctrl -->
                                        </div><!-- /.nv-notifications-item -->
                                        <div class="nv-notifications-item chat">
                                            <div class="nv-notifications-item-icon"></div>
                                            <div class="nv-notifications-item-txt">
                                                <div class="msg">
                                                    <p><strong>Dai Jiangm</strong> wants to chat with you.</p>
                                                </div><!-- /.msg -->
                                                <div class="msg-posted">5 minutes ago</div>
                                            </div><!-- /.nv-notifications-item-txt -->
                                            <div class="nv-notifications-item-ctrl">
                                                <span></span>
                                                <ul class="nv-notifications-item-options">
                                                    <li><a title="Mark as read">Mark as read</a></li>
                                                    <li><a title="Delete">Delete</a></li>
                                                </ul><!-- /.nv-notifications-item-options -->
                                            </div><!-- /.nv-notifications-item-ctrl -->
                                        </div><!-- /.nv-notifications-item -->
                                        <div class="nv-notifications-item chat">
                                            <div class="nv-notifications-item-icon"></div>
                                            <div class="nv-notifications-item-txt">
                                                <div class="msg">
                                                    <p>You have two new messages from <strong>Clarke Gillebert</strong></p>
                                                </div><!-- /.msg -->
                                                <div class="msg-posted">5 minutes ago</div>
                                            </div><!-- /.nv-notifications-item-txt -->
                                            <div class="nv-notifications-item-ctrl">
                                                <span></span>
                                                <ul class="nv-notifications-item-options">
                                                    <li><a title="Mark as read">Mark as read</a></li>
                                                    <li><a title="Delete">Delete</a></li>
                                                </ul><!-- /.nv-notifications-item-options -->
                                            </div><!-- /.nv-notifications-item-ctrl -->
                                        </div><!-- /.nv-notifications-item -->
                                        <div class="nv-notifications-item qa">
                                            <div class="nv-notifications-item-icon"></div>
                                            <div class="nv-notifications-item-txt">
                                                <div class="msg">
                                                    <p><strong>Anna Fali</strong> liked your question.</p>
                                                </div><!-- /.msg -->
                                                <div class="msg-posted">5 minutes ago</div>
                                            </div><!-- /.nv-notifications-item-txt -->
                                            <div class="nv-notifications-item-ctrl">
                                                <span></span>
                                                <ul class="nv-notifications-item-options">
                                                    <li><a title="Mark as read">Mark as read</a></li>
                                                    <li><a title="Delete">Delete</a></li>
                                                </ul><!-- /.nv-notifications-item-options -->
                                            </div><!-- /.nv-notifications-item-ctrl -->
                                        </div><!-- /.nv-notifications-item -->
                                        <div class="nv-notifications-item voting">
                                            <div class="nv-notifications-item-icon"></div>
                                            <div class="nv-notifications-item-txt">
                                                <div class="msg">
                                                    <p>New Voting has started in <strong>Florida</strong>.</p>
                                                </div><!-- /.msg -->
                                                <div class="msg-posted">Yesterday</div>
                                            </div><!-- /.nv-notifications-item-txt -->
                                            <div class="nv-notifications-item-ctrl">
                                                <span></span>
                                                <ul class="nv-notifications-item-options">
                                                    <li><a title="Mark as read">Mark as read</a></li>
                                                    <li><a title="Delete">Delete</a></li>
                                                </ul><!-- /.nv-notifications-item-options -->
                                            </div><!-- /.nv-notifications-item-ctrl -->
                                        </div><!-- /.nv-notifications-item -->
                                    </div><!-- /.nv-notifications-list -->
                                    <div class="nv-notifications-pane-footer">
                                        <h4><a title="Mark all as read">Mark all as read</a></h4>
                                    </div><!-- /.nv-notifications-pane-footer -->
                                </div><!-- /.nv-notifications-pane-frame -->
                            </div><!-- /.nv-notifications-pane -->
                        </div><!-- /.nv-template-notifications -->
                        <div class="nv-template-profile">
                            <a translate="" class="nv-btn small accent2 radius-high" href="/profile/login">Login / Register</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="nv-column desk-none tab-initial v-stretch">
                <div class="nv-column-content">
                    <div class="nv-touch-menu-trigger"></div>
                </div>
            </div>
        </div>
    </header><!-- /nv-temp-header -->
    <div class="nv-template-wrap">
        <div class="nv-section guttered">
            <div class="nv-port">
                <div class="nv-row large-spaced-top semi-spaced-bottom">
                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <div class="nv-block-banner" role="banner">
                                <img src="//service.e-materials.com/storage/resources/sehh/set/setss-banner-afiliados3.jpg" alt="SETSS - Asamblea General de afiliados de SIETeSS">
                            </div><!-- /.nv-block-banner -->
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->
                </div><!-- /.nv-row -->
                <div class="nv-row large-spaced-bottom">
                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <div class="nv-break-rooms-wrap">
                                <div class="nv-break-room-item">
                                    <div class="nv-break-room-pane">
                                        <div class="nv-break-room-meta">
                                            <h3 class="active">Room 1</h3>
                                            <p class="time">09:30 AM - 11:00 AM</p>
                                        </div><!-- /.nv-break-room-meta -->
                                        <h2>The ABCs of Clownfish Diseases</h2>
                                        <p>Steeped in economics, network theory and standards-speak, Gottfried Leibbrandt has spent a lifetime in payments and…</p>
                                        <div class="nv-break-room-info-cta">
                                            <h5>Moderator</h5>
                                            <div class="nv-break-room-info-cta-item">
                                                <div class="nv-break-room-moderator">
                                                    <figure><img src="https://loremflickr.com/320/240/face?lock=1" alt="name"></figure>
                                                    <div class="nv-break-room-moderator-data">
                                                        <h6>Alexandre Paiva</h6>
                                                        <p>Brazil</p>
                                                    </div><!-- /.nv-break-room-moderator-data -->
                                                </div><!-- /.nv-break-room-moderator -->
                                            </div><!-- /.nv-break-room-info-cta-item -->
                                            <div class="nv-break-room-info-cta-item">
                                                <a href class="nv-btn small accent2 radius-high">Join meeting</a>
                                            </div><!-- /.nv-break-room-info-cta-item -->
                                        </div><!-- /.nv-break-room-info-cta -->
                                    </div><!-- /.nv-break-room-pane -->
                                </div><!-- /.nv-break-room-item -->

                                <div class="nv-break-room-item">
                                    <div class="nv-break-room-pane">
                                        <div class="nv-break-room-meta">
                                            <h3>Room 1</h3>
                                            <p class="time">09:30 AM - 11:00 AM</p>
                                        </div><!-- /.nv-break-room-meta -->
                                        <h2>The ABCs of Clownfish Diseases</h2>
                                        <p>Steeped in economics, network theory and standards-speak, Gottfried Leibbrandt has spent a lifetime in payments and…</p>
                                        <div class="nv-break-room-info-cta">
                                            <h5>Moderator</h5>
                                            <div class="nv-break-room-info-cta-item">
                                                <div class="nv-break-room-moderator">
                                                    <figure><img src="https://loremflickr.com/320/240/face?lock=2" alt="name"></figure>
                                                    <div class="nv-break-room-moderator-data">
                                                        <h6>Alexandre Paiva</h6>
                                                        <p>Brazil</p>
                                                    </div><!-- /.nv-break-room-moderator-data -->
                                                </div><!-- /.nv-break-room-moderator -->
                                            </div><!-- /.nv-break-room-info-cta-item -->
                                            <div class="nv-break-room-info-cta-item">
                                                <a href class="nv-btn small accent2 radius-high">Join meeting</a>
                                            </div><!-- /.nv-break-room-info-cta-item -->
                                        </div><!-- /.nv-break-room-info-cta -->
                                    </div><!-- /.nv-break-room-pane -->
                                </div><!-- /.nv-break-room-item -->

                                <div class="nv-break-room-item">
                                    <div class="nv-break-room-pane">
                                        <div class="nv-break-room-meta">
                                            <h3>Room 1</h3>
                                            <p class="time">09:30 AM - 11:00 AM</p>
                                        </div><!-- /.nv-break-room-meta -->
                                        <h2>The ABCs of Clownfish Diseases</h2>
                                        <p>Steeped in economics, network theory and standards-speak, Gottfried Leibbrandt has spent a lifetime in payments and…</p>
                                        <div class="nv-break-room-info-cta">
                                            <h5>Moderator</h5>
                                            <div class="nv-break-room-info-cta-item">
                                                <div class="nv-break-room-moderator">
                                                    <figure><img src="https://loremflickr.com/320/240/face?lock=3" alt="name"></figure>
                                                    <div class="nv-break-room-moderator-data">
                                                        <h6>Alexandre Paiva</h6>
                                                        <p>Brazil</p>
                                                    </div><!-- /.nv-break-room-moderator-data -->
                                                </div><!-- /.nv-break-room-moderator -->
                                            </div><!-- /.nv-break-room-info-cta-item -->
                                            <div class="nv-break-room-info-cta-item">
                                                <a href class="nv-btn small accent2 radius-high">Join meeting</a>
                                            </div><!-- /.nv-break-room-info-cta-item -->
                                        </div><!-- /.nv-break-room-info-cta -->
                                    </div><!-- /.nv-break-room-pane -->
                                </div><!-- /.nv-break-room-item -->
                            </div><!-- /.nv-break-rooms-wrap -->
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->
                </div><!-- /.nv-row -->
            </div><!-- /.nv-port -->
        </div><!-- /.nv-section -->
    </div><!-- /.nv-template-wrap -->
</div><!-- /.nv-template-pane -->







