import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BlockCategory } from '@navus/core/classes/block-category';
import { BlockCategoryService } from '@navus/core/services/block-category.service';
import { LoadingService } from '@navus/ui/loading/loading.service';
import { ModalService } from '@navus/ui/modal/modal.service';

@Component({
  selector: 'main [nv-block-category-list-page]',
  templateUrl: './block-category-list-page.component.html',
  styles: []
})
export class BlockCategoryListPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  blockCategories: BlockCategory[] = [];

  pagination: { page?: number, perPage: number, total?: number } = {
    perPage: 30
  };
  sort: { field: string, direction: string } = {
    field: 'name',
    direction: 'asc'
  };
  selectedIds: number[] = [];

  blockCategoriesLoading: boolean = false;
  optionsShown: number = null;

  formFilter: FormGroup;
  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private blockCategoryService: BlockCategoryService,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      search_term: ['']
    });
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getBlockCategories();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getBlockCategories(append: boolean = false) {
    if (append && this.pagination.total && this.blockCategories.length >= this.pagination.total) { return; }

    this.pagination.page = append ? this.pagination.page + 1 : 1;

    const params: any = {
      page: this.pagination.page,
      per_page: this.pagination.perPage,
      sort_by: this.sort.field,
      sort_direction: this.sort.direction,
    };
    if (this.formFilter.value.search_term !== '') {
      params.name = this.formFilter.value.search_term;
    }
    this.blockCategoriesLoading = !append;
    this.blockCategoryService.getConferenceBlockCategories(this.conferenceId, params)
      .subscribe(
        (response: { data: BlockCategory[], meta: any }) => {
          if (append) {
            this.blockCategories = this.blockCategories.concat(response.data);
          } else {
            const port = document.getElementsByClassName('nv-config-content-port');
            if (port && port.length > 0) {
              port[0].scrollTop = 0;
            }
            
            this.blockCategories = response.data;
            this.pagination.total = response.meta.pagination.total;
          }
          this.blockCategoriesLoading = false;
        },
        () => {
          this.blockCategoriesLoading = false;
        }
      );
  }

  edit(blockCategory: BlockCategory) {
    this.router.navigate(['..', 'block-category', blockCategory.id], { relativeTo: this.route });
  }

  close() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  add() {
    this.router.navigate(['..', 'block-category'], { relativeTo: this.route });
  }

  delete(blockCategory: BlockCategory) {
    this.modalService.defaultModal({
      title: 'Session Category Deletion',
      body: 'Are you sure you want to delete this session category?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.blockCategoryService.deleteConferenceBlockCategory(this.conferenceId, blockCategory.id)
              .subscribe(
                () => {
                  this.getBlockCategories();
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }


  sortBlockCategories(field: string) {
    if (field === this.sort.field) {
      this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sort.field = field;
      this.sort.direction = 'asc';
    }
    this.getBlockCategories();
  }

  toggleSelected(blockCategory: BlockCategory) {
    const index = this.selectedIds.indexOf(blockCategory.id);
    if (index !== -1) {
      this.selectedIds.splice(index, 1);
    } else {
      this.selectedIds.push(blockCategory.id);
    }
  }

  selectAll() {
    this.selectedIds = [];
    for (const blockCategory of this.blockCategories) {
      this.selectedIds.push(blockCategory.id);
    }
  }

  unselectAll() {
    this.selectedIds = [];
  }

  openOptions(blockCategory: BlockCategory) {
    event.stopPropagation();
    this.optionsShown = blockCategory.id;
  }

  closeOptions() {
    event.stopPropagation();
    this.optionsShown = null;
  }

}
