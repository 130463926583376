<div *ngIf="website" class="nv-touch-menu" [ngClass]="{'on' : showResponsiveMenu}"> <!-- additional class 'ON' to show -->
    <div class="nv-tm-header">
        <div class="tm-logo" routerLink="/">
            <span>{{website.name}}</span>
            <img *ngIf="website.logo" [src]="website.logo" alt="{{ website?.name }}">
        </div><!-- / nv-touch-menu-logo -->
        <a class="tm-close" (click)="toggleMenu()"><span></span></a>
    </div><!-- /.nv-tm-header -->
    <nav>
        <ul>
            <li *ngFor="let page of pages" class="{{ 'nav-page-' + page.id }}">
                <a *ngIf="page.type !== 'external'" [routerLink]="page.resolveUrl()" (click)="toggleMenu()">{{ page.title }}</a>
                <a *ngIf="page.type === 'external' && isExternalLink(page.resolveUrl())" [href]="page.resolveUrl()" (click)="toggleMenu()" [target]="'_blank'">{{ page.title }}</a>
                <a *ngIf="page.type === 'external' && !isExternalLink(page.resolveUrl())" [routerLink]="page.resolveUrl()" (click)="toggleMenu()">{{ page.title }}</a>
                <ng-container *ngIf="page.pages && page.pages.length > 0">
                    <span class="nv-sub-toggler" [ngClass]="{'on': showSubPages}" (click)="showSubPages = !showSubPages"></span>
                    <ul *ngIf="showSubPages">
                        <li *ngFor="let subPage of page.pages">
                            <a *ngIf="subPage.type !== 'external'" [routerLink]="subPage.resolveUrl()" (click)="toggleMenu()">{{ subPage.title }}</a>
                            <a *ngIf="subPage.type === 'external' && isExternalLink(subPage.resolveUrl())" [href]="subPage.resolveUrl()" (click)="toggleMenu()" [target]="'_blank'">{{ subPage.title }}</a>
                            <a *ngIf="subPage.type === 'external' && !isExternalLink(subPage.resolveUrl())" [routerLink]="subPage.resolveUrl()" (click)="toggleMenu()">{{ subPage.title }}</a>
                        </li>
                    </ul>
                </ng-container>
            </li>
        </ul>
    </nav><!-- /.nv-main-menu -->
</div><!-- /.nv-touch-menu -->

<!--<header *ngIf="website" class="nv-main-header {{ website ? 'website-' + website.id : '' }}" [ngClass]="{ sticky: sticky }">-->
<!--    <div class="nv-logo-port" #headerLogo>-->
<!--        <a class="nv-logo-holder" routerLink="/">-->
<!--            <span>{{ website.name }}</span>-->
<!--            <img *ngIf="website.logo" [src]="website.logo" alt="{{ website.name }}">-->
<!--        </a>-->
<!--    </div>&lt;!&ndash; /.nv-logo-port &ndash;&gt;-->
<!--    <div class="nv-menu-n-profile" #headerMenu>-->
<!--        <nav class="nv-main-menu alt-tmp" [style.display]="showMenuIcon ? 'none' : 'flex'">-->
<!--            <ul>-->
<!--                <li *ngFor="let page of pages" class="{{ 'nav-page-' + page.id }}">-->
<!--                    <a [routerLink]="page.resolveUrl()" class="nav-link">{{ page.title }}</a>-->
<!--                    <ul *ngIf="page.pages && page.pages.length > 0">-->
<!--                        <li *ngFor="let subPage of page.pages">-->
<!--                            <a [routerLink]="subPage.resolveUrl()">{{ subPage.title }}</a>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </li>-->
<!--            </ul>-->
<!--        </nav>&lt;!&ndash; /.nv-main-menu &ndash;&gt;-->
<!--        <div class="nv-header-profile">-->
<!--            <div class="nhp-item" *ngIf="!user">-->
<!--                <nav>-->
<!--                    <ul>-->
<!--                        <li>-->
<!--                            <a class="nv-sign-in" routerLink="/profile/login" routerLinkActive="active" translate>Sign in</a>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </nav>-->
<!--            </div>&lt;!&ndash; /.nhp-item &ndash;&gt;-->
<!--            <div class="nhp-item" *ngIf="user">-->
<!--                <nav>-->
<!--                    <ul>-->
<!--                        <li>-->
<!--                            <a routerLink="/profile" class="nv-user">{{ user.first_name }} {{ user.last_name }}</a>-->
<!--                            <a class="nv-sign-out" (click)="logout()" translate>Sign out</a>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </nav>-->
<!--            </div>&lt;!&ndash; /.nhp-item &ndash;&gt;-->
<!--        </div>&lt;!&ndash; /.nv-header-profile &ndash;&gt;-->
<!--        <div class="nv-touch-menu-trigger" *ngIf="showMenuIcon" (click)="toggleMenu()"></div>-->
<!--    </div>-->
<!--</header>-->


<header class="nv-temp-header nv-section {{ website ? 'website-' + website.id : '' }}" *ngIf="website" [ngClass]="{ sticky: sticky }">
    <div class="nv-row space-between">
        <div class="nv-column desk-initial v-middle">
            <div class="nv-column-content">
                <div class="nv-template-logo">
                    <a href title="{{ website.name }}" routerLink="/"><img *ngIf="website.logo" [src]="website.logo" alt="{{ website.name }}"></a>
                </div><!-- /.nv-template-logo -->
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
        <div class="nv-column desk-initial v-stretch" [style.display]="showMenuIcon ? 'none' : 'flex'" #headerMenu>
            <div class="nv-column-content">
                <div class="nv-template-menu-n-profile">
                    <nav class="nv-template-menu">
                        <ul>
                            <li *ngFor="let page of pages" class="{{ 'nav-page-' + page.id }}">
                                <a *ngIf="page.type !== 'external'" [routerLink]="page.resolveUrl()" class="nav-link" [routerLinkActive]="'active'"><span>{{ page.title }}</span></a>
                                <a *ngIf="page.type === 'external' && isExternalLink(page.resolveUrl())" [href]="page.resolveUrl()" class="nav-link" [routerLinkActive]="'active'" [target]="'_blank'"><span>{{ page.title }}</span></a>
                                <a *ngIf="page.type === 'external' && !isExternalLink(page.resolveUrl())" [routerLink]="page.resolveUrl()" class="nav-link" [routerLinkActive]="'active'"><span>{{ page.title }}</span></a>
                                <ul *ngIf="page.pages && page.pages.length > 0">
                                    <li *ngFor="let subPage of page.pages">
                                        <a *ngIf="subPage.type !== 'external'"  [routerLink]="subPage.resolveUrl()">{{ subPage.title }}</a>
                                        <a *ngIf="subPage.type === 'external' && isExternalLink(subPage.resolveUrl())"  [href]="subPage.resolveUrl()" [target]="'_blank'">{{ subPage.title }}</a>
                                        <a *ngIf="subPage.type === 'external' && !isExternalLink(subPage.resolveUrl())"  [routerLink]="subPage.resolveUrl()">{{ subPage.title }}</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav><!-- /.nv-template-menu -->
                    <div class="nv-template-notifications" 
                        *ngIf="user && !disableNotificationsMenu" 
                        [class.on]="showNotificationsMenu"
                        nvOffClick (offClick)="showNotificationsMenu = false"
                    >
                        <div class="nv-notifications-toggler" (click)="toggleNotificationsMenu()">
                            <span *ngIf="unreadNotificationsCount > 0">{{ unreadNotificationsCount }}</span>
                        </div><!-- /.nv-notifications-toggler -->
                        <div class="nv-notifications-pane">
                            <div class="nv-notifications-pane-frame">
                                <header class="nv-notifications-pane-header">
                                    <h4>Notifications</h4>
                                    <a class="nv-notifications-mark-read" title="Mark all as read" (click)="markReadAll()">Mark all as read</a>
                                </header><!-- /.nv-notifications-pane-header -->
                                <nav class="nv-notifications-list-alt"
                                    nvInfiniteScroll
                                    (scrolled)="loadMoreNotifications()"
                                >
                                    <ul>
                                        <li [class.nv-read]="notification.read" *ngFor="let notification of notifications">
                                            <span class="nv-mark-as-read" *ngIf="!notification.read" (click)="markRead(notification.id)">
                                                <a></a>
                                                <em>Mark as read</em>
                                            </span>
                                            <time>{{ notification.schedule_time | nvDate }}</time>
                                            <h3>{{ notification.title }}</h3>
                                            <p>{{ notification.body }}</p>
                                            <!-- <p><a href class="nv-btn small accent1">Action Button</a></p> -->
                                        </li>
                                        <li class="nv-read" *ngIf="notifications.length === 0">
                                            <p style="text-align: center; padding: 50px 0;">We don’t have notifications for you yet</p>
                                        </li>
                                    </ul>
                                </nav>
                            </div><!-- /.nv-notifications-pane-frame -->
                        </div><!-- /.nv-notifications-pane -->
                    </div><!-- /.nv-template-notifications -->
                    <div class="nv-template-profile">
                        <a *ngIf="!user" class="nv-btn small accent2 radius-high" [routerLink]="['/profile/login']" [routerLinkActive]="'active'" translate>Login / Register</a>
                        <div *ngIf="user"
                             nvOffClick (offClick)="showProfileMenu = false"
                             [class.on]="showProfileMenu"
                             (click)="toggleProfileMenu()"
                             class="nv-btn small accent2 radius-100 icon nv-bar-profile-menu">
                            {{ user.first_name.charAt(0) }}{{ user.last_name.charAt(0) }}
                            <nav>
                                <ul>
                                    <li><a [routerLink]="['/profile']">Profile</a></li>
                                    <li><a (click)="logout()" translate>Sign Out</a></li>
                                </ul>
                            </nav><!-- /nv-bar-profile-menu -->
                        </div> <!-- LOGGED IN VER. -->
                    </div><!-- /.nv-template-profile -->
                </div><!-- /.nv-template-menu-n-profile -->
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->

<!--        <div class="nv-column desk-initial v-stretch" *ngIf="showMenuIcon">-->

        <div class="nv-column desk-none tab-initial v-stretch">
            <div class="nv-column-content">
<!--                <div class="nv-touch-menu-trigger" *ngIf="showMenuIcon" (click)="toggleMenu()"></div>-->
                <div class="nv-touch-menu-trigger" (click)="toggleMenu()"></div>
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->
    </div><!-- /.nv-row -->
</header><!-- /.nv-temp-header -->


