import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from '@navus/core/services/user.service';
import { CountryService } from '@navus/core/services/country.service';
import { Country } from '@navus/core/classes/country';
import { ModalService } from '@navus/ui/modal/modal.service';
import { PackageService } from '@navus/core/services/package.service';
import { Package } from '@navus/core/classes/package';
import { OrganizationService } from '@navus/core/services/organization.service';
import { Organization } from '@navus/core/classes/organization';

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth.component.html'
})
export class AuthComponent implements OnInit, OnDestroy {
  returnUrl: string = null;
  allCountries: Country[] = [];
  filteredCountries: Country[] = [];
  authForm: FormGroup;
  loading: boolean = false;
  requestingAccessCode: boolean = false;
  accessCodeRequested: boolean = false;
  mode: string = 'registration';
  packageType: string = '';
  package: Package;
  isConfig: boolean = false;

  subscriptions: Subscription[] = [];

  keyEvent(event: KeyboardEvent) {
    if (event.key && event.key.toLowerCase() === 'enter') {
      (document.activeElement as HTMLElement).blur();

      if (this.mode === 'registration') {
        this.register();
      } else if (this.mode === 'login') {
        this.login();
      }
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private countryService: CountryService,
    private packageService: PackageService,
    private organizationService: OrganizationService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getCountries();

    const fullLocation = window.location.host;
    const parts = fullLocation.replace('www.', '').split('.');
    const slug = parts[0];
    this.isConfig = slug === 'config';

    const routeSubscription = this.route.data
      .subscribe((params) => {
        if (params.mode) {
          this.mode = params.mode;
        }
      });
    this.subscriptions.push(routeSubscription);

    const querySubscription = this.route.queryParams
      .subscribe((params) => {
        if (params.email) {
          this.authForm.reset();
          this.authForm.controls['email'].setValue(params.email);
        }
        let plan = params.plan ? params.plan : 'advanced';
        this.packageType = plan;
        this.getPackage(plan);

        if (params.returnUrl) {
          this.returnUrl = params.returnUrl;
        }
      });
    this.subscriptions.push(querySubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.authForm = new FormGroup({
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,8}$/)
      ]),
      'first_name': new FormControl('', [
        Validators.required,
        Validators.maxLength(30)
      ]),
      'last_name': new FormControl('', [
        Validators.required,
        Validators.maxLength(30)
      ]),
      'password': new FormControl('', [
        Validators.required,
        // Validators.pattern(/.*^(?=.{6,20})(?=.*[a-zA-Z])(?=.*[0-9]).*$/)
      ]),
      'institution': new FormControl('', [
        Validators.required,
        Validators.maxLength(30)
      ]),
      'country_id': new FormControl('', [
        Validators.required
      ]),
      'terms_and_conditions': new FormControl(false, [
        Validators.pattern('true')
      ]),
      'access_code': new FormControl('')
    });
  }

  checkEmail() {
    if (!this.authForm.controls.email.valid) { return; }

    this.loading = true;
    this.userService.checkEmail(this.authForm.value.email)
      .subscribe(
        () => {
          this.mode = 'login';
          this.loading = false;
        },
        (error) => {
          if (error.status === 404) {
            this.mode = 'registration';
            this.authForm.controls['password'].reset();
          }
          this.loading = false;
        }
      );
  }

  forgotPassword() {
    if (this.authForm.value.email === '') { return; }

    this.requestingAccessCode = true;
    this.userService.requestAccessCode(this.authForm.value.email)
      .subscribe(
        () => {
          this.requestingAccessCode = false;
          this.accessCodeRequested = true;
          this.modalService.defaultModal({
            title: 'Access Code',
            body: 'Email with access code has been sent to ' + this.authForm.value.email,
            buttons: [{ text: 'Okay', role: 'cancel' }]
          });
        },
        (error) => {
          this.requestingAccessCode = false;
          this.modalService.error({ errors: error });
        }
      );
  }

  register() {
    if (this.authForm.valid) {
      this.loading = true;
      this.userService.register(this.authForm.value)
        .subscribe(
          (response) => {
            this.userService.login({ email: response.data.email, password: this.authForm.value.password })
              .subscribe(
                data => {
                  this.userService.getCurrentUser()
                    .subscribe(
                      () => {
                        if (this.isConfig) {
                          this.getOrganizations();
                        } else {
                          if (this.returnUrl) {
                            this.router.navigateByUrl(this.returnUrl);
                          } else {
                            this.router.navigate(['/']);
                          }
                        }
                      },
                      () => {
                        this.loading = false;
                      }
                    );
                },
                () => {
                  this.loading = false;
                }
              );
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loading = false;
          }
        );
    }
  }

  login() {
    if (!this.accessCodeRequested && !this.authForm.controls.password.valid) { return; }

    this.loading = true;
    this.userService.login(this.authForm.value)
      .subscribe(
        () => {
          this.userService.getCurrentUser()
            .subscribe(
              () => {
                if (this.isConfig) {
                  this.getOrganizations();
                } else {
                  if (this.returnUrl) {
                    this.router.navigateByUrl(this.returnUrl);
                  } else {
                    this.router.navigate(['/']);
                  }
                }
              },
              () => {
                this.loading = false;
              }
            );
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.loading = false;
        }
      );
  }

  getCountries() {
    this.countryService.getCountries()
      .subscribe(
      (response: any) => {
          this.allCountries = response.data;
          this.filteredCountries = response.data;
        }
    );
  }

  getPackage(type: string) {
    this.packageService.getPackageByType(type)
      .subscribe(
        (response) => {
          this.package = response.data;
        }
      );
  }

  getOrganizations() {
    this.loading = true;
    this.organizationService.getMyOrganizations()
      .subscribe(
        (response: { data: Organization[], meta: any }) => {
          if (response.data.length === 0) {
            this.router.navigate(['/o', 'setup'], { queryParams: { plan: this.packageType }});
          } else if (response.data.length >= 1) {
            const organization = response.data[0];
            this.router.navigate(['/o', organization.id, 'landing']);
          }

          this.loading = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }

  filterCountries(searchTerm: string) {
    if (searchTerm.length > 2) {
      this.filteredCountries = [];
      for (const country of this.allCountries) {
        if (country.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          this.filteredCountries.push(country);
        }
      }
    } else {
      this.filteredCountries = this.allCountries;
    }
  }

}
