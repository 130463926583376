<div class="nv-row gap-30 propagate" *ngIf="value">
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-input
                    type="number"
                    label="Limit"
                    [(ngModel)]="value.limit"
                    (ngModelChange)="onChange(value)"
            ></nv-input>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-checkbox
                    label="Hot topics"
                    [(ngModel)]="value.hotTopics"
                    (change)="onChange(value)"
            ></nv-checkbox>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-checkbox
                    label="Open in popup"
                    [(ngModel)]="value.openPopup"
                    (change)="onChange(value)"
            ></nv-checkbox>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-checkbox
                    label="Show info"
                    [(ngModel)]="value.showInfo"
                    (change)="onChange(value)"
            ></nv-checkbox>
        </div>
    </div>
</div>
