<div class="nv-radio-item">
    <label>
        <span class="nri-radio-port">
            <input
                    nvInputDirective
                    type="radio"
                    (change)="onChangeStatus($event)"
                    [name]="name"
                    [value]="value"
                    [checked]="checked"
                    [disabled]="disabled"
            >
            <span class="nri-radio-visual"></span>
        </span><!-- /.nri-radio-port -->
        <span class="nri-value">{{ label }}</span>
    </label>
</div><!-- /.nv-radio-item -->
