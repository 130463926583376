export class Conference {
  id: number;
  name: string;
  slug: string;
  type: string;
  logo: string;
  location: string;
  starts_at: string;
  ends_at: string;
  settings: any;
  processing: {
    demo_data_created: boolean;
    program_created: boolean;
  };

  has_tickets: boolean;
}
