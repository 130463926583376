import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class NotificationService {

  constructor(
    private apiService: ApiService
  ) { }

  getConferenceNotifications(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/push_notifications`, params);
  }

  getConferenceNotification(conferenceId: number, notificationId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/push_notifications/${notificationId}`, params);
  }

  updateConferenceNotification(conferenceId: number, notificationId: number, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/push_notifications/${notificationId}`, params);
  }

  createConferenceNotification(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/push_notifications`, params);
  }

  deleteConferenceNotification(conferenceId: number, notificationId: number) {
    return this.apiService.delete(`conferences/${conferenceId}/push_notifications/${notificationId}`);
  }

  readConferenceNotifications(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/push_notifications/read`, params);
  }

}
