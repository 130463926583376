import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from '@navus/core/services/organization.service';
import { PackageService } from '@navus/core/services/package.service';
import { Package } from '@navus/core/classes/package';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Organization } from '@navus/core/classes/organization';
import { CountryService } from '@navus/core/services/country.service';
import { ConferenceService } from '@navus/core/services/conference.service';
import { Country } from '@navus/core/classes/country';
import { WelcomeModalComponent } from '../../modals/welcome/welcome-modal.component';
import { ModalService } from '@navus/ui/modal/modal.service';

@Component({
  selector: 'main [nv-organization-setup-page]',
  templateUrl: './organization-setup-page.component.html'
})
export class OrganizationSetupPageComponent implements OnInit, OnDestroy {
  step: number = 1;
  name: string = '';
  organizationForm: FormGroup;
  conferenceForm: FormGroup;
  packageType: string = '';
  package: Package;
  organizationId: number;
  organization: Organization;
  allCountries: Country[] = [];
  filteredCountries: Country[] = [];
  industries: string[] = [];
  selectedIndustries: string[] = [];

  creatingOrganization: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private packageService: PackageService,
    private organizationService: OrganizationService,
    private conferenceService: ConferenceService,
    private countryService: CountryService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getCountries();

    const querySubscription = this.route.queryParams
      .subscribe((params) => {
        if (params.plan) {
          this.packageType = params.plan;
          this.getPackage(params.plan);
        }
      });
    this.subscriptions.push(querySubscription);

    const paramsSubscription = this.route.params
      .subscribe((params) => {
        if (params.organizationId) {
          this.organizationId = params.organizationId;
          this.getOrganization();
          this.getIndustries();
        }
      });
    this.subscriptions.push(paramsSubscription);

    const dataSubscription = this.route.data
      .subscribe((params) => {
        if (params.step) {
          if (params.step === 'industry') {
            this.step = 2;
          }
          if (params.step === 'event') {
            this.step = 3;
          }
        }
      });
    this.subscriptions.push(dataSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.organizationForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      package_id: [null]
    });
    this.conferenceForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      type: ['Conference'],
      starts_at: [''],
      ends_at: [''],
      country_id: [''],
      website: ['']
    });
  }

  getPackage(type: string) {
    this.packageService.getPackageByType(type)
      .subscribe(
        (response) => {
          this.package = response.data;
        }
      );
  }

  getCountries() {
    this.countryService.getCountries()
      .subscribe(
        (response) => {
          this.allCountries = response.data;
          this.filteredCountries = response.data;
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }

  filterCountries(searchTerm: string) {
    if (searchTerm.length > 2) {
      this.filteredCountries = [];
      for (const country of this.allCountries) {
        if (country.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          this.filteredCountries.push(country);
        }
      }
    } else {
      this.filteredCountries = this.allCountries;
    }
  }

  getOrganization() {
    this.organizationService.getOrganization(this.organizationId)
      .subscribe(
        (response) => {
          this.organization = response.data;
        }
      );
  }

  createOrganization() {
    if (this.package) {
      this.organizationForm.patchValue({ package_id: this.package.id });
    }

    this.creatingOrganization = true;
    this.organizationService.createOrganization(this.organizationForm.value)
      .subscribe(
        (response) => {
          const organization = response.data;
          this.router.navigate(['/o', organization.id, 'setup', 'industry']);
          this.creatingOrganization = false;
        },
        () => {
          this.creatingOrganization = false;
        }
      );
  }

  createConference() {
    if (!this.organization) { return; }

    this.conferenceService.createOrganizationConference(this.organization.id, this.conferenceForm.value)
      .subscribe(
        (response) => {
          const conference = response.data;
          this.router.navigate(['/o', this.organization.id, 'conference', conference.id, 'landing']);

          const modal = this.modalService.open(WelcomeModalComponent);
          modal.componentInstance.organizationId = this.organizationId;
        },
        () => { }
      );
  }

  getIndustries() {
    this.organizationService.getCategories({ with_pagination: 0 })
      .subscribe(
        (response: any) => {
          this.industries = response.data;
        },
        () => { }
      );
  }

  toggleIndustry(industry) {
    const index = this.selectedIndustries.indexOf(industry);
    if (index !== -1) {
      this.selectedIndustries.splice(index, 1);
    } else {
      this.selectedIndustries.push(industry);
    }
  }

  setIndustry() {
    this.organizationService.updateOrganization(this.organizationId, { categories: this.selectedIndustries.join(',') })
      .subscribe(
        (response) => {
          this.router.navigate(['/o', this.organizationId, 'setup', 'event']);
        },
        (error) => { }
      );
  }
}
