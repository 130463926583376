<div class="ca-item" [ngClass]="{ 'active': active, 'done': done }">
    <header>
        <h3>{{ title }}</h3>
        <div class="ca-btns">
            <nv-button *ngIf="(!active && touched) || (!active && edit)"
                       color="passive"

                       size="small"
                       iconPl="&#xe959;"
                       [onlyIcon]="true"
                       (click)="_activate()">
            </nv-button>
            <nv-button *ngIf="active && !edit"
                       size="small"
                       (click)="_next()">Next
            </nv-button>
            <nv-button *ngIf="active && edit"
                       size="small"
                       (click)="_close()">Close
            </nv-button>
        </div><!-- /.ca-btns -->
    </header>
    <section [hidden]="!active">
        <ng-content></ng-content>
    </section>
</div>
