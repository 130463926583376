<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Manage Members</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-8">
                <nv-button color="primary" iconPl="&#xe095;" (click)="invite()">Invite Member</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <form class="config-search-bar-w-filters" [formGroup]="formFilter" autocomplete="off">
        <div class="sbwf-port">
            <input type="text" placeholder="Search..."
                   formControlName="search_term" (change)="getUsers()">
            <span class="sbwf-outline"></span>
        </div><!-- /.sbwf-port -->
    </form><!-- /.config-search-bar-w-filters -->
</div>
<div class="nv-config-content-port">
    <div class="nv-global-loading on" [class.on]="usersLoading || invitationsLoading">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <section class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates">
                <div class="nv-column v-middle desk-12">
                    <div class="nv-column-content">
                        <table class="nv-data-table break-lap">
                            <tr *ngFor="let user of users">
                                <td class="dt-cell">
                                    <label>USER</label>
                                    <strong>{{ user.email }} ({{ user.first_name }} {{ user.last_name }})</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-cell">
                                    <label>ROLE</label>
                                    <strong>{{ user.role }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-cell">
                                    <label>STATUS</label>
                                    <strong class="status processed">Accepted</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-actions">
                                    <div class="nv-cell-actions">
                                    <span class="nv-cell-action-trigger"
                                          [class.on]="optionsShown === user.id"
                                          (click)="openOptions(user)"></span>
                                        <nav nvOffClick (offClick)="closeOptions()">
                                            <ul>
                                                <li class="delete" (click)="deleteUser(user)">
                                                    <a>Delete</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div><!-- /.nv-cell-actions -->
                                </td>
                            </tr>

                            <tr *ngFor="let invitation of invitations">
                                <td class="dt-cell">
                                    <label>USER</label>
                                    <strong>{{ invitation.email }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-cell">
                                    <label>ROLE</label>
                                    <strong>{{ invitation.role }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-cell">
                                    <label>STATUS</label>
                                    <strong class="status unprocessed">{{ invitation.status | titlecase }}</strong>
                                </td><!-- /.dt-cell -->
                                <td class="dt-actions">
                                    <div class="nv-cell-actions">
                                    <span class="nv-cell-action-trigger"
                                          [class.on]="optionsShown === invitation.id"
                                          (click)="openOptions(invitation)"></span>
                                        <nav nvOffClick (offClick)="closeOptions()">
                                            <ul>
                                                <li class="delete" (click)="deleteInvitation(invitation)">
                                                    <a>Cancel invitation</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div><!-- /.nv-cell-actions -->
                                </td>
                            </tr>
                        </table>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->
</div><!-- /.config-layout-lower-fixed -->
