import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { WebsiteService } from '@navus/core/services/website.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { WebsiteControlService } from '../../services/website-control.service';
import { BaseSectionComponent } from '../_base/base-section.component';
import { AuthorListSectionConfig } from './author-list-section-config';

@Component({
  selector: 'nv-author-list-section-configuration',
  templateUrl: './author-list-section-configuration.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AuthorListSectionConfigurationComponent),
      multi: true
    }
  ]
})

export class AuthorListSectionConfigurationComponent extends BaseSectionComponent implements OnInit, ControlValueAccessor {
  @Input() value: AuthorListSectionConfig;
  
  hover: boolean = false;

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService,
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );
  }

  ngOnInit() { }

  writeValue(value: any): void {
    this.value = new AuthorListSectionConfig(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

}
