import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable()
export class NavigationGuard implements CanActivate {
  constructor(
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const fullLocation = window.location.host;
    const parts = fullLocation.split('.');
    const subDomain = parts[0];
    const domain = parts[1];

    if (subDomain === 'config') {
      this.router.navigate(['/o']);
    } else {
      return of(true);
    }
  }
}
