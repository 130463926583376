import { Directive, Input, Optional, OnInit, OnChanges, OnDestroy, ElementRef } from '@angular/core';
import { FormControlName, NgModel } from '@angular/forms';

import { Subscription } from 'rxjs';

@Directive({
    selector: '[appInputFilled]'
})
export class InputFilledDirective implements OnInit, OnDestroy {
    @Input() public appInputFilled: any;
    @Input() public input: any;
    valueSub: Subscription;

    constructor(
        private el: ElementRef,
        @Optional() private formControlName: FormControlName,
        @Optional() private model: NgModel
    ) {

    }

    ngOnInit() {
        if (this.formControlName) {
            this.valueSub = this.formControlName.valueChanges.subscribe(value => {
                this.el.nativeElement.classList.toggle('nv-touched', value);
            });

            if (this.formControlName.value) {
                this.el.nativeElement.classList.toggle('nv-touched');
            }
        }
        if (this.model) {
            this.valueSub = this.model.control.valueChanges.subscribe(value => {
                this.el.nativeElement.classList.toggle('nv-touched', value);
            });

            if (this.model.model) {
                this.el.nativeElement.classList.toggle('nv-touched');
            }
        }
    }

    ngOnDestroy() {
        this.valueSub.unsubscribe();
    }

}
