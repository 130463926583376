<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Attendees</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-10">
                <a class="nv-btn medium secondary" (click)="delete()" [hidden]="!delegateId" translate>Delete</a>
                <nv-button (click)="close()" color="passive" translate>Close</nv-button>
                <nv-button (click)="save()" [loading]="savingDelegate" [disabled]="delegateForm.invalid" translate>Save</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav  vertical-break-tab">
                <ul>
                    <li>
                        <a class="active">Attendees</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'ticket-types']">Ticketing</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'registration-settings']">Registration Settings</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'certificates']">CME Certificates</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
<div class="nv-config-content-port">
    <div class="nv-global-loading on" [class.on]="loadingDelegate">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24 small-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form [formGroup]="delegateForm" class="nv-row config-plates clean gap-24 small-spaced-top spaced-bottom">
                            <div class="nv-column desk-6 lap-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Basic Information</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="code"
                                                        label="Code"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-4 phab-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="title"
                                                        label="Title"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-4 phab-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="first_name"
                                                        label="First Name"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-4 phab-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="last_name"
                                                        label="Last Name"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="email"
                                                        label="Email"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12" formGroupName="contact_info">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="institution"
                                                        label="Company/Institution"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12" formGroupName="contact_info">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="department"
                                                        label="Department"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        formControlName="status"
                                                        label="Status"
                                                        [options]="statusOptions"
                                                ></nv-select>
                                                <ng-container *ngIf="delegateId">
                                                    <small><a (click)="sendRegistrationEmail(delegateId)">Send a registration confirmation email</a></small>
                                                </ng-container>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-6 lap-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Address</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-select formControlName="country_id"
                                                           label="Country"
                                                           [required]="true"
                                                           [options]="filteredCountries"
                                                           [showSearch]="true"
                                                           (searched)="filterCountries($event)"
                                                ></nv-select>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="city"
                                                        label="City"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="address1"
                                                        label="Address1"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="zip"
                                                        label="ZIP"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="website"
                                                        label="Website"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="additional_info"
                                                        label="Additional info"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-select formControlName="conference_ticket_type_id"
                                                           label="Ticket type"
                                                           [options]="allTicketTypes"
                                                ></nv-select>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-12" *ngIf="registrationInfo.length > 0">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-20 spaced-top propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content" (click)="showRegistrationInfo = !showRegistrationInfo">
                                                <h4 class="nv-config-chunk-toggler" translate [class.on]="showRegistrationInfo">Registration Info</h4> <!-- < toggle class 'on' -->
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                    </div>
                                    <div class="nv-row config-plates clean gap-20 propagate" *ngIf="showRegistrationInfo"> <!-- < toggle style 'display' (flex / none) -->
                                        <ng-container *ngFor="let field of registrationInfo">
                                            <div class="nv-column desk-4 tab-6 phab-12">
                                                <div class="nv-column-content">
                                                    <p class="nv-reg-info-item">
                                                        <strong>{{ field.key | titlecase }}:</strong>
                                                        <span>{{ field.value ? field.value : '-' }}</span>
                                                    </p>
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /.nv-column -->
                                        </ng-container>
                                    </div><!-- /.nv-row -->
                                </div>
                            </div>
                            <div class="nv-column desk-12 lap-12" *ngIf="certificate">
                                <div class="nv-column-content">

                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content" (click)="showCertificateSection()">
                                                <h4 class="nv-config-chunk-toggler" translate [class.on]="showCertificate">Certification</h4> <!-- < toggle class 'on' -->
                                            </div>
                                        </div>
                                    </div><!-- /.nv-row -->

                                    <div class="nv-row config-plates clean semi-spaced-bottom" *ngIf="showCertificate">
                                        <div class="nv-profile-settings-box">
                                            <div class="nv-global-loading on" [class.on]="loadingAttendance">
                                                <div class="nv-spinner"></div>
                                            </div><!-- /.nv-global-loading -->
                                            <div class="nv-profile-settings-box-body"
                                                 *ngIf="delegateCertificate && delegateCertificate.active === 1">
                                                <p>Based on their attendance, attendee collected:</p>
                                                <ul class="attendance-numbers">
                                                    <li></li>
                                                    <li>
                                                        <strong>{{ delegateCertificate.cme_points }}</strong><span>Total {{certificate?.unit_name}}</span>
                                                    </li>
                                                    <li></li>
                                                </ul><!-- /.attendance-numbers -->
                                                <p class="nv-settings-request-cf">
                                                    <a class="nv-btn medium primary"(click)="createDelegateCertificate()">
                                                        Recalculate {{certificate?.unit_name}}
                                                    </a>
                                                    &nbsp;
                                                    <a *ngIf="certificate.active===1"
                                                       class="nv-btn medium primary"
                                                       (click)="downloadDelegateCertificate()">
                                                        Download the Certificate of attendance
                                                    </a>
                                                </p>
                                            </div><!-- /.nv-profile-settings-box-body -->
                                            <div class="nv-profile-settings-box-body"
                                                 *ngIf="!(delegateCertificate && delegateCertificate.active === 1)">
                                                <p>{{ certificate?.title }}</p>
                                                <p class="nv-settings-request-cf">
                                                    <a class="nv-btn medium primary" (click)="createDelegateCertificate()">
                                                        Request the Certificate of attendance
                                                    </a>
                                                </p>
                                            </div><!-- /.nv-profile-settings-box-body -->
                                        </div><!-- /.nv-profile-tickets-access-types -->
                                    </div>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-12 lap-12" *ngIf="delegateId">
                                <div class="nv-column-content">
                                    <nv-attendance-page [userId]="delegate?.user_id"></nv-attendance-page>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
