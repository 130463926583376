<div class="nv-config-control-bar">
    <div class="cluf-port">
        <div class="config-title-n-tools">
            <div class="tnt-left">
                <h1 translate>Video Player Presets</h1>
            </div><!-- /.tnt-left -->
            <div class="tnt-right">
                <div class="nv-btn-box flow-end gap-8">
                    <nv-button color="primary" iconPl="&#xe095;" (click)="new()">Add Video Player Preset</nv-button>
                </div><!-- /.nv-btn-box flow-end gap-6 -->
            </div><!-- /.tnt-right -->
        </div><!-- /.config-title-n-tools -->
        <div class="nv-port spaced-bottom">
            <div class="nv-line-tabs guttered">
                <nav class="nv-tabs-nav vertical-break-tab">
                    <ul>
                        <li>
                            <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'ematerials', 'materials']">Materials</a>
                        </li>
                        <li>
                            <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'ematerials', 'consent-settings']">Consent
                                Settings</a>
                        </li>
                        <li>
                            <a class="active">Video Player Presets</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>
<div class="nv-config-content-port">
    <div class="nv-global-loading on" [class.on]="videoPlayerPresetsLoading">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <section class="nv-section">
        <div class="nv-port">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <table class="nv-data-table" *ngIf="videoPlayerPresets?.length; else noData">
                        <tbody>
                        <tr *ngFor="let preset of videoPlayerPresets">
                            <td class="dt-cell nv-clickable"
                                (click)="edit(preset)">
                                <label>Name</label>
                                <strong>{{ preset.name }}</strong>
                            </td>
                            <td class="dt-cell nv-clickable"
                                (click)="edit(preset)">
                                <label>Default</label>
                                <strong>{{ preset.default? 'Yes' : 'No' }}</strong>
                            </td>
                            <td class="dt-cell nv-clickable"
                                (click)="edit(preset)">
                                <label>Autoplay</label>
                                <strong>{{ preset.settings?.autoplay === 1? 'Yes' : 'No' }}</strong>
                            </td>
                            <td class="dt-cell nv-clickable"
                                (click)="edit(preset)">
                                <label>Mute</label>
                                <strong>{{ preset.settings?.mute === 1? 'Yes' : 'No' }}</strong>
                            </td>
                            <td class="dt-cell nv-clickable"
                                (click)="edit(preset)">
                                <label>Loop</label>
                                <strong>{{ preset.settings?.loop === 1? 'Yes' : 'No' }}</strong>
                            </td>
                            <td class="dt-actions">
                                <div class="nv-cell-actions">
                                        <span class="nv-cell-action-trigger"
                                              [class.on]="optionsShown === preset.id"
                                              (click)="openOptions(preset)"></span>
                                    <nav nvOffClick (offClick)="closeOptions()">
                                        <ul>
                                            <li (click)="edit(preset)">
                                                <a>Edit</a>
                                            </li>
                                            <li class="delete" (click)="delete(preset)">
                                                <a>Delete</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div><!-- /.nv-cell-actions -->
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <ng-template #noData>
                        <div>No data.</div>
                    </ng-template>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->
</div><!-- /.config-content-port -->

