<div class="nv-modal medium">
    <div class="nv-modal-header">
        <h4 class="nv-modal-title">Material</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body">
        <div class="nv-global-loading on" [class.on]="loadingMaterial">
            <div class="nv-spinner"></div>
        </div><!-- /.nv-global-loading -->
        <div class="nv-row gap-20 propagate">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <form [formGroup]="materialForm">
                        <nv-input
                                label="Title"
                                formControlName="title"
                        ></nv-input>
                    </form>
                </div>
            </div>
            <ng-container *ngIf="material.type === 'webcast'">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <nv-select
                                [options]="videoPlayerPresetsOptions"
                                [(ngModel)]="selectedVideoPresetId"
                                [label]="'Default player preset'">
                        </nv-select>
                    </div>
                </div>
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <nv-button [loading]="videoPlayerPresetsLoading" (click)="setDefaultPresetForMaterial()">Set default player preset</nv-button>
                    </div>
                </div>
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'ematerials', 'video-player-presets']" (click)="close()">
                            <span>Manage player presets</span>
                        </a>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="nv-row">
            <div class="nv-column desk-12" *ngIf="material">
                <div class="nv-column-content">
                    <ng-container *ngIf="material.type === 'webcast'">
                        <nv-video [src]="material.path"></nv-video>
                    </ng-container>

                    <ng-container *ngIf="material.type === 'main' && materialsImages?.length">
                        <div class="nv-material-view" style="flex: 4">
                            <figure class="nv-material-preview">
                                <img *ngIf="currentPage && materialsImages?.length"
                                     [ngStyle]="{'height': scale * 100 + '%', 'maxWidth': '70%'}"
                                     [src]="materialsImages[currentPage-1].src">
                            </figure><!-- /nv-material-preview -->
                        </div>

                        <div class="nv-material-view" *ngIf="materialsImages?.length" style="flex: 1">
                            <div class="nv-material-detail-submenu">
                                <nav *ngIf="materialsImages?.length">
                                    <ul class="nmv-pagination">
                                        <li class="mds-prev" *ngIf="currentPage >= 2">
                                            <a (click)="setPage(currentPage-1)" title="Previous slide"></a>
                                        </li>
                                        <li class="mds-page">
                                            <input type="number" min=1 max="{{ materialsImages?.length }}"
                                                   [ngModel]="currentPage"
                                                   (ngModelChange)="setPage($event)">
                                            <span> / &nbsp;&nbsp;{{materialsImages?.length}}</span>
                                        </li>
                                        <li class="mds-next"
                                            *ngIf="currentPage < materialsImages.length">
                                            <a (click)="setPage(currentPage+1)" title="Next slide"></a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="nv-material-preview">
                                <ng-container *ngFor="let image of materialsImages; let i = index">
                                    <img [ngStyle]="{'height': '80px', 'maxWidth': '120px', margin: '10px'}"
                                         [src]="image.src"
                                         (click)="setPage(i+1)">
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-modal-footer">
        <div class="nv-row">
            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <div class="nv-btn-box gap-8">
                        <nv-button
                                size="small"
                                [color]="embedCopied ? 'passive' : 'primary'"
                                [disabled]="embedCopied"
                                (click)="copyEmbed()"
                        >{{ embedCopied ? 'Embed code copied' : 'Copy embed code' }}</nv-button>
                        <nv-button *ngIf="material?.type !== 'main'"
                                   size="small"
                                   [color]="pathCopied ? 'passive' : 'primary'"
                                   [disabled]="pathCopied"
                                   (click)="copyPath()"
                        >{{ pathCopied ? 'Path copied' : 'Copy path' }}</nv-button>
                    </div>
                </div>
            </div>
            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <div class="nv-btn-box flow-end">
                        <nv-button size="small" (click)="save()">Save</nv-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

