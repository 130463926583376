<section class="nv-section nv-promo-section guttered items-5 gradient accent1" [class.edit-mode]="mouseOver">
    <ng-container *ngIf="mouseOver">
        <a class="nv-section-add-top" title="Add section" (click)="createBefore()"></a>
        <a class="nv-section-add-bottom" title="Add section" (click)="createAfter()"></a>
        <div class="nv-section-edit-tool-box">
            <a class="edit" title="Edit section" (click)="edit()"></a>
            <a class="delete" title="Delete section" (click)="delete()"></a>
        </div><!-- /.nv-section-edit-tool-box -->
        <div class="nv-section-sort-tool-box">
            <a class="move-up" title="Move up" (click)="moveUp()"></a>
            <a class="move-down" title="Move down" (click)="moveDown()"></a>
        </div><!-- /.nv-section-sort-tool-box -->
    </ng-container>

    <div class="nv-port" style="position: relative">
        <div class="nv-row gap-24 v-stretch large-spaced-top spaced-bottom propagate flow-center">
            <div class="nv-column" *ngIf="configuration.showProgram">
                <div class="nv-column-content">
                    <div class="nv-promo-box">
                        <img style="position: absolute; right: 10px; top: 10px; cursor: pointer" *ngIf="configuration.showInfo"
                             src="../../../../assets/info.svg" (click)="showProgramPopup = true">
                        <div class="pb-icon" data-icon-pl="&#xe960;"></div><!-- /.pb-icon -->
                        <h2>Program</h2>
                        <p>View the event program and access speakers’ presentations from it.</p>
                        <div class="foo-btn">
                            <a [routerLink]="['/program']" class="nv-btn accent1 medium radius-high">View</a>
                        </div><!-- /.foo-btn -->
                    </div><!-- /.nv-promo-box -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column" *ngIf="configuration.showMobileApp">
                <div class="nv-column-content">
                    <div class="nv-promo-box">
                        <img style="position: absolute; right: 10px; top: 10px; cursor: pointer" *ngIf="configuration.showInfo"
                             src="../../../../assets/info.svg" (click)="showMobileAppPopup = true">
                        <div class="pb-icon" data-icon-pl="&#xe963;"></div><!-- /.pb-icon -->
                        <h2>Mobile App</h2>
                        <p>Download the event app and keep all the information and content of the meeting at your fingertips!</p>
                        <div class="foo-btn">
                            <a (click)="showMobileAppDownload = true" class="nv-btn accent1 medium radius-high">Download</a>
                        </div><!-- /.foo-btn -->
                    </div><!-- /.nv-promo-box -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column" *ngIf="configuration.showEmaterials">
                <div class="nv-column-content">
                    <div class="nv-promo-box">
                        <img style="position: absolute; right: 10px; top: 10px; cursor: pointer" *ngIf="configuration.showInfo"
                             src="../../../../assets/info.svg" (click)="showEmaterialsPopup = true">
                        <div class="pb-icon" data-icon-pl="&#xe961;"></div><!-- /.pb-icon -->
                        <h2>E-materials</h2>
                        <p>Access all published materials and get the best learning experience.</p>
                        <div class="foo-btn">
                            <a [routerLink]="['/e-materials']" class="nv-btn accent1 medium radius-high">Access presentations</a>
                        </div><!-- /.foo-btn -->
                    </div><!-- /.nv-promo-box -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column" *ngIf="configuration.showRegistration">
                <div class="nv-column-content">
                    <div class="nv-promo-box">
                        <img style="position: absolute; right: 10px; top: 10px; cursor: pointer" *ngIf="configuration.showInfo"
                             src="../../../../assets/info.svg" (click)="showRegistrationPopup = true">
                        <div class="pb-icon" data-icon-pl="&#xe965;"></div><!-- /.pb-icon -->
                        <h2>Event Registration</h2>
                        <p>Become an attendee of this event.</p>
                        <div class="foo-btn">
                            <a [routerLink]="['/delegate-registration']" class="nv-btn accent1 medium radius-high">Register</a>
                        </div><!-- /.foo-btn -->
                    </div><!-- /.nv-promo-box -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column" *ngIf="configuration.showEplaylist">
                <div class="nv-column-content">
                    <div class="nv-promo-box">
                        <img style="position: absolute; right: 10px; top: 10px; cursor: pointer" *ngIf="configuration.showInfo"
                             src="../../../../assets/info.svg" (click)="showEplaylistPopup = true">
                        <div class="pb-icon" data-icon-pl="&#xe95a;"></div><!-- /.pb-icon -->
                        <h2>E-playlist</h2>
                        <p>Create your own summary presentations, add notes and present them to your colleagues after the event.</p>
                        <div class="foo-btn">
                            <a [routerLink]="['/e-playlist']" class="nv-btn accent1 medium radius-high">Build now</a>
                        </div><!-- /.foo-btn -->
                    </div><!-- /.nv-promo-box -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->

            <div class="nv-column" *ngIf="configuration.showLivestream">
                <div class="nv-column-content">
                    <div class="nv-promo-box">
                        <img style="position: absolute; right: 10px; top: 10px; cursor: pointer" *ngIf="configuration.showInfo"
                             src="../../../../assets/info.svg" (click)="showLiveStreamPopup = true">
                        <div class="pb-icon" data-icon-pl="&#xe962;"></div><!-- /.pb-icon -->
                        <h2>Live Stream</h2>
                        <p>Watch the presentations in real-time and don't let the distance discourage you.</p>
                        <div class="foo-btn">
                            <a [routerLink]="['/live-streaming']" class="nv-btn accent1 medium radius-high">Watch!</a>
                        </div><!-- /.foo-btn -->
                    </div><!-- /.nv-promo-box -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</section><!-- /.nv-section -->

<div class="nv-info-modal light narrow" *ngIf="showMobileAppDownload">
    <div class="im-pane">
        <header class="im-header">
            <span class="im-close" (click)="showMobileAppDownload = false"></span>
            <h3>Download The App</h3>
        </header><!-- /.im-header -->
        <div class="im-body">
            <div class="im-content">
                <!-- Modal Content -->
                <div class="awd-badges-n-qr">
                    <div class="abnq-badges" *ngIf="iosLink && androidLink">
                        <a title="Navus on AppStore" href="{{iosLink}}" target="_blank">
                            <img alt="Navus on AppStore" src="../../../../assets/badge-app-store-us-black.png">
                        </a>
                        <a title="Navus on GooglePlay" href="{{androidLink}}" target="_blank">
                            <img alt="Navus on GooglePlay" src="../../../../assets/badge-google-play-us.png">
                        </a>
                    </div><!-- /.abnq-badges -->
                    <div class="abnq-badges" *ngIf="!iosLink || !androidLink">
                        <a title="Navus on AppStore" href="https://apps.apple.com/hr/app/navus/id1496648713" target="_blank">
                            <img alt="Navus on AppStore" src="../../../../assets/badge-app-store-us-black.png">
                        </a>
                        <a title="Navus on GooglePlay" href="https://play.google.com/store/apps/details?id=io.navus.navus" target="_blank">
                            <img alt="Navus on GooglePlay" src="../../../../assets/badge-google-play-us.png">
                        </a>
                    </div><!-- /.abnq-badges -->
                    <span>or</span>
                    <div class="abnq-qr">
                        <nv-qrcode [data]="'https://service.e-materials.com/api/organizations/' + organizationId + '/applications/' + app?.id + '/store'"></nv-qrcode>
                    </div><!-- /.abnq-qr -->
                </div><!-- /.awd-badges-n-qr -->

                <div class="nv-video-port bordered radius">
                    <img src="../../assets/aspect-16-9.png" alt="" class="nv-aspect" aria-hidden="true">
                    <video width="320" height="240" controls>
                        <source *ngIf="!iosLink || !androidLink" src="/assets/Navus_App_video.mp4" type="video/mp4">
                        <source *ngIf="iosLink || androidLink" src="/assets/Stand-alone_App_video.mp4" type="video/mp4">
                        <!--<source src="movie.ogg" type="video/ogg">-->
                        Your browser does not support the video tag.
                    </video>
                </div><!-- /.nv-ls-video-port -->
                <!-- /Modal Content -->
            </div><!-- /.im-content -->
        </div><!-- /.im-body -->
    </div><!-- /.im-pane -->
</div><!-- /.nv-info-modal -->

<div class="nv-info-modal" *ngIf="showProgramPopup">
    <div class="im-pane">
        <header class="im-header">
            <span class="im-close" (click)="showProgramPopup = false"></span>
            <h3>Program</h3>
        </header><!-- /.im-header -->
        <div class="im-body" style="max-height: 400px">
            <div class="im-content">
                <p>Participants can manage their schedule and browse through the event program. It's fully synchronized with the mobile app. The program also provides a path to access materials for each presentation.</p>
            </div><!-- /.im-content -->
        </div><!-- /.im-body -->
    </div><!-- /.im-pane -->
</div><!-- /.nv-info-modal -->

<div class="nv-info-modal" *ngIf="showMobileAppPopup">
    <div class="im-pane">
        <header class="im-header">
            <span class="im-close" (click)="showMobileAppPopup = false"></span>
            <h3>Mobile App</h3>
        </header><!-- /.im-header -->
        <div class="im-body" style="max-height: 400px">
            <div class="im-content">
                <strong>App Features</strong>
                <br>
                <br>
                <ul>
                    <li>Interactive Program</li>
                    <li>Speaker</li>
                    <li>Attendees</li>
                    <li>E-materials</li>
                    <li>Unlimited Voting</li>
                    <li>My Timeline</li>
                    <li>Sponsors and Exhibitors</li>
                    <li>Advertising spaces</li>

                    <li>Notes</li>
                    <li>News Section</li>
                    <li>Twitter Feed</li>
                    <li>Unlimited Push Notifications</li>
                    <li>Info Pages</li>
                    <li>AI recommendations</li>
                    <li>Access right/log in</li>
                </ul>
                <p>
                    <strong>Voting during a session.</strong> Questions will be displayed in the lecture rooms during the sessions and attendees vote through the App.<br>
                    Enable interaction and engagement among your audience before, during and after your events.
                </p>
                <p>
                    <strong>Take notes for each slide and tag slides.</strong> Use notes and tagged slides in the Summary Builder tool
                </p>
                <p>
                    <strong>Pop up window with Sponsor messages </strong>
                </p>
            </div><!-- /.im-content -->
        </div><!-- /.im-body -->
    </div><!-- /.im-pane -->
</div><!-- /.nv-info-modal -->

<div class="nv-info-modal" *ngIf="showEmaterialsPopup">
    <div class="im-pane">
        <header class="im-header">
            <span class="im-close" (click)="showEmaterialsPopup = false"></span>
            <h3>E-materials</h3>
        </header><!-- /.im-header -->
        <div class="im-body" style="max-height: 400px">
            <div class="im-content">
                <ul>
                    <li>Collect and group all content from all your events under one roof.</li>
                    <li>Publish all your materials in one place – presentations, e-learning modules, e-posters, abstracts, summary articles, publications, news, etc.</li>
                    <li>Provide Instant and automated e-education 365 days a year through the main Hub.</li>
                    <li>Smart Search: AI search function with keywords.</li>
                    <li>Smart Filters.</li>
                    <li>Personal recommendations for users based on more than 10 algorithms.</li>
                </ul>
                <strong>Direct Access to the E-materials portal via:</strong>
                <ul>
                    <li>Congress Website</li>
                    <li>Congress App</li>
                    <li>Program Planner</li>
                    <li>Live-Streaming program</li>
                </ul>
            </div><!-- /.im-content -->
        </div><!-- /.im-body -->
    </div><!-- /.im-pane -->
</div><!-- /.nv-info-modal -->

<div class="nv-info-modal" *ngIf="showEplaylistPopup">
    <div class="im-pane">
        <header class="im-header">
            <span class="im-close" (click)="showEplaylistPopup = false"></span>
            <h3>E-Playlist</h3>
        </header><!-- /.im-header -->
        <div class="im-body" style="max-height: 400px">
            <div class="im-content">
                <ul>
                    <li>Summary Builder. Delegates and users can select single slides, add their notes and export their individual
                        summary document (in slide format). This will support peer to peer education when delegates get back to
                        their workplace after the congress.</li>
                </ul>
            </div><!-- /.im-content -->
        </div><!-- /.im-body -->
    </div><!-- /.im-pane -->
</div><!-- /.nv-info-modal -->

<div class="nv-info-modal" *ngIf="showRegistrationPopup">
    <div class="im-pane">
        <header class="im-header">
            <span class="im-close" (click)="showRegistrationPopup = false"></span>
            <h3>Registration</h3>
        </header><!-- /.im-header -->
        <div class="im-body" style="max-height: 400px">
            <div class="im-content">

            </div><!-- /.im-content -->
        </div><!-- /.im-body -->
    </div><!-- /.im-pane -->
</div><!-- /.nv-info-modal -->

<div class="nv-info-modal" *ngIf="showLiveStreamPopup">
    <div class="im-pane">
        <header class="im-header">
            <span class="im-close" (click)="showLiveStreamPopup = false"></span>
            <h3>Livestreaming</h3>
        </header><!-- /.im-header -->
        <div class="im-body" style="max-height: 400px">
            <div class="im-content">
                <p>Live stream your events and reach and connect with audiences worldwide. Allow your delegates to watch the presentations in real-time.</p>
                <p>Live streaming page is connected to the event program: many sessions can be live streamed at the same time. Delegates can select the session they prefer to watch directly from the program.</p>
            </div><!-- /.im-content -->
        </div><!-- /.im-body -->
    </div><!-- /.im-pane -->
</div><!-- /.nv-info-modal -->

