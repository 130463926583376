<section class="nv-section nv-banner-section guttered" [class.edit-mode]="mouseOver">
    <ng-container *ngIf="mouseOver">
        <a class="nv-section-add-top" title="Add section" (click)="createBefore()"></a>
        <a class="nv-section-add-bottom" title="Add section" (click)="createAfter()"></a>
        <div class="nv-section-edit-tool-box">
            <a class="edit" title="Edit section" (click)="edit()"></a>
            <a class="delete" title="Delete section" (click)="delete()"></a>
        </div><!-- /.nv-section-edit-tool-box -->
        <div class="nv-section-sort-tool-box">
            <a class="move-up" title="Move up" (click)="moveUp()"></a>
            <a class="move-down" title="Move down" (click)="moveDown()"></a>
        </div><!-- /.nv-section-sort-tool-box -->
    </ng-container>

    <div class="nv-port" style="position: relative">
        <img style="position: absolute; right: 20px; top: 20px; cursor: pointer" *ngIf="configuration.showInfo"
             src="../../../../assets/info.svg" (click)="showPopup = true">

        <div class="nv-row spaced flow-center" *ngIf="banner">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <div class="nv-banner-block" *ngIf="banner?.resource_url && banner?.external_url">
                        <a (click)="bannerClick()" target="_blank">
                            <img [src]="banner?.resource_url" alt>
                        </a>
                    </div>
                    <div class="nv-banner-block" *ngIf="banner?.resource_url && !banner?.external_url">
                        <img [src]="banner?.resource_url" alt>
                    </div>
                </div><!-- /.nv-column-content -->
            </div><!-- /nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</section><!-- /.nv-section -->


<div class="nv-info-modal light" *ngIf="showPopup">
    <div class="im-pane" style="min-width: 50vw;">
        <header class="im-header">
            <h3>Show sponsor messages on more than 20 digital interfaces</h3>
            <span class="im-close" (click)="showPopup = false"></span>
        </header><!-- /.im-header -->
        <div class="im-body" style="padding: 20px;">
            <div class="im-content">
                <div style="max-width: 500px; margin: auto;">
                    <nv-slides class="ad-landing-type">
                        <nv-slide style="text-align: center">
                            <img [src]="'assets/banner-positions/desktop-homepage-banner' +  (hover ? 1 : '') + '.jpg'"
                                 (mouseenter)="hover = true"
                                 (mouseleave)="hover = false"
                                 style="margin-bottom: -80px;" alt>
                            <h4>DESKTOP - HOMEPAGE BANNER</h4>
                            <p>
                                <span>Dimensions: </span><strong>1200x148px</strong><br>
                                <span>Accepted Ad Formats: </span><strong>GIF, JPEG, animated GIF, PNG</strong>
                            </p>
                        </nv-slide>
                        <nv-slide style="text-align: center">
                            <img [src]="'assets/banner-positions/desktop-hub-banner' +  (hover ? 1 : '') + '.jpg'"
                                 (mouseenter)="hover = true"
                                 (mouseleave)="hover = false"
                                 style="margin-bottom: -100px;" alt>
                            <h4>DESKTOP - HUB BANNER</h4>
                            <p>
                                <span>Dimensions: </span><strong>1200x148px (8:1 Aspect Ratio)</strong><br>
                                <span>Accepted Ad Formats: </span><strong>GIF, JPEG, animated GIF, PNG</strong>
                            </p>
                        </nv-slide>
                        <nv-slide style="text-align: center">
                            <img [src]="'assets/banner-positions/desktop-search-banner' +  (hover ? 1 : '') + '.jpg'"
                                 (mouseenter)="hover = true"
                                 (mouseleave)="hover = false"
                                 style="margin-bottom: -100px;" alt>
                            <h4>DESKTOP - SEARCH BANNER</h4>
                            <p>
                                <span>Dimensions: </span><strong>1200x148px (8:1 Aspect Ratio)</strong><br>
                                <span>Accepted Ad Formats: </span><strong>GIF, JPEG, animated GIF, PNG</strong>
                            </p>
                        </nv-slide>
                        <nv-slide style="text-align: center">
                            <img [src]="'assets/banner-positions/app-home-screen-banner' +  (hover ? 1 : '') + '.jpg'"
                                 (mouseenter)="hover = true"
                                 (mouseleave)="hover = false"
                                 style="margin-bottom: -80px;" alt>
                            <h4>APP - NAVUS HOME SCREEN BANNER</h4>
                            <p>
                                <span>Dimensions: </span><strong>1600x650px</strong><br>
                                <span>Accepted Ad Formats: </span><strong>GIF, JPEG, animated GIF, PNG</strong>
                            </p>
                        </nv-slide>
                        <nv-slide style="text-align: center">
                            <img [src]="'assets/banner-positions/app-inner-screen-banner' +  (hover ? 1 : '') + '.jpg'"
                                 (mouseenter)="hover = true"
                                 (mouseleave)="hover = false"
                                 style="margin-bottom: -50px;" alt>
                            <h4>APP - INNER SCREENS BANNER</h4>
                            <p>
                                <span>Dimensions: </span><strong>1600x266px</strong><br>
                                <span>Accepted Ad Formats: </span><strong>GIF, JPEG, animated GIF, PNG</strong>
                            </p>
                        </nv-slide>
                        <nv-slide style="text-align: center">
                            <img [src]="'assets/banner-positions/app-event-home-screen-banner' +  (hover ? 1 : '') + '.jpg'"
                                 (mouseenter)="hover = true"
                                 (mouseleave)="hover = false"
                                 style="margin-bottom: -30px;" alt>
                            <h4>APP - EVENT HOME SCREEN BANNER</h4>
                            <p>
                                <span>Dimensions: </span><strong>1600x650px</strong><br>
                                <span>Accepted Ad Formats: </span><strong>GIF, JPEG, animated GIF, PNG</strong>
                            </p>
                        </nv-slide>
                    </nv-slides>
                </div>
                <p>&nbsp;</p>
                <ul>
                    <li>Generate up to 100 impressions per attendee.</li>
                    <li>Sell digital sponsorship spaces and make a profit</li>
                    <li>Set a Share of Voice and rotate messages</li>
                </ul>
            </div><!-- /.im-content -->
        </div><!-- /.im-body -->
    </div><!-- /.im-pane -->
</div><!-- /.nv-info-modal -->
