import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MeetingRoomService } from '@navus/core/services/meeting-room.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'nv-meeting-room-details-modal',
  templateUrl: 'meeting-room-details.component.html'
})

export class NavusMeetingRoomDetailsModalComponent implements OnInit {
  @Input() conferenceId: number;
  @Input() entityType: string;
  @Input() entityId: number;
  typeOptions = [
    { id: 'internal', name: 'Internal' },
    { id: 'external', name: 'External' },
  ];
  statusOptions = [
    { id: 'online', name: 'online' },
    { id: 'offline', name: 'offline' },
    { id: 'idle', name: 'idle' },
  ];

  meetingRoomForm: FormGroup;

  savingMeetingRoom: boolean = false;
  
  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private meetingRoomService: MeetingRoomService,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.meetingRoomForm = this.formBuilder.group({
      title: [''],
      room_name: [''],
      type: ['internal'],
      max_participants: [10],
      rtmp_url: [''],
      rtmp_key: [''],
      status: ['online'],
    });
    this.meetingRoomForm.get('title').valueChanges
      .subscribe(
        (value) => {
          this.meetingRoomForm.get('room_name')
            .setValue(value);
        }
      )
  }

  save() {
    this.savingMeetingRoom = true;
    const params = {
      entity_type: this.entityType,
      entity_id: this.entityId,
      ...this.meetingRoomForm.value
    }
    this.meetingRoomService
      .createConferenceMeetingRoom(this.conferenceId, params)
      .subscribe(
        () => {
          this.activeModal.close();
          this.savingMeetingRoom = false;
        },
        () => {
          this.savingMeetingRoom = false;
        }
      )
  }

  close() {
    this.activeModal.dismiss();
  }

}