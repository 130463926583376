import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import { NavusAccordionComponent } from './accordion.component';

@Component({
  selector: 'nv-accordions',
  templateUrl: './accordions.component.html'
})
export class NavusAccordionsComponent implements OnInit, AfterContentInit {
  @ContentChildren(NavusAccordionComponent) accordions: QueryList<NavusAccordionComponent>;
  @Input() disabled: boolean = false;
  @Input() edit: boolean;

  @Output() finish = new EventEmitter();

  ngOnInit() {
    if (this.edit) { this.edit = true; }
    if (this.disabled !== false) { this.disabled = true; }
  }

  ngAfterContentInit() {
    const activeAccordion = this.accordions.find(accordion => accordion.active);
    const editMode = this.accordions.find(accordion => accordion.edit);
    if (!editMode) {
      if (!activeAccordion) {
        this.accordions.first.active = true;
        this.accordions.first.touched = true;
      }
    }

    this.accordions.map(
      (accordion: NavusAccordionComponent) => {
        accordion.activate.subscribe(() => {
          this.accordions.toArray()
            .map(
              (accordionItem) => { accordionItem.active = accordionItem === accordion; }
            );
        });
        accordion.next.subscribe(() => {
          for (let i = 0; i < this.accordions.length; i++) {
            this.accordions.toArray()[i].active = i === this.accordions.toArray().indexOf(accordion) + 1;
          }
        });
      }
    );
  }

  show(accordionSelected, index) {
    this.accordions.toArray().map((accordion) => { accordion.active = false; });
    this.accordions.toArray()[index].active = true;
  }

}
