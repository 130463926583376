import { NgModule } from '@angular/core';
import { NavusCoreModule } from '@navus/core/navus-core.module';
import { NavusUiModule } from '@navus/ui/navus-ui.module';
import { SharedModule } from '../../../shared';
import { AdLandingPageComponent } from './pages/ad-landing/ad-landing-page.component';
import { BannerManagementPageComponent } from './pages/banner-management/banner-management-page.component';
import { IntegrationsPageComponent } from './pages/integrations/integrations-page.component';

@NgModule({
  imports: [
    NavusCoreModule.forRoot(),
    NavusUiModule,
    SharedModule
  ],
  declarations: [
    AdLandingPageComponent,
    BannerManagementPageComponent,
    IntegrationsPageComponent
  ],
  entryComponents: [],
  providers: []
})
export class AdModule {
}
