import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebsiteService } from '../../../navus-core/services/website.service';
import { ConferenceService } from '../../../navus-core/services/conference.service';
import { Website } from '../../../navus-core/classes/website';
import { Conference } from '../../../navus-core/classes/conference';
import { Subscription } from 'rxjs';
import { OrganizationService } from '../../../navus-core/services/organization.service';
import { Organization } from '../../../navus-core/classes/organization';

@Component({
  selector: 'main [nv-organization-dashboard-page]',
  templateUrl: './organization-dashboard-page.component.html'
})
export class OrganizationDashboardPageComponent implements OnInit, OnDestroy {
  organizationId: number;
  organization: Organization;
  websites: Website[] = [];
  conferences: Conference[] = [];
  subscriptions: Subscription[] = [];

  @HostBinding('class.nv-config-content') class = true;

  constructor(
    private route: ActivatedRoute,
    private organizationService: OrganizationService,
    private websiteService: WebsiteService,
    private conferenceService: ConferenceService,
  ) { }

  ngOnInit() {
    const routeSubscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
      }
    );
    this.subscriptions.push(routeSubscription);

    const organizationSubscription = this.organizationService.currentOrganization
      .subscribe(
        (organization: Organization) => {
          this.organization = organization;
          this.getWebsites();
          this.getConferences();
        },
        () => { }
      );
    this.subscriptions.push(organizationSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getWebsites() {
    if (this.organizationId) {
      const params = {
        per_page: 5,
        sort: 'updated_at',
        order: 'desc'
      };

      this.websiteService.getOrganizationWebsites(this.organizationId, params)
        .subscribe(
          (response) => {
            this.websites = response['data'];
          },
          (error) => {
            // TODO Error toast
          }
        );
    }
  }

  getConferences() {
    if (this.organizationId) {
      const params = {
        per_page: 5,
        sort: 'starts_at',
        order: 'desc'
      };

      this.conferenceService.getOrganizationConferences(this.organizationId, params)
        .subscribe(
          (response) => {
            this.conferences = response['data'];
          },
          (error) => {
            // TODO Error toast
          }
        );
    }
  }
}
