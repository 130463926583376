<div class="nv-page-wrap">

    <!-- new SEARCH -->


    <!-- Explain -->

    <!-- WIDGET BOXES -->
    <section class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row large-spaced gap-40">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <div class="nv-widget-box padded">
                            <header class="wb-header">
                                <h2>Popularity-Based Recommendations</h2>
                            </header><!-- /.wb-header -->
                            <div class="wb-body">
                                <div class="logged-bar">
                                    <p><a href="">Log in</a> for personalised recommendations</p>
                                </div><!-- /.logged-bar -->
                                <div class="nv-result-item">
                                    <div class="ri-top">
                                        <h3><a title="Minimal change disease - Focal glomerulosclerosis">Minimal change disease - Focal glomerulosclerosis</a></h3>
                                        <div class="ri-top-data">
                                            <div class="rtd-item">
                                                <a href="" class="nv-bookmark-trigger"></a>
                                            </div><!-- /.rtd-item -->
                                        </div><!-- /.ri-top-data -->
                                    </div><!-- /ri-top -->
                                    <div class="ri-mid">
                                        <div class="rm-left">
                                            <div class="nv-avatar-n-author">
                                                <figure class="ana-img" style="background-image: url('https://loremflickr.com/g/100/100/girl/all');"></figure>
                                                <p>Ewout Hoorn</p>
                                            </div><!-- /.nv-avatar-n-author -->
                                            <div class="nv-materials-available">
                                                <p>Materials:</p>
                                                <a href="" class="ma-item material" title="material available">
                                                    <span>material available</span>
                                                </a>
                                                <a href="" class="ma-item webcast" title="webcast available">
                                                    <span>webcast available</span>
                                                </a>
                                                <a href="" class="ma-item abstract" title="abstract available">
                                                    <span>abstract available</span>
                                                </a>
                                            </div><!-- /.nv-materials-available -->
                                        </div><!-- /.rm-left -->
                                    </div><!-- /.ri-mid -->
                                    <div class="ri-bottom">
                                        <div class="rb-left">
                                            <p class="nv-footnote">Found by: Presetnation Title, Material Content</p>
                                        </div><!-- /.rb-left -->
                                    </div><!-- /.ri-bottom -->
                                </div><!-- /.nv-result-item -->

                                <div class="nv-result-item">
                                    <div class="ri-top">
                                        <h3><a title="Minimal change disease - Focal glomerulosclerosis">Minimal change disease - Focal glomerulosclerosis</a></h3>
                                        <div class="ri-top-data">
                                            <div class="rtd-item">
                                                <a href="" class="nv-bookmark-trigger"></a>
                                            </div><!-- /.rtd-item -->
                                        </div><!-- /.ri-top-data -->
                                    </div><!-- /ri-top -->
                                    <div class="ri-mid">
                                        <div class="rm-left">
                                            <div class="nv-avatar-n-author">
                                                <figure class="ana-img" style="background-image: url('https://loremflickr.com/g/100/100/girl/all');"></figure>
                                                <p>Ewout Hoorn</p>
                                            </div><!-- /.nv-avatar-n-author -->
                                            <div class="nv-materials-available">
                                                <p>Materials:</p>
                                                <a href="" class="ma-item material" title="material available">
                                                    <span>material available</span>
                                                </a>
                                                <a href="" class="ma-item webcast" title="webcast available">
                                                    <span>webcast available</span>
                                                </a>
                                                <a href="" class="ma-item abstract" title="abstract available">
                                                    <span>abstract available</span>
                                                </a>
                                            </div><!-- /.nv-materials-available -->
                                        </div><!-- /.rm-left -->
                                    </div><!-- /.ri-mid -->
                                    <div class="ri-bottom">
                                        <div class="rb-left">
                                            <p class="nv-footnote">Found by: Presetnation Title, Material Content</p>
                                        </div><!-- /.rb-left -->
                                    </div><!-- /.ri-bottom -->
                                </div><!-- /.nv-result-item -->

                                <div class="nv-banner-break">
                                    <a><img src="https://loremflickr.com/g/1000/200/medicine/all" alt="banner"></a>
                                </div><!-- /.nv-banner-break -->

                                <div class="nv-result-item">
                                    <div class="ri-top">
                                        <h3><a title="Minimal change disease - Focal glomerulosclerosis">Minimal change disease - Focal glomerulosclerosis</a></h3>
                                        <div class="ri-top-data">
                                            <div class="rtd-item">
                                                <a href="" class="nv-bookmark-trigger"></a>
                                            </div><!-- /.rtd-item -->
                                        </div><!-- /.ri-top-data -->
                                    </div><!-- /ri-top -->
                                    <div class="ri-mid">
                                        <div class="rm-left">
                                            <div class="nv-avatar-n-author">
                                                <figure class="ana-img" style="background-image: url('https://loremflickr.com/g/100/100/girl/all');"></figure>
                                                <p>Ewout Hoorn</p>
                                            </div><!-- /.nv-avatar-n-author -->
                                            <div class="nv-materials-available">
                                                <p>Materials:</p>
                                                <a href="" class="ma-item material" title="material available">
                                                    <span>material available</span>
                                                </a>
                                                <a href="" class="ma-item webcast" title="webcast available">
                                                    <span>webcast available</span>
                                                </a>
                                                <a href="" class="ma-item abstract" title="abstract available">
                                                    <span>abstract available</span>
                                                </a>
                                            </div><!-- /.nv-materials-available -->
                                        </div><!-- /.rm-left -->
                                    </div><!-- /.ri-mid -->
                                    <div class="ri-bottom">
                                        <div class="rb-left">
                                            <p class="nv-footnote">Found by: Presetnation Title, Material Content</p>
                                        </div><!-- /.rb-left -->
                                    </div><!-- /.ri-bottom -->
                                </div><!-- /.nv-result-item -->

                                <p class="nv-skinless-link center">
                                    <a href="">View all recommendations</a>
                                </p>
                                <!-- /.nv-skinless-link -->
                            </div><!-- /.wb-body -->
                        </div><!-- /.nv-widget-box -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <div class="nv-row gap-40 v-stretch propagate">
                            <div class="nv-column desk-6">
                                <div class="nv-column-content">
                                    <div class="nv-widget-box padded white">
                                        <header class="wb-header">
                                            <h2>Latest</h2>
                                        </header><!-- /.wb-header -->
                                        <div class="wb-body">
                                            <nav class="nv-latest-presentations">
                                                <ul>
                                                    <li>
                                                        <a>Introduction and the case of ANCA - associated vasculitis</a>
                                                        <div class="nv-materials-available">
                                                            <p>Materials:</p>
                                                            <a href="" class="ma-item material" title="material available">
                                                                <span>material available</span>
                                                            </a>
                                                            <a href="" class="ma-item webcast" title="webcast available">
                                                                <span>webcast available</span>
                                                            </a>
                                                        </div><!-- /.nv-materials-available -->
                                                    </li>
                                                    <li>
                                                        <a>The case of lupus nephritis</a>
                                                        <div class="nv-materials-available">
                                                            <p>Materials:</p>
                                                            <a href="" class="ma-item material" title="material available">
                                                                <span>material available</span>
                                                            </a>
                                                            <a href="" class="ma-item webcast" title="webcast available">
                                                                <span>webcast available</span>
                                                            </a>
                                                        </div><!-- /.nv-materials-available -->
                                                    </li>
                                                    <li>
                                                        <a>Cardio- and nephroprotective properties of SGLT-2 inhibitors…</a>
                                                        <div class="nv-materials-available">
                                                            <p>Materials:</p>
                                                            <a href="" class="ma-item material" title="material available">
                                                                <span>material available</span>
                                                            </a>
                                                        </div><!-- /.nv-materials-available -->
                                                    </li>
                                                </ul>
                                            </nav><!-- /.nv-latest-presentations -->
                                            <p class="nv-skinless-link left">
                                                <a href="">View all latest content</a>
                                            </p>
                                        </div><!-- /.wb-body -->
                                    </div><!-- /.nv-widget-box -->
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column desk-6 -->

                            <div class="nv-column desk-6">
                                <div class="nv-column-content">
                                    <div class="nv-widget-box dark padded">
                                        <header class="wb-header">
                                            <h2>Summary Graphs</h2>
                                        </header><!-- /.wb-header -->
                                        <div class="wb-body">
                                            <div class="nv-graph-filter-type">
                                                <app-dropdown-container class="nv-dropdown-type1 plain light drop-right" [placeholder]="'By presentation type'"></app-dropdown-container>
                                            </div><!-- /.nv-graph-filter-type -->
                                            <div class="nv-graph-presentations-number">
                                                <h4>170</h4>
                                                <p>PRESENTATIONS</p>
                                            </div><!-- /.nv-graph-presentations-number -->
                                            <div class="nv-graph-values">
                                                <div class="gv-item">
                                                    <div class="gv-bar-n-score">
                                                        <div class="gv-bar" style="background-color:#629dff;width:100%;">
                                                            <div class="gv-score">90</div>
                                                        </div>
                                                    </div><!-- /.gv-bar-n-score -->
                                                    <div class="gv-label"><span>ORAL</span></div>
                                                </div><!-- /.gv-item -->

                                                <div class="gv-item">
                                                    <div class="gv-bar-n-score">
                                                        <div class="gv-bar" style="background-color:#51aee9;width:50%;">
                                                            <div class="gv-score">45</div>
                                                        </div>
                                                    </div><!-- /.gv-bar-n-score -->
                                                    <div class="gv-label"><span>E-LEARNING</span></div>
                                                </div><!-- /.gv-item -->

                                                <div class="gv-item">
                                                    <div class="gv-bar-n-score">
                                                        <div class="gv-bar" style="background-color:#e8966a;width:22.22%;">
                                                            <div class="gv-score">20</div>
                                                        </div>
                                                    </div><!-- /.gv-bar-n-score -->
                                                    <div class="gv-label"><span>POSTER</span></div>
                                                </div><!-- /.gv-item -->

                                                <div class="gv-item">
                                                    <div class="gv-bar-n-score">
                                                        <div class="gv-bar" style="background-color:#de6c6d;width:16.67%;">
                                                            <div class="gv-score">15</div>
                                                        </div>
                                                    </div><!-- /.gv-bar-n-score -->
                                                    <div class="gv-label"><span>INDUSTRY SYMPOSIUM</span></div>
                                                </div><!-- /.gv-item -->
                                            </div><!-- /.nv-graph-values -->
                                        </div><!-- /.wb-body -->
                                    </div><!-- /.nv-widget-box -->
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column desk-6 -->

                            <div class="nv-column desk-12">
                                <div class="nv-column-content">
                                    <div class="nv-widget-box padded">
                                        <header class="wb-header">
                                            <h2>Search by Event</h2>
                                        </header><!-- /.wb-header -->
                                        <div class="wb-body">
                                            <div class="nv-search-bar-plain">
                                                <input type="text" placeholder="Enter the name of the event">
                                            </div><!-- /.nv-search-bar-plain -->

                                            <div class="nv-events-row">
                                                <div class="er-port">
                                                    <div class="er-item">
                                                        <a href="" title="Era Edta"><span style="background-image:url('../../assets/logo-v-eraedta.png');"></span></a>
                                                        <p>Era Edta</p>
                                                    </div><!-- /.er-item -->
                                                    <div class="er-item">
                                                        <a href="" title="Imedex"><span style="background-image:url('../../assets/little-imedex.png');"></span></a>
                                                        <p>Imedex</p>
                                                    </div><!-- /.er-item -->
                                                    <div class="er-item nv-image-loading">
                                                        <a href="" title="ASN"><span class="nv-image-target nv-image-loading" style="background-image:url('../../assets/logo-event-ctd.png');"></span></a>
                                                        <p>ASN</p>
                                                    </div><!-- /.er-item -->
                                                    <div class="er-item">
                                                        <a href="" title="AIBD"><span style="background-image:url('../../assets/logo-event-aibd.png');"></span></a>
                                                        <p>AIBD</p>
                                                    </div><!-- /.er-item -->
                                                </div><!-- /.er-port -->
                                            </div><!-- /.nv-events-row -->

                                            <div class="nv-events-row">
                                                <div class="er-port">
                                                </div><!-- /.er-port -->
                                            </div><!-- /.nv-events-row -->

                                            <!--<div class="nv-search-bar-plain">-->
                                                <!--<input type="text" [(ngModel)]="searchConf" placeholder="Enter the name of the event">-->
                                            <!--</div>&lt;!&ndash; /.nv-search-bar-plain &ndash;&gt;-->
                                            <!--<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">-->
                                                <!--<div ngxSlickItem *ngFor="let slide of slides | searchFilter : searchConf : 'name'" class="slide er-item">-->
                                                    <!--<a href="" title="AIBD"><span style="background-image:url('../../assets/logo-event-aibd.png');"></span></a>-->
                                                    <!--<p>{{slide.name}}</p>-->
                                                <!--</div>-->
                                            <!--</ngx-slick-carousel>-->
                                        </div><!-- /.wb-body -->
                                    </div><!-- /nv-widget-box padded -->
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column desk-6 -->
                        </div><!-- /.nv-row -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->

    <!-- /Explain -->


    <!-- SEARCH BAR -->
    <section class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row spaced">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form class="nv-search-bar-widget">
                            <div class="sbw-bar">
                                <input type="text" class="sbw-input" placeholder="Search">
                                <span class="sbw-outline"></span>
                                <app-dropdown-container class="nv-dropdown-type1 drop-right" [placeholder]="'In titles, content and speakers'"></app-dropdown-container>
                                <button type="submit" class="sbw-btn"></button>
                                <span class="sbw-clear">clear</span>
                            </div><!-- /.sbw-bar -->
                            
                        </form><!-- /.nv-search-bar-widget -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->

    <!-- SEARCH CONTROLS -->
    <section class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row spaced-top small-spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                       <div class="nv-line-tabs">
                           <nav class="nv-tabs-nav">
                               <ul>
                                   <li><a class="active" href="">Presentations <span>(256)</span></a></li>
                                   <li><a href="">News <span>(132)</span></a></li>
                                   <li><a href="">Dialogues <span>(2)</span></a></li>
                               </ul>
                           </nav><!-- /.nv-tabs-nav -->
                           <div class="nv-thorough-pagination">
                               <!--<div class="tp-go-to-page"> Go to: <input type="text" value="1"> of 25 </div>-->
                               <div class="tp-rows-per-page">
                                   Rows per page:
                                   <app-dropdown-container class="nv-dropdown-type1 drop-right" [placeholder]="'10'"></app-dropdown-container>
                               </div><!-- /.tp-rows-per-page -->
                               <div class="tp-pages">
                                   <a class="first"></a>
                                   <a class="prev"></a>
                                   <span>1-10 of 240</span>
                                   <a class="next"></a>
                                   <a class="last"></a>
                               </div>
                           </div><!-- /nv-thorough-pagination -->
                       </div><!-- /.nv-line-tabs -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->

    <!-- FILTERS ROW -->
    <section class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row small-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-filters-row">
                            <p>Filters:</p>
                            <app-dropdown-container class="drop-left wide-drop filters-checked" [placeholder]="'ERA-EDTA 2018 E-materials + 2'">
                                <form class="nv-filter-pane">
                                    <div class="fp-input">
                                        <input type="text" name="" placeholder="Search sources…">
                                    </div><!-- /.fp-input -->
                                    <div class="fp-body">
                                        <div class="fp-all-sources">
                                            <p>Select all sources</p>
                                            <label class="nv-quasi-toggle-check">
                                                <input type="checkbox" name="">
                                                <span class="qtc-visual"></span>
                                            </label><!-- /.nv-quasi-toggle-check -->
                                        </div><!-- /.fp-all-sources -->
                                        <h4>Annual Congresses</h4>
                                        <ul>
                                            <li>
                                                <label class="nv-regular-checkbox">
                                                    <span class="rcb">
                                                        <input type="checkbox" checked>
                                                        <span class="rc-visual"></span>
                                                    </span><!-- /.rcb -->
                                                    ERA-EDTA 2018 E-materials
                                                </label>
                                            </li>
                                            <li>
                                                <label class="nv-regular-checkbox">
                                                    <span class="rcb">
                                                        <input type="checkbox" checked>
                                                        <span class="rc-visual"></span>
                                                    </span><!-- /.rcb -->
                                                    ERA-EDTA 2017 E-materials
                                                </label>
                                            </li>
                                            <li>
                                                <label class="nv-regular-checkbox">
                                                    <span class="rcb">
                                                        <input type="checkbox" checked>
                                                        <span class="rc-visual"></span>
                                                    </span><!-- /.rcb -->
                                                    ERA-EDTA 2016 E-materials
                                                </label>
                                            </li>
                                        </ul>
                                        <h4>Sources</h4>
                                        <ul>
                                            <li>
                                                <label class="nv-regular-checkbox">
                                                    <span class="rcb">
                                                        <input type="checkbox">
                                                        <span class="rc-visual"></span>
                                                    </span><!-- /.rcb -->
                                                    NDT Educational
                                                </label>
                                            </li>
                                            <li>
                                                <label class="nv-regular-checkbox">
                                                    <span class="rcb">
                                                        <input type="checkbox">
                                                        <span class="rc-visual"></span>
                                                    </span><!-- /.rcb -->
                                                    Clinical Transplantation Day 2018
                                                </label>
                                            </li>
                                            <li>
                                                <label class="nv-regular-checkbox">
                                                    <span class="rcb">
                                                        <input type="checkbox">
                                                        <span class="rc-visual"></span>
                                                    </span><!-- /.rcb -->
                                                    Clinical Transplantation Day 2017
                                                </label>
                                            </li>
                                            <li>
                                                <label class="nv-regular-checkbox">
                                                    <span class="rcb">
                                                        <input type="checkbox">
                                                        <span class="rc-visual"></span>
                                                    </span><!-- /.rcb -->
                                                    Clinical Transplantation Day 2016
                                                </label>
                                            </li>
                                            <li>
                                                <label class="nv-regular-checkbox">
                                                    <span class="rcb">
                                                        <input type="checkbox">
                                                        <span class="rc-visual"></span>
                                                    </span><!-- /.rcb -->
                                                    Diabesity in renal transplantation
                                                </label>
                                            </li>
                                            <li>
                                                <label class="nv-regular-checkbox">
                                                    <span class="rcb">
                                                        <input type="checkbox">
                                                        <span class="rc-visual"></span>
                                                    </span><!-- /.rcb -->
                                                    Compact Renal (Sponsored)
                                                </label>
                                            </li>
                                            <li>
                                                <label class="nv-regular-checkbox">
                                                    <span class="rcb">
                                                        <input type="checkbox">
                                                        <span class="rc-visual"></span>
                                                    </span><!-- /.rcb -->
                                                    Kidney Meets Liver 2018
                                                </label>
                                            </li>
                                        </ul>
                                    </div><!-- /.fp-body -->
                                    <button class="fp-btn">Done</button>
                                </form><!-- /.nv-filter-pane -->
                            </app-dropdown-container>
                            <app-dropdown-container class="nv-dropdown-type1 drop-left wide-drop" [placeholder]="'Any Presentation Type'"></app-dropdown-container>
                            <app-dropdown-container class="nv-dropdown-type1 drop-left wide-drop" [placeholder]="'My Selection'"></app-dropdown-container>
                            <app-dropdown-container class="nv-dropdown-type1 drop-left wide-drop" [placeholder]="'My History'"></app-dropdown-container>
                        </div><!-- /.nv-filters-row -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->

    <div class="logged-bar">
        <p>Get the most out of Hub by <a href="">login in</a> with your account</p>
    </div><!-- /.logged-bar -->

    <!-- Results listing -->
    <main class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row small-spaced-top">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-result-item">
                            <div class="ri-top">
                                <h3><a title="Minimal change disease - Focal glomerulosclerosis">Minimal change disease - Focal glomerulosclerosis</a></h3>
                                <div class="ri-top-data">
                                    <div class="rtd-item">
                                        <p class="nv-posted-stamp">Updated: 25 May 2018</p>
                                    </div><!-- /.rtd-item -->
                                    <div class="rtd-item">
                                        <a href="" class="nv-bookmark-trigger"></a>
                                    </div><!-- /.rtd-item -->
                                </div><!-- /.ri-top-data -->
                            </div><!-- /ri-top -->
                            <div class="ri-mid">
                                <div class="rm-left">
                                    <div class="nv-avatar-n-author">
                                        <figure class="ana-img" style="background-image: url('https://loremflickr.com/g/100/100/girl/all');"></figure>
                                        <p>Ewout Hoorn</p>
                                    </div><!-- /.nv-avatar-n-author -->
                                    <div class="nv-materials-available">
                                        <p>Materials:</p>
                                        <a href="" class="ma-item material" title="material available">
                                            <span>material available</span>
                                        </a>
                                        <a href="" class="ma-item webcast" title="webcast available">
                                            <span>webcast available</span>
                                        </a>
                                        <a href="" class="ma-item abstract" title="abstract available">
                                            <span>abstract available</span>
                                        </a>
                                    </div><!-- /.nv-materials-available -->
                                </div><!-- /.rm-left -->
                                <div class="rm-right">
                                    <a href="#" class="nv-btn filled extra-small purchase radius-4" data-icon-pl="&#xe04e;">Purchase this item for &euro; 5.-</a>
                                </div><!-- /.rm-right -->
                            </div><!-- /.ri-mid -->
                            <div class="ri-bottom">
                                <div class="rb-left">
                                    <p class="nv-footnote">Found by: Presetnation Title, Material Content</p>
                                </div><!-- /.rb-left -->
                                <div class="rb-right">
                                    <p class="nv-bcm-memeber">or <a href="">become an ERA-EDTA Member</a></p>
                                </div><!-- /.rb-right -->
                            </div><!-- /.ri-bottom -->
                        </div><!-- /.nv-result-item -->

                        <div class="nv-result-item">
                            <div class="ri-top">
                                <h3><a title="Minimal change disease - Focal glomerulosclerosis">Minimal change disease - Focal glomerulosclerosis</a></h3>
                                <div class="ri-top-data">
                                    <div class="rtd-item">
                                        <p class="nv-posted-stamp">Updated: 25 May 2018</p>
                                    </div><!-- /.rtd-item -->
                                    <div class="rtd-item">
                                        <a href="" class="nv-bookmark-trigger"></a>
                                    </div><!-- /.rtd-item -->
                                </div><!-- /.ri-top-data -->
                            </div><!-- /ri-top -->
                            <div class="ri-mid">
                                <div class="rm-left">
                                    <div class="nv-avatar-n-author">
                                        <figure class="ana-img" style="background-image: url('https://loremflickr.com/g/100/100/girl/all');"></figure>
                                        <p>Ewout Hoorn</p>
                                    </div><!-- /.nv-avatar-n-author -->
                                    <div class="nv-materials-available">
                                        <p>Materials:</p>
                                        <a href="" class="ma-item material" title="material available">
                                            <span>material available</span>
                                        </a>
                                        <a href="" class="ma-item webcast" title="webcast available">
                                            <span>webcast available</span>
                                        </a>
                                        <a href="" class="ma-item abstract" title="abstract available">
                                            <span>abstract available</span>
                                        </a>
                                    </div><!-- /.nv-materials-available -->
                                </div><!-- /.rm-left -->
                                <div class="rm-right">
                                    <a href="#" class="nv-btn filled extra-small purchase radius-4" data-icon-pl="&#xe04e;">Purchase this item for &euro; 5.-</a>
                                </div><!-- /.rm-right -->
                            </div><!-- /.ri-mid -->
                            <div class="ri-bottom">
                                <div class="rb-left">
                                    <p class="nv-footnote">Found by: Presetnation Title, Material Content</p>
                                </div><!-- /.rb-left -->
                                <div class="rb-right">
                                    <p class="nv-bcm-memeber">or <a href="">become an ERA-EDTA Member</a></p>
                                </div><!-- /.rb-right -->
                            </div><!-- /.ri-bottom -->
                        </div><!-- /.nv-result-item -->

                        <div class="nv-result-item">
                            <div class="ri-top">
                                <h3><a title="Minimal change disease - Focal glomerulosclerosis">Minimal change disease - Focal glomerulosclerosis</a></h3>
                                <div class="ri-top-data">
                                    <div class="rtd-item">
                                        <p class="nv-posted-stamp">Updated: 25 May 2018</p>
                                    </div><!-- /.rtd-item -->
                                    <div class="rtd-item">
                                        <a href="" class="nv-bookmark-trigger"></a>
                                    </div><!-- /.rtd-item -->
                                </div><!-- /.ri-top-data -->
                            </div><!-- /ri-top -->
                            <div class="ri-mid">
                                <div class="rm-left">
                                    <div class="nv-avatar-n-author">
                                        <figure class="ana-img" style="background-image: url('https://loremflickr.com/g/100/100/girl/all');"></figure>
                                        <p>Ewout Hoorn</p>
                                    </div><!-- /.nv-avatar-n-author -->
                                    <div class="nv-materials-available">
                                        <p>Materials:</p>
                                        <a href="" class="ma-item material" title="material available">
                                            <span>material available</span>
                                        </a>
                                        <a href="" class="ma-item webcast" title="webcast available">
                                            <span>webcast available</span>
                                        </a>
                                        <a href="" class="ma-item abstract" title="abstract available">
                                            <span>abstract available</span>
                                        </a>
                                    </div><!-- /.nv-materials-available -->
                                </div><!-- /.rm-left -->
                            </div><!-- /.ri-mid -->
                            <div class="ri-bottom">
                                <div class="rb-left">
                                    <p class="nv-footnote">Found by: Presetnation Title, Material Content</p>
                                </div><!-- /.rb-left -->
                            </div><!-- /.ri-bottom -->
                        </div><!-- /.nv-result-item -->

                        <div class="nv-result-item">
                            <div class="ri-top">
                                <h3><a title="Minimal change disease - Focal glomerulosclerosis">Minimal change disease - Focal glomerulosclerosis</a></h3>
                                <div class="ri-top-data">
                                    <div class="rtd-item">
                                        <p class="nv-posted-stamp">Updated: 25 May 2018</p>
                                    </div><!-- /.rtd-item -->
                                    <div class="rtd-item">
                                        <a href="" class="nv-bookmark-trigger"></a>
                                    </div><!-- /.rtd-item -->
                                </div><!-- /.ri-top-data -->
                            </div><!-- /ri-top -->
                            <div class="ri-mid">
                                <div class="rm-left">
                                    <div class="nv-avatar-n-author">
                                        <figure class="ana-img" style="background-image: url('https://loremflickr.com/g/100/100/girl/all');"></figure>
                                        <p>Ewout Hoorn</p>
                                    </div><!-- /.nv-avatar-n-author -->
                                    <div class="nv-materials-available">
                                        <p>Materials:</p>
                                        <a href="" class="ma-item material" title="material available">
                                            <span>material available</span>
                                        </a>
                                        <a href="" class="ma-item webcast" title="webcast available">
                                            <span>webcast available</span>
                                        </a>
                                        <a href="" class="ma-item abstract" title="abstract available">
                                            <span>abstract available</span>
                                        </a>
                                    </div><!-- /.nv-materials-available -->
                                </div><!-- /.rm-left -->
                                <div class="rm-right">
                                    <a href="#" class="nv-btn filled extra-small purchase radius-4" data-icon-pl="&#xe04e;">Purchase this item for &euro; 5.-</a>
                                </div><!-- /.rm-right -->
                            </div><!-- /.ri-mid -->
                            <div class="ri-bottom">
                                <div class="rb-left">
                                    <p class="nv-footnote">Found by: Presetnation Title, Material Content</p>
                                </div><!-- /.rb-left -->
                                <div class="rb-right">
                                    <p class="nv-bcm-memeber">or <a href="">become an ERA-EDTA Member</a></p>
                                </div><!-- /.rb-right -->
                            </div><!-- /.ri-bottom -->
                        </div><!-- /.nv-result-item -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
            <div class="nv-row semi-spaced">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                       <div class="nv-banner-break" role="banner">
                           <a><img src="https://loremflickr.com/1200/200/medicine/all"></a>
                       </div><!-- /.nv-banner-break -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
            <div class="nv-row small-spaced-top">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-result-item">
                            <div class="ri-top">
                                <h3><a title="Minimal change disease - Focal glomerulosclerosis">Minimal change disease - Focal glomerulosclerosis</a></h3>
                                <div class="ri-top-data">
                                    <div class="rtd-item">
                                        <p class="nv-posted-stamp">Updated: 25 May 2018</p>
                                    </div><!-- /.rtd-item -->
                                    <div class="rtd-item">
                                        <a href="" class="nv-bookmark-trigger"></a>
                                    </div><!-- /.rtd-item -->
                                </div><!-- /.ri-top-data -->
                            </div><!-- /ri-top -->
                            <div class="ri-mid">
                                <div class="rm-left">
                                    <div class="nv-avatar-n-author">
                                        <figure class="ana-img" style="background-image: url('https://loremflickr.com/g/100/100/girl/all');"></figure>
                                        <p>Ewout Hoorn</p>
                                    </div><!-- /.nv-avatar-n-author -->
                                    <div class="nv-materials-available">
                                        <p>Materials:</p>
                                        <a href="" class="ma-item material" title="material available">
                                            <span>material available</span>
                                        </a>
                                        <a href="" class="ma-item webcast" title="webcast available">
                                            <span>webcast available</span>
                                        </a>
                                        <a href="" class="ma-item abstract" title="abstract available">
                                            <span>abstract available</span>
                                        </a>
                                    </div><!-- /.nv-materials-available -->
                                </div><!-- /.rm-left -->
                                <div class="rm-right">
                                    <a href="#" class="nv-btn filled extra-small purchase radius-4" data-icon-pl="&#xe04e;">Purchase this item for &euro; 5.-</a>
                                </div><!-- /.rm-right -->
                            </div><!-- /.ri-mid -->
                            <div class="ri-bottom">
                                <div class="rb-left">
                                    <p class="nv-footnote">Found by: Presetnation Title, Material Content</p>
                                </div><!-- /.rb-left -->
                                <div class="rb-right">
                                    <p class="nv-bcm-memeber">or <a href="">become an ERA-EDTA Member</a></p>
                                </div><!-- /.rb-right -->
                            </div><!-- /.ri-bottom -->
                        </div><!-- /.nv-result-item -->

                        <div class="nv-result-item">
                            <div class="ri-top">
                                <h3><a title="Minimal change disease - Focal glomerulosclerosis">Minimal change disease - Focal glomerulosclerosis</a></h3>
                                <div class="ri-top-data">
                                    <div class="rtd-item">
                                        <p class="nv-posted-stamp">Updated: 25 May 2018</p>
                                    </div><!-- /.rtd-item -->
                                    <div class="rtd-item">
                                        <a href="" class="nv-bookmark-trigger"></a>
                                    </div><!-- /.rtd-item -->
                                </div><!-- /.ri-top-data -->
                            </div><!-- /ri-top -->
                            <div class="ri-mid">
                                <div class="rm-left">
                                    <div class="nv-avatar-n-author">
                                        <figure class="ana-img" style="background-image: url('https://loremflickr.com/g/100/100/girl/all');"></figure>
                                        <p>Ewout Hoorn</p>
                                    </div><!-- /.nv-avatar-n-author -->
                                    <div class="nv-materials-available">
                                        <p>Materials:</p>
                                        <a href="" class="ma-item material" title="material available">
                                            <span>material available</span>
                                        </a>
                                        <a href="" class="ma-item webcast" title="webcast available">
                                            <span>webcast available</span>
                                        </a>
                                        <a href="" class="ma-item abstract" title="abstract available">
                                            <span>abstract available</span>
                                        </a>
                                    </div><!-- /.nv-materials-available -->
                                </div><!-- /.rm-left -->
                                <div class="rm-right">
                                    <a href="#" class="nv-btn filled extra-small purchase radius-4" data-icon-pl="&#xe04e;">Purchase this item for &euro; 5.-</a>
                                </div><!-- /.rm-right -->
                            </div><!-- /.ri-mid -->
                            <div class="ri-bottom">
                                <div class="rb-left">
                                    <p class="nv-footnote">Found by: Presetnation Title, Material Content</p>
                                </div><!-- /.rb-left -->
                                <div class="rb-right">
                                    <p class="nv-bcm-memeber">or <a href="">become an ERA-EDTA Member</a></p>
                                </div><!-- /.rb-right -->
                            </div><!-- /.ri-bottom -->
                        </div><!-- /.nv-result-item -->

                        <div class="nv-result-item">
                            <div class="ri-top">
                                <h3><a title="Minimal change disease - Focal glomerulosclerosis">Minimal change disease - Focal glomerulosclerosis</a></h3>
                                <div class="ri-top-data">
                                    <div class="rtd-item">
                                        <p class="nv-posted-stamp">Updated: 25 May 2018</p>
                                    </div><!-- /.rtd-item -->
                                    <div class="rtd-item">
                                        <a href="" class="nv-bookmark-trigger"></a>
                                    </div><!-- /.rtd-item -->
                                </div><!-- /.ri-top-data -->
                            </div><!-- /ri-top -->
                            <div class="ri-mid">
                                <div class="rm-left">
                                    <div class="nv-avatar-n-author">
                                        <figure class="ana-img" style="background-image: url('https://loremflickr.com/g/100/100/girl/all');"></figure>
                                        <p>Ewout Hoorn</p>
                                    </div><!-- /.nv-avatar-n-author -->
                                    <div class="nv-materials-available">
                                        <p>Materials:</p>
                                        <a href="" class="ma-item material" title="material available">
                                            <span>material available</span>
                                        </a>
                                        <a href="" class="ma-item webcast" title="webcast available">
                                            <span>webcast available</span>
                                        </a>
                                        <a href="" class="ma-item abstract" title="abstract available">
                                            <span>abstract available</span>
                                        </a>
                                    </div><!-- /.nv-materials-available -->
                                </div><!-- /.rm-left -->
                            </div><!-- /.ri-mid -->
                            <div class="ri-bottom">
                                <div class="rb-left">
                                    <p class="nv-footnote">Found by: Presetnation Title, Material Content</p>
                                </div><!-- /.rb-left -->
                            </div><!-- /.ri-bottom -->
                        </div><!-- /.nv-result-item -->

                        <div class="nv-result-item">
                            <div class="ri-top">
                                <h3><a title="Minimal change disease - Focal glomerulosclerosis">Minimal change disease - Focal glomerulosclerosis</a></h3>
                                <div class="ri-top-data">
                                    <div class="rtd-item">
                                        <p class="nv-posted-stamp">Updated: 25 May 2018</p>
                                    </div><!-- /.rtd-item -->
                                    <div class="rtd-item">
                                        <a href="" class="nv-bookmark-trigger"></a>
                                    </div><!-- /.rtd-item -->
                                </div><!-- /.ri-top-data -->
                            </div><!-- /ri-top -->
                            <div class="ri-mid">
                                <div class="rm-left">
                                    <div class="nv-avatar-n-author">
                                        <figure class="ana-img" style="background-image: url('https://loremflickr.com/g/100/100/girl/all');"></figure>
                                        <p>Ewout Hoorn</p>
                                    </div><!-- /.nv-avatar-n-author -->
                                    <div class="nv-materials-available">
                                        <p>Materials:</p>
                                        <a href="" class="ma-item material" title="material available">
                                            <span>material available</span>
                                        </a>
                                        <a href="" class="ma-item webcast" title="webcast available">
                                            <span>webcast available</span>
                                        </a>
                                        <a href="" class="ma-item abstract" title="abstract available">
                                            <span>abstract available</span>
                                        </a>
                                    </div><!-- /.nv-materials-available -->
                                </div><!-- /.rm-left -->
                                <div class="rm-right">
                                    <a href="#" class="nv-btn filled extra-small purchase radius-4" data-icon-pl="&#xe04e;">Purchase this item for &euro; 5.-</a>
                                </div><!-- /.rm-right -->
                            </div><!-- /.ri-mid -->
                            <div class="ri-bottom">
                                <div class="rb-left">
                                    <p class="nv-footnote">Found by: Presetnation Title, Material Content</p>
                                </div><!-- /.rb-left -->
                                <div class="rb-right">
                                    <p class="nv-bcm-memeber">or <a href="">become an ERA-EDTA Member</a></p>
                                </div><!-- /.rb-right -->
                            </div><!-- /.ri-bottom -->
                        </div><!-- /.nv-result-item -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
            <div class="nv-row semi-spaced-top large-spaced-bottom flow-right">
                <div class="nv-column desk-initial">
                    <div class="nv-column-content">
                        <div class="nv-thorough-pagination">
                            <!--<div class="tp-go-to-page"> Go to: <input type="text" value="1"> of 25 </div>-->
                            <div class="tp-rows-per-page">
                                Rows per page:
                                <app-dropdown-container class="nv-dropdown-type1 drop-right" [placeholder]="'10'"></app-dropdown-container>
                            </div><!-- /.tp-rows-per-page -->
                            <div class="tp-pages">
                                <a class="first"></a>
                                <a class="prev"></a>
                                <span>1-10 of 240</span>
                                <a class="next"></a>
                                <a class="last"></a>
                            </div>
                        </div><!-- /nv-thorough-pagination -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </main><!-- /.nv-section -->

    <!-- EBMT chunk -->

    <!-- Start hero -->
    <section class="nv-section guttered nv-hero-start" style="background-image: url('../../assets/ebmt-hero-img-tinted.jpg')">
        <div class="nv-port">
            <div class="nv-row extra-large-spaced-top small-spaced-bottom flow-center">
                <div class="nv-column desk-8 phab-12">
                    <div class="nv-column-content">
                        <header class="nv-hero-preamble">
                            <h1>EBMT E-Learning</h1>
                            <h3>Learn smart with a source you know and trust</h3>
                            <a class="nv-btn filled accent1-inverted extruded radius-high big">
                                Get Started - Register now
                            </a><!-- /.nv-btn filled white extruded radius-high -->
                        </header><!-- /.nv-hero-preamble -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->


    <!-- Latest courses -->
    <div>
    <section class="nv-latest-courses">
        <div class="nv-section guttered">
            <div class="nv-port">
                <div class="nv-row spaced-top">
                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <h3 class="nv-tiny-itlize">Latest EBMT courses</h3>
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->
                </div><!-- /.nv-row -->
            </div><!-- /.nv-port -->
        </div><!-- /.nv-section -->
        <div class="nv-section">
            <div class="nv-port">
                <div class="nv-row small-spaced-bottom">
                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <div class="nv-courses-view">
                                <div class="cv-item">
                                    <div class="cv-item-port">
                                        <figure style="background-image: url('https://loremflickr.com/540/540/medicine/all?random=1')"><a title="article title"></a></figure>
                                        <div class="cv-txt">
                                            <header class="cvt-upper">
                                                <h5><span style="background-color:#82092d;">WEBINAR</span></h5>
                                                <h3><a>Advances in the treatment of primary immunodeficiency diseases</a></h3>
                                            </header><!-- /.cvt-upper -->
                                            <footer class="cvt-lower">
                                                <p>
                                                    <span>Talk given as part of</span>
                                                    4th IEWP Annual Conference
                                                </p>
                                            </footer><!-- /.cvt-lower -->
                                        </div><!-- /.cv-txt -->
                                    </div><!-- /.cv-item-port -->
                                </div><!-- /.cv-item -->

                                <div class="cv-item">
                                    <div class="cv-item-port">
                                        <figure style="background-image: url('https://loremflickr.com/540/540/medicine/all?random=2')"><a title="article title"></a></figure>
                                        <div class="cv-txt">
                                            <header class="cvt-upper">
                                                <h5><span style="background-color:#17b1d7;">NURSE HANDBOOK</span></h5>
                                                <h3><a>Cell Source and Apheresis</a></h3>
                                                <p class="cv-test">Test - <strong>2 CME pts</strong></p>
                                            </header><!-- /.cvt-upper -->
                                            <footer class="cvt-lower">
                                                <p>
                                                    <span>Prepared by</span>
                                                    Aleksandra Babic
                                                </p>
                                            </footer><!-- /.cvt-lower -->
                                        </div><!-- /.cv-txt -->
                                    </div><!-- /.cv-item-port -->
                                </div><!-- /.cv-item -->

                                <div class="cv-item">
                                    <div class="cv-item-port">
                                        <figure style="background-image: url('https://loremflickr.com/540/540/medicine/all?random=3')"><a title="article title"></a></figure>
                                        <div class="cv-txt">
                                            <header class="cvt-upper">
                                                <h5><span style="background-color:#318af1;">Physician</span></h5>
                                                <h3><a>Immunosuppressive treatment in aplastic anemia</a></h3>
                                            </header><!-- /.cvt-upper -->
                                            <footer class="cvt-lower">
                                                <p>
                                                    <span>Prepared by</span>
                                                    Antonio M. Risitano
                                                </p>
                                            </footer><!-- /.cvt-lower -->
                                        </div><!-- /.cv-txt -->
                                    </div><!-- /.cv-item-port -->
                                </div><!-- /.cv-item -->

                                <div class="cv-item">
                                    <div class="cv-item-port">
                                        <figure style="background-image: url('https://loremflickr.com/540/540/medicine/all?random=4')"><a title="article title"></a></figure>
                                        <div class="cv-txt">
                                            <header class="cvt-upper">
                                                <h5><span style="background-color:#82092d;">WEBINAR</span></h5>
                                                <h3><a>Advances in the treatment of primary immunodeficiency diseases</a></h3>
                                            </header><!-- /.cvt-upper -->
                                            <footer class="cvt-lower">
                                                <p>
                                                    <span>Talk given as part of</span>
                                                    4th IEWP Annual Conference
                                                </p>
                                            </footer><!-- /.cvt-lower -->
                                        </div><!-- /.cv-txt -->
                                    </div><!-- /.cv-item-port -->
                                </div><!-- /.cv-item -->

                                <div class="cv-item">
                                    <div class="cv-item-port">
                                        <figure style="background-image: url('https://loremflickr.com/540/540/medicine/all?random=5')"><a title="article title"></a></figure>
                                        <div class="cv-txt">
                                            <header class="cvt-upper">
                                                <h5><span style="background-color:#17b1d7;">NURSE HANDBOOK</span></h5>
                                                <h3><a>Cell Source and Apheresis</a></h3>
                                                <p class="cv-test">Test - <strong>2 CME pts</strong></p>
                                            </header><!-- /.cvt-upper -->
                                            <footer class="cvt-lower">
                                                <p>
                                                    <span>Prepared by</span>
                                                    Aleksandra Babic
                                                </p>
                                            </footer><!-- /.cvt-lower -->
                                        </div><!-- /.cv-txt -->
                                    </div><!-- /.cv-item-port -->
                                </div><!-- /.cv-item -->
                            </div><!-- /.nv-courses-view -->
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->
                </div><!-- /.nv-row -->
            </div><!-- /.nv-port -->
        </div><!-- /.nv-section -->
    </section><!-- /.nv-latest-courses -->
    </div>

    <!-- Latest news & banner -->
    <section class="nv-section guttered nv-latest-news w-banner">
        <div class="nv-port">
            <div class="nv-row large-spaced space-between">
                <div class="nv-column desk-8 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-latest-news-view">
                            <div class="lnv-heading">
                                <h3>The Latest News</h3>
                                <p><a>See all news</a></p>
                            </div><!-- /.lnv-heading -->

                            <div class="lnv-articles">
                                <div class="lnv-highlighted">
                                    <div class="lnv-item highlighted">
                                        <figure><a href="#"><img src="https://loremflickr.com/700/700/medicine/all?random=6" alt="article title"></a></figure>
                                        <div class="lnv-txt">
                                            <h4><a>How microbes and their metabolites correlate with HSCT outcomes?</a></h4>
                                            <p class="lnv-posted">December 12, 2018</p>
                                        </div><!-- /.lnv-txt -->
                                    </div><!-- /.lnv-item -->
                                </div><!-- /.lnv-highlighted -->
                                <div class="lnv-list">
                                    <div class="lnv-item">
                                        <figure><a href="#"><img src="https://loremflickr.com/300/300/medicine/all?random=7" alt="article title"></a></figure>
                                        <div class="lnv-txt">
                                            <h4><a>Veno-occlusive disease after high-dose busulfan/melphalan in neuroblastoma</a></h4>
                                            <p class="lnv-posted">December 11, 2018</p>
                                        </div><!-- /.lnv-txt -->
                                    </div><!-- /.lnv-item -->

                                    <div class="lnv-item">
                                        <figure><a href="#"><img src="https://loremflickr.com/300/300/medicine/all?random=8" alt="article title"></a></figure>
                                        <div class="lnv-txt">
                                            <h4><a>Digital PCR to assess platelet recovery after stem cell transplantation</a></h4>
                                            <p class="lnv-posted">December 09, 2018</p>
                                        </div><!-- /.lnv-txt -->
                                    </div><!-- /.lnv-item -->

                                    <div class="lnv-item">
                                        <figure><a href="#"><img src="https://loremflickr.com/300/300/medicine/all?random=9" alt="article title"></a></figure>
                                        <div class="lnv-txt">
                                            <h4><a>Calfactant for acute lung injury in pediatric stem cell and oncology patients</a></h4>
                                            <p class="lnv-posted">December 03, 2018</p>
                                        </div><!-- /.lnv-txt -->
                                    </div><!-- /.lnv-item -->
                                </div><!-- /.lnv-list -->
                            </div><!-- /.lnv-articles -->
                        </div><!-- /.nv-latest-news-view -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->

                <div class="nv-column desk-3 tab-12">
                    <div class="nv-column-content">
                        <figure class="nv-single-banner" role="banner">
                            <a><img src="../../assets/ebmt-sponsor-banner.png" alt="banner description"></a>
                        </figure><!-- /.nv-single-banner -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->


    <!-- Custom banner -->
    <section class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row small-spaced-top large-spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-custom-type-banner" role="banner" style="background-image: url('../../assets/ebmt-custom-banner.png')">
                            <h3>Discover more with EBMT</h3>
                            <p>Our free Recommendations service uses machine learning and your online activity to suggest research tailored to your needs.</p>
                            <!--<a class="nv-btn filled accent1 extruded radius-high medium" data-icon-pl-after="&#xe91d;">-->
                            <a class="nv-btn filled accent1 extruded radius-high medium">
                                Register for personalized feautures
                            </a><!-- /.nv-btn -->
                        </div><!-- /.nv-custom-type-banner -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->


    <!-- Summary articles -->
    <section class="nv-section">
        <div class="nv-port">
            <div class="nv-row v-stretch gap-30 spaced-top small-spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content" style="text-align: center;">
                        <h2 class="nv-light">Educational summary articles</h2>
                    </div><!-- /.nv-column-content -->
                </div><!-- /nv-column -->
            </div><!-- /.nv-row -->
            <div class="nv-row v-stretch gap-30 spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-edu-summary-articles">
                            <div class="esa-item">
                                <div class="esa-box">
                                    <div class="esa-meta-column">
                                        <h5><span>EBMT 2019</span></h5>
                                        <figure class="esa-speaker-img"><img src="../../assets/avatar-dummy.jpg"></figure>
                                        <p class="esa-author">
                                            <span>Presented by</span><br>
                                            Dr Jan Styczynski
                                        </p>
                                    </div><!-- /.esa-meta-column -->
                                    <div class="esa-content">
                                        <div class="esa-content-up">
                                            <h3><a>Dissecting leukemogenesis: chronology of mutations in AML</a></h3>
                                            <p class="esa-written-by">Written by Johanna Smith</p>
                                        </div><!-- /.esa-content-up -->
                                        <div class="esa-content-down">
                                            <span>Conference</span>
                                            <h5>44th Annual EBMT Meeting 2018</h5>
                                            <h6>Lisbon, Portugal</h6>
                                        </div><!-- /.esa-content-down -->
                                    </div><!-- /.esa-content -->
                                </div><!-- /.esa-box -->
                            </div><!-- /.esa-item -->

                            <div class="esa-item">
                                <div class="esa-box">
                                    <div class="esa-meta-column">
                                        <h5><span>EBMT 2019</span></h5>
                                        <figure class="esa-speaker-img"><img src="../../assets/patrick-patrick-stahl.jpg"></figure>
                                        <p class="esa-author">
                                            <span>Presented by</span><br>
                                            Dr Sian Howard
                                        </p>
                                    </div><!-- /.esa-meta-column -->
                                    <div class="esa-content">
                                        <div class="esa-content-up">
                                            <h3><a>The challenge: Differential diagnosis of post-transplant complications</a></h3>
                                            <p class="esa-written-by">Written by Johanna Goldberg</p>
                                        </div><!-- /.esa-content-up -->
                                        <div class="esa-content-down">
                                            <span>Conference</span>
                                            <h5>44th Annual EBMT Meeting 2018</h5>
                                            <h6>Lisbon, Portugal</h6>
                                        </div><!-- /.esa-content-down -->
                                    </div><!-- /.esa-content -->
                                </div><!-- /.esa-box -->
                            </div><!-- /.esa-item -->

                            <div class="esa-item">
                                <div class="esa-box">
                                    <div class="esa-meta-column">
                                        <h5><span>EBMT 2019</span></h5>
                                        <figure class="esa-speaker-img"><img src="../../assets/slider-pohoto-carlos.jpeg"></figure>
                                        <p class="esa-author">
                                            <span>Presented by</span><br>
                                            Dr Nicolas Milligam
                                        </p>
                                    </div><!-- /.esa-meta-column -->
                                    <div class="esa-content">
                                        <div class="esa-content-up">
                                            <h3><a>The challenge: Differential diagnosis of post-transplant complications</a></h3>
                                            <p class="esa-written-by">Written by Megan Grosswater</p>
                                        </div><!-- /.esa-content-up -->
                                        <div class="esa-content-down">
                                            <span>Conference</span>
                                            <h5>40th Annual EBMT Meeting 2018</h5>
                                            <h6>Oslo, Norway</h6>
                                        </div><!-- /.esa-content-down -->
                                    </div><!-- /.esa-content -->
                                </div><!-- /.esa-box -->
                            </div><!-- /.esa-item -->

                            <div class="esa-item">
                                <div class="esa-box">
                                    <div class="esa-meta-column">
                                        <h5><span>EBMT 2019</span></h5>
                                        <figure class="esa-speaker-img"><img src="../../assets/avatar-dummy-1.jpg"></figure>
                                        <p class="esa-author">
                                            <span>Presented by</span><br>
                                            Dr Sue Houston
                                        </p>
                                    </div><!-- /.esa-meta-column -->
                                    <div class="esa-content">
                                        <div class="esa-content-up">
                                            <h3><a>Dissecting leukemogenesis: chronology of mutations in AML</a></h3>
                                            <p class="esa-written-by">Written by Philip Suzansky</p>
                                        </div><!-- /.esa-content-up -->
                                        <div class="esa-content-down">
                                            <span>Conference</span>
                                            <h5>46th Monthly EBMT Meeting 2019</h5>
                                            <h6>Tokyo, Japan</h6>
                                        </div><!-- /.esa-content-down -->
                                    </div><!-- /.esa-content -->
                                </div><!-- /.esa-box -->
                            </div><!-- /.esa-item -->

                            <div class="esa-item">
                                <div class="esa-box">
                                    <div class="esa-meta-column">
                                        <h5><span>EBMT 2019</span></h5>
                                        <figure class="esa-speaker-img"><img src="../../assets/avatar-dummy-2.jpg"></figure>
                                        <p class="esa-author">
                                            <span>Presented by</span><br>
                                            Dr Boris Dugalov
                                        </p>
                                    </div><!-- /.esa-meta-column -->
                                    <div class="esa-content">
                                        <div class="esa-content-up">
                                            <h3><a>Emerging infectious threats: Zika virus, Chikungunya, Dengue etc.</a></h3>
                                            <p class="esa-written-by">Written by Marcus Groomhold</p>
                                        </div><!-- /.esa-content-up -->
                                        <div class="esa-content-down">
                                            <span>Conference</span>
                                            <h5>46th Monthly EBMT Meeting 2019</h5>
                                            <h6>Tokyo, Japan</h6>
                                        </div><!-- /.esa-content-down -->
                                    </div><!-- /.esa-content -->
                                </div><!-- /.esa-box -->
                            </div><!-- /.esa-item -->

                            <div class="esa-item">
                                <div class="esa-box">
                                    <div class="esa-meta-column">
                                        <h5><span>EBMT 2019</span></h5>
                                        <figure class="esa-speaker-img"><img src="../../assets/avatar-dummy-3.jpg"></figure>
                                        <p class="esa-author">
                                            <span>Presented by</span><br>
                                            Dr Christina Boglow
                                        </p>
                                    </div><!-- /.esa-meta-column -->
                                    <div class="esa-content">
                                        <div class="esa-content-up">
                                            <h3><a>New drugs in transplant recipients: practical recommendations for nephrologists</a></h3>
                                            <p class="esa-written-by">Written by Marcus Groomhold</p>
                                        </div><!-- /.esa-content-up -->
                                        <div class="esa-content-down">
                                            <span>Conference</span>
                                            <h5>48th Weekly EBMT Meeting 2019</h5>
                                            <h6>Budapest, Hungary</h6>
                                        </div><!-- /.esa-content-down -->
                                    </div><!-- /.esa-content -->
                                </div><!-- /.esa-box -->
                            </div><!-- /.esa-item -->

                        </div><!-- /.nv-edu-summary-articles -->
                        <div class="sap-btn">
                            <a class="nv-btn hollow neutral extruded radius-high medium">Explore all summary articles</a>
                        </div>
                    </div><!-- /.nv-column-content -->
                </div><!-- /nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->

    <!-- Hub -->
    <section class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row v-stretch gap-30 spaced-top small-spaced-bottom flow-center">
                <div class="nv-column desk-8 tab-12">
                    <div class="nv-column-content" style="text-align: center;">
                        <h2 class="nv-light">Hub - Empowering Knowledge</h2>
                        <p>Browse materials in the following subject areas, and make sure you bookmark these pages and check back regularly to see the latest news and offers in your subject area.</p>
                    </div><!-- /.nv-column-content -->
                </div><!-- /nv-column -->
            </div><!-- /.nv-row -->
            <div class="nv-row v-stretch gap-30 spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-hub-teasers">
                            <div class="ht-item">
                                <div class="ht-box">
                                    <figure style="background-image: url('https://loremflickr.com/540/540/medicine/all?random=6')"><a></a></figure>
                                    <div class="ht-txt">
                                        <h3><a>Nurse Handbook</a></h3>
                                        <p><a>All courses</a></p>
                                    </div><!-- /.ht-txt -->
                                </div><!-- /.ht-box -->
                            </div><!-- /.ht-item -->

                            <div class="ht-item">
                                <div class="ht-box">
                                    <figure style="background-image: url('https://loremflickr.com/540/540/medicine/all?random=7')"><a></a></figure>
                                    <div class="ht-txt">
                                        <h3><a>Data Manager Textbook</a></h3>
                                        <p><a>All courses</a></p>
                                    </div><!-- /.ht-txt -->
                                </div><!-- /.ht-box -->
                            </div><!-- /.ht-item -->

                            <div class="ht-item">
                                <div class="ht-box">
                                    <figure style="background-image: url('https://loremflickr.com/540/540/medicine/all?random=8')"><a></a></figure>
                                    <div class="ht-txt">
                                        <h3><a>Physician E-materials</a></h3>
                                        <p><a>All courses</a></p>
                                    </div><!-- /.ht-txt -->
                                </div><!-- /.ht-box -->
                            </div><!-- /.ht-item -->

                            <div class="ht-item">
                                <div class="ht-box">
                                    <figure style="background-image: url('https://loremflickr.com/540/540/medicine/all?random=9')"><a></a></figure>
                                    <div class="ht-txt">
                                        <h3><a>Webinar E-materials</a></h3>
                                        <p><a>All courses</a></p>
                                    </div><!-- /.ht-txt -->
                                </div><!-- /.ht-box -->
                            </div><!-- /.ht-item -->
                        </div><!-- /.nv-hub-teasers -->
                        <div class="sap-btn">
                            <a class="nv-btn hollow neutral extruded radius-high medium">Explore hub</a>
                        </div>
                    </div><!-- /.nv-column-content -->
                </div><!-- /nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->





    <!-- Search chunk -->
    <div class="nv-section nv-search-page-section guttered">
        <div class="nv-port">
            <div class="nv-row large-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-search-head">
                            <h1>Search The Hub</h1>
                            <form class="nv-search-bar">
                                <div class="nv-search-page-input input-like">
                                    <input type="text" placeholder="Search...">
                                    <span aria-hidden="true" class="nspi-focus-visual"></span>
                                    <div class="nv-skinless-select">
                                        <select>
                                            <option>in titles, content and speakers</option>
                                            <option>in something else</option>
                                            <option>in another section</option>
                                        </select>
                                    </div><!-- /.nv-skinless-select -->
                                    <button type="submit" class="nv-search-page-btn"></button>
                                </div><!-- /.nv-search-page-input -->
                                <div class="nv-search-filter-select input-like">
                                    <label>Type:</label>
                                    <div class="nv-skinless-select">
                                        <select>
                                            <option>All Types</option>
                                            <option>Some Types</option>
                                            <option>Presentations</option>
                                            <option>With Webcast</option>
                                        </select>
                                    </div><!-- /.nv-skinless-select -->
                                </div><!-- /.nv-search-filter-select input-like -->
                                <div class="nv-filter-trigger input-like">
                                    <a>Filters</a>
                                </div><!-- /.nv-filter-trigger input-like -->

                                <div class="nv-search-bar-plate not-shown">
                                    <div class="sbp-content">
                                        <h3>Add Filters</h3>
                                        <div class="sbp-filters">
                                            <div class="sbpf-column">
                                                <h4>Sources</h4>
                                                <ul>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb1">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            EBMT 2019
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb1">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            EBMT 2018
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb1">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            EBMT 2017
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div><!-- /.sbpf-column -->

                                            <div class="sbpf-column">
                                                <h4>Article Type</h4>
                                                <ul>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb2">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            Any
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb2">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            PDF
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb2">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            Webcast
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb2">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            Abstract
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div><!-- /.sbpf-column -->

                                            <div class="sbpf-column">
                                                <h4>Availability</h4>
                                                <ul>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb2">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            Free access
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb2">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            Limited access
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div><!-- /.sbpf-column -->

                                            <div class="sbpf-column">
                                                <h4>My Selection</h4>
                                                <ul>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb2">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            Liked
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb2">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            Timeline
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div><!-- /.sbpf-column -->

                                            <div class="sbpf-column">
                                                <h4>My History</h4>
                                                <ul>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb2">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            All Accessed Articles
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div><!-- /.sbpf-column -->
                                        </div><!-- /.sbp-filters -->
                                    </div><!-- /.sbp-content -->
                                    <div class="sbp-tool-bar">
                                        <div class="sbp-filter-tool">
                                            <p class="clear-all-filters"><a>Clear all filters</a></p>
                                            <div class="sft-btns">
                                                <button class="nv-btn hollow medium neutral-accent">Cancel</button>
                                                <button class="nv-btn filled medium accent1">Apply Filters</button>
                                            </div><!-- /.sft-btns -->
                                        </div><!-- /.sbp-filter-tool -->
                                    </div><!-- /.sbp-tool-bar -->
                                </div><!-- /.nv-search-bar-plate filters -->
                            </form><!-- /.nv-search-bar -->
                        </div><!-- /.nv-search-head -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </div><!-- /.nv-section -->

    <div class="nv-section nv-search-page-section guttered">
        <div class="nv-port">
            <div class="nv-row large-spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-search-results">
                            <div class="sr-top-bar">
                                <div class="sr-top-tools">
                                    <h2>Latest From The Hub</h2>
                                </div><!-- /.sr-top-tools -->
                                <div class="sr-order">
                                    <label>Sort by:</label>
                                    <div class="nv-skinless-select">
                                        <select>
                                            <option>Newest</option>
                                            <option>Oldest</option>
                                            <option>Most popular</option>
                                            <option>Time added</option>
                                        </select>
                                    </div><!-- /.nv-skinless-select -->
                                </div><!-- /.sr-order -->
                            </div><!-- /.sr-top-bar -->

                            <div class="nv-search-result-item recommended">
                                <p class="sri-posted">Updated: 2h ago</p>
                                <div class="sri-access"><span class="full">Full Access</span></div>
                                <div class="sri-content">
                                    <div class="sri-top-bar">
                                        <p class="sri-source"><span>EBMT 2018</span><a href="#">Nurse Handbook</a></p>
                                    </div><!-- /.sri-top-bar -->
                                    <div class="sri-mid-portion">
                                        <div class="sri-title-n-author">
                                            <h3><a title="pres.title">Haematopoietic stem cell transplantation (HSCT): How Does It Work?</a></h3>
                                            <p class="sri-author">
                                                <figure style="background-image: url('../../assets/patrick-patrick-stahl.jpg')"></figure>
                                                <span>Markus Smith</span>
                                            </p>
                                            <div class="sri-features">
                                                <a>Abstract</a>
                                                <a>PDF</a>
                                                <a>Webcast</a>
                                            </div><!-- /.sri-features -->
                                        </div><!-- /.sri-title-n-author -->
                                        <div class="sri-purchase">
                                            <button class="nv-btn hollow medium neutral-accent">1&euro; - Purchase this item</button>
                                        </div><!-- /.sri-purchase -->
                                    </div><!-- /.sri-mid-portion -->
                                    <div class="sri-bottom-line">
                                        <p class="sri-recommendation">Recommendation is based on what you already read.&nbsp;&nbsp;<a href="#">Not relevant?</a></p>
                                    </div><!-- /.sri-bottom-line -->
                                </div><!-- /.sri-content -->
                                <a class="sri-bookmark" title="bookmark this presentation"></a>
                            </div><!-- /.nv-search-result-item -->

                            <div class="nv-search-result-item recommended">
                                <p class="sri-posted">Updated: 2h ago</p>
                                <div class="sri-access"><span class="lock">Limited Access</span></div>
                                <div class="sri-content">
                                    <div class="sri-top-bar">
                                        <p class="sri-source"><span>EBMT 2018</span><a href="#">Nurse Handbook</a></p>
                                    </div><!-- /.sri-top-bar -->
                                    <div class="sri-mid-portion">
                                        <div class="sri-title-n-author">
                                            <h3><a title="pres.title">Nursing Research and Audit in the Transplant Setting</a></h3>
                                            <p class="sri-author">
                                                <figure style="background-image: url('../../assets/avatar-dummy.jpg')"></figure>
                                                <span>Sian McPharson</span>
                                            </p>
                                        </div><!-- /.sri-title-n-author -->
                                        <div class="sri-purchase">
                                            <button class="nv-btn hollow medium neutral-accent">1&euro; - Purchase this item</button>
                                        </div><!-- /.sri-purchase -->
                                    </div><!-- /.sri-mid-portion -->
                                    <div class="sri-bottom-line">
                                        <p class="sri-recommendation">Recommendation is based on what you already read.&nbsp;&nbsp;<a href="#">Not relevant?</a></p>
                                    </div><!-- /.sri-bottom-line -->
                                </div><!-- /.sri-content -->
                                <a class="sri-bookmark" title="bookmark this presentation"></a>
                            </div><!-- /.nv-search-result-item -->

                            <div class="nv-search-result-item">
                                <p class="sri-posted">Updated: 2h ago</p>
                                <div class="sri-access"><span class="full">Full Access</span></div>
                                <div class="sri-content">
                                    <div class="sri-top-bar">
                                        <p class="sri-source"><span>EBMT 2018</span><a href="#">Nurse Handbook</a></p>
                                    </div><!-- /.sri-top-bar -->
                                    <div class="sri-mid-portion">
                                        <div class="sri-title-n-author">
                                            <h3><a title="pres.title">Early and Acute Complications and the Principles of HSCT Nursing Care</a></h3>
                                            <p class="sri-author">
                                                <figure style="background-image: url('../../assets/patrick-patrick-stahl.jpg')"></figure>
                                                <span>Markus Smith</span>
                                            </p>
                                            <div class="sri-features">
                                                <a>Abstract</a>
                                                <a>PDF</a>
                                                <a>Webcast</a>
                                            </div><!-- /.sri-features -->
                                        </div><!-- /.sri-title-n-author -->
                                        <div class="sri-purchase">
                                            <button class="nv-btn hollow medium neutral-accent">1&euro; - Purchase this item</button>
                                        </div><!-- /.sri-purchase -->
                                    </div><!-- /.sri-mid-portion -->
                                    <div class="sri-bottom-line"></div><!-- /.sri-bottom-line -->
                                </div><!-- /.sri-content -->
                                <a class="sri-bookmark" title="bookmark this presentation"></a>
                            </div><!-- /.nv-search-result-item -->

                            <div class="nv-search-result-item">
                                <p class="sri-posted">Updated: 2h ago</p>
                                <div class="sri-access"><span class="lock">Limited Access</span></div>
                                <div class="sri-content">
                                    <div class="sri-top-bar">
                                        <p class="sri-source"><span>EBMT 2018</span><a href="#">Nurse Handbook</a></p>
                                    </div><!-- /.sri-top-bar -->
                                    <div class="sri-mid-portion">
                                        <div class="sri-title-n-author">
                                            <h3><a title="pres.title">Improving Immunosuppressive Treatment for Patients With Severe Aplastic Anemia</a></h3>
                                            <p class="sri-author">
                                                <figure style="background-image: url('../../assets/avatar-dummy.jpg')"></figure>
                                                <span>Sian McPharson</span>
                                            </p>
                                        </div><!-- /.sri-title-n-author -->
                                        <div class="sri-purchase">
                                            <button class="nv-btn hollow medium neutral-accent">1&euro; - Purchase this item</button>
                                        </div><!-- /.sri-purchase -->
                                    </div><!-- /.sri-mid-portion -->
                                    <div class="sri-bottom-line"></div><!-- /.sri-bottom-line -->
                                </div><!-- /.sri-content -->
                                <a class="sri-bookmark" title="bookmark this presentation"></a>
                            </div><!-- /.nv-search-result-item -->

                            <div class="nv-search-result-item">
                                <p class="sri-posted">Updated: 2h ago</p>
                                <div class="sri-access"><span class="full">Full Access</span></div>
                                <div class="sri-content">
                                    <div class="sri-top-bar">
                                        <p class="sri-source"><span>EBMT 2018</span><a href="#">Nurse Handbook</a></p>
                                    </div><!-- /.sri-top-bar -->
                                    <div class="sri-mid-portion">
                                        <div class="sri-title-n-author">
                                            <h3><a title="pres.title">Haematopoietic stem cell transplantation (HSCT): How Does It Work?</a></h3>
                                            <p class="sri-author">
                                                <figure style="background-image: url('../../assets/patrick-patrick-stahl.jpg')"></figure>
                                                <span>Markus Smith</span>
                                            </p>
                                            <div class="sri-features">
                                                <a>Abstract</a>
                                                <a>PDF</a>
                                                <a>Webcast</a>
                                            </div><!-- /.sri-features -->
                                        </div><!-- /.sri-title-n-author -->
                                        <div class="sri-purchase">
                                            <button class="nv-btn hollow medium neutral-accent">1&euro; - Purchase this item</button>
                                        </div><!-- /.sri-purchase -->
                                    </div><!-- /.sri-mid-portion -->
                                    <div class="sri-bottom-line"></div><!-- /.sri-bottom-line -->
                                </div><!-- /.sri-content -->
                                <a class="sri-bookmark" title="bookmark this presentation"></a>
                            </div><!-- /.nv-search-result-item -->

                            <div class="nv-search-result-item">
                                <p class="sri-posted">Updated: 2h ago</p>
                                <div class="sri-access"><span class="lock">Limited Access</span></div>
                                <div class="sri-content">
                                    <div class="sri-top-bar">
                                        <p class="sri-source"><span>EBMT 2018</span><a href="#">Nurse Handbook</a></p>
                                    </div><!-- /.sri-top-bar -->
                                    <div class="sri-mid-portion">
                                        <div class="sri-title-n-author">
                                            <h3><a title="pres.title">Nursing Research and Audit in the Transplant Setting</a></h3>
                                            <p class="sri-author">
                                                <figure style="background-image: url('../../assets/avatar-dummy.jpg')"></figure>
                                                <span>Sian McPharson</span>
                                            </p>
                                        </div><!-- /.sri-title-n-author -->
                                        <div class="sri-purchase">
                                            <button class="nv-btn hollow medium neutral-accent">1&euro; - Purchase this item</button>
                                        </div><!-- /.sri-purchase -->
                                    </div><!-- /.sri-mid-portion -->
                                    <div class="sri-bottom-line"></div><!-- /.sri-bottom-line -->
                                </div><!-- /.sri-content -->
                                <a class="sri-bookmark" title="bookmark this presentation"></a>
                            </div><!-- /.nv-search-result-item -->

                            <div class="nv-search-result-item">
                                <p class="sri-posted">Updated: 2h ago</p>
                                <div class="sri-access"><span class="full">Full Access</span></div>
                                <div class="sri-content">
                                    <div class="sri-top-bar">
                                        <p class="sri-source"><span>EBMT 2018</span><a href="#">Nurse Handbook</a></p>
                                    </div><!-- /.sri-top-bar -->
                                    <div class="sri-mid-portion">
                                        <div class="sri-title-n-author">
                                            <h3><a title="pres.title">Early and Acute Complications and the Principles of HSCT Nursing Care</a></h3>
                                            <p class="sri-author">
                                                <figure style="background-image: url('../../assets/patrick-patrick-stahl.jpg')"></figure>
                                                <span>Markus Smith</span>
                                            </p>
                                            <div class="sri-features">
                                                <a>Abstract</a>
                                                <a>PDF</a>
                                                <a>Webcast</a>
                                            </div><!-- /.sri-features -->
                                        </div><!-- /.sri-title-n-author -->
                                        <div class="sri-purchase">
                                            <button class="nv-btn hollow medium neutral-accent">1&euro; - Purchase this item</button>
                                        </div><!-- /.sri-purchase -->
                                    </div><!-- /.sri-mid-portion -->
                                    <div class="sri-bottom-line"></div><!-- /.sri-bottom-line -->
                                </div><!-- /.sri-content -->
                                <a class="sri-bookmark" title="bookmark this presentation"></a>
                            </div><!-- /.nv-search-result-item -->

                            <div class="nv-search-result-item">
                                <p class="sri-posted">Updated: 2h ago</p>
                                <div class="sri-access"><span class="lock">Limited Access</span></div>
                                <div class="sri-content">
                                    <div class="sri-top-bar">
                                        <p class="sri-source"><span>EBMT 2018</span><a href="#">Nurse Handbook</a></p>
                                    </div><!-- /.sri-top-bar -->
                                    <div class="sri-mid-portion">
                                        <div class="sri-title-n-author">
                                            <h3><a title="pres.title">Improving Immunosuppressive Treatment for Patients With Severe Aplastic Anemia</a></h3>
                                            <p class="sri-author">
                                                <figure style="background-image: url('../../assets/avatar-dummy.jpg')"></figure>
                                                <span>Sian McPharson</span>
                                            </p>
                                        </div><!-- /.sri-title-n-author -->
                                        <div class="sri-purchase">
                                            <button class="nv-btn hollow medium neutral-accent">1&euro; - Purchase this item</button>
                                        </div><!-- /.sri-purchase -->
                                    </div><!-- /.sri-mid-portion -->
                                    <div class="sri-bottom-line"></div><!-- /.sri-bottom-line -->
                                </div><!-- /.sri-content -->
                                <a class="sri-bookmark" title="bookmark this presentation"></a>
                            </div><!-- /.nv-search-result-item -->

                            <div class="nv-thorough-pagination">
                                <div class="tp-go-to-page">
                                    Go to:  <input type="text" value="1"> of 25
                                </div><!-- /.tp-go-to-page -->
                                <div class="tp-pages">
                                    <a class="first">First</a>
                                    <a>Previous</a>
                                    <span>1-10 of 240</span>
                                    <a>Next</a>
                                    <a class="last">Last</a>
                                </div><!-- /.tp-pages -->
                            </div><!-- /.nv-thorough-pagination -->
                        </div><!-- /.nv-search-results -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </div><!-- /.nv-section -->



    <!-- Search term results -->
    <div class="nv-section nv-search-page-section guttered">
        <div class="nv-port">
            <div class="nv-row large-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-search-head">
                            <h1>Search The Hub</h1>
                            <form class="nv-search-bar">
                                <div class="nv-search-page-input input-like">
                                    <input type="text" placeholder="Search...">
                                    <span aria-hidden="true" class="nspi-focus-visual"></span>
                                    <div class="nv-skinless-select">
                                        <select>
                                            <option>in titles, content and speakers</option>
                                            <option>in something else</option>
                                            <option>in another section</option>
                                        </select>
                                    </div><!-- /.nv-skinless-select -->
                                    <button type="submit" class="nv-search-page-btn"></button>
                                </div><!-- /.nv-search-page-input -->
                                <div class="nv-search-filter-select input-like">
                                    <label>Type:</label>
                                    <div class="nv-skinless-select">
                                        <select>
                                            <option>All Types</option>
                                            <option>Some Types</option>
                                            <option>Presentations</option>
                                            <option>With Webcast</option>
                                        </select>
                                    </div><!-- /.nv-skinless-select -->
                                </div><!-- /.nv-search-filter-select input-like -->
                                <div class="nv-filter-trigger input-like">
                                    <a>Filters</a>
                                </div><!-- /.nv-filter-trigger input-like -->

                                <div class="nv-search-bar-plate not-shown">
                                    <div class="sbp-content">
                                        <h3>Add Filters</h3>
                                        <div class="sbp-filters">
                                            <div class="sbpf-column">
                                                <h4>Sources</h4>
                                                <ul>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb1">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            EBMT 2019
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb1">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            EBMT 2018
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb1">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            EBMT 2017
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div><!-- /.sbpf-column -->

                                            <div class="sbpf-column">
                                                <h4>Article Type</h4>
                                                <ul>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb2">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            Any
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb2">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            PDF
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb2">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            Webcast
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb2">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            Abstract
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div><!-- /.sbpf-column -->

                                            <div class="sbpf-column">
                                                <h4>Availability</h4>
                                                <ul>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb2">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            Free access
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb2">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            Limited access
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div><!-- /.sbpf-column -->

                                            <div class="sbpf-column">
                                                <h4>My Selection</h4>
                                                <ul>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb2">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            Liked
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb2">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            Timeline
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div><!-- /.sbpf-column -->

                                            <div class="sbpf-column">
                                                <h4>My History</h4>
                                                <ul>
                                                    <li>
                                                        <label class="nv-w-cbox">
                                                            <input type="checkbox" name="cb2">
                                                            <span class="cb-vis" aria-hidden="true"></span>
                                                            All Accessed Articles
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div><!-- /.sbpf-column -->
                                        </div><!-- /.sbp-filters -->
                                    </div><!-- /.sbp-content -->
                                    <div class="sbp-tool-bar">
                                        <div class="sbp-filter-tool">
                                            <p class="clear-all-filters"><a>Clear all filters</a></p>
                                            <div class="sft-btns">
                                                <button class="nv-btn hollow medium neutral-accent">Cancel</button>
                                                <button class="nv-btn filled medium accent1">Apply Filters</button>
                                            </div><!-- /.sft-btns -->
                                        </div><!-- /.sbp-filter-tool -->
                                    </div><!-- /.sbp-tool-bar -->
                                </div><!-- /.nv-search-bar-plate filters -->
                            </form><!-- /.nv-search-bar -->
                        </div><!-- /.nv-search-head -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </div><!-- /.nv-section -->

    <section class="nv-section nv-search-page-section guttered">
        <div class="nv-port">
            <div class="nv-row large-spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-search-results">
                            <div class="nv-search-preamble">
                                <div class="sp-logos">
                                    <a class="sp-logo ok">
                                        <span style="background-image: url('../../assets/logo-event-ctd.png')"></span>
                                    </a><!-- /.sp-logo -->
                                    <a class="sp-logo ok">
                                        <span style="background-image: url('../../assets/logo-event-aibd.png')"></span>
                                    </a><!-- /.sp-logo -->
                                </div><!-- /.sp-logos -->
                                <div class="sp-notes">
                                    <p class="spn-semiopaque">Did you find this search useful? &nbsp; <a>Yes</a> - <a>No</a></p>
                                </div><!-- /.sp-notes -->
                            </div><!-- /.nv-search-preamble -->
                            <div class="sr-top-bar">
                                <div class="sr-top-tools">
                                    <p class="sri-result-number">48 results for</p>
                                    <h2><strong>‘HSCT Nursing’</strong></h2>
                                    <div class="sri-applied-filters">
                                        <p>Applied Filters:</p>
                                        <span>
                                            Article Type: <strong>Any</strong>
                                            <a title="remove filter"></a>
                                        </span>
                                        <span>
                                            Permission: <strong>Limited Access</strong>
                                            <a title="remove filter"></a>
                                        </span>
                                        <a class="sri-remove-filters">Clear filters</a>
                                    </div><!-- /.sri-applied-filters -->
                                </div><!-- /.sr-top-tools -->
                                <div class="sr-order">
                                    <label>Sort by:</label>
                                    <div class="nv-skinless-select">
                                        <select>
                                            <option>Newest</option>
                                            <option>Oldest</option>
                                            <option>Most popular</option>
                                            <option>Time added</option>
                                        </select>
                                    </div><!-- /.nv-skinless-select -->
                                </div><!-- /.sr-order -->
                            </div><!-- /.sr-top-bar -->

                            <div class="nv-search-result-item">
                                <p class="sri-posted">Updated: 2h ago</p>
                                <div class="sri-access"><span class="lock">Limited Access</span></div>
                                <div class="sri-content">
                                    <div class="sri-top-bar">
                                        <p class="sri-source"><span>EBMT 2018</span><a href="#">Nurse Handbook</a></p>
                                    </div><!-- /.sri-top-bar -->
                                    <div class="sri-mid-portion">
                                        <div class="sri-title-n-author">
                                            <h3><a title="pres.title">Haematopoietic stem cell transplantation (HSCT): How Does It Work?</a></h3>
                                            <p class="sri-author">
                                                <figure style="background-image: url('../../assets/patrick-patrick-stahl.jpg')"></figure>
                                                <span>Markus Smith</span>
                                            </p>
                                            <div class="sri-features">
                                                <a>Abstract</a>
                                                <a>PDF</a>
                                                <a>Webcast</a>
                                            </div><!-- /.sri-features -->
                                        </div><!-- /.sri-title-n-author -->
                                        <div class="sri-purchase">
                                            <button class="nv-btn hollow medium neutral-accent">1&euro; - Purchase this item</button>
                                        </div><!-- /.sri-purchase -->
                                    </div><!-- /.sri-mid-portion -->
                                    <div class="sri-bottom-line">
                                        <p class="sri-recommendation">Recommendation is based on what you already read.&nbsp;&nbsp;<a href="#">Not relevant?</a></p>
                                    </div><!-- /.sri-bottom-line -->
                                </div><!-- /.sri-content -->
                                <a class="sri-bookmark" title="bookmark this presentation"></a>
                            </div><!-- /.nv-search-result-item -->

                            <div class="nv-search-result-item">
                                <p class="sri-posted">Updated: 2h ago</p>
                                <div class="sri-access"><span class="lock">Limited Access</span></div>
                                <div class="sri-content">
                                    <div class="sri-top-bar">
                                        <p class="sri-source"><span>EBMT 2018</span><a href="#">Nurse Handbook</a></p>
                                    </div><!-- /.sri-top-bar -->
                                    <div class="sri-mid-portion">
                                        <div class="sri-title-n-author">
                                            <h3><a title="pres.title">Nursing Research and Audit in the Transplant Setting</a></h3>
                                            <p class="sri-author">
                                                <figure style="background-image: url('../../assets/avatar-dummy.jpg')"></figure>
                                                <span>Sian McPharson</span>
                                            </p>
                                        </div><!-- /.sri-title-n-author -->
                                        <div class="sri-purchase">
                                            <button class="nv-btn hollow medium neutral-accent">1&euro; - Purchase this item</button>
                                        </div><!-- /.sri-purchase -->
                                    </div><!-- /.sri-mid-portion -->
                                    <div class="sri-bottom-line">
                                        <p class="sri-recommendation">Recommendation is based on what you already read.&nbsp;&nbsp;<a href="#">Not relevant?</a></p>
                                    </div><!-- /.sri-bottom-line -->
                                </div><!-- /.sri-content -->
                                <a class="sri-bookmark" title="bookmark this presentation"></a>
                            </div><!-- /.nv-search-result-item -->

                            <div class="nv-search-result-item">
                                <p class="sri-posted">Updated: 2h ago</p>
                                <div class="sri-access"><span class="full">Full Access</span></div>
                                <div class="sri-content">
                                    <div class="sri-top-bar">
                                        <p class="sri-source"><span>EBMT 2018</span><a href="#">Nurse Handbook</a></p>
                                    </div><!-- /.sri-top-bar -->
                                    <div class="sri-mid-portion">
                                        <div class="sri-title-n-author">
                                            <h3><a title="pres.title">Early and Acute Complications and the Principles of <span class="nv-highlight">HSCT Nursing</span> Care</a></h3>
                                            <p class="sri-author">
                                                <figure style="background-image: url('../../assets/patrick-patrick-stahl.jpg')"></figure>
                                                <span>Markus Smith</span>
                                            </p>
                                            <div class="sri-features">
                                                <a>Abstract</a>
                                                <a>PDF</a>
                                                <a>Webcast</a>
                                            </div><!-- /.sri-features -->
                                        </div><!-- /.sri-title-n-author -->
                                        <div class="sri-purchase">
                                            <button class="nv-btn hollow medium neutral-accent">1&euro; - Purchase this item</button>
                                        </div><!-- /.sri-purchase -->
                                    </div><!-- /.sri-mid-portion -->
                                    <div class="sri-bottom-line"></div><!-- /.sri-bottom-line -->
                                </div><!-- /.sri-content -->
                                <a class="sri-bookmark" title="bookmark this presentation"></a>
                            </div><!-- /.nv-search-result-item -->

                            <div class="nv-thorough-pagination">
                                <div class="tp-go-to-page">
                                    Go to:  <input type="text" value="1"> of 25
                                </div><!-- /.tp-go-to-page -->
                                <div class="tp-pages">
                                    <a class="first">First</a>
                                    <a>Previous</a>
                                    <span>1-10 of 240</span>
                                    <a>Next</a>
                                    <a class="last">Last</a>
                                </div><!-- /.tp-pages -->
                            </div><!-- /.nv-thorough-pagination -->
                        </div><!-- /.nv-search-results -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->


    <div class="nv-section guttered banner-bar">
        <div class="nv-port">
            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-8">
                    <div class="nv-column-content">
                        <div class="nv-bar-banner">
                            <a><img src="../../assets/search-sample-banner.jpg" alt="sample banner"></a>
                        </div><!-- /.nv-bar-banner -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </div><!-- /.nv-section -->

    <div class="nv-section guttered nv-search-page-section single-view single-recommended">
        <div class="nv-port">
            <div class="nv-row spaced flow-center">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-recommended-chunk">
                            <h4>You may also like</h4>
                            <div class="rc-dual">
                                <div class="rcd-item">
                                    <h3>Physiology of Blood Purification: Dialysis &amp; Apheresis</h3>
                                    <p>
                                        <figure style="background-image: url('../../assets/patrick-patrick-stahl.jpg');"></figure>
                                        <span>Carlo Basile</span>
                                    </p>
                                </div><!-- /.rcd-item -->

                                <div class="rcd-item">
                                    <h3>Apheresis Education: One Center Curriculum Design Experience</h3>
                                    <p>
                                        <figure style="background-image: url('../../assets/avatar-dummy.jpg');"></figure>
                                        <span>Sian Wordwick</span>
                                    </p>
                                </div><!-- /.rcd-item -->
                            </div><!-- /.rc-dual -->
                        </div><!-- /.nv-recommended-chunk -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </div><!-- /.nv-section -->

    <div class="nv-section guttered nv-search-page-section single-view">
        <div class="nv-port">
            <div class="nv-row large-spaced">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-single-presentation-preamble">
                            <p class="spp-source">
                                <span>EBMT 2018</span><a href="#">Nurse Handbook</a>
                            </p><!-- /.spp-source -->
                            <h1>Apheresis education and certification for nurses</h1>

                            <div class="nv-preamble-meta">
                                <div class="pm-item">
                                    <figure style="background-image: url('../../assets/avatar-dummy.jpg');"></figure>
                                    <p>by Rosaleen Nemec</p>
                                </div><!-- /.pm-item -->
                                <div class="pm-item">
                                    <a class="nv-ask-question-cta"></a>
                                    <p>Ask a Question</p>
                                </div><!-- /.pm-item -->
                            </div><!-- /.nv-preamble-meta -->
                        </div><!-- /.nv-single-presentation-preamble -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </div><!-- /.nv-section -->

    <section class="nv-section guttered nv-search-page-section single-view">
        <div class="nv-port">
            <div class="nv-row small-spaced-top large-spaced-bottom gap-30">
                <main class="nv-column desk-8">
                    <div class="nv-column-content">
                        <div class="nv-single-presentation-content">
                            <div class="spc-tools">
                                <a class="bookmark" title="bookmark this presentation"></a>
                                <a class="share" title="share this presentation"></a>
                                <a class="more" title="more about this presentation"></a>
                            </div><!-- /.spc-tools -->

                            <div class="nv-available-to-purchase">
                                <h4>Materials are available to purchase</h4>
                                <a href="#" class="atp-item">
                                    <span>PDF</span>
                                    Apheresis education and certification for nurses
                                </a>
                                <a href="#" class="atp-item">
                                    <span>MP4</span>
                                    Transfusion Medicine and Cell Therapy in speciality societies
                                </a>
                                <div class="ni-btn">
                                    <div class="nv-btn filled medium accent1">1&euro;  - Purchase this item</div>
                                </div><!-- /.ni-btn -->
                            </div><!-- /.nv-available-to-purchase -->
                        </div><!-- /.nv-single-presentation-content -->

                        <div class="spc-txt">
                            <h5 class="nv-content-type">Abstract</h5>
                            <h3>Introduction:</h3>
                            <p>Hematopoietic stem cell transplantation requires a wide-range of expertise and procedural competence, in which nurses play important roles throughout, including stem cell mobilization and collection by apheresis. Little is published about post-licensure nursing education in apheresis, which suggests that it proceeds at the discretion of individual institutions, supplemented with practical training by equipment manufacturers. Information can be obtained on a small number of apheresis training courses for nurses in Australia, Canada, Indonesia, Italy, The Netherlands, Sweden and Turkey, and on nurse certification systems in Turkey and the United States.</p>
                            <p>There seems to be no certification officially linked to institutional accreditation or medical insurance reimbursement related to apheresis, except in Turkey. Because apheresis is associated with various adverse events, including citrate toxicity and vasovagal reactions, the Japan Society of Transfusion Medicine and Cell Therapy, in cooperation with 3 other speciality societies, started a "Qualified Apheresis Nurse" certification in 2010, when the Japan Marrow Donor Program officially added circulating stem cell collection to bone marrow harvest from unrelated donors as a source of hematopoietic stem cells. Questionnaire surveys, collected when nurses must renew or surrender their 5-year certification, show that our system matches nurses' learning desire and can be an objective and motive of their learning, thus leading to safer and more effective apheresis practice. We dare to imagine that an internationally standardized curriculum might emerge, to which we would contribute, and from which we would learn.</p>
                        </div><!-- /.spc-txt -->
                  </div><!-- /.nv-column-content -->
                </main><!-- /.nv-column -->

                <aside class="nv-column desk-4">
                    <div class="nv-column-content">
                        <div class="nv-side-meta">
                            <div class="sm-item">
                                <span>Latest Update:</span>
                                <p>September 2018</p>
                            </div><!-- /.sm-item -->

                            <div class="sm-item">
                                <span>Category:</span>
                                <p><a>Transfusion</a>, <a>Apheresis</a>, <a>Science</a></p>
                            </div><!-- /.sm-item -->
                        </div><!-- /.nv-side-meta -->

                        <div class="side-banner" role="banner">
                            <a><img src="../../assets/side-sponsors-banner.png" alt="whats on banner"></a>
                        </div><!-- /.side-banner -->

                        <div class="nv-tag-cloud">
                            <span>Select an area of interest:</span>
                            <div class="tc-port">
                                <a>Transfusion</a>
                                <a>Apheresis Science</a>
                                <a>Nursing Education</a>
                                <a>Nursing Practice</a>
                                <a>Nursing Administration</a>
                            </div><!-- /.tc-port -->
                        </div><!-- /.nv-tag-cloud -->
                    </div><!-- /.nv-column-content -->
                </aside><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->

    <div class="nv-section guttered nv-search-page-section single-view">
        <div class="nv-port">
            <div class="nv-row large-spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-recommendation-big">
                            <h4>You May Also Like</h4>
                            <div class="nv-row gap-20">
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <div class="nv-row-search-result-items by3">
                                            <div class="nv-row-sr-item">
                                                <div class="nv-search-result-item">
                                                    <div class="sri-access"><span class="lock">Limited Access</span></div>
                                                    <div class="sri-content">
                                                        <div class="sri-top-bar">
                                                            <p class="sri-source"><span>EBMT 2018</span><a href="#">Nurse Handbook</a></p>
                                                        </div><!-- /.sri-top-bar -->
                                                        <div class="sri-mid-portion">
                                                            <div class="sri-title-n-author">
                                                                <h3><a title="pres.title">Nursing Research and Audit in the Transplant Setting</a></h3>
                                                                <p class="sri-author">
                                                                    <figure style="background-image: url('../../assets/avatar-dummy.jpg')"></figure>
                                                                    <span>Sian McPharson</span>
                                                                </p>
                                                                <div class="sri-features">
                                                                    <a>Abstract</a>
                                                                    <a>PDF</a>
                                                                    <a>Webcast</a>
                                                                </div>
                                                            </div><!-- /.sri-title-n-author -->
                                                        </div><!-- /.sri-mid-portion -->
                                                    </div><!-- /.sri-content -->
                                                </div><!-- /.nv-search-result-item -->
                                            </div><!-- /.nv-row-sr-item -->

                                            <div class="nv-row-sr-item">
                                                <div class="nv-search-result-item">
                                                    <div class="sri-access"><span class="lock">Limited Access</span></div>
                                                    <div class="sri-content">
                                                        <div class="sri-top-bar">
                                                            <p class="sri-source"><span>EBMT 2018</span><a href="#">Nurse Handbook</a></p>
                                                        </div><!-- /.sri-top-bar -->
                                                        <div class="sri-mid-portion">
                                                            <div class="sri-title-n-author">
                                                                <h3><a title="pres.title">Lorem ipsum dolor sit amet, consectetur</a></h3>
                                                                <p class="sri-author">
                                                                    <figure style="background-image: url('../../assets/patrick-patrick-stahl.jpg')"></figure>
                                                                    <span>Boris McDouglov</span>
                                                                </p>
                                                                <div class="sri-features">
                                                                    <a>Abstract</a>
                                                                    <a>Webcast</a>
                                                                </div>
                                                            </div><!-- /.sri-title-n-author -->
                                                        </div><!-- /.sri-mid-portion -->
                                                    </div><!-- /.sri-content -->
                                                </div><!-- /.nv-search-result-item -->
                                            </div><!-- /.nv-row-sr-item -->

                                            <div class="nv-row-sr-item">
                                                <div class="nv-search-result-item">
                                                    <div class="sri-access"><span class="lock">Limited Access</span></div>
                                                    <div class="sri-content">
                                                        <div class="sri-top-bar">
                                                            <p class="sri-source"><span>EBMT 2018</span><a href="#">Nurse Handbook</a></p>
                                                        </div><!-- /.sri-top-bar -->
                                                        <div class="sri-mid-portion">
                                                            <div class="sri-title-n-author">
                                                                <h3><a title="pres.title">Nursing Research and Audit in the Transplant Setting</a></h3>
                                                                <p class="sri-author">
                                                                    <figure style="background-image: url('../../assets/avatar-dummy.jpg')"></figure>
                                                                    <span>Predrag Sarapa</span>
                                                                </p>
                                                            </div><!-- /.sri-title-n-author -->
                                                        </div><!-- /.sri-mid-portion -->
                                                    </div><!-- /.sri-content -->
                                                </div><!-- /.nv-search-result-item -->
                                            </div><!-- /.nv-row-sr-item -->
                                        </div><!-- /.nv-row-search-result-items -->
                                        <div class="rb-btn">
                                            <button class="nv-btn filled medium accent1">Load More</button>
                                        </div><!-- /.rb-btn -->
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                            </div><!-- /.nv-row -->
                        </div><!-- /.nv-recommendation-big -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </div><!-- /.nv-section -->

</div><!-- /.nv-page-wrap -->




