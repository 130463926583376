export class Page {
  id: number;
  parent_id: number;
  type: string;
  title: string;
  slug: string;
  access: string;
  components: any[];
  config: string;
  content: string;
  external: any;
  landing: boolean;
  modules: any;
  order: number;
  visible: boolean;
  portal_id: number;

  pages: Page[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
