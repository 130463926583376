<ng-container *ngIf="currentStream?.currentPresentation && currentStream.status !== 'offair' && currentStream.url">
  <ng-container *ngIf="hasAccess">
    <nv-video
        style="width: 100%; height: 100%;"
        [conferenceId]="conferenceId"
        [src]="currentStream.url">
    </nv-video>    
  </ng-container>
  <ng-container *ngIf="!hasAccess">
    <div class="nv-live-stage-msg">
      <div class="nv-ls-msg">
        <h4>No Access</h4>
        <p>Based on a current access rights, you don't have a permission to watch this content. Hit the button to get the access now.</p>
        <p>
          <a (click)="requestAccess()" class="nv-btn small primary">GET ACCESS</a>
        </p>
      </div>
    </div>
  </ng-container>    
</ng-container>
<ng-container *ngIf="!currentStream?.currentPresentation || currentStream.status === 'offair' && !currentStream.url">
  <ng-container *ngIf="currentStream?.offAir">
    <div class="nv-live-stage-msg off-air">
      <div class="nv-ls-msg">
        <h4>Off Air</h4>
        <h5>{{ currentStream.offAir.title }}</h5>
        <p>{{ currentStream.offAir.description }}</p>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!currentStream?.offAir">
    <div class="nv-live-stage-msg off-air">
      <div class="nv-ls-msg">
        <h4>Off Air</h4>
      </div>
    </div>
  </ng-container>
</ng-container> 