<div [ngClass]="{
        'nv-checked': value,
        'nv-disabled': disabled
        }"
        class="nv-checkbox-item">
    <label>
        <span class="nci-check-port">
            <input
                nvInputDirective
                type="checkbox"
                (change)="onChangeStatus($event);"
                [name]="name"
                [checked]="value"
                [disabled]="disabled">
            <span class="nci-check-visual"></span>
        </span><!-- /.nci-check-port -->
        <span class="nci-value">{{ label }}</span>
    </label>
</div>
