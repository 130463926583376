import { Component } from '@angular/core';

@Component({
    selector: 'app-billing-page',
    templateUrl: './billing.component.html'
})
export class BillingComponent {

    constructor() { }

}
