<div class="nv-config-control-bar">
    <div class="cluf-port">
        <div class="config-title-n-tools">
            <div class="tnt-left">
                <h1 translate>Certificate</h1>
            </div><!-- /.tnt-left -->
            <div class="tnt-right">
                <div class="nv-btn-box flow-end gap-10">
                    <a (click)="delete()" [hidden]="!certificateId" translate class="nv-btn secondary medium">Delete</a>
                    <nv-button (click)="close()" color="passive" translate>Close</nv-button>
                    <nv-button (click)="save()" [loading]="savingCertificate"
                               [disabled]="certificateForm.invalid" translate>Save
                    </nv-button>
                </div><!-- /.nv-btn-box flow-end gap-6 -->
            </div><!-- /.tnt-right -->
        </div><!-- /.config-title-n-tools -->
    </div>
    <div class="nv-port spaced-bottom">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav vertical-break-tab">
                <ul>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'delegates']">Attendees</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'ticket-types']">Ticketing</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'registration-settings']">Registration Settings</a>
                    </li>
                    <li>
                        <a class="active">CME Certificates</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
<div class="nv-config-content-port">
    <div class="nv-global-loading on" [class.on]="loadingCertificate">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24 small-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form [formGroup]="certificateForm"
                              class="nv-row config-plates clean gap-24 small-spaced-top spaced-bottom">
                            <div class="nv-column desk-6 tab-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Basic Information</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                    formControlName="title"
                                                    label="Title"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-select
                                                    [options]="sendToOptions"
                                                    formControlName="send_to"
                                                    label="Recipients"
                                                    required
                                                ></nv-select>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-select
                                                    [options]="templates"
                                                    formControlName="template_id"
                                                    label="Template"
                                                    required
                                                ></nv-select>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-9">
                                            <div class="nv-column-content">
                                                <nv-input
                                                    type="number"
                                                    formControlName="threshold"
                                                    label="Minimum required {{certificate?.unit_name}} to receive the certificate"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-9">
                                            <div class="nv-column-content">
                                                <nv-input
                                                    formControlName="unit_name"
                                                    label="Calculation unit"
                                                ></nv-input>
                                                <small>How do you call your unit for evaluating participants (eg. points, hours, candies...)</small>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-9">
                                            <div class="nv-column-content">
                                                <nv-switch
                                                        label="Activate download of certificates"
                                                        formControlName="active"
                                                ></nv-switch>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-6 tab-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
