<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Article</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-10">
                <a (click)="delete()" [hidden]="!articleId" class="nv-btn secondary medium" translate>Delete</a>
                <nv-button (click)="close()" color="passive" translate>Close</nv-button>
                <nv-button (click)="save()" [loading]="savingArticle" [disabled]="articleForm.invalid" translate>Save</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div>
</div>
<div class="nv-config-content-port">
    <div class="nv-global-loading on" [class.on]="loadingArticle">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24 small-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form [formGroup]="articleForm" class="nv-row config-plates clean gap-24 small-spaced-top spaced-bottom">
                            <div class="nv-column desk-6 tab-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Article Information</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="title"
                                                        label="Title"
                                                        required
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-text-editor
                                                        formControlName="content"
                                                        label="Content"
                                                        [organizationId]="organizationId"
                                                >
                                                </nv-text-editor>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        type="textarea"
                                                        formControlName="lead"
                                                        label="Intro text">
                                                </nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-6 tab-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Image</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-file
                                                        [organizationId]="organizationId"
                                                        [mime]="['image/png','image/jpg','image/jpeg']"
                                                        formControlName="image_url"
                                                        label="Featured image"
                                                ></nv-file>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Options</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-datepicker
                                                        formControlName="published_at"
                                                        label="Date to be published"
                                                        required
                                                ></nv-datepicker>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12" *ngIf="conferences.length > 1">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        [options]="conferences"
                                                        [multi]="true"
                                                        formControlName="conference_ids"
                                                        label="Events"
                                                ></nv-select>
                                                <small translate>&nbsp;News will be displayed on apps and websites on selected events</small>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        [options]="statusOptions"
                                                        formControlName="status"
                                                        label="Status"
                                                ></nv-select>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
