<div class="nv-row config-plates clean gap-30 spaced-top propagate">
    <div class="nv-column desk-12">
        <div class="nv-column-content" (click)="showCharts()">
            <h4 class="nv-config-chunk-toggler" translate [class.on]="showStatistics">Attendee Stats</h4> <!-- < toggle class 'on' -->
        </div>
    </div>
</div><!-- /.nv-row -->
<div class="nv-row config-plates clean semi-spaced-bottom"
     *ngIf="showStatistics">
    <!-- < toggle style 'display' (flex / none) -->
    <div class="nv-column desk-12 no-propagate">
        <div class="nv-column-content">
            <div class="nv-row config-plates clean gap-50 propagate">
                    <div class="nv-column desk-6 tab-12">
                        <div class="nv-column-content">
                            <ng-container *ngIf="!!userId; else noAttachedUserAttendance">
                                <nv-live-attendance [templateForTooltip]="tooltipTemplate"></nv-live-attendance>
                            </ng-container>
                            <ng-template #noAttachedUserAttendance>
                                <div class="nv-attendee-stats-shown">
                                    <h4 class="medium">Live Attendance</h4>
                                </div>
                                <div class="nv-attendee-stats-block">
                                    This attendee didn't create an account on the platform, virtual attendance doesn't exists
                                </div>
                            </ng-template>
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->

                <div class="nv-column desk-6 tab-12">
                    <div class="nv-column-content">
                        <ng-container *ngIf="!!userId; else noAttachedUserOnDemand">
                            <nv-on-demand-attendance [hasUser]="!!userId" [templateForTooltip]="tooltipTemplate"></nv-on-demand-attendance>
                        </ng-container>
                        <ng-template #noAttachedUserOnDemand>
                            <div class="nv-attendee-stats-shown">
                                <h4 class="medium">On-demand Attendance</h4>
                            </div>
                            <div class="nv-attendee-stats-block">
                                This attendee didn't create an account on the platform, virtual attendance doesn't exists
                            </div>
                        </ng-template>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->

                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <nv-on-site-attendance [templateForTooltip]="tooltipTemplate"></nv-on-site-attendance>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div>
    </div>
</div><!-- /.nv-row -->

<ng-template #tooltipTemplate let-model="model">
    <div>
        {{ model.name }}
    </div>
    <div>
        {{ model.value | timeFormat }}
    </div>
</ng-template>





