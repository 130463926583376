import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalButton } from '../classes/modal-button';

@Component({
  selector: 'nv-modal',
  templateUrl: './modal.component.html'
})

export class NavusModalComponent implements OnInit {
  @Input() title: string = '';
  @Input() buttons: ModalButton[];
  @Input() showTitle: boolean = true;
  @Input() size: string = 'small';
  @Input() body: string;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key && event.key.toLowerCase() === 'enter') {
      this.onClick(this.buttons[this.buttons.length - 1]);
      event.preventDefault();
      return false;
    }
  }

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    if (this.title.length <= 0 || !this.title) {
      this.showTitle = false;
    }

    if (!this.buttons) {
      this.buttons = [
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ];
    }
  }

  close() {
    this.activeModal.dismiss();
  }

  onClick(button) {
    if (button.role === 'cancel') {
      this.activeModal.dismiss();
    }
    if (button.handler) {
      button.handler();
      this.activeModal.close();
    }
  }
}
