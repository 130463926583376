import {Block} from './block';
import {Material} from './material';
import {Voting} from './voting';

export class Presentation {
    access: boolean;
    allow_comments: boolean;
    allow_feedback: boolean;
    author_list: any;
    available: boolean;
    available_audio_material: boolean;
    available_external: boolean;
    available_html_material: boolean;
    available_main_material: boolean;
    available_summary_material: boolean;
    available_supporting_material: boolean;
    available_webcast_material: boolean;
    block: Block;
    block_id: number;
    block_name: string;
    code: any;
    conference_id: number;
    consent_completed: boolean;
    course: any;
    description: string;
    disclaimer: boolean;
    downloadable: boolean;
    ends_at: string;
    external_id: any;
    external_url: string;
    free: boolean;
    has_audio_material: boolean;
    has_excluded_slides: boolean;
    has_html_material: boolean;
    has_main_material: boolean;
    has_materials: boolean;
    has_summary_material: boolean;
    has_supporting_material: boolean;
    has_voting: boolean;
    has_webcast_material: boolean;
    id: number;
    imported_id: string;
    materials: Material[];
    popularity: number;
    // references: []
    section: string;
    speaker_image: string;
    speaker_image_thumb: string;
    speaker_name: string;
    speakers: any;
    sponsored: boolean;
    starts_at: string;
    title: string;
    type: string;
    updated_at: string;
    vote_allowed: boolean;
    vote_available: boolean;
    vote_count: number;
    watermark: boolean;
    timeline: boolean;
    votings?: Voting[];
    free_formatted: string;
    sponsored_formatted: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
        if (values['free'] === true) {
            this.free_formatted = 'True';
        } else {
            this.free_formatted = '';
        }
        if (values['sponsored'] === true) {
            this.sponsored_formatted = 'True';
        } else {
            this.sponsored_formatted = '';
        }
    }
}
