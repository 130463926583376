import { Component } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';


@Component({
    selector: 'nv-toast',
    templateUrl: './toast.component.html'
})

export class NavusToastComponent extends Toast {

    constructor(
        protected toastrService: ToastrService,
        public toastPackage: ToastPackage
    ) {
        super(toastrService, toastPackage);
    }

    action(event: Event) {
        event.stopPropagation();
        this.toastPackage.triggerAction();
        return false;
    }

}
