import { Routes } from '@angular/router';
import { OrganizationGuard } from '../../guards/organization.guard';
import { EventMakerSupportFormPageComponent } from './pages/event-maker-support-form/event-maker-support-form-page.component';
import { AttendanceTrackingFormPageComponent } from './pages/attendance-tracking-form/attendance-tracking-form-page.component';
import { ConsentApplicationFormPageComponent } from './pages/consent-application-form/consent-application-form-page.component';
import { EPostersFormPageComponent } from './pages/e-posters-form/e-posters-form-page.component';
import { MobileAppHubFormPageComponent } from './pages/mobile-app-hub-form/mobile-app-hub-form-page.component';
import { WebHubFormPageComponent } from './pages/web-hub-form/web-hub-form-page.component';
import { TicketSalesPageComponent } from './pages/ticket-sales/ticket-sales-page.component';

export const ContactRoutes: Routes = [
  {
    path: 'event-maker-support',
    component: EventMakerSupportFormPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Contact request'
    }
  },
  {
    path: 'attendance-tracking',
    component: AttendanceTrackingFormPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Contact request'
    }
  },
  {
    path: 'consent-application',
    component: ConsentApplicationFormPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Contact request'
    }
  },
  {
    path: 'e-posters',
    component: EPostersFormPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Contact request'
    }
  },
  {
    path: 'mobile-app-hub',
    component: MobileAppHubFormPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Contact request'
    }
  },
  {
    path: 'web-hub',
    component: WebHubFormPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Contact request'
    }
  },
  {
    path: 'ticket-sales',
    component: TicketSalesPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Contact request'
    }
  }
];
