<div class="nv-modal medium">
    <div class="nv-modal-header">
        <h4 class="nv-modal-title">Video Player Preset</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body">
        <div class="nv-global-loading on" [class.on]="loadingPreset">
            <div class="nv-spinner"></div>
        </div><!-- /.nv-global-loading -->
        <div class="nv-row gap-20 propagate">
            <form class="nv-column desk-12" [formGroup]="presetForm">
                <div class="nv-column-content">
                    <div class="nv-row config-plates clean gap-24 propagate">
                        <div class="nv-column desk-12 tab-12">
                            <div class="nv-column-content">
                                <nv-input
                                        label="Name"
                                        formControlName="name"
                                ></nv-input>
                            </div>
                        </div>
                        <div class="nv-column desk-12 tab-12">
                            <div class="nv-column-content">
                                <nv-switch
                                        label="Default"
                                        formControlName="default"
                                ></nv-switch>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="nv-row">
            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <div class="nv-row gap-20 propagate">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <strong>Options</strong>
                            </div>
                        </div>
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <nv-checkbox
                                        label="Autoplay"
                                        [(ngModel)]="autoplay"
                                ></nv-checkbox>
                            </div>
                        </div>
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <nv-checkbox
                                        label="Mute"
                                        [(ngModel)]="mute"
                                ></nv-checkbox>
                            </div>
                        </div>
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <nv-checkbox
                                        label="Loop"
                                        [(ngModel)]="loop"
                                ></nv-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <div class="nv-row gap-20 propagate">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <strong>Navigation</strong>
                            </div>
                        </div>
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <nv-checkbox
                                        label="Show"
                                        [(ngModel)]="nav.show"
                                ></nv-checkbox>
                                <small>Whether it should show the playbar or not</small>
                            </div>
                        </div>
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <nv-checkbox
                                        label="Seek"
                                        [(ngModel)]="nav.seek"
                                ></nv-checkbox>
                                <small>Allow seeking through the video</small>
                            </div>
                        </div>
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <nv-checkbox
                                        label="Volume"
                                        [(ngModel)]="nav.volume"
                                ></nv-checkbox>
                                <small>Show or hide the volume control</small>
                            </div>
                        </div>
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <nv-checkbox
                                        label="Quality"
                                        [(ngModel)]="nav.quality"
                                ></nv-checkbox>
                                <small>Show the icon for switching the quality of the source</small>
                            </div>
                        </div>
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <nv-checkbox
                                        label="CC"
                                        [(ngModel)]="nav.cc"
                                ></nv-checkbox>
                                <small>Show the icon for displaying subtitles</small>
                            </div>
                        </div>
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <nv-checkbox
                                        label="Full screen"
                                        [(ngModel)]="nav.full_screen"
                                ></nv-checkbox>
                                <small>Show the icon for going into full screen</small>
                            </div>
                        </div>
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <nv-checkbox
                                        label="Picture-in-picture"
                                        [(ngModel)]="nav.pip"
                                ></nv-checkbox>
                                <small>Show the icon for going into Picture-in-picture mode</small>
                            </div>
                        </div>
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <nv-checkbox
                                        label="Time range"
                                        [(ngModel)]="nav.time_range"
                                ></nv-checkbox>
                                <small>Show the time range</small>
                            </div>
                        </div>
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <nv-checkbox
                                        label="Time"
                                        [(ngModel)]="nav.time"
                                ></nv-checkbox>
                                <small>Show the time</small>
                            </div>
                        </div>
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <nv-checkbox
                                        label="Duration"
                                        [(ngModel)]="nav.duration"
                                ></nv-checkbox>
                                <small>Show the duration</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-modal-footer">
        <div class="nv-row">
            <div class="nv-column desk-6">
                <div class="nv-column-content">
                    <div class="nv-btn-box flow-end">
                        <nv-button size="small" (click)="savePreset()">Save</nv-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

