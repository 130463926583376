<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Campaign</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-8">
                <nv-button color="accent1" [loading]="savingCampaign" [disabled]="campaignForm.invalid" (click)="save()" translate>Save</nv-button>
                &nbsp;
                <nv-button (click)="close()" translate>Close</nv-button>
                &nbsp;
                <nv-button color="primary" (click)="delete()" [hidden]="!campaignId" translate>Delete</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port spaced-bottom">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav">
                <ul>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'leadlink']">LeadLink</a>
                    </li>
                    <li>
                        <a class="active">Campaigns</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
<div class="nv-config-content-port">
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24 small-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form [formGroup]="campaignForm" class="nv-row config-plates clean gap-24 small-spaced-top spaced-bottom">
                            <div class="nv-column desk-6">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Basic Information</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="name"
                                                        label="Name"
                                                        required
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        type="textarea"
                                                        formControlName="description"
                                                        label="Description"
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-colorpicker
                                                        formControlName="color"
                                                        label="Color"
                                                ></nv-colorpicker>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                    </div>
                                </div>
                            </div>
                            <div class="nv-column desk-6">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <ng-container formGroupName="settings">
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <strong translate>Settings</strong>
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /.nv-column -->
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <nv-checkbox
                                                            formControlName="showEmail"
                                                            label="Show Email"
                                                    ></nv-checkbox>
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /.nv-column -->
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <nv-checkbox
                                                            formControlName="use_scandit_scanner"
                                                            label="Use Scandit Scanner"
                                                    ></nv-checkbox>
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /.nv-column -->
                                            <ng-container formGroupName="optIn">
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <strong translate>Opt-in</strong>
                                                    </div><!-- /.nv-column-content -->
                                                </div><!-- /.nv-column -->
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <nv-input
                                                                formControlName="url"
                                                                label="URL"
                                                        ></nv-input>
                                                    </div><!-- /.nv-column-content -->
                                                </div><!-- /.nv-column -->
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <nv-input
                                                                formControlName="text"
                                                                label="Text"
                                                        ></nv-input>
                                                    </div><!-- /.nv-column-content -->
                                                </div><!-- /.nv-column -->
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <nv-input
                                                                formControlName="privacyPolicy"
                                                                label="Privacy Policy"
                                                        ></nv-input>
                                                    </div><!-- /.nv-column-content -->
                                                </div><!-- /.nv-column -->
                                            </ng-container>
                                            <ng-container formGroupName="disclaimer">
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <strong translate>Disclaimer</strong>
                                                    </div><!-- /.nv-column-content -->
                                                </div><!-- /.nv-column -->
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <nv-input
                                                                formControlName="url"
                                                                label="URL"
                                                        ></nv-input>
                                                    </div><!-- /.nv-column-content -->
                                                </div><!-- /.nv-column -->
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <nv-input
                                                                formControlName="text"
                                                                label="Text"
                                                        ></nv-input>
                                                    </div><!-- /.nv-column-content -->
                                                </div><!-- /.nv-column -->
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
