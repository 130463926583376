import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class ApplicationService {

  constructor(
    private apiService: ApiService
  ) { }

  getApplications(params: any = {}) {
    return this.apiService.get(`applications`, params);
  }

  getApplication(applicationId: number, params: any = {}) {
    return this.apiService.get(`applications/${applicationId}`, params);
  }

  getOrganizationApplications(organizationId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/applications`, params);
  }

  getOrganizationApplication(organizationId: number, applicationId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/applications/${applicationId}`, params);
  }

  updateOrganizationApplication(organizationId: number, applicationId: number, params: any = {}) {
    return this.apiService.put(`organizations/${organizationId}/applications/${applicationId}`, params);
  }

  createOrganizationApplication(organizationId: number, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/applications`, params);
  }

  deleteOrganizationApplication(organizationId: number, applicationId: number) {
    return this.apiService.delete(`organizations/${organizationId}/applications/${applicationId}`);
  }

  getConferenceApplication(organizationId: number, conferenceId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/conferences/${conferenceId}/applications/main`, params);
  }

  updateConferenceApplication(organizationId: number, conferenceId: number, applicationId: number, params: any = {}) {
    return this.apiService.put(`organizations/${organizationId}/conferences/${conferenceId}/applications/${applicationId}`, params);
  }

  createConferenceApplication(organizationId: number, conferenceId: number, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/conferences/${conferenceId}/applications`, params);
  }

  deleteConferenceApplication(organizationId: number, conferenceId: number, applicationId: number) {
    return this.apiService.delete(`organizations/${organizationId}/conferences/${conferenceId}/applications/${applicationId}`);
  }


  updateOrganizationApplicationPage(organizationId: number, applicationId: number, params: any = {}) {
    return this.apiService.put(`organizations/${organizationId}/applications/${applicationId}`, params);
  }

  createOrganizationApplicationPage(organizationId: number, applicationId: number, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/applications/${applicationId}/pages`, params);
  }

  deleteOrganizationApplicationPage(organizationId: number, applicationId: number) {
    return this.apiService.delete(`organizations/${organizationId}/applications/${applicationId}`);
  }

  createOrganizationApplicationPageSection(organizationId: number, applicationId: number, pageId: string, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/applications/${applicationId}/pages/${pageId}/sections`, params);
  }

  updateOrganizationApplicationPageSection(organizationId: number, applicationId: number, pageId: string, sectionId: string, params: any = {}) {
    return this.apiService.put(`organizations/${organizationId}/applications/${applicationId}/pages/${pageId}/sections/${sectionId}`, params);
  }

  deleteOrganizationApplicationPageSection(organizationId: number, applicationId: number, pageId: string, sectionId: string) {
    return this.apiService.delete(`organizations/${organizationId}/applications/${applicationId}/pages/${pageId}/sections/${sectionId}`);
  }

}
