<header *ngIf="article" class="nv-section guttered nv-single-detail-header nv-news-single-header">
    <div class="nv-port">
        <div class="nv-row v-stretch large-spaced-top spaced-bottom">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <h1>{{ article.title }}</h1>
                </div><!-- /.nv-column-content -->
            </div><!-- /nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</header><!-- /.nv-section -->
<main *ngIf="article" class="nv-section guttered nv-single-detail-body nv-news-single-body">
    <span class="nv-single-stripe"></span>
    <div class="nv-port">
        <div class="nv-row v-stretch">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <div class="nv-row gap-28">
                        <div class="nv-column desk-8 tab-12 nv-single-news-content">
                            <div class="nv-column-content">
                                <figure>
                                    <img [src]="article.image_url" alt="{{article.title}}">
                                </figure>
                                <div [innerHTML]="article.content"></div>
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                        <div class="nv-column desk-4 tab-12">
                            <div class="nv-column-content">
                                <div class="nv-excerpts-box color-gray1" *ngIf="hotTopics.length > 0">
                                    <h4>HOT TOPICS</h4>
                                    <nav>
                                        <ul>
                                            <li *ngFor="let article of hotTopics">
                                                <figure>
                                                    <a (click)="articleDetails(article)">
                                                        <img [src]="article.image_url" [alt]="article.title">
                                                    </a>
                                                </figure>
                                                <header>
                                                    <h3>
                                                        <a (click)="articleDetails(article)">{{ article.title }}</a>
                                                    </h3>
                                                </header>
                                            </li>
                                        </ul>
                                    </nav>
                                </div><!-- /.nv-excerpts-box -->

                                <div class="nv-excerpts-box color-gray2">
                                    <h4>ALL NEWS</h4>
                                    <nav>
                                        <ul>
                                            <li *ngFor="let article of articles">
                                                <figure>
                                                    <a (click)="articleDetails(article)">
                                                        <img [src]="article.image_url" [alt]="article.title">
                                                    </a>
                                                </figure>
                                                <header>
                                                    <h3>
                                                        <a (click)="articleDetails(article)">{{ article.title }}</a>
                                                    </h3>
                                                </header>
                                            </li>
                                        </ul>
                                    </nav>
                                </div><!-- /.nv-excerpts-box -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div><!-- /.nv-row -->
                </div><!-- /.nv-column-content -->
            </div><!-- /nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</main><!-- /.nv-section -->

