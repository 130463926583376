import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Presentation } from '@navus/core/classes/presentation';
import { PresentationService } from '@navus/core/services/presentation.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { BlockService } from '@navus/core/services/block.service';
import { Block } from '@navus/core/classes/block';
import { Author } from '@navus/core/classes/author';
import { AuthorService } from '@navus/core/services/author.service';
import { LocationService } from '@navus/core/services/location.service';
import { Location } from '@navus/core/classes/location';
import { ConferenceService } from '@navus/core/services/conference.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'main [nv-presentation-details-page]',
  templateUrl: './presentation-details-page.component.html',
  providers: [DatePipe]
})
export class PresentationDetailsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  presentationId: number;
  presentation: Presentation = new Presentation();
  presentationForm: FormGroup;
  presentationTypes = [
    { id: 'Oral', name: 'Oral' },
    { id: 'Poster', name: 'Poster' },
    { id: 'Break', name: 'Break' }
  ];
  presentationSections = [
    { id: 'Scientific', name: 'Scientific' },
    { id: 'Promotional', name: 'Promotional' }
  ];
  showAdvanced: boolean = false;
  inSession: boolean = false;
  date: string;
  start_time: string;
  end_time: string;

  dates: any[] = [];

  blocks: Block[] = [];
  blocksSearch: string = '';
  blocksPage: number = 1;
  blocksTotal: number = null;

  locations: Location[] = [];
  locationsSearch: string = '';
  locationsPage: number = 1;
  locationsTotal: number = null;

  authors: Author[] = [];
  authorsSearch: string = '';
  authorsPage: number = 1;
  authorsTotal: number = null;

  loadingPresentation: boolean = false;
  savingPresentation: boolean = false;
  savingPresentationConsent: boolean = false;
  unsavedChanges: boolean = false;

  subscriptions: Subscription[] = [];

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private presentationService: PresentationService,
    private conferenceService: ConferenceService,
    private locationService: LocationService,
    private blockService: BlockService,
    private authorService: AuthorService,
    private toastService: ToastService,
    private modalService: ModalService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.initForm();

    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.presentationId = data.presentationId;
        this.getPresentation();
        this.getConferenceDates();
      }
    );
    this.subscriptions.push(subscription);

    const changeSubscription = this.presentationForm.valueChanges.subscribe(
      () => {
        this.unsavedChanges = true;
      }
    );
    this.subscriptions.push(changeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.presentationForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      type: ['Oral', [Validators.required]],
      section: ['Scientific', [Validators.required]],
      code: [''],
      description: [''],
      block_id: [null],
      location_id: [null],
      author_ids: [null],
      starts_at: ['', [Validators.required]],
      ends_at: ['', [Validators.required]],
      streamable: [false],
      external_webcast: [''],
      allow_publishing: [true],
      talk_to_speaker: [false],
      vote_available: [false],
      availability: [''],
      chat: this.formBuilder.group({
        enabled: [false],
        moderation: [false],
      })
    });
    this.unsavedChanges = false;
  }

  getPresentation() {
    if (this.presentationId) {
      this.loadingPresentation = true;
      this.presentationService
        .getConferencePresentation(this.conferenceId, this.presentationId, { include: 'block,location,authors' })
        .subscribe(
          (response: { data: Presentation, meta: any }) => {
            const presentation = response.data;

            this.blocks.push(presentation.block);
            delete presentation.block;

            this.locations.push(presentation.location);
            delete presentation.location;

            this.authors = this.authors.concat(presentation.authors);
            delete presentation.authors;

            if (!presentation.chat) {
              presentation.chat = { 
                enabled: false,
                moderation: false
              }
            }

            this.presentation = presentation;
            this.presentationForm.patchValue(presentation);

            this.inSession = !!this.presentation.block_id;

            this.date = presentation.starts_at.substr(0, 10);
            this.start_time = presentation.starts_at.substr(11, 8);
            this.end_time = presentation.ends_at.substr(11, 8);

            this.loadingPresentation = false;
            setTimeout(() => { this.unsavedChanges = false; }, 500);
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingPresentation = false;
          }
        );
    } else {
      this.presentation = new Presentation();

      this.locationService
        .getConferenceLocations(this.conferenceId, { per_page: 1 })
        .subscribe(
          (response: { data: Location[], meta: any }) => {
            if (response.data.length > 0) {
              this.locations = this.locations.concat(response.data);
              this.presentationForm.get('location_id').setValue(this.locations[0].id);
            }
          },
          () => { }
        );

      this.loadingPresentation = false;
    }
  }

  inSessionChange() {
    if (this.inSession) {
      this.presentationForm.get('block_id').setValidators([Validators.required]);
      this.presentationForm.get('location_id').setValidators(null);
    } else {
      this.presentationForm.get('block_id').setValidators(null);
      this.presentationForm.get('location_id').setValidators([Validators.required]);
    }
  }

  sessionChange(block_id: number) {
    const block = this.blocks.find((b) => b.id.toString() === block_id.toString());
    if (block && !this.presentationId) {
      this.presentationForm.get('streamable').setValue(block.streamable);
      this.date = block.starts_at.substr(0, 10);
      this.start_time = block.starts_at.substr(11, 5) + ':00';
      this.dateChange();
    }
  }

  dateChange() {
    if (this.date) {
      if (this.start_time) {
        this.presentationForm.get('starts_at').setValue(`${this.date} ${this.start_time}`);
      }
      if (this.end_time) {
        this.presentationForm.get('ends_at').setValue(`${this.date} ${this.end_time}`);
      }
    }
  }

  getConferenceDates() {
    this.conferenceService
      .getConferenceDates(this.conferenceId)
      .subscribe(
        (response) => {
          const dates = response.data;
          this.dates = dates.map((date: string) => ({ id: date, name: this.datePipe.transform(date, 'dd.MM.yyyy') }));
        },
        () => { }
      );
  }

  getBlocks(append: boolean = false) {
    if (!this.conferenceId) { return; }

    if (append) {
      if (this.blocksTotal && this.blocks.length >= this.blocksTotal) { return; }
      this.blocksPage++;
    } else {
      this.blocksPage = 1;
    }

    const params: any = {
      page: this.blocksPage,
      per_page: 10,
      sort: 'name',
      order: 'asc'
    };

    if (this.blocksSearch !== '') { params.search_term = this.blocksSearch; }

    this.blockService
      .getConferenceBlocks(this.conferenceId, params)
      .subscribe(
        (response: { data: Block[], meta: any }) => {
          if (append) {
            this.blocks = this.blocks.concat(response.data);
          } else {
            this.blocks = response.data;
            this.blocksTotal = response.meta.pagination.total;
          }
        },
        () => { }
      );
  }

  searchBlocks(searchTerm: string) {
    this.blocksSearch = searchTerm;
    this.getBlocks();
  }

  createBlock(event: any) {
    const blockName = event.value.toString();
    this.modalService.defaultModal({
      title: 'Add a session',
      body: 'Do you want to add a session <strong>' + blockName + '</strong>?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Add',
          color: 'primary',
          handler: () => {
            const block = new Block();
            block.name = blockName;

            this.blockService
              .createConferenceBlock(this.conferenceId, block)
              .subscribe(
                (response) => {
                  this.getBlocks();
                }
              );
          }
        }
      ]
    });
  }

  searchLocations(searchTerm: string) {
    this.locationsSearch = searchTerm;
    this.getLocations();
  }

  getLocations(append: boolean = false) {
    if (!this.conferenceId) { return; }

    if (append) {
      if (this.locationsTotal && this.locations.length >= this.locationsTotal) { return; }
      this.locationsPage++;
    } else {
      this.locationsPage = 1;
    }

    const params: any = {
      page: this.locationsPage,
      per_page: 10,
      sort: 'name',
      order: 'asc'
    };

    if (this.locationsSearch !== '') { params.search_term = this.locationsSearch; }

    this.locationService
      .getConferenceLocations(this.conferenceId, params)
      .subscribe(
        (response: { data: Location[], meta: any }) => {
          if (append) {
            this.locations = this.locations.concat(response.data);
          } else {
            this.locations = response.data;
            this.locationsTotal = response.meta.pagination.total;
          }
        },
        () => { }
      );
  }

  createLocation(event: any) {
    const locationName = event.value.toString();
    this.modalService.defaultModal({
      title: 'Add a location',
      body: 'Do you want to add a location <strong>' + locationName + '</strong>?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Add',
          color: 'primary',
          handler: () => {
            const location = new Location();
            location.name = locationName;

            this.locationService
              .createConferenceLocation(this.conferenceId, location)
              .subscribe(
                (response) => {
                  this.getLocations();
                }
              );
          }
        }
      ]
    });
  }

  getAuthors(append: boolean = false) {
    if (!this.conferenceId) { return; }

    if (append) {
      if (this.authorsTotal && this.authors.length >= this.authorsTotal) { return; }
      this.authorsPage++;
    } else {
      this.authorsPage = 1;
    }

    const params: any = {
      page: this.authorsPage,
      per_page: 10,
      sort: 'name',
      order: 'asc'
    };

    if (this.authorsSearch !== '') { params.search_term = this.authorsSearch; }

    this.authorService
      .getConferenceAuthors(this.conferenceId, params)
      .subscribe(
        (response: { data: Author[], meta: any }) => {
          if (append) {
            this.authors = this.authors.concat(response.data);
          } else {
            this.authors = response.data;
            this.authorsTotal = response.meta.pagination.total;
          }
        },
        () => { }
      );
  }

  searchAuthors(searchTerm: string) {
    this.authorsSearch = searchTerm;
    this.getAuthors();
  }

  createAuthor(event: any) {
    const speakerName = event.value.toString();
    this.modalService.defaultModal({
      title: 'Add a speaker',
      body: 'Do you want to add a speaker <strong>' + speakerName + '</strong>?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Add',
          color: 'primary',
          handler: () => {
            const nameSegments = speakerName.split(' ');
            const author = new Author();

            author.first_name = nameSegments[0];
            if (nameSegments.length > 1) {
              author.last_name = nameSegments[1];
            }

            this.authorService
              .createConferenceAuthor(this.conferenceId, author)
              .subscribe(
                (response) => {
                  this.getAuthors();
                }
              );
          }
        }
      ]
    });
  }

  save() {
    if (this.presentationForm.invalid) { return; }

    if (this.inSession) {
      this.presentationForm.get('location_id').setValue(null);

      const block_id = this.presentationForm.get('block_id').value;
      const block = this.blocks.find((b) => b.id == block_id);
      if (block) {
        this.date = block.starts_at.substr(0, 10);
      }
    } else {
      this.presentationForm.get('block_id').setValue(null);
    }

    this.presentationForm.get('starts_at').setValue(`${this.date} ${this.start_time}`);
    this.presentationForm.get('ends_at').setValue(`${this.date} ${this.end_time}`);

    this.savingPresentation = true;
    if (this.presentationId) {
      this.presentationService
        .updateConferencePresentation(this.conferenceId, this.presentationId, this.presentationForm.value)
        .subscribe(
          (response) => {
            this.presentation = response['data'];
            this.savingPresentation = false;
            this.toastService.success('Presentation successfully saved.', {});
          },
          (error) => {
            this.savingPresentation = false;
            this.modalService.error({ errors: error });
          }
        );
    } else {
      this.presentationService
        .createConferencePresentation(this.conferenceId, this.presentationForm.value)
        .subscribe(
          (response) => {
            this.presentation = response['data'];
            this.savingPresentation = false;
            this.toastService.success('Presentation successfully created.', {});
            this.router.navigate([this.presentation.id], { relativeTo: this.route });
          },
          (error) => {
            this.savingPresentation = false;
            this.modalService.error({ errors: error });
          }
        );
    }
  }

  close() {
    if (this.presentationId) {
      this.router.navigate(['../..', 'presentations'], { relativeTo: this.route });
    } else {
      this.router.navigate(['..', 'presentations'], { relativeTo: this.route });
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Presentation Deletion',
      body: 'Are you sure you want to delete this presentation?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.presentationService
              .deleteConferencePresentation(this.conferenceId, this.presentationId)
              .subscribe(
                () => {
                  this.close();
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

  setEndTime(length: number) {
    const startTime = new Date(`2020-02-02 ${this.start_time}`);
    const endTime = new Date((startTime.getTime() + (length * 60000)) - (startTime.getTimezoneOffset() * 60000));
    this.end_time = endTime.toISOString().substr(11, 5) + ':00';
    this.dateChange();
  }

}
