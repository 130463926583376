import { Component, Input, OnInit } from '@angular/core';
import { MaterialService } from '@navus/core/services/material.service';

@Component({
    selector: 'nv-off-air',
    templateUrl: './off-air.component.html',
})

export class NavusOffAirComponent implements OnInit {
  @Input() set offAir(value: OffAir) {
    this._offAir = value;
    this.init();
  };
  @Input() timezone: any;
  @Input() currentUser: any;
  @Input() position: 'main'|'side' = 'main';
  images: any[] = [];

  _offAir: OffAir;
  showTitle: boolean = false; 
  showDescription: boolean = false; 
  showCountdown: boolean = false; 

  counter: any;
  counterDays: string;
  counterHours: string;
  counterMinutes: string;
  counterSeconds: string;

  counterEnded: boolean = false;
  
    
  constructor(
    private materialService: MaterialService
  ) {}

  ngOnInit() { }

  init() {
    this.showTitle = this._offAir.title_position === this.position || this._offAir.title_position === 'both';
    this.showDescription = this._offAir.description_position === this.position || this._offAir.description_position === 'both';
    this.showCountdown = (this._offAir.countdown_position === this.position || this._offAir.countdown_position === 'both') && !!this._offAir.countdown_ends_at;

    // console.log('init', this._offAir);
    if (this._offAir.materials?.type === 'main' || this._offAir.materials?.type === 'supporting') {
      this.materialService
        .getMaterialImages(this._offAir.materials.id)
        .subscribe(
          (response) => {
            this.images = response.images;
          }
        )
    }

    if (this._offAir.countdown_ends_at) {
      this.counterTick();
      this.counter = setInterval(() => this.counterTick(), 1000);
    }
  }

  counterTick() {
    let currentTime = new Date().getTime();
    let counterTime = new Date(this._offAir.countdown_ends_at?.replace(' ', 'T')).getTime();
    
    currentTime -= new Date().getTimezoneOffset() * 1000;
    counterTime += this.timezone?.offset * 60 * 1000  || 0

    const diff = counterTime - currentTime;

    if (diff <= 0) {
      this.counterEnded = true;
      clearInterval(this.counter);
      return;
    }

    const seconds: any = Math.floor((diff / 1000) % 60);
    const minutes: any = Math.floor((diff / (1000 * 60)) % 60);
    const hours: any = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const days: any = Math.floor((diff / (1000 * 60 * 60 * 24)));
  
    this.counterDays = (days < 10) ? "0" + days : days;
    this.counterHours = (hours < 10) ? "0" + hours : hours;
    this.counterMinutes = (minutes < 10) ? "0" + minutes : minutes;
    this.counterSeconds = (seconds < 10) ? "0" + seconds : seconds;
  }

}

class OffAir {
  id: number;
  title: string;
  title_position: string;
  description: string;
  description_position: string;
  resource_type: 'resources'|'material'|'external'|'external_video';
  resource_value: string;
  materials?: any;
  countdown_ends_at: string;
  countdown_ended_label: string;
  countdown_position: string;
}