<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Registration Settings</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-10">
                <nv-button (click)="save()" translate>Save</nv-button>
            </div>
        </div>
    </div><!-- /.config-title-n-tools -->
    <div class="nv-port">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav vertical-break-tab">
                <ul>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'delegates']">Attendees</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'ticket-types']">Ticketing</a>
                    </li>
                    <li>
                        <a class="active">Registration Settings</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'certificates']">CME Certificates</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>

<div class="nv-config-content-port">
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates">
                <form class="nv-column desk-12" [formGroup]="conferenceSettingsForm">
                    <div class="nv-column-content">
                        <div class="nv-row config-plates clean gap-24 propagate">
                            <div class="nv-column desk-12 no-propagate">
                                <div class="nv-column-content">
                                    <h1>Registration Settings</h1>
                                </div>
                            </div>
                            <ng-container formGroupName="delegate_registration">
                                <div class="nv-column desk-12 tab-12">
                                    <div class="nv-column-content">
                                        <nv-switch
                                                label="Using Navus registration form"
                                                formControlName="enabled"
                                        ></nv-switch>
                                    </div>
                                </div>
                                <ng-container *ngIf="conferenceSettingsForm.value?.delegate_registration?.enabled">
                                    <div class="nv-column desk-12 tab-12">
                                        <div class="nv-column-content">
                                            <nv-checkbox
                                                    label="Send the confirmation email"
                                                    formControlName="send_welcome_email"
                                            ></nv-checkbox>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="nv-column desk-12 tab-12">
                                    <div class="nv-column-content">
                                        <nv-switch
                                                label="Enable ticketing"
                                                formControlName="ticketing"
                                        ></nv-switch>
                                        <small style="display: inline-block; margin-top: 12px;">
                                            <a [routerLink]="['..', 'ticket-types']">ticketing settings</a>
                                        </small>
                                    </div>
                                </div>
                                <div class="nv-column desk-12 tab-12" *ngIf="conferenceSettingsForm.value?.delegate_registration?.ticketing">
                                    <div class="nv-column-content">
                                        <nv-switch
                                                label="Enable bank transfer"
                                                formControlName="ticketing_enable_bank_transfer"
                                        ></nv-switch>
                                    </div>
                                </div>    
                            </ng-container>
                        </div>
                        <ng-container *ngIf="conferenceSettingsForm.value?.delegate_registration?.enabled">
                            <div class="nv-row config-plates clean spaced-top spaced-bottom">
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <h2>Registration Form Fields</h2>
                                    </div>
                                </div>
                            </div>
                            <div class="nv-row config-plates clean gap-24 propagate">
                                <div class="nv-column desk-12" formArrayName="delegate_registration_fields">
                                    <div class="nv-column-content">
                                        <div cdkDropList (cdkDropListDropped)="drop($event)">
                                            <ng-container 
                                                *ngFor="let item of conferenceSettingsForm.controls.delegate_registration_fields['controls']; let indexField = index;"
                                                formGroupName="{{indexField}}"
                                            >
                                                <div class="nv-row gap-16 propagate nv-delegate-reg-items" cdkDrag>
                                                    <div class="nv-column desk-1">
                                                        <div class="nv-column-content">
                                                            <div class="nv-cell-actions" cdkDragHandle>
                                                                <span class="nv-cell-action-trigger"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="nv-column desk-3" *ngIf="item.value.type !== 'instructions'">
                                                        <div class="nv-column-content">
                                                            <nv-input 
                                                                label="Field Name"
                                                                formControlName="field"
                                                            ></nv-input>
                                                        </div>
                                                    </div>
                                                    <div class="nv-column desk-3" *ngIf="item.value.type !== 'instructions'">
                                                        <div class="nv-column-content">
                                                            <nv-input 
                                                                label="Title"
                                                                formControlName="title"
                                                            ></nv-input>
                                                        </div>
                                                    </div>
                                                    <div class="nv-column desk-6" *ngIf="item.value.type === 'instructions'">
                                                        <div class="nv-column-content">
                                                            <nv-text-editor 
                                                                label="Instructions"
                                                                formControlName="instructions"
                                                                [organizationId]="organizationId"
                                                            ></nv-text-editor>
                                                        </div>
                                                    </div>
                                                    <div class="nv-column desk-3">
                                                        <div class="nv-column-content">
                                                            <nv-select 
                                                                label="Type"
                                                                formControlName="type"
                                                                [options]="fieldTypeOptions"
                                                                [required]="true"
                                                            ></nv-select>
                                                        </div>
                                                    </div>
                                                    <div class="nv-column desk-2">
                                                        <div class="nv-column-content">
                                                            <nv-button 
                                                                color="neutral"
                                                                size="small"
                                                                iconPl="&#xe918;"
                                                                [onlyIcon]="true"
                                                                (click)="removeField(indexField)"
                                                            ></nv-button>
                                                        </div>
                                                    </div>
                                                    <div class="nv-column desk-1"> 
                                                        <div class="nv-column-content"></div>
                                                    </div>
                                                    <div class="nv-column desk-3">
                                                        <div class="nv-column-content">
                                                            <nv-select 
                                                                label="Field Width"
                                                                formControlName="width"
                                                                [options]="fieldWidthOptions"
                                                                [required]="true"
                                                            ></nv-select>
                                                        </div>
                                                    </div>
                                                    <div class="nv-column desk-3"  *ngIf="item.value.type !== 'instructions'">
                                                        <div class="nv-column-content">
                                                            <nv-switch 
                                                                label="Mandatory" 
                                                                formControlName="mandatory"
                                                            ></nv-switch>
                                                        </div>
                                                    </div>
                                                    <div class="nv-column desk-3" *ngIf="item.value.type === 'select'">
                                                        <div class="nv-column-content"> 
                                                            <nv-input 
                                                                label="Options (one per line)"
                                                                formControlName="options"
                                                                type="textarea"
                                                            ></nv-input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>     
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="nv-row">
                            <div class="nv-column desk-12"> 
                                <div class="nv-column-content">
                                    <div>
                                        <nv-button iconPl="&#xe095;" (click)="addField()">Add field</nv-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
