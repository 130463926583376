<div class="nv-live-studio">
    <div class="nv-live-studio-streaming-program on"><!-- < toggle class 'on' to show/hide the program -->
        <div class="nv-streaming-program">
            <header>
                <h2 class="medium">Streaming Program</h2>
                <div class="nv-sp-header-tools">
                    <div class="nv-hide-finished-switch">
                        <div class="nv-custom-toggle-checkbox">
                            <label title="Show / Hide Meeting">
                                <input type="checkbox">
                                <span class="nv-custom-toggle-checkbox-visual"></span>
                            </label>
                        </div><!-- /.nv-custom-toggle-checkbox -->
                        <p>Hide finished</p>
                    </div><!-- /.nv-hide-finished-switch -->
                </div><!-- /.nv-sp-header-tools -->
            </header>
            <div class="nv-streaming-program-date-tabs">
                <div class="nv-line-tabs">
                    <nav class="nv-tabs-nav">
                        <ul>
                            <li>
                                <a href class="active" title="March 24">March 24</a>
                            </li>
                            <li>
                                <a href title="March 25">March 25</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div><!-- /.nv-streaming-program-date-tabs -->
            <div class="nv-streaming-program-body">
                <div class="nv-streaming-program-body-sleeve">
                    <div class="nv-streaming-program-item">
                        <div class="nv-streaming-program-meta-bar">
                            <time>12:30 - 14:00</time>
                            <h3>Session: Managing data</h3>
                            <p class="chairs"><strong>Chairs</strong>: Hulda Rogahn, Ransom Ruecker</p>
                        </div><!-- /.nv-streaming-program-meta-bar -->
                        <ul class="nv-streaming-program-schedule">
                            <li class="on-air">
                                <time>12:30 - 13:15</time>
                                <h4>What is important?</h4>
                                <p class="speakers"><strong>Speakers</strong>: Hulda Rogahn</p>
                            </li>
                            <li>
                                <time>13:15 - 14:30</time>
                                <h4>Sponsor Management - Offering and expectations</h4>
                                <p class="speakers"><strong>Speakers</strong>: Hulda Rogahn</p>
                            </li>
                        </ul><!-- /.nv-streaming-program-schedule -->
                        <div class="nv-sp-participants-box">
                            <header>
                                <h3>Participants</h3>
                                <p>
                                    <a href title="Clear all">Clear all</a>
                                    <a href title="Clear all">Invite all in</a>
                                </p>
                            </header>
                            <div class="nv-sp-participants">
                                <div class="nv-sp-participant">
                                    <figure class="nv-sp-participant-image" title="Mia Hills">
                                        <img src="https://loremflickr.com/200/200/girl" alt="Mia Hills">
<!--                                        <img src="../../assets/avatar-placeholder.png" title="">-->
                                        <span class="status"></span>
                                    </figure><!-- /.nv-sp-participant-image -->
                                    <div class="nv-sp-pariticpant-name">
                                        <h4>Mia Hills</h4>
                                        <p>Speaker</p>
                                    </div><!-- /.nv-sp-pariticpant-name -->
                                    <div class="nv-sp-participant-switch">
                                        <div class="nv-custom-toggle-checkbox">
                                            <label title="Include / Exclude Participant">
                                                <input type="checkbox">
                                                <span class="nv-custom-toggle-checkbox-visual"></span>
                                            </label>
                                        </div><!-- /.nv-custom-toggle-checkbox -->
                                    </div><!-- /.nv-sp-participant-switch -->
                                </div><!-- /.nv-sp-participant -->

                                <div class="nv-sp-participant">
                                    <figure class="nv-sp-participant-image" title="Mia Hills">
                                        <img src="https://loremflickr.com/200/200/girl" alt="Mia Hills">
                                        <span class="status"></span>
                                    </figure><!-- /.nv-sp-participant-image -->
                                    <div class="nv-sp-pariticpant-name">
                                        <h4>Mia Hills</h4>
                                        <p>Speaker</p>
                                    </div><!-- /.nv-sp-pariticpant-name -->
                                    <div class="nv-sp-participant-switch">
                                        <div class="nv-custom-toggle-checkbox">
                                            <label title="Include / Exclude Participant">
                                                <input type="checkbox">
                                                <span class="nv-custom-toggle-checkbox-visual"></span>
                                            </label>
                                        </div><!-- /.nv-custom-toggle-checkbox -->
                                    </div><!-- /.nv-sp-participant-switch -->
                                </div><!-- /.nv-sp-participant -->

                                <div class="nv-sp-participant">
                                    <figure class="nv-sp-participant-image" title="Mia Hills">
                                        <img src="https://loremflickr.com/200/200/girl" alt="Mia Hills">
                                        <span class="status"></span>
                                    </figure><!-- /.nv-sp-participant-image -->
                                    <div class="nv-sp-pariticpant-name">
                                        <h4>Mia Hills</h4>
                                        <p>Speaker</p>
                                    </div><!-- /.nv-sp-pariticpant-name -->
                                    <div class="nv-sp-participant-switch">
                                        <div class="nv-custom-toggle-checkbox">
                                            <label title="Include / Exclude Participant">
                                                <input type="checkbox">
                                                <span class="nv-custom-toggle-checkbox-visual"></span>
                                            </label>
                                        </div><!-- /.nv-custom-toggle-checkbox -->
                                    </div><!-- /.nv-sp-participant-switch -->
                                </div><!-- /.nv-sp-participant -->

                                <div class="nv-sp-participant">
                                    <figure class="nv-sp-participant-image red" title="Mia Hills">
                                        <img src="https://loremflickr.com/200/200/girl" alt="Mia Hills">
                                        <span class="status"></span>
                                    </figure><!-- /.nv-sp-participant-image -->
                                    <div class="nv-sp-pariticpant-name">
                                        <h4>Mia Hills</h4>
                                        <p>Speaker</p>
                                    </div><!-- /.nv-sp-pariticpant-name -->
                                    <div class="nv-sp-participant-switch">
                                        <div class="nv-custom-toggle-checkbox">
                                            <label title="Include / Exclude Participant">
                                                <input type="checkbox">
                                                <span class="nv-custom-toggle-checkbox-visual"></span>
                                            </label>
                                        </div><!-- /.nv-custom-toggle-checkbox -->
                                    </div><!-- /.nv-sp-participant-switch -->
                                </div><!-- /.nv-sp-participant -->

                                <div class="nv-sp-participant">
                                    <figure class="nv-sp-participant-image green" title="Mia Hills">
                                        <img src="https://loremflickr.com/200/200/girl" alt="Mia Hills">
                                        <span class="status"></span>
                                    </figure><!-- /.nv-sp-participant-image -->
                                    <div class="nv-sp-pariticpant-name">
                                        <h4>Mia Hills</h4>
                                        <p>Speaker</p>
                                    </div><!-- /.nv-sp-pariticpant-name -->
                                    <div class="nv-sp-participant-switch">
                                        <div class="nv-custom-toggle-checkbox">
                                            <label title="Include / Exclude Participant">
                                                <input type="checkbox">
                                                <span class="nv-custom-toggle-checkbox-visual"></span>
                                            </label>
                                        </div><!-- /.nv-custom-toggle-checkbox -->
                                    </div><!-- /.nv-sp-participant-switch -->
                                </div><!-- /.nv-sp-participant -->
                            </div><!-- /.nv-sp-participants -->
                        </div><!-- /.nv-sp-participants-box -->
                    </div><!-- /.nv-streaming-program-item -->

                    <div class="nv-streaming-program-item">
                        <div class="nv-streaming-program-meta-bar">
                            <time>17:00 - 18:00</time>
                            <h3>Session Interactivity & Engagement</h3>
                            <p class="chairs"><strong>Chairs</strong>: Maria Cecilia Benitez</p>
                        </div><!-- /.nv-streaming-program-meta-bar -->
                        <ul class="nv-streaming-program-schedule">
                            <li>
                                <time>13:15 - 14:30</time>
                                <h4>Sponsor Management - Offering and expectations</h4>
                                <p class="speakers"><strong>Speakers</strong>: Hulda Rogahn</p>
                            </li>
                            <li>
                                <time>13:15 - 14:30</time>
                                <h4>Sponsor Management - Offering and expectations</h4>
                                <p class="speakers"><strong>Speakers</strong>: Hulda Rogahn</p>
                                <h4 class="prerecorded">Pre-recorded</h4>
                                <ul class="nv-streaming-program-prerecorded">
                                    <li>
                                        <div class="nv-checkbox-item">
                                            <label>
                                                <span class="nci-check-port">
                                                    <input type="checkbox">
                                                    <span class="nci-check-visual"></span>
                                                </span><!-- /.nci-check-port -->
                                            </label>
                                        </div><!-- /nv-checkbox-item -->
                                        <p>Voting Session</p>
                                    </li>
                                    <li>
                                        <div class="nv-checkbox-item">
                                            <label>
                                                <span class="nci-check-port">
                                                    <input type="checkbox">
                                                    <span class="nci-check-visual"></span>
                                                </span><!-- /.nci-check-port -->
                                            </label>
                                        </div><!-- /nv-checkbox-item -->
                                        <p>screencast-csanzanzet2020.navus.io-2020.12.12-23_48_38</p>
                                    </li>
                                    <li>
                                        <div class="nv-checkbox-item">
                                            <label>
                                                <span class="nci-check-port">
                                                    <input type="checkbox">
                                                    <span class="nci-check-visual"></span>
                                                </span><!-- /.nci-check-port -->
                                            </label>
                                        </div><!-- /nv-checkbox-item -->
                                        <p>screencast-csanzanzet2020.navus.io-2020.12.13-00_11_07</p>
                                    </li>
                                </ul><!-- /.nv-streaming-program-prerecorded -->
                            </li>
                            <li>
                                <time>13:15 - 14:30</time>
                                <h4>Sponsor Management - Offering and expectations</h4>
                                <p class="speakers"><strong>Speakers</strong>: Hulda Rogahn</p>
                                <h4 class="prerecorded">Pre-recorded</h4>
                                <ul class="nv-streaming-program-prerecorded">
                                    <li>
                                        <div class="nv-checkbox-item">
                                            <label>
                                                <span class="nci-check-port">
                                                    <input type="checkbox">
                                                    <span class="nci-check-visual"></span>
                                                </span><!-- /.nci-check-port -->
                                            </label>
                                        </div><!-- /nv-checkbox-item -->
                                        <p>Voting Session</p>
                                    </li>
                                    <li>
                                        <div class="nv-checkbox-item">
                                            <label>
                                                <span class="nci-check-port">
                                                    <input type="checkbox">
                                                    <span class="nci-check-visual"></span>
                                                </span><!-- /.nci-check-port -->
                                            </label>
                                        </div><!-- /nv-checkbox-item -->
                                        <p>screencast-csanzanzet2020.navus.io-2020.12.12-23_48_38</p>
                                    </li>
                                    <li>
                                        <div class="nv-checkbox-item">
                                            <label>
                                                <span class="nci-check-port">
                                                    <input type="checkbox">
                                                    <span class="nci-check-visual"></span>
                                                </span><!-- /.nci-check-port -->
                                            </label>
                                        </div><!-- /nv-checkbox-item -->
                                        <p>screencast-csanzanzet2020.navus.io-2020.12.13-00_11_07</p>
                                    </li>
                                </ul><!-- /.nv-streaming-program-prerecorded -->
                            </li>
                        </ul><!-- /.nv-streaming-program-schedule -->
                    </div><!-- /.nv-streaming-program-item -->
                </div><!-- /.nv-streaming-program-body-sleeve -->
            </div><!-- /.nv-streaming-program-body -->
        </div><!-- /.nv-streaming-program -->
    </div><!-- /.nv-live-studio-streaming-program -->
    <div class="nv-lstudio-bar">
        <nav class="nv-lstudio-bar-nav">
            <ul>
                <li><a href title="Navus">Navus</a></li>
                <li><a href title="Hybrid 2022">Hybrid 2022</a></li>
                <li> <!-- < toggle class 'on' to show sub items -->
                    <ul>
                        <li><a href title="Amsterdam">Amsterdam</a></li>
                        <li><a href title="Euro stream">Euro stream</a></li>
                        <li><a href title="Johannesburg">Johannesburg</a></li>
                        <li><a href title="Live in Geneva">Live in Geneva</a></li>
                    </ul>
                    <a href title="Florida">Florida</a>
                </li>
            </ul>
        </nav><!-- /.nv-lstudio-bar-nav -->
        <div class="nv-lstduio-bar-tools">
            <nav class="nv-lstudio-bar-nav">
                <ul>
                    <li>
                        <ul>
                            <li> <!-- < toggle class 'on' to show options -->
                                <div class="nv-custom-toggle-checkbox">
                                    <label title="Show / Hide Meeting">
                                        <input type="checkbox">
                                        <span class="nv-custom-toggle-checkbox-visual"></span>
                                    </label>
                                </div><!-- /.nv-custom-toggle-checkbox -->
                                <p>Meeting</p>
                            </li>
                            <li>
                                <div class="nv-custom-toggle-checkbox">
                                    <label title="Show / Hide Stream">
                                        <input type="checkbox">
                                        <span class="nv-custom-toggle-checkbox-visual"></span>
                                    </label>
                                </div><!-- /.nv-custom-toggle-checkbox -->
                                <p>Stream</p>
                            </li>
                            <li>
                                <div class="nv-custom-toggle-checkbox">
                                    <label title="Show / Hide Q&A">
                                        <input type="checkbox">
                                        <span class="nv-custom-toggle-checkbox-visual"></span>
                                    </label>
                                </div><!-- /.nv-custom-toggle-checkbox -->
                                <p>Q&A</p>
                            </li>
                            <li>
                                <div class="nv-custom-toggle-checkbox">
                                    <label title="Show / Hide Voting">
                                        <input type="checkbox">
                                        <span class="nv-custom-toggle-checkbox-visual"></span>
                                    </label>
                                </div><!-- /.nv-custom-toggle-checkbox -->
                                <p>Voting</p>
                            </li>
                            <li>
                                <div class="nv-custom-toggle-checkbox">
                                    <label title="Show / Hide Slides">
                                        <input type="checkbox">
                                        <span class="nv-custom-toggle-checkbox-visual"></span>
                                    </label>
                                </div><!-- /.nv-custom-toggle-checkbox -->
                                <p>Slides</p>
                            </li>
                        </ul>
                        <a href title="Layout">Layout</a>
                    </li>
                </ul>
            </nav><!-- /.nv-lstudio-bar-nav -->
            <div class="nv-lstudio-profile">
                <span>SG</span>
            </div><!-- /.nv-lstudio-profile -->
        </div><!-- /.nv-lstduio-bar-tools -->
    </div><!-- /.nv-lstudio-bar -->
    <div class="nv-lstudio-body">
        <div class="nv-lstudio-body-sleeve">
            <div class="nv-lstudio-body-upper">
                <div class="nv-lstudio-pane nv-ls-pane-meeting">
                    <header>
                        <h3>Meeting</h3>
                        <div class="nv-ls-heading-tools">
                            <a href class="close-pane" title="Close Window"></a>
                        </div><!-- /.nv-ls-heading-tools -->
                    </header>
                    <div class="nv-lstudio-pane-body">
                        <div class="nv-lstudio-video-box">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/csM7mpjR4L4" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div><!-- /.nv-lstudio-video-box -->
                    </div><!-- /.nv-lstudio-pane-body -->
                </div><!-- /.nv-lstudio-pane -->
                <div class="nv-lstudio-pane nv-ls-pane-live-stage">
                    <header>
                        <h3>Live Stage: <span>Preview</span></h3>
                        <div class="nv-ls-heading-tools">
                            <form class="nv-ls-mode-switch">
                                <p>Mode:</p>
                                <label class="nv-ls-custom-switch">
                                    <input type="checkbox">
                                    <span class="nv-ls-custom-switch-visual" data-status-off="Preview" data-status-on="Live"></span>
                                </label><!-- /.nv-ls-custom-switch -->
                            </form><!-- /.nv-ls-mode-switch -->
                            <a href class="close-pane" title="Close Window"></a>
                        </div><!-- /.nv-ls-heading-tools -->
                    </header>
                    <div class="nv-lstudio-pane-body">
                        <div class="nv-live-stage-panel">
                            <div class="nv-live-stage-panel-video-box">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/H3XfhRat1ZM" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                            </div><!-- /.nv-live-stage-panel-video-box -->
                            <div class="nv-live-stage-panel-controls">
                                <header class="nv-ls-panel-controls-header">
                                    <div class="nv-ls-panel-controls-bar">
                                        <nav class="nv-ls-controls">
                                            <ul>
                                                <li>
                                                    <a href class="prev" title="Previous"></a>
                                                </li>
                                                <li>
                                                    <a href class="next" title="Next"></a>
                                                </li>
                                            </ul>
                                        </nav>
                                        <div class="nv-ls-template-select">
                                            <select>
                                                <option>Default template</option>
                                                <option>Template 1</option>
                                                <option>Template 2</option>
                                            </select>
                                        </div><!-- /.nv-ls-template-select -->
                                        <nav class="nv-ls-controls">
                                            <ul>
                                                <li>
                                                    <a href class="sync on" title="Turn off sync"></a>
                                                </li>
                                                <li>
                                                    <a href class="template" title="Save Template"></a>
                                                </li>
                                                <li>
                                                    <a href class="new-template" title="Save new template"></a>
                                                </li>
                                                <li>
                                                    <a href class="new-layer" title="New Layer"></a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div><!-- /.nv-ls-panel-controls-bar -->
                                </header><!-- /.nv-ls-panel-controls-header -->
                                <div class="nv-ls-panel-controls-msg">
                                    <span class="close" title="Close message"></span>
                                    <p>Template changes are being automatically synced.</p>
                                </div><!-- /.nv-ls-panel-controls-msg -->
                                <div class="nv-ls-panel-controls-body">
                                    <div class="nv-ls-panel-controls-port">
                                        <ul class="nv-live-studio-edit">
                                            <li class="inactive">
                                                <div class="nv-ls-edit-bar">
                                                    <span class="nv-ls-bar-state"></span>
                                                    <p>Video</p>
                                                    <div class="nv-ls-bar-tools">
                                                        <a href class="del" title="Delete"></a>
                                                        <a href class="toggler" title="Open"></a>
                                                    </div><!-- /.nv-ls-bar-tools -->
                                                </div><!-- /.nv-ls-edit-bar -->
                                            </li>
                                            <li class="on">
                                                <div class="nv-ls-edit-bar">
                                                    <span class="nv-ls-bar-state"></span>
                                                    <p>Countdown</p>
                                                    <div class="nv-ls-bar-tools">
                                                        <a href class="del" title="Delete"></a>
                                                        <a href class="toggler" title="Open"></a>
                                                    </div><!-- /.nv-ls-bar-tools -->
                                                </div><!-- /.nv-ls-edit-bar -->
                                                <div class="nv-ls-edit-body">
                                                    <div class="nv-ls-edit-position nv-edit-item">
                                                        <h4>Position</h4>
                                                        <div class="nv-ls-position-settings">
                                                            <div class="nv-ls-position-type">
                                                                <div class="nv-ls-pt-item">
                                                                    <div class="nv-checkbox-item">
                                                                        <label>
                                                                            <span class="nci-check-port">
                                                                                <input type="checkbox">
                                                                                <span class="nci-check-visual"></span>
                                                                            </span><!-- /.nci-check-port -->
                                                                        </label>
                                                                    </div><!-- /nv-checkbox-item -->
                                                                    <span>Full screen</span>
                                                                </div><!-- /.nv-ls-pt-item -->
                                                                <div class="nv-ls-pt-item">
                                                                    <div class="nv-checkbox-item">
                                                                        <label>
                                                                        <span class="nci-check-port">
                                                                            <input type="checkbox">
                                                                            <span class="nci-check-visual"></span>
                                                                        </span><!-- /.nci-check-port -->
                                                                        </label>
                                                                    </div><!-- /nv-checkbox-item -->
                                                                    <span>Section</span>
                                                                </div><!-- /.nv-ls-pt-item -->
                                                            </div><!-- /.nv-ls-position-type -->
                                                            <div class="nv-ls-position-placement">
                                                                <div class="nv-ls-position-squares">
                                                                    <span></span>
                                                                    <span></span>
                                                                    <span></span>
                                                                    <span></span>
                                                                    <span class="on"></span>
                                                                    <span></span>
                                                                    <span></span>
                                                                    <span></span>
                                                                    <span></span>
                                                                </div><!-- /.nv-ls-position-squares -->
                                                                <p>Place section:<br>Top left</p>
                                                            </div><!-- /.nv-ls-position-placement -->
                                                        </div><!-- /.nv-ls-position-settings -->
                                                    </div><!-- /.nv-ls-edit-position -->

                                                    <div class="nv-ls-url-settings nv-edit-item">
                                                        <h4>URL</h4>
                                                        <div class="nv-ls-url-settings-input">
                                                            <input type="text" name="url" value="https://app.zeplin.io/" disabled>
                                                            <a href class="nv-edit-url" title="Edit URL"></a>
                                                        </div><!-- /.nv-ls-url-settings-input -->
                                                    </div><!-- /.nv-ls-url-settings -->

                                                    <div class="nv-ls-output-settings nv-edit-item">
                                                        <h4>Output</h4>
                                                        <div class="nv-ls-output-items">
                                                            <div class="nv-ls-output-item">
                                                                <div class="nv-checkbox-item">
                                                                    <label>
                                                                        <span class="nci-check-port">
                                                                            <input type="checkbox">
                                                                            <span class="nci-check-visual"></span>
                                                                        </span><!-- /.nci-check-port -->
                                                                    </label>
                                                                </div><!-- /nv-checkbox-item -->
                                                                <span>Video</span>
                                                            </div><!-- /.nv-ls-output-item -->
                                                            <div class="nv-ls-output-item">
                                                                <div class="nv-checkbox-item">
                                                                    <label>
                                                                        <span class="nci-check-port">
                                                                            <input type="checkbox">
                                                                            <span class="nci-check-visual"></span>
                                                                        </span><!-- /.nci-check-port -->
                                                                    </label>
                                                                </div><!-- /nv-checkbox-item -->
                                                                <span>Audio</span>
                                                            </div><!-- /.nv-ls-output-item -->
                                                        </div><!-- /.nv-ls-output-items -->
                                                    </div><!-- /.nv-ls-output-settings -->

                                                    <div class="nv-ls-input-item nv-edit-item">
                                                        <div class="nv-form-item">
                                                            <label>
                                                                <input type="text" placeholder="Title">
                                                                <span class="label-title">Title</span>
                                                            </label>
                                                        </div><!-- /.nv-form-item -->
                                                    </div><!-- /.nv-ls-input-item -->

                                                    <div class="nv-ls-input-item nv-edit-item">
                                                        <div class="nv-form-item">
                                                            <label>
                                                                <input type="text" placeholder="Timer">
                                                                <span class="label-title">Timer</span>
                                                            </label>
                                                        </div><!-- /.nv-form-item -->
                                                    </div><!-- /.nv-ls-input-item -->

                                                    <div class="nv-ls-input-item nv-edit-item">
                                                        <div class="nv-form-item nv-select-item">
                                                            <label class="nv-not-mandatory">
                                                                <select>
                                                                    <option>option 1</option>
                                                                    <option>option 2</option>
                                                                    <option>option 3</option>
                                                                    <option>option 4</option>
                                                                </select>
                                                                <span aria-label="label-title" class="label-title">Resources</span>
                                                            </label>
                                                        </div><!-- /nv-form-item -->
                                                    </div><!-- /.nv-ls-input-item -->

                                                    <div class="nv-ls-upload-item nv-edit-item">
                                                        <div class="nv-ls-file-placholder"></div>
<!--                                                        <div class="nv-ls-file-img"><img src="https://loremflickr.com/300/240/abstract" alt="Image title"></div>-->
                                                        <p>
                                                            <a href title="Upload new file">Upload new file</a>
                                                            <a href title="Remove file">Remove file</a>
                                                        </p>
                                                    </div><!-- /nv-ls-upload-item -->

                                                </div><!-- /.nv-ls-edit-body -->
                                            </li>
                                            <li>
                                                <div class="nv-ls-edit-bar">
                                                    <span class="nv-ls-bar-state"></span>
                                                    <p>Slides</p>
                                                    <div class="nv-ls-bar-tools">
                                                        <a href class="del" title="Delete"></a>
                                                        <a href class="toggler" title="Open"></a>
                                                    </div><!-- /.nv-ls-bar-tools -->
                                                </div><!-- /.nv-ls-edit-bar -->
                                            </li>
                                            <li>
                                                <div class="nv-ls-edit-bar">
                                                    <span class="nv-ls-bar-state off"></span>
                                                    <p>Something</p>
                                                    <div class="nv-ls-bar-tools">
                                                        <a href class="toggler" title="Open"></a>
                                                    </div><!-- /.nv-ls-bar-tools -->
                                                </div><!-- /.nv-ls-edit-bar -->
                                            </li>
                                            <li>
                                                <div class="nv-ls-edit-bar">
                                                    <span class="nv-ls-bar-state off"></span>
                                                    <p>Something else</p>
                                                    <div class="nv-ls-bar-tools">
                                                        <a href class="toggler" title="Open"></a>
                                                    </div><!-- /.nv-ls-bar-tools -->
                                                </div><!-- /.nv-ls-edit-bar -->
                                            </li>
                                            <li class="hovered">
                                                <div class="nv-ls-edit-bar">
                                                    <span class="nv-ls-bar-state"></span>
                                                    <p>Something else</p>
                                                    <div class="nv-ls-bar-tools">
                                                        <a href class="toggler" title="Open"></a>
                                                    </div><!-- /.nv-ls-bar-tools -->
                                                </div><!-- /.nv-ls-edit-bar -->
                                            </li>
                                        </ul><!-- /.nv-live-studio-edit -->
                                    </div><!-- /.nv-ls-panel-controls-port -->
                                </div><!-- /.nv-ls-panel-controls-body -->
                                <div class="nv-ls-panel-controls-footer">
                                    <a href class="nv-btn accent1 fw small">Apply changes</a>
                                    <!-- <p class="nv-ls-footer-msg">Switch to the “Preview” mode to edit live stage layers.</p> -->
                                </div><!-- /.nv-ls-panel-controls-footer -->
                            </div><!-- /.nv-live-stage-panel-controls -->
                        </div><!-- /.nv-live-stage-panel -->
                    </div><!-- /.nv-lstudio-pane-body -->
                </div><!-- /.nv-lstudio-pane -->
            </div><!-- /.nv-lstudio-body-upper -->
            <div class="nv-lstudio-body-lower">
                <div class="nv-lstudio-pane nv-ls-pane-qa">
                    <header>
                        <h3>Q&A</h3>
                        <div class="nv-ls-heading-tools">
                            <a href class="close-pane" title="Close Window"></a>
                        </div><!-- /.nv-ls-heading-tools -->
                    </header>
                    <div class="nv-lstudio-pane-body">
                        <div class="nv-lstudio-qa">
                            <div class="nv-lstudio-qa-filters">
                                <div class="nv-form-item nv-select-item">
                                    <label>
                                        <select>
                                            <option>Session</option>
                                        </select>
                                        <span class="label-title">Session</span>
                                    </label>
                                </div><!-- /nv-form-item -->
                                <div class="nv-form-item nv-select-item">
                                    <label>
                                        <select>
                                            <option>Status</option>
                                        </select>
                                        <span class="label-title">Status</span>
                                    </label>
                                </div><!-- /nv-form-item -->
                            </div><!-- /.nv-lstudio-qa-filters -->
                            <div class="nv-lstudio-qa-port">
                                <!-- Q&A content -->
                                <div class="nv-pane-msg">
                                    <div class="nv-pane-msg-box">
                                        <p>Session not selected</p>
                                    </div>
                                </div><!-- /.nv-pane-msg -->
                            </div><!-- /.nv-lstudio-qa-port -->
                        </div><!-- /.nv-lstudio-qa -->
                    </div><!-- /.nv-lstudio-pane-body -->
                </div><!-- /.nv-lstudio-pane -->

                <div class="nv-lstudio-pane nv-ls-pane-voting">
                    <header>
                        <h3>Voting</h3>
                        <div class="nv-ls-heading-tools">
                            <a href class="close-pane" title="Close Window"></a>
                        </div><!-- /.nv-ls-heading-tools -->
                    </header>
                    <div class="nv-lstudio-pane-body">
                        <div class="nv-lstudio-voting">
                            <header>
                                <p>Title</p>
                                <p>Status</p>
                                <P>Actions</P>
                            </header>
                            <div class="nv-lstudio-voting-body">
                                <div class="nv-lstudio-voting-items">
                                    <div class="nv-voting-item">
                                        <h4>Did you enjoy this virtual event?</h4>
                                        <p class="status">Finished</p>
                                        <div class="nv-voting-item-tools">
                                            <a class="nv-voting-tool result" title="Check results"></a>
                                        </div><!-- /.nv-voting-item-tools -->
                                    </div><!-- /.nv-voting-item -->
                                    <div class="nv-voting-item">
                                        <h4>Lorem ipsum dolor sit amet?</h4>
                                        <p class="status">Pending</p>
                                        <div class="nv-voting-item-tools">
                                            <a class="nv-voting-tool play" title="Play"></a>
                                            <a class="nv-voting-tool open" title="Open"></a>
                                        </div><!-- /.nv-voting-item-tools -->
                                    </div><!-- /.nv-voting-item -->
                                    <div class="nv-voting-item">
                                        <h4>Will you organise virtual events next year?</h4>
                                        <p class="status active">Active</p>
                                        <div class="nv-voting-item-tools">
                                            <a class="nv-voting-tool stop" title="Stop"></a>
                                            <a class="nv-voting-tool open" title="Open"></a>
                                        </div><!-- /.nv-voting-item-tools -->
                                    </div><!-- /.nv-voting-item -->
                                </div><!-- /.nv-lstudio-voting-items -->
                            </div><!-- /.nv-lstudio-voting-body -->
                            <div class="nv-lstudio-voting-controls">
                                <a href class="nv-btn accent1 small" title="Add question">Add question</a>
                                <a href class="nv-btn accent1 small" title="Refresh voting">Refresh voting</a>
                            </div><!-- /.nv-lstudio-voting-controls -->
                        </div><!-- /.nv-lstudio-voting -->
                    </div><!-- /.nv-lstudio-pane-body -->
                </div><!-- /.nv-lstudio-pane -->

                <div class="nv-lstudio-pane nv-ls-pane-slides">
                    <header>
                        <h3>Slides</h3>
                        <div class="nv-ls-heading-tools">
                            <a href class="close-pane" title="Close Window"></a>
                        </div><!-- /.nv-ls-heading-tools -->
                    </header>
                    <div class="nv-lstudio-slides-port">
                        <div class="nv-pane-msg">
                            <div class="nv-pane-msg-box">
                                <p>No slides uploaded yet.</p>
                                <p class="nv-btn-box flow-center gap-10">
                                    <a href class="nv-btn small passive" title="Add slides">Add slides</a>
                                    <a href class="nv-btn small passive" title="Refresh">Refresh</a>
                                </p><!-- /.nv-btn-box -->
                            </div>
                        </div><!-- /.nv-pane-msg -->
                    </div><!-- /.nv-lstudio-slides-port -->
                </div><!-- /.nv-lstudio-pane -->
            </div><!-- /.nv-lstudio-body-lower -->
        </div><!-- /.nv-lstudio-body-sleeve -->
    </div><!-- /.nv-lstudio-body -->
    <div class="nv-lstudio-toolbar">
        <div class="nv-ls-streaming-button">
            <a href title="Go Live" class="ls-go active">Go Live</a>
            <a href title="Stop Streaming" class="ls-on">ON AIR</a>
        </div><!-- /.nv-ls-streaming-button -->
        <div class="nv-lstudio-side left">
            <nav class="nv-lstudio-toolbar-nav">
                <ul>
                    <li><a href title="Settings" class="settings">Settings</a></li>
                    <li><a href title="Autopilot" class="autopilot">Autopilot</a></li>
                    <li><a href title="Mute audio" class="audio">Audio</a></li><!-- < additional class 'muted' -->
                    <li><a href title="Video" class="video">Video</a></li>
                </ul>
            </nav><!-- /.nv-lstudio-toolbar-nav -->
        </div><!-- /.nv-lstudio-side -->
        <div class="nv-lstudio-side right">
            <div class="nv-lstudio-stream-control">
                <nav class="nv-lstudio-toolbar-nav">
                    <ul>
                        <li><a href title="Previous" class="prev">Prev</a></li>
                        <li><a href title="Next" class="next">Next</a></li>
                    </ul>
                </nav><!-- /.nv-lstudio-toolbar-nav -->
                <div class="nv-lstudio-stream-meta">
                    <h4><span>Session:</span> Managing data</h4>
                    <p>
                        <span>12:30 - 13:15</span>
                        WHAT IS IMPORTANT?
                    </p>
                </div><!-- /.nv-lstudio-stream-meta -->
            </div><!-- /.nv-lstudio-stream-control -->
            <nav class="nv-lstudio-toolbar-nav">
                <ul>
                    <li><a href title="View Program" class="active program">Program</a></li>
                </ul>
            </nav><!-- /.nv-lstudio-toolbar-nav -->
        </div><!-- /.nv-lstudio-side -->
    </div><!-- /.nv-lstudio-toolbar -->
</div><!-- /.nv-live-studio -->
