import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FileUploader } from 'ng2-file-upload';
import { Material } from '@navus/core/classes/material';
import { MaterialService } from '@navus/core/services/material.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ConfigService } from '../../../../services/config.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { MaterialDetailsAttachModalComponent } from '../../modals/material-details-attach/material-details-attach-modal.component';
import { ExternalMaterialComponent } from '../../modals/external-material/external-material.component';

@Component({
  selector: 'main [nv-material-list-page]',
  templateUrl: './material-list-page.component.html'
})
export class MaterialListPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;
  showSidebar = false;
  echo;

  organizationId: number;
  conferenceId: number;
  materials: Material[] = [];
  uploadingMaterials: any[] = [];
  uploader: FileUploader;
  addMaterialOpen = false;

  pagination: { page?: number, perPage: number, total?: number } = {
    perPage: 30
  };
  sortOptions: any[] = [
    { id: 'created_at asc', name: 'Created Ascending' },
    { id: 'created_at desc', name: 'Created Descending' },
    { id: 'published_at asc', name: 'Published Ascending' },
    { id: 'published_at desc', name: 'Published Descending' },
    { id: 'material_title asc', name: 'Material Title Ascending' },
    { id: 'material_title desc', name: 'Material Title Descending' },
    { id: 'presentation_title asc', name: 'Presentation Title Ascending' },
    { id: 'presentation_title desc', name: 'Presentation Title Descending' },
    { id: 'size asc', name: 'Size Ascending' },
    { id: 'size desc', name: 'Size Descending' },
    { id: 'status asc', name: 'Status Ascending' },
    { id: 'status desc', name: 'Status Descending' },
  ];
  sort: string = 'created_at desc';
  selectedIds: number[] = [];
  isFileOver: boolean = false;
  isFileOverTimer: any;

  materialsLoading: boolean = false;
  optionsShown: number = null;

  formFilter: FormGroup;
  filterPills: any[] = [];
  showFilter: boolean = false;

  materialTypes: any[] = [
    { id: 'webcast', name: 'Webcast' },
    { id: 'main', name: 'Main' },
  ];
  presentationTypes: any[] = [
    { id: 'oral', name: 'Oral' },
    { id: 'poster', name: 'Poster' },
  ];
  statuses: any[] = [
    { id: 'processed', name: 'Processed' },
    { id: 'unprocessed', name: 'Unprocessed' },
    { id: 'processing', name: 'Processing' },
  ];
  trueFalseOptions: any[] = [
    { id: 'true', name: 'Yes' },
    { id: 'false', name: 'No' },
  ];

  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private materialService: MaterialService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private toastService: ToastService,
    private configService: ConfigService
  ) {
  }

  ngOnInit() {
    this.initWebsocket();

    this.formFilter = this.formBuilder.group({
      search_term: [''],
      material_type: [null],
      presentation_type: [null],
      published: [null],
      status: [null],
      attached_presentation_id: [null],
    });
    this.formFilter.valueChanges.subscribe(
      (value) => {
        this.getMaterials();
      }
    );
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;

        this.setupUploader();
        this.getMaterials();
      }
    );
    this.subscriptions.push(subscription);

    const sidebarSubscription = this.configService.showSidebar.subscribe(showSidebar => this.showSidebar = showSidebar);
    this.subscriptions.push(sidebarSubscription);
  }

  ngOnDestroy() {
    this.echo.disconnect();
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initWebsocket() {
    this.echo = this.materialService
      .initWebsocket()
      .then(
        (echo) => {
          this.echo = echo;
          this.echo
            .channel(`conferences.${this.conferenceId}`)
            .listen(`.materials.status_changed`, (response: any) => {
              console.log(response);
              this.materials.forEach((material) => {
                if (material.id === response.entity_id) {
                  material.percent_processed = response.data.percent;
                  material.status = response.data.status;

                  if (response.error) {
                    this.getMaterials();
                  }
                }
              });
            });
        }
      );
  }

  getMaterials(append: boolean = false, showLoading: boolean = true) {
    if (append && this.pagination.total && this.materials.length >= this.pagination.total) {
      return;
    }
    this.pagination.page = append ? this.pagination.page + 1 : 1;
    const filters = this.formFilter.getRawValue();
    const params = {
      page: this.pagination.page,
      per_page: this.pagination.perPage,
      include: 'attached_presentation',
      ...filters,
    };
    if (this.sort && this.sort !== '') {
      params.sort_by = this.sort.split(' ')[0];
      params.sort_direction = this.sort.split(' ')[1];
    }
    // remove empty filters
    Object.keys(params).forEach(key => {
      if (params[key] === null || params[key] === '') {
        delete params[key];
      }
    });

    this.materialsLoading = !append && showLoading;
    this.materialService.getConferenceMaterials(this.conferenceId, params)
      .subscribe(
        (response: { data: Material[], meta: any }) => {
          this.uploadingMaterials = [];
          if (append) {
            this.materials = this.materials.concat(response.data);
          } else {
            const port = document.getElementsByClassName('nv-config-content-port');
            if (port && port.length > 0) {
              port[0].scrollTop = 0;
            }

            this.materials = response.data;
            this.pagination.total = response.meta.pagination.total;
          }
          this.materialsLoading = false;
        },
        () => {
          this.materialsLoading = false;
        }
      );
  }

  edit(material: Material, attach = false) {
    const modalRef = this.modalService.open(MaterialDetailsAttachModalComponent);
    modalRef.componentInstance.conferenceId = this.conferenceId;
    modalRef.componentInstance.materialId = material.id;
    modalRef.componentInstance.organizationId = this.organizationId;
    if (attach) {
      modalRef.componentInstance.selectedTab = 1;
    }
    modalRef.result.then(
      () => {
        this.getMaterials();
      },
      () => {

      }
    );
  }

  close() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  add() {
    this.router.navigate(['..', 'material'], { relativeTo: this.route });
  }

  delete(material: Material) {
    this.modalService.defaultModal({
      title: 'Material Deletion',
      body: 'Are you sure you want to delete this material?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.materialService.deleteConferenceMaterial(this.conferenceId, material.id)
              .subscribe(
                () => {
                  this.getMaterials();
                },
                (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

  toggleSelected(material: Material) {
    const index = this.selectedIds.indexOf(material.id);
    if (index !== -1) {
      this.selectedIds.splice(index, 1);
    } else {
      this.selectedIds.push(material.id);
    }
  }

  selectAll() {
    this.selectedIds = [];
    for (const material of this.materials) {
      this.selectedIds.push(material.id);
    }
  }

  unselectAll() {
    this.selectedIds = [];
  }

  openOptions(material: Material) {
    event.stopPropagation();
    this.optionsShown = material.id;
  }

  closeOptions() {
    event.stopPropagation();
    this.optionsShown = null;
  }

  closeSidebar() {
    this.configService.toggleSidebar();
  }

  onFileOver(isFileOver: boolean) {
    clearTimeout(this.isFileOverTimer);
    if (isFileOver) {
      this.isFileOver = true;
    } else {
      this.isFileOverTimer = setTimeout(() => {
        this.isFileOver = false;
      }, 500);
    }
  }

  filterRemove(item: { id: string, name: string, type: string }) {
    this.formFilter.get(item.type).setValue(null);
    this.filterPills.splice(this.filterPills.findIndex(fp => fp.id === item.id && fp.type === item.type), 1);
  }

  materialTypeChanged(selection: string): void {
    const materialType = this.materialTypes.find(mt => mt.id === selection);
    this.filterPills.push({ ...materialType, type: 'material_type' });
  }

  presentationTypeChanged(selection: string): void {
    const presentationType = this.presentationTypes.find(mt => mt.id === selection);
    this.filterPills.push({ ...presentationType, type: 'presentation_type' });
  }

  statusChanged(selection: string): void {
    const status = this.statuses.find(mt => mt.id === selection);
    this.filterPills.push({ ...status, type: 'status' });
  }

  publishedChanged(selection: string): void {
    const text = selection === 'false' ? 'Not published' : 'Published';
    this.filterPills.push({ id: text, name: text, type: 'published' });
  }

  hasAttachedPresentationChanged(selection: string): void {
    const text = selection === 'false' ? 'Has attached presentation' : 'No attached presentation';
    this.filterPills.push({ id: text, name: text, type: 'attached_presentation_id' });
  }

  addExternalMaterial() {
    const modalRef = this.modalService.open(ExternalMaterialComponent);
    modalRef.componentInstance.conferenceId = this.conferenceId;
    modalRef.result.then(
      (embed) => {
        this.getMaterials();
      },
      () => {
      }
    );
  }

  private setupUploader() {
    this.uploader = this.materialService.createConferenceMaterialUploader(this.conferenceId);
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.uploader.onProgressItem = (fileItem, progress) => {
      let uploadingMaterial = this.uploadingMaterials.find(um => um.title === fileItem.file.name);
      if (!uploadingMaterial) {
        uploadingMaterial = {
          title: fileItem.file.name,
          file_size: fileItem.file.size,
          extension: fileItem.file.type,
          percent_processed: progress,
        };
        this.uploadingMaterials.push(uploadingMaterial);
      } else {
        uploadingMaterial.percent_processed = progress;
      }
    };
    this.uploader.onErrorItem = (fileItem, response, status) => {
      const uploadingMaterialIndex = this.uploadingMaterials.findIndex(um => um.title === fileItem.file.name);
      if (uploadingMaterialIndex) {
        this.uploadingMaterials.splice(uploadingMaterialIndex, 1);
      }

      const error = JSON.parse(response);
      this.toastService.info(error.message);
    };
    this.uploader.onCompleteAll = () => {
      this.getMaterials(false, false);
    };
  }

  showProcessingErrors(errors) {
    this.modalService.defaultModal({
      title: 'Processing errors',
      body: errors.map(e => e.message).join('<br>')
    })
  }

}
