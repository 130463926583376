<div class="nv-quasi-select inline">
    <button class="nv-btn small filled quaternary" data-icon-pl="&#xe067;" appActionMenuOrigin>{{placeholder}}</button>
    <ul appActionMenuDropdown class="dropdown nv-dropdown">
        <li *ngFor="let item of items">
            <p (click)="selectItem(item)">{{item.name}}</p>
            <div class="nv-dd-tools">
                <span class="edit" title="edit" *ngIf="editAction.observers.length" (click)="editItem(item)"></span>
                <span class="delete" title="edit" *ngIf="deleteAction.observers.length" (click)="deleteItem(item)"></span>
            </div><!-- /.tools -->
        </li>
    </ul>
</div>
<!-- /.nv-inline-block -->
