import { Component, HostBinding, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@navus/core/classes/location';
import { LocationService } from '@navus/core/services/location.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { User } from '@navus/core/classes/user';
import { UserService } from '@navus/core/services/user.service';
import { MeetingRoom } from '@navus/core/classes/meeting-room';
import { MeetingRoomService } from '@navus/core/services/meeting-room.service';
import { NavusMeetingComponent } from '@navus/ui/meeting/meeting.component';
import { takeWhile } from 'rxjs/operators';


@Component({
  selector: 'main [nv-livestreaming-details-page]',
  templateUrl: './livestreaming-details-page.component.html'
})
export class LivestreamingDetailsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;
  @ViewChild('meeting') meeting: NavusMeetingComponent; 

  organizationId: number;
  conferenceId: number;
  
  locationId: number;
  location: Location;
  locationForm: FormGroup;
  currentUser: User;
  meetingRoom: MeetingRoom;
  typeOptions = [
    { id: 'internal', name: 'Yes' },
    { id: 'external', name: 'No' },
  ];

  meetingRoomForm: FormGroup;

  meetingRoomEdit: boolean = false;
  savingMeetingRoom: boolean = false;
  loadingLocation: boolean = true;
  savingLocation: boolean = false;
  creatingMeetingRoomLivestream: boolean = false;
  unsavedChanges: boolean = false;

  subscriptions: Subscription[] = [];

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private locationService: LocationService,
    private userService: UserService,
    private toastService: ToastService,
    private modalService: ModalService,
    private meetingRoomService: MeetingRoomService,
  ) { }

  ngOnInit() {

    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.locationId = data.locationId;
        this.initForm();
        this.getLocation();
        this.getMeetingRoom();
      }
    );
    this.subscriptions.push(subscription);

    const currentUserSubscription = this.userService.currentUser
      .subscribe(
        (user) => {
          this.currentUser = user;
        },
        (error) => { }
      );
    this.subscriptions.push(currentUserSubscription);

    const changeSubscription = this.locationForm.valueChanges.subscribe(
      () => {
        this.unsavedChanges = true;
      }
    );
    this.subscriptions.push(changeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.locationForm = this.formBuilder.group({
      name: ['', Validators.required],
    });
    this.meetingRoomForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      entity_id: [this.locationId, [Validators.required]],
      entity_type: ['Location', [Validators.required]],
      room_name: ['', [Validators.required]],
      type: ['internal', [Validators.required]],
      max_participants: [10, [Validators.required]],
      rtmp_url: [''],
      rtmp_key: [''],
      playback_url: [''],
      status: ['online', [Validators.required]],
    });
    this.meetingRoomForm.get('title').valueChanges
      .subscribe(
        (value) => {
          this.meetingRoomForm.get('room_name')
            .setValue(value);
        }
      )

    this.unsavedChanges = false;
  }

  getLocation() {
    if (this.locationId) {
      this.loadingLocation = true;
      this.locationService.getConferenceLocation(this.conferenceId, this.locationId)
        .subscribe(
          (response) => {
            this.location = response['data'];
            this.locationForm.patchValue(this.location);
            this.loadingLocation = false;

            setTimeout(() => { this.unsavedChanges = false; }, 500);
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingLocation = false;
          }
        );
    } else {
      this.location = new Location();
      this.loadingLocation = false;
    }
  }

  openStudio() {
    const path: string = `/${this.organizationId}/ls/${this.conferenceId}/${this.locationId}`;
    const h: number = window.screen.availHeight;
    const w: number = window.screen.availWidth;
    window.open(window.location.origin + path, '', `height=${h},width=${w}`);
  }
  
  getMeetingRoom() {
    this.meetingRoomService
      .getConferenceMeetingRooms(this.conferenceId, {
        entity_type: 'Location',
        entity_id: this.locationId,
      })
      .subscribe(
        (response) => {
          const meetingRooms = response.data;
          this.meetingRoom = meetingRooms[0] || null;
          this.meetingRoomForm.patchValue(this.meetingRoom);
        },
        () => {}
      )
  }

  saveMeetingRoom() {
    this.savingMeetingRoom = true;
    if (this.meetingRoom) {
      this.meetingRoomService
        .updateMeetingRoom(this.meetingRoom.id, this.meetingRoomForm.value)
        .subscribe(
          () => {
            this.getMeetingRoom();
            this.meetingRoomEdit = false;
            this.savingMeetingRoom = false;
          },
          () => {
            this.savingMeetingRoom = false;
          }
        );

    } else {
      this.meetingRoomService
        .createConferenceMeetingRoom(this.conferenceId, this.meetingRoomForm.value)
        .subscribe(
          () => {
            this.getMeetingRoom();
            this.meetingRoomEdit = false;
            this.savingMeetingRoom = false;
          },
          () => {
            this.savingMeetingRoom = false;
          }
        );
    }

  }

  createRtmp() {
    if (this.meetingRoom) { 
      this.creatingMeetingRoomLivestream = true;
      this.meetingRoomService
      .createMeetingRoomLivestream(this.meetingRoom.id)
      .subscribe(
        (response: { data: any, meta: any }) => {
          this.meetingRoom = response.data;
          this.meetingRoomForm.patchValue(response.data);

          this.creatingMeetingRoomLivestream = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.creatingMeetingRoomLivestream = false;
        }
      );      
    }
  }

  copyRoomURL() {
    navigator.clipboard.writeText('https://meet.navus.io/' + this.meetingRoom.room_name);
    this.toastService.success('Room URL copied to the clipboard');
  }

  save() {
    if (this.locationForm.invalid) { return; }

    this.savingLocation = true;
    if (this.locationId) {
      this.locationService
        .updateConferenceLocation(this.conferenceId, this.locationId, this.locationForm.value)
        .subscribe(
          (response) => {
            this.location = response['data'];
            this.toastService.success('Location successfully saved.', {});
            this.unsavedChanges = false;
            this.savingLocation = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingLocation = false;
          }
        );
    } else {
      this.locationService
        .createConferenceLocation(this.conferenceId, this.locationForm.value)
        .subscribe(
          (response) => {
            this.location = response['data'];
            this.toastService.success('Location successfully created.', {});
            this.router.navigate([this.location.id], { relativeTo: this.route });
            this.savingLocation = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingLocation = false;
          }
        );
    }
  }

  close() {
    if (this.unsavedChanges) {
      this.modalService.defaultModal({
        title: 'Unsaved changes',
        body: 'Are you sure you want to leave the page without saving changes?',
        size: 'small',
        buttons: [
          {
            text: 'Stay',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Leave',
            color: 'primary',
            handler: () => {
              if (this.locationId) {
                this.router.navigate(['../..', 'livestreams'], { relativeTo: this.route });
              } else {
                this.router.navigate(['..', 'livestreams'], { relativeTo: this.route });
              }
            }
          }
        ]
      });
    } else {
      if (this.locationId) {
        this.router.navigate(['../..', 'livestreams'], { relativeTo: this.route });
      } else {
        this.router.navigate(['..', 'livestreams'], { relativeTo: this.route });
      }
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Location Deletion',
      body: 'Are you sure you want to delete this location?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.locationService.deleteConferenceLocation(this.conferenceId, this.locationId)
              .subscribe(
                () => {
                  this.close();
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

}
