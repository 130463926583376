import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nv-tab',
  templateUrl: './tab.component.html'
})
export class NavusTabComponent implements OnInit {
  @Input() title: string;
  @HostBinding('class.active') @Input() active: boolean;

  ngOnInit() {
    this.active = !!this.active;
  }
}
