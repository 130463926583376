import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class AuthorService {

  constructor(
    private apiService: ApiService
  ) { }

  getAuthors(params: any = {}, config: any = {}) {
    return this.apiService.get(`authors`, params, config);
  }

  getAuthor(authorId: number, params: any = {}) {
    return this.apiService.get(`authors/${authorId}`, params);
  }

  updateAuthor(authorId: number, params: any = {}) {
    return this.apiService.put(`authors/${authorId}`, params);
  }

  createAuthor(params: any = {}) {
    return this.apiService.post(`authors`, params);
  }

  deleteAuthor(authorId: number) {
    return this.apiService.delete(`authors/${authorId}`);
  }

  getConferenceAuthors(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/authors`, params);
  }

  getConferenceAuthor(conferenceId: number, authorId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/authors/${authorId}`, params);
  }

  updateConferenceAuthor(conferenceId: number, authorId: number, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/authors/${authorId}`, params);
  }

  createConferenceAuthor(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/authors`, params);
  }

  deleteConferenceAuthor(conferenceId: number, authorId: number) {
    return this.apiService.delete(`conferences/${conferenceId}/authors/${authorId}`);
  }

}
