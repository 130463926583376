<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Communication</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-8">
                <nv-button color="primary" (click)="getQuestions()">Refresh</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <form class="config-search-bar-w-filters" [formGroup]="formFilter" autocomplete="off">
        <div class="sbwf-port">
            <input type="text" placeholder="Search..."
                   formControlName="search_term" (change)="getQuestions()">
            <span class="sbwf-outline"></span>
        </div><!-- /.sbwf-port -->
    </form><!-- /.config-search-bar-w-filters -->
    <div class="nv-port spaced-bottom">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav vertical-break-tab">
                <ul>
                    <li>
                        <a class="active">Questions</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'communication', 'push-notifications']">Push Notifications</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'communication', 'templates']">Templates</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>

<div class="nv-config-content-port" nvInfiniteScroll (scrolled)="getQuestions(true)">
<!--    <div class="nv-global-loading on" [class.on]="questionLoading">
        <div class="nv-spinner"></div>
    </div>&lt;!&ndash; /.nv-global-loading &ndash;&gt;-->
    <section class="nv-section">
        <div class="nv-port">
            <div  *ngIf="pagination.total === 0">
                <div class="nv-empty-state">
                    <div class="img">
                        <img src="../../../../assets/empty-states/empty-state-sessions.svg" alt="">
                    </div>
                    <strong>Questions from your audience will appear here</strong>
                </div>
            </div>
            <table class="nv-data-table break-lap">
                <tr *ngFor="let question of questions">
                    <td class="dt-cell">
                        <div [innerHTML]="question.content"></div>
                        <small>
                            <a *ngIf="question.delegate_id" [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'delegate', question.delegate_id]">{{ question.author?.first_name }} {{ question.author?.last_name }}, </a>
                            <span *ngIf="!question.delegate_id">{{ question.author?.first_name }} {{ question.author?.last_name }}, </span>
                            <span *ngIf="question.author?.country">{{ question.author.country.name }}, </span>
                            <span *ngIf="question.author?.institution">{{ question.author?.institution }}, </span>
                            <span>{{ question.created_at | date:'dd.MM.yyyy HH:mm' }}</span>
                        </small>
                    </td><!-- /.dt-cell -->
                    <td class="dt-cell" style="vertical-align: middle; width: 160px;">
                        <small *ngIf="question.status === 'waiting_for_approval'" translate>Waiting For Approval</small>
                        <small *ngIf="question.status === 'approved'" translate>Approved</small>
                        <small *ngIf="question.status === 'rejected'" translate>Rejected</small>
                    </td><!-- /.dt-cell -->
                    <td class="dt-cell" style="vertical-align: middle; width: 250px;">
                        <ng-container *ngIf="question.status === 'waiting_for_approval'">
                            <nv-button size="small" (click)="approve(question)" translate>Approve</nv-button>
                            &nbsp;
                            <nv-button size="small" (click)="reject(question)" translate>Reject</nv-button>
                        </ng-container>
                        <ng-container *ngIf="question.status === 'approved'">
                            <nv-button size="small" (click)="reject(question)" translate>Reject</nv-button>
                        </ng-container>
                        <ng-container *ngIf="question.status === 'rejected'">
                            <nv-button size="small" (click)="approve(question)" translate>Approve</nv-button>
                        </ng-container>
                    </td><!-- /.dt-cell -->
                </tr>
            </table>
        </div><!-- /.nv-port -->
    </section><!-- /.nv-section -->
</div><!-- /.config-layout-lower-fixed -->
