<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Ad Platform</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-8">

            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
    <!--div class="nv-port spaced-bottom">
        <div class="nv-line-tabs guttered">
            <nav class="nv-tabs-nav">
                <ul>
                    <li>
                        <a class="active">Ad Management</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'ad', 'banner-management']">Banner Management</a>
                    </li>
                    <li>
                        <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'ad', 'integrations']">Integrations</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div-->
</div>
<div class="nv-config-content-port">
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row config-plates">
                <div class="nv-column desk-12 lap-12">
                    <div class="nv-column-content">
                        <div class="nv-row spaced config-plates clean gap-60 flow-center">
                            <div class="nv-column desk-8 lap-8">
                                <div class="nv-column-content">
                                    <h3 style="text-align: center;">Show sponsor messages on more than 20 digital interfaces</h3>
                                    <nv-slides class="ad-landing-type">
                                        <nv-slide style="text-align: center">
                                            <img [src]="'assets/banner-positions/desktop-homepage-banner' +  (hover ? 1 : '') + '.jpg'"
                                                 (mouseenter)="hover = true"
                                                 (mouseleave)="hover = false"
                                                 style="margin-bottom: -80px;" alt>
                                            <h4>DESKTOP - HOMEPAGE BANNER</h4>
                                            <p>
                                                <span>Dimensions: </span><strong>1200x148px</strong><br>
                                                <span>Accepted Ad Formats: </span><strong>GIF, JPEG, animated GIF, PNG</strong>
                                            </p>
                                        </nv-slide>
                                        <nv-slide style="text-align: center">
                                            <img [src]="'assets/banner-positions/desktop-hub-banner' +  (hover ? 1 : '') + '.jpg'"
                                                 (mouseenter)="hover = true"
                                                 (mouseleave)="hover = false"
                                                 style="margin-bottom: -100px;" alt>
                                            <h4>DESKTOP - HUB BANNER</h4>
                                            <p>
                                                <span>Dimensions: </span><strong>1200x148px (8:1 Aspect Ratio)</strong><br>
                                                <span>Accepted Ad Formats: </span><strong>GIF, JPEG, animated GIF, PNG</strong>
                                            </p>
                                        </nv-slide>
                                        <nv-slide style="text-align: center">
                                            <img [src]="'assets/banner-positions/desktop-search-banner' +  (hover ? 1 : '') + '.jpg'"
                                                 (mouseenter)="hover = true"
                                                 (mouseleave)="hover = false"
                                                 style="margin-bottom: -100px;" alt>
                                            <h4>DESKTOP - SEARCH BANNER</h4>
                                            <p>
                                                <span>Dimensions: </span><strong>1200x148px (8:1 Aspect Ratio)</strong><br>
                                                <span>Accepted Ad Formats: </span><strong>GIF, JPEG, animated GIF, PNG</strong>
                                            </p>
                                        </nv-slide>
                                        <nv-slide style="text-align: center">
                                            <img [src]="'assets/banner-positions/app-home-screen-banner' +  (hover ? 1 : '') + '.jpg'"
                                                 (mouseenter)="hover = true"
                                                 (mouseleave)="hover = false"
                                                 style="margin-bottom: -80px;" alt>
                                            <h4>APP - NAVUS HOME SCREEN BANNER</h4>
                                            <p>
                                                <span>Dimensions: </span><strong>1600x650px</strong><br>
                                                <span>Accepted Ad Formats: </span><strong>GIF, JPEG, animated GIF, PNG</strong>
                                            </p>
                                        </nv-slide>
                                        <nv-slide style="text-align: center">
                                            <img [src]="'assets/banner-positions/app-inner-screen-banner' +  (hover ? 1 : '') + '.jpg'"
                                                 (mouseenter)="hover = true"
                                                 (mouseleave)="hover = false"
                                                 style="margin-bottom: -50px;" alt>
                                            <h4>APP - INNER SCREENS BANNER</h4>
                                            <p>
                                                <span>Dimensions: </span><strong>1600x266px</strong><br>
                                                <span>Accepted Ad Formats: </span><strong>GIF, JPEG, animated GIF, PNG</strong>
                                            </p>
                                        </nv-slide>
                                        <nv-slide style="text-align: center">
                                            <img [src]="'assets/banner-positions/app-event-home-screen-banner' +  (hover ? 1 : '') + '.jpg'"
                                                 (mouseenter)="hover = true"
                                                 (mouseleave)="hover = false"
                                                 style="margin-bottom: -30px;" alt>
                                            <h4>APP - EVENT HOME SCREEN BANNER</h4>
                                            <p>
                                                <span>Dimensions: </span><strong>1600x650px</strong><br>
                                                <span>Accepted Ad Formats: </span><strong>GIF, JPEG, animated GIF, PNG</strong>
                                            </p>
                                        </nv-slide>
                                    </nv-slides>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li>Generate up to 100 impressions per attendee.</li>
                                        <li>Sell digital sponsorship spaces and make a profit</li>
                                        <li>Set a Share of Voice and rotate messages</li>
                                    </ul>
                                    <p>
                                        Use all your sponsorship spaces at this event without any limit. Upgrade for only
                                        <strong>€4.90.- per attendee</strong> for this event. Upgrade by contacting your
                                        Navus Eventer <a [routerLink]="['/o', organizationId, 'contact', 'event-maker-support']">here</a>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div><!-- /.config-layout-lower-fixed -->
