import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ConferenceService } from '@navus/core/services/conference.service';

@Component({
  selector: 'main [nv-ticket-type-list-page]',
  templateUrl: './ticket-type-list-page.component.html'
})
export class TicketTypeListPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;
  organizationId: number;
  conferenceId: number;
  ticketTypes: any[] = [];
  totalTickets: number = 0;

  pagination: { page?: number, perPage: number, total?: number } = {
    perPage: 30
  };
  sort: { field: string, direction: string } = {
    field: 'name',
    direction: 'asc'
  };
  selectedIds: number[] = [];

  ticketTypesLoading: boolean = false;
  optionsShown: number = null;

  formFilter: FormGroup;
  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private conferenceService: ConferenceService,
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      search_term: ['']
    });
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getTicketTypes();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getTicketTypes(append: boolean = false) {
    if (append && this.pagination.total && this.ticketTypes.length >= this.pagination.total) { return; }

    this.pagination.page = append ? this.pagination.page + 1 : 1;

    const params: any = {
      page: this.pagination.page,
      per_page: this.pagination.perPage,
      sort_by: this.sort.field,
      sort_direction: this.sort.direction,
    };
    if (this.formFilter.value.search_term !== '') {
      params.search_term = this.formFilter.value.search_term;
    }
    this.ticketTypesLoading = !append;
    this.conferenceService
      .getConferenceTicketTypes(this.conferenceId, params)
      .subscribe(
        (response: { data: any[], meta: any }) => {
          if (append) {
            this.ticketTypes = this.ticketTypes.concat(response.data);
          } else {
            const port = document.getElementsByClassName('nv-config-content-port');
            if (port && port.length > 0) {
              port[0].scrollTop = 0;
            }
            
            this.ticketTypes = response.data;
            this.totalTickets = response.meta.tickets_count;
          }
          this.ticketTypesLoading = false;
        },
        () => {
          this.ticketTypesLoading = false;
        }
      );
  }

  edit(ticketType: any) {
    this.router.navigate(['..', 'ticket-type', ticketType.id], {relativeTo: this.route});
  }

  close() {
    this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'landing']);
  }

  add() {
    this.router.navigate(['..', 'ticket-type'], {relativeTo: this.route});
  }

  delete(ticketType: any) {
    this.modalService.defaultModal({
      title: 'Ticket Type Deletion',
      body: 'Are you sure you want to delete this ticket type?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.conferenceService
              .deleteConferenceTicketType(this.conferenceId, ticketType.id)
              .subscribe(
                () => {
                  this.getTicketTypes();
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

  sortTicketTypes(field: string) {
    if (field === this.sort.field) {
      this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sort.field = field;
      this.sort.direction = 'asc';
    }
    this.getTicketTypes();
  }

  toggleSelected(ticketType: any) {
    const index = this.selectedIds.indexOf(ticketType.id);
    if (index !== -1) {
      this.selectedIds.splice(index, 1);
    } else {
      this.selectedIds.push(ticketType.id);
    }
  }

  selectAll() {
    this.selectedIds = [];
    for (const ticketType of this.ticketTypes) {
      this.selectedIds.push(ticketType.id);
    }
  }

  unselectAll() {
    this.selectedIds = [];
  }

  openOptions(ticketType: any) {
    event.stopPropagation();
    this.optionsShown = ticketType.id;
  }

  closeOptions() {
    event.stopPropagation();
    this.optionsShown = null;
  }

}
