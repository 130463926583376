import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Certificate } from "@navus/core/classes/certificate";
import { Conference } from "@navus/core/classes/conference";
import { Delegate } from "@navus/core/classes/delegate";
import { Presentation } from "@navus/core/classes/presentation";
import { User } from "@navus/core/classes/user";
import { CertificateService } from "@navus/core/services/certificate.service";
import { ConferenceService } from "@navus/core/services/conference.service";
import { PresentationService } from "@navus/core/services/presentation.service";
import { UserService } from "@navus/core/services/user.service";
import { ModalService } from "@navus/ui/modal/modal.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'main [nv-website-profile-delegate]',
  templateUrl: './delegate.component.html'
})
export class ProfileDelegateComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-profile-settings-body') get classView() { return true; }
  user: User;
  delegate: Delegate;
  delegateCertificate: any;
  conference: Conference;
  conferenceId: number;

  usingTickets = false;
  usingRegistration = false;
  ticketType = false;

  certificates: Certificate[] = [];
  certificate;

  loadingAccess: boolean = false;
  loadingAttendance: boolean = false;
  
  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private certificateService: CertificateService,
    private conferenceService: ConferenceService,
    private presentationService: PresentationService,
    private modalService: ModalService,
  ) { }

  ngOnInit() {
    this.conferenceId = this.route.parent?.parent?.parent?.snapshot.data.website?.active_conference_id;
    this.conference = this.route.parent?.parent?.parent?.snapshot.data.website?.conferences.find(c => c.id === this.conferenceId);

    const userSubscription = this.userService.currentUser
      .subscribe((user: User) => {
        if (user) {
          this.user = user;
          this.delegate = user.delegates.find(d => d.conference_id === this.conferenceId);

          this.usingTickets = this.conference.settings.delegate_registration?.ticketing;
          this.usingRegistration = this.conference.settings.delegate_registration?.enabled;
          if (this.delegate?.conference_ticket_type_id) {
            this.getTicketType();
          }
          this.getCertificates();
        }
      });
    this.subscriptions.push(userSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getTicketType() {
    if (!this.delegate) { return; }

    this.loadingAccess = true;
    this.conferenceService
        .getConferenceTicketType(this.conferenceId, this.delegate.conference_ticket_type_id)
        .subscribe(
          (response) => {
            this.ticketType = response.data;
            this.loadingAccess = false;
          },
          () => {
            this.loadingAccess = false;
          }
        )
  }

  getCertificates() {
    this.loadingAttendance = true;
    this.certificateService
        .getConferenceCertificates(this.conferenceId)
        .subscribe(
          (response: { data: Certificate[], meta: any }) => {
            this.certificates = response.data;
            if (this.certificates.length > 0) {
              this.certificate = this.certificates[0];
            }
            this.loadingAttendance = false;
            this.getDelegateCertificate();
          },
          () => {
            this.loadingAttendance = false;
          }
        );
  }

  getDelegateCertificate() {
    if (!this.delegate) { return; } 
    if (!this.certificate) { return; }

    this.loadingAttendance = true;
    this.certificateService
        .getConferenceDelegateCertificate(this.conferenceId, this.certificate.id, this.delegate.id)
        .subscribe(
          (response) => {
            this.delegateCertificate = response;
            this.loadingAttendance = false;
          },
          () => {
            this.loadingAttendance = false;
          }
        );
  }

  createDelegateCertificate() {
    if (!this.delegate) { return; } 
    if (!this.certificate) { return; }

    this.loadingAttendance = true;
    this.certificateService
        .createConferenceDelegateCertificate(this.conferenceId, this.certificate.id, this.delegate.id)
        .subscribe(
          (response) => {
            if (response.active) {
              this.getDelegateCertificate();
            } else {
              this.modalService.defaultModal({
                body: `
                  You are not meeting the criteria set by the organizer. 
                  If you think that it is a mistake, please try again later or write 
                  to the support.
                `
              })
            }
            this.loadingAttendance = false;
          },
          () => {
            this.modalService.defaultModal({
              body: `
                You are not meeting the criteria set by the organizer. 
                If you think that it is a mistake, please try again later or write 
                to the support.
              `
            })
            this.loadingAttendance = false;
          }
        );
  }

  downloadDelegateCertificate() {
    if (!this.delegate) { return; } 
    if (!this.certificate) { return; }

    this.certificateService
        .downloadConferenceDelegateCertificate(this.conferenceId, this.certificate.id, this.delegate.id)
        .subscribe();
  }
}
