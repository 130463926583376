<div class="nv-row gap-30 propagate" *ngIf="value">
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-input
                    label="Background"
                    [(ngModel)]="value.background"
                    (ngModelChange)="onChange(value)"
            ></nv-input>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-input
                    label="Big text"
                    [(ngModel)]="value.bigText"
                    (ngModelChange)="onChange(value)"
            ></nv-input>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-input
                    label="Smaller text"
                    [(ngModel)]="value.smallerText"
                    (ngModelChange)="onChange(value)"
            ></nv-input>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-input
                    label="Action button URL"
                    [(ngModel)]="value.actionButtonUrl"
                    (ngModelChange)="onChange(value)"
            ></nv-input>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-input
                    label="Action button text"
                    [(ngModel)]="value.actionButtonText"
                    (ngModelChange)="onChange(value)"
            ></nv-input>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-select
                    label="Spacing"
                    [(ngModel)]="value.spaced"
                    (ngModelChange)="onChange(value)"
                    [options]="spacingOptions"
            ></nv-select>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-checkbox
                    label="Open in a new tab"
                    [(ngModel)]="value.openInNewTab"
                    (ngModelChange)="onChange(value)"
            ></nv-checkbox>
        </div>
    </div>
</div>

