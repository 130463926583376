<div class="nv-row gap-30 propagate" *ngIf="value">
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-select
                    label="Type"
                    [options]="typeOptions"
                    [(ngModel)]="value.type"
                    (ngModelChange)="onChange(value)"
            ></nv-select>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-select
                    placeholder="All"
                    [multi]="true"
                    [options]="sponsorGroups"
                    [(ngModel)]="value.sponsorGroupIds"
                    (ngModelChange)="onChange(value)"
                    label="Sponsor groups"
            ></nv-select>
        </div>
    </div>
</div>
