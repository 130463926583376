import { Component, ComponentFactoryResolver, HostBinding, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { WebsitePage } from '@navus/core/classes/website-page';
import { CustomHtmlSectionComponent } from '../../sections/custom-html/custom-html-section.component';
import { PageSectionsDirective } from '../../directives/page-sections.directive';
import { SliderSectionComponent } from '../../sections/slider/slider-section.component';
import { ArticleListSectionComponent } from '../../sections/article-list/article-list-section.component';
import { RecommendationsSectionComponent } from '../../sections/recommendations/recommendations-section.component';
import { TwitterSectionComponent } from '../../sections/twitter/twitter-section.component';
import { SearchSectionComponent } from '../../sections/search/search-section.component';
import { SummarySectionComponent } from '../../sections/summary/summary-section.component';
import { AuthorListSectionComponent } from '../../sections/author-list/author-list-section.component';
import { LiveSuiteSectionComponent } from '../../sections/live-suite/live-suite-section.component';
import { LiveSuiteIframeSectionComponent } from '../../sections/live-suite-iframe/live-suite-iframe-section.component';
import { ConferenceListSectionComponent } from '../../sections/conference-list/conference-list-section.component';
import { ProgramPlannerSectionComponent } from '../../sections/program-planner/program-planner-section.component';
import { BannerSectionComponent } from '../../sections/banner/banner-section.component';
import { HeroSectionComponent } from '../../sections/hero/hero-section.component';
import { PromoSectionComponent } from '../../sections/promo/promo-section.component';
import { WebsiteService } from '@navus/core/services/website.service';
import { SponsorListSectionComponent } from '../../sections/sponsor-list/sponsor-list-section.component';
import { MediasiteSectionComponent } from '../../sections/mediasite/mediasite-section.component';
import { AttendanceCertSectionComponent } from '../../sections/attendance-cert/attendance-cert-section.component';
import { CustomSectionComponent } from '../../sections/custom/custom-section.component';
import { DelegateListSectionComponent } from '../../sections/delegate-list/delegate-list-section.component';
import { LeaderboardSectionComponent } from '../../sections/leaderboard/leaderboard-section.component';

@Component({
  selector: 'nv-custom-page',
  templateUrl: './custom-page.component.html'
})
export class CustomPageComponent implements OnInit, OnDestroy {
  @HostBinding('class') pageClass: string = '';
  @ViewChild(PageSectionsDirective, { static: true }) pageSections: PageSectionsDirective;
  page: WebsitePage;

  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private websiteService: WebsiteService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    const subscription = this.route.data
      .subscribe(
        (data) => {
          this.page = data.page;
          this.pageClass = `page-${this.page.id}`;

          const sections = data.page.sections.sort(
            (a, b) => {
              if (a.order < b.order) {
                return -1;
              }
              if (a.order > b.order) {
                return 1;
              }
              return 0;
            }
          );
          this.loadSections(sections);
        }
      );
    this.subscriptions.push(subscription);

    const pageSubscription = this.websiteService.currentWebsite
      .subscribe(
        (website) => {
          this.page = website.pages.find((page) => page.id === this.page.id);
          this.pageClass = `page-${this.page.id}`;

          if (this.page) {
            const sections = this.page.sections.sort(
              (a, b) => {
                if (a.order < b.order) {
                  return -1;
                }
                if (a.order > b.order) {
                  return 1;
                }
                return 0;
              }
            );
            this.loadSections(sections);
          }
        }
      );
    this.subscriptions.push(pageSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  loadSections(pageSections: WebsitePageSection[]) {
    this.pageSections.viewContainerRef.clear();

    for (const section of pageSections) {
      const component = this.createSectionComponent(section);
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
      const componentRef = this.pageSections.viewContainerRef.createComponent(componentFactory);
      (componentRef.instance)['data'] = section;
    }
  }

  createSectionComponent(section: WebsitePageSection): Type<any> {
    switch (section.type) {
      case 'attendanceCert':
        return AttendanceCertSectionComponent;
      case 'mediasite':
        return MediasiteSectionComponent;
      case 'hero':
        return HeroSectionComponent;
      case 'twitter':
        return TwitterSectionComponent;
      case 'recommendations':
        return RecommendationsSectionComponent;
      case 'articleList':
        return ArticleListSectionComponent;
      case 'slider':
        return SliderSectionComponent;
      case 'customHtml':
        return CustomHtmlSectionComponent;
      case 'custom':
        return CustomSectionComponent;
      case 'search':
        return SearchSectionComponent;
      case 'summary':
        return SummarySectionComponent;
      case 'authorList':
        return AuthorListSectionComponent;
      case 'sponsorList':
        return SponsorListSectionComponent;
      case 'liveSuite':
        return LiveSuiteSectionComponent;
      case 'liveSuiteIframe':
        return LiveSuiteIframeSectionComponent;
      case 'conferenceList':
        return ConferenceListSectionComponent;
      case 'programPlanner':
        return ProgramPlannerSectionComponent;
      case 'banner':
        return BannerSectionComponent;
      case 'promo':
        return PromoSectionComponent;
      case 'delegateList':
        return DelegateListSectionComponent;
      case 'leaderboard':
        return LeaderboardSectionComponent;
      default:
        return CustomHtmlSectionComponent;

    }
  }

}
