export class BannerSectionConfig {
  position: string;
  conference_id: number;
  showInfo: boolean = false;
  interval: number = 600;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
