<div class="nv-row gap-30 propagate" *ngIf="value">
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-input
                    type="number"
                    label="Limit"
                    [(ngModel)]="value.limit"
                    (ngModelChange)="onChange(value)"
            ></nv-input>
        </div>
    </div>
    <div class="nv-column desk-12">
        <div class="nv-column-content">
            <nv-input
                    type="number"
                    label="Maximum number of columns"
                    [(ngModel)]="value.maxColumns"
                    (ngModelChange)="onChange(value)"
            ></nv-input>
        </div>
    </div>
</div>
