import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalButton } from '../classes/modal-button';

@Component({
    selector: 'nv-confirm',
    templateUrl: './confirm.component.html'
})

export class NavusConfirmComponent implements OnInit {

    @Input() title: string = '';
    @Input() body: string;
    @Input() buttons: ModalButton[];
    @Input() showTitle: boolean = true;
    @Input() size: string = 'small';

    constructor(
        public activeModal: NgbActiveModal,
    ) {}

    ngOnInit(): void {
        if (this.title === '') {this.showTitle = false; }
    }

    close() {
        this.activeModal.dismiss();
    }

    onClick(button) {
        if (button.role === 'cancel') {
            this.activeModal.dismiss();
        }
        if (button.handler) {
            button.handler();
            this.activeModal.close();
        }
    }

}
