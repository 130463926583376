<div class="nv-modal small">
    <div class="nv-modal-header">
        <h4 class="nv-modal-title" translate>Biography</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body" *ngIf="author">
        <div class="nv-speaker-teaser">
            <div class="st-svatar">
                <a title="{{ author?.name }}">
                    <span nvBackgroundImage [image]="author.image_url_thumb || this.author.image_url"></span>
                </a>
            </div><!-- /.st-svatar -->
            <h3>
                
                <a title="{{ author?.name }}">{{(author.title && author.title !== "") ? author.title + " " : ""}}{{ author.name }}</a>
            </h3>
            <p *ngIf="author.institution && author.institution !== ''">{{ author.institution }}</p>
        </div><!-- /.author-teaser -->
        <p [innerHTML]="biography"></p>
    </div>
</div>
