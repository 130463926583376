import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from './loading.service';
import { Subscription } from 'rxjs';


@Component({
    selector: '[nvLoading]',
    templateUrl: './loading.component.html'
})

export class NavusLoadingComponent implements OnInit, OnDestroy {
    // tslint:disable-next-line:no-input-rename
    @Input('nvLoading') config: { instance: string , type?: string };

    showLoading: boolean;
    subscription: Subscription;

    fullPage: boolean;

    constructor(
        private loadingService: LoadingService
    ) { }

    ngOnInit() {
         this.subscription = this.loadingService.loading
            .subscribe((config: any) => {
                if (!config) { return; }
                if (config.instances && this.config) {
                    this.showLoading = config.instances.indexOf(this.config.instance) !== -1;
                }
                if (config.type === 'fullPage') {
                    this.fullPage = true;
                }
            });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
