<section class="nv-section" nvInfiniteScroll (scrolled)="getQuestions(true)">
    <div class="nv-port">
        <nv-button color="nv-btn small accent1" (click)="getQuestions()">Refresh</nv-button><br><br>
        <div  *ngIf="pagination.total === 0">
            <div class="nv-empty-state">
                <div class="img">
                    <img src="../../assets/empty-states/empty-state-sessions.svg" alt="">
                </div>
                <strong>Questions from your audience will appear here</strong>
            </div>
        </div>
        <table class="nv-data-table break-lap" >
            <tr *ngFor="let question of questions">
                <td class="dt-cell">
                    <div [innerHTML]="question.content"></div>
                    <small>
                        <a *ngIf="question.delegate_id" [routerLink]="['/o', organizationId, 'conference', conferenceId, 'attendees', 'delegate', question.delegate_id]">{{ question.author?.first_name }} {{ question.author?.last_name }}, </a>
                        <span *ngIf="!question.delegate_id">{{ question.author?.first_name }} {{ question.author?.last_name }}, </span>
                        <span *ngIf="question.author?.country">{{ question.author.country.name }}, </span>
                        <span *ngIf="question.author?.institution">{{ question.author?.institution }}, </span>
                        <span>{{ question.created_at | date:'dd.MM.yyyy HH:mm' }}</span>
                    </small>
                </td><!-- /.dt-cell -->
            </tr>
        </table>
    </div><!-- /.nv-port -->
</section><!-- /.nv-section -->