<div class="onboardig-page-wrap">
    <header class="onboarding-header">
        <div class="oh-logo">
            <a><img src="../../assets/logo-textless.png" alt="NAVUS"></a>
        </div><!-- /.oh-logo -->
        <div class="oh-step-label">
            <h2>Setup the profile of your organization</h2>
        </div><!-- /.oh-step-label -->
        <div class="oh-step">
            <p>{{ step }} of 3</p>
        </div><!-- /.oh-step -->
        <span class="oh-progress-bar"><em [style.width]="(step / 3 * 100) + '%'"></em></span>
    </header><!-- /.onboarding-header -->


    <!-- CREATE ORGANIZATION -->
    <section class="nv-section onboarding-body guttered" *ngIf="step === 1">
        <div class="nv-port">
            <div class="nv-row spaced v-middle flow-center">
                <div class="nv-column desk-6 lap-8 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-onboarding-step-chunk">
                            <div class="nv-onb-icon">
                                <img src="../../assets/gfx-onboarding-icon-create-organization.svg" alt="Create Organization">
                            </div><!-- /.nv-onb-icon -->
                            <h3 class="medium">Let’s get started</h3>
                            <form class="nv-row gap-24 propagate" [formGroup]="organizationForm">
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <h5>Enter the name of your organization</h5>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <nv-input formControlName="name"
                                                  label="Organization Name"
                                        ></nv-input>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <nv-button color="secondary"
                                                   class="fw radius-high"
                                                   (click)="createOrganization()"
                                                   [loading]="creatingOrganization"
                                                   [disabled]="organizationForm.invalid">Continue</nv-button>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                            </form><!-- /nv-row -->
                        </div><!-- /.nv-onboarding-step-chunk -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.onboarding-body -->

    <!-- SELECT INDUSTRY -->
    <section class="nv-section onboarding-body guttered" *ngIf="step === 2">
        <div class="nv-port">
            <div class="nv-row large-spaced v-middle flow-center">
                <div class="nv-column desk-6 lap-8 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-onboarding-step-chunk">
                            <h3 class="medium">Which of these best describes your organization?</h3>
                            <p>SELECT INDUSTRY TYPE</p>
                            <div class="nv-cats-cloud">
                                <a *ngFor="let industry of industries"
                                   (click)="toggleIndustry(industry)"
                                   [class.active]="selectedIndustries.includes(industry)"
                                >{{ industry }}</a>
                            </div><!-- /.nv-cats-cloud -->
                            <div class="nv-row gap-24 spaced-top space-between">
                                <div class="nv-column desk-initial v-middle">
                                    <div class="nv-column-content">
                                        <div class="bb-side-label">
                                            <a [routerLink]="['/o', organizationId, 'setup', 'event']">Skip for now</a>
                                        </div>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column desk-auto -->
                                <div class="nv-column desk-initial">
                                    <div class="nv-column-content">
                                        <div class="nv-btn-box flow-end gap-20">
                                            <nv-button color="secondary"
                                                       (click)="setIndustry()">Continue</nv-button>
                                        </div><!-- /.nv-btn-box -->
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                            </div><!-- /.nv-row -->
                        </div><!-- /.nv-onboarding-step-chunk -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.onboarding-body -->

    <!-- CREATE EVENT -->
    <section class="nv-section onboarding-body guttered" style="overflow-x: auto" *ngIf="step === 3">
        <div class="nv-port">
            <div class="nv-row spaced v-middle flow-center">
                <div class="nv-column desk-6 lap-8 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-onboarding-step-chunk">
                            <div class="nv-onb-icon">
                                <img src="../../assets/empty-state-events.svg" alt="Create Event">
                            </div><!-- /.nv-onb-icon -->
                            <h3 class="medium">Create your first event</h3>
                            <form class="nv-row gap-24 propagate" [formGroup]="conferenceForm">
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <h5>Enter Event Name / Title</h5>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <nv-input formControlName="name"
                                                  label="Event Name"
                                        ></nv-input>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <nv-input formControlName="website"
                                                  label="Event Website (optional)"
                                        ></nv-input>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <h5>Enter Date & Location</h5>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-6">
                                    <div class="nv-column-content">
                                        <nv-datepicker formControlName="starts_at"
                                                       label="Event start"
                                                       type="calendar"
                                        ></nv-datepicker>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-6">
                                    <div class="nv-column-content">
                                        <nv-datepicker formControlName="ends_at"
                                                       label="Event end"
                                                       type="calendar"
                                        ></nv-datepicker>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <nv-select formControlName="country_id"
                                                   label="Country"
                                                   [required]="true"
                                                   [options]="filteredCountries"
                                                   [showSearch]="true"
                                                   (searched)="filterCountries($event)"
                                        ></nv-select>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <h5>Event Size</h5>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-4">
                                    <div class="nv-column-content">
                                        <nv-radio label="1-50"></nv-radio>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-4">
                                    <div class="nv-column-content">
                                        <nv-radio label="50-200"></nv-radio>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-4">
                                    <div class="nv-column-content">
                                        <nv-radio label="> 200"></nv-radio>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <div class="nv-row gap-24 spaced-top space-between">
                                            <div class="nv-column desk-initial v-middle">
                                                <div class="nv-column-content">
                                                    <div class="bb-side-label">
                                                        <a [routerLink]="['/']">Skip for now</a>
                                                    </div>
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /.nv-column desk-auto -->
                                            <div class="nv-column desk-initial">
                                                <div class="nv-column-content">
                                                    <div class="nv-btn-box flow-end gap-20">
                                                        <nv-button color="secondary"
                                                                   (click)="createConference()"
                                                                   [disabled]="conferenceForm.invalid">Continue</nv-button>
                                                    </div><!-- /.nv-btn-box -->
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /.nv-column -->
                                        </div><!-- /.nv-row -->
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                            </form><!-- /nv-row -->
                        </div><!-- /.nv-onboarding-step-chunk -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.onboarding-body -->

</div><!-- /.onboardig-page-wrap -->
