<div class="nv-global-loading on" [class.on]="loading">
    <div class="nv-spinner"></div>
</div>
<ng-container *ngIf="!meetingRoom">
    <ng-container *ngIf="!createDisabled && canCreate">
        <p *ngIf="createDescriptionText" [innerHtml]="createDescriptionText"></p>
        <nv-button (click)="showMeetingRoomDetails()">{{ createButtonText }}</nv-button>
    </ng-container>
    <ng-container *ngIf="!canCreate">
        <!-- <p [innerHtml]="notCreatedDescriptionText"></p> -->
    </ng-container>
</ng-container>
<ng-container *ngIf="meetingRoom">
    <ng-container *ngIf="!participant">
        <ng-container *ngIf="canJoin">
            <nv-button (click)="join()">{{ joinButtonText }}</nv-button>
        </ng-container>
        <ng-container *ngIf="!canJoin">
            <p [innerHtml]="cannotJoinDescriptionText"></p>
        </ng-container>   
    </ng-container>
    <ng-container *ngIf="participant">
        <div id="meet" class="nv-meet-video-box"></div>
    </ng-container>
   <ng-container *ngIf="streamingControls && participant?.moderator && meetingRoom.rtmp_key">
       <div class="streaming-controls">
           <nv-button *ngIf="!streaming" size="small" color="primary" (click)="startLiveStream()">Start streaming</nv-button>
           <nv-button *ngIf="streaming" size="small" color="secondary" (click)="stopLiveStream()">Stop streaming</nv-button>
       </div>
   </ng-container>
</ng-container>
