import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConferenceService } from '@navus/core/services/conference.service';
import { ToastService } from '@navus/ui/toast/toast.service';

@Component({
  selector: 'nv-program-modal',
  templateUrl: './program-modal.component.html'
})
export class ProgramModalComponent implements OnInit {
  @Input() organizationId: number = null;
  @Input() conferenceId: number = null;

  programForm: FormGroup;
  creatingProgram: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    private conferenceService: ConferenceService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.programForm = this.formBuilder.group({
      days : this.formBuilder.array([
        this.formBuilder.group({
          date: ['', [Validators.required]],
          start_time: ['', [Validators.required]],
          end_time: ['', [Validators.required]]
        })
      ]),
      locations: this.formBuilder.array([
        this.formBuilder.group({
          name: ['', [Validators.required]],
          color: ['']
        })
      ])
    });

  }

  addDay() {
    const days = this.programForm.controls.days as FormArray;
    const newDay = this.formBuilder.group({
      date: ['', [Validators.required]],
      start_time: ['', [Validators.required]],
      end_time: ['', [Validators.required]]
    });
    days.push(newDay);
  }

  removeDay(dayIndex) {
    const days = this.programForm.controls.days as FormArray;
    days.removeAt(dayIndex);
  }

  addLocation() {
    const locations = this.programForm.controls.locations as FormArray;
    const newLocation = this.formBuilder.group({
      name: ['', [Validators.required]],
      color: ['']
    });
    locations.push(newLocation);
  }

  removeLocation(locationIndex) {
    const locations = this.programForm.controls.locations as FormArray;
    locations.removeAt(locationIndex);
  }

  save() {
    this.creatingProgram = true;
    this.conferenceService
      .createProgram(this.conferenceId, this.programForm.value)
      .subscribe(
        () => {
          this.creatingProgram = false;
          this.activeModal.close();
        },
        (error) => {
          this.toastService.info(error.error.message);
          this.creatingProgram = false;
        }
      );
  }

  close() {
    this.activeModal.dismiss();
  }

  trackBy(index) {
    return index;
  }

}
