import { Routes } from '@angular/router';
import { OrganizationGuard } from '../../guards/organization.guard';
import { CampaignListPageComponent } from './pages/campaign-list/campaign-list-page.component';
import { LeadlinkLandingPageComponent } from './pages/leadlink-landing/leadlink-landing-page.component';
import { CampaignDetailsPageComponent } from './pages/campaign-details/campaign-details-page.component';

export const LeadlinkRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LeadlinkLandingPageComponent,
  },
  {
    path: 'campaigns',
    component: CampaignListPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Lead Link'
    }
  },
  {
    path: 'campaign/:campaignId',
    component: CampaignDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Lead Link Details'
    }
  },
  {
    path: 'campaign',
    component: CampaignDetailsPageComponent,
    canActivate: [OrganizationGuard],
    data: {
      title: 'Lead Link Details'
    }
  }
];
