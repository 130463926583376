import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class LiveSuiteService {

  constructor(
    private apiService: ApiService
  ) { }

  getLiveSuites(params: any = {}) {
    return this.apiService.get(`live_streaming_suites`, params);
  }

  getLiveSuite(liveSuiteCode: string, params: any = {}) {
    return this.apiService.get(`live_streaming_sources/${liveSuiteCode}`, params);
  }
}
