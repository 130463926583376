import { Component, Input } from '@angular/core';
import { Block } from '@navus/core/classes/block';
import { User } from '@navus/core/classes/user';
import { UserService } from '@navus/core/services/user.service';
import { ModalService } from '@navus/ui/modal/modal.service';

@Component({
  selector: 'nv-speaker-q-a',
  templateUrl: './speaker-q-a.component.html'
})
export class SpeakerQAComponent {
  @Input() public set blockId(value: number) {
    this._blockId = value;
  };
  @Input() public moderatingBlockIds: number[] = [];
  @Input() public availableBlocks: Block[];
  @Input() public conferenceId: number;
  @Input() public currentUserId: number;
  _blockId: number;

  statusOptions: any = [
    { id: 'all', name: 'All' },
    { id: 'true', name: 'Approved' },
    { id: 'false', name: 'Rejected' },
  ];
  approveStatus: string = 'all';

  constructor(
    private userService: UserService,
    private modalService: ModalService
  ) { }

  userDetails(userId: number) {
    this.userService
      .getChatUser(userId)
      .subscribe(
        (response) => {
          const user: User = response.data;
          this.modalService
            .defaultModal({
              body: `
                    <div>${user.first_name} ${user.last_name}</div>
                    <div>${user.institution}</div>
                    <div>${user.email}</div>
                  `
            });
        },
        () => {
        }
      );
  }
}
