import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { WebsiteService } from '@navus/core/services/website.service';
import { Website } from '@navus/core/classes/website';

@Component({
  selector: 'nv-program-page]',
  templateUrl: './program-page.component.html'
})
export class ProgramPageComponent implements OnInit, OnDestroy {
  conferenceId: number;

  subscriptions: Subscription[] = [];
    
  bannerSettings: any = {
    configuration: {
      position: 'Program'
    }
  };
  constructor(
    private route: ActivatedRoute,
    private websiteService: WebsiteService
  ) {  }

  ngOnInit() {
    const subscription = this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          this.conferenceId = website.active_conference_id;
          this.bannerSettings.configuration.conference_id =  this.conferenceId;
        }
      );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

}
