import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class NavusSearchPipe implements PipeTransform {
  transform(items: any[], searchValue: string, nestedProperties?: string[]): any[] {
    if (!items) {
      return [];
    }
    if (!searchValue) {
      return items;
    }
    searchValue = searchValue.toLowerCase();
    return items.filter(item => {
      const searchField = [];

      if (nestedProperties?.length) {
        nestedProperties.forEach((property) => {
          if (typeof item?.[property] === 'string') {
            searchField.push(item[property]);
          }
          if (typeof item?.[property] === 'object') {
            const validFields = Object.values(item[property]).filter(v => !!v);
            searchField.push(...validFields);
          }
        });
      }

      return searchField.some(field => field?.toLowerCase().indexOf(searchValue) > -1);
    });
  }
}
