<div class="nv-config-control-bar">
  <div class="cluf-port">
      <div class="config-title-n-tools">
          <div class="tnt-left">
              <h1 translate>Badge</h1>
          </div><!-- /.tnt-left -->
          <div class="tnt-right">
              <div class="nv-btn-box flow-end gap-10">
                <nv-button 
                    *ngIf="!badgeActive"
                    (click)="activate()" 
                    [loading]="recalculatingBadge"
                    [disabled]="!badgeId" translate
                >Activate</nv-button>
                <nv-button 
                    *ngIf="badgeActive"
                    (click)="deactivate()" 
                    [loading]="recalculatingBadge"
                    [disabled]="!badgeId" translate
                >Deactivate</nv-button>
                <nv-button 
                    (click)="recalculate()" 
                    [loading]="recalculatingBadge"
                    [disabled]="!badgeId" translate
                >Recalculate</nv-button>
                <a (click)="delete()" *ngIf="badgeId" translate class="nv-btn secondary medium">Delete</a>
                <nv-button (click)="close()" color="passive" translate>Close</nv-button>
                <nv-button (click)="save()" [loading]="savingBadge"
                           [disabled]="badgeForm.invalid" translate>Save
                </nv-button>
              </div><!-- /.nv-btn-box flow-end gap-6 -->
          </div><!-- /.tnt-right -->
      </div><!-- /.config-title-n-tools -->
  </div>
  <div class="nv-port spaced-bottom">
      <div class="nv-line-tabs guttered">
          <nav class="nv-tabs-nav vertical-break-tab">
              <ul>
                <li>
                  <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'badges']">Leaderboard</a>
                </li>
                <li>
                    <a class="active">Badges</a>
                </li>
                <li>
                    <a [routerLink]="['/o', organizationId, 'conference', conferenceId, 'badges', 'instructions']">Instructions</a>
                </li>
              </ul>
          </nav>
      </div>
  </div>
</div>
<div class="nv-config-content-port">
  <div class="nv-global-loading on" [class.on]="loadingBadge">
      <div class="nv-spinner"></div>
  </div><!-- /.nv-global-loading -->
  <div class="nv-section">
      <div class="nv-port">
          <div class="nv-row config-plates gap-24 small-spaced-top">
              <div class="nv-column desk-12">
                  <div class="nv-column-content">
                      <div class="nv-row gap-24">
                            <div class="nv-column desk-6 tab-12">
                                <div class="nv-column-content">
                                    <div class="nv-row gap-30 small-spaced-top propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Basic Information</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <form [formGroup]="badgeForm">
                                                    <nv-input
                                                        formControlName="name"
                                                        label="Name"
                                                    ></nv-input>
                                                </form>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                    </div>
                                </div>
                            </div>
                      </div>
                      <div class="nv-row gap-24" style="overflow: hidden">
                            <div class="nv-column desk-12">
                                <nv-tabs>
                                    <nv-tab [title]="'Levels'">
                                        <form [formGroup]="badgeForm">
                                            <ng-container formArrayName="badge_levels">
                                                <ng-container *ngFor="let item of badgeForm.controls.badge_levels['controls']; let indexLevel = index; trackBy: trackBy">
                                                    <div class="nv-row gap-30 spaced-top propagate"
                                                        style="border: 1px solid rgba(0, 0, 0, 0.15); border-radius: 4px; margin: 0 0 16px;">
                                                        <ng-container formGroupName="{{indexLevel}}">
                                                            <div class="nv-column">
                                                                <div class="nv-column-content">
                                                                    <h2>{{ indexLevel + 1 }}.</h2>
                                                                </div>
                                                            </div> 
                                                            <div class="nv-column desk-4">
                                                                <div class="nv-column-content">
                                                                    <h4>Details</h4>
                                                                    <nv-input
                                                                        formControlName="name"
                                                                        label="Name"
                                                                    ></nv-input>
                                                                    <br>
                                                                    <nv-file
                                                                        [hideFilename]="true"
                                                                        formControlName="image_url"
                                                                        [organizationId]="organizationId"
                                                                    ></nv-file>
                                                                </div><!-- /.nv-column-content -->
                                                            </div><!-- /.nv-column -->   
                                                            <div class="nv-column desk-6">
                                                                <div class="nv-column-content">
                                                                    <h4>Condition</h4>
                                                                    <div class="nv-row gap-16 propagate" formGroupName="condition">
                                                                        <div class="nv-column desk-9">
                                                                            <div class="nv-column-content">
                                                                                <nv-select
                                                                                    formControlName="condition_id"
                                                                                    label="Condition"
                                                                                    [options]="conditionOptions"
                                                                                ></nv-select>
                                                                            </div>
                                                                        </div>
                                                                        <div class="nv-column desk-3">
                                                                            <div class="nv-column-content">
                                                                                <nv-input
                                                                                    formControlName="goal"
                                                                                    label="goal"
                                                                                ></nv-input>
                                                                            </div>
                                                                        </div>
                                                                        <!-- <div class="nv-column desk-6">
                                                                            <div class="nv-column-content">
                                                                                <nv-input
                                                                                    formControlName="action_name"
                                                                                    label="action_name"
                                                                                ></nv-input>
                                                                            </div>
                                                                        </div>
                                                                        <div class="nv-column desk-6">
                                                                            <div class="nv-column-content">
                                                                                <nv-input
                                                                                    formControlName="action_type"
                                                                                    label="action_type"
                                                                                ></nv-input>
                                                                            </div>
                                                                        </div>
                                                                        <div class="nv-column desk-6">
                                                                            <div class="nv-column-content">
                                                                                <nv-input
                                                                                    formControlName="entity_type"
                                                                                    label="entity_type"
                                                                                ></nv-input>
                                                                            </div>
                                                                        </div>
                                                                        <div class="nv-column desk-6">
                                                                            <div class="nv-column-content">
                                                                                <nv-input
                                                                                    formControlName="entity_id"
                                                                                    label="entity_id"
                                                                                ></nv-input>
                                                                            </div>
                                                                        </div>
                                                                        <div class="nv-column desk-6">
                                                                            <div class="nv-column-content">
                                                                                <nv-switch
                                                                                    formControlName="unique"
                                                                                    label="unique"
                                                                                ></nv-switch>
                                                                            </div>
                                                                        </div> -->
                                                                    </div>
                                                                    <br>
                                                                    <h4>Condition text</h4>
                                                                    <nv-text-editor
                                                                        [label]="'Condition text'"
                                                                        formControlName="condition_description"
                                                                        [organizationId]="organizationId"
                                                                    ></nv-text-editor>
                                                                    <br>
                                                                    <h4>Success message</h4>
                                                                    <nv-text-editor
                                                                        [label]="'Success message'"
                                                                        formControlName="success_message"
                                                                        [organizationId]="organizationId"
                                                                    ></nv-text-editor>
                                                                </div><!-- /.nv-column-content -->
                                                            </div><!-- /.nv-column -->   
                                                            <div class="nv-column desk-1">
                                                                <div class="nv-column-content">
                                                                    <nv-button 
                                                                        color="passive"
                                                                        size="small"
                                                                        iconPl="&#xe918;"
                                                                        [onlyIcon]="true"
                                                                        (click)="removeLevels(indexLevel)"
                                                                    ></nv-button>
                                                                </div>
                                                            </div> 
                                                        </ng-container>      
                                                    </div>                         
                                                </ng-container>                             
                                            </ng-container>
                                        </form>
                                        <div class="nv-row gap-30 propagate">
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <nv-button 
                                                        (click)="addLevel()"
                                                    >Add level</nv-button>
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /.nv-column -->   
                                        </div>
                                    </nv-tab>
                                </nv-tabs>                                
                            </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
