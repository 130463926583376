import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ConferenceService } from '@navus/core/services/conference.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '@navus/ui/modal/modal.service';
import { Color } from '@swimlane/ngx-charts';
import { SessionData } from '../models/sessionData';
import { TimeFormatPipe } from '@navus/ui/pipes/time-format.pipe';
import { Conference } from '@navus/core/classes/conference';

@Component({
  selector: 'main [nv-conference-dashboard-totals]',
  templateUrl: './conference-dashboard-totals.component.html',
})
export class ConferenceDashboardTotalsComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  conference: Conference;

  loadingOverallNumbers: boolean;
  overallNumbers: OverallNumbers;

  dateFrom: string;
  dateTo: string;

  chartColorScheme = {
    domain: ['#318AF1', '#2873A1', '#8ABAD6', '#32B799', '#279079', '#18707F', '#ED4649', '#F49092', '#AD465A', '#ED8747']
  } as Color;
  chartCardColor: string = '#232837';

  conferenceInNumbersChartData: { name: string, value: number }[] = [];

  materialsInNumbersChartData: { name: string, value: number }[] = [];

  timeSpentCardChartData: { name: string, value: number }[] = [];
  timeSpentPieChartData: { name: string, value: number }[] = [];

  participationInNumbersChartData: { name: string, value: number }[] = [];
  participationInNumbersLegend: { id?: number, name: string, value: string, color: string }[];
  participationInNumbersActiveEntries = [];

  loadingDetailedNumbers: boolean;
  detailedNumbers: DetailedNumbers;

  loadingWatchingSessions: boolean;
  watchingSessionData: SessionData[];

  sessionParticipantsChartData: { name: string, series: { name: string, value: number }[] }[];
  presentationParticipantsChartData: { name: string, series: { name: string, value: number }[] }[];
  sessionParticipantsLegend: { id?: number, name: string, value: string, color: string }[];
  presentationParticipantsLegend: { id?: number, name: string, value: string, color: string }[];

  sessionTimeSpentChartData: { name: string, series: { name: string, value: number }[] }[];
  presentationTimeSpentChartData: { name: string, series: { name: string, value: number }[] }[];
  sessionTimeSpentLegend: { id?: number, name: string, value: string, color: string }[];
  presentationTimeSpentLegend: { id?: number, name: string, value: string, color: string }[];

  selectedSessionParticipants: SessionData;
  selectedSessionTimeSpent: SessionData;
  lastRecordedDateTime: string;

  downloadingWatchingSessions = false;
  downloadingWatchingSessionsDetailed = false;
  downloadingAttendeesWatchingSessionsLive = false;

  private subscriptions: Subscription[] = [];

  constructor(private conferenceService: ConferenceService,
              private modalService: ModalService,
              private timeFormatPipe: TimeFormatPipe,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.conferenceService.getConference(this.conferenceId)
          .subscribe((response) => {
            this.conference = response.data;
            this.dateFrom = this.conference.starts_at;
            this.dateTo = this.conference.ends_at;

            this.getStatistics();
          }, (error) => {
            this.modalService.error({ errors: error });
          });
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getStatistics(): void {
    this.getOverallNumbers();
    this.getDetailedNumbers();
    this.getWatchingSessions();
  }

  onSelectSessionParticipants(event) {
    const chosenSession = typeof event === 'string' ? event : event.series;
    if (chosenSession) {
      this.selectedSessionParticipants = this.watchingSessionData
        .find(item => item.name === chosenSession);
      this.presentationParticipantsChartData = this.selectedSessionParticipants
        .presentations?.map(item => ({
          name: item.title,
          series: [
            { name: 'Total Live Participants', value: item.total_participants_live },
            { name: 'Total On Demand Participants', value: item.total_participants_on_demand }
          ]
        }));
      this.presentationParticipantsLegend = this.presentationParticipantsChartData[0].series
        .map((series, index) => ({
          name: series.name,
          value: '',
          color: this.chartColorScheme.domain[index % 10]
        }));
    }
  }

  onSelectSessionTimeSpent(event) {
    const chosenSession = typeof event === 'string' ? event : event.series;
    if (chosenSession) {
      this.selectedSessionTimeSpent = this.watchingSessionData
        .find(item => item.name === chosenSession);
      this.presentationTimeSpentChartData = this.selectedSessionTimeSpent
        .presentations?.map(item => ({
          name: item.title,
          series: [
            { name: 'Total Time spent Live', value: item.time_spent_live },
            { name: 'Total Time spent On Demand', value: item.time_spent_on_demand }
          ]
        }));
      this.presentationTimeSpentLegend = this.presentationTimeSpentChartData[0].series
        .map((series, index) => ({
          name: series.name,
          value: '',
          color: this.chartColorScheme.domain[index % 10]
        }));
    }
  }

  participationInNumbersLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.participationInNumbersActiveEntries = this.participationInNumbersChartData.filter(item => item.name === name);
  }

  participationInNumbersLabelDeactivate(item: any): void {
    this.participationInNumbersActiveEntries = [];
  }

  formatNumber = (value: number): any => this.transformToTime(value);

  transformToTime(item): string {
    if (item.value) {
      return this.timeFormatPipe.transform(item.value);
    } else {
      return this.timeFormatPipe.transform(item);
    }
  }

  applyDateRange(): void {
    this.getStatistics();
  }

  downloadWatchingSessions() {
    this.downloadingWatchingSessions = true;
    this.conferenceService.downloadWatchingSessions(this.conferenceId)
      .subscribe(
        () => {
          this.downloadingWatchingSessions = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.downloadingWatchingSessions = false;
        }
      );
  }

  downloadAttendeesWatchingSessionsLive() {
    this.downloadingAttendeesWatchingSessionsLive = true;
    this.conferenceService.downloadAttendeesWatchingSessionsLive(this.conferenceId)
      .subscribe(
        () => {
          this.downloadingAttendeesWatchingSessionsLive = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.downloadingAttendeesWatchingSessionsLive = false;
        }
      );
  }

  downloadWatchingSessionsDetailed() {
    this.downloadingWatchingSessionsDetailed = true;
    this.conferenceService.downloadWatchingSessionsDetailed(this.conferenceId)
      .subscribe(
        () => {
          this.downloadingWatchingSessionsDetailed = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.downloadingWatchingSessionsDetailed = false;
        }
      );
  }

  private getOverallNumbers(): void {
    this.loadingOverallNumbers = true;
    this.conferenceService.getConferenceNumbersOverall(this.conferenceId, {
      from_date: this.dateFrom,
      to_date: this.dateTo
    })
      .subscribe((response) => {
        this.overallNumbers = response.data;
        this.loadingOverallNumbers = false;

        if (this.overallNumbers && Object.keys(this.overallNumbers).length) {
          this.populateConferenceInNumbersChart();
          this.populateMaterialsInNumbersChart();
        }
      }, (error) => {
        this.loadingOverallNumbers = false;
        this.modalService.error({ errors: error });
      });
  }

  private populateConferenceInNumbersChart(): void {
    this.conferenceInNumbersChartData = [
      {
        name: 'Registrations',
        value: this.overallNumbers.total_registrations,
      },
      {
        name: 'Sessions',
        value: this.overallNumbers.number_of_sessions,
      },
      {
        name: 'Presentations',
        value: this.overallNumbers.number_of_presentations,
      },
      {
        name: 'Sponsors',
        value: this.overallNumbers.number_of_sponsors,
      }
    ];
  }

  private populateMaterialsInNumbersChart(): void {
    this.materialsInNumbersChartData = [
      {
        name: 'Webcasts',
        value: this.overallNumbers.number_of_webcast_materials,
      },
      {
        name: 'Slides',
        value: this.overallNumbers.number_of_main_materials,
      },
      {
        name: 'Posters',
        value: this.overallNumbers.number_of_poster_materials,
      }
    ];
  }

  private getDetailedNumbers(): void {
    this.loadingDetailedNumbers = true;
    this.conferenceService.getConferenceNumbersDetailed(this.conferenceId, {
      from_date: this.dateFrom,
      to_date: this.dateTo
    })
      .subscribe((response) => {
        this.detailedNumbers = response.data;

        if (this.detailedNumbers && Object.keys(this.detailedNumbers).length) {
          this.populateParticipationMeasuredInTimeSpent();
          this.populateParticipationMeasuredInNumbers();
        }
        setTimeout(() => {
          this.loadingDetailedNumbers = false;
        }, 2000);
      }, (error) => {
        this.loadingDetailedNumbers = false;
        this.modalService.error({ errors: error });
      });
  }

  private populateParticipationMeasuredInTimeSpent(): void {
    this.timeSpentCardChartData = [
      {
        name: 'Browsing website',
        value: this.detailedNumbers.time_spent,
      },
      {
        name: 'Watching Live',
        value: this.detailedNumbers.time_spent_live,
      },
    ];
    this.timeSpentPieChartData = [
      {
        name: 'Webcasts',
        value: this.detailedNumbers.time_spent_webcast,
      },
      {
        name: 'Slides',
        value: this.detailedNumbers.time_spent_main,
      },
      {
        name: 'Posters',
        value: this.detailedNumbers.time_spent_poster,
      },
    ];
  }

  private populateParticipationMeasuredInNumbers(): void {
    this.participationInNumbersChartData = [
      {
        name: 'In person (on-site)',
        value: this.detailedNumbers.total_in_person_participants,
      },
      {
        name: 'Virtually active',
        value: this.detailedNumbers.total_active_participants,
      },
      {
        name: 'Virtual Live',
        value: this.detailedNumbers.total_live_participants,
      },
      {
        name: 'Virtual On-demand',
        value: this.detailedNumbers.total_on_demand_participants,
      },
    ];
    this.participationInNumbersLegend = this.participationInNumbersChartData
      .map((series, index) => ({
        name: series.name,
        value: series.value.toString(),
        color: this.chartColorScheme.domain[index % 10]
      }));
  }

  private getWatchingSessions(): void {
    this.loadingWatchingSessions = true;
    this.conferenceService.getConferenceWatchingSessions(this.conferenceId, {
      from_date: this.dateFrom,
      to_date: this.dateTo
    })
      .subscribe((response) => {
        this.loadingWatchingSessions = false;
        this.watchingSessionData = response.data;

        if (response.last_recorded_date_time) {
          this.lastRecordedDateTime = response.last_recorded_date_time;
        }

        this.populateStackedCharts();
      }, (error) => {
        this.loadingWatchingSessions = false;
        this.modalService.error({ errors: error });
      });
  }

  private populateStackedCharts(): void {
    this.sessionParticipantsChartData = this.watchingSessionData
      .map(session => ({
        name: session.name,
        series: [
          { name: 'Total Live Participants', value: session.total_participants_live },
          { name: 'Total On Demand Participants', value: session.total_participants_on_demand }
        ]
      }));
    this.sessionParticipantsLegend = this.sessionParticipantsChartData[0]?.series
      .map((series, index) => ({
        name: series.name,
        value: '',
        color: this.chartColorScheme.domain[index % 10]
      }));

    this.sessionTimeSpentChartData = this.watchingSessionData
      .map(session => ({
        name: session.name,
        series: [
          { name: 'Virtual Live', value: session.time_spent_live },
          { name: 'On-demand', value: session.time_spent_on_demand }
        ]
      }));
    this.sessionTimeSpentLegend = this.sessionTimeSpentChartData[0].series
      .map((series, index) => ({
        name: series.name,
        value: '',
        color: this.chartColorScheme.domain[index % 10]
      }));
  }
}

interface DetailedNumbers {
  time_spent: number;
  time_spent_live: number;
  time_spent_main: number;
  time_spent_on_demand: number;
  time_spent_poster: number;
  time_spent_webcast: number;
  total_active_participants: number;
  total_in_person_attendance: number;
  total_in_person_participants: number;
  total_live_participants: number;
  total_on_demand_participants: number;
  total_participants: number;
}

const DetailedNumbersNameMap = {
  time_spent: 'Time spent',
  time_spent_live: 'Time spent live',
  time_spent_main: 'Time spent main',
  time_spent_on_demand: 'Time spent on demand',
  time_spent_poster: 'Time spent poster',
  time_spent_webcast: 'Time spent webcast',
  total_active_participants: 'Total active participants',
  total_in_person_attendance: 'Total in person attendance',
  total_in_person_participants: 'Total in person participants',
  total_live_participants: 'Total live participants',
  total_on_demand_participants: 'Total on demand participants',
  total_participants: 'Total participants',
};

interface OverallNumbers {
  number_of_main_materials: number;
  number_of_materials: number;
  number_of_poster_materials: number;
  number_of_posters: number;
  number_of_presentations: number;
  number_of_sessions: number;
  number_of_sponsors: number;
  number_of_webcast_materials: number;
  total_registrations: number;
}

const OverallNumbersNameMap = {
  number_of_main_materials: 'Number of main materials',
  number_of_materials: 'Number of materials',
  number_of_poster_materials: 'Number of poster materials',
  number_of_posters: 'Number of posters',
  number_of_presentations: 'Number of presentations',
  number_of_sessions: 'Number of sessions',
  number_of_sponsors: 'Number of sponsors',
  number_of_webcast_materials: 'Number of webcast materials',
  total_registrations: 'Total registrations',
};
