import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../shared';
import { LivestreamingComponent } from './livestreaming.component';

const routes: Routes = [
    {
        path: ':organizationId/ls/:conferenceId/:locationId',
        component: LivestreamingComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)]
})

export class LivestreamingRoutingModule {
}
