import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class BadgeService {

  constructor(
    private apiService: ApiService
  ) { }

  getConferenceLeaderboard(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/delegate-badge-leaderboard`, params);
  }

  getConferenceBadges(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/badges`, params);
  }

  getConferenceBadge(conferenceId: number, badgeId: string, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/badges/${badgeId}`, params);
  }

  updateConferenceBadge(conferenceId: number, badgeId: string, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/badges/${badgeId}`, params);
  }

  recalculateConferenceBadge(conferenceId: number, badgeId: string, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/badges/${badgeId}/recalculate`, params);
  }

  activateConferenceBadge(conferenceId: number, badgeId: string, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/badges/${badgeId}/activate`, params);
  }

  deactivateConferenceBadge(conferenceId: number, badgeId: string, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/badges/${badgeId}/deactivate`, params);
  }

  createConferenceBadge(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/badges`, params);
  }

  deleteConferenceBadge(conferenceId: number, badgeId: string) {
    return this.apiService.delete(`conferences/${conferenceId}/badges/${badgeId}`);
  }

  getConferenceBadgeLevelsMine(conferenceId: number) {
    return this.apiService.get(`conferences/${conferenceId}/badge-levels/mine`);
  }

}