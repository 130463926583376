import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Delegate } from '@navus/core/classes/delegate';
import { DelegateService } from '@navus/core/services/delegate.service';
import { ConferenceService } from '@navus/core/services/conference.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { CountryService } from '@navus/core/services/country.service';
import { Country } from '@navus/core/classes/country';
import { Conference } from '@navus/core/classes/conference';
import { Certificate } from '@navus/core/classes/certificate';
import { CertificateService } from '@navus/core/services/certificate.service';

@Component({
  selector: 'main [nv-delegate-details-page]',
  templateUrl: './delegate-details-page.component.html'
})
export class DelegateDetailsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  conference: Conference;
  delegateId: number;
  delegate: Delegate;
  delegateForm: FormGroup;
  allCountries: Country[] = [];
  allTicketTypes: any[] = [];
  filteredCountries: Country[] = [];
  statusOptions: any[] = [
    { id: 'Fully registered', name: 'Fully registered' },
    { id: 'Pre-registered', name: 'Pre-registered' },
  ];
  registrationInfo: any[] = [];

  showRegistrationInfo: boolean = false;
  loadingDelegate: boolean = true;
  savingDelegate: boolean = false;
  unsavedChanges: boolean = false;

  certificates: Certificate[] = [];
  certificate;
  delegateCertificate: any;
  loadingAttendance = false;
  downloadingCertificate = false;
  showCertificate = false;

  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private delegateService: DelegateService,
    private conferenceService: ConferenceService,
    private countryService: CountryService,
    private toastService: ToastService,
    private modalService: ModalService,
    private certificateService: CertificateService,
  ) {
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  ngOnInit() {
    this.initForm();
    this.getCountries();
    this.getConference();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.delegateId = data.delegateId;
        this.getDelegate();
        this.getConferenceTicketTypes();
      }
    );
    this.subscriptions.push(subscription);

    const changeSubscription = this.delegateForm.valueChanges.subscribe(
      () => {
        this.unsavedChanges = true;
      }
    );
    this.subscriptions.push(changeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.delegateForm = this.formBuilder.group({
      code: [''],
      title: [''],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      country_id: [null],
      city: [''],
      address1: [''],
      zip: [''],
      website: [''],
      additional_info: [''],
      conference_ticket_type_id: [null],
      status: ['Fully registered'],
      contact_info: this.formBuilder.group({
        department: [''],
        institution: [''],
        city: [''],
        country_id: [''],
        zip: [''],
        address_line_1: [''],
        address_line_2: [''],
      })
    });
    this.unsavedChanges = false;
  }

  getDelegate() {
    if (this.delegateId) {
      this.loadingDelegate = true;
      this.delegateService.getConferenceDelegate(this.conferenceId, this.delegateId)
        .subscribe(
          (response) => {
            this.delegate = response['data'];
            if (this.delegate.contact_info === null) {
              this.delegate.contact_info = {};
            } // Fixes null issue

            this.delegateForm.patchValue(this.delegate);
            this.loadingDelegate = false;
            this.getRegistrationInfo();

            this.getCertificates();

            setTimeout(() => {
              this.unsavedChanges = false;
            }, 500);
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingDelegate = false;
          }
        );
    } else {
      this.delegate = new Delegate();
      this.loadingDelegate = false;
    }
  }

  getCountries() {
    this.countryService.getCountries()
      .subscribe(
        (response) => {
          this.allCountries = response.data;
          this.filteredCountries = response.data;
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }

  getConferenceTicketTypes() {
    this.conferenceService.getConferenceTicketTypes(this.conferenceId)
      .subscribe(
        (response) => {
          this.allTicketTypes = response.data;
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }

  getConference() {
    this.conferenceService.currentConference
      .subscribe(
        (conference) => {
          if (conference) {
            this.conference = conference;
            this.getRegistrationInfo();
          }
        }
      );
  }

  filterCountries(searchTerm: string) {
    if (searchTerm.length > 2) {
      this.filteredCountries = [];
      for (const country of this.allCountries) {
        if (country.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          this.filteredCountries.push(country);
        }
      }
    } else {
      this.filteredCountries = this.allCountries;
    }
  }

  save() {
    if (this.delegateForm.invalid) {
      return;
    }

    this.savingDelegate = true;
    if (this.delegateId) {
      this.delegateService.updateConferenceDelegate(this.conferenceId, this.delegateId, this.delegateForm.value)
        .subscribe(
          (response) => {
            this.delegate = response['data'];
            this.toastService.success('Delegate successfully saved.', {});
            this.unsavedChanges = false;
            this.savingDelegate = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingDelegate = false;
          }
        );
    } else {
      this.delegateService.createConferenceDelegate(this.conferenceId, this.delegateForm.value)
        .subscribe(
          (response) => {
            this.delegate = response['data'];
            this.toastService.success('Delegate successfully created.', {});
            this.router.navigate([this.delegate.id], { relativeTo: this.route });
            this.savingDelegate = false;
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingDelegate = false;
          }
        );
    }
  }

  close() {
    if (this.unsavedChanges) {
      this.modalService.defaultModal({
        title: 'Unsaved changes',
        body: 'Are you sure you want to leave the page without saving changes?',
        size: 'small',
        buttons: [
          {
            text: 'Stay',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Leave',
            color: 'primary',
            handler: () => {
              if (this.delegateId) {
                this.router.navigate(['../..', 'delegates'], { relativeTo: this.route });
              } else {
                this.router.navigate(['..', 'delegates'], { relativeTo: this.route });
              }
            }
          }
        ]
      });
    } else {
      if (this.delegateId) {
        this.router.navigate(['../..', 'delegates'], { relativeTo: this.route });
      } else {
        this.router.navigate(['..', 'delegates'], { relativeTo: this.route });
      }
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Delegate Deletion',
      body: 'Are you sure you want to delete this delegate?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.delegateService.deleteConferenceDelegate(this.conferenceId, this.delegateId)
              .subscribe(
                () => {
                  this.close();
                },
                (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

  sendRegistrationEmail() {
    this.modalService.defaultModal({
      title: 'Send Registration Confirmation Email',
      body: 'Are you sure you want to inform this delegate about successful registration?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Send',
          color: 'accent2',
          handler: () => {
            this.delegateService.sendRegistrationEmail(this.conferenceId, this.delegateId)
              .subscribe(
                () => {
                  this.modalService.defaultModal({
                    title: 'Email sent',
                    body: 'Registration Confirmation Email was successfully sent to the delegate.',
                    size: 'small',
                    buttons: [
                      {
                        text: 'Okay',
                        color: 'primary',
                        role: 'cancel'
                      }
                    ]
                  });
                },
                (error) => {
                  this.modalService.error({ errors: error });
                }
              );
          }
        }
      ]
    });
  }

  getRegistrationInfo() {
    if (this.conference && this.delegate) {
      const registrationFields = this.conference.settings.delegate_registration_fields;
      const registrationInfo = this.delegate.contact_info;

      this.registrationInfo = [];
      registrationFields.forEach(registrationField => {
        if (registrationInfo[registrationField.field]) {
          this.registrationInfo.push({
            key: registrationField.title,
            value: registrationInfo[registrationField.field],
          });
        }
        registrationField.field;
      });
    }
  }

  createDelegateCertificate() {
    if (!this.delegate) {
      return;
    }
    if (!this.certificate) {
      return;
    }

    this.loadingAttendance = true;
    this.certificateService
      .createConferenceDelegateCertificate(this.conferenceId, this.certificate.id, this.delegate.id)
      .subscribe(
        (response) => {
          if (response.active) {
            this.getDelegateCertificate();
          } else {
            this.modalService.defaultModal({
              body: `
                  You are not meeting the criteria set by the organizer. 
                  If you think that it is a mistake, please try again later or write 
                  to the support.
                `
            });
          }
          this.loadingAttendance = false;
        },
        () => {
          this.modalService.defaultModal({
            body: `
                You are not meeting the criteria set by the organizer. 
                If you think that it is a mistake, please try again later or write 
                to the support.
              `
          });
          this.loadingAttendance = false;
        }
      );
  }

  downloadDelegateCertificate() {
    if (!this.delegate) {
      return;
    }
    if (!this.certificate) {
      return;
    }
    this.downloadingCertificate = true;
    this.certificateService
      .downloadConferenceDelegateCertificate(this.conferenceId, this.certificate.id, this.delegate.id)
      .subscribe(
        () => {
          this.downloadingCertificate = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.downloadingCertificate = false;
        }
      );
  }

  showCertificateSection(): void {
    this.showCertificate = !this.showCertificate;
  }

  private getCertificates() {
    this.loadingAttendance = true;
    this.certificateService
      .getConferenceCertificates(this.conferenceId)
      .subscribe(
        (response: { data: Certificate[], meta: any }) => {
          this.certificates = response.data;
          if (this.certificates.length > 0) {
            this.certificate = this.certificates[0];
          }
          this.loadingAttendance = false;
          this.getDelegateCertificate();
        },
        () => {
          this.loadingAttendance = false;
        }
      );
  }

  private getDelegateCertificate() {
    if (!this.delegate) {
      return;
    }
    if (!this.certificate) {
      return;
    }

    this.loadingAttendance = true;
    this.certificateService
      .getConferenceDelegateCertificate(this.conferenceId, this.certificate.id, this.delegate.id)
      .subscribe(
        (response) => {
          this.delegateCertificate = response;
          this.loadingAttendance = false;
        },
        () => {
          this.loadingAttendance = false;
        }
      );
  }

}
