<!-- Conference Participation - not registered -->
<div class="nv-profile-settings-body-gutter" *ngIf="user && !delegate">
    <h3>Conference Participation</h3>
    <p>Sorry, you are not registered as a delegate on this event.</p>
    <p *ngIf="usingRegistration">Please click on the button bellow to register.</p>
    <div *ngIf="usingRegistration" class="foo-btn">
        <a [routerLink]="['/delegate-registration']" class="nv-btn accent1 large">Register for the event</a>
    </div><!-- /.foo-btn -->
</div><!-- /.nv-profile-settings-body-gutter -->

<!-- Conference Participation - registered user -->
<div class="nv-profile-settings-body-gutter" *ngIf="user && delegate">
    <h3>Conference Participation</h3>
    <p>You will find here all info about your participation at this event.</p>

    <div class="nv-profile-user-data-sheet nv-row v-stretch gap-24 propagate">
        <div class="nv-column desk-6 tab-12">
            <div class="nv-column-content">
                <div class="nv-profile-settings-box">
                    <div class="nv-global-loading on" [class.on]="loadingAccess">
                        <div class="nv-spinner"></div>
                    </div><!-- /.nv-global-loading -->
                    <header>
                        <h3>Your tickets and permissions</h3>
                    </header>
                    <div class="nv-profile-settings-box-body">
                        <div class="nv-tickets-access-types">
                            <ng-container *ngIf="!usingTickets">
                                <h4>
                                    <span>Ticket type</span>
                                    Registered Delegate
                                </h4>
                                <h4>
                                    <span>Access type</span>
                                    Full Access
                                </h4>
                            </ng-container>
                            <ng-container *ngIf="usingTickets">
                                <ng-container *ngIf="ticketType">
                                    <h4>
                                        <span>Ticket type</span>
                                        {{ ticketType.title }}
                                    </h4>
                                    <h4>
                                        <span>Access type</span>
                                        Full access
                                    </h4>
                                </ng-container>
                                <ng-container *ngIf="!ticketType">
                                    <h4>
                                        <span>Ticket type</span>
                                        Registered Delegate
                                    </h4>
                                    <h4>
                                        <span>Access type</span>
                                        No access
                                    </h4>
                                </ng-container>
                            </ng-container>
                        </div><!-- /.nv-tickets-access-types -->
                        <p *ngIf="usingTickets && !ticketType" style="text-align: center;">
                            <ng-container *ngIf="usingRegistration">
                                <nv-button [routerLink]="['/delegate-registration']">Buy ticket</nv-button>
                            </ng-container>
                            <ng-container *ngIf="!usingRegistration">
                                To gain access, write to the support
                            </ng-container>
                        </p>
                        <p class="nv-ps-link" *ngIf="false">
                            <a>See more info</a>
                        </p>
                    </div><!-- /.nv-profile-settings-box-body -->
                </div><!-- /.nv-profile-tickets-access-types -->
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->

        <div class="nv-column desk-6 tab-12" *ngIf="certificate">
            <div class="nv-column-content">
                <div class="nv-profile-settings-box">
                    <div class="nv-global-loading on" [class.on]="loadingAttendance">
                        <div class="nv-spinner"></div>
                    </div><!-- /.nv-global-loading -->
                    <header>
                        <h3>Your Attendance</h3>
                    </header>
                    <div class="nv-profile-settings-box-body"
                         *ngIf="delegateCertificate && delegateCertificate.active === 1">
                        <p *ngIf="conferenceId===19139">Your attendance has been confirmed:</p>
                        <p *ngIf="conferenceId!==19139">Based on your attendance, you collected:</p>
                        <ul class="attendance-numbers">
                            <li></li>
                            <li  *ngIf="conferenceId!==19139">
                                <strong>{{ delegateCertificate.cme_points }}</strong><span>Total {{certificate?.unit_name}}</span>
                            </li>
                            <li></li>
                        </ul><!-- /.attendance-numbers -->
                        <p class="nv-settings-request-cf">
                            <a
                                    class="nv-btn medium primary"
                                    (click)="createDelegateCertificate()"
                            >Recalculate {{certificate?.unit_name}}</a>
                            &nbsp;

                            <a *ngIf="certificate.active===1"
                               class="nv-btn medium primary"
                               (click)="downloadDelegateCertificate()"
                            >Download the Certificate of attendance</a>
                        </p>
                    </div><!-- /.nv-profile-settings-box-body -->
                    <div class="nv-profile-settings-box-body"
                         *ngIf="!(delegateCertificate && delegateCertificate.active === 1)">
                        <p>{{ certificate?.title }}</p>
                        <p class="nv-settings-request-cf">
                            <a
                                    class="nv-btn medium primary"
                                    (click)="createDelegateCertificate()"
                            >Request the Certificate of attendance</a>
                        </p>
                    </div><!-- /.nv-profile-settings-box-body -->
                </div><!-- /.nv-profile-tickets-access-types -->
            </div><!-- /.nv-column-content -->
        </div><!-- /.nv-column -->

        <ng-container *ngIf="conferenceId && delegate?.id">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <nv-delegate-live-attendance [conferenceId]="conferenceId" [delegateId]="delegate.id"
                                                 [templateForTooltip]="tooltipTemplate"></nv-delegate-live-attendance>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <nv-delegate-on-demand-attendance [conferenceId]="conferenceId" [delegateId]="delegate.id"
                                                      [templateForTooltip]="tooltipTemplate"></nv-delegate-on-demand-attendance>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <nv-delegate-on-site-attendance [conferenceId]="conferenceId" [delegateId]="delegate.id"
                                                    [templateForTooltip]="tooltipTemplate"></nv-delegate-on-site-attendance>
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </ng-container>

    </div><!-- /.nv-profile-user-data-sheet -->
</div><!-- /.nv-profile-settings-body-gutter -->


<ng-template #tooltipTemplate let-model="model">
    <div>
        {{ model.name }}
    </div>
    <div>
        {{ model.value | timeFormat }}
    </div>
</ng-template>
