import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class ArticleService {

  constructor(
    private apiService: ApiService
  ) { }

  getArticles(params: any = {}, config: any = {}) {
    return this.apiService.get(`articles`, params, config);
  }

  getArticle(articleId: number, params: any = {}) {
    return this.apiService.get(`articles/${articleId}`, params);
  }

  updateArticle(articleId: number, params: any = {}) {
    return this.apiService.put(`articles/${articleId}`, params);
  }

  createArticle(params: any = {}) {
    return this.apiService.post(`articles`, params);
  }

  deleteArticle(articleId: number) {
    return this.apiService.delete(`articles/${articleId}`);
  }

  getOrganizationArticles(organizationId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/articles`, params);
  }

  getOrganizationArticle(organizationId: number, articleId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/articles/${articleId}`, params);
    // return this.apiService.get(`articles/${articleId}`, { ...params, organizationId: organizationId });
  }

  updateOrganizationArticle(organizationId: number, articleId: number, params: any = {}) {
    return this.apiService.put(`organizations/${organizationId}/articles/${articleId}`, params);
    // return this.apiService.put(`articles/${articleId}`, { ...params, organizationId: organizationId });
  }

  createOrganizationArticle(organizationId: number, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/articles`, params);
    // return this.apiService.post(`articles`, { ...params, organizationId: organizationId });
  }

  deleteOrganizationArticle(organizationId: number, articleId: number) {
    return this.apiService.delete(`organizations/${organizationId}/articles/${articleId}`);
    // return this.apiService.delete(`articles/${articleId}`);
  }

}
