<div class="nv-live-suite-interactions-pane qa">
  <div class="nv-live-suite-interactions-toolbar nv-speaker-qa-toolbar">
    <h4>My agenda</h4>
    <div class="nv-interactions-tools">
        <div class="nv-chat-interaction-filters">
          <nv-select
            [(ngModel)]="selectedDay"
            [options]="dayOptions"
            label="Day"
          ></nv-select>
        </div>
    </div>
  </div>
  <!-- /.nv-ls-config-toolbox-header -->
  <div class="nv-ls-config-toolbox-body" style="height: 100%;">
    <div class="nv-ls-config-toolbox-body-port" style="height: 100%;">
      <div class="nv-ls-planner" style="padding: 0;">
        <!-- /.nv-ls-planner-filter -->
        <div class="nv-planner-item" *ngFor="let timeslotDistribution of timeslotDistributions; index as index">
          <ng-container *ngIf="timeslotDistribution.timeslot.date === selectedDay">
            <header class="nv-planner-header"
              [ngClass]="{ 'break-type': timeslotDistribution.block?.type === 'Break' || !(timeslotDistribution.block_id || timeslotDistribution.presentation_id) }">
              <div *ngIf="showTimeColumn" class="ph-time">
                <ng-container
                  *ngIf="index === 0 || timeslotDistribution.starts_at.substr(0, 16) !== timeslotDistributions[index - 1].starts_at.substr(0, 16)">
                  {{ timeslotDistribution.starts_at | dateFormat:'HH:mm' }}
                </ng-container>
              </div><!-- /.pt-time -->
              <div class="ph-body">
                <p class="ph-ls" *ngIf="timeslotDistribution.block?.streamable" translate>livestream</p>&nbsp;
                <p class="ph-ls" *ngIf="timeslotDistribution.timeslot?.location"
                  [style.background-color]="timeslotDistribution.timeslot.location?.color" translate>
                  {{timeslotDistribution.timeslot?.location.name}}</p>
                <h3>
                  <a *ngIf="!timeslotDistribution.block && !timeslotDistribution.presentation"
                    [innerHTML]="timeslotDistribution.default_title"></a>
                  <a *ngIf="timeslotDistribution.presentation"
                    (click)="presentationDetails(timeslotDistribution.presentation.id)" class="nv-clickable"
                    [innerHTML]="timeslotDistribution.presentation.title"></a>
                  <a (click)="togglePresentations(timeslotDistribution.block);"
                    [innerHTML]="timeslotDistribution.block?.name"></a>
                </h3>
                <div class="ph-meta">
                  <span class="phm-time-frame">
                    {{ timeslotDistribution.starts_at | dateFormat:'HH:mm' }} - {{ timeslotDistribution.ends_at |
                    dateFormat:'HH:mm' }}
                    <ng-container *ngIf="conference?.settings?.timezone?.title"> {{
                      conference?.settings?.timezone?.title }}</ng-container>
                    <ng-container *ngIf="timeslotDistribution?.block?.time_info"> | {{
                      timeslotDistribution?.block?.time_info }}</ng-container>
                  </span>
                  <span class="phm-cat" *ngIf="timeslotDistribution.block?.block_category_name != 'null'"
                    [class.nv-highlighted]="filter.blockCategoryId">
                    <em *ngIf="timeslotDistribution.block?.block_category_name"
                      [style.backgroundColor]="timeslotDistribution.block?.block_category_color!='null' ? timeslotDistribution.block?.block_category_color : '#cccccc'"></em>
                    {{ timeslotDistribution.block?.block_category_name }}
                  </span>
                </div><!-- /.ph-meta -->
                <h4 *ngIf="timeslotDistribution.block?.subtitle" style="font-weight: 400;"
                  [innerHtml]="timeslotDistribution.block?.subtitle">{{ timeslotDistribution.block?.subtitle }}</h4>
                <div class="nv-participant-row" *ngIf="timeslotDistribution.block?.chairpersons.length>0">
                  <label>Chaired By:</label>

                  <div class="nv-participant" *ngFor="let chairperson of timeslotDistribution.block.chairpersons">
                    <!--<div class="p-img"><span></span></div>-->
                    <span class="p-img" *ngIf="chairperson?.image_url_thumb">
                      <span
                        [style.backgroundImage]="chairperson?.image_url_thumb ? 'url('+ chairperson?.image_url_thumb +')' : ''"></span>
                    </span>
                    <div class="p-title-name">
                      <a (click)="showAuthor(chairperson)"
                        [innerHtml]="chairperson?.title ? chairperson?.title + '&nbsp;' + chairperson?.name : chairperson?.name"></a>
                    </div><!-- /.p-name -->
                  </div><!-- /.nv-participant -->
                </div><!-- /.nv-participant-row -->
              </div><!-- /.ph-body -->
            </header><!-- /.nv-planner-header -->
            <div class="nv-planner-sessions">
              <div *ngIf="blocksLoading.includes(timeslotDistribution.block?.id)" class="nv-loading"
                style="min-height: 120px;">
                <div class="nv-loading-visual" translate>Loading</div>
              </div>
              <ng-container *ngIf="!blocksLoading.includes(timeslotDistribution.block_id) && timeslotDistribution.block?.presentations">
                <ng-container *ngFor="let presentation of timeslotDistribution.block?.presentations">
                  <div class="ps-item" *ngIf="!presentation.hide">
                    <div class="psi-time">
                      {{ presentation.starts_at | dateFormat:'HH:mm' }}
                    </div>
                    <div class="psi-body">
                      <div class="nv-participant">
                        <div class="p-title-name">
                          <div class="psi-features">
                            <p class="psi-ls" *ngIf="presentation.streamable" translate>livestream</p>
                            <p class="psi-od" *ngIf="!presentation.streamable && presentation.webcast">on-demand</p>
                          </div><!-- /.psi-features -->
                          <h4>
                            <a (click)="presentationDetails(presentation.id)"
                              [innerHtml]="presentation.title"></a>
                          </h4>
                          <div class="nv-participants-box" *ngIf="presentation?.authors">
                            <p *ngFor="let speaker of presentation.authors; let isFirst = first;">
                              <span class="p-img" *ngIf="speaker.image_url_thumb">
                                <span
                                  [style.backgroundImage]="speaker.image_url_thumb ? 'url('+ speaker.image_url_thumb +')' : ''"></span>
                              </span>
                              <a (click)="showAuthor(speaker)">
                                <ng-container *ngIf="speaker.title">{{speaker.title}}&nbsp;</ng-container>
                                <span [innerHtml]="speaker.name"></span>
                              </a>
                            </p>
                          </div>

                          <div class="nv-participants-box"
                            *ngIf="!presentation?.authors && (speakers[presentation.id] && speakers[presentation.id].length > 0)">
                            <p *ngFor="let speaker of speakers[presentation.id]; index as i; let isFirst = first;">
                              <span class="p-img" *ngIf="speaker.image_url_thumb">
                                <span
                                  [style.backgroundImage]="speaker.image_url_thumb ? 'url('+ speaker.image_url_thumb +')' : ''"></span>
                              </span>
                              <span [innerHtml]="speaker"></span>
                            </p>
                          </div>
                          <p
                            *ngIf="!presentation?.authors && (!speakers[presentation.id] || speakers[presentation.id].length === 0)">
                            {{ presentation.speaker_name }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div><!-- /.nv-planner-sessions -->
          </ng-container>
        </div><!-- /.nv-planner-item -->
      </div><!-- /.nv-ls-planner -->
    </div><!-- /.nv-ls-config-toolbox-body-port -->
  </div><!-- /.nv-ls-config-toolbox-body -->
</div><!-- /.nv-ls-config-toolbox -->