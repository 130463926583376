import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConferenceService } from '@navus/core/services/conference.service';
import { Conference } from '@navus/core/classes/conference';
import { ModalService } from '@navus/ui/modal/modal.service';

@Component({
  selector: 'main [nv-conference-landing-page]',
  templateUrl: './conference-landing-page.component.html'
})
export class ConferenceLandingPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  conference: Conference;
  statistics: any;
  subscriptions: Subscription[] = [];
  toToItems: any[] = [];
  importingDemoData: boolean = false;
  demoData: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private conferenceService: ConferenceService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;

        this.getToDo();
      }
    );
    this.subscriptions.push(subscription);

    const conferenceSubscription = this.conferenceService.currentConference
      .subscribe(
        (conference: Conference) => {
          this.conference = conference;

          if (conference) {
            this.demoData = !!conference.processing['demo_data_created'];
          }
        },
        () => { }
      );
    this.subscriptions.push(conferenceSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  toggleStatus(toDoItem) {
    if (toDoItem.status === 'todo') {
      toDoItem.status = 'in_progress';
    } else if (toDoItem.status === 'in_progress') {
      toDoItem.status = 'done';
    } else if (toDoItem.status === 'done') {
      toDoItem.status = 'todo';
    }

    this.conferenceService.updateOrganizationConferenceToDo(this.organizationId, this.conferenceId, toDoItem.id, toDoItem)
      .subscribe();
  }

  close() {
    this.router.navigate(['/o', this.organizationId, 'landing']);
  }

  importDemoData() {
    this.importingDemoData = true;
    this.conferenceService.importOrganizationConferenceDemo(this.organizationId, this.conferenceId)
      .subscribe(
        (response) => {
          this.importingDemoData = false;
          this.demoData = true;
          this.modalService.defaultModal({
            body: 'Demo data successfully imported',
            size: 'small',
            buttons: [
              { text: 'Okay', color: 'primary', role: 'cancel' }
            ]
          });
        },
        (error) => {
          this.importingDemoData = false;
          this.modalService.error({ errors: error });
        }
      );
  }

  getToDo() {
    this.conferenceService.getOrganizationConferenceToDo(this.organizationId, this.conferenceId)
      .subscribe(
        (response) => {
          this.toToItems = response.data;
        },
        () => { }
      );
  }

  toggleDemoData() {
    if (this.demoData) {
      this.modalService.defaultModal({
        body: 'Are you sure you want to remove the demo data?',
        size: 'small',
        buttons: [
          {
            text: 'No',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Yes',
            color: 'accent2',
            handler: () => {
              this.conferenceService
                .removeOrganizationConferenceDemo(this.organizationId, this.conferenceId)
                .subscribe(
                  (response) => {
                    this.demoData = false;
                    this.modalService.defaultModal({
                      body: 'Demo data successfully removed',
                      size: 'small',
                      buttons: [
                        { text: 'Okay', color: 'primary', role: 'cancel' }
                      ]
                    });
                  },
                  () => { }
                );
            }
          }
        ]
      });
    } else {
      this.modalService.defaultModal({
        body: 'Are you sure you want to insert demo data?',
        size: 'small',
        buttons: [
          {
            text: 'No',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Yes',
            color: 'primary',
            handler: () => {
              this.conferenceService
                .importOrganizationConferenceDemo(this.organizationId, this.conferenceId)
                .subscribe(
                  (response) => {
                    this.demoData = true;
                    this.modalService.defaultModal({
                      body: 'Demo data successfully imported',
                      size: 'small',
                      buttons: [
                        { text: 'Okay', color: 'primary', role: 'cancel' }
                      ]
                    });
                  },
                  () => { }
                );
            }
          }
        ]
      });
    }
    }

  changeLogo() {
    this.modalService.openResource(this.organizationId)
      .then(
        (response) => {
          if (response) {
            this.conference.logo = response.url;
            this.conferenceService.updateOrganizationConference(this.organizationId, this.conferenceId, { logo: this.conference.logo })
              .subscribe(
                () => {
                  this.conferenceService.setCurrentConference(this.conferenceId, true).subscribe();
                }
              );
          }
        }
      )
      .catch();
  }
}
