import { NgModule } from '@angular/core';
import { NavusCoreModule } from '@navus/core/navus-core.module';
import { NavusUiModule } from '@navus/ui/navus-ui.module';
import { SharedModule } from '../../../shared';
import { DelegateListPageComponent } from './pages/delegate-list/delegate-list-page.component';
import { DelegateDetailsPageComponent } from './pages/delegate-details/delegate-details-page.component';
import { RegistrationSettingsPageComponent } from './pages/registration-settings/registration-settings-page.component';
import { TicketTypeListPageComponent } from './pages/ticket-type-list/ticket-type-list-page.component';
import { TicketTypeDetailsPageComponent } from './pages/ticket-type-details/ticket-type-details-page.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FileUploadModule } from 'ng2-file-upload';
import { CertificateListPageComponent } from './pages/certificate-list/certificate-list-page.component';
import { CertificateDetailsPageComponent } from './pages/certificate-details/certificate-details-page.component';
import { AttendancePageComponent } from './pages/attendance/attendance-page.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LiveAttendanceComponent } from './pages/attendance/live-attendance/live-attendance.component';
import { OnDemandAttendanceComponent } from './pages/attendance/on-demand-attendance/on-demand-attendance.component';
import { OnSiteAttendanceComponent } from './pages/attendance/on-site-attendance/on-site-attendance.component';
import { CustomChartLegendComponent } from './pages/attendance/custom-chart-legend/custom-chart-legend.component';

@NgModule({
  imports: [
    NavusCoreModule.forRoot(),
    NavusUiModule,
    SharedModule,
    DragDropModule,
    FileUploadModule,
    NgxChartsModule,
  ],
  declarations: [
    DelegateListPageComponent,
    DelegateDetailsPageComponent,
    RegistrationSettingsPageComponent,
    TicketTypeListPageComponent,
    TicketTypeDetailsPageComponent,
    CertificateListPageComponent,
    CertificateDetailsPageComponent,
    AttendancePageComponent,
    LiveAttendanceComponent,
    OnDemandAttendanceComponent,
    OnSiteAttendanceComponent,
    CustomChartLegendComponent
  ],
  entryComponents: [],
  providers: []
})
export class RegistrationModule {
}
