<ng-container *ngIf="!programLoading">
    <div class="nv-live-stage-config-pane-heading">
        <h1>Streaming Program</h1>
        <div class="nv-live-stage-config-pane-heading-tools">
            <nv-checkbox label="Hide finished" (change)="hideFinishedItems($event?.target?.checked)">
            </nv-checkbox>
        </div><!-- /.nv-live-stage-config-pane-heading-tools -->
    </div><!-- /.nv-live-stage-config-pane-heading -->
    <div class="nv-live-stage-tabs">
        <nv-tabs *ngIf="days.length">
            <nv-tab *ngFor="let day of days" title="{{ day | dateFormat:'MMMM' }} {{ day | dateFormat:'DD' }}">
                <div class="nv-ls-config-sessions">
                    <ng-container *ngFor="let timeslotDistribution of timeslotDistributions">
                        <div class="nv-ls-config-session-item" *ngIf="timeslotDistribution.timeslot.date === day">
                            <div class="nv-ls-session-item-meta">
                                <div class="nv-ls-session-item-heading">
                                    <p class="time">
                                        {{ timeslotDistribution.starts_at | dateFormat:'HH:mm' }}
                                        -
                                        {{ timeslotDistribution.ends_at | dateFormat:'HH:mm' }}
                                    </p>
                                    <h3 class="session">
                                        <a href="">Session</a>
                                        {{ timeslotDistribution.block.name }}
                                    </h3>
                                    <h5 class="chairs">Chairs:
                                        <span *ngFor="let chairperson of timeslotDistribution.block.chairpersons">
                                    {{ chairperson?.title ? chairperson?.title + '&nbsp;' + chairperson?.name : chairperson?.name }}
                                </span>
                                    </h5>
                                </div><!-- /.nv-ls-session-item-heading -->
                                <ul class="nv-ls-session-presentations">
                                    <li *ngFor="let presentation of timeslotDistribution.block.presentations">
                                        <div class="nv-ls-session-presentation-status">
                                            <ng-container
                                                    *ngIf="!(presentationLoading && presentation.id === selectedPresentation.id) else presentationLoader">
                                                    <span [ngClass]="currentPresentationId === presentation.id? 'live' : 'play'"
                                                          (click)="changeCurrentStreamingPresentation(presentation)"></span>
                                            </ng-container>
                                        </div>
                                        <div class="nv-ls-session-presentation-meta">
                                            <time>
                                                {{ presentation.starts_at | dateFormat:'HH:mm' }}
                                                -
                                                {{ presentation.ends_at | dateFormat:'HH:mm' }}
                                            </time>
                                            <h2>{{ presentation.title }}</h2>
                                            <div class="speakers" *ngIf="presentation.speaker_name">
                                                <span>Speakers:</span>
                                                <p>{{ presentation.speaker_name }}</p>
                                            </div><!-- /.speakers -->
                                            <div class="nv-ls-session-presentation-tools" *ngIf="presentation.webcasts?.length">
                                                <ng-container>
                                                    <span class="nv-ls-session-presentation-tool duration">Pre-recorded</span>
                                                </ng-container>
                                                <ng-container *ngFor="let webcast of presentation.webcasts">
                                                    <nv-checkbox class="nv-ls-session-presentation-tool"
                                                              [label]="webcast.title"
                                                              [value]="(webcastChecked[presentation.id]?.presentation?.id === presentation?.id && webcastChecked[presentation.id]?.webcast?.id === webcast.id)"
                                                              (click)="webcastCheckedChange({presentation: presentation, webcast: webcast}, $event)"></nv-checkbox>
                                                </ng-container>
                                            </div><!-- /.nv-ls-session-presentation-tools -->
                                        </div><!-- /.nv-ls-session-presentation-meta -->
                                    </li>
                                </ul><!-- /.nv-ls-session-presentations -->
                            </div><!-- /.nv-ls-session-item-meta -->
                            <div class="nv-ls-session-item-participants"
                                 *ngIf="timeslotDistribution.block?.participants?.length">
                                <h3>Participants</h3>
                                <div class="nv-ls-session-participant"
                                     *ngFor="let participant of timeslotDistribution.block.participants">
                                    <div class="participant">
                                        <h4>{{participant.speaker.name}}</h4>
                                        <p>{{participant.role | titlecase}}</p>
                                    </div><!-- /.participant -->
                                    <div class="nv-custom-toggle-checkbox">
                                        <label>
                                            <input type="checkbox"
                                                   [checked]="participant.status === 'in'"
                                                   (change)="updateSpeakerStatus(participant.speaker_id, $event)">
                                            <span class="nv-custom-toggle-checkbox-visual">
                                                <em class="off">Out</em>
                                                <em class="on">In</em>
                                            </span><!-- /.nv-custom-toggle-checkbox-visual -->
                                        </label>
                                    </div><!-- /.nv-custom-toggle-checkbox -->
                                </div><!-- /.nv-ls-session-participant -->
                                <div class="nv-ls-session-participant-tools">
                                    <p class="clear-all"><a href="">Clear all</a></p>
                                    <p class="invite-all-in"><a href="">Invite all in</a></p>
                                </div><!-- /.nv-ls-session-participant-tools -->
                            </div><!-- /.nv-ls-session-item-participants -->
                        </div><!-- /.nv-ls-config-session-item -->
                    </ng-container>

                </div><!-- /.nv-ls-config-sessions -->
            </nv-tab>
        </nv-tabs>
    </div><!-- /.nv-live-stage-tabs -->
</ng-container>

<ng-container *ngIf="programLoading">
    <div class="nv-ls-config-feature-panel">
        <div class="nv-ls-feature-panel-placeholder">
            <span class="nv-ls-feature-panel-loading"><em></em>Loading. Please wait.</span>
        </div><!-- /.nv-ls-feature-panel-placeholder -->
    </div><!-- /.nv-ls-config-feature-panel -->
</ng-container>

<ng-template #presentationLoader>
    <div class="nv-ls-config-feature-panel">
        <div class="nv-ls-feature-panel-placeholder">
            <span class="nv-ls-feature-panel-loading"><em></em></span>
        </div><!-- /.nv-ls-feature-panel-placeholder -->
    </div><!-- /.nv-ls-config-feature-panel -->
</ng-template>
