<div class="nv-config-control-bar">
    <div class="config-title-n-tools">
        <div class="tnt-left">
            <h1 translate>Event</h1>
        </div><!-- /.tnt-left -->
        <div class="tnt-right">
            <div class="nv-btn-box flow-end gap-10">
                <a (click)="delete()" [hidden]="!conferenceId" class="nv-btn secondary medium" translate>Delete</a>
                <nv-button (click)="close()" color="passive" translate>Close</nv-button>
                <nv-button (click)="save()" [loading]="savingConference" [disabled]="conferenceForm.invalid" translate>Save</nv-button>
            </div><!-- /.nv-btn-box flow-end gap-6 -->
        </div><!-- /.tnt-right -->
    </div><!-- /.config-title-n-tools -->
</div>
<div class="nv-config-content-port">
    <div class="nv-global-loading on" [class.on]="loadingConference">
        <div class="nv-spinner"></div>
    </div><!-- /.nv-global-loading -->
    <div class="nv-section">
        <div class="nv-port">
            <div class="nv-row config-plates gap-24 small-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <form [formGroup]="conferenceForm" class="nv-row config-plates clean gap-24 small-spaced-top spaced-bottom">
                            <div class="nv-column desk-6 tab-12">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <strong translate>Basic Information</strong>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="name"
                                                        label="Name"
                                                        required
                                                ></nv-input>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
<!--                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-select
                                                        [options]="conferenceTypes"
                                                        formControlName="type"
                                                        label="Type"
                                                        required
                                                ></nv-select>
                                            </div> /.nv-column-content 
                                        </div> /.nv-column -->
                                        
                                        <div class="nv-column desk-6 phone-12">
                                            <div class="nv-column-content">
                                                <nv-datepicker formControlName="starts_at"
                                                               label="Event start"
                                                               type="calendar"
                                                               [max]="conferenceForm.value.ends_at"
                                                               required
                                                ></nv-datepicker>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                        <div class="nv-column desk-6 phone-12">
                                            <div class="nv-column-content">
                                                <nv-datepicker formControlName="ends_at"
                                                               label="Event end"
                                                               type="calendar"
                                                               required
                                                ></nv-datepicker>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
                                
                                
                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-select formControlName="country_id"
                                                           label="Country"
                                                           [required]="true"
                                                           [options]="filteredCountries"
                                                           [showSearch]="true"
                                                           (searched)="filterCountries($event)"
                                                ></nv-select>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->

                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-select 
                                                        [(ngModel)]="timezone"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        label="Timezone"
                                                        [required]="true"
                                                        [options]="filteredTimezones"
                                                        [showSearch]="true"
                                                        (searched)="filterTimezones($event)"
                                                ></nv-select>
                                            </div><!-- /.nv-column-content -->
                                        </div><!-- /.nv-column -->
<!--                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="city"
                                                        label="City"
                                                ></nv-input>
                                            </div> /.nv-column-content 
                                        </div> /.nv-column -->
<!--                                        <div class="nv-column desk-12">
                                            <div class="nv-column-content">
                                                <nv-input
                                                        formControlName="website"
                                                        label="Current website"
                                                ></nv-input>
                                            </div> /.nv-column-content 
                                        </div> /.nv-column -->
                                    </div>
                                </div>
                            </div>
                           <div class="nv-column desk-6" formGroupName="copy_from_existing" *ngIf="!conferenceId">
                                <div class="nv-column-content">
                                    <div class="nv-row config-plates clean gap-30 spaced-top propagate">
                                        <ng-container>
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <strong translate>Copy from an existing conference</strong>
                                                </div>
                                            </div>
                                            <div class="nv-column desk-12">
                                                <div class="nv-column-content">
                                                    <nv-select
                                                        [options]="conferences"
                                                        formControlName="conference_id"
                                                        label="Conference"
                                                    ></nv-select>
                                                    <small>Select a conference to copy data from</small>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="conferenceForm.value.copy_from_existing.conference_id">
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <nv-checkbox
                                                            formControlName="copy_speakers"
                                                            label="Copy speakers"
                                                        ></nv-checkbox>
                                                    </div>
                                                </div>     
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <nv-checkbox
                                                            formControlName="copy_companies"
                                                            label="Copy sponsors and exhibitors"
                                                        ></nv-checkbox>
                                                    </div>
                                                </div>     
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <nv-checkbox
                                                            formControlName="copy_program"
                                                            label="Copy program"
                                                        ></nv-checkbox>
                                                    </div>
                                                </div>  
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <nv-checkbox
                                                            formControlName="copy_voting"
                                                            label="Copy voting"
                                                        ></nv-checkbox>
                                                    </div>
                                                </div>  
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <nv-checkbox
                                                            formControlName="copy_website"
                                                            label="Copy website"
                                                        ></nv-checkbox>
                                                    </div>
                                                </div>  
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <nv-checkbox
                                                            formControlName="copy_mobile_app"
                                                            label="Copy mobile app"
                                                        ></nv-checkbox>
                                                    </div>
                                                </div>  
                                                <div class="nv-column desk-12">
                                                    <div class="nv-column-content">
                                                        <nv-checkbox
                                                            formControlName="copy_ematerials"
                                                            label="Copy E-materials"
                                                        ></nv-checkbox>
                                                    </div>
                                                </div>                                                  
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
