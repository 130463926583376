import { Company } from '@navus/core/classes/company';

export class SponsorGroup {
  id?: number;
  name?: string;
  order?: number;
  color?: string;
  display: string;

  sponsors?: Company[];
}
