import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) { }

    transform(text: string, search): SafeHtml {
        if (search && text) {
          let pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
          pattern = pattern.split(' ').filter((t) => {
            return t.length > 0;
          }).join('|');
          const regex = new RegExp(pattern, 'ig');

          const found = text.replace(regex, (match) => `<span class="nv-highlighted">${match}</span>`);
          return this.sanitizer.bypassSecurityTrustHtml(found);
        } else {
          return text;
        }
      }
}
