import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../classes/user';

@Injectable()
export class UserService {
  private currentUserSubject = new BehaviorSubject<User>(null);
  public currentUser = this.currentUserSubject.asObservable();

  constructor(
    private apiService: ApiService
  ) { }

  getUsers(params: any = {}) {
    return this.apiService.get(`users`, params);
  }

  getUser(userId: number, params: any = {}) {
    return this.apiService.get(`users/${userId}`, params);
  }

  getChatUser(userId: number, params: any = {}) {
    return this.apiService.get(`userForChat/${userId}`, params);
  }

  updateUser(userId: number, params: any = {}) {
    return this.apiService.put(`users/${userId}`, params);
  }

  createUser(params: any = {}) {
    return this.apiService.post(`users`, params);
  }

  deleteUser(userId: number) {
    return this.apiService.delete(`users/${userId}`);
  }

  getOrganizationAdministrators(organizationId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/administrators`, params);
  }

  deleteOrganizationAdministrators(organizationId: number, userId: number) {
    return this.apiService.delete(`organizations/${organizationId}/administrators/${userId}`);
  }

  createOrganizationInvitations(organizationId: number, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/invitations`, params);
  }

  getOrganizationInvitations(organizationId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/invitations`, params);
  }

  deleteOrganizationInvitation(organizationId: number, invitationId: string, params: any = {}) {
    return this.apiService.delete(`organizations/${organizationId}/invitations/${invitationId}`, params);
  }

  getCurrentUser(params: any = {}) {
    if (localStorage.getItem('jwtToken')) {
      return this.apiService.get(`users/me`, params)
        .pipe(map(response => {
          this.currentUserSubject.next(response.data);
          return response.data;
        }));
    } else {
      return of(false);
    }
  }

  login(params: any) {
    return this.apiService.post(`login`, params)
      .pipe(map(
        (response) => {
          localStorage.setItem('jwtToken', response.data.token);
          return response;
        }
      ));
  }

  logout() {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('uuid');
    localStorage.removeItem('uuid.createdAt');
    localStorage.removeItem('uuid.identified');
    localStorage.removeItem('uuid.identifiedAt');
    this.currentUserSubject.next(null);
  }

  requestAccessCode(email: string) {
    return this.apiService.get(`users/request_access_code/${email}`);
  }

  checkEmail(email: string) {
    return this.apiService.get(`register/check_email/${email}`);
  }

  register(params: any) {
    return this.apiService.post(`register`, params);
  }

  registerWithCode(conferenceId: number, params: any) {
    return this.apiService.post(`conferences/${conferenceId}/delegates/users`, params);
  }

}
