import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WebsitePageSection } from '@navus/core/classes/website-page-section';
import { CompanyService } from '@navus/core/services/company.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { SponsorListSectionConfig } from './sponsor-list-section-config';
import { BaseSectionComponent } from '../_base/base-section.component';
import { WebsiteService } from '@navus/core/services/website.service';
import { WebsitePageService } from '@navus/core/services/website-page.service';
import { WebsiteControlService } from '../../services/website-control.service';
import { SponsorGroup } from '@navus/core/classes/sponsor-group';
import { Subscription, combineLatest } from 'rxjs';
import { SponsorGroupService } from '@navus/core/services/sponsor-group.service';
import { Website } from '@navus/core/classes/website';


@Component({
  selector: 'nv-sponsor-list-section',
  templateUrl: './sponsor-list-section.component.html'
})
export class SponsorListSectionComponent extends BaseSectionComponent implements OnInit, OnDestroy {
  @Input() data: WebsitePageSection;
  configuration: SponsorListSectionConfig = new SponsorListSectionConfig();

  conferenceId: number;
  sponsorGroups: SponsorGroup[] = [];

  subscriptions: Subscription[] = [];

  constructor(
    public websiteControlService: WebsiteControlService,
    public websiteService: WebsiteService,
    public pageService: WebsitePageService,
    public modalService: ModalService,
    private sponsorGroupService: SponsorGroupService,
    private companyService: CompanyService,
  ) {
    super(
      websiteControlService,
      websiteService,
      pageService,
      modalService
    );
  }

  ngOnInit() {
    this.configuration = new SponsorListSectionConfig(this.data.configuration);

    const subscription = this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          this.conferenceId = website.active_conference_id;
          this.getCompanies();
        },
        () => { }
      );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getCompanies() {
    combineLatest([
      this.sponsorGroupService.getConferenceSponsorGroups(this.conferenceId, {
        sort_by: 'order',
        sort_direction: 'asc',
        with_pagination: 0
      }),
      this.companyService.getConferenceSponsors(this.conferenceId, {
        sort_by: 'order',
        sort_direction: 'asc',
        with_pagination: 0
      })
    ])
      .subscribe(
        (response) => {
          let sponsorGroups = response[0].data;
          let companies = response[1].data;

          sponsorGroups = sponsorGroups.sort((a: any, b: any) => (a.order > b.order) ? 1 : -1);

          if (this.configuration.sponsorGroupIds && this.configuration.sponsorGroupIds.length > 0) {
            sponsorGroups = sponsorGroups.filter(sponsorGroup => this.configuration.sponsorGroupIds.includes(sponsorGroup.id));
          }

          for (const sponsorGroup of sponsorGroups) {
            sponsorGroup.sponsors = [];
            for (const company of companies) {
              if (company.sponsor_group_id === sponsorGroup.id) {
                if (
                    (!this.configuration.type || this.configuration.type === 'all') ||
                    (this.configuration.type === 'exhibitors' && company.is_exhibitor) ||
                    (this.configuration.type === 'sponsors' && !company.is_exhibitor)
                ) {
                  sponsorGroup.sponsors.push(company);
                }
              }
            }
            sponsorGroup.sponsors.sort((a: any, b: any) => (a.order > b.order) ? 1 : -1);
          }

          this.sponsorGroups = sponsorGroups;
        }
      );
  }
  
}
