import { NgModule } from '@angular/core';
import { NavusCoreModule } from '../../../navus-core/navus-core.module';
import { NavusUiModule } from '../../../navus-ui/navus-ui.module';
import { SharedModule } from '../../../shared';
import { BadgeDetailsPageComponent } from './pages/badge-details/badge-details.component';
import { BadgeListPageComponent } from './pages/badge-list/badge-list.component';
import { InstructionsPageComponent } from './pages/instructions/instructions.component';
import { LeaderboardPageComponent } from './pages/leaderboard/leaderboard.component';

@NgModule({
  imports: [
    NavusCoreModule.forRoot(),
    NavusUiModule,
    SharedModule
  ],
  declarations: [
    LeaderboardPageComponent,
    BadgeListPageComponent,
    BadgeDetailsPageComponent,
    InstructionsPageComponent,
  ],
  entryComponents: [],
  providers: []
})
export class BadgesModule {
}
