import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrganizationService } from '@navus/core/services/organization.service';
import { Organization } from '@navus/core/classes/organization';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { Country } from '@navus/core/classes/country';
import { CountryService } from '@navus/core/services/country.service';

@Component({
  selector: 'main [nv-organization-details-page]',
  templateUrl: './organization-details-page.component.html'
})
export class OrganizationDetailsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  organization: Organization;
  organizationForm: FormGroup;
  allCountries: Country[] = [];
  filteredCountries: Country[] = [];

  loadingOrganization: boolean = true;
  savingOrganization: boolean = false;
  unsavedChanges: boolean = false;

  subscriptions: Subscription[] = [];

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'd') {
      this.delete();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private countryService: CountryService,
    private organizationService: OrganizationService,
    private toastService: ToastService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getCountries();

    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.getOrganization();
      }
    );
    this.subscriptions.push(subscription);

    const changeSubscription = this.organizationForm.valueChanges.subscribe(
      () => {
        this.unsavedChanges = true;
      }
    );
    this.subscriptions.push(changeSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.organizationForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      logo: [''],
      contact_info: this.formBuilder.group({
        city: [''],
        country_id: [''],
        postal_code: [''],
        address_line_1: [''],
        address_line_2: ['']
      })
    });
    this.unsavedChanges = false;
  }

  getCountries() {
    this.countryService.getCountries()
      .subscribe(
        (response) => {
          this.allCountries = response.data;
          this.filteredCountries = response.data;
        },
        (error) => {
          this.modalService.error({ errors: error });
        }
      );
  }

  filterCountries(searchTerm: string) {
    if (searchTerm.length > 2) {
      this.filteredCountries = [];
      for (const country of this.allCountries) {
        if (country.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          this.filteredCountries.push(country);
        }
      }
    } else {
      this.filteredCountries = this.allCountries;
    }
  }

  getOrganization() {
    if (this.organizationId) {
      this.loadingOrganization = true;
      this.organizationService.getOrganization(this.organizationId)
        .subscribe(
          (response) => {
            this.organization = response['data'];
            this.organizationForm.patchValue(this.organization);
            this.loadingOrganization = false;
            setTimeout(() => { this.unsavedChanges = false; }, 500);
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.loadingOrganization = false;
          }
        );
    } else {
      this.organization = new Organization();
      this.loadingOrganization = false;
    }
  }

  save() {
    if (this.organizationForm.invalid) { return; }

    this.savingOrganization = true;
    if (this.organizationId) {
      this.organizationService
        .updateOrganization(this.organizationId, this.organizationForm.value)
        .subscribe(
          (response) => {
            this.organization = response['data'];
            this.toastService.success('Organization has been saved');
            this.unsavedChanges = false;
            this.savingOrganization = false;
            this.organizationService.setCurrentOrganization(this.organizationId, true).subscribe();
          },
          (error) => {
            this.modalService.error({ errors: error });
            this.savingOrganization = false;
          }
        );
    }
  }

  close() {
    if (this.unsavedChanges) {
      this.modalService.defaultModal({
        title: 'Unsaved changes',
        body: 'Are you sure you want to leave the page without saving changes?',
        size: 'small',
        buttons: [
          {
            text: 'Stay',
            color: 'passive',
            role: 'cancel'
          },
          {
            text: 'Leave',
            color: 'primary',
            handler: () => {
              this.router.navigate(['/o', this.organizationId, 'landing']);
            }
          }
        ]
      });
    } else {
      this.router.navigate(['/o', this.organizationId, 'landing']);
    }
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Organization Deletion',
      body: 'Are you sure you want to delete this organization?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.organizationService.deleteOrganization(this.organizationId)
              .subscribe(
                () => {
                  this.router.navigate(['/o']);
                },
                (error) => {
                  this.modalService.error({errors: error});
                }
              );
          }
        }
      ]
    });
  }

}
