import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../../../../navus-ui/modal/modal.service';
import { ToastService } from '../../../../../navus-ui/toast/toast.service';
import { ContactService } from '../../../../../navus-core/services/contact.service';

@Component({
  selector: 'main [nv-consent-application-form-page]',
  templateUrl: './consent-application-form-page.component.html'
})
export class ConsentApplicationFormPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;

  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private modalService: ModalService,
    private contactService: ContactService
  ) { }

  ngOnInit() {
    this.initForm();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = +data.organizationId;
        this.conferenceId = +data.conferenceId;
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {

  }

}
