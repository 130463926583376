import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Website } from '@navus/core/classes/website';
import { WebsiteService } from '@navus/core/services/website.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ToastService } from '@navus/ui/toast/toast.service';
import { PlaceholderPreviewModalComponent } from '../../modals/placeholder-preview/placeholder-preview-modal.component';
import { ConferenceService } from '@navus/core/services/conference.service';
import { Conference } from '@navus/core/classes/conference';

@Component({
  selector: 'main [nv-conference-website-page]',
  templateUrl: './conference-website-page.component.html'
})
export class ConferenceWebsitePageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  websiteId: number;

  conference: Conference;
  website: Website;
  websiteForm: FormGroup;
  publishingOptions = [
    // { id: 'now', name: 'Publish upon creation' },
    { id: 'future', name: 'Schedule for future' },
    { id: 'manual', name: 'Manually publish when ready' },
  ];
  languageOptions = [
    { id: 'en', name: 'English' },
    { id: 'es', name: 'Spanish' },
  ];
  step1done = false;
  step2done = false;
  step3done = false;

  subscriptions: Subscription[] = [];

  websiteLoading: boolean = false;
  loadingPublish: boolean = false;
  savingWebsite: boolean = false;
  domainValid: boolean = false;
  editActive: boolean = false;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 's') {
      this.save();
      event.preventDefault();
      return false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private websiteService: WebsiteService,
    private conferenceService: ConferenceService,
    private toastService: ToastService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.initForm();

    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.websiteId = data.websiteId;
        this.getConference();
        this.getWebsite();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.websiteForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      subdomain: ['', [Validators.required]],
      domain: ['navus.io', [Validators.required]],
      logo: [''],
      favicon: [null],
      description: [''],
      type: ['service'],
      theme: ['light-skin'],
      default_language: ['en'],
      meta_title: [''],
      meta_keywords: [''],
      meta_description: [''],
      conference_ids: [''],
      settings: this.formBuilder.group({
        google_analytics_code: [null],
        search_page_presentation_types: [null],
        search_page_material_types: [null],
        event_website: [''],
        release_option: ['manual'],
        release_date: [''],
        release_notification: [false],
        footer: [''],
      })
    });

    const subscription = this.websiteForm.get('subdomain').valueChanges
      .subscribe((value) => {
        const params: any = { full_url: value + '.navus.io' };
        if (this.websiteId) { params.website_id = this.websiteId; }

        this.websiteService.checkIfDomainExists(params)
          .subscribe(
            (response) => {
              this.websiteForm.get('subdomain').setErrors({'domain_taken': true});
            },
            (error) => {
              this.websiteForm.get('subdomain').setErrors(null);
            }
          );
      });
    this.subscriptions.push(subscription);
  }

  getConference() {
    this.conferenceService.currentConference
      .subscribe((conference) => { this.conference = conference; });
  }

  getWebsite() {
    this.websiteLoading = true;
    this.websiteService
      .getConferenceWebsite(this.organizationId, this.conferenceId)
      .subscribe(
        (response) => {
          this.websiteId = response['data'].id;
          this.website = response['data'];
          this.websiteForm.patchValue(this.website);
          this.websiteLoading = false;
        },
        (error) => {
          this.website = new Website();
          this.websiteLoading = false;
        }
      );
  }

  save() {
    if (this.websiteForm.invalid) { return; }

    this.savingWebsite = true;
    if (this.websiteId) {
      this.websiteService
        .updateConferenceWebsite(this.organizationId, this.conferenceId, this.websiteId, this.websiteForm.value)
        .subscribe(
          (response) => {
            this.website = response['data'];
            this.toastService.success('Website successfully saved.');
            this.savingWebsite = false;
          },
          (error) => {
            this.savingWebsite = false;
            this.modalService.error({ errors: error });
          }
        );
    } else {
      this.websiteService
        .createConferenceWebsite(this.organizationId, this.conferenceId, this.websiteForm.value)
        .subscribe(
          (response) => {
            this.website = response['data'];
            this.toastService.success('Website successfully saved.');
            this.websiteId = this.website.id;
            this.savingWebsite = false;
          },
          (error) => {
            this.savingWebsite = false;
            this.modalService.error({ errors: error });
          }
        );
    }
  }

  close() {
    this.router.navigate(['/o', this.organizationId, 'conference', this.conferenceId, 'landing']);
  }

  delete() {
    this.modalService.defaultModal({
      title: 'Website Deletion',
      body: 'Are you sure you want to delete this website?',
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'passive',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'accent2',
          handler: () => {
            this.websiteService
              .deleteConferenceWebsite(this.organizationId, this.conferenceId, this.websiteId)
              .subscribe(
                () => {
                  this.close();
                },
                () => { }
              );
          }
        }
      ]
    });
  }

  editWebsite() {
    if (this.conferenceId == 20369) {
      window.open(
        `https://conference.pugchallenge.eu?edit=true&auth=${localStorage.getItem('jwtToken')}`,
        '_blank'
      );    
    } else {
      window.open(
        `https://${this.website.subdomain}.${this.website.domain}?edit=true&auth=${localStorage.getItem('jwtToken')}`,
        '_blank'
      );      
    }
  }

  previewWebsite() {
    if (this.conferenceId == 20369) {
      window.open(
        `https://conference.pugchallenge.eu`,
        '_blank'
      );    
    } else {
      window.open(
        `https://${this.website.subdomain}.${this.website.domain}`
      );   
    }
  }

  togglePublished() {
    this.loadingPublish = true;
    this.websiteService.updateOrganizationWebsite(this.organizationId, this.websiteId, { published: !this.website.published })
      .subscribe(
        () => {
          this.website.published = !this.website.published;
          this.loadingPublish = false;
        },
        () => {
          this.loadingPublish = false;
        }
      );
  }

  showPlaceholderPreview() {
    const modalRef = this.modalService.open(PlaceholderPreviewModalComponent);
    modalRef.componentInstance.website = this.websiteForm.value;
    modalRef.componentInstance.conference = this.conference;
  }
}
