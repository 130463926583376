import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastService } from '@navus/ui/toast/toast.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { ConferenceService } from '@navus/core/services/conference.service';

@Component({
  selector: 'main [nv-notification-settings-page]',
  templateUrl: './notification-settings-page.component.html'
})
export class NotificationSettingsPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;

  notificationSettingsForm: FormGroup;

  loadingNotificationSettings: boolean = true;
  savingNotificationSettings: boolean = false;
  unsavedChanges: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private modalService: ModalService,
    private conferenceService: ConferenceService,
  ) {
  }

  ngOnInit() {
    this.initForm();
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;
        this.getNotificationSettings();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  initForm() {
    this.notificationSettingsForm = this.formBuilder.group({
      enabled: [false],
      show_list: [false],
      show_alert: [false],
    });
  }

  getNotificationSettings() {
    this.loadingNotificationSettings = true;
    this.conferenceService
      .getOrganizationConference(this.organizationId, this.conferenceId)
      .subscribe(
        (response) => {
          const conference = response.data;
          this.notificationSettingsForm.patchValue(conference.settings?.notification_settings);
          this.loadingNotificationSettings = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.loadingNotificationSettings = false;
        }
      );
  }

  save() {
    if (this.notificationSettingsForm.invalid) {
      return;
    }

    this.savingNotificationSettings = true;
    const validatedSettings = this.validateForm(this.notificationSettingsForm.value);
    const params = { settings: { notification_settings: validatedSettings } };
    this.conferenceService
      .updateOrganizationConference(this.organizationId, this.conferenceId, params)
      .subscribe(
        () => {
          this.toastService.success('Notification settings successfully saved.', {});
          this.unsavedChanges = false;
          this.savingNotificationSettings = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.savingNotificationSettings = false;
        }
      );
  }

  private validateForm(settings: { enabled: boolean, show_list: boolean, show_alert: boolean }) {
    if (!settings.enabled) {
      delete settings.show_list;
      delete settings.show_alert;
    }
    return settings;
  }

}
