<section class="nv-live-stage-config-panel nv-ls-panel-left-enhanced"><!-- < no additional class for default view, 'nv-ls-panel-left-enhanced' & 'nv-ls-panel-right-enhanced' -->
    <div class="nv-stage-config-layout-options">
        <span>layout options</span>
        <ul>
            <li class="lo-type1 active" title="Equal panels space"></li>
            <li class="lo-type2" title="Enhance left panel"></li>
            <li class="lo-type3" title="Enhance right panel"></li>
        </ul>
    </div><!-- /.nv-stage-config-layout-options -->
    <div class="nv-live-stage-config-layout">
        <div class="nv-live-stage-config-panes">
            <div class="nv-live-stage-config-left-pane">
                <div class="nv-ls-config-feature-panel">
                    <span class="nv-close-ls-config-feature-panel"></span>
                    <div class="nv-ls-feature-panel-placeholder">
                        <a href="" class="nv-btn medium secondary">Enter the Live Room</a>
                    </div><!-- /.nv-ls-feature-panel-placeholder -->
                </div><!-- /.nv-ls-config-feature-panel -->

<!--                <div class="nv-ls-config-feature-panel">-->
<!--                    <div class="nv-ls-feature-panel-placeholder">-->
<!--                        <span class="nv-ls-feature-panel-loading"><em></em>Loading. Please wait.</span>-->
<!--                    </div>&lt;!&ndash; /.nv-ls-feature-panel-placeholder &ndash;&gt;-->
<!--                </div>&lt;!&ndash; /.nv-ls-config-feature-panel &ndash;&gt;-->

                <div class="nv-ls-config-feature-panel">
                    <span class="nv-close-ls-config-feature-panel"></span>
                    <div class="nv-ls-feature-panel-placeholder">
                        <div class="nv-ls-feature-panel-add-slides">
                            <p>No slides uploaded yet.</p>
                            <a href="" class="nv-btn medium passive">Add slide</a>
                        </div><!-- /.nv-ls-feature-panel-add-slides -->
                    </div><!-- /.nv-ls-feature-panel-placeholder -->
                </div><!-- /.nv-ls-config-feature-panel -->

                <!-- Of Air chunk -->
                <div class="nv-ls-config-feature-panel">
                    <span class="nv-close-ls-config-feature-panel"></span>
                    <div class="nv-off-air-iface">
                        <div class="nv-off-air-iface-top">
                            <h3>Off Air Settings</h3>
                        </div><!-- /.nv-off-air-iface-top -->
                        <div class="nv-off-air-iface-body">
                            <div class="nv-off-air-if-port">
                                <form>
                                    <ul class="nv-off-air-control">
                                        <li>
                                            <div class="nv-form-item nv-radio-item">
                                                <label>
                                                    <span class="nri-radio-port">
                                                        <input type="radio" name="type">
                                                        <span class="nri-radio-visual"></span>
                                                    </span><!-- /.nri-radio-port -->
                                                </label>
                                            </div>
                                            <h4><span>Recommendations for improving and standardizing virtual journey</span></h4>
                                        </li>
                                        <li class="active">
                                            <div class="nv-form-item nv-radio-item">
                                                <label>
                                                    <span class="nri-radio-port">
                                                        <input type="radio" name="type">
                                                        <span class="nri-radio-visual"></span>
                                                    </span><!-- /.nri-radio-port -->
                                                </label>
                                            </div>
                                            <h4><span>Can I just chime in on that one my supervisor didn't like the latest revision you gave me can you switch back</span></h4>
                                        </li>
                                        <li>
                                            <div class="nv-form-item nv-radio-item">
                                                <label>
                                                    <span class="nri-radio-port">
                                                        <input type="radio" name="type">
                                                        <span class="nri-radio-visual"></span>
                                                    </span><!-- /.nri-radio-port -->
                                                </label>
                                            </div>
                                            <h4><a href>Drink from the firehose let's schedule</a></h4>
                                        </li>
                                        <li>
                                            <div class="nv-form-item nv-radio-item">
                                                <label>
                                                    <span class="nri-radio-port">
                                                        <input type="radio" name="type">
                                                        <span class="nri-radio-visual"></span>
                                                    </span><!-- /.nri-radio-port -->
                                                </label>
                                            </div>
                                            <h4><a href>Back-end of third quarter it's about managing expectations yet moving the goalposts</a></h4>
                                        </li>
                                        <li>
                                            <div class="nv-form-item nv-radio-item">
                                                <label>
                                                    <span class="nri-radio-port">
                                                        <input type="radio" name="type">
                                                        <span class="nri-radio-visual"></span>
                                                    </span><!-- /.nri-radio-port -->
                                                </label>
                                            </div>
                                            <h4><span>Recommendations for improving and standardizing virtual journey</span></h4>
                                        </li>
                                        <li class="active">
                                            <div class="nv-form-item nv-radio-item">
                                                <label>
                                                    <span class="nri-radio-port">
                                                        <input type="radio" name="type">
                                                        <span class="nri-radio-visual"></span>
                                                    </span><!-- /.nri-radio-port -->
                                                </label>
                                            </div>
                                            <h4><span>Can I just chime in on that one my supervisor didn't like the latest revision you gave me can you switch back</span></h4>
                                        </li>
                                        <li>
                                            <div class="nv-form-item nv-radio-item">
                                                <label>
                                                    <span class="nri-radio-port">
                                                        <input type="radio" name="type">
                                                        <span class="nri-radio-visual"></span>
                                                    </span><!-- /.nri-radio-port -->
                                                </label>
                                            </div>
                                            <h4><a href>Drink from the firehose let's schedule</a></h4>
                                        </li>
                                        <li>
                                            <div class="nv-form-item nv-radio-item">
                                                <label>
                                                    <span class="nri-radio-port">
                                                        <input type="radio" name="type">
                                                        <span class="nri-radio-visual"></span>
                                                    </span><!-- /.nri-radio-port -->
                                                </label>
                                            </div>
                                            <h4><a href>Back-end of third quarter it's about managing expectations yet moving the goalposts</a></h4>
                                        </li>
                                        <li>
                                            <div class="nv-form-item nv-radio-item">
                                                <label>
                                                    <span class="nri-radio-port">
                                                        <input type="radio" name="type">
                                                        <span class="nri-radio-visual"></span>
                                                    </span><!-- /.nri-radio-port -->
                                                </label>
                                            </div>
                                            <h4><span>Recommendations for improving and standardizing virtual journey</span></h4>
                                        </li>
                                        <li class="active">
                                            <div class="nv-form-item nv-radio-item">
                                                <label>
                                                    <span class="nri-radio-port">
                                                        <input type="radio" name="type">
                                                        <span class="nri-radio-visual"></span>
                                                    </span><!-- /.nri-radio-port -->
                                                </label>
                                            </div>
                                            <h4><span>Can I just chime in on that one my supervisor didn't like the latest revision you gave me can you switch back</span></h4>
                                        </li>
                                        <li>
                                            <div class="nv-form-item nv-radio-item">
                                                <label>
                                                    <span class="nri-radio-port">
                                                        <input type="radio" name="type">
                                                        <span class="nri-radio-visual"></span>
                                                    </span><!-- /.nri-radio-port -->
                                                </label>
                                            </div>
                                            <h4><a href>Drink from the firehose let's schedule</a></h4>
                                        </li>
                                        <li>
                                            <div class="nv-form-item nv-radio-item">
                                                <label>
                                                    <span class="nri-radio-port">
                                                        <input type="radio" name="type">
                                                        <span class="nri-radio-visual"></span>
                                                    </span><!-- /.nri-radio-port -->
                                                </label>
                                            </div>
                                            <h4><a href>Back-end of third quarter it's about managing expectations yet moving the goalposts</a></h4>
                                        </li>
                                        <li>
                                            <div class="nv-form-item nv-radio-item">
                                                <label>
                                                    <span class="nri-radio-port">
                                                        <input type="radio" name="type">
                                                        <span class="nri-radio-visual"></span>
                                                    </span><!-- /.nri-radio-port -->
                                                </label>
                                            </div>
                                            <h4><span>Recommendations for improving and standardizing virtual journey</span></h4>
                                        </li>
                                        <li class="active">
                                            <div class="nv-form-item nv-radio-item">
                                                <label>
                                                    <span class="nri-radio-port">
                                                        <input type="radio" name="type">
                                                        <span class="nri-radio-visual"></span>
                                                    </span><!-- /.nri-radio-port -->
                                                </label>
                                            </div>
                                            <h4><span>Can I just chime in on that one my supervisor didn't like the latest revision you gave me can you switch back</span></h4>
                                        </li>
                                        <li>
                                            <div class="nv-form-item nv-radio-item">
                                                <label>
                                                    <span class="nri-radio-port">
                                                        <input type="radio" name="type">
                                                        <span class="nri-radio-visual"></span>
                                                    </span><!-- /.nri-radio-port -->
                                                </label>
                                            </div>
                                            <h4><a href>Drink from the firehose let's schedule</a></h4>
                                        </li>
                                        <li>
                                            <div class="nv-form-item nv-radio-item">
                                                <label>
                                                    <span class="nri-radio-port">
                                                        <input type="radio" name="type">
                                                        <span class="nri-radio-visual"></span>
                                                    </span><!-- /.nri-radio-port -->
                                                </label>
                                            </div>
                                            <h4><a href>Back-end of third quarter it's about managing expectations yet moving the goalposts</a></h4>
                                        </li>
                                    </ul>
                                </form>
                            </div><!-- /.nv-off-air-if-port -->
                        </div><!-- /.nv-off-air-iface-body -->
                        <div class="nv-off-air-iface-bottom">
                            <ul class="nv-off-air-iface-control">
                                <li><a href="" title="Add" class="add">Add</a></li>
                                <li><a href="" title="Edit" class="edit" disabled>Edit</a></li>
                                <li><a href="" title="Activate" class="activate">Activate</a></li>
                                <li><a href="" title="Delete" class="delete">Delete</a></li>
                            </ul><!-- /.nv-off-air-iface-control -->
                        </div><!-- /.nv-off-air-iface-bottom -->
                    </div><!-- /.nv-off-air-iface -->
                </div><!-- /.nv-ls-config-feature-panel -->


                <!-- Off Air modal content -->
                <form class="nv-row gap-24 small-spaced propagate" style="display: none;"><!-- < tmp hidden -->
                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <div class="nv-form-item">
                                <label>
                                    <input type="text" placeholder="Title">
                                    <span class="label-title">Title</span>
                                </label>
                            </div><!-- /nv-form-item -->
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->
                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <div class="nv-form-item">
                                <label>
                                    <input type="text" placeholder="Description">
                                    <span class="label-title">Description</span>
                                </label>
                            </div><!-- /nv-form-item -->


                            <!-- TextArea option
                            <div class="nv-form-item">
                                <label>
                                    <textarea rows="3" colls="50" placeholder="Description">
                                    </textarea>
                                    <span class="label-title">Description</span>
                                </label>
                            </div>
                            -->

                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->

                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <div class="nv-form-item select-item">
                                <label>
                                    <select>
                                        <option>Materials</option>
                                        <option>Resources</option>
                                        <option>External</option>
                                    </select>
                                    <span class="label-title">Resource Type</span>
                                </label>
                            </div><!-- /nv-form-item -->
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->

                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <div class="nv-form-item select-item">
                                <label>
                                    <select>
                                        <option>Material 01</option>
                                        <option>Material 02</option>
                                        <option>Material 03</option>
                                    </select>
                                    <span class="label-title">Select Material</span>
                                </label>
                            </div><!-- /nv-form-item -->
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->

                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <div class="nv-form-item">
                                <label>
                                    <input type="text" placeholder="Resource">
                                    <span class="label-title">Resource</span>
                                </label>
                            </div><!-- /nv-form-item -->
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->

                    <div class="nv-column desk-6 phab-12">
                        <div class="nv-column-content">
                            <button type="submit" class="nv-btn primary fw medium">Save</button>
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->
                    <div class="nv-column desk-6 phab-12">
                        <div class="nv-column-content">
                            <button type="submit" class="nv-btn neutral fw medium">Cancel</button>
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->
                </form><!-- /.nv-row -->
                <!-- /Off Air modal content -->

            </div><!-- /.nv-live-stage-config-left-pane -->
            <div class="nv-live-stage-config-right-pane">
                <div class="nv-live-stage-config-pane-heading">
                    <h1>Streaming Program</h1>
                    <div class="nv-live-stage-config-pane-heading-tools">
                        <nv-checkbox
                                label="Hide finished"
                                formControlName="checkbox" >
                        </nv-checkbox>
                    </div><!-- /.nv-live-stage-config-pane-heading-tools -->
                </div><!-- /.nv-live-stage-config-pane-heading -->
                <div class="nv-live-stage-tabs">
                    <nv-tabs>
                        <nv-tab title="December 25">
                            <div class="nv-ls-config-sessions">
                                <div class="nv-ls-config-session-item">
                                    <div class="nv-ls-session-item-meta">
                                        <div class="nv-ls-session-item-heading">
                                            <p class="time">12:00PM - 15:00PM</p>
                                            <h3 class="session"><a href="">Session</a> Create a true virtual journey for your participants</h3>
                                            <h5 class="chairs">Chairs: Toni Cathomen, Klaus Olgaard</h5>
                                        </div><!-- /.nv-ls-session-item-heading -->
                                        <ul class="nv-ls-session-presentations">
                                            <li>
                                                <div class="nv-ls-session-presentation-status"><span class="live"></span></div>
                                                <div class="nv-ls-session-presentation-meta">
                                                    <time>12:00 - 12:15</time>
                                                    <h2>Recommendations for improving and standardizing vascular research on arterial stiffness: a scientific statement from...</h2>
                                                    <div class="speakers">
                                                        <span>Speakers:</span>
                                                        <p>Toni Cathomen, Klaus Olgaard</p>
                                                    </div><!-- /.speakers -->
                                                    <div class="nv-ls-session-presentation-tools">
                                                        <nv-checkbox
                                                                label="Autoplay"
                                                                formControlName="checkbox2" >
                                                        </nv-checkbox>
                                                        <span class="nv-ls-session-presentation-tool duration">Pre-recording, duration 13:15</span>
                                                    </div><!-- /.nv-ls-session-presentation-tools -->
                                                </div><!-- /.nv-ls-session-presentation-meta -->
                                            </li>
                                            <li>
                                                <div class="nv-ls-session-presentation-status"><span class="play"></span></div>
                                                <div class="nv-ls-session-presentation-meta">
                                                    <time>12:00 - 12:15</time>
                                                    <h2><a href="">Translating lessons from vascular biology into new treatment for coronar artery disease</a></h2>
                                                    <div class="speakers">
                                                        <span>Speakers:</span>
                                                        <p>Toni Cathomen, Klaus Olgaard</p>
                                                    </div><!-- /.speakers -->
                                                </div><!-- /.nv-ls-session-presentation-meta -->
                                            </li>
                                            <li>
                                                <div class="nv-ls-session-presentation-status"><span class="play"></span></div>
                                                <div class="nv-ls-session-presentation-meta">
                                                    <time>12:00 - 12:15</time>
                                                    <h2>Translating lessons from vascular biology into new treatment for coronar artery disease</h2>
                                                    <div class="speakers">
                                                        <span>Speakers:</span>
                                                        <p>Toni Cathomen, Klaus Olgaard</p>
                                                    </div><!-- /.speakers -->
                                                    <div class="nv-ls-session-presentation-tools">
                                                        <nv-checkbox
                                                                label="Autoplay"
                                                                formControlName="checkbox3" >
                                                        </nv-checkbox>
                                                        <span class="nv-ls-session-presentation-tool duration">Pre-recording, duration 02:15</span>
                                                    </div><!-- /.nv-ls-session-presentation-tools -->
                                                </div><!-- /.nv-ls-session-presentation-meta -->
                                            </li>
                                        </ul><!-- /.nv-ls-session-presentations -->
                                    </div><!-- /.nv-ls-session-item-meta -->
                                    <div class="nv-ls-session-item-participants">
                                        <h3>Participants</h3>
                                        <div class="nv-ls-session-participant">
                                            <div class="participant">
                                                <h4>Maeng Yeo-Jin</h4>
                                                <p>Speaker</p>
                                            </div><!-- /.participant -->
                                            <div class="nv-custom-toggle-checkbox">
                                                <label>
                                                    <input type="checkbox" name="blah1">
                                                    <span class="nv-custom-toggle-checkbox-visual">
                                                        <em class="off">Out</em>
                                                        <em class="on">In</em>
                                                    </span><!-- /.nv-custom-toggle-checkbox-visual -->
                                                </label>
                                            </div><!-- /.nv-custom-toggle-checkbox -->
                                        </div><!-- /.nv-ls-session-participant -->
                                        <div class="nv-ls-session-participant">
                                            <div class="participant">
                                                <h4>Irene Sotelo</h4>
                                                <p>Speaker</p>
                                            </div><!-- /.participant -->
                                            <div class="nv-custom-toggle-checkbox">
                                                <label>
                                                    <input type="checkbox" name="blah1">
                                                    <span class="nv-custom-toggle-checkbox-visual">
                                                        <em class="off">Out</em>
                                                        <em class="on">In</em>
                                                    </span><!-- /.nv-custom-toggle-checkbox-visual -->
                                                </label>
                                            </div><!-- /.nv-custom-toggle-checkbox -->
                                        </div><!-- /.nv-ls-session-participant -->
                                        <div class="nv-ls-session-participant">
                                            <div class="participant">
                                                <h4>Lucy Miller</h4>
                                                <p>Moderator</p>
                                            </div><!-- /.participant -->
                                            <div class="nv-custom-toggle-checkbox">
                                                <label>
                                                    <input type="checkbox" name="blah1">
                                                    <span class="nv-custom-toggle-checkbox-visual">
                                                        <em class="off">Out</em>
                                                        <em class="on">In</em>
                                                    </span><!-- /.nv-custom-toggle-checkbox-visual -->
                                                </label>
                                            </div><!-- /.nv-custom-toggle-checkbox -->
                                        </div><!-- /.nv-ls-session-participant -->
                                        <div class="nv-ls-session-participant-tools">
                                            <p class="clear-all"><a href="">Clear all</a></p>
                                            <p class="invite-all-in"><a href="">Invite all in</a></p>
                                        </div><!-- /.nv-ls-session-participant-tools -->
                                    </div><!-- /.nv-ls-session-item-participants -->
                                </div><!-- /.nv-ls-config-session-item -->

                                <div class="nv-ls-config-session-item">
                                    <div class="nv-ls-session-item-meta">
                                        <div class="nv-ls-session-item-heading">
                                            <p class="time">12:00PM - 15:00PM</p>
                                            <h3 class="session"><a href="">Session</a> Create a true virtual journey for your participants</h3>
                                            <h5 class="chairs">Chairs: Toni Cathomen, Klaus Olgaard</h5>
                                        </div><!-- /.nv-ls-session-item-heading -->
                                        <ul class="nv-ls-session-presentations">
                                            <li>
                                                <div class="nv-ls-session-presentation-status"><span class="live"></span></div>
                                                <div class="nv-ls-session-presentation-meta">
                                                    <time>12:00 - 12:15</time>
                                                    <h2>Recommendations for improving and standardizing vascular research on arterial stiffness: a scientific statement from...</h2>
                                                    <div class="speakers">
                                                        <span>Speakers:</span>
                                                        <p>Toni Cathomen, Klaus Olgaard</p>
                                                    </div><!-- /.speakers -->
                                                    <div class="nv-ls-session-presentation-tools">
                                                        <nv-checkbox
                                                                label="Autoplay"
                                                                formControlName="checkbox2" >
                                                        </nv-checkbox>
                                                        <span class="nv-ls-session-presentation-tool duration">Pre-recording, duration 13:15</span>
                                                    </div><!-- /.nv-ls-session-presentation-tools -->
                                                </div><!-- /.nv-ls-session-presentation-meta -->
                                            </li>
                                            <li>
                                                <div class="nv-ls-session-presentation-status"><span class="play"></span></div>
                                                <div class="nv-ls-session-presentation-meta">
                                                    <time>12:00 - 12:15</time>
                                                    <h2><a href="">Translating lessons from vascular biology into new treatment for coronar artery disease</a></h2>
                                                    <div class="speakers">
                                                        <span>Speakers:</span>
                                                        <p>Toni Cathomen, Klaus Olgaard</p>
                                                    </div><!-- /.speakers -->
                                                </div><!-- /.nv-ls-session-presentation-meta -->
                                            </li>
                                            <li>
                                                <div class="nv-ls-session-presentation-status"><span class="play"></span></div>
                                                <div class="nv-ls-session-presentation-meta">
                                                    <time>12:00 - 12:15</time>
                                                    <h2>Translating lessons from vascular biology into new treatment for coronar artery disease</h2>
                                                    <div class="speakers">
                                                        <span>Speakers:</span>
                                                        <p>Toni Cathomen, Klaus Olgaard</p>
                                                    </div><!-- /.speakers -->
                                                    <div class="nv-ls-session-presentation-tools">
                                                        <nv-checkbox
                                                                label="Autoplay"
                                                                formControlName="checkbox3" >
                                                        </nv-checkbox>
                                                        <span class="nv-ls-session-presentation-tool duration">Pre-recording, duration 02:15</span>
                                                    </div><!-- /.nv-ls-session-presentation-tools -->
                                                </div><!-- /.nv-ls-session-presentation-meta -->
                                            </li>
                                        </ul><!-- /.nv-ls-session-presentations -->
                                    </div><!-- /.nv-ls-session-item-meta -->
                                    <div class="nv-ls-session-item-participants">
                                        <h3>Participants</h3>
                                        <div class="nv-ls-session-participant">
                                            <div class="participant">
                                                <h4>Maeng Yeo-Jin</h4>
                                                <p>Speaker</p>
                                            </div><!-- /.participant -->
                                            <div class="nv-custom-toggle-checkbox">
                                                <label>
                                                    <input type="checkbox" name="blah1">
                                                    <span class="nv-custom-toggle-checkbox-visual">
                                                        <em class="off">Out</em>
                                                        <em class="on">In</em>
                                                    </span><!-- /.nv-custom-toggle-checkbox-visual -->
                                                </label>
                                            </div><!-- /.nv-custom-toggle-checkbox -->
                                        </div><!-- /.nv-ls-session-participant -->
                                        <div class="nv-ls-session-participant">
                                            <div class="participant">
                                                <h4>Irene Sotelo</h4>
                                                <p>Speaker</p>
                                            </div><!-- /.participant -->
                                            <div class="nv-custom-toggle-checkbox">
                                                <label>
                                                    <input type="checkbox" name="blah1">
                                                    <span class="nv-custom-toggle-checkbox-visual">
                                                        <em class="off">Out</em>
                                                        <em class="on">In</em>
                                                    </span><!-- /.nv-custom-toggle-checkbox-visual -->
                                                </label>
                                            </div><!-- /.nv-custom-toggle-checkbox -->
                                        </div><!-- /.nv-ls-session-participant -->
                                        <div class="nv-ls-session-participant">
                                            <div class="participant">
                                                <h4>Lucy Miller</h4>
                                                <p>Moderator</p>
                                            </div><!-- /.participant -->
                                            <div class="nv-custom-toggle-checkbox">
                                                <label>
                                                    <input type="checkbox" name="blah1">
                                                    <span class="nv-custom-toggle-checkbox-visual">
                                                        <em class="off">Out</em>
                                                        <em class="on">In</em>
                                                    </span><!-- /.nv-custom-toggle-checkbox-visual -->
                                                </label>
                                            </div><!-- /.nv-custom-toggle-checkbox -->
                                        </div><!-- /.nv-ls-session-participant -->
                                        <div class="nv-ls-session-participant-tools">
                                            <p class="clear-all"><a href="">Clear all</a></p>
                                            <p class="invite-all-in"><a href="">Invite all in</a></p>
                                        </div><!-- /.nv-ls-session-participant-tools -->
                                    </div><!-- /.nv-ls-session-item-participants -->
                                </div><!-- /.nv-ls-config-session-item -->

                                <div class="nv-ls-config-session-item">
                                    <div class="nv-ls-session-item-meta">
                                        <div class="nv-ls-session-item-heading">
                                            <p class="time">12:00PM - 15:00PM</p>
                                            <h3 class="session"><a href="">Session</a> Create a true virtual journey for your participants</h3>
                                            <h5 class="chairs">Chairs: Toni Cathomen, Klaus Olgaard</h5>
                                        </div><!-- /.nv-ls-session-item-heading -->
                                        <ul class="nv-ls-session-presentations">
                                            <li>
                                                <div class="nv-ls-session-presentation-status"><span class="live"></span></div>
                                                <div class="nv-ls-session-presentation-meta">
                                                    <time>12:00 - 12:15</time>
                                                    <h2>Recommendations for improving and standardizing vascular research on arterial stiffness: a scientific statement from...</h2>
                                                    <div class="speakers">
                                                        <span>Speakers:</span>
                                                        <p>Toni Cathomen, Klaus Olgaard</p>
                                                    </div><!-- /.speakers -->
                                                    <div class="nv-ls-session-presentation-tools">
                                                        <nv-checkbox
                                                                label="Autoplay"
                                                                formControlName="checkbox2" >
                                                        </nv-checkbox>
                                                        <span class="nv-ls-session-presentation-tool duration">Pre-recording, duration 13:15</span>
                                                    </div><!-- /.nv-ls-session-presentation-tools -->
                                                </div><!-- /.nv-ls-session-presentation-meta -->
                                            </li>
                                            <li>
                                                <div class="nv-ls-session-presentation-status"><span class="play"></span></div>
                                                <div class="nv-ls-session-presentation-meta">
                                                    <time>12:00 - 12:15</time>
                                                    <h2><a href="">Translating lessons from vascular biology into new treatment for coronar artery disease</a></h2>
                                                    <div class="speakers">
                                                        <span>Speakers:</span>
                                                        <p>Toni Cathomen, Klaus Olgaard</p>
                                                    </div><!-- /.speakers -->
                                                </div><!-- /.nv-ls-session-presentation-meta -->
                                            </li>
                                            <li>
                                                <div class="nv-ls-session-presentation-status"><span class="play"></span></div>
                                                <div class="nv-ls-session-presentation-meta">
                                                    <time>12:00 - 12:15</time>
                                                    <h2>Translating lessons from vascular biology into new treatment for coronar artery disease</h2>
                                                    <div class="speakers">
                                                        <span>Speakers:</span>
                                                        <p>Toni Cathomen, Klaus Olgaard</p>
                                                    </div><!-- /.speakers -->
                                                    <div class="nv-ls-session-presentation-tools">
                                                        <nv-checkbox
                                                                label="Autoplay"
                                                                formControlName="checkbox3" >
                                                        </nv-checkbox>
                                                        <span class="nv-ls-session-presentation-tool duration">Pre-recording, duration 02:15</span>
                                                    </div><!-- /.nv-ls-session-presentation-tools -->
                                                </div><!-- /.nv-ls-session-presentation-meta -->
                                            </li>
                                        </ul><!-- /.nv-ls-session-presentations -->
                                    </div><!-- /.nv-ls-session-item-meta -->
                                    <div class="nv-ls-session-item-participants">
                                        <h3>Participants</h3>
                                        <div class="nv-ls-session-participant">
                                            <div class="participant">
                                                <h4>Maeng Yeo-Jin</h4>
                                                <p>Speaker</p>
                                            </div><!-- /.participant -->
                                            <div class="nv-custom-toggle-checkbox">
                                                <label>
                                                    <input type="checkbox" name="blah1">
                                                    <span class="nv-custom-toggle-checkbox-visual">
                                                        <em class="off">Out</em>
                                                        <em class="on">In</em>
                                                    </span><!-- /.nv-custom-toggle-checkbox-visual -->
                                                </label>
                                            </div><!-- /.nv-custom-toggle-checkbox -->
                                        </div><!-- /.nv-ls-session-participant -->
                                        <div class="nv-ls-session-participant">
                                            <div class="participant">
                                                <h4>Irene Sotelo</h4>
                                                <p>Speaker</p>
                                            </div><!-- /.participant -->
                                            <div class="nv-custom-toggle-checkbox">
                                                <label>
                                                    <input type="checkbox" name="blah1">
                                                    <span class="nv-custom-toggle-checkbox-visual">
                                                        <em class="off">Out</em>
                                                        <em class="on">In</em>
                                                    </span><!-- /.nv-custom-toggle-checkbox-visual -->
                                                </label>
                                            </div><!-- /.nv-custom-toggle-checkbox -->
                                        </div><!-- /.nv-ls-session-participant -->
                                        <div class="nv-ls-session-participant">
                                            <div class="participant">
                                                <h4>Lucy Miller</h4>
                                                <p>Moderator</p>
                                            </div><!-- /.participant -->
                                            <div class="nv-custom-toggle-checkbox">
                                                <label>
                                                    <input type="checkbox" name="blah1">
                                                    <span class="nv-custom-toggle-checkbox-visual">
                                                        <em class="off">Out</em>
                                                        <em class="on">In</em>
                                                    </span><!-- /.nv-custom-toggle-checkbox-visual -->
                                                </label>
                                            </div><!-- /.nv-custom-toggle-checkbox -->
                                        </div><!-- /.nv-ls-session-participant -->
                                        <div class="nv-ls-session-participant-tools">
                                            <p class="clear-all"><a href="">Clear all</a></p>
                                            <p class="invite-all-in"><a href="">Invite all in</a></p>
                                        </div><!-- /.nv-ls-session-participant-tools -->
                                    </div><!-- /.nv-ls-session-item-participants -->
                                </div><!-- /.nv-ls-config-session-item -->
                            </div><!-- /.nv-ls-config-sessions -->
                        </nv-tab>
                        <nv-tab title="December 26" style="display: none;">
                        </nv-tab>
                    </nv-tabs>
                </div><!-- /.nv-live-stage-tabs -->
            </div><!-- /.nv-live-stage-config-right-pane -->
        </div><!-- /.nv-live-stage-config-panes -->
        <div class="nv-live-stage-config-bar">
            <div class="nv-live-stage-config-bar-panel">
                <div class="nv-ls-config-bar-status current">
                    <div class="stop-stream">
                        <span>Stop</span>
                        <button type="button" class="stop-stream"  title="Stop Streaming"></button>
                        <span>Stream</span>
                    </div>
                    <h4>Currently streaming</h4>
                    <p class="session"><a href="">Session</a> Create a true virtual journey for your participants</p>
                    <h3>Recommendations for improving and standardizing virtual journey</h3>
                </div><!-- /.nv-ls-config-bar-status -->
                <ul class="nv-live-stage-tool-list">
                    <li><a href="" class="preview-stage">Preview Stage</a></li>
                </ul>
            </div><!-- /.nv-live-stage-config-bar-panel -->

            <div class="nv-live-stage-config-bar-panel">
                <div class="nv-ls-config-bar-status ready"><!-- 'not-ready' class for alt state -->
                    <h4>STATUS</h4>
                    <span class="status-txt">ready</span>
                </div><!-- /.nv-ls-config-bar-status -->
                <ul class="nv-live-stage-tool-list">
                    <li><a href="" class="start-live-stream">Start Live Stream</a></li>
                    <li><a href="" class="screen-share">Screen share</a></li>
                    <li><a href="" class="off-air-screen">Off Air Screen</a></li>
                    <li><a href="" class="voting-screen">Voting</a></li>
                    <li><a href="" class="auto-pilot">Auto-pilot</a></li>
                    <li><a href="" class="setup">Setup</a></li>
                </ul>
            </div><!-- /.nv-live-stage-config-bar-panel -->
            <div class="nv-live-stage-config-bar-panel">
                <ul class="nv-live-stage-tool-list">
                    <li><a href="" class="chat">Chat</a></li>
                </ul>
            </div><!-- /.nv-live-stage-config-bar-panel -->
        </div><!-- /.nv-live-stage-config-bar -->
    </div><!-- /.nv-live-stage-config-layout -->
</section><!-- /.nv-live-stage-config-panel -->
