import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ConferenceService } from '@navus/core/services/conference.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '@navus/ui/modal/modal.service';
import { Color } from '@swimlane/ngx-charts';
import { SessionData } from '../models/sessionData';
import { TimeFormatPipe } from '@navus/ui/pipes/time-format.pipe';
import { Conference } from '@navus/core/classes/conference';

@Component({
  selector: 'main [nv-conference-dashboard-on-demand]',
  templateUrl: './conference-dashboard-on-demand.component.html',
})
export class ConferenceDashboardOnDemandComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  conference: Conference;

  loadingOnDemandNumbers: boolean;
  onDemandNumbers: OnDemandNumbers;

  dateFrom: string;
  dateTo: string;

  chartColorScheme = {
    domain: ['#318AF1', '#2873A1', '#8ABAD6', '#32B799', '#279079', '#18707F', '#ED4649', '#F49092', '#AD465A', '#ED8747']
  } as Color;
  chartCardColor: string = '#232837';

  totalTimeSpentChartData: { name: string, value: number }[] = [];
  totalTimeSpentPerDayChartData: { name: string, series: { name: string, value: number }[] }[] = [];

  totalParticipantsChartData: { name: string, value: number }[] = [];

  totalParticipantsPerDayChartData: { name: string, series: { name: string, value: number }[] }[] = [];

  loadingWatchingSessions: boolean;
  watchingSessionData: SessionData[];

  sessionParticipantsChartData: { name: string, value: number }[];
  presentationParticipantsChartData: { name: string, value: number }[];
  sessionParticipantsLegend: { name: string, value: string, color: string }[];
  sessionParticipantsActiveEntries = [];
  presentationParticipantsLegend: { name: string, value: string, color: string }[];
  presentationParticipantsActiveEntries = [];

  sessionTimeSpentChartData: { name: string, value: number }[];
  presentationTimeSpentChartData: { name: string, value: number }[];
  sessionTimeSpentLegend: { name: string, value: string, color: string }[];
  sessionTimeSpentActiveEntries = [];
  presentationTimeSpentLegend: { name: string, value: string, color: string }[];
  presentationTimeSpentActiveEntries = [];

  selectedSessionParticipants: SessionData;
  selectedSessionTimeSpent: SessionData;
  lastRecordedDateTime: string;

  downloadingOnDemandReport = false;

  private subscriptions: Subscription[] = [];

  constructor(private conferenceService: ConferenceService,
              private timeFormatPipe: TimeFormatPipe,
              private modalService: ModalService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = data.organizationId;
        this.conferenceId = data.conferenceId;

        this.conferenceService.getConference(this.conferenceId)
          .subscribe((response) => {
            this.conference = response.data;
            this.dateFrom = this.conference.starts_at;
            this.dateTo = this.conference.ends_at;

            this.getStatistics();
          }, (error) => {
            this.modalService.error({ errors: error });
          });
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getStatistics(): void {
    this.getOnDemandData();
    this.getWatchingSessions();
  }

  onSelectSessionParticipants(event) {
    const chosenSession = typeof event === 'string' ? event : event.name;
    if (chosenSession) {
      this.selectedSessionParticipants = this.watchingSessionData
        .find(item => item.name === chosenSession);
      this.presentationParticipantsChartData = this.selectedSessionParticipants
        .presentations?.map(item => ({ name: item.title, value: item.total_participants_on_demand }));
      this.presentationParticipantsLegend = this.presentationParticipantsChartData
        .map((presentation, index) => ({
          name: presentation.name,
          value: presentation.value.toString(),
          color: this.chartColorScheme.domain[index % 10]
        }));
    }
  }

  onSelectSessionTimeSpent(event) {
    const chosenSession = typeof event === 'string' ? event : event.name;
    if (chosenSession) {
      this.selectedSessionTimeSpent = this.watchingSessionData
        .find(item => item.name === chosenSession);
      this.presentationTimeSpentChartData = this.selectedSessionTimeSpent
        .presentations?.map(item => ({ name: item.title, value: item.time_spent_on_demand }));
      this.presentationTimeSpentLegend = this.presentationTimeSpentChartData
        .map((presentation, index) => ({
          name: presentation.name,
          value: this.timeFormatPipe.transform(presentation.value),
          color: this.chartColorScheme.domain[index % 10]
        }));
    }
  }

  sessionParticipantsLegendLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.sessionParticipantsActiveEntries = this.sessionParticipantsChartData.filter(item => item.name === name);
  }

  sessionParticipantsLegendLabelDeactivate(item: any): void {
    this.sessionParticipantsActiveEntries = [];
  }

  sessionTimeSpentLegendLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.sessionTimeSpentActiveEntries = this.sessionTimeSpentChartData.filter(item => item.name === name);
  }

  sessionTimeSpentLegendLabelDeactivate(item: any): void {
    this.sessionTimeSpentActiveEntries = [];
  }

  presentationParticipantsLegendLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.presentationParticipantsActiveEntries = this.presentationParticipantsChartData.filter(item => item.name === name);
  }

  presentationParticipantsLegendLabelDeactivate(item: any): void {
    this.presentationParticipantsActiveEntries = [];
  }

  presentationTimeSpentLegendLabelActivate(item: any): void {
    let name;
    if (item.value?.name) {
      name = item.value.name;
    } else {
      name = item.name;
    }
    this.presentationTimeSpentActiveEntries = this.presentationTimeSpentChartData.filter(item => item.name === name);
  }

  presentationTimeSpentLegendLabelDeactivate(item: any): void {
    this.presentationTimeSpentActiveEntries = [];
  }

  formatNumber = (value: number): any => this.transformToTime(value);

  transformToTime(value: number): string {
    return this.timeFormatPipe.transform(value);
  }

  applyDateRange(): void {
    this.getStatistics();
  }

  downloadOnDemandReport() {
    this.downloadingOnDemandReport = true;
    this.conferenceService.downloadOnDemandReport(this.conferenceId, {
      from_date: this.dateFrom,
      to_date: this.dateTo
    })
      .subscribe(
        () => {
          this.downloadingOnDemandReport = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.downloadingOnDemandReport = false;
        }
      );
  }

  private getOnDemandData(): void {
    this.loadingOnDemandNumbers = true;
    this.conferenceService.getConferenceWatchingOnDemand(this.conferenceId, {
      from_date: this.dateFrom,
      to_date: this.dateTo
    })
      .subscribe((response) => {
        this.loadingOnDemandNumbers = false;
        this.onDemandNumbers = response.data;

        this.generateTotalTimeSpentChart();
        this.generateTotalTimeSpentPerDayChart();

        this.generateTotalParticipantsChart();
        this.generateTotalParticipantsPerDayChart();
      }, (error) => {
        this.loadingOnDemandNumbers = false;
        this.modalService.error({ errors: error });
      });
  }

  private generateTotalTimeSpentChart(): void {
    this.totalTimeSpentChartData = [
      {
        name: 'Reading slides',
        value: this.onDemandNumbers.time_spent_main
      },
      {
        name: 'Viewing posters',
        value: this.onDemandNumbers.time_spent_poster
      },
      {
        name: 'Watching video content',
        value: this.onDemandNumbers.time_spent_webcast
      },
    ];
  }

  private generateTotalTimeSpentPerDayChart(): void {
    this.totalTimeSpentPerDayChartData = this.onDemandNumbers.total_participants_per_day
      .map(day => ({
        name: day.day,
        series: [
          {
            name: 'Total',
            value: day.time_spent,
          },
          {
            name: 'Video',
            value: day.time_spent_webcast,
          },
          {
            name: 'Slides',
            value: day.time_spent_main,
          },
          {
            name: 'Posters',
            value: day.time_spent_poster,
          },
        ]
      }));
  }

  private generateTotalParticipantsChart(): void {
    this.totalParticipantsChartData = [
      {
        name: 'Viewing all content',
        value: this.onDemandNumbers.total_participants
      },
      {
        name: 'Reading slides',
        value: this.onDemandNumbers.total_participants_main
      },
      {
        name: 'Viewing posters',
        value: this.onDemandNumbers.total_participants_poster
      },
      {
        name: 'Watching video content',
        value: this.onDemandNumbers.total_participants_webcast
      },
    ];
  }

  private generateTotalParticipantsPerDayChart(): void {
    this.totalParticipantsPerDayChartData = this.onDemandNumbers.total_participants_per_day
      .map(day => ({
        name: day.day,
        series: [
          {
            name: 'Total',
            value: day.total_participants,
          },
          {
            name: 'Video',
            value: day.total_participants_webcast,
          },
          {
            name: 'Slides',
            value: day.total_participants_main,
          },
          {
            name: 'Posters',
            value: day.total_participants_poster,
          },
        ]
      }));
  }

  private getWatchingSessions(): void {
    this.loadingWatchingSessions = true;
    this.conferenceService.getConferenceWatchingSessions(this.conferenceId, {
      from_date: this.dateFrom,
      to_date: this.dateTo
    })
      .subscribe((response) => {
        this.loadingWatchingSessions = false;
        this.watchingSessionData = response.data;

        if (response.last_recorded_date_time) {
          this.lastRecordedDateTime = response.last_recorded_date_time;
        }

        this.populateSessionCharts();
      }, (error) => {
        this.loadingWatchingSessions = false;
        this.modalService.error({ errors: error });
      });
  }

  private populateSessionCharts(): void {
    this.sessionParticipantsChartData = this.watchingSessionData
      .filter(session => session.total_participants_on_demand)
      .map(session => ({
        name: session.name,
        value: session.total_participants_on_demand
      }));
    this.sessionParticipantsLegend = this.watchingSessionData
      .filter(session => session.total_participants_on_demand)
      .map((session, index) => ({
        name: session.name,
        value: session.total_participants_on_demand.toString(),
        color: this.chartColorScheme.domain[index % 10]
      }));
    this.sessionTimeSpentChartData = this.watchingSessionData
      .filter(session => session.time_spent_on_demand)
      .map(session => ({
        name: session.name,
        value: session.time_spent_on_demand,
      }));
    this.sessionTimeSpentLegend = this.watchingSessionData
      .filter(session => session.time_spent_on_demand)
      .map((session, index) => ({
        name: session.name,
        value: this.timeFormatPipe.transform(session.time_spent_on_demand),
        color: this.chartColorScheme.domain[index % 10]
      }));
  }
}

interface OnDemandNumbers {
  time_spent: number;
  time_spent_main: number;
  time_spent_poster: number;
  time_spent_webcast: number;
  total_participants: number;
  total_participants_main: number;
  total_participants_poster: number;
  total_participants_webcast: number;
  total_participants_per_day: OnDemandDay[];
}

interface OnDemandDay {
  day: string;
  time_spent: number;
  time_spent_main: number;
  time_spent_poster: number;
  time_spent_webcast: number;
  total_participants: number;
  total_participants_main: number;
  total_participants_poster: number;
  total_participants_webcast: number;
}
