<section class="nv-section guttered nv-sponsors-page">
    <div class="nv-port">
        <div class="nv-row v-stretch large-spaced-top gap-40 propagate flow-center {{ sponsorGroup.display }}" *ngFor="let sponsorGroup of sponsorGroups">
            <div class="nv-column desk-12">
                <div class="nv-column-content" style="text-align: center;">
                    <h2>{{ sponsorGroup.name }}</h2>
                </div><!-- /.nv-column-content -->
            </div><!-- /nv-column -->
            <div class="nv-column" *ngFor="let sponsor of sponsorGroup.sponsors">
                <div class="nv-column-content">
                    <div class="nv-single-item" [routerLink]="['/sponsor', sponsor.id]">
                        <div class="si-logo" nvBackgroundImage [image]="sponsor.logo_url"></div><!-- /.si-logo -->
                        <h4>{{ sponsor.name }}</h4>
                        <p class="type"><span [style.background-color]="sponsorGroup.color"></span>{{ sponsorGroup.name }}</p><!-- platinum / gold / silver / friend -->
                    </div><!-- /.nv-single-item -->
                </div><!-- /.nv-column-content -->
            </div><!-- /nv-column -->
        </div><!-- /.nv-row -->
    </div><!-- /.nv-port -->
</section><!-- /.nv-section -->
