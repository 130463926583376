import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ArticleService } from '@navus/core/services/article.service';
import { Article } from '@navus/core/classes/article';
import { LoadingService } from '@navus/ui/loading/loading.service';
import { Subscription } from 'rxjs';
import { WebsiteService } from '@navus/core/services/website.service';
import { Website } from '@navus/core/classes/website';

@Component
({
  selector: 'nv-article-details-page',
  templateUrl: './article-details-page.component.html'
})
export class ArticleDetailsPageComponent implements OnInit, OnDestroy {
  organizationId: number;
  articleId: number;
  article: Article;
  hotTopics: Article[] = [];
  articles: Article[] = [];

  subscriptions: Subscription[] = [];

  constructor(
    private articleService: ArticleService,
    private websiteService: WebsiteService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    const websiteSubscription = this.websiteService.currentWebsite
      .subscribe(
        (website: Website) => {
          this.organizationId = website.organization_id;

          this.getHotTopics();
          this.getArticles();
        }
      );
    this.subscriptions.push(websiteSubscription);

    const subscription = this.route.params
      .subscribe(
        (data: any) => {
          this.articleId = data.articleId;

          this.getArticle();
        },
        () => { }
      );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getHotTopics() {
    this.loadingService.start('articles', 'fullPage');
    this.articleService.getOrganizationArticles(this.organizationId, { is_hot_topic: 1, with_pagination: 0 })
      .subscribe(
        (response: { data: Article[], meta: any }) => {
          this.hotTopics = response.data;
          this.loadingService.stop('articles');
        },
        () => {
          this.loadingService.stop('articles');
        }
      );
  }

  getArticles() {
    this.loadingService.start('articles', 'fullPage');
    this.articleService.getOrganizationArticles(this.organizationId, { is_hot_topic: 0 })
      .subscribe(
        (response: { data: Article[], meta: any }) => {
          this.articles = response.data;
          this.loadingService.stop('articles');
        },
        () => {
          this.loadingService.stop('articles');
        }
      );
  }

  getArticle() {
    this.loadingService.start('articles', 'fullPage');
    this.articleService.getOrganizationArticle(this.organizationId, this.articleId)
      .subscribe(
        (response: { data: Article, meta: any }) => {
          this.article = response.data;
          this.article.content = this.sanitizer.bypassSecurityTrustHtml(this.article.content.toString());
          this.loadingService.stop('articles');
        },
        () => {
          this.loadingService.stop('articles');
        }
      );
  }

  articleDetails(article) {
    if (article.link) {
      window.open(article.link, '_blank');
    } else {
      this.router.navigate(['/article', article.id]);
    }
  }

}
