import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Delegate } from '@navus/core/classes/delegate';
import { BadgeService } from '@navus/core/services/badge.service';
import { Badge } from '@navus/core/classes/badge';

@Component({
  selector: 'main [nv-leaderboard-page]',
  templateUrl: './leaderboard.component.html'
})
export class LeaderboardPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.nv-config-content') class = true;

  organizationId: number;
  conferenceId: number;
  delegates: Delegate[] = [];
  badgeLevels: any[] = [];

  pagination: { page?: number, perPage: number, total?: number } = {
    perPage: 30
  };
  sort: { field: string, direction: string } = {
    field: 'name',
    direction: 'asc'
  };
  selectedIds: number[] = [];

  delegatesLoading: boolean = false;
  
  formFilter: FormGroup;

  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private badgeService: BadgeService,
  ) { }

  ngOnInit() {
    this.formFilter = this.formBuilder.group({
      search_term: [''],
      badge_levels: [''],
    });
    const subscription = this.route.params.subscribe(
      (data) => {
        this.organizationId = +data.organizationId;
        this.conferenceId = +data.conferenceId;
        this.getDelegates();
        this.getBadgeLevels();
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getDelegates(append: boolean = false) {
    if (append && this.pagination.total && this.delegates.length >= this.pagination.total) { return; }

    this.pagination.page = append ? this.pagination.page + 1 : 1;

    const params: any = {
      include: 'delegate',
      completed: true,
      page: this.pagination.page,
      per_page: this.pagination.perPage,
      sort_by: this.sort.field,
      sort_direction: this.sort.direction,
    };
    if (this.formFilter.value.search_term !== '') {
      params.search_term = this.formFilter.value.search_term;
    }
    if (this.formFilter.value.badge_levels !== '') {
      params.badge_levels = this.formFilter.value.badge_levels;
    }
    this.delegatesLoading = !append;
    this.badgeService
      .getConferenceLeaderboard(this.conferenceId, params)
      .subscribe(
        (response: { data: Delegate[], meta: any }) => {
          if (append) {
            this.delegates = this.delegates.concat(response.data);
          } else {
            const port = document.getElementsByClassName('nv-config-content-port');
            if (port && port.length > 0) {
              port[0].scrollTop = 0;
            }

            this.delegates = response.data;
            this.pagination.total = response.meta.total;
          }
          this.delegatesLoading = false;
        },
        () => {
          this.delegatesLoading = false;
        }
      );
  }

  getBadgeLevels() {
    const params: any = {
      include: 'badge_levels',
    };
    this.delegatesLoading = true;
    this.badgeService
      .getConferenceBadges(this.conferenceId, params)
      .subscribe(
        (response: { data: Badge[], meta: any }) => {
          const badges =  response.data;
          const badgeLevels = [];

          badges.forEach(badge => {
            badge.badge_levels.forEach((badgeLevel) => {
              badgeLevels.push({
                id: badgeLevel.id,
                name: badge.name + ': ' + badgeLevel.name,
              });
            });
          })

          this.badgeLevels = badgeLevels;
          
          this.delegatesLoading = false;
        },
        () => {
          this.delegatesLoading = false;
        }
      );
  }

  add() {
    
  }

}
