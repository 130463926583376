import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class BlockService {

  constructor(
    private apiService: ApiService
  ) { }

  getBlocks(params: any = {}) {
    return this.apiService.get(`blocks`, params);
  }

  getBlock(blockId: number, params: any = {}) {
    return this.apiService.get(`blocks/${blockId}`, params);
  }

  updateBlock(blockId: number, params: any = {}) {
    return this.apiService.put(`blocks/${blockId}`, params);
  }

  createBlock(params: any = {}) {
    return this.apiService.post(`blocks`, params);
  }

  deleteBlock(blockId: number) {
    return this.apiService.delete(`blocks/${blockId}`);
  }

  getConferenceBlocks(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/blocks`, params);
  }

  getConferenceBlock(conferenceId: number, blockId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/blocks/${blockId}`, params);
  }

  updateConferenceBlock(conferenceId: number, blockId: number, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/blocks/${blockId}`, params);
  }

  createConferenceBlock(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/blocks`, params);
  }

  deleteConferenceBlock(conferenceId: number, blockId: number) {
    return this.apiService.delete(`conferences/${conferenceId}/blocks/${blockId}`);
  }

}
